import React, { useEffect, useRef, useState } from 'react'
import styles from './AddFilterDropdownBox.module.scss'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import AddFilterDropdownBoxDetails from './AddFilterDropdownBoxDetails/AddFilterDropdownBoxDetails'
import { useAppDispatch } from 'src/store/store'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
export default function AddFilterDropdownBox({
  newFilterdDropdown,
  setNewFilterdDropdown,
  newVariantData,
  setNewVariantData,
  selectedCheckboxes,
  setSelectedCheckboxes,
  filteredData,
  setFilteredData,
  handleResetData,
}) {
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const [dropdownIndex, setDropdownIndex] = useState(null)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const dispatch = useAppDispatch()

  const transformedArray =
    getAllState?.allModalData[0]?.isEdit === true
      ? addProductDetailSlice?.id?.variant?.colorCodeArray?.map((item) => {
          if (item.name === 'Color' || item.name === 'color') {
            const colorData = item?.data?.map((data) => {
              return { name: data?.name, colorCode: data?.colorCode }
            })
            return { name: item?.name, data: colorData }
          } else if (
            (item.name !== 'Color' && item.name !== 'SIZE') ||
            (item.name !== 'color' && item.name !== 'size')
          ) {
            return { name: item?.name, data: item?.data }
          } else {
            return item
          }
          // if (item.name === 'Color') {
          //   const colorData = item?.data?.map((colorItem) => colorItem?.name)
          //   return { name: item?.name, data: colorData }
          // } else {
          //   return item
          // }
        })
      : addProductDetailSlice?.id?.variant?.allVariant?.map((item) => {
          if (item.name === 'Color') {
            const colorData = item?.data?.map((data) => {
              return { name: data?.name, colorCode: data?.colorCode }
            })
            return { name: item?.name, data: colorData }
          } else if (item.name !== 'Color' && item.name !== 'SIZE') {
            return { name: item?.name, data: item?.data }
          } else {
            return item
          }
        })

  const [filterdDropdown, setFilterdDropdown] = useState(transformedArray || [])

  const handleFilteredBox = async (isCheck, data, colorCode) => {
    const checkboxValue = isCheck

    let updatedSelectedCheckboxes = [...(addProductDetailSlice?.id?.filterColorList || [])]
    let updatedFilteredData = [...(addProductDetailSlice?.id?.filteredList || [])]

    if (checkboxValue) {
      if (!updatedSelectedCheckboxes.includes(data)) {
        updatedSelectedCheckboxes.push(data)
        if (colorCode) {
          updatedFilteredData.push({ name: data, colorCode })
        } else {
          updatedFilteredData.push(data)
        }
      }
    } else {
      updatedSelectedCheckboxes = updatedSelectedCheckboxes.filter((item) => item !== data)
      if (colorCode) {
        updatedFilteredData = updatedFilteredData.filter((item) => item?.name !== data)
      } else {
        updatedFilteredData = updatedFilteredData.filter((item) => item !== data)
      }
    }
    setSelectedCheckboxes(updatedSelectedCheckboxes)
    setFilteredData(updatedFilteredData)

    await dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          filteredList: updatedFilteredData,
          filterColorList: updatedSelectedCheckboxes,
        },
      }),
    )

    const updatedData = newVariantData.map((item) => {
      const isChecked = updatedSelectedCheckboxes.some((selectedData) => item.total_variant.includes(selectedData))
      return { ...item, isChecked }
    })

    setNewVariantData(updatedData)
    const currentDropdownData = filterdDropdown[dropdownIndex]?.data
    const isAllChecked = currentDropdownData?.every((data) => updatedSelectedCheckboxes.includes(data))

    if (isAllChecked) {
      const allData = filterdDropdown.reduce((acc, dropdown) => [...acc, ...dropdown.data], [])
      setSelectedCheckboxes(allData)
      updateAllCheckboxes(true)
    }
  }

  const updateAllCheckboxes = (checked) => {
    const allData = filterdDropdown.reduce((acc, dropdown) => [...acc, ...dropdown.data], [])
    const updatedData = newVariantData.map((item) => ({
      ...item,
      isChecked: checked,
    }))
    setNewVariantData(updatedData)
    setSelectedCheckboxes(checked ? allData : [])
  }

  return (
    <div
      className={styles.addProdutcMain}
      //   className={
      //     newFilterdDropdown
      //       ? classNames(styles.addProdutcMain, styles.openAddProdutModal)
      //       : (styles.addProdutcMain, styles.closeAddProdutModal)
      //   }
    >
      <div
        // className={styles.addProductFilterAllDetailsBoxAlignment}
        className={
          newFilterdDropdown
            ? classNames(styles.addProductFilterAllDetailsBoxAlignment, styles.openAddProdutModal)
            : (styles.addProductFilterAllDetailsBoxAlignment, styles.closeAddProdutModal)
        }
      >
        <AddFilterDropdownBoxDetails
          selectedCheckboxes={selectedCheckboxes}
          setSelectedCheckboxes={setSelectedCheckboxes}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          handleResetData={handleResetData}
          newVariantData={newVariantData}
          setNewVariantData={setNewVariantData}
          newFilterdDropdown={newFilterdDropdown}
        />
      </div>
    </div>
  )
}
