import React, { useEffect } from 'react'
import styles from './PopularStoreMobileViewModal.module.scss'
import { useSelector } from 'react-redux'
import { useMutation, useQuery } from '@apollo/client'
import { notification } from 'antd'
import FOLLOW_STORE from 'src/graphql/mutations/followStore'
import UNFOLLOW_STORE from 'src/graphql/mutations/unFollowStore'
import GET_FOLLOW_STORES from 'src/graphql/queries/sellerQueries/getFollowStores'
import { useAuth } from 'src/utils/auth'
import NewStoreSlider from '../NewStoreSlider'
import { useAppDispatch } from 'src/store/store'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { fetchAllStoreData, setJoinedStoreState } from 'src/store/slices/fetchAllStoresSlice'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import ADD_STORELIKE from 'src/graphql/mutations/addStoreLike'
export default function PopularStoreMobileViewModal() {
  const dispatch = useAppDispatch()
  const { user }: any = useAuth()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  // const { loading, error, data, refetch } = useQuery(GET_FOLLOW_STORES)
  const allStores = useSelector((state: any) => state?.storesData?.getAllStore)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)

  const [followStore] = useMutation(FOLLOW_STORE, {
    onCompleted: (res) => {
      setTimeout(() => {
        // refetch()
        dispatch(fetchAllStoreData({ limit: null, page: 1, type: 'ALL_STORE', prevPost: [] }))
      }, 1000)
    },
    onError: (err) => {
      notification['error']({
        message: 'Error',
        description: 'Something went wrong',
      })
    },
  })

  const [unfollowStore] = useMutation(UNFOLLOW_STORE, {
    onCompleted: (res) => {
      setTimeout(() => {
        // refetch()
        dispatch(fetchAllStoreData({ limit: null, page: 1, type: 'ALL_STORE', prevPost: [] }))
      }, 1000)
    },
    onError: (err) => {
      notification['error']({
        message: 'Error',
        description: 'Something went wrong',
      })
    },
  })

  const unfollowStoreCall = (store) => {
    if (user) {
      unfollowStore({
        variables: { storeId: store.id },
        update: (proxy, { data }) => {
          const followedStore: any = proxy.readQuery({
            query: GET_FOLLOW_STORES,
          })
          let updatedDataofgetMyGroup = followedStore?.getFollowStores?.data?.stores?.filter(
            (grp) => grp?.id !== store?.id,
          )
          proxy.writeQuery({
            query: GET_FOLLOW_STORES,
            data: {
              getFollowStores: {
                data: {
                  stores: updatedDataofgetMyGroup,
                },
              },
            },
          })
        },
        // refetchQueries: [{ query: GET_FOLLOW_STORES }],
      })
    } else {
      handleRedirect()
    }
  }

  const followStoreCall = (store) => {
    if (user) {
      followStore({
        variables: { store_id: store.id },
        // refetchQueries: [{ query: GET_FOLLOW_STORES }],
      })
    } else {
      handleRedirect()
    }
  }

  const handleAddStoreLike = (id) => {
    if (user) {
      addStoreLike({
        variables: {
          storeId: id,
        },
        // refetchQueries: [
        //   {
        //     query: GET_SINGLE_STORE_LIKE,
        //     variables: {
        //       storeId: Number(id),
        //     },
        //   },
        // ],
      })
    } else {
      handleRedirect()
    }
  }

  const [addStoreLike] = useMutation(ADD_STORELIKE, {
    onCompleted: (res) => {
      // storeSearch?.refetch()
      dispatch(fetchAllStoreData({ limit: null, page: 1, type: 'ALL_STORE', prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  useEffect(() => {
    // refetch()
    dispatch(fetchAllStoreData({ limit: null, page: 1, type: 'ALL_STORE', prevPost: [] }))
  }, [])
  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const handleCloseFollowStore = () => {
    dispatch(
      fetchMobileModalData({
        allModalData: [
          ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
          {
            modal: 'popularMobileViewStoreModal',
            isOpen: false,
            id: { isSeller: false, group: null, type: 'post' },
            transform: 6,
          },
        ],
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
    dispatch(setJoinedStoreState([]))
  }

  return (
    <div className={styles.storeMobileViewModalAlignment}>
      <div className={styles.storeMobileViewModalHeading}>
        <h4>Popular Stores</h4>
        <div className={styles.storeMobileCloseAlignment} onClick={() => handleCloseFollowStore()}>
          <a>Close</a>
        </div>
      </div>

      <div className={styles.storeMobileViewBodyAlignment}>
        <div className={styles.storeMobileGridAlignment}>
          {allStores?.map((store, index) => (
            <div className={styles.storeMobileGridItemAlignment} key={index}>
              <NewStoreSlider
                store={store}
                followStoreCall={followStoreCall}
                unfollowStoreCall={unfollowStoreCall}
                handleAddStoreLike={handleAddStoreLike}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
