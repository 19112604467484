import { gql } from '@apollo/client'

const GET_ALL_BOOKMARK = gql`
  query GetAllBookmark($slug: String, $page: Int, $limit: Int, $userSlug: String) {
    getAllBookmark(slug: $slug, page: $page, limit: $limit, user_slug: $userSlug) {
      data {
        products {
          id
          title
          slug
          like_count
          comment_count
          sharepost_count
          dis_price
          dis_listPrice
          total_variants
          total_inventory_quantity
          images {
            media_id
            src
          }
          likes {
            id
          }
          variants {
            inventory_quantity
          }
          bookmark {
            id
            collection {
              id
              name
              user_id
              isPrivate
              slug
            }
          }
        }
      }
      total
    }
  }
`
export default GET_ALL_BOOKMARK
