import { useState } from 'react'

interface Errors {
  [key: string]: string
}

export const useOnChange = (initialValue) => {
  const [inputValue, setInputValue] = useState(initialValue)
  const [errors, setErrors] = useState<Errors>({})
  const handleChange = (e) => {
    const { name, type, value, checked } = e.target
    if (type === 'checkbox') {
      setInputValue({ ...inputValue, [name]: checked })
    } else if (type === 'radio') {
      setInputValue({ ...inputValue, [name]: value })
    } else {
      setInputValue({ ...inputValue, [name]: value })
    }
    setErrors({ ...errors, [name]: '' })
  }
  return {
    inputValue,
     setInputValue,
    errors,
    setErrors,
    handleChange,
  }
}
