import React from 'react'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAppDispatch } from 'src/store/store'

export default function NewRegisterSuccess() {
  const dispatch = useAppDispatch()

  const handleBackogin = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: { media: 'login', modalFor: 'login', startWith: 'loginModal' },
      }),
    )
  }
  return (
    <div className="new-register-sueccess-details-alignment">
      <div className="new-register-sueccess-right-sign-alignment">
        <img src="/assets/icon/right-sign-icon.svg" alt="right sign" />
      </div>
      <div className="new-register-sueccess-congratulation">
        <h4>Check Email</h4>
      </div>
      <div className="new-register-sueccess-ualoded-heading-alignment">
        {/* <h4>Your account has been successfully registered.</h4> */}
        <p>
          Please check your email inbox and click on the provided link to verify you email. If you don’t receive the
          email, <a href="/">click here to resend</a>
        </p>
      </div>
      <div className="new-register-sueccess-back-to-login-button-alignment" onClick={handleBackogin}>
        <button>Back to Login</button>
      </div>
    </div>
  )
}
