// @ts-nocheck


import React from 'react'
import Slider from 'react-slick'
import LeftIcon from '../../../../../public/assets/icon/left-icon-black.svg'
import LeftWhiteIcon from '../../../../../public/assets/icon/left-icon-white.svg'
import RightIcon from '../../../../../public/assets/icon/right-icon-black.svg'
import RightWhiteIcon from '../../../../../public/assets/icon/right-icon-white.svg'
import LazyImage from 'src/utils/LazyImage'

export default function PostPreviewImageSlider({ getSingleProduct }) {
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props
    return (
      <div className={className} onClick={onClick}>
        <img src={RightIcon.src} alt="RightIcon" className="defalt-icon" />
        <img src={RightWhiteIcon.src} alt="RightWhiteIcon" className="hover-img" />
      </div>
    )
  }

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props
    return (
      <div className={className} onClick={onClick}>
        <img src={LeftIcon.src} alt="LeftIcon" className="defalt-icon" />
        <img src={LeftWhiteIcon.src} alt="LeftWhiteIcon" className="hover-img" />
      </div>
    )
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  const sliderContent = () => {
    return getSingleProduct?.attachements?.map((media, index) => {
      return (
        <div className="img-media-alignment" key={index}>
          {media?.url?.includes('mp4') ? (
            <video loop autoPlay={true} muted>
              <source src={media?.url} type="video/mp4" />
            </video>
          ) : (
            // <img src={media?.url} alt="DemoImg" />

            <LazyImage
              image={{ src: media?.url, alt: 'Icon' }}
              objectFit={'contain'}
              handleClickOnImage={() => {}}
              // height={526}
              // height={isDesktop > 1024 ? 526 : 140}
              className={''}
              imageClassName={''}
            />
          )}
        </div>
      )
    })
  }

  return (
    <div className="social-profile-first-part-alignment">
      {/* <Slider {...settings}> */}
      {getSingleProduct?.attachements?.length > 1 ? <Slider {...settings}>{sliderContent()}</Slider> : sliderContent()}

      {/* </Slider> */}
    </div>
  )
}
