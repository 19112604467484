import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { fetchSellerWithdrawModalData } from 'src/store/slices/manageSellerWithdrawSlice'
import { fetchUserProfilModalScroll } from 'src/store/slices/userProfileonScrollSlice'
import { useAppDispatch } from 'src/store/store'

export default function SellerWithdrawFundModal({ isModalOpen, children }) {
  const myElementRef = useRef(null)
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const sellerWithdrawData = useSelector((state: any) => state?.sellerWithdrawData?.data)

  const handleClose = () => {
    // if (getAllState?.lastModalData?.modal === 'sellerWithdrawTransactionDetail') {
    //   dispatch(
    //     fetchModalData({
    //       allModalData: [],
    //       lastModalData: {},
    //     }),
    //   )
    // } else {

    let datass = sellerWithdrawData?.allModalData?.filter(
      (item) => item?.modal !== sellerWithdrawData?.lastModalData?.modal,
    )
    dispatch(
      fetchSellerWithdrawModalData({
        allModalData: datass,
        lastModalData: sellerWithdrawData?.lastModalData,
        withdrawFund: sellerWithdrawData?.withdrawFund,
      }),
    )
    // }
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        handleClose()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleClose])

  const handleScroll = () => {
    const scrollTop = myElementRef.current.scrollTop
    dispatch(
      fetchUserProfilModalScroll({
        scroll: scrollTop,
      }),
    )
  }

  useEffect(() => {
    dispatch(
      fetchUserProfilModalScroll({
        scroll: 0,
      }),
    )
  }, [getAllState])

  useEffect(() => {
    if (getAllState?.lastModalData?.isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [getAllState])

  return (
    <React.Fragment>
      <div onClick={() => handleClose()} className={`overlay ${isModalOpen ? 'open' : ''}`}></div>
      <div className={`seller-withdrawal-modal ${isModalOpen ? 'open' : ''}`}>
        <div className="sidebar-modal-alignment" ref={myElementRef} onScroll={handleScroll}>
          {children}
        </div>
      </div>
    </React.Fragment>
  )
}
