import React, { useEffect, useState } from 'react'
import styles from './FeedMobileViewCreateNewPostModal.module.scss'
import classNames from 'classnames'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAuth } from 'src/utils/auth'
import { useMutation, useQuery } from '@apollo/client'
import CREATE_POST_MUTATION from 'src/graphql/mutations/createPost'
import UPDATE_POST_MUTATION from 'src/graphql/mutations/updatePost'
import GET_STORE_BY_NAME from 'src/graphql/queries/getStoreByName'
import sellerClient from 'src/utils/apolloSellerClient'
import client1 from 'src/utils/apolloClient'
import UPDATE_SELLER_POST_MUTATION from 'src/graphql/queries/sellerQueries/updatePost'
import FileUpload from 'src/components/FileUpload'
import { ApiPost } from 'src/Helpers/Api/ApiData'
import { API } from 'src/config/API/api.config'
import query from 'src/graphql/mutations/acceptFriendRequest'
import { set } from 'react-hook-form'
import { fetchStoreData } from 'src/store/slices/updateStoreDetailSlice'
import { postCountData } from 'src/store/slices/createPostCountSlice'
import { notification } from 'antd'
import { fetchAllFeedSliceData, fetchGroupPost } from 'src/store/slices/fetchAllFeedSlice'
import { setPostDetailsData } from 'src/store/slices/createPostProductSlice'

export default function FeedMobileViewCreateNewPostModal() {
  const { user }: any = useAuth()
  const dispatch = useAppDispatch()
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [postImage, setPostImage] = useState(false)
  const [progressPercent, setProgressPercent] = useState(0)
  const [newUserInfo, setNewUserInfo] = useState({
    profileImages: [],
  })
  const postCount = useSelector((state: any) => state?.postCountData?.data)
  // const { refetch } = useQuery(MY_FEED_QUERY)
  const [files, setFiles] = useState({})
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const allPost = useSelector((state: any) => state?.feedData?.allPost)

  const maxFileSizeInBytes = 5000000000
  const convertNestedObjectToArray = (nestedObj) => Object.keys(nestedObj).map((key) => nestedObj[key])

  const addNewFiles = (newFiles) => {
    for (const file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!newUserInfo?.profileImages.length) {
          return { file }
        }
        files[file.name] = file
      }
    }
    return { ...files }
  }

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files)
    updateUploadedFiles(filesAsArray)
  }

  // const handleNewFileUpload = (e) => {
  //   const { files: newFiles } = e.target
  //   if (newFiles.length) {
  //     const updatedFiles = addNewFiles(newFiles)
  //     setFiles(updatedFiles)
  //     callUpdateFilesCb(updatedFiles)
  //   }
  // }

  const removeFile = (fileName) => {
    delete files[fileName]
    setFiles({ ...files })
    callUpdateFilesCb({ ...files })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (postCount.count < 100 && loading && !fetchMobileModal?.lastModalData?.id?.editPost?.isEdit) {
        dispatch(
          postCountData({
            ...postCount,
            count: postCount.count + 2,
            isUploaded: true,
            isError: false,
            isShow: true,
          }),
        )
      }
    }, 100)

    return () => {
      clearInterval(interval)
    }
  }, [postCount.count, loading])

  const isNormalPost = fetchMobileModal?.lastModalData?.id?.type === 'post' ? true : false
  const isStorePost = fetchMobileModal?.lastModalData?.id?.type === 'store' ? true : false
  const isGroupPost = fetchMobileModal?.lastModalData?.id?.type === 'group' ? true : false
  const mobile = fetchMobileModal?.lastModalData?.id?.type
  const group = fetchMobileModal?.lastModalData?.id?.group

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleClose = () => {
    let datass = fetchMobileModal?.allModalData?.filter(
      (item) => item?.modal !== fetchMobileModal?.lastModalData?.modal,
    )
    dispatch(
      fetchMobileModalData({
        allModalData: datass,
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
  }

  const updateUploadedFiles = (files) => setNewUserInfo({ ...newUserInfo, profileImages: files })

  let UploadImageRoute =
    API.endpoint.includes('stage') || API.endpoint.includes('local')
      ? `/upload?type=bluejestic-stage/post`
      : `/upload?type=bluejestic-production/post`
  const postHandler = async () => {
    if (inputValue !== '') {
      setProgressPercent(10)
      if (fetchMobileModal?.lastModalData?.id?.editPost?.isEdit) {
        setProgressPercent(20)
        if (isNormalPost) {
          setLoading(true)
          editPostMutation({
            variables: {
              id: fetchMobileModal?.lastModalData?.id?.editPost?.id,
              content: inputValue,
              title: inputValue,
            },
          })
        } else if (isGroupPost) {
          setLoading(true)
          editPostMutation({
            variables: {
              id: fetchMobileModal?.lastModalData?.id?.editPost?.id,
              content: inputValue,
              title: inputValue,
            },
            // refetchQueries: [
            //   { query: MY_FEED_QUERY, variables: { group_id: fetchMobileModal?.id?.editPost?.groupId } },
            // ],
          })
        } else if (isStorePost) {
          setLoading(true)
          updateSellerPost({
            client: sellerClient,
            variables: {
              id: fetchMobileModal?.lastModalData?.id?.editPost?.id,
              content: inputValue,
              title: inputValue,
            },
            // refetchQueries: [
            //   { query: MY_FEED_QUERY, variables: { group_id: fetchMobileModal?.id?.editPost?.groupId } },
            // ],
          })
        }
        setProgressPercent(90)
      } else {
        setProgressPercent(30)
        let ImageId = []
        // debugger
        if (newUserInfo.profileImages.length > 0) {
          const UploadFile = new FormData()
          newUserInfo.profileImages.forEach((file) => {
            UploadFile.append('image', file)
          })
          UploadFile.append('media_for', 'post')
          // const files = await ApiPost(UploadImageRoute, '')
          const files = await ApiPost(UploadImageRoute, UploadFile)
            .then((res: any) => {
              res?.data?.image?.map((image) => {
                ImageId.push(image.id)
                setLoading(false)
              })
              setProgressPercent(70)
              createPostHandle(ImageId)
            })
            .catch((err) => {
              notification['error']({
                message: 'Error',
                description: 'Something went wrong',
              })
              setProgressPercent(0)
              dispatch(
                postCountData({
                  ...postCount,
                  isError: true,
                  isShow: true,
                }),
              )
              return err
            })
        }
        setProgressPercent(100)
        dispatch(setPostDetailsData({ inputValue: inputValue, imageIds: ImageId }))
        setTimeout(() => {
          setLoading(false)
          handleClose()
        }, 500)
        if (newUserInfo.profileImages.length <= 0) {
          createPostHandle(ImageId)
        }
      }
    }
  }

  const createPostHandle = async (ImageId) => {
    await createPost({
      variables: {
        content: inputValue,
        title: inputValue,
        post_for: fetchMobileModal?.id?.isSeller ? 'SELLER' : isNormalPost ? 'USER' : 'GROUP',
        media_id: ImageId,
        group_id: isNormalPost ? null : group?.id,
        store_id: fetchMobileModal?.id?.isSeller && user?.store ? user?.store?.id : null,
      },
      // refetchQueries: fetchMobileModal?.id?.isSeller
      //   ? [
      //       { query: GET_STORE_BY_NAME, variables: { storeName: user?.store?.name } },
      //       { query: MY_FEED_QUERY, variables: { group_id: group?.id } },
      //     ]
      //   : isNormalPost
      //   ? [
      //       {
      //         query: MY_FEED_QUERY,
      //       },
      //     ]
      //   : [{ query: MY_FEED_QUERY, variables: { group_id: group?.id } }],
    })
    if (newUserInfo.profileImages.length <= 0) {
      // refetch()
    }
  }

  const [createPost] = useMutation(CREATE_POST_MUTATION, {
    client: fetchMobileModal?.lastModalData?.id?.isSeller ? sellerClient : client1,
    onCompleted: (res) => {
      setLoading(false)
      handleClose()
      setInputValue('')
      dispatch(fetchGroupPost({ page, limit, group_id: group?.id, prevPost: [] }))
      dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const [editPostMutation] = useMutation(UPDATE_POST_MUTATION, {
    onCompleted: () => {
      setLoading(false)
      handleClose()
      setInputValue('')
      setPostImage(false)
      dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
      dispatch(fetchGroupPost({ page, limit, group_id: group?.id, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const [updateSellerPost] = useMutation(UPDATE_SELLER_POST_MUTATION, {
    onCompleted: () => {
      setLoading(false)
      handleClose()
      setInputValue('')
      dispatch(fetchGroupPost({ page, limit, group_id: group?.id, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  // const handleClick = async () => {
  //   if (fetchMobileModal?.lastModalData?.id?.editPost?.isEdit) {
  //     if (isNormalPost) {
  //       setLoading(true)
  //       editPostMutation({
  //         variables: {
  //           id: fetchMobileModal?.lastModalData?.id?.editPost?.id,
  //           content: inputValue,
  //           title: inputValue,
  //         },
  //         refetchQueries: [
  //           {
  //             query: MY_FEED_QUERY,
  //             variables: { user_id: user?.id },
  //           },
  //           {
  //             query: MY_FEED_QUERY,
  //           },
  //         ],
  //       })
  //     } else if (isGroupPost) {
  //       setLoading(true)
  //       editPostMutation({
  //         variables: {
  //           id: fetchMobileModal?.lastModalData?.id?.editPost?.id,
  //           content: inputValue,
  //           title: inputValue,
  //         },
  //         refetchQueries: [
  //           { query: MY_FEED_QUERY, variables: { group_id: fetchMobileModal?.lastModalData?.id?.editPost?.groupId } },
  //         ],
  //       })
  //     } else if (isStorePost) {
  //       setLoading(true)
  //       updateSellerPost({
  //         client: sellerClient,
  //         variables: {
  //           id: fetchMobileModal?.lastModalData?.id?.editPost?.id,
  //           content: inputValue,
  //           title: inputValue,
  //         },
  //         refetchQueries: [
  //           { query: MY_FEED_QUERY, variables: { group_id: fetchMobileModal?.lastModalData?.id?.editPost?.groupId } },
  //         ],
  //       })
  //     }
  //   } else {
  //     setLoading(true)
  //     let ImageId = []
  //     // if (newUserInfo.profileImages.length > 0) {
  //     //   const UploadFile = new FormData()
  //     //   newUserInfo.profileImages.forEach((file) => {
  //     //     UploadFile.append('image', file)
  //     //   })
  //     //   UploadFile.append('media_for', 'post')
  //     //   const files = await ApiPost(UploadImageRoute, UploadFile)
  //     //     .then((res: any) => {
  //     //       res?.data?.image?.map((image) => {
  //     //         ImageId.push(image.id)
  //     //       })
  //     //     })
  //     //     .catch((err) => {
  //     //       return err
  //     //     })
  //     // }
  //     await createPost({
  //       variables: {
  //         content: inputValue,
  //         title: inputValue,
  //         post_for: fetchMobileModal?.lastModalData?.id?.isSeller ? 'SELLER' : isNormalPost ? 'USER' : 'GROUP',
  //         media_id: ImageId,
  //         group_id: isNormalPost ? null : group?.id,
  //         store_id: fetchMobileModal?.lastModalData?.id?.isSeller && user?.store ? user?.store?.id : null,
  //       },
  //       refetchQueries: fetchMobileModal?.lastModalData?.id?.isSeller
  //         ? [
  //             { query: GET_STORE_BY_NAME, variables: { storeName: user?.store?.name } },
  //             { query: MY_FEED_QUERY, variables: { group_id: group?.id } },
  //           ]
  //         : isNormalPost
  //         ? [
  //             {
  //               query: MY_FEED_QUERY,
  //             },
  //           ]
  //         : [{ query: MY_FEED_QUERY, variables: { group_id: group?.id } }],
  //     })
  //   }
  // }

  useEffect(() => {
    if (fetchMobileModal?.lastModalData?.id?.editPost?.isEdit) {
      setInputValue(fetchMobileModal?.lastModalData?.id?.editPost?.content)
    }
  }, [fetchMobileModal])

  return (
    <div className={styles.mobileViewCreateNewPostModalDetailsSection}>
      <div className={styles.mobileViewCreatenewPostMainHeadingAlignment}>
        <h4>Create New Post</h4>
        <div className={styles.mobileVIewCancleButton}>
          <button onClick={() => handleClose()}>Cancel</button>
        </div>
      </div>
      <div className={styles.mobileViewCreateNewPostMainDetailsAlignment}>
        <div className={styles.mobileViewCreateNewPostAlignment}>
          <div className={classNames(styles.mobileViewCreateNewPostImgAlignment, styles.roundedImg)}>
            <img
              src={
                fetchMobileModal?.lastModalData?.id?.isSeller
                  ? user?.store?.logo_image
                  : user?.logo_image && user?.logo_image
              }
              alt="feed img"
            />
          </div>
          <div className={styles.mobileViewCreatePostDescriptionBox}>
            <textarea
              defaultValue={inputValue}
              value={inputValue}
              autoFocus
              placeholder="What’s on your mind?"
              onChange={handleInputChange}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="file-uploded-img-section">
        {Object.keys(files).length != 0 && (
          <article className="file-preview-container">
            <section className="preview-list">
              {Object.keys(files).map((fileName, index) => {
                const file = files[fileName]
                const isImageFile = file.type.split('/')[0] === 'image'

                return (
                  <section className="preview-container" key={index}>
                    {isImageFile ? (
                      <img className="img-preview" src={URL.createObjectURL(file)} alt={`file preview ${index}`} />
                    ) : (
                      <video width="400">
                        <source src={URL.createObjectURL(file)} id="video_here" />
                        Your browser does not support HTML5 video.
                      </video>
                    )}

                    <div className="delete-img-alignment">
                      <div className="delete-icon-box" onClick={() => removeFile(fileName)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M3 6H5H21"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 11V17"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M14 11V17"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </section>
                )
              })}
            </section>
          </article>
        )}
      </div>
      <div className={styles.mobileViewCreateNewPostBottomDetailsAlignment}>
        <div className={styles.mobileViewBottomOptionAlignment}>
          <div
            className={styles.mobileViewBottomOptionChildDetailsAlignment}
            // onClick={(e) => updateUploadedFiles(e)}
            // onClick={() => setPostImage(!postImage)}
          >
            {/* <div>
                <img src="/assets/seller/icons/image.svg" alt="image" />
              </div>
              <p>Add Photo and Video</p> */}
            <form style={{ width: '100%' }}>
              <FileUpload
                isdrag={true}
                multiple
                // files={files}
                // setFiles={setFiles}
                updateFilesCb={updateUploadedFiles}
                progressPercent={progressPercent}
              />
            </form>
          </div>
          {fetchMobileModal?.lastModalData?.id?.isSeller && (
            <div className={styles.mobileViewBottomOptionChildDetailsAlignment}>
              <div>
                <img src="/assets/seller/icons/tag.svg" alt="tag" />
              </div>
              <p>Attach Product</p>
            </div>
          )}
        </div>
        {postImage ? (
          <div>
            <form className="p-8">
              <FileUpload multiple updateFilesCb={updateUploadedFiles} />
            </form>
          </div>
        ) : (
          <></>
        )}
        <div
          className={styles.mobileViewCreateNewPostShareButtonAlignment}
          // onClick={() => (inputValue.trim() === '' ? '' : handleClick())}
        >
          <button
            className={
              (inputValue.trim() === '' ||
                loading ||
                inputValue.trim() === fetchMobileModal?.lastModalData?.id?.editPost?.content.trim()) &&
              styles.disableButton
            }
            onClick={postHandler}
          >
            Share
          </button>
        </div>
      </div>
    </div>
  )
}
