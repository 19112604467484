import React, { useState } from 'react'
import styles from './RateAndReviewsDetailsModal.module.scss'
import { Button, Dropdown, Menu } from 'antd'
import classNames from 'classnames'

export default function SellerReviewModal() {
  const [isSort, setIsSort] = useState('This Month')

  const changeSortType = (e: { key: string }) => {
    setIsSort(e.key)
  }
  const SortType = (
    <Menu onClick={changeSortType}>
      <Menu.Item key="This Month">This Month</Menu.Item>
      <Menu.Item key="Highest">Highest</Menu.Item>
      <Menu.Item key="Lowest">Lowest</Menu.Item>
    </Menu>
  )

  return (
    <div>
      <div className={styles.rateAndReviewDetailsSection}>
        <div className={styles.rateAndReviewDetailsModalDetailsAlignment}>
          <div className={styles.rateAndReviewDetailsModalHeading}>
            <h5>Rate & Reviews</h5>
          </div>

          <div className={styles.rateAndReviewDetailsModalAlignment}>
            <div className={styles.rateAndReviewDetailsModalGrid}>
              <div className={styles.rateAndReviewDetailsModalGridItem}>
                <div className={styles.rateAndReviewDetailsModalLeftSide}>
                  <div className={styles.rateAndReviewDetailsModalLeftSideDetails}>
                    <h6>4.8</h6>
                    <div className={styles.rateAndReviewDetailsModalAverageRateAlignment}>
                      <div className={styles.rateAndReviewDetailsModalaverageRateStarAlignment}>
                        <div>
                          <img src="/assets/seller/icons/star-filled.svg" alt="star icon" />
                        </div>
                        <div>
                          <img src="/assets/seller/icons/star-filled.svg" alt="star icon" />
                        </div>
                        <div>
                          <img src="/assets/seller/icons/star-filled.svg" alt="star icon" />
                        </div>
                        <div>
                          <img src="/assets/seller/icons/star-filled.svg" alt="star icon" />
                        </div>
                        <div>
                          <img src="/assets/seller/icons/halfRateStar.svg" alt="star icon" />
                        </div>
                      </div>

                      <p>Average Rate</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.rateAndReviewDetailsModalGridItem}>
                <div className={styles.rateAndReviewDetailsModalRightSide}>
                  <div className={styles.rateAndReviewDetailsModalRightSideGrid}>
                    <div className={styles.rateAndReviewDetailsModalRightSideGridItem}>
                      <div className={styles.rateAndReviewDetailsModalRightbox}>
                        <h5>287</h5>
                        <p>Total Review</p>
                      </div>
                    </div>
                    <div className={styles.rateAndReviewDetailsModalRightSideGridItem}>
                      <div className={styles.rateAndReviewDetailsModalRightbox}>
                        <div className={styles.rateAndReviewDetailsModalPersantag}>
                          <div className={styles.rateAndReviewDetailsModalarrowAlignment}>
                            <img src="/assets/seller/icons/arrow-up-right.svg" alt="arrow icon" />
                          </div>
                          <span>0.5%</span>
                        </div>
                        <p>From January</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.rateAndReviewDetailsModalfilterNavAlignment}>
            <div className={styles.rateAndReviewDetailsModalStarFilterAlignment}>
              <div className={classNames(styles.rateAndReviewDetailsModalStarOptionAlignment, styles.activeRate)}>
                <span>All</span>
                <div className={styles.rateAndReviewDetailsModalStarImg}>
                  <img src="/assets/seller/icons/star-filled.svg" alt="star" />
                </div>
                <p>(197)</p>
              </div>
              <div className={styles.rateAndReviewDetailsModalStarOptionAlignment}>
                <span>5</span>
                <div className={styles.rateAndReviewDetailsModalStarImg}>
                  <img src="/assets/seller/icons/star-unfilled.svg" alt="star" />
                </div>
                <p>(197)</p>
              </div>
              <div className={styles.rateAndReviewDetailsModalStarOptionAlignment}>
                <span>4</span>
                <div className={styles.rateAndReviewDetailsModalStarImg}>
                  <img src="/assets/seller/icons/star-unfilled.svg" alt="star" />
                </div>
                <p>(197)</p>
              </div>
              <div className={styles.rateAndReviewDetailsModalStarOptionAlignment}>
                <span>3</span>
                <div className={styles.rateAndReviewDetailsModalStarImg}>
                  <img src="/assets/seller/icons/star-unfilled.svg" alt="star" />
                </div>
                <p>(197)</p>
              </div>
              <div className={styles.rateAndReviewDetailsModalStarOptionAlignment}>
                <span>2</span>
                <div className={styles.rateAndReviewDetailsModalStarImg}>
                  <img src="/assets/seller/icons/star-unfilled.svg" alt="star" />
                </div>
                <p>(197)</p>
              </div>
              <div className={styles.rateAndReviewDetailsModalStarOptionAlignment}>
                <span>1</span>
                <div className={styles.rateAndReviewDetailsModalStarImg}>
                  <img src="/assets/seller/icons/star-unfilled.svg" alt="star" />
                </div>
                <p>(197)</p>
              </div>
            </div>

            <div className={styles.rateAndReviewDetailsModalAlllMediaDetailsAlignment}>
              <div className={styles.rateAndReviewDetailsModalAlllMediaimgFlex}>
                <div className={styles.rateAndReviewDetailsModalAlllMediaMultiImg}>
                  <div className={styles.rateAndReviewDetailsModalAlllMediaimg}>
                    <img src="/assets/seller/img/rr1.png" alt="rate review" />
                  </div>
                  <div className={styles.rateAndReviewDetailsModalAlllMediaimg}>
                    <img src="/assets/seller/img/rr2.png" alt="rate review" />
                  </div>
                  <div className={styles.rateAndReviewDetailsModalAlllMediaimg}>
                    <img src="/assets/seller/img/rr3.png" alt="rate review" />
                  </div>
                </div>
                <p>Media</p>
              </div>
              <span>(54)</span>
            </div>

            <div className={styles.sortDropdownDetailsAlignment}>
              <p>Sort :</p>
              <div>
                <Dropdown overlay={SortType} className=" right-0 flex justify-center h-14">
                  <Button className="filter-button dropdown-gap">
                    {isSort}
                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1L5 5L9 1" stroke="#636773" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </Button>
                </Dropdown>
              </div>
            </div>
          </div>

          <div className={styles.rateAndReviewDetailsTable}>
            <table cellPadding={0} cellSpacing={0}>
              <tbody>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((_, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className={styles.rateReviewProfileAlignment}>
                          <div className={styles.rateReviewImg}>
                            <img src="/assets/seller/img/rrpi1.png" alt="profile img" />
                          </div>
                          <p>Jenny Wilson</p>
                        </div>
                      </td>

                      <td>
                        <div className={styles.iconFlexAlignment}>
                          {[0, 1, 2, 3, 4].map((_, index) => {
                            return <img src="/assets/seller/icons/star-filled.svg" alt="star" key={index} />
                          })}
                        </div>
                      </td>

                      <td>
                        <p>NIKE Pegasus 39</p>
                      </td>

                      <td>
                        <p>“Lorem ipsum dolor sit amet consectetur adipiscing elitr.”</p>
                      </td>
                      <td>
                        <p className={styles.textEnd}>4 hours ago</p>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
