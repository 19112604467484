import React from 'react';
import NotificationFooter from '../NotificationFooter';
import UserNotificationsProfile from '../NoticationIcon';
import { timeAgo } from 'src/utils/helperFuncs';

const PostComment: React.FC<any> = ({ notification, setOpen }: any) => {
    const payload = notification?.payload;
    const imagePayload = {
        name: payload?.userName,
        image: payload?.userImg,
    }
    const postImagePayload = {
        name: payload?.postUser,
        image: payload?.postUserImg,
    }
    return (
        <div className="py-4 px-1 sm:px-3 md:px-6">
            <div className="flex items-start space-x-5 p-4 hover:bg-gray-50">
                <UserNotificationsProfile imagePayload={imagePayload} />
                <div className=" min-w-0">
                    <div className="flex items-center">
                        <div className='flex items-start'>
                            <div className="flex items-start space-x-2 text-sm text-gray-500 ">
                                <span className='text-sm  text-gray-800'><b>{payload?.userName}</b> {payload?.message}</span>
                            </div>
                        </div>
                    </div>
                    <div className='p-4 shadow-md rounded-2xl border-4 border-white my-2 mb-4 bg-gray-100'>
                        <div className='flex items-center space-x-4'>
                            <div className=''>
                                <UserNotificationsProfile imagePayload={postImagePayload} variant='small' />
                            </div>
                            <div>
                                <div className='text-[13px] font-semibold text-gray-800 mt-2'>
                                    {payload?.postUser}
                                </div>
                                <div>
                                    <span className="text-[13px] font-normal text-[#7A7E88]">{timeAgo(new Date((payload?.postTime)).getTime())}</span>
                                </div>
                            </div>
                        </div>
                        <div className='text-[13px] text-gray-800 mt-3'>
                            {payload?.postMessage}
                        </div>
                    </div>
                    <NotificationFooter notification={notification} />
                </div>
            </div>
        </div>
    );
};

export default PostComment;