import React from 'react';
import NotificationFooter from '../NotificationFooter';
import UserNotificationsProfile from '../NoticationIcon';

const NotificationPlaceholder: React.FC<any> = ({ notification }) => {
    const payload = notification?.payload;
    const imagePayload = {
        name: payload?.userName || payload?.senderName || 'User',
        image: payload?.userImg,
    }

    //Temporary to support old notification template
    const getMessage = () => {
        if (notification?.templateIdentifier === 'liked-post-notification') {
            return payload?.message || 'Liked your post'
        } else if (notification?.templateIdentifier === 'message-received-notification') {
            return payload?.message || 'New message received'
        } else if (notification?.templateIdentifier === 'followed-user-notification') {
            return payload?.message || 'Followed you'
        }
        return payload?.message || 'Blue Jestic Notification'
    }
    return (
        <div className="py-4 px-1 sm:px-3 md:px-6">
            <div className="flex items-start space-x-5 p-4 hover:bg-gray-50">
                <UserNotificationsProfile imagePayload={imagePayload} />
                <div className="min-w-0 mt-3">
                    <div className="flex items-center">
                        <div className='flex items-start'>
                            <div className="flex items-start space-x-2 text-sm text-gray-500 ">
                                <span className='text-sm  text-gray-800'> {getMessage()}</span>
                            </div>
                        </div>
                    </div>
                    <NotificationFooter notification={notification} />
                </div>
            </div>
        </div>
    );
};

export default NotificationPlaceholder;