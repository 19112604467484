import { Spin } from 'antd'

export default function Loader() {
  return (
    <div className="flex loader-height-alignment">
      <div className="m-auto">
        <Spin size="large" tip="loading" />
      </div>
    </div>
  )
}
