import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAppDispatch } from 'src/store/store'
import FORGOT_PASSWORD from 'src/graphql/mutations/forgotPassword'
import { notification } from 'antd'
import { useRouter } from 'next/router'

export default function NewForgotPassword() {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const guestDetail = useSelector((state: any) => state?.guestAccountInfo?.data)
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState<any>({})

  const handleBaackLogin = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: { ...guestDetail?.id, media: 'login', modalFor: 'login', startWith: 'loginModal' },
      }),
    )
  }

  const handleMoveVerification = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: { ...guestDetail?.id, media: 'login', modalFor: 'verification', email: email },
      }),
    )
  }

  const validateForm = () => {
    let formIsValid = true
    let errors = {}
    if (
      !email &&
      !email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
    ) {
      formIsValid = false
      errors['email'] = '*Please Enter Valid Email!'
    }

    setErrors(errors)
    return formIsValid
  }

  const [forgotPassword] = useMutation(FORGOT_PASSWORD, {
    onCompleted: (data) => {
      notification['success']({
        message: 'E-Mail sent!',
        description: 'Please Check Email',
      })
      handleMoveVerification()
    },
    onError: (error) => {
      console.log(error)
      notification['error']({
        message: 'Error',
        description: 'Something went wrong, Please try later',
      })
    },
  })

  const handleOnChange = (e: any) => {
    const { name, value } = e.target
    setEmail(value)
    setErrors({ ...errors, [name]: '' })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    if (validateForm()) {
      await forgotPassword({
        variables: {
          email,
        },
      })
    }
  }

  return (
    <div className="new-forgot-password-details-alignment">
      <div className="new-forgot-password-heading">
        <h4>Forgot your Password?</h4>
        <p>We will send a verification code to your email to reset your password</p>
      </div>
      <form className="form-wrapper" onSubmit={handleSubmit}>
        <div className={`new-forgot-input-alignment ${errors.email ? 'danger-input' : ''}`}>
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => handleOnChange(e)}
            placeholder="Email"
            className="is-invalid"
          />
        </div>
        <div className="new-forgot-button-alignment">
          <div className="new-forgot-submit-button">
            <button type="submit">Submit</button>
          </div>
          <div className="new-forgot-cancle-button" onClick={handleBaackLogin}>
            <button>Cancel</button>
          </div>
        </div>
      </form>
    </div>
  )
}
