//@ts-nocheck

import React, { useEffect, useState } from 'react'
import styles from './BannerStreams.module.scss'
import Slider from 'react-slick'
import NoDataSection from 'src/components/noDataSection'
import { SampleNextArrow, SamplePrevArrow } from 'src/components/Arrrows'
import BannerStreamsCard from './BannerStreamsCard'
import moment from 'moment'

export default function BannerStreams({ liveStreams, isHost, loading }: { liveStreams: any, isHost?: boolean, loading?: boolean }) {
  const settings = {
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    infinite: false,

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.075,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.075,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  }

  const streams = liveStreams ? [...liveStreams] : []
  const runningStreams = streams?.sort((a, b) => b?.reaction_count - a?.reaction_count)
    .slice(0, 5);

  return (
    <>
      {runningStreams?.length === 0 || loading ? (
        <div className="m-5 pb-2">
          <NoDataSection
            loading={loading}
            text="There are no running streams available at the moment."
          />
        </div>
      ) :
        <div className={styles.channelsBannerSection}>
          <div className="container mobile-view-container " style={{ maxWidth: '1300px' }}>
            <div className={styles.channelsBannerAlignment}>
              {runningStreams?.length === 0 ? (
                <NoDataSection />
              ) : (<Slider {...settings}>
                {runningStreams?.map((item, i) => {
                  return (
                    <BannerStreamsCard item={item} isHost={isHost} />
                  )
                })}
              </Slider>)
              }
            </div>
          </div>
        </div >}
    </>
  )
}
