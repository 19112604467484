import React from 'react'
import styles from './ProductSizeDropdown.module.scss'

export default function ProductSizeDropdown({ handleSelectSize }) {
  return (
    <div className={styles.imagesSizeDropdownAlignment}>
      {/* <div
        className={styles.imagesSizeDropdownOption}
        onClick={() => handleSelectSize('1 : 1', { width: 1080, height: 1080 })}
      >
        <div className={styles.imagesSizeNameAlignment}>
          <span>1 : 1</span>
        </div>
      </div>
      <div className={styles.imagesSizeDropdownOption}>
        <div className={styles.imagesSizeHeadingAlignment}>
          <p>Portrait</p>
        </div>
        <div
          className={styles.imagesSizeNameAlignment}
          onClick={() => handleSelectSize('9 : 16', { width: 1080, height: 1920 })}
        >
          <span>9 : 16</span>
        </div>
        <div
          className={styles.imagesSizeNameAlignment}
          onClick={() => handleSelectSize('2 : 3', { width: 1080, height: 1620 })}
        >
          <span>2 : 3 </span>
        </div>
        <div
          className={styles.imagesSizeNameAlignment}
          onClick={() => handleSelectSize('4 : 5', { width: 1080, height: 1350 })}
        >
          <span>4 : 5 </span>
        </div>
      </div>
      <div className={styles.imagesSizeDropdownOption}>
        <div className={styles.imagesSizeHeadingAlignment}>
          <p>Landscape</p>
        </div>
        <div
          className={styles.imagesSizeNameAlignment}
          onClick={() => handleSelectSize('3 : 2', { width: 1620, height: 1080 })}
        >
          <span>3 : 2</span>
        </div>
        <div
          className={styles.imagesSizeNameAlignment}
          onClick={() => handleSelectSize('5 : 4', { width: 1350, height: 1080 })}
        >
          <span>5 : 4</span>
        </div>
        <div
          className={styles.imagesSizeNameAlignment}
          onClick={() => handleSelectSize('16 : 9', { width: 1920, height: 1080 })}
        >
          <span>16 : 9</span>
        </div>
      </div> */}
      <div className={styles.imagesSizeDropdownOption}>
        {/* <div className={styles.imagesSizeHeadingAlignment}>
          <p>Portrait</p>
        </div> */}
        <div
          className={styles.imagesSizeNameAlignment}
          onClick={() => handleSelectSize('1 : 1', { width: 1080, height: 1080 })}
        >
          <span>1 : 1</span>
        </div>
        <div
          className={styles.imagesSizeNameAlignment}
          onClick={() => handleSelectSize('2 : 3', { width: 1080, height: 1620 })}
        >
          <span>2 : 3 </span>
        </div>
        <div
          className={styles.imagesSizeNameAlignment}
          onClick={() => handleSelectSize('4 : 5', { width: 1080, height: 1350 })}
        >
          <span>4 : 5 </span>
        </div>
      </div>
    </div>
  )
}
