import React, { useEffect, useRef, useState } from 'react'
import styles from './AddSizeModal.module.scss'
import classNames from 'classnames'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'

export default function AddSizeModal() {
  const categoryRef: any = useRef()
  const [colorArray, setColorArray] = useState([''])
  const dispatch = useAppDispatch()
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const sizeArraySelected = [
    { size: 'S', id: 0 },
    { size: 'M', id: 1 },
    { size: 'L', id: 2 },
    { size: 'XL', id: 3 },
    { size: '2XL', id: 4 },
    { size: '3XL', id: 3 },
  ]
  const [inputIndex, setInputIndex] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [sizeArray, setSizeArray] = useState(sizeArraySelected)
  const [optionSearchResult, setOptionSearchResult] = useState([])
  const [error, setError] = useState([])

  const handleChange = (e, id) => {
    let errors = {}
    setInputValue(e.target.value)
    setInputIndex(id)
    let changed = colorArray.map((color, index) => {
      if (index === id) {
        return e.target.value
      } else {
        return color
      }
    })
    let errorchecking = changed?.map((data, index) => {
      if (data.trim() === '') {
        return (errors[index] = 'Enter valid input*')
      }
    })
    setError(errorchecking)
    setColorArray(changed)
  }

  const handleSelect = async (id, data) => {
    let ID = id
    if (data !== '') {
      let selectedSetting = colorArray.map((col, index) => {
        if (index === ID) {
          return data
        } else {
          return col
        }
      })
      removeDuplicates([...selectedSetting])
      setInputIndex(null)
      setInputValue('')
    }
  }

  const removeDuplicates = (arr) => {
    let errors = {}
    var unique = arr.reduce(function (acc, curr) {
      let lowerCaseArray = acc.map((data) => {
        return data.toLowerCase()
      })
      if (!lowerCaseArray.includes(curr.toLowerCase())) acc.push(curr)
      return acc
    }, [])

    const noEmptyStrings = unique.filter((str) => str !== '')
    let errorchecking = noEmptyStrings.map((data, index) => {
      if (data.trim() === '') {
        return (errors[index] = 'Enter valid input*')
      }
    })
    setError(errorchecking)
    setColorArray([...noEmptyStrings, ''])
  }

  const handleEnter = async (e, index) => {
    let errors = {}
    if (e.key === 'Enter') {
      if (colorArray.includes(inputValue)) {
        removeDuplicates(colorArray)
        setInputIndex(null)
        setInputValue('')
        let errorchecking = colorArray.map((data, index) => {
          if (data.trim() === '') {
            return (errors[index] = 'Enter valid input*')
          }
        })
        setError(errorchecking)
      }
    }
  }

  const validateSize = () => {
    let errors = {}
    let formIsValid = true
    let errorchecking = colorArray.map((data, index) => {
      if (data.trim() === '') {
        formIsValid = false
        return (errors[index] = 'Enter valid input*')
      }
    })
    setError(errorchecking)
    return formIsValid
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (inputIndex && categoryRef.current && !categoryRef.current.contains(e.target)) {
        setInputIndex(null)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [inputIndex])

  useEffect(() => {
    if (inputValue === '') {
      setSizeArray(sizeArraySelected)
      setOptionSearchResult(sizeArraySelected)
    } else {
      let datat = sizeArray?.filter((rep) => rep?.size?.toLowerCase()?.includes(inputValue?.toLowerCase()))
      setOptionSearchResult(datat)
    }
  }, [inputValue])

  const handleClickInput = (index) => {
    setInputIndex(index)
  }

  const outputArray = []

  function generateVariants(currentVariant, currentIndex, finalData) {
    if (currentIndex === finalData?.length) {
      outputArray.push({
        total_variant: currentVariant,
        // weightValue: '200',
        weightUnit: 'pound',
        unit: 'inch',
        // length: 2,
        // width: 2,
        // height: 2,
        // inventory_quantity: 100,
        // old_inventory_quantity: 200,
        isTaxable: false,
        isChecked: false,
        old_inventory_quantity: 0,
        inventory_quantity: 0,
        price: '0',
        sku: '0',
        // grams: 530,
        // price: '50',
        // sku: 'dadsdadasdasd',
        // barcode: '5151511565656',
      })
      return
    }

    const currentVariants = finalData[currentIndex].data
    if (Boolean(currentVariants?.length)) {
      for (const variant of currentVariants) {
        const newVariant = [...currentVariant, variant]
        generateVariants(newVariant, currentIndex + 1, finalData)
      }
    }
  }

  const handleSubmitSize = async () => {
    if (validateSize()) {
      if (addProductDetailSlice?.id?.variant?.dataEdit?.size) {
        // submitEditedSize({ name: 'SIZE', variant: colorArray, index: dataEdit?.index }, 'size')
        // setAddSizeModalOpen(false)
        // setDataEdit({})

        let data = { name: 'SIZE', variant: colorArray, index: addProductDetailSlice?.id?.variant?.dataEdit?.index }

        let sizeVariant = addProductDetailSlice?.id?.variant?.allVariant?.map((add, index) => {
          if (index === data?.index) {
            return { name: data?.name, data: data?.variant }
          } else {
            return add
          }
        })
        let colorVariant = addProductDetailSlice?.id?.variant?.allVariant?.filter((size) => size?.name === 'Color')
        let otherVariant = addProductDetailSlice?.id?.variant?.allVariant?.filter(
          (size) => size?.name !== 'SIZE' && size?.name !== 'Color',
        )

        const updatedVariant = {
          other: [
            { name: 'size', data: sizeVariant[0]?.data },
            { name: 'color', data: colorVariant[0]?.data.map((colorObj) => colorObj.name) },
          ],
        }

        const sizeAndColorArray = [
          { name: 'size', data: updatedVariant.other[0].data },
          { name: 'color', data: updatedVariant.other[1].data },
          ...otherVariant,
        ]

        const finalVariant = sizeAndColorArray.concat(updatedVariant.other.slice(2))
        await generateVariants([], 0, finalVariant)

        dispatch(
          manageAddProductDetail({
            modal: '',
            isOpen: false,
            id: {
              ...addProductDetailSlice?.id,
              variant: {
                ...addProductDetailSlice?.id?.variant,
                allVariant: addProductDetailSlice?.id?.variant?.allVariant?.map((add, index) => {
                  if (index === data?.index) {
                    return { name: data?.name, data: data?.variant }
                  } else {
                    return add
                  }
                }),
                colorCodeArray: addProductDetailSlice?.id?.variant?.allVariant?.map((add, index) => {
                  if (index === data?.index) {
                    return { name: data?.name, data: data?.variant }
                  } else {
                    return add
                  }
                }),
                allFinalVariant: addProductDetailSlice?.id?.variant?.allVariant?.map((add, index) => {
                  if (index === data?.index) {
                    return { name: data?.name, data: data?.variant }
                  } else {
                    return add
                  }
                }),
                allOptions: outputArray,
                isAddSizeModalOpen: false,
                dataEdit: {},
              },
            },
          }),
        )
      } else {
        // submitSize({ name: 'SIZE', variant: colorArray }, 'size')
        // setAddSizeModalOpen(false)
        // setDataEdit({})
        let data = { name: 'SIZE', variant: colorArray }
        dispatch(
          manageAddProductDetail({
            modal: '',
            isOpen: false,
            id: {
              ...addProductDetailSlice?.id,
              variant: {
                ...addProductDetailSlice?.id?.variant,
                allVariant: [
                  ...addProductDetailSlice?.id?.variant?.allVariant,
                  { name: data.name, data: data.variant },
                ],
                colorCodeArray: [
                  ...addProductDetailSlice?.id?.variant?.allVariant,
                  { name: data.name, data: data.variant },
                ],
                isAddSizeModalOpen: false,
                dataEdit: {},
              },
            },
          }),
        )
      }
      // if (addProductDetailSlice?.id?.variant?.variantError?.size) {
      //   let variantErrorDetail = { ...addProductDetailSlice?.id?.variant?.variantError }
      //   delete variantErrorDetail.size
      //   dispatch(
      //     manageAddProductDetail({
      //       modal: '',
      //       isOpen: false,
      //       id: {
      //         ...addProductDetailSlice?.id,
      //         variant: {
      //           ...addProductDetailSlice?.id?.variant,
      //           variantError: variantErrorDetail,
      //         },
      //       },
      //     }),
      //   )
      // }
    }
  }

  const handleDelete = (index) => {
    if (colorArray?.length > 1) {
      setColorArray(colorArray.filter((data, ind) => ind !== index))
    }
  }

  useEffect(() => {
    if (addProductDetailSlice?.id?.variant?.dataEdit?.size) {
      setColorArray(addProductDetailSlice?.id?.variant?.dataEdit?.size?.data)
    }
  }, [addProductDetailSlice?.id?.variant?.dataEdit])

  const handleCancel = () => {
    dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          variant: {
            ...addProductDetailSlice?.id?.variant,
            isAddSizeModalOpen: false,
            dataEdit: {},
          },
        },
      }),
    )
  }

  return (
    <div>
      <div className={styles.addSizeVariantModalAlignment}>
        <div className={styles.modalWrapper}>
          <div className={styles.modalBoxAlignment}>
            <div className={styles.modalHeaderAlignment}>
              <h3>Add Size Variant</h3>
              <div className={styles.headerButtonAlignment}>
                <div className={styles.cancleButtonAlignment}>
                  <button onClick={() => handleCancel()}>Cancel</button>
                </div>

                <div className={classNames(styles.saveButtonAlignment, styles.active)} onClick={handleSubmitSize}>
                  <button>Save and Close</button>
                </div>
              </div>
            </div>
            <div className={styles.modalBodyAlignment}>
              <div className={styles.formAlignment}>
                <label>Option Value</label>
                <div className={styles.inputDetailsAlignment}>
                  {colorArray?.map((data, index) => {
                    return (
                      <>
                        <span style={{ color: 'red' }}>{error[index]}</span>
                        <div className={styles.inputAlignment}>
                          <div className={styles.inputRelative}>
                            <input
                              type="text"
                              placeholder="Add New Size"
                              onChange={(e) => handleChange(e, index)}
                              value={data}
                              onKeyPress={(e) => handleEnter(e, index)}
                              onClick={() => handleClickInput(index)}
                              autoFocus
                            />
                            <div className={styles.deleteIconAlignment} onClick={() => handleDelete(index)}>
                              <img src="/assets/seller/icons/transh-blue.svg" alt="Transh" />
                            </div>
                          </div>
                          {/* <span onClick={() => handleDelete(index)}>Delete</span> */}
                          {inputIndex == index && optionSearchResult?.length > 0 && (
                            <div className={styles.dropdownAlignment} ref={categoryRef}>
                              <ul>
                                {(inputValue ? optionSearchResult : sizeArray)?.map((val, i) => {
                                  return (
                                    <li onClick={() => handleSelect(index, val?.size)} key={i}>
                                      {val?.size}
                                    </li>
                                  )
                                })}
                              </ul>
                            </div>
                          )}
                        </div>
                      </>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
