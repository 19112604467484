import { gql } from '@apollo/client'

const UPDATE_SELLER_POST_MUTATION = gql`
  mutation updateSellerPost(
    $id: Int!
    $title: String!
    $content: String!
    $isActive: Boolean
    $image: [Int]
    #  $user_id: Int
    $store_id: Int
    $isSchedule: Boolean
    #  $content: String!
    $scheduleTime: String
    $isDraft: Boolean
  ) {
    updateSellerPost(
      input: {
        id: $id
        store_id: $store_id
        content: $content
        title: $title
        # content: $content
        image: $image
        isActive: $isActive
        isDraft: $isDraft
        isSchedule: $isSchedule
        scheduleTime: $scheduleTime
        # user_id: $user_id
      }
    ) {
      id
    }
  }
`
export default UPDATE_SELLER_POST_MUTATION
