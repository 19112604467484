import React from 'react'
import styles from './BrowseByTypeProduct.module.scss'
export default function BrowseByTypeProduct() {
  return (
    <div className={styles.browseByTypeBodyGridBox}>
      <div className={styles.childGridAlignment}>
        {[0, 1, 2, 3].map((_, index) => {
          return (
            <div className={styles.imgAlignment} key={index}>
              <img src="/assets/img/bty-img.png" alt="category img" />
            </div>
          )
        })}
      </div>

      <div className={styles.browseByTypeProfile}>
        <div className={styles.browseByTypeProfileLeft}>
          <div className={styles.browseByTypeProfileImg}>
            <img src="/assets/img/bty-img.png" alt="profile img" />
          </div>
          <h6>Hats</h6>
        </div>

        <div className={styles.browseByTypeProfileRight}>
          <span>1000+ items</span>
        </div>
      </div>
    </div>
  )
}
