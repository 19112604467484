// @ts-nocheck
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import styles from './MobileViewBookMarkModal.module.scss'
import BookmarkAddNewModal from '../BookmarkModal/BookmarkAddNewModal'
import { useSelector } from 'react-redux'
import ADD_BOOKMARK from 'src/graphql/mutations/addBookmark'
import { useMutation, useQuery } from '@apollo/client'
import GET_ALL_BOOKMARK_COLLECTIONS from 'src/graphql/queries/getBookmarkCollections'
import { fetchAllFeedSliceData } from 'src/store/slices/fetchAllFeedSlice'
import REMOVE_BOOKMARK from 'src/graphql/mutations/removeBookmark'
import { useAppDispatch } from 'src/store/store'
import { manageBookmark } from 'src/store/slices/manageBookmarkTab'
import GET_STORE_FOLLOW from 'src/graphql/queries/getStoreFollow'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'

interface Post {
  id: string | number
  product?: {
    id: string | number
  }
  [key: string]: any
}

interface Collection {
  id: string | number
  name: string
  isPrivate?: boolean
  [key: string]: any
}

interface BookmarkCollection extends Collection {
  product_images?: string[]
}

interface RootState {
  manageBookmarkTabsSlice: {
    data: {
      id?: {
        type?: string
        collection_id?: string | number
        bookmark_id?: string | number
        post?: Post
        product_id?: string | number
        store_id?: string | number
        deleteCollection?: any
      }
    }
  }
}

interface BookmarkSliderProps {
  productId: string | number
}

interface FeedSuccessPayload {
  data: Post[]
  isEnd: boolean
}

function isFeedSuccessPayload(payload: unknown): payload is FeedSuccessPayload {
  return (
    typeof payload === 'object' &&
    payload !== null &&
    'data' in payload &&
    Array.isArray((payload as any).data) &&
    'isEnd' in payload &&
    typeof (payload as any).isEnd === 'boolean'
  )
}

type FeedPayloadAction =
  | PayloadAction<FeedSuccessPayload, string, { arg: any; requestId: string; requestStatus: 'fulfilled' }, never>
  | PayloadAction<
      unknown,
      string,
      { arg: any; requestId: string; requestStatus: 'rejected'; aborted: boolean; condition: boolean } & (
        | { rejectedWithValue: true }
        | { rejectedWithValue: false }
      ),
      SerializedError
    >

function SampleNextArrow(props: { className?: string; onClick?: () => void }) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
        <circle opacity="0.6" cx="15.5" cy="15.5" r="15.5" fill="white" />
        <path
          d="M13 10L19 16L13 22"
          stroke="#1E2432"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  )
}

function SamplePrevArrow(props: { className?: string; onClick?: () => void }) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
        <circle opacity="0.6" cx="15.5" cy="15.5" r="15.5" fill="white" />
        <path
          d="M13 10L19 16L13 22"
          stroke="#1E2432"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  )
}

const BookmarkSlider = ({ productId }: BookmarkSliderProps) => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_BOOKMARK_COLLECTIONS, {
    variables: {
      collectionId: null,
    },
  })
  const bookmarkTabData = useSelector((state: RootState) => state?.manageBookmarkTabsSlice?.data)

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4.2,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  }

  const [addCollectionWebModal, setAddCollectionWebModal] = useState(false)
  const [selectedCollection, setSelectedCollection] = useState<string | number | null>(null)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const dispatch = useAppDispatch()
  const [productRecommendationsFetched, setProductRecommendationsFetched] = useState(false)

  const [addBookmark] = useMutation(ADD_BOOKMARK, {
    onCompleted: (res) => {
      if (bookmarkTabData?.id?.type === 'feed') {
        dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] })).then((action: FeedPayloadAction) => {
          if (action.meta.requestStatus === 'fulfilled' && isFeedSuccessPayload(action.payload)) {
            const find_post = action.payload.data.find((p: Post) => p.id === bookmarkTabData?.id?.post?.id)
            if (find_post && find_post.product) {
              dispatch(
                manageBookmark({
                  modal: '',
                  isOpen: true,
                  id: {
                    ...bookmarkTabData?.id,
                    post: find_post,
                    addProductToCollection: {
                      ...bookmarkTabData?.id?.deleteCollection,
                      isShowAddCollectionModel: true,
                      productId: find_post?.product?.id,
                    },
                  },
                }),
              )
            }
          }
        })
      }
    },
    onError: (error) => {
      console.log(error)
    },
  })

  useEffect(() => {
    if (bookmarkTabData?.id?.collection_id) {
      setSelectedCollection(bookmarkTabData?.id?.collection_id)
    }
  }, [bookmarkTabData])

  const [removeBookmark] = useMutation(REMOVE_BOOKMARK, {
    onCompleted: (res) => {
      setSelectedCollection(null)
      if (bookmarkTabData?.id?.type === 'feed') {
        dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] })).then((action: FeedPayloadAction) => {
          if (action.meta.requestStatus === 'fulfilled' && isFeedSuccessPayload(action.payload)) {
            const find_post = action.payload.data.find((p: Post) => p.id === bookmarkTabData?.id?.post?.id)
            if (find_post && find_post.product) {
              dispatch(
                manageBookmark({
                  modal: '',
                  isOpen: true,
                  id: {
                    ...bookmarkTabData?.id,
                    post: find_post,
                    addProductToCollection: {
                      ...bookmarkTabData?.id?.deleteCollection,
                      isShowAddCollectionModel: true,
                      productId: find_post?.product?.id,
                    },
                  },
                }),
              )
            }
          }
        })
      }
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const handleAddBookmark = (item: BookmarkCollection) => {
    setSelectedCollection(item.id)
    if (bookmarkTabData?.id?.collection_id === item.id) {
      setSelectedCollection(null)
      removeBookmark({
        variables: {
          id: bookmarkTabData?.id?.bookmark_id,
        },
        refetchQueries: [
          {
            query: GET_ALL_BOOKMARK_COLLECTIONS,
            variables: {
              collectionId: null,
            },
          },
          ...(bookmarkTabData?.id?.type === 'product'
            ? [
                {
                  query: GET_STORE_FOLLOW,
                  variables: {
                    storeId: bookmarkTabData?.id?.store_id,
                    product_Id: bookmarkTabData?.id?.product_id,
                  },
                },
              ]
            : []),
        ],
      })
      return
    } else {
      addBookmark({
        variables: {
          productId: bookmarkTabData?.id?.product_id,
          collectionId: item.id,
        },
        refetchQueries: [
          {
            query: GET_ALL_BOOKMARK_COLLECTIONS,
            variables: {
              collectionId: null,
            },
          },
          ...(bookmarkTabData?.id?.type === 'product'
            ? [
                {
                  query: GET_STORE_FOLLOW,
                  variables: {
                    storeId: bookmarkTabData?.id?.store_id,
                    product_Id: bookmarkTabData?.id?.product_id,
                  },
                },
              ]
            : []),
        ],
      })
    }
  }

  return (
    <div>
      <div className={styles.mobileViewBookMarkDetailsFlex}>
        <div className={styles.mobileAddNewDetails} onClick={() => setAddCollectionWebModal(true)}>
          <div className={styles.mobileAddnewBoxAlignment}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12 5V19" stroke="#556EE6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M5 12H19" stroke="#556EE6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <p>Add New</p>
        </div>
        <div className={styles.BookmarkSlider}>
          <Slider {...settings}>
            {data?.getBookmarkCollections?.map((item: BookmarkCollection, index: number) => (
              <div>
                <div key={index} className={styles.mobileViewAddedImges}>
                  <div className={styles.addedImgAlignment} onClick={() => handleAddBookmark(item)}>
                    <img
                      src={item.product_images?.[0] ? item.product_images?.[0] : '/assets/img/c2.png'}
                      alt="book-mark imf"
                    />
                    {selectedCollection === item.id && (
                      <div className={styles.bookedRightSign}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11" fill="none">
                          <path
                            d="M14 1L5.0625 10L1 5.90909"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                  <p>{item.name}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      {addCollectionWebModal && (
        <BookmarkAddNewModal
          setAddCollectionWebModal={setAddCollectionWebModal}
          isEditCollection={false}
          name=""
          collectionId={null}
        />
      )}
    </div>
  )
}

export default BookmarkSlider
