import React from 'react'
import styles from './SellerCustomers.module.scss'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import GET_ALL_ORDERS_DATA_WITH_CUSTOMER_DETAILS from 'src/graphql/queries/sellerQueries/getAllOrdersDataWithCustomerDetails'
import { useQuery } from '@apollo/client'
import { useAuth } from 'src/utils/auth'

export default function SellerCustomers() {
  const { user }: any = useAuth()
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const { loading, error, data, refetch } = useQuery(GET_ALL_ORDERS_DATA_WITH_CUSTOMER_DETAILS, {
    variables: {
      store_id: user?.store?.id,
    },
  })

  const handleOpenStoreModal = () => {
    dispatch(
      fetchModalData({
        allModalData: [
          ...getAllState?.allModalData,
          { modal: 'sellerCustomerModal', isOpen: true, id: 0, modalFor: 'sellerCustomerViewModal' },
        ],
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  return (
    <div className={styles.sellerCustomersSection}>
      <div className={styles.sellerCustomersAlignment}>
        <div className={styles.sellerCustomersBox}>
          <div className={styles.sellerCustomersBoxHeding}>
            <div className={styles.sellerCustomersBoxHedingFlex}>
              <h5>Customers</h5>
              <div className={styles.customersTotalBoxAlignment}>
                <span>{data?.getAllOrdersDataWithCustomerDetails?.data?.length?.toLocaleString()}</span>
              </div>
            </div>
            <div className={styles.rightArrowAlignment} onClick={() => handleOpenStoreModal()}>
              <img src="/assets/seller/icons/right-green-arrow.svg" alt="right side" />
            </div>
          </div>
          <div className={styles.sellerCustomersBodyAlignment}>
            {data?.getAllOrdersDataWithCustomerDetails?.data?.map((c, i) => {
              return (
                <div className={styles.sellerCustomersDetailsAlignment} key={i}>
                  <div className={styles.sellerCustomersDetailsProfileFlex}>
                    <div className={styles.sellerCustomersProfileImg}>
                      <img src={c?.customer?.logo_image} alt="folllow profile" />
                    </div>
                    <h6>{c?.customer?.firstName + ' ' + c?.customer?.lastName}</h6>
                  </div>
                  {/* <div className={styles.customerMessageDetailsFlex}>
                    <div className={styles.customerTotalNumberAlignment}>
                      <span>3 </span>
                    </div>
                    <div className={styles.sellerCustomersMessageBox}>
                      <img src="/assets/seller/icons/message-circle.svg" alt="message icon" />
                    </div>
                  </div> */}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
