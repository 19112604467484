import React, { useEffect, useState } from 'react'
import ProductDetailModal from './ProductDetailModal'
import StoreProductModal from './StoreProductModal'
import UserProfileModal from '../UserProfile/UserProfileModal'
import GroupModal from '../Groups/GroupModal'
import { useSelector } from 'react-redux'
import ModalSlide from './ModalSlide'
import BookSpecialOfferModal from '../Books/BookSectionMain/BookSpecialOffer/BookSpecialOfferModal'
import ShoppingCart from '../Cart/ShoppingCart'
import BookNewReleaseModal from '../Books/BookSectionMain/BookInformationMain/BookNewRelease/BookNewReleaseModal'
import BookBestSellerModal from '../Books/BookSectionMain/BookInformationMain/BookBestSeller/BookBestSellerModal'
import BookOfTheMonthModal from '../Books/BookSectionMain/BookInformationMain/BookOfTheMonth/BookOfTheMonthModal'
import BookAuthorModal from '../Books/BookSectionMain/BookInformationMain/BookAuthor/BookAuthorModal'
import AuthorModal from '../Books/BookSectionMain/AuthorModal'
import BookClubModal from '../Books/BookSectionMain/BookInformationMain/BookClubs/BookClubModal'
import BookDetailModal from '../Books/BookSectionMain/BookDetailModal'
import BookApplianceTredingStoreModal from '../BookAppliance/BookApplianceModal/BookApplianceTredingStoreModal'
import BookApplianceIdeaModal from '../BookAppliance/BookApplianceModal/BookApplianceIdeaModal'
import HomeApplianceClubsModal from '../BookAppliance/BookApplianceModal/HomeApplianceClubsModal'
import InspirationalInfluencerModal from '../BookAppliance/BookApplianceModal/InspirationalInfluencerModal'
import BookApplianceNowTrendingModal from '../BookAppliance/BookApplianceModal/BookApplianceNowTrendingModal'
import PostAddEditModal from '../Feed/PostSomething/PostAddEditModal'
import MobileViewFilterModal from '../ProductPage/AllProduct/MobileViewFilterModal'
import ProductFilter from '../ProductPage/AllProduct/ProductFilter'
import NowTrendingArtCarft from '../ArtCraftMain/ArtCraftModal/NowTrendingArtCarft'
import TrendingStoreArtCarft from '../ArtCraftMain/ArtCraftModal/TrendingStoreArtCarft'
import DIYRoomArtCarft from '../ArtCraftMain/ArtCraftModal/DIYRoomArtCarft'
import MeetOurArtistsArtCarft from '../ArtCraftMain/ArtCraftModal/MeetOurArtistsArtCarft'
import LeranFromArtistsArtCarft from '../ArtCraftMain/ArtCraftModal/LeranFromArtistsArtCarft'
import CreativeClubsArtCarft from '../ArtCraftMain/ArtCraftModal/CreativeClubsArtCarft'
import GroupMobileViewModal from '../Groups/GroupModal/GroupMobileViewModal'
import FollowerFollowingModal from '../UserProfile/UserProfileModal/FollowerFollowingModal'
import MobileviewMoreaboutmodal from '../UserProfile/UserProfileModal/UserAbout/MobileviewMoreaboutmodal'
import DiyRoomDetailsModal from '../ArtCraftMain/ArtCraftModal/DIYRoomArtCarft/DiyRoomDetailsModal'
import DIYRoomModal from '../ArtCraftMain/ArtCraftModal/DIYRoomArtCarft/DIYRoomModal'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import MessageModal from '../MessageModal'
import MobileViewProductCategory from '../ProductPage/AllProduct/MobileViewProductCategory'
import ShareOnMyFeed from '../ProductPage/AllProduct/MobileViewProductCategory/ShareOnMyFeed'
import SharewithFriend from '../ProductPage/AllProduct/MobileViewProductCategory/SharewithFriend'
import AddProductModal from '../Seller/SellerProducts/AddProductModal'
import StockReportModal from '../Seller/Dashboard/SellerInventory/StockReport/StockReportModal'
import SellerReviewModal from '../Seller/Dashboard/SellerRateReviews/SellerLatestReview/SellerReviewModal'
import SellerCustomersDetailsModal from '../Seller/SellerRightSidebar/SellerCustomersDetailsModal'
import SellerFollowersDetails from '../Seller/SellerRightSidebar/SellerFollowersDetails'
import Notification from '../Notification'
import SellerModalSlice from './SellerModalSlice'
import NotificationSizeModal from './NotificationSizeModal'
import SellerCustomerModal from '../Seller/SellerRightSidebar/SellerCustomerModal'
import CustomerDetailModal from '../Seller/SellerRightSidebar/SellerCustomerModal/CustomerDetailModal'
import ProductAdjustImageModal from '../Seller/SellerProducts/ProductAdjustImageModal'
import CustomerOrderDetailsModal from '../Seller/SellerRightSidebar/SellerCustomerModal/CustomerOrderDetailsModal'
import SellerCustomerViewModal from './SellerCustomerModal'
import MobileViewSellerReviewModal from '../Seller/Dashboard/SellerRateReviews/SellerLatestReview/MobileViewSellerReviewModal'
import MobileViewStockReportModal from '../Seller/Dashboard/SellerInventory/StockReport/MobileViewStockReportModal'
import ClubModal from './ClubModal'
import { useMutation } from '@apollo/client'
import useItems from 'antd/lib/menu/hooks/useItems'
import ADD_STORE_ACTIVITY from 'src/graphql/mutations/sellerMutation/addStoreActivity'
import moment from 'moment'
import { useAuth } from 'src/utils/auth'
import { useAppDispatch } from 'src/store/store'
import { useRouter } from 'next/router'
import SearchModal from './SearchModal'
import BookmarkModal from '../BookmarkModal'
import StoresModal from './StoresModal'
import PeopleModal from './PeopleModal'
import SmallSizeModal from './SmallSizeModal'
import SeeAllStore from './StoresModal/SeeAllStore'
import OnlyTodayModal from './ShopModal/NewArrival'
import WhatsHotModal from './ShopModal/WhatsHotModal'
import NewArrivalModal from './ShopModal/NewArrivalModal'
import RisingStoresModal from './ShopModal/RisingStoresModal'
import ADD_PRODUCT_VIEW from 'src/graphql/mutations/sellerMutation/addProductView'
import ShareProductWithFriendsModal from './ShareProductWithFriendsModal'
import ShareOnMyGroupModal from './ShareOnMyGroupModal'
import ShareOnMyFeedModal from './ShareOnMyFeedModal'
import FeedMobileViewCreateNewPostModal from '../Feed/FeedMobileViewCreateNewPostModal'
import MobileViewAddVariantModal from '../Seller/SellerProducts/AddProductModal/MobileViewAddVariantModal'
import MobileAddSizeVariant from '../Seller/SellerProducts/AddProductModal/MobileViewAddVariantModal/MobileAddSizeVariant'
import MobileAddCategory from '../Seller/SellerProducts/AddProductModal/MobileViewAddVariantModal/MobileAddColorVariant'
import MobileAddColorVariant from '../Seller/SellerProducts/AddProductModal/MobileViewAddVariantModal/MobileAddColorVariant'
import MobileViewSelectCategoryModal from '../Seller/SellerProducts/AddProductModal/AddProductModalCategory/MobileViewSelectCategoryModal'
import MobileSelectImageRatioModal from '../Seller/SellerProducts/AddProductModal/AddProductModalImages/MobileSelectImageRatioModal'
import MobileViewAdjustImageModal from '../Seller/SellerProducts/AddProductModal/AddProductModalImages/MobileSelectImageRatioModal/MobileViewAdjustImageModal'
import MobileProductDescriptionModal from '../Seller/SellerProducts/AddProductModal/AddProductModalDescription/MobileProductDescriptionModal'
import MobilePicUpModal from '../Seller/SellerProducts/AddProductModal/MobileViewAddVariantModal/MobileAddColorVariant/MobilePicUpModal'
import JewelryHotDealModal from './JewelryModal/JewelryHotDealModal'
import JewelryLiveChannelsModal from './JewelryModal/JewelryLiveChannelsModal'
import JewelryNewArrivalModal from './JewelryModal/JewelryNewArrivalModal'
import JewelryStoreModal from './JewelryModal/JewelryStoreModal'
import MobileJewelryCategoryModal from './JewelryModal/JewelryMobileModals/MobileJewelryCategoryModal'
import MobileJewelryHotDetailsModal from './JewelryModal/JewelryMobileModals/MobileJewelryHotDetailsModal'
import MobileJewelryLiveChannel from './JewelryModal/JewelryMobileModals/MobileJewelryLiveChannel'
import MobileJewelryNewArrival from './JewelryModal/JewelryMobileModals/MobileJewelryNewArrival'
import MobileJewelryClubModal from './JewelryModal/JewelryMobileModals/MobileJewelryClubModal'
import MobileJewelryFansModal from './JewelryModal/JewelryMobileModals/MobileJewelryFansModal'
import JewelryClubModal from './JewelryModal/JewelryClubModal'
import JewelryFansModal from './JewelryModal/JewelryFansModal'
import MobileJewelryStoreModal from './JewelryModal/JewelryMobileModals/MobileJewelryStoreModal'
import SellerWithdrawFundModal from './SellerWithdrawFundModal'
import WithdrawEnterAmountModal from '../Seller/SellerWithdrawModals/WithdrawEnterAmountModal'
import WithdrawDetailModal from '../Seller/SellerWithdrawModals/WithdrawDetailModal'
import WithdrawOTPverificationModal from '../Seller/SellerWithdrawModals/WithdrawOTPverificationModal'
import WithdrawRequestProcessedModal from '../Seller/SellerWithdrawModals/WithdrawRequestProcessedModal'
import WithdrawTransactionDetailModal from '../Seller/SellerWithdrawModals/WithdrawTransactionDetailModal'
import LoginModal from './LoginModal'
import RegisterModal from './RegisterModal'
import ForgotPasswordModal from './ForgotPasswordModal.tsx'
import ClubCreationMain from './ClubCreationMain'
import CreateClubModal from './ClubCreationMain/CreateClubModal'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Checkout from '../Checkout'
import CollectionByProductModal from '../BookmarkModal/CollectionByProductModal'
import AddMessageModal from '../MessageModal/AddMessageModal'
import MobileViewPersonalAccount from '../MessageModal/MobileViewPersonalAccount'
import MessageChatBox from '../MessageModal/MessageChatBox'
import MobileMessageSelected from '../MessageModal/MobileMessageSelected'
import MobileViewSecondModal from './MobileModal/MobileViewSecondModal'
import MobileViewMessageDelete from '../MessageModal/MobileViewMessageDelete'
import MobileViewForwardMessageModal from '../MessageModal/MobileViewForwardMessageModal'
import MobileMessageChatOptionModal from '../MessageModal/MobileMessageChatOptionModal'
import MessageMedia from '../MessageModal/MessageMedia'
import MessageMediaPreview from '../MessageModal/MessageMediaPreview'
import StoreMobileViewModal from './StoresModal/StoreMobileViewModal'
import PopularStoreMobileViewModal from './StoresModal/PopularStoreMobileViewModal'
import VariantDisplayModel from './VariantDisplayModel'
import ProductVariant from './StoresModal/ProductsVariantModel'
import ProductModalParent from './ProductModalParent'
import MobileFilterModal from '../Seller/SellerProducts/AddProductModal/MobileViewAddVariantModal/MobileFilterModal'
import MobileProductDetailsShareButton from './ProductDetailModal/MobileProductDetailsShareModal'
import MobileProductDetailsShareModal from './ProductDetailModal/MobileProductDetailsShareModal'
import MobileViewBookMarkModal from './ProductDetailModal/MobileViewBookMarkModal'
import MobileViewProductAllVarintModal from './ProductDetailModal/MobileViewProductAllVarintModal'
import SharewithClubs from '../ProductPage/AllProduct/MobileViewProductCategory/SharewithClubs'
import FeedShareOnMyClub from '../ProductPage/AllProduct/MobileViewProductCategory/FeedShareOnMyClub'
// import LiveStreamModal from './LiveStreamModel/StreamMainComponent'
import LiveStreamMain from './LiveStreamModel'
import BookmarkCollectionDetailModal from '../BookmarkModal/BookmarkCollectionDetailModal'
import AddProductCollectionModal from '../ProductPage/AllProduct/AddProductCollectionModal'
import CollectionSharedModal from '../ProductPage/AllProduct/CollectionSharedModal'
import DeleteCollectionModal from '../ProductPage/AllProduct/DeleteCollectionModal'
import MobileViewCollectionDetailsModal from '../BookmarkModal/MobileViewCollectionDetailsModal'
import BoolmarkMoreOptionModal from '../BookmarkModal/BoolmarkMoreOptionModal'
import MobileViewBookmarkMoreOptionModal from '../BookmarkModal/BoolmarkMoreOptionModal'
import MobileViewShareMyFeedModal from '../BookmarkModal/MobileViewShareMyFeedModal'
import MobileViewSeeAllBookMarkDetails from '../BookmarkModal/MobileViewSeeAllBookMarkDetails'
import ShareAllPostMyFeedPost from '../Feed/NormalPost/ShareAllPostMyFeedPost'
import MobileViewRemoveBookmarkProduct from '../BookmarkModal/MobileViewRemoveBookmarkProduct'
import MobileviewUserProfileBio from '../UserProfile/UserProfileModal/UserAbout/MobileviewUserProfileBio'
import ExpandedMediaCommentModal from '../UserProfile/ExpandedMediaDetails/ExpandedMediaCommentModal'
import CategoryBrowsebyType from './CategoryBrowsebyType'
import CategoryFeaturedProducts from './CategoryFeaturedProducts'
import CategoryNewArrivals from './CategoryNewArrivals'
import CategoryHotWatches from './CategoryHotWatches'
import CategorySpecialOffers from './CategorySpecialOffers'
import MobilefeaturedproductModal from '../Category/ShopByCategoryDetails/MobileViewFeturedProduct'
import MobileViewNewArrivalProduct from '../Category/ShopByCategoryDetails/MobileViewNewArrivalProduct'
import MobileViewHotWatches from '../Category/ShopByCategoryDetails/MobileViewHotWatches'
import MobileViewSpecialOffers from '../Category/ShopByCategoryDetails/MobileViewSpecialOffers'
import BookmarkAddNewModal from '../BookmarkModal/BookmarkAddNewModal'
import InviteClubMemberModel from './InviteClubMemberModel'
import TrendingProduct from './TrendingProductsModel'
import OrderDetailModal from './OrderDetailModal'
import ShopCubProductSeeAll from './ShopModal/ShopCubProductSeeAll'
import NewArrival from './ShopModal/NewArrival'
import MenNowTrendingModal from './MenNowTrendingModal'
import NewArrivalCommon from '../Shop/NewArrivalCommon'
import JewelryUpdatedModal from './JewelryModal/JewelryUpdatedModal'
// import NewArrivalCommon from '../Shop/NewArrivalCommon'
import Invitefriendmodel from './../Invitefriendmodelmobile/index'
import Invitefriendsemailmodelmobile from '../invitefriendsemailmodelmobile'
import { GoogleOAuthProvider } from '@react-oauth/google'
import ProductReviewModal from '../customerportal/orders/ProductReviewModal'
import TAX_CALCULATION from 'src/graphql/mutations/taxCalculation'
import MobileNewArrivalModal from '../Category/ShopByCategoryDetails/MobileNewArrivalModal'
import PastMeetingVideo from './LiveStreamModel/PastMeetingVideo'
import AllStreamsModal from './LiveStreamModel/AllStreams'
import BeautyAndCosmeticsModal from '../BookAppliance/BookApplianceModal/BeautyAndCosmeticsModal'

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY)
const clientId = process.env.NEXT_PUBLIC_CLIENT_ID
export default function StoreAndProductDetailModal(props) {
  const router = useRouter()
  const { editPostHandler } = props
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const fetchAddEditPost = useSelector((state: any) => state?.postAddEditModal?.data)
  const fetchProductImageResizeModal = useSelector((state: any) => state?.productResizeData?.data)
  const fetchProductShareOptions = useSelector((state: any) => state?.productShareOptions?.data)
  const sellerWithdrawData = useSelector((state: any) => state?.sellerWithdrawData?.data)
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const bookmarkTabData = useSelector((state: any) => state?.manageBookmarkTabsSlice?.data)
  const manageMobileRename = useSelector((state: any) => state?.manageBookmarRenameSlice?.data)
  const inviteClubMembers = useSelector((state: any) => state?.manageInviteClubMemberSlice?.data)

  const [deviceType, setDeviceType] = useState('')
  const [contacts, setContacts] = useState([])

  const [addStoreActivity] = useMutation(ADD_STORE_ACTIVITY, {
    onCompleted: (res) => { },
    onError: (err) => { },
  })

  const [addProductView] = useMutation(ADD_PRODUCT_VIEW, {
    onCompleted: (res) => { },
    onError: (err) => { },
  })

  const [taxCalculation] = useMutation(TAX_CALCULATION, {
    onCompleted: (res) => { },
    onError: (err) => { },
  })

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth
      if (screenWidth < 768) {
        setDeviceType('Mobile')
      } else if (screenWidth >= 768 && screenWidth < 1024) {
        setDeviceType('Tablet')
      } else {
        setDeviceType('Desktop')
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (getAllState?.lastModalData?.isOpen) {
        event.preventDefault()
        event.returnValue = ''
        if (getAllState?.lastModalData?.modal === 'store') {
          addStoreActivity({
            variables: {
              input: {
                store_id: getAllState?.lastModalData?.id?.id,
                startDate: getAllState?.lastModalData?.startDate,
                endDate: moment().toISOString(),
                deviceName: deviceType,
              },
            },
          })
          let filtredModal = getAllState?.allModalData?.filter(
            (item) => item?.modal !== getAllState?.lastModalData?.modal,
          )
          dispatch(
            fetchModalData({
              allModalData: filtredModal,
              lastModalData: getAllState?.lastModalData,
            }),
          )
          router.reload()
        }
        if (getAllState?.lastModalData?.modal === 'productDetail') {
          addProductView({
            variables: {
              input: {
                store_id: getAllState?.lastModalData?.store_id,
                product_id: getAllState?.lastModalData?.id,
                startDate: getAllState?.lastModalData?.startDate,
                endDate: moment().toISOString(),
              },
            },
          })
          let filtredModal = getAllState?.allModalData?.filter(
            (item) => item?.modal !== getAllState?.lastModalData?.modal,
          )
          dispatch(
            fetchModalData({
              allModalData: filtredModal,
              lastModalData: getAllState?.lastModalData,
            }),
          )
          router.reload()
        }
      }
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [router, getAllState])

  type Contact = {
    emailAddresses?: { value: string }[]
    names?: { displayName: string }[]
    phoneNumbers?: { canonicalForm: string }[]
  }

  const transformedContacts = contacts
    .map((item: Contact) => {
      const newItem: Contact = {}

      if (item.emailAddresses?.length > 0) {
        newItem.emailAddresses = item.emailAddresses.map((email) => ({
          value: email.value,
        }))
      }

      if (item.names?.length > 0) {
        newItem.names = item.names.map((name) => ({
          displayName: name.displayName,
        }))
      }

      if (item.phoneNumbers?.length > 0) {
        newItem.phoneNumbers = item.phoneNumbers.map((phone) => ({
          canonicalForm: phone.canonicalForm,
        }))
      }

      return newItem
    })
    .filter((item: Contact) => item.emailAddresses !== undefined)

  const bigModals = () => {
    if (getAllState?.lastModalData?.modalFor === 'bigModal') {
      switch (getAllState?.lastModalData?.modal) {
        case 'userDetail':
          return <UserProfileModal userId={getAllState?.lastModalData?.id} slug={getAllState?.lastModalData?.slug} editPostHandler={editPostHandler} />
        case 'clubModal':
          return <ClubModal />
        case 'menNowTrendingModal':
          return <MenNowTrendingModal />
        case 'peopleModal':
          return <PeopleModal />
        case 'bookmarkModal':
          return <BookmarkModal />
        case 'bookmarksharedcollectionModal':
          return <BookmarkCollectionDetailModal />
        case 'collectionByProduct':
          return <CollectionByProductModal />
        case 'store':
          return <StoreProductModal storeData={getAllState?.lastModalData?.id?.store} slug={null} />
        case 'productDetail':
          return <ProductDetailModal productId={getAllState?.lastModalData?.id} />
        case 'pastMeetingVideo':
          return <PastMeetingVideo />
        case 'allStreams':
          return <AllStreamsModal />
        case 'liveStream':
          return <LiveStreamMain productId={getAllState?.lastModalData?.id} />
        // return <LiveStreamModal productId={getAllState?.lastModalData?.id} />
        case 'checkout':
          return (
            <Elements stripe={stripePromise}>
              <Checkout productArray={getAllState?.lastModalData?.id} />
            </Elements>
          )

        case 'group':
          return <GroupModal groupId={getAllState?.lastModalData?.id} />
        case 'cart':
          return (
            <Elements stripe={stripePromise}>
              <ShoppingCart groupId={getAllState?.lastModalData?.id} isBack={getAllState?.lastModalData?.id?.isBack} />
            </Elements>
          )
        case 'messageModal':
          return <MessageModal />
        case 'orderDetailModal':
          return <OrderDetailModal />
        case 'jewelryHotDealModal':
          return <JewelryHotDealModal />
        case 'jewelryLiveChannelsModal':
          return <JewelryLiveChannelsModal />
        case 'jewelryNewArrivalModal':
          return <JewelryNewArrivalModal />
        case 'jewelryStoreModal':
          return <JewelryStoreModal />
        case 'jewelryCLubModal':
          return <JewelryClubModal />
        case 'jewelryFansModal':
          return <JewelryFansModal />
        case 'bookSpecialOfferModal':
          return <BookSpecialOfferModal />
        case 'bookNewrelease':
          return <BookNewReleaseModal />
        case 'bookBestseller':
          return <BookBestSellerModal />
        case 'bookofthemonth':
          return <BookOfTheMonthModal />
        case 'bookAuthors':
          return <BookAuthorModal />
        case 'bookAuthorProfile':
          return <AuthorModal />
        case 'bookClub':
          return <BookClubModal />
        case 'bookDetail':
          return <BookDetailModal />
        case 'bookSpecialOfferModal':
          return <BookSpecialOfferModal />
        case 'trendingstore':
          return <BookApplianceTredingStoreModal />
        case 'allianceAuthor':
          return <BookApplianceIdeaModal />
        case 'applianceHomeClub':
          return <HomeApplianceClubsModal />
        case 'beautyAndCosmetics':
          return <BeautyAndCosmeticsModal />
        case 'applianceInspirationalInfluencer':
          return <InspirationalInfluencerModal />
        case 'applianceNowtrending':
          return <BookApplianceNowTrendingModal />
        case 'artCraftNowTrending':
          return <NowTrendingArtCarft />
        case 'artCraftTrendingStore':
          return <TrendingStoreArtCarft />
        case 'artCraftDIYRoom':
          return <DIYRoomArtCarft />
        case 'artCraftMeetOurArtists':
          return <MeetOurArtistsArtCarft />
        case 'artCraftLeranFromArtists':
          return <LeranFromArtistsArtCarft />
        case 'artCraftCreativeClubs':
          return <CreativeClubsArtCarft />
        case 'sellerStockReport':
          return <MobileViewStockReportModal />
        case 'ShopnewArrivalSeeAll':
          return <NewArrival />
        case 'whatsHotModal':
          return <WhatsHotModal />
        case 'newArrivalModal':
          return <NewArrivalModal />
        case 'risingStoresModal':
          return <RisingStoresModal />
        case 'mobileViewSeeAllBookMarkDetails':
          return <MobileViewSeeAllBookMarkDetails />
        case 'categoryBrowsebyType':
          return <CategoryBrowsebyType />
        case 'trendingProductModel':
          return <TrendingProduct />
        case 'categoryFeaturedProducts':
          return <CategoryFeaturedProducts />
        case 'categoryNewArrivals':
          return <CategoryNewArrivals />
        case 'categoryHotWatches':
          return <CategoryHotWatches />
        case 'categorySpecialOffers':
          return <CategorySpecialOffers />
        case 'shopCubProductSeeAll':
          return <ShopCubProductSeeAll />
      }
    }
  }

  const handleFilter = () => { }

  const mobileModals = () => {
    if (
      fetchMobileModal?.lastModalData?.modalFor !== 'mobileSecondModal' ? fetchMobileModal?.lastModalData?.isOpen : null
    ) {
      switch (fetchMobileModal?.lastModalData?.modal) {

        case 'filterModal': {
          const data = fetchMobileModal?.lastModalData?.data;
          return (
            <ProductFilter
              APIcall={() => { }}
              category_slug={data?.category_slug}
              subCategory_slug={data?.subCategory_slug}
              childSubCategory_slug={data?.childSubCategory_slug}
              store_slug={data?.store_slug}
            />
          )
        }
        case 'groupControlModal':
          return <GroupMobileViewModal />
        // case 'userProfileAboutModal':
        //   return <MobileviewMoreaboutmodal />
        case 'DIYroomModal':
          return <DIYRoomModal />
        case 'productOption':
          return <MobileViewProductCategory />
        case 'productShareOnMyFeed':
          return <ShareOnMyFeed />
        case 'productSharewithFriend':
          return <SharewithFriend />
        case 'productShareInClub':
          return <SharewithClubs />
        case 'inviteUserModal':
          return (
            <GoogleOAuthProvider clientId={clientId}>
              <Invitefriendmodel setContacts={setContacts} />
            </GoogleOAuthProvider>
          )
        case 'invitefriendsemailmodelmobile':
          return <Invitefriendsemailmodelmobile transformedContacts={transformedContacts} />
        case 'feedShareOnClub':
          return <FeedShareOnMyClub />
        case 'postAddEditModalMobile':
          return <FeedMobileViewCreateNewPostModal />
        case 'addVariantMobile':
          return <MobileViewAddVariantModal />
        case 'addSizeVariant':
          return <MobileAddSizeVariant />
        case 'addAddColorVariantMobile':
          return <MobileAddColorVariant />
        case 'mobilePicUpModal':
          return <MobilePicUpModal />
        case 'addCategoryMobile':
          return <MobileViewSelectCategoryModal />
        case 'addSelectImageRatio':
          return <MobileSelectImageRatioModal />
        case 'adjustImageMobile':
          return <MobileViewAdjustImageModal />
        case 'mobileProductDescription':
          return <MobileProductDescriptionModal />
        case 'mobileJewelryCategoryModal':
          return <MobileJewelryCategoryModal />
        case 'mobileJewelryHotDetails':
          return <MobileJewelryHotDetailsModal />
        case 'mobileJewelryLiveChannel':
          return <MobileJewelryLiveChannel />
        case 'mobileJewelryNewArrival':
          return <MobileJewelryNewArrival />
        case 'mobileJewelryClubModal':
          return <MobileJewelryClubModal />
        case 'mobileJewelryFansModal':
          return <MobileJewelryFansModal />
        case 'mobileJewelryStoreModal':
          return <MobileJewelryStoreModal />
        case 'addMessageModal':
          return <AddMessageModal />
        case 'MobileViewPersonalAccount':
          return <MobileViewPersonalAccount />
        case 'mobileViewStoreModal':
          return <StoreMobileViewModal />
        case 'popularMobileViewStoreModal':
          return <PopularStoreMobileViewModal />
        case 'mobileFilterModal':
          return <MobileFilterModal />
        case 'chatBoxModal':
          return (
            <>
              <MessageChatBox />
              {/* <MobileMessageSelected /> */}
            </>
          )

        case 'mobileProductDetailsShareModal':
          return <MobileProductDetailsShareModal />

        case 'mobileViewBookMarkModal':
          return <MobileViewBookMarkModal />

        case 'mobileViewProductVarint':
          return <MobileViewProductAllVarintModal />

        case 'mobileViewCollectionDetail':
          return <MobileViewCollectionDetailsModal />

        case 'mobileViewRemoveProductModal':
          return <MobileViewRemoveBookmarkProduct />

        case 'mobileViewBookMarkMoreOptionModal':
          return <MobileViewBookmarkMoreOptionModal />

        // case 'mobileViewAllBookmarkCollection':
        //   return <MobileViewAllBookmarkCollection />

        case 'mobileViewAddProductModal':
          return <AddProductCollectionModal />

        case 'mobileViewFriendCollectionModal':
          return <BookmarkCollectionDetailModal />

        case 'mobileViewShareMyFeedModal':
          return <MobileViewShareMyFeedModal />

        case 'mobileserProfileBio':
          return <MobileviewUserProfileBio />

        case 'mobilecommentModal':
          return <ExpandedMediaCommentModal />

        // case 'mobileBookmarkModal':
        //   return <ExpandedMediaCommentModal />

        case 'mobilefeaturedproductModal':
          return <MobilefeaturedproductModal />

        case 'mobilenewArrivalModal':
          return <MobileViewNewArrivalProduct />

        case 'mobileHotWatches':
          return <MobileViewHotWatches />

        case 'mobileSpeacialOffer':
          return <MobileViewSpecialOffers />

        case 'mobileOrderDetails':
          return <OrderDetailModal />

        case 'newArrivalCommon':
          return <NewArrivalCommon />

        case 'mobileJewelryModal':
          return <JewelryUpdatedModal />

        case 'clubDotsMenu':
          return <MobileViewBookmarkMoreOptionModal />

        case 'bookmarkProductModal':
          return <AddProductCollectionModal />
        case 'mobileNewArrivalModal':
          return <MobileNewArrivalModal />
          // case 'mobileReaction':
          //   return <MobileMessageSelected />
          break
      }
    }
  }

  const secondModals = () => {
    if (
      fetchMobileModal?.secondModal?.modalFor === 'mobileSecondModal' ? fetchMobileModal?.secondModal?.isOpen : null
    ) {
      switch (fetchMobileModal?.secondModal?.modal) {
        case 'removeMessageModal':
          return <MobileViewMessageDelete />
        case 'messageForwardModal':
          return <MobileViewForwardMessageModal />
        case 'messageMoreOptionModal':
          return <MobileMessageChatOptionModal />
        case 'messageMediaModal':
          return <MessageMedia />
        // case 'messageMediaPreviewModal':
        //   return <MessageMediaPreview />
      }
    }
  }

  const mediumSellerModal = () => {
    if (getAllState?.lastModalData?.modalFor === 'mediumModal' ? getAllState?.lastModalData?.isOpen : null) {
      switch (getAllState?.lastModalData?.modal) {
        // case 'addProductModal':
        //   return <AddProductModal />
        case 'stockReportModal':
          return <StockReportModal />
        case 'productsVariantData':
          return <ProductVariant />
        case 'sellerReviewModal':
          return <SellerReviewModal />
        case 'storesModal':
          return <StoresModal />
        case 'sellerFollowersDetailModal':
          return <SellerFollowersDetails />
        case 'sellerCustomerDetailModal':
          return <CustomerDetailModal />
        case 'sellerOrderDetailModal':
          return <CustomerOrderDetailsModal />
      }
    }
  }

  const sellerWithdrawModal = () => {
    if (
      sellerWithdrawData?.lastModalData?.modalFor === 'sellerWithdrawSize'
        ? sellerWithdrawData?.lastModalData?.isOpen
        : null
    ) {
      switch (sellerWithdrawData?.lastModalData?.modal) {
        case 'sellerWithdrawModalFirst':
          return <WithdrawEnterAmountModal />
        case 'sellerWithdrawDetailModal':
          return <WithdrawDetailModal />
        case 'sellerWithdrawOTPverification':
          return <WithdrawOTPverificationModal />
        case 'sellerWithdrawRequestProcessed':
          return <WithdrawRequestProcessedModal />
        case 'sellerWithdrawTransactionDetail':
          return <WithdrawTransactionDetailModal />
      }
    }
  }

  const sellerCustomerModals = () => {
    if (
      getAllState?.lastModalData?.modalFor === 'sellerCustomerViewModal' ? getAllState?.lastModalData?.isOpen : null
    ) {
      switch (getAllState?.lastModalData?.modal) {
        case 'sellerCustomerModal':
          return <SellerCustomerModal />
        // case 'userOrderDetailModal':
        //   return <SellerCustomerModal />
      }
    }
  }

  const shareProductModals = () => {
    switch (fetchProductShareOptions?.modal) {
      case 'productShareWithFriend':
        return <ShareProductWithFriendsModal />
      case 'productSharewithClub':
        return <ShareOnMyGroupModal />
      case 'productShareOnMyFeedModal':
        return <ShareOnMyFeedModal />
      case 'shareProductFromBookmark':
        return <ShareAllPostMyFeedPost />
      case 'sharedSuccessfullModel':
        return <CollectionSharedModal />
    }
  }

  return (
    <div>
      <LoginModal />
      {/* <----------------------------------------------POST_ADD_EDIT_WEB_MODAL_START------------------------------------------------------> */}
      {fetchAddEditPost?.modal === 'postAddEditModal' && <PostAddEditModal />}
      {/* <----------------------------------------------POST_ADD_EDIT_WEB_MODAL_END--------------------------------------------------------> */}

      {/* <----------------------------------------------SEARCH_MODAL_START--------------------------------------------------------------------> */}
      {/* <SearchModal /> */}
      {/* <----------------------------------------------SEARCH_MODAL_END--------------------------------------------------------------------> */}

      {/* <----------------------------------------------PRODUCT_SHARE_MODAL_START------------------------------------------------------> */}
      {shareProductModals()}
      {/* <----------------------------------------------PRODUCT_SHARE_MODAL_END--------------------------------------------------------> */}

      {/* <----------------------------------------------PRODUCT_RESIZE_MODAL_START------------------------------------------------------> */}
      {fetchProductImageResizeModal?.modal === 'productResizeSlice' && fetchProductImageResizeModal?.isOpen && (
        <ProductAdjustImageModal />
      )}
      {/* <----------------------------------------------PRODUCT_RESIZE_MODAL_END--------------------------------------------------------> */}

      {/* <----------------------------------------------PRODUCT_VARIANT_MODEL--------------------------------------------------------> */}
      {addProductDetailSlice?.id?.variant?.isVariantModel && <VariantDisplayModel />}
      {/* <----------------------------------------------PRODUCT_VARIANT_MODEL_END--------------------------------------------------------> */}

      {/*  <----------------------------------------------MOBILE_MODAL_START-------------------------------------------------------------> */}
      <MobileViewFilterModal
        isOpen={
          fetchMobileModal?.lastModalData?.modalFor !== 'mobileFirstModal' &&
            fetchMobileModal?.lastModalData?.for !== 'user'
            ? fetchMobileModal?.lastModalData?.isOpen
            : null
        }
      >
        {mobileModals()}
      </MobileViewFilterModal>
      <MobileViewSecondModal
        isOpen={
          fetchMobileModal?.secondModal?.modalFor === 'mobileSecondModal' ? fetchMobileModal?.secondModal?.isOpen : null
        }
      >
        {secondModals()}
      </MobileViewSecondModal>
      {/* <MobileViewSecondModal
        isOpen={
          fetchMobileModal?.messageMediaPreviewModal?.modalFor === 'messageMedia'
            ? fetchMobileModal?.messageMediaPreviewModal?.isOpen
            : null
        }
      > */}
      {fetchMobileModal?.messageMediaPreviewModal?.modal === 'messageMediaModal' &&
        fetchMobileModal?.messageMediaPreviewModal?.isOpen && <MessageMediaPreview />}
      {/* </MobileViewSecondModal> */}
      {/* <----------------------------------------------MOBILE_MODAL_END----------------------------------------------------------------> */}

      <ModalSlide
        isModalOpen={getAllState?.lastModalData?.modalFor === 'bigModal' ? getAllState?.lastModalData?.isOpen : false}
      >
        {bigModals()}
      </ModalSlide>

      {/* <----------------------------------------------SELLER_MODAL_START------------------------------------------> */}
      <SellerModalSlice
        isModalOpen={getAllState?.lastModalData?.modalFor === 'mediumModal' ? getAllState?.lastModalData?.isOpen : null}
      >
        {mediumSellerModal()}
      </SellerModalSlice>

      <ProductModalParent
        isModalOpen={
          getAllState?.lastModalData?.modalFor === 'productModal' ? getAllState?.lastModalData?.isOpen : null
        }
      >
        {getAllState?.lastModalData?.modal === 'addProductModal' && <AddProductModal />}
      </ProductModalParent>

      <SellerCustomerViewModal
        isModalOpen={
          getAllState?.lastModalData?.modalFor === 'sellerCustomerViewModal' ? getAllState?.lastModalData?.isOpen : null
        }
      >
        {sellerCustomerModals()}
      </SellerCustomerViewModal>
      {/* <----------------------------------------------SELLER_MODAL_END------------------------------------------> */}

      <SmallSizeModal
        isModalOpen={getAllState?.seeAllStore?.modalFor === 'smallModal' ? getAllState?.seeAllStore?.isOpen : null}
      >
        {getAllState?.seeAllStore?.modal === 'seeAllStoreModal' && <SeeAllStore className={'activeBorder'} />}
      </SmallSizeModal>

      <ClubCreationMain
        isModalOpen={
          getAllState?.groupCreationModal?.modalFor === 'smallModal' ? getAllState?.groupCreationModal?.isOpen : null
        }
      >
        {getAllState?.groupCreationModal?.modal === 'createClubModal' && <CreateClubModal />}
      </ClubCreationMain>

      {/* <----------------------------------------------WEB_MODAL_START--------------------------------------------------------------------> */}
      <NotificationSizeModal
        isModalOpen={
          getAllState?.lastModalData?.modalFor === 'notificationSizeModal' ? getAllState?.lastModalData?.isOpen : null
        }
      >
        {/* {getAllState?.lastModalData?.modal === 'notificationModal' && <Notification />} */}
      </NotificationSizeModal>
      <SellerWithdrawFundModal
        isModalOpen={
          sellerWithdrawData?.lastModalData?.modalFor === 'sellerWithdrawSize'
            ? sellerWithdrawData?.lastModalData?.isOpen
            : null
        }
      >
        {sellerWithdrawModal()}
      </SellerWithdrawFundModal>
      {/* <----------------------------------------------WEB_MODAL_END--------------------------------------------------------------------> */}

      {/* ------------------------------- Share and Bookmark modal design start -------------------------------------*/}
      {fetchProductImageResizeModal?.modal === 'mobileViewBookMarkModal' && fetchProductImageResizeModal?.isOpen && (
        <div className="product-details-bookmark-alignment">
          <div className="product-details-bookmark-box">
            <MobileViewBookMarkModal />
          </div>
        </div>
      )}

      {fetchProductImageResizeModal?.modal === 'mobileProductDetailsShareModal' &&
        fetchProductImageResizeModal?.isOpen && (
          <div className="product-details-bookmark-alignment">
            <div className="product-details-share-box">
              <MobileProductDetailsShareModal />
            </div>
          </div>
        )}

      {/* ------------------------------- Share and Bookmark modal design end -------------------------------------*/}

      {/* <----------------------------------------  Add product collection details start ---------------------------------> */}
      {bookmarkTabData?.id?.addProductToCollection?.isShowAddCollectionModel && <AddProductCollectionModal />}
      {/* <----------------------------------------  Add product collection details end ---------------------------------> */}

      {/* <-------------------------------- Delete collection modal design start ----------------------------------------> */}
      {bookmarkTabData?.id?.deleteCollection?.isShowDeleteModel && <DeleteCollectionModal />}
      {/* <-------------------------------- Delete collection modal design end ----------------------------------------> */}

      {/* --------------------------------- Rename Bookmark Collection ----------------------------------------------- */}
      {manageMobileRename?.data?.id?.showModel && (
        <BookmarkAddNewModal
          setAddCollectionWebModal={manageMobileRename?.data?.id?.showModel}
          isEditCollection={manageMobileRename?.data?.id?.isEditCollection}
          name={manageMobileRename?.data?.id?.collectionName}
          collectionId={manageMobileRename?.data?.id?.collectionId}
        />
      )}
      {/* --------------------------------- Rename Bookmark Collection End ----------------------------------------------- */}

      {/* --------------------------------- Rename Bookmark Collection ----------------------------------------------- */}
      {inviteClubMembers?.data?.showModel && <InviteClubMemberModel />}
      {/* --------------------------------- Rename Bookmark Collection End ----------------------------------------------- */}
    </div>
  )
}
