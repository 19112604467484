import { gql } from '@apollo/client'

export default gql`
mutation CREATEGROUP(
  $name: String!
  $description: String!
  $hashtags: [String]
  $category: String!
  $privacy: String!
  $logo_image: String!
  $banner_image: String!
  $subCategory_id: [Int]
) {
  createGroup(
    input: {
      name: $name
      description: $description
      hashtags: $hashtags
      category: $category
      privacy: $privacy
      logo_image: $logo_image
      banner_image: $banner_image
      subCategory_id: $subCategory_id
    }
  ) {
    data {
      id
    }
  }
}
`
