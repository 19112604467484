import React from 'react'
import styles from './SellerOnboardingThankYou.module.scss'

export default function SellerOnboardingThankYou() {
  return (
    <div className={styles.sellerOnboardingThankYouSection}>
      <div className={styles.sellerOnboardingThankYouBox}>
        <div className={styles.sellerRightIcon}>
          {/* <img src="/assets/seller/icons/sellerRightSign.svg" alt="sign icon" /> */}
          <svg width="157" height="144" viewBox="0 0 157 144" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="14.0168" width="51.425" height="51.753" rx="10" fill="#9E96FF" />
            <rect opacity="0.5" x="127.708" y="33.0039" width="29.2923" height="29.4792" rx="10" fill="#9E96FF" />
            <rect opacity="0.5" y="75.1756" width="31.4609" height="31.6616" rx="8" fill="#DEDBFF" />
            <rect opacity="0.5" x="114.026" y="107.257" width="36.5099" height="36.7428" rx="8" fill="#DEDBFF" />
            <ellipse cx="83.2858" cy="78.695" rx="59.863" ry="60.2448" fill="#556EE6" />
            <g filter="url(#filter0_d_312_1258)">
              <path
                d="M60.6211 81.9526L73.572 94.9861L105.949 62.4023"
                stroke="white"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_312_1258"
                x="26.6211"
                y="32.4023"
                width="113.328"
                height="100.584"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="15" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.227451 0 0 0 0 1 0 0 0 0.3 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_312_1258" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_312_1258" result="shape" />
              </filter>
            </defs>
          </svg>
        </div>
        <div className={styles.sellerThankYouDetails}>
          <h6>
            Thank you for submitting <br /> your Seller application with Bluejestic!
          </h6>
          <p>A team member will be in touch with you within 48-72 hours.</p>
        </div>
      </div>
    </div>
  )
}
