// @ts-nocheck

import React, { useEffect, useState } from 'react'
import styles from './NewSuggestedStores.module.scss'
import Slider from 'react-slick'
import NewStoreSlider from 'src/components/StoreAndProductDetailModal/StoresModal/NewStoreSlider'
import { useSelector } from 'react-redux'
import { fetchPopularStoreData, fetchSuggestedStoreData } from 'src/store/slices/fetchAllStoresSlice'
import { useAppDispatch } from 'src/store/store'
import useDebounce from 'src/hoc/useDebounce'
const CuateImage = '/assets/img/cuate-lg.svg'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <svg
        style={{ transform: 'none' }}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <circle opacity="0.6" cx="20" cy="20" r="20" fill="#F8F8F8" />
        <path d="M19 15L23 20L19 25" stroke="#1E2432" strokeLinecap="round" strokeLinejoin="round" />
      </svg>{' '}
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <svg
        style={{ transform: 'rotate(180deg)' }}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <circle opacity="0.6" cx="20" cy="20" r="20" fill="#F8F8F8" />
        <path d="M19 15L23 20L19 25" stroke="#1E2432" strokeLinecap="round" strokeLinejoin="round" />
      </svg>{' '}
    </div>
  )
}

const NewSuggestedStores = () => {
  const allSuggestedStores = useSelector((state: any) => state?.storesData?.allSuggestedStores)
  const dispatch = useAppDispatch()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1699,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 923,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  useEffect(() => {
    dispatch(fetchSuggestedStoreData({ limit: 10, page: 1, type: 'ALL_STORE', search: debouncedSearch }))
  }, [debouncedSearch])

  const followStoreCall = () => {}

  const unfollowStoreCall = () => {}

  const handleAddStoreLike = () => {}

  const handleChange = (e) => {
    setSearch(e.target.value)
  }

  return (
    <div className={styles.seeAllStoreBoxAlignment}>
      <div className={styles.newSuggestedStores}>
        <div className={styles.storesGrid}>
          <div className={styles.text}>
            <p>Suggested Stores</p>
            <span>Search for brands and stores that suits your styles</span>
          </div>
          <div className={styles.peopleFollowerSearchAlignment}>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
              <path
                d="M19 27C23.4183 27 27 23.4183 27 19C27 14.5817 23.4183 11 19 11C14.5817 11 11 14.5817 11 19C11 23.4183 14.5817 27 19 27Z"
                stroke="#ACB1C0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M28.9999 28.9999L24.6499 24.6499"
                stroke="#ACB1C0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <input
              type="text"
              name=""
              id=""
              placeholder="Search Stores by Name or by Type"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className={styles.sliderWidthDesign}>
        {allSuggestedStores?.length > 0 ? (
          <Slider {...settings}>
            {allSuggestedStores?.map((store, index) => (
              <React.Fragment key={index}>
                <NewStoreSlider
                  store={store}
                  followStoreCall={followStoreCall}
                  unfollowStoreCall={unfollowStoreCall}
                  handleAddStoreLike={handleAddStoreLike}
                />
              </React.Fragment>
            ))}
          </Slider>
        ) : (
          <div className={styles.noFollowesDataBoxAlignment}>
            <div className={styles.imgCenter}>
              <img src={CuateImage} alt="CuateImage" draggable={false} />
            </div>
            <p>Store not Found</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default NewSuggestedStores
