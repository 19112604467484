import { gql } from '@apollo/client'

const SELLER_CONNECT_ACCOUNTS = gql`
  query sellerConnectAccounts {
    sellerConnectAccounts {
      success
      message
      data {
        type
        brand
        country
        currency
        exp_month
        exp_year
        last4
        bank_name
        routing_number
        account_holder_name
        isDefault
        isActivated
      }
    }
  }
`
export default SELLER_CONNECT_ACCOUNTS
