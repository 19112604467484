import React from 'react'
import styles from './MobileViewStockReportModal.module.scss'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { fetchModalData } from 'src/store/slices/manageModalSlice'

export default function MobileViewStockReportModal() {
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const handleClose = () => {
    let datass = getAllState?.allModalData?.filter((item) => item?.modal !== getAllState?.lastModalData?.modal)
    dispatch(
      fetchModalData({
        allModalData: datass,
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }
  return (
    <div className={styles.mobileViewStockReportAlignment}>
      <div className={styles.mobileStockReportModalDetailsAlignment}>
        <div className={styles.mobileViewHeadingAlignment} onClick={() => handleClose()}>
          <div className={styles.mobileBackIconAlignment}>
            <img src="/assets/icon/Back.svg" alt="back icon" />
          </div>

          <h4>Stock Report</h4>
        </div>
        <div className={styles.mobileViewSearchAlignment}>
          <div className={styles.mobileViewSearchBoxAlignment}>
            <div className={styles.mobileViewSearchIcon}>
              <img src="/assets/seller/icons/search-icon.svg" alt="search icon" />
            </div>
            <input type="text" placeholder="Search" />
          </div>
        </div>
        <div className={styles.mobileOptionDetailsAlignment}>
          <ul>
            <li>All</li>
            <li>In Stock</li>
            <li>Low Stock</li>
            <li>Out of Stock</li>
          </ul>
        </div>
        <div className={styles.mobileViewDetailsMainAlignment}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => {
            return (
              <div className={styles.mobileDetailsAlignment} key={index}>
                <div className={styles.mobileViewStockReportProductAlignment}>
                  <div className={styles.mobileViewStockImgAlignment}>
                    <img src="/assets/seller/img/stock-product-img.png" alt="product img" />
                  </div>
                  <div className={styles.mobileViewStockDetailsAlignment}>
                    <h6>Dasha Modern Dress lorem...</h6>
                    <p>SKU 937153746</p>
                    <div className={styles.mobileViewStockAllDetailsNumberFlex}>
                      <div className={styles.mobileViewStockAllDetailsStockAlignmentBox}>
                        <span>In Stock</span>
                      </div>
                      <p>24 items</p>
                    </div>
                  </div>
                </div>
                <div className={styles.mobileViewStockReportPriceAlignment}>
                  <h5>$139.90</h5>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
