import React from 'react'
import styles from './ProductFilterBar.module.scss'
import classNames from 'classnames'
import ProductPagination from './ProductPagination'
export default function ProductFilterBar({
  total,
  dataLimite,
  page,
  setPage,
  topForFilter,
  setIsModalVisible,
  isModalVisible,
  handleBoxOpen,
}) {
  return (
    <div className={styles.productFilterSection} style={{ top: topForFilter }}>
      <div className={styles.productFilterBox}>
        <div className={styles.filterBarLeftSide}>
          <div
            className={classNames(styles.firstfilter, styles.filterActive)}
            onClick={() => setIsModalVisible(!isModalVisible)}
          >
            <div className={styles.firstFilterIcon}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M1.33069 3.33075H14.664" stroke="#7A7E88" strokeLinecap="round" />
                <path d="M3.33069 7.99744H12.664" stroke="#7A7E88" strokeLinecap="round" />
                <path d="M5.99731 12.6641H9.99731" stroke="#7A7E88" strokeLinecap="round" />
              </svg>
            </div>
            <p>Filters</p>

            <div className={styles.activeFilterBox}></div>
          </div>

          <div className={styles.secondFilter} onClick={() => handleBoxOpen()}>
            <div className={styles.secondFilterIcon}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M1.33203 2.66602L1.33203 13.3329" stroke="#7A7E88" strokeLinecap="round" />
                <path d="M14.6653 13.3327L1.33149 13.3327" stroke="#7A7E88" strokeLinecap="round" />
                <path
                  d="M3.33203 9.99967C7.37801 2.38063 10.3205 13.8092 13.9987 3.33301"
                  stroke="#7A7E88"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <p>Category Engagement</p>
          </div>
        </div>

        {/* <div className={styles.filterCenterSide}>
          <div className={styles.filterBox}>
            <span>Blue</span>
            <div className={styles.closeIcon}>
              <img src="/assets/icon/filter-close-icon.svg" alt="close icon" />
            </div>
          </div>
          <div className={styles.filterBox}>
            <span>Blue</span>
            <div className={styles.closeIcon}>
              <img src="/assets/icon/filter-close-icon.svg" alt="close icon" />
            </div>
          </div>
          <div className={classNames(styles.filterBox, styles.yellowBox)}>
            <span>Yellow</span>
            <div className={styles.closeIcon}>
              <img src="/assets/icon/filter-close-icon.svg" alt="close icon" />
            </div>
          </div>
          <div className={styles.filterBox}>
            <span>Blue</span>
            <div className={styles.closeIcon}>
              <img src="/assets/icon/filter-close-icon.svg" alt="close icon" />
            </div>
          </div>
        </div> */}

        <div className={styles.filterRightSide}>
          {/* <div className={styles.filterSearch}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                d="M7.33352 12.6669C10.279 12.6669 12.6669 10.279 12.6669 7.33352C12.6669 4.388 10.279 2.00018 7.33352 2.00018C4.388 2.00018 2.00018 4.388 2.00018 7.33352C2.00018 10.279 4.388 12.6669 7.33352 12.6669Z"
                stroke="#7A7E88"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.0001 14.0001L11.1001 11.1001"
                stroke="#7A7E88"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div> */}

          <ProductPagination pages={Math.ceil(total / dataLimite)} current={page} onClick={setPage} />

          <div className={styles.filterByTimeDropdown}>
            <p>Latest</p>

            <div className={styles.dropdownArrow}>
              <img src="/assets/icon/filter-dropdown-arrow.svg" alt="dropdown-arrow" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
