import { useDyteClient, useDyteSelector } from '@dytesdk/react-web-core'
import React, { useEffect } from 'react'
import styles from '../StreamMainComponent/LiveStreamModel.module.scss'
import { useAppDispatch } from 'src/store/store'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useMutation } from '@apollo/client'
import LEAVE_STREAM from 'src/graphql/mutations/endLiveStream'
import sellerClient from 'src/utils/apolloSellerClient'

function StreamLeaveComponent({ meeting, uuid, setAddedProducts }) {
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const liveStreamState: any = useDyteSelector((m) => m?.livestream?.state)
  const currentDate = new Date();
  const currentDateTime = currentDate.toISOString().replace('T', ' ').replace(/\..+/, '') + '.' + ('00' + currentDate.getMilliseconds()).slice(-3) + ' ' + new Date().toString().match(/([+-][0-9]{4})/)[1];

  const handleLeaveStream = async () => {
    meeting.livestream.stop()
    meeting.leave()

    leaveStream({
      variables: {
        uuid: uuid ? uuid : getAllState?.lastModalData?.data?.uuid,
        time: currentDateTime,
      },
    })
  }

  const [leaveStream] = useMutation(LEAVE_STREAM, {
    client: sellerClient,
    onCompleted: (res) => {

      setTimeout(() => {
        setAddedProducts([])
        dispatch(
          fetchModalData({
            allModalData: [
              ...getAllState?.allModalData,
              {
                modal: 'liveStream',
                isOpen: false,
                modalFor: 'bigModal',
                startDate: moment().toISOString(),
              },
            ],
            lastModalData: getAllState?.lastModalData,
          }),
        )
      }, 1500)
    },
    onError: (err) => { },
  })

  const handleGoLiveStream = async () => {
    await meeting.livestream.start()
    await meeting.recording.stop()
  }

  return (
    meeting?.self.presetName === 'livestream_host' &&
    (liveStreamState === 'IDLE' ? (
      <div className={styles.endLiveButtuon} onClick={() => handleGoLiveStream()}>
        <svg width="25px" height="25px" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m40.59 49.24c-1.28 0-33.08 24.32-33.08 76 0 53.23 30.89 81.28 32.64 81.49s16.43-13.58 16.43-14.68-26.95-20.59-26.95-65.28 26.73-61.33 26.73-62.85c0-.92-12.26-14.68-15.77-14.68z"
            fill="#e83a2a"
          />
          <path
            d="m74.77 78.6c-1.55 0-21.69 12.71-22.13 46.88-.45 35.05 19.72 52.36 21 52.58s14.24-13.36 14.24-14.46-15.48-11.84-14.68-35.49c.88-25.85 14.9-34.61 14.9-35.71s-11.36-13.8-13.33-13.8z"
            fill="#e83a2a"
          />
          <path
            d="m215.41 49.24c1.28 0 33.08 24.32 33.08 76 0 53.23-30.89 81.28-32.64 81.49s-16.43-13.58-16.43-14.68 26.95-20.59 26.95-65.28-26.73-61.36-26.73-62.87c0-.9 12.26-14.66 15.77-14.66z"
            fill="#e83a2a"
          />
          <path
            d="m181.23 78.6c1.55 0 21.69 12.71 22.13 46.88.45 35.05-19.72 52.36-21 52.58s-14.24-13.36-14.24-14.46 15.48-11.84 14.68-35.49c-.88-25.85-14.9-34.61-14.9-35.71s11.36-13.8 13.33-13.8z"
            fill="#e83a2a"
          />
          <path
            d="m151.07 128.11c0 15.17-10.17 28.47-26.79 27.47-14.77-.89-26.79-12.3-26.79-27.47s10.76-27.07 26.79-27.47c15.83-.39 26.79 12.3 26.79 27.47z"
            fill="#e83a2a"
          />
        </svg>
        <p>Go Live</p>
      </div>
    ) : (
      <div className={styles.endLiveButtuon} onClick={() => handleLeaveStream()}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <rect x="4.16455" y="4.1665" width="11.6667" height="11.6667" rx="3" fill="#E71616" />
        </svg>

        <p>End Live</p>
      </div>
    ))
  )
}

export default StreamLeaveComponent
