import React from 'react'
import CopyIcon from '../../../../public/assets/icon/copy.svg'
import GridIcon from '../../../../public/assets/icon/grid.svg'
import UserIcon from '../../../../public/assets/icon/users.svg'
import UserPlusIcon from '../../../../public/assets/icon/user-plus.svg'
import ReportIcon from '../../../../public/assets/icon/reports.svg'
import LogoutIcon from '../../../../public/assets/icon/log-out.svg'
import LEAVE_GROUP from 'src/graphql/mutations/leaveGroup'
import GETGROUP from 'src/graphql/queries/getGroup'
import { notification } from 'antd'
import { useMutation } from '@apollo/client'
import { useAuth } from 'src/utils/auth'
import { useSelector } from 'react-redux'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAppDispatch } from 'src/store/store'

export default function GroupMobileViewModal() {
  const { user } = useAuth()
  const dispatch = useAppDispatch()

  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)

  const handleClose = () => {
    let datass = fetchMobileModal?.allModalData?.filter(
      (item) => item?.modal !== fetchMobileModal?.lastModalData?.modal,
    )
    dispatch(
      fetchMobileModalData({
        allModalData: datass,
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
  }

  const [leaveGroup] = useMutation(LEAVE_GROUP, {
    onCompleted: (res) => {
      notification['success']({
        message: ' Left successfully',
        description: 'You have been left successfully',
      })
      handleClose()
    },
    onError: (err) => {
      console.log('err', err)
      notification['error']({
        message: 'Error',
        description: 'Something went wrong',
      })
    },
  })

  return (
    <div className="mobile-view-clubModal-details-alignment">
      <div className="mobile-view-clubModal-details-nav-alignment">
        <div className="mobile-view-clubModal-icon-alignment">
          <img src={CopyIcon.src} alt="CopyIcon" />
        </div>
        <div className="mobile-view-clubModal-option-name">
          <h6>Copy Link</h6>
          <span>Copy link to share</span>
        </div>
      </div>
      <div className="mobile-view-clubModal-details-nav-alignment">
        <div className="mobile-view-clubModal-icon-alignment">
          <img src={GridIcon.src} alt="GridIcon" />
        </div>
        <div className="mobile-view-clubModal-option-name">
          <h6>Share on my feed</h6>
          <span>Share this club on my feed</span>
        </div>
      </div>
      <div className="mobile-view-clubModal-details-nav-alignment">
        <div className="mobile-view-clubModal-icon-alignment">
          <img src={UserIcon.src} alt="UserIcon" />
        </div>
        <div className="mobile-view-clubModal-option-name">
          <h6>Share with Friends</h6>
          <span>Share this club with friends</span>
        </div>
      </div>
      <div className="mobile-view-clubModal-details-nav-alignment">
        <div className="mobile-view-clubModal-icon-alignment">
          <img src={UserPlusIcon.src} alt="UserPlusIcon" />
        </div>
        <div className="mobile-view-clubModal-option-name">
          <h6>Invite</h6>
          <span>Invite people to join this group</span>
        </div>
      </div>
      <div className="mobile-view-clubModal-details-nav-alignment">
        <div className="mobile-view-clubModal-icon-alignment">
          <img src={ReportIcon.src} alt="ReportIcon" />
        </div>
        <div className="mobile-view-clubModal-option-name">
          <h6>Report</h6>
          <span>Report this club to Bluejestic</span>
        </div>
      </div>
      <div
        className="mobile-view-clubModal-details-nav-alignment"
        onClick={() =>
          leaveGroup({
            variables: {
              userId: user.id,
              groupId: fetchMobileModal?.lastModalData?.id?.id,
            },
            refetchQueries: [{ query: GETGROUP, variables: { id: parseInt(fetchMobileModal?.lastModalData?.id?.id) } }],
          })
        }
      >
        <div className="mobile-view-clubModal-icon-alignment">
          <img src={LogoutIcon.src} alt="LogoutIcon" />
        </div>
        <div className="mobile-view-clubModal-option-name">
          <h6 className="logout">Leave Club</h6>
        </div>
      </div>
    </div>
  )
}
