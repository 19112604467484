export default function PartyIcon() {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clip-path="url(#clip0_607_121)">
                <path d="M0.931178 15.4087C1.21493 15.7162 2.38368 15.1799 3.30618 14.7499C3.99743 14.4287 6.76368 13.2937 8.13868 12.7049C8.50993 12.5462 9.04993 12.3387 9.43868 11.8274C9.78368 11.3724 10.6987 9.43994 8.85618 7.48244C6.98618 5.49494 5.05993 6.04369 4.33993 6.55369C3.91618 6.85369 3.57118 7.52994 3.41618 7.87369C2.76118 9.32619 1.82743 11.9874 1.44743 13.0337C1.16868 13.8049 0.649928 15.1037 0.931178 15.4087Z" fill="#FFC107" />
                <path d="M3.23119 8.31128C3.24869 8.52878 3.29244 8.88253 3.44244 9.56378C3.54494 10.0313 3.71244 10.5213 3.84869 10.845C4.25744 11.8188 4.83119 12.2113 5.41244 12.5263C6.39994 13.0613 7.07119 13.1613 7.07119 13.1613L6.26619 13.49C6.26619 13.49 5.77869 13.3888 5.11369 13.0613C4.47994 12.7488 3.81994 12.22 3.33744 11.185C3.12869 10.7363 3.00744 10.3013 2.93744 9.95628C2.85119 9.52878 2.83744 9.28628 2.83744 9.28628L3.23119 8.31128ZM2.24244 10.8463C2.24244 10.8463 2.34244 11.6575 3.01244 12.6813C3.79744 13.8788 4.89369 14.075 4.89369 14.075L4.16494 14.375C4.16494 14.375 3.35119 14.1263 2.57744 13.07C2.09494 12.4113 1.95994 11.6238 1.95994 11.6238L2.24244 10.8463ZM1.54869 12.7688C1.54869 12.7688 1.73119 13.4688 2.13119 13.9913C2.60744 14.615 3.21369 14.7963 3.21369 14.7963L2.65494 15.0438C2.65494 15.0438 2.23119 14.955 1.76744 14.3675C1.41494 13.9213 1.31494 13.4088 1.31494 13.4088L1.54869 12.7688Z" fill="#FF8F00" />
                <path opacity="0.44" d="M1.24491 14.5463C1.21991 14.49 1.21991 14.4263 1.24616 14.3713L4.42991 7.7688L4.95367 9.73755L1.60366 14.5763C1.51366 14.7113 1.31116 14.6938 1.24491 14.5463Z" fill="#FFFDE7" />
                <path d="M5.20619 10.3986C6.69369 12.1386 8.38744 11.9211 8.95119 11.4811C9.51619 11.0399 9.96244 9.52364 8.48119 7.81239C6.92869 6.01989 5.17119 6.53114 4.76494 6.91864C4.35869 7.30614 3.84119 8.80239 5.20619 10.3986Z" fill="url(#paint0_linear_607_121)" />
                <path d="M10.3149 11.115C9.77243 10.66 9.48368 10.7412 9.09618 10.9025C8.59618 11.11 7.80993 11.2637 6.74243 10.9025L7.06368 10.1287C7.69743 10.3425 8.15618 10.2387 8.55243 10.005C9.06243 9.70497 9.75993 9.29372 10.8449 10.205C11.2974 10.585 11.7612 10.8375 12.1012 10.7225C12.3487 10.64 12.4799 10.2712 12.5462 9.97747C12.5524 9.95122 12.5624 9.87622 12.5699 9.80997C12.6299 9.35122 12.7299 8.36122 13.4674 7.85497C14.2562 7.31372 15.0849 7.31372 15.0849 7.31372L15.2349 8.80372C14.8537 8.74747 14.5887 8.82497 14.3649 8.94872C13.5224 9.41747 14.2562 11.2175 12.9449 11.8225C11.6837 12.4087 10.6524 11.3975 10.3149 11.115Z" fill="#03A9F4" />
                <path d="M5.67491 9.215L5.13241 8.72875C6.12866 7.61625 5.86616 6.79875 5.67491 6.20375C5.63616 6.08375 5.59991 5.97 5.57616 5.86125C5.49116 5.47625 5.47366 5.14125 5.49991 4.84875C5.11741 4.3725 4.94866 3.87375 4.93741 3.84C4.70491 3.13625 4.87991 2.45 5.28116 1.80625C6.09241 0.5 7.56116 0.5 7.56116 0.5L8.05116 1.81125C7.67866 1.79625 6.45741 1.815 6.08241 2.40625C5.60866 3.15125 5.91991 3.61125 5.94241 3.66375C6.03366 3.545 6.12616 3.45 6.20866 3.37625C6.80741 2.845 7.32741 2.76875 7.65866 2.79875C8.03116 2.8325 8.36866 3.02 8.60991 3.3275C8.87366 3.665 8.98241 4.10375 8.89866 4.5025C8.81741 4.89125 8.55866 5.22 8.16991 5.42875C7.49116 5.79375 6.92616 5.74375 6.54741 5.6175C6.54991 5.62625 6.55116 5.63625 6.55366 5.645C6.56741 5.7075 6.59491 5.795 6.62741 5.89625C6.84866 6.58125 7.25991 7.66875 5.67491 9.215ZM6.59366 4.52375C6.66616 4.57625 6.74241 4.62 6.82116 4.65125C7.08366 4.75625 7.36991 4.72125 7.69491 4.54625C7.88616 4.44375 7.90866 4.33375 7.91616 4.2975C7.93866 4.18875 7.90116 4.05 7.81991 3.94625C7.74866 3.855 7.66616 3.8075 7.56741 3.7975C7.37991 3.78125 7.12616 3.9 6.87241 4.12625C6.75116 4.235 6.65866 4.36875 6.59366 4.52375Z" fill="#F44336" />
                <path d="M7.84632 9.41867L7.07007 9.39742C7.07007 9.39742 7.43882 7.31492 8.63257 6.96492C8.85632 6.89992 9.10132 6.83367 9.34757 6.79742C9.49382 6.77492 9.72507 6.74117 9.83882 6.69867C9.86507 6.50242 9.78257 6.25242 9.69007 5.96867C9.61757 5.74867 9.54257 5.52242 9.50257 5.27492C9.42507 4.79242 9.55382 4.36617 9.86507 4.07242C10.2451 3.71617 10.8588 3.60242 11.5513 3.75992C11.9463 3.84992 12.2376 4.04367 12.4938 4.21367C12.8601 4.45742 13.0738 4.58117 13.5213 4.27992C14.0626 3.91492 13.3551 2.48617 12.9788 1.66117L14.3826 1.07617C14.5713 1.48867 15.4826 3.61117 14.8813 4.82242C14.6788 5.22992 14.3301 5.49992 13.8726 5.60117C12.8776 5.82367 12.2951 5.43617 11.8701 5.15367C11.6688 5.01992 11.4926 4.91492 11.3013 4.85992C9.97257 4.48117 11.8276 6.43617 10.9588 7.31492C10.4376 7.84117 9.16382 7.97992 9.08132 7.99992C8.26132 8.19742 7.84632 9.41867 7.84632 9.41867Z" fill="#F48FB1" />
                <path d="M5.49885 4.84863C5.4751 5.12363 5.46385 5.28738 5.5351 5.64488C5.87885 5.89738 6.6276 5.89738 6.6276 5.89738C6.5951 5.79613 6.56635 5.70863 6.55385 5.64613C6.55135 5.63738 6.5501 5.62738 6.5476 5.61863C5.78635 5.23863 5.49885 4.84863 5.49885 4.84863Z" fill="#C92B27" />
                <path d="M3.94118 6.08011L2.64868 5.44636L3.29243 4.51636L4.30618 5.18761L3.94118 6.08011Z" fill="#FFC107" />
                <path d="M2.03613 4.32507C1.37613 4.23632 0.703633 3.67632 0.629883 3.61257L1.27863 2.85132C1.47488 3.01757 1.89113 3.29632 2.16988 3.33382L2.03613 4.32507Z" fill="#FB8C00" />
                <path d="M3.20122 2.65887L2.25122 2.34762C2.35997 2.01512 2.38872 1.65637 2.33247 1.31012L3.31997 1.15137C3.40122 1.65387 3.35997 2.17512 3.20122 2.65887Z" fill="#03A9F4" />
                <path d="M9.13403 1.91565L10.1109 1.7019L10.393 2.9914L9.41616 3.20515L9.13403 1.91565Z" fill="#FB8C00" />
                <path d="M11.5576 2.22114L10.8701 1.49489C11.2301 1.15364 11.3126 0.707393 11.3126 0.702393L12.3001 0.863643C12.2876 0.942393 12.1614 1.64989 11.5576 2.22114Z" fill="#FFC107" />
                <path d="M11.9392 6.07256L12.8126 5.79956L13.1108 6.75406L12.2375 7.02706L11.9392 6.07256Z" fill="#FB8C00" />
                <path d="M12.1938 14.1288L11.2 14.0113C11.2425 13.6576 10.9787 13.2238 10.9062 13.1276L11.7063 12.5276C11.7663 12.6063 12.2875 13.3276 12.1938 14.1288Z" fill="#F44336" />
                <path d="M15.0462 12.8612C14.6724 12.805 14.2899 12.7825 13.9124 12.7962L13.8787 11.7962C14.3174 11.7812 14.7612 11.8062 15.1949 11.8725L15.0462 12.8612Z" fill="#FB8C00" />
                <path d="M13.7017 14.2377L14.4042 13.5261L15.371 14.4809L14.6685 15.1924L13.7017 14.2377Z" fill="#F48FB1" />
                <path d="M11.6379 7.91675L12.3604 8.74287L11.5343 9.46537L10.8118 8.63925L11.6379 7.91675Z" fill="#F44336" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_607_121" x1="9.29794" y1="7.72977" x2="5.57707" y2="9.96227" gradientUnits="userSpaceOnUse">
                    <stop offset="0.024" stop-color="#8F4700" />
                    <stop offset="1" stop-color="#703E2D" />
                </linearGradient>
                <clipPath id="clip0_607_121">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}