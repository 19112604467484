import styles from "./UpcomingStreams.module.scss";
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import moment from 'moment'
import { StreamReactionButton } from "../StreamReactionButton";

const UpcomingStreamsCard = ({ item, isHost }: { item: any, isHost?: boolean }) => {
    const dispatch = useAppDispatch()

    const getAllState = useSelector((state: any) => state?.manageModalData?.data)
    const handleLiveStream = (data) => {
        dispatch(
            fetchModalData({
                allModalData: [
                    ...getAllState?.allModalData,
                    {
                        modal: 'liveStream',
                        isOpen: true,
                        modalFor: 'bigModal',
                        data: {
                            uuid: data?.uuid,
                            isHost: !!isHost,
                            isStartStream: !isHost,
                            hostName: data?.title,
                            finalTime: data?.final_date,
                        },
                        startDate: moment().toISOString(),
                    },
                ],
                lastModalData: getAllState?.lastModalData,
            }),
        )
    }
    return (
        <div
            className={styles.SellerSocialActivityEventUpcomingLiveStremDetailsMainAlignment}
            onClick={() => handleLiveStream(item)}
        >
            <div className={styles.SellerSocialActivityEventUpcomingLiveStremDetailsBoxAlignment}>
                <img src={item?.media ? item?.media : '/assets/img/channel-demo-img.png'} alt="channel img" />

                <div className={styles.SellerSocialActivityEventUpcomingDateNotificationAlignment}>
                    <div className={styles.dateBoxAlignment}>
                        <span>{moment(item?.final_date).format('MMM')}</span>

                        <div className={styles.dateChildBoxAlignment}>
                            <p>{moment(item?.final_date).format('D')}</p>
                        </div>
                    </div>
                </div>

                <div className={styles.SellerSocialActivityEventUpcomingBottomDetailsAlignment}>
                    <div className="flex items-center justify-between">
                        <h6>{item?.title}</h6>
                    </div>

                    <div className={styles.SellerSocialActivityEventUpcomingTimeDateAlignment}>
                        <div>
                            <p>{moment(item?.final_date).format('MMM D, YYYY')}</p>
                            <p>{moment(item?.time, 'HH:mm').format('h:mm A')}</p>
                        </div>
                        <div className="mt-"><StreamReactionButton item={item} /></div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default UpcomingStreamsCard;
