import { useState, useEffect } from 'react'

interface User {
  store?: {
    id: string
  }
}

export function useAuth() {
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    // Mock user data for development
    setUser({
      store: {
        id: '1'
      }
    })
  }, [])

  return { user }
}
