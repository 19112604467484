import { Upload, X } from 'lucide-react'
import React, { useState } from 'react'
import SellerCountryComponent from './SellerCountryComponent'

export default function SellerCompanyTab({ companyInfo, setInputValue, handleNext, errors, setErrors }) {
  const [countryIds, setCountryIds] = useState({
    countryId: null,
    stateId: null,
    cityId: null,
    state: null,
  })

  const [storeBanner, setStoreBanner] = useState<string | null>(null)

  const handleInfoChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setInputValue((prev) => ({
      ...prev,
      [field]: e.target.value,
    }))
    setErrors((prev) => ({
      ...prev,
      [field]: '',
    }))
  }

  const handleLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setInputValue((prev) => ({ ...prev, logo:file, logo_image: reader.result as string }))
        setErrors((prev) => ({ ...prev, logo_image: '' }))
      }
      reader.readAsDataURL(file)
    }
  }

  const handleBannerUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setInputValue((prev) => ({ ...prev, banner:file, banner_image: reader.result as string }))
        setErrors((prev) => ({ ...prev, banner_image: '' }))
      }
      reader.readAsDataURL(file)
    }
  }

  const handleFormSubmit = async (e) => {
    e?.preventDefault()
    // const mutationVariables = {
    //   city: countryIds?.cityId,
    //   country: countryIds?.countryId,
    //   state: countryIds?.stateId,
    // }
    // setInputValue((prev) => ({ ...prev, ...mutationVariables }))
    handleNext()
  }

  return (
    <div className="max-w-full mx-auto space-y-8">
      {/* Store Branding */}
      <div className="bg-white rounded-xl p-8 shadow-sm border border-gray-100">
        <h2 className="text-xl font-semibold text-gray-800 mb-6">Store Branding</h2>

        {/* Logo Upload */}
        <div className="mb-8">
          <label className="block text-base font-medium text-gray-700 mb-4">
            Store Logo <span className="text-red-600">*</span>{' '}
            {errors.logo_image && <span className="text-sm text-red-600">{errors.logo_image}</span>}
          </label>
          <div className="flex items-center gap-8">
            {companyInfo?.logo_image ? (
              <div className="relative">
                <img
                  src={companyInfo?.logo_image}
                  alt="Store Logo"
                  className="w-32 h-32 object-cover rounded-lg shadow-sm"
                  style={{ width: '128px', height: '128px' }}
                />
                <button
                  onClick={() => setInputValue((prev) => ({ ...prev, logo_image: null }))} // setStoreLogo(null)
                  className="absolute -top-2 -right-2 p-1.5 bg-white rounded-full shadow-md text-gray-500 hover:text-gray-700 transition-colors"
                >
                  <X size={16} />
                </button>
              </div>
            ) : (
              <label
                className="w-32 h-32 flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-indigo-500 transition-colors"
                style={{ width: '128px', height: '128px' }}
              >
                <Upload className="text-gray-400 mb-2" size={24} />
                <span className="text-sm text-gray-500">Upload Logo</span>
                <input type="file" className="hidden" accept="image/*" onChange={handleLogoUpload} />
              </label>
            )}
            <div className="text-sm text-gray-600">
              <p className="font-medium mb-2">Logo Requirements:</p>
              <ul className="list-disc list-inside space-y-1">
                <li>Square format recommended</li>
                <li>Minimum size: 400x400 pixels</li>
                <li>Maximum size: 2MB</li>
              </ul>
            </div>
          </div>
        </div>

        <div>
          <label className="block text-base font-medium text-gray-700 mb-4">
            Store Banner <span className="text-red-600">*</span>{' '}
            {errors.banner_image && <span className="text-sm text-red-600">{errors.banner_image}</span>}
          </label>
          <div className="flex items-center gap-8">
            {companyInfo?.banner_image ? (
              <div className="relative">
                <img
                  src={companyInfo?.banner_image}
                  alt="Store Banner"
                  className="w-64 h-32 object-cover rounded-lg shadow-sm"
                  style={{ width: '256px', height: '128px' }}
                />
                <button
                  onClick={() => setInputValue((prev) => ({ ...prev, banner_image: null }))} // setStoreBanner(null)
                  className="absolute -top-2 -right-2 p-1.5 bg-white rounded-full shadow-md text-gray-500 hover:text-gray-700 transition-colors"
                >
                  <X size={16} />
                </button>
              </div>
            ) : (
              <label
                className="w-64 h-32 flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-indigo-500 transition-colors"
                style={{ width: '256px', height: '128px' }}
              >
                <Upload className="text-gray-400 mb-2" size={24} />
                <span className="text-sm text-gray-500">Upload Banner</span>
                <input type="file" className="hidden" accept="image/*" onChange={handleBannerUpload} />
              </label>
            )}
            <div className="text-sm text-gray-600">
              <p className="font-medium mb-2">Banner Requirements:</p>
              <ul className="list-disc list-inside space-y-1">
                <li>Recommended size: 1200x600 pixels</li>
                <li>Maximum size: 5MB</li>
                <li>16:9 aspect ratio recommended</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl p-8 shadow-sm border border-gray-100">
        <h2 className="text-xl font-semibold text-gray-800 mb-6">Company Information</h2>

        <div className="space-y-6">
          <div>
            <label className="block text-base font-medium text-gray-700 mb-2">
              Store Name {' '}
            </label>
            <input
              type="text"
              disabled
              value={companyInfo?.store_name}
              onChange={handleInfoChange('store_name')}
              className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent cursor-not-allowed "
              placeholder="Enter your store name"
            />
          </div>

          <div>
            <label className="block text-base font-medium text-gray-700 mb-2">
              Company Name <span className="text-red-600">*</span>{' '}
              {errors.companyLegalName && <span className="text-sm text-red-600">{errors.companyLegalName}</span>}
            </label>
            <input
              type="text"
              value={companyInfo?.companyLegalName}
              onChange={handleInfoChange('companyLegalName')}
              className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
              placeholder="Enter your company name"
            />
          </div>

          <div>
            <label className="block text-base font-medium text-gray-700 mb-2">
              Business Email <span className="text-red-600">*</span>{' '}
              {errors.business_email && <span className="text-sm text-red-600">{errors.business_email}</span>}
            </label>
            <input
              type="email"
              value={companyInfo?.business_email}
              onChange={handleInfoChange('business_email')}
              className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
              placeholder="Enter business email"
            />
          </div>

          <div>
            <label className="block text-base font-medium text-gray-700 mb-2">
              Business Phone <span className="text-red-600">*</span>{' '}
              {errors?.business_phone_number && (
                <span className="text-sm text-red-600">{errors?.business_phone_number}</span>
              )}
            </label>
            <input
              type="tel"
              value={companyInfo?.business_phone_number}
              onChange={handleInfoChange('business_phone_number')}
              className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
              placeholder="Enter business phone"
            />
          </div>

          <div>
            <label className="block text-base font-medium text-gray-700 mb-2">
              Business Address<span className="text-red-600">*</span>{' '}
              {errors?.business_address && <span className="text-sm text-red-600">{errors?.business_address}</span>}
            </label>
            <input
              type="text"
              value={companyInfo?.business_address}
              onChange={handleInfoChange('business_address')}
              className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent mb-4"
              placeholder="Street address"
            />
            {/* <div className="grid grid-cols-2 gap-4">
              <input
                type="text"
                value={companyInfo.city}
                onChange={handleInfoChange('city')}
                className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                placeholder="City"
              />
              <input
                type="text"
                value={companyInfo.state}
                onChange={handleInfoChange('state')}
                className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                placeholder="State/Province"
              />
            </div> */}
            <div className=" gap-4 mt-4">
              <label className="block text-base font-medium text-gray-700 mb-2">
                Zip Code<span className="text-red-600">*</span>{' '}
                {errors?.business_zip_code && <span className="text-sm text-red-600">{errors?.business_zip_code}</span>}
              </label>
              <input
                type="number"
                value={companyInfo?.business_zip_code}
                onChange={handleInfoChange('business_zip_code')}
                className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                placeholder="ZIP/Postal code"
              />
              {/* <select
                value={companyInfo.country}
                onChange={handleInfoChange('country')}
                className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
              >
                <option value="">Select CountryComponent</option>
                <option value="US">United States</option>
                <option value="CA">Canada</option>
                <option value="GB">United Kingdom</option>
              </select> */}
            </div>
            <SellerCountryComponent
              setErrors={setErrors}
              errors={errors}
              countryIds={companyInfo}
              setCountryIds={setInputValue}
            />
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <button
          className="px-8 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
          onClick={handleFormSubmit}
        >
          {'Save Changes'}
        </button>
      </div>
    </div>
  )
}
