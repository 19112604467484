import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useRef, useState } from 'react'
import Loader from 'src/components/UI/Loader'
import { useStateContext } from 'src/utils/state'
import SendMessage from './SendMessage'
import {
  GET_ALL_USERS,
  GET_GROUPS,
  GET_GROUP_INVITED_USER,
  GET_GROUP_MSGS,
  GET_PRIVATE_MSGS,
} from 'src/graphql/queries/queries'
import { formatToYesterDay, sameDay } from 'src/utils/helperFuncs'
import MessageBubble from './MessageBubble'
import { useAuth } from 'src/utils/auth'
import WelcomeMessage from '../WelcomeMessage'
import client1 from 'src/utils/apolloClient'
import {
  ACCEPT_GROUP_REQUEST,
  EDIT_GROUP_NAME,
  EDIT_PRIVATE_MSG,
  LEAVE_GROUP,
  MUTE_UNMUTE_USER,
  REACT_ON_MESSAGE,
  READ_ALL_MSG,
  REMOVE_GROUP_MSG,
  REMOVE_PRIVATE_MSG,
} from 'src/graphql/mutations/mutations'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import { Modal } from 'antd'
import MessageMediaModel from './MessageMediaModel'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import MobileMessageSelected from '../MobileMessageSelected'
import useOnClickOutside from 'src/components/Hook/useOnClickOutSide'
import socketClient, { MESSAGE_EVENTS } from 'src/utils/socketClient'
import { Bell, BellOff } from 'lucide-react'
import { followUser, likePost } from 'src/utils/notificationManager'
import moment from 'moment'

const MessageChatBox = (props) => {
  const dispatch = useAppDispatch()
  const messagesEndRef = useRef(null)
  const productRef: any = useRef()
  const { user } = useAuth()
  const { selectedChat, notify, updateName, selectChat } = useStateContext()
  const [MessageOptionDropdown, setMessageOptionDropdown] = useState(false)
  const [MediaModal, setMediaModal] = useState(false)
  const [messages, setMessages] = useState(null)
  const [isGroupRename, setIsGroupRename] = useState(false)
  const [muteModal, setMuteModal] = useState(false)
  const [groupNameEdit, setGroupNameEdit] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const [messageOption, setMessageOption] = useState(false)
  const [messageBody, setMessageBody] = useState('')
  const [selectedEditMessage, setSelectedEditMessage] = useState(undefined)
  const [removeMessage, setRemoveMessage] = useState(undefined)
  const [typing, setTyping] = useState(undefined)

  // const mediaModalRef = useRef()
  // useOnClickOutside(mediaModalRef, () => setMediaModal(false))
  // const getChatMedia = useQuery(GET_CHAT_MEDIA, {
  //   client: apolloClientMessage,
  //   variables: {
  //     conversationId: selectedChat?.chatData?.latestMessage?.conversationId,
  //   },
  // })

  // useEffect(() => {
  //   getChatMedia.refetch()
  // }, [])

  const isGroupGlobalChat =
    selectedChat?.chatType === 'public' || selectedChat?.chatType === 'group' || selectedChat?.chatType === 'invite'

  const [fetchPrivateMsgs, { data, loading: loadingPrivate }] = useLazyQuery(
    GET_PRIVATE_MSGS,
    {
      client: client1,
      onError: (err) => {
        // alert(err)
      },
    },
  )

  const [fetchGroupMsgs, { data: groupData, loading: loadingGroup }] = useLazyQuery(
    GET_GROUP_MSGS,
    {
      client: client1,
      onError: (err) => {
        alert(err)
      },
    },
  )

  const [readAllMessages] = useMutation(READ_ALL_MSG, {
    client: client1,
    onError: (err) => {
      alert(err)
    },
  })

  const [leaveGroupMutation] = useMutation(LEAVE_GROUP, {
    client: client1,
    onCompleted() {
      handleDeleteCancel()
    },
    onError: (err) => {
      alert(err)
    },
  })

  const [updateGroupName, { loading: updatingName }] = useMutation(EDIT_GROUP_NAME, {
    client: client1,
    onError: (err) => {
      alert(err)
    },
  })

  const [acceptGroupRequest, { loading: acceptGroupRequestLoading }] = useMutation(ACCEPT_GROUP_REQUEST, {
    client: client1,
    onError: (err) => {
      console.log(err)
    },
  })


  useEffect(() => {
    socketClient.connect();

    socketClient.on(MESSAGE_EVENTS.NEW_MESSAGE, (incomingMessage) => {
      const newMessage = incomingMessage?.newMessage
      let getMsgQuery, getMsgVariables, getMsgQueryName, getLastMsgQuery, getLastMsgQueryName, lastMsgTargetId
      if (newMessage?.type === 'private') {
        const otherUserId = newMessage.participants.filter((p) => p !== user.id)[0]
        getMsgQuery = GET_PRIVATE_MSGS
        getMsgVariables = { userId: otherUserId }
        getMsgQueryName = 'getPrivateMessages'
        getLastMsgQuery = GET_ALL_USERS
        getLastMsgQueryName = 'getAllUsers'
        lastMsgTargetId = otherUserId
      } else if (newMessage?.type === 'group') {
        const groupConversationId = newMessage?.message?.conversationId
        getMsgQuery = GET_GROUP_MSGS
        getMsgVariables = { conversationId: groupConversationId }
        getMsgQueryName = 'getGroupMessages'
        getLastMsgQuery = GET_GROUPS
        getLastMsgQueryName = 'getGroups'
        lastMsgTargetId = groupConversationId
      }

      if (
        selectedChat &&
        newMessage.type === selectedChat.chatType &&
        (newMessage.type === 'public' || lastMsgTargetId === selectedChat.chatData.id)
      ) {
        readAllMessages({
          variables: {
            chatType: selectedChat.chatType,
            conversationId: lastMsgTargetId,
          },
        })
      }
      const conversationCache = client1.readQuery({
        query: getMsgQuery,
        variables: getMsgVariables,
      })
      if (conversationCache) {
        let formattedNewMessage = {};
        if (newMessage?.type === 'group') {
          formattedNewMessage = {
            id: newMessage?.message?.id,
            body: newMessage?.message?.body,
            conversationId: newMessage?.message?.conversationId,
            createdAt: newMessage?.message?.createdAt,
            isSeen: newMessage?.message?.isSeen || false,
            mutedParticipants: newMessage?.message?.mutedParticipants || [],
            reactions: newMessage?.message?.reactions || [],
            replyMessage: newMessage?.message?.replyMessage || null,
            senderId: newMessage?.message?.senderId,
            user: {
              id: newMessage?.message?.user?.id,
              firstName: newMessage?.message?.user?.firstName || "",
              lastName: newMessage?.message?.user?.lastName || "",
              profileAvtar: newMessage?.message?.user?.profileAvtar || ""
            }
          };
        } else {
          formattedNewMessage = {
            id: newMessage?.message?.id,
            body: newMessage?.message?.body,
            conversationId: newMessage?.message?.conversationId,
            createdAt: newMessage?.message?.createdAt,
            images: newMessage?.message?.images || [],
            isEdited: newMessage?.message?.isEdited || null,
            isSeen: newMessage?.message?.isSeen || false,
            reactions: newMessage?.message?.reactions || [],
            replyMessage: newMessage?.message?.replyMessage || null,
            senderId: newMessage?.message?.senderId,
            shared_content: newMessage?.message?.shared_content || null,
            user: {
              id: newMessage?.message?.user?.id,
              firstName: newMessage?.message?.user?.firstName || "",
              lastName: newMessage?.message?.user?.lastName || "",
              profileAvtar: newMessage?.message?.user?.profileAvtar || ""
            }
          };
        }

        client1.cache.modify({
          fields: {
            [getMsgQueryName](existingMessages) {
              return [...existingMessages, formattedNewMessage];
            }
          }
        });
      }
      const lastMsgCache = client1.readQuery({
        query: getLastMsgQuery,
      })

      if (lastMsgCache) {
        const updatedLastMsgCache =
          newMessage.type === 'public'
            ? {
              ...lastMsgCache[getLastMsgQueryName],
              latestMessage: newMessage.message,
            }
            : lastMsgCache[getLastMsgQueryName].map((l) => {
              const incomingMessageData = newMessage.message;

              if (l.id === lastMsgTargetId) {
                const updatedLatestMessage = {
                  ...l.latestMessage,
                  body: incomingMessageData?.body,
                  conversationId: incomingMessageData?.conversationId,
                  id: incomingMessageData?.id,
                  isEdited: incomingMessageData?.isEdited,
                  senderId: incomingMessageData?.senderId,
                  updatedAt: moment(incomingMessageData?.updatedAt).valueOf(),
                  user: {
                    ...l.latestMessage?.user,
                    id: incomingMessageData?.user?.id,
                    firstName: incomingMessageData?.user?.firstName,
                    lastName: incomingMessageData?.user?.lastName,
                    profileAvtar: incomingMessageData?.user?.profileAvtar || ""
                  }
                }
                return { ...l, latestMessage: updatedLatestMessage };
              }
              return l;
            })

        client1.writeQuery({
          query: getLastMsgQuery,
          data: {
            [getLastMsgQueryName]: updatedLastMsgCache,
          },
        })
      }
    })

    socketClient.on(MESSAGE_EVENTS.MESSAGE_EDITED, (incomingMessage) => {
      const newMessage = incomingMessage?.editMessage

      let getMsgQuery, getMsgVariables, getMsgQueryName, getLastMsgQuery, getLastMsgQueryName, lastMsgTargetId

      if (newMessage?.type === 'private') {
        const otherUserId = newMessage.participants.filter((p) => p !== user.id)[0]

        getMsgQuery = GET_PRIVATE_MSGS
        getMsgVariables = { userId: otherUserId }
        getMsgQueryName = 'getPrivateMessages'
        getLastMsgQuery = GET_ALL_USERS
        getLastMsgQueryName = 'getAllUsers'
        lastMsgTargetId = otherUserId
      } else if (newMessage.type === 'group') {
        const groupConversationId = newMessage.message.conversationId

        getMsgQuery = GET_GROUP_MSGS
        getMsgVariables = { conversationId: groupConversationId }
        getMsgQueryName = 'getGroupMessages'
        getLastMsgQuery = GET_GROUPS
        getLastMsgQueryName = 'getGroups'
        lastMsgTargetId = groupConversationId
      }

      if (
        selectedChat &&
        newMessage.type === selectedChat.chatType &&
        (newMessage.type === 'public' || lastMsgTargetId === selectedChat.chatData.id)
      ) {
        readAllMessages({
          variables: {
            chatType: selectedChat.chatType,
            conversationId: lastMsgTargetId,
          },
        })
      }
      const conversationCache = client1.readQuery({
        query: getMsgQuery,
        variables: getMsgVariables,
      })
      if (conversationCache) {
        const updatedConvoCache = conversationCache[getMsgQueryName]?.map((msg) => {
          if (msg?.id === newMessage?.message?.id) {
            return { ...msg, body: newMessage?.message?.body, isEdited: newMessage?.message?.isEdited }
          } else {
            return msg
          }
        })
        client1.writeQuery({
          query: getMsgQuery,
          variables: getMsgVariables,
          data: {
            [getMsgQueryName]: updatedConvoCache,
          },
        })
      }
    })

    socketClient.on(MESSAGE_EVENTS.MESSAGE_REACTION, (incomingMessage) => {
      const newMessage = incomingMessage?.reactMessage
      let getMsgQuery, getMsgVariables, getMsgQueryName, getLastMsgQuery, getLastMsgQueryName, lastMsgTargetId

      if (newMessage?.type === 'private') {
        const otherUserId = newMessage?.participants?.filter((p) => p !== user.id)[0]

        getMsgQuery = GET_PRIVATE_MSGS
        getMsgVariables = { userId: otherUserId }
        getMsgQueryName = 'getPrivateMessages'
        getLastMsgQuery = GET_ALL_USERS
        getLastMsgQueryName = 'getAllUsers'
        lastMsgTargetId = otherUserId
      } else if (newMessage?.type === 'group') {
        const groupConversationId = newMessage?.message?.conversationId

        getMsgQuery = GET_GROUP_MSGS
        getMsgVariables = { conversationId: groupConversationId }
        getMsgQueryName = 'getGroupMessages'
        getLastMsgQuery = GET_GROUPS
        getLastMsgQueryName = 'getGroups'
        lastMsgTargetId = groupConversationId
      }

      if (
        selectedChat &&
        newMessage.type === selectedChat.chatType &&
        (newMessage.type === 'public' || lastMsgTargetId === selectedChat.chatData.id)
      ) {
        readAllMessages({
          variables: {
            chatType: selectedChat.chatType,
            conversationId: lastMsgTargetId,
          },
        })
      }
      const conversationCache = client1.readQuery({
        query: getMsgQuery,
        variables: getMsgVariables,
      })

      if (conversationCache) {
        const updatedConvoCache = conversationCache[getMsgQueryName]?.map((msg) => {
          if (msg?.id === newMessage?.message?.id) {
            return { ...msg, reactions: newMessage.message.reactions, isReactionRemove: newMessage.isReactionRemove }
          } else {
            return msg
          }
        })

        let dummyData = client1.writeQuery({
          query: getMsgQuery,
          variables: getMsgVariables,
          data: {
            [getMsgQueryName]: updatedConvoCache,
          },
        })
      }
    })

    socketClient.on(MESSAGE_EVENTS.MESSAGE_READ, (incomingMessage) => {
      const messageRead = incomingMessage?.messageRead
      if (messageRead.readerId === user?.id) return
      let getMsgQuery, getMsgVariables, getMsgQueryName, getLastMsgQuery, getLastMsgQueryName, lastMsgTargetId

      if (messageRead.type === 'private') {
        const otherUserId = messageRead.participants.filter((p) => p !== user.id)[0]
        getMsgQuery = GET_PRIVATE_MSGS
        getMsgVariables = { userId: otherUserId }
        getMsgQueryName = 'getPrivateMessages'
        getLastMsgQuery = GET_ALL_USERS
        getLastMsgQueryName = 'getAllUsers'
        lastMsgTargetId = otherUserId
      } else if (messageRead.type === 'group') {
        const groupConversationId = messageRead.conversationId
        getMsgQuery = GET_GROUP_MSGS
        getMsgVariables = { conversationId: groupConversationId }
        getMsgQueryName = 'getGroupMessages'
        getLastMsgQuery = GET_GROUPS
        getLastMsgQueryName = 'getGroups'
        lastMsgTargetId = groupConversationId
      }
      // else if (messageRead.type === 'public') {
      //   getMsgQuery = GET_GLOBAL_MSGS
      //   getMsgVariables = null
      //   getMsgQueryName = 'getGlobalMessages'
      //   getLastMsgQuery = GET_GLOBAL_GROUP
      //   getLastMsgQueryName = 'getGlobalGroup'
      //   lastMsgTargetId = 0
      // }

      if (
        selectedChat &&
        messageRead.type === selectedChat.chatType &&
        (messageRead.type === 'public' || lastMsgTargetId === selectedChat.chatData.id)
      ) {
        const conversationCache = client1.readQuery({
          query: getMsgQuery,
          variables: getMsgVariables,
        })

        if (conversationCache) {
          const updatedConvoCache = conversationCache[getMsgQueryName].map((conv) => {
            if (conv.senderId === user.id) {
              return {
                ...conv,
                isSeen: true,
              }
            }
            return conv
          })

          client1.writeQuery({
            query: getMsgQuery,
            variables: getMsgVariables,
            data: {
              [getMsgQueryName]: updatedConvoCache,
            },
          })
        }

        const lastMsgCache = client1.readQuery({
          query: getLastMsgQuery,
        })

        if (lastMsgCache) {
          let updatedLastMsgCache = null
          if (messageRead.type === 'public') {
            if (
              lastMsgCache[getLastMsgQueryName] &&
              lastMsgCache[getLastMsgQueryName].latestMessage &&
              lastMsgCache[getLastMsgQueryName].latestMessage.senderId !== user.id
            ) {
              updatedLastMsgCache = {
                ...lastMsgCache[getLastMsgQueryName],
                latestMessage: {
                  ...lastMsgCache[getLastMsgQueryName].latestMessage,
                  isSeen: true,
                },
              }
            }
          } else {
            if (lastMsgCache[getLastMsgQueryName]) {
              updatedLastMsgCache = lastMsgCache[getLastMsgQueryName].map((l) => {
                if (l.id === lastMsgTargetId && l.latestMessage && l.latestMessage.senderId !== user.id) {
                  return {
                    ...l,
                    latestMessage: {
                      ...l.latestMessage,
                      isSeen: true,
                    },
                  }
                }
                return l
              })
            }
          }
          if (updatedLastMsgCache) {
            client1.writeQuery({
              query: getLastMsgQuery,
              data: {
                [getLastMsgQueryName]: updatedLastMsgCache,
              },
            })
          }
        }
      }

    })

    socketClient.on(MESSAGE_EVENTS.REMOVE_PRIVATE_MESSAGE, (incomingMessage) => {
      const newMessage = incomingMessage?.removePrivateMessage

      let getMsgQuery, getMsgVariables, getMsgQueryName, getLastMsgQuery, getLastMsgQueryName, lastMsgTargetId

      if (newMessage?.type === 'private') {
        const otherUserId = newMessage.participants.filter((p) => p !== user.id)[0]

        getMsgQuery = GET_PRIVATE_MSGS
        getMsgVariables = { userId: otherUserId }
        getMsgQueryName = 'getPrivateMessages'
        getLastMsgQuery = GET_ALL_USERS
        getLastMsgQueryName = 'getAllUsers'
        lastMsgTargetId = otherUserId
      } else if (newMessage.type === 'group') {
        const groupConversationId = newMessage.conversationId

        getMsgQuery = GET_GROUP_MSGS
        getMsgVariables = { conversationId: groupConversationId }
        getMsgQueryName = 'getGroupMessages'
        getLastMsgQuery = GET_GROUPS
        getLastMsgQueryName = 'getGroups'
        lastMsgTargetId = groupConversationId
      }

      if (
        selectedChat &&
        newMessage.type === selectedChat.chatType &&
        (newMessage.type === 'public' || lastMsgTargetId === selectedChat.chatData.id)
      ) {
        readAllMessages({
          variables: {
            chatType: selectedChat.chatType,
            conversationId: lastMsgTargetId,
          },
        })
      }

      const conversationCache = client1.readQuery({
        query: getMsgQuery,
        variables: getMsgVariables,
      })
      if (conversationCache) {
        const updatedConvoCache = conversationCache[getMsgQueryName]?.filter((msg) => msg?.id !== newMessage?.message?.id)
        try {
          client1.writeQuery({
            query: getMsgQuery,
            variables: getMsgVariables,
            data: {
              [getMsgQueryName]: updatedConvoCache,
            },
          })
        } catch (error) {
          console.log(error)
        }
      }
      setRemoveMessage(undefined)
    })

    socketClient.on(MESSAGE_EVENTS.EDIT_GROUP_MESSAGE, (incomingMessage) => {
      const newMessage = incomingMessage?.editGroupMessage

      let getMsgQuery, getMsgVariables, getMsgQueryName, getLastMsgQuery, getLastMsgQueryName, lastMsgTargetId

      if (newMessage?.type === 'private') {
        const otherUserId = newMessage.participants.filter((p) => p !== user.id)[0]

        getMsgQuery = GET_PRIVATE_MSGS
        getMsgVariables = { userId: otherUserId }
        getMsgQueryName = 'getPrivateMessages'
        getLastMsgQuery = GET_ALL_USERS
        getLastMsgQueryName = 'getAllUsers'
        lastMsgTargetId = otherUserId
      } else if (newMessage.type === 'group') {
        const groupConversationId = newMessage.message.conversationId

        getMsgQuery = GET_GROUP_MSGS
        getMsgVariables = { conversationId: groupConversationId }
        getMsgQueryName = 'getGroupMessages'
        getLastMsgQuery = GET_GROUPS
        getLastMsgQueryName = 'getGroups'
        lastMsgTargetId = groupConversationId
      }

      if (
        selectedChat &&
        newMessage.type === selectedChat.chatType &&
        (newMessage.type === 'public' || lastMsgTargetId === selectedChat.chatData.id)
      ) {
        readAllMessages({
          variables: {
            chatType: selectedChat.chatType,
            conversationId: lastMsgTargetId,
          },
        })
      }
      const conversationCache = client1.readQuery({
        query: getMsgQuery,
        variables: getMsgVariables,
      })
      if (conversationCache) {
        const updatedConvoCache = conversationCache[getMsgQueryName]?.map((msg) => {
          if (msg?.id === newMessage?.message?.id) {
            return { ...msg, body: newMessage?.message?.body, isEdited: newMessage?.message?.isEdited }
          } else {
            return msg
          }
        })

        client1.writeQuery({
          query: getMsgQuery,
          variables: getMsgVariables,
          data: {
            [getMsgQueryName]: updatedConvoCache,
          },
        })
      }
    })

    socketClient.on(MESSAGE_EVENTS.LEAVE_GROUP, (incomingMessage) => {
      const newMessage = incomingMessage?.leaveGroup
      let getMsgQuery, getMsgVariables, getMsgQueryName, getLastMsgQuery, getLastMsgQueryName
      getMsgQuery = GET_GROUPS
      let conversationCache = client1.readQuery({
        query: getMsgQuery,

      })

      let usersData = conversationCache?.getGroups?.map((grp) => {
        if (grp?.id === newMessage?.id) {
          return { ...newMessage }
        } else {
          return grp
        }
      })

      client1.writeQuery({
        query: GET_GROUPS,
        data: {
          getGroups: usersData,
        },
      })

      if (selectedChat?.chatType === 'group' && selectedChat?.chatData?.id === newMessage?.id) {
        selectChat(newMessage, 'group')
      }

    })

    socketClient.on(MESSAGE_EVENTS.ACCEPTED_GROUP, (incomingMessage) => {
      const newMessage = incomingMessage?.acceptGroup
      let getMsgQuery, getMsgVariables, getMsgQueryName, getLastMsgQuery, getLastMsgQueryName
      getMsgQuery = GET_GROUPS
      let conversationCache = client1.readQuery({
        query: getMsgQuery,

      })

      let usersData = conversationCache?.getGroups?.map((grp) => {
        if (grp?.id === newMessage?.id) {
          return { ...newMessage }
        } else {
          return grp
        }
      })

      client1.writeQuery({
        query: GET_GROUPS,
        data: {
          getGroups: usersData,
        },
      })

      if (selectedChat?.chatType === 'group' && selectedChat?.chatData?.id === newMessage?.id) {
        selectChat(newMessage, 'group')
      }
    })

    socketClient.on(MESSAGE_EVENTS.MESSAGE_START_TYPING, (incomingMessage) => {
      const typingData = incomingMessage?.typing;
      if (
        selectedChat?.chatType === typingData?.type && user?.id !== typingData?.typerId &&
        (typingData.type === 'public' || selectedChat?.chatData?.id === typingData?.conversationId)
      ) {
        setTyping(typingData?.type === 'private' ? 'Typing...' : typingData?.description);
      }

    });

    socketClient.on(MESSAGE_EVENTS.MESSAGE_STOP_TYPING, (incomingMessage) => {

      const typingData = incomingMessage?.typing;
      if (
        selectedChat?.chatType === typingData?.type &&
        (typingData.type === 'public' || selectedChat?.chatData?.id === typingData?.conversationId)
      ) {
        setTyping('');
      }

    });

    return () => {
      Object.values(MESSAGE_EVENTS).forEach(event => {
        socketClient.off(event)
      })
      socketClient.disconnect();
    }
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (MessageOptionDropdown && productRef.current && !productRef.current.contains(e.target)) {
        setMessageOptionDropdown(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [MessageOptionDropdown])
  const handleScroll = () => {
    if (MessageOptionDropdown && productRef.current) {
      setMessageOptionDropdown(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)
    return () => {
      window.removeEventListener('scroll', handleScroll, true)
    }
  }, [MessageOptionDropdown])

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView()
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])
  useEffect(() => {
    if (!selectedChat) return;

    if (selectedChat?.chatType === 'private') {
      fetchPrivateMsgs({
        variables: { userId: selectedChat.chatData.id },
      })
      readAllMessages({
        variables: {
          chatType: 'private',
          conversationId: selectedChat.chatData.id,
        },
      })
    } else if (selectedChat.chatType === 'group') {
      fetchGroupMsgs({
        variables: { conversationId: selectedChat.chatData.id },
      })
      readAllMessages({
        variables: {
          chatType: 'group',
          conversationId: selectedChat.chatData.id,
        },
      })
    }
  }, [selectedChat])

  useEffect(() => {
    if (!selectedChat) return
    if (data && selectedChat.chatType === 'private') {
      setMessages(data.getPrivateMessages)
    } else if (groupData && selectedChat.chatType === 'group') {
      setMessages(groupData.getGroupMessages)
    }
  }, [data, selectedChat, groupData])

  const handleAccept = (status) => {
    acceptGroupRequest({
      variables: { groupId: selectedChat.chatData.id, status: status },
      refetchQueries: [{ query: GET_GROUP_INVITED_USER }],
      update: (proxy, { data }) => {
        if (data?.acceptGroupRequest?.status === 'accepted') {
          const returnedData = data?.acceptGroupRequest
          const dataInCache: any = proxy.readQuery({
            query: GET_GROUPS,
          })
          proxy.writeQuery({
            query: GET_GROUPS,
            data: { getGroups: [...dataInCache.getGroups, returnedData] },
          })
          if (selectedChat?.chatData?.id === data?.acceptGroupRequest?.id) {
            selectChat(returnedData, 'group')
          }
          setIsGroupRename(false)
          notify('Group name updated!')
        }
        if (data?.acceptGroupRequest?.status === 'rejected') {
          const dataInCache: any = proxy.readQuery({
            query: GET_GROUPS,
          })
          if (dataInCache?.getGroups?.length > 0) {
            selectChat(dataInCache?.getGroups[0], 'group')
          } else {
            selectChat(null, null)
          }
        }
      },
    })
  }

  const [removePrivateMessage] = useMutation(REMOVE_PRIVATE_MSG, {
    client: client1,
    onError: (err) => {
      alert(err)
    },
  })

  const [removeGroupMessage] = useMutation(REMOVE_GROUP_MSG, {
    client: client1,
    onCompleted: (res) => {

      setRemoveMessage(undefined);
    },
    onError: (err) => {
      alert(err)
    },
  })

  const [muteUnmuteUser] = useMutation(MUTE_UNMUTE_USER, {
    client: client1,
    onCompleted: (res) => {
      const mutedParticipants = res?.muteUnmuteUser?.mutedParticipants
      let mergedData = { ...selectedChat?.chatData, mutedParticipants }
      selectChat(mergedData, selectedChat?.chatType)
      setMuteModal(false)
    },
    onError: (err) => {
      alert(err)
    },
  })

  const handleRemove = async (msg) => {
    setRemoveMessage(msg)
  }

  const dataToDisplay = () => {
    if (selectedChat?.chatType === 'invite') {
      return (
        <div className="centerMessageClass">
          <div className="" style={{ margin: 0 }}>
            <div className="no-chat-detaild-box-alignment">
              <div>
                <div className="no-invites-text-heading">
                  <p>
                    <span>
                      {selectedChat?.chatData.groupInvitedUser?.firstName +
                        ' ' +
                        selectedChat?.chatData.groupInvitedUser?.lastName}
                    </span>{' '}
                    invites you to :
                  </p>
                  {/* [...group?.adminUser, ...group?.users] */}
                </div>
                {selectedChat.chatType === 'invite' && (
                  <div className="group-message-profile-img-box-alignment-sidebar">
                    {[...Array(3)]?.map((_, index) => {
                      return (
                        <div className={`group-message-flex-alignment-${index}`} key={index}>
                          <div className={`group-message-profile-img-alignment-${index}`}>
                            <img
                              src={
                                [...selectedChat?.chatData?.adminUser, ...selectedChat?.chatData?.users] &&
                                  [...selectedChat?.chatData?.adminUser, ...selectedChat?.chatData?.users][index]
                                  ? [...selectedChat?.chatData?.adminUser, ...selectedChat?.chatData?.users] &&
                                  [...selectedChat?.chatData?.adminUser, ...selectedChat?.chatData?.users][index]
                                    ?.profileAvtar
                                  : '/assets/img/dummy-group-user.svg '
                              }
                              alt="group profile"
                            />
                          </div>
                        </div>
                      )
                    })}
                    <div className="group-message-first-dot-alignment">
                      <div className="first-dot-width-alignment"></div>
                    </div>
                    <div className="group-message-second-dot-alignment">
                      <div className="second-dot-width-alignment"></div>
                    </div>
                    <div className="group-message-third-dot-alignment">
                      <div className="third-dot-width-alignment"></div>
                    </div>
                  </div>
                )}
                <h5>
                  {selectedChat.chatType === 'private'
                    ? selectedChat.chatData.firstName + ' ' + selectedChat.chatData.lastName
                    : selectedChat?.chatData?.name}
                </h5>
                <div className="images-icon-and-text-alignment">
                  <div className="image-group">
                    {[...selectedChat?.chatData?.adminUser, ...selectedChat?.chatData?.users]
                      ?.slice(0, 3)
                      ?.map((us, index) => {
                        return <img src={us?.profileAvtar} key={index} />
                      })}
                  </div>
                  <span>
                    {[...selectedChat?.chatData?.adminUser, ...selectedChat?.chatData?.users]?.length === 2
                      ? [...selectedChat?.chatData?.adminUser, ...selectedChat?.chatData?.users] &&
                      [...selectedChat?.chatData?.adminUser, ...selectedChat?.chatData?.users][1]?.firstName
                      : [...selectedChat?.chatData?.adminUser, ...selectedChat?.chatData?.users] &&
                      [...selectedChat?.chatData?.adminUser, ...selectedChat?.chatData?.users][2]?.firstName}
                    {[...selectedChat?.chatData?.adminUser, ...selectedChat?.chatData?.users]?.length > 3 &&
                      ', and' + ' '}
                    {[...selectedChat?.chatData?.adminUser, ...selectedChat?.chatData?.users]?.length === 2
                      ? ''
                      : [...selectedChat?.chatData?.adminUser, ...selectedChat?.chatData?.users]?.length -
                      3 +
                      ' ' +
                      'other'}{' '}
                    joined this chat
                  </span>
                </div>
                <div className="invite-button-style">
                  <button onClick={() => handleAccept('accepted')}>Accept invitation</button>
                </div>
                <div className="decline-text-alignment">
                  <span onClick={() => handleAccept('rejected')}>Decline</span>
                </div>

                {selectedChat.chatType === 'private' && (
                  <React.Fragment>
                    <div className="location-details-alignment">
                      <div>
                        <img src={selectedChat?.chatData?.profileAvtar} alt="location icon" />
                      </div>
                    </div>
                    <div
                      className="visit-profile-button-alignment"
                      onClick={() => {
                        dispatch(
                          fetchModalData({
                            allModalData: [
                              ...getAllState?.allModalData,
                              {
                                modal: 'userDetail',
                                isOpen: true,
                                slug: selectedChat?.chatData?.userName,
                                modalFor: 'bigModal',
                              },
                            ],
                            lastModalData: getAllState?.lastModalData,
                          }),
                        )
                      }}
                    >
                      {/* <Link href={`/user/${selectedChat?.user?.userName}`}> */}
                      <button>Visit Profile</button>
                      {/* </Link> */}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      if (loadingGroup || !messages) {
        return (
          <div className="centerMessageClass">
            <Loader />
          </div>
        )
      } else if (messages.length === 0) {
        return (
          <div className="centerMessageClass">
            <div className="" style={{ margin: 0 }}>
              <div className="no-chat-detaild-box-alignment">
                <div>
                  {selectedChat.chatType === 'group' ? (
                    <div className="group-message-profile-img-box-alignment-sidebar">
                      {[...Array(3)]?.map((_, index) => {
                        return (
                          <div className={`group-message-flex-alignment-${index}`} key={index}>
                            <div className={`group-message-profile-img-alignment-${index}`}>
                              <img
                                src={
                                  [...selectedChat?.chatData?.users, ...selectedChat?.chatData?.adminUser] &&
                                    [...selectedChat?.chatData?.users, ...selectedChat?.chatData?.adminUser][index]
                                    ? [...selectedChat?.chatData?.users, ...selectedChat?.chatData?.adminUser] &&
                                    [...selectedChat?.chatData?.users, ...selectedChat?.chatData?.adminUser][index]
                                      ?.profileAvtar
                                    : '/assets/img/dummy-group-user.svg '
                                }
                                alt="group profile"
                              />
                            </div>
                          </div>
                        )
                      })}
                      <div className="group-message-first-dot-alignment">
                        <div className="first-dot-width-alignment"></div>
                      </div>
                      <div className="group-message-second-dot-alignment">
                        <div className="second-dot-width-alignment"></div>
                      </div>
                      <div className="group-message-third-dot-alignment">
                        <div className="third-dot-width-alignment"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="no-chat-profile-img">
                      <img src={selectedChat?.chatData?.profileAvtar?.[0]} alt="dasdsadas" />
                    </div>
                  )}
                  <h5>
                    {selectedChat.chatType === 'private'
                      ? selectedChat.chatData.firstName + ' ' + selectedChat.chatData.lastName
                      : selectedChat?.chatData?.name}
                  </h5>
                  {selectedChat.chatType === 'private' && (
                    <React.Fragment>
                      {selectedChat?.chatData?.city && <div className="location-details-alignment">
                        <div>
                          <img src="/assets/icon/map-pin-grey.svg" alt="location icon" />
                        </div>
                        <p>{selectedChat?.chatData?.city}</p>
                      </div>}
                      <div
                        className="visit-profile-button-alignment"
                        onClick={() => {
                          dispatch(
                            fetchModalData({
                              allModalData: [
                                ...getAllState?.allModalData,
                                {
                                  modal: 'userDetail',
                                  isOpen: true,
                                  slug: selectedChat?.chatData?.userName,
                                  modalFor: 'bigModal',
                                },
                              ],
                              lastModalData: getAllState?.lastModalData,
                            }),
                          )
                        }}
                      >
                        <button>Visit Profile</button>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <React.Fragment>
            {messages?.map((message, index) => {
              const isSameDay = index !== 0 ? sameDay(messages[index - 1].createdAt, message.createdAt) : false
              const isSameUser = index !== 0 && isSameDay && messages[index - 1].senderId === message.senderId
              return (
                <div
                  key={index}
                // onMouseEnter={() => setMessageOption(false)}
                >
                  {!isSameDay && (
                    <div className={'dateInfoWrapper'}>
                      <div className="infoText">{formatToYesterDay(message.createdAt)}</div>
                    </div>
                  )}
                  {isGroupGlobalChat && !isSameUser && user.id !== message?.senderId && message?.user?.username}
                  <div className={isSameUser ? 'msgMarginSameUser' : 'msgMarginDiffUser'}>
                    <MessageBubble
                      message={message}
                      isGroupGlobalChat={isGroupGlobalChat}
                      isSameUser={isSameUser}
                      handleEdit={handleEdit}
                      handleRemove={handleRemove}
                      sendReaction={sendReaction}
                    // setMessageOption={setMessageOption}
                    // messageOption={messageOption}
                    />
                  </div>
                </div>
              )
            })}
            {messages[messages?.length - 1]?.senderId === user?.id && messages[messages?.length - 1]?.isSeen && (
              <div className="relod-alignment">
                <span>Read</span>
              </div>
            )}
            <div ref={messagesEndRef} />
          </React.Fragment>
        )
      }
    }
  }


  const handleEdit = (message) => {
    setSelectedEditMessage(message)
    setMessageBody(message?.body)
  }

  const [reactMessageMutation, { loading: reactMessageLoading }] = useMutation(REACT_ON_MESSAGE, {
    client: client1,
    onCompleted: (res) => {
      // setSelectedEditMessage(undefined)
    },
    onError: (err) => {
      alert(err)
    },
  })


  const sendReaction = async (message, emoji_id) => {
    await reactMessageMutation({
      variables: {
        receiverId: selectedChat.chatData.id,
        messageId: message?.id,
        emojiId: emoji_id,
        chatType: selectedChat.chatType
      },
      update: () => {
        clearInput()
      },
    })
  }

  const handleRename = () => {
    setIsGroupRename(true)
    setMessageOptionDropdown(false)
  }

  const handleEditName = () => {
    updateGroupName({
      variables: { conversationId: selectedChat.chatData.id, name: groupNameEdit },
      update: (proxy, { data }) => {
        const returnedData = data.editGroupName
        const dataInCache: any = proxy.readQuery({
          query: GET_GROUPS,
        })

        const updatedGroups = dataInCache.getGroups.map((g) =>
          g.id === returnedData.groupId ? { ...g, name: returnedData.name } : g,
        )

        proxy.writeQuery({
          query: GET_GROUPS,
          data: { getGroups: updatedGroups },
        })

        if (selectedChat.chatData.id === returnedData.groupId) {
          updateName(returnedData)
        }
        setIsGroupRename(false)
        notify('Group name updated!')
      },
    })
  }

  const handleLeave = () => {
    setIsModalOpen(true)
  }

  const handleDeleteCancel = () => {
    setIsModalOpen(false)
  }

  const deletePostHandler = () => {
    leaveGroupMutation({
      variables: {
        conversationId: selectedChat?.chatData?.id,
      },
      update: (proxy, { data }) => {
        // if (data?.acceptGroupRequest?.status === 'accepted') {
        const returnedData = data?.acceptGroupRequest
        const dataInCache: any = proxy.readQuery({
          query: GET_GROUPS,
        })


        let mergedData = dataInCache?.getGroups?.filter((grp) => grp?.id !== selectedChat?.chatData?.id)
        proxy.writeQuery({
          query: GET_GROUPS,
          data: { getGroups: mergedData },
        })
        if (mergedData?.length > 0) {
          selectChat(mergedData[0], 'group')
        } else {
          selectChat(null)
        }
        // updateName(returnedData)
      },
    })
  }

  const [editPrivateMsg, { loading }] = useMutation(EDIT_PRIVATE_MSG, {
    client: client1,
    onError: (err) => {
      alert(err)
    },
  })

  const clearInput = () => {
    setMessageBody('')
    // setUploadedImage([])
    let ReflectedData = { ...selectedChat?.chatData }
    delete ReflectedData?.replyMessageId
    selectChat(ReflectedData, selectedChat?.chatType)
  }

  const handleEditSubmit = async (e) => {
    e.preventDefault()
    // if (messageBody.trim() === '' || !selectedChat) return

    if (selectedChat.chatType === 'private') {
      let ImageId = []
      if (selectedChat.chatData?.replyMessageId) {
        // if (uploadedImage.length > 0) {
        //   const UploadFile = new FormData()
        //   uploadedImage.forEach((file) => {
        //     UploadFile.append('image', file)
        //   })
        //   UploadFile.append('media_for', 'product')
        //   const files = await ApiPost(UploadImageRoute, UploadFile)
        //     .then((res: any) => {
        //       res?.data?.image?.map((image) => {
        //         ImageId.push(image.media)
        //       })
        //     })
        //     .catch((err) => {
        //       return err
        //     })
        // }
        await editPrivateMsg({
          variables: {
            receiverId: selectedChat.chatData.id,
            body: messageBody,
            replyMessageId: selectedChat?.chatData?.replyMessageId,
            images: ImageId?.length > 0 ? ImageId : [],
          },
          update: () => {
            clearInput()
          },
        })
      } else {
        if (selectedEditMessage) {
          await editPrivateMsg({
            variables: {
              receiverId: selectedChat.chatData.id,
              body: messageBody,
              images: ImageId?.length > 0 ? ImageId : [],
            },
            update: () => {
              clearInput()
            },
          })
        } else {
        }
      }
    } else if (selectedChat.chatType === 'group') {
      if (selectedChat.chatData?.replyMessageId) {
        // submitGroupMsg({
        //   variables: {
        //     conversationId: selectedChat.chatData.id,
        //     body: messageBody,
        //     replyMessageId: selectedChat.chatData?.replyMessageId,
        //   },
        //   update: () => {
        //     clearInput()
        //   },
        // })
      } else {
        // submitGroupMsg({
        //   variables: {
        //     conversationId: selectedChat.chatData.id,
        //     body: messageBody,
        //   },
        //   update: () => {
        //     clearInput()
        //   },
        // })
      }
    }
  }

  const deleteMessageHandler = async () => {
    if (selectedChat?.chatType === 'group') {
      await removeGroupMessage({
        variables: {
          removeGroupMessageId: removeMessage?.id,
          conversationId: selectedChat.chatData.id,
        },
        update: () => {
          clearInput()
        },
      })
    } else {
      await removePrivateMessage({
        variables: {
          removePrivateMessageId: removeMessage?.id,
          receiverId: selectedChat.chatData.id,
        },
        update: () => {
          clearInput()
        },
      })
    }
  }

  const handleMessageDeleteCancel = () => {
    setRemoveMessage(undefined)
  }

  if (!selectedChat || (selectedChat.chatData == null && selectedChat.chatType == null)) return <WelcomeMessage />

  const isMutedUser = selectedChat?.chatData?.mutedParticipants?.find((participantId) => participantId === user?.id);

  const handleMute = (): void => {
    setMuteModal(true);
  }

  const muteUserHandler = async () => {
    await muteUnmuteUser({
      variables: {
        conversationId: selectedChat?.chatData?.latestMessage?.conversationId,
        mute: !isMutedUser
      },
      update: () => {
        setMuteModal(false);

      },
    })
  }

  const handleMuteCancel = (): void => {
    setMuteModal(false);
    setMessageOptionDropdown(false);
  }



  return (

    <div className="message-chat-box-section">
      <div className="message-cha-box-alignment">
        {selectedChat?.chatType !== 'invite' && (
          <div className="message-heading-alignment">
            {isGroupGlobalChat ? (
              !isGroupRename && (
                <div className="group-message-heading-profile-flex-alignment">
                  <div className="group-message-heading-img-box-alignment">
                    {[...Array(3)]?.map((_, index) => {
                      return (
                        <div className={`group-message-flex-alignment-${index}`} key={index}>
                          <div className={`group-message-profile-img-alignment-${index}`}>
                            <img
                              src={
                                [...selectedChat?.chatData?.users, ...selectedChat?.chatData?.adminUser] &&
                                  [...selectedChat?.chatData?.users, ...selectedChat?.chatData?.adminUser][index]
                                  ? [...selectedChat?.chatData?.users, ...selectedChat?.chatData?.adminUser] &&
                                  [...selectedChat?.chatData?.users, ...selectedChat?.chatData?.adminUser][index]
                                    ?.profileAvtar
                                  : '/assets/img/dummy-group-user.svg '
                              }
                              alt="group profile"
                            />
                          </div>
                        </div>
                      )
                    })}
                    <div className="group-message-first-dot-alignment">
                      <div className="first-dot-width-alignment">
                        <img src="/assets/img/gdot1.png" alt="group profile" />
                      </div>
                    </div>
                    <div className="group-message-second-dot-alignment">
                      <div className="second-dot-width-alignment">
                        <img src="/assets/img/gdot2.png" alt="group profile" />
                      </div>
                    </div>
                    <div className="group-message-third-dot-alignment">
                      <div className="third-dot-width-alignment">
                        <img src="/assets/img/gdot3.png" alt="group profile" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <h4 className='mb-0'>{selectedChat?.chatData?.name}</h4>
                    <div className='typing-display-alignment'>{typing}</div>
                  </div>

                </div>

              )
            ) : (
              <div className="message-heading-profile-flex-alignment">
                <div className="message-heading-profile-img-alignment">
                  {selectedChat?.chatData?.logo_image && (
                    <img src={selectedChat?.chatData?.logo_image} alt="message img" />
                  )}
                </div>
                <div className="message-heading-details-alignment ">
                  <h4 className="mb-0">{selectedChat?.chatData?.firstName + ' ' + selectedChat?.chatData?.lastName}</h4>
                  <div className='typing-display-alignment'>{typing}</div>
                </div>
              </div>
            )}
            {!isGroupRename && (
              <div className="message-option-details-alignment">
                <div className="message-option-icon-alignment mobile-view-none-icon">
                  <img src="/assets/icon/message-search-icon.svg" alt="search icon" />
                </div>
                {/* <div className="open-serach-input-alignment">
                <input type="text" />
                <div className="close-input-alignment">
                  <img src="/assets/icon/close_icon.svg" alt="close icon" />
                </div>
              </div> */}
                <div
                  className="message-option-icon-alignment mobile-view-none-icon"
                  onClick={() => setMediaModal(!MediaModal)}
                >
                  {MediaModal ?
                    <div className="message-option-icon-alignment-active">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                        <path d="M20 4V9.81C19.12 9.3 18.1 9 17 9C13.69 9 11 11.69 11 15C11 15.34 11.04 15.67 11.09 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V2C0 0.89 0.89 0 2 0H8L10 2H18C18.5304 2 19.0391 2.21071 19.4142 2.58579C19.7893 2.96086 20 3.46957 20 4ZM15 18L20 15L15 12V18Z" fill="#3C4EA3" />
                      </svg>

                    </div>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                      <path d="M20 4V9.81C19.12 9.3 18.1 9 17 9C13.69 9 11 11.69 11 15C11 15.34 11.04 15.67 11.09 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V2C0 0.89 0.89 0 2 0H8L10 2H18C18.5304 2 19.0391 2.21071 19.4142 2.58579C19.7893 2.96086 20 3.46957 20 4ZM15 18L20 15L15 12V18Z" fill="#636773" />
                    </svg>

                  }
                </div>

                <div className="message-option-icon-alignment mobile-view-none-icon">
                  <div onClick={() => setMessageOptionDropdown(!MessageOptionDropdown)}>
                    <img src="/assets/icon/message-more-option.svg" alt="more option icon" />
                  </div>
                  {MessageOptionDropdown && (
                    <div className="message-option-dropdown-alignment" ref={productRef}>
                      {isGroupGlobalChat && (
                        <div className="message-box-option-name-alignment" onClick={handleRename}>
                          <img src="/assets/icon/rename-icon.svg" alt="mute" />
                          <span>Rename</span>
                        </div>
                      )}
                      <div className="message-box-option-name-alignment" onClick={handleMute}>
                        {isMutedUser ? <Bell /> : <BellOff />}
                        <span>{isMutedUser ? 'Unmute' : 'Mute'}</span>
                      </div>
                      {/* <div className="message-box-option-name-alignment">
                        <img src="/assets/icon/reports.svg" alt="report" />
                        <span>Report</span>
                      </div> */}
                      {!isGroupGlobalChat && (
                        <div className="message-box-option-name-alignment delete-message">
                          <img src="/assets/icon/trash.svg" alt="delete" />
                          <span>Delete Messages</span>
                        </div>
                      )}
                      {isGroupGlobalChat && (
                        <div className="message-box-option-name-alignment delete-message" onClick={handleLeave}>
                          <img src="/assets/icon/log-out-red.svg" alt="delete" />
                          <span>Leave {selectedChat?.chatData?.name}</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div
                  className="message-option-icon-alignment mobile-view-show-icon"
                  // onClick={() => setIsMoreOptionDropdown((prevstate) => !prevstate)}
                  onClick={() => {
                    dispatch(
                      fetchMobileModalData({
                        ...fetchMobileModal,
                        secondModal: {
                          modal: 'messageMoreOptionModal',
                          isOpen: true,
                          id: 0,
                          transform: 332,
                          zIndex: 99999999,
                          modalFor: 'mobileSecondModal',
                        },
                      }),
                    )
                  }}
                >
                  <div>
                    <img src="/assets/icon/message-more-option.svg" alt="more option icon" />
                  </div>
                </div>
                <div
                  className="message-option-icon-alignment mobile-view-show-icon"
                  onClick={() => {
                    let datass = fetchMobileModal?.allModalData?.filter(
                      (item) => item?.modal !== fetchMobileModal?.lastModalData?.modal,
                    )
                    dispatch(
                      fetchMobileModalData({
                        allModalData: datass,
                        lastModalData: fetchMobileModal?.lastModalData,
                      }),
                    )
                    // setIsOpenPersonalSelectedAccountModal((prevstate) => !prevstate)
                    // setIsSelectedMessageModal(false)
                    // setSelectedMessageEffect(null)
                  }}
                >
                  <div>
                    <img src="/assets/icon/m-close-icon.svg" alt="more option" />
                  </div>
                </div>
              </div>
            )}
            <div
              // ref={mediaModalRef}
              className={
                MediaModal
                  ? 'media-modal-show-area media-modal-alignment'
                  : 'media-modal-hidden-area media-modal-alignment'
              }
            >
              <MessageMediaModel MediaModal={MediaModal} />
            </div>
            {isGroupRename && (
              <>
                <div className="group-message-edit-header-profile-alignment">
                  <div className="group-message-edit-profile-alignment">
                    <div className="group-message-edit-profile-box-alignment">
                      {[...Array(3)]?.map((_, index) => {
                        return (
                          <div className={`group-message-flex-alignment-${index}`} key={index}>
                            <div className={`group-message-profile-img-alignment-${index}`}>
                              <img
                                src={
                                  [...selectedChat?.chatData?.users, ...selectedChat?.chatData?.adminUser] &&
                                    [...selectedChat?.chatData?.users, ...selectedChat?.chatData?.adminUser][index]
                                    ? [...selectedChat?.chatData?.users, ...selectedChat?.chatData?.adminUser] &&
                                    [...selectedChat?.chatData?.users, ...selectedChat?.chatData?.adminUser][index]
                                      ?.profileAvtar
                                    : '/assets/img/dummy-group-user.svg '
                                }
                                alt="group profile"
                              />
                            </div>
                          </div>
                        )
                      })}
                      <div className="group-message-first-dot-alignment">
                        <div className="first-dot-width-alignment">
                          <img src="/assets/img/gdot1.png" alt="group profile" />
                        </div>
                      </div>
                      <div className="group-message-second-dot-alignment">
                        <div className="second-dot-width-alignment">
                          <img src="/assets/img/gdot2.png" alt="group profile" />
                        </div>
                      </div>
                      <div className="group-message-third-dot-alignment">
                        <div className="third-dot-width-alignment">
                          <img src="/assets/img/gdot3.png" alt="group profile" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-name-edit-input-box-alignment">
                    <input
                      type="text"
                      placeholder="Fashion Lovers|"
                      onChange={(e) => setGroupNameEdit(e.target.value)}
                    />
                  </div>
                </div>
                <div className="group-message-edit-option-alignment">
                  <div className="cancle-button-alignment" onClick={() => setIsGroupRename(false)}>
                    <button>Cancel</button>
                  </div>
                  <div className="save-button-alignment" onClick={() => handleEditName()}>
                    <button>Save</button>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        <div
          className={`message-box-body-alignment group-message-body-alignment ${fetchMobileModal?.lastModalData?.id?.isMessageSelected && 'message-overflow-hidden'
            }`}
        >
          {dataToDisplay()}
        </div>
        {selectedChat?.chatType !== 'invite' && (
          <SendMessage
            setMessageBody={setMessageBody}
            messageBody={messageBody}
            selectedEditMessage={selectedEditMessage}
            handleEdit={handleEdit}
            handleEditSubmit={handleEditSubmit}
            setSelectedEditMessage={setSelectedEditMessage}
          />
        )}
      </div>
      <Modal
        title={isMutedUser ? 'Unmute User' : 'Mute User'}
        open={muteModal}
        onOk={() => muteUserHandler()}
        onCancel={() => handleMuteCancel()}
        wrapClassName="post-add-edit-modal"
        maskStyle={{ zIndex: 9999 }}
      >
        <p>Are you sure you want to {isMutedUser ? 'unmute' : 'mute'} the user?</p>
      </Modal>
      <Modal
        title="Exit Group"
        open={isModalOpen}
        onOk={() => deletePostHandler()}
        onCancel={() => handleDeleteCancel()}
        wrapClassName="post-add-edit-modal"
        maskStyle={{ zIndex: 9999 }}
      >
        <p>Are you sure you want to exit the group?</p>
      </Modal>

      <Modal
        title="Remove Message"
        open={removeMessage}
        onOk={() => deleteMessageHandler()}
        onCancel={() => handleMessageDeleteCancel()}
        wrapClassName="post-add-edit-modal"
        maskStyle={{ zIndex: 9999 }}
      >
        <p>Are you sure you want to delete the Message?</p>
      </Modal>
      <MobileMessageSelected handleEdit={handleEdit} handleRemove={handleRemove} sendReaction={sendReaction} />
    </div>
  )
}

export default MessageChatBox
