import React, { useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import { useAuth } from 'src/utils/auth'
import Avatar from 'src/components/shared/Avatar'
import { Spin } from 'antd'

const GroupPopOver = (props) => {
  const { name, slug, id, buttonHandler1, buttonHandler2 } = props

  const groupData = useQuery(
    gql`
    query GETGROUP($slug: String!) {
      group(slug: $slug) {
        id
        name
        description
        hashtags
        logo_image
        banner_image
        privacy
        user_id
        isExist
        subCategory_id: sub_Category {
          id
          name
        }
      }
    }
    `,
    { variables: { slug: slug } },
  )

  const groupMembers = useQuery(
    gql`
    query GetGroupMember($slug: String!) {
      getGroupMember(slug: $slug) {
        message
        success
        data {
          id
          firstName
          lastName
          logo_image
          banner_image
          isActiveForFriendStatus
          isFriendForFriendStatus
        }
      }
    }
    `,
    { variables: { slug: slug } },
  )

  useEffect(() => {
    groupData.refetch()
    groupMembers.refetch()
  }, [])

  let profile = groupData?.data?.group?.banner_image
  let profile_back = groupData?.data?.group?.logo_image
  let showDetail1 = []
  let showDetail2 =
  groupMembers?.data?.getGroupMember?.data?.length > 0
      ? groupMembers?.data?.getGroupMember?.data?.map((mem) => {
          return {
            user: {
              profileUrl: mem?.logo_image,
            },
          }
        })
      : []
  let isFollow = groupData?.data?.group?.isExist ? true : false
  let showDetailName1 = ''
  let showDetailName2 = 'Followers'
  let isGroup = true

  const { user } = useAuth()
  const shadowstyle = {
    boxShadow: '0px 5px 50px rgba(163, 156, 169, 0.15)',
  }

  const pouoDescription = {
    padding: '2px 28px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: ' #808191',
    height: '48px',
  }

  const followingTextStyle = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#808191',
  }

  let bio_detail = groupData?.data?.group?.description

  return (
    <React.Fragment>
      {groupData?.loading ? (
        <div className="flex ">
          <div className="m-auto spin-design-popover">
            <Spin tip="loading" />
          </div>
        </div>
      ) : (
        <div className="-m-8 relative" style={shadowstyle}>
          <img src={profile_back} className="rounded-t-xl h-36 relative z-10 object-cover" style={{ width: '330px' }} />
          <div className="relative w-300 rounded-b-xl -mt-1" style={{ background: '#ECEEF4', width: '330px' }}>
            <div className="absolute left-10 z-20 bg-white rounded-full" style={{ top: '-20px' }}>
              <Avatar
                radius={44}
                border={0}
                avatarlist={[
                  {
                    id: 1,
                    avatarURL: profile,
                  },
                ]}
              />
            </div>
            <div className="bg-white" style={{ height: '98px' }}>
              <h5
                className="text-sm text-black font-medium text-center -ml-7 hover:underline cursor-pointer"
                style={{ paddingTop: '4px', marginLeft: '-50px' }}
              >
                {name}
              </h5>
              <p className="popup-description" style={pouoDescription}>
                {bio_detail?.length > 75 ? bio_detail.substring(0, 75) + '...' : bio_detail}
              </p>
              <div className="flex justify-end items-end -mt-4" style={{ padding: '0 12px' }}>
                {isFollow ? (
                  <div>
                    <div onClick={() => buttonHandler2()}>
                      <button className="rounded-full bg-blue w-28 h-10 text-white text-xs mb-3">Following</button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div onClick={() => buttonHandler1()}>
                      <button className="rounded-full bg-blue w-28 h-10 text-white text-xs mb-3">Follow</button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="" style={{ padding: '0px 18px' }}>
              <div className="flex justify-between text-base items-center" style={{ height: '44px' }}>
                {!isGroup && (
                  <div className="flex items-center gap-2">
                    <div className="font-medium text-center">{showDetail1?.length}</div>
                    <span style={followingTextStyle}> {showDetailName1}</span>
                  </div>
                )}
                <div className="flex items-center gap-2">
                  <div className="font-medium text-center">{showDetail2?.length}</div>
                  <span style={followingTextStyle}> {showDetailName2}</span>
                </div>
                <div className="flex">
                  <Avatar
                    avatarlist={
                      showDetail2?.length > 0
                        ? showDetail2.slice(0, 5).map((prod) => {
                            return {
                              id: prod?.user?.id,
                              avatarURL: prod?.user?.profileUrl,
                            }
                          })
                        : []
                    }
                    radius={20}
                    border={2}
                  />
                  {showDetail2?.length > 5 && (
                    <div
                      className="rounded-full text-white text-8 -ml-4 pt-1 bg-blue border-2 border-white pb-2 pr-1"
                      style={{ width: 20, height: 20, zIndex: 999 }}
                    >
                      <span style={{ paddingLeft: '3px' }}>+{showDetail2?.length - 5}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default GroupPopOver
