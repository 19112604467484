import React, { useEffect } from 'react'
import styles from './SellerFollowers.module.scss'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/client'
import GET_ALL_STORE_FOLLOWERS from 'src/graphql/queries//getFollowes'
import { useAuth } from 'src/utils/auth'

export default function SellerFollowers() {
  const dispatch = useAppDispatch()
  const { user }: any = useAuth()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  const { loading, error, data, refetch } = useQuery(GET_ALL_STORE_FOLLOWERS, {
    variables: { getAllStoreFollowersId: user?.store?.id },
  })

  useEffect(() => {
    refetch()
  }, [])

  const handleOpenStoreModal = () => {
    dispatch(
      fetchModalData({
        allModalData: [
          ...getAllState?.allModalData,
          { modal: 'sellerFollowersDetailModal', isOpen: true, id: 0, modalFor: 'mediumModal' },
        ],
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  return (
    <div className={styles.sellerFollowrsSection}>
      <div className={styles.sellerFollowrsAlignment}>
        <div className={styles.sellerFollowrsBox}>
          <div className={styles.sellerFollowrsBoxHeding}>
            <div className={styles.sellerFollowersFlex}>
              <h5>Followers</h5>
              <div className={styles.followersTotalBoxAlignment}>
                <span>{data?.getAllStoreFollowers?.length}</span>
              </div>
            </div>
            <div className={styles.seeAllFollowesDetails} onClick={() => handleOpenStoreModal()}>
              <a>See All</a>
              <img src="/assets/seller/icons/right-blue-arrow.svg" alt="right arrow" />
            </div>
          </div>
          <div className={styles.sellerFollowrsSearchAlignment}>
            <div className={styles.sellerFollowrsSearchInput}>
              <img src="/assets/seller/icons/search-icon.svg" alt="search icon" />
              <div className={styles.sellerFollowersInput}>
                <input type="text" placeholder="Search" />
              </div>
            </div>
          </div>
          <div className={styles.sellerFollowersDetailsAlignment}>
            {data?.getAllStoreFollowers?.map((item, i) => {
              return (
                <div key={i} className={styles.sellerFollowersDetailsFlex}>
                  <div className={styles.sellerFollowersDetailsProfileFlex}>
                    <div className={styles.sellerFollowersProfileImg}>
                      <img src={item?.profileUrl} alt="folllow profile" />
                    </div>
                    <h6>
                      {item?.firstName} {item?.lastName}
                    </h6>
                  </div>
                  <div className={styles.sellerFollowersMessageBox}>
                    <img src="/assets/seller/icons/message-circle.svg" alt="message icon" />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
