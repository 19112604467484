// @ts-nocheck
import React from 'react'
import Slider from 'react-slick'
import star from '../../../../../../public/assets/img/WomansCategory/star.png'
import aroww from '../../../../../../public/assets/img/WomansCategory/aroww.png'
import ShareIcon3 from '../../../../../../public/assets/icon/share-grey.svg'
import BlackheartIcon from '../../../../../../public/assets/icon/heart-black.svg'
import BookCoverImg from '../../../../../../public/assets/img/bookCover.png'

export default function ExploreSimilarBooks() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.055,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  }
  return (
    <div className="book-other-book-details-alignment">
      <div className="explore-book-heading-alignment">
        <h5>Explore Similar Books</h5>
        <a>See More</a>
      </div>
      <div className="book-other-book-grid-alignment">
        <Slider {...settings}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((_, index) => {
            return (
              <div className="book-other-book-gridItem-alignment" key={index}>
                <div className="book-other-book-alignment">
                  <div className="book-img-alignment">
                    <img src={BookCoverImg.src} alt="BookCoverImg" />
                  </div>
                  <div className="book-other-name-alignment">
                    <h6>The Sweetness of Water</h6>
                    <p>Nathan Harris</p>

                    <div className="book-rate-alignment">
                      <p className="hike">
                        <span className="incris">
                          <img src={aroww.src} alt="incris" /> 200%
                        </span>
                      </p>
                      <div className="rating">
                        <span className="rating_point">4.8</span>
                        <div className="stars">
                          <img src={star.src} alt="star" />
                        </div>
                        <span className="num">(437)</span>
                      </div>
                    </div>
                    <div className="book-price-alignment">
                      <h5>$21.00</h5>
                      <span>
                        <del>$21.00</del>
                      </span>
                    </div>
                    <div className="like-share-alignment">
                      <div className="like-alignment">
                        <img src={BlackheartIcon.src} alt="BlackheartIcon" />
                        <span>235</span>
                      </div>
                      <div className="like-alignment">
                        <img src={ShareIcon3.src} alt="ShareIcon3" />
                        <span>235</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}
