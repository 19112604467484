import React, { useEffect, useState } from 'react'
import styles from './MobileViewSeeAllBookMarkDetails.module.scss'
import classNames from 'classnames'
import CollectionComponent from '../CollectionComponent'
import { useQuery } from '@apollo/client'
import GET_ALL_BOOKMARK_COLLECTIONS from 'src/graphql/queries/getBookmarkCollections'
import { useAppDispatch } from 'src/store/store'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useSelector } from 'react-redux'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import BookmarkAddNewModal from '../BookmarkAddNewModal'
import { useRouter } from 'next/router'

export default function MobileViewSeeAllBookMarkDetails() {
  const { loading, error, data, refetch } = useQuery(GET_ALL_BOOKMARK_COLLECTIONS, {
    variables: {
      isPrivate: true,
    },
  })

  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768)
  const [addCollectionModal, setAddCollectionModal] = useState(false)
  const router = useRouter()

  const handelOnclick = (item: {
    id: string | number;
    slug: string;
    user: { userName: string };
    name: string;
    image?: string;
    product_images?: string[];
  }) => {
    // if (isDesktop) {
    //   dispatch(
    //     fetchModalData({
    //       allModalData: [
    //         ...getAllState?.allModalData,
    //         { modal: 'collectionByProduct', isOpen: true, id: { width: 70, post: item }, modalFor: 'bigModal' },
    //       ],
    //       lastModalData: getAllState?.lastModalData,
    //     }),
    //   )
    // } else {
    //   dispatch(
    //     fetchMobileModalData({
    //       allModalData: [
    //         ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
    //         {
    //           modal: 'mobileViewCollectionDetail',
    //           isOpen: true,
    //           id: item?.slug,
    //           transform: 112,
    //         },
    //       ],
    //       lastModalData: fetchMobileModal?.lastModalData ? fetchMobileModal?.lastModalData : [],
    //     }),
    //   )
    // }
    if (isDesktop) {
      // dispatch(
      //   fetchModalData({
      //     allModalData: [
      //       ...getAllState?.allModalData,
      //       { modal: 'collectionByProduct', isOpen: true, id: { width: 70 }, item: item?.id, modalFor: 'bigModal' },
      //     ],
      //     lastModalData: getAllState?.lastModalData,
      //   }),
      // )
      // const routeSlug = item?.name?.toLowerCase().replace(/ /g, '')
      // if (item?.isPrivate) {
      //   router.push(`/bookmark-collection/${item?.slug}`)
      // } else {

      router.push(`/user/${item?.user?.userName}/collection/${item?.slug}`)
      // }
    } else {
      dispatch(
        fetchModalData({
          allModalData: [
            ...getAllState?.allModalData,
            { modal: 'mobileViewSeeAllBookMarkDetails', isOpen: false, id: 0, modalFor: 'bigModal' },
          ],
          lastModalData: getAllState?.lastModalData,
        }),
      )
      router.push(`/user/${item?.user?.userName}/collection/${item?.slug}`)

      // dispatch(
      //   fetchMobileModalData({
      //     allModalData: [
      //       ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
      //       {
      //         modal: 'mobileViewCollectionDetail',
      //         isOpen: true,
      //         id: item?.slug,
      //         transform: 112,
      //       },
      //     ],
      //     lastModalData: fetchMobileModal?.lastModalData ? fetchMobileModal?.lastModalData : [],
      //   }),
      // )
    }
  }

  const handleCloseModel = () => {
    let filtredModal = getAllState?.allModalData?.filter((item: { modal: string }) => item?.modal !== getAllState?.lastModalData?.modal)
    dispatch(
      fetchModalData({
        ...getAllState,
        allModalData: filtredModal,
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className={styles.mobileViewSeeAllBookMarkDetailsSection}>
      <div className={styles.mobileViewHeaderStickyAlignment}>
        <div className={styles.mobileViewSeeAllBookMarkHeader}>
          <h4>Collections</h4>

          <div className={styles.backArrowAlignment} onClick={() => handleCloseModel()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="37" height="32" viewBox="0 0 37 32" fill="none">
              <path
                d="M27 0H10C4.47715 0 0 4.47715 0 10V22C0 27.5228 4.47715 32 10 32H27C32.5228 32 37 27.5228 37 22V10C37 4.47715 32.5228 0 27 0Z"
                fill="white"
              />
              <path
                d="M21 10L16 16L21 22"
                stroke="#808191"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className={styles.addNewAlignment} onClick={() => setAddCollectionModal(true)}>
            <a>+ New</a>
          </div>
        </div>
      </div>

      <div className={styles.mobileViewAllCollectionBodyDetailsAlignment}>
        <div className={styles.mobileViewbookMarkCollectionCardProductGrid}>
          {data?.getBookmarkCollections?.map((item: {
            id: string | number;
            slug: string;
            user: { userName: string };
            name: string;
            image?: string;
            product_images?: string[];
          }, index: number) => {
            return (
              <div
                key={index}
                className={classNames(
                  styles.bookMarkModalCollectionCardPadding,
                  styles.bookMarkModalCollectionPaddingZero,
                )}
              >
                <CollectionComponent item={item} index={index} handelOnclick={handelOnclick} />
              </div>
            )
          })}
        </div>
      </div>

      {addCollectionModal && (
        <BookmarkAddNewModal
          setAddCollectionWebModal={setAddCollectionModal}
          isEditCollection={false}
          name=""
          collectionId={null}
        />
      )}
    </div>
  )
}
