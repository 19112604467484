import { useEffect, useState } from 'react'
import getImageUrl from 'src/utils/getImageUrl'
import { useAuth } from 'src/utils/auth'
import { useRouter } from 'next/router'
import InputWithEmoti from 'src/components/Feed/NormalPost/InputWithEmoti'
import PostComments from 'src/components/Feed/NormalPost/PostComments'
import NoComment from 'src/components/ProductDetail/NoComment'
import client1 from 'src/utils/apolloClient'
import GET_COMMENT_COUNT from 'src/graphql/queries/getCommentCount'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAppDispatch } from 'src/store/store'

const Comments = (props: any) => {
  const { user } = useAuth()
  const { comments, product } = props
  const [commentId, setCommentId] = useState({ id: product?.id, comment_for: 'PRODUCT' })
  const [showMore, setShowMore] = useState(false)
  const [isProductDetails, setIsProductDetails] = useState(true)
  const [commentData, setCommentData] = useState(0)
  const dispatch = useAppDispatch()

  const commentCall = async () => {
    try {
      const { data }: any = await client1.query({
        query: GET_COMMENT_COUNT,
        variables: {
          getCommentCountId: product?.id,
          commentFor: 'PRODUCT',
        },
        fetchPolicy: 'network-only',
      })
      setCommentData(data?.getCommentCount?.count)
    } catch (error) {}
  }

  useEffect(() => {
    commentCall()
  }, [])

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const showAllComments = () => {
    if (user) {
      setShowMore(true)
    } else {
      handleRedirect()
    }
  }

  return (
    <>
      <div className="new-comment-box-section">
        <div className="new-comment-box-main-alignment">
          <div className="new-comment-box-heading-alignment">
            <div className="new-comment-main-heading">
              <h4>Comments</h4>
              <span>({commentData})</span>
            </div>
            <a onClick={() => showAllComments()}>Show all comments</a>
          </div>
          {Boolean(product?.comment_count) && (
            <div className="comment-section-title">
              <h3>Comments</h3>
              <span>{Boolean(product?.comment_count?.length) ? `(${product?.comment_count})` : '0'}</span>
            </div>
          )}
          {/* {product?.comment_count === 0 && <NoComment />} */}

          {commentData === 0 && <NoComment />}

          <div className="product-comment-section-main">
            <PostComments
              setShowMore={setShowMore}
              showMore={showMore}
              backgroundColor="#f8f8f8"
              commentId={commentId}
              isProductComment={true}
              postId={product?.id}
              isBottonBorderVisible={true}
              isShowMore={true}
              isProductDetails={isProductDetails}
            />
          </div>
          {user && (
            <div className="post-footer flex items-center max-w-fit commentbox-padding">
              <img
                src={user?.logo_image ? getImageUrl(user?.logo_image) : '/assets/img/dummy-profile.svg'}
                alt={user?.profileImage?.alternativeText}
                className="rounded-full object-cover comment-image"
              />
              <InputWithEmoti post={product} type={'product'} isPost={true} store={product?.store} isButton={true} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Comments
