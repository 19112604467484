import React from 'react'
import styles from './WhatsHotModal.module.scss'
export default function WhatsHotModal() {
  return (
    <div className={styles.whatHotModalSection}>
      <div className={styles.whatHotModalHeaderLineAlignment}>
        <div className={styles.whatHotModalNewLineAlignment}>
          <div className={styles.whatHotModalNewLIneText}>
            {[...Array(200)].map((_, index) => {
              return (
                <React.Fragment key={index}>
                  <p>What’s Hot</p>
                  <p>🔥</p>
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </div>

      <div className={styles.whatHotModalMobileViewHeading}>
        <h4>What’s Hot</h4>
      </div>
      <div className={styles.whatHotModalDetailsAlignment}>
        <div className={styles.whatHotModalNewDetailsMainAlignment}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => {
            return (
              <div className={styles.whatHotModalNewDetailsImgCardBox} key={index}>
                <div className={styles.whatHotModalNewDetailsCardChidImg}>
                  <div className={styles.whatHotModalNewDetailsCardChidImgLeft}>
                    <img src="/assets/shop/img/new-demo-img.png" alt="new-img" />
                  </div>
                  <div className={styles.whatHotModalNewDetailsCardChidImgRight}>
                    <div className={styles.rightSideChildImg}>
                      <img src="/assets/shop/img/new-demo-img2.png" alt="new-img" />
                    </div>
                    <div className={styles.rightSideChildImg}>
                      <img src="/assets/shop/img/new-demo-img3.png" alt="new-img" />
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
