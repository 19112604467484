import React, { useState } from 'react'
import styles from './MobileViewBookMarkModal.module.scss'
import { useAppDispatch } from 'src/store/store'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'
import { useMutation, useQuery } from '@apollo/client'
import GET_ALL_BOOKMARK_COLLECTIONS from 'src/graphql/queries/getBookmarkCollections'
import ADD_BOOKMARK from 'src/graphql/mutations/addBookmark'
import { useSelector } from 'react-redux'
import BookmarkSlider from 'src/components/BookmarkSlider'

export default function MobileViewBookMarkModal() {
  const dispatch = useAppDispatch()
  const { loading, error, data, refetch } = useQuery(GET_ALL_BOOKMARK_COLLECTIONS, {
    variables: {
      collectionId: null,
    },
  })
  const [selectedCollection, setSelectedCollection] = useState(null)
  const fetchProductData = useSelector((state: any) => state?.productResizeData?.data)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)

  const handleAddBookmark = (item) => {
    setSelectedCollection(item?.id)
    addBookmark({
      variables: {
        productId: fetchProductData?.data?.id ?? fetchMobileModal?.lastModalData?.data?.id?.id,
        collectionId: item?.id,
      },
    })
  }

  const [addBookmark] = useMutation(ADD_BOOKMARK, {
    onCompleted: (res) => {},
    onError: (error) => {
      console.log(error)
    },
  })

  const closeBokMarkModel = () => {
    dispatch(
      fetchProductImageResizeModal({
        modal: 'mobileViewBookMarkModal',
        isOpen: false,
      }),
    )
  }

  return (
    <div className={styles.mobileViewBookMarkModalSection}>
      <div className={styles.closeAlignment} onClick={() => closeBokMarkModel()}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M18 6L6 18" stroke="#7A7E88" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M6 6L18 18" stroke="#7A7E88" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
      <div className={styles.mobileViewBookMarkMainLogo}>
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
          <path
            d="M33.25 36.75L21 28L8.75 36.75V8.75C8.75 7.82174 9.11875 6.9315 9.77513 6.27513C10.4315 5.61875 11.3217 5.25 12.25 5.25H29.75C30.6783 5.25 31.5685 5.61875 32.2249 6.27513C32.8813 6.9315 33.25 7.82174 33.25 8.75V36.75Z"
            fill="#1E2432"
            stroke="#1E2432"
            strokeWidth="2.625"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28 12L19.0625 21L15 16.9091"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className={styles.mobileViewBookMarkMainHeding}>
        <h4>Added to Bookmark!</h4>
      </div>

      <div className={styles.mobileViewBookMarkBodyAlignment}>
        <h6>You can also add to your Bookmark’s collection</h6>
        <div className={styles.mobileViewBookMarkBodyDetails}>
          <BookmarkSlider productId={0} />
        </div>
      </div>
    </div>
  )
}
