import { gql } from '@apollo/client'

const query = gql`
  mutation JOINGROUP($group_id: Int!, $user_id: Int!, $isAdmin: Boolean) {
    joinGroup(input: { group_id: $group_id, user_id: $user_id, isAdmin: $isAdmin }) {
      id
      name
    }
  }
`
export default query
