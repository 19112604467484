import { gql } from '@apollo/client'

const GET_FOLLOW_STORES = gql`
  query getFollowStores {
    getFollowStores {
      success
      message
      data {
        stores {
          id
          name
          logo
          cover_image
          followers_count
          products_count
          slug
          total_visitors
          past_visitors
          isFollow
          product_images
          like {
            id
          }
        }
      }
    }
  }
`
export default GET_FOLLOW_STORES
