// @ts-nocheck


import React, { useEffect } from 'react'
import styles from './CategoryBrowsebyType.module.scss'
import Slider from 'react-slick'
import CategoryProduct from 'src/components/Category/ShopByCategoryDetails/CategoryProduct'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import ShopProductCard from 'src/components/Shop/ShopProductCard'
import { fetchTrendingProducts } from 'src/store/slices/productsDataSlice'

export default function CategoryBrowsebyType() {
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const trendingProducts = useSelector((state: any) => state?.productData?.allTrendingProducts)

  useEffect(() => {
    dispatch(fetchTrendingProducts({ page: 1, limit: 4, childSubCategoryId: [[50], [104], [103], [49]] }))
  }, [])

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4.022,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3.022,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3.022,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const specialOffer = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4.95,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3.022,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.09,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  }

  const handleCloseProductModel = () => {
    let filtredModal = getAllState?.allModalData?.filter((item) => item?.modal !== getAllState?.lastModalData?.modal)
    dispatch(
      fetchModalData({
        ...getAllState,
        allModalData: filtredModal,
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  return (
    <>
      {/* <CategoryFilterTab /> */}
      <div className={styles.categoryBrowsedByTypeSection}>
        <div className={styles.categoryMobileViewHeaderAlignment}>
          <div className={styles.mobileViewBack} onClick={() => handleCloseProductModel()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="37" height="32" viewBox="0 0 37 32" fill="none">
              <path
                d="M27 0H10C4.47715 0 0 4.47715 0 10V22C0 27.5228 4.47715 32 10 32H27C32.5228 32 37 27.5228 37 22V10C37 4.47715 32.5228 0 27 0Z"
                fill="white"
              />
              <path
                d="M21 10L16 16L21 22"
                stroke="#808191"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <h4>Browse by Type</h4>
        </div>
        <div className={styles.categoryBrowsedByTypeDetails}>
          <div className={styles.categoryBrowsedByTypeSlider}>
            <Slider {...settings}>
              {trendingProducts?.map((item, index) => {
                return (
                  <div className={styles.categoryBrowsedProduct} key={index}>
                    <ShopProductCard productData={item} />
                  </div>
                )
              })}
            </Slider>
          </div>
          <div className={styles.categoryHotDetailsAlignment}>
            <div className={styles.hotHeading}>
              <h4>Hats</h4>
            </div>
            <div className={styles.specialOfferAlignment}>
              <div className={styles.childHeading}>
                <h6>Special Offers</h6>
                <div className={styles.seeMoreAlignment}>
                  <a>See more</a>
                </div>
              </div>
              <div className={styles.specialOfferAllBodyDetailsAlignment}>
                <div className={styles.specialOfferAllGrid}>
                  <div className={styles.specialOfferAllGridItem}>
                    <div className={styles.specialOfferImg}>
                      <img src="/assets/img/new-banner-img.png" alt="banner img" />
                    </div>
                  </div>
                  <div className={styles.specialOfferAllGridItem}>
                    <div className={styles.specialOfferSliderAlignment}>
                      <Slider {...specialOffer}>
                        {trendingProducts.map((item, index) => {
                          return (
                            <div className={styles.specialOfferDetailsAlignment} key={`z${index}`}>
                              <CategoryProduct index={index} item={item} />
                            </div>
                          )
                        })}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
