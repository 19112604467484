import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { fetchUserProfilModalScroll } from 'src/store/slices/userProfileonScrollSlice'
import { useAppDispatch } from 'src/store/store'
import styles from './SmallSizeModal.module.scss'
import { setJoinedStoreState } from 'src/store/slices/fetchAllStoresSlice'

export default function SmallSizeModal(props) {
  const { isModalOpen, children } = props
  const myElementRef = useRef(null)
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  const handleClose = () => {
    // let datass = getAllState?.allModalData?.filter((item) => item?.modal !== getAllState?.lastModalData?.modal)
    dispatch(
      fetchModalData({
        allModalData: getAllState?.allModalData,
        lastModalData: getAllState?.lastModalData,
        seeAllStore: {},
      }),
    )
    dispatch(setJoinedStoreState([]))
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        handleClose()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleClose])

  const handleScroll = () => {
    const scrollTop = myElementRef.current.scrollTop
    dispatch(
      fetchUserProfilModalScroll({
        scroll: scrollTop,
      }),
    )
  }

  useEffect(() => {
    dispatch(
      fetchUserProfilModalScroll({
        scroll: 0,
      }),
    )
  }, [getAllState])

  useEffect(() => {
    if (getAllState?.lastModalData?.isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [getAllState])

  return (
    <React.Fragment>
      <div
        onClick={() => handleClose()}
        className={`${styles.smallOverlay} ${isModalOpen ? styles.openOverlay : ''}`}
      ></div>
      <div className={`${styles.smallSizeModal} ${isModalOpen ? styles.open : ''}`}>
        <div className={styles.sidebarModalAlignment} ref={myElementRef} onScroll={handleScroll}>
          {children}
        </div>
      </div>
    </React.Fragment>
  )
}
