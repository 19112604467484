import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface manageEditPost {
  loading: true | false
  error: {} | null
  called: true | false
  data: any
}

const initialState = {
  loading: false,
  error: null,
  data: { isEdit: false, content: '', id: 0 },
  called: false,
} as manageEditPost

export const fetchEditPostData = createAsyncThunk('fetchEditData/fetchEditPostData', async (data: any) => {
  return data
})

const manageEditPost = createSlice({
  name: 'fetchEditData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEditPostData.pending, (state, action) => {
      state.loading = true
      state.error = null
      state.called = true
    })
    builder.addCase(fetchEditPostData.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
      state.called = true
    })
    builder.addCase(fetchEditPostData.rejected, (state, action) => {
      state.loading = false
      state.called = false
      state.error = action.error
      console.error('Error fetching cards!')
    })
  },
})

// export var _actions = manageEditPost.actions
export default manageEditPost.reducer
