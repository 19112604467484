import React from 'react'
import TrendingStoreBg from '../../../../../public/assets/img/BookApplience/BATrendingStore.png'
import MobileTrendingStoreBg from '../../../../../public/assets/img/BookApplience/mobileTrendingStoreBg.png'
import IakeLogo from '../../../../../public/assets/icon/IKEAlogo.svg'
import aroww from '../../../../../public/assets/img/WomansCategory/aroww.png'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'

interface ModalData {
  modal: string;
  [key: string]: any;
}

export default function BookApplianceTredingStoreModal() {
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  const handleClose = () => {
    let datass = getAllState?.allModalData?.filter((item: ModalData) => item?.modal !== getAllState?.lastModalData?.modal)
    dispatch(
      fetchModalData({
        allModalData: datass,
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }
  return (
    <div className="book-appliance-trending-store-details-alignment">
      <div className="mobile-view-book-appliance-back-alignment">
        <div className="book-appliance-back_details-alignment">
          <div onClick={() => handleClose()}>
            <img src="/assets/icon/left-icon.svg" alt="back-page" />
          </div>
          <p>Home...</p>
        </div>
        <div className="mobile-heading-alignment">
          <h4>Trending Store</h4>
        </div>
      </div>
      <div className="book-appliance-trending-store-banner-alignment">
        <img src={TrendingStoreBg.src} alt="TrendingStoreBg" />
        <img src={MobileTrendingStoreBg.src} alt="MobileTrendingStoreBg" className="mobile-view-banner" />
        <div className="book-appliance-trending-store-banner-details">
          <h4>Trending Store</h4>
          <p>Experience the latest in contemporary design and decor with our trendy furniture stores</p>
        </div>
      </div>

      <div className="book-appliance-trending-stote-detalis-alignment">
        <div className="book-appliance-trending-store-grid-alignment">
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => {
            return (
              <div className="book-appliance-trending-store-gridItem-alignment" key={index}>
                <div className="book-appliance-trending-store-box-alignment">
                  <div className="book-appliance-details-img-alignment">
                    <img src={IakeLogo.src} alt="IakeLogo" />
                  </div>
                  <div className="book-appliance-child-details-alignment">
                    <h5>Ikea</h5>
                    <span>Furniture</span>
                    <div>
                      <div className="book-appliance-product">
                        <span>2.1k Followers </span>
                        <p></p>
                        <span> 543 Product</span>
                      </div>
                      <div className="book-appliance-visitor">
                        <span>Today Visitor : 1.248</span>
                        <div className="book-appliance-pre">
                          <img src={aroww.src} alt="pre" /> <span>75%</span>
                        </div>
                      </div>
                    </div>
                    <div className="follow-button-alignment">
                      <button>Follow Store</button>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
