import React, { useEffect, useState } from 'react'
import styles from './MobilePicUpModal.module.scss'
import { SketchPicker } from 'react-color'
import { useAppDispatch } from 'src/store/store'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
import { useSelector } from 'react-redux'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'

export default function MobilePicUpModal() {
  const dispatch = useAppDispatch()
  const [color, setColor] = useState('')
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)

  const handleChangeComplete = (color) => {
    setColor(color.hex)
  }

  const handleSaveColor = () => {
    if (color.trim() !== '') {
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            selectedColor: color,
          },
        }),
      )
      let datass = fetchMobileModal?.allModalData?.filter(
        (item) => item?.modal !== fetchMobileModal?.lastModalData?.modal,
      )
      dispatch(
        fetchMobileModalData({
          allModalData: datass,
          lastModalData: fetchMobileModal?.lastModalData,
        }),
      )
    }
  }

  useEffect(() => {
    if (addProductDetailSlice?.id?.selectedColor.trim() !== '') {
      setColor(addProductDetailSlice?.id?.selectedColor)
    }
  }, [])

  return (
    <div className={styles.mobilePicUPModalSection}>
      <div className={styles.mobileFirstColurBoxAlignment} style={{ background: color }}></div>
      {/* <div className={styles.mobileViewSecondColurPicBox}></div> */}
      <SketchPicker color={color} onChangeComplete={(e) => handleChangeComplete(e)} />
      <div className={styles.mobileViewThirdColurBoxAlignment}>
        {/* <div className={styles.mobileViewColorStrick}>
          <svg xmlns="http://www.w3.org/2000/svg" width="243" height="21" viewBox="0 0 243 21" fill="none">
            <rect y="2.00009" width="243" height="17" rx="8.5" fill="url(#paint0_linear_4180_26158)" />
            <rect x="4" y="9.15527e-05" width="2" height="21" rx="1" fill="#090909" />
            <defs>
              <linearGradient
                id="paint0_linear_4180_26158"
                x1="-1.66411e-06"
                y1="10.9475"
                x2="243"
                y2="10.9474"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F20000" />
                <stop offset="0.25" stop-color="#F2DA00" />
                <stop offset="0.489583" stop-color="#0051ED" />
                <stop offset="0.75" stop-color="#DB00FF" />
                <stop offset="1" stop-color="#F80000" />
              </linearGradient>
            </defs>
          </svg>
        </div> */}
        <a onClick={handleSaveColor}>Done</a>
      </div>
    </div>
  )
}
