import React, { useEffect, useState } from 'react'
import styles from './BookmarkCollection.module.scss'
import Slider from 'react-slick'
import classNames from 'classnames'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import CollectionComponent from '../CollectionComponent'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useMutation, useQuery } from '@apollo/client'
import GET_ALL_BOOKMARK_COLLECTIONS from 'src/graphql/queries/getBookmarkCollections'
import UpdateBookmarkCollection from 'src/graphql/mutations/createBookMarkCollection'
import { notification } from 'antd'
import { useRouter } from 'next/router'

interface ArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

function SampleNextArrow(props: ArrowProps) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <img src="/assets/icon/right-icon-black.svg" alt="RightArrow" />
    </div>
  )
}

interface ArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

function SamplePrevArrow(props: ArrowProps) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <img src="/assets/icon/left-icon-black.svg" alt="LeftArrow" />
    </div>
  )
}

interface BookmarkCollectionProps {
  setAddCollectionWebModal: (show: boolean) => void;
}

interface BookmarkItem {
  id: number;
  slug: string;
  user: {
    userName: string;
  };
  name: string;
  isPrivate?: boolean;
  product_images?: string[];
  product_count?: number;
}

interface GetBookmarkCollectionsData {
  getBookmarkCollections: BookmarkItem[];
}

export default function BookmarkCollection({ setAddCollectionWebModal }: BookmarkCollectionProps) {
  const CollectionSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4.6,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1099,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const { loading, error, data, refetch } = useQuery<GetBookmarkCollectionsData>(GET_ALL_BOOKMARK_COLLECTIONS)
  const [addCollectionModal, setAddCollectionModal] = useState(false)
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  // const data?.getBookmarkCollections = data?.getBookmarkCollections
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const [inputValue, setInputValue] = useState('')
  const router = useRouter()

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768)

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    refetch()
  }, [fetchMobileModal, getAllState])

  const handelOnclick = (item: BookmarkItem) => {
    if (isDesktop) {
      // dispatch(
      //   fetchModalData({
      //     allModalData: [
      //       ...getAllState?.allModalData,
      //       { modal: 'collectionByProduct', isOpen: true, id: { width: 70 }, item: item?.id, modalFor: 'bigModal' },
      //     ],
      //     lastModalData: getAllState?.lastModalData,
      //   }),
      // )
      // const routeSlug = item?.name?.toLowerCase().replace(/ /g, '')
      // if (item?.isPrivate) {
      //   router.push(`/bookmark-collection/${item?.slug}`)
      // } else {
      router.push(`/user/${item?.user?.userName}/collection/${item?.slug}`)
      // }
    } else {
      router.push(`/user/${item?.user?.userName}/collection/${item?.slug}`)

      // dispatch(
      //   fetchMobileModalData({
      //     allModalData: [
      //       ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
      //       {
      //         modal: 'mobileViewCollectionDetail',
      //         isOpen: true,
      //         id: item?.slug,
      //         transform: 112,
      //       },
      //     ],
      //     lastModalData: fetchMobileModal?.lastModalData ? fetchMobileModal?.lastModalData : [],
      //   }),
      // )
    }
  }

  const addCollection = () => {
    // if(!inputValue) toast.error("")
    // if (isEditCollection) {
    //   editBookmarkCollection({
    //     variables: {
    //       name: inputValue,
    //       collectionId: collectionId,
    //     },
    //     refetchQueries: [
    //       { query: GET_SINGLE_COLLECTION, variables: { collectionId: collectionId } },
    //       { query: GET_ALL_BOOKMARK_COLLECTIONS },
    //     ],
    //   })
    // } else {
    addBookmarkCollection({
      variables: {
        input: {
          name: inputValue,
          image: '',
        },
      },
      refetchQueries: [
        {
          query: GET_ALL_BOOKMARK_COLLECTIONS,
        },
      ],
    })
    // }
  }

  const [addBookmarkCollection] = useMutation(UpdateBookmarkCollection, {
    onCompleted: () => {
      setAddCollectionModal(false)
      notification['success']({
        message: 'Collection added successfully',
        description: 'Your collection added successfully',
      })
    },
    onError: () => {
      notification['error']({
        message: 'Error',
        description: 'Not added collection',
      })
    },
  })

  return (
    <div className={styles.bookMarkModalCollectionAlignment}>
      <div className={styles.bookMarkModalCollectionHeader}>
        <div className={styles.bookMarkModalCollectionNameAlignment}>
          <h6>Collections</h6>
          <div className={styles.bookmarkCollectionNumber}>
            <span>{data?.getBookmarkCollections?.length}</span>
          </div>
        </div>
        <div className={styles.bookMarkWebVIewAlignment}>
          <a onClick={() => setAddCollectionWebModal(true)}>+ New Collection</a>
        </div>
        <div className={styles.bookMarkMobileVIewAlignment}>
          <a onClick={() => setAddCollectionModal(true)}>+ New</a>
        </div>
      </div>

      {/* {item?.product_images?.map((x) => {
                            return (
                              <div className={styles.bookMarkCollectionCardFirstImg}>
                                <img src={x} alt="bootmark-img" />
                              </div>
                            )
                          })} */}

      <div className={styles.bookMarkModalCollectionDetails}>
        {Boolean(data?.getBookmarkCollections?.length) ? (
          <div className={styles.bookMarkModalCollectionAllDetails}>
            <div className={styles.bookMarkModalCollectionAllDetailsSlider}>
              {isDesktop && (
                <div className={styles.bookMarkModalCollectionCardMain} {...CollectionSlider}>
                  {data?.getBookmarkCollections.map((item, index) => {
                    return (
                      <div key={index} className={styles.bookMarkModalCollectionCardPadding}>
                        <CollectionComponent item={item} index={index} handelOnclick={handelOnclick} />
                      </div>
                    )
                  })}
                </div>
              )}
              {/* Grid Details Design Start */}
              <div className={styles.bookMarkCollectionCardProductGrid}>
                {!isDesktop &&
                  data?.getBookmarkCollections?.slice(0, 3)?.map((item:any, index:number) => {
                    return (
                      <div
                        key={index}
                        className={classNames(
                          styles.bookMarkModalCollectionCardPadding,
                          styles.bookMarkModalCollectionPaddingZero,
                        )}
                      >
                        <CollectionComponent item={item} index={index} handelOnclick={handelOnclick} />
                      </div>
                    )
                  })}

                <div className={styles.mobileViewBookMarkMoreDetailsAlignment}>
                  <div className={styles.mobileViewBookMarkMoreDetailsgrid}>
                    {data?.getBookmarkCollections.slice(3, 7).map((item:any, i:number) => {
                      return (
                        <div key={i} className={styles.mobileViewBookMarkUploadedImg}>
                          {Boolean(item?.product_images?.length) ? (
                            item?.product_images?.slice(0, 3)?.map((x, index) => (
                              <div className={styles.mobileViewbookMarkCollectionCardImg} key={index}>
                                <img src={x} alt="bootmark-img" />
                              </div>
                            ))
                          ) : (
                            <div className={styles.mobileViewbookMarkCollectionCardImg}>
                              <img src="/assets/seller/img/shoes0.png" alt="bootmark-img" />
                            </div>
                          )}
                        </div>
                      )
                    })}

                    {Array.from({
                      length: Math.max(
                        0,
                        7 - (data?.getBookmarkCollections?.length || 0) < 4 ? 7 - (data?.getBookmarkCollections?.length || 0) : 4,
                      ),
                    }).map((_, index) => (
                      <div className={styles.mobileViewNoImg} key={index}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="48" viewBox="0 0 52 48" fill="none">
                          <g filter="url(#filter0_d_992_8874)">
                            <rect
                              x="1"
                              y="6.01929"
                              width="41.287"
                              height="41.287"
                              rx="4"
                              transform="rotate(-6.98269 1 6.01929)"
                              fill="#F8F8F8"
                            />
                            <rect
                              x="1.70599"
                              y="6.57121"
                              width="40.0197"
                              height="40.0197"
                              rx="3.36634"
                              transform="rotate(-6.98269 1.70599 6.57121)"
                              stroke="white"
                              strokeWidth="1.26732"
                            />
                          </g>
                          <g filter="url(#filter1_d_992_8874)">
                            <rect x="5.80469" y="3.32275" width="41.287" height="41.287" rx="4" fill="#F8F8F8" />
                            <rect
                              x="6.43835"
                              y="3.95641"
                              width="40.0197"
                              height="40.0197"
                              rx="3.36634"
                              stroke="white"
                              strokeWidth="1.26732"
                            />
                          </g>
                          <g filter="url(#filter2_d_992_8874)">
                            <rect
                              x="9.85059"
                              y="1.47363"
                              width="41.287"
                              height="41.287"
                              rx="4"
                              transform="rotate(5.35673 9.85059 1.47363)"
                              fill="#F8F8F8"
                            />
                            <rect
                              x="10.4223"
                              y="2.16368"
                              width="40.0197"
                              height="40.0197"
                              rx="3.36634"
                              transform="rotate(5.35673 10.4223 2.16368)"
                              stroke="white"
                              strokeWidth="1.26732"
                            />
                          </g>
                          <defs>
                            <filter
                              id="filter0_d_992_8874"
                              x="0.611177"
                              y="0.611421"
                              width="46.7776"
                              height="46.7774"
                              filterUnits="userSpaceOnUse"
                              colorInterpolationFilters="sRGB"
                            >
                              <feFlood floodOpacity="0" result="BackgroundImageFix" />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset />
                              <feGaussianBlur stdDeviation="0.422439" />
                              <feComposite in2="hardAlpha" operator="out" />
                              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_992_8874" />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_992_8874"
                                result="shape"
                              />
                            </filter>
                            <filter
                              id="filter1_d_992_8874"
                              x="4.95981"
                              y="2.47788"
                              width="42.9769"
                              height="42.9769"
                              filterUnits="userSpaceOnUse"
                              colorInterpolationFilters="sRGB"
                            >
                              <feFlood floodOpacity="0" result="BackgroundImageFix" />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset />
                              <feGaussianBlur stdDeviation="0.422439" />
                              <feComposite in2="hardAlpha" operator="out" />
                              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_992_8874" />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_992_8874"
                                result="shape"
                              />
                            </filter>
                            <filter
                              id="filter2_d_992_8874"
                              x="5.50668"
                              y="0.984468"
                              width="45.9398"
                              height="45.9395"
                              filterUnits="userSpaceOnUse"
                              colorInterpolationFilters="sRGB"
                            >
                              <feFlood floodOpacity="0" result="BackgroundImageFix" />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset />
                              <feGaussianBlur stdDeviation="0.422439" />
                              <feComposite in2="hardAlpha" operator="out" />
                              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_992_8874" />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_992_8874"
                                result="shape"
                              />
                            </filter>
                          </defs>
                        </svg>
                      </div>
                    ))}
                  </div>
                  <div
                    className={styles.bookmarkSeeMoreAlignment}
                    onClick={() =>
                      dispatch(
                        fetchModalData({
                          allModalData: [
                            ...getAllState?.allModalData,
                            { modal: 'mobileViewSeeAllBookMarkDetails', isOpen: true, id: 0, modalFor: 'bigModal' },
                          ],
                          lastModalData: getAllState?.lastModalData,
                        }),
                      )
                    }
                  >
                    <a>See All</a>
                  </div>
                </div>
              </div>
              {/* Grid Details Design End */}
            </div>
          </div>
        ) : (
          <div className={styles.bookMarkModalCollectionEmptyDetails}>
            <div className={styles.bookMarkModalCollectionEmptyImg}>
              <img src="/assets/icon/no-bookmark-img.svg" alt="no collection" />
            </div>
            <p>No collection</p>
          </div>
        )}
      </div>
      {addCollectionModal && (
        <div className={styles.mobileViewAddNewCollectionModalAlignment}>
          <div className={styles.mobileViewAddNewCollectionModalWrapper}>
            <div className={styles.mobileViewAddNewCollectionBox}>
              <div className={styles.mobileViewAddNewHeading}>
                <h6>Add New Collection</h6>

                <div className={styles.mobileCloseIconAlignment} onClick={() => setAddCollectionModal(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M18 6L6 18"
                      stroke="#495057"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6 6L18 18"
                      stroke="#495057"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className={styles.mobileViewBodyDetailsAlignment}>
                <div className={styles.mobileViewInputAlignment}>
                  <label htmlFor="">Collection Name</label>
                  <div className={styles.mobileViewInput}>
                    <input
                      type="text"
                      placeholder="e.g. Monochromatic Fashion"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.mobileViewButtonAlignment} onClick={() => addCollection()}>
                <button>Create</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

// const data?.getBookmarkCollections = [
//   {
//     __typename: 'BookmarkCollection',
//     id: 5,
//     user_id: 6,
//     name: 'LuxyChoice',
//     product_images: [
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694887252846-103515363.jpg',
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694902700822-51527426.jpg',
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694887252846-103515363.jpg',
//     ],
//     product_count: 2,
//   },
//   {
//     __typename: 'BookmarkCollection',
//     id: 15,
//     user_id: 6,
//     name: 'Fash',
//     product_images: [
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694887252846-103515363.jpg',
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694902700822-51527426.jpg',
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694887252846-103515363.jpg',
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694887252846-103515363.jpg',
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694902700822-51527426.jpg',
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694887252846-103515363.jpg',
//     ],
//     product_count: 0,
//   },

//   {
//     __typename: 'BookmarkCollection',
//     id: 15,
//     user_id: 6,
//     name: 'Fash',
//     product_images: [
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694887252846-103515363.jpg',
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694902700822-51527426.jpg',
//       // 'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694887252846-103515363.jpg',
//     ],
//     product_count: 0,
//   },

//   {
//     __typename: 'BookmarkCollection',
//     id: 15,
//     user_id: 6,
//     name: 'Fash',
//     product_images: [
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694887252846-103515363.jpg',
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694902700822-51527426.jpg',
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694887252846-103515363.jpg',
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694887252846-103515363.jpg',
//     ],
//     product_count: 0,
//   },

//   {
//     __typename: 'BookmarkCollection',
//     id: 15,
//     user_id: 6,
//     name: 'Fash',
//     product_images: [
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694887252846-103515363.jpg',
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694902700822-51527426.jpg',
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694887252846-103515363.jpg',
//     ],
//     product_count: 0,
//   },

//   {
//     __typename: 'BookmarkCollection',
//     id: 15,
//     user_id: 6,
//     name: 'Fash',
//     product_images: [
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694887252846-103515363.jpg',
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694902700822-51527426.jpg',
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694887252846-103515363.jpg',
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694887252846-103515363.jpg',
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694902700822-51527426.jpg',
//       'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/store/121/product/image-1694887252846-103515363.jpg',
//     ],
//     product_count: 0,
//   },
// ]
