// @ts-nocheck

import React, { useEffect, useState } from 'react'
import styles from './NewFollowedStore.module.scss'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchFollowedStoreData } from 'src/store/slices/fetchAllStoresSlice'
import Slider from 'react-slick'
import NewStoreSlider from 'src/components/StoreAndProductDetailModal/StoresModal/NewStoreSlider'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <svg
        style={{ transform: 'none' }}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <circle opacity="0.6" cx="20" cy="20" r="20" fill="#F8F8F8" />
        <path d="M19 15L23 20L19 25" stroke="#1E2432" strokeLinecap="round" strokeLinejoin="round" />
      </svg>{' '}
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <svg
        style={{ transform: 'rotate(180deg)' }}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <circle opacity="0.6" cx="20" cy="20" r="20" fill="#F8F8F8" />
        <path d="M19 15L23 20L19 25" stroke="#1E2432" strokeLinecap="round" strokeLinejoin="round" />
      </svg>{' '}
    </div>
  )
}

export default function NewFollowedStore() {
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const allStores = useSelector((state: any) => state?.storesData?.allStoreData)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.2,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1699,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 923,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)

  const followStoreCall = () => {}

  const unfollowStoreCall = () => {}

  const handleAddStoreLike = () => {}

  useEffect(() => {
    dispatch(fetchFollowedStoreData({ limit: limit, page: page, type: 'JOIN_STORES' }))
  }, [])

  const handleClose = () => {
    let datass = getAllState?.allModalData?.filter((item) => item?.modal !== getAllState?.lastModalData?.modal)
    dispatch(
      fetchModalData({
        allModalData: datass,
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  return (
    <div className={styles.storemainBox}>
      <div className={styles.mobileShowArrow} onClick={handleClose}>
        <svg xmlns="http://www.w3.org/2000/svg" width="37" height="32" viewBox="0 0 37 32" fill="none">
          <path
            d="M27 0H10C4.47715 0 0 4.47715 0 10V22C0 27.5228 4.47715 32 10 32H27C32.5228 32 37 27.5228 37 22V10C37 4.47715 32.5228 0 27 0Z"
            fill="white"
          />
          <path
            d="M21 10L16 16L21 22"
            stroke="#808191"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div className={styles.title}>
        <p>Stores</p>
        <span>Shop with confidence from reputable and reliable sellers</span>
      </div>
      <div className={styles.flexBox}>
        <div className={styles.flexBoxItems}>
          <div className={styles.content}>
            <div>
              <p>Followed Store({allStores?.length || 0})</p>
              <span>Your Favorites, Always in the Loop</span>
            </div>
            <button
              className={styles.mobileHide}
              onClick={() => {
                dispatch(
                  fetchModalData({
                    ...getAllState,
                    seeAllStore: {
                      modal: 'seeAllStoreModal',
                      isOpen: true,
                      storeFor: 'JOINED',
                      id: 0,
                      modalFor: 'smallModal',
                    },
                  }),
                )
              }}
            >
              See All
            </button>
            <button
              className={styles.mobileShow}
              onClick={() => {
                dispatch(
                  fetchMobileModalData({
                    allModalData: [
                      ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                      {
                        modal: 'mobileViewStoreModal',
                        isOpen: true,
                        id: { isSeller: false, group: null, type: 'post' },
                        transform: 42,
                      },
                    ],
                    lastModalData: fetchMobileModal?.lastModalData,
                  }),
                )
              }}
            >
              See All
            </button>
          </div>
        </div>
        <div className={styles.flexBoxItems}>
          {allStores?.length > 0 ? (
            <Slider {...settings}>
              {allStores?.map((store, index) => (
                <React.Fragment key={index}>
                  <NewStoreSlider
                    store={store}
                    followStoreCall={followStoreCall}
                    unfollowStoreCall={unfollowStoreCall}
                    handleAddStoreLike={handleAddStoreLike}
                  />
                </React.Fragment>
              ))}
            </Slider>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}
