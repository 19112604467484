import { gql } from '@apollo/client'

const query = gql`
  mutation ($storeId: Int!) {
    unFollowStore(storeId: $storeId) {
      id
      user_id
      store_id
    }
  }
`
export default query
