import GoogleMapReact from 'google-map-react'

export const MapView = (props) => {
  const { latitude, longitude, setLatLong, setSelectedLatlong } = props

  const Marker: any = () => (
    <div className="">
      <img src="/assets/img/checkout/map-marker.svg" />
    </div>
  )

  const mapOptions = (maps) => ({
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_TOP,
    },
  })

  const handleClick = (coord) => {
    setLatLong(coord)
  }

  if (!latitude || !longitude) return null

  return (
    // <></>
    <GoogleMapReact
      options={mapOptions}
      bootstrapURLKeys={{
        key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '',
      }}
      onClick={(e) => handleClick(e)}
      dragable={false}
      center={{
        lat: latitude,
        lng: longitude,
      }}
      zoom={12}
    >
      <Marker lat={latitude} lng={longitude} text="My Marker" />
    </GoogleMapReact>
  )
}
