import { gql } from '@apollo/client'

const GET_ALL_PRODUCTS_BY_STOREID = gql`
  query getSingleProductById($id: Int!) {
    getSingleProductById(id: $id) {
      id
      store_id
      title
      description
      isFeature
      isActive
      metaTitle
      keywords
      metaDescription
      isVisible
      isFreeShipping
      condition
      is_deleted
      brand_id
      status
      shipping_method_id
      ratio
      size {
        height
        width
      }
      variants {
        id
        title
        price
        sku
        barcode
        listPrice
        width
        height
        length
        weightUnit
        weightValue
        inventory_quantity
        old_inventory_quantity
        image_id
        image {
          media
        }
        total_variant {
          variant_option_id
          variant_option {
            value
            variation_id
          }
        }
      }
      shareCount {
        id
      }
      productLikes {
        id
      }
      inventoryPrice {
        price
        listPrice
        quantity
        sku
      }
      shipping {
        weightValue
        weightUnit
        unit
        length
        width
        height
      }
      ratio
      size {
        height
        width
      }
      categories {
        category_id
        subCategory_id
        childSubCategory_id
      }
      options {
        name
        data {
          value
          colorCode
        }
      }
      attributes {
        name
        value
      }
      images {
        media_id
        src
      }
      tags {
        tag
      }
    }
  }
`
export default GET_ALL_PRODUCTS_BY_STOREID
