import React, { useEffect, useState } from 'react'
import styles from './MobileJewelryFansModal.module.scss'
import PeopleItem from 'src/components/StoreAndProductDetailModal/PeopleModal/PeopleItem'
import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { fetchAllPeople } from 'src/store/slices/fetchAllPeopleSlice'
export default function MobileJewelryFansModal() {
  const dispatch = useAppDispatch()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(15)
  const peopleData = useSelector((state: any) => state?.peopleData)

  useEffect(() => {
    dispatch(fetchAllPeople({ limit: limit, page: page, type: 'ALL_PEOPLE', prevPost: [] }))
  }, [])

  const sendFriendRequestCall = () => {}
  const declineFriendRequestCall = () => {}
  return (
    <div className={styles.mobileJewelryFansModalSection}>
      <div className={styles.mobileJewelryFansModalAlignment}>
        <div className={styles.mobileJewelryFansModalHeading}>
          <svg xmlns="http://www.w3.org/2000/svg" width="37" height="32" viewBox="0 0 37 32" fill="none">
            <path
              d="M27 0H10C4.47715 0 0 4.47715 0 10V22C0 27.5228 4.47715 32 10 32H27C32.5228 32 37 27.5228 37 22V10C37 4.47715 32.5228 0 27 0Z"
              fill="white"
            />
            <path
              d="M21 10L16 16L21 22"
              stroke="#808191"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <h6>Jewelry Fans</h6>
        </div>

        <div className={styles.mobileJewelryFansModalBodyDetailsAlignment}>
          <div className={styles.mobileJewelryFansModalBodyGridAlignment}>
            {peopleData?.allPeople?.map((people, index) => (
              <React.Fragment key={index}>
                <PeopleItem
                  people={people}
                  declineFriendRequestCall={declineFriendRequestCall}
                  sendFriendRequestCall={sendFriendRequestCall}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
