import React from 'react'
import styles from './MobileViewFeturedProduct.module.scss'
import FeaturedProductsDetails from '../FeaturedProductsDetails'

export default function MobilefeaturedproductModal() {
  return (
    <div className={styles.mobileViewFeatureProductDetailsAlignment}>
      <div className={styles.mobileViewHeading}>
        <h6>Featured Products</h6>
      </div>

      <div className={styles.mobileViewFeatureBodyAlignment}>
        <div className={styles.mobileViewFeatureGrid}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => {
            return <FeaturedProductsDetails index={index} />
          })}
        </div>
      </div>
    </div>
  )
}
