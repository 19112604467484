import React, { useEffect, useRef, useState } from 'react'
import styles from './AddColorModal.module.scss'
import classNames from 'classnames'
import { SketchPicker } from 'react-color'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'

export default function AddColorModal() {
  const sizeArraySelected = [
    { name: 'Red', colorCode: '#ff0000', id: 0 },
    { name: 'Orange', colorCode: '#ff8000', id: 1 },
    { name: 'Yellow', colorCode: '#ffff00', id: 2 },
    { name: 'Chartreuse', colorCode: '#80ff00', id: 3 },
    { name: 'Green', colorCode: '#00ff00', id: 4 },
    { name: 'Spring green', colorCode: '#00ff80', id: 5 },
    { name: 'Cyan', colorCode: '#00ffff', id: 6 },
    { name: 'Dodger Blue', colorCode: '#0080ff', id: 7 },
    { name: 'Blue', colorCode: '#0000ff', id: 8 },
    { name: 'Purple', colorCode: '#8000ff', id: 9 },
    { name: 'Violet', colorCode: '#ff00ff', id: 10 },
    { name: 'Magenta', colorCode: '#ff0080', id: 11 },
  ]

  const dispatch = useAppDispatch()
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const categoryRef: any = useRef()
  const colorPickerRef: any = useRef()
  const [colorArray, setColorArray] = useState([{ name: '', colorCode: '' }])
  const [inputIndex, setInputIndex] = useState(null)
  const [inputColorIndex, setInputColorIndex] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [sizeArray, setSizeArray] = useState(sizeArraySelected)
  const [color, setColor] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [error, setError] = useState([])

  useEffect(() => {
    if (getAllState?.allModalData[0]?.isEdit) {
      let colorData = addProductDetailSlice?.id?.variant?.colorCodeArray
        ?.filter((item) => item.name === 'color' || item.name === 'Color')
        .map((item) => ({
          name: item.data.map((dataItem) => dataItem.name),
          colorCode: item.data.map((dataItem) => dataItem.colorCode),
        }))
        .map((item) => {
          const result = []
          for (let i = 0; i < item.name.length; i++) {
            result.push({
              name: item.name[i],
              colorCode: item.colorCode[i],
            })
          }
          return result
        })
        .flat()

      const staticObject = {
        name: '',
        colorCode: '',
      }
      colorData?.push(staticObject)

      setColorArray(colorData)
    }
  }, [getAllState?.allModalData[0]?.isEdit])

  useEffect(() => {
    if (addProductDetailSlice?.id?.variant?.dataEdit?.color && !getAllState?.allModalData[0]?.isEdit) {
      setColorArray(addProductDetailSlice?.id?.variant?.dataEdit?.color?.data)
    }
  }, [addProductDetailSlice?.id?.variant?.dataEdit])

  const handleChange = (e, id) => {
    let errors = {}
    setInputValue(e.target.value)
    setInputIndex(id)
    setInputColorIndex(null)
    let chnaged = colorArray.map((color, index) => {
      if (index === id) {
        return { ...color, name: e.target.value }
      } else {
        return color
      }
    })
    let errorchecking = chnaged?.map((data, index) => {
      if (data.name.trim() === '') {
        return (errors[index] = 'Enter valid input*')
      }
    })
    setError(errorchecking)
    setColorArray(chnaged)
    // dispatch(
    //   manageAddProductDetail({
    //     modal: '',
    //     isOpen: false,
    //     id: {
    //       ...addProductDetailSlice?.id,
    //       variant: {
    //         ...addProductDetailSlice?.id?.variant,
    //         colorArray: chnaged,
    //       },
    //     },
    //   }),
    // )
  }

  const handleSelect = async (id, data) => {
    let errors = {}
    let ID = id
    if (data !== '') {
      let selectedSetting = colorArray.map((col, index) => {
        if (index === ID) {
          return { ...col, name: data?.name, colorCode: data?.colorCode }
        } else {
          return col
        }
      })
      // let errorchecking = selectedSetting.map((data, index) => {
      //   if (data.name.trim() === "") {
      //     return (errors[index] = "Enter valid input*");
      //   }
      // });
      // setError(errorchecking);
      const uniqueMembers = unique(
        [...selectedSetting, { name: '', colorCode: '' }],
        (a: any, b: any) => a.name === b.name && a.colorCode === b.colorCode,
      )
      setColorArray(uniqueMembers)
      // dispatch(
      //   manageAddProductDetail({
      //     modal: '',
      //     isOpen: false,
      //     id: {
      //       ...addProductDetailSlice?.id,
      //       variant: {
      //         ...addProductDetailSlice?.id?.variant,
      //         colorArray: uniqueMembers,
      //       },
      //     },
      //   }),
      // )
      setInputIndex(null)
      setInputColorIndex(null)
    }
  }

  const unique = (a, fn) => {
    if (a.length === 0 || a.length === 1) {
      return a
    }
    if (!fn) {
      return a
    }

    for (let i = 0; i < a.length; i++) {
      for (let j = i + 1; j < a.length; j++) {
        if (fn(a[i], a[j])) {
          a.splice(i, 1)
        }
      }
    }
    return a
  }

  const handleEnter = async (e, index) => {
    let errors = {}
    if (e.key === 'Enter') {
      let filterData = colorArray?.filter((col, ind) => index === ind)
      if (filterData?.length > 0) {
        let errorchecking = colorArray.map((data, index) => {
          if (data.name.trim() === '') {
            return (errors[index] = 'Enter valid input*')
          }
        })
        setError(errorchecking)
        const uniqueMembers = unique(
          [...colorArray, { name: '', colorCode: '' }],
          (a, b) => a.name === b.name && a.colorCode === b.colorCode,
        )
        setColorArray([...uniqueMembers])
        // dispatch(
        //   manageAddProductDetail({
        //     modal: '',
        //     isOpen: false,
        //     id: {
        //       ...addProductDetailSlice?.id,
        //       variant: {
        //         ...addProductDetailSlice?.id?.variant,
        //         colorArray: [...uniqueMembers],
        //       },
        //     },
        //   }),
        // )
        setInputIndex(null)
      }
    }
  }

  useEffect(() => {
    setInputColorIndex(null)
    const checkIfClickedOutside = (e) => {
      if (inputIndex && categoryRef.current && !categoryRef.current.contains(e.target)) {
        setInputIndex(null)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [inputIndex])

  useEffect(() => {
    // setInputColorIndex(null)
    const checkIfClickedOutside = (e) => {
      if (inputColorIndex && colorPickerRef.current && !colorPickerRef.current.contains(e.target)) {
        setInputColorIndex(null)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [inputColorIndex])

  useEffect(() => {
    if (inputValue === '') {
      setSizeArray(sizeArraySelected)
      setSearchResult(sizeArraySelected)
    } else {
      let datat = sizeArray?.filter((rep) => rep?.name?.toLowerCase()?.includes(inputValue?.toLowerCase()))
      setSearchResult(datat)
    }
  }, [inputValue])

  const styled: any = {
    default: {
      color: {
        width: '40px',
        height: '15px',
        borderRadius: '3px',
        background: `rgba(255, 0, 0, 0.4);`,
      },
      popover: {
        position: 'absolute',
        zIndex: '3',
        background: '#ffffff',
        border: '1px solid #e4e4e8',
        boxShadow: '6px 6px 54px rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
        padding: '12px 0',
        width: '100%',
        bottom: '54px',
        height: 'auto',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
      swatch: {
        //   padding: "6px",
        //   background: "#ffffff",
        //   borderRadius: "2px",
        //   cursor: "pointer",
        //   display: "inline-block",
        //   boxShadow: "0 0 0 1px rgba(0,0,0,.2)",
        // },
      },
    },
  }

  const handleChangeComplete = (color, index) => {
    setColor(color.hex)
    setColorArray(
      colorArray.map((col, ind) => {
        if (ind === index) {
          return { ...col, colorCode: color.hex }
        } else {
          return col
        }
      }),
    )
  }

  const HandleColorPicker = (index, color) => {
    setColor(color)
    if (inputColorIndex === index) {
      setInputColorIndex(null)
    } else {
      setInputColorIndex(index)
    }
  }

  const handleClickInput = (index) => {
    setInputColorIndex(null)
    setInputIndex(index)
    setInputValue('')
    setSizeArray(sizeArraySelected)
  }

  const validateSize = () => {
    let errors = {}
    let formIsValid = true
    let errorchecking = colorArray.map((data, index) => {
      if (data.name.trim() === '' && data.colorCode.trim() !== '') {
        formIsValid = false
        return (errors[index] = 'Enter valid input*')
      } else if (data?.colorCode?.trim() === '' && data?.name?.trim() !== '') {
        formIsValid = false
        return (errors[index] = 'Enter valid color*')
      } else if (data.name.trim() === '' && data.colorCode.trim() === '') {
        formIsValid = false
        return (errors[index] = 'Enter valid color and input*')
      }
    })
    setError(errorchecking)
    return formIsValid
  }

  const outputArray = []

  function generateVariants(currentVariant, currentIndex, finalData) {
    if (currentIndex === finalData?.length) {
      outputArray.push({
        total_variant: currentVariant,
        // weightValue: '200',
        // weightUnit: 'pound',
        // length: 2,
        // width: 2,
        // height: 2,
        // unit: 'inch',
        // inventory_quantity: 100,
        // old_inventory_quantity: 200,
        isTaxable: false,
        isChecked: false,
        weightUnit: 'pound',
        unit: 'inch',
        old_inventory_quantity: 0,
        inventory_quantity: 0,
        price: '0',
        sku: '0',
        // grams: 530,
        // price: '50',
        // sku: 'dadsdadasdasd',
        // barcode: '5151511565656',
      })
      return
    }

    const currentVariants = finalData[currentIndex].data
    for (const variant of currentVariants) {
      const newVariant = [...currentVariant, variant]
      generateVariants(newVariant, currentIndex + 1, finalData)
    }
  }

  const handleSubmitSize = async () => {
    if (validateSize()) {
      if (addProductDetailSlice?.id?.variant?.dataEdit?.color) {
        // submitEditedSize({ name: 'Color', variant: colorArray, index: dataEdit?.index }, 'color')
        // setAddColorModalOpen(false)
        // setDataEdit({})
        let data = { name: 'Color', variant: colorArray, index: addProductDetailSlice?.id?.variant?.dataEdit?.index }

        let allVarientData = addProductDetailSlice?.id?.variant?.allVariant?.map((add, index) => {
          if (index === data?.index) {
            return { name: data?.name, data: data?.variant }
          } else {
            return add
          }
        })

        const finalAllData = allVarientData.map((item) => {
          if (item.name === 'Color' && Array.isArray(item.data)) {
            return {
              name: item.name.toLowerCase(),
              // data: item.data,
              data: item.data.map((colorObj) => colorObj.name),
            }
          }
          return item
        })

        await generateVariants([], 0, finalAllData)

        dispatch(
          manageAddProductDetail({
            modal: '',
            isOpen: false,
            id: {
              ...addProductDetailSlice?.id,
              variant: {
                ...addProductDetailSlice?.id?.variant,
                allVariant: addProductDetailSlice?.id?.variant?.allVariant?.map((add, index) => {
                  if (index === data?.index) {
                    return { name: data?.name, data: data?.variant }
                  } else {
                    return add
                  }
                }),
                colorCodeArray: addProductDetailSlice?.id?.variant?.allVariant?.map((add, index) => {
                  if (index === data?.index) {
                    return { name: data?.name, data: data?.variant }
                  } else {
                    return add
                  }
                }),
                allFinalVariant: addProductDetailSlice?.id?.variant?.allVariant?.map((add, index) => {
                  if (index === data?.index) {
                    return { name: data?.name, data: data?.variant }
                  } else {
                    return add
                  }
                }),
                allOptions: outputArray,
                isAddColorModalOpen: false,
                dataEdit: {},
              },
            },
          }),
        )
      } else {
        // submitSize({ name: 'Color', variant: colorArray }, 'color')
        // setAddColorModalOpen(false)
        // setDataEdit({})
        let data = { name: 'Color', variant: colorArray }
        dispatch(
          manageAddProductDetail({
            modal: '',
            isOpen: false,
            id: {
              ...addProductDetailSlice?.id,
              variant: {
                ...addProductDetailSlice?.id?.variant,
                allVariant: [
                  ...addProductDetailSlice?.id?.variant?.allVariant,
                  { name: data.name, data: data.variant },
                ],
                colorCodeArray: [
                  ...addProductDetailSlice?.id?.variant?.allVariant,
                  { name: data.name, data: data.variant },
                ],
                isAddColorModalOpen: false,
                dataEdit: {},
              },
            },
          }),
        )
      }
      // let variantErrorDetail = { ...addProductDetailSlice?.id?.variant?.variantError }
      // delete variantErrorDetail.color
      // dispatch(
      //   manageAddProductDetail({
      //     modal: '',
      //     isOpen: false,
      //     id: {
      //       ...addProductDetailSlice?.id,
      //       variant: {
      //         ...addProductDetailSlice?.id?.variant,
      //         variantError: variantErrorDetail,
      //       },
      //     },
      //   }),
      // )
    }
  }

  const handleDelete = (index) => {
    if (colorArray?.length > 1) {
      setColorArray(colorArray.filter((data, ind) => ind !== index))
    }
  }

  // useEffect(() => {
  //   if (addProductDetailSlice?.id?.variant?.dataEdit?.color) {
  //     setColorArray(addProductDetailSlice?.id?.variant?.dataEdit?.color?.data)
  //   }
  // }, [addProductDetailSlice?.id?.variant?.dataEdit])

  const handleCancel = () => {
    dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          variant: {
            ...addProductDetailSlice?.id?.variant,
            isAddColorModalOpen: false,
            dataEdit: {},
          },
        },
      }),
    )
  }

  return (
    <div className={styles.colorPickerModalAlignment}>
      <div className={styles.modalWrapper}>
        <div className={styles.modalBoxAlignment}>
          <div className={styles.modalHeaderAlignment}>
            <h3>Add Color Variant</h3>
            <div className={styles.headerButtonAlignment}>
              <div className={styles.cancleButtonAlignment}>
                <button onClick={() => handleCancel()}>Cancel</button>
              </div>
              {/* {!colorArray.includes("") ? ( 
                <div className="save-button-alignment active" onClick={handleSubmitSize}>
                  <button>Save and Close</button>
                </div>
              ) : (
                <div className="save-button-alignment">
                  <button>Save and Close</button>
                </div>
              )} */}
              <div className={classNames(styles.saveButtonAlignment, styles.active)} onClick={handleSubmitSize}>
                <button>Save and Close</button>
              </div>
              {/* <div className="save-button-alignment">
                <button>Save and Close</button>
              </div> */}
            </div>
          </div>
          <div className={styles.modalBodyAlignment}>
            <div className={styles.formAlignment}>
              <label>Option Value</label>
              {colorArray?.map((data, index) => {
                return (
                  <div className={styles.formInputDetailsAlignment} key={index}>
                    <div
                      className={styles.addInputButtonAlignment}
                      style={{ backgroundColor: data?.colorCode }}
                      onClick={() => HandleColorPicker(index, data?.colorCode)}
                    >
                      {data?.colorCode === '' && (
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10 4.16602V15.8327"
                            stroke="#556EE6"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4.16602 10H15.8327"
                            stroke="#556EE6"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </div>
                    <div className={styles.inputDetailsAlignment}>
                      <div
                        className={styles.inputAlignment}
                        // onClick={() => setColorDropdown(!ColorDropdown)}
                      >
                        <div className={styles.inputRelative}>
                          <input
                            type="text"
                            placeholder="Coral"
                            onChange={(e) => handleChange(e, index)}
                            value={data?.name}
                            onKeyPress={(e) => handleEnter(e, index)}
                            onClick={() => handleClickInput(index)}
                            autoFocus
                          />

                          <div className={styles.deleteIconAlignment} onClick={() => handleDelete(index)}>
                            <img src="/assets/seller/icons/transh-blue.svg" alt="Transh" />
                          </div>
                          <span style={{ color: 'red' }}>{error[index]}</span>
                        </div>
                      </div>
                    </div>
                    {inputColorIndex == index && (
                      <div className={styles.dropdownAlignmentColor} ref={colorPickerRef}>
                        <div style={styled.popover}>
                          <div style={styled.cover} />
                          <SketchPicker color={color} onChangeComplete={(e) => handleChangeComplete(e, index)} />
                          {/* <input type="color" /> */}
                        </div>
                      </div>
                    )}
                    {inputIndex == index && searchResult?.length > 0 && (
                      <div className={styles.dropdownAlignment} ref={categoryRef}>
                        <ul>
                          {(inputValue ? searchResult : sizeArray)?.map((val, i) => {
                            return (
                              <li onClick={() => handleSelect(index, val)} key={i}>
                                <div
                                  className={styles.coloBoxAalignment}
                                  style={{ backgroundColor: val?.colorCode }}
                                ></div>
                                {val?.name}
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
