import React from 'react'
import styles from './MobileViewInputOtpModal.module.scss'
import classNames from 'classnames'
export default function MobileViewInputOtpModal({ setIsMobileViewWithdrawRequestModal }) {
  return (
    <div className={styles.mobileViewWithdrawDetailsOtpModalSection}>
      <div className={styles.mobileViewWithdrawDetailsOtpModalHeading}>
        <h4>Withdrawal</h4>
        <a>Cancel</a>
      </div>

      <div className={styles.mobileViewWithdrawDetailsOtpAlignment}>
        <div className={styles.mobileKYCVerifiedCodeHeading}>
          <h4>Enter Code</h4>
          <p>We have sent you OTP to your registered email address for verification</p>
        </div>
        <div className={styles.bottomDetailsAlignment}>
          {/* <div className={classNames(styles.mobileKYCVerifiedCodeMainAlignment, styles.inCorrectPassWors)}> */}
          <div className={classNames(styles.mobileKYCVerifiedCodeMainAlignment, styles.successPassword)}>
            <div className={styles.incorrecrPassWordNotes}>
              <p>Incorrect code! Please try again.</p>
            </div>
            <div className={styles.mobileKYCVerifiedInputFlexAlignment}>
              <div className={styles.mobileKYCVerifiedInputAlignment}>
                <input type="text" />
              </div>
              <div className={styles.mobileKYCVerifiedInputAlignment}>
                <input type="text" />
              </div>
              <div className={styles.mobileKYCVerifiedInputAlignment}>
                <input type="text" />
              </div>
              <div className={styles.mobileKYCVerifiedInputAlignment}>
                <input type="text" />
              </div>
            </div>
          </div>
          <div className={styles.mobileKYCVerifiedOptionAlignment}>
            <a onClick={() => setIsMobileViewWithdrawRequestModal(true)}>Resend Code</a>
            <a>Back</a>
          </div>
        </div>
      </div>
    </div>
  )
}
