import { gql } from '@apollo/client'

export default gql`
  query {
    getAllCart {
      carts {
        storeDetail {
          id
          name
          logo_url
        }
        product {
          quantity
          product {
            id
            store_id
            cart_item_id
            title
            description
            isFeature
            isActive
            metaTitle
            keywords
            metaDescription
            isVisible
            isFreeShipping
            condition
            is_deleted
            variant {
              id
              title
              price
              listPrice
              inventory_quantity
              old_inventory_quantity
              image_id
              image {
                media
              }
              total_variant {
                variant_option_id
                variant_option {
                  value
                  variation_id
                  data {
                    name
                  }
                }
              }
            }
            images {
              media_id
              src
            }
            options {
              name
              data {
                value
                colorCode
              }
            }
            inventoryPrice {
              price
              listPrice
              quantity
              sku
            }
          }
        }
      }
    }
  }
`
