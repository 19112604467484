import React, { useEffect, useState } from 'react'
import styles from './MobileViewHotWatches.module.scss'
import CategoryProduct from '../CategoryProduct'
import { useAppDispatch } from 'src/store/store'
import { useRouter } from 'next/router'
import { fetchProductByCategory } from 'src/store/slices/fetchAllProduct'
import Link from 'next/link'

export default function MobileViewHotWatches() {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const [hotWatchesData, setHotWatchesData] = useState([])

  useEffect(() => {
    fetchProduct()
  }, [])

  const fetchProduct = () => {
    dispatch(
      fetchProductByCategory({
        category: 'Fashion & Accessories',
        subCategory: router?.asPath?.includes('women') ? 'Women' : 'Men',
        childSubCategory: router?.asPath?.includes('women') ? 'scarves' : 'sunglasses',
        isShopifyProduct: false,
        page: 1,
        limit: 6,
      }),
    ).then((response) => {
      setHotWatchesData(response?.payload?.getAllProducts?.data)
    })
  }
  return (
    <div className={styles.mobileViewHotWatchesModal}>
      <div className={styles.mobileViewHeading}>
        <h6>Hot Watches</h6>
      </div>

      <div className={styles.mobileViewBodyAlignment}>
        <div className={styles.mobileViewBannerAlignment}>
          <Link href={"/"}>
            <img src="/assets/img/new-banner-img.png" alt="new banner" />
          </Link>
        </div>

        <div className={styles.mobileViewHotWatchesDetailsAlignment}>
          <div className={styles.mobileViewHotWatchesGrid}>
            {hotWatchesData?.map((item, index) => {
              return <CategoryProduct index={index} item={item} />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
