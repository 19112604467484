import { useState, useContext, createContext, useEffect } from 'react'
import { fetchAllStoreProducts } from 'src/store/slices/fetchAllStoresSlice'
import { useAppDispatch } from 'src/store/store'

type ContextProps = {
  filterData: any | null
  setFilterData: (seller: any) => void
}

const ProductFilterContext = createContext<Partial<ContextProps>>({})

export const ProductFilterProvider = ({ children }) => {
  const [filterData, setFilterData] = useState<any>({ subCategory: [], category: [] })
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (Boolean(filterData?.subCategory?.length) || Boolean(filterData?.category?.length)) {
      dispatch(
        fetchAllStoreProducts({
          store_id: null,
          page: 1,
          limit: 20,
          search: '',
          status: '',
          subCategory: filterData?.subCategory?.map((ct) => ct?.subCategory),
          category: filterData?.subCategory?.map((ct) => ct?.category),
        }),
      )
    }
  }, [filterData])

  return <ProductFilterContext.Provider value={{ setFilterData, filterData }}>{children}</ProductFilterContext.Provider>
}

export const useProductFilter = () => useContext(ProductFilterContext)
