import { gql } from '@apollo/client'

const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!, $password: String!, $otp: String!) {
    resetPassword(email: $email, password: $password, otp: $otp) {
      message
      success
    }
  }
`
export default RESET_PASSWORD
