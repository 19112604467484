import PopOverComments from './PopOverComments'

const Avatar = (props: any) => {
  const customStyles = {
    container: {
      paddingLeft: props.avatarlist?.length === 1 ? 0 : props.radius / 2,
      display: 'flex',
    },
    avatarContainer: {
      width: props.radius,
      height: props.radius,
      minWidth: props.radius,
      maxWidth: props.radius,
      // padding: 3,
      marginLeft: props.avatarlist?.length === 1 ? 0 : props.mleft ? props.mleft : -props.radius / 2,
      borderRadius: props.radius,
      boxShadow: '0 0 6px rgba(0,0,0,0.2)',
      border: props.border + 'px solid #FFF',
    },
  }

  const content_comment = (item) => <PopOverComments name={item.name} avtar={item.avatarURL} comment={item.comment} />

  return (
    <div style={customStyles.container}>
      <div className="relative flex items-center">
        {props.avatarlist?.map((item: { id: number; avatarURL: string; name: string }, index) => (
          <div key={index}>
            {/* <Popover content={() => content_comment(item)} placement={props.type ? 'topLeft' : null}> */}
            <img src={item.avatarURL} key={item.id} style={customStyles.avatarContainer} className=" object-cover" />
            {/* </Popover> */}
          </div>
        ))}
      </div>
    </div>
  )
}
export default Avatar
