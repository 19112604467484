import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface manageMobileModalSlice {
  loading: true | false
  error: {} | null
  called: true | false
  data: any
}

const initialState = {
  loading: false,
  error: null,
  data: { allModalData: [], lastModalData: {} },
  called: false,
} as manageMobileModalSlice

export const fetchClubMobileModalData = createAsyncThunk(
  'fetchUserMobileModal/fetchClubMobileModalData',
  async (data: any) => {
    if (data) {
      data.allModalData = await data.allModalData
      data.lastModalData = await (data.allModalData && data.allModalData[data.allModalData?.length - 1])
    }
    return data
  },
)

const manageMobileModalSlice = createSlice({
  name: 'fetchUserMobileModal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchClubMobileModalData.pending, (state, action) => {
      state.loading = true
      state.error = null
      state.called = true
    })
    builder.addCase(fetchClubMobileModalData.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
      state.called = true
    })
    builder.addCase(fetchClubMobileModalData.rejected, (state, action) => {
      state.loading = false
      state.called = false
      state.error = action.error
      console.error('Error fetching cards!')
    })
  },
})

// export var _actions = manageMobileModalSlice.actions
export default manageMobileModalSlice.reducer
