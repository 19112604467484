import { gql } from '@apollo/client'

const GET_SINGLE_STORE_LIKE = gql`
  query GetStoreSingleLike($storeId: Int) {
    getStoreSingleLike(store_id: $storeId) {
      success
      message
      isLike
    }
  }
`
export default GET_SINGLE_STORE_LIKE
