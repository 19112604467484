//@ts-nocheck

import { useQuery } from '@apollo/client'
import styles from './UpcomingStreams.module.scss'
import { Button, Dropdown, Menu } from 'antd'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Slider from 'react-slick'
import GET_UPCOMING_LIVESTREAM from 'src/graphql/queries/getUpcomingLivestreams'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import { SampleNextArrow, SamplePrevArrow } from 'src/components/Arrrows'
import UpcomingStreamsCard from './UpcomingStreamsCard'
import NoDataSection from 'src/components/noDataSection'

export default function UpcomingStreams({ upcomingStreams, isHost, loading }: { upcomingStreams: any, isHost?: boolean, loading?: boolean }) {

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4.022,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1299,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },

      {
        breakpoint: 1099,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.03,
          slidesToScroll: 2,
          initialSlide: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.06,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const [isSort, setIsSort] = useState('All');
  const [filteredData, setFilteredData] = useState(upcomingStreams || []);

  const changeSortType = (e: { key: string }) => {
    setIsSort(e.key)
  }
  const SortType = (
    <Menu onClick={changeSortType}>
      <Menu.Item key="This Month">This Month</Menu.Item>
      <Menu.Item key="Highest">Highest</Menu.Item>
      <Menu.Item key="Lowest">Lowest</Menu.Item>
      <Menu.Item key="All">All</Menu.Item>
    </Menu>
  )
  useEffect(() => {
    let sortedData = upcomingStreams ? [...upcomingStreams] : [];
    if (isSort === 'This Month') {
      sortedData = sortedData.filter((item: any) =>
        moment(item?.final_date).isSame(moment(), 'month')
      )
    } else if (isSort === 'Highest') {
      sortedData = sortedData.sort((a: any, b: any) =>
        Number(b?.reaction_count) -
        Number(a?.reaction_count)
      )
    } else if (isSort === 'Lowest') {
      sortedData = sortedData.sort((a: any, b: any) =>
        Number(a?.reaction_count) -
        Number(b?.reaction_count)
      )
    }
    setFilteredData(sortedData)
  }, [upcomingStreams, isSort])

  const handleSeeAll = () => {
    dispatch(
      fetchModalData({
        allModalData: [
          ...getAllState?.allModalData,
          {
            modal: 'allStreams',
            isOpen: true,
            modalFor: 'bigModal',
            data: {
              streams: upcomingStreams,
              type: 'upcoming',
              title: 'Upcoming Live Streams',
            },
            startDate: moment().toISOString(),
          },
        ],
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  return (
    <>
      {upcomingStreams?.length === 0 || loading ? (
        <div className="mb-5 mt-10 ">
          <NoDataSection
            loading={loading}
            title="Upcoming Livestreams"
            text="There are no upcoming livestreams available at the moment."
          />
        </div>
      ) :
        <div className={styles.SellerSocialActivityEventUpcomingLiveStremSection}>
          <div className={styles.SellerSocialActivityEventUpcomingLiveStremBox}>
            <div className={styles.SellerSocialActivityEventUpcomingLiveStremHeadingAlignment}>
              <h4>Upcoming Livestreams</h4>

              <div className={styles.SellerSocialActivityEventUpcomingFilterAlignment}>
                <div className={styles.SellerSocialActivityEventUpcomingCalenderBoxAlignment}>
                  <Dropdown overlay={SortType} className=" right-0 flex justify-center h-14">
                    <Button className="filter-button dropdown-gap">
                      <div className="calnder-flex-alignment">
                        <img src="/assets/icon/calendar.svg" alt="calendar icon" />
                        {isSort}
                      </div>
                      <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L5 5L9 1" stroke="#636773" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </Button>
                  </Dropdown>
                </div>

                <div className={styles.SellerSocialActivityEventUpcomingSeeAllAlignment}>
                  <a onClick={handleSeeAll}>
                    See All <img src="/assets/icon/right-blue-arrow.svg" />
                  </a>
                </div>
              </div>
            </div>

            <div className={styles.SellerSocialActivityEventUpcomingLiveStremDetailsAlignment}>
              <Slider {...settings}>
                {filteredData?.map((item, index) => {
                  return (
                    <UpcomingStreamsCard
                      item={item}
                      isHost={isHost}
                    />
                  )
                })}
              </Slider>

            </div>
          </div>

        </div>}
    </>
  )
}
