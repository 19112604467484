import React, { useState, useCallback, useEffect } from 'react'
import styles from './ProductAdjustImageModal.module.scss'
import Cropper from 'react-easy-crop'
import { Point, Area } from 'react-easy-crop/types'
import { fetchPostAddEditModal } from 'src/store/slices/postAddEditSlice'
import { useAppDispatch } from 'src/store/store'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'
import { useSelector } from 'react-redux'
import getCroppedImg from 'src/utils/helperFuncs'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'

export default function ProductAdjustImageModal() {
  const dispatch = useAppDispatch()
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [media, setMedia] = useState({})
  const [selectedMedia, setSelectedMedia] = useState<any>({})
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({ width: 0, height: 0, x: 0, y: 0 })
  const [inputAspectRatio, setInputAspectRatio] = useState({ x: 1920, y: 1080 })
  const fetchProductImageResizeData = useSelector((state: any) => state?.productResizeData?.data)
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)

  const [inputSize, setInputSize] = useState({
    width: 1080,
    height: 1080,
    x: 0,
    y: 0,
  })

  const [mediaSize, setMediaSize] = useState({
    width: 0,
    height: 0,
    naturalWidth: 0,
    naturalHeight: 0,
  })

  const onCropComplete = useCallback((croppedArea, croppedAreaPixelsCB) => {
    setCroppedAreaPixels(croppedAreaPixelsCB)
  }, [])

  const handleCloseModal = () => {
    dispatch(fetchProductImageResizeModal({ ...fetchProductImageResizeData, modal: '', isOpen: false }))
  }

  useEffect(() => {
    if (fetchProductImageResizeData?.id?.media?.length > 0) {
      let imagesMedia =
        fetchProductImageResizeData?.id?.media?.filter(
          (med, index) => med?.id === fetchProductImageResizeData?.id?.selectedIndexofMediaForEdit, //FIELD_CHANGE
        ) &&
        fetchProductImageResizeData?.id?.media?.filter(
          (med, index) => med?.id === fetchProductImageResizeData?.id?.selectedIndexofMediaForEdit, //FIELD_CHANGE
        )[0]

      // const reader = new FileReader()
      // reader.addEventListener('load', () => {
      //   // setCurrentImage(reader.result)
      //   setSelectedMedia(reader.result)
      // })
      // reader.readAsDataURL(imagesMedia)

      let finalURL = imagesMedia?.newfile
      if (imagesMedia?.newfile?.type) {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          setSelectedMedia(reader.result)
        })
        reader.readAsDataURL(finalURL)
      } else {
        setSelectedMedia(finalURL)
      }
    }
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(selectedMedia, croppedAreaPixels, rotation)
      let mediaFile = fetchProductImageResizeData?.id?.media
      let mappedData = mediaFile?.map((_) => {
        if (_?.id === fetchProductImageResizeData?.id?.selectedIndexofMediaForEdit) {
          return { ..._, oldFile: { baseURL: croppedImage, zoom: Number(zoom), rotate: Number(rotation), crop: crop } }
        } else {
          return _
        }
      })
      dispatch(
        fetchProductImageResizeModal({
          modal: '',
          isOpen: false,
          id: {
            ...fetchProductImageResizeData?.id,
            media: mappedData,
          },
        }),
      )
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            productPreview: {
              ...addProductDetailSlice?.id?.productPreview,
              image: mappedData,
            },
          },
        }),
      )
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])

  useEffect(() => {
    if (Boolean(fetchProductImageResizeData?.id?.media?.length)) {
      let filteredData = fetchProductImageResizeData?.id?.media?.find(
        (me) => me?.id === fetchProductImageResizeData?.id?.selectedIndexofMediaForEdit,
      )
      if (filteredData) {
        setZoom(filteredData?.oldFile?.zoom)
        setCrop(filteredData?.oldFile?.crop)
        setRotation(filteredData?.oldFile?.rotate)
      }
    }
  }, [])

  return (
    <div className={styles.productAdjustImageModalSection}>
      <div className={styles.productAdjustImageModalWrapper}>
        <div className={styles.productAdjustImageModalBoxAlignment}>
          <div className={styles.productAdjustImageChildModal}>
            <div className={styles.productAdjustImageModalHeading}>
              <h4>Adjust Image</h4>
              <a onClick={() => handleCloseModal()}>Cancel</a>
            </div>
            <div className={styles.productAdjustImageAlignment}>
              <Cropper
                image={selectedMedia}
                crop={crop}
                rotation={rotation}
                minZoom={1}
                zoom={zoom}
                zoomWithScroll={true}
                showGrid={true}
                aspect={
                  fetchProductImageResizeData?.id?.selectedSize?.width /
                  fetchProductImageResizeData?.id?.selectedSize?.height
                }
                // objectFit="contain"
                setMediaSize={setMediaSize}
                restrictPosition={true}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onCropAreaChange={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <div className={styles.productOtherOptionAlignment}>
              <div className={styles.productAdjustRangeAlignment}>
                <div className={styles.productAdjustRangeBox}>
                  <h6>Zoom</h6>
                  <div className={styles.productAdjustRangeAlignment}>
                    <input
                      type="range"
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.0001}
                      aria-labelledby="Zoom"
                      onChange={(e: any) => {
                        setZoom(e.target.value)
                      }}
                    />
                  </div>
                </div>
                {/* <div className={styles.productAdjustRangeBox}>
                  <h6>Rotate</h6>
                  <div className={styles.productAdjustRangeAlignment}>
                    <input
                      type="range"
                      value={rotation}
                      min={0}
                      max={360}
                      step={1}
                      aria-labelledby="Zoom"
                      onChange={(e: any) => {
                        setRotation(e.target.value)
                      }}
                    />
                  </div>
                </div> */}
              </div>
              <div className={styles.productAdjustSaveChanges} onClick={showCroppedImage}>
                <button>Save Change</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
