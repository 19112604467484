import React from 'react'
import styles from './MobileViewWithdrawModal.module.scss'
import classNames from 'classnames'
export default function MobileViewWithdrawModal({ setIsMobileViewWithdrawDetailsModal, setIsMobileViewWithdrawModal }) {
  return (
    <div className={styles.mobileViewWithdrawModalSection}>
      <div className={styles.mobileViewWithdrawModalHeading}>
        <h4>Withdraw</h4>
        <a onClick={() => setIsMobileViewWithdrawModal(false)}>Cancel</a>
      </div>

      <div className={styles.mobileViewWithdrawModalDetails}>
        <div className={styles.mobileViewWithdrawAccountDetailsBoxAlignment}>
          <div className={styles.mobileViewMainAccountBalance}>
            <h5>Tommy Hilfiger Account Balance</h5>
            <span>(USD 6,775.75)</span>
          </div>

          <div className={styles.toAlignment}>
            <p>to</p>
          </div>

          <div className={styles.mobileViewBankDetails}>
            <p>Esther Howard</p>
            <span>Bank of America</span>
          </div>
        </div>

        <div className={styles.mobileAvailableAmountAlignment}>
          <p>Available amount </p>
          <p>$143,564.54</p>
        </div>
        <div className={styles.mobileViewAmountBoxAlignment}>
          <div className={styles.mobileViewWithdrawPriceDetails}>
            <label>Amount </label>
            <input type="text" value="$2,455" readOnly />
          </div>

          <div className={styles.mobileViewWithdrawPriceBox}>
            <div className={styles.mobileViewWithdrawPriceBoxGrid}>
              <div className={styles.mobileViewWithdrawPriceBoxGridItem}>
                <div className={styles.mobileViewWithdrawNumberBox}>
                  <p>1</p>
                </div>
              </div>
              <div className={styles.mobileViewWithdrawPriceBoxGridItem}>
                <div className={styles.mobileViewWithdrawNumberBox}>
                  <p>2</p>
                </div>
              </div>
              <div className={styles.mobileViewWithdrawPriceBoxGridItem}>
                <div className={styles.mobileViewWithdrawNumberBox}>
                  <p>3</p>
                </div>
              </div>
              <div className={styles.mobileViewWithdrawPriceBoxGridItem}>
                <div className={styles.mobileViewWithdrawNumberBox}>
                  <p>4</p>
                </div>
              </div>
              <div className={styles.mobileViewWithdrawPriceBoxGridItem}>
                <div className={styles.mobileViewWithdrawNumberBox}>
                  <p>5</p>
                </div>
              </div>
              <div className={styles.mobileViewWithdrawPriceBoxGridItem}>
                <div className={styles.mobileViewWithdrawNumberBox}>
                  <p>6</p>
                </div>
              </div>
              <div className={styles.mobileViewWithdrawPriceBoxGridItem}>
                <div className={styles.mobileViewWithdrawNumberBox}>
                  <p>7</p>
                </div>
              </div>
              <div className={styles.mobileViewWithdrawPriceBoxGridItem}>
                <div className={styles.mobileViewWithdrawNumberBox}>
                  <p>8</p>
                </div>
              </div>
              <div className={styles.mobileViewWithdrawPriceBoxGridItem}>
                <div className={styles.mobileViewWithdrawNumberBox}>
                  <p>9</p>
                </div>
              </div>
              <div className={styles.mobileViewWithdrawPriceBoxGridItem}>
                <div className={classNames(styles.mobileViewWithdrawNumberBox, styles.nonBgAlignment)}>
                  <p>.</p>
                </div>
              </div>
              <div className={styles.mobileViewWithdrawPriceBoxGridItem}>
                <div className={styles.mobileViewWithdrawNumberBox}>
                  <p>0</p>
                </div>
              </div>
              <div className={styles.mobileViewWithdrawPriceBoxGridItem}>
                <div className={classNames(styles.mobileViewWithdrawNumberBox, styles.nonBgAlignment)}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="delete">
                      <path
                        id="Vector"
                        d="M21 4H8L1 12L8 20H21C21.5304 20 22.0391 19.7893 22.4142 19.4142C22.7893 19.0391 23 18.5304 23 18V6C23 5.46957 22.7893 4.96086 22.4142 4.58579C22.0391 4.21071 21.5304 4 21 4Z"
                        stroke="#1E2432"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        id="Vector_2"
                        d="M18 9L12 15"
                        stroke="#1E2432"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        id="Vector_3"
                        d="M12 9L18 15"
                        stroke="#1E2432"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mobileVIewWithdrawButton}>
        <button onClick={() => setIsMobileViewWithdrawDetailsModal(true)}>Continue</button>
      </div>
    </div>
  )
}
