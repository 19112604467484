import React from 'react'
import styles from './MarketplaceFooter.module.scss'
import Link from 'next/link'

const FooterLogo = '/assets/img/footer-logo.svg'

const MarketplaceFooter = () => {
  return (
    <div className={styles.marketplaceFooter}>
      <div className="cus-container-md">
        <div className={styles.footerAlignment}>
          <div className={styles.leftContnet}>
            <img src={FooterLogo} alt="FooterLogo" />
            <span>@ 2024 Bluejestic, Inc. All rights reserved.</span>
          </div>
          <div className={styles.rightContnet}>
            <Link href="/terms-of-use">Terms</Link>
            <Link href="/privacy-policy">Privacy</Link>
            <a>Support</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarketplaceFooter
