import React from 'react'

export default function GalleryForMobileDetails({ splittedArray, setOpenImagePrev }) {
  return (
    <div className="image-card-flex">
      {splittedArray?.map((productColumn, idx) => {
        return (
          <div key={idx}>
            {productColumn?.map((item: any, index) => {
              return (
                <div
                  key={`l${index}`}
                  className="image-align-mobile"
                  onClick={() => setOpenImagePrev({ image: item.image, modal: true })}
                >
                  <img src={item.image} />
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}
