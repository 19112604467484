import React from 'react'
import { useSelector } from 'react-redux'
import ShopCubeSlider from 'src/components/Shop/ShopCubeSlider'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import styles from './ShopclubProduct.module.scss'
import DressesModalContent from '../Dressesmodalcontent'

export default function ShopCubProductSeeAll() {
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const dispatch = useAppDispatch()

  const handleOpenModal = ({ modalName, title, description, color }) => {
    dispatch(
      fetchModalData({
        allModalData: [
          ...getAllState?.allModalData,
          {
            modal: modalName,
            isOpen: true,
            id: {
              title,
              description,
              color,
            },
            modalFor: 'bigModal',
          },
        ],
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  const handleClose = () => {
    let filtredModal = getAllState?.allModalData?.filter((item) => item?.modal !== getAllState?.lastModalData?.modal)
    dispatch(
      fetchModalData({
        ...getAllState,
        allModalData: filtredModal,
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  return (
    <div className={styles.ShopclubeSlidermodalmain}>
      <div className={styles.ShopclubeSlidermodal}>
        <div className={styles.trendingtitle}>
          <div onClick={handleClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
              <path
                d="M27.0791 0.40918H10.0791C4.55625 0.40918 0.0791016 4.88633 0.0791016 10.4092V22.4092C0.0791016 27.932 4.55625 32.4092 10.0791 32.4092H27.0791C32.6019 32.4092 37.0791 27.932 37.0791 22.4092V10.4092C37.0791 4.88633 32.6019 0.40918 27.0791 0.40918Z"
                fill="white"
              />
              <path
                d="M21.0791 10.4092L16.0791 16.4092L21.0791 22.4092"
                stroke="#808191"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <h1>Trending Products</h1>
          <div></div>
        </div>
        <div>
          <ShopCubeSlider
            handleOpenModal={handleOpenModal}
            modalName={'shopCubProductSeeAll'}
            title={getAllState?.lastModalData?.id?.title}
            description={getAllState?.lastModalData?.id?.description}
            isSeeAll={false}
            height={'auto'}
            color={getAllState?.lastModalData?.id?.color}
            childSubCategoryId={getAllState?.lastModalData?.id?.childSubCategoryId}
            titleColor={'#FFFFFF'}
            descriptionColor={'#FFFFFF'}
          />
        </div>
        {/* <DressesModalContent /> */}
      </div>
    </div>
  )
}
