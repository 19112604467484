import React from 'react'
import styles from './AddProductModalTitle.module.scss'
import classNames from 'classnames'
import { fetchProductErrorHandler } from 'src/store/slices/productErrorHandlerSlice'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'

export default function AddProductModalTitle({ setProductData, productData, validation, setvalidation }) {
  const fetchProductErrorHandlerSlice = useSelector((state: any) => state?.productErrorHandlerSlice?.data)
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const dispatch = useAppDispatch()

  const handleChnage = async (e) => {
    // let errors = {}
    // let inputValue = e.target.value
    // if (inputValue.trim() === '') {
    //   errors = { ...validation, ...errors, title: '* Enter Input' }
    //   dispatch(
    //     fetchProductErrorHandler({
    //       ...fetchProductErrorHandlerSlice,
    //       focusFiled: 'title',
    //       errors: { ...fetchProductErrorHandlerSlice?.errors, title: '* Enter Input' },
    //     }),
    //   )
    // } else {
    //   let latestObject = { ...fetchProductErrorHandlerSlice?.errors }
    //   delete latestObject.title
    //   dispatch(
    //     fetchProductErrorHandler({
    //       ...fetchProductErrorHandlerSlice,
    //       focusFiled: 'title',
    //       errors: latestObject,
    //     }),
    //   )
    // }
    setProductData({ ...productData, title: e.target.value })
    setvalidation({ ...validation, title: '' })

    await dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          productPreview: {
            ...addProductDetailSlice?.id?.productPreview,
            title: e.target.value,
          },
          mobileProductsData: {
            ...addProductDetailSlice?.id?.mobileProductsData,
            title: e.target.value,
          },
        },
      }),
    )
  }

  return (
    <div className={styles.addProductTitleAlignment}>
      <div className={styles.addProductTitleBoxAlignment}>
        <h4>
          Product Title<span className={styles.starAlignment}>*</span>
          {/* <span className={styles.starAlignment}>{validation?.title}</span> */}
        </h4>
        <div className={classNames(styles.addProductTitleInput, validation?.title ? styles.titleError : '')}>
          <input
            // required
            type="text"
            placeholder="e.g. Trendy Queen Women's Turtleneck Long Sleeve Fall Fashion 2022"
            onChange={(e) => handleChnage(e)}
            autoFocus
            value={productData?.title}
            maxLength={120}
          />
        </div>
        <div className={styles.totalTextAlignment}>
          <span>{productData?.title?.length > 0 ? productData?.title?.length : 0}/120</span>
        </div>
      </div>
    </div>
  )
}
