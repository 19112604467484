import React from 'react'
import styles from './ProductPagination.module.scss'
import classNames from 'classnames'

export default function ProductPagination({ pages = 1, current = 1, onClick, isMobile }: any) {
  const handleClick = (event: React.MouseEvent, page: number) => {
    event.preventDefault();
    onClick && onClick(page);
  };
  const onPrev = (event: React.MouseEvent) => handleClick(event, current - 1);
  const onNext = (event: React.MouseEvent) => handleClick(event, current + 1);
  const onPrevDash = (event: React.MouseEvent) => handleClick(event, current - 2);
  const onNextDash = (event: React.MouseEvent) => handleClick(event, current + 2);
  const onFirst = (event: React.MouseEvent) => handleClick(event, 1);
  const onLast = (event: React.MouseEvent) => handleClick(event, pages);

  if (pages <= 1) {
    return null; // Don't render pagination if there's only one page or less
  }

  return (
    <div
      className={`${styles.filterPaginationAlignment} 
    ${isMobile ? `${styles.menPagination}` : ''}`}
    >
      {current !== 1 ? (
        <div className={styles.paginationBox} onClick={onPrev}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M9.99964 12.0011L5.99948 8.00089L9.99964 4.00073"
              stroke="#7A7E88"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ) : (
        <div className={classNames(styles.paginationBox, styles.opacity)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M9.99964 12.0011L5.99948 8.00089L9.99964 4.00073"
              stroke="#7A7E88"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
      {current !== 1 && (
        <div className={classNames(styles.paginationBox)} onClick={onFirst}>
          <p>1</p>
        </div>
      )}
      {current > 3 && (
        <div className={styles.paginationBox} onClick={onPrevDash}>
          <p>...</p>
        </div>
      )}
      {current - 1 > 1 && (
        <div className={styles.paginationBox} onClick={onPrev}>
          <p>{current - 1}</p>
        </div>
      )}
      <div className={classNames(styles.paginationBox, styles.activePaginationNumber)}>
        <p>{current}</p>
      </div>
      {current + 1 < pages && (
        <div className={styles.paginationBox} onClick={onNext}>
          <p>{current + 1}</p>
        </div>
      )}
      {current <= pages - 3 && (
        <div className={styles.paginationBox} onClick={onNextDash}>
          <p>...</p>
        </div>
      )}
      {current !== pages && !Number.isNaN(pages) && (
        <div className={styles.paginationBox} onClick={onLast}>
          <p>{pages}</p>
        </div>
      )}

      {current !== pages ? (
        <div className={styles.paginationBox} onClick={onNext}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M6.00004 3.99831L10.0004 7.99864L6.00004 11.999"
              stroke="#7A7E88"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ) : (
        <div className={classNames(styles.paginationBox, styles.opacity)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M6.00004 3.99831L10.0004 7.99864L6.00004 11.999"
              stroke="#7A7E88"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
    </div>
  )
}
