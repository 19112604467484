import React from 'react'
import styles from './MobileViewWithdrawRequestModal.module.scss'
export default function MobileViewWithdrawRequestModal({ setIsMobileViewWithdrawTransactionDetailsModal }) {
  return (
    <div className={styles.mobileViewWithdrawRequestModalSection}>
      <div className={styles.mobileViewWithdrawRequestModalHeading}>
        <h4>Withdraw</h4>
        <a>Cancel</a>
      </div>
      <div className={styles.mobileViewWithdrawRequestModalAlignment}>
        <div className={styles.mobileViewWithdrawRequestModalFirstPart}>
          <div className={styles.mobileViewWithdrawRequestIcon}>
            <img src="/assets/seller/icons/submission-icon.png" alt="submit" />
          </div>
          <p>Your withrawal Request has been processed</p>
        </div>

        <div className={styles.mobileViewWithdrawRequestModalBottomPart}>
          {/* <div className={styles.requestOkButton}>
          <button>OK</button>
        </div> */}

          <div className={styles.checkStatusButtonAlignment}>
            <button onClick={() => setIsMobileViewWithdrawTransactionDetailsModal(true)}>Check Status</button>
          </div>
        </div>
      </div>
    </div>
  )
}
