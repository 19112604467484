import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface manageAddProduct {
  loading: true | false
  error: {} | null
  called: true | false
  data: any
}

const initialState = {
  loading: false,
  error: null,
  data: {
    modal: '',
    isOpen: false,
    id: {
      selectedCategories: [],
      selectedColorArray: [],
      selectedColor: '',
      selectedText: '',
      variant: {
        isAddVariantModal: false,
        isAddColorModalOpen: false,
        isAddOtherVariantModalOpen: false,
        dataEdit: {},
        allVariant: [],
        colorCodeArray: [],
        allFinalVariant: [],
        variantError: {},
        isVariantModel: false,
        colorStates: [],
      },
      isEditableData: {
        index: null,
        data: null,
      },
      productPreview: {
        title: '',
        image: '',
        price: '',
        listPrice: '',
      },
      mobileProductsData: [],
      filteredList: [],
      filterColorList: [],
      newVariantDataState: [],
      productDetails: {
        productDetailVariant: {},
        productSelectedOption: {},
        productDetailColor: '',
      },
    },
  },
  called: false,
} as manageAddProduct

export const manageAddProductDetail = createAsyncThunk(
  'fetchProductDetail/manageAddProductDetail',
  async (data: any) => {
    return data
  },
)

const manageAddProduct = createSlice({
  name: 'fetchProductDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(manageAddProductDetail.pending, (state, action) => {
      state.loading = true
      state.error = null
      state.called = true
    })
    builder.addCase(manageAddProductDetail.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
      state.called = true
    })
    builder.addCase(manageAddProductDetail.rejected, (state, action) => {
      state.loading = false
      state.called = false
      state.error = action.error
      console.error('Error fetching cards!')
    })
  },
})

// export var _actions = manageAddProduct.actions
export default manageAddProduct.reducer
