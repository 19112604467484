import React from 'react'
import styles from './CategoryProduct.module.scss'
import { useRouter } from 'next/router'
export default function CategoryProduct({ index, item }) {
  const router = useRouter()

  return (
    <div className={styles.hotWatchesAllDetailsAlignment} key={index}
      onClick={() => {
        router.push(`/product/${item?.slug}`)
      }}
    >
      <div className={styles.hotWatchesImg}>
        <img src={item?.images?.[0]?.src} alt="watches" />

        {/* <div className={styles.offerLabel}>
          <p>25% off</p>
        </div> */}
      </div>
      <div className={styles.hotWatchesDetails}>
        <h6>{item?.title}</h6>
        <div className={styles.priceALignment}>
          <p>${item?.dis_price}</p>
        </div>
      </div>
    </div>
  )
}
