import { gql } from '@apollo/client'

const GET_ORDER_DETAILS = gql`
  query getOrderDetails($getOrderDetailsId: Int) {
    getOrderDetails(id: $getOrderDetailsId) {
      success
      message
      data {
        id
        generate_id
        total
        quantity
        price
        order_status
        createdAt
        shipping_address {
          streetAddress
          city
          state
          country
          zipcode
          buildingName
        }
        card_details {
          card_number
          expiry_date
          cvv
        }
        customer {
          id
          firstName
          lastName
          profileAvtar
          profileCoverImage
          address
          state
          city
          country
          phone
        }
        product {
          id
          title
          sku
          cropImages {
            id
            oldFile
            croppedFile {
              baseURL
              rotate
              zoom
              crop {
                x
                y
              }
            }
          }
        }
        order_id
      }
    }
  }
`
export default GET_ORDER_DETAILS
// $limit: Int, $page: Int
//  limit: $limit, page: $page
