import { gql } from '@apollo/client'
import userFragment from '../fragments/userFragment'

export default gql`
  query GETUSERBYNAME($slug: String!) {
    getUserbyName(slug: $slug) {
      id
      firstName
      lastName
      userName
      jobTitle
      phoneNumber
      bday
      address
      subAddress
      marital_status
      occupation
      education
      interest
      about
      experience
      hobbies
      gender
      city
      state
      country
      zipCode
      logo_image
      banner_image
      isActiveForFriendStatus
      isFriendForFriendStatus
      followings: followers {
        id
        user {
          id
          firstName
          lastName
          userName
          follower_count
          following_count
          isActiveForFriendStatus
          isFriendForFriendStatus
          logo_image
          banner_image
          mutualFriends {
            id
            firstName
            logo_image
            banner_image
          }
        }
      }
      followers: followings {
        id
        user: friend {
          id
          firstName
          lastName
          userName
          follower_count
          following_count
          isActiveForFriendStatus
          isFriendForFriendStatus
          logo_image
          banner_image
          mutualFriends {
            id
            firstName
            logo_image
            banner_image
          }
        }
      }
      medias {
        id
        url
      }
    }
  }
`
