//@ts-nocheck

import React from 'react'
import styles from './PastStreams.module.scss'
import moment from 'moment'
import Slider from 'react-slick'

import { SampleNextArrow, SamplePrevArrow } from 'src/components/Arrrows'
import PastStreamsCard from './PastStreamsCard';
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import NoDataSection from 'src/components/noDataSection'

export default function PastStreams({ pastStreams, isHost, loading }: { pastStreams: any, isHost?: boolean, loading?: boolean }) {
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4.022,
    slidesToScroll: 4,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1299,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },

      {
        breakpoint: 1099,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.03,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.03,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.06,
          slidesToScroll: 2,
        },
      },
    ],
  }
  const handleSeeAll = () => {
    dispatch(
      fetchModalData({
        allModalData: [
          ...getAllState?.allModalData,
          {
            modal: 'allStreams',
            isOpen: true,
            modalFor: 'bigModal',
            data: {
              streams: pastStreams,
              type: 'past',
              title: 'Past Live Streams',
            },
            startDate: moment().toISOString(),
          },
        ],
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }
  return (
    <>
      {pastStreams?.length === 0 || loading ? (
        <div className="mt-5 pb-2">
          <NoDataSection
            loading={loading}
            title="Past Livestreams"
            text="There are no past livestreams available at the moment."
          />
        </div>
      ) :
        <div className={styles.sellerSocialPastLiveStreamsSection}>
          <div className={styles.sellerSocialPastLiveStreamsBox}>
            <div className={styles.sellerSocialPastLiveStreamsHeadingAlignment}>
              <h4 className="ml-3">All Past Live Streams</h4>
              <a onClick={handleSeeAll}>See more <img src="/assets/icon/right-blue-arrow.svg" /></a>
            </div>

            <div className={styles.sellerSocialPastLiveStreamsDetailsAlignment}>
              <Slider {...settings}>
                {pastStreams?.map((item, index) => {
                  return (
                    <PastStreamsCard item={item} key={index} />
                  )
                })}
              </Slider>
            </div>
          </div>
        </div>}
    </>
  )
}
