import { gql } from '@apollo/client'

const query = gql`
  mutation DELETELIKE($id: Int!) {
    disLike(input: { id: $id }) {
      # like {

      user {
        id
      }
      # }
    }
  }
`

export default query
