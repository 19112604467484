import { gql } from '@apollo/client'

const GET_SINGLE_CATEGORY = gql`
  query GetSingleCategory(
    $type: String
    $categoryName: String
    $subcategoryName: String
    $childsubSlug: String
    $nestedSlug: String
  ) {
    getSingleCategory(
      type: $type
      category_name: $categoryName
      subcategory_slug: $subcategoryName
      childsub_slug: $childsubSlug
      nested_slug: $nestedSlug
    ) {
      success
      message
      data {
        id
        name
        media
        description
        banner_media
        parent_id
        position
        slug
      }
    }
  }
`
export default GET_SINGLE_CATEGORY
