import { gql } from '@apollo/client'

const query = gql`
  mutation ($groupId: Int!, $userId: [Int]!) {
    inviteGroups(input: { group_id: $groupId, user_id: $userId }) {
      id
      group_id
      user_id
    }
  }
`
export default query
