import React from 'react'
import { useAuth } from 'src/utils/auth'
import getImageUrl from 'src/utils/getImageUrl'
import InputWithEmoti from '../InputWithEmoti'

export default function PostCommentInput(props) {
  const { user }: any = useAuth()
  const { post, type, store, isButton, isPost, isMobileView, isMobileButton } = props
  return (
    <div className="post-footer flex items-center max-w-fit commentbox-padding">
      <img
        src={
          type === 'store'
            ? user?.store?.logo_image
            : user?.logo_image
            ? getImageUrl(user?.logo_image)
            : '/assets/img/dummy-profile.svg'
        }
        alt={user?.profileImage?.alternativeText}
        className="rounded-full object-cover comment-image"
      />
      <InputWithEmoti
        post={post}
        type={type}
        isPost={isPost}
        store={store}
        isButton={isButton}
        isMobileView={isMobileView}
        isMobileButton={isMobileButton}
      />
    </div>
  )
}
