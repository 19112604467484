import React from 'react'
import { useAuth } from 'src/utils/auth'
import getImageUrl from 'src/utils/getImageUrl'

const CoverPhoto = (props) => {
  const { currentUser } = props
  const { user } = useAuth()

  return (
    <div className="align-items-center d-flex flex-column left-sidebar-profile">
      <div className="left-sidebar-profile-photo m-auto">
        <img
          src={
            currentUser?.user?.logo_image
              ? getImageUrl(currentUser?.user?.logo_image)
              : user?.logo_image ?? '/assets/img/dummy-profile.svg'
          }
          width={100}
          height={100}
          className="object-cover"
        />
      </div>
      <div className="left-sidebar-user-name">
        {currentUser?.user ? (
          <h6>
            {currentUser?.user?.firstName && currentUser?.user?.lastName
              ? currentUser?.user?.firstName + ' ' + currentUser?.user?.lastName
              : ''}{' '}
          </h6>
        ) : (
          <h6>{user?.firstName && user?.lastName ? user?.firstName + ' ' + user?.lastName : ''} </h6>
        )}
      </div>
    </div>
  )
}

export default CoverPhoto
