import React from 'react'
import styles from './RisingStoresModal.module.scss'
import classNames from 'classnames'
export default function RisingStoresModal() {
  return (
    <div className={styles.risingStoresModalSection}>
      <div className={styles.risingStoresModalHeader}>
        <h4>Rising Stores</h4>
      </div>
      <div className={styles.risingStoresModalDetailsAlignment}>
        <div className={styles.risingStoresModalGridAlignment}>
          {[0, 1, 2, 3, 4, 5, 6].map((_, index) => {
            return (
              <div className={styles.risingStoresModalGridItemAlignment} key={index}>
                <div className={styles.shopRisingStoresDetailsBoxAlignment}>
                  <div className={styles.shopRisingStoreProductDetailsAlignment}>
                    <div className={styles.shopRisingStoreProductAllDetails}>
                      <div className={styles.shopRisingStoreProductAllImg}>
                        <img src="/assets/shop/img/rsi1.png" alt="rising store" />
                      </div>

                      <div className={styles.shopRisingStoreProductOtherDetails}>
                        <h5>ShoeStars Store</h5>
                        <p>208 Products</p>

                        <div className={styles.followStoreButton}>
                          <button>Follow Store</button>
                        </div>
                      </div>
                    </div>

                    <div className={styles.shopRisingLikeShareButton}>
                      <div className={styles.shopRisingLikeShareIconTotal}>
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M7.5 4C4.46244 4 2 6.43905 2 9.44773C2 14.8955 8.5 19.848 12 21C15.5 19.848 22 14.8955 22 9.44773C22 6.43905 19.5375 4 16.5 4C14.6399 4 12.9954 4.91467 12 6.31469C11.0046 4.91467 9.36015 4 7.5 4Z"
                              stroke="#7A7E88"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <p>234k</p>
                      </div>
                      <div className={styles.shopRisingLikeShareIconTotal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z"
                            stroke="#7A7E88"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
                            stroke="#7A7E88"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z"
                            stroke="#7A7E88"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.58984 13.51L15.4198 17.49"
                            stroke="#7A7E88"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M15.4098 6.51001L8.58984 10.49"
                            stroke="#7A7E88"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className={styles.shopRisingChildImagesAlignment}>
                    <div className={styles.shopRisingChildGrid}>
                      {[0, 1, 2, 3].map((_, index) => {
                        return (
                          <div className={styles.shopRisingChildImg} key={index}>
                            <img src="/assets/shop/img/rsci1.png" alt="rising store img" />
                          </div>
                        )
                      })}
                      <div className={classNames(styles.shopRisingChildImg, styles.lastImgAlignment)}>
                        <img src="/assets/shop/img/rsci1.png" alt="rising store img" />
                        <div className={styles.shopRisingMoreImg}>
                          <p>
                            <span>204 </span>more
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
