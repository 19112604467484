// @ts-nocheck


import React, { useState } from 'react'
import LeftIcon from '../../../../../public/assets/icon/left-icon-black.svg'
import LeftWhiteIcon from '../../../../../public/assets/icon/left-icon-white.svg'
import RightIcon from '../../../../../public/assets/icon/right-icon-black.svg'
import RightWhiteIcon from '../../../../../public/assets/icon/right-icon-white.svg'
import Slider from 'react-slick'
import { useAuth } from 'src/utils/auth'
import Avatar from 'src/components/shared/Avatar'
import CREATE_LIKE_MUTATION from 'src/graphql/mutations/createLike'
import DELETE_LIKE_MUTATION from 'src/graphql/mutations/deleteLike'
import CREATE_COMMENT_REPLY from 'src/graphql/mutations/createCommentReply'
import { useMutation } from '@apollo/client'
import InputWithEmoti from 'src/components/Feed/NormalPost/InputWithEmoti'
import Loader from 'src/components/UI/Loader'
import DemoImg from '../../../../../public/assets/img/demoimg3.png'
import PopOverAllComments from 'src/components/Feed/NormalPost/PopOver/PopOverAllComments'
import PostHeader from 'src/components/Feed/NormalPost/PostHeader'
// import MY_FEED_QUERY from 'src/graphql/queries/getMyFeed'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAppDispatch } from 'src/store/store'
import LazyImage from 'src/utils/LazyImage'
import { fetchUserPost } from 'src/store/slices/fetchAllFeedSlice'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <img src={RightIcon.src} alt="RightIcon" className="defalt-icon" />
      <img src={RightWhiteIcon.src} alt="RightWhiteIcon" className="hover-img" />
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <img src={LeftIcon.src} alt="LeftIcon" className="defalt-icon" />
      <img src={LeftWhiteIcon.src} alt="LeftWhiteIcon" className="hover-img" />
    </div>
  )
}

function ImgNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className="img-slider-next" onClick={onClick}>
      <img src={RightIcon.src} alt="RightIcon" className="defalt-icon" />
    </div>
  )
}

function ImgPrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className="img-slider-prev" onClick={onClick}>
      <img src={LeftIcon.src} alt="LeftIcon" className="defalt-icon" />
    </div>
  )
}

export default function MediaProfileModal(props) {
  const { setMediaModal, postId, mediaData, editPostHandler } = props
  const { user }: any = useAuth()
  const dispatch = useAppDispatch()
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }
  const ImageSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ImgNextArrow />,
    prevArrow: <ImgPrevArrow />,
  }

  let width
  if (typeof window !== 'undefined') {
    width = window.innerWidth
  }

  // const { loading, error, data, refetch } = useQuery(GET_POST_BY_ID, { variables: { post_id: postId?.id } })

  const content_comments = (comments) => <PopOverAllComments comments={comments} />

  // let selectedPost = data?.getSinglePost?.attachements?.map((media) => {
  //   return { ...media, ...data?.getSinglePost }
  // })

  const [showMore, setShowMore] = useState(false)
  const [isClose, isSetClose] = useState(true)
  const [replyCommentToggle, setReplyCommentToggle] = useState(9999)
  const [replyCommentText, setReplyCommentText] = useState<any>('')
  const [moreComments, setMoreComments] = useState(null)
  const [commentsLike, setCommentsLike] = useState<any>({})
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const [userId, setUserId] = useState(null)

  const HandleLike = (post, type, liked) => {
    if (user) {
      // if (isNormalPost) {
      normalPostLikeHandler(post, type, liked)
      // } else if (isStorePost) {
      //   storePostLikeHandler(post)
      // } else if (isGroupPost) {
      //   groupPostLikeHandler(post)
      // }
    } else {
      handleRedirect()
    }
  }

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const normalPostLikeHandler = (post, type, liked) => {
    let likeFor = !post?.product ? 'post' : 'sharepost'
    setUserId(post?.user?.id)
    if (liked) {
      deleteLike({
        variables: { id: liked.id, like_for: likeFor },
        // refetchQueries: [{ query: MY_FEED_QUERY, variables: { user_id: post?.user?.id } }],
      })
      return
    }
    createLike({
      variables: { parent_id: post.id, like_for: likeFor },
      // refetchQueries: [{ query: MY_FEED_QUERY, variables: { user_id: post?.user?.id } }],
    })
  }

  const [createLike] = useMutation(CREATE_LIKE_MUTATION, {
    onCompleted: (res) => {
      dispatch(fetchUserPost({ page, limit, user_id: userId, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const [deleteLike] = useMutation(DELETE_LIKE_MUTATION, {
    onCompleted: (res) => {
      dispatch(fetchUserPost({ page, limit, user_id: userId, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const handleChangeReply = (e) => {
    setReplyCommentText(e.target.value)
  }

  const replyComment = (id) => {
    if (id === replyCommentToggle) {
      setReplyCommentToggle(9999)
    } else {
      setReplyCommentToggle(id)
    }
  }

  const handleReplyComment = (comment) => {
    let comment_for = 'post'
    setUserId(comment?.user?.id)
    createCommentReply({
      variables: { comment: replyCommentText, reply_id: comment?.id, comment_for: comment_for, parent_id: postId?.id },
      // refetchQueries: [{ query: MY_FEED_QUERY, variables: { user_id: comment?.user?.id } }],
    }).then((response) => {
      if (response !== undefined) {
        setReplyCommentToggle(9999)
      }
    })
  }

  const [createCommentReply] = useMutation(CREATE_COMMENT_REPLY, {
    onCompleted: (res) => {
      dispatch(fetchUserPost({ page, limit, user_id: userId, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const handleLikePost = (comment, value) => {
    let liked
    if (value === 'commentreply') {
      liked = comment?.commentReplyLike?.find((like) => like?.user?.id === user.id)
    } else {
      liked = comment?.likes?.find((like) => like?.user?.id === user.id)
    }
    setUserId(comment?.user?.id)
    setCommentsLike(liked)
    if (liked) {
      deleteLike({
        variables: { id: liked?.id, like_for: value },
        // refetchQueries: [{ query: MY_FEED_QUERY, variables: { user_id: comment?.user?.id } }],
      })
      return
    }
    createLike({
      variables: { parent_id: comment.id, like_for: value },
      // refetchQueries: [{ query: MY_FEED_QUERY, variables: { user_id: comment?.user?.id } }],
    })
  }

  const timeAgo = (prevDate) => {
    const diff = Number(new Date()) - prevDate
    const minute = 60 * 1000
    const hour = minute * 60
    const day = hour * 24
    const month = day * 30
    const year = day * 365
    // if (moment(prevDate).format('DD/MM/YYY') === moment(new Date()).format('DD/MM/YYY')) {
    switch (true) {
      case diff < minute:
        const seconds = Math.round(diff / 1000)
        return `${seconds} ${seconds > 1 ? 'seconds' : 'second'} ago`
      case diff < hour:
        return Math.round(diff / minute) + 'm ago'
      case diff < day:
        return Math.round(diff / hour) + 'h ago'
      case diff < month:
        return Math.round(diff / day) + 'day ago'
      case diff < year:
        return Math.round(diff / month) + 'month ago'
      case diff > year:
        return Math.round(diff / year) + 'y ago'
      default:
        return ''
    }
    // } else {
    //   return moment(prevDate).format('DD MMM [at] hh:mm A')
    // }
  }

  const editPost = (post) => {
    editPostHandler({ id: post.id, content: post?.content, isEdit: true })
  }

  const handleComment = (post) => {
    if (user) {
      if (post?.comments?.length > 0) isSetClose(!isClose)
      setShowMore(false)
    } else {
      handleRedirect()
    }
  }

  const editPostMobile = () => {}
  const handelImageSlider = () => {}

  // if (error) return <p>`Error! ${error.message}`</p>

  return (
    <div className="social-profile-media-alignemt">
      <div className="social-profile-wrapper">
        <div className="social-profile-modal-box-alignment 111111">
          {false ? (
            <Loader />
          ) : (
            <Slider {...settings}>
              {mediaData?.map((post, i) => {
                const liked = post?.likes?.find((like) => like?.user?.id === user?.id)
                const isStorePost = post?.post_for === 'SELLER' ? true : false
                const isNormalPost = post?.post_for === 'USER' ? true : false
                const isGroupPost = post?.post_for === 'GROUP' ? true : false
                return (
                  <div className="social-profile-slider-flex-alignment" key={i}>
                    <div className="social-profile-first-part-alignment">
                      <Slider {...ImageSlider}>
                        {post?.attachements?.map((att, index) => {
                          return (
                            <div className="child-img-media-alignment" key={`u${index}`}>
                              <img src={att?.url} alt="product" />
                            </div>
                          )
                        })}
                      </Slider>
                    </div>
                    <div className="social-profile-second-part-alignment">
                      <div className="social-profile-modal-details-alignment">
                        <div className="social-profile-flex-alignment">
                          <PostHeader
                            isNormalPost={isNormalPost}
                            isGroupPost={isGroupPost}
                            isStorePost={isStorePost}
                            post={post}
                            store={post?.store !== null ? post?.store : {}}
                            setMediaModal={setMediaModal}
                            editPost={editPost}
                            editPostMobile={editPostMobile}
                          />
                        </div>
                      </div>

                      <div className="social-profile-height-alignment">
                        <div className="social-profile-notes-alignment">
                          <p> {post?.content?.length > 84 ? post?.content.substring(0, 84) + '...' : post?.content}</p>
                        </div>
                        <div className="post-comment-sticky-alignment">
                          <div className="flex justify-between items-center md:mr-6 comment-part comment-border-line">
                            <div className="post-comment-share-section">
                              <div
                                className="post-comment-section"
                                onClick={() => {
                                  handleComment(post)
                                }}
                              >
                                <img src={'/assets/icon/feed/comment-post-icon.svg'} />
                                <span>{post?.comments?.length === 0 ? '' : post?.comments?.length}</span>
                              </div>
                              <div className="post-share-section" onClick={handleRedirect}>
                                <img src={'/assets/icon/feed/share-post-icon.svg'} />
                                {/* <span>{post?.likes?.length}</span> */}
                                {/* <span>{post?.comments?.length === 0 ? '' : post?.comments?.length}</span> */}
                              </div>
                            </div>
                            <div className="post-like-section">
                              <svg
                                width="24"
                                height="21"
                                viewBox="0 0 24 21"
                                fill={liked ? '#FF0000' : '#FFFFFF'}
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => HandleLike(post, post?.post_for, liked)}
                              >
                                <path
                                  d="M7.1263 1.33594C3.83561 1.33594 1.16797 4.00359 1.16797 7.29427C1.16797 13.2526 8.20963 18.6693 12.0013 19.9293C15.793 18.6693 22.8346 13.2526 22.8346 7.29427C22.8346 4.00359 20.167 1.33594 16.8763 1.33594C14.8611 1.33594 13.0796 2.33634 12.0013 3.86758C10.923 2.33634 9.14146 1.33594 7.1263 1.33594Z"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  stroke={liked ? '#FF0000' : '#ACB1C0'}
                                />
                              </svg>
                              <div className="flex items-center">
                                <Avatar
                                  avatarlist={post?.likes
                                    ?.filter((comment, i) => i <= 3)
                                    .map((comment, i) => ({
                                      id: i,
                                      comment: comment?.comment,
                                      name: comment?.user?.firstName + ' ' + comment?.user?.lastName,
                                      avatarURL: comment?.user?.logo_image
                                        ? comment?.user?.logo_image
                                        : user?.logo_image
                                        ? user?.logo_image
                                        : '',
                                    }))}
                                  type={true}
                                  radius={width?.innerWidth <= 767 ? 24 : 26}
                                  border={2}
                                />
                                {post?.likes?.length > 4 && (
                                  // <Popover content={() => content_comments(post?.likes)} placement="topLeft">
                                  <div className="popover-main-align">
                                    <button>+{post.comments.length - 4}</button>
                                  </div>
                                  // </Popover>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="social-chat-details-section">
                          <div className="social-chat-details-alignment">
                            {post?.comments
                              ?.filter((_, index) => (!showMore ? index < 2 : _))
                              ?.map((comment, index) => {
                                return (
                                  <div className="social-chat-profile-bg-alignment" key={index}>
                                    <div className="social-chat-profile-alignment">
                                      <div className="social-profile-chat-img-alignment">
                                        {/* <img
                                        src={
                                          comment?.user?.profileUrl
                                            ? comment?.user?.profileUrl
                                            : user?.profileImage?.length > 0
                                            ? user?.profileImage?.[0]?.url
                                            : ''
                                        }
                                        alt="DemoImg"
                                      /> */}
                                        <LazyImage
                                          image={{
                                            src: comment?.user?.logo_image
                                              ? comment?.user?.logo_image
                                              : user?.logo_image
                                              ? user?.logo_image
                                              : '',
                                            alt: 'Icon',
                                          }}
                                          objectFit={'cover'}
                                          imageClassName={'post-media-radious'}
                                          handleClickOnImage={() => handelImageSlider()}
                                        />
                                      </div>
                                      <div>
                                        <div className="social-chat-send-alignment">
                                          <div>
                                            <div className="social-chat-heading-alignment">
                                              <h6>{comment?.user?.firstName + ' ' + comment?.user?.lastName}</h6>
                                              <span> {timeAgo(new Date(comment?.createdAt).getTime())}</span>
                                            </div>
                                            <div className="chat-text-alignment">
                                              <p>{comment?.comment}</p>
                                            </div>
                                          </div>
                                          {comment?.commentReply?.length > 0 && moreComments !== comment.id && (
                                            <div
                                              className="see-replies-text-align"
                                              onClick={() => setMoreComments(comment.id)}
                                            >
                                              <a>See replies</a>
                                            </div>
                                          )}
                                          {moreComments === comment.id
                                            ? comment?.commentReply?.map((commentReply, index) => {
                                                return (
                                                  <div className="child-social-chat-alignment" key={index}>
                                                    <div className="child-profile-img">
                                                      {/* <img src={commentReply?.user?.profileUrl
                                                          ? commentReply?.user?.profileUrl
                                                          : user?.profileImage?.length > 0
                                                          ? user?.profileImage?.[0]?.url
                                                          : ''} alt="DemoImg" /> */}
                                                      <LazyImage
                                                        image={{
                                                          src: commentReply?.user?.profileUrl
                                                            ? commentReply?.user?.profileUrl
                                                            : user?.profileImage?.length > 0
                                                            ? user?.profileImage?.[0]?.url
                                                            : '',
                                                          alt: 'Icon',
                                                        }}
                                                        objectFit={'cover'}
                                                        imageClassName={'post-media-radious'}
                                                        handleClickOnImage={() => handelImageSlider()}
                                                      />
                                                    </div>
                                                    <div className="child-profile-details-alignment">
                                                      <div>
                                                        <div className="child-social-chat-heading-alignment">
                                                          <h6>
                                                            {commentReply?.user?.firstName +
                                                              ' ' +
                                                              commentReply?.user?.lastName}
                                                          </h6>
                                                          <span>
                                                            {' '}
                                                            {timeAgo(new Date(comment?.createdAt).getTime())}
                                                          </span>
                                                        </div>
                                                        <div className="child-chat-text-alignment">
                                                          <p>{commentReply?.comment}</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              })
                                            : ''}
                                          {/* {comment?.commentReply?.length > 0 && moreComments !== comment.id && ( */}
                                          {moreComments === comment.id && (
                                            <div
                                              className="see-replies-text-align"
                                              onClick={() => setMoreComments(9999999)}
                                            >
                                              <a>Less replies</a>
                                            </div>
                                          )}
                                          {/* )} */}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="social-comment-below-section">
                                      <div className="replies-likes-main">
                                        <div
                                          className="replies-section-align"
                                          onClick={() => replyComment(comment?.id)}
                                        >
                                          <img src={'/assets/icon/feed/comment-reply.svg'} />
                                        </div>
                                        <span>{comment?.commentReply?.length} replies</span>
                                      </div>
                                      <div className="replies-likes-main">
                                        <div className="replies-section-align">
                                          <svg
                                            width="24"
                                            height="21"
                                            viewBox="0 0 24 21"
                                            fill={
                                              comment?.likes?.map((like) => {
                                                return like.user.id === user.id
                                              }).length > 0
                                                ? '#FF0000'
                                                : '#FFFFFF'
                                            }
                                            xmlns="http://www.w3.org/2000/svg"
                                            onClick={() => handleLikePost(comment, 'comment')}
                                          >
                                            <path
                                              d="M7.1263 1.33594C3.83561 1.33594 1.16797 4.00359 1.16797 7.29427C1.16797 13.2526 8.20963 18.6693 12.0013 19.9293C15.793 18.6693 22.8346 13.2526 22.8346 7.29427C22.8346 4.00359 20.167 1.33594 16.8763 1.33594C14.8611 1.33594 13.0796 2.33634 12.0013 3.86758C10.923 2.33634 9.14146 1.33594 7.1263 1.33594Z"
                                              strokeWidth="2"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              stroke={
                                                comment?.likes?.map((like) => {
                                                  return like.user.id === user.id
                                                }).length > 0
                                                  ? '#FF0000'
                                                  : '#556EE6'
                                              }
                                            />
                                          </svg>
                                        </div>
                                        <span>{comment?.likes?.length} likes</span>
                                      </div>
                                    </div>
                                    {replyCommentToggle === comment.id && (
                                      <div className="input-align-reply-comment">
                                        <input
                                          type="text"
                                          placeholder="write your comment"
                                          onChange={(e) => handleChangeReply(e)}
                                        />
                                        {replyCommentText?.trim()?.length > 0 ? (
                                          <div
                                            className="send-reply-comment"
                                            onClick={() => handleReplyComment(comment)}
                                          >
                                            <button>Send</button>
                                          </div>
                                        ) : (
                                          <div className="send-reply-comment opacity-div">
                                            <button>Send</button>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )
                              })}
                            {post?.comments?.length > 2 && (
                              <div
                                className="social-chat-profile-alignment-text"
                                onClick={() => setShowMore(!showMore)}
                              >
                                <a>{showMore ? 'Less Replies' : 'Show More Replies'}</a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {user && (
                        <div className="post-footer flex items-center max-w-fit commentbox-padding">
                          <img
                            src={user?.logo_image ? user?.logo_image : '/assets/img/dummy-profile.svg'}
                            alt={user?.profileImage?.alternativeText}
                            className="rounded-full object-cover comment-image"
                          />
                          <InputWithEmoti
                            post={post}
                            type={'post'}
                            isPost={true}
                            store={post?.store}
                            isButton={false}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </Slider>
          )}
        </div>
      </div>
    </div>
  )
}
