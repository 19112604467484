import moment from 'moment'
import React, { useEffect, useState } from 'react'
import StarRatings from 'react-star-ratings'
import RatedImages from './RatedImages'
import { useAuth } from 'src/utils/auth'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAppDispatch } from 'src/store/store'
import NoReview from './NoReview'
import RateAndReviewDetails from './RateAndReviewDetails'
import RateAndReviewAllDetailsModal from './RateAndReviewAllDetailsModal'
import { useMutation, useQuery } from '@apollo/client'
import client1 from 'src/utils/apolloClient'
import { GET_SINGLE_PRODUCT_BY_ID } from 'src/graphql/queries/getSingleProduct'
import GET_PRODUCTS_REVIEWS from 'src/graphql/queries/getProductReviewsBySlug'

export default function RateReview({ product, setOpenImageModalMobile, setOpenImageModal, openImageModal }) {
  
  const { user }: any = useAuth()
  const dispatch = useAppDispatch()
  const isMobile = window.innerWidth < 600
  const [rateAndReviewDetailsModal, setRateAndReviewDetailsModal] = useState(false)
  const starIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
      <path
        d="M10.0201 4.11979C10.6318 2.89633 12.3778 2.89633 12.9895 4.11979L14.5575 7.2558C14.8057 7.75222 15.2856 8.09174 15.8363 8.16058L18.7174 8.52072C20.0449 8.68665 20.6408 10.2742 19.7504 11.2727L17.6475 13.6308C17.312 14.007 17.1656 14.5151 17.2496 15.0121L17.8603 18.6274C18.0866 19.9668 16.6961 20.9961 15.4812 20.3886L12.2471 18.7716C11.7798 18.5379 11.2298 18.5379 10.7624 18.7716L7.53366 20.386C6.31774 20.9939 4.92656 19.9625 5.15496 18.6224L5.76986 15.0146C5.8548 14.5162 5.708 14.0063 5.37106 13.6294L3.26509 11.2736C2.37291 10.2755 2.9684 8.68619 4.29675 8.52015L7.17327 8.16058C7.72399 8.09174 8.20387 7.75222 8.45208 7.2558L10.0201 4.11979Z"
        fill="#FF7D23"
      />
    </svg>
  )

  // const { loading, error, data, refetch } = useQuery(GET_SINGLE_PRODUCT_BY_ID, {
  //   variables: {
  //     productId: product?.slug,
  //   },
  // })

  // const rattingArray = data?.product?.reviews || []
  const [rating, setRating] = useState(4.5) // initial rating value
  const [selectedRate, setSelectedRate] = useState(6)
  // const [ratingData, setRatingData] = useState(rattingArray)
  const [review, setReview] = useState([])

  const getProductReview = async () => {
    const { data } = await client1.query({
      query: GET_PRODUCTS_REVIEWS,
      fetchPolicy: 'network-only',
      variables: {
        input: { 
          order: "New to old",
          rating: 4,
          slug: product?.slug,
        }
      },
    })
    setReview(data?.getProductReviewsBySlug?.data?.userReviews || [])
  }

  useEffect(() => { getProductReview() }, [])

  // useEffect(() => {
  //   setRatingData(review)
  // }, [review])

  const handleRating = (rate: number) => {
    setRating(rate)
    if (user) {
    } else {
      handleRedirect()
    }
  }

  // const handleSelect = (rate) => {
  //   setSelectedRate(rate.id)
  //   if (rate.id === 6) {
  //     setRatingData(review)
  //   } else {
  //     let data = review.filter((star) => Math.trunc(star.ratting) === rate.id)
  //     setRatingData(data)
  //   }
  // }

  const movetoImageModal = () => {
    setOpenImageModal(true)
  }

  const movetoImageModalMobile = () => {
    setOpenImageModalMobile(true)
  }

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  return (
    <>
      <div className="review-container-main">
        {openImageModal ? (
          <RatedImages setOpenImageModal={setOpenImageModal} product={product} review={review} />
        ) : (
          <div className="review-section-container">
            <div className="review-title-align">
              <div className="rate-review-align">
                <div className="rate-section-align">
                  <span>Rate & Review</span>
                </div>
              </div>
            </div>
            <RateAndReviewDetails movetoImageModal={movetoImageModal} ratingData={review} />
          </div>
        )}
      </div>
      <div className="review-mobileView-all-show" onClick={() => setRateAndReviewDetailsModal(true)}>
        <a>See all review</a>
      </div>
      <RateAndReviewAllDetailsModal
        movetoImageModal={movetoImageModal}
        rateAndReviewDetailsModal={rateAndReviewDetailsModal}
        setRateAndReviewDetailsModal={setRateAndReviewDetailsModal}
        ratingData={review}
      />
    </>
  )
}

{
  /* <RatedImages setOpenImageModal={setOpenImageModal} /> */
}
