import React, { useEffect, useRef, useState } from 'react'
import styles from './AddProductShipping.module.scss'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
import { useAppDispatch } from 'src/store/store'
export default function AddProductShipping({ setProductData, productData, validation, setValidation }) {
  let unitObject = [
    { unit: 'lb', name: 'pound', data: [{ unit: 'in', dataName: 'inch' }] },
    { unit: 'kg', name: 'kilogram', data: [{ unit: 'cm', dataName: 'centimeter' }] },
    { unit: 'gm', name: 'gram', data: [{ unit: 'cm', dataName: 'centimeter' }] },
  ]
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const [IbsDropdown, setIbsDropdown] = useState(false)
  const [InDropdown, setInDropdown] = useState(false)
  const productRef: any = useRef()
  const dispatch = useAppDispatch()

  const handleChnage = async (e) => {
    const { name, value } = e.target
    if (name === 'weight') {
      setProductData({
        ...productData,
        shipping: { ...productData?.shipping, weight: { ...productData?.shipping?.weight, value: value } },
      })

      await dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            mobileProductsData: {
              ...addProductDetailSlice?.id?.mobileProductsData,
              shipping: {
                ...addProductDetailSlice?.id?.mobileProductsData?.shipping,
                weight: { ...addProductDetailSlice?.id?.mobileProductsData?.shipping?.weight, value: value },
              },
            },
          },
        }),
      )
    } else {
      setProductData({ ...productData, shipping: { ...productData?.shipping, [name]: value } })
      await dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            mobileProductsData: {
              ...addProductDetailSlice?.id?.mobileProductsData,
              shipping: {
                ...addProductDetailSlice?.id?.mobileProductsData?.shipping,
                [name]: value,
              },
            },
          },
        }),
      )
    }
    setValidation({ ...validation, [name]: '' })
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (IbsDropdown && productRef.current && !productRef.current.contains(e.target)) {
        setIbsDropdown(false)
      }
      if (InDropdown && productRef.current && !productRef.current.contains(e.target)) {
        setInDropdown(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [IbsDropdown, InDropdown])

  const handleSelectUnit = (un) => {
    setProductData({
      ...productData,
      shipping: {
        ...productData?.shipping,
        unit: un?.unit === 'lb' ? 'in' : 'cm',
        weight: { ...productData?.shipping?.weight, unit: un?.name },
        name: un?.unit,
      },
    })
  }

  const handleSelectSubUnit = (un) => {
    setProductData({
      ...productData,
      shipping: {
        ...productData?.shipping,
        unit: un,
      },
    })
  }

  return (
    <div className={styles.addProductShippingSection}>
      <div className={styles.addProductShippingPricingdBox}>
        <div className={styles.addProductShippingHeadingAlignment}>
          <h4>Shipping</h4>
          <div className={styles.shippingHeadingOtherOptionAlignment}>
            <span>Unit</span>
            <div className={styles.unitFlexAlignment}>
              <div
                className={
                  IbsDropdown === true
                    ? classNames(styles.unitDetailsChildFlex, styles.unitOptionActive)
                    : classNames(styles.unitDetailsChildFlex, styles.deactiveOption)
                }
                onClick={() => setIbsDropdown(!IbsDropdown)}
              >
                <p>{productData?.shipping?.name ?? 'lb'}</p>
                <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 1L4 4L7 1"
                    stroke="#ACB1C0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {IbsDropdown && (
                  <div className={styles.unitDetailsDropdownBoxAlignment} ref={productRef}>
                    {unitObject?.map((o, index) => {
                      return (
                        <div
                          key={index}
                          className={`${styles.unitDetailsDropdownOption} ${
                            productData?.shipping?.weight?.unit === o?.unit ? styles.selected : ''
                          }`}
                          onClick={() => handleSelectUnit(o)}
                        >
                          <p>{o?.unit}</p>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
              <div
                className={
                  InDropdown === true
                    ? classNames(styles.unitDetailsChildFlex, styles.unitOptionActive)
                    : classNames(styles.unitDetailsChildFlex, styles.deactiveOption)
                }
                onClick={() => setInDropdown(!InDropdown)}
              >
                <p>{productData?.shipping?.unit ?? 'in'}</p>
                <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 1L4 4L7 1"
                    stroke="#ACB1C0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {InDropdown && (
                  <div className={styles.unitDetailsDropdownBoxAlignment} ref={productRef}>
                    {[0].map((o, index) => {
                      return (
                        <div
                          key={index}
                          className={styles.unitDetailsDropdownOption}
                          // onClick={() => handleSelectSubUnit(o?.unit)}
                        >
                          <p>{productData?.shipping?.unit ?? 'in'}</p>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.addProductShippingFormAlignment}>
          <div className={classNames(styles.formInputDetailsAlignment, styles.invetoryInput)}>
            <label>Item weight</label>
            <div className={classNames(styles.formInputRelative, validation?.weight ? styles.titleError : '')}>
              <input
                disabled={addProductDetailSlice?.id.variant.allFinalVariant?.length > 0 ? true : false}
                type="text"
                name="weight"
                className={styles.otherInputpadding}
                onChange={(e) => handleChnage(e)}
                value={productData?.shipping?.weight?.value}
              />
              <div className={styles.iconAlignment}>
                <span>
                  {productData?.shipping?.weight?.unit === 'kilogram'
                    ? 'kg'
                    : productData?.shipping?.weight?.unit === 'gram'
                    ? 'gm'
                    : 'lbs'}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.threeGridAlignment}>
            <div className={styles.formInputDetailsAlignment}>
              <label>Length</label>
              <div className={classNames(styles.formInputRelative, validation?.length ? styles.titleError : '')}>
                <input
                  disabled={addProductDetailSlice?.id.variant.allFinalVariant?.length > 0 ? true : false}
                  type="text"
                  name="length"
                  className={styles.childInputPadding}
                  onChange={(e) => handleChnage(e)}
                  value={productData?.shipping?.length}
                />
                <div className={styles.iconAlignment}>
                  <span>{productData?.shipping?.unit ?? 'in'}</span>
                </div>
              </div>
            </div>
            <div className={styles.formInputDetailsAlignment}>
              <label>Width</label>
              <div className={classNames(styles.formInputRelative, validation?.width ? styles.titleError : '')}>
                <input
                  disabled={addProductDetailSlice?.id.variant.allFinalVariant?.length > 0 ? true : false}
                  type="text"
                  name="width"
                  className={styles.childInputPadding}
                  onChange={(e) => handleChnage(e)}
                  value={productData?.shipping?.width}
                />
                <div className={styles.iconAlignment}>
                  <span>{productData?.shipping?.unit ?? 'in'}</span>
                </div>
              </div>
            </div>
            <div className={styles.formInputDetailsAlignment}>
              <label>Height</label>
              <div className={classNames(styles.formInputRelative, validation?.height ? styles.titleError : '')}>
                <input
                  disabled={addProductDetailSlice?.id.variant.allFinalVariant?.length > 0 ? true : false}
                  type="text"
                  name="height"
                  className={styles.childInputPadding}
                  onChange={(e) => handleChnage(e)}
                  value={productData?.shipping?.height}
                />
                <div className={styles.iconAlignment}>
                  <span>{productData?.shipping?.unit ?? 'in'}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
