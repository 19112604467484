import React, { useEffect, useState } from 'react'
import { ApexOptions } from 'apexcharts'
import dynamic from 'next/dynamic'
import { Button, Dropdown, Menu, Select } from 'antd'
import { useQuery } from '@apollo/client'
import { useAuth } from 'src/utils/auth'
import moment from 'moment'
import { useSelector } from 'react-redux'
import GET_DATE_WISE_PRODUCT_TRACTION from 'src/graphql/queries/sellerQueries/getDateWiseProductTraction'
import { useRouter } from 'next/router'

export default function CustomerTraction({ getSingleProduct }) {
  const { Option } = Select
  const { user }: any = useAuth()
  const dateDropdown = ['Last 3 months', 'Last 6 months', 'Last 9 months']
  const Chart = dynamic(() => import('react-apexcharts'), { ssr: false }) as any
  const [isSort, setIsSort] = useState('Last 3 Months')
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const [monthData, setMonthData] = useState([])
  const router = useRouter()
  // const series = {
  //   Female: {
  //     data: [22, 35, 45, 55, 80],
  //   },
  //   Male: {
  //     data: [36, 55, 22, 40, 95],
  //   },
  // }

  const { loading, error, data, refetch } = useQuery(GET_DATE_WISE_PRODUCT_TRACTION, {
    variables: {
      productId: getSingleProduct?.id,
      startDate: moment().subtract(2, 'months').startOf('day').format('YYYY-MM-DD[T]00:00:00.000'),
      endDate: moment().format('YYYY-MM-DD[T]HH:mm:ss.000'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timeInterval: 'month',
    },
  })

  const sendDate = (startDate, endDate, time_interval) => {
    // refetch({
    //   productId: getAllState?.lastModalData?.id,
    //   startDate: startDate,
    //   endDate: endDate,
    //   timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    //   timeInterval: time_interval,
    // })
  }

  const changeSortType = (e: { key: string }) => {
    setIsSort(e.key)
    switch (e.key) {
      case 'Last 3 Months':
        const startDate3Months = moment().subtract(2, 'months').startOf('month')
        const endDate3Months = moment().endOf('month')
        const monthsArray3Months = generateMonthsArray(startDate3Months, endDate3Months)
        setMonthData(monthsArray3Months)

        return sendDate(
          moment().subtract(2, 'months').startOf('day').format('YYYY-MM-DD[T]00:00:00.000'),
          moment().format('YYYY-MM-DD[T]HH:mm:ss.000'),
          'month',
        )
      case 'Last 6 Months':
        const startDate6Months = moment().subtract(5, 'months').startOf('month')
        const endDate6Months = moment().endOf('month')
        const monthsArray6Months = generateMonthsArray(startDate6Months, endDate6Months)
        setMonthData(monthsArray6Months)

        return sendDate(
          moment().subtract(5, 'months').startOf('day').format('YYYY-MM-DD[T]00:00:00.000'),
          moment().format('YYYY-MM-DD[T]HH:mm:ss.000'),
          'month',
        )
      case 'This year':
        setMonthData(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'])

        return sendDate(
          moment().startOf('year').format('YYYY-MM-DD[T]00:00:00.000'),
          moment().format('YYYY-MM-DD[T]HH:mm:ss.000'),
          'month',
        )

      case 'Last year':
        setMonthData(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'])
        return sendDate(
          moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD[T]00:00:00.000'),
          moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD[T]23:59:59.999'),
          'month',
        )
      default:
        return ''
    }
  }

  const generateMonthsArray = (start: moment.Moment, end: moment.Moment) => {
    const monthsArray = []
    let currentMonth = start.clone()

    while (currentMonth.isSameOrBefore(end)) {
      monthsArray.push(currentMonth.format('MMM'))
      currentMonth.add(1, 'month')
    }

    return monthsArray
  }

  const SortType = (
    <Menu onClick={changeSortType}>
      <Menu.Item key="Last 3 Months">Last 3 Months</Menu.Item>
      <Menu.Item key="Last 6 Months">Last 6 Months</Menu.Item>
      <Menu.Item key="This year">This year</Menu.Item>
      <Menu.Item key="Last year">Last year</Menu.Item>
    </Menu>
  )

  const series = [
    {
      name: 'Sold',
      data: data?.getProductTraction?.data?.[2],
    },
    {
      name: 'View',
      data: data?.getProductTraction?.data?.[1],
    },
    {
      name: 'Commets',
      data: data?.getProductTraction?.data?.[0],
    },
  ]

  var options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    colors: ['#DE6B00', '#0078CF', '#9022ff'],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadiusApplication: 'end',
        borderRadius: 4,
        columnWidth: '60%',
        // endingShape: 'rounded',
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      fontSize: '14px',
      fontFamily: 'Poppins',
      fontWeight: 400,
      // horizontalAlign: 'right',
      labels: {
        colors: '#7A7E88',
        useSeriesColors: false,
      },
      // customLegendItems: ['This period', 'Previous period'],
      markers: {
        width: 6,
        height: 6,
        borderRadiusApplication: 'end',
        borderRadius: 4,
        strokeWidth: 0,
        strokeColor: '#000000',
        fillColors: ['#DE6B00', '#5b70d7', '#9022ff'],
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      // itemMargin: {
      //   horizontal: 15,
      //   vertical: -5,
      // },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.5,
        gradientToColors: ['#546E7A', '#E91E63'],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 100],
        colorStops: [
          [
            {
              offset: 0,
              color: '#DE6B00',
              opacity: 0.2,
            },
            {
              offset: 0.6,
              color: '#DE6B00',
              opacity: 0.5,
            },
            {
              offset: 50,
              color: '#DE6B00',
              opacity: 1,
            },
          ],
          [
            {
              offset: 0,
              color: '#0078CF',
              opacity: 0.4,
            },
            {
              offset: 50,
              color: '#0078CF',
              opacity: 0.8,
            },
            {
              offset: 100,
              color: '#0078CF',
              opacity: 1,
            },
          ],
          [
            {
              offset: 0,
              color: '#9022ff',
              opacity: 0.4,
            },
            {
              offset: 40,
              color: '#9022ff',
              opacity: 0.8,
            },
            {
              offset: 100,
              color: '#9022ff',
              opacity: 1,
            },
          ],
        ],
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: monthData,
      labels: {
        show: true,
        rotate: -45,
        maxHeight: 32,
        style: {
          fontSize: '14px',
          fontFamily: 'Poppins',
          fontWeight: 400,
          colors: ['#7A7E88', '#7A7E88'],
        },
      },
    },
    // yaxis: {
    //   show: true,
    //   tickPlacement: 'between',
    //   logarithmic: false,
    //   logBase: 10,
    //   forceNiceScale: false,
    //   decimalsInFloat: 0,
    //   labels: {
    //     show: true,
    //     align: 'right',
    //     formatter: (value) => {
    //       return `${value}`
    //     },
    //   },
    // },
    yaxis: {
      categories: [0, 1, 2, 3, 4],

      title: {
        // text: '$ (thousands)',
      },
      labels: {
        show: true,
        rotate: 0,
        maxHeight: 32,
        style: {
          fontSize: '14px',
          fontFamily: 'Poppins',
          fontWeight: 400,
          colors: ['#7A7E88', '#7A7E88'],
        },
        formatter: (value) => {
          return `${value}`
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
        },
      },
    },
  }

  const handleChangeMonth = () => {}

  useEffect(() => {
    const startDate3Months = moment().subtract(2, 'months').startOf('month')
    const endDate3Months = moment().endOf('month')
    const monthsArray3Months = generateMonthsArray(startDate3Months, endDate3Months)
    setMonthData(monthsArray3Months)
  }, [])

  return (
    <>
      {/* <div className="customer-traction-main">
        <div className="customer-heading-alignment">
          <h6>Traction</h6>
        </div>

        <div className="graph-container-main">
          <div className="graph-title-container">
            <div className="graph-title-align">
              <h3>Overview</h3>
            </div>
            <div className="dropdown-section-main">
              <Select size={'middle'} defaultValue="Last 3 months" onChange={handleChangeMonth}>
                <Option value={'Last 3 months'} label={''}>
                  <div className="dropdown-option-label-item">Last 3 months</div>
                </Option>
                <Option value={'Last 6 months'} label={''}>
                  <div className="dropdown-option-label-item">Last 6 months</div>
                </Option>
                <Option value={'Last 9 months'} label={''}>
                  <div className="dropdown-option-label-item">Last 9 months</div>
                </Option>
              </Select>
            </div>
          </div>
          <div className="product-apex-chart">
            <Chart series={series} options={options} type="line" height={318} />
          </div>
        </div>
        <div className="graph-container-main">
          <div className="graph-second-container">
            <div className="graph-align">
              <h3>Interest Based on Region</h3>
              <div className="interest-section-mobile">
                <div className="interest-text">
                  <span>Interest</span>
                </div>
                <div className="interest-up-contain">
                  <img src={'/assets/img/product-detail/green-arrow-icon.svg'} />
                  <span>42%</span>
                </div>
              </div>
            </div>
            <div className="graph-page-size-section">
              <div className="page-size-align">
                <span>1-5 of 20</span>
              </div>
              <div className="prev-next-section">
                <span>{'<Prev'} </span>
                <span>{'Next>'} </span>
              </div>
            </div>
          </div>
          <div className="devider"></div>
          <div className="">
            <div className="progressbar-main">
              <div className="progress-title">
                <span>Orlando</span>
                <p>100</p>
              </div>
              <div className="w-full h-1 overflow-hidden rounded" style={{ background: '#E3E4E8', height: '5px' }}>
                <div className="relative float-left  h-full" style={{ width: '100%', background: '#70C217' }}>
                  <p
                    className="absolute top-1/2 left-1/2 m-0 text-xs text-white"
                    style={{ transform: 'translate(-50%, -50%)' }}
                  ></p>
                </div>
              </div>
            </div>
            <div className="progressbar-main">
              <div className="progress-title">
                <span>Miami</span>
                <p>75</p>
              </div>
              <div className="w-full h-1 overflow-hidden rounded" style={{ background: '#E3E4E8', height: '5px' }}>
                <div className="relative float-left  h-full" style={{ width: '75%', background: '#5EA2B8' }}>
                  <p
                    className="absolute top-1/2 left-1/2 m-0 text-xs text-white"
                    style={{ transform: 'translate(-50%, -50%)' }}
                  ></p>
                </div>
              </div>
            </div>
            <div className="progressbar-main">
              <div className="progress-title">
                <span>Jacksonville</span>
                <p>60</p>
              </div>
              <div className="w-full h-1 overflow-hidden rounded" style={{ background: '#E3E4E8', height: '5px' }}>
                <div className="relative float-left  h-full" style={{ width: '60%', background: '#424EB8' }}>
                  <p
                    className="absolute top-1/2 left-1/2 m-0 text-xs text-white"
                    style={{ transform: 'translate(-50%, -50%)' }}
                  ></p>
                </div>
              </div>
            </div>
            <div className="progressbar-main">
              <div className="progress-title">
                <span>Tampa</span>
                <p>55</p>
              </div>
              <div className="w-full h-1 overflow-hidden rounded" style={{ background: '#E3E4E8', height: '5px' }}>
                <div className="relative float-left  h-full" style={{ width: '50%', background: '#6D3AAF' }}>
                  <p
                    className="absolute top-1/2 left-1/2 m-0 text-xs text-white"
                    style={{ transform: 'translate(-50%, -50%)' }}
                  ></p>
                </div>
              </div>
            </div>
            <div className="progressbar-main">
              <div className="progress-title">
                <span>Naples</span>
                <p>40</p>
              </div>
              <div className="w-full h-1 overflow-hidden rounded" style={{ background: '#E3E4E8', height: '5px' }}>
                <div className="relative float-left  h-full" style={{ width: '50%', background: '#9B2A89' }}>
                  <p
                    className="absolute top-1/2 left-1/2 m-0 text-xs text-white"
                    style={{ transform: 'translate(-50%, -50%)' }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="customer-traction-new-main">
        <div className="customer-tracation-grid-alignment">
          <div className="customer-tracation-box-new-alignment">
            <div className="customer-new-heading-alignment">
              <h4>Overview</h4>
              <h4 className="mobile-view-text">Traction</h4>
              <div className="customer-new-dropdown-alignment">
                <div className="drodwn-name-alignment">
                  {/* <p>Last 6 months</p> */}
                  <Dropdown overlay={SortType} className=" right-0 flex justify-center h-14">
                    <Button className="filter-button dropdown-gap">
                      {isSort}
                      <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L5 5L9 1" stroke="#636773" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </Button>
                  </Dropdown>
                </div>
                {/* <div className="dropdown-bottom-arrow">
                  <img src="/assets/icon/drop-down-bottom-arrow.svg" alt="bottom arrow" />
                </div> */}
              </div>
            </div>
            {/* <div className="customer-new-dropdown-tab-alignment">
              <ul>
                <li>All</li>
                <li>Sold</li>
                <li>View</li>
                <li>Comments</li>
              </ul>
            </div> */}
            <div className="new-customer-tracation-chart-alignment">
              <Chart options={options} series={series} type="bar" height="250px" width="100%" />
            </div>
            {/* <div className="new-customer-no-data-chart-alignment">
              <div>
                <img src="/assets/icon/no-data-chart-icon.svg" alt="no chart" />
              </div>
              <p>No insight available at the moment.</p>
            </div> */}
          </div>
          <div className="customer-tracation-box-new-alignment">
            <div className="customer-new-heading-alignment">
              <h4>Interest Based on Region</h4>
              <h4 className="mobile-view-text">Interest</h4>
              <div className="customer-new-tracation-right-details">
                <span>1-5 of 20</span>
                <div className="customer-next-prev-pagination-alignment">
                  <div className="child-name-flex-alignment">
                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="10" viewBox="0 0 5 10" fill="none">
                      <path
                        d="M4.09326 1L1.09326 5L4.09326 9"
                        stroke="#495057"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p>Prev</p>
                  </div>
                  <div className="child-name-flex-alignment">
                    <p>Next</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="10" viewBox="0 0 5 10" fill="none">
                      <path
                        d="M0.526366 9L3.52637 5L0.526367 1"
                        stroke="#495057"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="new-customer-tracation-chart-alignment">
              <div className="devider"></div>
              <div className=""></div>
              <div className="progressbar-main">
                <div className="progress-title">
                  <span>Orlando</span>
                  <p>100</p>
                </div>
                <div className="w-full h-1 overflow-hidden rounded" style={{ background: '#E3E4E8', height: '5px' }}>
                  <div className="relative float-left  h-full" style={{ width: '100%', background: '#70C217' }}>
                    <p
                      className="absolute top-1/2 left-1/2 m-0 text-xs text-white"
                      style={{ transform: 'translate(-50%, -50%)' }}
                    ></p>
                  </div>
                </div>
              </div>
              <div className="progressbar-main">
                <div className="progress-title">
                  <span>Miami</span>
                  <p>75</p>
                </div>
                <div className="w-full h-1 overflow-hidden rounded" style={{ background: '#E3E4E8', height: '5px' }}>
                  <div className="relative float-left  h-full" style={{ width: '75%', background: '#5EA2B8' }}>
                    <p
                      className="absolute top-1/2 left-1/2 m-0 text-xs text-white"
                      style={{ transform: 'translate(-50%, -50%)' }}
                    ></p>
                  </div>
                </div>
              </div>
              <div className="progressbar-main">
                <div className="progress-title">
                  <span>Jacksonville</span>
                  <p>60</p>
                </div>
                <div className="w-full h-1 overflow-hidden rounded" style={{ background: '#E3E4E8', height: '5px' }}>
                  <div className="relative float-left  h-full" style={{ width: '60%', background: '#424EB8' }}>
                    <p
                      className="absolute top-1/2 left-1/2 m-0 text-xs text-white"
                      style={{ transform: 'translate(-50%, -50%)' }}
                    ></p>
                  </div>
                </div>
              </div>
              <div className="progressbar-main">
                <div className="progress-title">
                  <span>Tampa</span>
                  <p>55</p>
                </div>
                <div className="w-full h-1 overflow-hidden rounded" style={{ background: '#E3E4E8', height: '5px' }}>
                  <div className="relative float-left  h-full" style={{ width: '50%', background: '#6D3AAF' }}>
                    <p
                      className="absolute top-1/2 left-1/2 m-0 text-xs text-white"
                      style={{ transform: 'translate(-50%, -50%)' }}
                    ></p>
                  </div>
                </div>
              </div>
              <div className="progressbar-main">
                <div className="progress-title">
                  <span>Naples</span>
                  <p>40</p>
                </div>
                <div className="w-full h-1 overflow-hidden rounded" style={{ background: '#E3E4E8', height: '5px' }}>
                  <div className="relative float-left  h-full" style={{ width: '50%', background: '#9B2A89' }}>
                    <p
                      className="absolute top-1/2 left-1/2 m-0 text-xs text-white"
                      style={{ transform: 'translate(-50%, -50%)' }}
                    ></p>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="new-customer-no-data-chart-alignment secongBox-chart">
              <div>
                <img src="/assets/icon/no-data-chart-icon.svg" alt="no chart" />
              </div>
              <p>No insight available at the moment.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
