import { useQuery } from '@apollo/client'
import { Crown, Search, Star } from 'lucide-react'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import LiveStreamsCard from 'src/components/StreamsSliders/LiveStreams/LiveStreamsCard'
import PastStreamsCard from 'src/components/StreamsSliders/PastStreams/PastStreamsCard'
import UpcomingStreamsCard from 'src/components/StreamsSliders/UpcomingStreams/UpcomingStreamsCard'

const AllStreamsModal = () => {
    const getAllState = useSelector((state: any) => state?.manageModalData?.data)
    const streamData = getAllState?.lastModalData?.data

    const getCardType = (type: string, item: any) => {
        if (type === 'live') return <LiveStreamsCard item={item} />
        if (type === 'upcoming') return <UpcomingStreamsCard item={item} />
        if (type === 'past') return <PastStreamsCard item={item} />
    }
    console.log("streamData", streamData)
    return (
        <div className='h-full relative'>
            <div className="py-5 px-5 text-white text-lg rounded-t-xl shadow-lg" style={{ background: 'linear-gradient(90deg, #171717 0%, #6747B0 100%)', position: 'sticky', top: 0, zIndex: 10 }} >
                {streamData.title}
            </div>
            <div className=" absolute top-16 left-0 right-0 bottom-0 overflow-auto my-3" style={{ maxHeight: '95%' }}>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-5">
                    {streamData?.streams?.map((stream: any) => (
                        <div key={stream.id} className="py-3">
                            {getCardType(streamData?.type, stream)}
                        </div>
                    ))}
                </div>
            </div>

        </div>
    )
}

export default AllStreamsModal
