import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  postData: {}, // Add newUserInfo state
}

const createPostProductSlice = createSlice({
  name: 'createpostproduct',
  initialState,
  reducers: {
    setPostDetailsData: (state, action) => {
      state.postData = action.payload
    },
  },
})

export const { setPostDetailsData } = createPostProductSlice.actions

export default createPostProductSlice.reducer
