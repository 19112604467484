export default function ShoppingIcon({ small }: { small?: boolean }) {
    return (
        small ?
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="24" viewBox="0 0 56 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8987 0C16.4375 0 15.1517 0.964775 14.7433 2.36774L10.7202 16.1853C10.1079 18.2883 11.6854 20.3904 13.8757 20.3904H16.9292L16.9292 20.3863C16.9292 18.1174 18.7685 16.2782 21.0373 16.2782C23.3061 16.2782 25.1454 18.1174 25.1454 20.3863L25.1454 20.3904H32.2947C33.7559 20.3904 35.0416 19.4257 35.4501 18.0227L39.4731 4.20516C40.0854 2.10217 38.508 0 36.3177 0H17.8987ZM19.6842 2.38562C19.1397 2.38562 18.6983 2.82704 18.6983 3.37156C18.6983 3.91607 19.1397 4.35749 19.6842 4.35749H36.3219C36.8664 4.35749 37.3078 3.91608 37.3078 3.37156C37.3078 2.82704 36.8664 2.38562 36.3219 2.38562H19.6842ZM33.449 7.68759H17.9595C17.415 7.68759 16.9736 8.12901 16.9736 8.67352C16.9736 9.21804 17.415 9.65946 17.9595 9.65946H33.449C33.9935 9.65946 34.4349 9.21804 34.4349 8.67352C34.4349 8.12901 33.9935 7.68759 33.449 7.68759ZM23.9399 13.1564C23.3954 13.1564 22.954 13.5979 22.954 14.1424C22.954 14.6869 23.3954 15.1283 23.9399 15.1283H31.0704C31.6149 15.1283 32.0563 14.6869 32.0563 14.1424C32.0563 13.5979 31.6149 13.1565 31.0704 13.1565L23.9399 13.1564Z" fill="#5F40AF" />
                <circle cx="21.0333" cy="20.3792" r="3.28645" fill="#5F40AF" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M44.1618 2.57202C42.8321 2.57202 41.6659 3.45928 41.3112 4.74075L38.1379 16.2044C37.6576 17.9393 38.6569 19.658 40.2496 20.1958C40.3449 18.0115 42.1459 16.2698 44.3537 16.2698C46.6191 16.2698 48.4562 18.1035 48.4618 20.3676H52.4529C53.8321 20.3676 55.0418 19.4473 55.4097 18.1181L55.5643 17.5597C57.6505 10.0232 51.9817 2.57202 44.1618 2.57202ZM45.6875 6.16658C45.4108 6.16658 45.1662 6.3464 45.0837 6.61051L43.7504 10.8769C43.5851 11.4059 43.9804 11.9435 44.5346 11.9435H50.383C50.6109 11.9435 50.8124 11.7954 50.8803 11.5779C51.7191 8.89395 49.7139 6.16658 46.902 6.16658H45.6875Z" fill="#5F40AF" />
                <circle cx="44.3509" cy="20.3792" r="3.28645" fill="#5F40AF" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.37168C0 2.82716 0.441418 2.38574 0.985936 2.38574L11.1802 2.38574C11.7247 2.38574 12.1661 2.82716 12.1661 3.37168C12.1661 3.9162 11.7247 4.35761 11.1802 4.35761L0.985936 4.35761C0.441418 4.35761 0 3.91619 0 3.37168ZM2.86666 7.68771L8.89161 7.68771C9.43613 7.68771 9.87754 8.12913 9.87754 8.67365C9.87754 9.21816 9.43613 9.65958 8.89161 9.65958L2.86666 9.65958C2.32214 9.65958 1.88072 9.21817 1.88072 8.67365C1.88072 8.12913 2.32214 7.68771 2.86666 7.68771ZM4.2557 14.1425C4.2557 13.598 4.69712 13.1566 5.24163 13.1566H7.03911C7.58363 13.1566 8.02505 13.598 8.02505 14.1425C8.02505 14.687 7.58363 15.1284 7.03911 15.1284H5.24163C4.69712 15.1284 4.2557 14.687 4.2557 14.1425Z" fill="#5F40AF" />
            </svg>
            :
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="24" viewBox="0 0 56 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8987 0C16.4375 0 15.1517 0.964775 14.7433 2.36774L10.7202 16.1853C10.1079 18.2883 11.6854 20.3904 13.8757 20.3904H16.9292L16.9292 20.3863C16.9292 18.1174 18.7685 16.2782 21.0373 16.2782C23.3061 16.2782 25.1454 18.1174 25.1454 20.3863L25.1454 20.3904H32.2947C33.7559 20.3904 35.0416 19.4257 35.4501 18.0227L39.4731 4.20516C40.0854 2.10217 38.508 0 36.3177 0H17.8987ZM19.6842 2.38562C19.1397 2.38562 18.6983 2.82704 18.6983 3.37156C18.6983 3.91607 19.1397 4.35749 19.6842 4.35749H36.3219C36.8664 4.35749 37.3078 3.91608 37.3078 3.37156C37.3078 2.82704 36.8664 2.38562 36.3219 2.38562H19.6842ZM33.449 7.68759H17.9595C17.415 7.68759 16.9736 8.12901 16.9736 8.67352C16.9736 9.21804 17.415 9.65946 17.9595 9.65946H33.449C33.9935 9.65946 34.4349 9.21804 34.4349 8.67352C34.4349 8.12901 33.9935 7.68759 33.449 7.68759ZM23.9399 13.1564C23.3954 13.1564 22.954 13.5979 22.954 14.1424C22.954 14.6869 23.3954 15.1283 23.9399 15.1283H31.0704C31.6149 15.1283 32.0563 14.6869 32.0563 14.1424C32.0563 13.5979 31.6149 13.1565 31.0704 13.1565L23.9399 13.1564Z" fill="#5F40AF" />
                <circle cx="21.0333" cy="20.3792" r="3.28645" fill="#5F40AF" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M44.1618 2.57202C42.8321 2.57202 41.6659 3.45928 41.3112 4.74075L38.1379 16.2044C37.6576 17.9393 38.6569 19.658 40.2496 20.1958C40.3449 18.0115 42.1459 16.2698 44.3537 16.2698C46.6191 16.2698 48.4562 18.1035 48.4618 20.3676H52.4529C53.8321 20.3676 55.0418 19.4473 55.4097 18.1181L55.5643 17.5597C57.6505 10.0232 51.9817 2.57202 44.1618 2.57202ZM45.6875 6.16658C45.4108 6.16658 45.1662 6.3464 45.0837 6.61051L43.7504 10.8769C43.5851 11.4059 43.9804 11.9435 44.5346 11.9435H50.383C50.6109 11.9435 50.8124 11.7954 50.8803 11.5779C51.7191 8.89395 49.7139 6.16658 46.902 6.16658H45.6875Z" fill="#5F40AF" />
                <circle cx="44.3509" cy="20.3792" r="3.28645" fill="#5F40AF" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.37168C0 2.82716 0.441418 2.38574 0.985936 2.38574L11.1802 2.38574C11.7247 2.38574 12.1661 2.82716 12.1661 3.37168C12.1661 3.9162 11.7247 4.35761 11.1802 4.35761L0.985936 4.35761C0.441418 4.35761 0 3.91619 0 3.37168ZM2.86666 7.68771L8.89161 7.68771C9.43613 7.68771 9.87754 8.12913 9.87754 8.67365C9.87754 9.21816 9.43613 9.65958 8.89161 9.65958L2.86666 9.65958C2.32214 9.65958 1.88072 9.21817 1.88072 8.67365C1.88072 8.12913 2.32214 7.68771 2.86666 7.68771ZM4.2557 14.1425C4.2557 13.598 4.69712 13.1566 5.24163 13.1566H7.03911C7.58363 13.1566 8.02505 13.598 8.02505 14.1425C8.02505 14.687 7.58363 15.1284 7.03911 15.1284H5.24163C4.69712 15.1284 4.2557 14.687 4.2557 14.1425Z" fill="#5F40AF" />
            </svg>
    )
}