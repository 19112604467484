import React from 'react'
import SearchIcon from '../../../../../../../public/assets/icon/search.svg'
import users from '../../../../../../../public/assets/img/WomansCategory/users.png'
import BookBannerImg from '../../../../../../../public/assets/img/book-banner-img.png'
import like from '../../../../../../../public/assets/img/WomansCategory/like.png'
import AosAnimation from 'src/utils/AosAnimation'

export default function BookClubModal() {
  return (
    <div className="book-club-modal-details-section">
      <div className="book-club-modal-details-alignment">
        <div className="book-club-heading-alignment">
          <h5>Book Clubs</h5>
        </div>
        <div className="book-club-details-flex-alignment">
          <div className="book-search-alignment">
            <img src={SearchIcon.src} alt="SearchIcon" />
            <input type="text" placeholder="Type keyword here..." />
          </div>
          <div className="create-club-button-alignment">
            <button>+ Create Club</button>
          </div>
        </div>
        <div className="book-club-details-body-alignment">
          <div className="book-club-grid-alignment ">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((_, index) => {
              return (
                <>
                  <AosAnimation animationType="fade-up" duration={1000}>
                    <div className="book-club-details_cover" key={index}>
                      <div className="card_fashion">
                        <div className="img_cover">
                          <img src={BookBannerImg.src} alt="img_cover" />
                        </div>
                        <div className="card_details">
                          <div className="card_details_cover">
                            <h5>Book Club 56</h5>
                            <p>Lorem ipsum Dolor sit amet consectetur</p>
                            <div className="users">
                              <div className="left_part">
                                <div className="user_img pr-4">
                                  <img src={users.src} alt="user_img" />
                                </div>
                                {[...Array(3)].map((_, i) => {
                                  return (
                                    <div className="users_likes" key={`r${i}`}>
                                      <div className="user_box">
                                        <img src={like.src} alt="user_box" />
                                      </div>
                                    </div>
                                  )
                                })}
                                <div className="count">+120</div>
                              </div>
                              <div className="right_part">
                                <button>Join Group</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AosAnimation>
                </>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
