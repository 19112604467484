// @ts-nocheck
import React from 'react'
import Slider from 'react-slick'
import BookBannerImg from '../../../../../../public/assets/img/bookCover.png'
import users from '../../../../../../public/assets/img/WomansCategory/users.png'
import like from '../../../../../../public/assets/img/WomansCategory/like.png'
import AosAnimation from 'src/utils/AosAnimation'

export default function MobileBookClubSection() {
  const mobileViewClubDetails = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1.4,
    slidesToScroll: 1,
  }

  return (
    <div className="mobile-book-club-section">
      <div className="mobile-book-club-headign-alignment">
        <h4>Book Club</h4>
        <a>See More</a>
      </div>
      <div>
        <div className="mobile-view-book-club-created-details-alignment">
          <Slider {...mobileViewClubDetails}>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((_, index) => {
              return (
                <>
                  <AosAnimation animationType="fade-up" duration={1000}>
                    <div className="book-club-details_cover" key={index}>
                      <div className="card_fashion">
                        <div className="img_cover">
                          <img src={BookBannerImg.src} alt="img_cover" />
                        </div>
                        <div className="card_details">
                          <div className="card_details_cover">
                            <h5>Book Club 56</h5>
                            <p>Lorem ipsum Dolor sit amet consectetur</p>
                            <div className="users">
                              <div className="left_part">
                                <div className="user_img pr-4">
                                  <img src={users.src} alt="user_img" />
                                </div>
                                {[...Array(3)].map(() => {
                                  return (
                                    <div className="users_likes">
                                      <div className="user_box">
                                        <img src={like.src} alt="user_box" />
                                      </div>
                                    </div>
                                  )
                                })}
                                <div className="count">+120</div>
                              </div>
                              <div className="right_part">
                                <button>Join Group</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AosAnimation>
                </>
              )
            })}
          </Slider>
        </div>
      </div>
    </div>
  )
}
