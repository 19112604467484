import React from 'react'
import styles from './AddProductInventoryPricing.module.scss'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { useAppDispatch } from 'src/store/store'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'

export default function AddProductInventoryPricing({ setProductData, productData, validation, setValidation }) {
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const dispatch = useAppDispatch()
  const handleChnage = async (e) => {
    const { name, value } = e.target
    setProductData({ ...productData, inventoryPrice: { ...productData?.inventoryPrice, [name]: value } })
    setValidation({ ...validation, [name]: '' })

    if (name === 'price') {
      await dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            productPreview: {
              ...addProductDetailSlice?.id?.productPreview,
              price: value,
            },
            mobileProductsData: {
              ...addProductDetailSlice?.id?.mobileProductsData,
              price: value,
            },
          },
        }),
      )
    }
    if (name === 'listPrice') {
      await dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            productPreview: {
              ...addProductDetailSlice?.id?.productPreview,
              listPrice: value,
            },
            mobileProductsData: {
              ...addProductDetailSlice?.id?.mobileProductsData,
              listPrice: value,
            },
          },
        }),
      )
    }
    // else {
    //   await dispatch(
    //     manageAddProductDetail({
    //       modal: '',
    //       isOpen: false,
    //       id: {
    //         ...addProductDetailSlice?.id,
    //         mobileProductsData: {
    //           ...addProductDetailSlice?.id?.mobileProductsData,
    //           [name]: value,
    //         },
    //       },
    //     }),
    //   )
    // }
  }

  return (
    <div className={styles.addProductInventoryPricingSection}>
      <div className={styles.addProductInventoryPricingdBox}>
        <div className={styles.addProductInventoryPricingdHeadingAlignment}>
          <h4>Inventory & Pricing</h4>
        </div>
        <div className={styles.addProductInventoryPricingdDetails}>
          <div className={styles.twoGridAlignment}>
            <div className={styles.formInputDetailsAlignment}>
              <label>Price</label>
              <div className={classNames(styles.formInputRelative, validation?.price ? styles.titleError : '')}>
                <input
                  disabled={addProductDetailSlice?.id.variant.allFinalVariant?.length > 0 ? true : false}
                  type="text"
                  // required
                  className={styles.secondInputAlignment}
                  onChange={(e) => handleChnage(e)}
                  name="price"
                  value={productData?.inventoryPrice?.price}
                />{' '}
                <div className={styles.iconAlignment2}>
                  <span>$</span>
                </div>
              </div>
            </div>
            <div className={styles.formInputDetailsAlignment}>
              <label>List Price</label>
              <div className={classNames(styles.formInputRelative, validation?.listPrice ? styles.titleError : '')}>
                <input
                  type="text"
                  // required
                  disabled={addProductDetailSlice?.id.variant.allFinalVariant?.length > 0 ? true : false}
                  className={styles.secondInputAlignment}
                  onChange={(e) => handleChnage(e)}
                  name="listPrice"
                  value={productData?.inventoryPrice?.listPrice}
                />{' '}
                <div className={styles.iconAlignment2}>
                  <span>$</span>
                </div>
              </div>
            </div>
            <div className={styles.formInputDetailsAlignment}>
              <label>Quantity</label>
              <div className={validation?.quantity ? styles.titleError : ''}>
                <input
                  // required
                  disabled={addProductDetailSlice?.id.variant.allFinalVariant?.length > 0 ? true : false}
                  type="text"
                  onChange={(e) => handleChnage(e)}
                  name="quantity"
                  value={productData?.inventoryPrice?.quantity}
                />
              </div>
            </div>
            <div className={styles.formInputDetailsAlignment}>
              <label>SKU</label>
              <div className={validation?.sku ? styles.titleError : ''}>
                <input
                  // required
                  disabled={addProductDetailSlice?.id.variant.allFinalVariant?.length > 0 ? true : false}
                  type="text"
                  onChange={(e) => handleChnage(e)}
                  name="sku"
                  value={productData?.inventoryPrice?.sku}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
