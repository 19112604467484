import { gql } from '@apollo/client'

export default gql`
  query getAllProducts(
    $category: String
    $subCategory: String
    $childSubCategory: String
    $isShopifyProduct: Boolean
    $where: JSON
  ) {
    getAllProducts(
      category: $category
      subCategory: $subCategory
      childSubCategory: $childSubCategory
      isShopifyProduct: $isShopifyProduct
      where: $where
    ) {
      id
      store_id
      title
      description
      isFeature
      isActive
      metaTitle
      keywords
      metaDescription
      isVisible
      isFreeShipping
      condition
      is_deleted
      likes {
        id
      }
      variants {
        id
        title
        price
        sku
        barcode
        inventory_quantity
        old_inventory_quantity
        image_id
        image {
          media
        }
        total_variant {
          variant_option_id
          variant_option {
            value
            variation_id
          }
        }
      }
      inventoryPrice {
        price
        listPrice
        quantity
        sku
      }
      shipping {
        weightValue
        weightUnit
        unit
        length
        width
        height
      }
      categories {
        category_id
        subCategory_id
        childSubCategory_id
      }
      options {
        name
        data {
          value
        }
      }
      attributes {
        name
        value
      }
      images {
        media_id
        src
      }
      tags {
        tag
      }
    }
  }
`

// import { gql } from '@apollo/client'

// export default gql`
//   query getAllProducts($start: Int, $limit: Int, $sort: String, $where: JSON) {
//     getAllProducts(start: $start, limit: $limit, sort: $sort, where: $where) {
//       id
//       category_id
//       subCategory_id
//       childSubCategory_id
//       title
//       description
//       price
//       listPrice
//       quantity
//       sku
//       filterImage
//       shareCount
//       likeCount
//       commentCount
//       customersCount
//       customers {
//         id
//         firstName
//         lastName
//         profileAvtar
//       }
//       image {
//         id
//         url
//       }
//       video {
//         id
//         url
//       }
//       isFeature
//       isActive
//       store: store_id
//       category: category_id
//       createdAt
//       metaTitle
//       keywords
//       metaDescription
//       isVisible
//       isFreeShipping
//       condition
//       tags
//       categories {
//         category {
//           name
//           id
//         }
//         subCategory {
//           name
//           id
//         }
//         childSubCategory {
//           name
//           id
//         }
//       }
//       attributes {
//         name
//         value
//       }
//       colors {
//         name
//         colorCode
//       }
//       sizes {
//         size
//       }
//       other {
//         name
//         data
//       }
//       shipping {
//         weightValue
//         weightUnit
//         length
//         width
//         height
//         unit
//       }
//       comment: productComments {
//         comment
//         user {
//           id
//           firstName
//           lastName
//         }
//       }
//       cropImages {
//         id
//         mediaId
//         oldFile
//         croppedFile {
//           mediaId
//           baseURL
//           zoom
//           rotate
//           crop {
//             x
//             y
//           }
//         }
//       }
//       ratio
//       size {
//         height
//         width
//       }
//     }
//   }
// `
