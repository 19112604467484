import React from 'react'
import styles from './CollectionSharedModal.module.scss'
export default function CollectionSharedModal() {
  return (
    <div className={styles.collectionSharedModal}>
      <div className={styles.collectionSharedWrapper}>
        <div className={styles.collectionSharedModalBox}>
          <div className={styles.collectionIcon}>
            <img src="/assets/icon/colletion-right-icon.svg" alt="collection icon " />
          </div>
          <p>Collection shared.</p>
        </div>
      </div>
    </div>
  )
}
