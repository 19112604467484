import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

const FramerAnimationModal = ({ children }) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{
          opacity: 0,
          scale: 0.1,
        }}
        animate={{
          opacity: 1,
          scale: 1,
          transition: {
            ease: 'easeOut',
            duration: 0.25,
          },
        }}
        exit={{
          opacity: 0,
          scale: 0.1,
          transition: {
            ease: 'easeIn',
            duration: 0.25,
          },
        }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}

export default FramerAnimationModal
