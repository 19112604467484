import { gql } from '@apollo/client'

const START_LIVE_STREAM = gql`
mutation StartLiveStream($uuid: String, $time: String) {
  startLiveStream(uuid: $uuid, time: $time) {
    success
    message
  }
}
`
export default START_LIVE_STREAM
