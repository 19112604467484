
import React from 'react';


export function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
        <div className={className} onClick={onClick}>
            <svg
                style={{ transform: 'none' }}
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
            >
                <circle opacity="0.6" cx="20" cy="20" r="20" fill="#F8F8F8" />
                <path d="M19 15L23 20L19 25" stroke="#1E2432" strokeLinecap="round" strokeLinejoin="round" />
            </svg>{' '}
        </div>
    )
}

export function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
        <div className={className} onClick={onClick}>
            <svg
                style={{ transform: 'rotate(180deg)' }}
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
            >
                <circle opacity="0.6" cx="20" cy="20" r="20" fill="#F8F8F8" />
                <path d="M19 15L23 20L19 25" stroke="#1E2432" strokeLinecap="round" strokeLinejoin="round" />
            </svg>{' '}
        </div>
    )
}