// @ts-nocheck

import { Modal } from 'antd'
import React, { useState } from 'react'
import Slider from 'react-slick'
import NoGalleryMedia from './NoGalleryMedia'

function Gallery({ getSingleProduct, isGallery }) {
  const RightIcon = '/assets/img/product-detail/right-icon.svg'
  const LeftIcon = '/assets/img/product-detail/left-icon.svg'
  const [openPreview, setOpenPreview] = useState({ image: '', modal: false })
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [previewURL, setPreviewURL] = useState('')

  const settings = {
    className: 'center',
    dots: isGallery && false,
    centerMode: false,
    infinite: false,
    centerPadding: '60px',
    slidesToShow: 2.5,
    speed: 500,
    rows: 2,
    slidesPerRow: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  const settings1 = {
    className: 'center',
    dots: false,
    centerMode: false,
    infinite: false,
    centerPadding: '60px',
    slidesToShow: 2,
    speed: 500,
    rows: 2,
    slidesPerRow: 2,
    nextArrow: <></>,
    prevArrow: <></>,
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <div className="right-full-arrow-alignment" onClick={onClick}>
        <img src={RightIcon} alt="RightIcon" />
      </div>
    )
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <div className="left-full-arrow-alignment" onClick={onClick}>
        <img src={LeftIcon} alt="LeftIcon" />
      </div>
    )
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const galleryIamge = (url: string) => {
    setPreviewURL(url)
    setIsModalVisible(true)
  }

  
  let media = getSingleProduct?.flatMap(review => review.userReviewsOnProductsMedias) || []

  return (
    <>
      <div className="gallery-container-main">
        {isGallery && (
          <div className="image-count-section">
            <span>{media.length} images</span>
            <p>See All</p>
          </div>
        )}
        {openPreview.modal ? (
          <div className="image-preview-section-main">
            <div className="image-preview-section">
              <div className="image-preview-align">
                <img src={openPreview.image} />
              </div>
              {/* <div className="image-comment-section-main">
                <div className="image-section-flex">
                  <div className="image-alignment">
                    <img src={'/assets/img/product-detail/c-image-5.jpg'} />
                  </div>
                  <span>Brenda Jones</span>
                  <p>2m</p>
                </div>
                <div className="comment-description-align">
                  <p>Can you provide additional information for these speakers?</p>
                </div>
                <div className="goto-comment-section">
                  <span>Go to Comment</span>
                </div>
              </div> */}
            </div>
            <div className="section-devide"></div>
            <div className="slider-section">
              <Slider {...settings1}>
                {media.map((data, index) => {
                  return (
                    <div
                      className="main-image-container-align-pre"
                      onClick={() => setOpenPreview({ image: data.url, modal: true })}
                      key={index}
                    >
                      <div className="image-contaoner-main-pre">
                        <img src={data.url} />
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          </div>
        ) : (
          <Slider {...settings}>
            {media.map((data, index) => {
              return (
                <div
                  className="main-image-container"
                  onClick={() => setOpenPreview({ image: data.url, modal: true })}
                  key={index}
                >
                  <div className="image-contaoner-main">
                    <img src={data.url} />
                  </div>
                  {!isGallery && (
                    <div className="image-info-main">
                      <div className="image-rate-section">
                        <div className="star-count-section">
                          <span>5</span>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.06414 1.92489C9.79234 0.468488 11.8707 0.468489 12.5989 1.92489L14.5082 5.7434C14.8036 6.33433 15.3749 6.7385 16.0304 6.82044L19.5455 7.25983C21.1257 7.45735 21.8351 9.34718 20.7752 10.5357L18.2132 13.4086C17.8139 13.8564 17.6396 14.4613 17.7396 15.0529L18.4825 19.4509C18.7519 21.0453 17.0967 22.2706 15.6505 21.5475L11.7152 19.5798C11.1589 19.3017 10.5041 19.3017 9.94783 19.5798L6.01889 21.5443C4.57147 22.268 2.91541 21.0402 3.18731 19.4449L3.93537 15.0559C4.03648 14.4626 3.86173 13.8556 3.46064 13.4069L0.894897 10.5368C-0.167149 9.34874 0.541729 7.4568 2.12298 7.25915L5.6326 6.82044C6.28818 6.7385 6.85942 6.33433 7.15489 5.7434L9.06414 1.92489Z"
                              fill="#FF7D23"
                            />
                          </svg>
                        </div>
                        <div className="image-count-alignment">
                          <img src={'/assets/img/product-detail/dummy-image-icon.svg'} />
                          <span>2</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </Slider>
        )}
      </div>
      {/* <NoGalleryMedia /> */}
    </>
  )
}

export default Gallery
