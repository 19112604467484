export default function ShoppingIcon({ small }: { small?: boolean }) {
    return (
        small ?
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <g clip-path="url(#clip0_602_294)">
                    <path d="M2 6V13.1765C2 13.5717 2.14048 13.9507 2.39052 14.2302C2.64057 14.5097 2.97971 14.6667 3.33333 14.6667H12.6667C13.0203 14.6667 13.3594 14.5097 13.6095 14.2302C13.8595 13.9507 14 13.5717 14 13.1765V6" stroke="#7A7E88" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M6 14.6667V10.6667C6 9.56218 6.89543 8.66675 8 8.66675V8.66675C9.10457 8.66675 10 9.56218 10 10.6667V14.6667" stroke="#7A7E88" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M1.82683 1.73731C1.93189 1.49218 2.17291 1.33325 2.4396 1.33325H4.66667L4.20581 4.55925C4.08772 5.3859 3.37975 5.99992 2.54471 5.99992V5.99992C1.34007 5.99992 0.527878 4.7682 1.00241 3.66097L1.82683 1.73731Z" stroke="#7A7E88" />
                    <path d="M4.66667 1.33325H8V4.14205C8 5.16812 7.16821 5.99992 6.14214 5.99992V5.99992C5.01153 5.99992 4.14305 4.99855 4.30294 3.87931L4.66667 1.33325Z" stroke="#7A7E88" />
                    <path d="M8 1.33325H11.3333L11.6971 3.87931C11.8569 4.99855 10.9885 5.99992 9.85786 5.99992V5.99992C8.83179 5.99992 8 5.16812 8 4.14205V1.33325Z" stroke="#7A7E88" />
                    <path d="M11.3333 1.33325H13.5603C13.827 1.33325 14.068 1.49218 14.1731 1.73731L14.9975 3.66096C15.472 4.7682 14.6599 5.99992 13.4552 5.99992V5.99992C12.6202 5.99992 11.9122 5.3859 11.7941 4.55925L11.3333 1.33325Z" stroke="#7A7E88" />
                </g>
                <defs>
                    <clipPath id="clip0_602_294">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            :
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                <g clip-path="url(#clip0_602_494)">
                    <path d="M1.875 5.625V12.3529C1.875 12.7235 2.0067 13.0788 2.24112 13.3408C2.47554 13.6028 2.79348 13.75 3.125 13.75H11.875C12.2065 13.75 12.5245 13.6028 12.7589 13.3408C12.9933 13.0788 13.125 12.7235 13.125 12.3529V5.625" stroke="#7A7E88" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M5.625 13.7502V10.0002C5.625 8.96471 6.46447 8.12524 7.5 8.12524V8.12524C8.53553 8.12524 9.375 8.96471 9.375 10.0002V13.7502" stroke="#7A7E88" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M1.71266 1.62856C1.81114 1.39875 2.03711 1.24976 2.28712 1.24976H4.375L3.94295 4.27413C3.83223 5.04911 3.16851 5.62476 2.38566 5.62476V5.62476C1.25631 5.62476 0.494885 4.47002 0.939758 3.43199L1.71266 1.62856Z" stroke="#7A7E88" />
                    <path d="M4.375 1.24976H7.5V3.88301C7.5 4.84495 6.72019 5.62476 5.75825 5.62476V5.62476C4.69831 5.62476 3.88411 4.68597 4.03401 3.63669L4.375 1.24976Z" stroke="#7A7E88" />
                    <path d="M7.5 1.24976H10.625L10.966 3.63669C11.1159 4.68597 10.3017 5.62476 9.24175 5.62476V5.62476C8.27981 5.62476 7.5 4.84495 7.5 3.88301V1.24976Z" stroke="#7A7E88" />
                    <path d="M10.625 1.24976H12.7129C12.9629 1.24976 13.1889 1.39875 13.2873 1.62856L14.0602 3.43199C14.5051 4.47002 13.7437 5.62476 12.6143 5.62476V5.62476C11.8315 5.62476 11.1678 5.04911 11.0571 4.27413L10.625 1.24976Z" stroke="#7A7E88" />
                </g>
                <defs>
                    <clipPath id="clip0_602_494">
                        <rect width="15" height="15" fill="white" />
                    </clipPath>
                </defs>
            </svg>
    )
}