import React, { useEffect, useRef, useState } from 'react'
import styles from './SellerOnboardingGetStarted.module.scss'
import classNames from 'classnames'

import { Country, State, City } from 'country-state-city'
import useOnClickOutside from 'src/components/Hook/useOnClickOutSide'

export default function SellerOnboardingGetStarted({ setInputValue, inputValue, handleSubmit }) {
  const closeProductRef: any = useRef()
  const closeBusinessRef: any = useRef()
  const array = ['Women - Apparel', 'Men - Apparel']
  const employment = ['Full-time', 'Part-time', 'Freelancer']
  const [isOpenProduct, setIsOpenProduct] = useState(false)
  const [isOpenBusiness, setIsOpenBusiness] = useState(false)
  // const [dropDown, setDropDown] = useState({ state: false, city: false })
  const allStates = State.getStatesOfCountry('US')
  const [filteredStates, setFilteredStates] = useState(allStates)

  const allCities = City.getCitiesOfState('US', inputValue?.state)

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputValue((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleInputChange = (e) => {
    const inputData = e.target.value.toLowerCase() // Convert the input value to lowercase for case-insensitive filtering
    const filtered = allStates.filter((state) => state.name.toLowerCase().includes(inputData))
    setFilteredStates(filtered)
  }

  const handleSelect = (field, type) => {
    setInputValue((prevState) => ({ ...prevState, [field]: type }))
  }

  const handleStateSelect = (e, name) => {
    if (name === 'state') {
      let isoCode = allStates.find((state) => state.name === e?.target.value)?.isoCode
      setInputValue({ ...inputValue, state: isoCode, state_name: e?.target.value })
    } else {
      setInputValue({ ...inputValue, city: e.target.value })
    }
    // setDropDown({ state: false, city: false })
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpenProduct && closeProductRef.current && !closeProductRef.current.contains(e.target)) {
        setIsOpenProduct(null)
      }
      if (isOpenBusiness && closeBusinessRef.current && !closeBusinessRef.current.contains(e.target)) {
        setIsOpenBusiness(null)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isOpenProduct, isOpenBusiness])

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className={styles.sellerOnboardingGetStartedSection}>
        <div className={styles.sellerOnboardingGetStartedHeading}>
          <h5>Let’s get started </h5>
        </div>
        <div className={styles.sellerApplicationFormAlignment}>
          <div className={styles.sellerApplicationFormBox}>
            <h6>Seller Application</h6>
            <p>Complete these steps to begin your seller application.</p>
            <div className={styles.sellerApplicationFormDetails}>
              <div className={styles.sellerApplicationFormFirstRow}>
                <div className={styles.sellerApplicationFormInput}>
                  <label htmlFor="">Title</label>
                  <div className={styles.sellerApplicationFormInputBox}>
                    <input
                      required
                      id="title"
                      type="text"
                      placeholder="Title"
                      name="title"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className={styles.sellerApplicationFormInput}>
                  <label htmlFor="">First name</label>
                  <div className={styles.sellerApplicationFormInputBox}>
                    <input
                      required
                      type="text"
                      placeholder="First name"
                      name="firstName"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className={styles.sellerApplicationFormInput}>
                  <label htmlFor="">Last name</label>
                  <div className={styles.sellerApplicationFormInputBox}>
                    <input
                      required
                      type="text"
                      placeholder="Last name"
                      name="lastName"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.sellerApplicationFormSecondRow}>
                <div className={styles.sellerApplicationFormInput}>
                  <label htmlFor="">Legal Company Name</label>
                  <div className={styles.sellerApplicationFormInputBox}>
                    <input
                      required
                      type="text"
                      placeholder="Name"
                      name="companyLegalName"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className={styles.sellerApplicationFormInput}>
                  <label htmlFor="">Phone</label>
                  <div className={styles.sellerApplicationFormInputBox}>
                    <input
                      required
                      type="text"
                      placeholder="(123)456-7890"
                      name="phoneNumber"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.sellerApplicationFormSecondRow}>
                <div className={styles.sellerApplicationFormInput}>
                  <label htmlFor="">Company Email</label>
                  <div className={styles.sellerApplicationFormInputBox}>
                    <input required type="text" placeholder="Name" name="email" onChange={(e) => handleChange(e)} />
                  </div>
                </div>
                <div className={styles.sellerApplicationFormInput}>
                  <label htmlFor="">Company Website</label>
                  <div className={styles.sellerApplicationFormInputBox}>
                    <input
                      required
                      type="text"
                      placeholder="company.com"
                      name="websiteUrl"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.sellerApplicationAddressRow}>
                <div className={styles.sellerApplicationFormInput}>
                  <label htmlFor="">Business Address</label>
                  <div className={styles.sellerApplicationFormInputBox}>
                    <input
                      required
                      id="streetAddress"
                      type="text"
                      placeholder="Address"
                      name="streetAddress"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.sellerApplicationOtherRow}>
                <div className={styles.sellerApplicationFormInput}>
                  <div className={styles.sellerApplicationFormInputBox}>
                    {/* <input required type="text" placeholder="State" name="state" onChange={(e) => handleChange(e)} /> */}

                    <select
                      name="country"
                      value={inputValue?.state_name}
                      onChange={(e) => handleStateSelect(e, 'state')}
                      required
                    >
                      <option selected value="">
                        Select state
                      </option>
                      {filteredStates.map((state, index) => {
                        return (
                          <option value={state?.name} key={index}>
                            {state?.name}
                          </option>
                        )
                      })}
                    </select>

                    {/* <div
                      className={classNames(styles.sellerApplicationMultipleOption, styles.mobileViewClubCategoryInput)}
                    >
                      <input
                        type="text"
                        required={inputValue?.state ? false : true}
                        placeholder="Select state"
                        value={inputValue?.state_name ?? ''}
                        onChange={handleInputChange}
                        onClick={() => setDropDown({ state: true, city: false })}
                      />

                      <div className={styles.dropdownArrow}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M6 9L12 15L18 9"
                            stroke="#ACB1C0"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div
                        className={
                          dropDown?.state
                            ? classNames(
                                styles.sellerOnboardingHourlyRateDropdownDesign,
                                styles.sellerOnboardingDropdownShow,
                              )
                            : classNames(
                                styles.sellerOnboardingHourlyRateDropdownDesign,
                                styles.sellerOnboardingDropdownHidden,
                              )
                        }
                        ref={ref}
                      >
                        <ul>
                          {filteredStates?.map(
                            (item) => (
                              <li onClick={() => handleStateSelect(item, 'state')}> {item?.name}</li>
                            ),
                            // <li className={styles.activeSelect}> {item?.label}</li>
                          )}
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className={styles.sellerApplicationFormInput}>
                  <div className={styles.sellerApplicationFormInputBox}>
                    {/* <input required type="text" placeholder="City" name="city" onChange={(e) => handleChange(e)} /> */}

                    <select
                      name="country"
                      value={inputValue?.city}
                      onChange={(e) => handleStateSelect(e, 'city')}
                      required
                      className={(styles.sellerApplicationMultipleOption, styles.mobileViewClubCategoryInput)}
                    >
                      <option selected value="">
                        Select city
                      </option>
                      {allCities.map((city, index) => {
                        return (
                          <option value={city?.name} key={index}>
                            {city?.name}
                          </option>
                        )
                      })}
                    </select>
                    {/* <div
                      className={classNames(styles.sellerApplicationMultipleOption, styles.mobileViewClubCategoryInput)}
                    >
                      <input
                        type="text"
                        required={inputValue?.city ? false : true}
                        placeholder="Select"
                        value={inputValue?.city ?? ''}
                        onClick={() => setDropDown({ city: !dropDown?.city, state: false })}
                      />

                      <div className={styles.dropdownArrow}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M6 9L12 15L18 9"
                            stroke="#ACB1C0"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div
                        className={
                          dropDown?.city
                            ? classNames(
                                styles.sellerOnboardingHourlyRateDropdownDesign,
                                styles.sellerOnboardingDropdownShow,
                              )
                            : classNames(
                                styles.sellerOnboardingHourlyRateDropdownDesign,
                                styles.sellerOnboardingDropdownHidden,
                              )
                        }
                        ref={refCity}
                      >
                        <ul>
                          {allCities?.map(
                            (item) => (
                              <li onClick={() => handleStateSelect(item?.name, 'city')}> {item?.name}</li>
                            ),
                            // <li className={styles.activeSelect}> {item?.label}</li>
                          )}
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>{' '}
                <div className={styles.sellerApplicationFormInput}>
                  <div className={styles.sellerApplicationFormInputBox}>
                    <input
                      required
                      type="text"
                      placeholder="Zipcode"
                      name="postalCode"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.sellerApplicationFormFiveRow}>
                <div className={styles.sellerApplicationMultipleOption}>
                  <div style={{ width: '100%' }}>
                    <label htmlFor="">Product</label>
                    <select
                      name="country"
                      value={inputValue?.city}
                      // onChange={(e) => handleStateSelect(e, 'city')}
                      required
                      className={(styles.sellerApplicationMultipleOption, styles.mobileViewClubCategoryInput)}
                      style={{ width: '100%' }}
                    >
                      {/* <div className={styles.sellerOnboardingHourlyRateDropdownDesignAlignment}> */}
                      {array.map((type, index) => {
                        return (
                          <option onClick={() => handleSelect('productType', type)} key={index}>
                            {type}
                          </option>
                        )
                      })}
                      {/* </div> */}
                    </select>
                  </div>
                </div>

                {/* <div
                  className={styles.sellerApplicationMultipleOption}
                  onClick={() => setIsOpenProduct(!isOpenProduct)}
                >
                  <div>
                    <label htmlFor="">Product</label>
                    <p>{inputValue?.productType}</p>
                  </div>
                  <div className={styles.dropdownArrow}>
                    <img src="/assets/seller/icons/downArrowGrey2.svg" alt="grey arrow" />
                  </div>
                  <div
                    className={
                      isOpenProduct
                        ? classNames(
                            styles.sellerOnboardingHourlyRateDropdownDesign,
                            styles.sellerOnboardingDropdownShow,
                          )
                        : classNames(
                            styles.sellerOnboardingHourlyRateDropdownDesign,
                            styles.sellerOnboardingDropdownHidden,
                          )
                    }
                    ref={closeProductRef}
                  >
                    <div className={styles.sellerOnboardingHourlyRateDropdownDesignAlignment}>
                      {array.map((type) => {
                        return <a onClick={() => handleSelect('productType', type)}>{type}</a>
                      })}
                    </div>
                  </div>
                </div> */}
                <div
                  className={styles.sellerApplicationMultipleOption}
                  onClick={() => {
                    setIsOpenBusiness(!isOpenBusiness)
                  }}
                >
                  <div>
                    <label htmlFor="">Business Type</label>
                    <p>{inputValue?.businessType}</p>
                  </div>
                  <div className={styles.dropdownArrow}>
                    <img src="/assets/seller/icons/downArrowGrey2.svg" alt="grey arrow" />
                  </div>
                  <div
                    className={
                      isOpenBusiness
                        ? classNames(
                            styles.sellerOnboardingHourlyRateDropdownDesign,
                            styles.sellerOnboardingDropdownShow,
                          )
                        : classNames(
                            styles.sellerOnboardingHourlyRateDropdownDesign,
                            styles.sellerOnboardingDropdownHidden,
                          )
                    }
                    ref={closeBusinessRef}
                  >
                    <div className={styles.sellerOnboardingHourlyRateDropdownDesignAlignment}>
                      {employment.map((type, index) => {
                        return (
                          <a onClick={() => handleSelect('businessType', type)} key={index}>
                            {type}
                          </a>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.startedSubmitButton}>
          <button type="submit">Submit</button>
        </div>
      </div>
    </form>
  )
}
