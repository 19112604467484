import { useState, useEffect, useContext, createContext } from 'react'
import nookies from 'nookies'
import GET_CURRENT_USER from 'src/graphql/queries/getCurrentUser'
import { User } from 'src/types'
import { useRouter } from 'next/router'
import sellerClient from './apolloSellerClient'
import client1 from 'src/utils/apolloClient'

type ContextProps = {
  loading: boolean
  user: User | null
  seller: any | null
  updateUser: (user: any) => void
  setUser: (user: any) => void
  setSeller: (seller: any) => void
}

const AuthContext = createContext<Partial<ContextProps>>({})

export const AuthProvider = ({ children }) => {
  const router = useRouter()
  const [user, setUser] = useState(null)
  const [seller, setSeller] = useState(null)
  const [loading, setLoading] = useState(false)

  const updateUser = (user: any) => {
    setUser(user)
    setSeller(user)
  }
  const { token, seller_token } = nookies.get()

  async function getcurrentUserData() {
    // if (!token) {
    //   setUser(null)
    //   nookies.set(undefined, 'token', '', {
    //     path: '/',
    //   })
    // setLoading(false)
    //   return
    // }
    try {
      const provider = client1

      if (provider) {
        const {
          data: { currentUser },
        } = await provider.query({
          query: GET_CURRENT_USER,
          fetchPolicy: 'network-only',
        })
        if (currentUser) {
          if (currentUser?.seller) {
            setSeller(currentUser?.seller)
            if (currentUser?.seller?.user) {
              setUser(currentUser?.seller?.user)
            }
          }
          if (currentUser?.user) {
            setUser(currentUser?.user)
            if (currentUser?.user?.seller) {
              setSeller(currentUser?.user?.seller)
            }
          }
        }
        setLoading(false)
      }
    } catch (error) {
      setUser(null)
      nookies.set(undefined, 'token', '', {
        path: '/',
      })
      setLoading(false)
    }
  }

  useEffect(() => {
    if (token) {
      getcurrentUserData()
    } else {
      setLoading(false)
    }
  }, [router])

  return (
    <AuthContext.Provider value={{ user, loading, updateUser, setUser, setSeller, seller }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
