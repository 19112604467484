const protocol = 'https'
const host = 'graph.api.bluejestic.com/graphql'
const port = ''
const trailUrl = ''

const hostUrl = `${protocol}://${host}${port ? ':' + port : ''}`
const endpoint = `${protocol}://${host}${port ? ':' + port : ''}${trailUrl}`

export default {
  protocol: protocol,
  host: host,
  port: port,
  apiUrl: trailUrl,
  endpoint: endpoint,
  hostUrl: hostUrl,
}
