import React from 'react'
import { useMutation } from '@apollo/client'
import { useSelector } from 'react-redux'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useStateContext } from 'src/utils/state'
import { REMOVE_GROUP_MSG, REMOVE_PRIVATE_MSG } from 'src/graphql/mutations/mutations'
import client1 from 'src/utils/apolloClient'

export default function MobileViewMessageDelete() {
  const dispatch = useAppDispatch()
  const { selectedChat, notify, updateName, selectChat } = useStateContext()
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const selectedMessage = fetchMobileModal?.lastModalData?.id?.message


  const [removePrivateMessage] = useMutation(REMOVE_PRIVATE_MSG, {
    client: client1,
    onError: (err) => {
      alert(err)
    },
  })

  const [removeGroupMessage] = useMutation(REMOVE_GROUP_MSG, {
    client: client1,
    onError: (err) => {
      alert(err)
    },
  })
  const deleteMessageHandler = async () => {
    if (selectedChat?.chatType === 'group') {
      await removeGroupMessage({
        variables: {
          removeGroupMessageId: selectedMessage?.id,
          conversationId: selectedChat.chatData.id,
        },
        update: () => {
          clearInput()
        },
      })
    } else {
      await removePrivateMessage({
        variables: {
          removePrivateMessageId: selectedMessage?.id,
          receiverId: selectedChat.chatData.id,
        },
        update: () => {
          clearInput()
        },
      })
    }
  }

  const clearInput = () => {
    dispatch(
      fetchMobileModalData({
        ...fetchMobileModal,
        secondModal: {},
      }),
    )
    dispatch(
      fetchMobileModalData({
        allModalData: [
          ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
          {
            modal: 'chatBoxModal',
            isOpen: true,
            id: {
              selectedMessageId: null,
              isMessageSelected: false,
            },
            transform: 42,
            zIndex: 9999999,
          },
        ],
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
  }

  return (
    <div className="more-option-modal-body-details-alignment">
      <p>
        Are you sure you want to delete this message?
        <br />
        This action only affects to you.
      </p>
      <div className="mobile-delete-modal-option-alignment">
        <ul>
          <li className="delete-list" onClick={deleteMessageHandler}>Delete</li>
          <li
            onClick={() => {
              clearInput()
            }}
          >
            Cancel
          </li>
        </ul>
      </div>
    </div>
  )
}
