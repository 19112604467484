import React, { useEffect, useRef, useState } from 'react'
import styles from './VariantDisplayModel.module.scss'
import classNames from 'classnames'
import { useAppDispatch } from 'src/store/store'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
import { useSelector } from 'react-redux'
import AddFilterDropdownBox from 'src/components/Seller/SellerProducts/AddProductModal/AddFilterDropdownBox/AddFilterDropdownBox'

function VariantDisplayModel() {
  const [LbsDropdown, setLbsDropdown] = useState(false)
  const [InDropdown, setInDropdown] = useState(false)
  const dropdownRef: any = useRef()
  const filterRef: any = useRef()
  const dispatch = useAppDispatch()
  const [unitName, setUnitName] = useState({})
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const [newVariantData, setNewVariantData] = useState(addProductDetailSlice?.id?.variant?.allOptions || [])

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([])
  const [isEditData, setIsEditData] = useState(addProductDetailSlice?.id?.isEditableData || {})
  const [sizeDropdown, setSizeDropdown] = useState(false)
  const [colorDropdown, setColorDropdown] = useState(false)
  let unitObject = [
    { unit: 'lbs', name: 'pound', data: [{ unit: 'in', dataName: 'inch' }] },
    { unit: 'kg', name: 'kilogram', data: [{ unit: 'cm', dataName: 'centimeter' }] },
    { unit: 'gm', name: 'gram', data: [{ unit: 'cm', dataName: 'centimeter' }] },
  ]

  const productRef: any = useRef()

  const [dropdownIndex, setDropdownIndex] = useState(null)
  const [filteredData, setFilteredData] = useState([])
  const transformedArray = getAllState?.allModalData[0]?.isEdit
    ? addProductDetailSlice?.id?.variant?.colorCodeArray?.map((item) => {
        if (item.name === 'Color') {
          const colorData = item?.data?.map((data) => {
            return { name: data?.name, colorCode: data?.colorCode }
          })
          return { name: item?.name, data: colorData }
        } else if (item.name !== 'Color' && item.name !== 'SIZE') {
          return { name: item?.name, data: item?.data }
        } else {
          return item
        }
      })
    : addProductDetailSlice?.id?.variant?.allVariant?.map((item) => {
        if (item.name === 'Color') {
          const colorData = item?.data?.map((data) => {
            return { name: data?.name, colorCode: data?.colorCode }
          })
          return { name: item?.name, data: colorData }
        } else if (item.name !== 'Color' && item.name !== 'SIZE') {
          return { name: item?.name, data: item?.data }
        } else {
          return item
        }
      })

  const [filterdDropdown, setFilterdDropdown] = useState(transformedArray || [])

  const [newFilterdDropdown, setNewFilterdDropdown] = useState(false)
  const deepEqual = (arr1, arr2) => JSON.stringify(arr1) === JSON.stringify(arr2)

  const handleChangeVariant = async (index, data) => {
    let finalIndex = newVariantData.findIndex((item) => deepEqual(item.total_variant, data.total_variant))
    await dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          isEditableData: {
            index: finalIndex,
            data: data,
          },
        },
      }),
    )
    setIsEditData({
      ...isEditData,
      index: finalIndex,
      data: data,
    })
  }

  useEffect(() => {
    if (selectedCheckboxes.length > 0) {
      let finalData = []
      newVariantData
        ?.filter((item) => item.isChecked)
        .map((item, index) => {
          return finalData.push(item)
        })
      let finalIndex = newVariantData?.findIndex((item) =>
        deepEqual(item?.total_variant, finalData?.[0]?.total_variant),
      )

      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            isEditableData: {
              index: finalIndex,
              data: finalData?.[0],
            },
          },
        }),
      )
      setIsEditData({
        ...isEditData,
        index: finalIndex,
        data: finalData?.[0],
      })
    }
  }, [selectedCheckboxes])

  const handleCheckData = (e, data, index) => {
    const updatedData = [...newVariantData]
    updatedData[index] = { ...updatedData[index], isChecked: e.target.checked } // Update the specific item
    let newVarient = updatedData

    setNewVariantData((prevData) => {
      const updatedData = [...prevData] // Create a copy of the array
      updatedData[index] = { ...updatedData[index], isChecked: e.target.checked } // Update the specific item
      return updatedData
    })

    let NewSelectedFilter = []

    filterdDropdown?.map((item, index) => {
      item?.data?.map((data) => {
        let filterVarient = []
        newVarient?.map((variant) => {
          if (variant?.total_variant?.includes(data?.name ? data?.name : data)) {
            filterVarient.push(variant)
          }
          return variant
        })

        if (filterVarient?.every((item) => item?.isChecked && !NewSelectedFilter?.includes(data))) {
          NewSelectedFilter?.push(data)
        }
      })
    })

    const removedColorCode = NewSelectedFilter.map((item) => {
      if (typeof item === 'object' && item.name) {
        return item.name
      } else {
        return item
      }
    })

    setSelectedCheckboxes(removedColorCode)
    setFilteredData(NewSelectedFilter)

    // setSelectedCheckboxes(NewSelectedFilter)
    // setFilteredData(NewSelectedFilter)
  }

  const handleFilteredBox = (e, data, colorCode) => {
    const checkboxValue = e.target.checked

    let updatedSelectedCheckboxes = [...selectedCheckboxes]
    let updatedFilteredData = [...filteredData]

    if (checkboxValue) {
      if (!updatedSelectedCheckboxes.includes(data)) {
        updatedSelectedCheckboxes.push(data)
      }
      if (colorCode) {
        updatedFilteredData.push({ name: data, colorCode })
      } else {
        updatedFilteredData.push(data)
      }
    } else {
      updatedSelectedCheckboxes = updatedSelectedCheckboxes.filter((item) => item !== data)
      if (colorCode) {
        updatedFilteredData = updatedFilteredData.filter((item) => item?.name !== data)
      } else {
        updatedFilteredData = updatedFilteredData.filter((item) => item !== data)
      }
    }
    setSelectedCheckboxes(updatedSelectedCheckboxes)
    setFilteredData(updatedFilteredData)
    const updatedData = newVariantData.map((item) => {
      const isChecked = updatedSelectedCheckboxes.some((selectedData) => item?.total_variant?.includes(selectedData))
      return { ...item, isChecked }
    })

    setNewVariantData(updatedData)
    const currentDropdownData = filterdDropdown[dropdownIndex]?.data
    const isAllChecked = currentDropdownData?.every((data) => updatedSelectedCheckboxes?.includes(data))

    if (isAllChecked) {
      const allData = filterdDropdown?.reduce((acc, dropdown) => [...acc, ...dropdown.data], [])
      setSelectedCheckboxes(allData)
      updateAllCheckboxes(true)
    }
  }

  const updateAllCheckboxes = (checked) => {
    const allData = filterdDropdown.reduce((acc, dropdown) => [...acc, ...dropdown.data], [])
    const updatedData = newVariantData.map((item) => ({
      ...item,
      isChecked: checked,
    }))
    setNewVariantData(updatedData)
    setSelectedCheckboxes(checked ? allData : [])
  }

  const handleSelectUnit = async (un) => {
    let finalData = []
    finalData = newVariantData.map((item, i) => {
      if (i === isEditData.index) {
        return { ...item, weightUnit: un?.name, unit: un?.unit === 'lbs' ? 'inch' : 'centimeter' }
      }
      return item
    })
    setUnitName({ ...unitName, [isEditData.index]: un?.unit })
    setNewVariantData(finalData)
  }

  const handleSaveNewVariant = async () => {
    let finalData = []
    finalData = newVariantData.map((item, i) => {
      if (i === isEditData.index) {
        return { ...item, ...isEditData.data }
      }
      return item
    })

    const isCheckedRemoved = finalData.map((variant) => {
      const { isChecked, ...rest } = variant
      return rest
    })

    await dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          variant: {
            ...addProductDetailSlice?.id.variant,
            allOptions: isCheckedRemoved,
            isVariantModel: false,
          },
        },
      }),
    )
  }

  const handleResetInfo = async () => {
    const indexToClear = isEditData.index // Index to clear (zero-based)

    // Properties to clear
    const propertiesToClear = [
      'price',
      'listPrice',
      'inventory_quantity',
      'sku',
      'barcode',
      'weightValue',
      'length',
      'width',
      'height',
    ]

    const clearedArray = newVariantData.map((item, index) => {
      if (index === indexToClear) {
        const clearedItem = { ...item }
        propertiesToClear.forEach((property) => {
          clearedItem[property] = 0
          clearedItem.weightUnit = 'pound' // Set weightUnit to "pound"
          clearedItem.unit = 'in'
          clearedItem.isTaxable = false
        })
        return clearedItem
      } else {
        return item
      }
    })
    setNewVariantData(clearedArray)
    setIsEditData({ ...isEditData, data: clearedArray[isEditData.index] })
  }

  const handleBackButton = async () => {
    await dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          variant: {
            ...addProductDetailSlice?.id.variant,
            isVariantModel: false,
          },
        },
      }),
    )
  }

  const handleResetData = () => {
    setSelectedCheckboxes([])
    setFilteredData([])
    setNewVariantData(addProductDetailSlice?.id?.variant?.allOptions || [])
  }

  const handleNewVariantData = async (index, field, value) => {
    let finalData = []
    if (field === 'price') {
      finalData = newVariantData.map((item, i) => {
        if (i === index) {
          return { ...item, price: value }
        }
        return item
      })
    } else if (field === 'inventory_quantity') {
      finalData = newVariantData.map((item, i) => {
        if (i === index) {
          return { ...item, inventory_quantity: parseInt(value) }
        }
        return item
      })
    } else if (field === 'sku') {
      finalData = newVariantData.map((item, i) => {
        if (i === index) {
          return { ...item, sku: value }
        }
        return item
      })
    } else if (field === 'weightValue') {
      finalData = newVariantData.map((item, i) => {
        if (i === index) {
          return { ...item, [field]: value }
        }
        return item
      })
    } else if (field === 'isTaxable') {
      finalData = newVariantData.map((item, i) => {
        if (i === index) {
          return { ...item, isTaxable: value }
        }
        return item
      })
    } else if (field === 'barcode') {
      finalData = newVariantData.map((item, i) => {
        if (i === index) {
          return { ...item, barcode: value }
        }
        return item
      })
    } else if (field === 'listPrice') {
      finalData = newVariantData.map((item, i) => {
        if (i === index) {
          return { ...item, [field]: value }
        }
        return item
      })
    } else {
      finalData = newVariantData.map((item, i) => {
        if (i === index) {
          return { ...item, [field]: parseInt(value) }
        }
        return item
      })
    }

    const filteredListPrices = finalData
      .filter((item) => parseFloat(item.listPrice) > 0) // Filter out items with listPrice less than or equal to 0
      .map((item) => parseFloat(item.listPrice))

    const smallestListPrice = filteredListPrices.reduce(
      (min, listPrice) => (listPrice < min ? listPrice : min),
      filteredListPrices[0],
    )

    const filteredPrices = finalData
      .filter((item) => parseFloat(item.price) > 0) // Filter out items with price less than or equal to 0
      .map((item) => parseFloat(item.price)) // Map prices to numbers

    const smallestPrice = filteredPrices.reduce((min, price) => (price < min ? price : min), filteredPrices[0])

    await dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          productPreview: {
            ...addProductDetailSlice?.id?.productPreview,
            listPrice: smallestListPrice ? smallestListPrice : 0,
            price: smallestPrice ? smallestPrice : 0,
          },
        },
      }),
    )

    setNewVariantData(finalData)
    setIsEditData({ ...isEditData, data: finalData[isEditData.index] })
  }

  useEffect(() => {
    const checkIfClickedOutside = async (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        await dispatch(
          manageAddProductDetail({
            modal: '',
            isOpen: false,
            id: {
              ...addProductDetailSlice?.id,
              variant: {
                ...addProductDetailSlice?.id.variant,
                isVariantModel: false,
              },
            },
          }),
        )
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [dropdownRef])

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (newFilterdDropdown && filterRef.current && !filterRef.current.contains(e.target)) {
        setNewFilterdDropdown(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [newFilterdDropdown])

  return (
    <div className={styles.variantDisplayModalSection}>
      <div className={styles.variantDisplayModalWrapper}>
        <div className={styles.variantWhiteBoxAlignment} ref={dropdownRef}>
          <div className={styles.variantHeadingALignment}>
            <h4>Variant</h4>

            <div className={styles.saveChangesButton}>
              <button onClick={() => handleSaveNewVariant()}>Save Change</button>
            </div>
          </div>

          {/* <div className={styles.variantTabAlignment}>
            <div className={styles.variantTabBox} onClick={() => setSizeDropdown(!sizeDropdown)}>
              <p>All size</p>
              <img
                src="/assets/icon/drop-down-icon-blue.svg"
                alt="drop-down-icon"
                className={sizeDropdown ? styles.oprnDropdownBox : ''}
              />

              <div
                className={
                  sizeDropdown
                    ? classNames(styles.variantAllProductVarintTabDropdownBox, styles.openVariantDropdown)
                    : classNames(styles.variantAllProductVarintTabDropdownBox, styles.closeVariantDropdown)
                }
              >
                <div className={styles.variantAllProdutctoptionAlignment}>
                  <p>All Size</p>
                </div>
                <div className={styles.variantAllProdutctoptionAlignment}>
                  <p>S</p>
                </div>
                <div className={styles.variantAllProdutctoptionAlignment}>
                  <p>M</p>
                </div>
                <div className={styles.variantAllProdutctoptionAlignment}>
                  <p>L</p>
                </div>
              </div>
            </div>
            <div className={styles.variantTabBox} onClick={() => setColorDropdown(!colorDropdown)}>
              <p>All color</p>
              <img
                src="/assets/icon/drop-down-icon-blue.svg"
                alt="drop-down-icon"
                className={colorDropdown ? styles.oprnDropdownBox : ''}
              />

              <div
                className={
                  colorDropdown
                    ? classNames(
                        styles.variantAllProductVarintTabDropdownBox,
                        styles.colorWidthAlignment,
                        styles.openVariantDropdown,
                      )
                    : classNames(
                        styles.variantAllProductVarintTabDropdownBox,
                        styles.colorWidthAlignment,
                        styles.closeVariantDropdown,
                      )
                }
              >
                <div className={styles.variantAllProdutctoptionAlignment}>
                  <div className={styles.colorBoxAlignment}></div>
                  <p>All Color</p>
                </div>
                <div className={styles.variantAllProdutctoptionAlignment}>
                  <div className={styles.colorBoxAlignment}></div>
                  <p>Red</p>
                </div>
                <div className={styles.variantAllProdutctoptionAlignment}>
                  <div className={styles.colorBoxAlignment}></div>
                  <p>Blue</p>
                </div>
                <div className={styles.variantAllProdutctoptionAlignment}>
                  <div className={styles.colorBoxAlignment}></div>
                  <p>Orange</p>
                </div>
              </div>
            </div>
            <div className={styles.variantTabBox}>
              <p>All Material</p>
              <img src="/assets/icon/drop-down-icon-blue.svg" alt="drop-down-icon" />
            </div>
          </div> */}

          <div ref={filterRef} className={styles.filterRelative}>
            <div className={styles.addProdutcFilterAlignment}>
              <div className={styles.addProductLeftSideAlignment}>
                <div className={styles.addProductFilterBox} onClick={() => setNewFilterdDropdown(!newFilterdDropdown)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M1.33081 3.33057H14.6641" stroke="#4C515D" strokeLinecap="round" />
                    <path d="M3.33081 7.99707H12.6641" stroke="#4C515D" strokeLinecap="round" />
                    <path d="M5.99756 12.6641H9.99756" stroke="#4C515D" strokeLinecap="round" />
                  </svg>

                  <h6>Filters</h6>
                </div>
                <div className={styles.allAddFilterDetailsAlignment}>
                  <div className={styles.addProductAllFilterListAlignment}>
                    {filteredData.map((it, index) => {
                      return (
                        <div className={styles.sizeBoxFilterAlignment} key={index}>
                          <div className={it.colorCode ? styles.colorBoxAlignment : styles.sizeDetailsBox}>
                            {it.name ? (
                              <div className={styles.colorBoxDetails}>
                                <div className={styles.colorShowBox} style={{ background: it.colorCode }}></div>
                                <p>{it.name}</p>
                              </div>
                            ) : (
                              <p>{it}</p>
                            )}
                            <div onClick={(e) => handleFilteredBox(e, it?.name ? it?.name : it, it?.colorCode)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path d="M12 4L4 12" stroke="#7A7E88" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4 4L12 12" stroke="#7A7E88" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    {/* <div className={styles.sizeBoxFilterAlignment}>
                        
                      </div> */}
                  </div>
                </div>
              </div>

              <div className={styles.clearFilterAlignment}>
                <a onClick={() => handleResetData()}>Clear Filter</a>
              </div>
            </div>
            <AddFilterDropdownBox
              newFilterdDropdown={newFilterdDropdown}
              setNewFilterdDropdown={setNewFilterdDropdown}
              newVariantData={newVariantData}
              setNewVariantData={setNewVariantData}
              selectedCheckboxes={selectedCheckboxes}
              setSelectedCheckboxes={setSelectedCheckboxes}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              handleResetData={handleResetData}
            />
          </div>

          <div className={styles.mobileViewVarintHeadingAlignment}>
            <div className={styles.mobileViewVarintHeading}>
              <div className={styles.mobileVIewBackICon} onClick={() => handleBackButton()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="37" height="32" viewBox="0 0 37 32" fill="none">
                  <path
                    d="M27 0H10C4.47715 0 0 4.47715 0 10V22C0 27.5228 4.47715 32 10 32H27C32.5228 32 37 27.5228 37 22V10C37 4.47715 32.5228 0 27 0Z"
                    fill="white"
                  />
                  <path
                    d="M21 10L16 16L21 22"
                    stroke="#808191"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <h4>S, Red</h4>

              <div className={styles.mobileViewResetIcon}>
                <img src="/assets/icon/reset-icon.svg" alt="reset icon" />
              </div>
            </div>
          </div>

          <div className={styles.variantBodyDetailsAlignment}>
            <div className={styles.vriantBodyGridAlignment}>
              <div className={styles.variantLeftSideDetailsAlignment}>
                <div className={styles.variantAllDetailsAlignment}>
                  {selectedCheckboxes?.length > 0
                    ? newVariantData
                        ?.filter((item) => item.isChecked)
                        .map((item, index) => {
                          return (
                            <div
                              key={index}
                              className={
                                // addProductDetailSlice?.id?.isEditableData?.index === index
                                isEditData?.data?.total_variant === item?.total_variant
                                  ? classNames(styles.variantDetailsBoxAlignment, styles.variantBoxActive)
                                  : styles.variantDetailsBoxAlignment
                              }
                              onClick={() => handleChangeVariant(index, item)}
                            >
                              <div className={styles.variantBoxDetailsGrid}>
                                <div className={styles.variantBoxDetailsGridItem}>
                                  <div className={styles.variantImg}>
                                    <img
                                      src={
                                        getAllState?.allModalData.find((item) => item?.modal === 'addProductModal')
                                          ?.isEdit
                                          ? item?.image?.media
                                            ? item?.image?.media
                                            : item?.image_id != null
                                            ? URL?.createObjectURL(newVariantData?.[index]?.image_id)
                                            : '/assets/img/demo-img.png'
                                          : item?.image_id
                                          ? URL?.createObjectURL(item?.image_id)
                                          : '/assets/img/demo-img.png'
                                      }
                                      alt="demo img"
                                    />
                                  </div>
                                </div>

                                <div className={styles.variantBoxDetailsGridItem}>
                                  <div className={styles.varintRightSideAlignment}>
                                    {/* <h6>S, Red, Plastic</h6> */}
                                    <h6>
                                      {item?.total_variant?.map((itms, idx) => {
                                        return idx === item?.total_variant?.length - 1 ? itms : itms + ', '
                                      })}
                                    </h6>
                                    <p>Available</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                    : addProductDetailSlice?.id?.variant?.allOptions?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={
                              addProductDetailSlice?.id?.isEditableData?.index === index
                                ? classNames(styles.variantDetailsBoxAlignment, styles.variantBoxActive)
                                : styles.variantDetailsBoxAlignment
                            }
                            onClick={() => handleChangeVariant(index, item)}
                          >
                            <div className={styles.variantBoxDetailsGrid}>
                              <div className={styles.variantBoxDetailsGridItem}>
                                <div className={styles.variantImg}>
                                  <img
                                    src={
                                      getAllState?.allModalData.find((item) => item?.modal === 'addProductModal')
                                        ?.isEdit
                                        ? item?.image?.media
                                          ? item?.image?.media
                                          : item?.image_id != null
                                          ? URL?.createObjectURL(newVariantData?.[index]?.image_id)
                                          : '/assets/img/demo-img.png'
                                        : item?.image_id
                                        ? URL?.createObjectURL(item?.image_id)
                                        : '/assets/img/demo-img.png'
                                    }
                                    alt="demo img"
                                  />
                                </div>
                              </div>

                              <div className={styles.variantBoxDetailsGridItem}>
                                <div className={styles.varintRightSideAlignment}>
                                  {/* <h6>S, Red, Plastic</h6> */}
                                  <h6>
                                    {item?.total_variant?.map((itms, idx) => {
                                      return idx === item?.total_variant?.length - 1 ? itms : itms + ', '
                                    })}
                                  </h6>
                                  <p>Available</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                </div>
              </div>

              <div className={styles.variantRightSideDetailsAlignment}>
                <div className={styles.variantRightHeadingDetails}>
                  <div className={styles.rightTopLeftDetailsAlignment}>
                    <div className={styles.variantMainImgAlignment}>
                      <img
                        src={
                          getAllState?.allModalData.find((item) => item?.modal === 'addProductModal')?.isEdit
                            ? newVariantData?.[isEditData?.index]?.image_id === null ||
                              newVariantData?.[isEditData?.index]?.image_id === undefined
                              ? '/assets/img/demo-img.png'
                              : newVariantData?.[isEditData?.index]?.image?.media ??
                                URL?.createObjectURL(newVariantData?.[isEditData?.index]?.image_id)
                            : newVariantData[isEditData?.index]?.image_id
                            ? URL?.createObjectURL(newVariantData?.[isEditData?.index]?.image_id)
                            : '/assets/img/demo-img.png'
                        }
                        alt="demo img"
                      />
                    </div>

                    <div className={styles.variantMainHeadingAlignment}>
                      <h4>
                        {isEditData?.data?.total_variant?.map((itm, idx) => {
                          return idx === isEditData?.data?.total_variant.length - 1 ? itm : itm + ', '
                        })}
                      </h4>
                      <p>Available</p>
                    </div>
                  </div>

                  <div className={styles.refreshAlignment} onClick={() => handleResetInfo()}>
                    <div className={styles.resetIcon}>
                      <img src="/assets/icon/reset-icon.svg" alt="reset icon" />
                    </div>
                    <p>Reset information</p>
                  </div>
                </div>
                <div className={styles.variantInventoryPricingSection}>
                  <div className={styles.variantInventoryPricingbox}>
                    <div className={styles.inventoryPriceHeading}>
                      <h6>Inventory & Pricing</h6>
                    </div>
                    <div className={styles.inventoryPricingAllDetails}>
                      <div className={styles.inventoryPricingAllGrid}>
                        <div className={styles.inventoryPricingAllGridItem}>
                          <div className={styles.inventoryInputAlignment}>
                            <label>
                              Price <span>*</span>
                            </label>
                            <div className={styles.inventoryInput}>
                              <div className={styles.dollarSign}>
                                <img src="/assets/icon/dollar-sign.svg" alt="dollar sign" />
                              </div>
                              <div>
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    handleNewVariantData(isEditData.index, e.target.name, e.target.value)
                                  }
                                  name="price"
                                  value={newVariantData[isEditData.index]?.price ?? ''}
                                  placeholder="10"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.inventoryPricingAllGridItem}>
                          <div className={styles.inventoryInputAlignment}>
                            <label>List Price</label>
                            <div className={styles.inventoryInput}>
                              <div className={styles.dollarSign}>
                                <img src="/assets/icon/dollar-sign.svg" alt="dollar sign" />
                              </div>
                              <div>
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    handleNewVariantData(isEditData.index, e.target.name, e.target.value)
                                  }
                                  name="listPrice"
                                  value={newVariantData[isEditData.index]?.listPrice ?? ''}
                                  placeholder="0"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.inventoryPricingAllGridItem}>
                          <div className={styles.inventoryInputAlignment}>
                            <label>Quantity</label>
                            <div className={styles.inventoryInput}>
                              <div>
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    handleNewVariantData(isEditData.index, e.target.name, e.target.value)
                                  }
                                  name="inventory_quantity"
                                  value={newVariantData[isEditData.index]?.inventory_quantity ?? 0}
                                  placeholder="256"
                                  className={styles.quantityInput}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.inventoryPricingAllGrid}>
                        <div className={styles.inventoryPricingAllGridItem}>
                          <div className={styles.inventoryInputAlignment}>
                            <label>SKU</label>
                            <div className={styles.inventoryInput}>
                              <div>
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    handleNewVariantData(isEditData.index, e.target.name, e.target.value)
                                  }
                                  name="sku"
                                  value={newVariantData[isEditData.index]?.sku ?? ''}
                                  placeholder="0"
                                  className={styles.inputTextLeftAlignment}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.inventoryPricingAllGridItem}>
                          <div className={styles.inventoryInputAlignment}>
                            <label>Barcode</label>
                            <div className={styles.inventoryInput}>
                              <div>
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    handleNewVariantData(isEditData.index, e.target.name, e.target.value)
                                  }
                                  name="barcode"
                                  value={newVariantData[isEditData.index]?.barcode ?? ''}
                                  placeholder="0"
                                  className={styles.inputTextLeftAlignment}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.priceCheckAlignment}>
                      <input
                        type="checkbox"
                        id="isTaxable"
                        name="isTaxable"
                        checked={newVariantData[isEditData.index]?.isTaxable ?? false}
                        value={newVariantData[isEditData.index]?.isTaxable ?? false}
                        onChange={(e) =>
                          handleNewVariantData(isEditData.index, e.target.name, e.target.checked ? true : false)
                        }
                      />
                      <p>Charge tax on this variant</p>
                    </div>
                  </div>
                </div>
                <div className={styles.shippingDetailsSection}>
                  <div className={styles.shippingBoxSection}>
                    <div className={styles.shippingHeadingFlexAlignment}>
                      <h6>Shipping</h6>

                      <div className={styles.shippingRightSideAlignment}>
                        <p>Unit</p>
                        <div className={styles.shippingOtherDetails}>
                          <div
                            className={styles.shippingOtherDetailsSecond}
                            onClick={() => setLbsDropdown(!LbsDropdown)}
                          >
                            <p>{unitName?.[isEditData?.index] ?? 'lbs'}</p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="5"
                              viewBox="0 0 8 5"
                              fill="none"
                              className={LbsDropdown ? styles.activeArrow : ''}
                            >
                              <path
                                d="M1 1L4 4L7 1"
                                stroke="#7A7E88"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            {LbsDropdown && (
                              <div className={styles.shippingUnitDetailsDropdownBoxAlignment} ref={productRef}>
                                {unitObject.map((o, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={styles.shippingUnitDetailsDropdownOption}
                                      onClick={() => handleSelectUnit(o)}
                                    >
                                      <p>{o?.unit}</p>
                                    </div>
                                  )
                                })}
                              </div>
                            )}
                          </div>

                          <div className={styles.shippingOtherDetailsSecond} onClick={() => setInDropdown(!InDropdown)}>
                            <p>{newVariantData?.[isEditData?.index]?.unit === 'inch' ? 'in' : 'cm'}</p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="5"
                              viewBox="0 0 8 5"
                              fill="none"
                              className={InDropdown ? styles.activeArrow : ''}
                            >
                              <path
                                d="M1 1L4 4L7 1"
                                stroke="#7A7E88"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            {InDropdown && (
                              <div className={styles.shippingUnitDetailsDropdownBoxAlignment}>
                                {[0].map((_, index) => {
                                  return (
                                    <div className={styles.shippingUnitDetailsDropdownOption} key={index}>
                                      <p>{newVariantData?.[isEditData?.index]?.unit === 'inch' ? 'in' : 'cm'}</p>
                                    </div>
                                  )
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.shippingBodyDetailsAlignment}>
                      <div className={styles.shippingBodyDetailsGridAlignment}>
                        <div className={classNames(styles.shippingInputAlignment, styles.itemWidthAlignment)}>
                          <label>Item weight</label>

                          <div className={styles.shippingInput}>
                            <input
                              type="text"
                              name="weightValue"
                              className={styles.otherInputpadding}
                              onChange={(e) => handleNewVariantData(isEditData.index, e.target.name, e.target.value)}
                              value={newVariantData[isEditData.index]?.weightValue ?? ''}
                            />
                            <span>{unitName?.[isEditData?.index] ?? 'lbs'}</span>
                          </div>
                        </div>
                        <div className={styles.shippingThirdGridAlignment}>
                          <div className={styles.shippingInputAlignment}>
                            <label>Length</label>

                            <div className={styles.shippingInput}>
                              <input
                                type="text"
                                name="length"
                                className={styles.childInputPadding}
                                onChange={(e) => handleNewVariantData(isEditData.index, e.target.name, e.target.value)}
                                value={newVariantData[isEditData.index]?.length ?? ''}
                              />
                              <span>{newVariantData?.[isEditData?.index]?.unit === 'centimeter' ? 'cm' : 'in'}</span>
                            </div>
                          </div>
                          <div className={styles.shippingInputAlignment}>
                            <label>Width</label>

                            <div className={styles.shippingInput}>
                              <input
                                type="text"
                                name="width"
                                className={styles.childInputPadding}
                                onChange={(e) => handleNewVariantData(isEditData.index, e.target.name, e.target.value)}
                                value={newVariantData[isEditData.index]?.width ?? ''}
                              />
                              <span>{newVariantData?.[isEditData?.index]?.unit === 'centimeter' ? 'cm' : 'in'}</span>
                            </div>
                          </div>
                          <div className={styles.shippingInputAlignment}>
                            <label>Height</label>

                            <div className={styles.shippingInput}>
                              <input
                                type="text"
                                name="height"
                                className={styles.childInputPadding}
                                onChange={(e) => handleNewVariantData(isEditData.index, e.target.name, e.target.value)}
                                value={newVariantData[isEditData.index]?.height ?? ''}
                              />
                              <span>{newVariantData?.[isEditData?.index]?.unit === 'centimeter' ? 'cm' : 'in'}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.mobileViewBottomButton}>
            <button onClick={() => handleSaveNewVariant()}>Save Change</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VariantDisplayModel
