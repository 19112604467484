import React from 'react'
import styles from './MobileJewelryHotDetailsModal.module.scss'
export default function MobileJewelryHotDetailsModal() {
  return (
    <div className={styles.mobileJewelryHotDetailsModalSection}>
      <div className={styles.mobileJewelryHotDetailsModalAlignment}>
        <div className={styles.mobileJewelryHeading}>
          <h6>Hot Deals</h6>
        </div>

        <div className={styles.mobileJewelryAllDetailsAlignment}>
          <div className={styles.mobileJewelryAllDetailsGrid}>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((_, index) => {
              return (
                <div className={styles.mobileJewelryAllDetailsGridItem} key={index}>
                  <div className={styles.mobileJewelryHotDetailsImg}>
                    <img src="/assets/jewelry/hot-details-2-img.png" alt="hot-img" />
                    <div className={styles.mobileJewelryHotDetails}>
                      <div className={styles.mobileOfferBoxAlignment}>
                        <p>25% off</p>
                      </div>
                      <div className={styles.mobileOfferBoxPriceAlignment}>
                        <h6>$99</h6>
                        <p>$299</p>
                      </div>
                    </div>
                    <div className={styles.mobileViewOptionAlignment}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="41" height="31" viewBox="0 0 41 31" fill="none">
                        <rect opacity="0.4" x="8" y="8" width="25" height="15" rx="5" fill="#1E2432" />
                        <circle cx="15.5" cy="15.5" r="1.5" transform="rotate(-90 15.5 15.5)" fill="#F8F8F8" />
                        <circle cx="20.5" cy="15.5" r="1.5" transform="rotate(-90 20.5 15.5)" fill="#F8F8F8" />
                        <circle cx="25.5" cy="15.5" r="1.5" transform="rotate(-90 25.5 15.5)" fill="#F8F8F8" />
                      </svg>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
