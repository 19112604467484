import { gql } from '@apollo/client'

const GET_SINGLE_PRODUCT_LIKE = gql`
  query GetProductSingleLike($slug: String!) {
    getProductSingleLike(slug: $slug) {
      isLike
      message
      success
      like_id
    }
  }
`
export default GET_SINGLE_PRODUCT_LIKE
