import React from 'react'
import UpcomingEvent from './UpcomingEvent'
import RecordedEvent from './RecordedEvent'
import LiveOngoingEvent from './LiveOngoingEvent'
import NewUpcomingLiveStrem from './NewUpcomingLiveStrem'
import AllPastLivestreams from './AllPastLivestreams'
import GET_UPCOMING_LIVESTREAM from 'src/graphql/queries/getUpcomingLivestreams'
import { useQuery } from '@apollo/client'
import Loader from 'src/components/UI/Loader'
import UpcominStreams from 'src/components/StreamsSliders/UpcomingStreams'
import PastStreams from 'src/components/StreamsSliders/PastStreams'
import BannerStreams from 'src/components/StreamsSliders/BannerStreams'
import { useAuth } from 'src/hooks/useAuth'

export default function StoreEvent({ storeData }) {
  const { user }: any = useAuth()

  const { loading, error, data, refetch } = useQuery(GET_UPCOMING_LIVESTREAM, {
    variables: { store_id: storeData?.id, type: ['LIVE', 'UPCOMING', 'PAST'] },
  })
  const liveStreamData = data?.getMeetingStreams?.data?.liveStreams
  const pastStreamData = data?.getMeetingStreams?.data?.pastStreams
  const upcomingStreamData = data?.getMeetingStreams?.data?.upcomingStreams
  return (
    <div className="event-section-alignment">
      {loading ? (
        <Loader />
      ) : (
        <div className='mt-3'>
          <BannerStreams liveStreams={liveStreamData} loading={loading} />
          <UpcominStreams upcomingStreams={upcomingStreamData} loading={loading} />
          <PastStreams pastStreams={pastStreamData} loading={loading} />
        </div>
      )}
    </div>
  )
}
