import { useQuery } from '@apollo/client'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { GET_GROUPS } from 'src/graphql/queries/queries'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAppDispatch } from 'src/store/store'
import { timeAgo, truncateString } from 'src/utils/helperFuncs'
import { useStateContext } from 'src/utils/state'
import client1 from 'src/utils/apolloClient'
import { Spin } from 'antd'
import { useAuth } from 'src/utils/auth'

export default function GroupMessageTab() {
  const { selectChat } = useStateContext()
  const { user } = useAuth();
  const screenWidth = window.innerWidth
  const dispatch = useAppDispatch()
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)

  const { data: groupData, loading: loadingGroups } = useQuery(GET_GROUPS, {
    client: client1,
    onError: (err) => { },
  })

  useEffect(() => {
    if (groupData) {
      let data = groupData?.getGroups?.findIndex((grp) => grp?.id === 29)
    }
  }, [])

  return (
    <div className="group-message-tab-section">
      {loadingGroups && <div className="loader-align w-full flex justify-center mt-20"> <Spin size="large" tip="loading" /></div>}
      {!loadingGroups && groupData &&
        [...groupData?.getGroups]
          .sort((a: any, b: any) => {
            if (a?.latestMessage?.createdAt === undefined) return 1
            if (b?.latestMessage?.createdAt === undefined) return -1
            return new Date(b?.latestMessage?.createdAt).getTime() - new Date(a?.latestMessage?.createdAt).getTime()
          })
          .map((group, index) => {
            let groupUsers = [...group?.users, ...group?.adminUser]
            return (
              <div
                className="group-message-tab-alignment"
                key={group.id}
                onClick={() => {
                  selectChat(group, 'group')
                  if (screenWidth < 768) {
                    dispatch(
                      fetchMobileModalData({
                        allModalData: [
                          ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                          {
                            modal: 'chatBoxModal',
                            isOpen: true,
                            id: 0,
                            transform: 42,
                            zIndex: 9999999,
                          },
                        ],
                        lastModalData: fetchMobileModal?.lastModalData,
                      }),
                    )
                  }

                }}
              >
                <div className="group-message-tab-box-alignment">
                  <div className="group-message-profile-details-alignment">
                    <div className="group-message-profile-img-box-alignment-sidebar">
                      {[...Array(3)].map((profile, index) => {
                        return (
                          <div className={`group-message-flex-alignment-${index}`} key={index}>
                            <div className={`group-message-profile-img-alignment-${index}`}>
                              <img
                                src={
                                  groupUsers[index] ? groupUsers[index]?.profileAvtar : '/assets/img/dummy-group-user.svg'
                                }
                                alt="group profile"
                              />
                            </div>
                          </div>
                        )
                      })}
                      <div className="group-message-first-dot-alignment">
                        <div className="first-dot-width-alignment">
                          <img src="/assets/img/gdot1.png" alt="group profile" />
                        </div>
                      </div>
                      <div className="group-message-second-dot-alignment">
                        <div className="second-dot-width-alignment">
                          <img src="/assets/img/gdot2.png" alt="group profile" />
                        </div>
                      </div>
                      <div className="group-message-third-dot-alignment">
                        <div className="third-dot-width-alignment">
                          <img src="/assets/img/gdot3.png" alt="group profile" />
                        </div>
                      </div>
                    </div>
                    <div className="group-message-profile-all-details-alignment">
                      <h5>{truncateString(group.name, 25)}</h5>
                      <p>
                        {group?.latestMessage?.body ? truncateString(group?.latestMessage?.body, 25) : 'Get Started!'}
                      </p>
                      {/* <div className="group-message-images-icon-and-text-alignment">
                    <div className="group-message-image-group">
                      {[0, 1, 2].map(() => {
                        return <img src="/assets/seller/img/fi4.png" />
                      })}
                    </div>
                    <span>Alice, and 12 other joined this group</span>
                  </div> */}
                    </div>
                  </div>
                  <div className="group-message-time-alignment">
                    <div className="group-total-message-alignment">
                      <div className="group-total-message-box-alignmant">
                        <p>8</p>
                      </div>
                    </div>
                    <span>{timeAgo(new Date(group?.latestMessage?.createdAt).getTime())}</span>
                  </div>
                </div>
              </div>
            )
          })}
    </div>
  )
}
