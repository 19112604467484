import { gql } from '@apollo/client'

export const UPDATE_PRODUCT_MUTATION = gql`
  mutation updateProduct(
    $input: ProductInput
  ) {
    updateProduct(
      input: $input
    ) {
      success
      message
    }
  }
`
