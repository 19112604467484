import React from 'react'
import HomeApplianceBanner from '../../../../../public/assets/img/BookApplience/home-applience-banner.png'
import MobileFashionGroupBanner from '../../../../../public/assets/img/MensCategory/t1.png'
import MobileHomeApplianceBanner from '../../../../../public/assets/img/BookApplience/mobileHomeAppliceBg.png'
import GET_GROUPS_ONBOARDING from 'src/graphql/queries/getAllgroupsOnboarding'
import JOIN_GROUP from 'src/graphql/mutations/joinGroup'
import LEAVE_GROUP from 'src/graphql/mutations/leaveGroup'
import { useMutation, useQuery } from '@apollo/client'
import users from '../../../../../public/assets/img/WomansCategory/users.png'
import like from '../../../../../public/assets/img/WomansCategory/like.png'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAuth } from 'src/utils/auth'
import { notification } from 'antd'

interface ModalData {
  modal: string;
  [key: string]: any;
}

interface ClubData {
  id: string | number;
  name?: string;
  description?: string;
  coverImage?: string;
  isExist?: boolean;
  [key: string]: any;
}

export default function HomeApplianceClubsModal() {
  const { user } = useAuth()

  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const { loading, error, data } = useQuery(GET_GROUPS_ONBOARDING, { variables: { type: 'SUGGESTED_CLUBS' } })

  const handleClose = () => {
    let datass = getAllState?.allModalData?.filter((item: ModalData) => item?.modal !== getAllState?.lastModalData?.modal)
    dispatch(
      fetchModalData({
        allModalData: datass,
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  const [joingroup] = useMutation(JOIN_GROUP, {
    onCompleted: (res) => {
      notification['success']({
        message: ' Joined successfully',
        description: 'You have been joined successfully',
      })
    },
    onError: (err) => {
      console.log('err', err)
      notification['error']({
        message: 'Error',
        description: 'Something went wrong',
      })
    },
  })

  const [leaveGroup] = useMutation(LEAVE_GROUP, {
    onCompleted: (res) => {
      notification['success']({
        message: ' Left successfully',
        description: 'You have been left successfully',
      })
    },
    onError: (err) => {
      console.log('err', err)
      notification['error']({
        message: 'Error',
        description: 'Something went wrong',
      })
    },
  })

  return (
    <div className="home-appliance-club-modal-details-alignment">
      <div className="mobile-view-book-appliance-back-alignment">
        <div className="book-appliance-back_details-alignment" onClick={() => handleClose()}>
          <div>
            <img src="/assets/icon/left-icon.svg" alt="back-page" />
          </div>
          <p>Home...</p>
        </div>
        <div className="mobile-heading-alignment">
          <h4>Home an...Clubs</h4>
        </div>
      </div>
      <div className="home-appliance-club-modal-banner-alignment">
        <img src={HomeApplianceBanner.src} alt="HomeApplianceBanner" />
        <img src={MobileHomeApplianceBanner.src} alt="MobileHomeApplianceBanner" className="mobile-view-banner" />
        <div className="home-appliance-club-modal-banner-details">
          <h4>Home and Appliance Clubs</h4>
          <p>Connect with Our Home Appliance Community for Tips, Ideas, and Inspiration</p>
        </div>
      </div>

      <div className="home-appliance-club-details-modal-details-alignment">
        <div className="home-appliance-club-grid-alignment">
          {data?.getAllGroups?.map((club: ClubData, index: number) => {
            return (
              <div className="home-appliance-club-gridItem-alignment" key={index}>
                <div className="fashion_group_slider_cover">
                  <div className="card_fashion">
                    <div className="img_cover">
                      <img src={club?.coverImage} alt="img_cover" />
                    </div>
                    <div className="card_details">
                      <div className="card_details_cover">
                        <h5
                          onClick={() =>
                            dispatch(
                              fetchModalData({
                                allModalData: [
                                  ...getAllState?.allModalData,
                                  { modal: 'group', isOpen: true, id: club?.id, modalFor: 'bigModal' },
                                ],
                                lastModalData: getAllState?.lastModalData,
                              }),
                            )
                          }
                        >
                          {club?.name}
                        </h5>
                        <p>{club?.description}</p>
                        <div className="users">
                          <div className="left_part">
                            <div className="user_img pr-4">
                              <img src={users.src} alt="user_img" />
                            </div>
                            {[...Array(3)].map((_, index) => {
                              return (
                                <div className="users_likes" key={index}>
                                  <div className="user_box">
                                    <img src={like.src} alt="user_box" />
                                  </div>
                                </div>
                              )
                            })}
                            <div className="count">+120</div>
                          </div>
                          {club?.isExist ? (
                            <div className="right_part">
                              <button
                                onClick={() =>
                                  leaveGroup({
                                    variables: {
                                      userId: user?.id,
                                      groupId: club?.id,
                                    },
                                    refetchQueries: [{ query: GET_GROUPS_ONBOARDING, variables: { type: 'JOINED' } }],
                                  })
                                }
                              >
                                Leave Club
                              </button>
                            </div>
                          ) : (
                            <div className="right_part">
                              <button
                                onClick={() =>
                                  joingroup({
                                    variables: { group_id: club?.id, user_id: user?.id, isAdmin: false },
                                    refetchQueries: [{ query: GET_GROUPS_ONBOARDING, variables: { type: 'JOINED' } }],
                                  })
                                }
                              >
                                Join Club
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
