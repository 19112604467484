import React, { useEffect, useState } from 'react'
import PeopleItem from '../../PeopleModal/PeopleItem'
import styles from './JewelryFansModal.module.scss'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchAllPeople } from 'src/store/slices/fetchAllPeopleSlice'

export default function JewelryFansModal() {
  const dispatch = useAppDispatch()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(15)
  const peopleData = useSelector((state: any) => state?.peopleData)

  useEffect(() => {
    dispatch(fetchAllPeople({ limit: limit, page: page, type: 'ALL_PEOPLE', prevPost: [] }))
  }, [])

  const declineFriendRequestCall = () => {}
  const sendFriendRequestCall = () => {}

  return (
    <div className={styles.jewelryFansModalSection}>
      <div className={styles.jewelryFansModalAlignment}>
        <div className={styles.jewelryFansHeading}>
          <h4>Jewelry Fans</h4>
        </div>
        <div className={styles.jewelryFansBodyAlignment}>
          <div className="men-category-enthusiats-slider jewelry-fan-alignment">
            <div className="men-category-enthusiats-grid  mobileView-design-alignment">
              {peopleData?.allPeople?.map((people, index) => (
                <React.Fragment key={index}>
                  <PeopleItem
                    people={people}
                    declineFriendRequestCall={declineFriendRequestCall}
                    sendFriendRequestCall={sendFriendRequestCall}
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
