import React, { useEffect, useState } from 'react'
import styles from './KYCVerifiedStep1.module.scss'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAuth } from 'src/utils/auth'
import { fetchSellerWithdrawModalData } from 'src/store/slices/manageSellerWithdrawSlice'
import { notification } from 'antd'

export default function KYCVerifiedStep1() {
  const { user }: any = useAuth()
  const dispatch = useAppDispatch()
  const sellerWithdrawData = useSelector((state: any) => state?.sellerWithdrawData?.data)

  const [input, setInput] = useState('')

  const HandleNext = () => {
    if (sellerWithdrawData?.withdrawFund?.amount?.upcoming_amount >= Number(input)) {
      dispatch(
        fetchSellerWithdrawModalData({
          allModalData: [
            ...sellerWithdrawData?.allModalData,
            {
              modal: 'sellerWithdrawDetailModal',
              isOpen: true,
              id: 0,
              modalFor: 'sellerWithdrawSize',
            },
          ],
          lastModalData: sellerWithdrawData?.lastModalData,
          withdrawFund: { ...sellerWithdrawData?.withdrawFund, selectedAmout: input },
        }),
      )
    } else {
      notification['error']({
        message: 'Error',
        description: 'Amount is greter than total amount',
      })
    }
  }

  const bindInput = (value) => {
    var regex = new RegExp('^[^0-9]*$')
    var key = String.fromCharCode(!value.charCode ? value.which : value.charCode)
    if (regex.test(key)) {
      value.preventDefault()
      return false
    }
  }

  useEffect(() => {
    if (input !== sellerWithdrawData?.withdrawal?.selectedAmout) {
      setInput(sellerWithdrawData?.withdrawFund?.selectedAmout)
    }
  }, [])

  return (
    <div className={styles.kYCVerifiedStep1Section}>
      <div className={styles.kYCVerifiedStep1Alignment}>
        <div className={styles.kYCVerifiedStep1NameAlignment}>
          <div>
            <h6>{user?.store?.name} Account Balance</h6>
            {/* <span>(USD 50,460.57)</span> */}
          </div>
          <div className={styles.toAlignment}>
            <p>to</p>
          </div>
          <div>
            <h6>{sellerWithdrawData?.withdrawFund?.bankDetail?.account_holder_name}</h6>
            <span>Bank of America - xxx{sellerWithdrawData?.withdrawFund?.bankDetail?.last4}</span>
          </div>
        </div>
        <div className={styles.kYCVerifiedAmountBoxAlignment}>
          <h6>Amount</h6>
          <div className={styles.amountBox}>
            <span>$</span>
            <input type="text" value={input} onChange={(e) => setInput(e.target.value)} onKeyPress={bindInput} />
          </div>
          <div className={styles.amountBottomDetails}>
            <h6>Available amount </h6>
            <span>${sellerWithdrawData?.withdrawFund?.amount?.upcoming_amount.toFixed(2)}</span>
          </div>
        </div>
        <div className={styles.continueButtonAlignment} onClick={HandleNext}>
          <button>Continue</button>
        </div>
      </div>
    </div>
  )
}
