import React, { useEffect } from 'react'
import styles from './MobileJewelryStoreModal.module.scss'
import { useMutation } from '@apollo/client'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAppDispatch } from 'src/store/store'
import FOLLOW_STORE from 'src/graphql/mutations/followStore'
import UNFOLLOW_STORE from 'src/graphql/mutations/unFollowStore'
import { notification } from 'antd'
import { useAuth } from 'src/utils/auth'
import NewStoreSlider from 'src/components/StoreAndProductDetailModal/StoresModal/NewStoreSlider'
import { useSelector } from 'react-redux'
import { fetchStoreByCategory } from 'src/store/slices/fetchAllStoresSlice'

export default function MobileJewelryStoreModal() {
  const dispatch = useAppDispatch()
  const { user }: any = useAuth()
  const categoryStore = useSelector((state: any) => state?.storesData?.categoryStore)

  useEffect(() => {
    dispatch(fetchStoreByCategory({ category_id: [2], subCategory_id: [11], page: 1, limit: 10 }))
  }, [])

  const [followStore] = useMutation(FOLLOW_STORE, {
    onCompleted: (res) => {
      dispatch(fetchStoreByCategory({ category_id: [2], subCategory_id: [11], page: 1, limit: 10 }))
    },
    onError: (err) => {
      notification['error']({
        message: 'Error',
        description: 'Something went wrong',
      })
    },
  })

  const [unfollowStore] = useMutation(UNFOLLOW_STORE, {
    onCompleted: (res) => {
      dispatch(fetchStoreByCategory({ category_id: [2], subCategory_id: [11], page: 1, limit: 10 }))
    },
    onError: (err) => {
      notification['error']({
        message: 'Error',
        description: 'Something went wrong',
      })
    },
  })

  const followStoreCall = (store) => {
    if (user) {
      followStore({
        variables: { store_id: store.id },
      })
    } else {
      handleRedirect()
    }
  }

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const unfollowStoreCall = () => {}

  return (
    <div className={styles.mobileJewelryStoreModalSection}>
      <div className={styles.mobileJewelryStoreModalAlignment}>
        <div className={styles.mobileJewelryStoreModalHeader}>
          <h6>Jewelry Stores</h6>
        </div>

        <div className={styles.mobileJewelryStoreModalBody}>
          <div className={styles.mobileJewelryStoreModalGridAlignment}>
            {categoryStore.map((store, index) => {
              return (
                <React.Fragment key={index}>
                  <NewStoreSlider
                    store={store}
                    followStoreCall={followStoreCall}
                    unfollowStoreCall={unfollowStoreCall}
                    handleAddStoreLike={() => {}}
                  />
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
