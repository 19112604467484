import React, { useEffect, useState } from 'react'
import styles from './AddProductModalCategory.module.scss'
import classNames from 'classnames'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useQuery } from '@apollo/client'
import GET_CATEGORIES_QUERY from 'src/graphql/queries/getCategories'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'

export default function AddProductModalCategory({ productData, setProductData, validation, setvalidation }) {
  const { loading, error, data } = useQuery(GET_CATEGORIES_QUERY)
  const [categories, setCategories] = useState([])
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const fetchProductImageResizeData = useSelector((state: any) => state?.productResizeData?.data)
  const [tabOpen, setTabOpen] = useState(0)
  const [subTabOpen, setSubTabOpen] = useState(0)
  const dispatch = useAppDispatch()
  const handleChangeTab = (key, data) => {
    if (data === 'category') {
      if (tabOpen === key) {
        setTabOpen(0)
      } else {
        setTabOpen(key)
      }
    } else {
      if (subTabOpen === key) {
        setSubTabOpen(0)
      } else {
        setSubTabOpen(key)
      }
    }
  }

  useEffect(() => {
    if (getAllState?.lastModalData?.isEdit && getAllState?.lastModalData?.id) {
      let editProductData = productData

      let Array1 = editProductData?.category
      // let Array1 = fetchProductImageResizeData?.id?.selectedCategories
      let Array2 = data?.getAllCategoryDetail
      if (Array2?.length > 0) {
        let outputArray = Array2?.map((category) => {
          let subCategory = category.subCategory.map((subCat) => {
            let childSubCategory = subCat.childSubCategory.map((childSubCat) => {
              let isChecked = Array1?.some(
                (item) =>
                  item?.category_id === category?.id &&
                  item?.subCategory_id === subCat?.id &&
                  item?.childSubCategory_id === childSubCat?.id,
              )
              return { ...childSubCat, isChecked }
            })
            return { ...subCat, childSubCategory }
          })
          return { ...category, subCategory }
        })
        setCategories(outputArray)
      }

      dispatch(
        fetchProductImageResizeModal({
          modal: '',
          isOpen: false,
          id: {
            ...fetchProductImageResizeData?.id,
            selectedCategories: editProductData?.category?.map((cat) => {
              return {
                category_id: cat?.category_id,
                subCategory_id: cat?.subCategory_id,
                childSubCategory_id: cat?.childSubCategory_id,
              }
            }),
          },
        }),
      )
    } else if (fetchProductImageResizeData?.id?.selectedCategories?.length > 0) {
      let Array1 = fetchProductImageResizeData?.id?.selectedCategories
      let Array2 = data?.getAllCategoryDetail
      if (Array2?.length > 0) {
        let outputArray = Array2?.map((category) => {
          let subCategory = category.subCategory.map((subCat) => {
            let childSubCategory = subCat.childSubCategory.map((childSubCat) => {
              let isChecked = Array1?.some(
                (item) =>
                  item?.category_id === category?.id &&
                  item?.subCategory_id === subCat?.id &&
                  item?.childSubCategory_id === childSubCat?.id,
              )
              return { ...childSubCat, isChecked }
            })
            return { ...subCat, childSubCategory }
          })
          return { ...category, subCategory }
        })
        setCategories(outputArray)
      }
    } else {
      setCategories(data?.getAllCategoryDetail)
    }
  }, [getAllState, data, productData])

  const handleClick = (e, category, subCategory, childSubCategory) => {
    let cat = category.id
    let subCat = subCategory.id
    let childSubCate = childSubCategory.id
    let filteredData = fetchProductImageResizeData?.id?.selectedCategories?.filter(
      (cate) => cate.childSubCategory_id === childSubCate,
    )
    if (filteredData?.length > 0) {
      dispatch(
        fetchProductImageResizeModal({
          modal: '',
          isOpen: false,
          id: {
            ...fetchProductImageResizeData?.id,
            selectedCategories: fetchProductImageResizeData?.id?.selectedCategories?.filter(
              (cate) => cate.childSubCategory_id !== childSubCate,
            ),
          },
        }),
      )
    } else {
      dispatch(
        fetchProductImageResizeModal({
          modal: '',
          isOpen: false,
          id: {
            ...fetchProductImageResizeData?.id,
            selectedCategories: [
              ...fetchProductImageResizeData?.id?.selectedCategories,
              { category_id: cat, subCategory_id: subCat, childSubCategory_id: childSubCate },
            ],
          },
        }),
      )
    }
    let filtredData = categories?.map((cat) => {
      if (cat?.id === category?.id) {
        return {
          ...cat,
          subCategory: cat?.subCategory?.map((subcat) => {
            if (subcat?.id === subCategory?.id) {
              return {
                ...subcat,
                childSubCategory: subcat?.childSubCategory?.map((subchildcat) => {
                  if (subchildcat?.id === childSubCategory?.id) {
                    return { ...subchildcat, isChecked: e.target.checked }
                  } else {
                    return subchildcat
                  }
                }),
              }
            } else {
              return subcat
            }
          }),
        }
      } else {
        return cat
      }
    })
    setCategories(filtredData)
    setvalidation({ ...validation, category: '' })
  }
  let subcategoryArray = ['Women', 'Men', 'Girls', 'Boys']

  return (
    <div className={styles.addProductModalCategoryAlignment}>
      <div className={styles.addProductModalCategoryBox}>
        <div className={styles.addProductModalCategoryHeading}>
          <h4>Category</h4>
          <span className={styles.starAlignment}>*</span>
          <span className={styles.starAlignment}>{validation?.category}</span>
        </div>
        <div className={styles.categoryNotes}>
          <span>Product Categories</span>
        </div>
        <div className={styles.modalChildetailsBoxAlignment}>
          <div className={styles.gridAlignment}>
            <div className={styles.addMinusBoxAlignment}>
              <div className={styles.minusAlignment}></div>
            </div>
            <div className={styles.categoryFashionNameAlignment}>
              {categories?.map((category, i) => {
                return (
                  <React.Fragment key={i}>
                    <div className={styles.mainDropdownNameAlignment}>
                      <div className={styles.firstNameAlignment}>{category?.name}</div>
                      <div onClick={() => handleChangeTab(category?.id, 'category')} className={styles.imageTransition}>
                        <img
                          src="/assets/seller/icons/category-dropdown.svg"
                          alt="DropdownIcon"
                          className={
                            tabOpen === category?.id
                              ? classNames(styles.iconRotate, styles.transitionIcon)
                              : classNames(styles.transitionIcon, styles.iconRotateStable)
                          }
                        />
                      </div>
                    </div>
                    <div
                      className={
                        tabOpen === category?.id
                          ? classNames(styles.tabShowArea, styles.childDetailsDropdownNameAlignment)
                          : classNames(styles.tabHiddenArea, styles.childDetailsDropdownNameAlignment)
                      }
                    >
                      {category?.subCategory?.map((subCategory, i) => {
                        return (
                          <React.Fragment key={i}>
                            <div className={styles.childDetailsNameAlignment}>
                              <div className={styles.firstNameAlignment}>
                                {category?.name === 'Fashion & Accessories' ? subcategoryArray[i] : subCategory?.name}
                              </div>
                              <div
                                onClick={() => handleChangeTab(subCategory?.id, 'subcategory')}
                                className={styles.imageTransition}
                              >
                                <img
                                  src="/assets/seller/icons/category-dropdown.svg"
                                  alt="DropdownIcon"
                                  className={
                                    subTabOpen === subCategory?.id
                                      ? classNames(styles.iconRotate, styles.transitionIcon)
                                      : classNames(styles.transitionIcon, styles.iconRotateStable)
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className={
                                subTabOpen === subCategory?.id
                                  ? classNames(styles.tabShowArea, styles.optionDetailsBorder)
                                  : classNames(styles.tabHiddenArea, styles.optionDetailsBorder)
                              }
                            >
                              <div
                                // className={
                                //   subTabOpen === subCategory?.id
                                //     ? classNames(styles.tabShowArea, styles.optionDetailsAlignment)
                                //     : classNames(styles.tabHiddenArea, styles.optionDetailsAlignment)
                                // }
                                className={styles.optionDetailsAlignment}
                              >
                                {subCategory?.childSubCategory?.map((childSubCategory, i) => {
                                  return (
                                    <div key={i} className={styles.checkBoxAlignment}>
                                      <div className={styles.checkboxAlignment}>
                                        <input
                                          type="checkbox"
                                          checked={childSubCategory?.isChecked}
                                          className="checkbox"
                                          onClick={(e) => handleClick(e, category, subCategory, childSubCategory)}
                                        />
                                      </div>
                                      <span
                                      // for="check1"
                                      >
                                        {childSubCategory?.name}
                                      </span>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </React.Fragment>
                        )
                      })}
                    </div>
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        </div>

        <div
          className={styles.mobileViewCategoryAlignment}
          onClick={() =>
            dispatch(
              fetchMobileModalData({
                allModalData: [
                  ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                  {
                    modal: 'addCategoryMobile',
                    isOpen: true,
                    id: 0,
                    transform: 20,
                  },
                ],
                lastModalData: fetchMobileModal?.lastModalData,
              }),
            )
          }
        >
          <input type="text" placeholder="Select Category" />
          <div className={styles.iconAlignment}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M4 7L8 11L12 7" stroke="#636773" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}
