import React, { useEffect, useState } from 'react'
import styles from './BookmarkAddNewModal.module.scss'
import { useMutation } from '@apollo/client'
import createBookMarkCollection from 'src/graphql/mutations/createBookMarkCollection'
import EditBookmarkCollection from 'src/graphql/mutations/editBookMarkCollection'
import GET_ALL_BOOKMARK_COLLECTIONS from 'src/graphql/queries/getBookmarkCollections'
import { notification } from 'antd'
import { motion, AnimatePresence } from 'framer-motion'
import FramerAnimationModal from 'src/utils/FramerAnimationModal'
import GET_SINGLE_COLLECTION from 'src/graphql/queries/getSingleCollection'
import { useAppDispatch } from 'src/store/store'
import { renameBookmarkManage } from 'src/store/slices/manageBookmarkRename'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'

interface BookmarkAddNewModalProps {
  setAddCollectionWebModal: (value: boolean) => void;
  isEditCollection?: boolean;
  name?: string;
  collectionId?: string | number | null;
}

export default function BookmarkAddNewModal({
  setAddCollectionWebModal,
  isEditCollection,
  name,
  collectionId,
}: BookmarkAddNewModalProps) {
  const [inputValue, setInputValue] = useState('')
  const dispatch = useAppDispatch()
  const router = useRouter()
  const manageMobileRename = useSelector((state: any) => state?.manageBookmarRenameSlice?.data)

  const [createBookMarkCollectionMutation] = useMutation(createBookMarkCollection, {
    onCompleted: () => {
      notification['success']({
        message: 'Collection added successfully',
        description: 'Your collection added successfully',
      })
    },
    onError: () => {
      notification['error']({
        message: 'Error',
        description: 'Not added collection',
      })
    },
  })



  const [editBookmarkCollection] = useMutation(EditBookmarkCollection, {
    onCompleted: (res) => {
      notification['success']({
        message: 'Collection updated successfully',
        description: 'Your collection updated successfully',
      })
      // router.push(`/bookmark-collection/${res?.updateCollection?.slug}/`)
      let userDetailArray: any = router?.query?.id
      userDetailArray.pop()

      if (userDetailArray?.length === 2) {
        router.push(`/user/${userDetailArray.join('/')}/${res?.updateCollection?.slug}`)
      }
    },
    onError: () => {
      notification['error']({
        message: 'Error',
        description: 'Not collection updated',
      })
    },
  })

  const handleCloseModel = () => {
    manageMobileRename?.data?.id.showModel
      ? dispatch(
        renameBookmarkManage({
          data: {
            id: {
              showModel: false,
              isEditCollection: false,
              collectionName: '',
              collectionId: null,
            },
          },
        }),
      )
      : setAddCollectionWebModal(false)
  }

  const addCollection = () => {
    // if(!inputValue) toast.error("")
    if (isEditCollection) {
      editBookmarkCollection({
        variables: {
          name: inputValue,
          slug: collectionId,
        },
        refetchQueries: [
          { query: GET_SINGLE_COLLECTION, variables: { slug: collectionId } },
          {
            query: GET_ALL_BOOKMARK_COLLECTIONS,
            variables: {
              isPrivate: true,
            },
          },
        ],
      })

      dispatch(
        renameBookmarkManage({
          data: {
            id: {
              showModel: false,
              isEditCollection: false,
              collectionName: '',
              collectionId: null,
            },
          },
        }),
      )
    } else {
      createBookMarkCollectionMutation({
        variables: {
          input: {
            name: inputValue,
            image: '',
          },
        },
        refetchQueries: [
          {
            query: GET_ALL_BOOKMARK_COLLECTIONS,
            variables: {
              collectionId: null,
            },
          },
        ],
      })
    }
    if (!manageMobileRename?.data?.id.showModel) {
      setAddCollectionWebModal(false)
    }
  }

  useEffect(() => {
    if (isEditCollection && name) {
      setInputValue(name)
    }
  }, [isEditCollection, name])

  return (
    <div className={styles.bookMarkAddNewModalSection}>
      <div className={styles.bookMarkModalWrapper} onClick={() => handleCloseModel()}>
        {/* <FramerAnimationModal> */}
        <div className={styles.bookMarkModalBox} onClick={(e) => e.stopPropagation()}>
          <div className={styles.storeproductfilterclickdropalignmentmain}>
            <div className={styles.storeproductfilterclickdropalignment}>
              <p></p>
            </div>
          </div>
          <div className={styles.bookMarkHeadingAlignment}>
            <h4>{isEditCollection ? 'Rename' : 'Add New'} Collection</h4>
            <div className={styles.bookMarkCloseIcon} onClick={() => handleCloseModel()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M18 6L6 18" stroke="#495057" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 6L18 18" stroke="#495057" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>
          <div className={styles.bookMarkBodyDetailsAlignment}>
            <div className={styles.bookMarkBodyInputAlignment}>
              <label>Collection Name</label>
              <div className={styles.bookMarkBodyInput}>
                <input
                  type="text"
                  placeholder="e.g. Monochromatic Fashion"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
              </div>
              <div className={styles.bookMarkButton}>
                <button className={styles.activeCreate} onClick={() => addCollection()}>
                  {isEditCollection ? 'Update' : 'Create'}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* </FramerAnimationModal> */}
      </div>
    </div>
  )
}
