import { gql } from '@apollo/client'

const CANCLE_ORDER = gql`
mutation CancelOrder($orderItemId: Int!) {
  cancelOrder(order_item_id: $orderItemId) {
    message
    success
  }
}
`
export default CANCLE_ORDER
