import React, { useEffect, useRef, useState } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import styles from './videoPlayer.module.scss'

// const PauseIcon = 'public/assets/icons/pauseButton.svg'
// const UnMuteIcon = 'public/assets/icons/unMuteButton.svg'
// const PlayIcon = 'public/assets/icons/playButton.svg'
// const MuteIcon = 'public/assets/icons/muteButton.svg'

export default function VideoPlayer({ url, enable_mute }: any) {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [isPlaying, setIsPlaying] = useState(false) // Start with paused state
  const [isMuted, setIsMuted] = useState(true)
  const [isLoaded, setIsLoaded] = useState(false)
  const [totalDurationWatched, setTotalDurationWatched] = useState(0)
  const [currentDuration, setCurrentDuration] = useState(0)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsPlaying(true)
          } else {
            setIsPlaying(false)
          }
        })
      },
      { threshold: 0.7 },
    )

    if (videoRef.current) {
      observer.observe(videoRef.current)
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current)
      }
    }
  }, [videoRef])

  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying && videoRef.current.paused) {
        videoRef.current.play().catch((error) => {})
      } else {
        videoRef.current.pause()
      }
      setIsMuted(true)
    }
  }, [isPlaying])

  const togglePlayPause = () => {
    setIsPlaying((prevIsPlaying) => !prevIsPlaying)
  }

  const toggleMuteUnmute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted
      setIsMuted((prevIsMuted) => !prevIsMuted)
    }
  }

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentDuration(videoRef.current.currentTime)
      // Update total duration watched
      setTotalDurationWatched((prevDuration) => prevDuration + 1) // Assuming each time update adds 1 second
    }
  }

  const handleVideoLoad = () => {
    setIsLoaded(true)
    if (videoRef.current) {
      if (isPlaying && videoRef.current.paused) {
        videoRef.current.play().catch((error) => {})
      } else {
        videoRef.current.pause()
      }
      setIsMuted(true)
    }
  }

  return (
    <div className={styles.videoPlayerButtonAlignment}>
      {/* <video loop autoPlay={false} muted={isMuted} src={url} ref={videoRef} /> */}
      {!isLoaded && (
        <SkeletonTheme baseColor="#d1d1d1" highlightColor="#FFFFFF">
          <Skeleton style={{ width: '100%', height: '354px' }} />
        </SkeletonTheme>
      )}
      <video
        playsInline
        controls
        loop
        muted={isMuted}
        autoPlay={/iPad|iPhone|iPod/.test(navigator.userAgent) ? true : false}
        ref={videoRef}
        onLoadedData={handleVideoLoad}
        onTimeUpdate={handleTimeUpdate}
        style={{
          opacity: isLoaded ? 1 : 0,
          transition: 'opacity 1s',
          background: 'black',
          width: '100%',
          height: '100%',
        }}
      >
        <source src={url} />
        Sorry, your browser doesn't support embedded videos.
      </video>
      <div className={styles.actionButtonAlignment}>
        {/* <img onClick={togglePlayPause} src={isPlaying ? PauseIcon : PlayIcon} alt="PauseIcon" /> */}
        {/* {enable_mute && <img onClick={toggleMuteUnmute} src={isMuted ? UnMuteIcon : MuteIcon} alt="MuteIcon" />} */}
      </div>
      {/* <p>Total duration watched: {totalDurationWatched} seconds</p> */}
      {/* <p>Current duration: {currentDuration} seconds</p> */}
    </div>
  )
}
