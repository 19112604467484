import gql from 'graphql-tag'

const GET_SHIPPING_METHOD = gql`
  query GetShippingMethod($storeId: Int) {
    getShippingMethod(store_id: $storeId) {
      message
      success
      data {
        id
        name
        zipcode
        processing_time
        processing_time_type
        is_free_delivery
        is_free_international_delivery
        handling_fee
        is_active
        is_deleted
        delivery_options_type
        service_code
      }
    }
  }
`
export default GET_SHIPPING_METHOD
