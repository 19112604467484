// @ts-nocheck

import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import styles from './GroupSideSection.module.scss'
import { useAuth } from 'src/utils/auth'
import DECLINE_FRIEND_REQUEST from 'src/graphql/mutations/declineFriendRequest'
import SEND_FRIEND_REQUEST from 'src/graphql/mutations/sendFriendRequest'
import GETGROUP from 'src/graphql/queries/getGroup'
import { useMutation } from '@apollo/client'
import UserIcon from '../../../../public/assets/icon/user-icon2.svg'
import GlobalIcon from '../../../../public/assets/icon/globe.svg'
import JOIN_GROUP from 'src/graphql/mutations/joinGroup'
import MoreIcon from '../../../../public/assets/icon/moreOptionIcon.svg'
import { notification } from 'antd'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import LazyImage from 'src/utils/LazyImage'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { fetchClubMobileModalData } from 'src/store/slices/fetchClubMobileModalData'
import { clubMembersInvite } from 'src/store/slices/inviteClubMebersSlice'
import { fetchClubMembers } from 'src/store/slices/fetchAllClubsSlice'

export default function GroupSideSection(props) {
  const { group } = props
  const dispatch = useAppDispatch()
  const { user } = useAuth()
  const router = useRouter()
  const [showMore, setShowMore] = useState(true)
  const allManageClub = useSelector((state: any) => state?.clubData?.clubMembers)
  const sortedMembers = [...(allManageClub || [])].sort((a, b) => (a.id === user?.id ? -1 : b.id === user?.id ? 1 : 0))
  const fetchClubMobileModal = useSelector((state: any) => state?.clubMobileModal?.data)

  const MemeberPeople = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 3,
    arrow: false,
  }
  const [expand, setExpand] = useState(false)

  const [declineFriendRequest] = useMutation(DECLINE_FRIEND_REQUEST, {
    onCompleted: () => {
      dispatch(fetchClubMembers(router?.query?.id))
    },
    onError: (error) => {},
  })

  const [sendFriendRequest] = useMutation(SEND_FRIEND_REQUEST, {
    onCompleted: ({ sendFriendRequest }) => {
      dispatch(fetchClubMembers(router?.query?.id))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const handleDeclineRequest = (userId) => {
    if (user) {
      declineFriendRequest({
        variables: { user_id: user.id, friend_id: parseInt(userId) },
        refetchQueries: [{ query: GETGROUP, variables: { slug: router?.query?.id } }],
      })
    } else {
      handleRedirect()
    }
  }

  const handleSendRequest = (userId) => {
    if (user) {
      sendFriendRequest({
        variables: {
          friend_id: parseInt(userId),
          user_id: user?.id,
          isActive: true,
          isFriend: true,
        },
        refetchQueries: [{ query: GETGROUP, variables: { slug: router?.query?.id } }],
      })
    } else {
      handleRedirect()
    }
  }

  const handleInviteFriends = () => {
    if (user) {
      dispatch(
        clubMembersInvite({
          data: {
            showModel: true,
          },
        }),
      )
    } else {
      handleRedirect()
    }
  }

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const [joingroup] = useMutation(JOIN_GROUP, {
    onCompleted: (res) => {
      notification['success']({
        message: ' Joined successfully',
        description: 'You have been joined successfully',
      })
    },
    onError: (err) => {
      console.log('err', err)
      notification['error']({
        message: 'Error',
        description: 'Something went wrong',
      })
    },
  })

  const handleExpand = () => {
    setExpand(!expand)
  }

  const handleShowMore = () => {
    setShowMore(!showMore)
  }

  let adminUser = allManageClub?.filter((ad) => ad?.isAdmin)?.[0]

  const handleOpenDescription = () => {
    dispatch(
      fetchClubMobileModalData({
        allModalData: [
          ...(fetchClubMobileModal?.allModalData ? fetchClubMobileModal?.allModalData : []),
          {
            modal: 'clubDescriptionModal',
            isOpen: true,
            id: {
              group: group,
            },
            transform: 302,
            for: 'club',
          },
        ],
        lastModalData: fetchClubMobileModal?.lastModalData,
      }),
    )
  }

  const handelImageSlider = () => {}

  useEffect(() => {
    dispatch(fetchClubMembers(router?.query?.id))
  }, [])

  return (
    <React.Fragment>
      <div className={styles.clubAboutDetailsAlignment}>
        <div className={styles.clubAboutMoreDetailsAlignment}>
          <div className={styles.clubAboutMediaImgAlignment}>
            {sortedMembers.slice(0, 5)?.map((mem, index) => {
              return (
                <div className={styles.clubProfileImg} key={index}>
                  <img src={mem?.logo_image} alt="profile img" />
                </div>
              )
            })}
          </div>

          {allManageClub?.length > 0 && (
            <div className={styles.clubAboutNotesAlignment}>
              <p>
                <span>
                  {sortedMembers
                    .slice(0, 3)
                    ?.map((mem) => {
                      return mem?.firstName + ' ' + mem?.lastName
                    })
                    .join(', ')}
                  {sortedMembers?.length - 3 > 0 && ' and'}{' '}
                  {sortedMembers?.length - 3 > 0 && sortedMembers?.length + 'more'}{' '}
                </span>
                joined this club
              </p>
            </div>
          )}
        </div>
        <div className={styles.aboutGroupAlignment}>
          <h5>About Club</h5>
          <div>
            <p>
              {showMore
                ? group?.description?.length > 135
                  ? group?.description.substring(0, 135) + '...'
                  : group?.description
                : group?.description}

              {/* <===============  Web view See More start ==================> */}
              {group?.description?.length > 135 ? (
                showMore ? (
                  <span className={styles.webViewSaw} onClick={handleShowMore}>
                    Show More
                  </span>
                ) : (
                  <span className={styles.webViewSaw} onClick={handleShowMore}>
                    Less
                  </span>
                )
              ) : (
                ''
              )}
            </p>
          </div>
        </div>
        <div className={styles.mobileViewClubDetailsAlignment}>
          <div className={styles.mobileViewClubFlexAlignment}>
            <div className={styles.mobileViewclubProfileAlignment}>
              <div className={styles.mobileViewImgAlignment}>
                <img
                  src={group?.logo_image ? group?.logo_image : '/assets/img/groups/empty-group-logo.png'}
                  alt="ProfileImg"
                />
              </div>
              <div className={styles.mobileViewClubChildDetailsAlignment}>
                <h4>{group?.name ? group?.name : ''}</h4>
                <div className={styles.mobileMemeberDetailsAlignment}>
                  <div>
                    <img src={UserIcon.src} alt="UserIcon" />
                  </div>
                  <span>{allManageClub?.length ? allManageClub?.length : 0} Members</span>
                </div>
                <div className={styles.mobileMemeberDetailsAlignment}>
                  <div>
                    <img src={GlobalIcon.src} alt="GlobalIcon" />
                  </div>
                  <span>{group?.privacy ? group?.privacy : ''}</span>
                </div>
              </div>
            </div>
            <div className={styles.mobileViewnotificationAlignment}>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.5 6C13.5 4.80653 13.0259 3.66193 12.182 2.81802C11.3381 1.97411 10.1935 1.5 9 1.5C7.80653 1.5 6.66193 1.97411 5.81802 2.81802C4.97411 3.66193 4.5 4.80653 4.5 6C4.5 11.25 2.25 12.75 2.25 12.75H15.75C15.75 12.75 13.5 11.25 13.5 6Z"
                  stroke="#636773"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.2971 15.75C10.1653 15.9773 9.97603 16.166 9.74832 16.2971C9.52061 16.4283 9.26243 16.4973 8.99965 16.4973C8.73686 16.4973 8.47869 16.4283 8.25098 16.2971C8.02327 16.166 7.83401 15.9773 7.70215 15.75"
                  stroke="#636773"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          {!group?.isExist && (
            <div className={styles.mobileViewbuttonAlignment}>
              <div className={styles.joinClubButtonAlignment}>
                <button
                  onClick={() => {
                    joingroup({
                      variables: { group_id: group?.id, user_id: user.id, isAdmin: false },
                      refetchQueries: [{ query: GETGROUP, variables: { slug: router?.query?.id } }],
                    })
                  }}
                >
                  Join Club
                </button>
              </div>
              <div
                className={styles.mobileViewMoreOptionAlignment}
                onClick={() => {
                  dispatch(
                    fetchClubMobileModalData({
                      allModalData: [
                        ...(fetchClubMobileModal?.allModalData ? fetchClubMobileModal?.allModalData : []),
                        { modal: 'clubDotsMenu', isOpen: true, id: group, transform: 302, for: 'club' },
                      ],
                      lastModalData: fetchClubMobileModal?.lastModalData,
                    }),
                  )
                }}
              >
                <img src={MoreIcon.src} alt="MoreIcon" />
              </div>
            </div>
          )}

          {group?.isExist && (
            <div className={styles.mobileViewjoinClubDetailsAlignment}>
              <div className={styles.mobileViewJoinClubButton}>
                <button className={styles.joinedButton}>Joined</button>
              </div>
              {/* <div
                className={styles.mobileVIewMoreOption}
                onClick={() => {
                  dispatch(
                    fetchClubMobileModalData({
                      allModalData: [
                        ...(fetchClubMobileModal?.allModalData ? fetchClubMobileModal?.allModalData : []),
                        { modal: 'clubDotsMenu', isOpen: true, id: group, transform: 302, for: 'club' },
                      ],
                      lastModalData: fetchClubMobileModal?.lastModalData,
                    }),
                  )
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path
                    d="M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z"
                    fill="#636773"
                    stroke="#636773"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.25 9.75C14.6642 9.75 15 9.41421 15 9C15 8.58579 14.6642 8.25 14.25 8.25C13.8358 8.25 13.5 8.58579 13.5 9C13.5 9.41421 13.8358 9.75 14.25 9.75Z"
                    fill="#636773"
                    stroke="#636773"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.75 9.75C4.16421 9.75 4.5 9.41421 4.5 9C4.5 8.58579 4.16421 8.25 3.75 8.25C3.33579 8.25 3 8.58579 3 9C3 9.41421 3.33579 9.75 3.75 9.75Z"
                    fill="#636773"
                    stroke="#636773"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div> */}
            </div>
          )}
          <div className={styles.mobileViewAboutClubDetailsAlignment}>
            <h5>About Club</h5>
            <p>
              {!expand && group?.description?.substring(0, 110) + `${!expand ? ' ' : '... '}`}
              {expand && group?.description + ' '}

              {/* <======================= Web view seemore start ========================> */}

              <a onClick={handleExpand} className={styles.webviewSeeMore}>
                {expand ? 'Less' : group?.description?.length > 110 && 'Read More'}
              </a>

              {/* <======================= Web view seemore end ========================> */}

              {/* <======================= Mobile view seemore start ========================> */}

              <a onClick={handleOpenDescription} className={styles.mobileviewSeeMore}>
                {expand ? 'Less' : group?.description?.length > 110 && 'Read More'}
              </a>
              {/* <======================= Mobile view seemore end ========================> */}
            </p>
          </div>
          {adminUser && (
            <div className={styles.mobileViewadminDetailsAlignment}>
              <h5>Admin</h5>
              <div className={styles.mobileViewAdminAllDetailsAlignment}>
                <div className={styles.mobileViewadminProfileDetailsAlignment}>
                  {adminUser?.profileUrl && (
                    <div className={styles.mobileViewprofileImgAlignment}>
                      <img src={adminUser?.profileUrl} alt="ProfileImg" />
                    </div>
                  )}
                  <div className={styles.mobileAdminProfileNameAlignment}>
                    <h6>
                      {adminUser && `${adminUser?.firstName + ' ' + adminUser?.lastName}`} {adminUser ? `(You)` : ''}
                    </h6>
                    <p>{adminUser && `@${adminUser?.firstName + ' ' + adminUser?.lastName}`}</p>
                  </div>
                </div>
                {adminUser &&
                  (adminUser?.id === user?.id ? (
                    <div className={styles.mobileFollowButtonAlignment}>
                      {/* <button className="following">You</button> */}
                    </div>
                  ) : adminUser?.isActiveForFriendStatus && adminUser?.isActiveForFriendStatus ? (
                    <div className={styles.mobileFollowButtonAlignment}>
                      <button onClick={() => handleDeclineRequest(adminUser?.id)} className="following">
                        Following
                      </button>
                    </div>
                  ) : (
                    <div className={styles.mobileFollowButtonAlignment}>
                      <button onClick={() => handleSendRequest(adminUser?.id)}>+ Follow</button>
                    </div>
                  ))}
              </div>
            </div>
          )}
          <div className={styles.mobileViewNotesAlignment}>
            <div className={styles.mobileViewNotesPersonImgAlignment}>
              {allManageClub?.slice(0, 3)?.map((grp, index) => {
                return (
                  <div className={styles.imgLayoutAlignment} key={index}>
                    <div className={styles.mobileViewimgAlignment}>
                      <img src={grp?.profileUrl} alt="ProfileImg" />
                    </div>
                  </div>
                )
              })}
            </div>
            <p>
              {allManageClub
                ?.slice(0, 2)
                ?.map((grp) => {
                  return grp?.firstName
                })
                .join(', ')}{' '}
              {allManageClub?.length - 3 > 0 && ' and'}{' '}
              {allManageClub?.length - 3 > 0 && allManageClub?.length + 'more'} Joined this club
            </p>
          </div>
        </div>
        {allManageClub?.filter((fil) => fil?.id !== user?.id)?.slice(-3).length > 0 && (
          <div className={styles.newestMembersSection}>
            <div className={styles.newestMembersHeaderAlignment}>
              <p>Newest Members </p>
              {/* <a onClick={() => handleInviteFriends()}>Invite a friends</a> */}
            </div>
            <div className={styles.memberDetailsSilderAlignment}>
              <Slider {...MemeberPeople}>
                {allManageClub
                  ?.filter((fil) => fil?.id !== user?.id)
                  ?.slice(-3)
                  ?.map((member, index) => {
                    let name = member?.firstName + ' ' + member?.lastName
                    let isFollow = member?.isActiveForFriendStatus && member?.isFriendForFriendStatus ? true : false
                    return (
                      <div className={styles.memeberDetailsAlignment} key={index}>
                        <div className={styles.memberProfileImgAlignment}>
                          {/* <img src={member?.profileUrl} alt="MemberImg" /> */}
                          <LazyImage
                            image={{ src: member?.logo_image, alt: 'Icon' }}
                            objectFit={'cover'}
                            handleClickOnImage={() => handelImageSlider()}
                            position={'absolute'}
                            borderRadius={'50%'}
                          />
                        </div>
                        <div className={styles.memeberChildDetailsAlignment}>
                          <Link href={`/user/${member?.userName}`}>
                            <h5>{name?.length > 12 ? name.substring(0, 12) + '...' : name + ''}</h5>
                          </Link>
                          <span>{member?.followers?.length} Followers</span>
                          {isFollow ? (
                            <div
                              className={styles.followButtonAlignment}
                              onClick={() => handleDeclineRequest(member?.id)}
                            >
                              <button className="following">Following</button>
                            </div>
                          ) : (
                            <div className={styles.followButtonAlignment} onClick={() => handleSendRequest(member?.id)}>
                              <button>Follow</button>
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  })}
              </Slider>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
