import React from 'react'
import SearchIcon from '../../../../../../../public/assets/icon/search.svg'
import BlackheartIcon from '../../../../../../../public/assets/icon/heart-black.svg'
import ShareIcon2 from '../../../../../../../public/assets/icon/share.svg'
import UserIcon from '../../../../../../../public/assets/icon/users.svg'
import BookIcon from '../../../../../../../public/assets/icon/book.svg'
import HeartIcon from '../../../../../../../public/assets/icon/heart.svg'
import AuthorImg from '../../../../../../../public/assets/img/auther-img.png'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'

export default function BookAuthorModal(props) {
  const { setIsOpen } = props

  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  return (
    <div className="more-author-details-alignment">
      <div className="more-author-heading-alignment">
        <h4>Authors</h4>
        <div className="more-author-search-input-alignment">
          <img src={SearchIcon.src} alt="SearchIcon" />
          <input type="text" placeholder="Type keyword here..." />
        </div>
      </div>
      <div className="more-author-details-modal-body-alignment">
        <div className="more-author-grid-alignment">
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((_, index) => {
            return (
              <div
                className="more-author-gridItem-alignment"
                key={index}
                onClick={() =>
                  dispatch(
                    fetchModalData({
                      allModalData: [
                        ...getAllState?.allModalData,
                        { modal: 'bookSpecialOfferModal', isOpen: true, id: 0, modalFor: 'bigModal' },
                      ],
                      lastModalData: getAllState?.lastModalData,
                    }),
                  )
                }
              >
                <div className="more-author-box-alignment">
                  <div className="author-img-alignment">
                    <img src={AuthorImg.src} alt="AuthorImg" />
                  </div>

                  <div className="more-auther-details-alignment">
                    <h6>David Baldaccci</h6>

                    <div className="auther-following-details-alignment">
                      <div className="child-anme-alignment">
                        <img src={BookIcon.src} alt="BookIcon" />
                        <span>150</span>
                      </div>
                      <div className="child-anme-alignment">
                        <img src={UserIcon.src} alt="UserIcon" />
                        <span>12M</span>
                      </div>
                      <div className="child-anme-alignment">
                        <img src={BlackheartIcon.src} alt="BlackheartIcon" />
                        <span>275 </span>
                      </div>
                    </div>
                    <div className="auther-options-alignment">
                      <div className="follow-page-button-alignment">
                        <button>Follow Page</button>
                      </div>

                      <div className="like-share-alignment">
                        <div className="box-alignment">
                          <img src={HeartIcon.src} alt="HeartIcon" />
                        </div>
                        <div className="box-alignment">
                          <img src={ShareIcon2.src} alt="ShareIcon" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
