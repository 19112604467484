// @ts-nocheck

import React, { useEffect, useState } from 'react'
import styles from './TrendingProduct.module.scss'
import BrowseByTypeProduct from 'src/components/Category/ShopByCategoryDetails/BrowseByTypeProduct'
import Slider from 'react-slick'
import CategoryProduct from 'src/components/Category/ShopByCategoryDetails/CategoryProduct'
import AllProduct from 'src/components/ProductPage/AllProduct'
import { fetchAllStoreProducts } from 'src/store/slices/fetchAllStoresSlice'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { setProductByCategoryState } from 'src/store/slices/fetchAllProduct'

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4.022,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3.022,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3.022,
        slidesToScroll: 1,
      },
    },
  ],
}

const specialOffer = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4.95,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3.022,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2.09,
        slidesToScroll: 1,
        variableWidth: true,
      },
    },
  ],
}

export default function TrendingProduct() {
  const dispatch = useAppDispatch()
  const [filterData, setFilterData] = useState<any>({ subCategory: [], category: [] })
  const [page, setPage] = useState(1)
  const productByCategory = useSelector((state: any) => state?.productByCategory)

  useEffect(() => {
    fetchProduct()
  }, [])

  const fetchProduct = (product_id = null, type = null) => {
    dispatch(
      fetchAllStoreProducts({
        store_id: null,
        page: 1,
        limit: 20,
        search: '',
        status: '',
        subCategory: [],
      }),
    ).then(() => {
      if (type === 'dislike' || type === 'like') {
        let update_product = productByCategory?.product?.getAllProducts?.data?.map((prd) => {
          if (prd?.id === product_id) {
            return {
              ...prd,
              likes: type === 'like' ? { id: 3.3333 } : null,
            }
          } else {
            return prd
          }
        })
        dispatch(
          setProductByCategoryState({
            getAllProducts: {
              data: update_product,
            },
          }),
        )
      }
    })
  }

  const fetchUpdatedProduct = (product_id, type) => {
    fetchProduct(product_id, type)
  }

  return (
    <>
      <div className={styles.categoryBrowsedByTypeSection}>
        <div className={styles.categoryMobileViewHeaderAlignment}>
          <div className={styles.mobileViewBack}>
            <svg xmlns="http://www.w3.org/2000/svg" width="37" height="32" viewBox="0 0 37 32" fill="none">
              <path
                d="M27 0H10C4.47715 0 0 4.47715 0 10V22C0 27.5228 4.47715 32 10 32H27C32.5228 32 37 27.5228 37 22V10C37 4.47715 32.5228 0 27 0Z"
                fill="white"
              />
              <path
                d="M21 10L16 16L21 22"
                stroke="#808191"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <h4>Browse by Type</h4>
        </div>
        <div className={styles.categoryBrowsedByTypeDetails}>
          <div className={styles.categoryBrowsedByTypeSlider}>
            <Slider {...settings}>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => {
                return (
                  <div className={styles.categoryBrowsedProduct} key={index}>
                    <BrowseByTypeProduct />
                  </div>
                )
              })}
            </Slider>
          </div>
          <div className={styles.categoryHotDetailsAlignment}>
            <div className={styles.hotHeading}>
              <h4>Hats</h4>
            </div>
            <div className={styles.specialOfferAlignment}>
              <div className={styles.childHeading}>
                <h6>Special Offers</h6>
                <div className={styles.seeMoreAlignment}>
                  <a>See more</a>
                </div>
              </div>
              <div className={styles.specialOfferAllBodyDetailsAlignment}>
                <div className={styles.specialOfferAllGrid}>
                  <div className={styles.specialOfferAllGridItem}>
                    <div className={styles.specialOfferImg}>
                      <img src="/assets/img/new-banner-img.png" alt="banner img" />
                    </div>
                  </div>
                  <div className={styles.specialOfferAllGridItem}>
                    <div className={styles.specialOfferSliderAlignment}>
                      <Slider {...specialOffer}>
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item, index) => {
                          return (
                            <div className={styles.specialOfferDetailsAlignment} key={`z${index}`}>
                              <CategoryProduct index={index} item={item} />
                            </div>
                          )
                        })}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AllProduct
          products={productByCategory?.product?.getAllProducts?.data}
          storeId={0}
          color={'#FFFFFF'}
          isFilterMenu={true}
          total={productByCategory?.product?.getAllProducts?.total}
          dataLimite={20}
          topForFilter={-14}
          isPadding={true}
          setPage={setPage}
          isCalled={productByCategory?.called}
          page={page}
          mobileTopForFilter={97}
          setFilterData={setFilterData}
          filterData={filterData}
          APIcall={fetchUpdatedProduct}
        />
      </div>
    </>
  )
}
