import React, { useState } from 'react'
import star from '../../../../../../public/assets/img/WomansCategory/star.png'
import aroww from '../../../../../../public/assets/img/WomansCategory/aroww.png'
import BlackheartIcon from '../../../../../../public/assets/icon/heart-black.svg'
import ShareIcon from '../../../../../../public/assets/icon/share-black.svg'
import SpecialOfferBannerImg from '../../../../../../public/assets/img/speacial-offer-banner.png'
import BookCoverImg from '../../../../../../public/assets/img/bookCover.png'

export default function BookSpecialOfferModal() {
  return (
    <div className="special-offer-details-alignment">
      <div className="special-offer-banner-alignment">
        <img src={SpecialOfferBannerImg.src} alt="SpecialOfferBannerImg" />
        <div className="special-offer-banner-details-alignment">
          <h2>SPECIAL OFFERS</h2>
          <p>
            Get lost in a good book at a great price! Check out our special offers on bestselling titles and new
            releases
          </p>
        </div>
      </div>
      <div className="speaicl-offer-tab-alignment">
        <ul>
          <li className="offer-active">All</li>
          <li>Art and Design</li>
          <li>Anthology</li>
          <li>Crime</li>
          <li>Drama</li>
          <li>Mystery</li>
          <li>Romance</li>
          <li>History</li>
          <li>Philosophy</li>
          <li>Dictionary</li>
        </ul>
      </div>
      <div className="special-offer-book-details-alignment">
        <div className="speaicl-offer-book-grid-alignment">
          {[0, 1, 2, 3, 4, 5].map((_, index) => {
            return (
              <div className="speaicl-offer-book-gridItem-alignment" key={index}>
                <div className="speaicl-offer-book-alignment">
                  <div className="book-img-alignment">
                    <img src={BookCoverImg.src} alt="BookCoverImg" />
                  </div>
                  <div className="release-book-name-alignment">
                    <h6>The Sweetness of Water</h6>
                    <p>Nathan Harris</p>

                    <div className="book-rate-alignment">
                      <p className="hike">
                        <span className="incris">
                          <img src={aroww.src} alt="incris" /> 200%
                        </span>
                      </p>
                      <div className="rating">
                        <span className="rating_point">4.8</span>
                        <div className="stars">
                          <img src={star.src} alt="star" />
                        </div>
                        <span className="num">(437)</span>
                      </div>
                    </div>
                    <div className="book-price-alignment">
                      <h5>$21.00</h5>
                      <span>
                        <del>$21.00</del>
                      </span>
                    </div>
                    <div className="like-share-alignment">
                      <div className="like-alignment">
                        <img src={BlackheartIcon.src} alt="BlackheartIcon" />
                        <span>235</span>
                      </div>
                      <div className="like-alignment">
                        <img src={ShareIcon.src} alt="ShareIcon " />
                        <span>235</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
