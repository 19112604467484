import { gql } from '@apollo/client'

const GET_JOINED_GROUP = gql`
  query {
    getJoinedGroups {
      id
      name
      slug
      description
      hashtags
      coverImage
      bannerImage
      isFollow
      members {
        id
        firstName
        lastName
        profileUrl: profileAvtar
      }
      privacy
      user_id
    }
  }
`
export default GET_JOINED_GROUP
