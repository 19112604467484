import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface meetingDetail {
  loading: true | false
  error: {} | null
  called: true | false
  data: any
}

const initialState = {
  loading: false,
  error: null,
  data: {
    token: null,
    participant: null,
  },
  called: false,
} as meetingDetail

export const createMeetingSlice = createAsyncThunk('fetchMeetingData/createMeetingSlice', async (data: any) => {
  return data
})

const meetingDetail = createSlice({
  name: 'fetchMeetingDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createMeetingSlice.pending, (state, action) => {
      state.loading = true
      state.error = null
      state.called = true
    })
    builder.addCase(createMeetingSlice.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
      state.called = true
    })
    builder.addCase(createMeetingSlice.rejected, (state, action) => {
      state.loading = false
      state.called = false
      state.error = action.error
      console.error('Error fetching cards!')
    })
  },
})

// export var _actions = meetingDetail.actions
export default meetingDetail.reducer
