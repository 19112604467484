import { gql } from '@apollo/client'

const GET_ALL_ORDER_HISTORY = gql`
  query getAllOrdersHistory(
    $store_id: Int!
    $start_date: String!
    $end_date: String!
    $time_zone: String
    $time_interval: String
    $user_id: Int
  ) {
    getAllOrdersHistory(
      store_id: $store_id
      start_date: $start_date
      end_date: $end_date
      time_zone: $time_zone
      time_interval: $time_interval
      user_id: $user_id
    ) {
      success
      message
      data {
        id
        store_id
        order_status
        order_id
        createdAt
        product {
          id
          price
          title
          images
        }
        total
        price
        quantity
        user_id
        customer {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`

export default GET_ALL_ORDER_HISTORY
