import React, { useEffect, useState } from 'react'
import styles from './ExpandedMediaCommentModal.module.scss'
import PostComments from 'src/components/Feed/NormalPost/PostComments'
import { useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import CREATE_GROUP_COMMENT from 'src/graphql/mutations/createGroupComment'
import MY_FEED_QUERY from 'src/graphql/queries/getMyFeed'
import GET_COMMENT_BY_ID from 'src/graphql/queries/getCommentById'
import { useAppDispatch } from 'src/store/store'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'

export default function ExpandedMediaCommentModal() {
  const fetchPost = useSelector((state: any) => state?.manageMobileModal?.data)
  const dispatch = useAppDispatch()
  const [postContent, setPostContent] = useState('')
  const [showMore, setShowMore] = useState(false)
  const [postId, setPostId] = useState({ id: null, post_for: null })
  const [commentId, setCommentId] = useState({
    id: fetchPost?.lastModalData?.id?.post?.product
      ? fetchPost?.lastModalData?.id?.post?.product?.id
      : fetchPost?.lastModalData?.id?.post?.id,
    comment_for: fetchPost?.lastModalData?.id?.post?.product ? 'PRODUCT' : 'POST',
  })
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)

  const groupPostCommentHandler = () => {
    if (postContent.trim().length === 0) return
    createComment({
      variables: {
        parent_id: fetchMobileModal?.lastModalData?.id?.post?.id,
        comment: postContent,
        comment_for: fetchMobileModal?.lastModalData?.id.postFor === 'group' ? 'group' : 'post',
      },
      // variables: { parent_id: fetchMobileModal?.lastModalData?.id?.post?.product?.id, comment: postContent, comment_for: 'PRODUCT' },

      refetchQueries: [
        { query: MY_FEED_QUERY, variables: { group_id: fetchMobileModal?.lastModalData?.id?.post?.groupId } },
        {
          query: GET_COMMENT_BY_ID,
          variables: { id: fetchMobileModal?.lastModalData?.id?.post?.id, comment_for: commentId?.comment_for },
        },
        // { query: GET_COMMENT_BY_ID, variables: { id: fetchMobileModal?.lastModalData?.id?.post?.id, comment_for: 'SHAREPOST' } },
      ],
    }).then((refetch) => {
      if (refetch.data !== undefined) {
        setPostContent('')
        // dispatch(
        //   fetchMobileModalData({
        //     allModalData: [
        //       ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
        //       {
        //         modal: 'mobilecommentModal',
        //         isOpen: false,
        //         id: {
        //           post: null,
        //         },
        //         transform: 16,
        //         unit: '%'
        //       },
        //     ],
        //     lastModalData: fetchMobileModal?.lastModalData,
        //   }),
        // )
      }
    })
  }

  const [createComment] = useMutation(CREATE_GROUP_COMMENT, {
    onError: (error) => {
      console.log(error)
    },
  })

  return (
    <div className={styles.expendedMediaCommentModalSection}>
      <div className={styles.expendedMediaCommentModalAlignment}>
        <h4>Comments</h4>

        <div className={styles.expendedMediaCommentModalAllDetails}>
          <PostComments
            // comments={getSingleProduct?.comments}
            setShowMore={setShowMore}
            showMore={showMore}
            postId={fetchMobileModal?.lastModalData?.id?.post?.id}
            backgroundColor="#FFFFFF"
            commentId={commentId}
            isProductComment={false}
            isBottonBorderVisible={true}
            isShowMore={true}
          />
        </div>
        <div className={styles.expendedMediaCommentBoxAlignment}>
          <div className={styles.expendedMediaCommentBoxInput}>
            <div className={styles.expendedMediaInput}>
              <input
                type="text"
                value={postContent}
                placeholder="Type comment..."
                onChange={(e) => setPostContent(e.target.value)}
              />
            </div>
            {/* <div className={styles.picIconAlignment}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M14.25 2.25H3.75C2.92157 2.25 2.25 2.92157 2.25 3.75V14.25C2.25 15.0784 2.92157 15.75 3.75 15.75H14.25C15.0784 15.75 15.75 15.0784 15.75 14.25V3.75C15.75 2.92157 15.0784 2.25 14.25 2.25Z"
                  stroke="#636773"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.375 7.5C6.99632 7.5 7.5 6.99632 7.5 6.375C7.5 5.75368 6.99632 5.25 6.375 5.25C5.75368 5.25 5.25 5.75368 5.25 6.375C5.25 6.99632 5.75368 7.5 6.375 7.5Z"
                  stroke="#636773"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.75 11.25L12 7.5L3.75 15.75"
                  stroke="#636773"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div> */}
          </div>
          <div className={styles.expendedMediaBoxAlignment} onClick={() => groupPostCommentHandler()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
              <path
                d="M15.9521 8.36328L11 13.3154"
                stroke="#556EE6"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22 2.31543L15 22.3154L11 13.3154L2 9.31543L22 2.31543Z"
                stroke="#556EE6"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}
