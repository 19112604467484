import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface createPostCountSlice {
  loading: true | false
  error: {} | null
  called: true | false
  data: any
}

const initialState = {
  loading: false,
  error: null,
  data: {
    count: 0,
    isUploaded: false,
    isError: false,
    isShow: false,
  },
  called: false,
} as createPostCountSlice

export const postCountData = createAsyncThunk('fetchStoreDetail/postCountData', async (data: any) => {
  return data
})

const createPostCountSlice = createSlice({
  name: 'fetchStoreDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postCountData.pending, (state, action) => {
      state.loading = true
      state.error = null
      state.called = true
    })
    builder.addCase(postCountData.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
      state.called = true
    })
    builder.addCase(postCountData.rejected, (state, action) => {
      state.loading = false
      state.called = false
      state.error = action.error
      console.error('Error fetching cards!')
    })
  },
})

// export var _actions = createPostCountSlice.actions
export default createPostCountSlice.reducer
