import React, { useEffect, useState } from 'react'
import styles from './SuggestedClubsDetails.module.scss'
import ClubItems from '../ClubItems'
import useDebounce from 'src/hoc/useDebounce'

export default function SuggestedClubsDetails({ handleCreateClub, title, description, APIcallType }) {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  // useEffect(() => {
  //   console.log('debouncedSearch', debouncedSearch)
  // }, [debouncedSearch])

  return (
    <>
      <div className={styles.newSuggestedStores}>
        <div className={styles.storesGrid}>
          <div>
            <p>{title}</p>
            <span>{description}</span>
          </div>
          <div className={styles.peopleFollowerSearchAlignment}>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
              <path
                d="M19 27C23.4183 27 27 23.4183 27 19C27 14.5817 23.4183 11 19 11C14.5817 11 11 14.5817 11 19C11 23.4183 14.5817 27 19 27Z"
                stroke="#ACB1C0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M28.9999 28.9999L24.6499 24.6499"
                stroke="#ACB1C0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <input
              type="text"
              name=""
              id=""
              placeholder="Search Clubs by Name or by Type"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className={styles.suggestedClubsDetailsSection}>
        <div className={styles.suggestedClubsDetailsBoxAlignment}>
          <div className={styles.mobileviewShowContnetDesign}>
            <div className={styles.peopleFollowerSearchAlignment}>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                <path
                  d="M19 27C23.4183 27 27 23.4183 27 19C27 14.5817 23.4183 11 19 11C14.5817 11 11 14.5817 11 19C11 23.4183 14.5817 27 19 27Z"
                  stroke="#ACB1C0"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M28.9999 28.9999L24.6499 24.6499"
                  stroke="#ACB1C0"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <input
                type="text"
                name=""
                id=""
                placeholder="Search Stores by Name or by Type"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <p>Recommended Clubs</p>
            <span>Clubs you might be interested in</span>
          </div>
          <div className={styles.suggestedClubsAllDetailsAlignment}>
            <ClubItems search={search} APIcallType={APIcallType} />
          </div>
        </div>
      </div>
    </>
  )
}
