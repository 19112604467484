import React from 'react'
import { Modal } from 'antd'
import { useSelector } from 'react-redux'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAppDispatch } from 'src/store/store'
import NewLoginForm from 'src/components/LandingPage/Header/NewLoginForm'
import NewModalSlider from 'src/components/LandingPage/Header/NewModalSlider'
import NewForgotPassword from 'src/components/LandingPage/Header/NewForgotPassword'
import NewVerificationCode from 'src/components/LandingPage/Header/NewVerificationCode'
import NewCreateNewPassword from 'src/components/LandingPage/Header/NewCreateNewPassword'
import PasswordUpdated from 'src/components/LandingPage/Header/PasswordUpdated'
import NewSignupForm from 'src/components/LandingPage/Header/NewSignupForm'
import NewRegisterSuccess from 'src/components/LandingPage/Header/NewRegisterSuccess'
import { useAuth } from 'src/utils/auth'

export default function LoginModal() {
  const dispatch = useAppDispatch()
  const guestDetail = useSelector((state: any) => state?.guestAccountInfo?.data)
  const { user } = useAuth();

  const hideLoginModalHandler = () => {
    dispatch(guestAccountDetailSlice({ modal: '', isOpen: false, id: 0 }))
  }

  const signupLoginModal = () => {
    // return (
    //   <div className="new-login-right-side-alignment">
    //     <NewRegisterSuccess />
    //   </div>
    // )
    switch (guestDetail?.id?.modalFor) {
      case 'login':
        return (
          <div className="new-login-right-side-alignment">
            <NewLoginForm />
          </div>
        )
      case 'signup':
        return (
          <div className="new-login-right-side-alignment">
            <NewSignupForm />
          </div>
        )
      case 'registerSuccess':
        return (
          <div className="new-login-right-side-alignment">
            <NewRegisterSuccess />
          </div>
        )
      default:
        return ''
    }
  }

  const otherAuthModal = () => {
    switch (guestDetail?.id?.modalFor) {
      case 'forgot':
        return (
          <div className="new-forgot-password-right-side-alignment">
            <NewForgotPassword />
          </div>
        )
      case 'verification':
        return (
          <div className="new-forgot-password-right-side-alignment">
            <NewVerificationCode />
          </div>
        )
      case 'newPassword':
        return (
          <div className="new-forgot-password-right-side-alignment">
            <NewCreateNewPassword />
          </div>
        )
      case 'passwordUpdated':
        return (
          <div className="new-forgot-password-right-side-alignment">
            <PasswordUpdated />
          </div>
        )
      default:
        return ''
    }
  }

  return (
    <React.Fragment>
      <Modal
        title=""
        open={(user && user?.id) ? false : guestDetail?.isOpen && guestDetail.modal === 'authModal'}
        onOk={hideLoginModalHandler}
        onCancel={hideLoginModalHandler}
        width={1300}
        footer={null}
        className="login-modal"
        closable={false}
        maskStyle={{ zIndex: 999999999999999999 }}
        wrapClassName="login-modal-index"
      >
        <div className="login-wrapper">
          <div className="login-inner-wrapper">
            <div className="new-login-alignment">
              <div className="new-login-left-side-alignment mobile:hidden">
                <NewModalSlider />
              </div>
              {signupLoginModal()}
              {otherAuthModal()}
              {/* <div className="new-forgot-password-right-side-alignment"> */}
              {/* <NewForgotPassword /> */}
              {/* <NewVerificationCode /> */}
              {/* <NewCreateNewPassword  /> */}
              {/* <PasswordUpdated /> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

{
  /* <div className="mobile-modal-logo">
  <img src="/assets/landing/logo/mobile-logo.svg" alt="logo" />
</div> */
}
