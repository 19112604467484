import { gql } from '@apollo/client'

const GET_LIVESTREAM_URL = gql`
  query GetLivestreamUrl($uuid: String!) {
    getLivestreamUrl(uuid: $uuid) {
      message
      success
      url
    }
}
`
export default GET_LIVESTREAM_URL
