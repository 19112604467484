import React, { useEffect, useState } from 'react'
import styles from './CustomerDetailModal.module.scss'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { Modal } from 'antd'
import { useQuery } from '@apollo/client'
import OrderHistory from '../OrderHistory'
import { useAuth } from 'src/utils/auth'

export default function CustomerDetailModal() {
  const { user }: any = useAuth()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  // const { loading, error, data, refetch } = useQuery(GET_ALL_ORDERS_DATA_WITH_CUSTOMER_DETAILS, {
  //   variables: {
  //     user_id: getAllState?.lastModalData?.id,
  //     store_id: user?.store?.id,
  //   },
  // })

  const [AddNotesModal, setAddNotesModal] = useState(false)

  // useEffect(() => {
  //   refetch()
  // }, [])

  // const data: any = { getAllOrdersDataWithCustomerDetails: { data: '' } }

  return (
    <div className={styles.customerDetailsModalSection}>
      <div className={styles.customerDetailsModalBg}>
        <div className={styles.customerDetailsMainHeader}>
          <div className={styles.customerdDetailsMainProfileFlex}>
            <div className={styles.customerProfileImg}>
              <img
                src={
                  getAllState?.lastModalData?.id?.customer?.logo_image
                    ? getAllState?.lastModalData?.id?.customer?.logo_image
                    : 'https://bluejestic-media.s3.amazonaws.com/dummy-profile%20%281%29-1669122101773-764781589-1669182656605-3255156.jpg'
                }
                alt="customer details"
              />
            </div>
            <div className={styles.customerProfileNameAlignment}>
              <h5>
                {getAllState?.lastModalData?.id?.customer?.firstName &&
                getAllState?.lastModalData?.id?.customer?.lastName
                  ? getAllState?.lastModalData?.id?.customer?.firstName +
                    getAllState?.lastModalData?.id?.customer?.lastName
                  : 'Data Not found'}
              </h5>
              <div className={styles.customerMapAlignment}>
                <div>
                  <img src="/assets/seller/icons/map-icon.svg" alt="map icon" />
                </div>
                <p>
                  {getAllState?.lastModalData?.id?.customer?.city
                    ? `${getAllState?.lastModalData?.id?.customer?.city},`
                    : ''}
                  {getAllState?.lastModalData?.id?.customer?.state
                    ? getAllState?.lastModalData?.id?.customer?.state
                    : ''}
                  {getAllState?.lastModalData?.id?.customer?.city === '' &&
                    getAllState?.lastModalData?.id?.customer?.state === '' &&
                    'Not Found '}
                </p>
              </div>
              <div className={styles.customerMessageFlex}>
                <div className={styles.customerMessageButton}>
                  <button>Message</button>
                </div>
                <div className={styles.customerMessageMore}>
                  <img src="/assets/seller/icons/more-option-icon.svg" alt="more icon" />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.customerOverviewDetailsAlignment}>
            <div className={styles.overviewDetailsAlignment}>
              <h5>{getAllState?.lastModalData?.id?.total_orders ? getAllState?.lastModalData?.id?.total_orders : 0}</h5>
              <p>Purchased</p>
            </div>
            <div className={styles.overviewDetailsAlignment}>
              <h5>${getAllState?.lastModalData?.id?.total_spent ? getAllState?.lastModalData?.id?.total_spent : 0}</h5>
              <p>Total Spent</p>
            </div>
          </div>
        </div>
        <div className={styles.customerDetailsBodyAlignment}>
          <div className={styles.customerDetailsBodyGrid}>
            <div className={styles.customerDetailsBodyGridItem}>
              <div className={styles.customerDetailsInformationBox}>
                <div className={styles.customerDetailsInformationHeading}>
                  <h6>Customer Information</h6>
                </div>
                <div className={styles.customerDetailsInformationBody}>
                  <div className={styles.customerDetailsInformationDetails}>
                    <p>Name</p>
                    <span>
                      {' '}
                      {getAllState?.lastModalData?.id?.customer?.firstName &&
                      getAllState?.lastModalData?.id?.customer?.lastName
                        ? getAllState?.lastModalData?.id?.customer?.firstName +
                          getAllState?.lastModalData?.id?.customer?.lastName
                        : 'Data Not found'}
                    </span>
                  </div>
                  <div className={styles.customerDetailsInformationDetails}>
                    <p>Email</p>
                    <span>
                      {' '}
                      {getAllState?.lastModalData?.id?.customer?.email
                        ? getAllState?.lastModalData?.id?.customer?.email
                        : '-'}
                    </span>
                  </div>
                  <div className={styles.customerDetailsInformationDetails}>
                    <p>Phone</p>
                    <span>
                      {' '}
                      {getAllState?.lastModalData?.id?.customer?.phone
                        ? getAllState?.lastModalData?.id?.customer?.phone
                        : '-'}
                    </span>
                  </div>
                  <div className={styles.customerDetailsInformationDetails}>
                    <p>Shipping Address</p>
                    <span>
                      {getAllState?.lastModalData?.id?.customer?.shipping_address?.streetAddress
                        ? `${getAllState?.lastModalData?.id?.customer?.shipping_address?.streetAddress}, `
                        : '-'}
                      {getAllState?.lastModalData?.id?.customer?.shipping_address?.city &&
                        (`${getAllState?.lastModalData?.id?.customer?.shipping_address?.city}, ` || '')}
                      {getAllState?.lastModalData?.id?.customer?.shipping_address?.state &&
                        (`${getAllState?.lastModalData?.id?.customer?.shipping_address?.state}, ` || '')}
                      {getAllState?.lastModalData?.id?.customer?.shipping_address?.country &&
                        (`${getAllState?.lastModalData?.id?.customer?.shipping_address?.country}, ` || '')}
                      {getAllState?.lastModalData?.id?.customer?.shipping_address?.zipcode &&
                        (`${getAllState?.lastModalData?.id?.customer?.shipping_address?.zipcode} ` || '')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.customerDetailsBodyGridItem}>
              <div className={styles.customerDetailsNotesBoxAlignment}>
                <div className={styles.customerDetailsInformationHeading}>
                  <h6>Notes</h6>
                  <div className={styles.addNotesButton} onClick={() => setAddNotesModal(true)}>
                    <button>
                      <img src="/assets/seller/icons/plus-icon.svg" alt="plus" />
                      New Note
                    </button>
                  </div>
                </div>
                <div className={styles.customerDetailsBoxDetails}>
                  <div className={classNames(styles.customerDetailsNotesBox, styles.boxActive)}>
                    <p>
                      He is a cherished customer whose loyalty and kindness have captured our hearts. lorem ipsum dolor
                      sit amet consectetur adipiscing elitr
                    </p>
                    <div className={styles.customerDetailsFooterDetailsAlignment}>
                      <div className={styles.customerDetailsOptionAlignment}>
                        <div className={styles.customerDetailsOption}>
                          <img src="/assets/seller/icons/trash-red.svg" alt="trash icon" />
                        </div>
                        <div className={styles.customerDetailsOption}>
                          <img src="/assets/seller/icons/edit-grey.svg" alt="edit icon" />
                        </div>
                      </div>
                      <p>2/24/2023</p>
                    </div>
                  </div>
                  <div className={styles.customerDetailsNotesBox}>
                    <p>He is a cherished customer whose loyalty and kindness have captured our hearts.</p>
                    <div className={styles.customerDetailsFooterDetailsAlignment}>
                      <div className={styles.customerDetailsOptionAlignment}></div>
                      <p>2/24/2023</p>
                    </div>
                  </div>
                  <div className={styles.customerDetailsNotesBox}>
                    <p>He is a cherished customer whose loyalty and kindness have captured our hearts.</p>
                    <div className={styles.customerDetailsFooterDetailsAlignment}>
                      <div className={styles.customerDetailsOptionAlignment}></div>
                      <p>2/24/2023</p>
                    </div>
                  </div>
                  <div className={styles.customerFooterBOx}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <OrderHistory />
      </div>
      <Modal
        visible={AddNotesModal}
        centered
        width={790}
        footer={null}
        closable={false}
        onCancel={() => setAddNotesModal(false)}
        wrapClassName={'post-add-edit-modal'}
        className={styles.customerNotesModal}
        maskStyle={{ zIndex: 9999 }}
      >
        <div className={styles.customerAddNotesModal}>
          <div className={styles.customerAddNotesModalWrapper}>
            <div className={styles.customerAddNotesBoxAlignment}>
              <div className={styles.addNotesHeading}>
                <h6>New Note</h6>
              </div>
              <div className={styles.addNotesBoxAlignment}>
                <textarea readOnly>
                  He is a cherished customer whose loyalty and kindness have captured our hearts. lorem ipsum dolor sit
                  amet consectetur adipiscing elitr
                </textarea>
              </div>
              <div className={styles.addNotesButtonALignment}>
                <div className={styles.addNotesCancle} onClick={() => setAddNotesModal(false)}>
                  <button>Cancel</button>
                </div>
                <div className={styles.addNotesSave}>
                  <button>Save note</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
