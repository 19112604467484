import OpenAI from 'openai'

const openai = new OpenAI({
  apiKey: process.env.NEXT_PUBLIC_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true, // Note: In production, you should proxy these requests through your backend
})

export const generatePostContent = async (prompt: string, tone: string): Promise<string> => {
  try {
    console.log('OpenAI Config:', {
      apiKeyExists: process.env.NEXT_PUBLIC_OPENAI_API_KEY,
      prompt,
      tone,
    })

    const toneInstructions = {
      casual:
        'Write in a relaxed, friendly tone using everyday language. Include emojis naturally and keep it conversational.',
      professional:
        'Write in a polished, business-appropriate tone. Use professional language while remaining engaging. Use emojis sparingly.',
      enthusiastic:
        'Write with high energy and excitement. Use more emojis and exclamation marks to convey enthusiasm.',
      informative:
        'Write in an educational and clear tone. Focus on sharing knowledge while remaining accessible. Include relevant hashtags.',
      minimal: 'Write in a clean, concise style. Keep it simple and direct with minimal emojis.',
    }

    const completion = await openai.chat.completions.create({
      model: 'gpt-3.5-turbo',
      messages: [
        {
          role: 'system',
          content: `You are a helpful social media post writer. ${
            toneInstructions[tone] || toneInstructions.casual
          } Keep posts engaging and include relevant hashtags when appropriate.`,
        },
        {
          role: 'user',
          content: prompt,
        },
      ],
      temperature: 0.7,
      max_tokens: 200,
    })

    console.log('OpenAI Response:', completion.choices[0].message)
    return completion.choices[0].message.content || ''
  } catch (error) {
    console.error('Error generating post content:', error)
    throw new Error('Failed to generate post content. Please try again.')
  }
}
