import React, { useState } from 'react'
import BookCoverImg from '../../../../../../../public/assets/img/bookCover.png'
import SearchIcon from '../../../../../../../public/assets/icon/search.svg'
import star from '../../../../../../../public/assets/img/WomansCategory/star.png'
import aroww from '../../../../../../../public/assets/img/WomansCategory/aroww.png'
import BlackheartIcon from '../../../../../../../public/assets/icon/heart-black.svg'
import ShareIcon from '../../../../../../../public/assets/icon/share-black.svg'

export default function BookOfTheMonthModal() {
  return (
    <div className="best-ofthe-year-details-alignment">
      <div className="best-ofthe-year-modal-heading-alignment">
        <div className="best-ofthe-year-heading-label-alignment">
          <p>Book of The Month</p>
        </div>
        <div className="best-ofthe-year-search-alignment">
          <div className="best-ofthe-year-input-alignment">
            <img src={SearchIcon.src} alt="SearchIcon" />
            <input type="text" placeholder="Type keyword here..." />
          </div>
        </div>
      </div>
      <div className="best-ofthe-year-note-alignment">
        <p>
          Experience an unforgettable adventure with the hottest new release book of the year, filled with action and
          unforgettable characters - get it now!
        </p>
      </div>
      <div className="best-ofthe-year-tab-alignment">
        <ul>
          <li className="best-ofthe-year-active">All</li>
          <li>Fiction</li>
          <li>Nonfiction</li>
          <li>Kids</li>
        </ul>
      </div>
      <div className="best-ofthe-year-book-details-alignment">
        <div className="best-ofthe-year-book-grid-alignment">
          {[0, 1, 2, 3, 4, 5].map((_, index) => {
            return (
              <div className="best-ofthe-year-book-gridItem-alignment" key={index}>
                <div className="best-ofthe-year-book-alignment">
                  <div className="book-img-alignment">
                    <img src={BookCoverImg.src} alt="BookCoverImg" />
                  </div>
                  <div className="best-ofthe-year-name-alignment">
                    <h6>The Sweetness of Water</h6>
                    <p>Nathan Harris</p>
                    <div className="book-rate-alignment">
                      <p className="hike">
                        <span className="incris">
                          <img src={aroww.src} alt="incris" /> 200%
                        </span>
                      </p>
                      <div className="rating">
                        <span className="rating_point">4.8</span>
                        <div className="stars">
                          <img src={star.src} alt="star" />
                        </div>
                        <span className="num">(437)</span>
                      </div>
                    </div>
                    <div className="book-price-alignment">
                      <h5>$21.00</h5>
                      <span>
                        <del>$21.00</del>
                      </span>
                    </div>
                    <div className="like-share-alignment">
                      <div className="like-alignment">
                        <img src={BlackheartIcon.src} alt="BlackheartIcon" />
                        <span>235</span>
                      </div>
                      <div className="like-alignment">
                        <img src={ShareIcon.src} alt="ShareIcon " />
                        <span>235</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
