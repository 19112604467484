import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import GET_GROUPS_ONBOARDING from 'src/graphql/queries/getAllgroupsOnboarding'
import GET_GROUP_MEMBERS from 'src/graphql/queries/getGroupMember'
import GET_GROUPS from 'src/graphql/queries/getGroups'
import GET_JOINED_GROUP from 'src/graphql/queries/sellerQueries/getJoinedGroups'
import GET_MY_GROUPS from 'src/graphql/queries/sellerQueries/getMyGroups'
import client1 from 'src/utils/apolloClient'

interface fetchAllClub {
  loading: true | false
  error: {} | null
  called: true | false
  allPost: []
  groupPosts: []
  userPosts: []
}

const initialState = {
  loading: false,
  error: null,
  currentStep: '',
  allPost: [],
  groupPosts: [],
  userPosts: [],
  called: false,
} as fetchAllClub

export const fetchAllClubSliceData = createAsyncThunk('fetchClubs/fetchAllClubSliceData', async (post: any) => {
  const { data } = await client1.query({
    query: GET_GROUPS_ONBOARDING,
    variables: {
      limit: post?.limit,
      page: post?.page,
      type: post?.type,
    },
    fetchPolicy: 'network-only',
  })
  return { club: [...post?.prevPost, ...data?.getAllClubs?.data], count: data?.getAllClubs?.count }
})

// export const fetchSuggestedClubsData = createAsyncThunk('fetchClubs/fetchSuggestedClubsData', async (post: any) => {
//   const { data } = await client1.query({
//     query: GET_GROUPS,
//     fetchPolicy: 'network-only',
//   })
//   return data
// })

export const fetchJoinedClubsData = createAsyncThunk('fetchClubs/fetchJoinedClubsData', async (post: any) => {
  const { data } = await client1.query({
    query: GET_GROUPS_ONBOARDING,
    variables: {
      limit: post?.limit,
      page: post?.page,
      type: post?.type,
    },
    fetchPolicy: 'network-only',
  })
  return data?.getAllClubs?.data
})

export const fetchManageClubsData = createAsyncThunk('fetchClubs/fetchManageClubsData', async (post: any) => {
  const { data } = await client1.query({
    query: GET_GROUPS_ONBOARDING,
    variables: {
      limit: post?.limit,
      page: post?.page,
      type: post?.type,
    },
    fetchPolicy: 'network-only',
  })
  return data?.getAllClubs?.data
})

export const fetchClubMembers = createAsyncThunk('fetchClubs/fetchClubMembers', async (post: any) => {
  const { data } = await client1.query({
    query: GET_GROUP_MEMBERS,
    variables: {
      slug: post,
    },
    fetchPolicy: 'network-only',
  })
  return data?.getGroupMember?.data
})

// export const fetchUserPost = createAsyncThunk('fetchClubs/fetchUserPost', async (post: any) => {
//   const { data } = await client1.query({
//     query: MY_FEED_QUERY,
//     variables: {
//       user_id: post?.user_id,
//       page: post?.page,
//       limit: post?.limit,
//     },
//     fetchPolicy: 'network-only',
//   })
//   return [...post?.prevPost, ...data?.getAllPost?.data]
// })

const fetchAllClub = createSlice({
  name: 'fetchClubs',
  initialState,
  reducers: {
    setJoinedClub: (state: any, action) => {
      state.joinedClubs = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllClubSliceData.pending, (state, action) => {
      state.all_club_loading = true
    })
    builder.addCase(fetchAllClubSliceData.fulfilled, (state, action) => {
      state.all_club_loading = false
      state.allPost = action?.payload?.club
      state.club_total_count = action?.payload?.count
    })
    builder.addCase(fetchAllClubSliceData.rejected, (state, action) => {
      state.all_club_loading = false
      state.error = action.error.message
    })

    // builder.addCase(fetchSuggestedClubsData.pending, (state, action) => {
    //   state.loading = 'loading'
    // })
    // builder.addCase(fetchSuggestedClubsData.fulfilled, (state, action) => {
    //   state.loading = 'success'
    //   state.suggestedClub = action?.payload
    // })
    // builder.addCase(fetchSuggestedClubsData.rejected, (state, action) => {
    //   state.loading = 'failure'
    //   state.error = action.error.message
    // })

    builder.addCase(fetchJoinedClubsData.pending, (state, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchJoinedClubsData.fulfilled, (state, action) => {
      state.loading = 'success'
      state.joinedClubs = action?.payload
    })
    builder.addCase(fetchJoinedClubsData.rejected, (state, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })

    builder.addCase(fetchManageClubsData.pending, (state, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchManageClubsData.fulfilled, (state, action) => {
      state.loading = 'success'
      state.manageClubs = action?.payload
    })
    builder.addCase(fetchManageClubsData.rejected, (state, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })

    builder.addCase(fetchClubMembers.pending, (state, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchClubMembers.fulfilled, (state, action) => {
      state.loading = 'success'
      state.clubMembers = action?.payload
    })
    builder.addCase(fetchClubMembers.rejected, (state, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })
  },
})

// export var _actions = fetchAllClub.actions
export const { setJoinedClub } = fetchAllClub.actions
export default fetchAllClub.reducer
