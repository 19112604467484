// @ts-nocheck


import React, { useState } from 'react'
import FramerAnimationModal from 'src/utils/FramerAnimationModal'
import Slider from 'react-slick'
import { useAppDispatch } from 'src/store/store'
import { fetchProductShareOptions } from 'src/store/slices/productShareOptionsSlice'
import { useSelector } from 'react-redux'
import { useAuth } from 'src/utils/auth'
import getImageUrl from 'src/utils/getImageUrl'
import { Picker } from 'emoji-mart'
import { Spin } from 'antd'
import SHARE_FEED_POST_MUTATION from 'src/graphql/mutations/shareBookmarkPost'
import SHARE_POST_MUTATION from 'src/graphql/mutations/createSharePost'
import { useMutation, useQuery } from '@apollo/client'
// import MY_FEED_QUERY from 'src/graphql/queries/getMyFeed'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'
import Product from 'src/components/ProductPage/AllProduct/Product'
import GET_SINGLE_COLLECTION from 'src/graphql/queries/getSingleCollection'
import GET_SINGLE_COLLECTION_LIKE from 'src/graphql/queries/getCollectionSingleLike'
import { useRouter } from 'next/router'
import { fetchAllFeedSliceData } from 'src/store/slices/fetchAllFeedSlice'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <img src="/assets/icon/next-arrow.svg" alt="next arrow" />
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <img src="/assets/icon/prev-arrow.svg" alt="prev arrow" />
    </div>
  )
}

export default function ShareAllPostMyFeedPost() {
  const dispatch = useAppDispatch()
  const { user }: any = useAuth()
  const fetchProductShare = useSelector((state: any) => state?.productShareOptions?.data)
  const fetchProductImageResize = useSelector((state: any) => state?.productResizeData?.data)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const [postContent, setPostContent] = useState('')
  const [showEmojis, setShowEmojis] = useState(false)
  const [loading, setLoading] = useState(false)
  const router = useRouter()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)

  const colletionData = useQuery(GET_SINGLE_COLLECTION, {
    variables: {
      slug: fetchProductShare?.id?.detail?.slug,
      userSlug: fetchProductShare?.id?.detail?.user_slug,
    },
  })

  const likeData = useQuery(GET_SINGLE_COLLECTION_LIKE, {
    variables: {
      slug: fetchProductShare?.id?.detail?.slug,
      userSlug: fetchProductShare?.id?.detail?.user_slug,
    },
  })

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2.016,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  const handleCloseFeedModel = () => {
    dispatch(fetchProductShareOptions({ modal: '', isOpen: false, id: {} }))
  }

  const addEmoji = (e) => {
    let sym = e.unified.split('-')
    let codesArray = []
    sym.forEach((el) => codesArray.push('0x' + el))
    let emoji = String.fromCodePoint(...codesArray)
    setPostContent(postContent + emoji)
  }

  const postHandler = (data) => {
    setLoading(true)
    if (fetchProductShare?.id?.isGroupPost) {
      sharePostToClubs({
        variables: {
          content: postContent,
          share_post_for: 'GROUP',
          group_id: fetchProductShare?.id?.groupData?.id ? [fetchProductShare?.id?.groupData?.id] : [],
          collection_id: colletionData?.data?.getSingleCollection?.data?.id,
        },
        // refetchQueries: [{ query: MY_FEED_QUERY, variables: { page: 1, limit: 10 } }],
      }).then((response) => {
        if (response?.data !== undefined) {
          dispatch(fetchProductShareOptions({ modal: '', isOpen: false, id: {} }))
          dispatch(
            fetchProductImageResizeModal({
              modal: 'mobileProductDetailsShareModal',
              isOpen: false,
              isShareBookmark: false,
            }),
          )
          dispatch(
            fetchProductShareOptions({
              modal: 'sharedSuccessfullModel',
              isOpen: true,
            }),
          )
          setTimeout(() => {
            dispatch(
              fetchProductShareOptions({
                modal: '',
                isOpen: false,
              }),
            )
          }, 2000)
        }
      })
    } else {
      createSharePost({
        variables: {
          input: {
            content: postContent,
            collection_id: colletionData?.data?.getSingleCollection?.data?.id,
            share_post_for: 'POST',
          },
        },
        // refetchQueries: [{ query: MY_FEED_QUERY, variables: { page: 1, limit: 10 } }],
      }).then((response) => {
        if (response?.data !== undefined) {
          dispatch(fetchProductShareOptions({ modal: '', isOpen: false, id: {} }))
          dispatch(
            fetchProductImageResizeModal({
              modal: 'mobileProductDetailsShareModal',
              isOpen: false,
              isShareBookmark: false,
            }),
          )
          dispatch(
            fetchProductShareOptions({
              modal: 'sharedSuccessfullModel',
              isOpen: true,
            }),
          )
          setTimeout(() => {
            dispatch(
              fetchProductShareOptions({
                modal: '',
                isOpen: false,
              }),
            )
          }, 2000)
        } else {
          setLoading(false)
        }
      })
    }
  }

  const [createSharePost] = useMutation(SHARE_FEED_POST_MUTATION, {
    onCompleted: (res) => {
      setLoading(false)
      dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const [sharePostToClubs] = useMutation(SHARE_POST_MUTATION, {
    onCompleted: (res) => {
      setLoading(false)
      dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  return (
    <div className="dropdown-modal-details">
      <div className="dropdown-modal-wrapper">
        {/* <FramerAnimationModal> */}
        <div className="dropdown-modal-box-alignment">
          <div className="bg-white rounded-xl -m-16">
            <div className="modal-header flex justify-between items-center px-8 py-4">
              <p className="text-black font-medium text-sm">
                {fetchProductShare?.id?.isGroupPost
                  ? `Share with ${fetchProductShare?.id?.groupData?.name}`
                  : 'Share on My Feed'}
              </p>
              <button onClick={() => handleCloseFeedModel()}>
                <img src={'/assets/img/feed/ic_Close.svg'} />
              </button>
            </div>
            <div className="flex p-3 px-4 border-b border-gray-200">
              <img
                src={user?.logo_image ? getImageUrl(user?.logo_image) : '/assets/img/dummy-profile.svg'}
                className="object-cover create-post-alignment"
              />
              <div className="w-full">
                <textarea
                  className="border-0 w-full text-sm font-normal h-16 px-8 pt-3"
                  placeholder="Write something..."
                  value={postContent}
                  onChange={(e) => setPostContent(e.target.value)}
                  style={{ resize: 'none', minHeight: '40px' }}
                />
              </div>
            </div>

            <div className="shre-all-feed-details-modal-body">
              {fetchProductShare?.id?.shareData?.length > 2 ? (
                <div className="share-all-feed-details-box-alignment">
                  <Slider {...settings}>
                    {fetchProductShare?.id?.shareData?.map((item, index) => {
                      return <Product product={item?.products} showProduct={{}} storeData={{}} isForPost={true} index={index} />
                    })}
                  </Slider>
                </div>
              ) : (
                <div className="share-all-feed-details-box-alignment collection-product-grid-view">
                  {fetchProductShare?.id?.shareData?.map((b, index) => {
                    return <Product product={b?.products} showProduct={{}} storeData={{}} isForPost={true} index={index} />
                  })}
                </div>
              )}

              <div className="shared-profile-name-alignment">
                <div className="shared-profile-name-details">
                  <div className="shared-feed-profile-alignment">
                    <div className="bookMarkCollectionCardDetails">
                      {colletionData?.data?.getSingleCollection?.data?.product_images?.map((item, index) => {
                        return (
                          <div className="bookMarkCollectionCardImg" key={index}>
                            <img src={item ? item : '/assets/img/bookMark-img.png'} alt="bootmark-img" />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className="shared-all-profile-name">
                    <h6>{colletionData?.data?.getSingleCollection?.data?.name}</h6>
                    <div className="name-child-details-alignment">
                      <span>{colletionData?.data?.getSingleCollection?.data?.product_count} Products</span>
                      <p></p>
                      <span>{colletionData?.data?.getSingleCollection?.data?.likes} likes</span>
                    </div>
                  </div>
                </div>

                <div className="shared-like-alignment">
                  {likeData?.data?.getCollectionSingleLike?.isLike ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#ff0000">
                      <path
                        d="M7.5 4C4.46244 4 2 6.43905 2 9.44773C2 14.8955 8.5 19.848 12 21C15.5 19.848 22 14.8955 22 9.44773C22 6.43905 19.5375 4 16.5 4C14.6399 4 12.9954 4.91467 12 6.31469C11.0046 4.91467 9.36015 4 7.5 4Z"
                        stroke="#ff0000"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M7.5 4C4.46244 4 2 6.43905 2 9.44773C2 14.8955 8.5 19.848 12 21C15.5 19.848 22 14.8955 22 9.44773C22 6.43905 19.5375 4 16.5 4C14.6399 4 12.9954 4.91467 12 6.31469C11.0046 4.91467 9.36015 4 7.5 4Z"
                        stroke="#7A7E88"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </div>

            <div className="flex border-t p-8">
              <div className="post-footer-left flex justify-between w-full">
                <div className="flex items-center">
                  <div className="mr-8" onClick={() => setShowEmojis(!showEmojis)}>
                    <img src="/assets/img/feed/ic_Emoticon.svg" />
                  </div>
                  <div className="mr-8">
                    <img src="/assets/img/feed/ic_GIF.svg" />
                  </div>
                </div>
              </div>
              {loading ? (
                <Spin />
              ) : (
                <div className="bg-blue rounded-xl flex items-center px-4 " style={{ height: 30 }}>
                  <button className="text-12 text-white font-medium w-32" onClick={() => postHandler(postContent)}>
                    Share
                  </button>
                </div>
              )}
              {/* <div className="bg-blue rounded-xl flex items-center px-4 " style={{ height: 30 }}>
                <button className="text-12 text-white font-medium w-32" onClick={() => postHandler(postContent)}>
                  Share
                </button>
              </div> */}
            </div>
            {showEmojis && (
              <div className="p-8">
                <Picker onSelect={addEmoji} set="apple" />
              </div>
            )}
          </div>
        </div>
        {/* </FramerAnimationModal> */}
      </div>
    </div>
  )
}
