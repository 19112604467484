import React from 'react'

export default function MobileViewForwardMessageModal(props) {
  return (
    <>
      <div className="more-option-modal-forword-body-details-alignment">
        <div className="more-option-modal-forword-body-details-alignment-flx1">
          <h6>Forward to</h6>
          <div className="mobile-view-forword-search-alignment">
            <div>
              <img src="/assets/icon/search.svg" alt="search icon" />
            </div>
            <input type="text" placeholder="Search" />
          </div>
          <div className="mobile-view-forword-all-profile-details-alignment">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((_, index) => {
              return (
                <div className="mobile-view-forword-profile-name-alignment" key={index}>
                  <div className="mobile-view-profile-alignment">
                    <div className="mobile-view-profile-img-alignment">
                      <img src="/assets/img/mpi1.png" alt="profile" />
                    </div>
                    <p>Arlene McCoy</p>
                  </div>
                  <div className="mobile-view-input-checkBox-alignment">
                    <input type="checkbox" name="" id="" />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="mobile-view-forword-send-button">
          <button className="active-forword-button">Send</button>
        </div>
      </div>
    </>
  )
}
