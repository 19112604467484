import React from 'react'

export default function WelcomeMessage() {
  return (
    <div className="welcome-message-section">
      <div className="welcome-message-alignment">
        <div>
          <svg width="348" height="392" viewBox="0 0 348 392" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M235.879 308.661C246.926 298.297 252.622 282.77 246.687 269.637C238.047 282.853 223.824 293.261 207.333 298.435C200.91 300.45 193.524 302.113 190.031 307.196C187.857 310.358 187.699 314.319 188.678 317.901C189.658 321.482 191.674 324.776 193.671 328.026L193.682 328.944C209.315 324.981 224.831 319.025 235.879 308.661Z"
              fill="#ECEEF4"
            />
            <path
              d="M246.355 269.705C244.493 281.142 238.398 292.139 229.079 300.429C227.056 302.304 224.761 303.864 222.27 305.055C219.708 306.186 216.914 306.701 214.114 306.557C211.478 306.474 208.792 306.124 206.177 306.597C204.895 306.821 203.671 307.301 202.58 308.008C201.488 308.715 200.55 309.634 199.823 310.71C198.09 313.25 197.514 316.179 197.066 319.059C196.569 322.258 196.111 325.587 193.998 328.338C193.742 328.671 194.354 328.996 194.61 328.663C198.286 323.876 197.056 317.759 199.537 312.596C200.694 310.188 202.697 308.066 205.691 307.339C208.309 306.703 211.079 307.062 213.748 307.166C216.484 307.34 219.225 306.926 221.786 305.955C224.338 304.878 226.698 303.398 228.775 301.572C233.324 297.698 237.211 293.112 240.284 287.995C243.695 282.39 245.999 276.186 247.073 269.718C247.137 269.323 246.419 269.312 246.355 269.705Z"
              fill="white"
            />
            <path
              d="M232.272 297.872C234.17 299.044 236.386 299.598 238.614 299.456C240.841 299.315 242.969 298.484 244.701 297.081C245.029 296.8 244.539 296.345 244.211 296.627C242.6 297.936 240.619 298.709 238.545 298.837C236.471 298.966 234.408 298.443 232.648 297.342C232.276 297.105 231.903 297.636 232.272 297.872Z"
              fill="white"
            />
            <path
              d="M215.445 306.754C215.076 304.756 215.136 302.703 215.62 300.729C216.105 298.756 217.003 296.907 218.256 295.305C218.511 294.971 217.898 294.646 217.644 294.98C216.344 296.65 215.413 298.575 214.914 300.63C214.414 302.684 214.357 304.821 214.747 306.899C214.823 307.294 215.521 307.147 215.445 306.754Z"
              fill="white"
            />
            <path
              d="M242.785 282.222C242.002 281.944 241.303 281.471 240.755 280.848C240.206 280.225 239.827 279.472 239.652 278.662C239.581 278.266 238.884 278.414 238.954 278.807C239.151 279.701 239.571 280.531 240.175 281.221C240.779 281.91 241.548 282.437 242.41 282.752C242.492 282.793 242.585 282.805 242.674 282.787C242.764 282.768 242.845 282.72 242.903 282.65C242.927 282.615 242.943 282.575 242.95 282.534C242.958 282.492 242.955 282.449 242.944 282.409C242.933 282.368 242.913 282.33 242.885 282.298C242.858 282.266 242.824 282.24 242.785 282.222Z"
              fill="white"
            />
            <path
              d="M194.302 254.701C194.338 254.949 194.375 255.198 194.406 255.45C194.872 258.784 195.023 262.155 194.859 265.518C194.851 265.778 194.838 266.042 194.822 266.301C194.302 274.483 192.064 282.465 188.254 289.731C186.704 292.697 184.914 295.533 182.902 298.209C180.098 301.944 176.715 305.801 175.535 309.996C175.407 310.431 175.309 310.874 175.243 311.323L192.388 328.883C192.45 328.906 192.508 328.932 192.57 328.955L193.217 329.684C193.333 329.551 193.45 329.41 193.566 329.277C193.634 329.2 193.698 329.119 193.765 329.042C193.809 328.99 193.853 328.937 193.896 328.892C193.911 328.874 193.925 328.856 193.935 328.842C193.978 328.797 194.013 328.751 194.051 328.709C194.707 327.927 195.358 327.139 196.004 326.346C196.009 326.342 196.009 326.342 196.009 326.335C200.912 320.281 205.274 313.818 208.364 306.989C208.457 306.783 208.555 306.574 208.64 306.361C210.005 303.299 211.086 300.119 211.869 296.861C212.286 295.091 212.6 293.299 212.809 291.494C213.338 286.834 213.017 282.116 211.862 277.57C209.553 268.461 203.906 260.092 194.988 255.078C194.76 254.95 194.535 254.826 194.302 254.701Z"
              fill="#ECEEF4"
            />
            <path
              d="M194.083 254.929C200.549 265.032 203.33 276.989 201.654 288.466C201.35 290.992 200.599 293.445 199.435 295.709C198.139 297.984 196.25 299.868 193.967 301.16C191.803 302.468 189.415 303.588 187.657 305.329C186.792 306.176 186.14 307.216 185.756 308.362C185.371 309.509 185.264 310.73 185.444 311.925C185.826 314.857 187.403 317.495 189.049 320.029C190.875 322.842 192.825 325.739 193.051 329.037C193.078 329.436 193.793 329.376 193.765 328.977C193.372 323.239 188.136 318.996 186.527 313.581C185.776 311.054 185.9 308.316 187.785 306.175C189.433 304.302 191.894 303.145 194.098 301.837C196.351 300.597 198.266 298.827 199.674 296.68C200.966 294.481 201.825 292.057 202.206 289.537C203.142 283.955 203.057 278.249 201.955 272.697C200.701 266.247 198.227 260.094 194.665 254.565C194.442 254.216 193.861 254.582 194.083 254.929Z"
              fill="white"
            />
            <path
              d="M202.426 284.76C207.121 284.718 211.128 281.668 211.799 277.65C211.865 277.254 211.158 277.147 211.092 277.543C210.463 281.307 206.746 284.102 202.357 284.142C201.895 284.146 201.967 284.765 202.426 284.76Z"
              fill="white"
            />
            <path
              d="M195.165 300.623C193.541 299.333 192.197 297.728 191.214 295.905C190.231 294.081 189.631 292.078 189.448 290.016C189.419 289.617 188.705 289.677 188.734 290.076C188.928 292.222 189.558 294.305 190.585 296.201C191.612 298.096 193.015 299.764 194.708 301.103C195.044 301.378 195.499 300.897 195.165 300.623Z"
              fill="white"
            />
            <path
              d="M199.937 266.785C199.069 266.96 198.173 266.943 197.313 266.734C196.453 266.525 195.649 266.129 194.96 265.575C194.628 265.296 194.173 265.778 194.503 266.055C195.267 266.664 196.155 267.1 197.105 267.333C198.056 267.566 199.045 267.59 200.006 267.404C200.196 267.367 200.346 267.247 200.328 267.065C200.311 266.977 200.261 266.899 200.188 266.847C200.115 266.795 200.025 266.773 199.937 266.785Z"
              fill="white"
            />
            <path
              d="M154.575 328.42C133.236 342.96 112.02 346.65 94.8347 338.811C72.7815 328.751 59.4224 301.052 58.1831 262.818L59.4896 262.94C60.7113 300.642 73.7544 327.895 95.2741 337.712C111.998 345.342 132.756 341.675 153.724 327.387L154.575 328.42Z"
              fill="#ECEEF4"
            />
            <path
              d="M286.443 82.2209C284.342 105.295 278.355 129.626 268.966 153.102C257.356 182.133 241.493 207.703 223.092 227.049L222.071 226.161C240.383 206.909 256.174 181.452 267.736 152.541C278.185 126.414 284.4 99.2259 285.717 73.9268L287.022 74.1445C286.883 76.8164 286.69 79.5085 286.443 82.2209Z"
              fill="#ECEEF4"
            />
            <path
              d="M229.756 49.4267L228.487 49.5651C222.831 27.4148 211.869 12.0692 196.783 5.1872C177.605 -3.56107 153.891 2.31356 130.009 21.7292C106.006 41.2439 84.7566 72.0687 70.1762 108.527L68.9453 107.965C83.5898 71.3481 104.948 40.3743 129.086 20.7511C153.345 1.02874 177.543 -4.88897 197.222 4.08836C212.741 11.1676 223.99 26.8454 229.756 49.4267Z"
              fill="#ECEEF4"
            />
            <path
              d="M300.144 242.269C274.299 283.247 242.944 312.526 211.853 324.712L211.224 323.563C242.099 311.461 273.271 282.332 298.998 241.541L300.144 242.269Z"
              fill="#ECEEF4"
            />
            <path
              d="M340.019 150.462C339.593 155.139 339.047 159.873 338.382 164.664L337.085 164.351C342.06 128.597 340.295 95.8159 331.981 69.5433L333.23 69.335C340.482 92.2531 342.787 120.071 340.019 150.462Z"
              fill="#ECEEF4"
            />
            <path
              d="M70.0792 114.941C66.7619 121.906 63.6066 129.121 60.7009 136.387C43.9797 178.197 35.5062 220.897 36.1986 259.871L34.8918 259.733C34.1986 220.613 42.6966 177.766 59.47 135.825C62.3841 128.539 65.5484 121.304 68.8753 114.319L70.0792 114.941Z"
              fill="#ECEEF4"
            />
            <path
              d="M153.948 357.149C132.609 371.69 111.393 375.38 94.2078 367.541C72.1545 357.48 58.7955 329.782 57.5562 291.548L58.8626 291.67C60.0844 329.372 73.1275 356.625 94.6471 366.442C111.371 374.071 132.129 370.404 153.097 356.117L153.948 357.149Z"
              fill="#ECEEF4"
            />
            <path
              d="M285.816 110.949C283.715 134.024 277.728 158.355 268.339 181.83C256.729 210.861 240.866 236.432 222.465 255.777L221.444 254.89C239.756 235.638 255.547 210.18 267.109 181.269C277.558 155.143 283.773 127.954 285.09 102.655L286.395 102.873C286.256 105.545 286.064 108.237 285.816 110.949Z"
              fill="#ECEEF4"
            />
            <path
              d="M283.514 79.7509L282.245 79.8894C276.59 57.739 265.627 42.3934 250.541 35.5114C231.363 26.7631 207.65 32.6378 183.768 52.0534C159.764 71.5681 138.515 102.393 123.934 138.851L122.704 138.29C137.348 101.672 158.706 70.6986 182.844 51.0754C207.103 31.353 231.301 25.4353 250.98 34.4126C266.499 41.4918 277.749 57.1696 283.514 79.7509Z"
              fill="#ECEEF4"
            />
            <path
              d="M298.451 281.095C272.606 322.073 241.25 351.352 210.16 363.538L209.531 362.389C240.406 350.287 271.578 321.158 297.305 280.367L298.451 281.095Z"
              fill="#ECEEF4"
            />
            <path
              d="M339.392 179.189C338.966 183.865 338.42 188.599 337.754 193.39L336.458 193.077C341.432 157.324 339.668 124.542 331.354 98.2699L332.603 98.0615C339.854 120.98 342.16 148.797 339.392 179.189Z"
              fill="#ECEEF4"
            />
            <path
              d="M69.4518 143.669C66.1345 150.633 62.9792 157.849 60.0734 165.114C43.3523 206.925 34.8788 249.624 35.5712 288.599L34.2644 288.46C33.5712 249.34 42.0691 206.494 58.8426 164.553C61.7567 157.267 64.921 150.031 68.2478 143.047L69.4518 143.669Z"
              fill="#ECEEF4"
            />
            <path
              d="M153.413 151.096C156.438 146.476 161.377 142.846 166.877 142.539C172.378 142.232 178.989 146.9 181.703 151.704C186.669 160.49 184.514 168.939 177.675 175.05C176.746 174.736 173.503 173.788 172.559 173.485L171.96 172.407L171.221 173.062C162.769 173.903 154.603 172.966 148.242 166.69C149.033 161.227 150.388 155.716 153.413 151.096Z"
              fill="#2F2E41"
            />
            <path
              d="M176.076 212.769L175.077 222.005L175.665 234.116L148.747 230.929L146.217 212.769L149.44 212.8L176.076 212.769Z"
              fill="#FFB6B6"
            />
            <path
              d="M165.024 172.855C171.197 172.855 176.201 167.867 176.201 161.715C176.201 155.563 171.197 150.575 165.024 150.575C158.851 150.575 153.846 155.563 153.846 161.715C153.846 167.867 158.851 172.855 165.024 172.855Z"
              fill="#FFB7B7"
            />
            <path
              d="M152.638 182.411L159.035 178.586L171.827 177.312L183.134 183.05L183.021 200.579L175.848 218.76L145.785 214.935L144.643 208.522C144.643 208.522 136.328 197.392 145.283 191.017L152.638 182.411Z"
              fill="#556EE6"
            />
            <path
              d="M160.845 144.797C163.267 144.12 165.827 144.104 168.257 144.751C170.687 145.398 172.898 146.683 174.659 148.473C176.42 150.263 177.666 152.492 178.267 154.926C178.868 157.36 178.802 159.911 178.076 162.311C171.268 162.852 164.539 162.219 159.234 157.419L158.762 153.301L156.827 156.747C154.875 156.199 152.92 155.649 150.961 155.095C151.56 152.666 152.788 150.437 154.523 148.63C156.257 146.823 158.437 145.501 160.845 144.797Z"
              fill="#2F2E41"
            />
            <path
              d="M114.249 130.361C113.946 131.005 113.788 131.708 113.787 132.419C113.785 133.131 113.941 133.834 114.242 134.479C114.542 135.124 114.982 135.695 115.528 136.153C116.075 136.611 116.715 136.943 117.405 137.128L138.23 176.288L146.636 169.823L123.522 133.362C123.756 132.187 123.549 130.968 122.941 129.935C122.333 128.901 121.366 128.127 120.223 127.757C119.08 127.387 117.84 127.448 116.739 127.928C115.638 128.408 114.752 129.274 114.249 130.361Z"
              fill="#FFB6B6"
            />
            <path
              d="M148.41 189.52C148.41 189.52 146.48 191.225 143.735 186.516C141.538 182.745 130.581 165.861 129.03 160.008C128.179 160.032 127.344 159.773 126.657 159.272C125.969 158.771 125.469 158.056 125.234 157.24L137.004 152.688C139.563 153.962 138.853 155.729 138.204 156.644L142.587 163.196L157.168 179.503L149.826 188.311L148.41 189.52Z"
              fill="#556EE6"
            />
            <path
              d="M347.365 392H0.634855C0.46648 392 0.304995 391.933 0.185936 391.815C0.0668773 391.696 0 391.535 0 391.368C0 391.2 0.0668773 391.039 0.185936 390.921C0.304995 390.802 0.46648 390.736 0.634855 390.736H347.365C347.533 390.736 347.695 390.802 347.814 390.921C347.933 391.039 348 391.2 348 391.368C348 391.535 347.933 391.696 347.814 391.815C347.695 391.933 347.533 392 347.365 392Z"
              fill="#CCCCCC"
            />
            <path
              d="M264.355 38.8882C258.097 32.7408 249.74 29.348 241.359 27.1773C231.873 24.7202 222.063 23.2728 212.372 21.8842C202.272 20.437 192.131 19.3207 181.951 18.5354C171.785 17.7543 161.6 17.3071 151.396 17.1939C142.305 17.0924 133.152 17.0893 124.104 18.0946C115.583 19.0414 107.081 20.9415 99.3987 24.8423C92.56 28.2398 86.8145 33.4808 82.8151 39.9699C79.1383 46.0099 76.9522 53.2504 77.7124 60.3504C78.0689 63.8465 79.1612 67.2285 80.9179 70.2755C82.9771 73.6426 85.6014 76.6322 88.6762 79.1139C95.0242 84.456 102.614 88.3612 108.075 94.7103C109.517 96.3735 110.776 98.1855 111.832 100.115C112.625 101.579 113.517 102.839 114.992 103.693C116.309 104.441 117.818 104.785 119.33 104.68C120.843 104.575 122.29 104.026 123.489 103.102C125.19 101.772 126.412 99.7206 127.728 98.0323L132.319 92.1452C132.65 91.6174 133.035 91.1243 133.466 90.6734C133.776 90.4186 133.265 90.6431 133.366 90.5796C133.422 90.5446 133.52 90.564 133.581 90.5392C133.886 90.4518 134.2 90.3938 134.517 90.3659C137.868 89.7517 141.227 89.1772 144.593 88.6426C157.022 86.644 169.531 84.9953 182.092 84.1068C188.567 83.5915 195.065 83.437 201.557 83.6441C209.951 83.9864 218.23 85.6476 226.594 86.3642C235.598 87.1358 245.057 87.0292 253.542 83.5688C260.333 80.7992 266.639 76.1395 270.316 69.7036C272.061 66.6596 273.094 63.2629 273.34 59.7659C273.789 51.8339 269.919 44.3541 264.355 38.8882Z"
              fill="#ECEEF4"
            />
            <path
              d="M146.392 23.6934C144.791 23.6934 143.19 23.7018 141.591 23.7187C126.233 23.8812 110.361 25.1186 98.3163 33.0081C87.6254 40.0109 80.1326 55.6096 86.4736 66.7963C89.3855 71.9331 94.661 75.6722 100.246 79.6304C102.435 81.1822 104.7 82.7867 106.869 84.5148C111.609 88.2895 116.072 93.3554 118.64 99.0516L129.918 84.5876L132.048 84.1811C134.098 83.79 182.548 74.6341 208.351 77.6065C210.724 77.88 213.122 78.1946 215.442 78.4987C225.924 79.8732 235.825 81.1707 245.33 79.2548C255.253 77.2537 266.149 69.6159 266.798 59.4024C267.242 52.4101 262.677 44.7394 255.169 39.8604C247.578 34.9272 238.11 32.8905 229.063 31.227C201.793 26.2192 174.121 23.6975 146.392 23.6934Z"
              fill="white"
            />
            <path
              d="M233.914 45.9488H125.003C124.374 45.9488 123.772 45.7 123.327 45.2571C122.883 44.8142 122.633 44.2136 122.633 43.5872C122.633 42.9609 122.883 42.3602 123.327 41.9173C123.772 41.4744 124.374 41.2256 125.003 41.2256H233.914C234.542 41.2256 235.145 41.4744 235.589 41.9173C236.034 42.3602 236.283 42.9609 236.283 43.5872C236.283 44.2136 236.034 44.8142 235.589 45.2571C235.145 45.7 234.542 45.9488 233.914 45.9488Z"
              fill="#556EE6"
            />
            <path
              d="M233.914 60.4459H125.003C124.374 60.4459 123.772 60.1971 123.327 59.7542C122.883 59.3113 122.633 58.7106 122.633 58.0843C122.633 57.4579 122.883 56.8573 123.327 56.4144C123.772 55.9715 124.374 55.7227 125.003 55.7227H233.914C234.542 55.7227 235.145 55.9715 235.589 56.4144C236.034 56.8573 236.283 57.4579 236.283 58.0843C236.283 58.7106 236.034 59.3113 235.589 59.7542C235.145 60.1971 234.542 60.4459 233.914 60.4459Z"
              fill="#556EE6"
            />
            <path
              d="M137.695 74.941H125.003C124.374 74.941 123.772 74.6922 123.327 74.2493C122.883 73.8064 122.633 73.2057 122.633 72.5794C122.633 71.9531 122.883 71.3524 123.327 70.9095C123.772 70.4666 124.374 70.2178 125.003 70.2178H137.695C138.323 70.2178 138.926 70.4666 139.371 70.9095C139.815 71.3524 140.065 71.9531 140.065 72.5794C140.065 73.2057 139.815 73.8064 139.371 74.2493C138.926 74.6922 138.323 74.941 137.695 74.941Z"
              fill="#556EE6"
            />
            <path
              d="M118.018 131.587C123.58 131.587 128.089 127.093 128.089 121.549C128.089 116.006 123.58 111.512 118.018 111.512C112.455 111.512 107.946 116.006 107.946 121.549C107.946 127.093 112.455 131.587 118.018 131.587Z"
              fill="#ECEEF4"
            />
            <path
              d="M88.7133 244.982C90.158 242.818 89.3029 239.721 86.8035 238.063C84.3041 236.406 81.1068 236.817 79.6621 238.981C78.2175 241.145 79.0726 244.243 81.572 245.9C84.0714 247.557 87.2687 247.147 88.7133 244.982Z"
              fill="#ECEEF4"
            />
            <path
              d="M304.814 244.301C306.259 242.137 305.404 239.039 302.905 237.382C300.405 235.725 297.208 236.135 295.763 238.299C294.319 240.463 295.174 243.561 297.673 245.218C300.172 246.876 303.37 246.465 304.814 244.301Z"
              fill="#ECEEF4"
            />
            <path
              d="M223.236 166.186C224.681 164.022 223.826 160.924 221.326 159.267C218.827 157.609 215.63 158.02 214.185 160.184C212.74 162.348 213.596 165.446 216.095 167.103C218.594 168.76 221.792 168.35 223.236 166.186Z"
              fill="#ECEEF4"
            />
            <path d="M190.038 380.459H184.153L181.353 357.836H190.038V380.459Z" fill="#FFB6B6" />
            <path
              d="M193.144 390.621H188.923L188.17 386.65L186.24 390.621H175.047C174.51 390.622 173.988 390.451 173.555 390.134C173.123 389.817 172.804 389.371 172.644 388.86C172.485 388.35 172.493 387.802 172.668 387.296C172.843 386.79 173.175 386.354 173.617 386.05L182.556 379.897V375.883L191.958 376.442L193.144 390.621Z"
              fill="#2F2E41"
            />
            <path d="M156.98 380.459H151.095L148.294 357.836H156.98V380.459Z" fill="#FFB6B6" />
            <path
              d="M160.086 390.621H155.866L155.113 386.65L153.183 390.621H141.99C141.453 390.622 140.93 390.451 140.498 390.134C140.066 389.817 139.747 389.371 139.587 388.86C139.427 388.35 139.436 387.802 139.611 387.296C139.786 386.79 140.118 386.354 140.56 386.05L149.499 379.897V375.883L158.901 376.442L160.086 390.621Z"
              fill="#2F2E41"
            />
            <path
              d="M192.577 360.95H192.513L191.382 324.165C192.114 322.757 192.486 321.191 192.464 319.605C192.442 318.019 192.026 316.463 191.255 315.076L191.093 314.784L191.513 312.843C192.053 310.349 191.805 307.75 190.804 305.402L189.748 271.089C193.52 246.725 175.543 230.835 175.543 230.835H148.613L142.831 250.632C139.009 287.744 139.702 323.909 145.768 358.946L145.602 359.029C145.007 359.234 144.751 360.056 145.032 360.865C145.312 361.675 146.022 362.165 146.617 361.96L160.84 361.467C161.435 361.262 161.69 360.44 161.41 359.631C161.13 358.822 160.42 358.332 159.825 358.536L158.858 359.024L160.92 323.529L160.691 317.045L161.163 309.69L163.343 276.606L171.594 317.29L180.023 361.359C179.791 361.569 179.627 361.844 179.555 362.148C179.483 362.452 179.505 362.771 179.618 363.062C179.731 363.354 179.93 363.604 180.189 363.781C180.448 363.957 180.755 364.051 181.069 364.05H192.577C192.99 364.05 193.386 363.886 193.677 363.596C193.969 363.305 194.133 362.911 194.133 362.5C194.133 362.089 193.969 361.694 193.677 361.404C193.386 361.113 192.99 360.95 192.577 360.95Z"
              fill="#2F2E41"
            />
            <path
              d="M180.018 258.099C180.626 257.726 181.144 257.224 181.536 256.63C181.929 256.036 182.185 255.363 182.289 254.659C182.392 253.955 182.339 253.237 182.134 252.556C181.928 251.875 181.576 251.246 181.1 250.716L185.225 206.586L174.638 207.401L173.913 250.523C173.071 251.377 172.573 252.51 172.513 253.707C172.454 254.903 172.836 256.079 173.589 257.013C174.341 257.947 175.411 258.573 176.596 258.773C177.781 258.974 178.999 258.734 180.018 258.099Z"
              fill="#FFB6B6"
            />
            <path
              d="M182.741 183.228C182.741 183.228 185.294 182.856 184.998 188.294C184.761 192.648 185.886 219.485 183.964 225.228C184.689 225.673 185.245 226.345 185.544 227.14C185.843 227.934 185.868 228.805 185.616 229.616L173.268 226.993C171.83 224.529 172.128 221.998 173.173 221.588L173.112 213.713L170.383 187.755L180.892 183.464L182.741 183.228Z"
              fill="#556EE6"
            />
          </svg>
        </div>
        <div className="welcome-no-data-text-alignment">
          <h6>Welcome to Messaging!</h6>
          <p>Let's kick off a delightful conversation with your friend!</p>
        </div>
      </div>
    </div>
  )
}
