import { gql } from '@apollo/client'


export const ADD_SHIPPING_MUTATION = gql`
 mutation addShippingMethod ($input:addShippingInput){
  addShippingMethod(input:$input){
    success
    message
  }
}
`
