import { gql } from '@apollo/client'

const SEND_OTP_FOR_WITHDRAWAL = gql`
  mutation sendOTPForWithdrawal {
    sendOTPForWithdrawal {
      success
      message
      data
    }
  }
`
export default SEND_OTP_FOR_WITHDRAWAL
