import { gql } from '@apollo/client'

const SELLER_BANK_ONBOARDING_STATUS = gql`
  query sellerBankOnboardingStatus {
    sellerBankOnboardingStatus {
      success
      message
      data
    }
  }
`
export default SELLER_BANK_ONBOARDING_STATUS
// $limit: Int, $page: Int
//  limit: $limit, page: $page
