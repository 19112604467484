import React from 'react'
import styles from './AddProductFreeShipping.module.scss'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
export default function AddProductFreeShipping({ setProductData, productData }) {
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const dispatch = useAppDispatch()

  const handleChecked = async (e) => {
    setProductData({ ...productData, isFreeShipping: e.target.checked })

    await dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          mobileProductsData: {
            ...addProductDetailSlice?.id?.mobileProductsData,
            isFreeShipping: e.target.checked,
          },
        },
      }),
    )
  }
  return (
    <div className={styles.addProductModalFreeShippingSection}>
      <div className={styles.addProductModalFreeShippingBox}>
        <div className={styles.addProductModalFreeShippingHeadingAlignment}>
          <h4>Free Shipping</h4>
          <p>Do you provide free shipping for this item?</p>
        </div>
        <div className={styles.freeShippingoptionBgAlignment}>
          <div className={styles.freeShippingoptionBoxAlignment}>
            <img src="/assets/seller/icons/shipping-fast.svg" alt="shipping-fast" />
          </div>
          <label className="switch mb-0">
            <input type="checkbox" onClick={(e) => handleChecked(e)} checked={productData?.isFreeShipping} readOnly />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
    </div>
  )
}
