// @ts-nocheck

import React, { useState } from 'react'
import PinIcon from '../../../../../../public/assets/icon/feather.svg'
import RightArrow from '../../../../../../public/assets/icon/share-right-arrow.svg'
import LeftArrow from '../../../../../../public/assets/icon/share-left-arrow.svg'
import SHARE_POST_MUTATION from 'src/graphql/mutations/createSharePost'
// import MY_FEED_QUERY from 'src/graphql/queries/getMyFeed'
import Slider from 'react-slick'
import { useAuth } from 'src/utils/auth'
import { useMutation } from '@apollo/client'
import { notification } from 'antd'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { fetchAllFeedSliceData } from 'src/store/slices/fetchAllFeedSlice'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <img src={RightArrow.src} alt="RightArrow" />
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <img src={LeftArrow.src} alt="LeftArrow" />
    </div>
  )
}
export default function ShareOnMyFeed(props) {
  const { setIsshareOnMyFeed, isShareOnMyFeed, selectedProductId, setOpenMobileDropdown, setSelectedProductId } = props
  const { user } = useAuth()
  const dispatch = useAppDispatch()
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const [startX, setStartX] = useState(null)
  const [postContent, setPostContent] = useState('')
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)

  let productMedia = fetchMobileModal
    ? [...(fetchMobileModal?.lastModalData?.id?.video ?? []), ...(fetchMobileModal?.lastModalData?.id?.images ?? [])]
    : []

  function handleTouchStart(e) {
    setStartX(e.touches[0].pageX)
  }

  function handleTouchMove(e) {
    if (startX && startX + e.touches[0].pageY > 350) {
      setIsshareOnMyFeed(false)
    }
  }

  function handleTouchEnd() {
    setStartX(null)
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.04,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.06,
          slidesToScroll: 2,
        },
      },
    ],
  }

  const [createSharePost] = useMutation(SHARE_POST_MUTATION, {
    onCompleted: (res) => {
      // setIsshareOnMyFeed(false)
      // setOpenMobileDropdown(false)
      // setSelectedProductId(0)
      dispatch(
        fetchMobileModalData({
          allModalData: [],
          lastModalData: {},
        }),
      )
      dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const handleClose = () => {
    let datass = fetchMobileModal?.allModalData?.filter(
      (item) => item?.modal !== fetchMobileModal?.lastModalData?.modal,
    )
    dispatch(
      fetchMobileModalData({
        allModalData: datass,
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
  }

  const postHandler = () => {
    // if (type === 'GROUP') {
    //   createSharePost({
    //     variables: {
    //       content: data,
    //       store_name: storeData,
    //       share_post_for: 'GROUP',
    //       product_id: selectedProduct.id,
    //       group_id: groupId?.id ? [groupId?.id] : [],
    //     },
    //     refetchQueries: [{ query: MY_FEED_QUERY }],
    //   }).then((response) => {
    //     if (response?.data !== undefined) {
    //       setIsModalVisible(false)
    //     }
    //   })
    // } else if (type === 'POST') {
    createSharePost({
      variables: {
        content: postContent,
        product_id: fetchMobileModal?.lastModalData?.id?.id,
        store_name: fetchMobileModal?.lastModalData?.id?.storeData?.name,
        share_post_for: 'POST',
      },
      // refetchQueries: [{ query: MY_FEED_QUERY, variables: { page: 1, limit: 10 } }],
    })
    // .then((response) => {
    //   if (response?.data !== undefined) {

    //   }
    // })
    // }
  }

  return (
    <React.Fragment>
      <div className="share-me-feed-section">
        <div className="share-me-feed-modal">
          <div className="share-me-feed-heder-alignment">
            <div className="share-me-feed-heading-alignment">
              <h4>Share on My Feed</h4>
              <a onClick={() => handleClose()}>Cancel</a>
            </div>
          </div>
          <div className="share-me-feed-details-section">
            <div className="share-me-feed-search-alignment">
              <div className="share-me-profile-img-alignment">
                <img src={user?.logo_image} alt="ProfileImg" />
              </div>
              <div className="share-me-feed-search-input-alignment">
                <img src={PinIcon.src} alt="PinIcon" />
                <input type="text" placeholder="Write something..." onChange={(e) => setPostContent(e.target.value)} />
              </div>
            </div>
            <div className="share-me-feed-all-details-section">
              <div className="share-me-feed-design-section">
                <Slider {...settings}>
                  {productMedia?.map((url, index) => {
                    return (
                      <div className="share-me-feed-img-alignment" key={index}>
                        {url?.src?.includes('mp4') ? (
                          <video muted autoPlay loop style={{ objectFit: 'cover' }}>
                            <source src={url?.src} />
                          </video>
                        ) : (
                          <img src={url?.src} alt="ProductImg" />
                        )}
                      </div>
                    )
                  })}
                </Slider>
                <div className="share-me-feed-details-alignment">
                  <div>
                    <div className="share-me-feed-heading">
                      <h5>
                        {' '}
                        {fetchMobileModal?.lastModalData?.id?.title?.length > 34
                          ? fetchMobileModal?.lastModalData?.id?.title.substring(0, 34) + '...'
                          : fetchMobileModal?.lastModalData?.id?.title}
                      </h5>
                    </div>
                    <div className="share-me-feed-price-alignment">
                      <h6>$ {fetchMobileModal?.lastModalData?.id?.dis_price}</h6>
                      {fetchMobileModal?.lastModalData?.id?.dis_listPrice && (
                        <span>
                          <del>{fetchMobileModal?.lastModalData?.id?.dis_listPrice ? "$" : ''} {fetchMobileModal?.lastModalData?.id?.dis_listPrice}</del>
                        </span>
                      )}
                    </div>
                    {/* <div className="share-me-feed-delivery-shipping">
                      <div className="best-seller-alignment">
                        <h6>Best seller</h6>
                      </div>
                      <div className="dot-alignment">
                        <p></p>
                      </div>
                      <div className="delivery-menu-align">
                        <img src={'/assets/img/feed/shared-icon/truck-icon.svg'} />
                        <span>free</span>
                      </div>
                      <div className="dot-alignment">
                        <p></p>
                      </div>
                      <div className="arrow-menu-align">
                        <img src={'/assets/img/feed/shared-icon/side-icon-up-green.svg'} />
                        <span>200%</span>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="share-me-feed-footer-alignment" onClick={() => postHandler()}>
            <button>Share</button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
