import { useQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import GET_LIVESTREAM_URL from "src/graphql/queries/getLivestreamUrl";
import dynamic from 'next/dynamic';
const Player = dynamic(
    () => import('react-hls-player'),
    { ssr: false }
);

const LiveStreamRunner = ({ uuid, mediaUrl }) => {


    const videoRef = useRef(null);
    const [hlsUrl, setHlsUrl] = useState();

    const { data, loading: loading } = useQuery(GET_LIVESTREAM_URL, {
        variables: { uuid },
        onCompleted: (data) => {
            setHlsUrl(data.getLivestreamUrl.url)
        }
    })



    return (
        <div className="w-full h-full">
            {
                hlsUrl ?
                    <Player
                        muted
                        autoPlay
                        playerRef={videoRef}
                        src={hlsUrl}
                    />
                    :
                    <img src={mediaUrl} alt="channels banner" />
            }

        </div>
    );
};

export default LiveStreamRunner;