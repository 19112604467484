import React, { useState } from 'react'
import ImageModalForRating from '../../../../ProductDetail/ImageModalForRating'
import ImagePrevModalMobile from '../../../../ProductDetail/ImagePrevModalMobile'
import RatedImages from '../../../../ProductDetail/RatedImages'
import RateReview from '../../../../ProductDetail/RateReview'

export default function RateReviewModalMobile({ product }) {
  const [openImageModalMobile, setOpenImageModalMobile] = useState(false)
  const [openImageModal, setOpenImageModal] = useState(false)
  const [openImagePrev, setOpenImagePrev] = useState({ image: '', modal: false })

  const handleBack = (data) => {
    if (openImageModalMobile) {
      setOpenImageModalMobile(false)
    } else {
      if (openImagePrev.modal) {
        setOpenImageModalMobile(true)
        setOpenImagePrev({ image: '', modal: false })
      } else {
      }
    }
  }

  return (
    <div className="modal-container-main">
      {/* <div className="back-menu-align" style={{ padding: '16px 0 0 0' }}> */}
      {/* <div
          className="back-menu-main"
          onClick={() => handleBack(0)}
          style={openImageModalMobile ? { width: '140px', padding: '0 11px' } : {}}
        >
          <span>{openImageModalMobile ? '< Rate & Review' : openImagePrev.modal ? '< Backs' : '< Back'}</span>
        </div> */}
      {/* </div> */}
      <div>
        {/* FOR MOBILE */}
        {openImageModalMobile ? (
          <ImageModalForRating setOpenImagePrev={setOpenImagePrev} setOpenImageModalMobile={setOpenImageModalMobile} />
        ) : openImagePrev.modal ? (
          <ImagePrevModalMobile openImagePrev={openImagePrev} />
        ) : (
          <>
            <RateReview
              product={product}
              setOpenImageModal={setOpenImageModal}
              openImageModal={openImageModal}
              setOpenImageModalMobile={setOpenImageModalMobile}
            />
   
          </>
        )}
      </div>
    </div>
  )
}
