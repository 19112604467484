import React from 'react'
import styles from './CollectionComponent.module.scss'
import classNames from 'classnames'

import bookmarkNodatasliderimage from '/assets/icon/bookmarkslidernodtata.svg'

interface CollectionComponentProps {
  item: {
    name: string;
    image?: string;
    product_images?: string[];
    [key: string]: any;
  };
  index: number;
  handelOnclick: (item: any) => void;
}

export default function CollectionComponent({ item, index, handelOnclick }: CollectionComponentProps) {
  return (
    <>
      <div className={styles.bookMarkCollectionCardDetails}>
        <div className={styles.bookMarkCollectionCardDetails}>
          {/* {false ? ( */}
          {item?.product_images && item.product_images.length > 0 ? (
            item.product_images.slice(0, 3).map((x: string, index: number) => {
              return (
                <div className={styles.bookMarkCollectionCardImg} key={index}>
                  <img src={x} alt="bootmark-img" onClick={() => handelOnclick(item)} />
                  {/* <img src={bookmarkNodatasliderimage} alt="bootmark-img" /> */}

                  {!item?.isPrivate && (
                    <div className={styles.tagDesign}>
                      <button>Shared</button>
                    </div>
                  )}
                </div>
              )
            })
          ) : (
            <>
              <div className={styles.bookMarkCollectionCardImg} key={index}>
                {/* <img src={'/assets/icon/bookmarkslidernodtata.svg'} alt="bootmark-img" onClick={() => handelOnclick(item)} /> */}
              </div>
              <div className={styles.bookMarkCollectionCardImg} key={index}>
                {/* <img src={'/assets/icon/bookmarkslidernodtata.svg'} alt="bootmark-img" onClick={() => handelOnclick(item)} /> */}
              </div>
              {[...Array(1)]?.map((x, index) => {
                return (
                  <div
                    className={styles.bookMarkCollectionCardImg}
                    key={index}
                    style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}
                  >
                    {/* <img src={'/assets/icon/bookmarkslidernodtata.svg'} style={{ objectFit: 'contain' }} alt="bootmark-img" onClick={() => handelOnclick(item)} /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="134"
                      height="101"
                      viewBox="0 0 134 101"
                      fill="none"
                      style={{ height: 'auto', margin: '0 0 15px 0' }}
                      onClick={() => handelOnclick(item)}
                    >
                      <path
                        d="M89.2601 92.0142L16.6967 85.8472C16.6058 85.8398 16.5153 85.8298 16.4262 85.8171L52.388 69.406C53.0734 69.086 54.5507 69.0784 55.6877 69.389C56.0003 69.4646 56.2985 69.5904 56.5708 69.7615L78.5009 84.6895L79.5516 85.4039L89.2601 92.0142Z"
                        fill="#D5D8DE"
                      />
                      <path
                        d="M112.154 93.9606L49.3931 88.6266L62.0432 83.9165L62.9536 83.5771L79.4378 77.4389C80.5185 77.0366 83.0206 77.2185 84.2057 77.7509C84.2853 77.7871 84.3587 77.8244 84.4261 77.8628L112.154 93.9606Z"
                        fill="#C39FFF"
                      />
                      <path
                        d="M119.354 99.6901L6.98721 90.1402C5.27813 89.993 3.69722 89.1739 2.59132 87.8626C1.48542 86.5512 0.944838 84.8547 1.08817 83.1453L6.18548 23.1691C6.33268 21.46 7.1518 19.8791 8.46314 18.7732C9.77448 17.6673 11.4709 17.1268 13.1804 17.2701L125.547 26.82C127.256 26.9672 128.837 27.7863 129.943 29.0977C131.049 30.409 131.59 32.1055 131.446 33.8149L126.349 93.7911C126.202 95.5002 125.383 97.0811 124.071 98.187C122.76 99.2929 121.064 99.8335 119.354 99.6901ZM13.1471 17.6608C11.5413 17.5262 9.94769 18.034 8.71583 19.0729C7.48397 20.1117 6.71449 21.5968 6.5762 23.2023L1.4789 83.1785C1.34426 84.7843 1.85208 86.378 2.89095 87.6099C3.92983 88.8417 5.41492 89.6112 7.02042 89.7495L119.387 99.2994C120.993 99.4341 122.587 98.9262 123.819 97.8874C125.051 96.8485 125.82 95.3634 125.958 93.7579L131.056 33.7817C131.19 32.1759 130.683 30.5822 129.644 29.3504C128.605 28.1185 127.12 27.349 125.514 27.2108L13.1471 17.6608Z"
                        fill="#636773"
                      />
                      <path
                        d="M119.756 95.0016L7.38846 85.4516C6.92235 85.4115 6.49119 85.1881 6.18959 84.8304C5.88798 84.4728 5.74054 84.0101 5.77963 83.5439L10.8769 23.5677C10.9171 23.1016 11.1405 22.6704 11.4981 22.3688C11.8558 22.0672 12.3184 21.9198 12.7846 21.9589L125.152 31.5088C125.618 31.549 126.049 31.7724 126.351 32.13C126.652 32.4876 126.8 32.9503 126.761 33.4165L121.663 93.3927C121.623 93.8588 121.4 94.29 121.042 94.5916C120.684 94.8932 120.222 95.0406 119.756 95.0016ZM12.7514 22.3496C12.3888 22.3192 12.029 22.4339 11.7508 22.6685C11.4726 22.903 11.2989 23.2384 11.2677 23.6009L6.17035 83.5771C6.13995 83.9397 6.25461 84.2996 6.4892 84.5778C6.72378 84.8559 7.05913 85.0297 7.42166 85.0609L119.789 94.6108C120.151 94.6412 120.511 94.5266 120.789 94.292C121.068 94.0574 121.241 93.7221 121.272 93.3595L126.37 33.3833C126.4 33.0207 126.286 32.6608 126.051 32.3827C125.816 32.1045 125.481 31.9308 125.118 31.8995L12.7514 22.3496Z"
                        fill="#BEC1C9"
                      />
                      <path
                        d="M99.1805 40.3582C105.86 40.9258 111.734 35.9715 112.302 29.2925C112.869 22.6134 107.915 16.7388 101.236 16.1711C94.557 15.6035 88.6824 20.5578 88.1148 27.2368C87.5471 33.9159 92.5014 39.7905 99.1805 40.3582Z"
                        fill="#BEC1C9"
                      />
                      <path
                        d="M101.523 72.725C109.982 73.444 117.57 65.443 118.469 54.8545C119.369 44.2659 113.241 35.0993 104.782 34.3804C96.3225 33.6614 88.7353 41.6623 87.8354 52.2509C86.9355 62.8395 93.0636 72.0061 101.523 72.725Z"
                        fill="#ECEEF4"
                      />
                      <path
                        d="M103.521 49.7005L103.563 49.7041L100.63 93.1946L99.0309 93.0587L103.521 49.7005Z"
                        fill="#BEC1C9"
                      />
                      <path
                        d="M103.689 78.6711C103.728 78.764 103.729 78.8686 103.691 78.962C103.653 79.0554 103.58 79.1299 103.487 79.1692L101.192 80.1391L100.896 79.4389L103.191 78.469C103.284 78.4297 103.389 78.429 103.482 78.4669C103.575 78.5048 103.65 78.5783 103.689 78.6711Z"
                        fill="#BEC1C9"
                      />
                      <path
                        d="M57.4869 68.9818C65.9462 69.7008 73.5334 61.6999 74.4333 51.1113C75.3332 40.5227 69.2051 31.3562 60.7457 30.6372C52.2864 29.9183 44.6992 37.9192 43.7993 48.5078C42.8994 59.0963 49.0275 68.2629 57.4869 68.9818Z"
                        fill="#ECEEF4"
                      />
                      <path
                        d="M59.5961 75.6479C59.5568 75.555 59.4823 75.4815 59.3889 75.4436C59.2955 75.4057 59.1908 75.4065 59.098 75.4457L57.4957 76.1228L59.53 45.9619L59.4879 45.9583L54.9977 89.3166L56.5967 89.4525L57.4385 76.9723L59.3939 76.1459C59.4868 76.1066 59.5602 76.0321 59.5981 75.9387C59.636 75.8453 59.6353 75.7407 59.5961 75.6479Z"
                        fill="#BEC1C9"
                      />
                      <path
                        d="M84.8375 7.493C95.5564 8.40399 102.552 29.3414 101.412 42.7583C100.272 56.1752 90.6579 66.3132 79.939 65.4022C69.2201 64.4912 61.4551 52.8762 62.5953 39.4593C63.7356 26.0425 74.1186 6.58201 84.8375 7.493Z"
                        fill="#E1D0FF"
                      />
                      <path
                        d="M82.6226 73.653C82.598 73.5948 82.5621 73.5419 82.5171 73.4975C82.4721 73.4531 82.4187 73.4181 82.3601 73.3943C82.3016 73.3705 82.2388 73.3585 82.1756 73.3589C82.1124 73.3594 82.0498 73.3723 81.9916 73.3969L79.9613 74.2548L82.539 36.0378L82.4857 36.0332L76.7961 90.9728L78.8222 91.145L79.8888 75.3312L82.3665 74.2841C82.4248 74.2595 82.4776 74.2237 82.522 74.1786C82.5664 74.1336 82.6015 74.0802 82.6253 74.0216C82.6491 73.963 82.6611 73.9003 82.6606 73.8371C82.6601 73.7738 82.6472 73.7113 82.6226 73.653Z"
                        fill="#E1D0FF"
                      />
                    </svg>
                  </div>
                )
              })}
            </>
          )}
        </div>
      </div>
      <div
        className={classNames(styles.bookMarkCollectionProductNameDetailsAlignment, styles.centerBookMarkDetails)}
        onClick={() => handelOnclick(item)}
      >
        <h6>{item?.name}</h6>
        <div className={styles.bookMarkAllCollectionDetails}>
          <span>{item?.product_count} Products</span>
          <p></p>
          <span>{item?.likes} likes</span>
        </div>
      </div>
    </>
  )
}
