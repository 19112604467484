import React, { useEffect, useState } from 'react'
import ShippingCalculateModal from './ShippingCalculateModal'
import ShippingPriceModal from './ShippingPriceModal'
import GET_SHIPPING_METHOD from 'src/graphql/queries/getShippingMethod'
import client1 from 'src/utils/apolloClient'
import { useMutation } from '@apollo/client'
import UPDATE_SHIPPING_METHOD from 'src/graphql/mutations/updateShippingMethod'
import { notification } from 'antd'

interface ShippingDeleteModalProps {
  isOpen: boolean
  onClose: () => void
  getuspsservices: () => void
  value: ShippingMethodData | null
}
interface ShippingMethodData {
  __typename: 'getShippingMethodData'
  id: number
  name: string
  zipcode: string
  processing_time: string
  processing_time_type: string
  is_free_delivery: boolean
  is_free_international_delivery: boolean
  handling_fee: number
  is_active: boolean
  is_deleted: boolean
  delivery_options_type: string
  service_code: string[]
  min_days?: string
  max_days?: string
}
export default function ShippingDeleteModal({ getuspsservices, isOpen, onClose, value }: ShippingDeleteModalProps) {
  if (!isOpen) return null
  const [updateShippingMethod, { loading: updateLoading }] = useMutation(UPDATE_SHIPPING_METHOD)
  const onDelete = async () => {
    try {
      const { __typename, ...shippingMethodData } = value || {}

      const { data } = await updateShippingMethod({ variables: { input: { ...shippingMethodData, is_deleted: true } } })
      if (data?.updateShippingMethod?.success) {
        notification['success']({
          message: 'Deleted successfully',
          description: data?.updateShippingMethod?.message || 'Shipping method deleted successfully',
        })
        getuspsservices()
        onClose()
      }
    } catch (error) {
      notification['error']({
        message: 'Error',
        description: 'Something went wrong, Please try later',
      })
      onClose()
    }
  }
  return (
    <>
      <div
        className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
        style={{ background: '#00000080' }}
      >
        <div className="relative transform overflow-hidden rounded-lg bg-white dark:bg-dark-800 text-left shadow-xl transition-all w-full max-w-512 sm:my-8">
          <div className="p-6">
            <div className="text-center">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Delete Shipping Method</h3>
              <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                Are you sure you want to delete this shipping method? This action cannot be undone.
              </p>
            </div>
          </div>
          <div className="border-t border-gray-200 dark:border-dark-700 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button
              disabled={updateLoading}
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
              onClick={onDelete}
            >
              {updateLoading ? 'Deleting...' : 'Delete'}
            </button>
            <button
              disabled={updateLoading}
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white dark:bg-dark-800 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-200 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-dark-600 hover:bg-gray-50 dark:hover:bg-dark-700 sm:mt-0 sm:w-auto"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
