import { useEffect, useState } from 'react'
import GET_SHIPPING_METHOD from 'src/graphql/queries/getShippingMethod'
import client1 from 'src/utils/apolloClient'
import ShippingCalculateModal from './ShippingCalculateModal'
import ShippingCuspProfile from './ShippingCusProfile'
import ShippingCusProfileModal from './ShippingCusProfileModal'
import ShippingDeleteModal from './ShippingDeleteModal'
import { useAuth } from 'src/utils/auth'
interface ShippingMethodData {
  __typename: 'getShippingMethodData'
  id: number
  name: string
  zipcode: string
  processing_time: string
  processing_time_type: string
  is_free_delivery: boolean
  is_free_international_delivery: boolean
  handling_fee: number
  is_active: boolean
  is_deleted: boolean
  delivery_options_type: string
  service_code: string[]
}
export default function ShippingProfile() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { user, seller } = useAuth()
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedShipping, setSelectedShipping] = useState<ShippingMethodData | null>(null)
  const [uspsservices, setUspsServices] = useState([])
  const [datafetching, setDatafetching] = useState(false)

  const getuspsservices = async () => {
    const { data, loading } = await client1.query({
      query: GET_SHIPPING_METHOD,
      fetchPolicy: 'network-only',
      variables: {
        storeId: seller?.seller_detail?.id,
      },
    })

    setDatafetching(loading)
    setUspsServices(data?.getShippingMethod?.data)
  }

  useEffect(() => {
    getuspsservices()
  }, [])

  const handleDeleteShipping = (shipping: ShippingMethodData) => {
    setSelectedShipping(shipping)
    setIsDeleteModalOpen(true)
  }

  return (
    <>
      <div className="mt-6">
        <div className="space-y-6">
          <div className="bg-white dark:bg-dark-800 rounded-lg border border-gray-200 dark:border-dark-600 p-6 mx-8">
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div>
                  <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Example Profiles</h2>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Here are some common shipping profile configurations to help you get started.
                  </p>
                </div>
                <button
                  className="text-sm text-indigo-600 hover:text-indigo-500 font-medium"
                  onClick={() => setIsModalOpen(true)}
                >
                  Create Custom Profile
                </button>
              </div>
              <div className="divide-y divide-gray-200 dark:divide-dark-600">
                {datafetching ? (
                  <div>Loading...</div>
                ) : uspsservices.length > 0 ? (
                  uspsservices.map((uspsservice, key) => (
                    <div className={`${key === 0 ? 'pb-8' : 'py-8 border-t'}`} key={key}>
                      <div className="flex items-start justify-between">
                        <div className="flex-1">
                          <div className="flex items-center">
                            {/* <label className="Shipping-price-modal-toggle-switch">
                              <input type="checkbox" className="peer" />
                              <div className="Shipping-price-modal-switch-container">
                                <div className="Shipping-price-modal-toggle-circle"></div>
                              </div>
                            </label> */}
                            <div>
                              <h3 className="text-sm font-medium text-gray-900 dark:text-white">{uspsservice?.name}</h3>
                              <p className="text-sm text-gray-500 dark:text-gray-400">
                                {uspsservice?.description || 'Basic shipping within United States'}
                              </p>
                            </div>
                          </div>
                          <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                            <div className="flex items-center text-sm text-gray-600 dark:text-gray-300">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="lucide lucide-clock mr-2 flex-shrink-0"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <polyline points="12 6 12 12 16 14"></polyline>
                              </svg>
                              <span className="truncate">{uspsservice?.processing_time || '1-2'} business days</span>
                            </div>
                            <div className="flex items-center text-sm text-gray-600 dark:text-gray-300">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="lucide lucide-dollar-sign mr-2 flex-shrink-0"
                              >
                                <line x1="12" x2="12" y1="2" y2="22"></line>
                                <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                              </svg>
                              <span className="truncate">${uspsservice?.handling_fee || '2.99'}</span>
                            </div>
                            <div className="flex items-center text-sm text-gray-600 dark:text-gray-300">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="lucide lucide-package mr-2 flex-shrink-0"
                              >
                                <path d="m7.5 4.27 9 5.15"></path>
                                <path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z"></path>
                                <path d="m3.3 7 8.7 5 8.7-5"></path>
                                <path d="M12 22V12"></path>
                              </svg>
                              <span className="truncate">
                                {uspsservice?.service_code?.join(', ') ||
                                  'USPS Ground Advantage, USPS First Class Mail'}
                              </span>
                            </div>
                            <div className="flex items-center text-sm text-gray-600 dark:text-gray-300">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="lucide lucide-globe mr-2 flex-shrink-0"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <path d="M12 2a14.5 14.5 0 0 0 0 20 14.5 14.5 0 0 0 0-20"></path>
                                <path d="M2 12h20"></path>
                              </svg>
                              <span className="truncate">{uspsservice?.zipcode || 'United States'}</span>
                            </div>
                          </div>
                        </div>
                        <div className="ml-4 flex-shrink-0 flex items-center gap-5">
                          <button
                            className="text-sm text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 mr-2"
                            onClick={() => {
                              setSelectedShipping(uspsservice)
                              setIsEditModalOpen(true)
                            }}
                          >
                            Edit
                          </button>
                          <button
                            className="text-sm text-red-400 hover:text-red"
                            onClick={() => {
                              handleDeleteShipping(uspsservice)
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <ShippingCuspProfile />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ShippingCusProfileModal
        getuspsservices={getuspsservices}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
          setIsEditModalOpen(false)
          setSelectedShipping(null)
        }}
      />
      <ShippingDeleteModal
        value={selectedShipping}
        getuspsservices={getuspsservices}
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false)
          setSelectedShipping(null)
        }}
      />
      <ShippingCalculateModal
        getuspsservices={getuspsservices}
        value={selectedShipping}
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsModalOpen(false)
          setIsEditModalOpen(false)
          setSelectedShipping(null)
        }}
      />
    </>
  )
}
