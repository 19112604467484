import React from 'react'
import styles from './WithdrawTransactionDetailModal.module.scss'
export default function WithdrawTransactionDetailModal() {
  return (
    <div className={styles.withdrawTransactionDetailModalSection}>
      <div className={styles.withdrawTransactionDetailModalHeading}>
        <h4>Transaction Detail</h4>
      </div>

      <div className={styles.withdrawTransactionDetailModalBannerDetails}>
        <div className={styles.withdrawTransactionDetailModalIcon}>
          <img src="/assets/seller/icons/withdraw-icon.png" alt="withdraw icon" />
        </div>

        <div className={styles.withdrawDetails}>
          <h6>WITHDRAWAL</h6>
          <p>
            <b>ID</b> 27746833648{' '}
          </p>
          <div className={styles.pendingStatusAlignment}>
            <button>Pending</button>
          </div>
        </div>
      </div>

      <div className={styles.withdrawTransactionDetailsSlipAlignment}>
        <div className={styles.withdrawTransactionDetailsSlipChildDetails}>
          <p>Transfer Amount</p>
          <h6>$2,455</h6>
        </div>
        <div className={styles.withdrawTransactionDetailsSlipChildDetails}>
          <p>Processed</p>
          <div>
            <h6>Sunday, August 13, 2023</h6>
            <span>9:14 AM</span>
          </div>
        </div>
        <div className={styles.withdrawTransactionDetailsSlipChildDetails}>
          <p>Estimated Deposit </p>
          <h6>Tuesday, August 15, 2023</h6>
        </div>
        <div className={styles.withdrawTransactionDetailsSlipChildDetails}>
          <p>Withdrawal To</p>
          <div>
            <h6>Esther Howard</h6>
            <p>9:14 AM</p>
          </div>
        </div>
        <div className={styles.withdrawTransactionDetailsSlipChildDetails}>
          <p>Note</p>
          <div>
            <h6>-</h6>
          </div>
        </div>
      </div>
    </div>
  )
}
