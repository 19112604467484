import { gql } from '@apollo/client'

export default gql`
  query GET_STORE_BY_NAME($slug: String!) {
    getSingleStore(slug: $slug) {
      success
      message
      data {
        id
        shortDescription
        longDescription
        name
        # logo
        # cover_image
        logo_image
        banner_image
        product_count
        streetAddress
        city
        state
        postalCode
        createdAt
        isExist
        createdAt
        store_owner {
          id
          userName
          firstName
          lastName
          logo_image
          banner_image
          jobTitle
          isFollow
        }
        seller_detail {
          aboutUs
        }
      }
    }
  }
`