import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface manageBookmarkRename {
  loading: true | false
  error: {} | null
  called: true | false
  data: any
}

const initialState = {
  loading: false,
  error: null,
  data: {
    modal: '',
    isOpen: false,
    id: {
      showModel: false,
      isEditCollection: false,
      collectionName: '',
      collectionId: null
    },
  },
  called: false,
} as manageBookmarkRename

export const renameBookmarkManage = createAsyncThunk('fetchProductDetail/renameBookmarkManage', async (data: any) => {
  return data
})

const manageBookmarkRename = createSlice({
  name: 'fetchProductDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(renameBookmarkManage.pending, (state, action) => {
      state.loading = true
      state.error = null
      state.called = true
    })
    builder.addCase(renameBookmarkManage.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
      state.called = true
    })
    builder.addCase(renameBookmarkManage.rejected, (state, action) => {
      state.loading = false
      state.called = false
      state.error = action.error
      console.error('Error fetching cards!')
    })
  },
})

// export var _actions = manageBookmarkRename.actions
export default manageBookmarkRename.reducer
