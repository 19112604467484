import React, { useRef, useState } from 'react'
import { Tabs } from 'antd'
import Comments from '../StoreAndProductDetailModal/ProductDetailModal/TabSectionForMobile/Comments'
import Gallery from '../../../src/components/ProductDetail/Gallery'
import Details from '../../../src/components/ProductDetail/Description'
import Shipping from '../StoreAndProductDetailModal/ProductDetailModal/TabSectionForMobile/Shipping/Shipping'
import CustomerTraction from './CustomerTraction'
import RateReview from './RateReview'

export default function TabSection(props: any) {
  const { getSingleProduct, viewIndex, SetViewIndex } = props
  
  const { TabPane } = Tabs

  const [openImageModal, setOpenImageModal] = useState(false)

  function callback(key: any) {
    SetViewIndex(key)
  }

  return (
    <div className="product-detail-info-tabs rounded-xl bg-white ml-36 pt-8 text-gray-500 pb-20 tab-section-product">
      <Tabs defaultActiveKey="1" onChange={callback} activeKey={viewIndex.toString()}>
        <TabPane tab="Description" key="1">
          <Details description={getSingleProduct?.description} />
        </TabPane>
        <TabPane tab="Rate & Review" key="6">
          <RateReview
            product={getSingleProduct}
            setOpenImageModal={setOpenImageModal}
            openImageModal={openImageModal}
            setOpenImageModalMobile={{}}
          />
        </TabPane>
        {/* <TabPane tab="Gallery" key="4">
          <Gallery getSingleProduct={getSingleProduct} isGallery={true} />
        </TabPane> */}
        <TabPane tab="Comments" key="3" id="commentId">
          <Comments
            description={getSingleProduct?.description}
            product={getSingleProduct}
            comments={getSingleProduct?.comment}
            height={250}
          />
        </TabPane>
        <TabPane tab="Product Traction" key="2">
          <CustomerTraction getSingleProduct={getSingleProduct} />
        </TabPane>

        <TabPane tab="Shipping" key="5">
          <Shipping storeId={getSingleProduct?.store_id} />
        </TabPane>
      </Tabs>
    </div>
  )
}
