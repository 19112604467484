// import { gql } from '@apollo/client'

// const GET_PRODUCT_RECOMMENDATION = gql`
//   query GetProductRecommendation($slug: String!) {
//     getProductRecommendation(slug: $slug) {
//       data {
//         id
//         images {
//           src
//         }
//         title
//       }
//       message
//       success
//       total
//     }
//   }
// `
// export default GET_PRODUCT_RECOMMENDATION
import { gql } from '@apollo/client'

const GET_PRODUCT_RECOMMENDATION = gql`
  query GetProductRecommendation($type: String) {
    getProductRecommendation(type: $type) {
      data {
        id
        title
        slug
        like_count
        comment_count
        sharepost_count
        dis_price
        dis_listPrice
        total_variants
        total_inventory_quantity
        productLikes {
          id
        }
        id
        images {
          media_id
          src
          id
        }
        store {
          id
          name
        }
        attributes {
          id
          name
          value
        }
        categories {
          id
          category {
            id
            name
          }
          subCategory {
            id
            name
          }
          childSubCategory {
            id
            name
          }
        }
        likes {
          id
        }
        bookmark {
          id
          collection {
            id
            name
            user_id
            isPrivate
          }
        }
      }
      message
      success
      total
    }
  }
`
export default GET_PRODUCT_RECOMMENDATION
