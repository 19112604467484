import React from 'react'
import styles from './MobileProductDescriptionModal.module.scss'
export default function MobileProductDescriptionModal() {
  return (
    <div className={styles.mobileProductDescriptionModalSection}>
      <div className={styles.mobileProductHeadingAlignment}>
        <h6>Product Description</h6>
        <a>Close</a>
      </div>
      <div className={styles.mobileProductBodyDescriptionAlignment}>
        <textarea readOnly>
          Introducing the "Nike Men's Free Run 5.0 Road Running Shoes in Green" – the perfect blend of style, comfort,
          and performance to take your running experience to new heights. Step into the world of
        </textarea>
      </div>
      <div className={styles.mobileProductButtonAlignment}>
        <button>Save Change</button>
      </div>
    </div>
  )
}
