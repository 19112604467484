import React, { useEffect, useRef, useState } from 'react'
import styles from './AddProductModalImages.module.scss'
import ProductSizeSelectionModal from './ProductSizeSelectionModal'
import ProductImageUploder from './ProductImageUploder'
import ProductSizeDropdown from './ProductSizeDropdown'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'
import classNames from 'classnames'
import { Modal, notification } from 'antd'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
// import { ProductUploadImage } from './ProductUploadImage'
// import { DndProvider, useDrop } from 'react-dnd'
import { ItemTypes } from './ProductUploadImage/ItemTypes'
import { v4 as uuidv4 } from 'uuid'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'

const style = {
  width: 400,
  display: 'flex',
}

const imageArray = [
  {
    id: 1,
    text: 'https://images.pexels.com/photos/3791466/pexels-photo-3791466.jpeg?auto=compress&cs=tinysrgb&w=1600',
  },
  {
    id: 2,
    text: 'https://images.pexels.com/photos/3052361/pexels-photo-3052361.jpeg?auto=compress&cs=tinysrgb&w=1600',
  },
  {
    id: 3,
    text: 'https://images.pexels.com/photos/1485894/pexels-photo-1485894.jpeg?auto=compress&cs=tinysrgb&w=1600',
  },
  {
    id: 4,
    text: 'https://images.pexels.com/photos/1595437/pexels-photo-1595437.jpeg?auto=compress&cs=tinysrgb&w=1600',
  },
  {
    id: 5,
    text: 'https://images.pexels.com/photos/1749303/pexels-photo-1749303.jpeg?auto=compress&cs=tinysrgb&w=1600',
  },
  {
    id: 6,
    text: 'https://images.pexels.com/photos/2681741/pexels-photo-2681741.jpeg?auto=compress&cs=tinysrgb&w=1600',
  },
  {
    id: 7,
    text: 'https://images.pexels.com/photos/1457812/pexels-photo-1457812.jpeg?auto=compress&cs=tinysrgb&w=1600',
  },
]

export default function AddProductModalImages({ validation, setvalidation }) {
  const dispatch = useAppDispatch()
  const productRef: any = useRef()
  const [SizeDropdown, setSizeDropdown] = useState(false)
  const fetchProductImageResizeData = useSelector((state: any) => state?.productResizeData?.data)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const [sharedProductPreview, setSharedProductPreview] = useState(false)
  const [previewData, setPreviewData] = useState({ imageUrl: '' })
  const [ProductPreview, setProductPreview] = useState(false)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)

  const handleSelectSize = (ratio, size) => {
    let EditedImage = Boolean(
      fetchProductImageResizeData?.id?.media?.filter(
        (md) => fetchProductImageResizeData?.id?.ratio === fetchProductImageResizeData?.id?.ratio,
      )?.length,
    )
    if (fetchProductImageResizeData?.id?.ratio !== ratio && EditedImage) {
      notification['error']({
        message: 'Remove old Images',
        description: 'First remove the old ratio image and only then you can change the ratio',
        style: { marginTop: '50px' },
      })
    } else {
      let data = {
        ...fetchProductImageResizeData?.id,
        ratio: ratio,
        selectedSize: size,
        isSelected: true,
      }
      dispatch(
        fetchProductImageResizeModal({
          modal: '',
          isOpen: false,
          id: data,
        }),
      )
      setSizeDropdown(false)
    }
  }

  const handleRemove = (file, index) => {
    let removedMedia = fetchProductImageResizeData?.id?.media.filter((url, ind) => ind !== index)
    dispatch(
      fetchProductImageResizeModal({
        modal: '',
        isOpen: false,
        id: {
          ...fetchProductImageResizeData?.id,
          media: removedMedia,
          isDeleted: file?.isEdit
            ? [...fetchProductImageResizeData?.id?.isDeleted, file?.mediaId]
            : [...fetchProductImageResizeData?.id?.isDeleted],
        },
      }),
    )
    dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          productPreview: {
            ...addProductDetailSlice?.id?.productPreview,
            image: removedMedia,
          },
        },
      }),
    )
  }

  const handleEditImage = (file) => {
    dispatch(
      fetchProductImageResizeModal({
        modal: 'productResizeSlice',
        isOpen: true,
        id: {
          ...fetchProductImageResizeData?.id,
          selectedIndexofMediaForEdit: file?.id,
          // media:
        },
      }),
    )
  }

  const handleEditImageMobile = (file, index) => {
    dispatch(
      fetchProductImageResizeModal({
        modal: 'productResizeSlice',
        isOpen: false,
        id: {
          ...fetchProductImageResizeData?.id,
          selectedIndexofMediaForEdit: file?.id,
          index: index,
          file: file,
          // media:
        },
      }),
    )
    dispatch(
      fetchMobileModalData({
        allModalData: [
          ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
          {
            modal: 'adjustImageMobile',
            isOpen: true,
            id: 0,
            transform: 7,
          },
        ],
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (SizeDropdown && productRef.current && !productRef.current.contains(e.target)) {
        setSizeDropdown(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [SizeDropdown])

  const handleReorder = (bool) => {
    dispatch(
      fetchProductImageResizeModal({
        modal: '',
        isOpen: false,
        id: {
          ...fetchProductImageResizeData?.id,
          isReorder: bool,
          // media:
        },
      }),
    )
  }

  const handlePreview = (image) => {
    setSharedProductPreview(true)
    setPreviewData(image)
  }

  const handleCancel = () => {
    setSharedProductPreview(false)
  }

  const [cards, setCards] = useState(imageArray)

  // const findCard = useCallback(
  //   (id) => {
  //     const card = cards.filter((c) => `${c.id}` === id)[0]
  //     return {
  //       card,
  //       index: cards.indexOf(card),
  //     }
  //   },
  //   [cards],
  // )

  // const moveCard = useCallback(
  //   (id, atIndex) => {
  //     const { card, index } = findCard(id)
  //     setCards(
  //       update(cards, {
  //         $splice: [
  //           [index, 1],
  //           [atIndex, 0, card],
  //         ],
  //       }),
  //     )
  //   },
  //   [findCard, cards, setCards],
  // )

  const containerClasses = classNames(styles.addProductAlignment, {
    [styles.fiveFourWidth]: fetchProductImageResizeData?.id?.ratio === '5 : 4',
    [styles.sixNineWidth]: fetchProductImageResizeData?.id?.ratio === '6 : 9',
    [styles.threeTwoWidth]: fetchProductImageResizeData?.id?.ratio === '3 : 2',
  })

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
  }

  const getCroppedImg = (imageSrc, cropWidth, cropHeight) => {
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.src = imageSrc
      image.onload = () => {
        const canvas = document.createElement('canvas')
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        const width = cropWidth * scaleX
        const height = cropHeight * scaleY

        canvas.width = cropWidth
        canvas.height = cropHeight
        const ctx = canvas.getContext('2d')
        const maxSize = Math.max(image.width, image.height)
        const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

        // set each dimensions to double largest dimension to allow for a safe area for the
        // image to rotate in without being clipped by canvas context
        canvas.width = safeArea
        canvas.height = safeArea

        // translate canvas context to a central location on image to allow rotating around the center.
        ctx.translate(safeArea / 2, safeArea / 2)
        ctx.rotate(getRadianAngle(0))
        ctx.translate(-safeArea / 2, -safeArea / 2)

        // draw rotated image and store data.
        ctx.fillStyle = '#DCDCDC'
        ctx.fillRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5)
        const data = ctx.getImageData(0, 0, safeArea, safeArea)

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = cropWidth
        canvas.height = cropHeight

        let startfrom = image.width / 2 > cropWidth ? image.width / 2 - cropWidth / 2 : 0

        // paste generated rotate image with correct offsets for x,y crop values.
        ctx.putImageData(
          data,
          Math.round(0 - safeArea / 2 + image.width * 0.5 - startfrom),
          Math.round(0 - safeArea / 2 + image.height * 0.5 - 0),
        )

        canvas.toBlob((blob) => {
          const reader = new FileReader()
          reader.onloadend = () => {
            const base64String = reader.result
            resolve(base64String)
          }
          reader.readAsDataURL(blob)
        }, 'image/jpeg')
      }

      image.onerror = (error) => {
        reject(error)
      }
    })
  }

  const OnChangeHandler = async (e) => {
    e.preventDefault()
    const files = e.target.files
    const images = Array.from(files).map((file: any) => file)
    let realImage = []
    setvalidation({ ...validation, images: '' })
    try {
      const croppedImages = await Promise.all(
        images.map(async (image, index) => {
          const reader: any = new FileReader()
          reader.onloadend = () => {
            const base64String = reader.result
            realImage = [...realImage, base64String]
          }
          reader.readAsDataURL(image)
          const croppedImage: any = await getCroppedImg(
            URL.createObjectURL(image),
            fetchProductImageResizeData?.id?.selectedSize?.width,
            fetchProductImageResizeData?.id?.selectedSize?.height,
          )
          return croppedImage
        }),
      )
      let addOtherData = croppedImages?.map((img, index) => {
        return {
          newfile: realImage[index],
          originalFile: files[index]?.type?.includes('video') ? files[index] : null,
          oldFile: {
            baseURL: img,
            zoom: 1,
            rotate: 0,
            crop: { width: 0, height: 0 },
          },
          isEdit: false,
          ratio: fetchProductImageResizeData?.id?.ratio,
          id: uuidv4(),
        }
      })
      await dispatch(
        fetchProductImageResizeModal({
          modal: '',
          isOpen: false,
          id: {
            ...fetchProductImageResizeData?.id,
            prevImages: fetchProductImageResizeData?.id?.prevImages,
            prevVideos: fetchProductImageResizeData?.id?.prevVideos,
            media: [...fetchProductImageResizeData?.id?.media, addOtherData],
          },
        }),
      )
    } catch (error) {
      console.error('Error cropping images:', error)
    }
  }

  // const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }))

  return (
    <>
      <div className={styles.addProductImagesDetailsAlignment}>
        <div className={styles.addProductImagesDetailsBox}>
          <div
            className={styles.addProductHeading}
            // onClick={() => setProductPreview(true)}
          >
            <h4>Product Image</h4>
            <span>*</span>
            <span>{validation?.images}</span>
          </div>
          <div className={styles.addProductNotesALignment}>
            <p>You are allowed to upload 15 max images</p>
          </div>
          <div className={styles.addProductViewFilterAlignment}>
            <div className={styles.totalImagesNumber}>
              <p>
                {fetchProductImageResizeData?.id?.media?.length > 0
                  ? fetchProductImageResizeData?.id?.media?.length
                  : 0}{' '}
                {/* of 15  */}
                images
              </p>
            </div>
            {fetchProductImageResizeData?.id?.isReorder ? (
              <div className={styles.recorderProductDetailsAlignment} onClick={() => handleReorder(false)}>
                <span className={styles.blue}>Done</span>
              </div>
            ) : (
              <div className={styles.filterRightSideAlignment}>
                <div className={styles.filterRelativeAlignment} onClick={() => setSizeDropdown(!SizeDropdown)}>
                  <div className={styles.imagesSizeAlignment}>
                    <p>{`${fetchProductImageResizeData?.id?.ratio}`} </p>
                    <div className={styles.downArrow}>
                      <img src="/assets/seller/icons/grey-down-arrow.svg" alt="down arrow" />
                    </div>
                  </div>
                  {SizeDropdown && (
                    <div ref={productRef}>
                      <ProductSizeDropdown handleSelectSize={handleSelectSize} />
                    </div>
                  )}
                </div>
                <div className={styles.recorderProductDetailsAlignment}>
                  <span onClick={() => handleReorder(true)} className={styles.blue}>
                    Reorder
                  </span>
                  <div className={styles.recorderIconAlignment}>
                    <div>
                      <img src="/assets/seller/icons/Prev-Button.svg" alt="PrevButtonIcon" />
                    </div>
                    <div>
                      <img src="/assets/seller/icons/Next-Button.svg" alt="NextButtinIcon" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={styles.addProductDetailsAlignment}>
            {fetchProductImageResizeData?.id?.isSelected ? (
              !fetchProductImageResizeData?.id?.isReorder && (
                <ProductImageUploder validation={validation} setvalidation={setvalidation} />
              )
            ) : (
              <ProductSizeSelectionModal handleSelectSize={handleSelectSize} />
            )}
            {Boolean(fetchProductImageResizeData?.id?.media?.length) && (
              <div className={styles.productScrollImage}>
                <div
                  className={styles.overFlowDetailsAlignment}
                  style={fetchProductImageResizeData?.id?.isReorder ? { width: '100%' } : {}}
                >
                  {fetchProductImageResizeData?.id?.media?.map((file, index) => {
                    return (
                      <div className={containerClasses} key={index}>
                        {file?.originalFile ? (
                          <video>
                            <source src={file?.originalFile} />
                          </video>
                        ) : (
                          <img src={file?.oldFile?.baseURL !== '' ? file?.oldFile?.baseURL : file?.newfile} />
                          // <></>
                        )}
                        <div className={styles.hoverDetailsAlignment}>
                          <div className={styles.imgOptionAlignment}>
                            <div className={styles.optionBoxAlignment} onClick={() => handleRemove(file, index)}>
                              <img src="/assets/seller/icons/trash.svg" alt="TranceIcon" />
                            </div>
                            <div className={styles.optionBoxAlignment} onClick={() => handlePreview(file)}>
                              <img src="/assets/seller/icons/maximize.svg" alt="MaxIMizeIcon" />
                            </div>
                            {!file?.originalFile && !file?.isEdit && (
                              <div className={styles.optionBoxAlignment} onClick={() => handleEditImage(file)}>
                                <img src="/assets/seller/icons/edit.svg" alt="edit" />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      // <div ref={drop} style={style}>
                      //   {imageArray?.map((file: any, id: any) => (
                      //     <ProductUploadImage
                      //       key={file.id}
                      //       id={`${file.id}`}
                      //       file={file.text}
                      //       moveCard={moveCard}
                      //       findCard={findCard}
                      //       handleRemove={handleRemove}
                      //       handlePreview={handlePreview}
                      //       handleEditImage={handleEditImage}
                      //     />
                      //   ))}
                      // </div>
                    )
                  })}
                  {/* </DndProvider> */}
                </div>
              </div>
            )}
          </div>
          <div
            className={styles.mobileViewAddProductAlignment}
            onClick={() =>
              dispatch(
                fetchMobileModalData({
                  allModalData: [
                    ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                    {
                      modal: 'addSelectImageRatio',
                      isOpen: true,
                      id: 0,
                      transform: 70,
                    },
                  ],
                  lastModalData: fetchMobileModal?.lastModalData,
                }),
              )
            }
          >
            <input
              type="text"
              placeholder="Select image ratio"
              disabled={fetchMobileModal?.lastModalData?.isOpen ? true : false}
            />
            <div className={styles.iconAlignment}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M4 7L8 11L12 7" stroke="#636773" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>

          <div className={styles.uploadedButtonAlignment}>
            <button className={styles.activeButton}>
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                <path
                  d="M14.502 10V12.6667C14.502 13.0203 14.3615 13.3594 14.1114 13.6095C13.8614 13.8595 13.5222 14 13.1686 14H3.83529C3.48166 14 3.14253 13.8595 2.89248 13.6095C2.64243 13.3594 2.50195 13.0203 2.50195 12.6667V10"
                  stroke="#B1BCF4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.8346 5.33333L8.5013 2L5.16797 5.33333"
                  stroke="#B1BCF4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path d="M8.5 2V10" stroke="#B1BCF4" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              Upload Images
              <input type="file" onChange={(e) => OnChangeHandler(e)} multiple={true} />
            </button>
          </div>
          <div className={styles.productUploadedImgesDetailsAlignment}>
            <div className={styles.productUploadedImgesDetailsHeading}>
              <h6>{fetchProductImageResizeData?.id?.media?.length} of 15 images</h6>
              <a>Reorder</a>
            </div>
            <div className={styles.productUploadedAllImgesAlignment}>
              {fetchProductImageResizeData?.id?.media?.map((file, index) => {
                return (
                  <div
                    className={styles.productUploadImg}
                    onClick={() => handleEditImageMobile(file, index)}
                    key={index}
                  >
                    {file?.originalFile ? (
                      <video>
                        <source src={file?.originalFile} />
                      </video>
                    ) : (
                      <img src={file?.oldFile?.baseURL !== '' ? file?.oldFile?.baseURL : file?.newfile} />
                    )}
                    <div className={styles.productSelectedImg}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path
                          d="M4.16797 7.5L1.66797 10L4.16797 12.5"
                          stroke="#F8F8F8"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.832 7.5L18.332 10L15.832 12.5"
                          stroke="#F8F8F8"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2 10H6"
                          stroke="#F8F8F8"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14 10H18"
                          stroke="#F8F8F8"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <Modal
          open={ProductPreview}
          className="my-auto rounded-2xl mt-64"
          wrapClassName="post-add-edit-modal"
          onCancel={handleCancel}
          footer={null}
          closable={false}
          maskStyle={{ zIndex: 999999999 }}
          centered
        >
          <div className="bg-white rounded-0 -m-8" style={{ minWidth: '100%', height: '100%' }}>
            <img src="/assets/img/profile-setup.png" className="w-full h-full rounded-2xl" />
          </div>
        </Modal>
      </div>
      {/* <Modal
        open={sharedProductPreview}
        className="my-auto rounded-2xl"
        wrapClassName="post-add-edit-modal"
        onCancel={handleCancel}
        footer={null}
        closable={false}
        maskStyle={{ zIndex: 9999 }}
        centered
      >
        <div className="bg-white rounded-0 -m-8" style={{ minWidth: '100%', height: '100%' }}>
          {previewData?.imageUrl.includes('mp4') ? (
            <video controls className="w-full h-full rounded-2xl" autoPlay muted>
              <source src={previewData?.imageUrl} id="video_here" />
              Your browser does not support HTML5 video.
            </video>
          ) : (
            <img src={previewData?.imageUrl} className="w-full h-full rounded-2xl" />
          )}
        </div>
      </Modal> */}
    </>
  )
}
