import React, { useEffect, useRef, useState } from 'react'
import MessageSidebar from './MessageSidebar'
import MessageChatBox from './MessageChatBox'
import GroupMessageSidebar from './GroupMessageSidebar'
import { useStateContext } from 'src/utils/state'
import ManageGroupMember from './ManageGroupMember'

export default function MessageModal() {
  const screenWidth = window.innerWidth
  const screenHeight = window.innerHeight

  const houreRef: any = useRef()
  const { selectedChat } = useStateContext()
  const [isManageGroup, setIsManageGroup] = useState(false)

  const handleOpen = (boolean) => {
    setIsManageGroup(boolean)
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isManageGroup && houreRef.current && !houreRef.current.contains(e.target)) {
        setIsManageGroup(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isManageGroup])

  return (
    <div className="message-modal-section">
      <div className="message-modal-alignment">
        <div className="message-modal-grid-alignment">
          <div className="message-gridItem-alignment message-gridItem-alignment-users">
            <MessageSidebar />
          </div>
          <div className='message-gridItem-alignment-chat-main'>
            <div className="message-gridItem-alignment">
              <div className="message-box-alignment ">
                <div className="message-box-child-alignment">
                  {screenWidth >= 768 && (
                    <MessageChatBox key={`chat-${selectedChat?.chatType}-${selectedChat?.chatData?.id}`} />
                  )}
                  {isManageGroup && <div ref={houreRef}>{<ManageGroupMember handleOpen={handleOpen} />}</div>}
                </div>
                {(selectedChat?.chatType === 'group' || selectedChat?.chatType === 'invite') && (
                  <GroupMessageSidebar handleOpen={handleOpen} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
