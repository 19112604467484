import React from 'react'
import { timeAgo, truncateString } from 'src/utils/helperFuncs'
import { useStateContext } from 'src/utils/state'

export default function GroupMessageItem({ group }) {
  const { selectChat } = useStateContext()

  return (
    <div className="group-message-tab-alignment" key={group.id} onClick={() => selectChat(group, 'group')}>
      <div className="group-message-tab-box-alignment">
        <div className="group-message-profile-details-alignment">
          <div className="group-message-profile-img-box-alignment-sidebar">
            {group?.users.slice(0, 3).map((profile, index) => {
              return (
                <div className={`group-message-flex-alignment-${index}`} key={index}>
                  <div className={`group-message-profile-img-alignment-${index}`}>
                    <img src={profile?.profileAvtar?.[0]} alt="group profile" />
                  </div>
                </div>
              )
            })}
            <div className="group-message-first-dot-alignment">
              <div className="first-dot-width-alignment">
                <img src="/assets/img/gdot1.png" alt="group profile" />
              </div>
            </div>
            <div className="group-message-second-dot-alignment">
              <div className="second-dot-width-alignment">
                <img src="/assets/img/gdot2.png" alt="group profile" />
              </div>
            </div>
            <div className="group-message-third-dot-alignment">
              <div className="third-dot-width-alignment">
                <img src="/assets/img/gdot3.png" alt="group profile" />
              </div>
            </div>
          </div>
          <div className="group-message-profile-all-details-alignment">
            <h5>{group?.name !== undefined && truncateString(group?.name, 25)}</h5>
            <p>
              {group?.latestMessage?.body !== undefined
                ? truncateString(group?.latestMessage?.body, 25)
                : 'Get Started!'}
            </p>
          </div>
        </div>
        <div className="group-message-time-alignment">
          <div className="group-total-message-alignment">
            <div className="group-total-message-box-alignmant">
              <p>8</p>
            </div>
          </div>
          <span>{timeAgo(new Date(parseInt(group?.latestMessage?.updatedAt)).getTime())}</span>
        </div>
      </div>
    </div>
  )
}
