import { gql } from '@apollo/client'

const GET_SHARED_COLLECTION = gql`
  query GetSharedCollection {
    getSharedCollection {
      id
      name
      slug
      user {
        id
        firstName
        lastName
        logo_image
        userName
      }
      product_count
      product_images
      likes
      product_count
      isPrivate
    }
  }
`
export default GET_SHARED_COLLECTION
