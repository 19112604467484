// @ts-nocheck
import React from 'react'
import Slider from 'react-slick'
import star from '../../../../../../public/assets/img/WomansCategory/star.png'
import aroww from '../../../../../../public/assets/img/WomansCategory/aroww.png'
import ShareIcon3 from '../../../../../../public/assets/icon/share-grey.svg'
import BlackheartIcon from '../../../../../../public/assets/icon/heart-black.svg'
import image1 from '../../../../../../public/assets/img/book-img/image8.png'

export default function MobileMoreBookBy() {
  const MoreBook = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.055,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  }

  return (
    <div className="mobile-view-book-other-details-alignment">
      <div className="mobile-more-details-heading-alignment">
        <h4>More book by Billy</h4>
        <a>See All</a>
      </div>
      <div className="mobile-view-more-details-alignment">
        <Slider {...MoreBook}>
          {[0, 1, 2, 3, 4, 5].map((_, index) => {
            return (
              <div className="mobile-more-details-book-alignment" key={index}>
                <div className="book-img-alignment">
                  <img src={image1.src} alt="BookCoverImg" />
                </div>
                <div className="mobile-more-details-name-alignment">
                  <h6>The Sweetness of Water</h6>
                  <p>Nathan Harris</p>
                  <div className="book-rate-alignment">
                    <p className="hike">
                      <span className="incris">
                        <img src={aroww.src} alt="incris" /> 200%
                      </span>
                    </p>
                    <div className="rating">
                      <span className="rating_point">4.8</span>
                      <div className="stars">
                        <img src={star.src} alt="star" />
                      </div>
                      <span className="num">(437)</span>
                    </div>
                  </div>
                  <div className="book-price-alignment">
                    <h5>$21.00</h5>
                    <span>
                      <del>$21.00</del>
                    </span>
                  </div>
                  <div className="like-share-alignment">
                    <div className="like-alignment">
                      <img src={BlackheartIcon.src} alt="BlackheartIcon" />
                      <span>235</span>
                    </div>
                    <div className="like-alignment">
                      <img src={ShareIcon3.src} alt="ShareIcon3 " />
                      <span>235</span>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}
