import { gql } from '@apollo/client'

const GET_GROUP_MEMBERS = gql`
  query GetGroupMember($slug: String!) {
    getGroupMember(slug: $slug) {
      message
      success
      data {
        id
        firstName
        lastName
        userName
        phoneNumber
        isAdmin
        gender
        bday
        jobTitle
        isActiveForFriendStatus
        isFriendForFriendStatus
        mutualFriends
        logo_image
        banner_image
        followings: followers {
          user: friend {
            id
            firstName
            profileUrl
          }
        }
        followers: followings {
          user: friend {
            id
            firstName
            profileUrl
          }
        }
      }
    }
  }
`
export default GET_GROUP_MEMBERS
