import { gql } from '@apollo/client'

const CHANGE_COLLECTION_STATUS = gql`
  mutation AccessControlledCollection($slug: String!, $isPrivate: Boolean) {
    accessControlledCollection(slug: $slug, isPrivate: $isPrivate) {
      message
      success
    }
  }
`
export default CHANGE_COLLECTION_STATUS
