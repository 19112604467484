import React, { useEffect, useMemo, useState } from 'react'
import { useOnChange } from 'src/utils/useOnChange'
import { AddService, CloseIcon, DeleteIcon, LocationIcon, ZipCodeIcon } from './ShippingMethodSvg'
import { v4 as uuidv4 } from 'uuid'
import countryList from 'react-select-country-list'
import AddNewShippingService from './AddNewShippingService'

interface ShippingPriceModalProps {
  isOpen: boolean
  onClose: () => void
  updateValue?: number
}

export default function ShippingPriceModal({ isOpen, onClose, updateValue }: ShippingPriceModalProps) {
  if (!isOpen) return null
  const options = useMemo(() => countryList().getData(), [])

  const { inputValue, setInputValue, handleChange, errors, setErrors } = useOnChange({
    name: '',
    processing_time: '',
    zipcode: '',
    handling_fee: '',
  })

  const [shippingservicesList, setShippingservicesList] = useState([])

  useEffect(() => {
    if (updateValue) {
    } else {
      let tempData = [
        {
          id: uuidv4(),
          country: 'US',
          additional_item: '0',
          is_free_delivery: false,
          one_item: '0',
          delivery_service: '',
        },
        {
          id: uuidv4(),
          country: 'Everywhere',
          additional_item: '0',
          is_free_delivery: true,
          one_item: '0',
          delivery_service: '',
        },
        //        Everywhere else
        // { id:uuidv4(),country: null, additional_item: null, delivery_service: null, is_free_delivery: null, one_item: null },
        // { id:uuidv4(),country: null, additional_item: null, delivery_service: null, is_free_delivery: null, one_item: null },
      ]
      setShippingservicesList(tempData)
    }
  }, [updateValue])

  return (
    <div
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
      style={{ background: '#00000080', padding: '70px 0 0 0', zIndex: 9999 }}
    >
      <div
        className="relative w-full max-w-768 bg-white dark:bg-dark-800 rounded-lg shadow-xl flex flex-col max-h-[90vh]"
        style={{ maxHeight: '90vh' }}
      >
        <div className="flex items-center justify-between p-10 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Fixed Price Shipping Profile</h2>
          <button className="text-gray-400 hover:text-gray-500 focus:outline-none" onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div className="flex-1 overflow-y-auto">
          <form className="p-10 space-y-8">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Profile Name <span className="text-red-600">*</span>
                {errors?.name && <span className="text-sm text-red-600">{errors.name}</span>}
              </label>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Give your shipping profile a name to easily identify it
              </p>
              <input
                name="name"
                value={inputValue?.name}
                onChange={handleChange}
                type="text"
                placeholder="Enter profile name"
                className="w-full px-4 py-3 mt-2 border border-gray-300 dark:border-dark-600 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-dark-700 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Processing Time</label>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Set expectations for how long it takes to prepare and ship orders
              </p>
              <div className="relative mt-2">
                <LocationIcon />
                <select
                  name={'processing_time'}
                  value={inputValue?.processing_time}
                  onChange={handleChange}
                  className="w-full px-10 py-3 border border-gray-300 dark:border-dark-600 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-dark-700 dark:text-white appearance-none"
                >
                  <option value="">Select processing time</option>
                  <option value="1-2">1-2 business days</option>
                  <option value="3-5">3-5 business days</option>
                  <option value="5-7">5-7 business days</option>
                  <option value="7-10">7-10 business days</option>
                  <option value="custom">Custom time range</option>
                </select>
              </div>
              {inputValue?.processing_time === 'custom' && (
                <div className="mt-4 grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Minimum Days
                    </label>
                    <input
                      name="min_days"
                      value={inputValue?.min_days}
                      onChange={handleChange}
                      type="number"
                      min="1"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-dark-600 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-dark-700 dark:text-white"
                      placeholder="Enter minimum days"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Maximum Days
                    </label>
                    <input
                      name="max_days"
                      value={inputValue?.max_days}
                      onChange={handleChange}
                      type="number"
                      min="1"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-dark-600 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-dark-700 dark:text-white"
                      placeholder="Enter maximum days"
                    />
                  </div>
                </div>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Ship From ZIP Code <span className="text-red-600">*</span>
                {errors?.zipcode && <span className="text-sm text-red-600">{errors.zipcode}</span>}
              </label>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Enter the ZIP code where you'll be shipping from
              </p>
              <div className="relative mt-2">
                <ZipCodeIcon />
                <input
                  name="zipcode"
                  value={inputValue?.zipcode}
                  onChange={handleChange}
                  type="number"
                  className="block w-full rounded-md border border-gray-300 dark:border-dark-600 dark:bg-dark-700 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm py-3 px-10 appearance-none"
                  placeholder="Enter ZIP code"
                />
              </div>
            </div>
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">Shipping Services</h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Select shipping services to offer your customers
                  </p>
                </div>
              </div>
            </div>
            {shippingservicesList?.length > 0 &&
              shippingservicesList?.map((data, index) => {
                let tittle = options?.find((single_country) => single_country?.value === data?.country)
                if (data?.country === 'Everywhere') {
                  tittle = 'Everywhere else'
                }

                return (
                  <div key={index} className="space-y-4 p-4 border border-gray-200 dark:border-dark-600 rounded-lg">
                    {/* <div className="pb-3 flex justify-between items-end">
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Carrier Name
                      </label>
                      <div className="w-8 h-8 flex justify-center items-center cursor-pointer" onClick={closeForm}>
                        <DeleteIcon />
                      </div>
                    </div> */}
                    <div>
                      <div className="flex justify-between items-end">
                        <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          {tittle?.label ? tittle?.label : tittle}
                        </h4>
                        {index !== 0 && index !== 1 && (
                          // <div className="w-8 h-8 flex justify-center items-center cursor-pointer" onClick={closeForm}>
                          <div className="w-8 h-8 flex justify-center items-center cursor-pointer">
                            <DeleteIcon />
                          </div>
                        )}
                        {/* <div className="w-8 h-8 flex justify-center items-center cursor-pointer" onClick={closeForm}>
                          <DeleteIcon />
                        </div> */}
                      </div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Configure shipping rates for international destinations
                      </p>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                          Select Continent
                        </label>
                        <select className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 dark:bg-dark-700 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm py-3 px-4">
                          <option value="">Select a continent</option>
                          <option value="North America">North America</option>
                          <option value="South America">South America</option>
                          <option value="Europe">Europe</option>
                          <option value="Asia">Asia</option>
                          <option value="Africa">Africa</option>
                          <option value="Oceania">Oceania</option>
                        </select>
                      </div>
                      <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                          What you'll charge
                        </label>
                        <select className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 dark:bg-dark-700 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm py-3 px-4">
                          <option value="is_free_delivery">Free delivery</option>
                          <option value="fixed_price">Fixed Price</option>
                        </select>
                      </div>
                      {data?.is_free_delivery && (
                        <div>
                          <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                              One item
                            </label>
                            <div className="relative mt-2">
                              <input
                                name="oneitem"
                                // value={inputValue?.zipcode}
                                // onChange={handleChange}
                                type="number"
                                className="block w-full rounded-md border border-gray-300 dark:border-dark-600 dark:bg-dark-700 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm py-3 px-10 appearance-none"
                                placeholder="Enter one item charge"
                              />
                            </div>
                          </div>
                          <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                              Additional item
                            </label>
                            <div className="relative mt-2">
                              <input
                                name="additionalitem"
                                // value={inputValue?.zipcode}
                                // onChange={handleChange}
                                type="number"
                                className="block w-full rounded-md border border-gray-300 dark:border-dark-600 dark:bg-dark-700 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm py-3 px-10 appearance-none"
                                placeholder="Enter additional item charge"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                          Default International Rate
                        </label>
                        <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                          This rate will be used for countries without specific rates
                        </p>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span className="text-gray-500 sm:text-sm">$</span>
                          </div>
                          <input
                            type="number"
                            className="block w-full rounded-md border border-gray-300 dark:border-dark-600 dark:bg-dark-700 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm py-3 px-10"
                            placeholder="0.00"
                            step="0.01"
                            min="0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            <AddNewShippingService {...{shippingservicesList}} />

            {/* <div className="flex justify-end">
              <button
                type="button"
                className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-indigo-600 dark:text-indigo-400 hover:text-indigo-500"
                onClick={openForm}
              >
                <AddService />
                Add Service
              </button>
            </div> */}
          </form>
        </div>
        <div className="bg-white dark:bg-dark-800 border-t border-gray-200 dark:border-dark-700 p-10">
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-gray-300 dark:border-dark-600 shadow-sm px-4 py-2 bg-white dark:bg-dark-800 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              onClick={onClose}
            >
              Create Shipping Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
