import React, { useEffect, useState } from 'react'
import styles from './MobileViewProductAllVarintModal.module.scss'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import GET_PRODUCT_BY_NAME from 'src/graphql/queries/getProductByName'
import { useQuery } from '@apollo/client'
import { useAppDispatch } from 'src/store/store'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useRouter } from 'next/router'

export default function MobileViewProductAllVarintModal() {
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const router = useRouter()
  const [selectedOptions, setSelectedOptions] = useState<any>({})
  const [colorCode, setColorCode] = useState('')
  const [selectedVariant, setSelectedVariant] = useState<any>(null)
  const dispatch = useAppDispatch()
  const { loading, error, data, refetch } = useQuery(GET_PRODUCT_BY_NAME, {
    variables: { slug: router?.query?.id },
  })

  useEffect(() => {
    setSelectedOptions(addProductDetailSlice?.id?.productDetails?.productSelectedOption)
    setColorCode(addProductDetailSlice?.id?.productDetails?.productDetailColor)
    setSelectedVariant(addProductDetailSlice?.id?.productDetails?.productDetailVariant)
  }, [addProductDetailSlice?.id?.productDetails])

  const handleOptionSelect = (optionName, optionValue, colorCode) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [optionName]: optionValue,
    }))

    optionName === 'color' && setColorCode(colorCode)

    const updatedSelectedOptions = {
      ...selectedOptions,
      [optionName]: optionValue,
    }

    const finalData = data?.getSingleProduct?.variants.find((variant) => {
      return Object.entries(updatedSelectedOptions).every(([key, value]) => {
        return variant.total_variant.some((option) => option.variant_option.value === value)
      })
    })
    setSelectedVariant(finalData)
  }

  const handleSaveVariants = () => {
    dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          productDetails: {
            ...addProductDetailSlice?.id?.productDetails,
            productDetailVariant: selectedVariant,
            productSelectedOption: selectedOptions,
            productDetailColor: colorCode,
          },
        },
      }),
    )
    dispatch(
      fetchMobileModalData({
        modal: '',
        isOpen: false,
        id: {},
      }),
    )
  }

  return (
    <div className={styles.addProductBodyMainAlignment}>
      <div className={styles.addProdutcHeadingAlignment}>
        <h6>Variants</h6>
      </div>

      <div className={styles.addProductBodyAlignment}>
        {data?.getSingleProduct.options?.map((item, index) => {
          return (
            <div className={styles.addProdutcBodyChildDetails} key={index}>
              <label>{item?.name} </label>
              <div className={styles.addProdutcAllChildList}>
                {data?.getSingleProduct.options[index]?.data?.map((data, i) => {
                  return (
                    <div
                      key={`a${i}`}
                      className={classNames(
                        styles.addChildListBox,
                        selectedOptions[item.name] === data.value && styles.activeDetails,
                      )}
                      onClick={() => handleOptionSelect(item.name, data.value, data?.colorCode)}
                    >
                      {data?.colorCode && (
                        <div className={styles.colorBoxAlignment} style={{ background: `${data?.colorCode}` }}></div>
                      )}
                      <span>{data?.value}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>

      <div className={styles.addProductButtonAlignment} onClick={() => handleSaveVariants()}>
        <button>Done</button>
      </div>
    </div>
  )
}
