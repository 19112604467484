import { gql } from '@apollo/client'

const GET_PRODUCT_IMAGES = gql`
  query GetProductImages($productId: Int!) {
    getProductImages(product_id: $productId) {
      data {
        id
        title
        slug
        like_count
        comment_count
        sharepost_count
        dis_price
        dis_listPrice
        total_variants
        total_inventory_quantity
        images {
          media_id
          src
        }
        variants {
          inventory_quantity
        }
        likes {
          id
        }
        bookmark {
          id
          collection {
            id
            name
            user_id
            isPrivate
          }
        }
      }
      message
      success
    }
  }
`
export default GET_PRODUCT_IMAGES
