import React from 'react'
import BookDetailView from './BookDetailView'
import MobileViewAboutBookDetail from './MobileViewAboutBookDetail'
import BookDetailMain from './BookDetailMain'
import ExploreSimilarBooks from './ExploreSimilarBooks'
import MobileMoreBookBy from './MobileMoreBookBy'
import MobileBookClubSection from './MobileBookClubSection'
import BookReviewSection from './BookReviewSection'

export default function BookDetailModal() {
  return (
    <React.Fragment>
      <div className="book-details-modal-section">
        <BookDetailView />
        <MobileViewAboutBookDetail />
        <BookDetailMain />
        <MobileMoreBookBy />
        <MobileBookClubSection />
        <ExploreSimilarBooks />
        <BookReviewSection />
      </div>
    </React.Fragment>
  )
}
