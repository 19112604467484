import { gql } from '@apollo/client'

export default gql`
  query GET_GROUPS_ONBOARDING($limit: Int, $page: Int, $type: String) {
    getAllClubs(limit: $limit, page: $page, type: $type) {
      success
      message
      count
      data {
        id
        name
        description
        banner_image
        logo_image
        privacy
        slug
        isExist
        total_members
        members {
          id
          #firstName
          #lastName
          #userName
          logo_image
          banner_image
        }
      }
    }
  }
`
