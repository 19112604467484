import { gql } from '@apollo/client'

const query = gql`
  mutation CREATELIKEPOST($parent_id: Int!, $like_for: String!) {
    createLike(input: { parent_id: $parent_id, like_for: $like_for }) {
      message
      id
    }
  }
`
export default query
