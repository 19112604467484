import { useQuery } from '@apollo/client'
import { Crown, Eye, Search, Star, Users } from 'lucide-react'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import Loader from 'src/components/UI/Loader'
import GET_LIVESTREAM_RECORDINGS from 'src/graphql/queries/getLivestreamRecording'

const PastMeetingVideo = () => {
    const getAllState = useSelector((state: any) => state?.manageModalData?.data)
    const { data, loading, error } = useQuery(GET_LIVESTREAM_RECORDINGS, {
        variables: {
            uuid: getAllState?.lastModalData?.data?.uuid
        }
    })
    const recordingData = data?.getLivestreamRecording?.data;

    const getRoleBadge = (presetName: string) => {
        switch (presetName) {
            case 'livestream_host':
                return (
                    <span className="flex items-center gap-1 text-xs font-medium text-indigo-600 dark:text-indigo-400">
                        <Crown size={12} />
                        Host
                    </span>
                );
            case 'livestream_viewer':
                return (
                    <span className="flex items-center gap-1 text-xs font-medium text-green-600 dark:text-green-400">
                        <Star size={12} />
                        Viewer
                    </span>
                );
            default:
                return null;
        }
    };
    return (
        <div className="w-full h-full ">
            {loading ? <Loader /> :
                <div className="flex flex-col w-full h-full ">
                    {/* Stream Header */}
                    <div className="flex flex-col lg:flex-row w-full h-full p-5">
                        <div className='w-3/4 h-full bg-white shadow-md border border-gray-200 rounded-lg mx-5 '>
                            <div className="w-full px-4 py-2 border-b border-gray-200">
                                <div className="text-sm flex justify-center mt-5 font-semibold">{recordingData?.title}</div>
                                <div className="flex items-center ">
                                    <div className="flex items-center space-x-3">
                                        <div className="w-16 h-16  overflow-hidden">
                                            <img
                                                src={recordingData?.store_logo}
                                                alt={recordingData?.store_name}
                                                className="w-full h-full object-cover"
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <div className=" items-center space-x-1">
                                                <span className="font-medium">{recordingData?.store_name}</span>
                                                <div className='flex items-center'>
                                                    <div className="text-sm  mr-3">4.8</div>
                                                    <div className="flex items-center">
                                                        {[1, 2, 3, 4, 5].map((star) => (
                                                            <svg key={star} className="w-3 h-3 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                                                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                            </svg>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>
                            {/* Video Section - 70% on desktop, full width on mobile */}
                            <div className="p-8">
                                <div className="w-full h-full bg-black rounded-lg aspect-video p-">
                                    {/* Video player will go here */}
                                    <video
                                        className="w-full h-full object-cover rounded-lg"
                                        controls
                                        playsInline
                                        autoPlay
                                    >
                                        <source src={recordingData?.recording_url} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        </div>
                        {/* User Section - 30% on desktop, full width on mobile */}
                        <div className="w-1/3 h-full mx-4 bg-white shadow-md border border-gray-200 rounded-lg ">
                            <div className=" flex flex-col">
                                {/* Search */}
                                <div className="mb-4">
                                    {/* <div className="relative">
                                        <Search className="absolute left-3 top-2.5 text-gray-400" size={16} style={{ transform: "translateY(-50%)", top: "50%" }} />
                                        <input
                                            type="text"
                                            placeholder="Search viewers..."
                                            value={searchTerm}
                                            onChange={onSearchViewers}
                                            className="w-full pl-14 pr-4 py-3 text-sm border border-gray-200 dark:border-dark-700 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-dark-800 dark:text-white"
                                        />
                                    </div> */}
                                </div>

                                {/* Viewers Count */}
                                {recordingData?.participants && <div className="mb-4 border-b border-gray-200 dark:border-dark-700 px-8 pt-10 pb-12">
                                    <div className="flex items-center justify-center">
                                        <div className="flex items-center text-lg font-bold text-gray-900 dark:text-white">
                                            <Eye size={24} className="mr-2" />
                                            Viewer ( {recordingData?.participants?.length} )
                                        </div>
                                    </div>
                                </div>}

                                {/* Viewers List */}
                                <div className="flex-1 overflow-y-auto space-y-2 px-3">
                                    {recordingData?.participants?.map((viewer) => (
                                        <div
                                            key={viewer.customParticipantId}
                                            className="flex items-center gap-3 p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-dark-700"
                                        >
                                            {viewer.picture ? (
                                                <img
                                                    src={viewer.picture}
                                                    alt={viewer.name}
                                                    className="w-8 h-8 rounded-full object-cover"
                                                />
                                            ) : (
                                                <div className="w-10 h-10 rounded-full bg-gray-200 dark:bg-dark-700 flex items-center justify-center mr-2 p-10">
                                                    <span className="text-sm font-semibold text-gray-600 dark:text-white">
                                                        {viewer.name.split(' ').map((name, i) => i < 2 ? name[0] : null).join('').toUpperCase()}
                                                    </span>
                                                </div>
                                            )}
                                            <div className="flex-1 min-w-0">
                                                <div className="flex items-center gap-2">
                                                    <span className="font-medium text-gray-900 dark:text-white truncate">
                                                        {viewer.name}
                                                    </span>
                                                    {getRoleBadge(viewer.preset_name)}
                                                </div>
                                                <span className="text-xs text-gray-500">
                                                    Joined at {moment(viewer.createdAt).format('MMM DD, YYYY ')}
                                                </span>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    )
}

export default PastMeetingVideo
