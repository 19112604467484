import { gql } from '@apollo/client'

const query = gql`
  mutation DECLINE_FRIEND_REQUEST($user_id: Int!, $friend_id: Int!) {
    deleteFriend(user_id: $user_id, friend_id: $friend_id) {
      message
    }
  }
`
export default query
