import { gql } from '@apollo/client'

const query = gql`
  mutation CREATE_COMMENT_REPLY($input: createCommentReplyInput!) {
    createCommentReply(input: $input) {
      id
    }
  }
`
export default query
