import React from 'react';
import NotificationFooter from '../NotificationFooter';

interface NewProductsNotificationProps {
    notification: any;
}

const StoreProducts: React.FC<NewProductsNotificationProps> = ({ notification }) => {
    const payload = notification?.payload;

    return (
        <div className="p-4 px-6">
            <div className="flex items-start gap-5">
                <div className="flex-shrink-0">
                    <div className="w-[40px] h-[40px] sm:w-[56px] sm:h-[56px] ml-4 rounded-lg overflow-hidden ">
                        {payload?.storeImg ? (
                            <img src={payload.storeImg} alt={payload.storeName || "Store"} className="w-full h-full" />

                        ) : (
                            <div className="w-full h-full flex items-center justify-center bg-gray-200">
                                <span className="text-gray-500 text-lg font-semibold">
                                    {payload?.storeName?.charAt(0)}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex-1">
                    <div className="space-y-3">
                        <div>
                            <div className="text-base">
                                <span className='text-sm sm:text-sm text-gray-800'><b>{payload?.storeName}</b> {payload?.message}</span>
                            </div>
                        </div>

                        {payload?.products && payload.products.length > 0 && (
                            <div className="flex space-x-3 py-3 px-5  shadow-sm border-4 border-white rounded-2xl bg-gray-100" style={{ width: 'fit-content' }}>
                                {payload.products.slice(0, 3).map((image: string, index: number) => (
                                    <div key={index} className="aspect-square rounded-lg w-[64px] h-[64px] sm:w-[80px] sm:h-[80px] overflow-hidden border ">
                                        <img
                                            src={image}
                                            alt={`Product ${index + 1}`}
                                            width={80}
                                            height={80}
                                            className="w-full h-full"
                                        />
                                    </div>
                                ))}

                                {payload.products.length > 3 && (
                                    <div className="flex items-center justify-center w-[64px] h-[64px] sm:w-[80px] sm:h-[80px] overflow-hidden border border-gray-200 rounded-lg bg-gray-100">
                                        <span className="text-gray-500 text-sm font-semibold flex justify-center ml-2">+{payload.products.length - 4} More</span>
                                    </div>
                                )}
                            </div>
                        )}
                        <NotificationFooter notification={notification} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StoreProducts;