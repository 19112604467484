import { gql } from '@apollo/client'

const GET_SINGLE_ORDER = gql`
  query Data($orderMasterId: Int!, $order_item_id: Int) {
    getSingleOrder(order_master_id: $orderMasterId, order_item_id: $order_item_id) {
      data {
        id
        order_id
        user_id
        shipping_id
        card_id
        paymentId
        orderDate
        totalAmount
        totalTaxAmount
        totalBasicAmount
        pendingAmount
        order_status
        isActive
        isPaymentDone
        createdAt
        updatedAt
        shipping_detail {
          city
          country
          state
          streetAddress
          zipcode
        }
        orderItems {
          id
          order_master_id
          product_id
          createdAt
          seller_id
          store_id
          quantity
          product_price
          basicAmount
          taxAmount
          discountAmount
          shipment_charges
          totalAmount
          pendingAmount
          tracking_number
          shipment_id
          label_id
          label_url
          trackingUrl
          globalCharges
          sellerCharges
          sellerFinalAmount
          user_id
          shipping_id
          order_status_code
          order_status
          paymentStatus
          refund_reciept_url
          variant_id
          product {
            id
            store_id
            title
            dis_listPrice
            dis_price
            images {
              src
            }
            store {
              id
              name
              logo_image
              banner_image
              streetAddress
              city
              state
              country
              postalCode
              companyEmail
              phoneNumber
            }
          }
        }
      }
      message
      success
    }
  }
`

export default GET_SINGLE_ORDER
