import { useMutation } from '@apollo/client'
import { notification } from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import RESET_PASSWORD from 'src/graphql/mutations/resetPassword'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAppDispatch } from 'src/store/store'

export default function NewCreateNewPassword() {
  const dispatch = useAppDispatch()
  const guestDetail = useSelector((state: any) => state?.guestAccountInfo?.data)
  const [errors, setErrors] = useState<any>({})
  const [inputValue, setInputValue] = useState<any>({
    password: '',
    confirmPassword: '',
  })

  const validateForm = () => {
    let formIsValid = true
    let errors = {}
    if (inputValue?.password.trim() === '') {
      formIsValid = false
      errors['password'] = 'Please Enter Password'
    }
    if (inputValue?.confirmPassword.trim() === '') {
      formIsValid = false
      errors['confirmPassword'] = 'Please Enter Confirm Password'
    }
    if (inputValue?.confirmPassword !== inputValue?.password) {
      formIsValid = false
      errors['doesNotMached'] = 'Password does not mached'
    }
    setErrors(errors)
    return formIsValid
  }

  const successPage = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: { ...guestDetail?.id, media: 'login', modalFor: 'passwordUpdated' },
      }),
    )
  }

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted: (data) => {
      notification['success']({
        message: 'Reset',
        description: 'Password Changed Successfully',
      })
      successPage()
    },
    onError: (error) => {
      console.log(error)
      notification['error']({
        message: 'Error',
        description: 'Something went wrong, Please try later',
      })
    },
  })

  const handleFinalPassword = async () => {
    if (validateForm()) {
      await resetPassword({
        variables: {
          email: guestDetail?.id?.email,
          otp: guestDetail?.id?.otp,
          password: inputValue?.confirmPassword,
        },
      })
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputValue({
      ...inputValue,
      [name]: value,
    })
  }

  return (
    <div className="new-create-password-alignment">
      <div className="new-create-heding-alignment">
        <h4>Create New Password</h4>
      </div>
      <div className="new-create-body-alignment">
        <div className={`new-create-input-alignment ${errors?.password && 'wrong-password-input'}`}>
          {/* confirm-condtion  */}
          <input type="text" placeholder="New password" name="password" onChange={(e) => handleChange(e)} />
          <div className="new-create-password-icon">
            <div className="right-sign-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
                <path d="M5.4 12L0 4.8L5.4 7.2L15.6 0L5.4 12Z" fill="#1D8603" />
              </svg>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M14.9499 14.9499C13.5254 16.0358 11.7908 16.6373 9.99992 16.6666C4.16659 16.6666 0.833252 9.99994 0.833252 9.99994C1.86983 8.06819 3.30753 6.38045 5.04992 5.04994M8.24992 3.53327C8.82353 3.39901 9.41081 3.33189 9.99992 3.33327C15.8333 3.33327 19.1666 9.99994 19.1666 9.99994C18.6607 10.9463 18.0575 11.8372 17.3666 12.6583M11.7666 11.7666C11.5377 12.0122 11.2617 12.2092 10.955 12.3459C10.6484 12.4825 10.3173 12.556 9.98166 12.5619C9.64598 12.5678 9.31255 12.5061 9.00126 12.3803C8.68997 12.2546 8.40719 12.0675 8.16979 11.8301C7.9324 11.5927 7.74525 11.3099 7.61951 10.9986C7.49377 10.6873 7.43202 10.3539 7.43795 10.0182C7.44387 9.68252 7.51734 9.35148 7.65398 9.04481C7.79062 8.73815 7.98763 8.46215 8.23325 8.23327"
                stroke="#7A7E88"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 2L18 18"
                stroke="#7A7E88"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          {/* <div className="password-box-alignment">
            <div className="password-box-heading">
              <h6>Medium</h6>
            </div>
            <div className="password-box-lines-alignment">
              <div className="box-lines box-lines-active"></div>
              <div className="box-lines box-lines-active"></div>
              <div className="box-lines"></div>
            </div>
            <div className="password-box-conditions">
              <div className="password-box-all-conditins-details ">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                    <path d="M3.80769 10L0 4L3.80769 6L11 0L3.80769 10Z" fill="#1D8603" />
                  </svg>
                </div>
                <p>At least 8 characters</p>
              </div>
              <div className="password-box-all-conditins-details ">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                    <path d="M3.80769 10L0 4L3.80769 6L11 0L3.80769 10Z" fill="#1D8603" />
                  </svg>
                </div>
                <p>Must contain special character</p>
              </div>
              <div className="password-box-all-conditins-details ">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                    <path d="M3.80769 10L0 4L3.80769 6L11 0L3.80769 10Z" fill="#1D8603" />
                  </svg>
                </div>
                <p>At least contain one Uppercase </p>
              </div>
              <div className="password-box-all-conditins-details ">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                    <path d="M3.80769 10L0 4L3.80769 6L11 0L3.80769 10Z" fill="#1D8603" />
                  </svg>
                </div>
                <p>At least contain one Number</p>
              </div>
            </div>
          </div> */}
        </div>
        <div
          className={`new-create-input-alignment ${errors?.doesNotMached && 'wrong-password-input'} ${
            inputValue?.password === inputValue?.confirmPassword && 'confirm-condtion'
          }`}
        >
          <input
            type="text"
            placeholder="Confirm New Password"
            name="confirmPassword"
            onChange={(e) => handleChange(e)}
          />
          <div className="new-create-password-icon">
            <div className="right-sign-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
                <path d="M5.4 12L0 4.8L5.4 7.2L15.6 0L5.4 12Z" fill="#1D8603" />
              </svg>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M14.9499 14.9499C13.5254 16.0358 11.7908 16.6373 9.99992 16.6666C4.16659 16.6666 0.833252 9.99994 0.833252 9.99994C1.86983 8.06819 3.30753 6.38045 5.04992 5.04994M8.24992 3.53327C8.82353 3.39901 9.41081 3.33189 9.99992 3.33327C15.8333 3.33327 19.1666 9.99994 19.1666 9.99994C18.6607 10.9463 18.0575 11.8372 17.3666 12.6583M11.7666 11.7666C11.5377 12.0122 11.2617 12.2092 10.955 12.3459C10.6484 12.4825 10.3173 12.556 9.98166 12.5619C9.64598 12.5678 9.31255 12.5061 9.00126 12.3803C8.68997 12.2546 8.40719 12.0675 8.16979 11.8301C7.9324 11.5927 7.74525 11.3099 7.61951 10.9986C7.49377 10.6873 7.43202 10.3539 7.43795 10.0182C7.44387 9.68252 7.51734 9.35148 7.65398 9.04481C7.79062 8.73815 7.98763 8.46215 8.23325 8.23327"
                stroke="#7A7E88"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 2L18 18"
                stroke="#7A7E88"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="new-create-paaword-button-alignment" onClick={handleFinalPassword}>
        <button>Submit</button>
      </div>
    </div>
  )
}
