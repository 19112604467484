import { gql } from '@apollo/client'

export default gql`
  query GETGROUP($slug: String!) {
    group(slug: $slug) {
      id
      name
      description
      hashtags
      logo_image
      banner_image
      privacy
      user_id
      isExist
      subCategory_id: sub_Category {
        id
        name
      }
    }
  }
`
