import { gql } from '@apollo/client'

export default gql`
  query GET_POST_BY_ID($post_id: Int!) {
    getSinglePost(post_id: $post_id) {
      id
      content
      comment_count
      isShare
      createdAt
      title
      store_id
      store_name
      post_for
      store {
        id
        name
        logo
        slug
        logo_image
        banner_image
      }
      group {
        id
        name
        description
        hashtags
        banner_image
        logo_image
        privacy
        isExist
        admins {
          id
          firstName
          lastName
          phoneNumber
          gender
          bday
          jobTitle
          banner_image
          logo_image
          # profileCoverImage
          # profileUrl: profileAvtar
          isActiveForFriendStatus
          isFriendForFriendStatus
          mutualFriends
        }
        members {
          id
          firstName
          lastName
          phoneNumber
          gender
          bday
          jobTitle
          banner_image
          logo_image
          # profileCoverImage
          # profileUrl: profileAvtar
          isActiveForFriendStatus
          isFriendForFriendStatus
          mutualFriends
        }
      }
      product {
        id
        price
        title
        image
        store {
          id
          name
          logo
          cover_image
          isFollow
          slug
          products {
            id
            image
          }
          followers {
            id
            user {
              id
              firstName
              lastName
              # profileUrl
              banner_image
              logo_image
            }
          }
        }
      }
      attachements: medias {
        id
        url
      }
      likes {
        id
        user {
          id
          firstName
          lastName
          # profileUrl
          banner_image
          logo_image
        }
      }
      user {
        id
        userName
        firstName
        lastName
        address
        about
        state
        country
        isActiveForFriendStatus
        isFriendForFriendStatus
        friends {
          id
        }
        # profileImage {
        #   url
        #   alternativeText
        # }
        # coverImage {
        #   url
        #   alternativeText
        # }
        banner_image
        logo_image
        followers {
          id
          user {
            id
            firstName
            # profileUrl
            banner_image
            logo_image
          }
        }
        followings {
          id
          user: friend {
            id
            firstName
            # profileUrl
            banner_image
            logo_image
          }
        }
      }
    }
  }
`
