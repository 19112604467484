import React from 'react'
import styles from './MobileViewWithdrawDetailsModal.module.scss'
export default function MobileViewWithdrawDetailsModal({
  setIsMobileViewWithdrawDetailsModal,
  setIsMobileViewWithdrawPasswordModal,
  setIsMobileViewWithdrawOtpModal,
}) {
  return (
    <div className={styles.mobileViewWithdrawDetailsDetaModalSection}>
      <div className={styles.mobileViewWithdrawDetailsDetaModalHeading}>
        <h4>Withdrawal Detail</h4>
        <a onClick={() => setIsMobileViewWithdrawDetailsModal(false)}>Cancel</a>
      </div>

      <div className={styles.mobileViewWithdrawDetailsDetaModalDetails}>
        <div className={styles.mobileVIewWithdrawDetailsDataBox}>
          <div className={styles.mainMobileViewBoxDetails}>
            <div className={styles.imgAlignment}>
              <img src="/assets/seller/icons/union-icon.svg" alt="withdraw icon" />
            </div>

            <div>
              <p>Transfer Amount</p>
              <h4>$2,455</h4>
            </div>
          </div>

          <div className={styles.mobilrViewWhiteBoxAlignment}>
            <div className={styles.mobileViewChildDetailsAlignment}>
              <span>From</span>
              <p>Tommy Hilfiger Account Balance</p>
            </div>
            <div className={styles.mobileViewChildDetailsAlignment}>
              <span>To</span>
              <p>Esther Howard - Bank of America</p>
            </div>
            <div className={styles.mobileViewChildDetailsAlignment}>
              <span>Estimated Arrival</span>
              <p>June 24 , 2023</p>
            </div>
          </div>

          <div className={styles.mobileViewWithdrawNotesBox}>
            <span>Note</span>

            <div className={styles.mobileViewNotesAlignment}>
              <textarea placeholder="Write note here..." readOnly> Write note here...</textarea>
            </div>
          </div>
        </div>
        {/* <div className={styles.mobileVIewWithdrawDetailsDetaButton}>
          <button onClick={() => setIsMobileViewWithdrawPasswordModal(true)}>Continue</button>
        </div> */}
      </div>
      <div className={styles.mobileWithDrawButtonAlignment}>
        <div className={styles.backButtonAlignment}>
          <button>Back</button>
        </div>
        <div className={styles.withdrawButtonAlignment}>
          <button onClick={() => setIsMobileViewWithdrawOtpModal(true)}>Withdraw</button>
        </div>
      </div>
    </div>
  )
}
