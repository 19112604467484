import { gql } from '@apollo/client'

const GET_ALL_BOOKMARK_COLLECTIONS = gql`
  query GetBookmarkCollections($isPrivate: Boolean) {
    getBookmarkCollections(isPrivate: $isPrivate) {
      id
      user_id
      name
      product_images
      product_count
      likes
      isPrivate
      slug
      user {
        firstName
        lastName
        userName
        logo_image
      }
    }
  }
`
export default GET_ALL_BOOKMARK_COLLECTIONS
