import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import TumbnailPreviewModal from 'src/components/Seller/SellerProducts/AddProductModal/TumbnailPreviewModal'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'
import { fetchUserProfilModalScroll } from 'src/store/slices/userProfileonScrollSlice'
import { useAppDispatch } from 'src/store/store'

export default function ProductModalParent(props) {
  const { isModalOpen, children } = props
  const myElementRef = useRef(null)
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const fetchProductImageResizeData = useSelector((state: any) => state?.productResizeData?.data)
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)

  const handleClose = () => {
    let datass = getAllState?.allModalData?.filter((item) => item?.modal !== getAllState?.lastModalData?.modal)
    dispatch(
      fetchModalData({
        allModalData: datass,
        lastModalData: getAllState?.lastModalData,
      }),
    )
    dispatch(
      fetchProductImageResizeModal({
        modal: '',
        isOpen: false,
        id: {
          ...fetchProductImageResizeData?.id,
          prevImages: [],
          prevVideos: [],
          selectedSize: { x: 1080, y: 1080 },
          isSelected: false,
          ratio: '1 : 1',
          media: [],
        },
      }),
    )
    dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          variant: {
            isAddVariantModal: false,
            isAddColorModalOpen: false,
            isAddOtherVariantModalOpen: false,
            dataEdit: {},
            allVariant: [],
            colorCodeArray: [],
            allFinalVariant: [],
            variantError: {},
            isVariantModel: false,
            allOptions: [],
          },
          productPreview: {
            title: '',
            image: [],
            price: '',
            listPrice: '',
          },
        },
      }),
    )
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        handleClose()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleClose])

  const handleScroll = () => {
    const scrollTop = myElementRef.current.scrollTop
    dispatch(
      fetchUserProfilModalScroll({
        scroll: scrollTop,
      }),
    )
  }

  useEffect(() => {
    dispatch(
      fetchUserProfilModalScroll({
        scroll: 0,
      }),
    )
  }, [getAllState])

  useEffect(() => {
    if (getAllState?.lastModalData?.isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [getAllState])

  return (
    <React.Fragment>
      <div
        // onClick={() => handleClose()}
        className={`overlay ${isModalOpen ? 'open' : ''}`}
      ></div>
      <div className={`add-product-modal-contain ${isModalOpen ? 'open' : ''}`}>
        <TumbnailPreviewModal />
        <div className="container-add-product-modal" ref={myElementRef} onScroll={handleScroll}>
          {children}
        </div>
      </div>
    </React.Fragment>
  )
}
