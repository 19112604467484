export const ART_CRAFT_CATEGORY = [
  {
    name: 'Pottery',
    image: '/assets/img/artCarft/artCraftCategory/pottery.png',
    text: 'Pottery is a blend of art and function, providing a unique and authentic touch to any setting.',
    bannerImage: '/assets/img/artCarft/artCraftCategory/artCraftCategoryBanner/pottery.png',
  },
  {
    name: 'Textile Art',
    image: '/assets/img/artCarft/artCraftCategory/textile-art.png',
    text: 'Transform your bedroom into a stylish and comfortable sanctuary with our high-quality furniture collection.',
    bannerImage: '/assets/img/artCarft/artCraftCategory/artCraftCategoryBanner/textile-art.png',
  },
  {
    name: 'Painting',
    image: '/assets/img/artCarft/artCraftCategory/painting.png',
    text: 'Discover our premium furniture collection and create a living room that reflects your style and comfort needs.',
    bannerImage: '/assets/img/artCarft/artCraftCategory/artCraftCategoryBanner/painting.png',
  },
  {
    name: 'Printmaking',
    image: '/assets/img/artCarft/artCraftCategory/printmaking.png',
    text: 'Upgrade your kitchen with our high-quality products that combine style, and performance for the ultimate cooking and dining experience.',
    bannerImage: '/assets/img/artCarft/artCraftCategory/artCraftCategoryBanner/printmaking.png',
  },
  {
    name: 'Papercraft',
    image: '/assets/img/artCarft/artCraftCategory/papercraft.png',
    text: 'Elevate your bathroom experience with our premium products. Transform your space into a personal oasis of comfort and relaxation.',
    bannerImage: '/assets/img/artCarft/artCraftCategory/artCraftCategoryBanner/papercraft.png',
  },
  {
    name: 'Metalworking',
    image: '/assets/img/artCarft/artCraftCategory/metalworking.png',
    text: 'Find inspiration for your home with our curated decor collection. Elevate your style and create a space that feels uniquely yours.',
    bannerImage: '/assets/img/artCarft/artCraftCategory/artCraftCategoryBanner/metalworking.png',
  },
  {
    name: 'Woodworking',
    image: '/assets/img/artCarft/artCraftCategory/woodworking.png',
    text: 'Simplify your life with our innovative appliances. Designed to enhance your home experience and make your daily tasks a breeze.',
    bannerImage: '/assets/img/artCarft/artCraftCategory/artCraftCategoryBanner/woodworking.png',
  },
  {
    name: 'Glass Art',
    image: '/assets/img/artCarft/artCraftCategory/glass-art.png',
    text: 'Upgrade your cooking game with our premium cookware. Create delicious meals with ease and style, from beginner to pro.',
    bannerImage: '/assets/img/artCarft/artCraftCategory/artCraftCategoryBanner/glass-art.png',
  },
  {
    name: 'Sculpture',
    image: '/assets/img/artCarft/artCraftCategory/sculpture.png',
    text: 'Transform your living space into a modern and organized home entertainment center with our versatile media furniture collection.',
    bannerImage: '/assets/img/artCarft/artCraftCategory/artCraftCategoryBanner/sculpture.png',
  },
  {
    name: 'Miniature',
    image: '/assets/img/artCarft/artCraftCategory/miniature.png',
    text: 'Get rid of clutter and organize your media space with our stylish and functional media organization furniture.',
    bannerImage: '/assets/img/artCarft/artCraftCategory/artCraftCategoryBanner/miniature.png',
  },
]
