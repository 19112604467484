import React from 'react'
import styles from './AddProductModalSEO.module.scss'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
export default function AddProductModalSEO({ setProductData, productData }) {
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const dispatch = useAppDispatch()

  const handleChange = async (e) => {
    const { name, value } = e.target
    setProductData({ ...productData, seo: { ...productData.seo, [name]: value } })

    await dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          mobileProductsData: {
            ...addProductDetailSlice?.id?.mobileProductsData,
            seo: {
              ...addProductDetailSlice?.id?.mobileProductsData?.seo,
              [name]: value,
            },
          },
        },
      }),
    )
  }

  return (
    <div className={styles.addProductModalSEOAlignment}>
      <div className={styles.addProductModalSEOBox}>
        <div>
          <div className={styles.aaddProductModalSEOHeadingAlignment}>
            <h4>SEO</h4>
          </div>
        </div>
        <div className={styles.seoFormAlignment}>
          <div className={styles.formgroupGridAlignment}>
            <div className={styles.formDetailsAlignment}>
              <label>Meta Title</label>
              <input
                type="text"
                name="metaTitle"
                placeholder="e.g NIKE Running Shoe"
                onChange={(e) => handleChange(e)}
                value={productData?.seo?.metaTitle}
              />
            </div>
            <div className={styles.formDetailsAlignment}>
              <label>Meta Description</label>
              <input
                type="text"
                name="metaDescription"
                placeholder="e.g Experience ultimate freedom..."
                onChange={(e) => handleChange(e)}
                value={productData?.seo?.metaDescription}
              />
            </div>
          </div>
          <div className={styles.formDetailsAlignment}>
            <label>Keywords</label>
            <textarea
              name="keywords"
              onChange={(e) => handleChange(e)}
              value={productData?.seo?.keywords}
              rows={4}
              cols={5}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  )
}
