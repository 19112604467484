import React, { useEffect, useState } from 'react'
import styles from './LiveStreamProduct.module.scss'
import { useMutation, useQuery } from '@apollo/client'
import GET_ALL_PRODUCT_BY_STORE from 'src/graphql/queries/getAllProductByStore'
import { useAuth } from 'src/utils/auth'
import ADD_PRODUCT_TO_STREAM from 'src/graphql/mutations/addProductToStream'
import { NEW_PRODUCTS, REMOVE_PRODUCTS } from 'src/graphql/subscriptions/subscriptions'
import REMOVE_PRODUCT_FROM_STREAM from 'src/graphql/mutations/removeProductFromLivestream'
import CREATE_CART_MUTATION from 'src/graphql/mutations/createCart'
import sellerClient from 'src/utils/apolloSellerClient'
import { getAllCartCountSliceData } from 'src/store/slices/getCartCount'
import { useAppDispatch } from 'src/store/store'
import socketClient, { LIVESTREAM_EVENTS } from 'src/utils/socketClient'

export default function LiveStreamProduct({ meeting, uuId, addedProducts, setAddedProducts }) {
  const { user }: any = useAuth()
  const [isShowProductModel, setIsShowProductModel] = useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    socketClient.connect()
    socketClient.on(LIVESTREAM_EVENTS.NEW_PRODUCT, (data) => {
      if (data?.uuid === uuId) {
        if (addedProducts) {
          setAddedProducts((prevProducts) => [...prevProducts, data?.fetchProduct])
        } else {
          setAddedProducts([data?.fetchProduct])
        }
      }
    })

    socketClient.on(LIVESTREAM_EVENTS.REMOVE_PRODUCT, (data) => {
      if (data?.uuid === uuId) {
        setAddedProducts(addedProducts?.filter((product) => product?.id !== data?.removeProduct?.id))
      }
    })

    return () => {
      socketClient.off(LIVESTREAM_EVENTS.NEW_PRODUCT)
      socketClient.off(LIVESTREAM_EVENTS.REMOVE_PRODUCT)
      socketClient.disconnect()
    }
  }, [addedProducts])


  const all_Products = useQuery(GET_ALL_PRODUCT_BY_STORE, {
    variables: {
      store_id: user?.store?.id,
      // store_id: 1,
      page: 1,
      limit: 40,
      search: '',
      status: '',
    },
  })

  const QuantityHandler = (item) => {
    if (user) {
      createCart({
        variables: { input: { parent_id: item?.id, quantity: 1, cart_for: 'PRODUCT' } },
      })
    }
  }

  const [createCart] = useMutation(CREATE_CART_MUTATION, {
    onCompleted: (res) => {
      dispatch(getAllCartCountSliceData())
    },

    onError: (error) => { },
  })

  const handleAddProductForSale = (item) => {
    const isIdMatch = addedProducts?.some((data) => data?.id === item?.id)
    // if (isIdMatch) {
    //   handleRemoveCollection(item)
    // }
    addProductToStream({
      variables: {
        streamId: uuId,
        productId: item?.id,
      },
    })
  }

  const [addProductToStream] = useMutation(ADD_PRODUCT_TO_STREAM, {
    client: sellerClient,
    onCompleted: (res) => {
      all_Products?.refetch()
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const handleRemoveCollection = (item) => {
    const updatedArray = addedProducts.filter((arrayItem) => arrayItem.id !== item.id)
    setAddedProducts(updatedArray)
    removeStreamProduct({
      variables: {
        uuid: uuId,
        productId: item?.id,
      },
    })
  }

  const [removeStreamProduct] = useMutation(REMOVE_PRODUCT_FROM_STREAM, {
    client: sellerClient,
    onCompleted: (res) => { },
    onError: (error) => {
      console.log(error)
    },
  })

  return (
    <div className={styles.liveStreamProductSection}>
      <div className={styles.liveStreamHeading}>
        <h4>Product</h4>
      </div>

      <div className={styles.liveStreamProductBodyAlignment}>
        {addedProducts?.map((item, index) => {
          return (
            <div key={index} className={styles.produtcDetailsAlignment}>
              <div className={styles.productDetailsFlexAlignment}>
                <div className={styles.leftFlexAlignment}>
                  <div className={styles.productImg}>
                    <img src={item?.images[0].src ? item?.images[0].src : '/assets/img/nn1.png'} alt="product img" />
                  </div>
                  <div className={styles.productAllDetails}>
                    <h4>{item?.title}</h4>
                    <span>Price ${item?.dis_listPrice ? item?.dis_listPrice : item?.dis_price}</span>
                  </div>
                </div>
                {meeting?.self.presetName === 'livestream_host' ? (
                  <div className={styles.productDeleteIcon} onClick={() => handleRemoveCollection(item)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M3 6H5H21"
                        stroke="#E71616"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                        stroke="#E71616"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10 11V17"
                        stroke="#E71616"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14 11V17"
                        stroke="#E71616"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                ) : (
                  <div className={styles.addCartIcon} onClick={() => QuantityHandler(item)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M6 6.00098H3V20.001C3 20.5314 3.21071 21.0401 3.58579 21.4152C3.96086 21.7903 4.46957 22.001 5 22.001H19C19.5304 22.001 20.0391 21.7903 20.4142 21.4152C20.7893 21.0401 21 20.5314 21 20.001V6.00098H18H6Z"
                        stroke="#556EE6"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path d="M12 11V16.5385" stroke="#556EE6" strokeWidth="1.5" strokeLinecap="round" />
                      <path d="M14.7695 13.7695H9.23107" stroke="#556EE6" strokeWidth="1.5" strokeLinecap="round" />
                      <path
                        d="M8 6.00098C8 4.94011 8.42143 3.92269 9.17157 3.17255C9.92172 2.4224 10.9391 2.00098 12 2.00098C13.0609 2.00098 14.0783 2.4224 14.8284 3.17255C15.5786 3.92269 16 4.94011 16 6.00098"
                        stroke="#556EE6"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
          )
        })}

        {isShowProductModel && (
          <div className={styles.productDeleteCollectionModalWrapper}>
            <div className={styles.productDeleteCollecyionHeading}>
              <button onClick={() => setIsShowProductModel(false)}>X</button>
              {all_Products?.data?.getAllProductByStore?.data.map((item, index) => {
                return (
                  <div key={index} className={styles.produtcDetailsAlignment}>
                    <div className={styles.productDetailsFlexAlignment}>
                      <div className={styles.leftFlexAlignment}>
                        <div className={styles.productImg}>
                          <img
                            src={item?.images[0].src ? item?.images[0].src : '/assets/img/nn1.png'}
                            alt="product img"
                          />
                        </div>
                        <div className={styles.productAllDetails}>
                          <h4>{item?.title}</h4>
                          <span>Price ${item?.dis_listPrice ? item?.dis_listPrice : item?.dis_price}</span>
                        </div>
                      </div>
                      <div className={styles.addCartIcon} onClick={() => handleAddProductForSale(item)}>
                        <button>Add</button>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>

      {meeting?.self.presetName === 'livestream_host' && (
        <div className={styles.addProductButtonAlignment} onClick={() => setIsShowProductModel(true)}>
          <button>+ Add Product</button>
        </div>
      )}
    </div>
  )
}
