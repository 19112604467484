import { gql } from '@apollo/client'
import { ASTValidationContext } from 'graphql/validation/ValidationContext'

export const JOIN_LIVE_STREAM = gql`
  mutation JoinLiveStream($input: joinLiveStreamInput) {
    joinLiveStream(input: $input) {
      authToken
      message
      success
      store {
        logo
        name
      }
      user {
        profileAvtar
        name
      }
    }
  }
`
