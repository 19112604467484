import { gql } from '@apollo/client'

const query = gql`
  mutation CreateCart($input: CartInput!) {
    createCart(input: $input) {
      cart {
        product {
          id
        }
        quantity
      }
    }
  }
`
// const query = gql`
//   mutation CREATECART($parent_id: Int!, $quantity: Int!, $cart_for: String!, $variant_id: Int!) {
//     createCart(input: { parent_id: $parent_id, quantity: $quantity, cart_for: $cart_for, variant_id: $variant_id }) {
//       cart {
//         qty: quantity
//         product {
//           id
//         }
//         user {
//           id
//         }
//       }
//     }
//   }
// `
export default query
