import React from 'react'

export default function BookDetailInfo() {
  return (
    <div className="book-details-section">
      <h4>Book Detail</h4>
      <div className="book-all-details-alignment">
        <div>
          <div className="book-personal-details-alignment">
            <label>Title</label>
            <span>Billy Summers</span>
          </div>
          <div className="book-personal-details-alignment">
            <label>Author</label>
            <span>Stephen King</span>
          </div>
          <div className="book-personal-details-alignment">
            <label>Page</label>
            <span>120</span>
          </div>
          <div className="book-personal-details-alignment">
            <label>Dimension</label>
            <span>6 x 9 in</span>
          </div>
        </div>
        <div>
          <div className="book-personal-details-alignment">
            <label>Language </label>
            <span>English</span>
          </div>
          <div className="book-personal-details-alignment">
            <label>Publisher </label>
            <span>Scribner</span>
          </div>
          <div className="book-personal-details-alignment">
            <label>Published Date </label>
            <span>February 12, 2004 </span>
          </div>
          <div className="book-personal-details-alignment no-border">
            <label>ISBN </label>
            <span>1982173610</span>
          </div>
        </div>
      </div>
    </div>
  )
}
