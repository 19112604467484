import { gql } from '@apollo/client'

const GET_USER_ACTIVITY_REPORT = gql`
  query GetUserActivityReport($startDate: String!, $endDate: String!, $timeInterval: String) {
    getUserActivityReport(start_date: $startDate, end_date: $endDate, time_interval: $timeInterval) {
      data {
        current_post_count
        current_post_count_diff
        current_like_count
        current_like_count_diff_pct
        current_comment_count
        current_comment_count_diff_pct
      }
      success
      message
    }
  }
`
export default GET_USER_ACTIVITY_REPORT
