import React, { useEffect, useState } from 'react'
import CartFooter from 'src/components/Cart/CartFooter'
import CartProductSection from 'src/components/Cart/CartProductSection'
import dynamic from "next/dynamic";
const DynamicLottie = dynamic(() => import("react-lottie-player"), { ssr: false });
import emptyCart from '../onbording/lottie/empty-cart.json'
import NotificationIcon from '../../../public/assets/icon/notification-icon-blue.svg'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { fetchCartData } from 'src/store/slices/getCartCount'

const ShoppingCart = (props) => {
  const { setCheckoutModal, setCartModal, setProductDataForCheckout, isBack } = props

  const cartCount = useSelector((state: any) => state?.getCartCount?.cartData)
  const [tempProductArray, setTempProductArray] = useState([])
  const [productIdArray, setProductIdArray] = useState([])
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  useEffect(() => {
    if (cartCount?.length > 0) {
      let arrayId =
        cartCount &&
        cartCount[0]?.carts
          ?.map((prd: any) => {
            return prd.product?.map((pr) => {
              return {
                productId: pr?.product?.id,
                quantity: pr?.quantity,
                totalPrice:
                  (pr?.product?.variant?.price
                    ? Number(pr?.product?.variant?.price)
                    : pr?.product?.inventoryPrice?.price
                    ? Number(pr?.product?.inventoryPrice?.price)
                    : 0) * pr?.quantity,
                variant_id: pr?.product?.variant?.id,
              }
            })
          })
          .flat()
      if (arrayId?.length > 0) {
        setProductIdArray(arrayId)
      } else {
        setProductIdArray([])
      }
    }
  }, [cartCount])

  useEffect(() => {
    dispatch(fetchCartData())
  }, [])

  const handleClose = () => {
    let datass = getAllState?.allModalData?.filter((item) => item?.modal !== getAllState?.lastModalData?.modal)
    dispatch(
      fetchModalData({
        allModalData: datass,
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  return (
    // <div>
    <>
      <div className="cart-page-container">
        {isBack && (
          <div className="mobile-cart-back-alignment">
            <div className="back_details-alignment" onClick={() => handleClose()}>
              <div>
                <img src="/assets/icon/left-icon.svg" alt="back-page" />
              </div>
              <p>Back</p>
            </div>
            <div className="notification-alignment">
                <div className="chat-notification-icon" style={{ position: 'absolute', bottom: '-7px', right: '-5px', backgroundColor: 'red', borderRadius: '50%', padding: '4px 8px', color: 'white', fontSize: '12px' }}>
                <span>{cartCount?.[0]?.carts?.length || 0}</span>
                </div>
              <img src={NotificationIcon.src} alt="NotificationIcon" />
            </div>
          </div>
        )}
        <div className="cart-main-header">
          <div className="cart-main-text">
            <span>Cart</span>
          </div>
          <div className="prouct-main-count">
            <p>
              (
              {cartCount
                ?.map(({ carts }) => carts.map(({ product }) => product.length))
                .flat()
                .reduce((total, length) => total + length, 0) || 0}{' '}
              items)
            </p>
          </div>
        </div>
        <div className="cart-main-contain">
          {cartCount && cartCount[0]?.carts?.length > 0 ? (
            cartCount &&
            cartCount[0]?.carts?.map((product, index) => {
              return (
                <CartProductSection
                  index={index}
                  product={product}
                  setTempProductArray={setTempProductArray}
                  tempProductArray={tempProductArray}
                  productIdArray={productIdArray}
                  setProductIdArray={setProductIdArray}
                />
              )
            })
          ) : (
            <div className="empty-cart-section">
              <div className="empty-cart-sub-section">
                <div>
                  <DynamicLottie loop={false} animationData={emptyCart} play className="lottie-img" />
                </div>
                <div className="empty-cart-text">
                  <span>Your Cart is Empty</span>
                </div>
                <div className="empty-cart-button-section">
                  <div className="empty-cart-button">
                    <button
                    // onClick={handleGetKey}
                    >
                      <img src="/assets/img/cart/empty-cart-icon.svg" alt="search" className="search-icon" />
                      <span>Explore Product</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        <CartFooter productIdArray={productIdArray} />
      </div>
    </>
    // </div>
  )
}

export default ShoppingCart
