import styles from "./LiveStreams.module.scss";
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import moment from 'moment'
import { StreamReactionButton } from "../StreamReactionButton";

const LiveStreamsCard = ({ item, isHost }: { item: any, isHost?: boolean }) => {
    const dispatch = useAppDispatch()
    const getAllState = useSelector((state: any) => state?.manageModalData?.data)
    const handleLiveStream = (data) => {
        dispatch(
            fetchModalData({
                allModalData: [
                    ...getAllState?.allModalData,
                    {
                        modal: 'liveStream',
                        isOpen: true,
                        modalFor: 'bigModal',
                        data: {
                            uuid: data?.uuid,
                            isHost: !!isHost,
                            isStartStream: !isHost,
                            hostName: data?.title,
                            finalTime: data?.final_date,
                        },
                        startDate: moment().toISOString(),
                    },
                ],
                lastModalData: getAllState?.lastModalData,
            }),
        )
    }
    return (
        <div key={item?.id} className={styles.channelDetailsLiveSliderAlignment} onClick={() => handleLiveStream(item)}>
            <div className={styles.channelDetailsLiveSliderImgAlignment}>
                <img src={item?.media} alt="live img" />

                <div className={styles.channelDetailsLiveSliderAllDetails}>
                    <div className={styles.channelDetailsLiveAllDetails}>
                        <div className={styles.channelDetailsliveAndViewDetailsALignment}>
                            <div className={styles.channelBannerLiveButtonAlignment}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                >
                                    <g clipPath="url(#clip0_1106_72)">
                                        <path
                                            d="M8.00016 14.6668C11.6821 14.6668 14.6668 11.6821 14.6668 8.00016C14.6668 4.31826 11.6821 1.3335 8.00016 1.3335C4.31826 1.3335 1.3335 4.31826 1.3335 8.00016C1.3335 11.6821 4.31826 14.6668 8.00016 14.6668Z"
                                            stroke="white"
                                            strokeWidth="1.25"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M7.90674 11.0229C9.56359 11.0229 10.9067 9.6798 10.9067 8.02295C10.9067 6.36609 9.56359 5.02295 7.90674 5.02295C6.24988 5.02295 4.90674 6.36609 4.90674 8.02295C4.90674 9.6798 6.24988 11.0229 7.90674 11.0229Z"
                                            fill="white"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1106_72">
                                            <rect width="16" height="16" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>

                                <p>LIVE</p>
                            </div>

                            <div className={styles.channelBannerLiveViewNumberAlignment}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                >
                                    <path
                                        d="M0.666992 7.99984C0.666992 7.99984 3.33366 2.6665 8.00033 2.6665C12.667 2.6665 15.3337 7.99984 15.3337 7.99984C15.3337 7.99984 12.667 13.3332 8.00033 13.3332C3.33366 13.3332 0.666992 7.99984 0.666992 7.99984Z"
                                        stroke="#E4E4E8"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                                        stroke="#E4E4E8"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <p>{item.participants_count}</p>
                            </div>
                        </div>

                        <div className={styles.channelDetailsBottomDetailsAlignment}>
                            <div className={styles.channelDetailsHeadingNameAlignment}>
                                <h6>{item?.title}</h6>
                                <div className={styles.channelDetailsLogoAlignment}>
                                    <div className={styles.channelDetailsLogoImg}>
                                        <img src={item?.store_logo} alt="logo" />
                                    </div>
                                    <p>{item?.store_name}</p>
                                </div>
                            </div>
                            <div className={styles.channelDetailsLikeShareAlignment}>
                                <StreamReactionButton item={item} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LiveStreamsCard;
