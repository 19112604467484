import React, { useState, forwardRef, useImperativeHandle } from 'react'
import styles from './PostheaderMobile.module.scss'

interface PostheaderMobileProps {
  menu: React.ReactElement
  Zindexmodal: any
  onOverlayClick?: () => void
}

const PostheaderMobile = forwardRef<{ toggleOpen: () => void }, PostheaderMobileProps>(({ menu, Zindexmodal, onOverlayClick }, ref) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
    if (isOpen && onOverlayClick) {
      onOverlayClick()
    }
  }

  useImperativeHandle(ref, () => ({
    toggleOpen: () => {
      setIsOpen(!isOpen)
    },
  }))

  return (
    <>
      <div className={`${styles.PostheaderMobileoverlay} ${isOpen ? styles.open : ''}`} onClick={toggleOpen}></div>
      <div className={`${styles.PostheaderMobilemain} ${isOpen ? styles.open : ''}`} style={{ zIndex: Zindexmodal }}>
        <div className={styles.storeproductfilterclickdropalignment} onClick={toggleOpen}>
          <p></p>
        </div>
        <div className={`${styles.mobileviewdetailsalignment} mobileviewdetailsalignment`}>{menu}</div>
      </div>
    </>
  )
})

export default PostheaderMobile
