import { gql } from '@apollo/client'

export default gql`
  query GET_PRODUCT_BY_NAME($slug: String!) {
    getSingleProduct(slug: $slug) {
      id
      slug
      store_id
      title
      description
      isFeature
      isActive
      metaTitle
      keywords
      metaDescription
      isVisible
      isFreeShipping
      condition
      is_deleted
      variants {
        id
        title
        price
        sku
        barcode
        listPrice
        inventory_quantity
        old_inventory_quantity
        image_id
        image {
          media
        }
        total_variant {
          variant_option_id
          variant_option {
            value
            variation_id
          }
        }
      }
      store {
        id
        name
        slug
      }
      shareCount {
        id
      }
      productLikes {
        id
      }
      inventoryPrice {
        price
        listPrice
        quantity
        sku
      }
      shipping {
        weightValue
        weightUnit
        unit
        length
        width
        height
      }
      categories {
        #category_id
        #subCategory_id
        #childSubCategory_id
        category {
          id
          name
        }
        subCategory {
          id
          name
        }
        childSubCategory {
          id
          name
        }
      }
      options {
        name
        data {
          value
          colorCode
        }
      }
      attributes {
        name
        value
      }
      images {
        media_id
        src
      }
      tags {
        tag
      }
      size {
        height
        width
      }
      ratio
    }
  }
`
