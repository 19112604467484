import { gql } from '@apollo/client'

export const UPDATE_SHIPPING_METHOD = gql`
  mutation UpdateShippingMethod($input: updateShippingInput!) {
    updateShippingMethod(input: $input) {
      message
      success
    }
  }
`

export default UPDATE_SHIPPING_METHOD
