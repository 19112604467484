import { gql } from '@apollo/client'

const query = gql`
  mutation SEND_FRIEND_REQUEST($friend_id: Int!, $user_id: Int!, $isActive: Boolean, $isFriend: Boolean) {
    addFriend(input: { friend_id: $friend_id, user_id: $user_id, isActive: $isActive, isFriend: $isFriend }) {
      id
    }
  }
`
export default query
