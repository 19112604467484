import React, { useEffect, useRef, useState } from 'react'
import ProfileImg from '../../../../../public/assets/img/profile/profileimage.png'
import MoreIcon from '../../../../../public/assets/icon/moreOptionIcon.svg'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
export default function UserHeader({ setTab, tab, userData }) {
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const fetchSroll = useSelector((state: any) => state?.userProfileModalScroll?.data)
  const [tabIndex, setTabIndex] = useState(0)
  const [scrollIndex, setScrollIndex] = useState(0)

  function activeLink(id) {
    setTabIndex(id)
    const navlink: any = document.querySelectorAll('.nav__link')
    navlink.forEach((item) => item.classList.remove('active-link'))
    navlink.forEach((item, index) => {
      if (index === id) {
        return item.classList.add('active-link')
      } else {
        return item
      }
    })
  }

  const handleClose = () => {
    let datass = getAllState?.allModalData?.filter((item) => item?.modal !== getAllState?.lastModalData?.modal)
    dispatch(
      fetchModalData({
        allModalData: datass,
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  return (
    <>
      {/* <----------------------------------------------USER_PROFILE_WEB_VIEW_TAB_START-------------------------------------------------------> */}
      <div className="social-product-tab-alignment">
        <nav className="nav">
          <ul className="nav__list p-4">
            <a
              className="nav__link active-link mr-5"
              onClick={() => {
                activeLink(0)
                setTab({ tab: 'feed', isWeb: true })
              }}
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.15065 16.6665C7.15397 16.6665 7.96732 15.8532 7.96732 14.8499C7.96732 13.8466 7.15397 13.0332 6.15065 13.0332C5.14733 13.0332 4.33398 13.8466 4.33398 14.8499C4.33398 15.8532 5.14733 16.6665 6.15065 16.6665Z"
                  fill={tabIndex === 0 ? '#000000' : '#ACB1C0'}
                />
                <path
                  d="M5.65898 8.52487C4.95898 8.4082 4.33398 8.9832 4.33398 9.69153C4.33398 10.2832 4.77565 10.7582 5.35898 10.8582C7.79232 11.2832 9.70898 13.2082 10.1423 15.6415C10.2423 16.2249 10.7173 16.6665 11.309 16.6665C12.0173 16.6665 12.5923 16.0415 12.484 15.3415C12.2061 13.6326 11.3974 12.0545 10.1724 10.831C8.94735 9.60745 7.36826 8.80062 5.65898 8.52487ZM5.63398 3.76653C4.94232 3.69153 4.33398 4.24987 4.33398 4.94153C4.33398 5.54987 4.79232 6.04987 5.39232 6.1082C10.4007 6.6082 14.384 10.5915 14.884 15.5999C14.9423 16.2082 15.4423 16.6665 16.0507 16.6665C16.7507 16.6665 17.3007 16.0582 17.234 15.3665C16.6257 9.24987 11.759 4.37487 5.63398 3.76653Z"
                  fill={tabIndex === 0 ? '#000000' : '#ACB1C0'}
                />
              </svg>
              <span className="nav__name">Feed</span>
            </a>
            <a
              className="nav__link "
              onClick={() => {
                activeLink(1)
                setTab({ tab: 'media', isWeb: true })
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M22 8V13.81C21.12 13.3 20.1 13 19 13C15.69 13 13 15.69 13 19C13 19.34 13.04 19.67 13.09 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0391 2 18.5304 2 18V6C2 4.89 2.89 4 4 4H10L12 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8ZM17 22L22 19L17 16V22Z"
                  fill={tabIndex === 1 ? '#000000' : '#ACB1C0'}
                />
              </svg>
              <span className="nav__name">Media</span>
            </a>
            {/* <svg
              width="116"
              height="78"
              viewBox="0 0 116 78"
              fill="none"
              className="indicator"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 78H116C107.163 78 100 70.8366 100 62V16C100 7.16344 107.163 0 116 0H0C8.83656 0 16 7.16344 16 16V62C16 70.8366 8.83656 78 0 78Z"
                fill="#ECEEF4"
              />
            </svg> */}
          </ul>
        </nav>
      </div>
      {/* <----------------------------------------------USER_PROFILE_WEB_VIEW_TAB_END---------------------------------------------------------> */}

      <div className="stcky-header-alignment">
        {/* <----------------------------------------------USER_PROFILE_MOBILE_VIEW_BACK_START-------------------------------------------------------> */}
        {fetchSroll?.scroll >= 500 && (
          <div className="mobile-view-heading-section">
            <div className="store-back_details-alignment">
              <div>
                <img src="/assets/icon/left-icon.svg" alt="back-page" />
              </div>
              <p onClick={() => handleClose()}>Back</p>
            </div>
            <div className="store-mobile-header-alignment">
              <div className="mobile-profile-img-alignment">
                <img src={userData?.profileUrl} alt="ProfileImg" />
              </div>
              <div className="mobile-profile-name-alignment">
                <h5>{userData?.firstName + ' ' + userData?.lastName}</h5>
              </div>
            </div>
            <div className="store-more-option-alignment">
              <img src={MoreIcon.src} alt="MoreIcon" />
            </div>
          </div>
        )}
        {/* <----------------------------------------------USER_PROFILE_MOBILE_VIEW_BACK_END-------------------------------------------------------> */}

        {/* <----------------------------------------------USER_PROFILE_MOBILE_VIEW_TAB_START-------------------------------------------------------> */}
        <div className="mobile-view-social-tab-alignment">
          <div className="mobile-view-tab-box-alignment">
            <ul>
              <li
                className={tab?.tab === 'feed' && 'tab-active'}
                onClick={() => {
                  setTab({ tab: 'feed', isWeb: false })
                }}
              >
                <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.15065 16.6665C7.15397 16.6665 7.96732 15.8532 7.96732 14.8499C7.96732 13.8466 7.15397 13.0332 6.15065 13.0332C5.14733 13.0332 4.33398 13.8466 4.33398 14.8499C4.33398 15.8532 5.14733 16.6665 6.15065 16.6665Z"
                    fill={tabIndex === 0 ? '#000000' : '#ACB1C0'}
                  />
                  <path
                    d="M5.65898 8.52487C4.95898 8.4082 4.33398 8.9832 4.33398 9.69153C4.33398 10.2832 4.77565 10.7582 5.35898 10.8582C7.79232 11.2832 9.70898 13.2082 10.1423 15.6415C10.2423 16.2249 10.7173 16.6665 11.309 16.6665C12.0173 16.6665 12.5923 16.0415 12.484 15.3415C12.2061 13.6326 11.3974 12.0545 10.1724 10.831C8.94735 9.60745 7.36826 8.80062 5.65898 8.52487ZM5.63398 3.76653C4.94232 3.69153 4.33398 4.24987 4.33398 4.94153C4.33398 5.54987 4.79232 6.04987 5.39232 6.1082C10.4007 6.6082 14.384 10.5915 14.884 15.5999C14.9423 16.2082 15.4423 16.6665 16.0507 16.6665C16.7507 16.6665 17.3007 16.0582 17.234 15.3665C16.6257 9.24987 11.759 4.37487 5.63398 3.76653Z"
                    fill={tabIndex === 0 ? '#000000' : '#ACB1C0'}
                  />
                </svg>
                <p>Feed</p>
              </li>

              <li
                className={tab?.tab === 'media' && 'tab-active'}
                onClick={() => {
                  setTab({ tab: 'media', isWeb: true })
                }}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M22 8V13.81C21.12 13.3 20.1 13 19 13C15.69 13 13 15.69 13 19C13 19.34 13.04 19.67 13.09 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0391 2 18.5304 2 18V6C2 4.89 2.89 4 4 4H10L12 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8ZM17 22L22 19L17 16V22Z"
                    fill={tabIndex === 1 ? '#000000' : '#ACB1C0'}
                  />
                </svg>
                <p>Media</p>
              </li>
            </ul>
          </div>
        </div>
        {/* <----------------------------------------------USER_PROFILE_MOBILE_VIEW_TAB_END---------------------------------------------------------> */}
      </div>
    </>
  )
}
