import { gql } from '@apollo/client'

const TAX_CALCULATION = gql`
  mutation TaxCalculation($city: String, $state: String, $zipcode: String!) {
  taxCalculation(city: $city, State: $state, zipcode: $zipcode) {
    data {
      additional_rate
      city_rate
      county_rate
      state_rate
      total_rate
      zip_code
    }
    message
    success
  }
}
`
export default TAX_CALCULATION
