import React, { useEffect, useState } from 'react'
import styles from './AddProductModal.module.scss'
import AddProductModalTitle from './AddProductModalTitle'
import AddProductModalImages from './AddProductModalImages'
import AddProductModalVariant from './AddProductModalVariant'
import AddProductModalAttributes from './AddProductModalAttributes'
import AddProductModalSEO from './AddProductModalSEO'
import AddProductModalProductVisibility from './AddProductModalProductVisibility'
import AddProductModalProductFeaturedProduct from './AddProductModalProductFeaturedProduct'
import AddProductInventoryPricing from './AddProductInventoryPricing'
import AddProductShipping from './AddProductShipping'
import AddProductFreeShipping from './AddProductFreeShipping'
import AddProductCondition from './AddProductCondition'
import AddProductTags from './AddProductTags'
import AddVariant from './AddVariant'
import { useMutation, useQuery } from '@apollo/client'
import GET_CATEGORIES_QUERY from 'src/graphql/queries/getCategories'
import AddProductModalDescription from './AddProductModalDescription'
import AddProductModalCategory from './AddProductModalCategory'
import AddSizeModal from './AddVariant/AddSizeModal/AddSizeModal'
import AddColorModal from './AddVariant/AddColorModal'
import AddOtherVariantModal from './AddVariant/AddOtherVariantModal'
import AddProductModalHeader from './AddProductModalHeader'
import { useSelector } from 'react-redux'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'
import { useAppDispatch } from 'src/store/store'
import { ApiPost } from 'src/Helpers/Api/ApiData'
import GET_PRODUCTS_QUERY from 'src/graphql/queries/getAllProduct'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { UPDATE_PRODUCT_MUTATION } from 'src/graphql/mutations/updateProduct'
import { useAuth } from 'src/utils/auth'
import { API } from 'src/config/API/api.config'
import { fetchProductErrorHandler } from 'src/store/slices/productErrorHandlerSlice'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
import AddProductBrand from './AddProductBrand'
import GET_ALL_PRODUCTS_BY_STOREID from 'src/graphql/queries/getAllProductsByStoreId'
import sellerClient from 'src/utils/apolloSellerClient'
import TumbnailPreviewModal from './TumbnailPreviewModal'
import AddProductProductStatus from './AddProductProductStatus'
import classNames from 'classnames'
import GET_ALL_PRODUCT_BY_STORE from 'src/graphql/queries/getAllProductByStore'
import axios from 'axios'
import nookies from 'nookies'
import AddProductCategorySelection from './AddProductCategorySelection'
import client1 from 'src/utils/apolloClient'
import { CREATE_PRODUCT_MUTATION } from 'src/graphql/mutations/createProduct'

export default function AddProductModal(props) {
  const { user }: any = useAuth()
  const dispatch = useAppDispatch()

  const [createProduct, { loading: isCreating }] = useMutation(CREATE_PRODUCT_MUTATION)

  const fetchProductImageResizeData = useSelector((state: any) => state?.productResizeData?.data)
  const fetchProductErrorHandlerSlice = useSelector((state: any) => state?.productErrorHandlerSlice?.data)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)

  const [data, setData] = useState<any>()

  const getProduct = async () => {
    const { data } = await client1.query({
      query: GET_ALL_PRODUCTS_BY_STOREID,
      variables: {
        id: getAllState?.lastModalData?.id,
      },
      fetchPolicy: 'network-only',
    })
    setData(data)
  }

  useEffect(() => {
    if (Boolean(getAllState?.lastModalData?.id)) {
      getProduct()
    }
  }, [getAllState?.lastModalData?.id])

  const [editProductData, setEditProductData] = useState<any>()
  const [productData, setProductData] = useState<any>(addProductDetailSlice?.id?.mobileProductsData || editProductData)
  const [tags, setTags] = useState(editProductData?.tags?.map((item) => item.tag) ?? [])

  useEffect(() => {
    setProductData(addProductDetailSlice?.id?.mobileProductsData)
    setTags(addProductDetailSlice?.id?.mobileProductsData?.tags)
  }, [])

  useEffect(() => {
    async function fetchData() {
      if (getAllState?.lastModalData?.id && getAllState?.isUpdateState != true) {
        let updatedImagesArray = await data?.getSingleProductById?.images?.map((img) => {
          return {
            id: '465361af-713b-423c-96d6-b6e1d1e2014b',
            mediaId: img?.media_id,
            isEdit: true,
            oldFile: {
              mediaId: img?.media_id,
              baseURL: img?.src,
              zoom: 1,
              rotate: 0,
              crop: {
                x: 0,
                y: 0,
              },
            },
            croppedFile: {
              mediaId: 212,
              baseURL: img?.src,
              zoom: 1,
              rotate: 0,
              crop: {
                x: 0,
                y: 0,
              },
            },
          }
        })

        let arrayOfString = await data?.getSingleProductById?.images?.map((img) => {
          return img?.src
        })

        const newVariantsArray = await data?.getSingleProductById?.variants.map((item) => {
          let { __typename, ...rest } = item
          return { ...rest }
        })

        const attributes =
          (await data?.getSingleProductById?.attributes?.map((item) => {
            return {
              name: item?.name,
              value: item?.value,
            }
          })) ?? []

        const convertedArray = data?.getSingleProductById?.options.map((item) => {
          return {
            name: item.name,
            data: item.data.map((dataItem) => dataItem.value),
          }
        })

        setEditProductData(data?.getSingleProductById)
        setAttributeArray(attributes)
        setTags(data?.getSingleProductById?.tags?.map((item) => item.tag) ?? [])
        // setProductData(data?.getSingleProductById)
        setProductData({
          id: data?.getSingleProductById?.id,
          store_id: data?.getSingleProductById?.store,
          title: data?.getSingleProductById?.title,
          image: arrayOfString,
          description: data?.getSingleProductById?.description,
          status: data?.getSingleProductById?.status,
          brand_id: data?.getSingleProductById?.brand_id,
          video: data?.getSingleProductById?.video,
          category: data?.getSingleProductById?.categories,
          cropImages: updatedImagesArray,
          images: arrayOfString,
          ratio: data?.getSingleProductById?.ratio,
          size: {
            height: data?.getSingleProductById?.size?.height,
            width: data?.getSingleProductById?.size?.width,
          },
          options: data?.getSingleProductById?.options,
          variant: newVariantsArray,
          seo: {
            metaTitle: data?.getSingleProductById?.metaTitle,
            metaDescription: data?.getSingleProductById?.metaDescription,
            keywords: data?.getSingleProductById?.keywords,
          },
          isVisible: data?.getSingleProductById?.isVisible,
          isFeature: data?.getSingleProductById?.isFeature,
          inventoryPrice: {
            price: data?.getSingleProductById?.inventoryPrice?.price ?? '0',
            listPrice: data?.getSingleProductById?.inventoryPrice?.listPrice ?? 0,
            quantity: data?.getSingleProductById?.inventoryPrice?.quantity ?? 0,
            sku: data?.getSingleProductById?.inventoryPrice?.sku ?? '0',
          },
          shipping: {
            weight: {
              value: data?.getSingleProductById?.shipping?.weightValue ?? 0,
              unit: data?.getSingleProductById?.shipping?.weightUnit ?? 'lbs', //I will send you anyone of the two value. 1.kg, 2.lbs
            },
            length: data?.getSingleProductById?.shipping?.length ?? '',
            width: data?.getSingleProductById?.shipping?.width ?? '',
            height: data?.getSingleProductById?.shipping?.height ?? '',
            unit: data?.getSingleProductById?.shipping?.unit ?? 'in', //I will send you anyone of the two value. 1.m, 2.cm, 3.inch
            // unit: productData?.shipping?.unit
          },
          isFreeShipping: data?.getSingleProductById?.isFreeShipping,
          condition: data?.getSingleProductById?.condition, //I will send you anyone of the two value. 1.New and 2.Used
          tags: data?.getSingleProductById?.tags,
        })

        let sizeMap =
          (await data?.getSingleProductById?.options
            ?.find((data) => data.name === 'size' || data?.name === 'SIZE')
            ?.data?.map((variant) => {
              return variant?.value
            })) || []
        let sizeData = Boolean(sizeMap.length) ? [{ name: 'SIZE', data: sizeMap }] : []

        let colorMap =
          (await data?.getSingleProductById?.options
            ?.find((data) => data.name === 'color' || data?.name === 'Color')
            ?.data?.map((variant) => {
              return { name: variant?.value }
            })) || []
        let colorData = Boolean(colorMap.length) ? [{ name: 'Color', data: colorMap }] : []

        let otherData = await data?.getSingleProductById?.options?.filter(
          (data) => data.name !== 'Color' && data.name !== 'SIZE' && data.name !== 'color' && data.name !== 'size',
        )

        let colorDataWithCode =
          (await data?.getSingleProductById?.options
            ?.find((data) => data.name === 'color' || data?.name === 'Color')
            ?.data?.map((variant) => {
              return { name: variant?.value, colorCode: variant?.colorCode }
            })) || []

        let colorsFinalData = Boolean(colorDataWithCode.length) ? [{ name: 'Color', data: colorDataWithCode }] : []

        let finalOtherData =
          (await otherData?.map((data) => {
            return { name: data?.name, data: data?.data?.map((variant) => variant?.value) }
          })) || []
        let Array = [...(sizeData ?? []), ...(colorData ?? []), ...(finalOtherData ?? [])]

        const updatedVariantArray = await newVariantsArray?.map((item) => {
          return {
            ...item,
            total_variant: convertTotalVariant(item.total_variant),
          }
        })

        let colorsData = await data?.getSingleProductById?.options
          ?.find((data) => data.name === 'Color' || data?.name === 'color')
          ?.data?.map((variant) => {
            return { name: variant?.value, colorCode: variant?.colorCode }
          })
        const editVariantArray = removeTypename(data?.getSingleProductById?.options)

        const transformedArray = [...sizeData, ...colorsFinalData, ...finalOtherData]
        // const transformedArray = editVariantArray?.map((item) => {
        //   if (item.name.toLowerCase() === 'size') {
        //     // If the name is 'size', transform the data accordingly
        //     const sizeData = item.data.map((sizeItem) => sizeItem.value)
        //     return {
        //       name: 'SIZE',
        //       data: sizeData,
        //     }
        //   } else if (item.name.toLowerCase() === 'color') {
        //     // If the name is 'color', transform the data accordingly
        //     const colorData = item?.data?.map((colorItem) => {
        //       return {
        //         name: colorItem.value,
        //         colorCode: colorItem.colorCode,
        //       }
        //     })
        //     return {
        //       name: 'Color',
        //       data: colorData,
        //     }
        //   } else {
        //     // If the name is neither 'size' nor 'color', return the item as is
        //     return item
        //   }
        // })

        const filteredPrices = data?.getSingleProductById.variants
          .filter((item) => parseFloat(item.price) > 0) // Filter out items with price less than or equal to 0
          .map((item) => parseFloat(item.price)) // Map prices to numbers

        const smallestPrice = filteredPrices?.reduce((min, price) => (price < min ? price : min), filteredPrices[0])

        const filteredListPrices = data?.getSingleProductById.variants
          .filter((item) => parseFloat(item.listPrice) > 0) // Filter out items with listPrice less than or equal to 0
          .map((item) => parseFloat(item.listPrice))

        const smallestListPrice = filteredListPrices?.reduce(
          (min, listPrice) => (listPrice < min ? listPrice : min),
          filteredListPrices[0],
        )

        await dispatch(
          manageAddProductDetail({
            modal: '',
            isOpen: false,
            id: {
              ...addProductDetailSlice?.id,
              variant: {
                ...addProductDetailSlice?.id?.variant,
                allVariant: Array,
                allFinalVariant: Array,
                allOptions: updatedVariantArray,
                colorCodeArray: transformedArray,
                // colorCodeArray: colorsData,
                // dataEdit: {
                //   color: colorsFinalData?.[0],
                // },
              },

              productPreview: {
                ...addProductDetailSlice?.id?.productPreview,
                title: data?.getSingleProductById?.title,
                image: arrayOfString,
                price:
                  data?.getSingleProductById?.variants.length > 0 ? smallestPrice : data?.getSingleProductById?.price,
                listPrice:
                  data?.getSingleProductById?.variants.length > 0
                    ? smallestListPrice
                    : data?.getSingleProductById?.listPrice,
              },

              mobileProductsData: {
                ...addProductDetailSlice?.id?.mobileProductsData,
                id: data?.getSingleProductById?.id,
                store_id: data?.getSingleProductById?.store,
                title: data?.getSingleProductById?.title,
                image: arrayOfString,
                status: data?.getSingleProductById?.status,
                brand_id: data?.getSingleProductById?.brand_id,
                video: data?.getSingleProductById?.video,
                category: data?.getSingleProductById?.categories,
                cropImages: updatedImagesArray,
                images: arrayOfString,
                ratio: data?.getSingleProductById?.ratio,
                size: {
                  height: data?.getSingleProductById?.size?.height,
                  width: data?.getSingleProductById?.size?.width,
                },
                options: data?.getSingleProductById?.options,
                variant: newVariantsArray,
                seo: {
                  metaTitle: data?.getSingleProductById?.metaTitle,
                  metaDescription: data?.getSingleProductById?.metaDescription,
                  keywords: data?.getSingleProductById?.keywords,
                },
                isVisible: data?.getSingleProductById?.isVisible,
                isFeature: data?.getSingleProductById?.isFeature,
                inventoryPrice: {
                  price: data?.getSingleProductById?.price ?? '0',
                  listPrice: data?.getSingleProductById?.listPrice ?? 0,
                  quantity: data?.getSingleProductById?.quantity ?? 0,
                  sku: data?.getSingleProductById?.sku ?? '0',
                },
                shipping: {
                  weight: {
                    value: data?.getSingleProductById?.shipping?.weightValue ?? 0,
                    unit: data?.getSingleProductById?.shipping?.weightUnit ?? 'lbs',
                  },
                  length: data?.getSingleProductById?.shipping?.length ?? '',
                  width: data?.getSingleProductById?.shipping?.width ?? '',
                  height: data?.getSingleProductById?.shipping?.height ?? '',
                  unit: data?.getSingleProductById?.shipping?.unit ?? 'in',
                  // unit: productData?.shipping?.unit
                },
                isFreeShipping: data?.getSingleProductById?.isFreeShipping,
                condition: data?.getSingleProductById?.condition,
                // tags: data?.getSingleProductById?.tags,
                tags: data?.getSingleProductById?.tags?.map((item) => item.tag) ?? [],
              },
            },
          }),
        )

        let categoryId = await data?.getSingleProductById?.categories?.map((cat) => {
          return {
            category_id: cat?.category_id,
            subCategory_id: cat?.subCategory_id,
            childSubCategory_id: cat?.childSubCategory_id,
          }
        })

        await dispatch(
          fetchProductImageResizeModal({
            modal: '',
            isOpen: false,
            id: {
              ...fetchProductImageResizeData?.id,
              selectedCategories: categoryId,
              media: updatedImagesArray,
              isSelected: true,
              ratio: data?.getSingleProductById?.ratio,
              selectedSize: {
                height: data?.getSingleProductById?.size?.height || 1080,
                width: data?.getSingleProductById?.size?.width || 1080,
              },
            },
          }),
        )
      } else {
        setProductData({
          id: null,
          store_id: null,
          title: '',
          image: [],
          status: '',
          brand_id: null,
          video: [],
          category: [],
          cropImages: [],
          ratio: '',
          size: {
            height: null,
            width: null,
          },
          options: [],
          variant: [],
          seo: {
            metaTitle: '',
            metaDescription: '',
            keywords: '',
          },
          isVisible: false,
          isFeature: false,
          inventoryPrice: {
            price: '',
            listPrice: '',
            quantity: '',
            sku: '',
          },
          shipping: {
            weight: {
              value: '',
              unit: '', //I will send you anyone of the two value. 1.kg, 2.lbs
            },
            length: 0,
            width: 0,
            height: 0,
            unit: 0, //I will send you anyone of the two value. 1.m, 2.cm, 3.inch
            // unit: productData?.shipping?.unit
          },
          isFreeShipping: false,
          condition: 'New', //I will send you anyone of the two value. 1.New and 2.Used
          tags: [],
        })
      }
      // if (data?.getSingleProductById && getAllState?.lastModalData?.isUpdateState === false) {
      //   await dispatch(
      //     fetchModalData({
      //       ...getAllState,
      //       isUpdateState: true,
      //     }),
      //   )
      // }
    }
    fetchData()
  }, [data, editProductData?.variants?.length])

  function convertTotalVariant(variant) {
    return variant.map((item) => item.variant_option.value)
  }

  // const [tags, setTags] = useState(editProductData?.tags?.map((item) => item.tag) ?? [])
  const [attributeArray, setAttributeArray] = useState([])
  const [validation, setvalidation] = useState({})
  const [loadingProduct, setLoadingProduct] = useState(false)
  const [deviceType, setDeviceType] = useState(0)

  // const newValue = {
  //   title: editProductData?.title ? editProductData?.title : '',
  //   image: editProductData?.images ? editProductData?.images : [],
  //   description: editProductData?.description ? editProductData?.description : '',
  //   category: editProductData?.categories ? editProductData?.categories : [],
  //   variant: editProductData?.variants ? editProductData?.variants : [],
  //   attributes: [
  //     {
  //       name: '',
  //       value: '',
  //     },
  //   ],
  //   seo: {
  //     metaTitle: editProductData?.seo?.metaTitle ? editProductData?.seo?.metaTitle : '',
  //     metaDescription: editProductData?.seo?.metaDescription ? editProductData?.seo?.metaDescription : '',
  //     keywords: editProductData?.seo?.keywords ? editProductData?.seo?.keywords : '',
  //   },
  //   isVisible: editProductData?.isVisible ? editProductData?.isVisible : false,
  //   isFeature: editProductData?.isFeature ? editProductData?.isFeature : false,
  //   inventoryPrice: {
  //     price:
  //       addProductDetailSlice?.id.variant.allFinalVariant?.length < 1 && editProductData?.inventoryPrice?.price
  //         ? editProductData?.inventoryPrice?.price
  //         : 0,
  //     listPrice:
  //       addProductDetailSlice?.id.variant.allFinalVariant?.length < 1 && editProductData?.inventoryPrice?.listPrice
  //         ? editProductData?.inventoryPrice?.listPrice
  //         : 0,
  //     quantity:
  //       addProductDetailSlice?.id.variant.allFinalVariant?.length < 1 && editProductData?.inventoryPrice?.quantity
  //         ? editProductData?.inventoryPrice?.quantity
  //         : 0,
  //     sku:
  //       addProductDetailSlice?.id.variant.allFinalVariant?.length < 1 && editProductData?.inventoryPrice?.sku
  //         ? editProductData?.inventoryPrice?.sku
  //         : '',
  //   },
  //   shipping: {
  //     weight: {
  //       value: editProductData?.shipping?.weightValue ? editProductData?.shipping.weightValue : 0,
  //       unit: editProductData?.shipping?.weightUnit ? editProductData?.shipping.weightUnit : 'lbs',
  //     },
  //     length: editProductData?.shipping?.length ? editProductData?.shipping.length : '',
  //     width: editProductData?.shipping?.width ? editProductData?.shipping?.width : '',
  //     height: editProductData?.shipping?.height ? editProductData?.shipping?.height : '',
  //     unit: editProductData?.shipping?.unit ? editProductData?.shipping?.unit : 'in',
  //   },
  //   isFreeShipping: editProductData?.isFreeShipping ? editProductData?.isFreeShipping : false,
  //   condition: editProductData?.condition ? editProductData?.condition : 'New',
  //   tags: editProductData?.tags?.map((item) => item.tag) ?? [],
  // }

  // const [productData, setProductData] = useState<any>(newValue)

  // useEffect(() => {
  //   setProductData(editProductData)
  // }, [editProductData])

  function removeTypename(obj) {
    if (Array.isArray(obj)) {
      return obj.map((item) => removeTypename(item))
    } else if (typeof obj === 'object' && obj !== null) {
      const newObj = {}
      for (let key in obj) {
        if (key !== '__typename') {
          newObj[key] = removeTypename(obj[key])
        }
      }
      return newObj
    }
    return obj
  }

  useEffect(() => {
    if (addProductDetailSlice?.id.variant.allFinalVariant?.length > 0) {
      setProductData({
        ...productData,
        inventoryPrice: {
          price: 0,
          listPrice: 0,
          quantity: 0,
          sku: '0',
        },
        shipping: {
          weight: {
            value: 0,
            unit: 'pound',
          },
          length: 0,
          width: 0,
          height: 0,
          unit: 'inch',
          // unit: productData?.shipping?.unit
        },
      })
    }
  }, [addProductDetailSlice?.id.variant.allFinalVariant?.length])

  useEffect(() => {
    if (getAllState?.lastModalData?.isEdit && getAllState?.lastModalData?.id?.id) {
      // let editProductsDatas = getAllState?.lastModalData?.id
      let editProductsDatas = editProductData?.options
      // setProductData(editProductsDatas)

      let sizeMap = editProductsDatas
        ?.find((data) => data.name === 'size' || data?.name === 'SIZE')
        ?.data?.map((variant) => {
          return variant?.value
        })
      let sizeData = [{ name: 'SIZE', data: sizeMap }]

      let colorMap = editProductsDatas
        ?.find((data) => data.name === 'color' || data?.name === 'Color')
        ?.data?.map((variant) => {
          return { name: variant?.value }
        })
      let colorData = [{ name: 'Color', data: colorMap }]
      // let colorData = [
      //   {
      //     name: 'Color',
      //     data: editProductsDatas?.find((data) => data.name === 'Color' || data.name === 'color')?.data,
      //   },
      // ]
      let otherData = editProductsDatas?.filter(
        (data) => data.name !== 'Color' && data.name !== 'SIZE' && data.name !== 'color' && data.name !== 'size',
      )
      let finalOtherData = otherData?.map((data) => {
        return { name: data?.name, data: data?.data?.map((variant) => variant?.value) }
      })
      let Array = [...(sizeData ?? []), ...(colorData ?? []), ...(finalOtherData ?? [])]
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            variant: {
              ...addProductDetailSlice?.id?.variant,
              allVariant: Array,
              allFinalVariant: Array,
              colorCodeArray: Array,
              // allOptions: Array,
            },
          },
        }),
      )

      setAttributeArray(editProductData?.attributes)
      // setTags(editProductsDatas?.tags)

      // setCategoryArrayList(
      //   editProductsDatas?.category?.map((cat) => {
      //     return {
      //       category_id: cat?.category?.id,
      //       subCategory_id: cat?.subCategory?.id,
      //       childSubCategory_id: cat?.childSubCategory?.id,
      //     }
      //   }),
      // )
      // let Array1 = editProductsDatas?.category
      // let Array2 = data?.getAllCategoryDetail
      // if (Array2?.length > 0) {
      //   let outputArray = Array2?.map((category) => {
      //     let subCategory = category.subCategory.map((subCat) => {
      //       let childSubCategory = subCat.childSubCategory.map((childSubCat) => {
      //         let isChecked = Array1.some(
      //           (item) =>
      //             item.category.id === category.id &&
      //             item.subCategory.id === subCat.id &&
      //             item.childSubCategory.id === childSubCat.id,
      //         )
      //         return { ...childSubCat, isChecked }
      //       })
      //       return { ...subCat, childSubCategory }
      //     })
      //     return { ...category, subCategory }
      //   })
      //   setCategories(outputArray)
      // }
    } else {
      // setCategories(data?.getAllCategoryDetail)
    }
  }, [getAllState])

  // useEffect(() => {
  //   if (getAllState?.lastModalData?.isEdit) {
  //     if (Boolean(getAllState?.lastModalData?.id?.cropImages)) {
  //       const fetchImages = async () => {
  //         const base64Images = []
  //         // for await (const imageUrl of getAllState?.lastModalData?.id?.cropImages) {
  //         for await (const imageUrl of getAllState?.lastModalData?.id?.cropImages) {
  //           // const response = await fetch(imageUrl?.oldFile)
  //           // const blob = await response.blob()
  //           // const base64Image = await new Promise((resolve) => {
  //           //   const reader = new FileReader()
  //           //   reader.onloadend = () => {
  //           //     const base64String = reader.result
  //           //     resolve(base64String)
  //           //   }
  //           //   reader.readAsDataURL(blob)
  //           // })
  //           // const response1 = await fetch(imageUrl.croppedFile.baseURL)
  //           // const blob1 = await response1.blob()
  //           // const base64Image1 = await new Promise((resolve) => {
  //           //   const reader1 = new FileReader()
  //           //   reader1.onloadend = () => {
  //           //     const base64String1 = reader1.result
  //           //     resolve(base64String1)
  //           //   }
  //           //   reader1.readAsDataURL(blob1)
  //           // })
  //           base64Images.push({
  //             imageUrl: imageUrl?.oldFile,
  //             mediaId: imageUrl?.mediaId,
  //             originalFile: null,
  //             id: imageUrl?.id,
  //             isEdit: true,
  //             ratio: getAllState?.lastModalData?.id?.ratio,
  //             newfile: '',
  //             oldFile: { ...imageUrl?.croppedFile, baseURL: imageUrl?.croppedFile?.baseURL },
  //           })
  //         }

  //         await dispatch(
  //           fetchProductImageResizeModal({
  //             ...fetchProductImageResizeData,
  //             id: {
  //               ...fetchProductImageResizeData?.id,
  //               media: [...fetchProductImageResizeData?.id?.media, base64Images],
  //               // prevImages: [...fetchProductImageResizeData?.id?.prevImages, base64Images],
  //               isDeleted: [],
  //               isSelected: true,
  //               ratio: getAllState?.lastModalData?.id?.ratio ? getAllState?.lastModalData?.id?.ratio : '1 : 1',
  //               selectedSize: {
  //                 height: getAllState?.lastModalData?.id?.size?.height
  //                   ? getAllState?.lastModalData?.id?.size?.height
  //                   : 1080,
  //                 width: getAllState?.lastModalData?.id?.size?.width
  //                   ? getAllState?.lastModalData?.id?.size?.width
  //                   : 1080,
  //               },
  //             },
  //           }),
  //         )
  //       }
  //       fetchImages()
  //     }
  //   }
  // }, [data])

  useEffect(() => {
    const handleResize = () => {
      const screenWidth: any = window.innerWidth
      setDeviceType(screenWidth)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoadingProduct(true)
    const authUser = JSON.parse(localStorage.getItem('authUser'))
    let sizeVariant = addProductDetailSlice?.id?.variant?.allVariant?.filter((size) => size?.name === 'SIZE')
    let colorVariant = addProductDetailSlice?.id?.variant?.allVariant?.filter((size) => size?.name === 'Color')
    let otherVariant = addProductDetailSlice?.id?.variant?.allVariant?.filter(
      (size) => size?.name !== 'SIZE' && size?.name !== 'Color',
    )

    const updatedVariant = {
      other: [
        { name: 'size', data: sizeVariant[0]?.data },
        // { name: 'color', data: colorVariant[0]?.data.map((colorObj) => colorObj.name) },
        { name: 'color', data: colorVariant[0]?.data },
      ],
    }

    const sizeAndColorArray = [
      { name: 'size', data: updatedVariant.other[0].data },
      { name: 'color', data: updatedVariant.other[1].data },
      ...otherVariant,
    ]

    // Create the new "variant" object with the "size" and "color" as separate objects
    const finalVariant = {
      other: sizeAndColorArray.concat(updatedVariant.other.slice(2)),
    }

    const finalOptionsData = finalVariant?.other?.map((variant) => {
      return {
        name: variant?.name,
        data: JSON.stringify(variant?.data),
      }
    })

    const newVarientData = addProductDetailSlice?.id?.variant?.allOptions?.map((variant, index) => {
      return {
        ...variant,
        weightValue: variant?.weightValue ?? '0',
        weightUnit: variant?.weightUnit ?? 'pound',
        length: parseInt(variant?.length ?? 0),
        width: parseInt(variant?.width ?? 0), // new width value
        height: parseInt(variant?.height ?? 0), // new height value
        unit: variant?.unit ?? 'inch', // new unit value

        inventory_quantity: variant?.inventory_quantity ?? 0, // new inventory quantity
        price: variant?.price ?? '0', // new price
        sku: variant?.sku ?? '0',
        listPrice: variant?.listPrice ?? '0',
        image_id: variant?.image_id ?? null,

        position: index + 1,

        on_hand: variant?.inventory_quantity ?? 0,
        compare_at_price: '0',
        old_inventory_quantity: variant?.inventory_quantity ?? 0,

        isTaxable: variant?.isTaxable ?? false, // new isTaxable value
        barcode: variant?.barcode ?? '0', // new barcode value
      }
    })

    const isCheckedRemoved = newVarientData?.map((variant) => {
      const { isChecked, ...rest } = variant
      return rest
    })

    let UploadImageRoute =
      API.endpoint.includes('stage') || API.endpoint.includes('local')
        ? `/upload?type=bluejestic-stage/store/${user?.store?.id}/product`
        : `/upload?type=bluejestic-production/store/${user?.store?.id}/product`
    async function uploadImage(imageId, index) {
      if (imageId?.image_id) {
        const data = new FormData()
        data.append('image', imageId.image_id)
        data.append('media_for', 'product')
        const images = await ApiPost(UploadImageRoute, data)
        isCheckedRemoved[index].image_id = (images as any)?.data?.image[0]?.id
      }
    }

    for (let i = 0; i < isCheckedRemoved?.length; i++) {
      await uploadImage(isCheckedRemoved[i], i)
    }
    const newValue = {
      store_id: user?.store?.id ?? 0,
      title: productData?.title ?? '',
      brand_id: productData?.brand_id ?? null,
      status: productData?.status ?? '',
      image: fetchProductImageResizeData?.id?.media ?? [],
      description: productData?.description ?? '',
      category: [fetchProductImageResizeData?.id?.selectedCategories],
      // variants: addProductDetailSlice?.id?.variant?.allOptions,
      variants: isCheckedRemoved ?? [],
      options:
        // size: sizeVariant?.length > 0 ? sizeVariant && sizeVariant[0]?.data : [],
        // color: colorVariant?.length > 0 ? colorVariant && colorVariant[0]?.data : [],
        finalOptionsData ?? [],

      attributes: attributeArray ?? [],
      seo: {
        metaTitle: productData?.seo?.metaTitle ?? '',
        metaDescription: productData?.seo?.metaDescription ?? '',
        keywords: productData?.seo?.keywords ?? '',
      },
      isVisible: productData?.isVisible ?? false,
      isFeature: productData?.isFeature ?? false,
      inventoryPrice: {
        price: productData?.inventoryPrice?.price ?? 0,
        listPrice: productData?.inventoryPrice?.listPrice ?? 0,
        quantity: productData?.inventoryPrice?.quantity ?? 0,
        sku: productData?.inventoryPrice?.sku ?? '0',
      },
      shipping: {
        weight: {
          value: productData?.shipping?.weight?.value ?? 0,
          // unit: 'lbs',,
          unit: productData?.shipping?.weight?.unit ?? 'pound',
        },
        length: productData?.shipping?.length ?? 0,
        width: productData?.shipping?.width ?? 0,
        height: productData?.shipping?.height ?? 0,
        // unit: 'inch', //I will send you anyone of the two value. 1.m, 2.cm, 3.inch
        unit: productData?.shipping?.unit === 'in' ? 'inch' : 'centimeter',
      },
      isFreeShipping: productData?.isFreeShipping ?? false,
      condition: productData?.condition ?? '', //I will send you anyone of the two value. 1.New and 2.Used
      tags: tags ?? [],
    }

    let resp: any = await addProduct(newValue)
    if (resp?.data?.createProduct?.success) {
      dispatch(
        fetchModalData({
          allModalData: [],
          lastModalData: {},
          isUpdateState: false,
        }),
      )
      dispatch(
        fetchProductImageResizeModal({
          modal: '',
          isOpen: false,
          id: {
            ...fetchProductImageResizeData?.id,
            prevImages: [],
            prevVideos: [],
            isDeleted: [],
            selectedSize: { x: 1080, y: 1080 },
            isSelected: false,
            ratio: '1 : 1',
            media: [],
          },
        }),
      )
    } else {
    }
  }

  const addProduct = async (product) => {
    let imageResponse
    let videoResponse
    let UploadImageRoute =
      API.endpoint.includes('stage') || API.endpoint.includes('local')
        ? `/upload?type=bluejestic-stage/store/${user?.store?.id}/product`
        : `/upload?type=bluejestic-production/store/${user?.store?.id}/product`
    let imageUpload = product?.image?.map((dataurl) => {
      if (!dataurl?.originalFile) {
        var arr = dataurl?.newfile?.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }

        var arr2 = dataurl?.oldFile?.baseURL.split(','),
          mime2 = arr2[0].match(/:(.*?);/)[1],
          bstr2 = atob(arr2[1]),
          m = bstr2.length,
          u8arr2 = new Uint8Array(m)
        while (m--) {
          u8arr2[m] = bstr2.charCodeAt(m)
        }

        return {
          ...dataurl,
          newfile: new File([u8arr], 'image.jpg', { type: mime }),
          oldFile: { ...dataurl?.oldFile, baseURL: new File([u8arr2], 'image.jpg', { type: mime2 }) },
        }
      } else {
        return dataurl
      }
    })

    let finalObject = []
    let videoIds = []
    if (imageUpload && imageUpload.length > 0) {
      const imageFolderName = getAllState?.lastModalData?.id?.title?.replace(/\s/g, '')
      const BaseURL = 'https://stage-graph.api.bluejestic.com'
      const token = nookies.get()
      for (const obejctOf of imageUpload) {
        if (!obejctOf?.originalFile) {
          const data = new FormData()
          let blackArray = [obejctOf.newfile, obejctOf.oldFile.baseURL]
          for (const imageFile of blackArray) {
            data.append('image', imageFile)
          }
          data.append('media_for', 'product')
          // imageResponse = await ApiPost(UploadImageRoute, data)
          imageResponse = await axios.post(BaseURL + UploadImageRoute, data, {
            headers: {
              'Content-Type': 'multipart/form-data',
              authorization: `Bearer ${token?.token}`,
            },
          })

          finalObject = [
            ...finalObject,
            {
              ...obejctOf,
              newfile: imageResponse?.data?.image[0]?.id,
              oldFile: { ...obejctOf?.oldFile, baseURL: imageResponse?.data?.image[1]?.id },
            },
          ]
        } else {
          const data = new FormData()
          data.append('image', obejctOf?.originalFile)
          data.append('media_for', 'product')
          videoResponse = await ApiPost(UploadImageRoute, data)
          // seller/product/${imageFolderName}
          finalObject = [
            ...finalObject,
            {
              id: obejctOf?.id,
              newfile: videoResponse?.data?.image[0]?.id,
            },
          ]
        }
      }
    }

    let finalImageArray = finalObject.map((img) => {
      return {
        // id: img?.id,
        // oldFile: img?.newfile,
        // croppedFile: img?.oldFile
        //   ? img?.oldFile
        //   : {
        baseURL: img?.newfile,
        //         zoom: 1,
        //         rotate: 0,
        //         crop: { x: 0, y: 0 },
        //       },
      }
    })

    let finalImages = finalImageArray.map((item) => item.baseURL)

    const finalOptionsArray = product?.options.map((item) => {
      return {
        ...item,
        data: JSON.stringify(item.data),
      }
    })

    let finalValue = {
      store_id: user?.store?.id,
      title: product?.title,
      // video: videoIds,
      images: finalImages,
      brand_id: product?.brand_id,
      status: product?.status,
      description: product?.description,
      category: product?.category,
      ...((addProductDetailSlice?.id?.variant?.allOptions?.length > 0 ||
        addProductDetailSlice?.id?.variant?.allOptions?.[0]?.total_variant?.length > 0 ||
        product?.variants?.[0]?.total_variant?.length > 0) && {
        options: product?.options,
      }),
      // options: finalOptionsArray,
      variants: product?.variants,
      attributes: product?.attributes,
      seo: product?.seo,
      isVisible: product?.isVisible,
      isFeature: product?.isFeature,
      ratio: fetchProductImageResizeData?.id?.ratio,
      size: fetchProductImageResizeData?.id?.selectedSize,
      // inventoryPrice: {
      //   price: Number(product?.inventoryPrice?.price),
      //   listPrice: Number(product?.inventoryPrice?.listPrice),
      //   quantity: Number(product?.inventoryPrice?.quantity),
      //   sku: product?.inventoryPrice?.sku,
      // },
      // shipping: {
      //   weight: {
      //     value: Number(product?.shipping?.weight?.value),
      //     unit: product?.shipping?.weight?.unit,
      //   },
      //   length: Number(product?.shipping?.length),
      //   width: Number(product?.shipping?.width),
      //   height: Number(product?.shipping?.height),
      //   unit: product?.shipping?.unit,
      // },
      ...((addProductDetailSlice?.id?.variant?.allOptions?.length < 1 ||
        addProductDetailSlice?.id?.variant?.allOptions?.[0]?.total_variant?.length < 1 ||
        product?.variants?.[0]?.total_variant?.length < 1) && {
        inventoryPrice: {
          price: Number(product?.inventoryPrice?.price),
          listPrice: Number(product?.inventoryPrice?.listPrice),
          quantity: Number(product?.inventoryPrice?.quantity),
          sku: product?.inventoryPrice?.sku,
        },
        shipping: {
          weight: {
            value: Number(product?.shipping?.weight?.value),
            unit: product?.shipping?.weight?.unit,
          },
          length: Number(product?.shipping?.length),
          width: Number(product?.shipping?.width),
          height: Number(product?.shipping?.height),
          unit: product?.shipping?.unit,
        },
      }),
      isFreeShipping: product?.isFreeShipping,
      condition: product?.condition,
      tags: product?.tags,
    }

    if (finalImages?.length > 0) {
      try {
        let result = await addProductMutation({
          variables: {
            store_id: user?.store?.id,
            title: product?.title,
            images: finalImages,
            brand_id: product?.brand_id,
            status: product?.status,
            description: product?.description,
            category: product?.category,
            ...((addProductDetailSlice?.id?.variant?.allOptions?.length > 0 ||
              addProductDetailSlice?.id?.variant?.allOptions?.[0]?.total_variant?.length > 0 ||
              product?.variants?.[0]?.total_variant?.length > 0) && {
              options: product?.options,
            }),
            variants: product?.variants,
            attributes: product?.attributes,
            seo: product?.seo,
            isVisible: product?.isVisible,
            isFeature: product?.isFeature,
            ratio: fetchProductImageResizeData?.id?.ratio,
            size: fetchProductImageResizeData?.id?.selectedSize,
            ...((addProductDetailSlice?.id?.variant?.allOptions?.length < 1 ||
              addProductDetailSlice?.id?.variant?.allOptions?.[0]?.total_variant?.length < 1 ||
              product?.variants?.[0]?.total_variant?.length < 1) && {
              inventoryPrice: {
                price: Number(product?.inventoryPrice?.price),
                listPrice: Number(product?.inventoryPrice?.listPrice),
                quantity: Number(product?.inventoryPrice?.quantity),
                sku: product?.inventoryPrice?.sku,
              },
              shipping: {
                weight: {
                  value: Number(product?.shipping?.weight?.value),
                  unit: product?.shipping?.weight?.unit,
                },
                length: Number(product?.shipping?.length),
                width: Number(product?.shipping?.width),
                height: Number(product?.shipping?.height),
                unit: product?.shipping?.unit,
              },
            }),
            isFreeShipping: product?.isFreeShipping,
            condition: product?.condition,
            tags: product?.tags,
          },
          refetchQueries: [
            // {
            //   query: GET_PRODUCTS_QUERY,
            //   variables: {
            //     where: {
            //       store_id: user?.store?.id,
            //     },
            //   },
            // },
            {
              query: GET_ALL_PRODUCT_BY_STORE,
              variables: {
                store_id: user?.store?.id,
                page: 1,
                limit: 40,
                search: '',
                status: '',
              },
            },
          ],
        })
        return await result
      } catch (err) {
        console.log(err)
        return null
      }
    }
  }

  const [addProductMutation] = useMutation(CREATE_PRODUCT_MUTATION, {
    onCompleted: (res) => {
      setLoadingProduct(false)
    },
    onError: (err) => {
      console.log('err', err)
    },
  })

  const [updateProductMutation] = useMutation(UPDATE_PRODUCT_MUTATION, {
    onCompleted: (res) => {
      setLoadingProduct(false)
    },
    onError: (err) => {
      console.log('err', err)
    },
  })

  const handleEdit = async (e) => {
    e.preventDefault()
    setLoadingProduct(true)
    let sizeVariant = addProductDetailSlice?.id?.variant?.allVariant?.filter((size) => size?.name === 'SIZE')
    let colorVariant = addProductDetailSlice?.id?.variant?.colorCodeArray?.filter((size) => size?.name === 'Color')
    let otherVariant = addProductDetailSlice?.id?.variant?.allVariant?.filter(
      (size) => size?.name !== 'SIZE' && size?.name !== 'Color',
    )

    const updatedVariant = {
      other: [
        { name: 'size', data: sizeVariant[0]?.data },
        // { name: 'color', data: colorVariant[0]?.data.map((colorObj) => colorObj.name) },
        { name: 'color', data: colorVariant[0]?.data },
      ],
    }

    const sizeAndColorArray = [
      { name: 'size', data: updatedVariant.other[0].data },
      { name: 'color', data: updatedVariant.other[1].data },
      ...otherVariant,
    ]

    // Create the new "variant" object with the "size" and "color" as separate objects
    const finalVariant = {
      other: sizeAndColorArray.concat(updatedVariant.other.slice(2)),
    }

    const finalOptionsArray = finalVariant?.other.map((item) => {
      return {
        ...item,
        data: JSON.stringify(item.data),
      }
    })

    const newVarientEditData = addProductDetailSlice?.id?.variant?.allOptions?.map((variant, index) => {
      return {
        ...variant,
        weightValue: variant?.weightValue ?? '0',
        weightUnit: variant?.weightUnit ?? 'pound',
        length: parseInt(variant?.length ?? 0),
        width: parseInt(variant?.width ?? 0), // new width value
        height: parseInt(variant?.height ?? 0), // new height value
        unit: variant?.unit ?? 'inch', // new unit value

        inventory_quantity: variant?.inventory_quantity ?? 0, // new inventory quantity
        price: variant?.price ?? '0', // new price
        sku: variant?.sku ?? '0',
        listPrice: variant?.listPrice ?? '0',
        image_id: variant?.image_id ?? null,

        position: index + 1,

        on_hand: variant?.inventory_quantity ?? 0,
        compare_at_price: '0',
        old_inventory_quantity: variant?.inventory_quantity ?? 0,

        isTaxable: variant?.isTaxable ?? false, // new isTaxable value
        barcode: variant?.barcode ?? '0', // new barcode value
      }
    })

    const isCheckedRemoved = newVarientEditData?.map((variant) => {
      const { isChecked, id, title, image, ...rest } = variant
      return { ...rest }
    })

    let UploadImageRoute =
      API.endpoint.includes('stage') || API.endpoint.includes('local')
        ? `/upload?type=bluejestic-stage/store/${user?.store?.id}/product`
        : `/upload?type=bluejestic-production/store/${user?.store?.id}/product`
    async function uploadImage(imageId, index) {
      if (imageId?.image_id !== null) {
        if (typeof imageId.image_id === 'number') {
          isCheckedRemoved[index].image_id = imageId.image_id
        } else {
          // Image is not uploaded yet, so proceed with the upload
          const data = new FormData()
          data.append('image', imageId.image_id)
          data.append('media_for', 'product')
          const images = await ApiPost(UploadImageRoute, data)
          isCheckedRemoved[index].image_id = (images as any)?.data?.image[0]?.id
        }
      }
      // if (imageId?.image_id ) {
      //   const data = new FormData()
      //   data.append('image', imageId.image_id)
      //   data.append('media_for', 'product')
      //   const images = await ApiPost(UploadImageRoute, data)
      //   isCheckedRemoved[index].image_id = (images as any)?.data?.image[0]?.id
      // }
    }

    for (let i = 0; i < isCheckedRemoved?.length; i++) {
      await uploadImage(isCheckedRemoved[i], i)
    }

    const editedValue = {
      // id: 0,
      store_id: user?.store?.id ?? 0,
      title: productData?.title ?? '',
      brand_id: productData?.brand_id ?? null,
      status: productData?.status ?? 'Draft',
      description: productData?.description ?? '',
      category: fetchProductImageResizeData?.id?.selectedCategories ?? [],
      image: fetchProductImageResizeData?.id?.media ?? [],
      removeImages: fetchProductImageResizeData?.id?.isDeleted ?? [],
      // variant: {
      //   size: sizeVariant?.length > 0 ? sizeVariant && sizeVariant[0]?.data : [],
      //   color: colorVariant?.length > 0 ? colorVariant && colorVariant[0]?.data : [],
      //   other: otherVariant,
      // },
      variants: isCheckedRemoved ?? [],
      options:
        // size: sizeVariant?.length > 0 ? sizeVariant && sizeVariant[0]?.data : [],
        // color: colorVariant?.length > 0 ? colorVariant && colorVariant[0]?.data : [],
        finalOptionsArray ?? [],
      attributes: attributeArray ?? [],
      seo: {
        metaTitle: productData?.seo?.metaTitle ?? '',
        metaDescription: productData?.seo?.metaDescription ?? '',
        keywords: productData?.seo?.keywords ?? '',
      },
      isVisible: productData?.isVisible ?? false,
      isFeature: productData?.isFeature ?? false,
      inventoryPrice: {
        price: productData?.inventoryPrice?.price ?? 0,
        listPrice: productData?.inventoryPrice?.listPrice ?? 0,
        quantity: productData?.inventoryPrice?.quantity ?? 0,
        sku: productData?.inventoryPrice?.sku ?? '0',
      },
      shipping: {
        weight: {
          value: productData?.shipping?.weight?.value ?? 0,
          unit: 'pound',
          // unit: productData?.shipping?.weight?.unit
        },
        length: productData?.shipping?.length ?? 0,
        width: productData?.shipping?.width ?? 0,
        height: productData?.shipping?.height ?? 0,
        unit: 'inch',
        // unit: productData?.shipping?.unit
      },
      isFreeShipping: productData?.isFreeShipping ?? false,
      condition: productData?.condition ?? '',
      tags: tags ?? [],
    }

    let resp = null
    resp = await updateProduct(getAllState?.lastModalData?.id, editedValue)
    if (resp) {
      dispatch(
        fetchModalData({
          allModalData: [],
          lastModalData: {},
          isUpdateState: false,
        }),
      )
      dispatch(
        fetchProductImageResizeModal({
          modal: '',
          isOpen: false,
          id: {
            ...fetchProductImageResizeData?.id,
            prevImages: [],
            prevVideos: [],
            isDeleted: [],
            selectedSize: { x: 1080, y: 1080 },
            isSelected: false,
            ratio: '1 : 1',
            media: [],
          },
        }),
      )
    } else {
    }
  }

  const updateProduct = async (id, product) => {
    let imageResponse
    let videoResponse
    let finalObject = []
    let result

    let UploadImageRoute =
      API.endpoint.includes('stage') || API.endpoint.includes('local')
        ? `/upload?type=bluejestic-stage/store/${user?.store?.id}/product`
        : `/upload?type=bluejestic-production/store/${user?.store?.id}/product`

    let imageUpload = fetchProductImageResizeData?.id?.media?.map((dataurl) => {
      if (!dataurl?.isEdit) {
        var arr = dataurl?.newfile?.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }

        var arr2 = dataurl?.oldFile?.baseURL.split(','),
          mime2 = arr2[0].match(/:(.*?);/)[1],
          bstr2 = atob(arr2[1]),
          m = bstr2.length,
          u8arr2 = new Uint8Array(m)
        while (m--) {
          u8arr2[m] = bstr2.charCodeAt(m)
        }
        return {
          ...dataurl,
          newfile: new File([u8arr], 'image.jpg', { type: mime }),
          oldFile: { ...dataurl?.oldFile, baseURL: new File([u8arr2], 'image.jpg', { type: mime2 }) },
        }
      } else {
        return dataurl
      }
    })

    if (imageUpload && imageUpload.length > 0) {
      const imageFolderName = getAllState?.lastModalData?.id?.title?.replace(/\s/g, '')
      for (const obejctOf of imageUpload) {
        if (!obejctOf?.isEdit) {
          const data = new FormData()
          let blackArray = [obejctOf.newfile, obejctOf.oldFile.baseURL]
          for (const imageFile of blackArray) {
            data.append('image', imageFile)
          }
          data.append('media_for', 'product')
          imageResponse = await ApiPost(UploadImageRoute, data)
          finalObject = [
            ...finalObject,
            {
              ...obejctOf,
              newfile: imageResponse?.data?.image[0]?.id,
              oldFile: { ...obejctOf?.oldFile, baseURL: imageResponse?.data?.image[1]?.id },
            },
          ]
        } else {
          finalObject = [...finalObject, { ...obejctOf }]
        }
      }
    }

    let finalImageArray = finalObject.map((img) => {
      if (img?.isEdit) {
        return {
          // id: img?.id,
          // oldFile: img?.mediaId,
          // croppedFile: {
          baseURL: img?.oldFile?.mediaId,
          //   zoom: img?.oldFile?.zoom,
          //   rotate: img?.oldFile?.rotate,
          //   crop: img?.oldFile?.crop,
          // },
        }
      } else {
        return {
          // id: img?.id,
          // oldFile: img?.newfile,
          // croppedFile: img?.oldFile
          //   ? img?.oldFile
          //   : {
          baseURL: img?.newfile,
          //   zoom: 1,
          //   rotate: 0,
          //   crop: { x: 0, y: 0 },
          // },
        }
      }
    })

    let finalImages = finalImageArray.map((item) => item.baseURL)

    let editedUploadData = {
      id: id,
      store_id: product?.store_id,
      title: product?.title,
      // cropImages: finalImageArray,
      images: finalImages,
      removeImages: product?.removeImages,
      brand_id: product?.brand_id,
      status: product?.status ?? 'Draft',
      description: product?.description,
      category: product?.category,
      variants: product?.variants,
      options: product?.options,
      attributes: product?.attributes,
      seo: product?.seo,
      isVisible: product?.isVisible,
      isFeature: product?.isFeature,
      ratio: fetchProductImageResizeData?.id?.ratio,
      size: fetchProductImageResizeData?.id?.selectedSize,
      inventoryPrice: {
        price: Number(product?.inventoryPrice?.price),
        listPrice: Number(product?.inventoryPrice?.listPrice),
        quantity: Number(product?.inventoryPrice?.quantity),
        sku: product?.inventoryPrice?.sku,
      },
      shipping: {
        weight: {
          value: Number(product?.shipping?.weight?.value),
          unit: product?.shipping?.weight?.unit,
        },
        length: Number(product?.shipping?.length),
        width: Number(product?.shipping?.width),
        height: Number(product?.shipping?.height),
        unit: product?.shipping?.unit,
      },
      isFreeShipping: product?.isFreeShipping,
      condition: product?.condition,
      tags: product?.tags,
    }

    try {
      result = await updateProductMutation({
        mutation: UPDATE_PRODUCT_MUTATION,
        variables: {
          id: id,
          store_id: product?.store_id,
          brand_id: product?.brand_id,
          status: product?.status,
          title: product?.title,
          // cropImages: finalImageArray,
          images: finalImages,
          removeImages: product?.removeImages,
          description: product?.description,
          category: product?.category,
          variants: product?.variants,
          options: product?.options,
          attributes: product?.attributes,
          seo: product?.seo,
          isVisible: product?.isVisible,
          isFeature: product?.isFeature,
          ratio: fetchProductImageResizeData?.id?.ratio,
          size: fetchProductImageResizeData?.id?.selectedSize,
          inventoryPrice: {
            price: Number(product?.inventoryPrice?.price),
            listPrice: Number(product?.inventoryPrice?.listPrice),
            quantity: Number(product?.inventoryPrice?.quantity),
            sku: product?.inventoryPrice?.sku,
          },
          shipping: {
            weight: {
              value: Number(product?.shipping?.weight?.value),
              unit: product?.shipping?.weight?.unit,
            },
            length: Number(product?.shipping?.length),
            width: Number(product?.shipping?.width),
            height: Number(product?.shipping?.height),
            unit: product?.shipping?.unit,
          },
          isFreeShipping: product?.isFreeShipping,
          condition: product?.condition,
          tags: product?.tags,
        },
        refetchQueries: [
          {
            query: GET_PRODUCTS_QUERY,
            variables: {
              where: {
                store_id: user?.store?.id,
              },
            },
          },
        ],
      })
      return await result
    } catch (err) {
      console.log(err)
      return null
    }
  }

  const validateForm = (type) => {
    let errors = {}
    let formIsValid = true
    if (productData?.title?.trim() === '') {
      dispatch(
        fetchProductErrorHandler({
          ...fetchProductErrorHandlerSlice,
          focusFiled: 'all',
          errors: { ...fetchProductErrorHandlerSlice?.errors, title: '* Enter Input' },
        }),
      )
    }
    if (!Boolean(fetchProductImageResizeData?.id?.media?.length)) {
      dispatch(
        fetchProductErrorHandler({
          ...fetchProductErrorHandlerSlice,
          focusFiled: 'all',
          errors: { ...fetchProductErrorHandlerSlice?.errors, images: '* Enter Input' },
        }),
      )
    }

    if (productData?.description?.trim() === '') {
      formIsValid = false
      errors['description'] = 'Enter Description'
    }
    if (fetchProductImageResizeData?.id?.selectedCategories?.length === 0) {
      formIsValid = false
      errors['category'] = 'Select Category'
    }

    if (productData?.title?.trim() === '') {
      formIsValid = false
      errors['title'] = 'Enter Title'
    }
    if (fetchProductImageResizeData?.id?.media?.length === 0) {
      formIsValid = false
      errors['images'] = 'Select Images'
    }
    if (
      (addProductDetailSlice?.id?.variant?.allOptions === undefined ||
        addProductDetailSlice?.id?.variant?.allOptions?.[0]?.total_variant < 1) &&
      (!productData?.inventoryPrice?.price || productData?.inventoryPrice?.price < 1)
    ) {
      formIsValid = false
      errors['price'] = '*Enter Price'
    }
    if (
      (addProductDetailSlice?.id?.variant?.allOptions === undefined ||
        addProductDetailSlice?.id?.variant?.allOptions?.[0]?.total_variant < 1) &&
      (!productData?.inventoryPrice?.listPrice || productData?.inventoryPrice?.listPrice < 1)
    ) {
      formIsValid = false
      errors['listPrice'] = '*Enter List Price'
    }
    if (
      (addProductDetailSlice?.id?.variant?.allOptions === undefined ||
        addProductDetailSlice?.id?.variant?.allOptions?.[0]?.total_variant < 1) &&
      (!productData?.inventoryPrice?.quantity || productData?.inventoryPrice?.quantity < 1)
    ) {
      formIsValid = false
      errors['quantity'] = '*Enter Quantity'
    }
    if (
      (addProductDetailSlice?.id?.variant?.allOptions === undefined ||
        addProductDetailSlice?.id?.variant?.allOptions?.[0]?.total_variant < 1) &&
      !productData?.inventoryPrice?.sku
    ) {
      formIsValid = false
      errors['sku'] = '*Enter SKU'
    }
    if (
      (addProductDetailSlice?.id?.variant?.allOptions === undefined ||
        addProductDetailSlice?.id?.variant?.allOptions?.[0]?.total_variant < 1) &&
      (!productData?.shipping?.height || productData?.shipping?.height < 1)
    ) {
      formIsValid = false
      errors['height'] = '*Enter Height'
    }
    if (
      (addProductDetailSlice?.id?.variant?.allOptions === undefined ||
        addProductDetailSlice?.id?.variant?.allOptions?.[0]?.total_variant < 1) &&
      (!productData?.shipping?.length || productData?.shipping?.length < 1)
    ) {
      formIsValid = false
      errors['length'] = '*Enter Length'
    }
    if (
      (addProductDetailSlice?.id?.variant?.allOptions === undefined ||
        addProductDetailSlice?.id?.variant?.allOptions?.[0]?.total_variant < 1) &&
      (!productData?.shipping?.width || productData?.shipping?.width < 1)
    ) {
      formIsValid = false
      errors['width'] = '*Enter Width'
    }
    if (
      (addProductDetailSlice?.id?.variant?.allOptions === undefined ||
        addProductDetailSlice?.id?.variant?.allOptions?.[0]?.total_variant < 1) &&
      (!productData?.shipping?.weight?.value || productData?.shipping?.weight?.value < 1)
    ) {
      formIsValid = false
      errors['weight'] = '*Enter Weight'
    }

    // if (productData?.seo?.metaTitle?.trim() === '') {
    //   formIsValid = false
    //   errors['metaTitle'] = '* Enter Input'
    // }
    // if (productData?.seo?.metaDescription?.trim() === '') {
    //   formIsValid = false
    //   errors['metaDescription'] = '* Enter Input'
    // }
    // if (productData?.seo?.keywords?.trim() === '') {
    //   formIsValid = false
    //   errors['keywords'] = '* Enter Input'
    // }
    // if (productData?.inventoryPrice?.price?.trim() === '') {
    //   formIsValid = false
    //   errors['price'] = '* Enter Input'
    // }
    // if (productData?.inventoryPrice?.listPrice.trim() === '') {
    //   formIsValid = false
    //   errors['listPrice'] = '* Enter Input'
    // }
    // if (productData?.inventoryPrice?.quantity.trim() === '') {
    //   formIsValid = false
    //   errors['quantity'] = '* Enter Input'
    // }
    // if (productData?.inventoryPrice?.sku.trim() === '') {
    //   formIsValid = false
    //   errors['sku'] = '* Enter Input'
    // }
    // if (productData?.shipping?.weight?.value?.trim() === '') {
    //   formIsValid = false
    //   errors['weightvalue'] = '* Enter Input'
    // }
    // if (productData?.shipping?.length?.trim() === '') {
    //   formIsValid = false
    //   errors['shipping'] = '* Enter Input'
    // }
    // if (productData?.shipping?.width?.trim() === '') {
    //   formIsValid = false
    //   errors['productWidth'] = '* Enter Input'
    // }
    // if (productData?.shipping?.height?.trim() === '') {
    //   formIsValid = false
    //   errors['productHeight'] = '* Enter Input'
    // }
    setvalidation(errors)
    return formIsValid
  }

  const handleBack = () => {
    let datass = getAllState?.allModalData?.filter((item) => item?.modal !== getAllState?.lastModalData?.modal)
    dispatch(
      fetchModalData({
        allModalData: datass,
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  return (
    <>
      <div className={styles.addProductModalDetailsAlignemnt}>
        <AddProductModalHeader
          handleSubmit={handleSubmit}
          handleEdit={handleEdit}
          setLoadingProduct={setLoadingProduct}
          loadingProduct={loadingProduct}
          validateForm={validateForm}
          productData={productData}
          setProductData={setProductData}
        />
        <div className={styles.addProductMobileViewHeaderAlignment}>
          <div onClick={handleBack}>
            <svg xmlns="http://www.w3.org/2000/svg" width="37" height="32" viewBox="0 0 37 32" fill="none">
              <path
                d="M27 0H10C4.47715 0 0 4.47715 0 10V22C0 27.5228 4.47715 32 10 32H27C32.5228 32 37 27.5228 37 22V10C37 4.47715 32.5228 0 27 0Z"
                fill="white"
              />
              <path
                d="M21 10L16 16L21 22"
                stroke="#808191"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className={styles.headerNameAlignment}>
            <h6>Add new product</h6>
            <div className={styles.mobileViewStatusAlignment}>
              <div className={classNames(styles.mobileViewStatusBox, styles.publishStatus)}>
                <p>Published</p>
              </div>
            </div>
          </div>
          <div className={styles.moreIconAlignment}>
            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
              <path
                d="M34 6H10C4.47715 6 0 10.4772 0 16V28C0 33.5228 4.47715 38 10 38H34C39.5228 38 44 33.5228 44 28V16C44 10.4772 39.5228 6 34 6Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 25C13.8954 25 13 24.1046 13 23C13 21.8954 13.8954 21 15 21C16.1046 21 17 21.8954 17 23C17 24.1046 16.1046 25 15 25ZM22 25C20.8954 25 20 24.1046 20 23C20 21.8954 20.8954 21 22 21C23.1046 21 24 21.8954 24 23C24 24.1046 23.1046 25 22 25ZM27 23C27 24.1046 27.8954 25 29 25C30.1046 25 31 24.1046 31 23C31 21.8954 30.1046 21 29 21C27.8954 21 27 21.8954 27 23Z"
                fill="#808191"
              />
            </svg>
          </div>
        </div>

        <div className={styles.addProductModalBody}>
          <div className={styles.modalBodyDetailsAlignment}>
            <div className={styles.addProductFirstPartAlignment}>
              <AddProductModalTitle
                setProductData={setProductData}
                productData={productData}
                validation={validation}
                setvalidation={setvalidation}
              />
              <AddProductModalImages validation={validation} setvalidation={setvalidation} />
              <AddProductModalDescription
                setProductData={setProductData}
                productData={productData}
                validation={validation}
                setvalidation={setvalidation}
              />
              {/* <AddProductModalCategory
                setProductData={setProductData}
                productData={productData}
                validation={validation}
                setvalidation={setvalidation}
              /> */}
              <AddProductCategorySelection
                setProductData={setProductData}
                productData={productData}
                validation={validation}
                setvalidation={setvalidation}
              />
              <AddProductModalAttributes setAttributeArray={setAttributeArray} attributeArray={attributeArray} />
              <AddProductModalVariant />
            </div>
            <div className={styles.addProductSecondPartAlignment}>
              <AddProductProductStatus setProductData={setProductData} productData={productData} />
              {/* <AddProductBrand /> */}
              <AddProductBrand setProductData={setProductData} productData={productData} />

              <AddProductModalProductFeaturedProduct setProductData={setProductData} productData={productData} />
              <AddProductFreeShipping setProductData={setProductData} productData={productData} />

              <AddProductCondition setProductData={setProductData} productData={productData} />
              <AddProductModalSEO setProductData={setProductData} productData={productData} />
              <div className={styles.secondPartBoxAlignment}>
                <AddProductInventoryPricing
                  setProductData={setProductData}
                  productData={productData}
                  validation={validation}
                  setValidation={setvalidation}
                />
                <AddProductShipping
                  setProductData={setProductData}
                  productData={productData}
                  validation={validation}
                  setValidation={setvalidation}
                />
                {/* <AddProductModalProductVisibility setProductData={setProductData} productData={productData} /> */}

                <AddProductTags
                  setProductData={setProductData}
                  productData={productData}
                  setTags={setTags}
                  tags={tags}
                />
              </div>
            </div>
          </div>
          <div className={styles.addProductButtonAlignment}>
            <div className={styles.saveAsDraftButton}>
              <button>Save As Draft</button>
            </div>
            <div className={styles.publishButton}>
              <button
                onClick={(e) => {
                  getAllState?.lastModalData?.isEdit ? handleEdit(e) : handleSubmit(e)
                }}
              >
                Publish
              </button>
            </div>
          </div>
        </div>

        <div className={styles.mobileViewAddProductModalBody}>
          <AddProductProductStatus setProductData={setProductData} productData={productData} />
          <AddProductModalTitle
            setProductData={setProductData}
            productData={productData}
            validation={validation}
            setvalidation={setvalidation}
          />
          <AddProductModalImages validation={validation} setvalidation={setvalidation} />
          <AddProductModalDescription
            setProductData={setProductData}
            productData={productData}
            validation={validation}
            setvalidation={setvalidation}
          />
          <AddProductModalCategory
            setProductData={setProductData}
            productData={productData}
            validation={validation}
            setvalidation={setvalidation}
          />
          <AddProductInventoryPricing
            setProductData={setProductData}
            productData={productData}
            validation={validation}
            setValidation={setvalidation}
          />
          <AddProductShipping
            setProductData={setProductData}
            productData={productData}
            validation={validation}
            setValidation={setvalidation}
          />
          <AddProductModalAttributes setAttributeArray={setAttributeArray} attributeArray={attributeArray} />
          <AddProductModalVariant />
          <AddProductModalProductFeaturedProduct setProductData={setProductData} productData={productData} />
          <AddProductFreeShipping setProductData={setProductData} productData={productData} />
          <AddProductCondition setProductData={setProductData} productData={productData} />
          <AddProductModalSEO setProductData={setProductData} productData={productData} />
          <AddProductTags setProductData={setProductData} productData={productData} setTags={setTags} tags={tags} />
          <div className={styles.mobileViewPublishButton}>
            {/* <div className={styles.publishBoxAlignment}>
              <p>Publish</p>

              <div className="add-modal-cusotom-toggle">
                <label className="add-modal-switch">
                  <input type="checkbox" />
                  <span className="add-modal-slider add-modal-round"></span>
                </label>
              </div>
            </div> */}
            <div className={styles.publishButton}>
              <button
                onClick={(e) => {
                  getAllState?.lastModalData?.isEdit ? handleEdit(e) : handleSubmit(e)
                }}
              >
                Publish
              </button>
            </div>
          </div>
        </div>
      </div>
      {addProductDetailSlice?.id?.variant?.isAddVariantModal && <AddVariant />}
      {addProductDetailSlice?.id?.variant?.isAddSizeModalOpen && <AddSizeModal />}
      {addProductDetailSlice?.id?.variant?.isAddColorModalOpen && <AddColorModal />}
      {addProductDetailSlice?.id?.variant?.isAddOtherVariantModalOpen && <AddOtherVariantModal />}
    </>
  )
}
