import React, { useEffect, useState } from 'react'
import { useAuth } from 'src/utils/auth'
import GET_POST_BY_ID from 'src/graphql/queries/getSinglePost'
import { useQuery } from '@apollo/client'
import InputWithEmoti from 'src/components/Feed/NormalPost/InputWithEmoti'
import Loader from 'src/components/UI/Loader'
import PostHeader from 'src/components/Feed/NormalPost/PostHeader'
import PostReaction from 'src/components/Feed/NormalPost/PostReaction'
import PostPreviewImageSlider from './PostPreviewImageSlider'
import PostComments from 'src/components/Feed/NormalPost/PostComments'
import FramerAnimationModal from 'src/utils/FramerAnimationModal'
const PostPreviewModal = (props) => {
  const {
    setMediaModal,
    postId,
    commentId,
    setCommentId,
    setOpenStoreModal,
    editPost,
    editPostMobile,
    setIsMobileComment,
    isUserPostPreviewModal,
  } = props
  const isStorePost = false
  const isNormalPost = true
  const isGroupPost = false
  const { user } = useAuth()

  const getSingle = useQuery(GET_POST_BY_ID, { variables: { post_id: postId?.id } })

  const [showMore, setShowMore] = useState(false)
  const [isClose, isSetClose] = useState(true)
  const [getSingleProduct, setGetSingleProduct] = useState<any>({})

  useEffect(() => {
    if (getSingle?.data?.getSinglePost) {
      setGetSingleProduct(getSingle?.data?.getSinglePost)
    }
  }, [getSingle?.data])


  useEffect(()=>{
    getSingle?.refetch()
  },[])

  if (getSingle?.error) return <p>`Error! ${getSingle?.error.message}`</p>

  return (
    <div className="social-profile-media-alignemt">
      <div className="social-profile-wrapper">
        <FramerAnimationModal>
          <div
            className="social-profile-modal-box-alignment"
            // style={isUserPostPreviewModal ? { top: '30px' } : { top: '30px' }}
          >
            {getSingle?.loading ? (
              <Loader />
            ) : (
              <div className="social-profile-slider-flex-alignment">
                <PostPreviewImageSlider getSingleProduct={getSingleProduct} />
                <div className="social-profile-second-part-alignment">
                  <div className="social-profile-modal-details-alignment">
                    <div className="social-profile-flex-alignment">
                      <PostHeader
                        isNormalPost={isNormalPost}
                        isGroupPost={isGroupPost}
                        isStorePost={isStorePost}
                        post={getSingleProduct}
                        store={getSingleProduct?.store !== null ? getSingleProduct?.store : {}}
                        setMediaModal={setMediaModal}
                        setOpenStoreModal={setOpenStoreModal}
                        editPost={editPost}
                        editPostMobile={editPostMobile}
                      />
                    </div>
                  </div>
                  <div className="social-profile-height-alignment">
                    <div className="social-profile-notes-alignment">
                      <p>
                        {getSingleProduct?.content?.length > 84
                          ? getSingleProduct?.content.substring(0, 84) + '...'
                          : getSingleProduct?.content}
                      </p>
                    </div>
                    <div className="post-comment-sticky-alignment">
                      <PostReaction
                        isNormalPost={isNormalPost}
                        isGroupPost={isGroupPost}
                        isStorePost={isStorePost}
                        isSetClose={isSetClose}
                        setShowMore={setShowMore}
                        post={getSingleProduct}
                        store={getSingleProduct?.store !== null ? getSingleProduct?.store : {}}
                        group={getSingleProduct?.group !== null ? getSingleProduct?.group : {}}
                        setCommentId={setCommentId}
                        isPreview={true}
                        setIsMobileComment={setIsMobileComment}
                      />
                    </div>
                    {user && (
                      <div className="social-chat-details-section">
                        <div
                          className={`social-chat-details-alignment ${
                            getSingleProduct?.comment_count < 1 ? 'no-data-alignment' : ''
                          }`}
                        >
                          <PostComments
                            setShowMore={setShowMore}
                            showMore={showMore}
                            postId={postId?.id}
                            backgroundColor="#FFFFFF"
                            commentId={commentId}
                            isProductComment={false}
                            isBottonBorderVisible={true}
                            isShowMore={true}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {user && (
                    <div className="post-footer flex items-center max-w-fit commentbox-padding">
                      <img
                        src={user?.logo_image ? user?.logo_image : '/assets/img/dummy-profile.svg'}
                        alt={user?.profileImage?.alternativeText}
                        className="rounded-full object-cover comment-image"
                      />
                      <InputWithEmoti
                        post={getSingleProduct}
                        type={'post'}
                        isPost={true}
                        store={getSingleProduct?.store}
                        isButton={false}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </FramerAnimationModal>
      </div>
    </div>
  )
}

export default PostPreviewModal
