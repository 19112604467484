import { gql } from '@apollo/client'

const query = gql`
  mutation CREATECOMMENT($parent_id: Int!, $comment: String!, $comment_for: String!) {
    createComment(input: { comment: $comment, parent_id: $parent_id, comment_for: $comment_for }) {
      comment
    }
  }
`
export default query
