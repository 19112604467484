import React, { useState, useEffect } from 'react'
import { Menu, Dropdown, notification, Spin, Modal, Progress, Row, Col, Image, Input, Button, Select } from 'antd'
import CREATE_POST_MUTATION from 'src/graphql/mutations/createPost'
import UPDATE_POST_MUTATION from 'src/graphql/mutations/updatePost'
import GET_STORE_BY_NAME from 'src/graphql/queries/getStoreByName'
import { useMutation } from '@apollo/client'
// import MY_FEED_QUERY from 'src/graphql/queries/getMyFeed'
import FileUpload from 'src/components/FileUpload'
import { useAuth } from 'src/utils/auth'
import getImageUrl from 'src/utils/getImageUrl'
import data from '@emoji-mart/data'
import { Picker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'
import { ApiPost } from 'src/Helpers/Api/ApiData'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { fetchPostAddEditModal } from 'src/store/slices/postAddEditSlice'
import sellerClient from 'src/utils/apolloSellerClient'
import client1 from 'src/utils/apolloClient'
import { API } from 'src/config/API/api.config'
import UPDATE_SELLER_POST_MUTATION from 'src/graphql/queries/sellerQueries/updatePost'
import nookies from 'nookies'
import { useRouter } from 'next/router'
import {
  fetchAllFeedSliceData,
  fetchGroupPost,
  fetchUserPost,
  fetchStorePost,
} from 'src/store/slices/fetchAllFeedSlice'
import axios from 'axios'
import styles from './styles.module.scss'
import { CloseOutlined } from '@ant-design/icons'
import { generatePostContent } from 'src/services/openai'
import { DownOutlined } from '@ant-design/icons'
import { GlobalOutlined, TeamOutlined, HomeOutlined } from '@ant-design/icons'
import { Modal as AntModal } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

const SUPPORTED_VIDEO_FORMATS = [
  'video/mp4',
  'video/webm',
  'video/ogg',
  'video/quicktime',
  'video/x-msvideo',
  'video/x-matroska',
  'video/3gpp',
  'video/x-ms-wmv'
];

const SUPPORTED_IMAGE_FORMATS = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/svg+xml'
];

const isVideoFile = (file) => {
  return SUPPORTED_VIDEO_FORMATS.includes(file?.type);
};

const isImageFile = (file) => {
  return SUPPORTED_IMAGE_FORMATS.includes(file?.type);
};

export default function PostAddEditModal(props) {
  const fetchAddEditPost = useSelector((state: any) => state?.postAddEditModal?.data)
  const dispatch = useAppDispatch()
  const { user, seller }: any = useAuth()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const router = useRouter()
  const path = router.asPath
  const seller_route = path.split('/')[1]
  const [progressPercent, setProgressPercent] = useState(0)
  const [loading, setLoading] = useState(false)

  const isNormalPost = fetchAddEditPost?.id?.type === 'post' ? true : false
  const isStorePost = fetchAddEditPost?.id?.type === 'store' ? true : false
  const isGroupPost = fetchAddEditPost?.id?.type === 'group' ? true : false

  console.log('Post type flags:', {
    type: fetchAddEditPost?.id?.type,
    isNormalPost,
    isStorePost,
    isGroupPost,
    fetchAddEditPost: fetchAddEditPost?.id
  });

  const mobile = fetchAddEditPost?.id?.mobile
  const group = fetchAddEditPost?.id?.group

  const [showEmojis, setShowEmojis] = useState(false)
  const [postImage, setPostImage] = useState(false)
  const [postContent, setPostContent] = useState(
    fetchAddEditPost?.id?.editPost?.content ? fetchAddEditPost?.id?.editPost?.content : '',
  )
  const [visibility, setVisibility] = useState('everyone')

  // useEffect(() => {
  //   if (fetchAddEditPost?.id?.editPost?.visibility) {
  //     setVisibility(fetchAddEditPost.id.editPost.visibility);
  //   }
  // }, [fetchAddEditPost?.id?.editPost?.visibility]);

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const allPost = useSelector((state: any) => state?.feedData?.allPost)

  const [newUserInfo, setNewUserInfo] = useState({
    profileImages: fetchAddEditPost?.id?.editPost?.media || [],
  })
  const [showAiModal, setShowAiModal] = useState(false)
  const [showAiLoading, setShowAiLoading] = useState(false)
  const [aiPrompt, setAiPrompt] = useState('')
  const [selectedTone, setSelectedTone] = useState('casual')
  const [generatedContent, setGeneratedContent] = useState('')

  const toneOptions = [
    { value: 'casual', label: 'Casual' },
    { value: 'professional', label: 'Professional' },
    { value: 'enthusiastic', label: 'Enthusiastic' },
    { value: 'informative', label: 'Informative' },
    { value: 'minimal', label: 'Minimal' }
  ]

  const postImageVideo = () => setPostImage(!postImage)

  useEffect(() => {
    if (fetchAddEditPost?.id?.editPost?.media?.length > 0) {
      setPostImage(true)
    }
  }, [fetchAddEditPost?.id?.editPost?.media])

  const updateUploadedFiles = (files) => {
    console.log('Files to update:', files);
    setNewUserInfo(prev => ({
      ...prev,
      profileImages: files
    }))
  }

  const handleCancel = () => {
    setShowEmojis(false)
    setPostImage(false)
    let datas = {
      editPost: { isEdit: false, content: null, id: null },
      mobile: false,
      type: '',
    }
    dispatch(fetchPostAddEditModal({ modal: '', isOpen: false, id: datas }))
  }

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a href="#">Friends</a>
      </Menu.Item>
      <Menu.Item key="1">
        <a href="#">Groups</a>
      </Menu.Item>
    </Menu>
  )

  const addEmoji = (e) => {
    let sym = e.unified.split('-')
    let codesArray = []
    sym.forEach((el) => codesArray.push('0x' + el))
    let emoji = String.fromCodePoint(...codesArray)
    setPostContent(postContent + e.native)
  }

  const [editPostMutation] = useMutation(UPDATE_POST_MUTATION, {
    onCompleted: () => {
      notification['success']({
        message: 'Post updated successfully',
        description: 'Your post has been updated successfully',
      })
      setLoading(false)
      handleCancel()
      setPostContent('')
      dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
      dispatch(fetchGroupPost({ page, limit, group_id: fetchAddEditPost?.id?.editPost?.groupId, prevPost: [] }))
      dispatch(fetchUserPost({ page, limit, user_id: getAllState?.lastModalData?.id, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const [updateSellerPost] = useMutation(UPDATE_SELLER_POST_MUTATION, {
    onCompleted: () => {

      notification['success']({
        message: 'Post updated successfully',
        description: 'Your post has been updated successfully',
      })
      dispatch(fetchStorePost({ page, limit, slug: seller?.seller_detail?.slug, prevPost: [] }))
      setLoading(false)
      handleCancel()
      setPostContent('')
    },
    onError: (error) => {
      console.log(error)
    },
  })

  let UploadImageRoute =
    API.endpoint.includes('stage') || API.endpoint.includes('local')
      ? `/upload?type=bluejestic-stage/post`
      : `/upload?type=bluejestic-production/post`

  const postHandler = async () => {
    const token = nookies.get()

    if (postContent !== '') {
      if (fetchAddEditPost?.id?.editPost?.isEdit) {
        if (isNormalPost) {
          setLoading(true)
          setProgressPercent(20)
          editPostMutation({
            variables: {
              id: fetchAddEditPost?.id?.editPost?.id,
              content: postContent,
              title: postContent,
              visible_for: visibility, // Pass visibility to backend
            },
            // refetchQueries: [
            //   {
            //     query: MY_FEED_QUERY,
            //     variables: { user_id: getAllState?.lastModalData?.id },
            //   },
            //   {
            //     query: MY_FEED_QUERY,
            //     variables: { page: 1, limit: 10 },
            //   },
            // ],
          })
          setProgressPercent(90) // Set to 90% after the edit is done
          setTimeout(() => setProgressPercent(100), 500)
        } else if (isGroupPost) {
          setLoading(true)
          editPostMutation({
            variables: {
              id: fetchAddEditPost?.id?.editPost?.id,
              content: postContent,
              title: postContent,
              visible_for: visibility, // Pass visibility to backend
            },
            // refetchQueries: [
            //   { query: MY_FEED_QUERY, variables: { group_id: fetchAddEditPost?.id?.editPost?.groupId } },
            // ],
          })
        } else if (isStorePost) {
          setLoading(true)
          updateSellerPost({
            client: sellerClient,
            variables: {
              id: fetchAddEditPost?.id?.editPost?.id,
              content: postContent,
              title: postContent,
              visible_for: visibility, // Pass visibility to backend
            },
            // refetchQueries: [
            //   { query: MY_FEED_QUERY, variables: { group_id: fetchAddEditPost?.id?.editPost?.groupId } },
            // ],
          })
        }
      } else {
        setLoading(true)
        let media_type = ''
        let ImageId = []
        if (newUserInfo.profileImages.length > 0) {
          setProgressPercent(30)
          const UploadFile = new FormData()
          if (newUserInfo.profileImages[0]?.type.includes('video')) {
            media_type = 'video'
          } else if (newUserInfo.profileImages[0]?.type.includes('image')) {
            media_type = 'image'
          }
          let BaseURL = API?.endpoint

          BaseURL = BaseURL.replace('/graphql', '')
          BaseURL = BaseURL.replace('.bluejestic', '.api.bluejestic')

          newUserInfo.profileImages.forEach((file) => {
            UploadFile.append('image', file)
          })
          UploadFile.append('media_for', 'post')
          // const files = await ApiPost(UploadImageRoute, UploadFile)

          await axios
            .post(BaseURL + UploadImageRoute, UploadFile, {
              headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${token?.token}`,
              },
            })

            .then((res: any) => {
              res?.data?.image?.map((image) => {
                ImageId.push(image.id)
              })
              setProgressPercent(50)
            })
            .catch((err) => {
              setProgressPercent(0)
              return err
            })
        }
        // console.log('fetchAddEditPost+++++', fetchAddEditPost, {
        //   content: postContent,
        //   title: postContent,
        //   post_for: fetchAddEditPost?.id?.isSeller ? 'SELLER' : isNormalPost ? 'USER' : 'GROUP',
        //   media_id: ImageId,
        //   group_id: isNormalPost ? null : group?.id,
        //   store_id: fetchAddEditPost?.id?.isSeller && user?.store ? user?.store?.id : null,
        //   media_type: media_type,
        // })
        // return
        setProgressPercent(70)
        await createPost({
          variables: {
            content: postContent,
            title: postContent,
            post_for: fetchAddEditPost?.id?.isSeller ? 'SELLER' : isNormalPost ? 'USER' : 'GROUP',
            media_id: ImageId,
            group_id: isNormalPost ? null : group?.id,
            store_id: fetchAddEditPost?.id?.isSeller && user?.store ? user?.store?.id : null,
            media_type: media_type,
            visible_for: visibility,
          },
          refetchQueries: fetchAddEditPost?.id?.isSeller
            ? [
              { query: GET_STORE_BY_NAME, variables: { storeName: user?.store?.name } },
              // { query: MY_FEED_QUERY, variables: { group_id: group?.id } },
            ]
            : isNormalPost
              ? [
                // {
                //   query: MY_FEED_QUERY,
                //   variables: { page: 1, limit: 10 },
                // },
              ]
              : isGroupPost
                ? [
                  // { query: MY_FEED_QUERY, variables: { group_id: fetchAddEditPost?.id?.group?.id } }
                ]
                : [
                  // { query: MY_FEED_QUERY, variables: { page: 1, limit: 10 } }
                ],
        })
      }
    }
  }

  const [createPost] = useMutation(CREATE_POST_MUTATION, {
    ...(fetchAddEditPost?.id?.isSeller && {
      client: sellerClient,
    }),
    onCompleted: (res) => {
      notification['success']({
        message: 'Post created successfully',
        description: 'Your post has been created successfully',
      })

      if (fetchAddEditPost?.id?.isSeller) {
        dispatch(fetchStorePost({ page, limit, slug: seller?.seller_detail?.slug, prevPost: [] }))
        dispatch(
          fetchGroupPost({
            page,
            limit,
            group_id: group?.id,
            prevPost: [],
          }),
        )
      } else if (isNormalPost) {
        dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
        dispatch(fetchUserPost({ page, limit, user_id: user?.id, prevPost: [] }))
      } else if (isGroupPost) {
        dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
        dispatch(
          fetchGroupPost({
            page,
            limit,
            group_id: fetchAddEditPost?.id?.group?.id,
            prevPost: [],
          }),
        )
      }
      setProgressPercent(100)
      setTimeout(() => {
        setLoading(false)
        handleCancel()
        setPostContent('')
      }, 500)
    },
    onError: (error) => {
      console.log(error)
      notification['error']({
        message: 'Error creating post',
        description: error.message,
      })
      setLoading(false)
      setProgressPercent(0)
    },
  })

  const handleImageUpload = (e: any) => {
    const files: any = Array.from(e.target.files);

    // Validate file types
    const invalidFiles = files.filter(file => !isImageFile(file) && !isVideoFile(file));
    if (invalidFiles.length > 0) {
      notification.error({
        message: 'Invalid file format',
        description: 'Please upload only images or videos in supported formats.',
      });
      return;
    }

    // Check file sizes
    const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB limit
    const oversizedFiles = files.filter(file => file.size > MAX_FILE_SIZE);
    if (oversizedFiles.length > 0) {
      notification.error({
        message: 'File too large',
        description: 'Files must be less than 100MB',
      });
      return;
    }

    setNewUserInfo(prev => ({
      ...prev,
      profileImages: [...prev.profileImages, ...files],
    }));
  };

  const handleSubmit = async () => {
    if (!postContent?.trim() && !newUserInfo.profileImages?.length) return;

    setLoading(true);
    setProgressPercent(25);

    try {
      if (fetchAddEditPost?.id?.editPost?.id) {
        // Handle edit post
        if (fetchAddEditPost?.id?.isSeller) {
          await updateSellerPost({
            variables: {
              id: fetchAddEditPost?.id?.editPost?.id,
              content: postContent,
              visible_for: visibility,
              images: newUserInfo.profileImages,
            },
          });
        } else {
          await editPostMutation({
            variables: {
              id: fetchAddEditPost?.id?.editPost?.id,
              content: postContent,
              visible_for: visibility,
              images: newUserInfo.profileImages,
            },
          });
        }
      } else {
        // Handle new post
        setProgressPercent(50);

        // Handle media uploads if any
        let media_type = '';
        let ImageId = [];
        if (newUserInfo.profileImages.length > 0) {
          setProgressPercent(30);
          const UploadFile = new FormData();

          // Determine media type based on first file
          const firstFile = newUserInfo.profileImages[0];
          if (isVideoFile(firstFile)) {
            media_type = 'video';
          } else if (isImageFile(firstFile)) {
            media_type = 'image';
          }

          let BaseURL = API?.endpoint;
          BaseURL = BaseURL.replace('/graphql', '');
          BaseURL = BaseURL.replace('.bluejestic', '.api.bluejestic');

          newUserInfo.profileImages.forEach((file) => {
            UploadFile.append('image', file);
          });
          UploadFile.append('media_for', 'post');

          const token = nookies.get();
          await axios
            .post(BaseURL + UploadImageRoute, UploadFile, {
              headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${token?.token}`,
              },
            })
            .then((res: any) => {
              res?.data?.image?.map((image) => {
                ImageId.push(image.id);
              });
              setProgressPercent(50);
            })
            .catch((err) => {
              setProgressPercent(0);
              notification.error({
                message: 'Upload failed',
                description: 'Failed to upload media files. Please try again.',
              });
              return err;
            });
        }

        await createPost({
          variables: {
            content: postContent,
            title: postContent,
            post_for: fetchAddEditPost?.id?.isSeller ? 'SELLER' : isNormalPost ? 'USER' : 'GROUP',
            media_id: ImageId,
            group_id: isNormalPost ? null : group?.id,
            store_id: fetchAddEditPost?.id?.isSeller && user?.store ? user?.store?.id : null,
            media_type: media_type,
            visible_for: visibility,
          },
          refetchQueries: fetchAddEditPost?.id?.isSeller
            ? [{ query: GET_STORE_BY_NAME, variables: { storeName: user?.store?.name } }]
            : [],
        });
      }
    } catch (error) {
      console.error('Error submitting post:', error);
      notification.error({
        message: 'Error creating post',
        description: error.message,
      });
    }
  };

  const handleAiPost = () => {
    setShowAiModal(true)
  }

  const handleBackToMain = () => {
    setShowAiModal(false)
    setShowAiLoading(false)
    setAiPrompt('')
  }

  const handleUseGeneratedContent = () => {
    if (!generatedContent) {
      notification.error({
        message: 'Error',
        description: 'No content available to use. Please generate content first.',
      });
      return;
    }

    console.log('Using generated content:', generatedContent);

    // Update the post content state
    setPostContent(generatedContent);

    // Close the AI modal and reset its state
    setShowAiModal(false);
    setShowAiLoading(false);
    setAiPrompt('');
  }

  const generateAiContent = async () => {
    if (!aiPrompt.trim()) return

    setShowAiLoading(true)
    try {
      console.log('Generating content with prompt:', aiPrompt);
      const generatedPost = await generatePostContent(aiPrompt, selectedTone)
      console.log('Generated post:', generatedPost);

      if (!generatedPost) {
        throw new Error('No content was generated');
      }

      setGeneratedContent(generatedPost)
    } catch (error) {
      console.error('Error in generateAiContent:', error);
      notification.error({
        message: 'Error',
        description: error instanceof Error ? error.message : 'Failed to generate content. Please try again.',
      })
    } finally {
      setShowAiLoading(false)
    }
  }

  const visibilityOptions = [
    {
      value: 'everyone',
      title: 'Everyone',
      description: 'Anyone on Social Commerce can see this post',
      icon: (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      )
    },
    {
      value: 'friends',
      title: 'Friends',
      description: 'Only your friends can see this post',
      icon: (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      )
    },
    {
      value: 'my-clubs',
      title: 'My Clubs',
      description: 'Share with members of your clubs',
      icon: (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M16 3.13C16.8604 3.35031 17.623 3.85071 18.1676 4.55232C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89318 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      )
    },
    {
      value: 'only-me',
      title: 'Only Me',
      description: 'Only you can see this post',
      icon: (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      )
    }
  ]

  const handleVisibilityChange = (value) => {
    setVisibility(value);
  }

  const [showGifModal, setShowGifModal] = useState(false);
  const [gifs, setGifs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoadingGifs, setIsLoadingGifs] = useState(false);

  const handleGifButtonClick = () => {
    console.log('Toggling GIF picker');
    setShowGifModal(!showGifModal);
    if (!showGifModal) {
      fetchGifs(''); // Only fetch when opening
    }
  };

  const fetchGifs = async (query: string) => {
    console.log('Fetching GIFs with query:', query);
    setIsLoadingGifs(true);
    try {
      const apiKey = process.env.NEXT_PUBLIC_TENOR_API_KEY;
      console.log('Using API key:', apiKey); // Log API key (remove in production)

      const url = `https://tenor.googleapis.com/v2/search?q=${query || 'trending'}&key=${apiKey}&client_key=social_commerce&limit=20`;
      console.log('Request URL:', url);

      const response = await axios.get(url);

      console.log('Full Tenor API response:', response);
      console.log('Response data:', response.data);
      console.log('First result:', response.data.results?.[0]);

      if (response.data.results) {
        console.log('Setting gifs:', response.data.results);
        setGifs(response.data.results);
      } else {
        console.error('No results in response:', response.data);
        notification.error({
          message: 'No GIFs found',
          description: 'Try a different search term.'
        });
      }
    } catch (error) {
      console.error('Error fetching GIFs:', error);
      if (axios.isAxiosError(error)) {
        console.error('Axios error details:', {
          status: error.response?.status,
          data: error.response?.data,
          message: error.message
        });
      }
      notification.error({
        message: 'Error loading GIFs',
        description: 'Please check your internet connection and try again.'
      });
    } finally {
      setIsLoadingGifs(false);
    }
  };

  useEffect(() => {
    if (showGifModal) {
      console.log('GIF modal opened, fetching trending GIFs');
      fetchGifs('');
    }
  }, [showGifModal]);

  const handleGifSearch = (value: string) => {
    console.log('Search input changed:', value);
    setSearchQuery(value);
    fetchGifs(value);
  };

  const handleGifSelect = (gif: any) => {
    console.log('GIF selected:', gif);
    const gifUrl = gif.media_formats?.mediumgif?.url || gif.media_formats?.gif?.url || gif.url;
    setPostContent(prev => prev + ' ' + gifUrl);
    setShowGifModal(false);
  };

  const handleRemoveImage = (index) => {
    setNewUserInfo(prev => ({
      ...prev,
      profileImages: prev.profileImages.filter((_, i) => i !== index)
    }));
  };

  const [emojiPickerPos, setEmojiPickerPos] = useState({ top: 0, left: 0 });

  const handleEmojiButtonClick = (event) => {
    const button = event.currentTarget;
    const rect = button.getBoundingClientRect();
    setEmojiPickerPos({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX
    });
    setShowEmojis(!showEmojis);
  };

  const [selectedAiStyle, setSelectedAiStyle] = useState('professional');
  const aiStyles = [
    { key: 'professional', label: 'Professional' },
    { key: 'casual', label: 'Casual' },
    { key: 'humorous', label: 'Humorous' },
    { key: 'storytelling', label: 'Storytelling' },
    { key: 'persuasive', label: 'Persuasive' }
  ];

  return (
    <>
      {fetchAddEditPost?.isOpen && (
        <>
          {/* Overlay */}
          <div
            className={styles.modalOverlay}
            onClick={handleCancel}
          />

          {/* Modal Content */}
          <div className={styles.modalContainer}>
            {mobile ? (
              <div className={styles.mobileModalContent}>
                <div className={styles.modalHeader}>
                  <h2>{fetchAddEditPost?.id?.editPost?.id ? 'Edit Post' : 'Create Post'}</h2>
                  <button onClick={handleCancel} className={styles.closeButton}>
                    <CloseOutlined />
                  </button>
                </div>
                <div className={styles.modalBody}>
                  <div className={styles.userInfo}>
                    <Image
                      src={user?.profileImage ? getImageUrl(user.profileImage) : '/assets/img/product-detail/avatar01.png'}
                      alt="Profile"
                      width={40}
                      height={40}
                      className={styles.avatar}
                    />
                    <div>
                      <h3>{user?.name}</h3>
                      <Dropdown
                        overlay={
                          <Menu>
                            {visibilityOptions.map(option => (
                              <Menu.Item
                                key={option.value}
                                onClick={() => handleVisibilityChange(option.value)}
                              >
                                <div className={styles.visibilityOption}>
                                  {option.icon}
                                  <div>
                                    <div className={styles.visibilityTitle}>{option.title}</div>
                                    <div className={styles.visibilityDescription}>{option.description}</div>
                                  </div>
                                </div>
                              </Menu.Item>
                            ))}
                          </Menu>
                        }
                        trigger={['click']}
                      >
                        <button className={styles.visibilitySelector}>
                          {visibilityOptions.find(opt => opt.value === visibility)?.title || 'Everyone'}
                          <DownOutlined />
                        </button>
                      </Dropdown>
                    </div>
                  </div>
                  <div className={styles.postContent}>
                    <Input.TextArea
                      value={postContent}
                      onChange={(e) => setPostContent(e.target.value)}
                      placeholder="What's on your mind?"
                      autoSize={{ minRows: 3 }}
                      className={styles.postInput}
                    />
                    {newUserInfo.profileImages.length > 0 && (
                      <div className={styles.imagePreviewGrid}>
                        {newUserInfo.profileImages.slice(0, 5).map((image, index) => (
                          <div key={index} className={styles.imagePreviewItem}>
                            <img
                              src={URL.createObjectURL(image)}
                              alt={`Preview ${index + 1}`}
                              className={styles.previewImage}
                            />
                            <button
                              className={styles.removeImageButton}
                              onClick={() => handleRemoveImage(index)}
                            >
                              <CloseOutlined />
                            </button>
                          </div>
                        ))}
                        {newUserInfo.profileImages.length > 5 && (
                          <div className={styles.imageCountOverlay}>
                            <span>+{newUserInfo.profileImages.length - 5}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className={styles.actionButtons}>
                    <div className={styles.leftButtons}>
                      <button className={`${styles.actionButton} ${styles.mediaButton}`} onClick={() => document.getElementById('fileInput')?.click()}>
                        <img src="/public/assets/img/feed/postimg.png" alt="Media" />
                        Media
                      </button>
                      <button className={`${styles.actionButton} ${styles.emojiButton}`} onClick={() => setShowEmojis(!showEmojis)}>
                        <img src="/public/assets/img/feed/postemoji.png" alt="Emoji" />
                        Emoji
                      </button>
                      <button className={`${styles.actionButton} ${styles.gifButton}`} onClick={() => setShowGifModal(true)}>
                        <img src="/public/assets/img/feed/postgif.png" alt="GIF" />
                        GIF
                      </button>
                      <button className={`${styles.actionButton} ${styles.aiButton}`} onClick={handleAiPost}>
                        <img src="/public/assets/img/feed/postai.png" alt="AI Post" />
                        AI Post
                      </button>
                    </div>
                    <div className={styles.rightButtons}>
                      {loading ? (
                        <button className={styles.submitButton} disabled>
                          <Spin size="small" />
                        </button>
                      ) : (
                        <button
                          onClick={handleSubmit}
                          disabled={!postContent?.trim() && !newUserInfo.profileImages?.length}
                          className={styles.submitButton}
                        >
                          Post
                        </button>
                      )}
                    </div>
                  </div>

                  {/* Emoji Picker Overlay */}
                  {showEmojis && (
                    <div className={styles.pickerOverlay}>
                      <div className={styles.pickerHeader}>
                        <button onClick={() => setShowEmojis(false)} className={styles.backButton}>
                          <LeftOutlined /> Back
                        </button>
                        <span>Select Emoji</span>
                        <button onClick={() => setShowEmojis(false)} className={styles.closeButton}>
                          <CloseOutlined />
                        </button>
                      </div>
                      <div className={styles.pickerContent}>
                        <Picker onSelect={addEmoji} set="apple" />
                      </div>
                    </div>
                  )}

                  {/* GIF Picker Overlay */}
                  {showGifModal && (
                    <div className={styles.pickerOverlay}>
                      <div className={styles.pickerHeader}>
                        <button onClick={() => setShowGifModal(false)} className={styles.backButton}>
                          <LeftOutlined /> Back
                        </button>
                        <span>Select GIF</span>
                        <button onClick={() => setShowGifModal(false)} className={styles.closeButton}>
                          <CloseOutlined />
                        </button>
                      </div>
                      <div className={styles.pickerContent}>
                        <div className={styles.gifSearch}>
                          <Input.Search
                            placeholder="Search GIFs..."
                            onChange={(e) => handleGifSearch(e.target.value)}
                            className={styles.searchInput}
                          />
                        </div>
                        <div className={styles.gifGrid}>
                          {isLoadingGifs ? (
                            <div className={styles.loading}>
                              <Spin />
                            </div>
                          ) : (
                            <div className={styles.gifResults}>
                              {gifs.map((gif) => (
                                <div
                                  key={gif.id}
                                  className={styles.gifItem}
                                  onClick={() => handleGifSelect(gif)}
                                >
                                  <img
                                    src={gif.media_formats?.mediumgif?.url || gif.media_formats?.gif?.url || gif.url}
                                    alt={gif.content_description || 'GIF'}
                                    loading="lazy"
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {showAiModal && (
                    <div className={styles.aiOverlay}>
                      <div className={styles.aiHeader}>
                        <button onClick={() => setShowAiModal(false)} className={styles.backButton}>
                          <LeftOutlined /> Back
                        </button>
                        <span>AI Post Generator</span>
                        <button onClick={() => setShowAiModal(false)} className={styles.closeButton}>
                          <CloseOutlined />
                        </button>
                      </div>
                      <div className={styles.aiContent}>
                        <div className={styles.aiInputSection}>
                          <div className={styles.aiInputHeader}>
                            <h3>What would you like to post about?</h3>
                            <Select
                              value={selectedAiStyle}
                              onChange={(value) => setSelectedAiStyle(value)}
                              className={styles.styleSelect}
                              options={aiStyles.map(style => ({
                                value: style.key,
                                label: style.label
                              }))}
                              placeholder="Select style"
                            />
                          </div>
                          <Input.TextArea
                            value={aiPrompt}
                            onChange={(e) => setAiPrompt(e.target.value)}
                            placeholder="Enter a topic or idea for your post..."
                            className={styles.aiPromptInput}
                            rows={4}
                          />
                        </div>

                        <div className={styles.aiGenerateSection}>
                          {generatedContent ? (
                            <>
                              <div className={styles.generatedContent}>
                                <h3>Generated Post</h3>
                                <div className={styles.contentPreview}>
                                  {generatedContent}
                                </div>
                              </div>
                              <div className={styles.actionButtons}>
                                <Button onClick={() => setGeneratedContent('')}>
                                  Generate Again
                                </Button>
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    setPostContent(generatedContent);
                                    setShowAiModal(false);
                                  }}
                                >
                                  Use This Post
                                </Button>
                              </div>
                            </>
                          ) : (
                            <Button
                              type="primary"
                              onClick={generateAiContent}
                              disabled={!aiPrompt.trim()}
                              loading={showAiLoading}
                              className={styles.generateButton}
                            >
                              Generate Post
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <Modal
                title={fetchAddEditPost?.id?.editPost?.id ? 'Edit Post' : 'Create Post'}
                open={fetchAddEditPost?.isOpen}
                onCancel={handleCancel}
                footer={null}
                width={658}
                className={`post-modal ${styles.postModal} ${mobile ? styles.mobilePostModal : ''}`}
                closeIcon={<CloseOutlined />}
              >
                <div className={styles.modalContent}>
                  {/* User Info and Input Section */}
                  <div className={styles.userInfo}>
                    <Image
                      src={isStorePost ? user?.store?.logo_image : (user?.logo_image ? getImageUrl(user?.logo_image) : '/assets/img/dummy-profile.svg')}
                      alt="Profile"
                      width={40}
                      height={40}
                      preview={false}
                      className={styles.avatar}
                    />
                    <div className={styles.userDetails}>
                      {/* <div className={styles.nameAndVisibility}>
                        <h3>{user?.name}</h3>
                        <Dropdown
                          overlay={
                            <Menu>
                              {visibilityOptions.map(option => (
                                <Menu.Item
                                  key={option.value}
                                  onClick={() => handleVisibilityChange(option.value)}
                                >
                                  <div className={styles.visibilityOption}>
                                    {option.icon}
                                    <div>
                                      <div className={styles.visibilityTitle}>{option.title}</div>
                                      <div className={styles.visibilityDescription}>{option.description}</div>
                                    </div>
                                  </div>
                                </Menu.Item>
                              ))}
                            </Menu>
                          }
                          trigger={['click']}
                        >
                          <button className={styles.visibilitySelector}>
                            {visibilityOptions.find(opt => opt.value === visibility)?.title || 'Everyone'}
                            <DownOutlined />
                          </button>
                        </Dropdown>
                      </div> */}
                      <div className={styles.postInput}>
                        <img src="/assets/seller/icons/feather.svg" alt="feather icon" className={styles.featherIcon} />
                        <Input.TextArea
                          placeholder="Write something..."
                          autoSize={{ minRows: 2, maxRows: 4 }}
                          value={postContent}
                          onChange={(e) => setPostContent(e.target.value)}
                          className={styles.input}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Image Preview Grid */}
                  {newUserInfo.profileImages?.length > 0 && (
                    <div className={styles.imagePreviewGrid}>
                      {newUserInfo.profileImages.slice(0, 5).map((image, index) => (
                        <div key={index} className={styles.imagePreviewItem}>
                          <img
                            src={URL.createObjectURL(image)}
                            alt={`Preview ${index + 1}`}
                            className={styles.previewImage}
                          />
                          <button
                            className={styles.removeImageButton}
                            onClick={() => handleRemoveImage(index)}
                          >
                            <CloseOutlined />
                          </button>
                        </div>
                      ))}
                      {newUserInfo.profileImages.length > 5 && (
                        <div className={styles.imageCountOverlay}>
                          <span>+{newUserInfo.profileImages.length - 5}</span>
                        </div>
                      )}
                    </div>
                  )}

                  {/* Action Buttons */}
                  <div className={styles.actionButtons}>
                    <div className={styles.leftButtons}>
                      <button className={`${styles.actionButton} ${styles.mediaButton}`} onClick={() => document.getElementById('fileInput')?.click()}>
                        <img src="/assets/img/feed/postimg.png" alt="Media" />
                        Media
                      </button>
                      <button className={`${styles.actionButton} ${styles.emojiButton}`} onClick={() => setShowEmojis(!showEmojis)}>
                        <img src="/assets/img/feed/postemoji.png" alt="Emoji" />
                        Emoji
                      </button>
                      <button className={`${styles.actionButton} ${styles.gifButton}`} onClick={() => setShowGifModal(true)}>
                        <img src="/assets/img/feed/postgif.png" alt="GIF" />
                        GIF
                      </button>
                      <button className={`${styles.actionButton} ${styles.aiButton}`} onClick={handleAiPost}>
                        <img src="/assets/img/feed/postai.png" alt="AI Post" />
                        AI Post
                      </button>
                    </div>
                    <div className={styles.rightButtons}>
                      {loading ? (
                        <button className={styles.submitButton} disabled>
                          <Spin size="small" />
                        </button>
                      ) : (
                        <button
                          onClick={handleSubmit}
                          disabled={!postContent?.trim() && !newUserInfo.profileImages?.length}
                          className={styles.submitButton}
                        >
                          Post
                        </button>
                      )}
                    </div>
                  </div>

                  {/* Emoji Picker Overlay */}
                  {showEmojis && (
                    <div className={styles.pickerOverlay}>
                      <div className={styles.pickerHeader}>
                        <button onClick={() => setShowEmojis(false)} className={styles.backButton}>
                          <LeftOutlined /> Back
                        </button>
                        <span>Select Emoji</span>
                        <button onClick={() => setShowEmojis(false)} className={styles.closeButton}>
                          <CloseOutlined />
                        </button>
                      </div>
                      <div className={styles.pickerContent}>
                        <Picker onSelect={addEmoji} set="apple" />
                      </div>
                    </div>
                  )}

                  {/* GIF Picker Overlay */}
                  {showGifModal && (
                    <div className={styles.pickerOverlay}>
                      <div className={styles.pickerHeader}>
                        <button onClick={() => setShowGifModal(false)} className={styles.backButton}>
                          <LeftOutlined /> Back
                        </button>
                        <span>Select GIF</span>
                        <button onClick={() => setShowGifModal(false)} className={styles.closeButton}>
                          <CloseOutlined />
                        </button>
                      </div>
                      <div className={styles.pickerContent}>
                        <div className={styles.gifSearch}>
                          <Input.Search
                            placeholder="Search GIFs..."
                            onChange={(e) => handleGifSearch(e.target.value)}
                            className={styles.searchInput}
                          />
                        </div>
                        <div className={styles.gifGrid}>
                          {isLoadingGifs ? (
                            <div className={styles.loading}>
                              <Spin />
                            </div>
                          ) : (
                            // <div className={styles.gifResults}>
                            <>
                              {gifs.map((gif) => (
                                <div
                                  key={gif.id}
                                  className={styles.gifItem}
                                  onClick={() => handleGifSelect(gif)}
                                >
                                  <img
                                    src={gif.media_formats?.mediumgif?.url || gif.media_formats?.gif?.url || gif.url}
                                    alt={gif.content_description || 'GIF'}
                                    loading="lazy"
                                  />
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {showAiModal && (
                    <div className={styles.aiOverlay}>
                      <div className={styles.aiHeader}>
                        <button onClick={() => setShowAiModal(false)} className={styles.backButton}>
                          <LeftOutlined /> Back
                        </button>
                        <span>AI Post Generator</span>
                        <button onClick={() => setShowAiModal(false)} className={styles.closeButton}>
                          <CloseOutlined />
                        </button>
                      </div>
                      <div className={styles.aiContent}>
                        <div className={styles.aiInputSection}>
                          <div className={styles.aiInputHeader}>
                            <h3>What would you like to post about?</h3>
                            <Select
                              value={selectedAiStyle}
                              onChange={(value) => setSelectedAiStyle(value)}
                              className={styles.styleSelect}
                              options={aiStyles.map(style => ({
                                value: style.key,
                                label: style.label
                              }))}
                              placeholder="Select style"
                            />
                          </div>
                          <Input.TextArea
                            value={aiPrompt}
                            onChange={(e) => setAiPrompt(e.target.value)}
                            placeholder="Enter a topic or idea for your post..."
                            className={styles.aiPromptInput}
                            rows={4}
                          />
                        </div>

                        <div className={styles.aiGenerateSection}>
                          {generatedContent ? (
                            <>
                              <div className={styles.generatedContent}>
                                <h3>Generated Post</h3>
                                <div className={styles.contentPreview}>
                                  {generatedContent}
                                </div>
                              </div>
                              <div className={styles.actionButtons}>
                                <Button onClick={() => setGeneratedContent('')}>
                                  Generate Again
                                </Button>
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    setPostContent(generatedContent);
                                    setShowAiModal(false);
                                  }}
                                >
                                  Use This Post
                                </Button>
                              </div>
                            </>
                          ) : (
                            <Button
                              type="primary"
                              onClick={generateAiContent}
                              disabled={!aiPrompt.trim()}
                              loading={showAiLoading}
                              className={styles.generateButton}
                            >
                              Generate Post
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Modal>
            )}
          </div>
        </>
      )}
      <input
        type="file"
        id="fileInput"
        multiple
        accept="image/*, video/*"
        onChange={handleImageUpload}
        style={{ display: 'none' }}
      />
    </>
  );
}
