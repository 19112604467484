import React, { useState } from 'react'
import ProfileImg from '../../../../public/assets/img/auther-img.png'
import MoreIcon from '../../../../public/assets/icon/moreOptionIcon.svg'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'

export default function GroupMenubarMobile({ setTab, group }) {
  const dispatch = useAppDispatch()
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const fetchSroll = useSelector((state: any) => state?.userProfileModalScroll?.data)
  const [tabIndex, setTabIndex] = useState(0)

  function activeLink(id) {
    setTabIndex(id)
  }

  const handleClose = () => {
    let datass = getAllState?.allModalData?.filter((item) => item?.modal !== getAllState?.lastModalData?.modal)

    dispatch(
      fetchModalData({
        allModalData: datass,
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  return (
    <div className="stcky-header-alignment">
      {fetchSroll?.scroll >= 500 && (
        <div className="club-mobile-view-heading-section">
          <div className="store-back_details-alignment" onClick={() => handleClose()}>
            <div>
              <img src="/assets/icon/left-icon.svg" alt="back-page" />
            </div>
            <p>Back</p>
          </div>
          <div className="store-mobile-header-alignment">
            <div className="mobile-profile-img-alignment">
              <img src={group?.coverImage} alt="ProfileImg" />
            </div>
            <div className="mobile-profile-name-alignment">
              <h5>{group?.name}</h5>
            </div>
          </div>
          <div
            className="store-more-option-alignment"
            onClick={() => {
              dispatch(
                fetchMobileModalData({
                  allModalData: [
                    ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                    { modal: 'groupControlModal', isOpen: true, id: group, transform: 35 },
                  ],
                  lastModalData: fetchMobileModal?.lastModalData,
                }),
              )
            }}
          >
            <img src={MoreIcon.src} alt="MoreIcon" />
          </div>
        </div>
      )}
      <div className="mobile-view-club-tab-alignment">
        <ul>
          <li
            className={tabIndex === 0 && 'active-club-tab'}
            onClick={() => {
              activeLink(0)
              setTab('feed')
            }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7 19C8.10457 19 9 18.1046 9 17C9 15.8954 8.10457 15 7 15C5.89543 15 5 15.8954 5 17C5 18.1046 5.89543 19 7 19Z"
                fill={tabIndex === 0 ? '#3C4EA3' : '#636773'}
              />
              <path
                d="M6.54004 9.53791C5.72644 9.40232 5 10.0706 5 10.8938C5 11.5814 5.51335 12.1334 6.19135 12.2497C9.01961 12.7436 11.2473 14.9808 11.751 17.8088C11.8672 18.4867 12.4193 19 13.107 19C13.9303 19 14.5986 18.2736 14.4727 17.4601C14.1498 15.474 13.2098 13.64 11.7859 12.218C10.3621 10.7961 8.52673 9.85838 6.54004 9.53791ZM6.51099 4.00787C5.70706 3.9207 5 4.56959 5 5.37343C5 6.08042 5.53272 6.66151 6.2301 6.72931C12.0513 7.3104 16.6811 11.9398 17.2622 17.7603C17.33 18.4673 17.9112 19 18.6182 19C19.4319 19 20.0711 18.293 19.9936 17.4892C19.2866 10.3805 13.6301 4.71486 6.51099 4.00787Z"
                fill={tabIndex === 0 ? '#3C4EA3' : '#636773'}
              />
            </svg>
            <p>Feed</p>
          </li>
          <li
            className={tabIndex === 1 && 'active-club-tab'}
            onClick={() => {
              activeLink(1)
              setTab('member')
            }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.3595 19.8471V18.1033C16.3595 17.1783 15.992 16.2913 15.338 15.6372C14.6839 14.9832 13.7969 14.6157 12.8719 14.6157H5.89675C4.97179 14.6157 4.08471 14.9832 3.43067 15.6372C2.77662 16.2913 2.40918 17.1783 2.40918 18.1033V19.8471"
                stroke={tabIndex === 1 ? '#3C4EA3' : '#636773'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.3843 11.128C11.3104 11.128 12.8719 9.56654 12.8719 7.6404C12.8719 5.71427 11.3104 4.15283 9.3843 4.15283C7.45817 4.15283 5.89673 5.71427 5.89673 7.6404C5.89673 9.56654 7.45817 11.128 9.3843 11.128Z"
                stroke={tabIndex === 1 ? '#3C4EA3' : '#636773'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.5908 19.847V18.1032C21.5902 17.3305 21.333 16.5798 20.8596 15.9691C20.3861 15.3584 19.7233 14.9222 18.9751 14.729"
                stroke={tabIndex === 1 ? '#3C4EA3' : '#636773'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.4875 4.26611C16.2377 4.45819 16.9027 4.89449 17.3775 5.50622C17.8523 6.11794 18.1101 6.87031 18.1101 7.6447C18.1101 8.41909 17.8523 9.17145 17.3775 9.78318C16.9027 10.3949 16.2377 10.8312 15.4875 11.0233"
                stroke={tabIndex === 1 ? '#3C4EA3' : '#636773'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <p>Member</p>
          </li>
          <li
            className={tabIndex === 2 && 'active-club-tab'}
            onClick={() => {
              activeLink(2)
              setTab('media')
            }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="#636773" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M22 8V13.81C21.12 13.3 20.1 13 19 13C15.69 13 13 15.69 13 19C13 19.34 13.04 19.67 13.09 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0391 2 18.5304 2 18V6C2 4.89 2.89 4 4 4H10L12 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8ZM17 22L22 19L17 16V22Z"
                fill={tabIndex === 2 ? '#3C4EA3' : '#636773'}
              />
            </svg>
            <p>Media</p>
          </li>
          <li
            className={tabIndex === 3 && 'active-club-tab'}
            onClick={() => {
              activeLink(3)
              setTab('search')
            }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2 19V17H12V19H2ZM2 14V12H7V14H2ZM2 9V7H7V9H2ZM20.6 19L16.75 15.15C16.35 15.4333 15.9127 15.6457 15.438 15.787C14.9627 15.929 14.4833 16 14 16C12.6167 16 11.4377 15.5123 10.463 14.537C9.48767 13.5623 9 12.3833 9 11C9 9.61667 9.48767 8.43733 10.463 7.462C11.4377 6.48733 12.6167 6 14 6C15.3833 6 16.5627 6.48733 17.538 7.462C18.5127 8.43733 19 9.61667 19 11C19 11.4833 18.9293 11.9627 18.788 12.438C18.646 12.9127 18.4333 13.35 18.15 13.75L22 17.6L20.6 19ZM14 14C14.8333 14 15.5417 13.7083 16.125 13.125C16.7083 12.5417 17 11.8333 17 11C17 10.1667 16.7083 9.45833 16.125 8.875C15.5417 8.29167 14.8333 8 14 8C13.1667 8 12.4583 8.29167 11.875 8.875C11.2917 9.45833 11 10.1667 11 11C11 11.8333 11.2917 12.5417 11.875 13.125C12.4583 13.7083 13.1667 14 14 14Z"
                fill={tabIndex === 3 ? '#3C4EA3' : '#636773'}
              />
            </svg>
            <p>Search</p>
          </li>
        </ul>
      </div>
    </div>
  )
}
