import React, { useEffect, useState } from 'react'
import styles from './SellerCustomerModal.module.scss'
import { Button, Dropdown, Menu } from 'antd'
import { useAppDispatch } from 'src/store/store'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/client'
import { useAuth } from 'src/utils/auth'
import GET_ALL_ORDERS_DATA_WITH_CUSTOMER_DETAILS from 'src/graphql/queries/sellerQueries/getAllOrdersDataWithCustomerDetails'

export default function SellerCustomerModal() {
  const dispatch = useAppDispatch()
  const { user }: any = useAuth()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const [isSort, setIsSort] = useState('Latest')
  const { loading, error, data, refetch } = useQuery(GET_ALL_ORDERS_DATA_WITH_CUSTOMER_DETAILS, {
    variables: {
      store_id: user?.store?.id,
    },
  })

  const changeSortType = (e: { key: string }) => {
    setIsSort(e.key)
  }

  const SortType = (
    <Menu onClick={changeSortType}>
      <Menu.Item key="Latest">Latest</Menu.Item>
      <Menu.Item key="Highest">Highest</Menu.Item>
      <Menu.Item key="Lowest">Lowest</Menu.Item>
    </Menu>
  )

  const handleMove = (customer_id) => {
    dispatch(
      fetchModalData({
        allModalData: [
          ...getAllState?.allModalData,
          { modal: 'sellerCustomerDetailModal', isOpen: true, id: customer_id, modalFor: 'mediumModal' },
        ],
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  useEffect(() => {
    refetch()
  }, [])

  return (
    <div className={styles.sellerCustomersChildDetailsModalSection}>
      <div className={styles.sellerCustomersChildDetailsModalBg}>
        <div className={styles.sellerCustomersChildDetailsHeading}>
          <div className={styles.sellerCustomersChildDetailsTopheading}>
            <div className={styles.sellerCustomersChildDetailsHeadingName}>
              <h6>Customers</h6>
              <div className={styles.sellerCustomerTotalNumber}>
                <span>{data?.getAllOrdersDataWithCustomerDetails?.data?.length?.toLocaleString()}</span>
              </div>
            </div>
            <div className={styles.importCsvSection}>
              <div className={styles.importIconAlignment}>
                <img src="/assets/seller/icons/file-import.svg" />
              </div>
              <p>Import CSV</p>
            </div>
          </div>
          <div className={styles.sellerCustomerFilterSectionAlignment}>
            <div className={styles.sellerFilterAndSearchAlignment}>
              <div className={styles.filterBoxAlignment}>
                <div className={styles.filterIconAlignment}>
                  <img src="/assets/seller/icons/filter-icon.svg" alt="filter icon" />
                </div>
                <span>Filters</span>
              </div>
              <div className={styles.searchCustomerBoxAlignment}>
                <div className={styles.searchIconAlignment}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
                      stroke="#ACB1C0"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.9996 14.0001L11.0996 11.1001"
                      stroke="#ACB1C0"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <input type="text" placeholder="Search customer here..." />
              </div>
            </div>
            <div className={styles.sellerCustomerFilterSSortDropdown}>
              <label>Sort: </label>
              <Dropdown overlay={SortType} className=" right-0 flex justify-center h-14">
                <Button className="filter-button dropdown-gap">
                  {isSort}
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L5 5L9 1" stroke="#636773" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </Button>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className={styles.sellerCustomerDetailsTableAlignment}>
          <div className={styles.sellerCustomerDetailsTable}>
            <table>
              <thead>
                <tr>
                  <th>Customer Name</th>
                  <th className={styles.alignCenter}>Purchased Item</th>
                  <th className={styles.alignCenter}>Total Spent</th>
                  <th className={styles.alignCenter}>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.getAllOrdersDataWithCustomerDetails?.data?.map((cst, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className={styles.sellerCustomerProfile}>
                          <div className={styles.sellerCustomerProfileImg}>
                            <img src={cst?.customer?.logo_image} alt="customer seller" />
                          </div>
                          <p>{cst?.customer?.firstName + ' ' + cst?.customer?.lastName}</p>
                        </div>
                      </td>
                      <td>
                        <div className={styles.productItemTotal}>
                          <span>{cst?.total_orders}</span>
                        </div>
                      </td>
                      <td>
                        <div className={styles.productTotalSpan}>
                          <p>${cst?.total_spent}</p>
                        </div>
                      </td>
                      <td>
                        <div className={styles.productActionFlex}>
                          <div>
                            <img src="/assets/seller/icons/chat-message-icon.svg" alt="message-icon" />
                          </div>
                          <div onClick={() => handleMove(cst)}>
                            <img src="/assets/seller/icons/right-grey-arrow2.svg" alt="more" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
