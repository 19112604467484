import React, { useEffect, useRef, useState } from 'react'
import styles from './MobileProductDetailsShareModal.module.scss'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchProductShareOptions } from 'src/store/slices/productShareOptionsSlice'
import { useQuery } from '@apollo/client'
import GET_PRODUCT_BY_NAME from 'src/graphql/queries/getProductByName'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'
import { useRouter } from 'next/router'
import { FacebookShareButton, PinterestShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'
import { notification } from 'antd'
import client1 from 'src/utils/apolloClient'
import Loader from 'src/components/UI/Loader'
import GET_PRODUCT_IMAGES from 'src/graphql/queries/getProductImages'

export default function MobileProductDetailsShareModal() {
  const dropdownRef: any = useRef()
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const fetchProductImageResize = useSelector((state: any) => state?.productResizeData?.data)
  const isMobile = window.innerWidth < 600
  const router = useRouter()

  const storeData = {
    name: 'Alexander McQueen',
    id: 1,
  }

  let hostname

  if (typeof window !== 'undefined') {
    hostname = window.location.origin.split('/')[2]
  }

  const [groupId, setGroupId] = useState<any>({})
  const [isLoader, setIsLoader] = useState<any>(false)

  const { loading, error, data, refetch } = useQuery(GET_PRODUCT_BY_NAME, {
    variables: {
      slug: router?.query?.id,

      // store_id: Number(
      //   fetchProductImageResize?.singleBookmarkProduct?.isProduct
      //     ? fetchProductImageResize?.singleBookmarkProduct?.id
      //       ? fetchProductImageResize?.singleBookmarkProduct?.id
      //       : getAllState?.lastModalData?.id
      //     : getAllState?.lastModalData?.id,
      // ),
    },
  })

  // const { data: data2 } = useQuery(GET_ALL_PRODUCT_BY_STORE, {
  //   variables: { store_id: Number(getAllState?.lastModalData?.store_id), page: 1, limit: 250, search: '', status: '' },
  // })

  // useEffect(() => {
  //   const selectedProduct = data2?.getAllProductByStore?.data?.find(
  //     (product) => product.id === Number(getAllState?.lastModalData?.id),
  //   )
  //   setSelectedProduct({ ...selectedProduct, selectedProduct, storeData })
  // }, [data2])

  const handleShareCloseModel = () => {
    dispatch(
      fetchProductImageResizeModal({
        modal: 'mobileProductDetailsShareModal',
        isOpen: false,
        isShareBookmark: false,
        singleBookmarkProduct: {
          isProduct: false,
          id: null,
        },
      }),
    )
  }

  const shareOnfeed = async () => {
    if (isMobile) {
      dispatch(
        fetchMobileModalData({
          allModalData: [
            ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
            {
              modal: 'productShareOnMyFeed',
              isOpen: true,
              id: {
                ...data?.getSingleProduct,
                dis_listPrice: fetchMobileModal?.lastModalData?.id?.dis_price,
                dis_price: fetchMobileModal?.lastModalData?.id?.price,
              },
              transform: 20,
            },
          ],
          lastModalData: fetchMobileModal?.lastModalData,
        }),
      )
    } else {
      if (fetchProductImageResize?.isShareBookmark) {
        dispatch(
          fetchProductShareOptions({
            modal: 'shareProductFromBookmark',
            isOpen: true,
            isShareBookmark: fetchProductImageResize?.isShareBookmark,
            id: {
              shareData: fetchProductImageResize?.shareData,
              detail: fetchProductImageResize?.detail,
            },
          }),
        )
      } else {
        setIsLoader(true)
        const { data, loading } = await client1.query({
          query: GET_PRODUCT_IMAGES,
          variables: {
            productId: fetchProductImageResize?.shareData?.id,
          },
          fetchPolicy: 'network-only',
        })
        dispatch(
          fetchProductShareOptions({
            modal: 'productShareOnMyFeedModal',
            isOpen: true,
            id: {
              ...data,
              selectedProduct: {
                ...fetchProductImageResize?.shareData,
                images: data?.getProductImages?.data?.images,
                dis_listPrice: fetchProductImageResize?.dis_price,
                dis_price: fetchProductImageResize?.price,
              },
              isGroupPost: false,
            },
          }),
        )
      }
    }
  }

  const handleOpenShareFriend = () => {
    if (isMobile) {
      dispatch(
        fetchMobileModalData({
          allModalData: [
            ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
            {
              modal: 'productSharewithFriend',
              isOpen: true,
              id: data?.getSingleProduct,
              transform: 20,
            },
          ],
          lastModalData: fetchMobileModal?.lastModalData,
        }),
      )
    } else {
      dispatch(
        fetchProductShareOptions({
          modal: 'productShareWithFriend',
          isOpen: true,
          id: {
            product_id: fetchProductImageResize?.shareData?.id,
          },
        }),
      )
    }
  }

  const shareFeedOnGroup = () => {
    if (isMobile) {
      dispatch(
        fetchMobileModalData({
          allModalData: [
            ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
            {
              modal: 'productShareInClub',
              isOpen: true,
              id: {
                ...data,
                groupId: groupId,
                selectedProduct: {
                  ...data?.getSingleProduct,
                  dis_price: fetchMobileModal?.lastModalData?.id?.price,
                  dis_listPrice: fetchMobileModal?.lastModalData?.id?.dis_price,
                },
                isGroupPost: true,
              },
              transform: 220,
            },
          ],
          lastModalData: fetchMobileModal?.lastModalData,
        }),
      )
    } else {
      dispatch(
        fetchProductShareOptions({
          modal: 'productSharewithClub',
          isOpen: true,
          id: {
            ...data,
            groupId: groupId,
            selectedProduct: fetchProductImageResize?.isShareBookmark
              ? fetchProductImageResize?.shareData
              : {
                  ...data?.getSingleProduct,
                  dis_price: fetchProductImageResize?.price,
                  dis_listPrice: fetchProductImageResize?.dis_price,
                },
            isGroupPost: true,
          },
        }),
      )
    }
  }

  // useEffect(() => {
  //   const checkIfClickedOutside = (e) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
  //       dispatch(
  //         fetchProductImageResizeModal({
  //           modal: 'mobileProductDetailsShareModal',
  //           isOpen: false,
  //         }),
  //       )
  //     }
  //   }
  //   document.addEventListener('mousedown', checkIfClickedOutside)
  //   return () => {
  //     document.removeEventListener('mousedown', checkIfClickedOutside)
  //   }
  // }, [dropdownRef])

  const handleShare = (type) => {}

  const handleCopyClick = (text) => {
    navigator?.clipboard
      .writeText(text)
      .then(() => {
        notification['success']({
          message: 'Copied!',
        })
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err)
      })
  }

  const getProductImage = () => {
    if (data?.getSingleProduct?.variants?.length > 0 && data?.getSingleProduct?.variants[0]?.image?.media) {
      return data?.getSingleProduct?.variants[0]?.image?.media;
    }
    return '';
  };

  return (
    <>
      <div ref={dropdownRef}>
        <div className="mobile-view-details-alignment product-details-mobile-view-option">
          <div className="product-details-share-modal-heading">
            <h4>Share</h4>
            <div onClick={() => handleShareCloseModel()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M18 6L6 18" stroke="#7A7E88" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 6L18 18" stroke="#7A7E88" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>
          <div className="mobile-view-sharing-option-alignment">
            <div className="mobile-view-sharing-option-flex">
              <div
                className="mobile-view-sharing-box"
                onClick={() => handleCopyClick(`${process.env.NEXT_PUBLIC_DOMAIN}${router?.asPath}`)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <g clipPath="url(#clip0_2144_94190)">
                    <path
                      d="M10 13.0019C10.4294 13.576 10.9774 14.051 11.6065 14.3948C12.2357 14.7385 12.9315 14.943 13.6466 14.9942C14.3618 15.0454 15.0796 14.9422 15.7513 14.6916C16.4231 14.441 17.0331 14.0489 17.54 13.5419L20.5399 10.5419C21.4507 9.59886 21.9547 8.33586 21.9433 7.02488C21.9319 5.71391 21.4061 4.45985 20.479 3.53282C19.552 2.60578 18.2979 2.07994 16.987 2.06855C15.676 2.05716 14.413 2.56113 13.47 3.47191L11.75 5.1819"
                      stroke="#1E2432"
                      strokeWidth="2.28571"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.0001 11.0012C13.5707 10.4271 13.0228 9.95201 12.3936 9.60826C11.7644 9.2645 11.0686 9.06008 10.3535 9.00887C9.63836 8.95765 8.92057 9.06083 8.24882 9.31142C7.57706 9.562 6.96706 9.95413 6.46017 10.4612L3.46019 13.4612C2.54941 14.4042 2.04544 15.6672 2.05683 16.9782C2.06822 18.2891 2.59406 19.5432 3.5211 20.4702C4.44813 21.3973 5.70219 21.9231 7.01316 21.9345C8.32414 21.9459 9.58714 21.4419 10.5301 20.5311L12.2401 18.8211"
                      stroke="#1E2432"
                      strokeWidth="2.28571"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2144_94190">
                      <rect width="23.9999" height="23.9999" fill="white" transform="translate(0 0.00195312)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="mobile-view-sharing-box" onClick={() => handleShare('facebook')}>
                <FacebookShareButton url={`${process.env.NEXT_PUBLIC_DOMAIN}${router?.asPath}`} hashtag="#YourHashtag">
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <path
                      d="M44.0002 23.9981C44.0002 12.9525 35.0458 3.99805 24.0001 3.99805C12.9544 3.99805 4 12.9523 4 23.9981C4 33.9807 11.3138 42.2549 20.8751 43.7553V29.7794H15.7969V23.9981H20.8751V19.5919C20.8751 14.5793 23.861 11.8106 28.4293 11.8106C30.6176 11.8106 32.9064 12.2012 32.9064 12.2012V17.1231H30.3845C27.8998 17.1231 27.1251 18.6648 27.1251 20.2466V23.9981H32.672L31.7853 29.7794H27.1251V43.7553C36.6864 42.2549 44.0002 33.9808 44.0002 23.9981Z"
                      fill="#1877F2"
                    />
                    <path
                      d="M31.7852 29.7794L32.6719 23.9981H27.125V20.2465C27.125 18.6646 27.8999 17.1231 30.3844 17.1231H32.9063V12.2012C32.9063 12.2012 30.6176 11.8105 28.4293 11.8105C23.861 11.8105 20.875 14.5793 20.875 19.5918V23.9981H15.7969V29.7794H20.875V43.7552C21.9088 43.9172 22.9536 43.9985 24 43.9982C25.0464 43.9985 26.0913 43.9173 27.125 43.7552V29.7794H31.7852Z"
                      fill="white"
                    />
                  </svg>
                </FacebookShareButton>
              </div>
              <div className="mobile-view-sharing-box" onClick={() => handleShare('whatsapp')}>
                <WhatsappShareButton url={`${process.env.NEXT_PUBLIC_DOMAIN}${router?.asPath}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <path
                      d="M38.0998 9.8182C36.2662 7.96604 34.0821 6.49752 31.675 5.49828C29.2679 4.49904 26.686 3.98909 24.0797 3.99817C13.1597 3.99817 4.25961 12.8982 4.25961 23.8183C4.25961 27.3183 5.17962 30.7183 6.89963 33.7183L4.09961 43.9984L14.5997 41.2384C17.4997 42.8184 20.7597 43.6584 24.0797 43.6584C34.9998 43.6584 43.8998 34.7583 43.8998 23.8383C43.8998 18.5382 41.8398 13.5582 38.0998 9.8182ZM24.0797 40.2984C21.1197 40.2984 18.2197 39.4984 15.6797 37.9984L15.0797 37.6384L8.83964 39.2784L10.4996 33.1983L10.0996 32.5783C8.45513 29.9523 7.58191 26.9168 7.57963 23.8183C7.57963 14.7382 14.9797 7.33819 24.0597 7.33819C28.4598 7.33819 32.5998 9.0582 35.6998 12.1782C37.2348 13.7062 38.4512 15.5236 39.2786 17.5251C40.106 19.5266 40.5279 21.6725 40.5198 23.8383C40.5598 32.9183 33.1598 40.2984 24.0797 40.2984ZM33.1198 27.9783C32.6198 27.7383 30.1798 26.5383 29.7398 26.3583C29.2798 26.1983 28.9598 26.1183 28.6198 26.5983C28.2798 27.0983 27.3397 28.2183 27.0597 28.5383C26.7797 28.8783 26.4797 28.9183 25.9797 28.6583C25.4797 28.4183 23.8797 27.8783 21.9997 26.1983C20.5197 24.8783 19.5397 23.2583 19.2397 22.7583C18.9597 22.2583 19.1997 21.9983 19.4597 21.7383C19.6797 21.5183 19.9597 21.1583 20.1997 20.8783C20.4397 20.5983 20.5397 20.3783 20.6997 20.0583C20.8597 19.7183 20.7797 19.4383 20.6597 19.1983C20.5397 18.9583 19.5397 16.5182 19.1397 15.5182C18.7397 14.5582 18.3197 14.6782 18.0197 14.6582H17.0597C16.7197 14.6582 16.1997 14.7782 15.7397 15.2782C15.2997 15.7782 14.0197 16.9782 14.0197 19.4183C14.0197 21.8583 15.7997 24.2183 16.0397 24.5383C16.2797 24.8783 19.5397 29.8783 24.4997 32.0183C25.6797 32.5383 26.5997 32.8383 27.3197 33.0583C28.4998 33.4383 29.5798 33.3783 30.4398 33.2583C31.3998 33.1183 33.3798 32.0583 33.7798 30.8983C34.1998 29.7383 34.1998 28.7583 34.0598 28.5383C33.9198 28.3183 33.6198 28.2183 33.1198 27.9783Z"
                      fill="#65D072"
                    />
                  </svg>
                </WhatsappShareButton>
              </div>
              <div className="mobile-view-sharing-box" onClick={() => handleShare('pintrest')}>
                <PinterestShareButton
                  description={data?.getSingleProduct?.description || ''}
                  url={`${process.env.NEXT_PUBLIC_DOMAIN}${router?.asPath}`}
                  media={getProductImage()}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <path
                      d="M18.0796 43.0783C19.9996 43.6583 21.9396 43.9983 23.9996 43.9983C29.304 43.9983 34.3911 41.8911 38.1418 38.1404C41.8926 34.3896 43.9997 29.3025 43.9997 23.9982C43.9997 21.3717 43.4824 18.771 42.4773 16.3444C41.4722 13.9179 39.999 11.7131 38.1418 9.85595C36.2847 7.99876 34.0799 6.52557 31.6533 5.52047C29.2268 4.51537 26.6261 3.99805 23.9996 3.99805C21.3732 3.99805 18.7724 4.51537 16.3459 5.52047C13.9194 6.52557 11.7146 7.99876 9.85741 9.85595C6.10666 13.6067 3.99951 18.6938 3.99951 23.9982C3.99951 32.4982 9.33954 39.7983 16.8796 42.6783C16.6996 41.1183 16.5196 38.5382 16.8796 36.7582L19.1796 26.8782C19.1796 26.8782 18.5996 25.7182 18.5996 23.8782C18.5996 21.1181 20.3196 19.0581 22.2796 19.0581C23.9996 19.0581 24.7996 20.3181 24.7996 21.9382C24.7996 23.6582 23.6596 26.1182 23.0796 28.4782C22.7396 30.4382 24.1196 32.1582 26.1196 32.1582C29.6797 32.1582 32.4397 28.3582 32.4397 22.9982C32.4397 18.1981 28.9997 14.9181 24.0596 14.9181C18.4196 14.9181 15.0996 19.1181 15.0996 23.5382C15.0996 25.2582 15.6596 26.9982 16.5796 28.1382C16.7596 28.2582 16.7596 28.4182 16.6996 28.7182L16.1196 30.8982C16.1196 31.2382 15.8996 31.3582 15.5596 31.1182C12.9996 29.9982 11.5196 26.3582 11.5196 23.4182C11.5196 17.0981 15.9996 11.3581 24.6396 11.3581C31.5197 11.3581 36.8797 16.2981 36.8797 22.8582C36.8797 29.7382 32.6197 35.2582 26.5196 35.2582C24.5796 35.2582 22.6796 34.2182 21.9996 32.9982L20.6596 37.7382C20.1996 39.4583 18.9396 41.7583 18.0796 43.1383V43.0783Z"
                      fill="#D32D2F"
                    />
                  </svg>
                </PinterestShareButton>
              </div>
              <div className="mobile-view-sharing-box" onClick={() => handleShare('twitter')}>
                <TwitterShareButton url={`${process.env.NEXT_PUBLIC_DOMAIN}${router?.asPath}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <path
                      d="M29.2013 6H34.108L23.388 17.8619L36 34H26.1253L18.392 24.211L9.54133 34H4.632L16.0987 21.312L4 6.00129H14.1253L21.116 14.9487L29.2013 6ZM27.48 31.1578H30.1987L12.648 8.69377H9.73067L27.48 31.1578Z"
                      fill="#101419"
                    />
                  </svg>
                </TwitterShareButton>
              </div>
            </div>
          </div>
          <div className="product-details-mobile-view-main">
            <div
              className="mobile-view-details-grid-alignment"
              // onClick={() => shareOnfeed()}
              onClick={() => shareOnfeed()}
            >
              <div className="mobile-view-details-icon-alignment">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19 3H5C3.89543 3 3 3.89543 3 5V10C3 11.1046 3.89543 12 5 12H19C20.1046 12 21 11.1046 21 10V5C21 3.89543 20.1046 3 19 3Z"
                    stroke="#808191"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 15H5C3.89543 15 3 15.8954 3 17V19C3 20.1046 3.89543 21 5 21H10C11.1046 21 12 20.1046 12 19V17C12 15.8954 11.1046 15 10 15Z"
                    stroke="#808191"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19 15H17C15.8954 15 15 15.8954 15 17V19C15 20.1046 15.8954 21 17 21H19C20.1046 21 21 20.1046 21 19V17C21 15.8954 20.1046 15 19 15Z"
                    stroke="#808191"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="mobile-view-child-details-alignment">
                <h5>Share on my feed</h5>
                <p>
                  {fetchProductImageResize?.isShareBookmark
                    ? 'Share collection post on my feed'
                    : 'Share this post on my feed'}
                </p>
              </div>
            </div>
            <div
              className="mobile-view-details-grid-alignment"
              // onClick={() => {
              //   dispatch(
              //     fetchMobileModalData({
              //       allModalData: [
              //         ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
              //         {
              //           modal: 'productSharewithFriend',
              //           isOpen: true,
              //           id: selectedProduct,
              //           transform: 20,
              //         },
              //       ],
              //       lastModalData: fetchMobileModal?.lastModalData,
              //     }),
              //   )
              // }}
              onClick={() => handleOpenShareFriend()}
            >
              <div className="mobile-view-details-icon-alignment">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20 21.875V19.7917C20 18.6866 19.5786 17.6268 18.8284 16.8454C18.0783 16.064 17.0609 15.625 16 15.625H8C6.93913 15.625 5.92172 16.064 5.17157 16.8454C4.42143 17.6268 4 18.6866 4 19.7917V21.875"
                    stroke="#808191"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 11.4583C14.2091 11.4583 16 9.59285 16 7.29167C16 4.99048 14.2091 3.125 12 3.125C9.79086 3.125 8 4.99048 8 7.29167C8 9.59285 9.79086 11.4583 12 11.4583Z"
                    stroke="#808191"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="mobile-view-child-details-alignment">
                <h5>Share with Friends</h5>
                <p>
                  {fetchProductImageResize?.isShareBookmark
                    ? 'Share collection with friends'
                    : 'Share post with friends'}
                </p>
              </div>
            </div>
            <div className="mobile-view-details-grid-alignment" onClick={() => shareFeedOnGroup()}>
              <div className="mobile-view-details-icon-alignment">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17 21.875V19.7917C17 18.6866 16.5786 17.6268 15.8284 16.8454C15.0783 16.064 14.0609 15.625 13 15.625H5C3.93913 15.625 2.92172 16.064 2.17157 16.8454C1.42143 17.6268 1 18.6866 1 19.7917V21.875"
                    stroke="#808191"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 11.4583C11.2091 11.4583 13 9.59285 13 7.29167C13 4.99048 11.2091 3.125 9 3.125C6.79086 3.125 5 4.99048 5 7.29167C5 9.59285 6.79086 11.4583 9 11.4583Z"
                    stroke="#808191"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M23 21.8751V19.7917C22.9993 18.8685 22.7044 17.9717 22.1614 17.2421C21.6184 16.5124 20.8581 15.9913 20 15.7605"
                    stroke="#808191"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16 3.2605C16.8604 3.48998 17.623 4.01123 18.1676 4.74207C18.7122 5.47291 19.0078 6.37178 19.0078 7.29696C19.0078 8.22213 18.7122 9.121 18.1676 9.85184C17.623 10.5827 16.8604 11.1039 16 11.3334"
                    stroke="#808191"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="mobile-view-child-details-alignment">
                <h5>Share with Club</h5>
                <p>
                  {fetchProductImageResize?.isShareBookmark
                    ? 'Share collection with Clubs'
                    : 'Share post with clubs friends'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
