import React from 'react'
import Link from 'next/link'
import OverviewIcon from '../../../../../public/assets/icon/Overview.svg'
import OverviewActiveIcon from '../../../../../public/assets/icon/Overview-active.svg'
import { useRouter } from 'next/router'
const NavMenu = (props) => {
  const router = useRouter()

  return (
    <div className="summary-left-nav-menu">
      <ul>
        <li className={router.pathname.includes('overview') ? 'tab-active' : ''}>
          <Link href="/customerportal/overview" scroll={false}>
            <a className="flex items-center hover:none">
              <div className="deActive-img-alignment">
                <img src={OverviewIcon.src} alt="Overview" />
              </div>
              <div className="active-img-alignment">
                <img src={OverviewActiveIcon.src} alt="Overview" />
              </div>
              <span className="ml-7">Overview</span>
            </a>
          </Link>
        </li>
        <li className={router.pathname.includes('orders') ? 'tab-active' : ''}>
          <Link href="/customerportal/orders" scroll={false}>
            <a className="flex items-center">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="5.5" y1="7.5" x2="17.5" y2="7.5" stroke="#808191" strokeLinecap="round" />
                <line x1="5.5" y1="11.5" x2="17.5" y2="11.5" stroke="#808191" strokeLinecap="round" />
                <line x1="5.5" y1="15.5" x2="17.5" y2="15.5" stroke="#808191" strokeLinecap="round" />
                <rect x="0.75" y="0.75" width="22.5" height="22.5" rx="3.25" stroke="#7A7E88" strokeWidth="1.5" />
              </svg>

              <span className="ml-7">Orders</span>
            </a>
          </Link>
        </li>
        {/* <li className={router.pathname.includes('giftcard') ? 'tab-active' : ''}>
          <Link href="/customerportal/giftcard" scroll={false}>
            <a className="flex items-center">
              <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 4.625C0 3.53098 0.434597 2.48177 1.20818 1.70818C1.98177 0.934597 3.03098 0.5 4.125 0.5H19.875C20.969 0.5 22.0182 0.934597 22.7918 1.70818C23.5654 2.48177 24 3.53098 24 4.625V14.375C24 15.469 23.5654 16.5182 22.7918 17.2918C22.0182 18.0654 20.969 18.5 19.875 18.5H4.125C3.03098 18.5 1.98177 18.0654 1.20818 17.2918C0.434597 16.5182 0 15.469 0 14.375V4.625ZM1.5 9.5V14.375C1.5 15.824 2.676 17 4.125 17H7.5V10.5605L5.031 13.031C4.89017 13.1718 4.69916 13.2509 4.5 13.2509C4.30084 13.2509 4.10983 13.1718 3.969 13.031C3.82817 12.8902 3.74905 12.6992 3.74905 12.5C3.74905 12.3008 3.82817 12.1098 3.969 11.969L6.4395 9.5H1.5ZM3.402 8C3.07186 7.42808 2.93964 6.76321 3.02586 6.1085C3.11208 5.45378 3.41191 4.84581 3.87886 4.37886C4.34581 3.91191 4.95378 3.61208 5.6085 3.52586C6.26321 3.43964 6.92808 3.57186 7.5 3.902V2H4.125C3.42881 2 2.76113 2.27656 2.26884 2.76884C1.77656 3.26113 1.5 3.92881 1.5 4.625V8H3.402ZM10.0605 9.5L12.531 11.969C12.6718 12.1098 12.7509 12.3008 12.7509 12.5C12.7509 12.6992 12.6718 12.8902 12.531 13.031C12.3902 13.1718 12.1992 13.2509 12 13.2509C11.8008 13.2509 11.6098 13.1718 11.469 13.031L9 10.5605V17H19.875C20.5712 17 21.2389 16.7234 21.7312 16.2312C22.2234 15.7389 22.5 15.0712 22.5 14.375V9.5H10.0605Z"
                  fill="#808191"
                />
              </svg>
              <span className="ml-7">Gift Card</span>
            </a>
          </Link>
        </li> */}
        <li className={router.pathname.includes('aiassistant') ? 'tab-active' : ''}>
          <Link href="/customerportal/aiassistant" scroll={false}>
            <a className="flex items-center">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill="#808191"/>
                <path d="M12 6C10.9 6 10 6.9 10 8C10 9.1 10.9 10 12 10C13.1 10 14 9.1 14 8C14 6.9 13.1 6 12 6Z" fill="#808191"/>
                <path d="M12 17C14.7 17 17 14.7 17 12C17 9.3 14.7 7 12 7C9.3 7 7 9.3 7 12C7 14.7 9.3 17 12 17ZM12 9C13.7 9 15 10.3 15 12C15 13.7 13.7 15 12 15C10.3 15 9 13.7 9 12C9 10.3 10.3 9 12 9Z" fill="#808191"/>
              </svg>
              <span className="ml-7">AI Assistant</span>
            </a>
          </Link>
        </li>
        {/* <li className={router.pathname.includes('profile') ? 'tab-active' : ''}>
          <Link href="/customerportal/profile" scroll={false}>
            <a className="flex items-center">
              <div className="deActive-img-alignment">
                <img src={ProfileIcon.src} alt="Profile" />
              </div>
              <div className="active-img-alignment">
                <img src={ProfileActiveIcon.src} alt="Profile" />
              </div>
              <span className="ml-7">Profile</span>
            </a>
          </Link>
        </li> */}
      </ul>
    </div>
  )
}

export default NavMenu
