import { gql } from '@apollo/client'

const WITHDRAW_PAYMENT_FROM_SELLER = gql`
  mutation withDrawPaymentFromSeller($amount: Int!, $accountId: String!, $otp: String!) {
    withDrawPaymentFromSeller(amount: $amount, accountId: $accountId, otp: $otp) {
      success
      message
      data
    }
  }
`
export default WITHDRAW_PAYMENT_FROM_SELLER
// $limit: Int, $page: Int
//  limit: $limit, page: $page
