import { gql } from '@apollo/client'

const GET_STORE_FOLLOWERS = gql`
query GetStoreFollowers($slug: String!) {
  getStoreFollowers(slug: $slug) {
    data {
      id
      user {
        id
        profileUrl
        profileCoverImageUrl
        isActiveForFriendStatus
        isFriendForFriendStatus
        firstName
        lastName
        userName
      }
    }
    success
    message
  }
}
`
export default GET_STORE_FOLLOWERS
