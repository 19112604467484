import React from 'react';
import NotificationFooter from '../NotificationFooter';
import UserNotificationsProfile from '../NoticationIcon';
import router from 'next/router';

const ClubInvite: React.FC<any> = ({ notification, setOpen }: any) => {
    const payload = notification?.payload;
    const imagePayload = {
        name: payload?.userName,
        image: payload?.userImg,
    }

    const navigateToClub = () => {
        setOpen(false);
        router.push(`/club/${payload?.clubId}`);
    }
    return (
        <div className="py-4 px-1 sm:px-3 md:px-6">
            <div className="flex items-start space-x-5 p-4 hover:bg-gray-50">
                <UserNotificationsProfile imagePayload={imagePayload} />
                <div className="w-full">
                    <div className=" items-center">
                        <div className='flex items-center'>
                            <h3 className="text-sm font-semibold text-gray-900 my-0 mr-1">{payload?.userName}</h3>
                            <div className="flex items-center space-x-2 text-sm ">
                                <span>{payload?.message}</span>
                            </div>
                        </div>
                        <div className='flex items-center justify-between w-full'>
                            <a onClick={navigateToClub} className='text-[#556EE6] hover:underline hover:text-[#556EE6] font-semibold'>{payload?.clubName}</a>
                            <div className='flex items-center mt-1'>
                                <button onClick={navigateToClub} className='py-2 px-5 text-black bg-gray-200 rounded-xl ml-2 text-[12px] sm:text-[13px]'>Decline</button>
                                <button onClick={navigateToClub} className='py-2 px-5 text-white bg-[#556EE6] rounded-xl ml-2 text-[12px] sm:text-[13px]'>Accept</button>

                            </div>
                        </div>
                    </div>
                    <NotificationFooter notification={notification} />
                </div>
            </div>

        </div>
    );
};

export default ClubInvite;