import React from 'react'
import styles from './SellerOnboardingTellSelf.module.scss'
import classNames from 'classnames'
import Link from 'next/link'
export default function SellerOnboardingTellSelf({ setInputValue, inputValue, handleNext }) {
  const accountTypeArray = ['Artists', 'Individuals', 'Small Businesses', 'Large Companies']

  return (
    <div className={styles.sellerOnboardingTellSelfSection}>
      <div className={styles.sellerOnboardingContent}>
        <div className={styles.sellerOnboardingTellSelfHeading}>
          <h6>Tell us about yourself</h6>
          <p>Your response will help us to offer you a better experience. </p>
        </div>
        <div className={styles.sellerOnboardingTellSelfOptionDetails}>
          <div className={styles.sellerOnboardingTellSelfOptionGrid}>
            {accountTypeArray.map((acType, index) => {
              return (
                <div
                  className={styles.sellerOnboardingTellSelfOptionGridItem}
                  onClick={() => setInputValue((prevState) => ({ ...prevState, accountType: acType }))}
                  key={index}
                >
                  <div
                    className={
                      inputValue?.accountType === acType
                        ? classNames(styles.tellSelfOptionBox, styles.activeType)
                        : styles.tellSelfOptionBox
                    }
                  >
                    <p>{acType}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div
        className={
          inputValue?.accountType !== ''
            ? styles.sellerOnboardingNextAlignment
            : classNames(styles.sellerOnboardingNextAlignment, styles.deactiveButtonClass)
        }
        onClick={() => {
          inputValue?.accountType !== '' ? handleNext('started') : null
        }}
      >
        <a>
          Next <img src="/assets/seller/icons/next-blue-arrow.svg" alt="next arrow" />
        </a>
      </div>
    </div>
  )
}
