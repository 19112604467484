// @ts-nocheck

import React, { useEffect, useRef } from 'react'
import styles from './TumbnailPreviewModal.module.scss'
import { useSelector } from 'react-redux'
import Slider from 'react-slick'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={styles.slickNextThumbline} onClick={onClick}>
      <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 1L1 6L5 11" stroke="#1E2432" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={styles.slickPrevThumbline} onClick={onClick}>
      <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 1L1 6L5 11" stroke="#1E2432" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  )
}

export default function TumbnailPreviewModal() {
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const myElementRef = useRef(null)
  const dispatch = useAppDispatch()
  const fetchProductImageResizeData = useSelector((state: any) => state?.productResizeData?.data)

  let colorMap =
    addProductDetailSlice?.id.variant.allVariant
      ?.find((data) => data.name === 'color' || data?.name === 'Color')
      ?.data?.map((variant) => {
        return { name: variant?.name }
      }) || []

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  useEffect(() => {
    // const checkIfClickedOutside = async (e) => {
    //   if (myElementRef.current && myElementRef.current.contains(e.target)) {
    //     dispatch(
    //       fetchModalData({
    //         allModalData: [],
    //         lastModalData: {},
    //         isUpdateState: false,
    //       }),
    //     )
    //   }
    // }
    // document.addEventListener('mousedown', checkIfClickedOutside)
    // return () => {
    //   document.removeEventListener('mousedown', checkIfClickedOutside)
    // }
  }, [myElementRef])

  const handleClose = () => {
    let datass = getAllState?.allModalData?.filter((item) => item?.modal !== getAllState?.lastModalData?.modal)
    dispatch(
      fetchModalData({
        allModalData: datass,
        lastModalData: getAllState?.lastModalData,
      }),
    )
    dispatch(
      fetchProductImageResizeModal({
        modal: '',
        isOpen: false,
        id: {
          ...fetchProductImageResizeData?.id,
          prevImages: [],
          prevVideos: [],
          selectedSize: { x: 1080, y: 1080 },
          isSelected: false,
          ratio: '1 : 1',
          media: [],
        },
      }),
    )
    dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          variant: {
            isAddVariantModal: false,
            isAddColorModalOpen: false,
            isAddOtherVariantModalOpen: false,
            dataEdit: {},
            allVariant: [],
            colorCodeArray: [],
            allFinalVariant: [],
            variantError: {},
            isVariantModel: false,
            allOptions: [],
          },
          productPreview: {
            title: '',
            image: [],
            price: '',
            listPrice: '',
          },
        },
      }),
    )
  }

  const handlePrevent = (e) => {
    e.stopPropagation()
  }

  return (
    <div className={styles.tumbnailPreviewModalSection} ref={myElementRef} onClick={() => handleClose()}>
      <div className={styles.tumbnailPreviewModalBox} onClick={(e) => handlePrevent(e)}>
        <div className={styles.tumbnailPreviewModalHeadingAlignment}>
          <h4>Tumbnail Preview</h4>
        </div>

        <div className={styles.tumbnailPreviewModalDetails}>
          <div className={styles.tumbnailPreviewModalDetailsBox}>
            <div className={styles.tumbnailPreviewModalPreviewImg}>
              {addProductDetailSlice?.id?.productPreview?.image?.length > 1 ? (
                <Slider {...settings}>
                  {addProductDetailSlice?.id?.productPreview?.image?.length > 0 ? (
                    addProductDetailSlice?.id?.productPreview?.image?.map((item, index) => {
                      return (
                        // <div key={index} style={{ width: '50px', height: '100px', display: 'flex' }}>
                        //   <Slider {...settings}>
                        //     <div style={{ width: '50px', height: '100px', display: 'flex' }}>
                        //       <img src={item?.newfile} alt="img" />
                        //     </div>
                        //   </Slider>

                        // </div>
                        <div key={index}>
                          <img src={item?.oldFile?.baseURL ?? item} alt="img" draggable={false} />
                        </div>
                        // <div>
                        //   <h3>2</h3>
                        // </div>
                        // <div>
                        //   <h3>3</h3>
                        // </div>
                      )
                    })
                  ) : (
                    <img src="/assets/seller/img/tpi.png" alt="img" draggable={false} />
                  )}
                </Slider>
              ) : addProductDetailSlice?.id?.productPreview?.image?.length > 0 ? (
                addProductDetailSlice?.id?.productPreview?.image?.map((item, index) => {
                  return (
                    <div key={index}>
                      <img src={item?.oldFile?.baseURL ?? item} alt="img" draggable={false} />
                    </div>
                  )
                })
              ) : (
                <img src="/assets/seller/img/tpi.png" alt="img" draggable={false} />
              )}

              <div className={styles.colorBoxAlignment}>
                <div className={styles.colorBoxImgAlignment}>
                  <img src="/assets/seller/img/color-img.png" alt="img" draggable={false} />
                </div>

                <span>{colorMap?.length}</span>
              </div>

              {/* <div className={styles.popularProductHoverInfoAlign}>
                  <button>
                    <svg width="28" height="28" viewBox="0 0 36 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <ellipse cx="18" cy="17" rx="18" ry="17" fill="black" fillOpacity="0.4" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.0336 11.0395L15.0677 17.0735L21.1017 23.0393L19.113 25.0506L11.0678 17.0962L19.0222 9.0509L21.0336 11.0395Z"
                        fill="#E4E4E8"
                      />
                    </svg>
                  </button>
                  <button style={{ transform: 'rotate(180deg)' }}>
                    <svg width="28" height="28" viewBox="0 0 36 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <ellipse cx="18" cy="17" rx="18" ry="17" fill="black" fillOpacity="0.4" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.0336 11.0395L15.0677 17.0735L21.1017 23.0393L19.113 25.0506L11.0678 17.0962L19.0222 9.0509L21.0336 11.0395Z"
                        fill="#E4E4E8"
                      />
                    </svg>
                  </button>
                </div> */}
            </div>

            <div className={styles.tumbnailPreviewModalDetailsAlignment}>
              <h6>
                {addProductDetailSlice?.id?.productPreview?.title
                  ? addProductDetailSlice?.id?.productPreview?.title
                  : 'Modern wall clock Wooden cloc...'}
              </h6>

              <div className={styles.tumbnailPreviewPrice}>
                <p>
                  ${' '}
                  {addProductDetailSlice?.id?.productPreview?.price
                    ? addProductDetailSlice?.id?.productPreview?.price
                    : 79.99}
                </p>
                <span>
                  ${' '}
                  {addProductDetailSlice?.id?.productPreview?.listPrice
                    ? addProductDetailSlice?.id?.productPreview?.listPrice
                    : 125.0}
                </span>
              </div>

              {/* <div className={styles.tumbnailPreviewtshareDeliveryShipping}>
                <div className={styles.tumbnailPreviewBestSellerAlignment}>
                  <h6>Best seller</h6>
                </div>
                <div className={styles.tumbnailPreviewActivityDotAlignment}>
                  <p></p>
                </div>
                <div className={styles.tumbnailPreviewDiscountMenuAlign}>
                  <img src="/assets/seller/icons/truckshipped-icon.svg" />
                  <span>free</span>
                </div>
                <div className={styles.tumbnailPreviewActivityDotAlignment}>
                  <p></p>
                </div>
                <div className={styles.tumbnailPreviewarrowmenualign}>
                  <img src="/assets/seller/icons/Stroke-arrow.svg" />
                  <span>200%</span>
                </div>
              </div> */}

              <div className={styles.tumbnailPreviewOtherOptionAlignment}>
                <div className={styles.tumbnailPreviewOtherOptionDetails}>
                  <div className={styles.tumbnailPreviewIcon}>
                    <img src="/assets/seller/icons/comment-icon.svg" alt="comment icon" />
                  </div>
                  <span>235</span>
                </div>
                <div className={styles.tumbnailPreviewOtherOptionDetails}>
                  <div className={styles.tumbnailPreviewIcon}>
                    <img src="/assets/seller/icons/share-icon.svg" alt="share icon" />
                  </div>
                  <span>235</span>
                </div>
                <div className={styles.tumbnailPreviewOtherOptionDetails}>
                  <div className={styles.tumbnailPreviewIcon}>
                    <img src="/assets/seller/icons/heart-icon.svg" alt="heart icon" />
                  </div>
                  <span>235</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
