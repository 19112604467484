import React, { useEffect, useRef, useState } from 'react'
import styles from './AddOtherVariantModal.module.scss'
import classNames from 'classnames'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
export default function AddOtherVariantModal() {
  const categoryRef: any = useRef()
  const optionValueRef: any = useRef()
  const dispatch = useAppDispatch()
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const variantName = ['Material', 'Pattern', 'Style', 'Weight', 'Dimensions']
  const sizeArraySelected = [
    { size: 'Cotton', id: 0 },
    { size: 'Polymer', id: 1 },
    { size: 'Rubber', id: 2 },
    { size: 'Paper', id: 3 },
    { size: 'Wood', id: 4 },
    { size: 'Steel', id: 3 },
    { size: 'Bronze', id: 3 },
    { size: 'Gold', id: 3 },
    { size: 'Glass', id: 3 },
  ]

  const [variantNameDropdown, setVariantNameDropdown] = useState(false)
  const [variant, setVariant] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [optionSearchResult, setOptionSearchResult] = useState([])
  const [inputIndex, setInputIndex] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [sizeArray, setSizeArray] = useState(sizeArraySelected)
  const [error, setError] = useState([])
  const [variantError, setvariantError] = useState({})

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (variantNameDropdown && categoryRef.current && !categoryRef.current.contains(e.target)) {
        setVariantNameDropdown(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [variantNameDropdown])

  const handleSelectCategory = (selected) => {
    let variantError = {}
    let formIsValid = true
    setVariant(selected)
    if (selected.trim() === '') {
      formIsValid = false
      variantError['variant'] = 'Enter valid input*'
    }
    setvariantError(variantError)
  }

  const writeVariantName = (e) => {
    let input = e.target.value
    let formIsValid = true
    setVariant(e.target.value)
    if (input.trim() === '') {
      formIsValid = false
      variantError['variant'] = 'Enter valid input*'
      setvariantError(variantError)
    } else {
      setvariantError({})
    }
  }

  useEffect(() => {
    let datat = variantName?.filter((rep) => rep?.toLowerCase()?.includes(variant?.toLowerCase()))
    setSearchResult(datat)
  }, [variant])

  const handleChange = (e, id) => {
    let errors = {}
    setInputIndex(id)
    setInputValue(e.target.value)
    let changed = addProductDetailSlice?.id?.variant?.colorArray.map((color, index) => {
      if (index === id) {
        return e.target.value
      } else {
        return color
      }
    })
    // setColorArray(changed)
    dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          variant: {
            ...addProductDetailSlice?.id?.variant,
            colorArray: changed,
          },
        },
      }),
    )
    let errorchecking = changed?.map((data, index) => {
      if (data.trim() === '') {
        return (errors[index] = 'Enter valid input*')
      }
    })
    setError(errorchecking)
  }

  const handleSelect = async (id, data) => {
    let ID = id
    if (data !== '') {
      let selectedSetting = addProductDetailSlice?.id?.variant?.colorArray.map((col, index) => {
        if (index === ID) {
          return data
        } else {
          return col
        }
      })
      // setColorArray([...selectedSetting, ""]);
      removeDuplicates([...selectedSetting])
      setInputIndex(null)
      setInputValue('')
    }
  }

  const removeDuplicates = (arr) => {
    let errors = {}
    var unique = arr.reduce(function (acc, curr) {
      let lowerCaseArray = acc.map((data) => {
        return data.toLowerCase()
      })
      if (!lowerCaseArray.includes(curr.toLowerCase())) acc.push(curr)
      return acc
    }, [])
    const noEmptyStrings = unique.filter((str) => str !== '')
    let errorchecking = noEmptyStrings.map((data, index) => {
      if (data.trim() === '') {
        return (errors[index] = 'Enter valid input*')
      }
    })
    setError(errorchecking)
    // setColorArray([...noEmptyStrings, ''])
    dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          variant: {
            ...addProductDetailSlice?.id?.variant,
            colorArray: [...noEmptyStrings, ''],
          },
        },
      }),
    )
  }

  const handleEnter = async (e, index) => {
    if (e.key === 'Enter') {
      if (addProductDetailSlice?.id?.variant?.colorArray.includes(inputValue)) {
        removeDuplicates(addProductDetailSlice?.id?.variant?.colorArray)
        setInputIndex(null)
        setInputValue('')
      }
    }
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (inputIndex && optionValueRef.current && !optionValueRef.current.contains(e.target)) {
        setInputIndex(null)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [inputIndex])

  useEffect(() => {
    if (inputValue === '') {
      setSizeArray(sizeArraySelected)
      setOptionSearchResult(sizeArraySelected)
    } else {
      let datat = sizeArray?.filter((rep) => rep?.size?.toLowerCase()?.includes(inputValue?.toLowerCase()))
      setOptionSearchResult(datat)
    }
  }, [inputValue])

  const handleClickInput = (index) => {
    // if (inputIndex === index) {
    //   setInputIndex(null);
    // } else {
    setInputIndex(index)
    // }
  }

  const validateSize = () => {
    let errors = {}
    let variantError = {}
    let formIsValid = true
    let errorchecking = addProductDetailSlice?.id?.variant?.colorArray.map((data, index) => {
      if (data.trim() === '') {
        formIsValid = false
        return (errors[index] = 'Enter valid input*')
      }
    })
    setError(errorchecking)
    if (variant.trim() === '') {
      formIsValid = false
      variantError['variant'] = 'Enter valid input*'
    }
    setvariantError(variantError)
    return formIsValid
  }

  const outputArray = []

  function generateVariants(currentVariant, currentIndex, finalData) {
    if (currentIndex === finalData?.length) {
      outputArray.push({
        total_variant: currentVariant,
        // weightValue: '200',
        // weightUnit: 'pound',
        // length: 2,
        // width: 2,
        // height: 2,
        // unit: 'inch',
        // inventory_quantity: 100,
        // old_inventory_quantity: 200,
        isTaxable: false,
        isChecked: false,
        weightUnit: 'pound',
        unit: 'inch',
        old_inventory_quantity: 0,
        inventory_quantity: 0,
        price: '0',
        sku: '0',
        // grams: 530,
        // price: '50',
        // sku: 'dadsdadasdasd',
        // barcode: '5151511565656',
      })
      return
    }

    const currentVariants = finalData[currentIndex].data
    for (const variant of currentVariants) {
      const newVariant = [...currentVariant, variant]
      generateVariants(newVariant, currentIndex + 1, finalData)
    }
  }

  const handleSubmitOherVariant = async () => {
    if (validateSize()) {
      if (addProductDetailSlice?.id?.variant?.dataEdit?.other) {
        // submitEditedSize({ name: variant, variant: colorArray, index: dataEdit?.index }, 'other')
        // setAddOtherVariantModalOpen(false)
        // setDataEdit({})
        let colorVariant = addProductDetailSlice?.id?.variant?.allVariant?.filter((size) => size?.name === 'Color')
        let sizeVariant = addProductDetailSlice?.id?.variant?.allVariant?.filter((size) => size?.name === 'Size')

        let data = {
          name: variant,
          variant: addProductDetailSlice?.id?.variant?.colorArray,
          index: addProductDetailSlice?.id?.variant?.dataEdit?.index,
        }

        let allData = addProductDetailSlice?.id?.variant?.allVariant?.map((add, index) => {
          if (index === data?.index) {
            return { name: data?.name, data: data?.variant }
          } else {
            return add
          }
        })

        const finalAllData = allData.map((item) => {
          if (item.name === 'Color' && Array.isArray(item.data)) {
            return {
              name: item.name.toLowerCase(),
              data: item.data.map((colorObj) => colorObj.name),
            }
          }
          return item
        })
        await generateVariants([], 0, finalAllData)

        dispatch(
          manageAddProductDetail({
            modal: '',
            isOpen: false,
            id: {
              ...addProductDetailSlice?.id,
              variant: {
                ...addProductDetailSlice?.id?.variant,
                allVariant: addProductDetailSlice?.id?.variant?.allVariant?.map((add, index) => {
                  if (index === data?.index) {
                    return { name: data?.name, data: data?.variant }
                  } else {
                    return add
                  }
                }),
                colorCodeArray: addProductDetailSlice?.id?.variant?.allVariant?.map((add, index) => {
                  if (index === data?.index) {
                    return { name: data?.name, data: data?.variant }
                  } else {
                    return add
                  }
                }),
                allFinalVariant: addProductDetailSlice?.id?.variant?.allVariant?.map((add, index) => {
                  if (index === data?.index) {
                    return { name: data?.name, data: data?.variant }
                  } else {
                    return add
                  }
                }),
                allOptions: outputArray,
                isAddOtherVariantModalOpen: false,
                dataEdit: {},
              },
            },
          }),
        )
      } else {
        // submitSize({ name: variant, variant: colorArray }, 'other')
        // setAddOtherVariantModalOpen(false)
        // setDataEdit({})
        let data = { name: variant, variant: addProductDetailSlice?.id?.variant?.colorArray }
        let type = 'other'
        dispatch(
          manageAddProductDetail({
            modal: '',
            isOpen: false,
            id: {
              ...addProductDetailSlice?.id,
              variant: {
                ...addProductDetailSlice?.id?.variant,
                allVariant: [
                  ...addProductDetailSlice?.id?.variant?.allVariant,
                  { name: data.name, data: data.variant },
                ],
                colorCodeArray: [
                  ...addProductDetailSlice?.id?.variant?.allVariant,
                  { name: data.name, data: data.variant },
                ],
                isAddOtherVariantModalOpen: false,
                dataEdit: {},
              },
            },
          }),
        )
      }
    }
  }

  const handleDelete = (index) => {
    if (addProductDetailSlice?.id?.variant?.colorArray?.length > 1) {
      // setColorArray(colorArray.filter((data, ind) => ind !== index))
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            variant: {
              ...addProductDetailSlice?.id?.variant,
              colorArray: addProductDetailSlice?.id?.variant?.colorArray?.filter((data, ind) => ind !== index),
            },
          },
        }),
      )
    }
  }

  useEffect(() => {
    if (addProductDetailSlice?.id?.variant?.dataEdit?.other) {
      // setColorArray(dataEdit?.other?.data)
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            variant: {
              ...addProductDetailSlice?.id?.variant,
              colorArray: addProductDetailSlice?.id?.variant?.dataEdit?.other?.data,
            },
          },
        }),
      )
      setVariant(addProductDetailSlice?.id?.variant?.dataEdit?.other?.name)
    }
  }, [addProductDetailSlice?.id?.variant?.dataEdit])

  const handleCancel = () => {
    dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          variant: {
            ...addProductDetailSlice?.id?.variant,
            isAddOtherVariantModalOpen: false,
            dataEdit: {},
          },
        },
      }),
    )
  }

  return (
    <div className={styles.otherVariantModalAlignment}>
      <div className={styles.modalWrapper}>
        <div className={styles.modalBoxAlignment}>
          <div className={styles.modalHeaderAlignment}>
            <h3>Add Other Variant</h3>
            <div className={styles.headerButtonAlignment}>
              <div className={styles.cancleButtonAlignment}>
                <button onClick={() => handleCancel()}>Cancel</button>
              </div>
              <div className={classNames(styles.saveButtonAlignment, styles.active)} onClick={handleSubmitOherVariant}>
                <button>Save and Close</button>
              </div>
            </div>
          </div>
          <div className={styles.modalBodyAlignment}>
            <div className={styles.formAlignment}>
              <label>Variant Name</label>
              <div className={styles.inputDetailsAlignment}>
                <div
                  className={classNames(styles.inputAlignment, styles.variantInput)}
                  onClick={() => setVariantNameDropdown(!variantNameDropdown)}
                >
                  <span style={{ color: 'red', paddingLeft: '4px' }}>{variantError['variant']}</span>
                  <input type="text" placeholder="e.g Material" value={variant} onChange={(e) => writeVariantName(e)} />
                  {variantNameDropdown && searchResult?.length > 0 && (
                    <div className={styles.dropdownAlignment} ref={categoryRef}>
                      <ul>
                        {(variant ? searchResult : variantName)?.map((vars, index) => {
                          return (
                            <li onClick={() => handleSelectCategory(vars)} key={index}>
                              {vars}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.formAlignment}>
              <label className={styles.optionLabel}>Option Value</label>
              <div className={styles.inputDetailsAlignment}>
                {addProductDetailSlice?.id?.variant?.colorArray?.map((data, index) => {
                  return (
                    <React.Fragment key={index}>
                      <span style={{ color: 'red' }}>{error[index]}</span>
                      <div
                        className={styles.inputAlignment}
                        //  onClick={() => setOptionValueDropdown(!OptionValueDropdown)}
                      >
                        <div className={styles.inputRelative}>
                          <input
                            type="text"
                            placeholder="Add New Material"
                            onChange={(e) => handleChange(e, index)}
                            value={data}
                            onKeyPress={(e) => handleEnter(e, index)}
                            onClick={() => handleClickInput(index)}
                            autoFocus
                          />
                          <div ref={optionValueRef}>
                            {inputIndex == index && optionSearchResult?.length > 0 && (
                              <div className={styles.dropdownAlignment}>
                                <ul>
                                  {(inputValue ? optionSearchResult : sizeArray)?.map((val) => {
                                    return <li onClick={() => handleSelect(index, val?.size)}>{val?.size}</li>
                                  })}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className={styles.deleteIconAlignment} onClick={() => handleDelete(index)}>
                          <img src="/assets/seller/icons/transh-blue.svg" alt="Transh" />
                        </div>
                      </div>
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
