import React, { useState } from 'react';
import { useNotifications, useFetchUserPreferences } from '@novu/notification-center';
import NotificationsList from '../Notifications-List';
import NotificationsHeader from '../Notifications-Header';

const NotificationsInbox = ({ setOpen }) => {
    const [activeTab, setActiveTab] = useState('all');
    const [showUnreadOnly, setShowUnreadOnly] = useState(false);
    const { notifications, isFetching, hasNextPage, fetchNextPage, isFetchingNextPage } = useNotifications();

    const filteredNotifications = notifications?.filter(notification => {
        if (activeTab === 'all') return true;
        return notification?.payload?.type === activeTab;
    }).filter(notification => {
        if (!showUnreadOnly) return true;
        return !notification.read;
    });

    return (
        <div className="w-[430px] sm:w-[400px] md:w-[450px] lg:w-[625px] -m-6 bg-white rounded-xl shadow-lg">
            <NotificationsHeader
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                setShowUnreadOnly={setShowUnreadOnly}
                showUnreadOnly={showUnreadOnly}

            />
            <NotificationsList
                notifications={filteredNotifications}
                isFetching={isFetching}
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
                isFetchingNextPage={isFetchingNextPage}
                setOpen={setOpen}
            />
        </div>
    );
};

export default NotificationsInbox;