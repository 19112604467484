import React from 'react'
import styles from './CustomerOrderDetailsModal.module.scss'
import { useQuery } from '@apollo/client'
import GET_ORDER_DETAILS from 'src/graphql/queries/sellerQueries/getOrderDetails'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import moment from 'moment'
import Link from 'next/link'
export default function CustomerOrderDetailsModal() {
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const dispatch = useAppDispatch()

  const { loading, error, data, refetch } = useQuery(GET_ORDER_DETAILS, {
    variables: {
      getOrderDetailsId: getAllState?.lastModalData?.id,
    },
  })

  const orderStatus = (status) => {
    switch (status) {
      case 'pending':
        return 'Pending'
      default:
        return ''
    }
  }

  const handleOpenProduct = (id) => {
    dispatch(
      fetchModalData({
        allModalData: [
          ...getAllState?.allModalData,
          { modal: 'productDetail', isOpen: true, id: id, modalFor: 'bigModal', startDate: moment().toISOString() },
        ],
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  return (
    <div className={styles.customerOrderDetailsModalAlignment}>
      <div className={styles.customerOrderDetailsModalBg}>
        <div className={styles.customerOrderIdDetails}>
          <h6>Order ID</h6>
          <p>{data?.getOrderDetails?.data?.generate_id}</p>
        </div>
        <div className={styles.customerOrderDetailsAlignment}>
          <div className={styles.customerOrderDetailsFlex}>
            <div className={styles.customerOrderProductDetails}>
              <div className={styles.customerOrderProductImg}>
                <img
                  src={data?.getOrderDetails?.data?.product?.cropImages[0]?.croppedFile?.baseURL}
                  alt="order product"
                />
              </div>
              <div className={styles.customerOrderProductChildDetails}>
                <h5>{data?.getOrderDetails?.data?.product?.title}</h5>
                <span>SKU {data?.getOrderDetails?.data?.product?.sku}</span>
                <div className={styles.orderProductOtherDetails}>
                  <div className={styles.orderProductOtherNamesDetails}>
                    <p>Size</p>
                    <span>8.5 EU</span>
                  </div>
                  <div className={styles.orderProductOtherNamesDetails}>
                    <p>Color </p>
                    <span>Red</span>
                  </div>
                  <div className={styles.orderProductOtherNamesDetails}>
                    <p>Qty</p>
                    <span>{data?.getOrderDetails?.data?.quantity}</span>
                  </div>
                </div>
                <Link href={`/product/${data?.getOrderDetails?.data?.product?.slug}`}>
                  <div
                    className={styles.viewProductOption}
                    // onClick={() => handleOpenProduct(data?.getOrderDetails?.data?.product?.id)}
                  >
                    <a>View Product </a>
                  </div>
                </Link>
              </div>
            </div>
            <div className={styles.customerRightSideDetails}>
              <div className={styles.customerDeliverAlignment}>
                <span>{orderStatus(data?.getOrderDetails?.data?.order_status)}</span>
              </div>
              <p>$ {data?.getOrderDetails?.data?.price}</p>
            </div>
          </div>
        </div>
        <div className={styles.customerOrderShippingDetailsAlignment}>
          <div className={styles.customerOrderShippingGrid}>
            <div className={styles.customerOrderShippingGridItem}>
              <div className={styles.customerOrderShippingBox}>
                <div className={styles.shippingHeading}>
                  <h6>Shipping Detail</h6>
                </div>
                <div className={styles.shippingAddressAndCarrierDetails}>
                  <div className={styles.shippingAddressAndCarrierGrid}>
                    <div className={styles.shippingAddressAndCarrierGridItem}>
                      <div className={styles.shippingAddressDetailsAlignment}>
                        <h6>Shipping Address</h6>
                        <p>
                          {data?.getOrderDetails?.data?.shipping_address?.city &&
                            (`${data?.getOrderDetails?.data?.shipping_address?.streetAddress}, ` || '')}
                          {data?.getOrderDetails?.data?.shipping_address?.city &&
                            (`${data?.getOrderDetails?.data?.shipping_address?.city}, ` || '')}
                          {data?.getOrderDetails?.data?.shipping_address?.state &&
                            (`${data?.getOrderDetails?.data?.shipping_address?.state}, ` || '')}
                          {data?.getOrderDetails?.data?.shipping_address?.country &&
                            (`${data?.getOrderDetails?.data?.shipping_address?.country}, ` || '')}
                          {data?.getOrderDetails?.data?.shipping_address?.zipcode &&
                            (`${data?.getOrderDetails?.data?.shipping_address?.zipcode} ` || '')}
                        </p>
                        <span>
                          {data?.getOrderDetails?.data?.customer?.phone && '+1'}
                          {data?.getOrderDetails?.data?.customer?.phone}
                        </span>
                      </div>
                    </div>
                    <div className={styles.shippingAddressAndCarrierGridItem}>
                      <div className={styles.carrierAlignment}>
                        <h6>Carrier</h6>
                        <div className={styles.carrierLogo}>
                          <img src="/assets/seller/img/DHLIcon.png" alt="dhl logo" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.shippingTimeLineAlignment}>
                    <div className={styles.shippingTimeLineBoxAlignment}>
                      <div className={styles.shippingTimeLineHeadingAlignment}>
                        <h6>Shipping Timeline</h6>

                        <div className={styles.trakingIDAlignment}>
                          <p>Tracking ID</p>
                          <p>8857364764653434</p>
                        </div>
                      </div>
                      <div className={styles.shippingTimeTrackDetailsAlignment}>
                        <div className={styles.shippingTimeTrackDetailsAlignment}>
                          <div className={styles.shippingTimeTrackGrid}>
                            <div className={styles.shippingTimeTrackGridItem}>
                              <div className={styles.shippingTimeTrackBox}></div>
                              <div className={styles.trackLineAlignment}></div>
                            </div>
                            <div className={styles.shippingTimeTrackGridItem}>
                              <div className={styles.shippingTimeDetailsAlignment}>
                                <div className={styles.shippingTimeAndDateAlignment}>
                                  <p> 5 : 24 PM</p>
                                  <p>Monday, May 12, 2023</p>
                                </div>
                                <h6>Delivered - Delivered to Recipient at FedEx Facility</h6>
                              </div>
                            </div>
                          </div>
                          {[0, 1, 2, 3, 4, 5].map((i, index) => {
                            return (
                              <div className={styles.shippingTimeTrackGrid} key={index}>
                                <div className={styles.shippingTimeTrackGridItem}>
                                  <div className={styles.shippingTimeTrackBox}></div>
                                  <div className={styles.trackLineAlignment}></div>
                                </div>
                                <div className={styles.shippingTimeTrackGridItem}>
                                  <div className={styles.shippingTimeDetailsAlignment}>
                                    <div className={styles.shippingTimeAndDateAlignment}>
                                      <p> 5 : 24 PM</p>
                                      <p>Monday, May 12, 2023</p>
                                    </div>
                                    <h6>Delivered - Delivered to Recipient at FedEx Facility</h6>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                          <div className={styles.shippingTimeTrackGrid}>
                            <div className={styles.shippingTimeTrackGridItem}>
                              <div className={styles.shippingTimeTrackBox}></div>
                            </div>
                            <div className={styles.shippingTimeTrackGridItem}>
                              <div className={styles.shippingTimeDetailsAlignment}>
                                <div className={styles.shippingTimeAndDateAlignment}>
                                  <p> 5 : 24 PM</p>
                                  <p>Monday, May 12, 2023</p>
                                </div>
                                <h6>Delivered - Delivered to Recipient at FedEx Facility</h6>
                              </div>
                            </div>
                          </div>
                          <div className={styles.shippingTrackBottom}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.customerOrderShippingGridItem}>
              <div className={styles.rightPartAlignment}>
                <div className={styles.customerBoxAlignment}>
                  <h5>Customer</h5>
                  <div className={styles.customerProfileDetailsAlignment}>
                    <div className={styles.customerProfileImg}>
                      <img src={data?.getOrderDetails?.data?.customer?.profileAvtar} alt="traking img" />
                    </div>
                    <h6>
                      {data?.getOrderDetails?.data?.customer?.firstName +
                        ' ' +
                        data?.getOrderDetails?.data?.customer?.lastName}
                    </h6>
                  </div>
                </div>
                <div className={styles.paymentBoxAlignment}>
                  <h5>Payment</h5>
                  <div className={styles.paymentProfileDetailsAlignment}>
                    <div>
                      <img src="/assets/seller/img/Mastercard-logo.png" alt="paymment logo" />
                    </div>
                    <div>
                      <p>
                        Mastercard ({data?.getOrderDetails?.data?.card_details?.card_number && 'xxxx'}-
                        {data?.getOrderDetails?.data?.card_details?.card_number &&
                          (data?.getOrderDetails?.data?.card_details?.card_number).slice(-4)}
                        )
                      </p>
                      <span>expires {data?.getOrderDetails?.data?.card_details?.expiry_date}</span>
                    </div>
                  </div>
                </div>
                <div className={styles.orderSummaryAlignment}>
                  <h5>Order Summary</h5>
                  <div className={styles.orderSummaryInvoiceDetailsAlignment}>
                    <div className={styles.orderSummaryAllChildDetailsAlignment}>
                      <p>Items Price</p>
                      <span>$ {data?.getOrderDetails?.data?.price}</span>
                    </div>
                    <div className={styles.orderSummaryAllChildDetailsAlignment}>
                      <p>Discount</p>
                      <span>$ 0</span>
                    </div>
                    <div className={styles.orderSummaryAllChildDetailsAlignment}>
                      <p>Shipping</p>
                      <span>$ 0</span>
                    </div>
                    <div className={styles.orderSummaryAllChildDetailsAlignment}>
                      <p>handling</p>
                      <span>$ 0</span>
                    </div>
                    <div className={styles.orderSummaryAllChildDetailsAlignment}>
                      <p>Taxes</p>
                      <span>$ 0</span>
                    </div>
                    <div className={styles.orederSummaryTotalDetailsAlignment}>
                      <h6>Total:</h6>
                      <p>$ {data?.getOrderDetails?.data?.total}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
