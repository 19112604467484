import React from 'react'
import styles from './SellerOnboardingBanner.module.scss'
export default function SellerOnboardingBanner() {
  return (
    <div className={styles.sellerOnboardingBannerAlignment}>
      <div className={styles.sellerOnboardingBannerImg}>
        {/* <img src="/assets/seller/img/onboadingBannerImg.png" alt="banner img" /> */}
        <video
          autoPlay
          muted
          loop
          src="https://bluejestic-media.s3.amazonaws.com/seller/oboarding/banner/_import_62dcdb18210e54-1685160947257-475780489.mov"
        ></video>
        <div className={styles.sellerOnboardingBannerDetails}>
          <h6>Join and connect with thousands of marketers and influencers</h6>
          <p>
            Welcome to Bluejestic, where sellers thrive in the world of social commerce. Join us and unleash your
            business's true potential!
          </p>
        </div>
      </div>
    </div>
  )
}
