import React, { useState } from 'react'
import LearnFromArtistsdetailsModal from './LearnFromArtistsdetailsModal'
import MobileViewComment from 'src/components/Feed/NormalPost/MobileViewComment'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { fetchPostAddEditModal } from 'src/store/slices/postAddEditSlice'

export default function LeranFromArtistsArtCarft() {
  const dispatch = useAppDispatch()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isClose, isSetClose] = useState(true)
  const [showMore, setShowMore] = useState(false)
  const [commentId, setCommentId] = useState({ id: null, comment_for: '' })
  const [isMobileComment, setIsMobileComment] = useState(false)
  const postAddEditModal = useSelector((state: any) => state?.postAddEditModal?.data)

  let type = 'post'
  const isStorePost = type === 'store' ? true : false
  const isNormalPost = type === 'post' ? true : false
  const isGroupPost = type === 'group' ? true : false

  const showModal = () => {
    setIsModalVisible(true)
  }

  const showDeletePostModal = () => {
    setIsModalOpen(true)
  }

  const editPost = () => {
    let data = {
      ...postAddEditModal?.id,
      type: type,
      editPost: { isEdit: true, content: 'NO DATA FOUND', id: 113 },
    }
    dispatch(fetchPostAddEditModal({ modal: 'postAddEditModal', isOpen: true, id: data }))
  }

  const editPostMobile = () => {}

  return (
    <div>
      {/* <div className="art-craft-learn-artists-store-details-alignment">
        <div className="mobile-view-art-craft-back-alignment">
          <div className="art-craft-back_details-alignment">
            <div>
              <img src="/assets/icon/left-icon.svg" alt="back-page" />
            </div>
            <p>Home...</p>
          </div>
          <div className="mobile-heading-alignment">
            <h4>DIY Room</h4>
          </div>
        </div>
        <div className="art-craft-learn-artists-store-banner-alignment">
          <img src="/assets/img/artCarft/learnArtists.png" alt="Learn artist" />
          <img
            src="/assets/img/artCarft/mobileviewlearnArtistsBanner.png"
            alt="MobileNowcreative-clubBanner"
            className="mobile-view-banner"
          />
          <div className="art-craft-learn-artists-store-banner-positiona-alignment">
            <div className="art-craft-learn-artists-store-banner-details">
              <h4>Learn form Our Artist</h4>
              <p>Take your art to the next level with our Creative Clubs.</p>
            </div>
          </div>
        </div>

        <div className="art-craft-learn-artists-tab-alignment">
          <ul>
            <li>All</li>
            <li>paper Craft</li>
            <li>Ceramics</li>
            <li>Wall Art</li>
            <li>Miniature</li>
            <li>Lamps</li>
            <li>Collage</li>
            <li>Wooden art</li>
            <li>Metal art</li>
          </ul>
        </div>

        <div className="art-craft-learn-artists-modal-details-alignment">
          <div className="art-craft-learn-artists-modal-grid-alignment">
            <div className="art-craft-learn-artists-modal-gridIem-alignment">
              <div className="art-craft-learn-artists-modal-all-details-alignment">
                <div className="art-craft-learn-artists-modal-img-alignment">
                  <img src="/assets/img/artCarft/artLearnArtists/la1.png" alt="learn img" />

                  <div className="time-label-alignment">
                    <div className="time-label-box-alignment">
                      <span>3m 33s</span>
                    </div>
                  </div>
                </div>
                <div className="art-craft-learns-artists-all-child-details-alignment">
                  <span>Andy Sebastian</span>
                  <p>Five minute Craft : DIY Tutorial for Hand made</p>
                </div>
              </div>
            </div>
            <div className="art-craft-learn-artists-modal-gridIem-alignment">
              <div className="art-craft-learn-artists-modal-all-details-alignment">
                <div className="art-craft-learn-artists-modal-img-alignment">
                  <img src="/assets/img/artCarft/artLearnArtists/la2.png" alt="learn img" />

                  <div className="time-label-alignment">
                    <div className="time-label-box-alignment">
                      <span>3m 33s</span>
                    </div>
                  </div>
                </div>
                <div className="art-craft-learns-artists-all-child-details-alignment">
                  <span>Andy Sebastian</span>
                  <p>Five minute Craft : DIY Tutorial for Hand made</p>
                </div>
              </div>
            </div>
            <div className="art-craft-learn-artists-modal-gridIem-alignment">
              <div className="art-craft-learn-artists-modal-all-details-alignment">
                <div className="art-craft-learn-artists-modal-img-alignment">
                  <img src="/assets/img/artCarft/artLearnArtists/la3.png" alt="learn img" />

                  <div className="time-label-alignment">
                    <div className="time-label-box-alignment">
                      <span>3m 33s</span>
                    </div>
                  </div>
                </div>
                <div className="art-craft-learns-artists-all-child-details-alignment">
                  <span>Andy Sebastian</span>
                  <p>Five minute Craft : DIY Tutorial for Hand made</p>
                </div>
              </div>
            </div>
            <div className="art-craft-learn-artists-modal-gridIem-alignment">
              <div className="art-craft-learn-artists-modal-all-details-alignment">
                <div className="art-craft-learn-artists-modal-img-alignment">
                  <img src="/assets/img/artCarft/artLearnArtists/la4.png" alt="learn img" />

                  <div className="time-label-alignment">
                    <div className="time-label-box-alignment">
                      <span>3m 33s</span>
                    </div>
                  </div>
                </div>
                <div className="art-craft-learns-artists-all-child-details-alignment">
                  <span>Andy Sebastian</span>
                  <p>Five minute Craft : DIY Tutorial for Hand made</p>
                </div>
              </div>
            </div>

            <div className="art-craft-learn-artists-modal-gridIem-alignment">
              <div className="art-craft-learn-artists-modal-all-details-alignment">
                <div className="art-craft-learn-artists-modal-img-alignment">
                  <img src="/assets/img/artCarft/artLearnArtists/la5.png" alt="learn img" />

                  <div className="time-label-alignment">
                    <div className="time-label-box-alignment">
                      <span>3m 33s</span>
                    </div>
                  </div>
                </div>
                <div className="art-craft-learns-artists-all-child-details-alignment">
                  <span>Andy Sebastian</span>
                  <p>Five minute Craft : DIY Tutorial for Hand made</p>
                </div>
              </div>
            </div>
            <div className="art-craft-learn-artists-modal-gridIem-alignment">
              <div className="art-craft-learn-artists-modal-all-details-alignment">
                <div className="art-craft-learn-artists-modal-img-alignment">
                  <img src="/assets/img/artCarft/artLearnArtists/la6.png" alt="learn img" />

                  <div className="time-label-alignment">
                    <div className="time-label-box-alignment">
                      <span>3m 33s</span>
                    </div>
                  </div>
                </div>
                <div className="art-craft-learns-artists-all-child-details-alignment">
                  <span>Andy Sebastian</span>
                  <p>Five minute Craft : DIY Tutorial for Hand made</p>
                </div>
              </div>
            </div>
            <div className="art-craft-learn-artists-modal-gridIem-alignment">
              <div className="art-craft-learn-artists-modal-all-details-alignment">
                <div className="art-craft-learn-artists-modal-img-alignment">
                  <img src="/assets/img/artCarft/artLearnArtists/la7.png" alt="learn img" />

                  <div className="time-label-alignment">
                    <div className="time-label-box-alignment">
                      <span>3m 33s</span>
                    </div>
                  </div>
                </div>
                <div className="art-craft-learns-artists-all-child-details-alignment">
                  <span>Andy Sebastian</span>
                  <p>Five minute Craft : DIY Tutorial for Hand made</p>
                </div>
              </div>
            </div>
            <div className="art-craft-learn-artists-modal-gridIem-alignment">
              <div className="art-craft-learn-artists-modal-all-details-alignment">
                <div className="art-craft-learn-artists-modal-img-alignment">
                  <img src="/assets/img/artCarft/artLearnArtists/la8.png" alt="learn img" />

                  <div className="time-label-alignment">
                    <div className="time-label-box-alignment">
                      <span>3m 33s</span>
                    </div>
                  </div>
                </div>
                <div className="art-craft-learns-artists-all-child-details-alignment">
                  <span>Andy Sebastian</span>
                  <p>Five minute Craft : DIY Tutorial for Hand made</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <LearnFromArtistsdetailsModal />
      {/* {isMobileComment && ( */}

      <MobileViewComment
        // comments={post?.comments}
        isClose={false}
        setShowMore={setShowMore}
        // isSetClose={isSetClose}
        isSmallBox={true}
        showMore={showMore}
        post={{ id: 113 }}
        type={type}
        store={{}}
        commentId={{ id: 113, comment_for: 'POST' }}
        setIsMobileComment={setIsMobileComment}
        isNormalPost={isNormalPost}
        isGroupPost={isGroupPost}
        isStorePost={isStorePost}
        editPost={editPost}
        editPostMobile={editPostMobile}
        showModal={showModal}
        showDeletePostModal={showDeletePostModal}
      />
      {/* )} */}
    </div>
  )
}
