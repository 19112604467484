import React, { useEffect, useRef, useState } from 'react'
import styles from './TermsPoliciesHeader.module.scss'
import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
const MarketplaceLogo = '/assets/img/bluejestic-new-logo.svg'
const MobileMenu = '/assets/img/mobile-menu.svg'

export default function TermsPoliciesHeader() {
  const [header, setHeader] = useState(false)
  const [menu, setMenu] = useState(false)
  const dropdownRef: any = useRef()
  const router = useRouter()
  const path = router.asPath
  const seller = path.split('/')[1]
  const aspath = router?.asPath?.split('#')[1]

  const [name, setName] = useState(null)

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (header && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setHeader(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [header])

  useEffect(() => {
    if (header) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [header])


  const privacyPolicyRoute = [{
    route: 'terms-of-use',
    name: 'Terms of Use'
  }, {
    route: 'privacy-policy',
    name: 'Privacy Policy',
    subRoute: [{
      hash: '',
      name: 'Information Bluejestic Collects'
    },
    {
      hash: '',
      name: 'Sharing Information'
    },
    {
      hash: 'data-security-policy',
      name: 'Data Security Policy'
    },
    {
      hash: 'cookie-policy',
      name: 'Cookie Policy'
    }
    ]
  }, {
    route: 'legal',
    name: 'Legal'
  }]

  const aboutRoute = [{
    route: 'about',
    name: 'Who we are'
  }, {
    route: 'contactus',
    name: 'Contact us'

  }, {
    route: 'investors',
    name: 'Investors'

  }]

  const handleOpen = (routename) => {
    if (name !== "") {
      setName('')

    } else {

      setName(routename)
    }
  }


  return (
    <>
      <div className={styles.termsPoliciesHeader}>
        <div className="cus-container-md">
          <div className={styles.headerAlignment}>
            <div className={styles.logo}>
              <img src={MarketplaceLogo} alt="MarketplaceLogo" />
              <span>Terms & Policies</span>
            </div>
            <div className={styles.menu}>

              {(seller === 'contactus' || seller === 'about' ? aboutRoute : privacyPolicyRoute).map((o) => {
                return (

                  <Link href={`/${o?.route}`}>
                    <span className={seller === `${o?.route}` ? styles.activeMenu : ''}>{o?.name}</span>
                  </Link>
                )
              })}
            </div>
            <div className={styles.buttonAlignment}>
              <Link href="/marketplace/signin">
                <a>Login</a>
              </Link>
              <button>Join Bluejestic</button>
            </div>
            <div className={styles.mobilemenu} onClick={() => setHeader(!header)}>
              <img src={MobileMenu} alt="MobileMenu" />
            </div>
          </div>
        </div>
      </div>
      {header && <div className={styles.headerBlur}></div>}
      <div className={classNames(styles.mobileheader, header ? styles.show : styles.hide)} ref={dropdownRef}>
        {(seller === 'contactus' ? aboutRoute : privacyPolicyRoute).map((o: any, index) => {
          return (
            <div className={styles.menudesign} >
              <span onClick={() => handleOpen(o.name)}>{o.name}</span>
              <div className={classNames(styles.childMenu, o.name === name ? styles.show : styles.hide)}>
                {
                  o?.subRoute?.map((sub) => {
                    return (
                      <Link href={`/privacy-policy#${sub?.hash === '' ? aspath : sub?.hash}`}>
                        <span className={aspath === sub?.hash ? styles.activeClass : ''}>{sub?.name}</span>
                      </Link>

                    )
                  })
                }
              </div>
            </div>
          )
        })
        }
        {/* <div className={styles.menudesign}>
          <span onClick={() => setMenu(!menu)}>Privacy Policy</span>
          <div className={classNames(styles.childMenu, menu ? styles.show : styles.hide)}>
            <span className={styles.activeClass}>Information Bluejestic Collects</span>
            <span>Sharing Information</span>
            <span>Data Security Policy</span>
            <span>Cookie Policy</span>
          </div>
        </div>
        <div className={styles.menudesign}>
          <span>Legal</span>
        </div> */}
      </div>
    </>
  )
}
