import { gql } from '@apollo/client'

const GET_ALL_LIVESTREAM_PRODUCTS = gql`
mutation GetallLivestreamProduct($uuid: String!) {
  getallLivestreamProduct(uuid: $uuid) {
    message
    success
    data {
      id
      title
      dis_listPrice
      dis_price
      images {
        media_id
        src
      }
    }
  }
}
`
export default GET_ALL_LIVESTREAM_PRODUCTS
