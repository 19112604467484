import React, { useEffect, useState } from 'react'
import ShippingCalculateModal from './ShippingCalculateModal'
import ShippingPriceModal from './ShippingPriceModal'
import client1 from 'src/utils/apolloClient'

interface ShippingCusProfileModalProps {
  isOpen: boolean
  onClose: () => void
  getuspsservices?: () => void
}

export default function ShippingCusProfileModal({ getuspsservices, isOpen, onClose }: ShippingCusProfileModalProps) {
  const [isCalculateModalOpen, setIsCalculateModalOpen] = React.useState(false)
  const [isPriceModalOpen, setIsPriceModalOpen] = React.useState(false)

  if (!isOpen) return null

  return (
    <>
      <div
        className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
        style={{ background: '#00000080',zIndex: 999 }}
        >
        <div className="relative transform overflow-hidden rounded-lg bg-white dark:bg-dark-800 text-left shadow-xl transition-all w-full max-w-512 sm:my-8">
          <div className="p-6">
            <div className="text-center">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                How would you like to set up shipping?
              </h3>
              <p>Choose how you want to calculate shipping rates</p>
            </div>
            <div className="mt-6 space-y-4">
              <button
                onClick={() => setIsCalculateModalOpen(true)}
                className="w-full p-4 text-left border rounded-lg hover:border-indigo-500 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 dark:border-dark-600 dark:hover:border-indigo-400"
              >
                <div className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="lucide lucide-calculator flex-shrink-0 h-10 w-10 text-indigo-600 dark:text-indigo-400"
                  >
                    <rect width="16" height="20" x="4" y="2" rx="2"></rect>
                    <line x1="8" x2="16" y1="6" y2="6"></line>
                    <line x1="16" x2="16" y1="14" y2="18"></line>
                    <path d="M16 10h.01"></path>
                    <path d="M12 10h.01"></path>
                    <path d="M8 10h.01"></path>
                    <path d="M12 14h.01"></path>
                    <path d="M8 14h.01"></path>
                    <path d="M12 18h.01"></path>
                    <path d="M8 18h.01"></path>
                  </svg>
                  <div className="ml-3">
                    <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                      I'll have shipping calculated for me
                    </h4>
                    <p className="text-12 text-gray-500 dark:text-gray-400">
                      We'll calculate real-time rates based on your package details
                    </p>
                  </div>
                </div>
              </button>
              <button
                className="w-full p-4 text-left border rounded-lg hover:border-indigo-500 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 dark:border-dark-600 dark:hover:border-indigo-400 cursor-not-allowed "
                // onClick={()=>setIsPriceModalOpen(true)}
              >
                <div className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="lucide lucide-dollar-sign flex-shrink-0 h-10 w-10 text-indigo-600 dark:text-indigo-400"
                  >
                    <line x1="12" x2="12" y1="2" y2="22"></line>
                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                  </svg>
                  <div className="ml-3">
                    <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                      I'll enter fixed prices myself
                    </h4>
                    <p className="text-12 text-gray-500 dark:text-gray-400">
                      Set your own flat rates or price-based rules
                    </p>
                  </div>
                </div>
              </button>
            </div>
          </div>
          <div className="border-t border-gray-200 dark:border-dark-700 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button
              type="button"
              className="px-3 mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 dark:border-dark-600 bg-white dark:bg-dark-800 text-base font-medium text-gray-700 dark:text-gray-200 shadow-sm hover:bg-gray-50 dark:hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      {/* For ME MODAL */}
      <ShippingCalculateModal
        getuspsservices={getuspsservices}
        isOpen={isCalculateModalOpen}
        onClose={() => {
          setIsCalculateModalOpen(false)
          onClose()
        }}
      />
      {/* Fixed Price modal */}
      <ShippingPriceModal isOpen={isPriceModalOpen} onClose={() => setIsPriceModalOpen(false)} />
    </>
  )
}
