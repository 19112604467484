import React from 'react'
import MeetOurArtistsBanner from '../../../../../public/assets/img/artCarft/meet-artists-bg.png'
import e1 from '../../../../../public/assets/img/WomansCategory/e4.png'
import e2 from '../../../../../public/assets/img/artCarft/artists/e2.png'
import e3 from '../../../../../public/assets/img/artCarft/artists/e3.png'
import e4 from '../../../../../public/assets/img/artCarft/artists/e4.png'

export default function MeetOurArtistsArtCarft() {
  const ARTIST_IMAGE = [e1.src, e2.src, e3.src, e4.src, e1.src, e2.src, e3.src, e4.src, e1.src, e2.src]

  return (
    <div className="art-craft-meet-our-artists-store-details-alignment">
      <div className="mobile-view-art-craft-back-alignment">
        <div className="art-craft-back_details-alignment">
          <div>
            <img src="/assets/icon/left-icon.svg" alt="back-page" />
          </div>
          <p>Home...</p>
        </div>
        <div className="mobile-heading-alignment">
          <h4>Meet Our Artists</h4>
        </div>
      </div>
      <div className="art-craft-meet-our-artists-store-banner-alignment">
        <img src={MeetOurArtistsBanner.src} alt="MeetOurArtistsBanner" />
        <img
          src="/assets/img/artCarft/mobileViewMeetOurBanner.png"
          alt="MobileNowTrendingBanner"
          className="mobile-view-banner"
        />

        <div className="art-craft-meet-our-artists-store-banner-details">
          <h4>Meet Our Artists</h4>
          <p>
            Don't miss out on our artist's incredible talent and captivating art. Follow their account to stay inspired.
          </p>
        </div>
      </div>

      <div className="art-craft-meet-our-artists-store-all-details-alignment">
        <div className="art-craft-meet-our-artists-store-all-details-grid">
          {ARTIST_IMAGE.map((image, index) => {
            return (
              <div className="art-craft-meet-our-artists-store-all-details-gridItem" key={index}>
                <div className="art-craft-meet-our-artists-details-alignment ">
                  <div className="person-img-alignment">
                    <img src={image} alt="41" />
                  </div>
                  <div className="art-craft-meet-our-artists-child-details-alignment">
                    <h5>Vincent Zhang</h5>
                    <span>Model</span>
                    <span>2.1k Followers</span>
                    <div className="art-craft-meet-our-artists-button-alignment">
                      <button>Follow</button>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
