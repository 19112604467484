import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface productResizeSlice {
  loading: true | false
  error: {} | null
  called: true | false
  data: any
}

const initialState = {
  loading: false,
  error: null,
  data: {
    modal: '',
    isOpen: false,
    isShareBookmark: false,
    shareData: {},
    singleBookmarkProduct: {
      id: null,
      isProduct: false,
    },
    id: {
      shareData: null,
      selectedCategories: [],
      ratio: '1 : 1',
      selectedSize: { width: 1080, height: 1080 },
      isSelected: false,
      media: [],
      isReorder: false,
      prevImages: [],
      prevVideo: [],
      selectedIndexofMediaForEdit: '',
      variant: {
        isAddVariantModal: false,
      },
    },
  },
  called: false,
} as productResizeSlice

export const fetchProductImageResizeModal = createAsyncThunk(
  'fetchModal/fetchProductImageResizeModal',
  async (data: any) => {
    let FilteredData = { ...data, id: { ...data?.id, media: data?.id?.media.flat() } }

    return FilteredData
  },
)

const productResizeSlice = createSlice({
  name: 'fetchModal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProductImageResizeModal.pending, (state, action) => {
      state.loading = true
      state.error = null
      state.called = true
    })
    builder.addCase(fetchProductImageResizeModal.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
      state.called = true
    })
    builder.addCase(fetchProductImageResizeModal.rejected, (state, action) => {
      state.loading = false
      state.called = false
      state.error = action.error
      console.error('Error fetching cards!')
    })
  },
})

// export var _actions = productResizeSlice.actions
export default productResizeSlice.reducer
