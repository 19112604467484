import React from 'react'

export default function MobileViewAboutBookDetail() {
  return (
    <div className="mobile-view-about-details-alignment">
      <h4>About</h4>
      <div className="mobile-view-about-details-section-alignment">
        <p>
          Chances are, if you’re a target of Billy Summers, two immutable truths apply: You’ll never even know what hit
          you, and you’re really getting what you deserve. He’s a killer for hire and the best in the business—but he’ll
          do the job only if the assignment is a ...
        </p>
      </div>
      <div className="read-more-alignment">
        <a>Read More</a>
      </div>
    </div>
  )
}
