import { gql } from '@apollo/client'

const GET_PRODUCT_STOCK_REPORT = gql`
  query getProductStockReport($storeId: Int, $stock_status: String) {
    getProductStockReport(store_id: $storeId, stock_status: $stock_status) {
      success
      message
      data {
        id
        title
        price
        sku
        quantity
        stock_status
        current_stock_status
        cropImages {
          croppedFile {
            baseURL
          }
        }
      }
    }
  }
`
export default GET_PRODUCT_STOCK_REPORT
