import React, { useEffect, useState } from 'react'
import GalleryForMobileDetails from './GalleryForMobileDetails'
import AllGalleryForMobileModal from './AllGalleryForMobileModal'

export default function GalleryForMobile() {
  const [splittedArray, setSplittedArray] = useState([])
  const [openImagePrev, setOpenImagePrev] = useState({ image: '', modal: false })
  const [seeAllMediaModal, setSeeAllMediaModal] = useState(false)

  const imageArray = [
    { id: 1, image: '/assets/img/product-detail/c-image-1.jpg' },
    { id: 2, image: '/assets/img/product-detail/c-image-2.jpg' },
    { id: 3, image: '/assets/img/product-detail/c-image-3.jpg' },
    { id: 4, image: '/assets/img/product-detail/c-image-4.jpg' },
    { id: 5, image: '/assets/img/product-detail/c-image-5.jpg' },
    { id: 6, image: '/assets/img/product-detail/c-image-6.jpg' },
    { id: 7, image: '/assets/img/product-detail/c-image-7.jpg' },
    { id: 8, image: '/assets/img/product-detail/c-image-8.jpg' },
    { id: 9, image: '/assets/img/product-detail/c-image-9.jpg' },
    { id: 10, image: '/assets/img/product-detail/c-image-10.jpg' },
    { id: 11, image: '/assets/img/product-detail/c-image-11.jpg' },
    { id: 12, image: '/assets/img/product-detail/c-image-12.jpg' },
    { id: 13, image: '/assets/img/product-detail/c-image-13.png' },
    { id: 14, image: '/assets/img/product-detail/c-image-14.jpg' },
    { id: 15, image: '/assets/img/product-detail/c-image-15.jpg' },
    { id: 16, image: '/assets/img/product-detail/c-image-16.jpg' },
  ]

  const spliceIntoChunks = (tempDiscardtweet: any, chunkSize: any) => {
    const resChunk: any = []
    if (tempDiscardtweet.length > 0) {
      let discardtweet = [...tempDiscardtweet]
      while (discardtweet.length > 0) {
        const chunk = discardtweet && discardtweet?.splice(0, chunkSize)
        resChunk.push(chunk)
        setSplittedArray(resChunk)
      }
    } else {
      setSplittedArray([])
    }
    return resChunk
  }

  useEffect(() => {
    spliceIntoChunks(imageArray, Math.ceil(imageArray.length / 2))
  }, [])

  useEffect(() => {
    onScroll()
  }, [])

  const onScroll = () => {}
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })

  return (
    <>
      <div className="gallery-main-container">
        {openImagePrev.modal ? (
          <div className="back-button-align">
            <div className="gellery-image-preview">
              <div className="gallery-header-mobile">
                <div className="gallery-title">
                  <h3>Gallery</h3>
                  <span>(54)</span>
                </div>
              </div>
              <div>
                <img src={openImagePrev.image} />
              </div>
            </div>
            <div className="image-section-flex">
              <div className="image-alignment">
                <img src={'/assets/img/product-detail/c-image-5.jpg'} />
              </div>
              <span>Brenda Jones</span>
              <p>2m</p>
            </div>
            <div className="comment-description-align">
              <p>Can you provide additional information for these speakers?</p>
            </div>
            <div className="goto-comment-section">
              <span>Go to Comment</span>
            </div>
            <div className="image-card-flex-prev">
              {splittedArray?.map((productColumn, index) => {
                return (
                  <div key={index}>
                    {productColumn?.map((item: any, idx) => {
                      return (
                        <div
                          key={`q${idx}`}
                          className="image-align-mobile"
                          onClick={() => {
                            setOpenImagePrev({ image: item.image, modal: true })
                            onScroll()
                          }}
                        >
                          <img src={item.image} />
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        ) : (
          <div>
            <div className="gallery-main-image-section">
              <div className="gallery-header-mobile">
                <div className="gallery-title">
                  <h3>Gallery</h3>
                  <span>(54)</span>
                </div>
              </div>
              <GalleryForMobileDetails setOpenImagePrev={setOpenImagePrev} splittedArray={splittedArray} />
            </div>

            <div className="mobile-view-all-media-seaction" onClick={() => setSeeAllMediaModal(!seeAllMediaModal)}>
              <a>See all media</a>
            </div>
          </div>
        )}
      </div>

      <AllGalleryForMobileModal
        setOpenImagePrev={setOpenImagePrev}
        splittedArray={splittedArray}
        setSeeAllMediaModal={setSeeAllMediaModal}
        seeAllMediaModal={seeAllMediaModal}
      />
    </>
  )
}
