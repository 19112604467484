import React from 'react';
import NotificationFooter from '../NotificationFooter';
import ShippingIcon from 'public/assets/svg/ShippingIcon';

interface ShippingNotificationProps {
    notification: any;
}

const ShippingProduct: React.FC<ShippingNotificationProps> = ({ notification }) => {
    const payload = notification?.payload;

    return (
        <div className="py-4 px-1 sm:px-3 md:px-6 hover:!bg-[#F8F8F8] border-b border-gray-200 bg-[#EEF1FD]">
            <div className="p-4 ">
                <div className="flex items-start gap-5">
                    <div className="w-[40px] h-[24px] sm:w-[56px] sm:h-[24px] flex items-center justify-center bg-[#EEE9FF] rounded-lg">
                        <ShippingIcon />
                    </div>
                    <div className="flex-1">
                        <div className="flex items-start justify-between gap-3">
                            <div className="flex-1">
                                <h3 className="text-sm sm:text-sm text-gray-900 font-normal">
                                    {payload?.message}
                                </h3>
                                <NotificationFooter notification={notification} />
                            </div>
                            {payload?.product && (
                                <div className="flex-shrink-0 w-[56px] h-[56px] sm:w-[80px] sm:h-[80px] rounded-lg overflow-hidden border-4 border-white bg-gray-100">
                                    <img
                                        src={payload.product}
                                        alt={payload.productName || "Product"}
                                        width={80}
                                        height={80}
                                        className="w-full h-full object-cover"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShippingProduct;