export const APPLIANCE_CATEGORY = [
  {
    name: 'Bed Room',
    image: '/assets/img/applianceCategory/bed-room.png',
    text: 'Transform your bedroom into a stylish and comfortable sanctuary with our high-quality furniture collection.',
    bannerImage: '/assets/img/applianceCategory/applianceCategoryBanner/bed-room.png',
  },
  {
    name: 'Lighting',
    image: '/assets/img/applianceCategory/lighting.png',
    text: 'Transform your bedroom into a stylish and comfortable sanctuary with our high-quality furniture collection.',
    bannerImage: '/assets/img/applianceCategory/applianceCategoryBanner/home-decor.png',
  },
  {
    name: 'Living Room',
    image: '/assets/img/applianceCategory/living-room.png',
    text: 'Discover our premium furniture collection and create a living room that reflects your style and comfort needs.',
    bannerImage: '/assets/img/applianceCategory/applianceCategoryBanner/living-room.png',
  },
  {
    name: 'Kitchen',
    image: '/assets/img/applianceCategory/kitchen.png',
    text: 'Upgrade your kitchen with our high-quality products that combine style, and performance for the ultimate cooking and dining experience.',
    bannerImage: '/assets/img/applianceCategory/applianceCategoryBanner/kitchen.png',
  },
  {
    name: 'Bath Room',
    image: '/assets/img/applianceCategory/bath-room.png',
    text: 'Elevate your bathroom experience with our premium products. Transform your space into a personal oasis of comfort and relaxation.',
    bannerImage: '/assets/img/applianceCategory/applianceCategoryBanner/bath-room.png',
  },
  {
    name: 'Home Decor',
    image: '/assets/img/applianceCategory/home-decor.png',
    text: 'Find inspiration for your home with our curated decor collection. Elevate your style and create a space that feels uniquely yours.',
    bannerImage: '/assets/img/applianceCategory/applianceCategoryBanner/home-decor.png',
  },
  {
    name: 'Appliances',
    image: '/assets/img/applianceCategory/appliances.png',
    text: 'Simplify your life with our innovative appliances. Designed to enhance your home experience and make your daily tasks a breeze.',
    bannerImage: '/assets/img/applianceCategory/applianceCategoryBanner/appliances.png',
  },
  {
    name: 'Cookwares',
    image: '/assets/img/applianceCategory/cookwares.png',
    text: 'Upgrade your cooking game with our premium cookware. Create delicious meals with ease and style, from beginner to pro.',
    bannerImage: '/assets/img/applianceCategory/applianceCategoryBanner/cookwares.png',
  },
  {
    name: 'Media Furniture',
    image: '/assets/img/applianceCategory/media-furniture.png',
    text: 'Transform your living space into a modern and organized home entertainment center with our versatile media furniture collection.',
    bannerImage: '/assets/img/applianceCategory/applianceCategoryBanner/media-furniture.png',
  },
  {
    name: 'Organization',
    image: '/assets/img/applianceCategory/organization.png',
    text: 'Get rid of clutter and organize your media space with our stylish and functional media organization furniture.',
    bannerImage: '/assets/img/applianceCategory/applianceCategoryBanner/organization.png',
  },
]
