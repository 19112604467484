import { useMutation } from '@apollo/client'
import React from 'react'
import CloseIcon from '../../../../../public/assets/icon/close-icon.svg'
import SearchIcon from '../../../../../public/assets/icon/search-folllow.svg'
import SEND_FRIEND_REQUEST from 'src/graphql/mutations/sendFriendRequest'
import DECLINE_FRIEND_REQUEST from 'src/graphql/mutations/declineFriendRequest'
import REMOVE_FOLLOWER from 'src/graphql/mutations/deleteFollowDetail'
import GET_USER_BY_NAME from 'src/graphql/queries/getUserByName'
import { useAuth } from 'src/utils/auth'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import Link from 'next/link'

export default function UserFollowModal(props) {
  const { user }: any = useAuth()
  const { setFollowModal, userData, followers, refetch } = props
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const router = useRouter()
  const [declineFriendRequest] = useMutation(DECLINE_FRIEND_REQUEST, {
    onCompleted: () => {},
    onError: (error) => {},
  })

  const [deleteFollowDetail] = useMutation(REMOVE_FOLLOWER, {
    onCompleted: () => {},
    onError: (error) => {},
  })

  const refetchQuery = () => {
    return [
      {
        query: GET_USER_BY_NAME,
        variables: { slug: router?.query?.id?.[0] },
      },
    ]
  }

  const [sendFriendRequest] = useMutation(SEND_FRIEND_REQUEST, {
    onCompleted: ({ sendFriendRequest }) => {},
    onError: (error) => {
      console.log(error)
    },
  })


  let filterAccountUser =
    followers === 'Followers'
      ? Number(router?.query?.id?.[0]) !== user.userName
        ? userData?.followers.filter((flwer) => flwer?.user?.id !== user?.id)
        : userData?.followers
      : Number(router?.query?.id?.[0]) !== user.userName
      ? userData?.followings.filter((flwing) => flwing?.user?.id !== user?.id)
      : userData?.followings

  let ownUser = (followers === 'Followers' ? userData?.followers : userData?.followings)?.filter(
    (followers) => followers?.user?.id === user?.id,
  )

  const handleMove = (id) => {
    setFollowModal(false)
    dispatch(
      fetchModalData({
        allModalData: [
          ...getAllState?.allModalData,
          { modal: 'userDetail', isOpen: true, id: id, modalFor: 'bigModal' },
        ],
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  return (
    <div className="followers-modal-alignment">
      <div className="followers-modal-wrapper">
        {/* <FramerAnimationModal> */}
        <div className="followers-modal-box-alignment">
          <div className="modal-heading-alignment">
            <div className="heding-alignment">
              <h5>{followers === 'Followers' ? followers : 'Following'}</h5>
              <span>{followers === 'Followers' ? userData?.followers?.length : userData?.followings?.length}</span>
            </div>
            <div className="close-alignment" onClick={() => setFollowModal(false)}>
              <img src={CloseIcon.src} alt="CloseIcon" />
            </div>
          </div>
          <div className="followers-search-alignment">
            <input type="text" placeholder="Search" />
            <div className="serach-icon-alignment">
              <img src={SearchIcon.src} alt="SearchIcon" />
            </div>
          </div>
          <div className="followers-details-alignment">
            {ownUser?.length > 0 && (
              <div className="followers-flex-alignment">
                <div className="followers-name-profile-alignment">
                  <div className="profile-alignment">
                    <img
                      src={
                        ownUser[0]?.user?.logo_image
                          ? ownUser[0]?.user?.logo_image
                          : user?.logo_image
                          ? user?.logo_image
                          : ''
                      }
                      alt="DemoImg"
                    />
                  </div>
                  <h4>{`${ownUser[0]?.user?.firstName} ${' '} ${ownUser[0]?.user?.lastName} `}</h4>
                </div>
              </div>
            )}
            {filterAccountUser?.map((follow, i) => {
              let name = `${follow?.user?.firstName + ' ' + follow?.user?.lastName} `
              return (
                <div className="followers-flex-alignment" key={i}>
                  <div className="followers-name-profile-alignment">
                    <div className="profile-alignment">
                      <img src={follow?.user?.logo_image} alt="DemoImg" />
                    </div>
                    <Link href={`/user/${follow?.user?.userName}`}>
                      <h4
                      // onClick={() => handleMove(follow?.user?.id)}
                      >
                        {name}
                      </h4>
                    </Link>
                  </div>
                  {router?.query?.id?.[0] === user.userName ? (
                    //FOR_OWN_PROFILE=============================================================================================
                    followers === 'Followers' ? (
                      <div
                        className="folloers-button-alignment"
                        onClick={() =>
                          declineFriendRequest({
                            variables: { user_id: follow?.user?.id, friend_id: user.id },
                            refetchQueries: refetchQuery,
                          })
                        }
                      >
                        <button className="following">remove</button>
                      </div>
                    ) : (
                      <div
                        className="folloers-button-alignment"
                        onClick={() =>
                          declineFriendRequest({
                            variables: { user_id: user.id, friend_id: follow?.user?.id },
                            refetchQueries: refetchQuery,
                          })
                        }
                      >
                        <button className="following">Following</button>
                      </div>
                    )
                  ) : //FOR_OTHER_USER_PROFILE=====================================================================================
                  follow?.user?.isActiveForFriendStatus && follow?.user?.isFriendForFriendStatus ? (
                    <div
                      className="folloers-button-alignment"
                      onClick={() =>
                        declineFriendRequest({
                          variables: { user_id: user.id, friend_id: follow?.user?.id },
                          refetchQueries: refetchQuery,
                        })
                      }
                    >
                      <button className="following">Following</button>
                    </div>
                  ) : (
                    <div
                      className="folloers-button-alignment"
                      onClick={() =>
                        sendFriendRequest({
                          variables: {
                            friend_id: follow?.user?.id,
                            user_id: user?.id,
                            isActive: true,
                            isFriend: true,
                          },
                          refetchQueries: refetchQuery,
                        })
                      }
                    >
                      <button>Follow</button>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
        {/* </FramerAnimationModal> */}
      </div>
    </div>
  )
}
