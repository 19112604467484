import React, { useEffect, useState } from 'react'
import styles from './LiveStreamComment.module.scss'
import { useDyteSelector } from '@dytesdk/react-web-core'
import InputEmoji from 'react-input-emoji'

export default function LiveStreamComment({ messageData, setMessageData, meeting, messages, viewerList }) {
  // const [messages, setMessages] = useState([])
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const unixTimestamp = Math.floor(new Date(meeting?.meta.meetingStartedTimestamp).getTime() / 1000)

  //     let msg = await meeting.chat.getMessages(unixTimestamp, 10, false)

  //     setMessages(msg?.messages)

  //   }
  //   fetchData()
  // }, [])

  const handleSendMessage = async () => {
    // await meeting?.chat?.send(messageData)
    await meeting.chat.sendTextMessage(messageData)
    setMessageData('')
  }

  return (
    <div className={styles.liveStreamAcommentSection}>
      <div className={styles.liveStreamHeading}>
        <h4>Comments</h4>
      </div>
      {meeting?.livestream.state === 'LIVESTREAMING' ? (
        <>
          <div className={styles.liveSTreamBodyAlignment}>
            {messages?.map((item: any, index) => {
              const viewer = viewerList?.find(viewer => viewer?.userId === item?.userId);
              return (
                <div className={styles.liveStreamCommentFlex} key={index}>
                  <div className={styles.liveStreamProfileDetails}>
                    <div className={styles.liveStreamProfileImg}>
                      {/* <img src="/assets/img/demo-img-2.png" alt="demo img" /> */}
                      {viewer?.picture ? (
                        <img
                          src={viewer?.picture}
                          alt="demo img"
                        />
                      ) : (
                        <span style={{ justifyContent: 'center' }}>
                          {(item?.displayName).split(' ').map((item) => {
                            return item[0].toUpperCase()
                          })}
                        </span>
                      )}
                    </div>

                    <div className="flex text-[#636773] ">
                      <h6 className='text-14'>{item?.displayName} </h6>
                      <p className=' ml-3 font-normal text-13'>{item?.message}</p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className={styles.liveVommentBottomInputMessage}>
            <div className={styles.liveStreamInput}>
              {/* <input
            type="text"
            value={messageData}
            placeholder="Type comment..."
            onChange={(e) => setMessageData(e.target.value)}
            onKeyDown={(e: any) => {
              if (e.code === 'Enter') {
                handleSendMessage()
              }
            }}
          /> */}

              <InputEmoji
                placeholder="Type comment..."
                value={messageData}
                onChange={(e) => setMessageData(e)}
                fontSize={12}
                borderRadius={10}
                height={46}
                onKeyDown={(e: any) => {
                  if (e?.code === 'Enter') {
                    handleSendMessage()
                  }
                }}
              />

              {/* <div className={styles.liveStreamIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M11.998 22.0006C17.5209 22.0006 21.998 17.5235 21.998 12.0008C21.998 6.47804 17.5209 2.00098 11.998 2.00098C6.4752 2.00098 1.99805 6.47804 1.99805 12.0008C1.99805 17.5235 6.4752 22.0006 11.998 22.0006Z"
                stroke="#636773"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.99805 14.0005C7.99805 14.0005 9.49805 16.0005 11.998 16.0005C14.498 16.0005 15.998 14.0005 15.998 14.0005"
                stroke="#636773"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.99805 9.00098H9.00805"
                stroke="#636773"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.998 9.00098H15.008"
                stroke="#636773"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div> */}
            </div>
            <div className={styles.liveSendMessage} onClick={() => handleSendMessage()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path
                  d="M15.9502 8.36426L10.998 13.3163"
                  stroke="#7A7E88"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.998 2.31641L14.998 22.316L10.998 13.3162L1.99805 9.31627L21.998 2.31641Z"
                  stroke="#7A7E88"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.noDataCommentSection}>
          <p>Comment section will be available once live streaming started</p>
        </div>
      )}
    </div>
  )
}
