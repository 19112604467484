import { useMutation } from '@apollo/client'
import { Checkbox, notification } from 'antd'
import React, { useState } from 'react'
import UPDATE_CART_MUTATION from 'src/graphql/mutations/updateCart'
import DELETE_CART_ITEM from 'src/graphql/mutations/deleteCartItem'
import { fetchCartData, getAllCartCountSliceData } from 'src/store/slices/getCartCount'
import { useAppDispatch } from 'src/store/store'

export default function CartProduct({ product, productIdArray, setProductIdArray, selectedQuantity, index }) {
  const [productQty, setProductQty] = useState(0)
  const dispatch = useAppDispatch()

  const [updateCart] = useMutation(UPDATE_CART_MUTATION, {
    onCompleted() {
      dispatch(fetchCartData())
    },
    onError(err) {
      notification['error']({
        message: 'Error',
        description: err.message,
      })
    },
  })

  const [DeleteCartItem] = useMutation(DELETE_CART_ITEM, {
    async onCompleted() {
      dispatch(getAllCartCountSliceData())
      dispatch(fetchCartData())
    },
    onError(err) {
      notification['error']({
        message: 'Error',
        description: err.message,
      })
    },
  })

  const handleClick = (type) => {
    if (type === '+') {
      updateCart({
        variables: {
          input: {
            parent_id: product?.id,
            variant_id: product?.variant?.id,
            quantity: selectedQuantity + 1,
            cart_for: 'PRODUCT',
          },
        },
      })
    } else {
      if (selectedQuantity !== 1) {
        updateCart({
          variables: {
            input: {
              parent_id: product?.id,
              variant_id: product?.variant?.id,
              quantity: selectedQuantity - 1,
              cart_for: 'PRODUCT',
            },
          },
        })
      }
    }
  }

  const handleDeleteCart = async () => {
    const selectedProductIds = product?.cart_item_id;
    DeleteCartItem({
      variables: { deleteCartItemsId: selectedProductIds },
    });
  }

  // const increaseQuantityHandler = () => {
  //   updateCart({
  //     variables: { parent_id: id, id: cartId, quantity: product?.qty + 1, cart_for: 'PRODUCT' },
  //   })
  // }
  // const decreaseQuantityHandler = () => {
  //   // if (loading || qty <= 1) return
  //   updateCart({
  //     variables: { parent_id: id, id: cartId, quantity: product?.qty - 1, cart_for: 'PRODUCT' },
  //   })
  // }

  const handleChange = (e) => {
    if (e.target.value === '') {
      setProductQty(0)
    } else {
      setProductQty(parseInt(e.target.value))
    }
  }

  const onChange = (e, data) => {
    let dataFilter = productIdArray.filter((arr) => arr.productId === data?.id)
    if (dataFilter.length > 0) {
      setProductIdArray(productIdArray.filter((id) => id.productId !== data?.id))
    } else {
      productIdArray.push({
        productId: data?.id,
        quantity: selectedQuantity,
        totalPrice:
          (data?.variant?.price
            ? Number(data?.variant?.price)
            : data?.inventoryPrice?.price
            ? Number(data?.inventoryPrice?.price)
            : 0) * selectedQuantity,
        variant_id: data?.variant?.id,
      })
      setProductIdArray([...productIdArray])
    }
  }

  return (
    <div className="product-first-part" key={index}>
      <div>
        <Checkbox onChange={(e) => onChange(e, product)} defaultChecked />
      </div>
      <div className="product-section-main">
        <div className="mobile-view-flex-alignment">
          <div className="product-detail-alignment">
            <div className="product-card-inner-detail">
              <div className="product-image-contain">
                <img
                  src={
                    product?.variant?.image?.url
                      ? product?.variant?.image?.url
                      : product?.images?.length > 0
                      ? product?.images[0]?.src
                      : ''
                  }
                />
              </div>
              <div className="product-info-main">
                <div className="product-title-align">
                  <div className="cart-product-detail">
                    <h2>{product.title.length > 30 ? product.title.substring(0, 30) + '...' : product.title}</h2>
                  </div>
                  <div className="product-store-detail">
                    <div className="save-delete-section">
                      <div className="save-product-button">
                        <span>Save for later</span>
                      </div>
                      <div className="trash-align" onClick={() => handleDeleteCart()}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M2.5 5H4.16667H17.5"
                            stroke="#ACB1C0"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M15.8327 4.9974V16.6641C15.8327 17.1061 15.6571 17.53 15.3445 17.8426C15.032 18.1551 14.608 18.3307 14.166 18.3307H5.83268C5.39065 18.3307 4.96673 18.1551 4.65417 17.8426C4.34161 17.53 4.16602 17.1061 4.16602 16.6641V4.9974M6.66602 4.9974V3.33073C6.66602 2.8887 6.84161 2.46478 7.15417 2.15222C7.46673 1.83966 7.89065 1.66406 8.33268 1.66406H11.666C12.108 1.66406 12.532 1.83966 12.8445 2.15222C13.1571 2.46478 13.3327 2.8887 13.3327 3.33073V4.9974"
                            stroke="#ACB1C0"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.33398 9.16406V14.1641"
                            stroke="#ACB1C0"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.666 9.16406V14.1641"
                            stroke="#ACB1C0"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="product-detail-section">
                  {product?.variant ? (
                    <div className="cart-detail-section-main">
                      <div className="size-color-container">
                        {product?.variant?.total_variant?.map((variant) => {
                          return (
                            <div className="color-contain">
                              <div className="color-selecition">
                                <span>{variant?.variant_option?.data?.name}: </span>
                                <div className="color-preview">
                                  <span>{variant?.variant_option?.value}</span>
                                </div>
                              </div>
                              {/* <p>Change</p> */}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className="product-quantity-box">
                    <div className="quantity-counter">
                      <div className="quantity-button-contain">
                        <div className="quantity-button-section" onClick={() => handleClick('-')}>
                          <img src={'/assets/img/product-detail/minus-icon.svg'} />
                        </div>
                        <div className="product-quantity-box">
                          <input type="text" value={selectedQuantity} disabled />
                        </div>
                        <div className="quantity-button-section" onClick={() => handleClick('+')}>
                          <img src={'/assets/img/product-detail/plus-icon.svg'} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="price-align">
                    <span>
                      ${' '}
                      {(
                        (product?.variant?.price
                          ? Number(product?.variant?.price)
                          : product?.inventoryPrice?.price
                          ? Number(product?.inventoryPrice?.price)
                          : 0) * selectedQuantity
                      ).toFixed(2)}
                    </span>
                  </div>
                </div>

                <div className="mobile-view-product-details-alignment">
                  <div className="mobile-view-chils-details-alignment">
                    <div className="mobile-view-details-alignment">
                      <span>Size: </span>
                      <p> 11’,</p>
                    </div>
                    <div className="mobile-view-details-alignment">
                      <span>Color: </span>
                      <p>Dark Grey</p>
                    </div>
                  </div>

                  <div className="mobile-view-change-alignment">
                    <a>Change</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-product-store-details">
            <div className="save-delete-section">
              <div className="save-product-button">
                <span>Save for later</span>
              </div>
              <div className="trash-align" onClick={() => handleDeleteCart()}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.5 5H4.16667H17.5"
                    stroke="#ACB1C0"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.8327 4.9974V16.6641C15.8327 17.1061 15.6571 17.53 15.3445 17.8426C15.032 18.1551 14.608 18.3307 14.166 18.3307H5.83268C5.39065 18.3307 4.96673 18.1551 4.65417 17.8426C4.34161 17.53 4.16602 17.1061 4.16602 16.6641V4.9974M6.66602 4.9974V3.33073C6.66602 2.8887 6.84161 2.46478 7.15417 2.15222C7.46673 1.83966 7.89065 1.66406 8.33268 1.66406H11.666C12.108 1.66406 12.532 1.83966 12.8445 2.15222C13.1571 2.46478 13.3327 2.8887 13.3327 3.33073V4.9974"
                    stroke="#ACB1C0"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.33398 9.16406V14.1641"
                    stroke="#ACB1C0"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.666 9.16406V14.1641"
                    stroke="#ACB1C0"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="mobile-view-product-flex-alignment">
          <div className="product-quantity-box">
            <div className="quantity-counter">
              <div className="quantity-button-contain">
                <div className="quantity-button-section" onClick={() => handleClick('-')}>
                  <img src={'/assets/img/product-detail/minus-icon.svg'} />
                </div>
                <div className="product-quantity-box">
                  <input type="text" value={selectedQuantity} disabled />
                </div>
                <div className="quantity-button-section" onClick={() => handleClick('+')}>
                  <img src={'/assets/img/product-detail/plus-icon.svg'} />
                </div>
              </div>
            </div>
          </div>
          <div className="price-align">
            <span>
              ${' '}
              {
                (
                  (product?.variant?.price
                    ? Number(product?.variant?.price)
                    : product?.inventoryPrice?.price
                    ? Number(product?.inventoryPrice?.price)
                    : 0) * selectedQuantity
                ).toFixed(2)
                // (product?.variant?.inventory_quantity
                //   ? product?.variant?.inventory_quantity
                //   : product?.inventoryPrice?.quantity
                //   ? product?.inventoryPrice?.quantity
                //   : 0)
              }
            </span>
          </div>
        </div>
        <div className="bottom-border"></div>
      </div>
    </div>
  )
}

