import { ArrowLeftIcon, ArrowRightIcon, CheckCircleIcon } from '@heroicons/react/24/solid'
import nookies from 'nookies'
import React, { useEffect, useState } from 'react'
import FinishStep from './FinishStep'
import { useRouter } from 'next/router'
import { isEmpty } from 'src/utils/isEmpty'
import { useOnChange } from 'src/utils/useOnChange'
import ShippingProfile from '../OrderShippingMain/shipping/ShippingProfile'
import SellerCompanyTab from './DirectSellerOnboard/SellerCompanyTab'
import SellerOnBoardingProfileTab from './DirectSellerOnboard/SellerOnBoardingProfileTab'
import SellerOnboardTaxesTab from './DirectSellerOnboard/SellerOnboardTaxesTab'
import WelcomePage from './WelcomePage'
import { API } from 'src/config/API/api.config'
import { ApiPost } from 'src/Helpers/Api/ApiData'
import { notification } from 'antd'
import { useMutation } from '@apollo/client'
import sellerOnboard from 'src/graphql/mutations/sellerOnboard'
import SELLER_ONBOARD from 'src/graphql/mutations/sellerOnboard'
import { useAuth } from 'src/utils/auth'

interface OnboardingStep {
  id: number
  title: string
  description: string
  component?: React.ReactNode
  completed: boolean
}
interface ImageResponse {
  data?: {
    image?: {
      media: string
    }[]
  }
}

export interface sellerInputValue {
  seller: {
    firstName: string
    lastName: string
    profile_image: string
    phoneNumber: string
    is_tax_calculation: boolean
    is_price_include_tax: boolean
    tax_categories: string[]
  }
  sellerIdentification: {
    id_type: string
    document: string
  }
  tax_rules: [
    {
      country: string
      state: string
      rate_percentage: 5
    },
  ]
  password: {
    current_password: string
    new_password: string
  }
  store: {
    logo_image: string
    banner_image: string
    store_name: string
    companyLegalName: string
  }
  business: {
    business_address: string
    business_address_detail: string
    business_email: string
    business_city: string
    business_state: string
    business_zip_code: string
    business_country: string
    business_phone_number: string
  }
}

const SellerOnboardingStepper = () => {
  const router = useRouter()
  const { seller, user } = useAuth()
  const [showWelcome, setShowWelcome] = useState(true)

  const { inputValue, setInputValue, handleChange, errors, setErrors } = useOnChange({})
  useEffect(() => {
    console.log(`inputValue`, inputValue)
  }, [inputValue])

  const [currentStep, setCurrentStep] = useState(0)
  const handleNext = () => {
    if (isValid()) {
      if (currentStep < steps.length - 1) {
        const updatedSteps = [...steps]
        updatedSteps[currentStep].completed = true
        setSteps(updatedSteps)
        setCurrentStep((prev) => prev + 1)
      } else if (currentStep === steps.length - 1) {
        // if last step
        const updatedSteps = [...steps]
        updatedSteps[currentStep].completed = true
        setSteps(updatedSteps)
      }
    }
  }

  const isValid = () => {
    const newErrors = {
      // step 1 varieble
      logo_image: '',
      banner_image: '',
      store_name: '',
      companyLegalName: '',
      business_email: '',
      business_phone_number: '',
      business_address: '',
      business_zip_code: '',
      city: '',
      country: '',
      state: '',
      // step 2 varieble
      profile_image: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      current_password: '',
      new_password: '',
      //extra for 2nd
      new_password_confirm: '',
      //step 3 varieble
      // is_tax_calculation: true,
      // is_price_include_tax: true,
      // tax_rules: [
      //   {
      //     country: "",
      //     state: "",
      //     rate_percentage: 5,
      //   }
      // ],
      // tax_categories: ["Digital", "Physical"],
      // sellerIdentification: {
      //   id_type: string
      //   document: string
      // }
    }
    let isValid = true
    if (currentStep === 0) {
      if (isEmpty(inputValue?.logo_image)) {
        newErrors.logo_image = 'Logo image is required'
        isValid = false
      }
      if (isEmpty(inputValue?.banner_image)) {
        newErrors.banner_image = 'Banner image is required'
        isValid = false
      }
      if (isEmpty(inputValue?.store_name)) {
        newErrors.store_name = 'store name is required'
        isValid = false
      }
      if (isEmpty(inputValue?.companyLegalName)) {
        newErrors.companyLegalName = 'company name is required'
        isValid = false
      }
      if (isEmpty(inputValue?.business_email)) {
        newErrors.business_email = 'Email is required'
        isValid = false
      }
      if (isEmpty(inputValue?.business_phone_number)) {
        newErrors.business_phone_number = 'Phone number is required'
        isValid = false
      }
      if (isEmpty(inputValue?.business_address)) {
        newErrors.business_address = 'Business address is required'
        isValid = false
      }
      if (isEmpty(inputValue?.business_zip_code)) {
        newErrors.business_zip_code = 'Zip code is required'
        isValid = false
      }
      if (isEmpty(inputValue?.cityId)) {
        newErrors.city = 'City  is required'
        isValid = false
      }
      if (isEmpty(inputValue?.countryId)) {
        newErrors.country = 'Country is required'
        isValid = false
      }
      if (isEmpty(inputValue?.stateId)) {
        newErrors.state = 'state is required'
        isValid = false
      }
    }
    if (currentStep === 1) {
      
      if (isEmpty(inputValue?.profile_image)) {
        newErrors.profile_image = 'Profile image is required'
        isValid = false
      }

      if (isEmpty(inputValue?.firstName)) {
        newErrors.firstName = 'First name is required'
        isValid = false
      }
      if (isEmpty(inputValue?.lastName)) {
        newErrors.lastName = 'Last Name is required'
        isValid = false
      }
      if (isEmpty(inputValue?.phoneNumber)) {
        newErrors.phoneNumber = 'Phone number is required'
        isValid = false
      }
      if (isEmpty(user)) {
        if (isEmpty(inputValue?.current_password)) {
          newErrors.current_password = 'Current password is required'
          isValid = false
        }
        if (isEmpty(inputValue?.new_password)) {
          newErrors.new_password = 'New password is required'
          isValid = false
        }
        if (isEmpty(inputValue?.new_password_confirm)) {
          newErrors.new_password_confirm = 'Confirm password is required'
          isValid = false
        }
      }
    }

    setErrors(newErrors)
    return isValid
  }
  // useEffect for save initial pre value
  useEffect(() => {
    const setInitialValue = {
      email: seller?.email,
      logo_image: seller?.seller_detail?.logo_image,
      companyLegalName: seller?.seller_detail?.companyLegalName,
      store_name: seller?.seller_detail?.name,
      business_email: seller?.seller_business_detail?.business_email,
      business_phone_number: seller?.seller_business_detail?.business_phone_number,
      business_address: seller?.seller_business_detail?.business_address,
      business_zip_code: seller?.seller_business_detail?.business_zip_code,
      profile_image: '',
      firstName: seller?.firstName,
    }
    setInputValue(setInitialValue)
  }, [seller])

  const [steps, setSteps] = useState<OnboardingStep[]>([
    {
      id: 1,
      title: 'Company Information',
      description: 'Tell us about your business',
      completed: false,
    },
    {
      id: 2,
      title: 'Personal Profile',
      description: 'Set up your seller profile',
      completed: false,
      // component: <ProfileInfo />,
    },
    {
      id: 3,
      title: 'Tax Information',
      description: 'Configure your tax settings',
      completed: false,
      // component: <TaxInfo />,
    },
    {
      id: 4,
      title: 'Shipping Profile',
      description: 'Set up your shipping preferences',
      completed: false,
      // component: <ShippingInfo />,
    },
    {
      id: 5,
      title: 'Finish Setup',
      description: 'Complete your seller onboarding',
      completed: false,
      // component: <FinalStep />,
    },
  ])
  // useEffect for replace token in  nookies
  useEffect(() => {
    const handleToken = async () => {
      // First check localStorage
      const storedToken = localStorage.getItem('token')
      const cookieToken = nookies.get()?.token
      if (router?.query?.token) {
        const nookiesToken = Array.isArray(router?.query?.token) ? router?.query?.token[0] : router?.query?.token

        nookies.set(null, 'token', nookiesToken, {
          path: '/',
          maxAge: 30 * 24 * 60 * 60, // 30 days
          secure: process.env.NODE_ENV === 'production', // Only secure in production
          sameSite: 'lax', // Changed to lax for better compatibility
        })
        // Set in localStorage
        localStorage.setItem('token', nookiesToken)
        const { token, ...restQuery } = router.query
        await router.replace(
          {
            pathname: '/seller/onboarding',
            query: restQuery,
          },
          undefined,
          { shallow: true },
        )
      } else if (!cookieToken && storedToken) {
        // If token is in localStorage but not in cookies, restore it
        nookies.set(undefined, 'token', storedToken, {
          path: '/',
          maxAge: 30 * 24 * 60 * 60,
          secure: process.env.NODE_ENV === 'production',
          sameSite: 'lax',
        })
      } else if (!storedToken && cookieToken) {
        localStorage.setItem('token', cookieToken)
      }
    }

    handleToken()
  }, [router.query])

  const [sellerOnboard] = useMutation(SELLER_ONBOARD, {
    onCompleted: (res) => {
      console.log(`resresresresressssssssssss`, res)
      if (res?.sellerOnboard?.success && res?.sellerOnboard.error?.length == 0) {
        notification['success']({
          message: 'success',
          description: 'Seller onboard Completed succesfully.',
        })
        setCurrentStep(4)
        setInputValue(null)
      } else {
        notification['error']({
          message: 'Error',
          description: res?.sellerOnboard.error[0] || 'Something went wrong',
        })
      }
    },
    onError: (err) => {
      console.log('err', err)
      notification['error']({
        message: 'Error',
        description: 'Something went wrong',
      })
    },
  })

  const handleSubmit = async () => {
    try {
      let UploadImageRoute =API.endpoint.includes('stage') || API.endpoint.includes('local')
          ? `/upload?type=bluejestic-stage/post`
          : `/upload?type=bluejestic-production/post`

      const uploadPromises = []

      // Define variables for the uploaded image URLs
      let logo_image, banner_image, profile_image

      const uploadImage = async (file, type) => {
        const UploadFile = new FormData()
        UploadFile.append('image', file)
        UploadFile.append('media_for', 'post')
        const image_data: ImageResponse = await ApiPost(UploadImageRoute, UploadFile)
        console.log(`image_dataimage_dataimage_dataimage_data`, image_data)
        if (Boolean(image_data?.data?.image?.length) && image_data?.data?.image[0]?.media) {
          return image_data?.data?.image[0]?.media
        }
        return null
      }

      if (inputValue?.logo) {
        uploadPromises.push(
          uploadImage(inputValue?.logo, 'logo').then((url) => {
            logo_image = url
          }),
        )
      } else {
        logo_image = inputValue?.logo
      }
      if (inputValue?.banner_image) {
        uploadPromises.push(
          uploadImage(inputValue?.banner, 'banner').then((url) => {
            banner_image = url
          }),
        )
      }
      if (inputValue?.profile_image) {
        uploadPromises.push(
          uploadImage(inputValue?.profile, 'profile').then((url) => {
            profile_image = url
          }),
        )
      }

      // Wait for all upload promises to resolve
      await Promise.all(uploadPromises)

      // Prepare final API payload
      const apiSendData = {
        seller: {
          firstName: inputValue?.firstName,
          lastName: inputValue?.lastName,
          profile_image,
          phoneNumber: inputValue?.phoneNumber,
          ...(inputValue?.is_tax_calculation && { is_tax_calculation: inputValue?.is_tax_calculation }),
          ...(inputValue?.is_price_include_tax && { is_price_include_tax: inputValue?.is_price_include_tax }),
          ...(inputValue?.tax_categories && { tax_categories: inputValue?.tax_categories }),
        },
        ...(inputValue?.sellerIdentification && { sellerIdentification: { ...inputValue?.sellerIdentification } }),
        ...(inputValue?.tax_rules && { taxRules: [...inputValue?.tax_rules] }),
        ...(isEmpty(user) && {
          password: {
            current_password: inputValue?.current_password,
            new_password: inputValue?.new_password,
          },
        }),
        store: {
          logo_image,
          banner_image,
          store_name: inputValue?.store_name,
          companyLegalName: inputValue?.companyLegalName,
        },
        business: {
          business_address: inputValue?.business_address,
          business_email: inputValue?.business_email,
          business_city: inputValue?.cityId,
          business_state: inputValue?.state,
          business_zip_code: inputValue?.business_zip_code,
          business_country: inputValue?.country,
          business_phone_number: inputValue?.business_phone_number,
        },
      }

      // Call final API
      sellerOnboard({
        variables: {
          input: apiSendData,
        },
      })
    } catch (error) {
      notification['error']({
        message: 'Error',
        description: 'Something went wrong. Please try again later.',
      })
    }
  }

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1)
    }
  }

  const handleSkip = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prev) => prev + 1)
    }
  }

  const handleStartOnboarding = () => {
    setShowWelcome(false)
  }

  if (showWelcome) {
    return <WelcomePage handleStart={handleStartOnboarding} />
  }

  return (
    <div className="w-full mx-auto px-4 py-8" style={{ maxWidth: '1248px' }}>
      <div className="flex flex-col lg:flex-row gap-8">
        {/* Steps Navigation - Left Side */}
        <div className="w-full flex-shrink-0" style={{ maxWidth: '384px' }}>
          <div className="bg-white dark:bg-gray-800 rounded-2xl p-6 border border-gray-200 dark:border-gray-700 sticky top-8">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-8">Your Progress</h2>
            <div className="space-y-4">
              {steps.map((step, index) => (
                <button
                  key={step.id}
                  onClick={() => index <= currentStep && setCurrentStep(index)}
                  disabled={index > currentStep}
                  className={`w-full flex items-center p-4 rounded-xl transition-all duration-200 ${
                    index === currentStep
                      ? 'bg-indigo-50 dark:bg-indigo-900/30 border-2 border-indigo-500'
                      : index < currentStep
                      ? 'bg-green-50 dark:bg-green-900/30 border border-green-500'
                      : 'bg-gray-50 dark:bg-gray-700/50 border border-gray-200 dark:border-gray-600 cursor-not-allowed opacity-60'
                  }`}
                >
                  <div
                    className={`w-10 h-10 rounded-full flex items-center justify-center mr-4 transition-colors ${
                      step.completed
                        ? 'bg-green-500 text-white'
                        : index === currentStep
                        ? 'bg-indigo-500 text-white'
                        : 'bg-gray-200 dark:bg-gray-600 text-gray-500 dark:text-gray-400'
                    }`}
                  >
                    {step.completed ? (
                      <CheckCircleIcon className="w-6 h-6" />
                    ) : (
                      <span className="text-lg font-semibold">{step.id}</span>
                    )}
                  </div>
                  <div className="text-left">
                    <div
                      className={`font-semibold ${
                        index === currentStep ? 'text-indigo-600 dark:text-indigo-400' : 'text-gray-900 dark:text-white'
                      }`}
                    >
                      {step.title}
                    </div>
                    <div className="text-sm text-gray-500 dark:text-gray-400">{step.description}</div>
                  </div>
                </button>
              ))}
            </div>

            {/* Navigation Buttons */}
            <div className="mt-6">
              <div className="flex justify-between items-center gap-3">
                {currentStep !== 4 && (
                  <button
                    onClick={handleBack}
                    disabled={currentStep === 0}
                    className={`flex items-center justify-center px-3 py-1.5 text-sm rounded transition-all duration-200 ${
                      currentStep === 0
                        ? 'bg-gray-100 text-gray-400 cursor-not-allowed dark:bg-gray-700 dark:text-gray-500'
                        : 'bg-white text-gray-600 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600 border border-gray-200 dark:border-gray-600'
                    }`}
                  >
                    <ArrowLeftIcon className="w-4 h-4 mr-1" />
                    Back
                  </button>
                )}

                <div className="flex items-center gap-2">
                  {currentStep === 2 && (
                    <button
                      onClick={handleSkip}
                      className="flex items-center justify-center px-3 py-1.5 text-sm font-medium text-gray-600 hover:text-gray-800 dark:text-gray-300 dark:hover:text-gray-100 rounded border border-gray-200 dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-200"
                    >
                      Skip
                    </button>
                  )}

                  <button
                    onClick={() => {
                      if (currentStep === steps.length - 1) {
                        router.push('/seller/dashboard/')
                      } else if (currentStep === 3) {
                        handleSubmit()
                      } else {
                        handleNext()
                      }
                    }}
                    className={`flex items-center justify-center px-4 py-2 text-sm font-medium rounded transition-colors duration-200 ${
                      currentStep === steps.length - 1
                        ? 'bg-green-500 hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-700'
                        : 'bg-indigo-500 hover:bg-indigo-600 dark:bg-indigo-600 dark:hover:bg-indigo-700'
                    } text-white`}
                  >
                    {currentStep === steps.length - 1 || currentStep === 3 ? (
                      <>
                        Finish
                        <CheckCircleIcon className="w-4 h-4 ml-1" />
                      </>
                    ) : (
                      <>
                        Next
                        <ArrowRightIcon className="w-4 h-4 ml-1" />
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Form Content - Right Side */}
        <div className="flex-1">
          <div className="bg-white dark:bg-gray-800 rounded-2xl p-8 border border-gray-200 dark:border-gray-700">
            <div className="mb-8">
              <h2 className="text-3xl font-bold text-gray-900 dark:text-white">{steps[currentStep].title}</h2>
              <p className="mt-2 text-lg text-gray-600 dark:text-gray-300">{steps[currentStep].description}</p>
            </div>

            {/* {steps[currentStep].component} */}
            {currentStep === 0 && (
              <SellerCompanyTab
                setErrors={setErrors}
                errors={errors}
                companyInfo={inputValue}
                setInputValue={setInputValue}
                handleNext={handleNext}
              />
            )}
            {currentStep === 1 && (
              <SellerOnBoardingProfileTab
                isValid={isValid}
                user={user}
                handleChange={handleChange}
                setErrors={setErrors}
                errors={errors}
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleNext={handleNext}
              />
            )}
            {currentStep === 2 && (
              <SellerOnboardTaxesTab
                setErrors={setErrors}
                errors={errors}
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleNext={handleNext}
                handleChange={handleChange}
              />
            )}
            {currentStep === 3 && <ShippingProfile />}
            {currentStep === 4 && <FinishStep />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SellerOnboardingStepper
