// @ts-nocheck


import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import BlueShoppingIcon from '../../../../../public/assets/icon/blue-shopping-bag.svg'
import CREATE_CART_MUTATION from 'src/graphql/mutations/createCart'
import REMOVE_BOOKMARK from 'src/graphql/mutations/removeBookmark'
// import MY_FEED_QUERY from 'src/graphql/queries/getMyFeed'
import { useMutation } from '@apollo/client'
import { Modal, notification } from 'antd'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import useDeviceDetect from 'src/utils/useDeviceDetect'
import moment from 'moment'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAuth } from 'src/utils/auth'
import ADD_BOOKMARK from 'src/graphql/mutations/addBookmark'
import LazyImage from 'src/utils/LazyImage'
import { fetchAllFeedSliceData } from 'src/store/slices/fetchAllFeedSlice'
import { getAllCartCountSliceData } from 'src/store/slices/getCartCount'
import Link from 'next/link'
import AddProductCollectionModal from 'src/components/AddProductTomodel'
import { manageBookmark } from 'src/store/slices/manageBookmarkTab'

import styles from './Messagechatboxsldier.module.scss'
import client1 from 'src/utils/apolloClient'
import GET_PRODUCT_IMAGES from 'src/graphql/queries/getProductImages'

const RightArrow = '/assets/icon/share-right-arrow.svg'
const LeftArrow = '/assets/icon/share-left-arrow.svg'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <img src={RightArrow} alt="RightArrow" />
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <img src={LeftArrow} alt="LeftArrow" />
    </div>
  )
}

export default function Messagechatboxslidert(props) {
  const { post, product_id } = props
  const { user }: any = useAuth()
  const [product, setProduct] = useState(null)
  const [sharedProductPreview, setSharedProductPreview] = useState(false)
  const [previewURL, setPreviewURL] = useState('')
  const dispatch = useAppDispatch()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const bookmarkTabData = useSelector((state: any) => state?.manageBookmarkTabsSlice?.data)

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2.04,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.04,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleDivClick = (post) => {
    // bookmarkProduct(post)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const [createCart] = useMutation(CREATE_CART_MUTATION, {
    onCompleted: (res) => {
      dispatch(getAllCartCountSliceData())
    },
    onError: (error) => {
      notification['error']({
        message: 'Error',
        description: 'Sorry, you can only purchase allowable quantity of this product',
      })
    },
  })

  const QuantityHandler = () => {
    if (user) {
      createCart({
        variables: { input: { parent_id: post?.product?.id, quantity: 1, cart_for: 'PRODUCT' } },
      })
    } else {
      handleRedirect()
    }
  }

  const [addBookmark] = useMutation(ADD_BOOKMARK, {
    onCompleted: (res) => {
      dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const [removeBookmark] = useMutation(REMOVE_BOOKMARK, {
    onCompleted: (res) => {
      dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const handelImageSlider = () => { }

  const addBookmarkFromFeed = (post) => {
    if (user) {
      if (post?.isBookmarked) {
        removeBookmark({
          variables: {
            id: post?.product?.bookmark?.id,
          },
        })
      } else {
        dispatch(
          manageBookmark({
            modal: '',
            isOpen: true,
            id: {
              ...bookmarkTabData?.id,
              // post: post,
              type: 'feed',
              bookmark_id: post?.product?.bookmark?.id,
              product_id: post?.product?.id,
              collection_id: post?.product?.bookmark?.collection?.id,
              addProductToCollection: {
                ...bookmarkTabData?.id?.deleteCollection,
                isShowAddCollectionModel: true,
                productId: post?.product?.id,
              },
            },
          }),
        )
      }
    } else {
      handleRedirect()
    }
  }

  const fetchProduct = async () => {
    const { data, loading } = await client1.query({
      query: GET_PRODUCT_IMAGES,
      variables: {
        productId: product_id,
      },
      fetchPolicy: 'network-only',
    })
    setProduct(data?.getProductImages?.data)
  }

  useEffect(() => {
    fetchProduct()
  }, [product_id])

  const Images = [
    'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/product/shopify/IMG_0981-1722941348772-98409480.webp',
    'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/product/shopify/IMG_0986-1722941357221-369732271.webp',
    'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/product/shopify/IMG_0978-1722941365388-240906987.webp',
    'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/product/shopify/IMG_0973-1722941375433-39132163.webp',
    'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/product/shopify/IMG_0971-1722941385645-35679618.webp',
    'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/product/shopify/IMG_0977-1722941396211-787590138.webp',
  ]


  return (
    <React.Fragment>
      <div className={styles.Messagechatboxslidert}>
        <div className="share-post-design-section">
          {product?.images?.length > 2 ? (
            // <div className="share-post-design-section-slider-alignment">
            <Slider {...settings}>
              {product?.images?.map((prd, index) => {
                let url = prd?.src
                return (
                  <div
                    key={index}
                    className="share-product-img-alignment"
                    onClick={() => {
                      setSharedProductPreview(true)
                      setPreviewURL(url)
                    }}
                  >
                    {url?.includes('mp4') ? (
                      <video muted autoPlay loop style={{ objectFit: 'cover' }}>
                        <source src={url} />
                      </video>
                    ) : (
                      // <LazyImage
                      //   image={{
                      //     src: url,
                      //     alt: 'Icon',
                      //   }}
                      //   objectFit={'cover'}
                      //   handleClickOnImage={() => handelImageSlider()}
                      //   className={'shared-product-media'}
                      // />
                      <div
                        className="shared-product-media"
                        style={{
                          backgroundImage: `url(${url})`,
                        }}
                      ></div>
                    )}
                  </div>
                )
              })}
            </Slider>
          ) : (
            <div className="share-post-design-grid-section">
              {product?.images?.map((prd, index) => {
                let url = prd?.src
                return (
                  <div
                    className="share-product-img-alignment"
                    key={index}
                    onClick={() => {
                      setSharedProductPreview(true)
                      setPreviewURL(url)
                    }}
                  >
                    {url?.includes('mp4') || url?.includes('mov') ? (
                      <video muted autoPlay loop style={{ objectFit: 'cover' }}>
                        <source src={url} />
                      </video>
                    ) : (
                      // <img src={url} alt="DemoProductImg" />
                      <LazyImage
                        image={{
                          src: url,
                          alt: 'Icon',
                        }}
                        objectFit={'cover'}
                        handleClickOnImage={() => handelImageSlider()}
                      />
                    )}
                  </div>
                )
              })}
            </div>
          )}
          <div className="share-post-details-alignment">
            <div>
              <Link href={`/product/${product?.slug}`}>
                <div
                  className="share-post-heading"
                  onClick={() => {
                    let filtredModal = getAllState?.allModalData?.filter(
                      (item) => item?.modal !== getAllState?.lastModalData?.modal,
                    )
                    dispatch(
                      fetchModalData({
                        ...getAllState,
                        allModalData: filtredModal,
                        lastModalData: getAllState?.lastModalData,
                      }),
                    )
                  }}
                >
                  <div className={styles.chatsliderdetails}>
                    <h5>
                      {/* {useDeviceDetect()?.isMobile
                      ? post?.product?.title?.length > 30
                        ? post?.product?.title.substring(0, 30) + '...'
                        : post?.product?.title
                      : post?.product?.title?.length > 68
                      ? post?.product?.title.substring(0, 68) + '...'
                      : post?.product?.title} */}
                      {product?.title}
                    </h5>
                  </div>
                </div>
              </Link>
              <div className="share-post-price-alignment">
                <h6>
                  {product?.dis_price && '$'}
                  {product?.dis_price}
                </h6>
                <span>
                  <del>
                    {product?.dis_listPrice && '$'}
                    {product?.dis_listPrice}
                  </del>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
