// import { gql } from '@apollo/client'

// export default gql`
//   query GetShipDetail($input: getShipDetailInput!) {
//     getShipDetail(input: $input) {
//       data {
//         rate_details {
//           amount {
//             currency
//             amount
//           }
//         }
//         delivery_days
//         estimated_delivery_date
//         ship_date
//         service_type
//         service_code
//         carrier_code
//         carrier_friendly_name
//       }
//       message
//       success
//       isShopifyProduct
//       ship_charge
//       is_free_delivery
//     }
//   }
// `

// export interface ShippingDimensions {
//   weightValue: string
//   weightUnit: string
//   length: number
//   width: number
//   height: number
//   unit: string | null
// }

// export interface PaymentInputProductRes {
//   productId: number
//   quantity: number
//   variant_id: number
//   store_id: number
//   shipping: ShippingDimensions
// }

// export interface GetShipDetailInput {
//   products: PaymentInputProductRes
// }

// -------------------------

// import { gql } from '@apollo/client'

// export default gql`
//   query GetShipDetail($input: getShipDetailInput!) {
//     getShipDetail(input: $input) {
//       data {
//         rate_details {
//           amount {
//             currency
//             amount
//           }
//         }
//         delivery_days
//         estimated_delivery_date
//         ship_date
//         service_type
//         service_code
//         carrier_code
//         carrier_friendly_name
//       }
//       message
//       success
//       isShopifyProduct
//       ship_charge
//       is_free_delivery
//     }
//   }
// `

// export interface ShippingDimensions {
//   weightValue: string
//   weightUnit: string
//   length: number
//   width: number
//   height: number
//   unit: string | null
// }

// export interface PaymentInputProductRes {
//   productId: number
//   quantity: number
//   store_id: number
//   shipping: ShippingDimensions
// }

// export interface GetShipDetailInput {
//   products: PaymentInputProductRes
// }

import { gql } from '@apollo/client'

export default gql`
  query GetShipDetail($input: getShipDetailInput!) {
    getShipDetail(input: $input) {
      data {
        rate_details {
          amount {
            currency
            amount
          }
        }
        delivery_days
        estimated_delivery_date
        ship_date
        service_type
        service_code
        carrier_code
        carrier_friendly_name
      }
      message
      success
      isShopifyProduct
      ship_charge
      is_free_delivery
      product_id
    }
  }
`


interface Amount {
  currency: string
  amount: number
}

interface RateDetails {
  amount: Amount
}

export interface ShippingResponse {
  rate_details: RateDetails[]
  delivery_days: number
  estimated_delivery_date: string
  ship_date: string
  service_type: string
  service_code: string
  carrier_code: string
  carrier_friendly_name: string
}

export interface PaymentInputProductRes {
  productId: number
  quantity: number
  store_id: number
  variant_id: number
}

export interface GetShipDetailInput {
  products: PaymentInputProductRes
}
