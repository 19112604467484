import React from 'react'
import styles from './ShowMoreButton.module.scss'

export default function ShowMoreButton({ onClick }) {
  return (
    <div className={styles.Showmorebuttonmain} onClick={onClick}>
      <button>
        <p>Show More</p>
      </button>
    </div>
  )
}
