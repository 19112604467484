import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import GET_CHAT_MEDIA from 'src/graphql/queries/messageQueries/getMedias'
import { useStateContext } from 'src/utils/state'
import client1 from 'src/utils/apolloClient'
import Slider from 'react-slick'
import styles from './preview.module.scss'
function MessageMediaModel({ MediaModal }) {
  const { selectedChat } = useStateContext()
  const getChatMedia = useQuery(GET_CHAT_MEDIA, {
    client: client1,
    variables: {
      conversationId: selectedChat?.chatData?.latestMessage?.conversationId,
    },
  })

  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  const handlePreview = (index) => {
    setCurrentImageIndex(index)
    setIsOpenPreviewModal(true)
    console.log('Preview modal opened')
  }

  const handleClose = () => {
    setIsOpenPreviewModal(false)
    console.log('Preview modal closed')
  }

  function SamplePreviewNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <div className={className} onClick={onClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <circle opacity="0.6" cx="20" cy="20" r="20" transform="rotate(180 20 20)" fill="white" />
          <path d="M21 25L17 20L21 15" stroke="#1E2432" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    )
  }

  function SamplePreviewPrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <div className={className} onClick={onClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <circle opacity="0.6" cx="20" cy="20" r="20" transform="rotate(180 20 20)" fill="white" />
          <path d="M21 25L17 20L21 15" stroke="#1E2432" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    )
  }
  const previewModalSetting = {

    // dotsClass: 'slick-dots slick-thumb',
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    verticalSwiping: true,
    swipeToSlide: true,
    centerPadding: '0px',

    prevArrow: <SamplePreviewPrevArrow />,
    nextArrow: <SamplePreviewNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          // vertical: false,
          // verticalSwiping: false,
        },
      },
    ],
  }
  useEffect(() => {
    if (MediaModal) {
      getChatMedia.refetch()
    }
  }, [MediaModal])

  return (
    <>
      <div className="top-menu-bg-alignment"></div>
      <div className="bottom-menu-bg-alignment"></div>
      <div className="media-modal-details-alignment">
        {getChatMedia?.data?.getMedias?.length === 0 && (
          <div className="no-media-found-alignment">
            <p>No media found</p>
          </div>
        )}
        <div className="media-modal-img-grid-alignment">
          {getChatMedia?.data?.getMedias?.map((img, index) => {
            return (
              <div className="media-modal-img-gridItem-alignment" key={index} onClick={() => handlePreview(index)}>
                <div className="media-modal-img">
                  <img src={img} alt="media img" />
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {isOpenPreviewModal && (
        <div className={styles.mediapriviewmodal}>
          <div className={styles.closebuttonalignment}>
            <div className={styles.closebuttondesign} onClick={handleClose}>
              X
            </div>
          </div>
          <div className={styles.centeralignmentmodal}>
            <div className={styles.mediapreviewimg}>
              <img src={getChatMedia?.data?.getMedias[currentImageIndex]} alt="media img" />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default MessageMediaModel
