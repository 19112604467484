import React from 'react'
import { useSelector } from 'react-redux'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAppDispatch } from 'src/store/store'
import { timeAgo, truncateString } from 'src/utils/helperFuncs'
import { useStateContext } from 'src/utils/state'

export default function UserMessageItem({ chatData }) {
  const { selectChat } = useStateContext()
  const screenWidth = window.innerWidth
  const dispatch = useAppDispatch()
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)

  return (
    <div
      className={`message-personal-profile-chat-alignment`}
      onClick={() => {
        {
          selectChat(chatData, 'private')
          if (screenWidth < 768) {
            dispatch(
              fetchMobileModalData({
                allModalData: [
                  ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                  {
                    modal: 'chatBoxModal',
                    isOpen: true,
                    id: 0,
                    transform: 42,
                    zIndex: 9999999,
                  },
                ],
                lastModalData: fetchMobileModal?.lastModalData,
              }),
            )
          }
        }
      }}
    >
      <div className="message-personal-profile-alignment">
        <div className="message-personal-profile-img-alignment">
          <img src={chatData?.user?.logo_image} alt="profile img" />
        </div>
        <div className="message-personal-profile-person-details-alignment">
          <h5>{chatData?.user?.firstName + ' ' + chatData?.user?.lastName}</h5>
          <p>{chatData?.latestMessage?.body !== undefined && truncateString(chatData?.latestMessage?.body, 35)}</p>
        </div>
      </div>
      <div className="message-time-alignment">
        <span>{timeAgo(new Date(parseInt(chatData?.latestMessage?.updatedAt)).getTime())}</span>
      </div>
    </div>
  )
}
