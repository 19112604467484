import { gql } from '@apollo/client'

const query = gql`
  mutation DELETEPOST($id: Int!) {
    deletePost(id: $id) {
      post {
        id
      }
    }
  }
`
export default query
