import { gql } from '@apollo/client'

const SELLER_AVAILABLE_AMOUNT = gql`
  query sellerAvailableAmount {
    sellerAvailableAmount {
      success
      message
      data {
        upcoming_amount
        final_amount
      }
    }
  }
`
export default SELLER_AVAILABLE_AMOUNT
// $limit: Int, $page: Int
//  limit: $limit, page: $page
