import { gql } from '@apollo/client'

const REMOVE_BOOKMARK = gql`
  mutation RemoveBookmark($id: Int) {
    removeBookmark(id: $id) {
      success
      message
      product_id
      id
    }
  }
`

export default REMOVE_BOOKMARK
