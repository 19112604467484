import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Slider from 'react-slick'
import BookmarkAddNewModal from 'src/components/BookmarkModal/BookmarkAddNewModal'
import BookmarkSlider from 'src/components/BookmarkSlider'
import ADD_PRODUCT_TO_COLLECTION from 'src/graphql/mutations/addProductToCollection'
import GET_ALL_BOOKMARK from 'src/graphql/queries/getAllBookmark'
import GET_ALL_BOOKMARK_COLLECTIONS from 'src/graphql/queries/getBookmarkCollections'
import { manageBookmark } from 'src/store/slices/manageBookmarkTab'
import { useAppDispatch } from 'src/store/store'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <img src="/assets/icon/new-slider-next-arrow.svg" alt="slider arrow" />
    </div>
  )
}

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props
//   return <div className={className} style={{ ...style, display: 'block', background: 'green' }} onClick={onClick} />
// }
export default function AddProductCollectionModal() {
  const dispatch = useAppDispatch()
  const [addCollectionWebModal, setAddCollectionWebModal] = useState(false)

  let width
  if (typeof window !== 'undefined') {
    width = window.innerWidth
  }
  const { loading, error, data, refetch } = useQuery(GET_ALL_BOOKMARK_COLLECTIONS)
  const bookmarkTabData = useSelector((state: any) => state?.manageBookmarkTabsSlice?.data)
  const [selectedCollection, setSelectedCollection] = useState(null)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const [isDesktop, setIsDesktop] = useState(width)

  const handleCloseModel = () => {
    dispatch(
      manageBookmark({
        modal: '',
        isOpen: false,
        id: {
          ...bookmarkTabData?.id,
          addProductToCollection: {
            ...bookmarkTabData?.id?.deleteCollection,
            isShowAddCollectionModel: false,
            // collectionId: getAllState?.lastModalData?.item?.id,
            productId: null,
          },
        },
      }),
    )
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5.1,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
    ],
  }

  return (
    <>
      <div className="add-product-modal-alignment">
        <div className="add-product-modal-wrapper">
          <div className='store-product-filter-click-drop-alignment-main' onClick={() => handleCloseModel()}>
            <div className="store-product-filter-click-drop-alignment">
              <p></p>
            </div>
          </div>
          <div className="add-product-modal-box-alignment">
            <div className="add-product-modal-heading">
              <h4>Add Product to</h4>
              <div className="close-icon-alignment" onClick={() => handleCloseModel()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M18 6L6 18" stroke="#7A7E88" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6 6L18 18" stroke="#7A7E88" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            <BookmarkSlider productId={bookmarkTabData?.id?.addProductToCollection.productId} />
          </div>
        </div>
      </div>
      {addCollectionWebModal && (
        <BookmarkAddNewModal
          setAddCollectionWebModal={setAddCollectionWebModal}
          isEditCollection={false}
          name=""
          collectionId={null}
        />
      )}
    </>
  )
}
