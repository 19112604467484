import React, { useEffect, useRef, useState } from 'react'
import styles from './AddProductProductStatus.module.scss'
import classNames from 'classnames'
import { useAppDispatch } from 'src/store/store'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
import { useSelector } from 'react-redux'
export default function AddProductProductStatus({ setProductData, productData }) {
  const [AddBProductStatus, setAddProductStatus] = useState(false)
  const dropdownRef: any = useRef()
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const dispatch = useAppDispatch()

  const handleDropdown = async (name) => {
    setProductData({ ...productData, status: name })

    await dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          mobileProductsData: {
            ...addProductDetailSlice?.id?.mobileProductsData,
            status: name,
          },
        },
      }),
    )
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setAddProductStatus(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [dropdownRef])

  return (
    <div className={styles.addProductStatusSection}>
      <div className={styles.addProductStatusBox}>
        <div className={styles.addProductStatusHeading}>
          <h4>Product Status</h4>
        </div>

        <div
          className={styles.addProductStatusInputAlignment}
          ref={dropdownRef}
          onClick={() => setAddProductStatus(!AddBProductStatus)}
        >
          <input type="text" placeholder="Select Status" value={productData?.status} />
          <div className={styles.addProductStatusDropdownIcon}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              className={AddBProductStatus ? styles.productStatusActiveArrow : ''}
            >
              <path d="M4 7L8 11L12 7" stroke="#636773" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>

          <div
            className={
              AddBProductStatus
                ? classNames(styles.productStatusDropdownBox, styles.openDropdownBox)
                : classNames(styles.productStatusDropdownBox, styles.closeDropdownBox)
            }
          >
            <div className={styles.selectedListProductStatus}>
              <p onClick={() => handleDropdown('Draft')}>Draft</p>
              <p onClick={() => handleDropdown('Published')}>Published</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
