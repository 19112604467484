import { Settings } from 'lucide-react';
import ShoppingIcon from 'public/assets/svg/ShoppingIcon';
import SocialIcon from 'public/assets/svg/SocialIcon';
import MessageIcon from 'public/assets/svg/MessageIcon';
import { useNotifications } from '@novu/notification-center';
import router from 'next/router';

const tabs = [
    { id: 'all', label: 'All', icon: null },
    { id: 'shopping', label: 'Shopping', icon: <ShoppingIcon /> },
    { id: 'social', label: 'Social', icon: <SocialIcon /> },
    { id: 'chat', label: 'Messaging', icon: <MessageIcon /> },
];
const NotificationsHeader = ({ setActiveTab, activeTab, setShowUnreadOnly, showUnreadOnly }) => {
    const { unreadCount, markAllNotificationsAsRead } = useNotifications();
    const handleMarkAllAsRead = async () => {
        try {
            await markAllNotificationsAsRead();
        } catch (error) {
            console.error('Error marking all as read:', error);
        }
    };
    return (
        <div>
            <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between p-4 sm:p-4">
                <div className="flex items-start space-x-2 mb-2 sm:mb-0">
                    <h2 className="text-base sm:text-lg font-semibold">Notification</h2>
                    {unreadCount > 0 && (
                        <span className="bg-red-500 text-white text-xs px-3 sm:px-3 rounded-full mt-1 sm:mt-2">
                            {unreadCount}
                        </span>
                    )}
                </div>
                <div className="flex items-center space-x-2 sm:space-x-4">
                    <button
                        onClick={handleMarkAllAsRead}
                        className="text-gray-500 hover:text-gray-900 text-xs sm:text-sm"
                    >
                        Mark all as read
                    </button>
                    <button
                        className="text-gray-600 hover:text-gray-800"
                        onClick={() => router.push('/summary/profile/?tab=4')}
                    >
                        <Settings size={20} className="sm:w-7 sm:h-7" />
                    </button>
                </div>
            </div>

            <div className="p-4 sm:p-4 pt-0 pb-3 sm:pb-5 border-b overflow-x-auto flex justify-between items-center">
                <div className="flex space-x-2 sm:space-x-3 min-w-max">
                    {tabs.map(tab => (
                        <button
                            key={tab.id}
                            onClick={() => setActiveTab(tab.id)}
                            className={`flex items-center space-x-1 px-3 sm:px-5 py-1 border rounded-lg text-xs sm:text-sm whitespace-nowrap ${activeTab === tab.id
                                ? 'bg-blue-100 text-[#3C4EA3] font-bold'
                                : 'bg-gray-100 text-[#636773] hover:bg-gray-200'
                                }`}
                        >
                            {tab.icon}
                            <span className='text-[12px] sm:text-[13px]'>{tab.label}</span>
                        </button>
                    ))}
                </div>
                <div className="">
                    <label className="flex items-center space-x-2">
                        <span className=" text-gray-600 font-normal text-xs sm:text-sm">Unread</span>
                        <input
                            type="checkbox"
                            checked={showUnreadOnly}
                            onChange={(e) => setShowUnreadOnly(e.target.checked)}
                            className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        />

                    </label>
                </div>
            </div>
        </div>
    );
};

export default NotificationsHeader;