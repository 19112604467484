import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useAuth } from 'src/utils/auth'
import CREATE_LIKE_MUTATION from 'src/graphql/mutations/createLike'
import DELETE_LIKE_MUTATION from 'src/graphql/mutations/deleteLike'
import GET_COMMENT_BY_ID from 'src/graphql/queries/getCommentById'
import CREATE_COMMENT_REPLY from 'src/graphql/mutations/createCommentReply'
import SEND_FRIEND_REQUEST from 'src/graphql/mutations/sendFriendRequest'
import DECLINE_FRIEND_REQUEST from 'src/graphql/mutations/declineFriendRequest'
import PopOverSection from './PopOver/PopOverSection'
import getImageUrl from 'src/utils/getImageUrl'
import { DEFAULT_USER_AVATAR } from 'src/config/constant'
// import MY_FEED_QUERY from 'src/graphql/queries/getMyFeed'
import { notification, Popover, Spin } from 'antd'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import NoComment from 'src/components/ProductDetail/NoComment'
import Link from 'next/link'
import { fetchAllFeedSliceData } from 'src/store/slices/fetchAllFeedSlice'
import client1 from 'src/utils/apolloClient'

export default function PostComments(props) {
  const {
    setShowMore,
    showMore,
    postId,
    backgroundColor,
    commentId,
    isClose,
    isBottonBorderVisible,
    isShowMore,
    isProductDetails,
  } = props
  const { loading, error, data, refetch } = useQuery(GET_COMMENT_BY_ID, {
    variables: { id: commentId?.id, comment_for: commentId?.comment_for },
  })

  // useEffect(() => {
  //   getComment()
  // }, [commentId])

  // const [loading, setLoading] = useState(true)
  // const [data, setData] = useState<any>()

  // const getComment = async () => {
  //   if (commentId?.id) {
  //     const { loading, data } = await client1.query({
  //       query: GET_COMMENT_BY_ID,
  //       variables: { id: commentId?.id, comment_for: commentId?.comment_for },
  //       fetchPolicy: 'network-only',
  //     })
  //     setLoading(loading)
  //     setData(data)
  //   }
  // }

  const dispatch = useAppDispatch()
  const { user }: any = useAuth()
  const [moreComments, setMoreComments] = useState(null)
  const [replyCommentToggle, setReplyCommentToggle] = useState(9999)
  const [replyCommentText, setReplyCommentText] = useState<any>('')
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)

  useEffect(() => {
    if (!isClose) {
      refetch()
    }
  }, [isClose])

  const [createLike] = useMutation(CREATE_LIKE_MUTATION, {
    onError: (error) => {
      console.log(error)
    },
  })

  const [deleteLike] = useMutation(DELETE_LIKE_MUTATION, {
    onError: (error) => {
      console.log(error)
    },
  })

  const [createCommentReply] = useMutation(CREATE_COMMENT_REPLY, {
    onError: (error) => {
      console.log(error)
    },
  })

  const replyComment = (id) => {
    if (id === replyCommentToggle) {
      setReplyCommentToggle(9999)
    } else {
      setReplyCommentToggle(id)
    }
  }

  const handleReplyComment = (comment) => {
    let comment_for = 'post'
    createCommentReply({
      variables: {
        input: { comment: replyCommentText, reply_id: comment?.id, comment_for: comment_for, parent_id: postId },
      },
      refetchQueries: [
        { query: GET_COMMENT_BY_ID, variables: { id: commentId?.id, comment_for: commentId?.comment_for } },
      ],
    }).then((response) => {
      if (response !== undefined) {
        setReplyCommentToggle(9999)
      }
    })
  }

  const handleLikePost = (comment, value) => {
    let liked
    if (value === 'commentreply') {
      liked = comment?.commentReplyLike?.find((like) => like?.user?.id === user.id)
    } else {
      liked = comment?.likes?.find((like) => like?.user?.id === user.id)
    }
    if (liked) {
      deleteLike({
        variables: { id: liked?.id, like_for: value },
        refetchQueries: [
          { query: GET_COMMENT_BY_ID, variables: { id: commentId?.id, comment_for: commentId?.comment_for } },
        ],
      })
      return
    }
    createLike({
      variables: { parent_id: comment.id, like_for: value },
      refetchQueries: [
        { query: GET_COMMENT_BY_ID, variables: { id: commentId?.id, comment_for: commentId?.comment_for } },
      ],
    })
  }

  const handleChangeReply = (e) => {
    setReplyCommentText(e.target.value)
  }

  const timeAgo = (prevDate) => {
    const diff = Number(new Date()) - prevDate
    const minute = 60 * 1000
    const hour = minute * 60
    const day = hour * 24
    const month = day * 30
    const year = day * 365
    // if (moment(prevDate).format('DD/MM/YYY') === moment(new Date()).format('DD/MM/YYY')) {
    switch (true) {
      case diff < minute:
        const seconds = Math.round(diff / 1000)
        return `${seconds} ${seconds > 1 ? 'seconds' : 'second'} ago`
      case diff < hour:
        return Math.round(diff / minute) + 'm ago'
      case diff < day:
        return Math.round(diff / hour) + 'h ago'
      case diff < month:
        return Math.round(diff / day) + 'day ago'
      case diff < year:
        return Math.round(diff / month) + 'month ago'
      case diff > year:
        return Math.round(diff / year) + 'y ago'
      default:
        return ''
    }
    // } else {
    //   return moment(prevDate).format('DD MMM [at] hh:mm A')
    // }
  }

  const [declineFriendRequest] = useMutation(DECLINE_FRIEND_REQUEST, {
    onCompleted: () => {
      dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
      // notification['success']({
      //   message: 'Remove Request successfully',
      // })
    },
    onError: (error) => {},
  })

  const [sendFriendRequest] = useMutation(SEND_FRIEND_REQUEST, {
    onCompleted: ({ sendFriendRequest }) => {
      dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const content_person = (comment) => (
    <PopOverSection
      profile={comment?.user?.profileUrl ? getImageUrl(comment?.user?.profileUrl) : DEFAULT_USER_AVATAR}
      profile_back={
        comment?.user?.profileCoverImageUrl ? getImageUrl(comment?.user?.profileCoverImageUrl) : DEFAULT_USER_AVATAR
      }
      name={comment.user?.firstName + ' ' + comment.user?.lastName}
      id={comment?.user?.userName}
      showDetailName1={'Followings'}
      showDetailName2={'Followers'}
      showDetail1={comment?.user?.followings?.length > 0 ? comment?.user?.followings : []}
      showDetail2={comment?.user?.followers?.length > 0 ? comment?.user?.followers : []}
      isFollow={comment?.user?.isActiveForFriendStatus && comment?.user?.isFriendForFriendStatus ? true : false}
      buttonHandler1={() =>
        sendFriendRequest({
          variables: {
            friend_id: comment?.user?.id,
            user_id: user?.id,
            isActive: true,
            isFriend: true,
          },
          // refetchQueries: [
          //   {
          //     query: MY_FEED_QUERY,
          //     variables: { id: parseInt(comment?.user?.id) },
          //   },
          // ],
        })
      }
      buttonHandler2={() =>
        declineFriendRequest({
          variables: { user_id: user.id, friend_id: comment?.user?.id },
          // refetchQueries: [
          //   {
          //     query: MY_FEED_QUERY,
          //     variables: { id: parseInt(comment?.user?.id) },
          //   },
          // ],
        })
      }
      isGroup={false}
    />
  )

  return (
    <div
      style={loading ? { overflow: 'hidden' } : { overflow: 'scroll' }}
      className={
        isClose
          ? 'open-comment post-comments-wrapper'
          : isBottonBorderVisible
          ? `close-comment-heigth border-hide ${
              data?.getCommentById?.data?.length === 0 ? 'no-data-comment-details' : ''
            }`
          : 'close-comment post-comments-wrapper'
      }
    >
      <div>
        {loading ? (
          <div className="social-chat-profile-bg-alignment items-center ">
            {/* <div className="flex justify-between items-center" style={{ height: '256px' }}>
              <div className="m-auto">
                <Spin size="large" tip="loading" />
              </div>
            </div> */}
          </div>
        ) : data?.getCommentById?.data?.length === 0 ? (
          // <div className="new-comment-no-data-alignment">
          //   <div>
          //     <img src="/assets/icon/no-comment-icon.svg" alt="no comment icon" />
          //   </div>
          //   <p>No comments.</p>
          // </div>

          <></>
        ) : (
          // <NoComment />
          data?.getCommentById?.data
            ?.filter((_, index) => (!showMore ? index < (!isShowMore ? 999999999999 : 2) : _))
            ?.map((comment, index) => {
              let profilePhoto =
                comment?.user?.id === user?.id
                  ? user?.logo_image
                  : 'https://bluejestic-media.s3.amazonaws.com/dummy-profile%20%281%29-1669122101773-764781589-1669182656605-3255156.jpg'
              return (
                <div className="social-chat-profile-bg-alignment" key={index}>
                  <div
                    className="social-chat-profile-alignment"
                    style={{
                      backgroundColor: !isProductDetails ? backgroundColor : 'white',
                      border: !isProductDetails ? '1px solid #f1f1f5' : 'none',
                    }}
                  >
                    <Popover content={content_person(comment)} placement="bottom">
                      <div className="social-profile-chat-img-alignment">
                        <img src={comment?.user?.logo_image ? comment?.user?.logo_image : profilePhoto} alt="DemoImg" />
                      </div>
                    </Popover>
                    <div>
                      <div className="social-chat-send-alignment">
                        <div>
                          <div className="social-chat-heading-alignment">
                            <h6
                              // onClick={() => {
                              //   dispatch(
                              //     fetchModalData({
                              //       allModalData: [
                              //         ...getAllState?.allModalData,
                              //         {
                              //           modal: 'userDetail',
                              //           isOpen: true,
                              //           id: comment?.user?.id,
                              //           modalFor: 'bigModal',
                              //         },
                              //       ],
                              //       lastModalData: getAllState?.lastModalData,
                              //     }),
                              //   )
                              // }}
                              className="hover:underline cursor-pointer"
                            >
                              <Link href={`/user/${comment?.user?.userName}`}>
                                {comment?.user?.firstName + ' ' + comment?.user?.lastName}
                              </Link>
                            </h6>
                            <span> {timeAgo(new Date(comment?.createdAt).getTime())}</span>
                          </div>
                          <div className="chat-text-alignment">
                            <p>{comment?.comment}</p>
                          </div>
                        </div>
                        {comment?.commentReply?.length > 0 && moreComments !== comment.id && (
                          <div className="see-replies-text-align" onClick={() => setMoreComments(comment.id)}>
                            <a>See replies </a>
                          </div>
                        )}
                        {moreComments === comment.id
                          ? comment?.commentReply?.map((commentReply, index) => {
                              return (
                                <div className="child-social-chat-alignment" key={index}>
                                  <div className="child-profile-img">
                                    <img
                                      src={
                                        commentReply?.user?.profileUrl
                                          ? commentReply?.user?.profileUrl
                                          : user?.logo_image
                                      }
                                      alt="DemoImg"
                                    />
                                  </div>
                                  <div className="child-profile-details-alignment">
                                    <div>
                                      <div className="child-social-chat-heading-alignment">
                                        <h6>{commentReply?.user?.firstName + ' ' + commentReply?.user?.lastName}</h6>
                                        <span> {timeAgo(new Date(commentReply?.createdAt).getTime())}</span>
                                      </div>
                                      <div className="child-chat-text-alignment">
                                        <p>{commentReply?.comment}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          : ''}
                        {moreComments === comment.id && (
                          <div className="see-replies-text-align" onClick={() => setMoreComments(9999999)}>
                            <a>Less replies</a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      !isProductDetails
                        ? 'social-comment-below-section revece-alignment'
                        : 'social-comment-below-section'
                    }
                  >
                    <div
                      className={!isProductDetails && 'replies-likes-main'}
                      style={{ display: 'flex', alignItems: 'center', gap: '12px', justifyContent: 'flex-start' }}
                    >
                      <div className="replies-section-align" onClick={() => replyComment(comment?.id)}>
                        <img src={'/assets/icon/feed/comment-reply.svg'} />
                      </div>
                      <span>{comment?.commentReply?.length} replies</span>
                    </div>
                    <div
                      className={!isProductDetails && 'replies-likes-main'}
                      style={{ display: 'flex', alignItems: 'center', gap: '12px', justifyContent: 'flex-start' }}
                    >
                      <div className="replies-section-align">
                        <svg
                          width="24"
                          height="21"
                          viewBox="0 0 24 21"
                          fill={
                            comment?.likes?.map((like) => like.user.id === user.id).length > 0 ? '#FF0000' : '#FFFFFF'
                          }
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => handleLikePost(comment, 'comment')}
                        >
                          <path
                            d="M7.1263 1.33594C3.83561 1.33594 1.16797 4.00359 1.16797 7.29427C1.16797 13.2526 8.20963 18.6693 12.0013 19.9293C15.793 18.6693 22.8346 13.2526 22.8346 7.29427C22.8346 4.00359 20.167 1.33594 16.8763 1.33594C14.8611 1.33594 13.0796 2.33634 12.0013 3.86758C10.923 2.33634 9.14146 1.33594 7.1263 1.33594Z"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            stroke={
                              comment?.likes?.map((like) => like.user.id === user.id).length > 0 ? '#FF0000' : '#556EE6'
                            }
                          />
                        </svg>
                      </div>
                      <span>{comment?.likes?.length} likes</span>
                    </div>
                  </div>
                  {replyCommentToggle === comment.id && (
                    <div className="input-align-reply-comment">
                      <input type="text" placeholder="write your comment" onChange={(e) => handleChangeReply(e)} />
                      {replyCommentText?.trim()?.length > 0 ? (
                        <div className="send-reply-comment" onClick={() => handleReplyComment(comment)}>
                          <button>Send</button>
                        </div>
                      ) : (
                        <div className="send-reply-comment opacity-div">
                          <button>Send</button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )
            })
        )}
        {!loading && data?.getCommentById?.data?.length > 2 && isShowMore && (
          <div className="social-chat-profile-alignment-text" onClick={() => setShowMore(!showMore)}>
            <a>{showMore ? 'Less Replies' : 'Show More Replies'}</a>
          </div>
        )}
      </div>
    </div>
  )
}
