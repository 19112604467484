import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import GET_CART_COUNT from 'src/graphql/queries/getCartCount'
import client1 from 'src/utils/apolloClient'
import GET_CART_ITEMS from 'src/graphql/queries/getCartItems'

interface getAllcartCount {
  loading: true | false
  error: {} | null
  called: true | false
  cartCount: number
  cartData: []
}

const initialState = {
  loading: false,
  error: null,
  data: 0,
  called: false,
  cartCount: 0,
  cartData: [],
} as getAllcartCount

export const getAllCartCountSliceData = createAsyncThunk('getCount/getAllCartCountSliceData', async () => {
  const { data } = await client1.query({
    query: GET_CART_COUNT,
    fetchPolicy: 'network-only',
  })
  return data?.getCartCount?.count
})

export const fetchCartData = createAsyncThunk('getCount/fetchCartData', async () => {
  const { data } = await client1.query({
    query: GET_CART_ITEMS,
    fetchPolicy: 'network-only',
  })
  return data?.getAllCart
})

const getAllcartCount = createSlice({
  name: 'getCount',
  initialState,
  reducers: {
    setCartData: (state: any, action) => {
      state.cartData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCartCountSliceData.pending, (state: any, action) => {
      state.loading = 'loading'
    })
    builder.addCase(getAllCartCountSliceData.fulfilled, (state: any, action) => {
      state.loading = 'success'
      state.cartCount = action?.payload
    })
    builder.addCase(getAllCartCountSliceData.rejected, (state: any, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })
    builder.addCase(fetchCartData.pending, (state: any, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchCartData.fulfilled, (state: any, action) => {
      state.loading = 'success'
      state.cartData = action?.payload
    })
    builder.addCase(fetchCartData.rejected, (state: any, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })
  },
})

export const { setCartData } = getAllcartCount.actions
export default getAllcartCount.reducer
