import { gql } from '@apollo/client'

const GET_CART_COUNT = gql`
  query GetCartCount {
    getCartCount {
      count
    }
  }
`
export default GET_CART_COUNT
