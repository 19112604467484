import { gql } from '@apollo/client'

export default gql`
  mutation SIGNUP(
    $firstName: String
    $lastName: String
    $email: String
    $password: String
    $social_type: String
    $access_token: String
  ) {
    signup(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        password: $password
        social_type: $social_type
        access_token: $access_token
      }
    ) {
      success
      message
      data {
        user {
          firstName
          lastName
          email
        }
        login {
          token
          seller_token
          isUserOnboardCompleted
          success
          message
          role
        }
      }
    }
  }
`
