import { gql } from '@apollo/client'

export default gql`
  query GET_USERS($id: Int) {
    getAllUsersData(id: $id) {
      id
      firstName
      lastName
      userName
      email
      phoneNumber
      gender
      bday
      jobTitle
      isFriendForFriendStatus
      isActiveForFriendStatus
      profileUrl
    }
  }
`
