import React, { useState } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export const addTransformParam = (src: string) => {
  return src && src.indexOf('?') === -1 ? '?t=w' : '&t=w'
}

const contentHubSizes = [576, 992, 1200, 1920, 2880]
const deviceSizes = [576, 992, 1440, 1920, 2880]

interface LazyStoreImagePropType {
  image?: any
  priority?: boolean
  sizes?: string
  className?: string
  width?: number
  height?: number
  disableSkeleton?: boolean
  objectFit?: any
  loading?: 'lazy' | 'eager' | undefined
  handleClickOnImage?: any
  imageClassName?: any
  borderRadius?: any
  position?: any
}

const LazyStoreImage: React.FC<LazyStoreImagePropType> = ({
  image,
  priority,
  sizes,
  width,
  height,
  loading = 'lazy',
  className,
  disableSkeleton,
  objectFit,
  handleClickOnImage,
  imageClassName,
  borderRadius,
  position,
}) => {
  const { src, alt } = image
  const [isLoaded, setIsLoaded] = useState(false)
  const handleImageLoad = () => {
    setIsLoaded(true)
  }

  return (
    <>
      {/* <div style={{ height: '100%', position: 'relative' }}> */}
        {/* !isLoaded && !disableSkeleton */}
        {!isLoaded && !disableSkeleton && (
          <SkeletonTheme baseColor="#d1d1d1" highlightColor="#FFFFFF">
            <Skeleton
              style={{
                width: width ? width : '100%',
                height: height ? height : '100%',
                borderRadius: borderRadius,
                position: position,
              }}
              className={className}
            />
          </SkeletonTheme>
        )}

        {image ? (
          <img
            alt={alt}
            width={width}
            height={height}
            onLoad={handleImageLoad}
            loading={loading}
            onClick={handleClickOnImage}
            src={`${src}${addTransformParam(src)}${deviceSizes[deviceSizes.length - 1]}`}
            srcSet={deviceSizes
              .map((deviceSize, index) => `${src}${addTransformParam(src)}${contentHubSizes[index]} ${deviceSize}w`)
              .join(', ')}
            className={imageClassName}
            sizes={sizes ? sizes : `(max-width: 768px) 100vw, 50vw`}
            style={{
              opacity: isLoaded ? 1 : 0,
              transition: 'opacity 1s',
              width: '100%',
              height: '100%',
              objectFit: objectFit,
              cursor: 'pointer',
              objectPosition: 'center',
            }}
          />
        ) : null}
      {/* </div> */}
    </>
  )
}

export default LazyStoreImage
