import React, { useEffect, useState } from 'react'
import CloseIcon from '../../../../public/assets/icon/x.svg'
import aroww from '../../../../public/assets/img/WomansCategory/aroww.png'
import IakeLogo from '../../../../public/assets/icon/IKEAlogo.svg'
// import MY_FEED_QUERY from 'src/graphql/queries/getMyFeed'
import GET_STORE_BY_NAME from 'src/graphql/queries/getStoreByName'
import { useMutation, useQuery } from '@apollo/client'
import { IStore } from 'src/types'
import NormalPost from 'src/components/Feed/NormalPost'
import NoDataFeedImg from '../../../../public/assets/img/no-following-img.png'
import DECLINE_FRIEND_REQUEST from 'src/graphql/mutations/declineFriendRequest'
import SEND_FRIEND_REQUEST from 'src/graphql/mutations/sendFriendRequest'
import GETGROUP from 'src/graphql/queries/getGroup'
import { useAuth } from 'src/utils/auth'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import Link from 'next/link'
import { fetchAllFeedSliceData } from 'src/store/slices/fetchAllFeedSlice'

export default function GroupModalSearch({ group }) {
  const { user } = useAuth()
  const dispatch = useAppDispatch()
  // const { loading, error, data, refetch } = useQuery(MY_FEED_QUERY)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const allPost = useSelector((state: any) => state?.feedData?.allPost)

  useEffect(() => {
    dispatch(fetchAllFeedSliceData({ page, limit, prevPost: allPost }))
  }, [])

  const [declineFriendRequest] = useMutation(DECLINE_FRIEND_REQUEST, {
    onCompleted: () => {},
    onError: (error) => {},
  })

  const [sendFriendRequest] = useMutation(SEND_FRIEND_REQUEST, {
    onCompleted: ({ sendFriendRequest }) => {},
    onError: (error) => {
      console.log(error)
    },
  })

  const handleDeclineRequest = (userId) => {
    declineFriendRequest({
      variables: { user_id: user.id, friend_id: parseInt(userId) },
      refetchQueries: [{ query: GETGROUP, variables: { id: parseInt(group?.id) } }],
    })
  }

  const handleSendRequest = (userId) => {
    sendFriendRequest({
      variables: {
        friend_id: parseInt(userId),
        user_id: user?.id,
        isActive: true,
        isFriend: true,
      },
      refetchQueries: [{ query: GETGROUP, variables: { id: parseInt(group?.id) } }],
    })
  }

  return (
    <div className="mobile-view-group-search-alignment">
      <div className="mobile-view-group-search-input-alignment">
        <input type="search" placeholder="Search anything on this club..." />
        <div className="close-icon-alignment">
          <img src={CloseIcon.src} alt="CloseIcon" />
        </div>
      </div>
      <div className="mobile-view-group-result-details-alignment">
        <p>0 result found</p>
      </div>
      <div className="mobile-view-group-details-alignment">
        <div className="mobile-view-group-details-heading-alignment">
          <h4>Member </h4>
          <div className="number-box-alignment">
            <span>{group?.members?.length}</span>
          </div>
        </div>
      </div>
      <div className="mobile-view-group-search-member-details-alignment">
        <div className="mobile-view-group-search-member-grid-alignment">
          {group?.members
            ?.filter((fil) => fil?.id !== user?.id)
            ?.map((member, index) => {
              let name = member?.firstName + ' ' + member?.lastName
              let isFollow = member?.isActiveForFriendStatus && member?.isFriendForFriendStatus ? true : false
              return (
                <div className="book-appliance-trending-store-gridItem-alignment" key={index}>
                  <div className="book-appliance-trending-store-box-alignment">
                    <div className="book-appliance-details-img-alignment">
                      <img src={member?.profileUrl} alt="IakeLogo" />
                    </div>
                    <div className="book-appliance-child-details-alignment">
                      <Link href={`/user/${member?.userName}`}>
                        <h5
                        // onClick={() => {
                        //   dispatch(
                        //     fetchModalData({
                        //       allModalData: [
                        //         ...getAllState?.allModalData,
                        //         { modal: 'userDetail', isOpen: true, id: member?.id, modalFor: 'bigModal' },
                        //       ],
                        //       lastModalData: getAllState?.lastModalData,
                        //     }),
                        //   )
                        // }}
                        >
                          {name?.length > 15 ? name.substring(0, 15) + '...' : name}
                        </h5>
                      </Link>
                      <span>Model</span>
                      <div>
                        <div className="book-appliance-product">
                          <span>{member?.followers?.length} Followers </span>
                        </div>
                      </div>
                      {isFollow ? (
                        <div className="follow-button-alignment" onClick={() => handleDeclineRequest(member?.id)}>
                          <button className="following">Following</button>
                        </div>
                      ) : (
                        <div className="follow-button-alignment" onClick={() => handleSendRequest(member?.id)}>
                          <button>Follow</button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
      <div className="mobile-view-group-details-alignment">
        <div className="mobile-view-group-details-heading-alignment">
          <h4>Post</h4>
          <div className="number-box-alignment">
            <span>{allPost?.length}</span>
          </div>
        </div>
      </div>
      <div>
        {allPost?.map((post, i) => (
          <NormalPost
            index={i}
            post={post}
            store={{}}
            image={true}
            type="post"
            color={false}
            feedSize={true}
            isUserPostPreviewModal={true}
          />
        ))}
      </div>

      {/* No data Details Section  */}

      {/* <div className="mobile-view-no-search-data">
        <div>
          <img src={NoDataFeedImg.src} alt="NoDataFeedImg" />
        </div>
        <div className="mobile-view-no-search-data-text-alignment">
          <span>
            There are no recent <br /> seach.
          </span>
        </div>
      </div> */}
    </div>
  )
}
