import React from 'react'
import { useSelector } from 'react-redux'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAppDispatch } from 'src/store/store'

export default function AddMessageModal() {
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const dispatch = useAppDispatch()
  return (
    <div>
      {' '}
      <div className="message-modal-body-details-alignment">
        <ul>
          <li
            className="active-message-option"
            onClick={() =>
              dispatch(
                fetchMobileModalData({
                  allModalData: [
                    ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                    {
                      modal: 'MobileViewPersonalAccount',
                      isOpen: true,
                      id: 0,
                      transform: 72,
                      zIndex: 9999999,
                    },
                  ],
                  lastModalData: fetchMobileModal?.lastModalData,
                }),
              )
            }
          >
            Personal Message
          </li>
          <li>Group Message</li>
        </ul>
      </div>
    </div>
  )
}
