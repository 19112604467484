import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import UserFeed from './UserFeed'
import UserHeader from './UserHeader'
import UserMedia from './UserMedia'
// import MY_FEED_QUERY from 'src/graphql/queries/getMyFeed'
import useDeviceDetect from 'src/utils/useDeviceDetect'
import { fetchAllFeedSliceData } from 'src/store/slices/fetchAllFeedSlice'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'

export default function UserDataSection(props) {
  const { userId, editPostHandler, setIsVisible, isVisible, userData } = props
  const [tab, setTab] = useState({ tab: 'feed', isWeb: 'true' })

  // const { loading, error, data, refetch } = useQuery(MY_FEED_QUERY, {
  //   variables: { user_id: userId, page: 1, limit: 10 },
  // })
  const dispatch = useAppDispatch()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const allPost = useSelector((state: any) => state?.feedData?.allPost)

  useEffect(() => {
    dispatch(fetchAllFeedSliceData({ page, limit, prevPost: allPost }))
  }, [])
  const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrollY(window.pageYOffset)
    })
  }, [scrollY])

  // useEffect(() => {
  //   refetch()
  // }, [])

  return (
    <>
      <div className="social-gridItem-alignment">
        <UserHeader setTab={setTab} tab={tab} userData={userData} />
        {tab?.tab === 'media' && <UserMedia allPost={allPost} editPostHandler={editPostHandler} />}
        {tab?.tab === 'feed' && <UserFeed allPost={allPost} editPostHandler={editPostHandler} tab={tab} />}
      </div>
    </>
  )
}
