import React from 'react'
import styles from './MobileViewNewArrivalProduct.module.scss'
import NewArrivalProduct from '../NewArrivalProduct'

export default function MobileViewNewArrivalProduct() {
  return (
    <div className={styles.mobileViewNewArrivalProductDetailsAlignment}>
      <div className={styles.mobileViewHeading}>
        <h6>New Arrivals</h6>
      </div>

      <div className={styles.mobileViewArrivalBodyAlignment}>
        <div className={styles.mobileViewArrivalGrid}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => {
            return (
              <React.Fragment key={index}>
                <NewArrivalProduct product={{}} />
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </div>
  )
}
