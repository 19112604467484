import React, { useState } from 'react'
import CustomerTraction from 'src/components/ProductDetail/CustomerTraction'
import Comments from './Comments'
import GalleryForMobile from './GalleryForMobile/GalleryForMobile'
import RateReviewModalMobile from './RateReviewModalMobile'
import Shipping from './Shipping/Shipping'
import TabSectionModal from './TabSectionMainForMobile'
import ProductDescription from './ProductDescription'

export default function TabSectionForMobile(props) {
  const { openTraction, setOpenTraction, tabArray, product, productMobileTab, setProductMobileTab } = props
  const { getSingleProduct } = props

  const starIcon = (
    <svg width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.06414 1.92489C9.79234 0.468488 11.8707 0.468489 12.5989 1.92489L14.5082 5.7434C14.8036 6.33433 15.3749 6.7385 16.0304 6.82044L19.5455 7.25983C21.1257 7.45735 21.8351 9.34718 20.7752 10.5357L18.2132 13.4086C17.8139 13.8564 17.6396 14.4613 17.7396 15.0529L18.4825 19.4509C18.7519 21.0453 17.0967 22.2706 15.6505 21.5475L11.7152 19.5798C11.1589 19.3017 10.5041 19.3017 9.94783 19.5798L6.01889 21.5443C4.57147 22.268 2.91541 21.0402 3.18731 19.4449L3.93537 15.0559C4.03648 14.4626 3.86173 13.8556 3.46064 13.4069L0.894897 10.5368C-0.167149 9.34874 0.541729 7.4568 2.12298 7.25915L5.6326 6.82044C6.28818 6.7385 6.85942 6.33433 7.15489 5.7434L9.06414 1.92489Z"
        fill="#FF7D23"
      />
    </svg>
  )

  return (
    <div className="tab-section-mobile-main">
      <div className="tab-container-main">
        <div className="tab-container-flex-alignment">
          <ul>
            <li
              className={productMobileTab === 1 ? 'product-details-active' : ''}
              onClick={() => setProductMobileTab(1)}
            >
              Description
            </li>
            <li
              className={productMobileTab === 3 ? 'product-details-active' : ''}
              onClick={() => setProductMobileTab(3)}
            >
              Rate & Review
            </li>
            {/* <li
              className={productMobileTab === 2 ? 'product-details-active' : ''}
              onClick={() => setProductMobileTab(2)}
            >
              Gallery
            </li> */}
            <li
              className={productMobileTab === 6 ? 'product-details-active' : ''}
              onClick={() => setProductMobileTab(6)}
            >
              Comments
            </li>
            <li
              className={productMobileTab === 4 ? 'product-details-active' : ''}
              onClick={() => setProductMobileTab(4)}
            >
              Traction
            </li>
            <li
              className={productMobileTab === 5 ? 'product-details-active' : ''}
              onClick={() => setProductMobileTab(5)}
            >
              Shipping
            </li>
          </ul>
        </div>

        <div className="product-details-all-contain-alignment">
          {productMobileTab === 1 && <ProductDescription description={getSingleProduct?.description} />}
          {productMobileTab === 2 && (
            <div className="modal-container-main">
              <GalleryForMobile />
            </div>
          )}
          {productMobileTab === 3 && <RateReviewModalMobile product={getSingleProduct} />}
          {productMobileTab === 4 && <CustomerTraction getSingleProduct={getSingleProduct}/>}
          {productMobileTab === 5 && <Shipping storeId={getSingleProduct?.storeId} />}
          {productMobileTab === 6 && (
            <Comments
              description={getSingleProduct?.description}
              product={getSingleProduct}
              comments={getSingleProduct?.comment}
              height={250}
            />
          )}
        </div>
      </div>
    </div>
  )
}
