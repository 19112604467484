import { useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import styles from './GroupMember.module.scss'
import { useAuth } from 'src/utils/auth'
import DECLINE_FRIEND_REQUEST from 'src/graphql/mutations/declineFriendRequest'
import SEND_FRIEND_REQUEST from 'src/graphql/mutations/sendFriendRequest'
import GETGROUP from 'src/graphql/queries/getGroup'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import UserIcon from '../../../../public/assets/icon/user-plus.svg'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import Link from 'next/link'
import { useRouter } from 'next/router'
import REMOVE_GROUP_MEMBERS from 'src/graphql/mutations/removeGroupMember'
import { clubMembersInvite } from 'src/store/slices/inviteClubMebersSlice'
import { fetchClubMembers } from 'src/store/slices/fetchAllClubsSlice'

export default function GroupMember(props) {
  const { group, setIsOpenDeleteModel, isOpenDeleteModel, setSelectedUserForRemove, selectedUserForRemove } = props
  const { user } = useAuth()
  const dispatch = useAppDispatch()
  const allManageClub = useSelector((state: any) => state?.clubData?.clubMembers)
  const router = useRouter()
  const isAdmin = allManageClub?.find((data) => data?.id === user?.id)?.isAdmin
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  const sortedMembers = [...(allManageClub || [])].sort((a, b) => (a.id === user?.id ? -1 : b.id === user?.id ? 1 : 0))

  useEffect(() => {
    dispatch(fetchClubMembers(router?.query?.id))
  }, [])

  const [declineFriendRequest] = useMutation(DECLINE_FRIEND_REQUEST, {
    onCompleted: () => {
      dispatch(fetchClubMembers(router?.query?.id))
    },
    onError: (error) => {},
  })

  const [sendFriendRequest] = useMutation(SEND_FRIEND_REQUEST, {
    onCompleted: ({ sendFriendRequest }) => {
      dispatch(fetchClubMembers(router?.query?.id))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const handleDeclineRequest = (userId) => {
    if (user) {
      declineFriendRequest({
        variables: { user_id: user.id, friend_id: parseInt(userId) },
        refetchQueries: [{ query: GETGROUP, variables: { slug: router?.query?.id } }],
      })
    } else {
      handleRedirect()
    }
  }

  const handleSendRequest = (userId) => {
    if (user) {
      sendFriendRequest({
        variables: {
          friend_id: parseInt(userId),
          user_id: user?.id,
          isActive: true,
          isFriend: true,
        },
        refetchQueries: [{ query: GETGROUP, variables: { slug: router?.query?.id } }],
      })
    } else {
      handleRedirect()
    }
  }

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const deletePostHandler = (memberData) => {
    setIsOpenDeleteModel(false)

    removeGroupMember({
      variables: {
        memberId: [memberData?.id],
        clubId: group?.id,
      },
      refetchQueries: [{ query: GETGROUP, variables: { slug: router?.query?.id } }],
    })
  }

  const [removeGroupMember] = useMutation(REMOVE_GROUP_MEMBERS, {
    onCompleted: (res) => {},
    onError: (error) => {
      console.log(error)
    },
  })

  const handleInviteFriends = () => {
    if (user) {
      dispatch(
        clubMembersInvite({
          data: {
            showModel: true,
          },
        }),
      )
    } else {
      handleRedirect()
    }
  }

  const handleCancelDelete = () => {
    setIsOpenDeleteModel(false)
    setSelectedUserForRemove({})
  }

  const handleRemove = (member) => {
    if (user && isAdmin) {
      setIsOpenDeleteModel(true), setSelectedUserForRemove(member)
    } else {
      handleRedirect()
    }
  }

  return (
    <div className={styles.memberDetailsAlignment}>
      <div className={styles.headingAlignment}>
        <div className={styles.headingLeftSideAlignment}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M12 5.50006C12.9283 5.50006 13.8185 5.86881 14.4749 6.52519C15.1313 7.18156 15.5 8.0718 15.5 9.00006C15.5 9.92832 15.1313 10.8186 14.4749 11.4749C13.8185 12.1313 12.9283 12.5001 12 12.5001C11.0717 12.5001 10.1815 12.1313 9.52513 11.4749C8.86875 10.8186 8.5 9.92832 8.5 9.00006C8.5 8.0718 8.86875 7.18156 9.52513 6.52519C10.1815 5.86881 11.0717 5.50006 12 5.50006ZM5 8.00006C5.56 8.00006 6.08 8.15006 6.53 8.42006C6.38 9.85006 6.8 11.2701 7.66 12.3801C7.16 13.3401 6.16 14.0001 5 14.0001C4.20435 14.0001 3.44129 13.684 2.87868 13.1214C2.31607 12.5588 2 11.7957 2 11.0001C2 10.2044 2.31607 9.44135 2.87868 8.87874C3.44129 8.31613 4.20435 8.00006 5 8.00006ZM19 8.00006C19.7956 8.00006 20.5587 8.31613 21.1213 8.87874C21.6839 9.44135 22 10.2044 22 11.0001C22 11.7957 21.6839 12.5588 21.1213 13.1214C20.5587 13.684 19.7956 14.0001 19 14.0001C17.84 14.0001 16.84 13.3401 16.34 12.3801C17.2119 11.2545 17.6166 9.83626 17.47 8.42006C17.92 8.15006 18.44 8.00006 19 8.00006ZM5.5 18.2501C5.5 16.1801 8.41 14.5001 12 14.5001C15.59 14.5001 18.5 16.1801 18.5 18.2501V20.0001H5.5V18.2501ZM0 20.0001V18.5001C0 17.1101 1.89 15.9401 4.45 15.6001C3.86 16.2801 3.5 17.2201 3.5 18.2501V20.0001H0ZM24 20.0001H20.5V18.2501C20.5 17.2201 20.14 16.2801 19.55 15.6001C22.11 15.9401 24 17.1101 24 18.5001V20.0001Z"
              fill="#1E2432"
            />
          </svg>

          <div className={styles.headingDetailsFlex}>
            <h3>Member </h3>
            <p></p>
            <span>{allManageClub?.length}</span>
          </div>
        </div>

        <div className={styles.hedingRightSideAlignment}>
          <div className={styles.searchIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path
                d="M8.25 14.25C11.5637 14.25 14.25 11.5637 14.25 8.25C14.25 4.93629 11.5637 2.25 8.25 2.25C4.93629 2.25 2.25 4.93629 2.25 8.25C2.25 11.5637 4.93629 14.25 8.25 14.25Z"
                stroke="#7A7E88"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.7498 15.7498L12.4873 12.4873"
                stroke="#7A7E88"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          {isAdmin && (
            <div className={styles.inviteAlignment} onClick={() => handleInviteFriends()}>
              <div className={styles.inviteIcon}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M10.667 14V12.6667C10.667 11.9594 10.386 11.2811 9.88594 10.781C9.38585 10.281 8.70757 10 8.00033 10H3.33366C2.62641 10 1.94814 10.281 1.44804 10.781C0.947944 11.2811 0.666992 11.9594 0.666992 12.6667V14"
                    stroke="#556EE6"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.66667 7.33333C7.13943 7.33333 8.33333 6.13943 8.33333 4.66667C8.33333 3.19391 7.13943 2 5.66667 2C4.19391 2 3 3.19391 3 4.66667C3 6.13943 4.19391 7.33333 5.66667 7.33333Z"
                    stroke="#556EE6"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.333 5.33325V9.33325"
                    stroke="#556EE6"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.333 7.33325H11.333"
                    stroke="#556EE6"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <p>Invite</p>
            </div>
          )}
        </div>
      </div>
      <div className={styles.mobileHeaderAlignment}>
        <div className={styles.mobileHeaderNameAlignment}>
          <h6>Member</h6>
          <div className={styles.totalMemberAlignment}>
            <span>{allManageClub?.length}</span>
          </div>
        </div>
        <div className={styles.mobileDropdwnAlignment}>
          <img src={UserIcon.src} alt="UserIcon" />
          <span>Invite</span>
        </div>
      </div>
      <div className={styles.memberAllDetailsAlignment}>
        {/* {Boolean(group?.admins?.length) && (
          <div className="member-details-box-alignment owner">
            <div className="member-profile-alignment">
              <div className="profile-alignment">
                <img src={group?.admins[0]?.profileUrl} alt="MemberImg" />
              </div>
              <div className={styles.profileNameAlignment}>
                <h4
                  className="hover:underline cursor-pointer"
                  onClick={() => {
                    dispatch(
                      fetchModalData({
                        allModalData: [
                          ...getAllState?.allModalData,
                          { modal: 'userDetail', isOpen: true, id: group?.admins[0]?.id, modalFor: 'bigModal' },
                        ],
                        lastModalData: getAllState?.lastModalData,
                      }),
                    )
                  }}
                >
                  {group?.admins[0]?.firstName + ' ' + group?.admins[0]?.lastName}
                </h4>
                <div className={styles.childDetailsAlignment}>
                  <span>{group?.admins[0]?.followers?.length} Followerssss</span>
                  <p></p>
                  <span>{group?.admins[0]?.followings?.length} Following</span>
                </div>
              </div>
            </div>
            {group?.admins[0]?.id === user?.id ? (
              <div className={styles.followButtonAlignment}>
                <button>You</button>
              </div>
            ) : group?.admins[0]?.isActiveForFriendStatus && group?.admins[0]?.isFriendForFriendStatus ? (
              <div className={styles.followButtonAlignment} onClick={() => handleDeclineRequest(group?.admins[0]?.id)}>
                <button className="following">Following</button>
              </div>
            ) : (
              <div className={styles.followButtonAlignment} onClick={() => handleSendRequest(group?.admins[0]?.id)}>
                <button>Follow</button>
              </div>
            )}
          </div>
        )} */}

        {sortedMembers?.map((member, index) => {
          let isFollow = member?.isActiveForFriendStatus && member?.isFriendForFriendStatus ? true : false
          return (
            <>
              {selectedUserForRemove?.id === member?.id && !isOpenDeleteModel ? (
                <>
                  <div className={styles.memberDetailsBoxAlignment} key={index}>
                    <div className={styles.removeMemberBoxAlignment}>
                      <p>Removed</p>

                      <div className={styles.removeIconAlignment}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M3 6H5H21"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 14L11.5 15.5L14.5 12.5"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className={styles.mobileViewFlexAlignment}>
                  <div className={styles.memberDetailsBoxAlignment} key={index}>
                    <div className={styles.memberProfileAlignment}>
                      <div className={styles.profileAlignment}>
                        <img src={member?.logo_image} alt="MemberImg" />
                      </div>
                      <div className={styles.profileNameAlignment}>
                        <Link href={`/user/${member?.userName}`}>
                          <h4 className="hover:underline cursor-pointer">
                            {member?.firstName + ' ' + member?.lastName + ''} {member?.isAdmin && <span>(admin)</span>}
                          </h4>
                        </Link>
                        <div className={styles.childDetailsAlignment}>
                          <span>{member?.followers?.length} Followers</span>
                          <p></p>
                          <span>{member?.followings?.length} Following</span>
                        </div>
                      </div>
                    </div>
                    {member?.id === user?.id ? (
                      <div className={styles.followButtonAlignment}>
                        {member?.isAdmin && (
                          <div className={styles.followButtonAlignment}>
                            <div className={styles.adminDetails}>
                              <span>Admin</span>
                            </div>
                          </div>
                        )}
                        <button>You</button>
                      </div>
                    ) : isFollow ? (
                      <>
                        <div className={styles.followButtonAlignment}>
                          {group?.isExist && isAdmin && (
                            <div
                              className={styles.adminDetails}
                              onClick={() => {
                                handleRemove(member)
                              }}
                            >
                              <span className={styles.remove}>Remove</span>
                            </div>
                          )}
                          <button className={styles.following} onClick={() => handleDeclineRequest(member?.id)}>
                            Following
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        {group?.isExist && (
                          <div className={styles.followButtonAlignment}>
                            {isAdmin && (
                              <div
                                className={styles.adminDetails}
                                onClick={() => {
                                  handleRemove(member)
                                }}
                              >
                                <span className={styles.remove}>Remove</span>
                              </div>
                            )}
                            <button onClick={() => handleSendRequest(member?.id)}>+ Follow</button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {member?.id !== user?.id && isAdmin && (
                    <div
                      className={styles.memberDeleteBox}
                      onClick={() => {
                        setIsOpenDeleteModel(true), setSelectedUserForRemove(member)
                      }}
                    >
                      <div className={styles.memberIcon}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M3 6H5H21"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 11V17"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M14 11V17"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )
        })}

        {/* <Modal
          title="Delete Modal"
          open={isOpenDeleteModel}
          onOk={() => deletePostHandler(selectedUserForRemove)}
          onCancel={() => handleCancelDelete()}
        >
          <p>Are you sure you want to delete this card?</p>
        </Modal> */}
      </div>
    </div>
  )
}
