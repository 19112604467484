import { gql } from '@apollo/client'

const GET_UPCOMING_LIVESTREAM = gql`
  query GetMeetingStreams($store_id: Int, $type: [String!]) {
    getMeetingStreams(store_id: $store_id, type: $type) {
      message
      success
      data {
      liveStreams {
        meeting_id
        uuid
        title
        status
        date
        time
        media
        final_date
        isQuick
        store_id
        store_name
        store_logo
        slug
        duration
        participants_count
        isLive
        reaction_count
        currentUserLiked
      }
      pastStreams {
        meeting_id
        uuid
        title
        status
        date
        time
        media
        final_date
        isQuick
        store_id
        store_name
        store_logo
        slug
        duration
        participants_count
        isLive
        reaction_count
        currentUserLiked
      }
      upcomingStreams {
        meeting_id
        uuid
        title
        status
        date
        time
        media
        final_date
        isQuick
        store_id
        store_name
        store_logo
        slug
        duration
        participants_count
        isLive
        reaction_count
        currentUserLiked
      }
     }
    }
  }
`
export default GET_UPCOMING_LIVESTREAM
