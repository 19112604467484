import React from 'react';
import NotificationFooter from '../NotificationFooter';
import UserNotificationsProfile from '../NoticationIcon';
import router from 'next/router';

const ClubComments: React.FC<any> = ({ notification, setOpen }: any) => {
    const payload = notification?.payload;
    const imagePayload = {
        name: payload?.userName,
        image: payload?.userImg,
    }
    return (
        <div className="py-4 px-1 sm:px-3 md:px-6">
            <div className="flex items-start space-x-5 p-4 hover:bg-gray-50">
                {/* Avatar */}
                <UserNotificationsProfile imagePayload={imagePayload} />

                <div className="w-full">
                    <div className=" items-center">
                        <div className=' items-center'>
                            <span className="text-sm font-semibold text-gray-900 my-0 mr-1">{payload?.userName}</span>
                            <span>{payload?.message}</span>
                        </div>
                        <a onClick={() => router.push(`/club/${payload?.clubSlug}`)} className='hover:underline text-[#556EE6] hover:text-[#556EE6] mr-2 font-semibold '>{payload?.clubName}</a>
                    </div>
                    <NotificationFooter notification={notification} />
                </div>
            </div>

        </div>
    );
};

export default ClubComments;