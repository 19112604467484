import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface SalesData {
  selectedFilter: string;
  key: string;
  totalRevenue?: number;
  totalOrders?: number;
  averageOrder?: number;
  orderFulfillmentRate?: number;
  conversionRate?: number;
  conversionTrend?: number;
  totalVisitors?: number;
  totalPurchases?: number;
  abandonedCarts?: number;
  abandonedCartsTrend?: number;
  abandonmentRate?: number;
  topSales?: any[];
  topRegions?: any[];
}

interface TrafficEngagementFilterState {
  loading: boolean;
  error: any | null;
  called: boolean;
  data: SalesData;
}

const initialState = {
  loading: false,
  error: null,
  data: { 
    selectedFilter: 'Today', 
    key: '',
    totalRevenue: 0,
    totalOrders: 0,
    averageOrder: 0,
    orderFulfillmentRate: 0,
    conversionRate: 0,
    conversionTrend: 0,
    totalVisitors: 0,
    totalPurchases: 0,
    abandonedCarts: 0,
    abandonedCartsTrend: 0,
    abandonmentRate: 0,
    topSales: [],
    topRegions: []
  },
  called: false,
} as TrafficEngagementFilterState

const getMockData = (filter: string): Partial<SalesData> => {
  // Mock data based on filter
  switch (filter) {
    case 'Today':
      return {
        totalRevenue: 12500,
        totalOrders: 125,
        averageOrder: 100,
        orderFulfillmentRate: 95,
        conversionRate: 3.2,
        conversionTrend: 0.8,
        totalVisitors: 3906,
        totalPurchases: 125,
        abandonedCarts: 45,
        abandonedCartsTrend: -5,
        abandonmentRate: 26.5,
      }
    case 'This month':
      return {
        totalRevenue: 375000,
        totalOrders: 3750,
        averageOrder: 100,
        orderFulfillmentRate: 94,
        conversionRate: 3.5,
        conversionTrend: 1.2,
        totalVisitors: 107143,
        totalPurchases: 3750,
        abandonedCarts: 1250,
        abandonedCartsTrend: -3,
        abandonmentRate: 25,
      }
    default:
      return {
        totalRevenue: 12500,
        totalOrders: 125,
        averageOrder: 100,
        orderFulfillmentRate: 95,
        conversionRate: 3.2,
        conversionTrend: 0.8,
        totalVisitors: 3906,
        totalPurchases: 125,
        abandonedCarts: 45,
        abandonedCartsTrend: -5,
        abandonmentRate: 26.5,
      }
  }
}

export const fetchTrafficEngagementFilterSliceData = createAsyncThunk(
  'fetchTrafficEngagementFilterData/fetchTrafficEngagementFilterSliceData',
  async (data: { selectedFilter: string; key: string }) => {
    // Simulate API call delay
    await new Promise(resolve => setTimeout(resolve, 500));
    
    return {
      ...data,
      ...getMockData(data.selectedFilter)
    }
  },
)

const trafficEngagementFilterSlice = createSlice({
  name: 'fetchTrafficEngagementFilterData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTrafficEngagementFilterSliceData.pending, (state) => {
      state.loading = true
      state.error = null
      state.called = true
    })
    builder.addCase(fetchTrafficEngagementFilterSliceData.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
      state.called = true
    })
    builder.addCase(fetchTrafficEngagementFilterSliceData.rejected, (state, action) => {
      state.loading = false
      state.called = false
      state.error = action.error
      console.error('Error fetching sales data:', action.error)
    })
  },
})

export default trafficEngagementFilterSlice.reducer
