import { Checkbox, Radio } from 'antd'
import React, { useEffect, useState } from 'react'
import DummyImg from '../../../../../../public/assets/img/productdemoimg.png'
import PinIcon from '../../../../../../public/assets/icon/feather.svg'
import { useQuery } from '@apollo/client'
import { useAuth } from 'src/utils/auth'
import GET_MY_GROUP from 'src/graphql/queries/getMyGroup'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { fetchProductShareOptions } from 'src/store/slices/productShareOptionsSlice'
import { fetchJoinedClubsData } from 'src/store/slices/fetchAllClubsSlice'

export default function SharewithClubs(props) {
  const { setIsShareWithFriend, groupId, isShareWithFriend, setOpenMobileDropdown } = props
  const { user } = useAuth()
  const dispatch = useAppDispatch()
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const fetchProductShareOptionsData = useSelector((state: any) => state?.productShareOptions?.data)
  const fetchProductImageResize = useSelector((state: any) => state?.productResizeData?.data)

  const [value, setValue] = useState<any>(groupId)

  const joinedGroup = useSelector((state: any) => state?.clubData?.joinedClubs)
  // const { loading, error, data, refetch } = useQuery(GET_MY_GROUP, {
  //   variables: {
  //     isJoined: true,
  //   },
  // })
  const [startX, setStartX] = useState(null)

  function handleTouchStart(e) {
    setStartX(e.touches[0].pageX)
  }

  function handleTouchMove(e) {
    if (startX && startX + e.touches[0].pageY > 350) {
      setIsShareWithFriend(false)
    }
  }

  function handleTouchEnd() {
    setStartX(null)
  }

  const handleClose = () => {
    let datass = fetchMobileModal?.allModalData?.filter(
      (item) => item?.modal !== fetchMobileModal?.lastModalData?.modal,
    )
    dispatch(
      fetchMobileModalData({
        allModalData: datass,
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
  }

  const [search, setSearch] = useState<any>('')
  const [followers, setFollowers] = useState<any>([])
  const handleChange = (e) => {
    setSearch(e.target.value)
  }

  let selectedFam = followers?.filter((fam) => fam.isChecked)

  // const handleCancel = () => {
  //   setIsShareFriedModalVisible(false)
  // }

  const handleUnselect = () => {
    setFollowers(
      followers?.map((fam) => {
        return { ...fam, isChecked: false }
      }),
    )
  }

  const handleShareGroup = () => {
    if (fetchProductImageResize?.isShareBookmark) {
      dispatch(
        fetchMobileModalData({
          allModalData: [
            ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
            {
              modal: 'mobileViewShareMyFeedModal',
              isOpen: true,
              id: {
                shareData: fetchProductImageResize?.shareData,
                id: fetchMobileModal?.lastModalData?.id?.id,
                isGroupModel: true,
              },
              transform: 143,
            },
          ],
          lastModalData: fetchMobileModal?.lastModalData ? fetchMobileModal?.lastModalData : [],
        }),
      )
    } else {
      dispatch(
        fetchMobileModalData({
          allModalData: [
            ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
            {
              modal: 'feedShareOnClub',
              isOpen: true,
              id: fetchMobileModal?.lastModalData?.id?.selectedProduct,
              transform: 20,
            },
          ],
          lastModalData: fetchMobileModal?.lastModalData,
        }),
      )
    }
  }

  const handleCancel = () => {
    dispatch(fetchProductShareOptions({ modal: '', isOpen: false, id: {} }))
  }

  const handleOnChnage = (e) => {
    setValue(e.target.value)
    dispatch(
      fetchProductShareOptions({
        ...fetchProductShareOptionsData,
        id: { ...fetchProductShareOptionsData?.id, groupData: e.target.value },
      }),
    )
  }

  function onChange(e, fam) {
    setFollowers(
      followers?.map((follow) => {
        if (follow?.user?.id === fam?.user?.id) {
          return { ...follow, isChecked: e.target.checked }
        } else {
          return { ...follow }
        }
      }),
    )
  }

  useEffect(() => {
    setFollowers(joinedGroup || [])
  }, [joinedGroup])

  useEffect(() => {
    dispatch(fetchJoinedClubsData({ page: 1, limit: 100, type: 'JOIN_CLUBS' }))
  }, [])

  return (
    <React.Fragment>
      {/* <div
        className={`follow-followingOverlay-share ${isShareWithFriend ? 'share-on-my-feed' : ''}`}
        onClick={() => setIsShareWithFriend(false)}
      />
      <div className={`follow-following-modal-share ${isShareWithFriend ? 'share-on-my-feed' : ''}`}> */}
      <div className="share-with-friend-modal-alignment">
        <div className="share-with-friend-modal-header-alignment">
          <div className="share-with-friend-modal-heading-alignment">
            <h4>Share with Club</h4>
            <a onClick={() => handleClose()}>Cancel</a>
          </div>
        </div>
        <div className="share-with-friend-modal-body-alignment">
          <div className="px-3">
            <div className="rounded-2xl h-16 w-full border border-gray-300 items-center px-3 mb-2 relative friend-search-alignment">
              <img src="/assets/icon/search-icon-grey.svg" alt="search" className="absolute top-4" />
              <input
                type={'text'}
                placeholder="Search"
                onChange={(e) => handleChange(e)}
                className="w-full h-full pl-10"
              ></input>
            </div>
          </div>
          {selectedFam?.length > 0 && (
            <div className="px-3">
              <div className="flex justify-between items-center mt-3">
                <div className="text-sm text-gray-400 font-normal">{selectedFam?.length} friends selected</div>
                <div className="text-sm text-blue-500 cursor-pointer" onClick={() => handleUnselect()}>
                  Unselect All
                </div>
              </div>
            </div>
          )}
          <div className="share-with-friend-invite-modal">
            {(search
              ? followers?.filter((supplier) => supplier?.name?.toLowerCase().includes(search?.toLowerCase()))
              : followers
            )?.map((fam, index) => {
              return (
                <div
                  className="rounded-2xl w-full flex justify-between items-center px-4 py-1 py-2 my-2 hover:bg-indigo-50"
                  key={index}
                >
                  <div className="flex justify-start items-center">
                    <div className="relative">
                      <img
                        src={fam?.logo_image}
                        className="mr-4 object-cover"
                        style={{ width: '48px', height: '34px', borderRadius: '3.33913px' }}
                      />
                      <div className="absolute flex justify-center h-3 w-3 bg-green-500 rounded-full z-10 bottom-0 right-7"></div>
                    </div>
                    <div>
                      <div className="text-sm font-medium text-black cursor-default">{fam?.name}</div>
                    </div>
                  </div>
                  <Radio.Group onChange={handleOnChnage} value={fetchProductShareOptionsData?.id?.groupData}>
                    <Radio value={fam}></Radio>
                  </Radio.Group>
                  {/* <Checkbox onChange={(e) => onChange(e, fam)} checked={fam?.isChecked}></Checkbox> */}
                </div>
              )
            })}
          </div>
          <div className="share-with-friend-type-alignment">
            <div className="share-with-friend-search-input-alignment">
              <img src={PinIcon.src} alt="PinIcon" />
              <input type="text" placeholder="Write something..." />
            </div>
          </div>
        </div>
        <div className="share-with-friend-footer-alignment" onClick={() => handleShareGroup()}>
          <button className={!fetchProductShareOptionsData?.id?.groupData ? 'close' : ''}>Share</button>
        </div>
      </div>
    </React.Fragment>
  )
}
