import { gql } from '@apollo/client'

const REMOVE_GROUP_MEMBERS = gql`
mutation RemoveGroupMember($memberId: [Int], $clubId: Int!) {
  removeGroupMember(member_id: $memberId, club_id: $clubId) {
    message
    success
  }
}
`

export default REMOVE_GROUP_MEMBERS
