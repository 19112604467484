import { io, Socket } from 'socket.io-client'
import nookies from 'nookies'

// Message events
export const MESSAGE_EVENTS = {
  NEW_MESSAGE: 'NEW_MESSAGE',
  MESSAGE_EDITED: 'EDIT_PRIVATE_MESSAGE',
  MESSAGE_REACTION: 'REACT_MESSAGE',
  MESSAGE_READ: 'MESSAGE_READ',
  EDIT_GROUP_MESSAGE: 'EDIT_GROUP_MESSAGE',
  LEAVE_GROUP: 'LEAVE_GROUP',
  ACCEPTED_GROUP: 'ACCEPTED_GROUP',
  MESSAGE_START_TYPING: 'MESSAGE_START_TYPING',
  MESSAGE_STOP_TYPING: 'MESSAGE_STOP_TYPING',
  INVITE_USERS: 'INVITE_USERS',
  REMOVE_PRIVATE_MESSAGE: 'REMOVE_PRIVATE_MESSAGE',
}

export const LIVESTREAM_EVENTS = {
  NEW_PRODUCT: 'NEW_PRODUCT',
  REMOVE_PRODUCT: 'REMOVE_PRODUCT',
  LIVESTREAM_CREATED: 'LIVESTREAM_CREATED',
}

class SocketClient {
  private static instance: SocketClient
  private socket: Socket | null = null

  private constructor() { }

  public static getInstance(): SocketClient {
    if (!SocketClient.instance) {
      SocketClient.instance = new SocketClient()
    }
    return SocketClient.instance
  }

  public connect(): void {
    if (this.socket?.connected) return

    const { token } = nookies.get()

    const connectionUrl = process.env.NEXT_PUBLIC_API_MESSAGING_ENDPOINT.replace('graphql', '')
    this.socket = io(connectionUrl, {
      auth: {
        token: token ? `Bearer ${token}` : ''
      },
      transports: ['websocket']
    })

    this.socket.on('connect', () => {
      console.log('Socket.IO connected')
    })

    this.socket.on('disconnect', () => {
      console.log('Socket.IO disconnected')
    })

    this.socket.on('error', (error) => {
      console.error('Socket.IO error:', error)
    })
  }

  public disconnect(): void {
    if (this.socket) {
      this.socket.disconnect()
      this.socket = null
    }
  }

  public on(event: string, callback: (...args: any[]) => void): void {
    this.socket?.on(event, callback)
  }

  public off(event: string, callback?: (...args: any[]) => void): void {
    this.socket?.off(event, callback)
  }

  public emit(event: string, ...args: any[]): void {
    this.socket?.emit(event, ...args)
  }

  public isConnected(): boolean {
    return this.socket?.connected || false
  }
}

export const socketClient = SocketClient.getInstance()
export default socketClient
