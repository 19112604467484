import React, { useEffect, useRef, useState } from 'react'
import styles from './AddProductBrand.module.scss'
import classNames from 'classnames'
import { ADD_PRODUCT_BRAND } from 'src/graphql/mutations/addProductBrand'
import { useMutation, useQuery } from '@apollo/client'
import GET_BRANDS_QUERY from 'src/graphql/queries/getAllProductBrands'
import { useAuth } from 'src/utils/auth'
import { useSelector } from 'react-redux'
export default function AddProductBrand({ setProductData, productData }) {
  const [AddBrandDropdown, setAddBrandDropdown] = useState(false)
  const [isTypeBrand, setIsTypeBrand] = useState(false)
  const [brand, setBrand] = useState('')
  const { user }: any = useAuth()
  const { loading, error, data, refetch } = useQuery(GET_BRANDS_QUERY, {
    variables: {
      store_id: user?.store?.id,
    },
  })
  const [brandList, setBrandList] = useState([])
  const [searchData, setSearchData] = useState<any>([])
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  useEffect(() => {
    if (getAllState?.lastModalData?.isEdit) {
      setBrand(brandList?.find((item) => item?.id === productData?.brand_id)?.name)
    }
  }, [productData])
  useEffect(() => {
    if (data?.getBrandByProductId?.data?.length > 0) {
      const originalData = data?.getBrandByProductId?.data
      setBrandList([{ __typename: 'getbrandRes', name: 'None' }, ...originalData])
      setSearchData([{ __typename: 'getbrandRes', name: 'None' }, ...originalData])
    }
  }, [data?.getBrandByProductId?.data?.length])

  const dropdownRef: any = useRef()
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setAddBrandDropdown(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [dropdownRef])

  const [addProductMutation] = useMutation(ADD_PRODUCT_BRAND, {
    onCompleted: (res) => {
      // setLoadingProduct(false)
    },
    onError: (err) => {
      console.log('err', err)
    },
  })

  const handleAddNewBrnd = async () => {
    setAddBrandDropdown(true)
    if (brand === '') {
      alert('Please enter brand name')
      return
    } else {
      setIsTypeBrand(false)
      let result = await addProductMutation({
        variables: {
          store_id: user?.store?.id,
          name: brand,
        },
        refetchQueries: [
          {
            query: GET_BRANDS_QUERY,
            variables: {
              store_id: user?.store?.id,
            },
          },
        ],
      })
      setProductData({ ...productData, brand_id: result?.data?.addProductBrand?.brand?.id })
      return await result
    }
  }

  const handleChange = (e) => {
    // setProductData({ ...productData, brand: e.target.value })
    setBrand(e.target.value)
    var value = e.target.value.toLowerCase()
    let filterData = Boolean(searchData?.length)
      ? searchData?.filter((item) => item?.name.toLowerCase().includes(value))
      : []
    if (filterData.length === 0) {
      setBrandList([
        {
          name: 'No brand found',
        },
      ])
      setIsTypeBrand(true)
    } else {
      setBrandList(filterData)
      setIsTypeBrand(false)
    }
  }

  return (
    <div className={styles.addBrandSection}>
      <div className={styles.addBrandBox}>
        <div className={styles.addBrandHeading}>
          <h4>Brand</h4>
        </div>

        <div className={styles.addBrandInputAlignment} ref={dropdownRef} onClick={() => setAddBrandDropdown(true)}>
          <input type="text" placeholder="Select Brand" value={brand} onChange={(e) => handleChange(e)} />
          {isTypeBrand && <p onClick={() => handleAddNewBrnd()}>Add</p>}
          <div
            className={styles.addBrandDropdownIcon}
            onClick={(e) => {
              e.stopPropagation()
              setAddBrandDropdown(false)
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              className={AddBrandDropdown ? styles.brandActiveArrow : ''}
            >
              <path d="M4 7L8 11L12 7" stroke="#636773" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <div
            className={
              AddBrandDropdown
                ? classNames(styles.brandDropdownBox, styles.openDropdownBox)
                : classNames(styles.brandDropdownBox, styles.closeDropdownBox)
            }
          >
            {/* <div className={styles.noBrandDataText}>
              <p>No brand listed</p>
            </div> */}

            <div className={styles.selectedListBrand}>
              {brandList?.map((item, index) => (
                <p
                  // className={styles.selectedBrand}
                  className={item?.name.toLowerCase() === brand?.toLowerCase() ? styles.selectedBrand : ''}
                  key={index}
                  onClick={() => {
                    setProductData(
                      { ...productData, brand_id: item?.name === 'None' ? null : item?.id },
                      setBrand(item?.name),
                    )
                  }}
                >
                  {item?.name}
                </p>
              ))}
            </div>

            {/* <div
              className={styles.addBrandOption}
              onClick={() => {
                setIsTypeBrand(true)
                setBrand('')
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M10 4.16669V15.8334"
                  stroke="#556EE6"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.16675 10H15.8334"
                  stroke="#556EE6"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <a>Add Brand</a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
