import '../pages/index.scss'
import { ApolloProvider, useSubscription } from '@apollo/client'
import { AuthProvider } from 'src/utils/auth'
import { Provider } from 'react-redux'
import store from 'src/store/store'
import StoreAndProductDetailModal from 'src/components/StoreAndProductDetailModal'
import Head from 'next/head'
import { StateProvider } from '../utils/state'
import SellerSidebar from 'src/components/Seller/SellerSidebar'
import { useRouter } from 'next/router'
import React from 'react'
import SellerOnboarding from './seller/onboarding'
import BecomeSeller from './seller/become-seller'
import MobileLayout from 'src/components/MobileLayout/MobileLayout'
import Header from 'src/components/shared/headers/mainHeader/Header'
import SummarySidebar from 'src/components/customerportal/SummarySidebar'
import { createApolloClient } from 'src/utils/apolloClients'
import { LIVESTREAM_NOTIFICATION } from 'src/graphql/subscriptions/subscriptions'
import { notification } from 'antd'
import MarketplaceHeader from 'src/components/MarketplaceHeader'
import MarketplaceFooter from 'src/components/MarketplaceFooter'
import TermsPoliciesHeader from 'src/components/TermsPoliciesHeader'
import { ProductFilterProvider } from 'src/utils/ProductContext'
// import { FpjsProvider, FingerprintJSPro } from '@fingerprintjs/fingerprintjs-pro-react'
import SellerOnboardingtwo from './seller/onboarding'
import { Toaster } from 'react-hot-toast'

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const path = router.asPath
  const userOnboard = path.split('/')[1]
  const seller = path.split('/')[1]
  const sellerOnboarding = path.split('/')[2]
  const client1 = createApolloClient('token')



  return (
    <>
      {/* <FpjsProvider
        loadOptions={{
          apiKey: 'UrrKaaMI5WFGJuIOoQjj',
          region: "ap"
        }}
      > */}
        <AuthProvider>
          <ApolloProvider client={client1}>
            <Provider store={store}>
              <ProductFilterProvider>
                <StateProvider>
                  <Head>
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                  </Head>
                  {seller === 'seller' ? (
                    sellerOnboarding === 'become-seller' ? (
                      <React.Fragment>
                        <Header />
                        <BecomeSeller />
                      </React.Fragment>
                    ) : sellerOnboarding === 'onboarding' ? (
                      <div className="min-h-screen bg-gray-50">
                        <main>
                          <SellerOnboardingtwo />
                        </main>
                      </div>
                    ) : sellerOnboarding === 'onboarding2' ? (
                      <div className="min-h-screen bg-gray-50">
                        <main>
                          <SellerOnboardingtwo />
                        </main>
                      </div>
                    ) : (
                      <React.Fragment>
                        <Header />
                        <SellerSidebar>
                          <Component {...pageProps} />
                        </SellerSidebar>
                      </React.Fragment>
                    )
                  ) : seller === 'customerportal' ? (
                    <React.Fragment>
                      <Header />
                      <SummarySidebar>
                        <Component {...pageProps} />
                      </SummarySidebar>
                    </React.Fragment>
                  ) : seller === 'marketplace' ? (
                    <React.Fragment>
                      <MarketplaceHeader />
                      <Component {...pageProps} />
                      <MarketplaceFooter />
                    </React.Fragment>
                  ) : seller === 'terms-of-use' ||
                    seller === 'privacy-policy' ||
                    seller === 'legal' ||
                    seller === 'contactus' ||
                    seller === 'about' ? (
                    <React.Fragment>
                      <TermsPoliciesHeader />
                      <Component {...pageProps} />
                      <MarketplaceFooter />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {userOnboard !== 'onboarding' && <Header />}
                      <div className="mobile-view-main-padding-alignment">
                        <Component {...pageProps} />
                      </div>
                      {userOnboard !== 'onboarding' && <MobileLayout />}
                    </React.Fragment>
                  )}
                  <StoreAndProductDetailModal />
                  <Toaster 
                    position="top-right"
                    toastOptions={{
                      duration: 3000,
                      style: {
                        background: '#363636',
                        color: '#fff',
                      },
                      success: {
                        duration: 3000,
                        iconTheme: {
                          primary: '#4CAF50',
                          secondary: '#fff',
                        },
                      },
                      error: {
                        duration: 3000,
                        iconTheme: {
                          primary: '#f44336',
                          secondary: '#fff',
                        },
                      },
                    }}
                  />
                </StateProvider>
              </ProductFilterProvider>
            </Provider>
          </ApolloProvider>
        </AuthProvider>
      {/* </FpjsProvider> */}
    </>
  )
}

export default MyApp
