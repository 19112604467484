import React, { useState, useRef, useEffect } from 'react'
import { Menu, Dropdown, notification } from 'antd'
import { useMutation } from '@apollo/client'
// import MY_FEED_QUERY from 'src/graphql/queries/getMyFeed'
import { useAuth } from 'src/utils/auth'
import CREATE_SAVE_POST from 'src/graphql/mutations/createSavePost'
import DELETE_SAVE_POST from 'src/graphql/mutations/unSavePost'
import CloseIcon from '../../../../../public/assets/icon/search-close.svg'
import PostHeaderPopOver from './PostHeaderPopOver'
import { useRouter } from 'next/router'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAppDispatch } from 'src/store/store'
import LazyImage from 'src/utils/LazyImage'
import { fetchAllFeedSliceData } from 'src/store/slices/fetchAllFeedSlice'
import { useSelector } from 'react-redux'
import PostheaderMobile from './postheadermobile'
import { fetchProductShareOptions } from 'src/store/slices/productShareOptionsSlice'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import ReportPostModal from 'src/components/ReportPost'

export default function PostHeader(props) {
  const { user }: any = useAuth()
  const router = useRouter()
  const path = router.asPath
  const seller = path.split('/')[1]
  const dispatch = useAppDispatch()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const [isReportModalOpen, setIsReportModalOpen] = useState(false)
  const postheaderMobileRef = useRef(null);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const checkMobileView = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    
    checkMobileView();
    window.addEventListener('resize', checkMobileView);
    
    return () => window.removeEventListener('resize', checkMobileView);
  }, []);

  const openMobileMenu = () => {
    if (postheaderMobileRef.current) {
      postheaderMobileRef.current.toggleOpen();
    }
  };

  const {
    isNormalPost,
    isGroupPost,
    isStorePost,
    post,
    store,
    savePost,
    editPost,
    showModal,
    showDeletePostModal,
    setMediaModal,
    isHideMoreMenu,
    editPostMobile,
  } = props
  
  const createSavePostHandler = () => {
    createSavePost({
      variables: {
        post: post.id,
        user: user.id,
      },
      // refetchQueries: [{ query: MY_FEED_QUERY }],
    })
  }

  const removeSavePostHandler = (id) => {
    unSavePost({
      variables: {
        post_id: id,
      },
      // refetchQueries: [{ query: MY_FEED_QUERY }],
    })
  }

  const [unSavePost] = useMutation(DELETE_SAVE_POST, {
    onCompleted: () => {
      notification['success']({
        message: 'Post Unsaved',
        description: 'Post Unsaved successfully',
      })
    },
    onError: (error) => {
      console.log(error)
      notification['error']({
        message: 'Error',
        description: error.message,
      })
    },
  })

  const [createSavePost] = useMutation(CREATE_SAVE_POST, {
    onCompleted: () => {
      notification['success']({
        message: 'Post Saved',
        description: 'Post Saved successfully',
      })
      dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
      notification['error']({
        message: 'Error',
        description: error.message,
      })
    },
  })

  const navigateToLogin = () => { }

  const handleShareWithFriends = () => {

    const isMobile = window.innerWidth < 600;
    if (isMobile) {
      dispatch(
        fetchMobileModalData({
          allModalData: [
            {
              modal: 'productSharewithFriend',
              isOpen: true,
              id: post.id,
              transform: 20,
            },
          ],
          lastModalData: {},
        }),
      );
    } else {
      dispatch(
        fetchProductShareOptions({
          modal: 'productShareWithFriend',
          isOpen: true,
          id: post.id,
        }),
      );
    }
  };

  // const handleReportSubmit = (reason: string) => {
  //   // TODO: Implement the API call to submit the report
  //   notification.success({
  //     message: 'Report Submitted',
  //     description: 'Thank you for reporting. We will review this post.',
  //   });
  // };

  const menu = (
    <Menu>
      {/* {post?.post_for !== null &&
        (!post?.isSave ? (
          <Menu.Item key="0">
            <div
              className="dropdown-option dropdown-option-first hover:bg-indigo-50 py-3"
              onClick={user ? createSavePostHandler : navigateToLogin}
            >
              <div className="dropdown-main">
                <div className="dropdown-icon">
                  <img src="/assets/img/store/product/bookmark.svg" />
                </div>
                <div className="dropdown-content">
                  <h5>Save Post</h5>
                  <p>Save this post for later</p>
                </div>
              </div>
            </div>
          </Menu.Item>
        ) : (
          <Menu.Item key="0">
            <div
              className="dropdown-option dropdown-option-first hover:bg-indigo-50 py-3"
              onClick={() => removeSavePostHandler(post?.id)}
            >
              <div className="dropdown-main">
                <div className="dropdown-icon">
                  <img src="/assets/img/store/product/bookmark.svg" />
                </div>
                <div className="dropdown-content">
                  <h5>UnSave Post</h5>
                  <p>UnSave this post</p>
                </div>
              </div>
            </div>
          </Menu.Item>
        ))} */}
      <Menu.Item key="1">
        <div
          className="dropdown-option py-3  tablet:hidden smallTablet:hidden"
          onClick={user ? createSavePostHandler : navigateToLogin}
        >
          <div className="dropdown-main">
            <div className="dropdown-icon">
              <img src="/assets/img/store/product/saveicon.svg" />
            </div>
            <div className="dropdown-content">
              <h5>Save Post</h5>
              <p>Save this post for later</p>
            </div>
          </div>
        </div>
      </Menu.Item>
      {post?.user?.id === user?.id && (
        <>
          <Menu.Item key="2">
            <div
              className="dropdown-option py-3 mobile:hidden tablet:hidden smallTablet:hidden"
              onClick={() => editPost(post)}
            >
              <div className="dropdown-main">
                <div className="dropdown-icon">
                  <img src="/assets/img/store/product/Pencilicon.svg" />
                </div>
                <div className="dropdown-content">
                  <h5>Edit Post</h5>
                  <p>Save this post for later</p>
                </div>
              </div>
            </div>
          </Menu.Item>
          <Menu.Item key="2">
            <div className="dropdown-option py-3 hide-web-class" onClick={() => editPostMobile(post)}>
              <div className="dropdown-main">
                <div className="dropdown-icon">
                  <img src="/assets/img/store/product/Pencilicon.svg" />
                </div>
                <div className="dropdown-content">
                  <h5>Edit Post</h5>
                  <p>Save this post for later</p>
                </div>
              </div>
            </div>
          </Menu.Item>
        </>
      )}
      <Menu.Item key="3">
        <div className="dropdown-option py-3">
          <div className="dropdown-main">
            <div className="dropdown-icon">
              <img src="/assets/img/store/product/grid.svg" />
            </div>
            <div className="dropdown-content">
              <h5>Share on my feed</h5>
              <p>Share this post on my feed</p>
            </div>
          </div>
        </div>
      </Menu.Item>
      {post?.user?.id !== user?.id && (
        <Menu.Item key="4">
          <div className="dropdown-option py-3">
            <div className="dropdown-main">
              <div className="dropdown-icon">
                <img src="/assets/img/feed/save-post.svg" />
              </div>
              <div className="dropdown-content">
                <h5>Share on my feed</h5>
                <p>Share this post on my feed</p>
              </div>
            </div>
          </div>
        </Menu.Item>
      )}
      <Menu.Item key="5" onClick={handleShareWithFriends}>
        <div className="dropdown-option py-3">
          <div className="dropdown-main">
            <div className="dropdown-icon">
              <img src="/assets/img/feed/share-friends.svg" />
            </div>
            <div className="dropdown-content">
              <h5>Share with Friends</h5>
              <p>Share post with friends</p>
            </div>
          </div>
        </div>
      </Menu.Item>
      {seller === 'seller' ? (
        <Menu.Item key="6">
          <div
            className="dropdown-option dropdown-option-last hover:bg-indigo-50 py-3"
            onClick={() => showDeletePostModal(post)}
          >
            <div className="dropdown-main">
              <div className="dropdown-icon">
                <img src="/assets/img/feed/delete.svg" />
              </div>
              <div className="dropdown-content">
                <h5>Delete Post</h5>
                <p>Delete this post</p>
              </div>
            </div>
          </div>
        </Menu.Item>
      ) : (
        post?.user?.id === user?.id && (
          <Menu.Item key="7">
            <div
              className="dropdown-option dropdown-option-last hover:bg-indigo-50 py-3"
              onClick={() => showDeletePostModal(post)}
            >
              <div className="dropdown-main">
                <div className="dropdown-icon">
                  <img src="/assets/img/feed/delete.svg" />
                </div>
                <div className="dropdown-content">
                  <h5>Delete Post</h5>
                  <p>Delete this post</p>
                </div>
              </div>
            </div>
          </Menu.Item>
        )
      )}
    </Menu>
  )

  const otherUsersMenu = (
    <Menu>
      {post?.post_for !== null &&
        (post?.savedPost ? (
          <Menu.Item key="0">
            <div
              className="dropdown-option dropdown-option-first hover:bg-indigo-50 py-3"
              onClick={user ? createSavePostHandler : navigateToLogin}
            >
              <div className="dropdown-main">
                <div className="dropdown-icon">
                  <img src="/assets/img/store/product/bookmark.svg" />
                </div>
                <div className="dropdown-content">
                  <h5>Save Post</h5>
                  <p>Save this post for later</p>
                </div>
              </div>
            </div>
          </Menu.Item>
        ) : (
          <Menu.Item key="0">
            <div
              className="dropdown-option dropdown-option-first hover:bg-indigo-50 py-3"
              onClick={() => removeSavePostHandler(post?.id)}
            >
              <div className="dropdown-main">
                <div className="dropdown-icon">
                  <img src="/assets/img/store/product/bookmark.svg" />
                </div>
                <div className="dropdown-content">
                  <h5>UnSave Post</h5>
                  {/* <p>UnSave this post from your collection</p> */}
                  <p>UnSave this post</p>
                </div>
              </div>
            </div>
          </Menu.Item>
        ))}
      {/* Share on my feed Commented */}
      {!isStorePost && (
        <Menu.Item key="2">
          <div className="dropdown-option py-3">
            <div className="dropdown-main">
              <div className="dropdown-icon">
                <img src="/assets/img/feed/share-feed.svg" />
              </div>
              <div className="dropdown-content">
                <h5>Share on my feed</h5>
                <p>Share this post on my feed</p>
              </div>
            </div>
          </div>
        </Menu.Item>
      )}
      {!isStorePost && (
        <Menu.Item key="3" onClick={handleShareWithFriends}>
          <div className="dropdown-option hover:bg-indigo-50 py-3">
            <div className="dropdown-main">
              <div className="dropdown-icon">
                <img src="/assets/img/feed/share-friends.svg" />
              </div>
              <div className="dropdown-content">
                <h5>Share with Friends</h5>
                <p>Share post with friends</p>
              </div>
            </div>
          </div>
        </Menu.Item>
      )}
      <Menu.Item key="report">
        <div 
          className="dropdown-option hover:bg-indigo-50 py-3"
          onClick={() => setIsReportModalOpen(true)}
        >
          <div className="dropdown-main">
            <div className="dropdown-icon">
              <img src="/assets/img/feed/report.svg" />
            </div>
            <div className="dropdown-content">
              <h5>Report Post</h5>
              <p>Report this post</p>
            </div>
          </div>
        </div>
      </Menu.Item>
      {seller === 'seller' ? (
        <Menu.Item key="1">
          <div className="dropdown-option py-3" onClick={() => editPost(post)}>
            <div className="dropdown-main">
              <div className="dropdown-icon">
                <img src="/assets/img/store/product/grid.svg" />
              </div>
              <div className="dropdown-content">
                <h5>Edit Post</h5>
                <p>Save this post for later</p>
              </div>
            </div>
          </div>
        </Menu.Item>
      ) : (
        post?.store_id === user?.store?.id &&
        post?.post_for !== 'SELLER' && (
          <Menu.Item key="1">
            <div className="dropdown-option py-3" onClick={() => editPost(post)}>
              <div className="dropdown-main">
                <div className="dropdown-icon">
                  <img src="/assets/img/store/product/grid.svg" />
                </div>
                <div className="dropdown-content">
                  <h5>Edit Post</h5>
                  <p>Save this post for later</p>
                </div>
              </div>
            </div>
          </Menu.Item>
        )
      )}
      {seller === 'seller' ? (
        <Menu.Item key="4">
          <div
            className="dropdown-option dropdown-option-last hover:bg-indigo-50 py-3"
            onClick={() => showDeletePostModal(post)}
          >
            <div className="dropdown-main">
              <div className="dropdown-icon">
                <img src="/assets/img/feed/delete.svg" />
              </div>
              <div className="dropdown-content">
                <h5>Delete Post</h5>
                <p>Delete this post</p>
              </div>
            </div>
          </div>
        </Menu.Item>
      ) : (
        post?.user?.id === user?.id &&
        (post?.post_for === 'USER' || post?.post_for === 'GROUP') &&
        seller === 'seller' && (
          <Menu.Item key="4">
            <div
              className="dropdown-option dropdown-option-last hover:bg-indigo-50 py-3"
              onClick={() => showDeletePostModal(post)}
            >
              <div className="dropdown-main">
                <div className="dropdown-icon">
                  <img src="/assets/img/feed/delete.svg" />
                </div>
                <div className="dropdown-content">
                  <h5>Delete Post</h5>
                  <p>Delete this post</p>
                </div>
              </div>
            </div>
          </Menu.Item>
        )
      )}
    </Menu>
  )

  const handleMoveLogin = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Share-Product-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const handelImageSlider = () => { }

  return (
    <>
      <ReportPostModal
        isOpen={isReportModalOpen}
        onClose={() => setIsReportModalOpen(false)}
        postId={post?.id}
        isSharedPost={post?.product === null ? false : true}
      />
      <div className="flex" style={{ gap: '10px', alignItems: 'center' }}>
        <div
          className={`post-profile-align  ${post?.post_for === 'SELLER' || isStorePost ? 'seller-post-profile-img-alignment' : ''
            }`}
        >
          {/* seller-post-profile-img-alignment */}
          {/* FOR_NORMAL_POST==================================================================================== */}
          {(isNormalPost || isGroupPost) &&
            (isNormalPost && post?.post_for === 'SELLER' ? (
              // <img
              //   src={post?.store?.logo?.length > 0 ? post?.store?.logo && post?.store?.logo[0] : ''}
              //   alt={post?.user?.profileImage?.url}
              //   className=" object-cover"
              // />
              <LazyImage
                image={{
                  src: post?.store?.logo_image ? post?.store?.logo_image : '',
                  alt: 'Icon',
                }}
                objectFit={'cover'}
                imageClassName={`post-profile-align-skeleton  ${post?.post_for === 'SELLER' || isStorePost ? 'seller-post-profile-img-alignment-skeleton' : ''
                  }`}
                handleClickOnImage={() => handelImageSlider()}
                position={'absolute'}
                borderRadius={'50%'}
              />
            ) : (
              // <img
              //   src={
              //     post?.user?.id === user?.id
              //       ? user?.logo_image && user?.logo_image
              //       : post?.user?.logo_image
              //   }
              //   alt={post?.user?.logo_image}
              //   className=" object-cover"
              // />
              <LazyImage
                image={{
                  src: post?.user?.id === user?.id ? user?.logo_image && user?.logo_image : post?.user?.logo_image,
                  alt: 'Icon',
                }}
                objectFit={'cover'}
                handleClickOnImage={() => handelImageSlider()}
                position={'absolute'}
                borderRadius={'50%'}
                imageClassName={`post-profile-align-skeleton  ${post?.post_for === 'SELLER' || isStorePost ? 'seller-post-profile-img-alignment-skeleton' : ''
                  }`}
              />
            ))}
          {/* FOR_STORE_POST==================================================================================== */}
          {isStorePost && (
            <img
              src={post?.store?.logo?.length > 0 ? post?.store?.logo[0] : ''}
              alt={post?.profileImage?.alternativeText}
              // alt={post?.profileImage?.alternativeText}
              // style={{ width: 50, height: 50 }}
              className=" object-cover"
            />
          )}
        </div>
        <PostHeaderPopOver
          isNormalPost={isNormalPost}
          isGroupPost={isGroupPost}
          isStorePost={isStorePost}
          post={post}
          store={store}
        />
      </div>
      {!isHideMoreMenu &&
        (post?.user?.id === user?.id ? (
          <div className="more-menu-align">
            {user ? (
              <>
                <div className='feed-dropdown-web'>
                  <Dropdown overlay={menu} trigger={['click']} overlayClassName="feed-dropdown">
                    <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5 10C6.10457 10 7 10.8954 7 12C7 13.1046 6.10457 14 5 14C3.89543 14 3 13.1046 3 12C3 10.8954 3.89543 10 5 10ZM12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10ZM21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14C20.1046 14 21 13.1046 21 12Z"
                          fill="#92929D"
                        />
                      </svg>
                    </a>
                  </Dropdown>
                </div>
                <div className='feed-dropdown-mobile' onClick={openMobileMenu}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5 10C6.10457 10 7 10.8954 7 12C7 13.1046 6.10457 14 5 14C3.89543 14 3 13.1046 3 12C3 10.8954 3.89543 10 5 10ZM12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10ZM21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14C20.1046 14 21 13.1046 21 12Z"
                      fill="#92929D"
                    />
                  </svg>
                </div>
              </>
            ) : (
              <img src="/assets/img/feed/ic_More.svg" style={{ cursor: 'pointer' }} onClick={handleMoveLogin} />
            )}
            {setMediaModal && (
              <div className="social-option-alignment">
                <div
                  onClick={() => {
                    setMediaModal(false)
                    // router.back()
                  }}
                  className="close-icon-contain"
                >
                  <img src={CloseIcon.src} alt="CloseIcon" />
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="more-menu-align">
            {user ? (
              <>
                <div className='feed-dropdown-web'>
                  <Dropdown overlay={otherUsersMenu} trigger={['click']} overlayClassName="feed-dropdown">
                    <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                      <img src="/assets/img/feed/ic_More.svg" />
                    </a>
                  </Dropdown>
                </div>
                <div className="feed-dropdown-mobile" onClick={openMobileMenu}>
                  <img src="/assets/img/feed/ic_More.svg" />
                </div>
              </>
            ) : (
              <img src="/assets/img/feed/ic_More.svg" style={{ cursor: 'pointer' }} onClick={handleMoveLogin} />
            )}
            {setMediaModal && (
              <div className="social-option-alignment">
                <div
                  onClick={() => {
                    setMediaModal(false)
                    // router.back()
                  }}
                  className="close-icon-contain"
                >
                  <img src={CloseIcon.src} alt="CloseIcon" />
                </div>
              </div>
            )}
          </div>
        ))}

      {isMobileView && (
        <PostheaderMobile
          ref={postheaderMobileRef}
          menu={post?.user?.id === user?.id ? menu : otherUsersMenu}
          Zindexmodal={99999999}
          onOverlayClick={() => setIsReportModalOpen(false)}
        />
      )}
    </>
  )
}
