import React, { useState } from 'react'
import { Modal, Menu, Dropdown, Checkbox, Tabs, Row, Popover, notification } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import DELETE_POST from 'src/graphql/mutations/deletePost'
// import MY_FEED_QUERY from 'src/graphql/queries/getMyFeed'
import DELETE_SHARED_POST from 'src/graphql/mutations/deleteSharedPost'
import GET_GROUP_POST from 'src/graphql/queries/getGroupPost'
import { DELETE_SELLER_POST } from 'src/graphql/mutations/sellerMutation/deleteSellerPost'
import { useAuth } from 'src/utils/auth'
import GET_STORE_BY_NAME from 'src/graphql/queries/getStoreByName'
import sellerClient from 'src/utils/apolloSellerClient'
import {
  fetchAllFeedSliceData,
  fetchGroupPost,
  fetchStorePost,
  fetchUserPost,
} from 'src/store/slices/fetchAllFeedSlice'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'

export default function PostDeleteModal(props) {
  const { isModalOpen, isNormalPost, setIsModalOpen, isGroupPost, post, handleDeleteCancel, isStorePost } = props
  const dispatch = useAppDispatch()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const { user }: any = useAuth()
  const fetchAddEditPost = useSelector((state: any) => state?.postAddEditModal?.data)

  const [deletePost] = useMutation(DELETE_POST, {
    onCompleted: () => {
      setIsModalOpen(false)
      dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
      dispatch(fetchGroupPost({ page, limit, group_id: post?.groupId, prevPost: [] }))
      dispatch(fetchUserPost({ page, limit, user_id: user?.id, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const [deleteSharedPost] = useMutation(DELETE_SHARED_POST, {
    onCompleted: () => {
      setIsModalOpen(false)
      dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
      dispatch(fetchGroupPost({ page, limit, group_id: post?.groupId, prevPost: [] }))
      // dispatch(fetchGroupPost({ page, limit, group_id: post?.groupId, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const [deleteSellerPost] = useMutation(DELETE_SELLER_POST, {
    client: sellerClient,
    onCompleted: () => {
      setIsModalOpen(false)
      dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
      dispatch(fetchStorePost({ page, limit, slug: fetchAddEditPost?.id?.editPost?.store_slug, prevPost: [] }))
    },
    onError: (error) => { },
  })

  const deletePostHandler = (post) => {
    if (isNormalPost) {
      if (post?.product) {
        deleteSharedPost({
          variables: { post_id: post?.id },
          // refetchQueries: [{ query: MY_FEED_QUERY, variables: { page: 1, limit: 10 } }],
        })
      } else if (post?.post_for === 'SELLER') {
        deleteSellerPost({
          variables: {
            id: parseInt(post.id),
          },
          refetchQueries: [
            // { query: MY_FEED_QUERY, variables: { page: 1, limit: 10 } },
            { query: GET_STORE_BY_NAME, variables: { storeName: user?.store?.name } },
          ],
        })
      } else {
        deletePost({
          variables: { id: post.id },
          // refetchQueries: [
          //   { query: MY_FEED_QUERY, variables: { page: 1, limit: 10 } },
          //   { query: MY_FEED_QUERY, variables: { group_id: post?.groupId } },
          // ],
        })
      }
    } else if (isGroupPost) {
      if (post?.product) {
        deleteSharedPost({
          variables: { post_id: post?.id },
          // refetchQueries: [{ query: MY_FEED_QUERY, variables: { group_id: post?.groupId } }],
        })
      } else {
        deletePost({
          variables: { id: post.id },
          // refetchQueries: [{ query: MY_FEED_QUERY, variables: { group_id: post?.groupId } }],
        })
      }
    } else if (isStorePost) {
      deleteSellerPost({
        variables: {
          id: parseInt(post.id),
        },
        refetchQueries: [
          // { query: MY_FEED_QUERY, variables: { page: 1, limit: 10 } },
          { query: GET_STORE_BY_NAME, variables: { storeName: user?.store?.name } },
        ],
      })
    }
  }

  return (
    <Modal
      title="Delete Modal"
      open={isModalOpen}
      onOk={() => deletePostHandler(post)}
      onCancel={() => handleDeleteCancel()}
      wrapClassName="post-add-edit-modal"
      maskStyle={{ zIndex: 9999 }}
    >
      <p>Are you sure you want to delete this product?</p>{' '}
    </Modal>
  )
}
