import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useAuth } from 'src/utils/auth'
import getImageUrl from 'src/utils/getImageUrl'
import LeftArrowIcon from '../../../../../public/assets/icon/left-icon-white.svg'
import Moreicon from '../../../../../public/assets/icon/white-more-icon.svg'

export default function UserCoverImageSection(props) {
  const { userData, userId } = props
  const { user } = useAuth()
  const [profileImage, setProfileImage] = useState<any>()

  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  const handleClose = () => {
    let datass = getAllState?.allModalData?.filter((item) => item?.modal !== getAllState?.lastModalData?.modal)
    dispatch(
      fetchModalData({
        allModalData: datass,
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  return (
    <div className="social-banner-alignment">
      <img
        src={
          profileImage
            ? URL.createObjectURL(profileImage)
            : userData?.profileCoverImageUrl
              ? getImageUrl(userData?.profileCoverImageUrl)
              : '/assets/img/user-profile/cover-updated.jpg'
        }
        style={{ width: '100%' }}
        className="rounded-t-xl object-cover"
      />
      {userId == user?.id && (
        <div className="edit-cover-button-alignment">
          <button>Edit Cover</button>
        </div>
      )}
      <div className="mobile-view-banner-option-alignment">
        <div className="mobile-view-left-box-alignment" onClick={() => handleClose()}>
          <img src={LeftArrowIcon.src} alt="LeftArrowIcon" />
        </div>
        <div className="mobile-view-left-box-alignment">
          <img src={Moreicon.src} alt="Moreicon" />
        </div>
      </div>
    </div>
  )
}
