import React from 'react'
import styles from './AddProductCondition.module.scss'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'

export default function AddProductCondition({ setProductData, productData }) {
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const dispatch = useAppDispatch()

  const handleChecked = async (e, type) => {
    setProductData({ ...productData, condition: type })

    await dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          mobileProductsData: {
            ...addProductDetailSlice?.id?.mobileProductsData,
            condition: type,
          },
        },
      }),
    )
  }
  return (
    <div className={styles.addProductConditionSection}>
      <div className={styles.addProductConditionBox}>
        <div className={styles.addProductConditionHeading}>
          <h4>Category</h4>
          <span className={styles.starAlignment}>*</span>
        </div>
        <div className={styles.conditionNotes}>
          <span>Product conditions</span>
        </div>
        <div className={styles.addProductConditionDetails}>
          <div className={styles.checkBoxAlignment}>
            <input
              type="checkbox"
              id="New"
              onClick={(e) => handleChecked(e, 'New')}
              checked={productData?.condition === 'New' ? true : false}
              readOnly
            />
            <label htmlFor="New">New</label>
          </div>
          <div className={styles.checkBoxAlignment}>
            <input
              type="checkbox"
              id="Used"
              onClick={(e) => handleChecked(e, 'Used')}
              checked={productData?.condition === 'Used' ? true : false}
              readOnly
            />
            <label htmlFor="Used">Used</label>
          </div>
        </div>
      </div>
    </div>
  )
}
