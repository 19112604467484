import React from 'react'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAppDispatch } from 'src/store/store'

export default function PasswordUpdated() {
  const dispatch = useAppDispatch()

  const handleBackogin = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: { media: 'login', modalFor: 'login', startWith: 'loginModal' },
      }),
    )
  }

  return (
    <div className="password-uploaded-details-alignment">
      <div className="password-right-sign-alignment">
        <img src="/assets/icon/right-sign-icon.svg" alt="right sign" />
      </div>
      <div className="password-ualoded-heading-alignment">
        <h4>Password Updated</h4>
        <p>You can now log in using your new password</p>
      </div>
      <div className="back-to-login-button-alignment" onClick={handleBackogin}>
        <button>Back to Login</button>
      </div>
    </div>
  )
}
