import gql from 'graphql-tag'

const GET_ALL_PEOPLE = gql`
  query GetAllPeople($gender: String, $search: String, $type: String, $limit: Int, $page: Int) {
    getAllPeople(gender: $gender, search: $search, type: $type, limit: $limit, page: $page) {
      data {
        id
        firstName
        lastName
        logo_image
        banner_image
        userName
        jobTitle
        isFollow
        followers_count
        userName
        gender
      }
      count
      success
      message
    }
  }
`
export default GET_ALL_PEOPLE
