// @ts-nocheck
import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAppDispatch } from 'src/store/store'
import SHARE_FEED_POST_MUTATION from 'src/graphql/mutations/shareBookmarkPost'
import SHARE_POST_MUTATION from 'src/graphql/mutations/createSharePost'
import { fetchProductShareOptions } from 'src/store/slices/productShareOptionsSlice'
// import MY_FEED_QUERY from 'src/graphql/queries/getMyFeed'
import { Spin } from 'antd'
import Slider from 'react-slick'
import Product from 'src/components/ProductPage/AllProduct/Product'
import GET_SINGLE_COLLECTION from 'src/graphql/queries/getSingleCollection'
import GET_SINGLE_COLLECTION_LIKE from 'src/graphql/queries/getCollectionSingleLike'
import CollectionSharedModal from 'src/components/ProductPage/AllProduct/CollectionSharedModal'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'
import { fetchAllFeedSliceData } from 'src/store/slices/fetchAllFeedSlice'

function SampleNextArrow(props: { className?: string; style?: React.CSSProperties; onClick?: () => void }) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <img src="/assets/icon/next-arrow.svg" alt="next arrow" />
    </div>
  )
}

function SamplePrevArrow(props: { className?: string; style?: React.CSSProperties; onClick?: () => void }) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <img src="/assets/icon/prev-arrow.svg" alt="prev arrow" />
    </div>
  )
}

export default function MobileViewShareMyFeedModal() {
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const fetchProductShareOptionsData = useSelector((state: any) => state?.productShareOptions?.data)
  const dispatch = useAppDispatch()
  const [postContent, setPostContent] = useState('')
  const [loading, setLoading] = useState(false)
  const getAllState = useSelector((state: any) => state?.manageMobileModal?.data)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)

  const colletionData = useQuery(GET_SINGLE_COLLECTION, {
    variables: {
      slug: getAllState?.lastModalData?.id?.id,
    },
  })

  const likeData = useQuery(GET_SINGLE_COLLECTION_LIKE, {
    variables: {
      slug: getAllState?.lastModalData?.item,
    },
  })

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2.016,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  const handleClose = () => {
    let datass = fetchMobileModal?.allModalData?.filter(
      (item: { modal: string }) => item?.modal !== fetchMobileModal?.lastModalData?.modal,
    )
    dispatch(
      fetchMobileModalData({
        allModalData: datass,
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
  }

  const postHandler = () => {
    setLoading(true)
    if (fetchMobileModal?.lastModalData?.id?.isGroupModel) {
      sharePostToClubs({
        variables: {
          content: postContent,
          share_post_for: 'GROUP',
          group_id: fetchProductShareOptionsData?.id?.groupData?.id
            ? [fetchProductShareOptionsData?.id?.groupData?.id]
            : [],
          collection_id: colletionData?.data?.getSingleCollection?.data?.id,
        },
        // refetchQueries: [{ query: MY_FEED_QUERY, variables: { page: 1, limit: 10 } }],
      }).then((response) => {
        if (response?.data !== undefined) {
          dispatch(
            fetchMobileModalData({
              allModalData: [
                ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                {
                  modal: 'mobileViewBookMarkMoreOptionModal',
                  isOpen: false,
                },
              ],
              lastModalData: fetchMobileModal?.lastModalData ? fetchMobileModal?.lastModalData : [],
            }),
          )

          dispatch(
            fetchProductImageResizeModal({
              modal: 'mobileProductDetailsShareModal',
              isOpen: false,
              isShareBookmark: false,
            }),
          )

          dispatch(
            fetchProductShareOptions({
              modal: 'sharedSuccessfullModel',
              isOpen: true,
            }),
          )

          setTimeout(() => {
            dispatch(
              fetchProductShareOptions({
                modal: '',
                isOpen: false,
              }),
            )
          }, 2000)
        }
      })
    } else {
      createSharePost({
        variables: {
          input: {
            content: postContent,
            collection_id: colletionData?.data?.getSingleCollection?.data?.id,
            share_post_for: 'POST',
          },
        },
        // refetchQueries: [{ query: MY_FEED_QUERY, variables: { page: 1, limit: 10 } }],
      }).then((response) => {
        if (response?.data !== undefined) {
          dispatch(
            fetchMobileModalData({
              allModalData: [
                ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                {
                  modal: 'mobileViewBookMarkMoreOptionModal',
                  isOpen: false,
                },
              ],
              lastModalData: fetchMobileModal?.lastModalData ? fetchMobileModal?.lastModalData : [],
            }),
          )

          dispatch(
            fetchProductShareOptions({
              modal: 'sharedSuccessfullModel',
              isOpen: true,
            }),
          )

          setTimeout(() => {
            dispatch(
              fetchProductShareOptions({
                modal: '',
                isOpen: false,
              }),
            )
          }, 2000)
        }
      })
    }
  }

  const [createSharePost] = useMutation(SHARE_FEED_POST_MUTATION, {
    onCompleted: (res) => {
      // setLoading(false)
      dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const [sharePostToClubs] = useMutation(SHARE_POST_MUTATION, {
    onCompleted: (res) => {
      setLoading(false)
      dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  return (
    <div className="share-me-feed-section">
      <div className="share-me-feed-modal">
        <div className="share-me-feed-heder-alignment">
          <div className="share-me-feed-heading-alignment">
            <h4>
              {fetchMobileModal?.lastModalData?.id?.isGroupModel
                ? `Share with ${fetchProductShareOptionsData?.id?.groupData?.name}`
                : 'Share on My Feed------------------'}
            </h4>
            <a onClick={() => handleClose()}>Cancel</a>
          </div>
        </div>
        <div className="share-me-feed-details-section">
          <div className="share-me-feed-search-alignment">
            <div className="share-me-profile-img-alignment">
              <img src="/assets/img/profile/profileimage.png" alt="ProfileImg" />
            </div>
            <div className="share-me-feed-search-input-alignment">
              <img src="/assets/icon/feather.svg" alt="PinIcon" />
              <input
                type="text"
                placeholder="Write something..."
                value={postContent}
                onChange={(e) => setPostContent(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="share-me-feed-body-alignment">
          <div className="share-me-feed-all-details-alignment">
            {fetchMobileModal?.lastModalData?.id?.shareData?.length > 2 ? (
              <Slider {...settings}>
                {fetchMobileModal?.lastModalData?.id?.shareData?.map((item: any, index: number) => {
                  return <Product product={item} showProduct={{}} storeData={{}} isForPost={true} index={index} />
                })}
              </Slider>
            ) : (
              <div className="collection-product-grid-view">
                {fetchMobileModal?.lastModalData?.id?.shareData?.data
                  ? fetchMobileModal?.lastModalData?.id?.shareData?.data?.map((b: any, index: number) => {
                      return <Product product={b} showProduct={{}} storeData={{}} isForPost={true} index={index} />
                    })
                  : fetchMobileModal?.lastModalData?.id?.shareData?.map((b: any, index: number) => {
                      return <Product product={b} showProduct={{}} storeData={{}} isForPost={true} index={index} />
                    })}
              </div>
            )}
          </div>

          <div className="shared-profile-name-alignment">
            <div className="shared-profile-name-details">
              <div className="shared-feed-profile-alignment">
                <div className="bookMarkCollectionCardDetails">
                  {colletionData?.data?.getSingleCollection?.data?.product_images?.map((item: any, index: number) => {
                    return (
                      <div className="bookMarkCollectionCardImg" key={index}>
                        <img src={item ? item : '/assets/img/bookMark-img.png'} alt="bootmark-img" />
                      </div>
                    )
                  })}
                  {/* <div className="bookMarkCollectionCardImg">
                    <img src="/assets/seller/img/shoes0.png" alt="bootmark-img" />
                  </div>
                  <div className="bookMarkCollectionCardImg">
                    <img src="/assets/img/bookMark-img.png" alt="bootmark-img" />
                  </div>
                  <div className="bookMarkCollectionCardImg">
                    <img src="/assets/img/bookMark-img.png" alt="bootmark-img" />
                  </div> */}
                </div>
              </div>
              <div className="shared-all-profile-name">
                <h6>{colletionData?.data?.getSingleCollection?.data?.name}</h6>
                <div className="name-child-details-alignment">
                  <span>{colletionData?.data?.getSingleCollection?.data?.product_count} Products</span>
                  <p></p>
                  <span>{colletionData?.data?.getSingleCollection?.data?.likes} likes</span>
                </div>
              </div>
            </div>

            <div className="shared-like-alignment">
              {likeData?.data?.getCollectionSingleLike?.isLike ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M7.5 4C4.46244 4 2 6.43905 2 9.44773C2 14.8955 8.5 19.848 12 21C15.5 19.848 22 14.8955 22 9.44773C22 6.43905 19.5375 4 16.5 4C14.6399 4 12.9954 4.91467 12 6.31469C11.0046 4.91467 9.36015 4 7.5 4Z"
                    stroke="#7A7E88"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M7.5 4C4.46244 4 2 6.43905 2 9.44773C2 14.8955 8.5 19.848 12 21C15.5 19.848 22 14.8955 22 9.44773C22 6.43905 19.5375 4 16.5 4C14.6399 4 12.9954 4.91467 12 6.31469C11.0046 4.91467 9.36015 4 7.5 4Z"
                    stroke="#7A7E88"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
          </div>
        </div>

        {loading ? (
          <Spin />
        ) : (
          <div className="share-me-bottom-details-alignment" onClick={() => postHandler()}>
            <button>Share</button>
          </div>
        )}
      </div>
    </div>
  )
}
