// @ts-nocheck

import React, { useEffect, useState } from 'react'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchSingleOrderDetail } from 'src/store/slices/fetchOrderDetail'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useMutation } from '@apollo/client'
import CANCLE_ORDER from 'src/graphql/mutations/cancelOrder'
import moment from 'moment'
import {
  X, Calendar, Truck, MessageCircle, RotateCcw, Star,
  HeadphonesIcon, ShoppingCart, Printer, MapPin, CreditCard,
  Package, AlertCircle, ArrowRight
} from 'lucide-react'

export default function OrderDetailModal() {
  const dispatch = useAppDispatch() 
  const getSingleDetail = useSelector((state: any) => state?.fetchOrderDetail?.getSingleDetail)
  
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const [selectedAction, setSelectedAction] = useState<'tracking' | 'cancel' | 'return' | 'replace' | null>(null)

  useEffect(() => {
    if (getAllState?.lastModalData?.id?.order_master_id) {
      dispatch(
        fetchSingleOrderDetail({
          order_master_id: getAllState?.lastModalData?.id?.order_master_id,
          order_item_id: getAllState?.lastModalData?.id?.order_item_id,
        }),
      )
    }
  }, [getAllState?.lastModalData?.id?.order_master_id])

  const [cancelOrders] = useMutation(CANCLE_ORDER, {
    onCompleted: () => {
      dispatch(
        fetchModalData({
          allModalData: [
            ...getAllState?.allModalData,
            {
              modal: 'orderDetailModal',
              isOpen: false,
              modalFor: 'bigModal',
            },
          ],
          lastModalData: getAllState?.lastModalData,
        }),
      )
    },
    onError: (error) => {
      console.error('Cancel order error:', error)
    },
  })

  const handleCancelOrder = () => {
    cancelOrders({
      variables: {
        orderItemId: getSingleDetail?.orderItems?.[0]?.id,
      },
    })
  }

  const handleCloseModal = () => {
    dispatch(
      fetchModalData({
        allModalData: [
          ...getAllState?.allModalData,
          {
            modal: 'orderDetailModal',
            isOpen: false,
            modalFor: 'bigModal',
          },
        ],
        lastModalData: getAllState?.lastModalData,
      })
    )
  }

  const handlePrintInvoice = () => {
    const printWindow = window.open('', '_blank')
    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
            <title>Order Invoice #${getSingleDetail?.order_id}</title>
            <style>
              body { font-family: Arial, sans-serif; margin: 40px; }
              .header { text-align: center; margin-bottom: 30px; }
              .order-info { margin-bottom: 20px; }
              .items { border-collapse: collapse; width: 100%; margin: 20px 0; }
              .items th, .items td { border: 1px solid #ddd; padding: 8px; text-align: left; }
              .total { text-align: right; margin-top: 20px; }
              .address { margin-top: 20px; }
              @media print {
                body { margin: 0; }
                button { display: none; }
              }
            </style>
          </head>
          <body>
            <div class="header">
              <h1>Order Invoice</h1>
              <p>Order #${getSingleDetail?.order_id}</p>
              <p>Date: ${moment(getSingleDetail?.data?.orderDate).format('MMM D, YYYY')}</p>
            </div>
            
            <div class="order-info">
              <p><strong>Order Status:</strong> ${getSingleDetail?.orderItems[0]?.order_status}</p>
              <p><strong>Payment Status:</strong> ${getSingleDetail?.isPaymentDone ? 'Completed' : 'Pending'}</p>
            </div>

            <table class="items">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                ${getSingleDetail?.orderItems?.map(item => `
                  <tr>
                    <td>${item.product?.title}</td>
                    <td>${item.quantity}</td>
                    <td>$${item.product_price}</td>
                    <td>$${item.totalAmount}</td>
                  </tr>
                `).join('')}
              </tbody>
            </table>

            <div class="total">
              <p><strong>Subtotal:</strong> $${getSingleDetail?.totalBasicAmount}</p>
              <p><strong>Shipping:</strong> $${getSingleDetail?.orderItems?.[0]?.shipment_charges}</p>
              <p><strong>Tax:</strong> $${getSingleDetail?.totalTaxAmount}</p>
              ${getSingleDetail?.orderItems?.[0]?.discountAmount ?
          `<p><strong>Discount:</strong> -$${getSingleDetail?.orderItems?.[0]?.discountAmount}</p>` : ''}
              <p><strong>Total:</strong> $${getSingleDetail?.totalAmount}</p>
            </div>

            <div class="address">
              <h3>Shipping Address:</h3>
              <p>${getSingleDetail?.shipping_detail?.streetAddress}</p>
              <p>${getSingleDetail?.shipping_detail?.city}, 
                 ${getSingleDetail?.shipping_detail?.state} 
                 ${getSingleDetail?.shipping_detail?.zipcode}</p>
              <p>${getSingleDetail?.shipping_detail?.country}</p>
            </div>

            <button onclick="window.print()" style="margin-top: 20px; padding: 10px 20px;">
              Print Invoice
            </button>
          </body>
        </html>
      `)
      printWindow.document.close()
    }
  }

  const getStatusColor = (status: string) => {
    const statusMap = {
      delivered: 'bg-green-100 text-green-800',
      in_transit: 'bg-blue-100 text-blue-800',
      confirmed: 'bg-purple-100 text-purple-800',
      pending: 'bg-yellow-100 text-yellow-800',
      cancelled: 'bg-red-100 text-red-800',
      returned: 'bg-gray-100 text-gray-800',
    }
    return statusMap[status] || 'bg-gray-100 text-gray-800'
  }

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount)
  }

  const renderActionPanel = () => {
    switch (selectedAction) {
      case 'tracking':
        return (
          <div className="p-6 bg-gray-50 rounded-lg space-y-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-900">Tracking Information</h3>
              <button
                onClick={() => setSelectedAction(null)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-600">Tracking Number:</span>
                <a
                  href={getSingleDetail?.data?.orderItems?.[0]?.trackingUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm text-blue-600 hover:text-blue-700"
                >
                  {getSingleDetail?.data?.orderItems?.[0]?.tracking_number}
                  <ArrowRight className="w-4 h-4 inline-block ml-1" />
                </a>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-600">Carrier:</span>
                <span className="text-sm text-gray-900">{getSingleDetail?.data?.orderItems?.[0]?.carrier || 'UPS'}</span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-600">Status:</span>
                <span className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(getSingleDetail?.data?.orderItems?.[0]?.order_status)}`}>
                  {getSingleDetail?.data?.orderItems?.[0]?.order_status}
                </span>
              </div>
            </div>
          </div>
        )
      case 'cancel':
        return (
          <div className="p-6 bg-gray-50 rounded-lg space-y-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-900">Cancel Order</h3>
              <button
                onClick={() => setSelectedAction(null)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <p className="text-sm text-gray-500">
              Are you sure you want to cancel this order? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-4 mt-4">
              <button
                onClick={() => setSelectedAction(null)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                No, Keep Order
              </button>
              <button
                onClick={handleCancelOrder}
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
              >
                Yes, Cancel Order
              </button>
            </div>
          </div>
        )
      case 'return':
        return (
          <div className="p-6 bg-gray-50 rounded-lg space-y-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-900">Return Items</h3>
              <button
                onClick={() => setSelectedAction(null)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <div className="space-y-4">
              <p className="text-sm text-gray-500">
                Select the items you want to return and choose a reason for the return.
              </p>
              {getSingleDetail?.data?.orderItems?.map((item) => (
                <div key={item.id} className="flex items-center space-x-4">
                  <input
                    type="checkbox"
                    id={`return-${item.id}`}
                    className="h-4 w-4 text-blue-600 rounded border-gray-300"
                  />
                  <label htmlFor={`return-${item.id}`} className="text-sm text-gray-900">
                    {item.product?.title}
                  </label>
                </div>
              ))}
              <select className="mt-2 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md">
                <option value="">Select a reason</option>
                <option value="wrong-item">Wrong item received</option>
                <option value="damaged">Item damaged/defective</option>
                <option value="not-needed">No longer needed</option>
                <option value="not-as-described">Not as described</option>
              </select>
              <button className="w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700">
                Submit Return Request
              </button>
            </div>
          </div>
        )
      case 'replace':
        return (
          <div className="p-6 bg-gray-50 rounded-lg space-y-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-900">Replace Items</h3>
              <button
                onClick={() => setSelectedAction(null)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <div className="space-y-4">
              <p className="text-sm text-gray-500">
                Select the items you want to replace and choose a reason for the replacement.
              </p>
              {getSingleDetail?.data?.orderItems?.map((item) => (
                <div key={item.id} className="flex items-center space-x-4">
                  <input
                    type="checkbox"
                    id={`replace-${item.id}`}
                    className="h-4 w-4 text-blue-600 rounded border-gray-300"
                  />
                  <label htmlFor={`replace-${item.id}`} className="text-sm text-gray-900">
                    {item.product?.title}
                  </label>
                </div>
              ))}
              <select className="mt-2 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md">
                <option value="">Select a reason</option>
                <option value="defective">Product is defective</option>
                <option value="damaged">Product arrived damaged</option>
                <option value="wrong-size">Wrong size</option>
                <option value="not-as-described">Product not as described</option>
              </select>
              <button className="w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700">
                Submit Replacement Request
              </button>
            </div>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className="bg-white w-full h-full flex flex-col">
      {/* Modal Header */}
      <div className="flex items-center justify-between p-6 border-b border-gray-200 flex-shrink-0">
        <h2 className="text-xl font-semibold text-gray-900">
          Order #{getSingleDetail?.order_id}
        </h2>
        <div className="flex items-center space-x-4">
          <button
            onClick={handlePrintInvoice}
            className="text-gray-500 hover:text-gray-700"
            title="Print Invoice"
          >
            <Printer className="w-5 h-5" />
          </button>
          <button
            onClick={handleCloseModal}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
      </div>

      {/* Order Details - Scrollable Content */}
      <div className="flex-1 p-6 overflow-y-auto">
        {/* Order Summary */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 mb-6">
          <div className="p-6">
            <div className="flex items-center justify-between pb-4 border-b border-gray-100">
              <div className="flex items-center space-x-4">
                <div>
                  <p className="text-sm font-medium text-gray-600">Order Placed</p>
                  <div className="flex items-center text-gray-500">
                    <Calendar className="w-4 h-4 mr-1" />
                    <span className="text-sm">
                      {moment(getSingleDetail?.orderDate).format('MMM D, YYYY')}
                    </span>
                  </div>
                </div>
                <div className="h-8 w-px bg-gray-200" />
                <div>
                  <p className="text-sm font-medium text-gray-600">Order ID</p>
                  <p className="text-sm text-gray-500">#{getSingleDetail?.order_id}</p>
                </div>
                <div className="h-8 w-px bg-gray-200" />
                <div>
                  <p className="text-sm font-medium text-gray-600">Total</p>
                  <p className="text-sm font-medium text-gray-900">
                    {formatCurrency(getSingleDetail?.totalAmount)}
                  </p>
                </div>
              </div>
              <span className={`px-3 py-1 rounded-full text-xs font-medium ${getStatusColor(getSingleDetail?.orderItems?.[0]?.order_status)}`}>
                {getSingleDetail?.orderItems?.[0]?.order_status}
              </span>
            </div>

            {/* Products */}
            <div className="mt-6 space-y-6">
              {getSingleDetail?.orderItems?.map((item) => (
                <div key={item.id} className="flex items-start space-x-6">
                  <div className="flex-shrink-0">
                    <img
                      src={item.product?.images?.[0]?.src}
                      alt={item.product?.title}
                      className="w-24 h-24 object-cover rounded-lg"
                    />
                  </div>
                  <div className="flex-1">
                    <div className="flex justify-between">
                      <div>
                        <a
                          href={`/product/${item.product?.id}`}
                          className="text-base font-medium text-gray-900 hover:text-blue-600"
                        >
                          {item.product?.title}
                        </a>
                        <p className="mt-1 text-sm text-gray-500">Quantity: {item.quantity}</p>
                        {item.discountAmount > 0 && (
                          <p className="mt-1 text-sm text-green-600">
                            Saved {formatCurrency(item.discountAmount)}
                          </p>
                        )}
                      </div>
                      <div className="text-right">
                        <p className="text-base font-medium text-gray-900">
                          {formatCurrency(item.totalAmount)}
                        </p>
                        <p className="text-sm text-gray-500">
                          {formatCurrency(item.product_price)} each
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Cost Breakdown */}
            <div className="mt-6 pt-6 border-t border-gray-100">
              <div className="space-y-4">
                <div className="flex justify-between text-sm text-gray-600">
                  <span>Subtotal</span>
                  <span>{formatCurrency(getSingleDetail?.totalBasicAmount || 0)}</span>
                </div>
                <div className="flex justify-between text-sm text-gray-600">
                  <span>Shipping</span>
                  <span>{formatCurrency(getSingleDetail?.orderItems?.[0]?.shipment_charges || 0)}</span>
                </div>
                <div className="flex justify-between text-sm text-gray-600">
                  <span>Tax</span>
                  <span>{formatCurrency(getSingleDetail?.totalTaxAmount || 0)}</span>
                </div>
                {getSingleDetail?.orderItems?.[0]?.discountAmount > 0 && (
                  <div className="flex justify-between text-sm text-green-600">
                    <span>Discount</span>
                    <span>-{formatCurrency(getSingleDetail?.orderItems?.[0]?.discountAmount)}</span>
                  </div>
                )}
                <div className="flex justify-between text-base font-medium text-gray-900 pt-4 border-t border-gray-100">
                  <span>Total</span>
                  <span>{formatCurrency(getSingleDetail?.totalAmount || 0)}</span>
                </div>
              </div>
            </div>

            {/* Shipping and Payment Info */}
            <div className="mt-6 pt-6 border-t border-gray-100">
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <div className="flex items-center space-x-2 mb-4">
                    <MapPin className="w-5 h-5 text-gray-400" />
                    <h3 className="text-sm font-medium text-gray-900">Shipping Address</h3>
                  </div>
                  <p className="text-sm text-gray-500">
                    {getSingleDetail?.shipping_detail?.streetAddress}<br />
                    {getSingleDetail?.shipping_detail?.city}, {getSingleDetail?.shipping_detail?.state} {getSingleDetail?.shipping_detail?.zipcode}<br />
                    {getSingleDetail?.shipping_detail?.country}
                  </p>
                </div>
                <div>
                  <div className="flex items-center space-x-2 mb-4">
                    <CreditCard className="w-5 h-5 text-gray-400" />
                    <h3 className="text-sm font-medium text-gray-900">Payment Information</h3>
                  </div>
                  <p className="text-sm text-gray-500">
                    Payment ID: {getSingleDetail?.paymentId}<br />
                    {getSingleDetail?.isPaymentDone ? 'Payment Completed' : 'Payment Pending'}
                  </p>
                </div>
              </div>
            </div>

            {/* Action Panel */}
            {renderActionPanel()}

            {/* Action Buttons */}
            <div className="mt-6 pt-6 border-t border-gray-100">
              <div className="flex flex-wrap gap-3">
                {getSingleDetail?.orderItems?.[0]?.trackingUrl && (
                  <button
                    onClick={() => setSelectedAction('tracking')}
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-blue-700 bg-blue-50 rounded-lg hover:bg-blue-100"
                  >
                    <Package className="w-4 h-4 mr-2" />
                    Track Package
                  </button>
                )}
                {getSingleDetail?.orderItems?.[0]?.order_status !== 'cancelled' && (
                  <button
                    onClick={() => setSelectedAction('cancel')}
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-red-700 bg-red-50 rounded-lg hover:bg-red-100"
                  >
                    <AlertCircle className="w-4 h-4 mr-2" />
                    Cancel Order
                  </button>
                )}
                {['delivered', 'in_transit'].includes(getSingleDetail?.orderItems?.[0]?.order_status) && (
                  <>
                    <button
                      onClick={() => setSelectedAction('return')}
                      className="inline-flex items-center px-4 py-2 text-sm font-medium text-orange-700 bg-orange-50 rounded-lg hover:bg-orange-100"
                    >
                      <RotateCcw className="w-4 h-4 mr-2" />
                      Return Items
                    </button>
                    <button
                      onClick={() => setSelectedAction('replace')}
                      className="inline-flex items-center px-4 py-2 text-sm font-medium text-purple-700 bg-purple-50 rounded-lg hover:bg-purple-100"
                    >
                      <ShoppingCart className="w-4 h-4 mr-2" />
                      Replace Items
                    </button>
                  </>
                )}
                <button
                  onClick={handlePrintInvoice}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-50 rounded-lg hover:bg-gray-100"
                >
                  <Printer className="w-4 h-4 mr-2" />
                  Print Invoice
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
