import React, { useEffect, useState } from 'react'
import PeopleFollowers from '../PeopleFollowers'
import { useMutation } from '@apollo/client'
import { useAuth } from 'src/utils/auth'
import SEND_FRIEND_REQUEST from 'src/graphql/mutations/sendFriendRequest'
import DECLINE_FRIEND_REQUEST from 'src/graphql/mutations/declineFriendRequest'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchAllPeople } from 'src/store/slices/fetchAllPeopleSlice'
import useDebounce from 'src/hoc/useDebounce'

export default function FindPeopleSection({ index, search, setSearch }) {
  const { user } = useAuth()
  const dispatch = useAppDispatch()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const peopleData = useSelector((state: any) => state?.peopleData)
  const debouncedSearch = useDebounce(search, 500)

  useEffect(() => {
    setPage(1)
    dispatch(
      fetchAllPeople({
        limit: limit,
        page: 1,
        type: 'ALL_PEOPLE',
        prevPost: [],
        search: debouncedSearch,
      }),
    )
  }, [debouncedSearch])

  useEffect(() => {
    setPage(1)
    dispatch(
      fetchAllPeople({
        limit: limit,
        page: 1,
        type: 'ALL_PEOPLE',
        prevPost: [],
        search: debouncedSearch,
      }),
    )
  }, [])

  const [sendFriendRequest] = useMutation(SEND_FRIEND_REQUEST, {
    onCompleted: () => {
      setPage(1)
      dispatch(fetchAllPeople({ limit: limit, page: 1, type: 'ALL_PEOPLE', prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const [declineFriendRequest] = useMutation(DECLINE_FRIEND_REQUEST, {
    onCompleted: () => {
      setPage(1)
      dispatch(fetchAllPeople({ limit: limit, page: 1, type: 'ALL_PEOPLE', prevPost: [] }))
    },
    onError: (error) => console.log(error),
  })

  const declineFriendRequestCall = (people) => {
    if (user) {
      declineFriendRequest({
        variables: { user_id: user?.id, friend_id: people?.id },
      })
    } else {
      handleRedirect()
    }
  }

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }


  const sendFriendRequestCall = (people) => {
    if (user) {
      sendFriendRequest({
        variables: {
          friend_id: people?.id,
          user_id: user?.id,
          isActive: true,
          isFriend: true,
        },
      })

    } else {
      handleRedirect()
    }
  }

  const fetchMorePeople = (page, limit) => {
    dispatch(
      fetchAllPeople({
        limit: limit,
        page: page,
        type: 'ALL_PEOPLE',
        prevPost: peopleData?.allPeople,
        search: debouncedSearch,
      }),
    )
  }

  const handleSeeMore = () => {
    setPage((prevState) => prevState + 1)
    fetchMorePeople(page + 1, limit)
  }

  useEffect(() => {
    if (index === '3') {
      setPage(1)
      dispatch(
        fetchAllPeople({
          limit: limit,
          page: 1,
          type: 'ALL_PEOPLE',
          prevPost: [],
        }),
      )
    }
  }, [index])

  return (
    <PeopleFollowers
      handleSeeMore={handleSeeMore}
      data={peopleData?.allPeople}
      title={'Find People'}
      declineFriendRequestCall={declineFriendRequestCall}
      sendFriendRequestCall={sendFriendRequestCall}
      setSearch={setSearch}
    />
  )
}
