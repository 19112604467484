import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import MY_FEED_QUERY from 'src/graphql/queries/getMyFeed'
import GET_PRODUCT_RECOMMENDATION from 'src/graphql/queries/getProductRecommendation'
import GET_STORE_POST from 'src/graphql/queries/getStorePosts'
import GET_STORE_FOLLOWERS from 'src/graphql/queries/sellerQueries/getStoreFollowers'
import client1 from 'src/utils/apolloClient'

interface fetchAllFeed {
  loading: true | false
  error: {} | null
  called: true | false
  allPost: []
  productRecommendations: []
  groupPosts: []
  userPosts: []
}

const initialState = {
  loading: false,
  error: null,
  currentStep: '',
  allPost: [],
  productRecommendations: [],
  groupPosts: [],
  userPosts: [],
  called: false,
} as fetchAllFeed

export const fetchAllFeedSliceData = createAsyncThunk('fetchScroll/fetchAllFeedSliceData', async (post: any) => {
  const { data } = await client1.query({
    query: MY_FEED_QUERY,
    variables: {
      page: post?.page,
      limit: post?.limit,
    },
    fetchPolicy: 'network-only',
  })
  return {
    data: [...post?.prevPost, ...data?.getAllPost?.data],
    isEnd: data?.getAllPost?.data?.length > 0 ? false : true,
  }
})

export const fetchGroupPost = createAsyncThunk('fetchScroll/fetchGroupPost', async (post: any) => {
  const { data } = await client1.query({
    query: MY_FEED_QUERY,
    variables: {
      group_id: post?.group_id,
      page: post?.page,
      limit: post?.limit,
    },
    fetchPolicy: 'network-only',
  })
  return [...post?.prevPost, ...data?.getAllPost?.data]
})

export const fetchUserPost = createAsyncThunk('fetchScroll/fetchUserPost', async (post: any) => {
  const { data } = await client1.query({
    query: MY_FEED_QUERY,
    variables: {
      user_id: post?.user_id,
      page: post?.page,
      limit: post?.limit,
    },
    fetchPolicy: 'network-only',
  })
  return {
    data: [...post?.prevPost, ...data?.getAllPost?.data],
    isEnd: data?.getAllPost?.data?.length > 0 ? false : true,
  }
})

export const fetchStorePost = createAsyncThunk('fetchScroll/fetchStorePost', async (post: any) => {
  const { data } = await client1.query({
    query: GET_STORE_POST,
    variables: {
      slug: post?.slug,
      page: post?.page,
      limit: post?.limit,
    },
    fetchPolicy: 'network-only',
  })
  return data?.getStorePosts?.data
})

export const fetchStoreFollowers = createAsyncThunk('fetchScroll/fetchStoreFollowers', async (post: any) => {
  const { data } = await client1.query({
    query: GET_STORE_FOLLOWERS,
    variables: {
      slug: post,
    },
    fetchPolicy: 'network-only',
  })
  return data?.getStoreFollowers?.data
})

export const fetchProductRecommendation = createAsyncThunk(
  'fetchScroll/fetchProductRecommendation',
  async (productRecommendation: any) => {
    const { data } = await client1.query({
      query: GET_PRODUCT_RECOMMENDATION,
      variables: {
        type: productRecommendation?.type,
      },
      fetchPolicy: 'network-only',
    })

    return data?.getProductRecommendation?.data
    // data: [...productRecommendation?.prevRecommendation, [ ...data?.getProductRecommendation?.data]],
    // isEnd: data?.getAllPost?.data?.length > 0 ? false : true,
    // }
  },
)

const fetchAllFeed = createSlice({
  name: 'fetchScroll',
  initialState,
  reducers: {
    setCurrentStep: (state: any, action) => {
      state.allPost = action.payload
    },
    setGroupPosts: (state: any, action) => {
      state.groupPosts = action.payload
    },
    setStorePosts: (state: any, action) => {
      state.storePost = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllFeedSliceData.pending, (state, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchAllFeedSliceData.fulfilled, (state, action: any) => {
      state.loading = 'success'
      state.allPost = action?.payload?.data
      state.isEnd = action?.payload?.isEnd
    })
    builder.addCase(fetchAllFeedSliceData.rejected, (state, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })
    builder.addCase(fetchGroupPost.pending, (state, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchGroupPost.fulfilled, (state, action) => {
      state.loading = 'success'
      state.groupPosts = action?.payload
    })
    builder.addCase(fetchGroupPost.rejected, (state, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })
    builder.addCase(fetchUserPost.pending, (state, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchUserPost.fulfilled, (state, action) => {
      state.loading = 'success'
      state.userPosts = action?.payload?.data
      state.isuserPostsEnd = action?.payload?.isEnd
    })
    builder.addCase(fetchUserPost.rejected, (state, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })

    builder.addCase(fetchStorePost.pending, (state, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchStorePost.fulfilled, (state, action) => {
      state.loading = 'success'
      state.storePost = action?.payload
    })
    builder.addCase(fetchStorePost.rejected, (state, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })

    builder.addCase(fetchStoreFollowers.pending, (state, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchStoreFollowers.fulfilled, (state, action) => {
      state.loading = 'success'
      state.storeFollowers = action?.payload
    })
    builder.addCase(fetchStoreFollowers.rejected, (state, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })
    builder.addCase(fetchProductRecommendation.pending, (state, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchProductRecommendation.fulfilled, (state, action) => {
      state.productRecommendations = action?.payload?.data
      state.isEnd = action?.payload?.isEnd
      state.loading = 'success'
      // state.productRecommendations = action?.payload
    })
    builder.addCase(fetchProductRecommendation.rejected, (state, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })
  },
})

// export var _actions = fetchAllFeed.actions
export const { setCurrentStep, setGroupPosts, setStorePosts } = fetchAllFeed.actions
export default fetchAllFeed.reducer
