import PostComment from "./PostComment";
import PostLike from "./PostLike";
import MessageAlert from "./MessageAlert";
import StoreProducts from "./StoreProducts";
import ShippingProduct from "./ShippingProduct";
import CartReminder from "./CartReminder";
import ClubInvite from "./ClubInvite";
import ClubActivity from "./ClubActivity";
import ClubComments from "./ClubComments";
import PostView from "./PostView";
import NewFollower from "./NewFollowers";
import Recommendation from "./Recommendations";
import LiveEvents from "./LiveEvents";
import NotificationPlaceholder from "./NotificationPlaceholder";

export const NOTIFICATION_COMPONENTS_CONSTANTS = {
    MESSAGE_ALERT: 'message-alert',
    CART_REMINDER: 'cart-reminder',
    CLUB_INVITE: 'club-invite',
    CLUB_ACTIVITY: 'club-activity',
    CLUB_COMMENTS: 'club-comment',
    LIVE_EVENT: 'live-event',
    NEW_FOLLOWER: 'new-follower',
    POST_COMMENT: 'post-comment',
    POST_LIKE: 'post-like',
    POST_VIEW: 'post-view',
    RECOMMENDATION: 'recommendation',
    SHIPPING_PRODUCT: 'shipping-product',
    STORE_PRODUCT: 'store-product',
}

interface NotificationsComponentsProps {
    notification: any;
    setOpen: any;
}

const NotificationsComponents = ({ notification, setOpen }: NotificationsComponentsProps) => {
    if (notification?.templateIdentifier?.includes(NOTIFICATION_COMPONENTS_CONSTANTS.MESSAGE_ALERT)) {
        return <MessageAlert notification={notification} />
    } else if (notification?.templateIdentifier?.includes(NOTIFICATION_COMPONENTS_CONSTANTS.POST_LIKE)) {
        return <PostLike notification={notification} />
    } else if (notification?.templateIdentifier?.includes(NOTIFICATION_COMPONENTS_CONSTANTS.POST_COMMENT)) {
        return <PostComment notification={notification} />
    } else if (notification?.templateIdentifier?.includes(NOTIFICATION_COMPONENTS_CONSTANTS.STORE_PRODUCT)) {
        return <StoreProducts notification={notification} />
    } else if (notification?.templateIdentifier?.includes(NOTIFICATION_COMPONENTS_CONSTANTS.SHIPPING_PRODUCT)) {
        return <ShippingProduct notification={notification} />
    } else if (notification?.templateIdentifier?.includes(NOTIFICATION_COMPONENTS_CONSTANTS.CART_REMINDER)) {
        return <CartReminder notification={notification} />
    } else if (notification?.templateIdentifier?.includes(NOTIFICATION_COMPONENTS_CONSTANTS.CLUB_INVITE)) {
        return <ClubInvite notification={notification} setOpen={setOpen} />
    } else if (notification?.templateIdentifier?.includes(NOTIFICATION_COMPONENTS_CONSTANTS.CLUB_ACTIVITY)) {
        return <ClubActivity notification={notification} />
    } else if (notification?.templateIdentifier?.includes(NOTIFICATION_COMPONENTS_CONSTANTS.CLUB_COMMENTS)) {
        return <ClubComments notification={notification} />
    } else if (notification?.templateIdentifier?.includes(NOTIFICATION_COMPONENTS_CONSTANTS.POST_VIEW)) {
        return <PostView notification={notification} />
    } else if (notification?.templateIdentifier?.includes(NOTIFICATION_COMPONENTS_CONSTANTS.NEW_FOLLOWER)) {
        return <NewFollower notification={notification} setOpen={setOpen} />
    } else if (notification?.templateIdentifier?.includes(NOTIFICATION_COMPONENTS_CONSTANTS.RECOMMENDATION)) {
        return <Recommendation notification={notification} />
    } else if (notification?.templateIdentifier?.includes(NOTIFICATION_COMPONENTS_CONSTANTS.LIVE_EVENT)) {
        return <LiveEvents notification={notification} setOpen={setOpen} />
    } else {
        return <NotificationPlaceholder notification={notification} />
    }
};

export default NotificationsComponents;
