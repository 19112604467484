import gql from 'graphql-tag'

const GET_MIN_MAX_AVG_PRICE_OF_PRODUCTS = gql`
query GetMinMaxAvgPriceOfProducts($input: getMinMaxAvgPriceOfProductsInput) {
  getMinMaxAvgPriceOfProducts(input: $input) {
    success
    message
    data {
      minPrice
      maxPrice
      avgPrice
    }
  }
}

`
export default GET_MIN_MAX_AVG_PRICE_OF_PRODUCTS
