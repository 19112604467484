// import React from 'react'
// import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js'
// const CARD_ELEMENT_OPTIONS = {
//   style: {
//     base: {
//       color: '#32325d',
//       fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
//       fontSmoothing: 'antialiased',
//       fontSize: '13px',
//       '::placeholder': {
//         color: '#aab7c4',
//       },
//     },
//     invalid: {
//       color: '#fa755a',
//       iconColor: '#fa755a',
//     },
//   },
// }

// export default function CardInput() {
//   return (
//     <div className="card-input-container">
//       <div>
//         <label>Card Number</label>
//         <div tabIndex={0} className="card-input-field">
//           <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
//         </div>
//       </div>
//       <div className="card-input-grid">
//         <div>
//           <label>Expiration Date</label>
//           <div className="card-input-field">
//             <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
//           </div>
//         </div>
//         <div>
//           <label>CVC</label>
//           <div className="card-input-field">
//             <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

import React from 'react'
import { CardElement } from '@stripe/react-stripe-js'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

export default function CardInput() {
  return <CardElement options={CARD_ELEMENT_OPTIONS} />
}
