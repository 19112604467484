import React from 'react'
import styles from './Newarrivaltitle.module.scss'
import { useSelector } from 'react-redux'
import NewArrival from '../NewArrival'

const Newarrivalcartoon = '/assets/img/arrivaltitle/arrivalcartoon.png'

export default function Newartivaltitle() {
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  // const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)

  return (
    <>
      <div className={styles.Newarrivaltitlemain} style={{ background: getAllState?.lastModalData?.id?.color }}>
        <div className={styles.Newarrivaltitletextmain}>
          <h4>{getAllState?.lastModalData?.id?.title || 'Hot Deals'}</h4>
          <p>{getAllState?.lastModalData?.id?.description}</p>
        </div>

        <div className={styles.Newarrivalcartoon}>
          <img src={Newarrivalcartoon} alt="Newarrivalcartoon" />
        </div>
      </div>
      {/* <NewArrival /> */}
    </>
  )
}
