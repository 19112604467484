import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { MEN_STYLE } from 'src/Helpers/Json/ManCategoryList'
import { WOMEN_FIND_YOUR_STYLE } from 'src/Helpers/Json/WomanCategoryList'

interface styleDetailModal {
  loading: true | false
  error: {} | null
  called: true | false
  data: any
}

const initialState = {
  loading: false,
  error: null,
  data: { isEdit: false, modal: '', id: 0, for: '' },
  called: false,
} as styleDetailModal

export const manageStyleDetail = createAsyncThunk('fetchStyleModalData/manageStyleDetail', async (data: any) => {
  let filterData
  if (data?.id !== '') {
    if (data?.for === 'men') {
      if (data?.type === 'styleDetail') {
        filterData = MEN_STYLE.find((category) => category?.name === data?.id)
      }
    }
    if (data?.for === 'women') {
      if (data?.type === 'styleDetail') {
        filterData = WOMEN_FIND_YOUR_STYLE.find((category) => category?.name === data?.id)
      }
    }
    if (filterData) {
      let finalData = { ...data, id: filterData }
      return finalData
    }
  }
})

const styleDetailModal = createSlice({
  name: 'fetchStyleModalData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(manageStyleDetail.pending, (state, action) => {
      state.loading = true
      state.error = null
      state.called = true
    })
    builder.addCase(manageStyleDetail.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
      state.called = true
    })
    builder.addCase(manageStyleDetail.rejected, (state, action) => {
      state.loading = false
      state.called = false
      state.error = action.error
      console.error('Error fetching cards!')
    })
  },
})

// export var _actions = styleDetailModal.actions
export default styleDetailModal.reducer
