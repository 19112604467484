import React, { useEffect, useState } from 'react';
import { Popover } from 'antd'
import {
    NovuProvider,
    NotificationBell,
} from '@novu/notification-center';

import NotificationsInbox from './Notifications-Inbox';

interface NotificationsProps {
    userId: string;
}

const Notifications: React.FC<NotificationsProps> = ({ userId }) => {
    const [open, setOpen] = useState(false);

    return (
        <div>
            <NovuProvider
                subscriberId={String(userId)}
                applicationIdentifier={process?.env?.NEXT_PUBLIC_NOVU_APP_ID}
                backendUrl='https://novu-api.bluejestic.com'
                socketUrl='https://novu-ws.bluejestic.com'
                initialFetchingStrategy={{ fetchNotifications: true, fetchUserPreferences: true, fetchUnreadCount: true, fetchUnseenCount: true }}
            >
                <div>
                    <Popover
                        content={<NotificationsInbox setOpen={setOpen} />}
                        placement="bottomRight"
                        trigger="click"
                        zIndex={99999999999}
                        arrowPointAtCenter={false}
                        open={open}
                        onOpenChange={(open) => { setOpen(open) }}
                    >
                        <div>
                            <NotificationBell />
                        </div>
                    </Popover>
                </div>
            </NovuProvider>
            <div />
        </div>
    );
};

export default Notifications; 