export const MAN_CATEGORY = 'Men'

export const MEN_BANNER_VIDEO = [
  "https://bluejestic-media.storage.googleapis.com/bluejestic-stage/men/banner/VEED_GQ Middle East _ Men's Fashion Film-1702277154755-913652731.mp4",
  'https://bluejestic-media.s3.amazonaws.com/fashion/men/banner/bannerImg-1674277014841-201899188.png',
]

export const MEN_STYLE = [
  {
    name: 'Athleisure',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Athleisure 1-1701750012675-117558396.png',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Athleisure 1-1701751383659-909097772.png',
    banneText:
      'Elevate your style and comfort with our Mens Athleisure collection, made for both performance and everyday wear',
  },
  {
    name: 'Business',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/image 168-1701750492547-530326751.png',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/image 168-1701751520430-989320103.png',
    banneText:
      'Elevate your style and comfort with our Mens Athleisure collection, made for both performance and everyday wear',
  },
  {
    name: 'Casual',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/oyipbf1642472528321 3-1701750544073-804035339.png',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/oyipbf1642472528321 2-1701751712623-291529168.png',
    banneText:
      'Stay stylish and comfortable with our mens casual collection. From everyday wear to weekend essentials, elevate your look with our versatile selection of clothing',
  },
  {
    name: 'cowboy',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Rectangle 15780-1701750583227-231505628.png',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Rectangle 15780-1701751743975-753465177.png',
    banneText:
      'Channel your inner cowboy with our mens cowboy collection. Authentic western wear including boots, hats and denim',
  },
  {
    name: 'Emo',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Rectangle 15780 (1)-1701750619154-733783493.png',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Rectangle 15837-1701751999639-593624816.png',
    banneText: 'Channel your inner emo with our mens collection. Band t-shirts, skinny jeans and studded belts',
  },
  {
    name: 'Formal',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Rectangle 15780 (2)-1701750652671-478526653.png',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Rectangle 15780 (1)-1701751817375-332271728.png',
    banneText:
      'Make a lasting impression with our mens formal collection. Elegant suits, dress shirts and ties for special occasions',
  },
  {
    name: 'Glam Rock',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Glam-Rock 3-1701750687880-50835075.png',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Glam-Rock 2-1701751850534-460937404.png',
    banneText:
      'Channel your inner rockstar with our mens glam rock collection. Bold and flashy clothing and accessories',
  },
  {
    name: 'Hip-Hop',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/HTB1spC4KVXXXXbUXFXXq6xXFXXX0 3-1701750715384-717345659.png',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/HTB1spC4KVXXXXbUXFXXq6xXFXXX0 2-1701751880164-436686676.png',
    banneText: 'Stay on trend with our mens hip-hop collection. Clothing and accessories for the ultimate hip-hop look',
  },
  {
    name: 'Punk',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/black-ripped-jeans-punk-fashion 3-1701750744964-407622497.png',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/black-ripped-jeans-punk-fashion 2-1701751912912-827258445.png',
    banneText: 'Make a statement with our mens punk collection. Clothing and accessories for the ultimate punk look',
  },
]

export const MEN_SECTION_TEXT = {
  category: [
    {
      name: 'Activewear',
      description:
        'Push your performance to the next level with our high-performance activewear, designed to keep up with your active lifestyle.',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image: 'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/activewear-1674209717545-374188724.png',
    },
    {
      name: 'Big & Tall',
      description: 'Big refers to the overall size of something whereas tall refers to the height of something',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image: 'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/bigandtall-1674209764464-164433358.png',
    },
    {
      name: 'Jackets',
      description: 'Stay stylish and warm with our versatile collection of jackets, designed for every occasion',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image: 'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/jackets-1674209804468-440663817.png',
    },
    {
      name: 'Dress Shirts',
      description:
        'Exude confidence and class in our expertly crafted dress shirts, designed for the modern professional.',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image: 'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/dressshirts-1674209837451-228501723.png',
    },
    {
      name: 'Jeans',
      description:
        'Elevate your casual look with our collection of premium jeans, designed to fit and flatter all body types',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image: 'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/jeans-1674209871911-485934879.png',
    },
    {
      name: 'Pants',
      description:
        'Upgrade your wardrobe with our versatile collection of pants, tailored to perfection for a flawless fit and modern style',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image: 'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/pants-1674209900411-56203399.png',
    },
    {
      name: 'Shoes',
      description:
        'Step up your shoe game with our wide selection of stylish and comfortable shoes, designed for every occasion',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image: 'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/shoes-1674209925255-903893063.png',
    },
    {
      name: 'Sleepwear',
      description:
        'Experience ultimate comfort and relaxation with our luxurious sleepwear collection, perfect for a cozy night in.',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image: 'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/sleepwear-1674209959163-65270540.png',
    },
    {
      name: 'Sweaters',
      description:
        'Wrap yourself in comfort and fashion with our collection of premium sweaters, designed to keep you warm in style',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image: 'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/sweaters-1674209984484-843383446.png',
    },
    {
      name: 'Socks',
      description:
        'Keep your feet looking and feeling great with our collection of trendy and comfortable socks, perfect for everyday wear.',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image: 'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/socks-1674210016430-286110944.png',
    },
    {
      name: 'Coats & Jackets',
      description: 'Upgrade your style with our premium mens t-shirts. Trendy designs, comfortable fit.',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/coatsandjackets-1674210046986-938159875.png',
    },
    {
      name: 'Swimwear',
      description: 'Dive into style with our top-quality mens swimwear.Perfect for any water adventure',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image: 'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/swimwear-1674210067857-940243398.png',
    },
    {
      name: 'T shirts',
      description: 'Upgrade your style with our premium mens t-shirts. Trendy designs, comfortable fit.',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image: 'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/tshirts-1674210095908-892591583.png',
    },
    {
      name: 'Underwear',
      description: 'Upgrade your style with our premium mens t-shirts. Trendy designs, comfortable fit.',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image: 'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/underwear-1674210113806-773125627.png',
    },
  ],
  now_trending: {
    text: 'Stay ahead of the trend with our collection of the latest fashion styles and designs',
    image: '',
  },
  find_your_style: {
    text: 'Discover your perfect fashion match with our wide collection of clothing and accessories, tailored to suit your personal style',
    image: '',
  },
  fashion_group: {
    text: 'Join the fashion elite with our exclusive clubs, featuring the latest designer clothing and accessories before they hit the market.',
    image: '',
  },
  trending_store: {
    text: 'Stay fashion-forward with a mens trending stores. Shop the latest styles and be the envy of your friends',
    image: '',
  },
  men_fashion_enthusiasts: {
    text: 'Step up your style game with the help of a mens influencer. Get expert fashion and grooming tips for a confident, elevated look',
    image: '',
  },
}

export const MEN_CATEGORY_IMAGE = [
  'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/activewear-1674209717545-374188724.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/bigandtall-1674209764464-164433358.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/jackets-1674209804468-440663817.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/dressshirts-1674209837451-228501723.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/jeans-1674209871911-485934879.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/pants-1674209900411-56203399.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/shoes-1674209925255-903893063.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/sleepwear-1674209959163-65270540.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/sweaters-1674209984484-843383446.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/socks-1674210016430-286110944.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/coatsandjackets-1674210046986-938159875.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/swimwear-1674210067857-940243398.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/tshirts-1674210095908-892591583.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/men/category/underwear-1674210113806-773125627.png',
]

export const MENFIND_YOUR_STYLE = [
  {
    name: 'Artsy',
    image:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyle/Rectangle%2015774-1674544258915-491454055.png',
    color: '#d5e3fc',
    bannerImage:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyleBanner//image%20168%20%281%29-1675838916172-692074626.png',
    opacity: 0.6,
  },
  {
    name: 'Chic',
    image:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyle/Rectangle%2015774-1674544143636-905558893.png',
    bannerImage:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyleBanner//image%20168%20%282%29-1675838981617-716401181.png',
    color: '#fbecf1',
    opacity: 0.6,
  },
  {
    name: 'Formal',
    image:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyle/Rectangle%2015774-1674544171068-107967558.png',
    bannerImage:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyleBanner//image%20168%20%283%29-1675839007089-986222373.png',
    color: '#c89f9f',
    opacity: 0.6,
  },
  {
    name: 'Casual',
    image:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyle/Rectangle%2015774-1674544062902-46953332.png',
    bannerImage:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyleBanner//image%20168%20%284%29-1675839022632-290314794.png',
    color: '#e8ead3',
    opacity: 0.6,
  },
  {
    name: 'Sexy',
    image:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyle/Rectangle%2015774-1674544018329-845085806.png',
    bannerImage:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyleBanner//image%20168%20%287%29-1675839070174-200921115.png',
    color: '#aaacb1',
    opacity: 0.6,
  },
  {
    name: 'Elegant',
    image:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyle/Rectangle%2015774-1674544102538-472889623.png',
    bannerImage:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyleBanner//image%20168%20%285%29-1675839033832-590225831.png',
    color: '#fbf2db',
    opacity: 0.6,
  },
  {
    name: 'Street',
    image:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyle/Rectangle%2015774-1674544200457-607122469.png',
    bannerImage:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyleBanner//image%20168%20%286%29-1675839046742-863983274.png',
    color: '#d29ffa',
    opacity: 0.6,
  },
]

export const MEN_CATEGORY = [
  {
    name: 'Activewear',
    description:
      'Push your performance to the next level with our high-performance activewear, designed to keep up with your active lifestyle.',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Group 1000004427-1701691028065-522702582.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/13fe206b4ca65f510e6e6e8becfc8f7c-1701688369530-538473962.jpg',
    __typename: 'SubCategory',
  },
  {
    name: 'Big & Tall',
    description: 'Big refers to the overall size of something whereas tall refers to the height of something.',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Group 1000004544-1701691191656-218217972.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Rectangle 15863-1701688705041-900308982.jpg',
    __typename: 'SubCategory',
  },
  {
    name: 'Jackets',
    description: 'Stay stylish and warm with our versatile collection of jackets, designed for every occasion.',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Group 1000004423-1701691279423-675303291.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Rectangle 15863-1701688869013-415210074.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Dress Shirts',
    description:
      'Exude confidence and class in our expertly crafted dress shirts, designed for the modern professional.',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Group 1000004429-1701691493189-605158435.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/black-shirt-neor-1390x2000 1-1701689159384-545352459.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Jeans',
    description:
      'Elevate your casual look with our collection of premium jeans, designed to fit and flatter all body types.',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Group 1000004431-1701691696819-537889050.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Rectangle 15863-1701689259723-763191166.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Pants',
    description:
      'Upgrade your wardrobe with our versatile collection of pants, tailored to perfection for a flawless fit and modern style.',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Group 1000004434-1701691780020-561935449.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/2a662c14-e484-47cb-a474-21fe26a25355_1 1-1701689317228-600211252.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Shoes',
    description:
      'Step up your shoe game with our wide selection of stylish and comfortable shoes, designed for every occasion.',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Group 1000004433-1701691859633-941433987.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Pexels Photo by Lukas-1701689461988-117684766.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Sleepwear',
    description:
      'Experience ultimate comfort and relaxation with our luxurious sleepwear collection, perfect for a cozy night in.',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Group 1000004432-1701691912810-555170190.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/1633446972-screen-shot-2021-10-05-at-11-15-19-am-1633446931 1-1701689510954-923730747.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Suits & Blazer',
    description: "Make a lasting impression in our sharp, tailored men's suits, crafted for the modern gentleman",
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Group 1000004430-1701692282482-578812636.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/business-concept-young-successful-businessman-posing-dark-background-isolated-white-background-copy-space 4-1701689679509-379085154.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Sweaters',
    description:
      'Wrap yourself in comfort and fashion with our collection of premium sweaters, designed to keep you warm in style.',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Group 1000004439-1701692347162-394766633.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Rectangle 15863-1701689790633-679877369.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Socks',
    description:
      'Keep your feet looking and feeling great with our collection of trendy and comfortable socks, perfect for everyday wear.',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Group 1000004440-1701692433186-810819494.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/GUEST_cccc3cdb-4811-4e7a-b050-78886e58e9eb 1-1701689864598-590245920.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Swimwear',
    description: 'Dive into style with our top-quality mens swimwear.Perfect for any water adventure.',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Group 1000004438-1701692492008-979692459.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/green_1512x 1-1701689985391-286461567.png',
    __typename: 'SubCategory',
  },
  {
    name: 'T shirts',
    description: 'Upgrade your style with our premium mens t-shirts. Trendy designs, comfortable fit.',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Group 1000004437-1701692542554-290555453.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/jcrjby1646302622077 1-1701690030068-844647945.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Underwear',
    description: 'Upgrade your style with our premium mens t-shirts. Trendy designs, comfortable fit.',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Group 1000004436-1701692587392-565782028.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/men/Q7030014_mens-underwear-guide_index_01_desktop 1-1701690084842-79653908.png',
    __typename: 'SubCategory',
  },
]
