import React, { useEffect, useState } from 'react'
import styles from './SeeAllStore.module.scss'
import AosAnimation from 'src/utils/AosAnimation'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { fetchAllStoreData, fetchPopularStoreData } from 'src/store/slices/fetchAllStoresSlice'
import { useAuth } from 'src/utils/auth'
import { useMutation } from '@apollo/client'
import FOLLOW_STORE from 'src/graphql/mutations/followStore'
import UNFOLLOW_STORE from 'src/graphql/mutations/unFollowStore'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { notification, Spin } from 'antd'
import NewStoreSlider from '../NewStoreSlider'
import ADD_STORELIKE from 'src/graphql/mutations/addStoreLike'
import classNames from 'classnames'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import ShowMoreButton from 'src/components/Comman/ShowMoreButton'
import useDebounce from 'src/hoc/useDebounce'
const CuateImage = '/assets/img/cuate.svg'

export default function SeeAllStore({ className = '' }) {
  const { user }: any = useAuth()
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const allStores = useSelector((state: any) => state?.storesData?.getAllStore)
  const allStoresDetail = useSelector((state: any) => state?.storesData)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(9)
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const [followStore] = useMutation(FOLLOW_STORE, {
    onCompleted: (res) => {
      // setTimeout(() => {
      //   dispatch(fetchPopularStoreData({ limit: limit, page: page, type: 'ALL_STORE', search: '' }))
      // }, 600)
    },
    onError: (err) => {
      notification['error']({
        message: 'Error',
        description: 'Something went wrong',
      })
    },
  })

  const [unfollowStore] = useMutation(UNFOLLOW_STORE, {
    onCompleted: (res) => {
      // setTimeout(() => {
      //   dispatch(fetchPopularStoreData({ limit: limit, page: page, type: 'ALL_STORE', search: '' }))
      // }, 500)
    },
    onError: (err) => {
      notification['error']({
        message: 'Error',
        description: 'Something went wrong',
      })
    },
  })

  const followStoreCall = (store) => {
    if (user) {
      followStore({
        variables: { store_id: store.id },
      })
    } else {
      handleRedirect()
    }
  }

  const unfollowStoreCall = (store) => {
    if (user) {
      unfollowStore({
        variables: { storeId: store.id },
      })
    } else {
      handleRedirect()
    }
  }

  const handleAddStoreLike = (id) => {
    if (user) {
      addStoreLike({
        variables: {
          storeId: id,
        },
        // refetchQueries: [
        //   {
        //     query: GET_SINGLE_STORE_LIKE,
        //     variables: {
        //       storeId: Number(id),
        //     },
        //   },
        // ],
      })
    } else {
      handleRedirect()
    }
  }

  const [addStoreLike] = useMutation(ADD_STORELIKE, {
    onCompleted: (res) => {
      dispatch(
        fetchAllStoreData({
          limit: limit,
          page: page,
          type: getAllState?.seeAllStore?.storeFor === 'JOINED' ? 'JOIN_STORES' : 'ALL_STORE',
          prevPost: [],
          search: debouncedSearch,
        }),
      )
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const fetchMoreStore = (page, limit) => {
    dispatch(
      fetchAllStoreData({
        limit: limit,
        page: page,
        type: getAllState?.seeAllStore?.storeFor === 'JOINED' ? 'JOIN_STORES' : 'ALL_STORE',
        prevPost: allStores,
        search: debouncedSearch,
      }),
    )
  }

  useEffect(() => {
    dispatch(
      fetchAllStoreData({
        page,
        limit,
        prevPost: page === 1 ? [] : allStores,
        type: getAllState?.seeAllStore?.storeFor === 'JOINED' ? 'JOIN_STORES' : 'ALL_STORE',
        search: debouncedSearch,
      }),
    )
  }, [debouncedSearch])

  const handleClose = () => {
    let datass = getAllState?.allModalData?.filter((item) => item?.modal !== getAllState?.lastModalData?.modal)
    dispatch(
      fetchModalData({
        allModalData: datass,
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  const handleSeeMore = () => {
    setPage((prevState) => prevState + 1)
    fetchMoreStore(page + 1, limit)
  }

  const handleChange = (e) => {
    setSearch(e.target.value)
    setPage(1)
  }

  return (
    <div className={classNames(styles.seeAllStoreDetailsAlignment, `${styles[className]}`)}>
      <div className={styles.firstheaderBoxDesign}>
        <div className={styles.backButtonShow} onClick={handleClose}>
          <svg xmlns="http://www.w3.org/2000/svg" width="37" height="32" viewBox="0 0 37 32" fill="none">
            <path
              d="M27 0H10C4.47715 0 0 4.47715 0 10V22C0 27.5228 4.47715 32 10 32H27C32.5228 32 37 27.5228 37 22V10C37 4.47715 32.5228 0 27 0Z"
              fill="white"
            ></path>
            <path
              d="M21 10L16 16L21 22"
              stroke="#808191"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </div>
        <div>
          <h2>
            {' '}
            {getAllState?.seeAllStore?.storeFor === 'JOINED' ? 'Followed' : 'Popular'} Stores (
            {allStoresDetail?.store_total_count || 0})
          </h2>
          <p>Shop with confidence from reputable and reliable sellers</p>
        </div>
        <div className={styles.peopleFollowerSearchAlignment}>
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path
              d="M19 27C23.4183 27 27 23.4183 27 19C27 14.5817 23.4183 11 19 11C14.5817 11 11 14.5817 11 19C11 23.4183 14.5817 27 19 27Z"
              stroke="#ACB1C0"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M28.9999 28.9999L24.6499 24.6499"
              stroke="#ACB1C0"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <input
            type="text"
            name=""
            id=""
            placeholder="Search Stores by Name or by Type"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className={styles.img}>
          <img src={CuateImage} alt="CuateImage" />
        </div>
      </div>
      <div className={styles.seeAllStoreBoxAlignment}>
        <div className={styles.hideInput}>
          <div className={styles.peopleFollowerSearchAlignment}>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
              <path
                d="M19 27C23.4183 27 27 23.4183 27 19C27 14.5817 23.4183 11 19 11C14.5817 11 11 14.5817 11 19C11 23.4183 14.5817 27 19 27Z"
                stroke="#ACB1C0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M28.9999 28.9999L24.6499 24.6499"
                stroke="#ACB1C0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <input
              type="text"
              name=""
              id=""
              placeholder="Search Stores by Name or by Type"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className={styles.allFollowStoreDetailsAlignment}>
          <AosAnimation animationType="fade-up" duration={1000} delay={200}>
            <div className={styles.allFollowStoreGridAlignment}>
              {allStores?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <NewStoreSlider
                      store={item}
                      followStoreCall={followStoreCall}
                      unfollowStoreCall={unfollowStoreCall}
                      handleAddStoreLike={handleAddStoreLike}
                    />
                  </React.Fragment>
                )
              })}
            </div>
          </AosAnimation>
        </div>
      </div>
      {allStoresDetail?.store_total_count !== allStores?.length && (
        <div className={styles.showMoreButtonContain}>
          {allStoresDetail?.loading ? (
            <div>
              <div className="m-auto flex justify-center items-center">
                <Spin size="large" tip="loading" />
              </div>
            </div>
          ) : (
            <ShowMoreButton onClick={handleSeeMore} />
          )}
        </div>
      )}
    </div>
  )
}
