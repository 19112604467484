import React, { useEffect, useRef, useState } from 'react'
import GroupMainSection from './GroupMainSection'
import GroupMenubar from './GroupMenubar'
import GroupSideSection from './GroupSideSection'
import GroupMember from './GroupMember'
import GroupMedia from './GroupMedia'
import GroupFeed from './GroupFeed'
import { useMutation, useQuery } from '@apollo/client'
import GETGROUP from 'src/graphql/queries/getGroup'
import Loader from '../../UI/Loader'
import GroupModalSearch from './GroupModalSearch'
import GroupMenubarMobile from './GroupMenubarMobile'
import { useAppDispatch } from 'src/store/store'
import { fetchUserProfilModalScroll } from 'src/store/slices/userProfileonScrollSlice'
import { MyQuery, MyQueryVariables } from '../types'
import CreateClubModal from 'src/components/StoreAndProductDetailModal/ClubCreationMain/CreateClubModal'
import { useAuth } from 'src/utils/auth'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useSelector } from 'react-redux'
import ClubSubHeader from './ClubSubHeader'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { GET_GROUPS } from 'src/graphql/queries/queries'
import GET_JOINED_GROUP from 'src/graphql/queries/sellerQueries/getJoinedGroups'
import JOIN_GROUP from 'src/graphql/mutations/joinGroup'
import LEAVE_GROUP from 'src/graphql/mutations/leaveGroup'
import { useRouter } from 'next/router'
export default function GroupModal(props) {
  const myElementRef = useRef(null)
  const { user }: any = useAuth()
  const dispatch = useAppDispatch()
  const { groupId } = props
  const router = useRouter()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const { loading, error, data, refetch } = useQuery<MyQuery, MyQueryVariables>(GETGROUP, {
    variables: { slug: groupId },
  })
  let groupAdmins = data?.group?.admins?.filter((item) => item?.id)
  const [tab, setTab] = useState('feed')

  const handleScroll = () => {
    const scrollTop = myElementRef.current.scrollTop
    dispatch(
      fetchUserProfilModalScroll({
        scroll: scrollTop,
      }),
    )
  }

  useEffect(() => {
    refetch()
  }, [])

  // if (loading) return <Loader />
  // if (error) return <p> `Error! ${error.message}`</p>

  const handleCreateClub = () => {
    if (user) {
      dispatch(
        fetchModalData({
          ...getAllState,
          groupCreationModal: {
            modal: 'createClubModal',
            isOpen: true,
            id: { data: data, isEdit: true },
            modalFor: 'smallModal',
          },
        }),
      )
    } else {
      // handleRedirect()
    }
  }

  const handleJoinClub = () => {
    if (user) {
      if (data?.group?.isExist) {
        leaveGroupCall(data?.group)
      } else {
        joingroupCall(data?.group)
      }
    } else {
      handleRedirect()
    }
  }

  const handleRedirect = () => {
    !user &&
      dispatch(
        guestAccountDetailSlice({
          modal: 'authModal',
          isOpen: true,
          id: {
            media:
              'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
            modalFor: 'login',
            startWith: 'loginModal',
          },
        }),
      )
  }

  const [joingroup] = useMutation(JOIN_GROUP, {
    onCompleted: (res) => {
      refetch()
    },
    onError: (err) => {},
  })

  const joingroupCall = (club) => {
    if (user) {
      joingroup({
        variables: { group_id: club?.id, user_id: user?.id, isAdmin: false },
        update: (proxy, { data }) => {
          const suggestedGroup: any = proxy.readQuery({
            query: GET_JOINED_GROUP,
          })
          proxy.writeQuery({
            query: GET_JOINED_GROUP,
            data: {
              getJoinedGroups: [
                ...suggestedGroup?.getJoinedGroups,
                {
                  ...club,
                  isFollow: true,
                  members: [
                    {
                      firstName: user?.firstName,
                      lastName: user?.lastName,
                      id: user?.id,
                      profileUrl: user?.logo_image,
                    },
                    ...club?.members,
                  ],
                },
              ],
            },
          })
          const joinedGroup: any = proxy.readQuery({
            query: GET_GROUPS,
          })
          let updatedData = joinedGroup?.groups?.filter((grp) => grp?.id !== club?.id)
          proxy.writeQuery({
            query: GET_GROUPS,
            data: { groups: updatedData },
          })
        },
      })
      refetch()
    } else {
      handleRedirect()
    }
  }

  const [leaveGroup] = useMutation(LEAVE_GROUP, {
    onCompleted: (res) => {
      refetch()
    },
    onError: (err) => {},
  })

  const leaveGroupCall = (club) => {
    if (user) {
      leaveGroup({
        variables: {
          userId: user.id,
          groupId: club?.id,
        },
      })
    } else {
      handleRedirect()
    }
  }
  return (
    <div className="men-fashion-add-details-modal-alignment" ref={myElementRef} onScroll={handleScroll}>
      {/* <span onClick={() => handleCreateClub()} >Edit</span> */}
      <div className="men-fashion-add-details-height-alignment">
        {/* <ClubSubHeader
          group={data?.group}
          refetch={refetch}
          tab={tab}
          setTab={setTab}
          handleJoinClub={handleJoinClub}
        /> */}
        <GroupMainSection group={data?.group} refetch={refetch} />
        <GroupMenubar setTab={setTab} group={data?.group} tab={tab} handleJoinClub={handleJoinClub} />
        <div className="men-fashin-main-details-alignment">
          <div className="men-fashion-details-flex-alignment">
            <div className="men-fashion-first-part-alignment">
              <div className="men-fashion-sticky-alignment">
                <GroupSideSection group={data?.group} />
              </div>
            </div>
            <div className="men-web-view-tab-alignment">
              <GroupMenubarMobile setTab={setTab} group={data?.group} />
            </div>
            <div className="men-second-part-alignment">
              {tab === 'feed' && <GroupFeed group={data?.group} />}
              {tab === 'member' && <GroupMember group={data?.group} />}
              {tab === 'media' && <GroupMedia />}
              {tab === 'search' && <GroupModalSearch group={data?.group} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
