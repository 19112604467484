import { gql } from '@apollo/client';

export const SEND_CHAT_MESSAGE = gql`
  mutation SendChatMessage($input: ChatMessageInput!) {
    sendChatMessage(input: $input) {
      success
      message
    }
  }
`;

export const SEND_POST_LIKE = gql`
  mutation SendPostLike($input: PostLikeInput!) {
    sendPostLike(input: $input) {
      success
      message
    }
  }
`;

export const SEND_POST_VIEW = gql`
  mutation SendPostView($input: PostViewInput!) {
    sendPostView(input: $input) {
      success
      message
    }
  }
`;

export const SEND_NEW_FOLLOWER = gql`
  mutation SendNewFollower($input: NewFollowerInput!) {
    sendNewFollower(input: $input) {
      success
      message
    }
  }
`;

export const SEND_POST_COMMENT = gql`
  mutation SendPostComment($input: PostCommentInput!) {
    sendPostComment(input: $input) {
      success
      message
    }
  }
`;

export const SEND_STORE_PRODUCT = gql`
  mutation SendStoreProduct($input: StoreProductInput!) {
    sendStoreProduct(input: $input) {
      success
      message
    }
  }
`;

export const SEND_PRODUCT_SHIPPING = gql`
  mutation SendProductShipping($input: ProductShippingInput!) {
    sendProductShipping(input: $input) {
      success
      message
    }
  }
`;

export const SEND_CART_REMINDER = gql`
  mutation SendCartReminder($input: CartReminderInput!) {
    sendCartReminder(input: $input) {
      success
      message
    }
  }
`;

export const SEND_CLUB_INVITE = gql`
  mutation SendClubInvite($input: ClubInviteInput!) {
    sendClubInvite(input: $input) {
      success
      message
    }
  }
`;

export const SEND_CLUB_ACTIVITY = gql`
  mutation SendClubActivity($input: ClubActivityInput!) {
    sendClubActivity(input: $input) {
      success
      message
    }
  }
`;

export const SEND_CLUB_COMMENT = gql`
  mutation SendClubComment($input: ClubCommentInput!) {
    sendClubComment(input: $input) {
      success
      message
    }
  }
`;

export const SEND_RECOMMENDATION = gql`
  mutation SendRecommendation($input: RecommendationInput!) {
    sendRecommendation(input: $input) {
      success
      message
    }
  }
`;

export const SEND_LIVE_STREAM = gql`
  mutation SendLiveStream($input: LiveStreamInput!) {
    sendLiveStream(input: $input) {
      success
      message
    }
  }
`;

// Types for mutation inputs
export interface NotificationResponse {
    success: boolean;
    message?: string;
}

export interface ChatMessageInput {
    to: string;
    userName: string;
    userImg: string;
    message: string;
}

export interface PostLikeInput {
    to: string;
    userName: string;
    userImg: string;
}

export interface PostViewInput {
    to: string;
    userName: string;
    userImg: string;
    viewCount: number;
}

export interface NewFollowerInput {
    to: string;
    userName: string;
    userImg: string;
    userSlug: string;
}

export interface PostCommentInput {
    to: string;
    userName: string;
    userImg: string;
    postTime: string;
    postMessage: string;
    postUser: string;
    postUserImg: string;
}

export interface StoreProductInput {
    to: string;
    storeName: string;
    storeImg: string;
    message: string;
    products: string[];
}

export interface ProductShippingInput {
    to: string;
    orderName: string;
    productImage: string;
}

export interface CartReminderInput {
    to: string;
    productImage: string;
}

export interface ClubInviteInput {
    to: string;
    clubName: string;
    clubSlug: string;
    userName: string;
    userImg: string;
}

export interface ClubActivityInput {
    to: string;
    clubName: string;
    clubSlug: string;
    product: string;
}

export interface ClubCommentInput {
    to: string;
    clubName: string;
    clubSlug: string;
    userName: string;
    userImg: string;
}

export interface RecommendationInput {
    to: string;
    name: string;
    url: string;
    product: string;
}

export interface LiveStreamInput {
    to: string;
    storeName: string;
    storeSlug: string;
    storeImg: string;
}