import { gql } from '@apollo/client'
const SELLER_ONBOARD = gql`
  mutation SellerOnbord($input: sellerOnboardInput) {
    sellerOnboard(input: $input) {
      message
      success
      error
    }
  }
`

export default SELLER_ONBOARD
