// import SellerOnboardingMainTwo from 'src/components/Seller/SellerOnboardingMainTwo'

// const SellerOnboarding = () => {
//   return <SellerOnboardingMainTwo />
// }

// export default SellerOnboarding

import SellerOnboardingPage from 'src/components/Seller/seller-onboarding/seller-onboarding'

const SellerOnboardingtwo = () => {
  return <SellerOnboardingPage />
}

export default SellerOnboardingtwo
