import React from 'react'
import styles from './NewStoreSlider.module.scss'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import moment from 'moment'
import LazyImage from 'src/utils/LazyImage'
import LazyLoad from 'src/utils/LazyLoad'
import LazyStoreImage from 'src/utils/LazyStoreImage'
import Link from 'next/link'
import { useAuth } from 'src/utils/auth'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import ADD_STORELIKE from 'src/graphql/mutations/addStoreLike'
import { useMutation, useQuery } from '@apollo/client'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { fetchSearchData } from 'src/store/slices/searchModalSlice'

export default function NewStoreSlider({ store, followStoreCall, unfollowStoreCall, handleAddStoreLike }) {
  const dispatch = useAppDispatch()
  const { user } = useAuth()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const fetchSearchModalData = useSelector((state: any) => state?.searchModalSlice?.data)

  const handelImageSlider = () => {}

  const handleClose = () => {
    dispatch(
      fetchModalData({
        ...getAllState,
        allModalData: [],
        lastModalData: null,
        seeAllStore: null,
      }),
    )
    dispatch(
      fetchMobileModalData({
        allModalData: [
          ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
          {
            modal: 'mobileViewStoreModal',
            isOpen: false,
            id: { isSeller: false, group: null, type: 'post' },
            transform: 42,
          },
        ],
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
  }

  const handleCloseSearch = () => {
    dispatch(fetchSearchData({ ...fetchSearchModalData, isOpen: false, id: {}, search: '', searchPreview: '' }))
  }

  return (
    <div className={styles.shopRisingStoresDetailsSlider}>
      <div className={styles.shopRisingStoresDetailsBoxAlignment}>
        <div className={styles.shopRisingStoreProductDetailsAlignment}>
          <div className={styles.shopRisingStoreProductAllDetails}>
            <div className={styles.shopRisingStoreProductAllImg}>
              <LazyImage
                image={{ src: store?.logo_image, alt: 'Icon' }}
                objectFit={'contain'}
                handleClickOnImage={() => handelImageSlider()}
                className={'store-logo-for-skeleton'}
              />
            </div>
            <div className={styles.shopRisingStoreProductOtherDetails} onClick={() => handleCloseSearch()}>
              <Link href={`/store/${store?.slug}/`}>
                <h5 onClick={handleClose}>{store?.name}</h5>
              </Link>
              <p>{store?.product_count} Products</p>
              <div className={styles.followStoreButton}>
                {store?.isFollow ? (
                  <button className={styles.following} onClick={() => unfollowStoreCall(store)}>
                    Followed
                  </button>
                ) : (
                  <button onClick={() => followStoreCall(store)}>Follow Store</button>
                )}
              </div>
            </div>
          </div>
          <div className={styles.shopRisingLikeShareButton}>
            <div className={styles.shopRisingLikeShareIconTotal}>
              <div onClick={() => handleAddStoreLike(store.id)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill={store?.isLike ? '#ff0000' : 'none'}
                >
                  <path
                    d="M7.5 4C4.46244 4 2 6.43905 2 9.44773C2 14.8955 8.5 19.848 12 21C15.5 19.848 22 14.8955 22 9.44773C22 6.43905 19.5375 4 16.5 4C14.6399 4 12.9954 4.91467 12 6.31469C11.0046 4.91467 9.36015 4 7.5 4Z"
                    stroke={store?.isLike ? '#ff0000' : '#7A7E88'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <p>{store?.like_count}</p>
            </div>
            <div className={styles.shopRisingLikeShareIconTotal}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z"
                  stroke="#7A7E88"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
                  stroke="#7A7E88"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z"
                  stroke="#7A7E88"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.58984 13.51L15.4198 17.49"
                  stroke="#7A7E88"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.4098 6.51001L8.58984 10.49"
                  stroke="#7A7E88"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className={styles.shopRisingChildImagesAlignment}>
          <div className={styles.shopRisingChildGrid}>
            {store?.product_images?.map((x, index) => {
              return (
                // <LazyLoad id={index} offset={50} height={200}>
                <div
                  key={index}
                  className={`${styles.shopRisingChildImg} ${
                    store?.product_count > 5 && index === 4 && styles.lastImgAlignment
                  }`}
                >
                  <LazyStoreImage
                    image={{ src: x, alt: 'Icon' }}
                    objectFit={'cover'}
                    handleClickOnImage={() => handelImageSlider()}
                    // imageClassName={classNames(styles.shopRisingMoreImg, styles.lastImgAlignment)}
                  />
                  {store?.product_count > 5 && index === 4 && (
                    <div className={styles.shopRisingMoreImg}>
                      <p>
                        <span>{store?.product_count - store?.product_images?.length} </span>more
                      </p>
                    </div>
                  )}
                </div>
                // </LazyLoad>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
