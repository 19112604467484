import { gql } from '@apollo/client'

const GET_JEWELRY_COLLECTION = gql`
  query GetJewelryCollection($type: String) {
    getJewelryCollection(type: $type) {
      id
      likes
      name
      product_count
      product_images
      slug
      user_id
      banner_image
      description
      colection_for
      user {
        id
        firstName
        lastName
        #profileAvtar
        userName
      }
    }
  }
`
export default GET_JEWELRY_COLLECTION
