import React from 'react'
import SellerOnboardingStepper from './SellerOnboardingStepper'

const SellerOnboardingPage: React.FC = () => {

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800">
      <div>
        <SellerOnboardingStepper />
      </div>
    </div>
  )
}

export default SellerOnboardingPage
