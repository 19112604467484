import React, { useEffect, useState } from 'react'
import styles from './SellerFollowersDetails.module.scss'
import { useAuth } from 'src/utils/auth'
import { useMutation, useQuery } from '@apollo/client'
import GET_ALL_STORE_FOLLOWERS from 'src/graphql/queries//getFollowes'
import SEND_FRIEND_REQUEST from 'src/graphql/mutations/sendFriendRequest'
import DECLINE_FRIEND_REQUEST from 'src/graphql/mutations/declineFriendRequest'
import PeopleItem from 'src/components/StoreAndProductDetailModal/PeopleModal/PeopleItem'

export default function SellerFollowersDetails() {
  const { user }: any = useAuth()
  const [search, setSearch] = useState(null)

  const { loading, error, data, refetch } = useQuery(GET_ALL_STORE_FOLLOWERS, {
    variables: { getAllStoreFollowersId: user?.store?.id },
  })

  useEffect(() => {
    refetch()
  }, [])

  const [sendFriendRequest] = useMutation(SEND_FRIEND_REQUEST, {
    onCompleted: (res) => {
      setTimeout(() => {
        refetch()
      }, 500)
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const [declineFriendRequest] = useMutation(DECLINE_FRIEND_REQUEST, {
    onCompleted: (res) => {
      setTimeout(() => {
        refetch()
      }, 500)
    },
    onError: (error) => {},
  })

  const sendFriendRequestCall = (usr) => {
    sendFriendRequest({
      variables: {
        friend_id: usr?.id,
        user_id: user?.id,
        isActive: true,
        isFriend: true,
      },
    })
  }

  const declineFriendRequestCall = (usr) => {
    declineFriendRequest({
      variables: { user_id: user?.id, friend_id: usr?.id },
    })
  }

  return (
    <div className={styles.sellerFollowersSection}>
      <div className={styles.sellerFollowersAlignment}>
        <div className={styles.sellerFollowersHeadingAlignment}>
          <div className={styles.sellerFollowerFlex}>
            <h6>Followers</h6>
            <div className={styles.totalFollowersNumber}>
              <span>{data?.getAllStoreFollowers?.length}</span>
            </div>
          </div>
          <div className={styles.serachAlignment}>
            <div className={styles.searchInputAlignment}>
              <div className={styles.iconAlignment}>
                <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
                    stroke="#ACB1C0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.0004 19.0004L14.6504 14.6504"
                    stroke="#ACB1C0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <input type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value as string)} />
            </div>
          </div>
        </div>
        <div className={styles.sellerAllFollowersDetailsAlignment}>
          <div className={styles.sellerAllFollowersDetailsGrid}>
            {(search
              ? data?.getAllStoreFollowers
                  ?.map((data) => ({ ...data, name: data?.firstName + data?.lastName }))
                  ?.filter((item: any) => item?.name?.toLowerCase()?.includes(search?.toLowerCase()))
              : data?.getAllStoreFollowers
            )?.map((people, index) => (
              <React.Fragment key={index}>
                <PeopleItem
                  people={people}
                  declineFriendRequestCall={declineFriendRequestCall}
                  sendFriendRequestCall={sendFriendRequestCall}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
