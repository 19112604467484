export const VIDEO_ARRAY = [
  'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Share-Product-Short.mp4',
  'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Save-Product-short.mp4',
  'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
  'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Messaging-short.mp4',
  'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Club-Short.mp4',
  'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Shopping-Bag-Short.mp4',
]

// export const VIDEO_ARRAY = [
//   '/assets/Video/Share-Product-Short.mp4',
//   '/assets/Video/Save-Product-short.mp4',
//   '/assets/Video/Live-Shopping-Short.mp4',
//   '/assets/Video/Messaging-short.mp4',
//   '/assets/Video/Club-Short.mp4',
//   '/assets/Video/Shopping-Bag-Short.mp4',
// ]
