import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useQuery } from '@apollo/client'
import GET_CHAT_MEDIA from 'src/graphql/queries/messageQueries/getMedias'
import { useStateContext } from 'src/utils/state'
import client1 from 'src/utils/apolloClient'

export default function MessageMedia() {
  const [mediaModal, setmediaModal] = useState(false)
  const dispatch = useAppDispatch()
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const { selectedChat } = useStateContext()
  const { data, refetch } = useQuery(GET_CHAT_MEDIA, {
    client: client1,
    variables: {
      conversationId: selectedChat?.chatData?.latestMessage?.conversationId,
    },
  })

  useEffect(() => {
    refetch()
  }, [selectedChat])

  const handleClose = () => {
    dispatch(
      fetchMobileModalData({
        ...fetchMobileModal,
        secondModal: {},
      }),
    )
  }

  return (
    <>
      <div className="message-media-modal-details-alignment">
        <div className="message-media-heading-alignment">
          <h6>Media</h6>
          <div className="mobile-view-header-close-alignment" onClick={() => handleClose()}>
            <img src="/assets/icon/close_icon.svg" alt="close icon" />
          </div>
        </div>
        <div className="message-media-details-media-post-details">
          {data?.getMedias?.length === 0 && (
            <div className="no-media-found-alignment">
              <p>No media found</p>
            </div>
          )}
          <div className="message-media-details-media-grid-alignment">
            {data?.getMedias?.map((img, index) => {
              return (
                <div className="message-media-details-media-gridItem" key={index}>
                  <div
                    className={mediaModal ? 'message-media-img-alignment remove-img' : 'message-media-img-alignment'}
                    onClick={() => {
                      dispatch(
                        fetchMobileModalData({
                          ...fetchMobileModal,
                          messageMediaPreviewModal: {
                            modal: 'messageMediaModal',
                            isOpen: true,
                            id: {
                              selectedImage: img,
                            },
                            transform: 50,
                            zIndex: 99999999,
                            modalFor: 'messageMedia',
                          },
                        }),
                      )
                    }}
                  >
                    <img src={img} alt="media img" />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
