import React, { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { useAuth } from 'src/utils/auth'
import { useStateContext } from 'src/utils/state'
import { GET_ALL_USERS, GET_GROUPS, GET_GROUP_INVITED_USER } from 'src/graphql/queries/queries'
import { GET_ALL_GROUP_USER } from 'src/graphql/queries/queries'
import { INVITE_FOR_GROUP_MESSAGE } from 'src/graphql/mutations/mutations'
import { CREATE_MESSAGE_GROUP } from 'src/graphql/mutations/mutations'
import { INVITED_USER } from 'src/graphql/subscriptions/subscriptions'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import InvitedGroupSection from './InvitedGroupSection'
import MessageKnobs from './MessageKnobs'
import SearchPeopleSection from './SearchPeopleSection'
import AddPersonalMessageModal from './AddPersonalMessageModal'
import AddGroupMessageModal from './AddGroupMessageModal'
import GET_MUTUAL_FOLLOWING from 'src/graphql/queries/sellerQueries/getMutualFollowing'
import socketClient, { MESSAGE_EVENTS } from 'src/utils/socketClient'
import client1 from 'src/utils/apolloClient'

export default function MessageSidebar() {
  const productRef: any = useRef()
  const { user } = useAuth()
  const dispatch = useAppDispatch()
  const { selectChat } = useStateContext()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const staticArray = ['/assets/img/no-user-img.svg', '/assets/img/no-user-img.svg', '/assets/img/no-user-img.svg']

  const {
    data: userData,
    loading: loadingUsers,
    refetch,
  }: any = useQuery<any>(GET_MUTUAL_FOLLOWING, {
    onError: (err) => { },
  })

  const [searchTag, setSearchTag] = useState('')
  const [newMessageDropdown, setnewMessageDropdown] = useState(false)
  const [PersonalMessageModal, setPersonalMessageModal] = useState(false)
  const [GroupMessageModal, setGroupMessageModal] = useState(false)
  const [participants, setParticipants] = useState([])
  const [groupName, setGroupName] = useState('')
  const [allUserData, setAllUserData] = useState([])
  const [invitedDropdown, setinvitedDropdown] = useState(false)

  const { data: groupData, loading: loadingGroups } = useQuery(GET_GROUPS, {
    client: client1,
    onError: (err) => { },
  })

  const { data: inviteUser, loading: loadingGetAllGroupInvitedUsers } = useQuery(GET_GROUP_INVITED_USER, {
    client: client1,
    onError: (err) => { },
  })


  useEffect(() => {
    socketClient.connect()
    socketClient.on(MESSAGE_EVENTS.INVITE_USERS, (incomingMessage) => {
      const newMessage = incomingMessage.inviteUsers
      let getMsgQuery, getMsgVariables, getMsgQueryName, getLastMsgQuery, getLastMsgQueryName
      getMsgQuery = GET_GROUP_INVITED_USER
      getMsgVariables = { getAllGroupInvitedUsers: [] }
      getMsgQueryName = 'getPrivateMessages'
      getLastMsgQuery = GET_ALL_USERS
      getLastMsgQueryName = 'getAllUsers'
      const conversationCache = client1.readQuery({
        query: getMsgQuery,
      })
      if (conversationCache) {
        const updatedConvoCache = [...conversationCache?.getAllGroupInvitedUsers, newMessage]
        client1.writeQuery({
          query: getMsgQuery,
          data: { getAllGroupInvitedUsers: updatedConvoCache },
        })
      }
    })
    return () => {
      socketClient.off(MESSAGE_EVENTS.INVITE_USERS)
      socketClient.disconnect();
    }

  }, [])

  const [createMessageGroup, { loading }] = useMutation(CREATE_MESSAGE_GROUP, {
    client: client1,
    onError: (err) => { },
  })

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (newMessageDropdown && productRef.current && !productRef.current.contains(e.target)) {
        setnewMessageDropdown(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [newMessageDropdown])

  const handleChange = (e, selected) => {
    setParticipants(
      participants?.map((user) => {
        if (user?.user?.id === selected?.user?.id) {
          return { ...user, isChecked: !user?.isChecked }
        } else {
          return user
        }
      }),
    )
  }

  const [InviteGroupUsers] = useMutation(INVITE_FOR_GROUP_MESSAGE, {
    client: client1,
    onCompleted: (res) => { },
    onError: (err) => {
      console.log('err', err)
    },
  })

  const handleCreateGroup = () => {
    const checkedParticipants = participants?.filter((pr) => pr?.isChecked)?.map((us) => us?.user?.id)
    createMessageGroup({
      variables: {
        name: groupName,
        participants: checkedParticipants,
      },
      update: (proxy, { data }) => {
        const returnedData = data.createMessageGroup
        InviteGroupUsers({
          variables: {
            conversationId: returnedData?.id,
            participants: checkedParticipants,
          },
          refetchQueries: [{ query: GET_ALL_GROUP_USER, variables: { group_id: returnedData?.id } }],
        })
        const dataInCache: any = proxy.readQuery({
          query: GET_GROUPS,
        })
        proxy.writeQuery({
          query: GET_GROUPS,
          data: { getGroups: [...dataInCache.getGroups, returnedData] },
        })
        let updatedData = {
          ...returnedData,
          users: returnedData?.users?.map((us) => {
            if (user?.id === us?.id) {
              return {
                ...us,
                isAdmin: true,
              }
            } else {
              return {
                ...us,
                isAdmin: false,
                isInvitedUser: checkedParticipants?.includes(us?.id),
              }
            }
          }),
        }
        selectChat(updatedData, 'group')
        setGroupMessageModal(false)
      },
    })

  }


  useEffect(() => {
    if (userData?.getMutualFollowing?.data?.length > 0) {
      let userDataFound = userData?.getMutualFollowing?.data?.map((usr) => {
        return { ...usr, name: usr?.user?.firstName + ' ' + usr?.user?.lastName }
      })
      setParticipants([...userDataFound])
    }
    if (groupData?.getGroups?.length > 0) {
      let userDataFound = userData?.getMutualFollowing?.data?.map((usr) => {
        return { ...usr, name: usr?.user?.firstName + ' ' + usr?.user?.lastName }
      })
      if (userDataFound) {
        setAllUserData([...groupData.getGroups, ...userDataFound])
      } else {
        setAllUserData([...groupData.getGroups])
      }
    }
  }, [groupData, userData])

  useEffect(() => {
    refetch()
  }, [])

  const handleClose = () => {
    let datass = getAllState?.allModalData?.filter((item) => item?.modal !== getAllState?.lastModalData?.modal)
    dispatch(
      fetchModalData({
        allModalData: datass,
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }
  return (
    <div className="message-sidebar-section">
      <div className="message-sidebar-header-sticky">
        <div className="message-mobile-view-bg">
          <div className="message-back-more-option-alignment">
            <div onClick={() => handleClose()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="37" height="32" viewBox="0 0 37 32" fill="none">
                <path
                  d="M27 0H10C4.47715 0 0 4.47715 0 10V22C0 27.5228 4.47715 32 10 32H27C32.5228 32 37 27.5228 37 22V10C37 4.47715 32.5228 0 27 0Z"
                  fill="white"
                />
                <path
                  d="M21 10L16 16L21 22"
                  stroke="#808191"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="44" height="32" viewBox="0 0 44 32" fill="none">
                <path
                  d="M34 0H10C4.47715 0 0 4.47715 0 10V22C0 27.5228 4.47715 32 10 32H34C39.5228 32 44 27.5228 44 22V10C44 4.47715 39.5228 0 34 0Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15 19C13.8954 19 13 18.1046 13 17C13 15.8954 13.8954 15 15 15C16.1046 15 17 15.8954 17 17C17 18.1046 16.1046 19 15 19ZM22 19C20.8954 19 20 18.1046 20 17C20 15.8954 20.8954 15 22 15C23.1046 15 24 15.8954 24 17C24 18.1046 23.1046 19 22 19ZM27 17C27 18.1046 27.8954 19 29 19C30.1046 19 31 18.1046 31 17C31 15.8954 30.1046 15 29 15C27.8954 15 27 15.8954 27 17Z"
                  fill="#808191"
                />
              </svg>
            </div>
          </div>
          <div className="message-sidebar-heading">
            <h6>Messaging</h6>
            <div className="new-button-alignment">
              {/* FOT_WEB============================================== */}
              <button className="webview-button" onClick={() => setnewMessageDropdown(!newMessageDropdown)}>
                <img src="/assets/icon/plus-button-icon.svg" alt="plus-icon" />
                <a>New Message</a>
              </button>
              {/* FOT_MOBILE============================================== */}
              <button
                className="mobileview-button"
                // onClick={() => setIsOpenStoreFollowerModal(true)}
                onClick={() =>
                  dispatch(
                    fetchMobileModalData({
                      allModalData: [
                        ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                        {
                          modal: 'addMessageModal',
                          isOpen: true,
                          id: 0,
                          transform: 460,
                          zIndex: 9999999,
                          footer_index: 999999,
                        },
                      ],
                      lastModalData: fetchMobileModal?.lastModalData,
                    }),
                  )
                }
              >
                <img src="/assets/icon/plus-button-icon.svg" alt="plus-icon" />
              </button>
              {newMessageDropdown && (
                <div className="new-button-dropdown-alignment" ref={productRef}>
                  <div
                    className="new-button-dropdown-option-alignment "
                    // new-button-option-active
                    onClick={() => setPersonalMessageModal(true)}
                  >
                    <span>Personal Message</span>
                  </div>
                  <div className="new-button-dropdown-option-alignment" onClick={() => setGroupMessageModal(true)}>
                    <span>Group Message</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {Boolean(inviteUser?.getAllGroupInvitedUsers?.length) && (
            <InvitedGroupSection
              setinvitedDropdown={setinvitedDropdown}
              inviteUser={inviteUser}
              invitedDropdown={invitedDropdown}
            />
          )}
          <div className="message-search-alignment">
            <div>
              <img src="/assets/icon/search.svg" alt="search icon" />
            </div>
            <input type="text" placeholder="Search user or group" onChange={(e) => setSearchTag(e.target.value)} />
            <div className="close-icon-alignment">
              <img src="/assets/icon/close_icon.svg" alt="close icon" />
            </div>
          </div>
          {searchTag && <div className="total-chat-number-alignment">
            <span>
              {searchTag !== '' &&
                allUserData?.filter((supplier: any) =>
                  supplier?.name?.toLowerCase()?.includes(searchTag?.toLowerCase()),
                )?.length}{' '}
              {searchTag !== '' && 'chat found'}
            </span>
          </div>}
        </div>

        <div className="mobile-view-chat-details-alignment">
          {searchTag === '' ? (
            <MessageKnobs />
          ) : (
            <SearchPeopleSection allUserData={allUserData} searchTag={searchTag} />
          )}
        </div>
      </div>
      {PersonalMessageModal && (
        <AddPersonalMessageModal userData={userData} setPersonalMessageModal={setPersonalMessageModal} />
      )}

      {GroupMessageModal && (
        <AddGroupMessageModal
          staticArray={staticArray}
          setGroupMessageModal={setGroupMessageModal}
          setGroupName={setGroupName}
          setSearchTag={setSearchTag}
          participants={participants}
          handleChange={handleChange}
          searchTag={searchTag}
          handleCreateGroup={handleCreateGroup}
        />
      )}
      {/* <MessageSearchText setIsSearchtextModal={setIsSearchtextModal} isSearchtextModal={isSearchtextModal} /> */}
    </div>
  )
}
