import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import PostPreviewModal from 'src/components/UserProfile/UserProfileModal/UserDataSection/PostPreviewModal'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAppDispatch } from 'src/store/store'
import LazyImage from 'src/utils/LazyImage'
import VideoPlayer from 'src/utils/LazyVideoPlayer'
import { useAuth } from 'src/utils/auth'
import styles from './ImageGridPage.module.css'

const ImageGridPage = ({
  images,
  post,
  setCommentId,
  commentId,
  editPost,
  editPostMobile,
  setIsMobileComment,
  isUserPostPreviewModal,
}) => {
  const dispatch = useAppDispatch()
  const { user }: any = useAuth()
  const [MediaModal, setMediaModal] = useState(false)
  const [postId, setPostId] = useState({ id: null, post_for: null })

  const handelImageSlider = (idx: number, post: any) => {
    if (user) {
      setMediaModal(true)
      setPostId({ id: post?.id, post_for: post?.post_for })
      setCommentId({ id: post?.id, comment_for: 'POST' })
    } else {
      handleRedirect()
    }
  }
  const [isDesktop, setIsDesktop] = useState(0)

  const handleResize = () => setIsDesktop(window.innerWidth)

  useEffect(() => {
    setIsDesktop(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  useEffect(() => {
    if (MediaModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [MediaModal])

  if (images && images?.length > 0) {
    if (images?.length === 1) {
      return (
        <>
          {MediaModal && (
            <PostPreviewModal
              setMediaModal={setMediaModal}
              isUserMedia={false}
              postId={postId}
              commentId={commentId}
              setCommentId={setCommentId}
              editPost={editPost}
              editPostMobile={editPostMobile}
              setIsMobileComment={setIsMobileComment}
              isUserPostPreviewModal={isUserPostPreviewModal}
            />
          )}
          <div style={{ width: '100%', padding: '8px' }}>
            <Row>
              {images?.map((file: any, index: any) => {
                return (
                  <React.Fragment key={index}>
                    <Col span={24}>
                      <div className="image-alignment" style={{ width: '100%' }}>
                        {file?.url.includes('mp4') || file?.url.includes("mov") ? (
                          <VideoPlayer url={(file?.url)?.toString()} enable_mute={false} />
                          // <video width="250" controls style={{ width: '100%', height: '100%', objectFit: 'contain' }}>
                          //   <source src={file?.url} />
                          // </video>
                        ) : (
                          // <img
                          //   onClick={() => handelImageSlider(index, post)}
                          //   src={file?.url}
                          //   alt="preview"
                          //   key={index}
                          //   className="post-media-radious"
                          //   style={{ cursor: 'pointer', width: '100%', height: '100%', objectFit: 'contain' }}
                          // />
                          <LazyImage
                            image={{ src: file?.url, alt: 'Icon' }}
                            objectFit={'cover'}
                            height={isDesktop > 1024 ? 308 : 140}
                            handleClickOnImage={() => handelImageSlider(index, post)}
                            className={'post-media-radious'}
                            imageClassName={'post-loaded-radious'}
                            // borderRadius={'12px'}
                          />
                        )}
                      </div>
                    </Col>
                  </React.Fragment>
                )
              })}
            </Row>
          </div>
        </>
      )
    } else if (images?.length === 2) {
      return (
        <>
          {MediaModal && (
            <PostPreviewModal
              setMediaModal={setMediaModal}
              isUserMedia={false}
              postId={postId}
              commentId={commentId}
              setCommentId={setCommentId}
              editPost={editPost}
              editPostMobile={editPostMobile}
              setIsMobileComment={setIsMobileComment}
              isUserPostPreviewModal={isUserPostPreviewModal}
            />
          )}
          <div style={{ width: '100%', padding: '8px' }}>
            <Row gutter={[2, 2]}>
              {images?.map((file: any, index: any) => {
                return (
                  <React.Fragment key={index}>
                    <Col span={12}>
                      {file?.url.includes('mp4') ? (
                        <video width="250" controls style={{ width: '100%', height: '100%', objectFit: 'contain' }}>
                          <source src={file?.url} />
                        </video>
                      ) : (
                        // <img
                        //   onClick={() => handelImageSlider(index, post)}
                        //   src={file?.url}
                        //   alt="preview"
                        //   key={index}
                        //   className="post-media-radious"
                        //   style={{
                        //     cursor: 'pointer',
                        //     width: '100%',
                        //     height: '100%',
                        //     objectFit: 'cover',
                        //     objectPosition: 'center',
                        //   }}
                        // />
                        <LazyImage
                          image={{ src: file?.url, alt: 'Icon' }}
                          objectFit={'cover'}
                          handleClickOnImage={() => handelImageSlider(index, post)}
                          // height={526}
                          height={isDesktop > 1024 ? 526 : 140}
                          className={'post-media-radious'}
                          imageClassName={'post-loaded-radious'}
                        />
                      )}
                    </Col>
                  </React.Fragment>
                )
              })}
            </Row>
          </div>
        </>
      )
    } else if (images?.length === 3) {
      return (
        <>
          {MediaModal && (
            <PostPreviewModal
              setMediaModal={setMediaModal}
              isUserMedia={false}
              postId={postId}
              commentId={commentId}
              setCommentId={setCommentId}
              editPost={editPost}
              editPostMobile={editPostMobile}
              setIsMobileComment={setIsMobileComment}
              isUserPostPreviewModal={isUserPostPreviewModal}
            />
          )}
          <div style={{ width: '100%', padding: '8px' }}>
            <div className={`${styles.imageGrid} ${styles.imageGrid3}`}>
              {images?.map((file: any, index: any) => {
                return (
                  <div key={index} className={styles.imageItem}>
                    {file?.url.includes('mp4') || file?.url.includes("mov") ? (
                      <VideoPlayer url={(file?.url)?.toString()} enable_mute={false} />
                    ) : (
                      <LazyImage
                        image={{ src: file?.url, alt: 'Icon' }}
                        objectFit={'cover'}
                        height={isDesktop > 1024 ? 308 : 140}
                        handleClickOnImage={() => handelImageSlider(index, post)}
                        className={'post-media-radious'}
                        imageClassName={'post-loaded-radious'}
                      />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )
    } else if (images?.length === 4) {
      return (
        <>
          {MediaModal && (
            <PostPreviewModal
              setMediaModal={setMediaModal}
              isUserMedia={false}
              postId={postId}
              commentId={commentId}
              setCommentId={setCommentId}
              editPost={editPost}
              editPostMobile={editPostMobile}
              setIsMobileComment={setIsMobileComment}
              isUserPostPreviewModal={isUserPostPreviewModal}
            />
          )}
          <div style={{ width: '100%', padding: '8px' }}>
            <div className={`${styles.imageGrid} ${styles.imageGrid4}`}>
              {images?.map((file: any, index: any) => {
                return (
                  <div key={index} className={styles.imageItem}>
                    {file?.url.includes('mp4') || file?.url.includes("mov") ? (
                      <VideoPlayer url={(file?.url)?.toString()} enable_mute={false} />
                    ) : (
                      <LazyImage
                        image={{ src: file?.url, alt: 'Icon' }}
                        objectFit={'cover'}
                        height={isDesktop > 1024 ? 308 : 140}
                        handleClickOnImage={() => handelImageSlider(index, post)}
                        className={'post-media-radious'}
                        imageClassName={'post-loaded-radious'}
                      />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )
    } else if (images?.length === 5) {
      return (
        <>
          {MediaModal && (
            <PostPreviewModal
              setMediaModal={setMediaModal}
              isUserMedia={false}
              postId={postId}
              commentId={commentId}
              setCommentId={setCommentId}
              editPost={editPost}
              editPostMobile={editPostMobile}
              setIsMobileComment={setIsMobileComment}
              isUserPostPreviewModal={isUserPostPreviewModal}
            />
          )}
          <div style={{ 
            width: '100%', 
            maxWidth: '100%', 
            padding: 0, 
            margin: 0, 
            boxSizing: 'border-box' 
          }}>
            <div className={`${styles.imageGrid} ${styles.imageGrid5}`} style={{
              width: '100%',
              maxWidth: '100%',
              margin: 0,
              padding: 0
            }}>
              {images?.slice(0, 5)?.map((file: any, index: any) => {
                return (
                  <div key={index} className={styles.imageItem} style={{
                    width: '100%',
                    height: '100%'
                  }}>
                    {file?.url.includes('mp4') || file?.url.includes("mov") ? (
                      <VideoPlayer url={(file?.url)?.toString()} enable_mute={false} />
                    ) : (
                      <LazyImage
                        image={{ src: file?.url, alt: 'Icon' }}
                        objectFit={'cover'}
                        height={isDesktop > 1024 ? (index === 0 || index === 4 ? 616 : 308) : 140}
                        handleClickOnImage={() => handelImageSlider(index, post)}
                        className={'post-media-radious'}
                        imageClassName={'post-loaded-radious'}
                        // style={{
                        //   width: '100%',
                        //   height: '100%',
                        //   objectFit: 'cover'
                        // }}
                      />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          {MediaModal && (
            <PostPreviewModal
              setMediaModal={setMediaModal}
              isUserMedia={false}
              postId={postId}
              commentId={commentId}
              setCommentId={setCommentId}
              editPost={editPost}
              editPostMobile={editPostMobile}
              setIsMobileComment={setIsMobileComment}
              isUserPostPreviewModal={isUserPostPreviewModal}
            />
          )}
          <div style={{ width: '100%', padding: '8px' }}>
            <Row gutter={[2, 4]}>
              {images?.slice(0, 4)?.map((file: any, index: any) => {
                return (
                  <React.Fragment key={index}>
                    <Col span={12}>
                      {file?.url.includes('mp4') ? (
                        <video width="250" controls style={{ width: '100%', height: '100%', objectFit: 'contain' }}>
                          <source src={file?.url} />
                        </video>
                      ) : (
                        // <img
                        //   onClick={() => handelImageSlider(index, post)}
                        //   src={file?.url}
                        //   alt="preview"
                        //   key={index}
                        //   className="post-media-radious"
                        //   style={{
                        //     cursor: 'pointer',
                        //     width: '100%',
                        //     height: '100%',
                        //     objectFit: 'cover',
                        //     objectPosition: 'center',
                        //   }}
                        // />
                        <LazyImage
                          image={{ src: file?.url, alt: 'Icon' }}
                          objectFit={'cover'}
                          handleClickOnImage={() => handelImageSlider(index, post)}
                          className={'post-media-radious'}
                          height={isDesktop > 1024 ? 308 : 140}
                          imageClassName={'post-loaded-radious'}
                        />
                      )}
                    </Col>
                  </React.Fragment>
                )
              })}
              <Col span={8} onClick={() => handelImageSlider(4, post)}>
                <div
                  className="absolute flex items-center justify-center w-full color-white"
                  style={{
                    color: 'white',
                    bottom: '50%',
                    zIndex: '9',
                    fontSize: '48px',
                    top: '50%',
                    cursor: 'pointer',
                  }}
                >{`+${images.length - 5}`}</div>
                <img
                  src={images[5]?.url}
                  alt="preview"
                  key={'+'}
                  className="post-media-radious"
                  style={{
                    cursor: 'pointer',
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    objectPosition: 'center',
                    filter: 'contrast(0.5)',
                  }}
                />
              </Col>
            </Row>
          </div>
        </>
      )
    }
  } else {
    return <></>
  }
}

export default ImageGridPage
