import { gql } from '@apollo/client'

const GET_SEARCH_STORE_WITH_ELASTIC_SEARCH = gql`
  query SearchStoressWithElasticSearch(
    $search: String
    $limit: Int
    $page: Int
    $category: String
    $subCategory: String
    $isCategoryFilter: Boolean
  ) {
    searchStoressWithElasticSearch(
      search: $search
      limit: $limit
      page: $page
      category: $category
      subCategory: $subCategory
      isCategoryFilter: $isCategoryFilter
    ) {
      success
      message
      data {
        stores {
          id
          name
          slug
          logo_image
          banner_image
          isFollow
          isLike
          like_count
          followers_count
          product_count
          product_images
        }
      }
    }
  }
`
export default GET_SEARCH_STORE_WITH_ELASTIC_SEARCH
// $limit: Int, $page: Int
//  limit: $limit, page: $page
