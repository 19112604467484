import React, { useEffect, useState } from 'react'
import styles from './ClubModal.module.scss'
import SuggestedClubsDetails from './SuggestedClubsDetails'
import { useSelector } from 'react-redux'
import { useAuth } from 'src/utils/auth'
import CreateClubModal from './CreateClubModal'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAppDispatch } from 'src/store/store'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { fetchJoinedClubsData, fetchManageClubsData } from 'src/store/slices/fetchAllClubsSlice'
import JoinClubSection from './JoinClubSection'

export default function ClubModal() {
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const { user }: any = useAuth()

  const dispatch = useAppDispatch()

  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    if (getAllState?.lastModalData?.modal === 'clubModal') {
      dispatch(fetchJoinedClubsData({ page: 1, limit: 100, type: 'JOIN_CLUBS' }))
      dispatch(fetchManageClubsData({ page: 1, limit: 100, type: 'CLUBS_YOU_MANAGE' }))
    }
  }, [])

  const handleCreateClub = () => {
    if (user) {
      dispatch(
        fetchModalData({
          ...getAllState,
          groupCreationModal: {
            modal: 'createClubModal',
            isOpen: true,
            id: {
              isEdit: false,
            },
            modalFor: 'smallModal',
          },
        }),
      )
      // setIsModalVisible(true)
    } else {
      handleRedirect()
    }
  }

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  return (
    <div className={styles.clubModalSection}>
      <div className={styles.mobileViewHeaderSticky}>
        <div className={styles.mobileViewClubModalHeader}>
          <h4>Clubs</h4>
          <div
            className={styles.mobileViewBackArrowAlignment}
            onClick={() => {
              dispatch(
                fetchModalData({
                  allModalData: [
                    ...getAllState?.allModalData,
                    { modal: 'clubModal', isOpen: false, id: 0, modalFor: 'bigModal' },
                  ],
                  lastModalData: getAllState?.lastModalData,
                }),
              )
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="37" height="32" viewBox="0 0 37 32" fill="none">
              <path
                d="M27 0H10C4.47715 0 0 4.47715 0 10V22C0 27.5228 4.47715 32 10 32H27C32.5228 32 37 27.5228 37 22V10C37 4.47715 32.5228 0 27 0Z"
                fill="white"
              />
              <path
                d="M21 10L16 16L21 22"
                stroke="#808191"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

          <div className={styles.mobileViewCreateClub}>
            <div className={styles.mobileCreateClubModalButton} onClick={() => handleCreateClub()}>
              <button>+ Create</button>
            </div>
          </div>
        </div>
      </div>
      {user ? (
        <>
          <JoinClubSection handleCreateClub={handleCreateClub} />
          <SuggestedClubsDetails
            handleCreateClub={handleCreateClub}
            title={'Suggested Clubs'}
            description={'Clubs you might be interested in'}
            APIcallType={'SUGGESTED_CLUBS'}
          />
        </>
      ) : (
        <SuggestedClubsDetails
          handleCreateClub={handleCreateClub}
          title={'Popular Clubs'}
          description={'Clubs you might be interested in'}
          APIcallType={'ALL_CLUB'}
        />
      )}
      {isModalVisible && <CreateClubModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />}
    </div>
  )
}
