import React, { useEffect, useState } from 'react'

export default function ImageModalForRating({ setOpenImagePrev, setOpenImageModalMobile }) {
  const starIcon = (
    <svg width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.06414 1.92489C9.79234 0.468488 11.8707 0.468489 12.5989 1.92489L14.5082 5.7434C14.8036 6.33433 15.3749 6.7385 16.0304 6.82044L19.5455 7.25983C21.1257 7.45735 21.8351 9.34718 20.7752 10.5357L18.2132 13.4086C17.8139 13.8564 17.6396 14.4613 17.7396 15.0529L18.4825 19.4509C18.7519 21.0453 17.0967 22.2706 15.6505 21.5475L11.7152 19.5798C11.1589 19.3017 10.5041 19.3017 9.94783 19.5798L6.01889 21.5443C4.57147 22.268 2.91541 21.0402 3.18731 19.4449L3.93537 15.0559C4.03648 14.4626 3.86173 13.8556 3.46064 13.4069L0.894897 10.5368C-0.167149 9.34874 0.541729 7.4568 2.12298 7.25915L5.6326 6.82044C6.28818 6.7385 6.85942 6.33433 7.15489 5.7434L9.06414 1.92489Z"
        fill="#FF7D23"
      />
    </svg>
  )

  const rattingArray = []
  const [splittedArray, setSplittedArray] = useState([])
  const [selectedRate, setSelectedRate] = useState(6)

  const imageArray = [
    { id: 1, image: '/assets/img/product-detail/c-image-1.jpg' },
    { id: 2, image: '/assets/img/product-detail/c-image-2.jpg' },
    { id: 3, image: '/assets/img/product-detail/c-image-3.jpg' },
    { id: 4, image: '/assets/img/product-detail/c-image-4.jpg' },
    { id: 5, image: '/assets/img/product-detail/c-image-5.jpg' },
    { id: 6, image: '/assets/img/product-detail/c-image-6.jpg' },
    { id: 7, image: '/assets/img/product-detail/c-image-7.jpg' },
    { id: 8, image: '/assets/img/product-detail/c-image-8.jpg' },
    { id: 9, image: '/assets/img/product-detail/c-image-9.jpg' },
    { id: 10, image: '/assets/img/product-detail/c-image-10.jpg' },
    { id: 11, image: '/assets/img/product-detail/c-image-11.jpg' },
    { id: 12, image: '/assets/img/product-detail/c-image-12.jpg' },
    { id: 13, image: '/assets/img/product-detail/c-image-13.png' },
    { id: 14, image: '/assets/img/product-detail/c-image-14.jpg' },
    { id: 15, image: '/assets/img/product-detail/c-image-15.jpg' },
    { id: 16, image: '/assets/img/product-detail/c-image-16.jpg' },
  ]

  const ratingSection = [
    { id: 6, name: 'All', starIcon: starIcon, ratting: rattingArray.length },
    { id: 5, name: '5', starIcon: starIcon, ratting: 5 },
    { id: 4, name: '4', starIcon: starIcon, ratting: 4 },
    { id: 3, name: '3', starIcon: starIcon, ratting: 3 },
    { id: 2, name: '2', starIcon: starIcon, ratting: 2 },
    { id: 1, name: '1', starIcon: starIcon, ratting: 1 },
  ]

  const handleSelect = (rate) => {
    setSelectedRate(rate.id)
    // if (rate.id === 6) {
    //   setRatingData(rattingArray)
    // } else {
    //   let data = rattingArray.filter((star) => Math.trunc(star.ratting) === rate.id)
    //   setRatingData(data)
    // }
  }

  const spliceIntoChunks = (tempDiscardtweet: any, chunkSize: any) => {
    const resChunk: any = []
    if (tempDiscardtweet.length > 0) {
      let discardtweet = [...tempDiscardtweet]
      while (discardtweet.length > 0) {
        const chunk = discardtweet && discardtweet?.splice(0, chunkSize)
        resChunk.push(chunk)
        setSplittedArray(resChunk)
      }
    } else {
      setSplittedArray([])
    }
    return resChunk
  }

  useEffect(() => {
    spliceIntoChunks(imageArray, Math.ceil(imageArray.length / 2))
  }, [])

  return (
    <div className="image-container-main">
      <div className="image-header-mobile">
        <div className="image-title">
          <h3>Images</h3>
          <span>(54)</span>
        </div>
      </div>
      <div className="ratting-menu-section">
        {ratingSection.map((rate, index) => {
          return (
            <div
              className={selectedRate === rate.id ? 'star-container active-class' : 'star-container'}
              onClick={() => handleSelect(rate)}
              key={index}
            >
              <div className="all-start-align">
                <span>{rate.name}</span>
              </div>
              <div className="start-icon-align">{rate.starIcon}</div>
              <div className="feedback-total-count">
                <p>({rate.ratting})</p>
              </div>
            </div>
          )
        })}
      </div>
      <div className="image-card-flex-grid-alignment">
        {splittedArray?.map((productColumn, index) => {
          return (
            <div key={index}>
              {productColumn?.map((item: any, idx) => {
                return (
                  <div
                    className="image-main-section"
                    onClick={() => {
                      setOpenImagePrev({ image: item.image, modal: true })
                      setOpenImageModalMobile(false)
                    }}
                    key={`a${idx}`}
                  >
                    <div className="image-align-mobile">
                      <img src={item.image} />
                    </div>
                    <div className="image-info-main-section">
                      <div className="image-rate-section">
                        <div className="star-count-section">
                          <span>5</span>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.06414 1.92489C9.79234 0.468488 11.8707 0.468489 12.5989 1.92489L14.5082 5.7434C14.8036 6.33433 15.3749 6.7385 16.0304 6.82044L19.5455 7.25983C21.1257 7.45735 21.8351 9.34718 20.7752 10.5357L18.2132 13.4086C17.8139 13.8564 17.6396 14.4613 17.7396 15.0529L18.4825 19.4509C18.7519 21.0453 17.0967 22.2706 15.6505 21.5475L11.7152 19.5798C11.1589 19.3017 10.5041 19.3017 9.94783 19.5798L6.01889 21.5443C4.57147 22.268 2.91541 21.0402 3.18731 19.4449L3.93537 15.0559C4.03648 14.4626 3.86173 13.8556 3.46064 13.4069L0.894897 10.5368C-0.167149 9.34874 0.541729 7.4568 2.12298 7.25915L5.6326 6.82044C6.28818 6.7385 6.85942 6.33433 7.15489 5.7434L9.06414 1.92489Z"
                              fill="#FF7D23"
                            />
                          </svg>
                        </div>
                        <div className="image-count-alignment">
                          <img src={'/assets/img/product-detail/dummy-image-icon.svg'} />
                          <span>2</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}
