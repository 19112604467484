import React, { useEffect, useState } from 'react'
import NowTrendingBanner from '../../../../../public/assets/img/BookApplience/now-trending.png'
import MobileNowTrendingBanner from '../../../../../public/assets/img/BookApplience/mobileNowTrendingBg.png'
import BootMarkIcon from '../../../../../public/assets/icon/bookmark-white.svg'
import MoreOptionIcon from '../../../../../public/assets/icon/more-option.svg'
import StarIcon from '../../../../../public/assets/icon/orange-star.svg'
import aroww from '../../../../../public/assets/img/WomansCategory/aroww.png'
import others from '../../../../../public/assets/img/WomansCategory/others.png'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import { fetchProductByCategory } from 'src/store/slices/fetchAllProduct'
import { useAuth } from 'src/utils/auth'
import { manageBookmark } from 'src/store/slices/manageBookmarkTab'
import { useMutation } from '@apollo/client'
import ADD_BOOKMARK from 'src/graphql/mutations/addBookmark'
import REMOVE_BOOKMARK from 'src/graphql/mutations/removeBookmark'

export default function BookApplianceNowTrendingModal() {
  const dispatch = useAppDispatch()
  const { user } = useAuth()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const bookmarkTabData = useSelector((state: any) => state?.manageBookmarkTab?.data)
  const [trendingProducts, setTrendingProducts] = useState<any[]>([])
  const [bookmarkedProducts, setBookmarkedProducts] = useState<{[key: string]: boolean}>({})

  useEffect(() => {
    fetchTrendingProducts()
  }, [])

  useEffect(() => {
    // Load bookmarked state from localStorage
    const loadBookmarkedState = () => {
      const newBookmarkedState: {[key: string]: boolean} = {}
      trendingProducts.forEach(product => {
        const isBookmarked = localStorage.getItem(`bookmarked_${product.id}`)
        if (isBookmarked) {
          newBookmarkedState[product.id] = JSON.parse(isBookmarked)
        }
      })
      setBookmarkedProducts(newBookmarkedState)
    }
    loadBookmarkedState()
  }, [trendingProducts])

  const fetchTrendingProducts = () => {
    dispatch(
      fetchProductByCategory({
        category: 'beauty-and-cosmetics',
        subCategory: '',
        childSubCategory: '',
        isShopifyProduct: false,
        page: 1,
        limit: 10,
      }),
    ).then((response) => {
      setTrendingProducts(response?.payload?.getAllProducts?.data || [])
    })
  }

  const handleClose = () => {
    let datass = getAllState?.allModalData?.filter((item: any) => item?.modal !== getAllState?.lastModalData?.modal)
    dispatch(
      fetchModalData({
        allModalData: datass,
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  const formatPrice = (price: string) => {
    return parseFloat(price).toFixed(2)
  }

  const handleAddBookmark = (product: any) => {
    if (!user) {
      // Handle guest user case - redirect to login or show message
      return
    }

    dispatch(
      manageBookmark({
        modal: '',
        isOpen: true,
        id: {
          ...bookmarkTabData?.id,
          type: 'product',
          bookmark_id: product?.bookmark?.id,
          product_id: product?.id,
          collection_id: product?.bookmark?.collection?.id,
          addProductToCollection: {
            ...bookmarkTabData?.id?.deleteCollection,
            isShowAddCollectionModel: true,
            productId: product?.id,
          },
        },
      }),
    )
    
    // Update local state
    setBookmarkedProducts(prev => ({
      ...prev,
      [product.id]: true
    }))
    localStorage.setItem(`bookmarked_${product.id}`, JSON.stringify(true))
  }

  const handleMoreOptions = (product: any) => {
    // Implement more options functionality
    console.log('More options clicked for product:', product.id)
  }

  return (
    <div className="book-appliance-now-trending-store-details-alignment">
      <div className="mobile-view-book-appliance-back-alignment">
        <div className="book-appliance-back_details-alignment">
          <div onClick={() => handleClose()}>
            <img src="/assets/icon/left-icon.svg" alt="back-page" />
          </div>
          <p>Home...</p>
        </div>
        <div className="mobile-heading-alignment">
          <h4>Now Trending</h4>
        </div>
      </div>
      <div className="book-appliance-now-trending-store-banner-alignment">
        <img src={NowTrendingBanner.src} alt="NowTrendingBanner" />
        <img src={MobileNowTrendingBanner.src} alt="MobileNowTrendingBanner" className="mobile-view-banner" />

        <div className="book-appliance-now-trending-store-banner-details">
          <h4>Now Trending</h4>
          <p>Upgrade your home with trending smart devices and stylish decor.</p>
        </div>
      </div>

      <div className="book-appliance-now-trending-details-alignment">
        <div className="book-appliance-now-trending-grid-alignment">
          {trendingProducts.map((product, index) => (
            <div key={product.id || index} className="book-appliance-now-trending-gridItem-alignment">
              <div className="book-appliance-now-trending-img-alignment">
                <img 
                  src={product.images?.[0]?.src || ''} 
                  alt={product.title || 'Product Image'} 
                />
                <div className="hover-card-details-alignment">
                  <div className="other-option-alignment-details">
                    <div 
                      className={`hover-option-box-alignment ${bookmarkedProducts[product.id] ? 'active' : ''}`}
                      onClick={() => handleAddBookmark(product)}
                    >
                      <img src={BootMarkIcon.src} alt="BootMarkIcon" />
                    </div>
                    {/* <div 
                      className="hover-option-box-alignment"
                      onClick={() => handleMoreOptions(product)}
                    >
                      <img src={MoreOptionIcon.src} alt="MoreOptionIcon" />
                    </div> */}
                  </div>
                  <div className="hover-details-other-details-alignment">
                    <h5>{product.title}</h5>
                    <div className="price-details-alignment">
                      <div className="price-tag-alignment">
                        <p>${formatPrice(product.dis_price || '0')}</p>
                        {product.dis_listPrice && <del>${formatPrice(product.dis_listPrice)}</del>}
                      </div>
                      {product.dis_listPrice && product.dis_price && (
                        <div className="offer-tag-alignment">
                          <span>
                            {Math.round(((parseFloat(product.dis_listPrice) - parseFloat(product.dis_price)) / parseFloat(product.dis_listPrice)) * 100)}% OFF
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="review-rate-alignment">
                      <div className="rate-alignment">
                        <p>{product.rating || '4.5'}</p>
                        <div className="rate-star-alignment">
                          {[...Array(5)].map((_, i) => (
                            <img key={i} src={StarIcon.src} alt="StarIcon" />
                          ))}
                        </div>
                      </div>
                      <div className="review-number-alignment">
                        <span>({product.comment_count || '0'})</span>
                      </div>
                    </div>

                    <div className="hover-hike">
                      <span className="inter">
                        <img src={others.src} alt="hike" /> Interest
                      </span>
                      <span className="incris">
                        <img src={aroww.src} alt="incris" /> {product.interest_rate || '200%'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
