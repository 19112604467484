import React from 'react'
import styles from './ClubSlider.module.scss'
import { useAppDispatch } from 'src/store/store'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import LazyImage from 'src/utils/LazyImage'
import Link from 'next/link'
import { useSelector } from 'react-redux'
import { fetchSearchData } from 'src/store/slices/searchModalSlice'
import { fetchModalData } from 'src/store/slices/manageModalSlice'

export default function ClubSlider({ club, leaveGroupCall, joingroupCall, isOpen }) {
  const dispatch = useAppDispatch()
  const fetchSearchModalData = useSelector((state: any) => state?.searchModalSlice?.data)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const handleRedirectClub = () => {
    // dispatch(fetchSearchData({ ...fetchSearchModalData, isOpen: false, id: {}, search: '', searchPreview: '' }))
    let filtredModal = getAllState?.allModalData?.filter((item) => item?.modal !== getAllState?.lastModalData?.modal)
    dispatch(
      fetchModalData({
        ...getAllState,
        allModalData: filtredModal,
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  const handelImageSlider = () => {}

  return (
    <div className={styles.clubModalDetailsAllContentAlignment}>
      <div className={styles.clubModalDetailsCardGridItem}>
        <div className={styles.clubModalDetailsCardImage}>
          {/* <img src={club?.bannerImage} alt="CardImage" /> */}
          <LazyImage
            image={{ src: club?.banner_image ? club?.banner_image : '/assets/img/club-banner-img.png', alt: 'Icon' }}
            objectFit={'cover'}
            handleClickOnImage={() => handelImageSlider()}
            position={'absolute'}
            borderRadius={'16px 16px 0 0'}
          />
        </div>
        <div className={styles.clubModalblurDiv}></div>
        <div className={styles.clubModalCardDetails}>
          <div className={styles.clubModalCardDetailsAlignment}>
            <Link href={`/club/${club?.slug}`}>
              <h4 onClick={() => handleRedirectClub()}>{club?.name}</h4>
            </Link>
            <h5 className={styles.clubModalLimeCamp}>{club?.description}</h5>
            <div className={styles.clubModalAllContentAlignment}>
              <div className={styles.clubModalusers}>
                <div className={styles.clubModalUserIcon}>
                  <img src="/assets/icon/users-club.svg" alt="user" />
                  <div className={styles.clubModaLeftPart} onClick={() => handleRedirect()}>
                    <div className={styles.clubModaUsersLikes}>
                      {club?.members?.map((member, index) => {
                        return (
                          <div className={styles.clubModalUserBox} key={index}>
                            <LazyImage
                              image={{ src: member?.logo_image, alt: 'Icon' }}
                              objectFit={'cover'}
                              handleClickOnImage={() => handelImageSlider()}
                              height={20}
                              width={20}
                              position={'absolute'}
                              borderRadius={'50%'}
                              className={styles.clubLazyImg}
                            />
                          </div>
                        )
                      })}
                    </div>
                    {club?.members?.length > 3 && (
                      <div className={styles.clubModaCount}> +{club?.total_members - club?.members?.length}</div>
                    )}
                  </div>
                </div>
                <div className={styles.clubModalRightSideContent}>
                  {club?.isExist ? (
                    <button onClick={() => leaveGroupCall(club)}>Joined</button>
                  ) : (
                    <button className={styles.joinButton} onClick={() => joingroupCall(club)}>
                      Join Club
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
