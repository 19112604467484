import React, { useState } from 'react'
import SellerLeftSidebar from 'src/components/Seller/SellerLeftSidebar'
import SellerRightSidebar from 'src/components/Seller/SellerRightSidebar'
import SellerProtectedRoute from 'src/hoc/SellerProtectedRoute'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { fetchPageScrollEventSlice } from 'src/store/slices/pageScrollEventSlice'
import { useAuth } from 'src/utils/auth'
import { useRouter } from 'next/router'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'
import { fetchProductErrorHandler } from 'src/store/slices/productErrorHandlerSlice'
import MobileViewSellerSidebar from 'src/components/Seller/SellerSidebar/MobileViewSellerSidebar'
import LeftSidebar from '../shared/LeftSidebar'
import { useQuery } from '@apollo/client'
import GET_CURRENT_USER from 'src/graphql/queries/getCurrentUser'
import ProtectedRoute from 'src/hoc/ProtectedRoute'

const SummarySidebar = ({ children }) => {
  const { user }: any = useAuth()
  const router = useRouter()
  const dispatch = useAppDispatch()
  const fetchSroll = useSelector((state: any) => state?.pageScrollEventSlice?.data)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const fetchProductImageResizeData = useSelector((state: any) => state?.productResizeData?.data)
  const [isDashboardSidebarModal, setIsDashboardSidebarModal] = useState(false)
  const [openSidebar, setOpenSidebar] = useState(false)
  const { loading, error, data, refetch } = useQuery(GET_CURRENT_USER)

  React.useEffect(() => {
    if (isDashboardSidebarModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [isDashboardSidebarModal])

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop
    dispatch(
      fetchPageScrollEventSlice({
        scroll: scrollPosition,
      }),
    )
  }

  const renderSentence = () => {
    switch (router?.pathname) {
      case '/seller/dashboard':
        return 'Dashboard'
      case '/seller/social-activity':
        return 'Social Activity'
      case '/seller/insight':
        return 'Insight'
      case '/seller/finance':
        return 'Finance'
      case '/seller/products':
        return 'Product'
      case '/seller/order-shipping':
        return 'Order & Shipping'
      case '/seller/setting':
        return 'Settings'
      default:
        return ''
    }
  }

  const handleClose = (data) => {
    setIsDashboardSidebarModal(data)
  }

  return (
    <>
      <div
        className="summary-layout-alignment 
      "
      >
        {/* setting-page-grid */}
        <div className="gutter-row mobile-view-padding-margin left-sidebar-alignment">
          <div className={`${openSidebar ? 'relative w-full sticky-div allDetailsDiv' : 'relative w-full sticky-div'}`}>
            <div>
              {!isDashboardSidebarModal && (
                <LeftSidebar
                  setOpenSidebar={setOpenSidebar}
                  openSidebar={openSidebar}
                  currentUser={data?.currentUser}
                />
              )}
            </div>
          </div>
        </div>
        <div
          className={
            isDashboardSidebarModal || fetchSroll?.scroll > 220
              ? 'mobileViewSrollSkicty Open'
              : 'mobileViewSrollSkicty Close'
          }
        >
          <div
            className={
              isDashboardSidebarModal
                ? 'mobileViewScrollHeaderAlignment'
                : 'mobileViewScrollHeaderAlignment no-border-alignment'
            }
          >
            <div className="mobileViewBackIcon" onClick={() => router.back()}>
              <img src="/assets/icon/Back.svg" alt="back" />
            </div>
            <div className="mobileViewScrollProfileDetailsAlignment">
              <div className="mobileViewScrollProfileDetailsImg">
                <img src={user?.store?.logo} alt="seller profile img" />
              </div>
              <h6>{user?.store?.name}</h6>
            </div>
          </div>
        </div>
        {/* <div
          className={
            fetchSroll?.scroll > 220 ? 'sellerMobileViewSidebarDetails' : 'sellerMobileViewSidebarDetails show'
          }
        >
          <div className="sellerMobileHedingFlex">
            <div className="sellerMenuIcon" onClick={() => setIsDashboardSidebarModal(true)}>
              <img src="/assets/seller/icons/menu.svg" alt="menu icon" />
            </div>
            <h4>{renderSentence()}</h4>
          </div>

          {router?.pathname === '/seller/products' && (
            <div
              className="sellerMobileViewButton"
              onClick={() => {
                dispatch(
                  fetchModalData({
                    allModalData: [
                      ...getAllState?.allModalData,
                      { modal: 'addProductModal', isOpen: true, id: 0, modalFor: 'mediumModal', isEdit: false },
                    ],
                    lastModalData: getAllState?.lastModalData,
                  }),
                )
                dispatch(
                  fetchProductImageResizeModal({
                    modal: '',
                    isOpen: false,
                    id: {
                      ...fetchProductImageResizeData?.id,
                      prevImages: [],
                      prevVideos: [],
                      isDeleted: [],
                      selectedIndexofMediaForEdit: '',
                      selectedSize: { width: 1080, height: 1080 },
                      isSelected: false,
                      ratio: '1 : 1',
                      media: [],
                    },
                  }),
                )
                dispatch(
                  fetchProductErrorHandler({
                    errors: {},
                    focusFiled: '',
                  }),
                )
              }}
            >
              <button>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M8 3.33398V12.6673"
                    stroke="#556EE6"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.33301 8H12.6663"
                    stroke="#556EE6"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                New Product
              </button>
            </div>
          )}

          {router?.pathname === '/seller/setting' && (
            <div className="seller-mobile-view-save-button">
              <button>Save </button>
            </div>
          )}
        </div> */}
        <div
          className={`mobile-view-seller-follow-followingOverlay ${isDashboardSidebarModal ? 'open' : ''}`}
          onClick={() => setIsDashboardSidebarModal(false)}
        />
        <div className={`mobile-view-seller-follow-following-modal ${isDashboardSidebarModal ? 'open' : ''}`}>
          <MobileViewSellerSidebar handleClose={handleClose} />
        </div>
        <div className="gutter-row mt32 w-100 mobile-view-padding-margin mobile-view-height-alignment center-details-alignment summary-right-side-details-alignment">
          {children}
        </div>
        <div className="gutter-row mt16 mobile-view-none mobile-view-padding-margin right-sidebar-alignment">
          <div className="sticky" style={{ top: '86px' }}>
            <div className="m16">
              <SellerRightSidebar />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProtectedRoute(SummarySidebar)
