import { gql } from '@apollo/client'

const GET_ALL_PRODUCT_BY_STORE = gql`
  query getAllProductByStore(
    $store_id: Int
    $page: Int!
    $limit: Int!
    $search: String
    $isUnassigned: Boolean
    $status: String
    $order: String
    $category: [Int]
    $subCategory: [Int]
    $childSubCategory: [Int]
    $minPrice: Int
    $maxPrice: Int
  ) {
    getAllProductByStore(
      store_id: $store_id
      page: $page
      limit: $limit
      search: $search
      isUnassigned: $isUnassigned
      status: $status
      order: $order
      category: $category
      subCategory: $subCategory
      childSubCategory: $childSubCategory
      minPrice: $minPrice
      maxPrice: $maxPrice
    ) {
      data {
        id
        title
        slug
        like_count
        comment_count
        sharepost_count
        dis_price
        dis_listPrice
        total_variants
        total_inventory_quantity
        images {
          media_id
          src
        }
        variants {
          inventory_quantity
        }
        likes {
          id
        }
        bookmark {
          id
          collection {
            id
            name
            user_id
            isPrivate
          }
        }
      }
      total
    }
  }
`
export default GET_ALL_PRODUCT_BY_STORE
