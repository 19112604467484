import { gql } from '@apollo/client'
import { ASTValidationContext } from 'graphql/validation/ValidationContext'

export const ADD_PRODUCT_BRAND = gql`
  mutation AddProductBrand($name: String, $store_id: Int!) {
    addProductBrand(name: $name, store_id: $store_id) {
      brand {
        id
        name
      }
      message
      success
    }
  }
`
