import { gql } from '@apollo/client'

const REMOVE_BOOKMARK_COLLECTIONS = gql`
  mutation RemoveCollection($slug: String) {
    removeCollection(slug: $slug) {
      message
      success
    }
  }
`

export default REMOVE_BOOKMARK_COLLECTIONS
