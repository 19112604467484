import React from 'react'
import styles from './CategoryNewArrivals.module.scss'
import NewArrivalProduct from 'src/components/Category/ShopByCategoryDetails/NewArrivalProduct'
export default function CategoryNewArrivals() {
  return (
    <div className={styles.categoryNewArrivaldProductSection}>
      <div className={styles.categoryNewArrivalheading}>
        <h4>New Arrivals</h4>
      </div>
      <div className={styles.categorNewArrivalBodyAlignemt}>
        <div className={styles.categorNewArrivalGridAlignment}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => {
            return (
              <React.Fragment key={index}>
                <NewArrivalProduct product={{}} />
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </div>
  )
}
