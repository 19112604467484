import React, { useEffect, useState } from 'react'
import styles from './PeopleModal.module.scss'
import { Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import FollowersSection from './FollowersSection'
import FollowingSection from './FollowingSection'
import FindPeopleSection from './FindPeopleSection'
import { useAmp } from 'next/amp'
import { useAuth } from 'src/utils/auth'
import { useAppDispatch } from 'src/store/store'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useSelector } from 'react-redux'
import useDebounce from 'src/hoc/useDebounce'
import { fetchAllPeople } from 'src/store/slices/fetchAllPeopleSlice'

export default function PeopleModal() {
  const { user } = useAuth()
  const [index, setIndex] = useState(user ? '1' : '3')
  const [search, setSearch] = useState('')

  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  const handleChange = (index) => {
    setIndex(index)
  }

  const handleSearchChange = (e) => {
    setSearch(e.target.value)
    setIndex('3')
  }

  return (
    <div className={styles.peopleModalBoxAlignment}>
      <div className={styles.newSuggestedStores}>
        <div className={styles.storesGrid}>
          <div>
            <div className={styles.mobileShow}>
              <div
                onClick={() => {
                  dispatch(
                    fetchModalData({
                      allModalData: [
                        ...getAllState?.allModalData,
                        { modal: 'peopleModal', isOpen: false, id: 0, modalFor: 'bigModal' },
                      ],
                      lastModalData: getAllState?.lastModalData,
                    }),
                  )
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="37" height="32" viewBox="0 0 37 32" fill="none">
                  <path
                    d="M27 0H10C4.47715 0 0 4.47715 0 10V22C0 27.5228 4.47715 32 10 32H27C32.5228 32 37 27.5228 37 22V10C37 4.47715 32.5228 0 27 0Z"
                    fill="white"
                  />
                  <path
                    d="M21 10L16 16L21 22"
                    stroke="#808191"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <p>People</p>
            <span>Search people with similar shopping interest</span>
          </div>
          <div className={styles.peopleFollowerSearchAlignment}>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
              <path
                d="M19 27C23.4183 27 27 23.4183 27 19C27 14.5817 23.4183 11 19 11C14.5817 11 11 14.5817 11 19C11 23.4183 14.5817 27 19 27Z"
                stroke="#ACB1C0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M28.9999 28.9999L24.6499 24.6499"
                stroke="#ACB1C0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <input
              type="text"
              name=""
              id=""
              placeholder="Search People by Name"
              onChange={(e) => handleSearchChange(e)}
            />
          </div>
        </div>
      </div>
      <div className={styles.peopleModalTabAlignment}>
        <Tabs activeKey={index} onChange={handleChange}>
          {user && (
            <TabPane tab="Followers" key="1">
              <FollowersSection index={index} search={search} setSearch={setSearch} />
            </TabPane>
          )}
          {user && (
            <TabPane tab="Following" key="2">
              <FollowingSection index={index} setSearch={setSearch} />
            </TabPane>
          )}
          <TabPane tab="Find People" key="3">
            <FindPeopleSection index={index} search={search} setSearch={setSearch} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}
