import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useAuth } from 'src/utils/auth'
import { formatTime } from 'src/utils/helperFuncs'
import { useStateContext } from 'src/utils/state'
import Messagechatboxslidert from '../MessageChatboxslider'
import { X } from 'lucide-react'

const React1 = '/assets/icon/react-1.svg'
const React2 = '/assets/icon/react-2.svg'
const React3 = '/assets/icon/react-3.svg'
const React4 = '/assets/icon/react-4.svg'
const React5 = '/assets/icon/react-5.svg'
const React6 = '/assets/icon/react-6.svg'
const PlusIcon = '/assets/icon/plus-button-icon.svg'

export default function MessageBubble({
  message,
  isGroupGlobalChat,
  isSameUser,
  handleEdit,
  handleRemove,
  sendReaction,
}) {
  const { user } = useAuth()
  const isSentMsg = message.senderId == user?.id
  const [replyMessageId, setReplyMessageId] = useState(null)
  const { selectedChat, notify, updateName, selectChat } = useStateContext()
  const dispatch = useAppDispatch()
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)

  const handleReplyMessage = (msg) => {
    setReplyMessageId(msg?.id)
    selectChat({ ...selectedChat?.chatData, replyMessageId: msg?.id }, selectedChat?.chatType)
  }

  const removeReplyMessage = () => {
    setReplyMessageId(null)
    selectChat({ ...selectedChat?.chatData, replyMessageId: null }, selectedChat?.chatType)
  }


  const [chatReaction, setChatReaction] = useState(false)
  const [messageOption, setMessageOption] = useState(false)


  const sendReactionFun = (reaction_emoji_id) => {

    switch (reaction_emoji_id) {
      case 1:
        return <img src={React1} alt="React1" />
      case 2:
        return <img src={React2} alt="React1" />
      case 3:
        return <img src={React3} alt="React1" />
      case 4:
        return <img src={React4} alt="React1" />
      case 5:
        return <img src={React5} alt="React1" />
      default:
        return <img src={React6} alt="React1" />
    }
  }

  const reactImageArray = [
    {
      id: 1,
      emoji: React1,
    },
    {
      id: 2,
      emoji: React2,
    },
    {
      id: 3,
      emoji: React3,
    },
    {
      id: 4,
      emoji: React4,
    },
    {
      id: 5,
      emoji: React5,
    },
    {
      id: 6,
      emoji: React6,
    },
  ]

  return (
    <React.Fragment>
      {isSentMsg ? (
        <>
          <div
            className={`right-message-box-alignment ${message?.id === fetchMobileModal?.lastModalData?.id?.selectedMessageId ? 'selectedEffect' : ''
              }`}
            onMouseLeave={(e) => {
              setChatReaction(false)
              e.stopPropagation()
              setMessageOption(null)
            }}
            onMouseEnter={(e) => {
              e.stopPropagation()
              setMessageOption(message?.id)
            }}
          >
            <div
              className={`right-side-main-alignment right-side-replied-details-section ${selectedChat?.chatData?.replyMessageId === message?.id ? 'border-effect' : ''
                }`}
            >
              {selectedChat?.chatData?.replyMessageId === message?.id && (
                <div className='cross-icon-absolute' >
                  <X size={16} onClick={() => removeReplyMessage()} />
                </div>)}
              {message?.replyMessage?.body && (
                <div className="right-side-replied-details-alignment">
                  <p>you replied</p>
                  <div className="right-side-replied-message-box-alignment">
                    <p>{message?.replyMessage?.body}</p>
                  </div>
                </div>
              )}
              {message?.shared_content?.type === 'PRODUCT' && (
                <div className="message-box-right-side-message-alignment">
                  <Messagechatboxslidert product_id={message?.shared_content?.product_id} />
                </div>
              )}
              {message?.body && (
                <>
                  <div className="message-box-right-side-message-alignment">
                    {message?.isEdited && 'Edited'}
                    <div className="web-view-time-alignment">
                      <span>{formatTime(message.createdAt)}</span>
                    </div>
                    <div
                      className={`right-message-box-alignment ${message?.id === fetchMobileModal?.lastModalData?.id?.selectedMessageId
                        ? 'selectedEffect'
                        : 'closeEffect'
                        }`}
                    >
                      <p
                        onClick={() => {
                          dispatch(
                            fetchMobileModalData({
                              allModalData: [
                                ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                                {
                                  modal: 'chatBoxModal',
                                  isOpen: true,
                                  id: {
                                    selectedMessageId: message?.id,
                                    isMessageSelected: true,
                                    message,
                                  },
                                  transform: 42,
                                  zIndex: 9999999,
                                },
                              ],
                              lastModalData: fetchMobileModal?.lastModalData,
                            }),
                          )
                        }}
                      >
                        {message?.body}
                      </p>
                      {message?.reactions?.length > 0 && (
                        <div className="reacted-details">
                          <div className="reated-details-flex-alignment">
                            {reactImageArray?.map((r, i) => {
                              let filter = reactImageArray?.find(
                                (re) => re?.id === message?.reactions[i]?.emoji_id,
                              )

                              return filter ? (
                                <div className="reacted-icon-alignment">
                                  <img src={filter?.emoji} alt="React1" />
                                </div>
                              ) : (
                                <></>
                              )
                            })}
                            {/* <div className="reacted-icon-alignment">
                                    <img src={React2} alt="React1" />
                                  </div>
                                  <div className="reacted-icon-alignment">
                                    <img src={React3} alt="React1" />
                                  </div>
                                  <div className="reacted-icon-alignment">
                                    <img src={React4} alt="React2" />
                                  </div>
                                  <div className="reacted-icon-alignment">
                                    <img src={React5} alt="React5" />
                                  </div>
                                  <div className="reacted-icon-alignment">
                                    <img src={React6} alt="React5" />
                                  </div> */}
                          </div>
                        </div>
                      )}
                      <div className="mobile-view-right-side-message-time-alignment">
                        <span>{formatTime(message.createdAt)} </span>
                      </div>
                      {messageOption === message?.id && (
                        <div className="right-message-hover-details-alignment">
                          <div className="message-icon-alignment" onClick={() => handleRemove(message)}>
                            <img src="/assets/icon/message-delete-icon.svg" alt="delete arrow" />
                          </div>
                          <div className="message-icon-alignment" onClick={() => handleEdit(message)}>
                            <img src="/assets/icon/message-edit-icon.svg" alt="left arrow" />
                          </div>
                          <div className="message-icon-alignment" onClick={() => handleReplyMessage(message)}>
                            <img src="/assets/icon/message-left-arrow.svg" alt="left arrow" />
                          </div>
                          {/* <div className="message-icon-alignment">
                            <img src="/assets/icon/message-right-arrow.svg" alt="right arrow" />
                          </div> */}
                          <div
                            className="message-icon-alignment"
                            onMouseEnter={(e) => {
                              e.stopPropagation()
                              setChatReaction(true)
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <g clipPath="url(#clip0_104_1091)">
                                <path
                                  d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                                  stroke="#636773"
                                  strokeWidth="1.25"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6 10.5C6 10.5 7.125 12 9 12C10.875 12 12 10.5 12 10.5"
                                  stroke="#636773"
                                  strokeWidth="1.25"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6.75 6.75H6.75667"
                                  stroke="#636773"
                                  strokeWidth="1.25"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M11.25 6.75H11.2567"
                                  stroke="#636773"
                                  strokeWidth="1.25"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_104_1091">
                                  <rect width="18" height="18" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            { }
                            {chatReaction && (
                              <div className="reaction-box-alignment">
                                <div className="child-reaction-box" onClick={() => sendReaction(message, 1)}>
                                  <img src={React1} alt="React1" />
                                </div>
                                <div className="child-reaction-box" onClick={() => sendReaction(message, 2)}>
                                  <img src={React2} alt="React1" />
                                </div>
                                <div className="child-reaction-box" onClick={() => sendReaction(message, 3)}>
                                  <img src={React3} alt="React1" />
                                </div>
                                <div className="child-reaction-box" onClick={() => sendReaction(message, 4)}>
                                  <img src={React4} alt="React1" />
                                </div>
                                <div className="child-reaction-box" onClick={() => sendReaction(message, 5)}>
                                  <img src={React5} alt="React1" />
                                </div>
                                <div className="child-reaction-box" onClick={() => sendReaction(message, 6)}>
                                  <img src={React6} alt="React1" />
                                </div>
                                <div className="child-reaction-box">
                                  <img src={PlusIcon} alt="PlusIcon" />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    {message?.replyMessage?.body && (
                      <div className="replied-arrow-alignment">
                        <div className="right-side-replied-arrow-alignment">
                          <img src="/assets/icon/replied-left-arrow.svg" alt="repplied" />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            {message?.images?.length > 0 && (
              <div className="right-img-flex">
                <div className="right-side-media-alignment">
                  <img src={message?.images[0]} alt="meadia-img" />
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div
            className="left-side-main-alignment replied-details-section"
            // onMouseLeave={(e) => {
            //   setChatReaction(false)
            // }}
            onMouseLeave={(e) => {
              setChatReaction(false)
              e.stopPropagation()
              setMessageOption(null)
            }}
            onMouseEnter={(e) => {
              e.stopPropagation()
              setMessageOption(message?.id)
            }}
          >
            <div className="message-box-left-side-message-alignment">
              <div className="left-side-user-details-flex-alignemnt">
                {message?.shared_content?.type === 'PRODUCT' && (
                  <div className="left-side-user-details-flex-alignemnt">
                    <Messagechatboxslidert product_id={message?.shared_content?.product_id} />
                  </div>
                )}
              </div>
              <div className="left-side-user-details-flex-alignemnt">
                {isGroupGlobalChat && !isSameUser && user.id !== message.senderId ? (
                  <div className="left-side-user-img-alignment">
                    <img
                      src={
                        message?.user?.profileAvtar?.length
                          ? message?.user?.profileAvtar[0]
                          : '/assets/img/message-user-img.png'
                      }
                      alt="message userr"
                    />
                  </div>
                ) : (
                  isGroupGlobalChat && <div className="left-side-user-img-alignment"></div>
                )}

                <div
                  className={`repied-details-all-alignment  ${selectedChat?.chatData?.replyMessageId === message?.id ? 'border-effect' : ''} ${message?.body && Boolean(message?.images?.length) ? 'img-with-message' : ''
                    }`}
                >
                  {selectedChat?.chatData?.replyMessageId === message?.id && (
                    <div className='cross-icon-absolute' >
                      <X size={16} onClick={() => removeReplyMessage()} />
                    </div>)}
                  {message?.replyMessage?.body && (
                    <div className="replied-details-alignment">
                      <p>Replied to you</p>
                      <div className="replied-message-box-alignment">
                        <p>{message?.replyMessage?.body}</p>
                      </div>
                    </div>
                  )}
                  <div className="replied-message-first-flex">
                    {message?.replyMessage?.body && (
                      <div className="replied-arrow-alignment">
                        <div className="message-icon-alignment">
                          <img src="/assets/icon/replied-right-arrow.svg" alt="repplied" />
                        </div>
                      </div>
                    )}
                    <div className="replied-message-child-flex-alignment">
                      {message?.body && (
                        <div className="left-side-user-details-flex-alignemnt">
                          <div
                            className={`left-message-box-alignment group-message ${isSameUser && user.id !== message.senderId && 'without-border'
                              }`}
                          >
                            {isGroupGlobalChat &&
                              !isSameUser &&
                              user.id !== message.senderId &&
                              !message?.replyMessage?.body && (
                                <h6>{message?.user?.firstName + ' ' + message?.user?.lastName}</h6>
                              )}
                            <p>{message?.body} </p>
                            {message?.reactions?.length > 0 && (
                              <div className="reacted-details">
                                <div className="reated-details-flex-alignment">
                                  {reactImageArray?.map((r, i) => {
                                    let filter = reactImageArray?.find(
                                      (re) => re?.id === message?.reactions[i]?.emoji_id,
                                    )

                                    return filter ? (
                                      <div className="reacted-icon-alignment">
                                        <img src={filter?.emoji} alt="React1" />
                                      </div>
                                    ) : (
                                      <></>
                                    )
                                  })}
                                  {/* <div className="reacted-icon-alignment">
                                    <img src={React2} alt="React1" />
                                  </div>
                                  <div className="reacted-icon-alignment">
                                    <img src={React3} alt="React1" />
                                  </div>
                                  <div className="reacted-icon-alignment">
                                    <img src={React4} alt="React2" />
                                  </div>
                                  <div className="reacted-icon-alignment">
                                    <img src={React5} alt="React5" />
                                  </div>
                                  <div className="reacted-icon-alignment">
                                    <img src={React6} alt="React5" />
                                  </div> */}
                                </div>
                              </div>
                            )}
                            {messageOption === message?.id && (
                              <div className="message-hover-details-alignment">
                                <div className="message-icon-alignment" onClick={() => handleReplyMessage(message)}>
                                  <img src="/assets/icon/message-left-arrow.svg" alt="left arrow" />
                                </div>
                                {/* <div className="message-icon-alignment">
                                  <img src="/assets/icon/message-right-arrow.svg" alt="right arrow" />
                                </div> */}
                                <div
                                  className="message-icon-alignment"
                                  onMouseEnter={(e) => {
                                    e.stopPropagation()
                                    setChatReaction(true)
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                  >
                                    <g clipPath="url(#clip0_104_1091)">
                                      <path
                                        d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                                        stroke="#636773"
                                        strokeWidth="1.25"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M6 10.5C6 10.5 7.125 12 9 12C10.875 12 12 10.5 12 10.5"
                                        stroke="#636773"
                                        strokeWidth="1.25"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M6.75 6.75H6.75667"
                                        stroke="#636773"
                                        strokeWidth="1.25"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M11.25 6.75H11.2567"
                                        stroke="#636773"
                                        strokeWidth="1.25"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_104_1091">
                                        <rect width="18" height="18" fill="white" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  {chatReaction && (
                                    <div className="reaction-box-alignment">
                                      <div className="child-reaction-box" onClick={() => sendReaction(message, 1)}>
                                        <img src={React1} alt="React1" />
                                      </div>
                                      <div className="child-reaction-box" onClick={() => sendReaction(message, 1)}>
                                        <img src={React2} alt="React1" />
                                      </div>
                                      <div className="child-reaction-box" onClick={() => sendReaction(message, 3)}>
                                        <img src={React3} alt="React1" />
                                      </div>
                                      <div className="child-reaction-box" onClick={() => sendReaction(message, 4)}>
                                        <img src={React4} alt="React1" />
                                      </div>
                                      <div className="child-reaction-box" onClick={() => sendReaction(message, 5)}>
                                        <img src={React5} alt="React1" />
                                      </div>
                                      <div className="child-reaction-box" onClick={() => sendReaction(message, 6)}>
                                        <img src={React6} alt="React1" />
                                      </div>
                                      <div className="child-reaction-box" onClick={() => sendReaction(message, 1)}>
                                        <img src={PlusIcon} alt="PlusIcon" />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="align-items-end flex">
                        {message?.images?.length > 0 && (
                          <div className="right-img-flex">
                            <div className="right-side-media-alignment">
                              <img src={message?.images[0]} alt="meadia-img" />
                            </div>
                          </div>
                        )}
                        <span>{formatTime(message.createdAt)}</span>

                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  )
}
