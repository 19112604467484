import React from 'react'
import SellerOnboardingMain from 'src/components/Seller/SellerOnboardingMain/SellerOnboardingMain'
import SellerBecomeProtectedRoute from 'src/hoc/SellerBecomeProtectedRoute'

const BecomeSeller = () => {
  return (
    <React.Fragment>
      <SellerOnboardingMain />
    </React.Fragment>
  )
}

export default BecomeSeller
// export default SellerBecomeProtectedRoute(BecomeSeller)
