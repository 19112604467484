import React from 'react'
import { Modal, Checkbox, Tabs, Row } from 'antd'
import getImageUrl from 'src/utils/getImageUrl'
const { TabPane } = Tabs
export default function PostSharewithFriendModal(props) {
  const { handleCancel, isModalVisible, user, handleOk } = props

  function onChange(e: { target: { checked: any } }) {}

  return (
    <Modal
      open={isModalVisible}
      className="group-feed-modal mt-8 p-4 bg-blue rounded-xl"
      onCancel={handleCancel}
      footer={null}
      closable={false}
      width={490}
    >
      <div className="bg-white rounded-xl -m-16">
        <div className="modal-header flex justify-between items-center px-8 py-4">
          <p className="text-sm text-black font-medium">Share with Friends</p>
          <button className="outline-none  focus:outline-none" onClick={handleCancel}>
            <img src="/assets/img/feed/ic_Close.svg" />
          </button>
        </div>
        <div className="px-12 pt-8">
          <Tabs defaultActiveKey="1" className="user-info-tabs">
            <TabPane tab="Friends" key="1" className="">
              <div className="rounded-2xl h-16 w-full border border-gray-300 flex justify-between items-center px-4">
                <input type={'text'} placeholder="Search" className="w-90"></input>
                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.58891 7.7845C4.63478 7.85112 4.69616 7.90559 4.76775 7.94322C4.83935 7.98085 4.91902 8.00052 4.99991 8.00052C5.08079 8.00052 5.16047 7.98085 5.23206 7.94322C5.30366 7.90559 5.36504 7.85112 5.41091 7.7845L9.91091 1.2845C9.963 1.20953 9.99354 1.12172 9.99923 1.0306C10.0049 0.93949 9.98552 0.848562 9.94315 0.767697C9.90079 0.686832 9.83707 0.619124 9.75893 0.571929C9.68078 0.524734 9.5912 0.499857 9.49991 0.500001H0.499907C0.408829 0.500377 0.319576 0.525574 0.241748 0.572882C0.163919 0.62019 0.100459 0.68782 0.0581917 0.768497C0.0159247 0.849175 -0.00355002 0.939848 0.00186196 1.03077C0.00727394 1.12168 0.0373678 1.20941 0.0889073 1.2845L4.58891 7.7845Z"
                    fill="#ACB1C0"
                  />
                </svg>
              </div>
              <div className="h-2" />
              {user?.friends?.map((friend, index) => (
                <Row key={index} className="gutter-row">
                  <div className="rounded-2xl w-full bg-indigo-50 flex justify-between items-center px-4 py-2 my-3">
                    <div className="flex justify-start">
                      <img
                        src={getImageUrl(friend?.user?.logo_image) || '/assets/img/dummy-profile.svg'}
                        className="h-16 rounded-full mr-4 object-cover"
                      />
                      <div>
                        <div className="text-12 font-medium mt-2">{`${friend.user.firstName} ${friend.user.lastName}`}</div>
                        <div className="text-9 font-medium text-green-500">Online</div>
                      </div>
                    </div>
                    <Checkbox onChange={onChange}></Checkbox>
                  </div>
                </Row>
              ))}
              <div className="h-6" />
              <div className=" -mx-6 px-6 py-4">
                <div className="grid grid-cols-4 gap-4">
                  <div className="col-span-3">
                    <input
                      type="text"
                      placeholder="Type your message here"
                      className="w-full text-12 bg-gray-200 px-4 py-3 rounded-2xl"
                    ></input>
                  </div>
                  <div>
                    <button
                      className="py-3 px-4 rounded-2xl text-12 text-white bg-indigo-500 w-full"
                      onClick={handleOk}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Email" key="2"></TabPane>
            <TabPane tab="Address" key="3" style={{ width: '100% !important' }}></TabPane>
          </Tabs>
        </div>
      </div>
    </Modal>
  )
}
