import { gql } from '@apollo/client'

const query = gql`
  mutation LEAVEGROUP($userId: Int!, $groupId: Int!) {
    leaveGroup(userId: $userId, groupId: $groupId) {
      id
    }
  }
`
export default query
