import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { useRouter } from 'next/router'

const FinishStep = () => {
  const router = useRouter()
  return (
    <div
      className="space-y-8 flex justify-center flex-col"
      style={{
        minHeight: '380px',
      }}
    >
      <div>
        <div className="text-center300">
          <div className="flex justify-center">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-green-100 dark:bg-green-900/30 mb-4">
              <CheckCircleIcon className="w-10 h-10 text-green-500" />
            </div>
          </div>
          <h3 className="text-2xl font-bold text-gray-900 dark:text-white text-center">Congratulations!</h3>
          <p className="mt-2 text-gray-600 dark:text-gray-300">
            Your seller account has been successfully set up. You're now ready to start selling on our platform.
          </p>
        </div>
      </div>

      {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white dark:bg-gray-700/50 rounded-xl p-6 border border-gray-200 dark:border-gray-600">
          <h4 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Quick Actions</h4>
          <div className="space-y-4">
            <Link
              href="/products/new"
              className="flex items-center justify-between w-full p-3 text-left bg-gray-50 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors duration-200"
            >
              <span className="font-medium text-gray-900 dark:text-white">Add Your First Product</span>
              <span className="text-indigo-600 dark:text-indigo-400">→</span>
            </Link>
            <Link
              href="/settings/store"
              className="flex items-center justify-between w-full p-3 text-left bg-gray-50 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors duration-200"
            >
              <span className="font-medium text-gray-900 dark:text-white">Customize Store</span>
              <span className="text-indigo-600 dark:text-indigo-400">→</span>
            </Link>
            <Link
              href="/settings/payment"
              className="flex items-center justify-between w-full p-3 text-left bg-gray-50 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors duration-200"
            >
              <span className="font-medium text-gray-900 dark:text-white">Set Up Payment Methods</span>
              <span className="text-indigo-600 dark:text-indigo-400">→</span>
            </Link>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-700/50 rounded-xl p-6 border border-gray-200 dark:border-gray-600">
          <h4 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Helpful Resources</h4>
          <div className="space-y-4">
            <a
              href="/docs/getting-started"
              className="flex items-center justify-between w-full p-3 text-left bg-gray-50 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors duration-200"
            >
              <span className="font-medium text-gray-900 dark:text-white">Getting Started Guide</span>
              <span className="text-indigo-600 dark:text-indigo-400">→</span>
            </a>
            <a
              href="/docs/best-practices"
              className="flex items-center justify-between w-full p-3 text-left bg-gray-50 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors duration-200"
            >
              <span className="font-medium text-gray-900 dark:text-white">Seller Best Practices</span>
              <span className="text-indigo-600 dark:text-indigo-400">→</span>
            </a>
            <a
              href="/docs/faq"
              className="flex items-center justify-between w-full p-3 text-left bg-gray-50 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors duration-200"
            >
              <span className="font-medium text-gray-900 dark:text-white">FAQ</span>
              <span className="text-indigo-600 dark:text-indigo-400">→</span>
            </a>
          </div>
        </div>
      </div> */}

      <div className="flex justify-center">
        <button
          onClick={() => router.push('/seller/dashboard/')}
          type="button"
          className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
        >
          Go to Dashboard
        </button>
      </div>
    </div>
  )
}

export default FinishStep
