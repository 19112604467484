import React from 'react'
import NormalPost from '../../Feed/NormalPost'
import PostSomething from 'src/components/Feed/PostSomething'
import PostSomethingMobile from 'src/components/Feed/PostSomethingMobile'
import { useSelector } from 'react-redux'
  

export default function GroupFeed(props) {
  const { group } = props
  const groupPosts = useSelector((state: any) => state?.feedData?.groupPosts)

  return (
    <div className="">
      {group?.isExist && (
        <React.Fragment>
          <PostSomething title={'Post Something'} mobile={false} type="group" group={group} isSeller={false} />
          <PostSomethingMobile title={'Write something...'} mobile={true} type="group" group={group} isSeller={false} />
        </React.Fragment>
      )}
      <div className="mt-4">
        {groupPosts?.length > 0 ? (
          groupPosts?.map((post, index) => {
            let postData = { ...post, groupId: group?.id }
            return (
              <div key={index}>
                <NormalPost
                  index={index}
                  post={postData}
                  store={{}}
                  image={true}
                  type="group"
                  color={false}
                  feedSize={true}
                  isUserPostPreviewModal={false}
                />
              </div>
            )
          })
        ) : (
          <>
            <div className="feed-no-data-box-alignment">
              <div>
                <img src="/assets/img/no-feed-data.svg" alt="NoDataFeedImg" />
              </div>
              <div className="mobile-view-no-data-text-alignment">
                <span>This Club doesn’t have any post yet.</span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
