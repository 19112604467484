import { gql } from '@apollo/client'

const ADD_PRODUCT_TO_STREAM = gql`
mutation AddProductToStream($streamId: String, $productId: Int) {
  addProductToStream(stream_id: $streamId, product_id: $productId) {
    message
    success
  }
}
`
export default ADD_PRODUCT_TO_STREAM
