import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import { fetchAllPeople } from 'src/store/slices/fetchAllPeopleSlice'
import InspirationalBanner from '../../../../../public/assets/img/BookApplience/inspirationalBanner.png'
import MobileInspirationalBanner from '../../../../../public/assets/img/BookApplience/mobile-inspirationalBanner.png'
import DemoImg from '../../../../../public/assets/img/MensCategory/m1.png'
import { useMutation } from '@apollo/client'
import SEND_FRIEND_REQUEST from 'src/graphql/mutations/sendFriendRequest'
import DECLINE_FRIEND_REQUEST from 'src/graphql/mutations/declineFriendRequest'
import GET_USERS from 'src/graphql/queries/getUsers'
import { notification } from 'antd'
import { useAuth } from 'src/utils/auth'

interface ModalData {
  modal: string;
  [key: string]: any;
}

export default function InspirationalInfluencerModal() {
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const peopleData = useSelector((state: any) => state?.peopleData)
  const { user } = useAuth()

  const [sendFriendRequest] = useMutation(SEND_FRIEND_REQUEST, {
    onCompleted: () => {
      notification['success']({
        message: 'Friend request sent successfully',
      })
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const [declineFriendRequest] = useMutation(DECLINE_FRIEND_REQUEST, {
    onCompleted: () => {
      notification['success']({
        message: 'Remove Request successfully',
      })
    },
    onError: (error) => {
      console.log(error)
    },
  })

  useEffect(() => {
    dispatch(fetchAllPeople({ limit: 15, page: 1, type: 'ALL_PEOPLE', prevPost: [], gender: 'Male' }))
  }, [])

  const handleClose = () => {
    let datass = getAllState?.allModalData?.filter((item: ModalData) => item?.modal !== getAllState?.lastModalData?.modal)
    dispatch(
      fetchModalData({
        allModalData: datass,
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  return (
    <div className="inspiration-influencer-modal-details-alignment">
      <div className="mobile-view-book-appliance-back-alignment">
        <div className="book-appliance-back_details-alignment" onClick={() => handleClose()}>
          <div>
            <img src="/assets/icon/left-icon.svg" alt="back-page" />
          </div>
          <p>Home...</p>
        </div>
        <div className="mobile-heading-alignment">
          <h4>Influencer</h4>
        </div>
      </div>
      <div className="inspiration-influencer-modal-banner-alignment">
        {/* <img src={InspirationalBanner.src} alt="InspirationalBanner" />
        <img src={MobileInspirationalBanner.src} alt="MobileInspirationalBanner" className="mobile-view-banner" /> */}

        <div className="inspiration-influencer-modal-banner-details">
          <h4>Beauty Fans</h4>
        </div>
      </div>

      <div className="inspiration-influencer-details-alignment">
        <div className="inspiration-influencer-grid-alignment">
          {peopleData?.allPeople?.length > 0 ? (
            peopleData.allPeople.map((person: any, index: number) => (
              <div className="inspiration-influencer-gridItem-alignment" key={index}>
                <div className="inspirational-influencers-enthusiats-details">
                  <div className="image-center-alignment">
                    <img src={person.logo_image || DemoImg.src} alt="FollowrsImage" />
                  </div>
                  <h5 className="line-clamp">{person.firstName + ' ' + person.lastName}</h5>
                  <p>{person.jobTitle || 'Model'}</p>
                  <span>{person.followers_count + ' Followers' || '0 Followers'}</span>
                  <div>
                    {person?.isFollow ? (
                      <div className="button-center-alignment">
                        <button
                          className="button-follow"
                          onClick={() =>
                            declineFriendRequest({
                              variables: { user_id: user?.id, friend_id: person?.id },
                              refetchQueries: [
                                {
                                  query: GET_USERS,
                                  variables: {
                                    id: user.id,
                                  },
                                },
                              ],
                            })
                          }
                        >
                          Following
                        </button>
                      </div>
                    ) : (
                      <div className="button-center-alignment">
                        <button
                          className="button-follow"
                          onClick={() =>
                            sendFriendRequest({
                              variables: {
                                friend_id: person?.id,
                                user_id: user?.id,
                                isActive: true,
                                isFriend: true,
                              },
                              refetchQueries: [
                                {
                                  query: GET_USERS,
                                  variables: {
                                    id: user.id,
                                  },
                                },
                              ],
                            })
                          }
                        >
                          Follow
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-data-message">
              <p>No influencers found</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
