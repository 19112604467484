import { gql } from '@apollo/client'

export default gql`
  query GetBrandByProductId($store_id: Int!) {
    getBrandByProductId(store_id: $store_id) {
      success
      message
      data {
        name
        id
      }
    }
  }
`
