import React from 'react'
import NotitcationIcon from '../../../../public/assets/icon/notification-icon-white.svg'
import LeftArrowIcon from '../../../../public/assets/icon/left-icon-white.svg'
import Moreicon from '../../../../public/assets/icon/white-more-icon.svg'
import { useAppDispatch } from 'src/store/store'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useSelector } from 'react-redux'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAuth } from 'src/utils/auth'
import { GET_GROUPS } from 'src/graphql/queries/queries'
import GET_JOINED_GROUP from 'src/graphql/queries/sellerQueries/getJoinedGroups'
import { useMutation } from '@apollo/client'
import JOIN_GROUP from 'src/graphql/mutations/joinGroup'
import LEAVE_GROUP from 'src/graphql/mutations/leaveGroup'
import LazyImage from 'src/utils/LazyImage'

export default function GroupMainSection(props) {
  const { group, refetch } = props
  const { user }: any = useAuth()
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)

  const handleClose = () => {
    let datass = getAllState?.allModalData?.filter((item) => item?.modal !== getAllState?.lastModalData?.modal)
    dispatch(
      fetchModalData({
        allModalData: datass,
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  const handleRedirect = () => {
    !user &&
      dispatch(
        guestAccountDetailSlice({
          modal: 'authModal',
          isOpen: true,
          id: {
            media:
              'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
            modalFor: 'login',
            startWith: 'loginModal',
          },
        }),
      )
  }

  const [joingroup] = useMutation(JOIN_GROUP, {
    onCompleted: (res) => {
      refetch()
    },
    onError: (err) => {},
  })

  const joingroupCall = (club) => {
    if (user) {
      joingroup({
        variables: { group_id: club?.id, user_id: user?.id, isAdmin: false },
        update: (proxy, { data }) => {
          const suggestedGroup: any = proxy.readQuery({
            query: GET_JOINED_GROUP,
          })
          proxy.writeQuery({
            query: GET_JOINED_GROUP,
            data: {
              getJoinedGroups: [
                ...suggestedGroup?.getJoinedGroups,
                {
                  ...club,
                  isFollow: true,
                  members: [
                    {
                      firstName: user?.firstName,
                      lastName: user?.lastName,
                      id: user?.id,
                      profileUrl: user?.logo_image,
                    },
                    ...club?.members,
                  ],
                },
              ],
            },
          })
          const joinedGroup: any = proxy.readQuery({
            query: GET_GROUPS,
          })
          let updatedData = joinedGroup?.groups?.filter((grp) => grp?.id !== club?.id)
          proxy.writeQuery({
            query: GET_GROUPS,
            data: { groups: updatedData },
          })
        },
      })
      refetch()
    } else {
      handleRedirect()
    }
  }

  const [leaveGroup] = useMutation(LEAVE_GROUP, {
    onCompleted: (res) => {
      refetch()
    },
    onError: (err) => {},
  })

  const leaveGroupCall = (club) => {
    if (user) {
      leaveGroup({
        variables: {
          userId: user.id,
          groupId: club?.id,
        },
      })
    } else {
      handleRedirect()
    }
  }

  const handleJoinClub = () => {
    if (group?.isExist) {
      leaveGroupCall(group)
    } else if (!group?.isExist) {
      joingroupCall(group)
    } else {
      handleRedirect()
    }
  }
  const handelImageSlider = () => {}

  return (
    <div className="men-fashion-banner-alignment">
      <LazyImage
        image={{
          src: group?.bannerImage?.url,
          alt: 'Icon',
        }}
        height={316}
        imageClassName={'group-banner-radious'}
        className={'group-banner-radious'}
        objectFit={'cover'}
        handleClickOnImage={() => handelImageSlider()}
      />
      {/* <div className="men-group-profile-img-alignment">
        <LazyImage
          image={{
            src: group?.coverImage?.url,
            alt: 'Icon',
          }}
          imageClassName={'group-post-media-radious'}
          objectFit={'cover'}
          handleClickOnImage={() => handelImageSlider()}
          className={'group-profile-skeleton'}
        />
      </div> */}
      {/* <div className="men-group-option-alignment">
        <div className="men-groupAdmin-box-alignment" onClick={handleRedirect}>
          <p>Club Admins</p>
          <div className="img-alignment">
            <img src={group?.admins && group?.admins[0]?.profileUrl} alt="MenPfImg" />
          </div>
        </div>
        <div className="notigication-leave-alignment">
          <div className="notification-alignment" onClick={handleRedirect}>
            <img src={NotitcationIcon.src} alt="NotitcationIcon" />
          </div>

          <div className="leave-group-alignment" onClick={handleJoinClub}>
            <p>{group?.isExist ? 'Leave' : 'Join'} Club</p>
          </div>
        </div>
      </div> */}
      <div className="mobile-view-banner-option-alignment">
        <div className="mobile-view-left-box-alignment">
          <img src={LeftArrowIcon.src} alt="LeftArrowIcon" onClick={() => handleClose()} />
        </div>
        <div
          className="mobile-view-left-box-alignment"
          onClick={() => {
            dispatch(
              fetchMobileModalData({
                allModalData: [
                  ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                  { modal: 'groupControlModal', isOpen: true, id: group, transform: 35 },
                ],
                lastModalData: fetchMobileModal?.lastModalData,
              }),
            )
          }}
        >
          <img src={Moreicon.src} alt="Moreicon" />
        </div>
      </div>
    </div>
  )
}
