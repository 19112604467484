import { gql } from '@apollo/client'

export default gql`
  query GET_ALL_BILLING_ADDRESS {
    getAllBillingAddress {
      id
      firstName
      lastName
      streetAddress
      country
      country_name
      state
      state_name
      city
      zipcode
      buildingName
      number
      isDefault
      isPinLocation
      latitude
      longitude
    }
  }
`
