import { gql } from '@apollo/client'

const FOLLOW_UNFOLLOW_COLLECTION = gql`
  mutation FollowUnfollowcollection($collectionId: Int) {
    followUnfollowcollection(collection_id: $collectionId) {
      message
      success
    }
  }
`
export default FOLLOW_UNFOLLOW_COLLECTION
