export default function MessageIcon({ small }: { small?: boolean }) {
    return (
        small ?
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M14.6665 7.62955C14.6691 8.60723 14.4407 9.57168 13.9999 10.4444C13.4772 11.4901 12.6738 12.3697 11.6795 12.9846C10.6852 13.5994 9.53933 13.9254 8.37027 13.9258C7.39259 13.9284 6.42814 13.7 5.55546 13.2592L3.58198 13.917C2.65545 14.2258 1.77398 13.3444 2.08283 12.4178L2.74066 10.4444C2.29986 9.57168 2.07144 8.60723 2.07399 7.62955C2.07444 6.46049 2.40037 5.31463 3.01526 4.32034C3.63015 3.32604 4.50973 2.52258 5.55546 1.99994C6.42814 1.55915 7.39259 1.33072 8.37027 1.33327H8.74064C10.2846 1.41845 11.7429 2.07013 12.8363 3.16353C13.9297 4.25694 14.5814 5.71522 14.6665 7.25918V7.62955Z" stroke="#7A7E88" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.33325 5.33325H11.7333" stroke="#7A7E88" stroke-linecap="round" />
                <path d="M5.33325 7.46655H11.7333" stroke="#7A7E88" stroke-linecap="round" />
                <path d="M5.33325 9.6001H9.59992" stroke="#7A7E88" stroke-linecap="round" />
            </svg>
            :
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                <path d="M13.75 7.15278C13.7524 8.06935 13.5382 8.97353 13.125 9.79166C12.635 10.772 11.8817 11.5966 10.9496 12.1731C10.0174 12.7496 8.9432 13.0551 7.8472 13.0555C6.93063 13.0579 6.02645 12.8438 5.20832 12.4305L3.35818 13.0473C2.48956 13.3368 1.66319 12.5104 1.95273 11.6418L2.56944 9.79166C2.1562 8.97353 1.94205 8.06935 1.94444 7.15278C1.94487 6.05678 2.25042 4.98254 2.82688 4.05039C3.40335 3.11824 4.22795 2.36499 5.20832 1.87502C6.02645 1.46178 6.93063 1.24763 7.8472 1.25002H8.19442C9.64188 1.32987 11.009 1.94082 12.0341 2.96589C13.0592 3.99095 13.6701 5.3581 13.75 6.80556V7.15278Z" stroke="#7A7E88" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5 5H11" stroke="#7A7E88" stroke-linecap="round" />
                <path d="M5 7H11" stroke="#7A7E88" stroke-linecap="round" />
                <path d="M5 9H9" stroke="#7A7E88" stroke-linecap="round" />
            </svg>
    )
}