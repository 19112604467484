import { gql } from '@apollo/client'

const query = gql`
  mutation UPDATEPOST($id: Int!, $content: String) {
    updatePost(input: { id: $id, content: $content }) {
      id
      content
    }
  }
`

export default query
