import { gql } from '@apollo/client'

const GET_SEARCH_USERS_WITH_ELASTIC_SEARCH = gql`
  query searchUsersWithElasticSearch($search: String, $limit: Int, $page: Int) {
    searchUsersWithElasticSearch(search: $search, limit: $limit, page: $page) {
      success
      message
      data {
        users {
          id
          firstName
          lastName
          logo_image
          banner_image
          jobTitle
          isFollow
          followers_count
          userName
        }
      }
    }
  }
`
export default GET_SEARCH_USERS_WITH_ELASTIC_SEARCH
// $limit: Int, $page: Int
//  limit: $limit, page: $page
