import { gql } from '@apollo/client'

const ADD_STORE_ACTIVITY = gql`
  mutation AddStoreActivity($input: storeActivityInput!) {
    addStoreActivity(input: $input) {
      success
      message
      data {
        id
      }
    }
  }
`
export default ADD_STORE_ACTIVITY

// mutation AddStoreActivity($store_id: Int!, $startDate: String, $endDate: String) {
//   addStoreActivity(store_id: $store_id, startDate: $startDate, endDate: $endDate) {
//     success
//     message
//     data {
//       id
//     }
//   }
// }
