// @ts-nocheck


import React, { useEffect, useState } from 'react'
import { useAuth } from 'src/utils/auth'
import getImageUrl from 'src/utils/getImageUrl'
import { Picker } from 'emoji-mart'
import Slider from 'react-slick'
import RightArrow from '../../../../public/assets/icon/share-right-arrow.svg'
import LeftArrow from '../../../../public/assets/icon/share-left-arrow.svg'
import { useSelector } from 'react-redux'
import { fetchProductShareOptions } from 'src/store/slices/productShareOptionsSlice'
import { useAppDispatch } from 'src/store/store'
import SHARE_POST_MUTATION from 'src/graphql/mutations/createSharePost'
// import MY_FEED_QUERY from 'src/graphql/queries/getMyFeed'
import { useMutation } from '@apollo/client'
import { Spin, notification } from 'antd'
import FramerAnimationModal from 'src/utils/FramerAnimationModal'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'
import { fetchAllFeedSliceData } from 'src/store/slices/fetchAllFeedSlice'

function SampleNextArrow(props) {
  const { className, style, onClick } = props

  return (
    <div className={className} onClick={onClick}>
      <img src={RightArrow.src} alt="RightArrow" />
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <img src={LeftArrow.src} alt="LeftArrow" />
    </div>
  )
}

export default function ShareOnMyFeedModal(props) {
  const { user } = useAuth()
  const dispatch = useAppDispatch()
  const fetchProductShareOptionsData = useSelector((state: any) => state?.productShareOptions?.data)
  const fetchProductImageResize = useSelector((state: any) => state?.productResizeData?.data)
  const [postContent, setPostContent] = useState('')
  const [showEmojis, setShowEmojis] = useState(false)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)

  const handleCancel = () => {
    if (fetchProductShareOptionsData?.id?.isGroupPost) {
      dispatch(fetchProductShareOptions({ ...fetchProductShareOptionsData, modal: 'productSharewithClub' }))
    } else {
      dispatch(fetchProductShareOptions({ modal: '', isOpen: false, id: {} }))
    }
  }

  useEffect(() => {}, [])

  const addEmoji = (e) => {
    let sym = e.unified.split('-')
    let codesArray = []
    sym.forEach((el) => codesArray.push('0x' + el))
    let emoji = String.fromCodePoint(...codesArray)
    setPostContent(postContent + emoji)
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.04,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  let productMedia = fetchProductShareOptionsData?.id?.selectedProduct?.images

  const [createSharePost] = useMutation(SHARE_POST_MUTATION, {
    onCompleted: (res) => {
      setLoading(false)
      dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const postHandler = (data) => {
    // let selectedFam = followers?.filter((fam) => fam.isChecked)
    if (fetchProductShareOptionsData?.id?.isGroupPost) {
      setLoading(true)
      createSharePost({
        variables: {
          content: data,
          store_name: fetchProductShareOptionsData?.id?.selectedProduct?.storeData?.name,
          share_post_for: 'GROUP',
          product_id: fetchProductShareOptionsData?.id?.selectedProduct?.id,
          group_id: fetchProductShareOptionsData?.id?.groupData?.id
            ? [fetchProductShareOptionsData?.id?.groupData?.id]
            : [],
        },
        // refetchQueries: [{ query: MY_FEED_QUERY, variables: { page: 1, limit: 10 } }],
      }).then((response) => {
        if (response?.data !== undefined) {
          dispatch(fetchProductShareOptions({ modal: '', isOpen: false, id: {} }))
        }
      })
    } else if (!fetchProductShareOptionsData?.id?.isGroupPost) {
      setLoading(true)
      createSharePost({
        variables: {
          content: data,
          product_id: fetchProductShareOptionsData?.id?.selectedProduct?.id,
          store_name: fetchProductShareOptionsData?.id?.selectedProduct?.storeData?.name,
          share_post_for: 'POST',
        },
        // refetchQueries: [{ query: MY_FEED_QUERY, variables: { page: 1, limit: 10 } }],
      }).then((response) => {
        if (response?.data !== undefined) {
          dispatch(fetchProductShareOptions({ modal: '', isOpen: false, id: {} }))
          // dispatch(
          //   fetchProductImageResizeModal({
          //     modal: 'mobileProductDetailsShareModal',
          //     isOpen: false,
          //     id:{
          //       ...fetchProductImageResize?.id,
          //       singleBookmarkProduct :{
          //         isProduct: false,
          //         id: null
          //       }
          //     }
          //   }),
          // )
        }
      })
    }
  }

  return (
    <div className="dropdown-modal-details">
      <div className="dropdown-modal-wrapper">
        <FramerAnimationModal>
          <div className="dropdown-modal-box-alignment">
            <div className="bg-white rounded-xl -m-16">
              <div className="modal-header flex justify-between items-center px-8 py-4">
                <p className="text-black font-medium text-sm">
                  {fetchProductShareOptionsData?.id?.isGroupPost
                    ? `Share with ${fetchProductShareOptionsData?.id?.groupData?.name}`
                    : 'Share on My Feed'}
                </p>
                <button onClick={handleCancel}>
                  <img src="/assets/img/feed/ic_Close.svg" />
                </button>
              </div>
              <div className="flex p-3 px-4 border-b border-gray-200">
                <img
                  src={user?.logo_image ? getImageUrl(user?.logo_image) : '/assets/img/dummy-profile.svg'}
                  className="object-cover create-post-alignment"
                />
                <div className="w-full">
                  <textarea
                    className="border-0 w-full text-sm font-normal h-16 px-8 pt-3"
                    placeholder="Write something..."
                    value={postContent}
                    onChange={(e) => setPostContent(e.target.value)}
                    style={{ resize: 'none', minHeight: '40px' }}
                  />
                </div>
              </div>
              <div className="share-post-design-section">
                {productMedia?.length > 2 ? (
                  <Slider {...settings}>
                    {productMedia &&
                      productMedia?.length > 0 &&
                      productMedia?.map((media, i) => {
                        return (
                          <div className="share-product-img-alignment" key={i}>
                            {media?.src?.includes('mp4') || media?.src?.includes('mov') ? (
                              <video muted autoPlay loop style={{ objectFit: 'cover' }}>
                                <source src={media?.src} />
                              </video>
                            ) : (
                              <img src={media?.src} alt="DemoProductImg" />
                            )}
                          </div>
                        )
                      })}
                  </Slider>
                ) : (
                  <div className="share-post-design-grid-section">
                    {productMedia &&
                      productMedia?.length > 0 &&
                      productMedia?.map((media, i) => {
                        return (
                          <div className="share-product-img-alignment" key={i}>
                            {/* {media?.src ? (
                              media?.src?.includes('mp4') ? (
                                <video muted autoPlay loop style={{ objectFit: 'cover' }}>
                                  <source src={media?.src} />
                                </video>
                              ) : (
                                <img src={media?.src} alt="DemoProductImg" />
                              )
                            ) : media?.includes('mp4') ? (
                              <video muted autoPlay loop style={{ objectFit: 'cover' }}>
                                <source src={media} />
                              </video>
                            ) : (
                              <img src={media} alt="DemoProductImg" />
                            )} */}
                            {media?.src?.includes('mp4') || media?.src?.includes('mov') ? (
                              <video muted autoPlay loop style={{ objectFit: 'cover' }}>
                                <source src={media?.src} />
                              </video>
                            ) : (
                              <img src={media?.src} alt="DemoProductImg" />
                            )}
                          </div>
                        )
                      })}
                  </div>
                )}
                <div className="share-post-details-alignment">
                  <div>
                    <div className="share-post-heading">
                      <h5>{fetchProductShareOptionsData?.id?.selectedProduct?.title}</h5>
                    </div>
                    <div className="share-post-price-alignment">
                      <h6>
                        ${' '}
                        {fetchProductShareOptionsData?.id?.selectedProduct?.dis_price
                          ? fetchProductShareOptionsData?.id?.selectedProduct?.dis_price
                          : fetchProductShareOptionsData?.id?.selectedProduct?.dis_listPrice || 0}
                      </h6>
                      {fetchProductShareOptionsData?.id?.selectedProduct?.dis_listPrice &&
                        fetchProductShareOptionsData?.id?.selectedProduct?.dis_price && (
                          <span>
                            <del>$ {fetchProductShareOptionsData?.id?.selectedProduct?.dis_listPrice || 0}</del>
                          </span>
                        )}
                    </div>
                    {/* <div className="share-post-delivery-shipping">
                      <div className="best-seller-alignment">
                        <h6>Best seller</h6>
                      </div>
                      <div className="dot-alignment">
                        <p></p>
                      </div>
                      <div className="delivery-menu-align">
                        <img src={'/assets/img/feed/shared-icon/truck-icon.svg'} />
                        <span>free</span>
                      </div>
                      <div className="dot-alignment">
                        <p></p>
                      </div>
                      <div className="arrow-menu-align">
                        <img src={'/assets/img/feed/shared-icon/side-icon-up-green.svg'} />
                        <span>200%</span>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="flex border-t p-8">
                <div className="post-footer-left flex justify-between w-full">
                  <div className="flex items-center">
                    <div className="mr-8" onClick={() => setShowEmojis(!showEmojis)}>
                      <img src="/assets/img/feed/ic_Emoticon.svg" />
                    </div>
                    <div className="mr-8">
                      <img src="/assets/img/feed/ic_GIF.svg" />
                    </div>
                  </div>
                </div>
                {loading ? (
                  <Spin />
                ) : (
                  <div className="bg-blue rounded-xl flex items-center px-4 " style={{ height: 30 }}>
                    <button className="text-12 text-white font-medium w-32" onClick={() => postHandler(postContent)}>
                      Share
                    </button>
                  </div>
                )}
                {/* <div className="bg-blue rounded-xl flex items-center px-4 " style={{ height: 30 }}>
                  <button className="text-12 text-white font-medium w-32" onClick={() => postHandler(postContent)}>
                    Share
                  </button>
                </div> */}
              </div>
              {showEmojis && (
                <div className="p-8">
                  <Picker onSelect={addEmoji} set="apple" />
                </div>
              )}
            </div>
          </div>
        </FramerAnimationModal>
      </div>
    </div>
  )
}
