import { gql } from '@apollo/client'

export default gql`
  query GetAllProductByCategory(
    $categoryId: Int
    $subCategoryId: Int
    $childSubCategoryId: [[Int]]
    $limit: Int
    $page: Int
  ) {
    getAllProductByCategory(
      category_id: $categoryId
      subCategory_id: $subCategoryId
      childSubCategory_id: $childSubCategoryId
      limit: $limit
      page: $page
    ) {
      data {
        products {
          product_data {
            id
            title
            dis_price
            slug
            images {
              src
            }
          }
          childSubCategory {
            id
            name
            media
            banner_media
          }
        }
        total_count
      }
      success
      message
    }
  }
`
