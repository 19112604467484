import { gql } from '@apollo/client'

export default gql`
mutation SharePost($input: sharePostInput!) {
  sharePost(input: $input) {
    message
    success
    status
    data {
      id
    }
  }
}
`
