import React from 'react'
import TrendingStoreBanner from '../../../../../public/assets/img/artCarft/trending-store-bg.png'
import CanSolLogo from '../../../../../public/assets/img/artCarft/cansol-logo.png'
import FabLogo from '../../../../../public/assets/img/artCarft/fablogo.png'
import PoscaLogo from '../../../../../public/assets/img/artCarft/poscslogo.png'
import WinsonLogo from '../../../../../public/assets/img/artCarft/winsorlogo.png'
import SakubaLogo from '../../../../../public/assets/img/artCarft/sakubalogo.png'
import aroww from '../../../../../public/assets/img/WomansCategory/aroww.png'
export default function TrendingStoreArtCarft() {
  return (
    <div className="art-craft-trending-store-details-section">
      <div className="mobile-view-art-craft-back-alignment">
        <div className="art-craft-back_details-alignment">
          <div>
            <img src="/assets/icon/left-icon.svg" alt="back-page" />
          </div>
          <p>Art an...</p>
        </div>
        <div className="mobile-heading-alignment">
          <h4>Trending Stores</h4>
        </div>
      </div>
      <div className="art-craft-trending-store-banner-alignment">
        <img src={TrendingStoreBanner.src} alt="NowTrendingBanner" />
        <img
          src="/assets/img/artCarft/mobileviewTrendingStoreBanner.png"
          alt="MobileNowTrendingBanner"
          className="mobile-view-banner"
        />

        <div className="art-craft-trending-store-banner-details">
          <h4>Trending Stores</h4>
          <p>Bring your artistic vision to life with our high-quality supplies from the trendiest store around.</p>
        </div>
      </div>

      <div className="art-craft-trending-store-details-alignment">
        <div className="art-craft-trending-store-grid-alignment">
          <div className="art-craft-trending-store-gridItem-alignment">
            <div className="art-craft-details-box-alignment">
              <div className="art-craft-details-logo-alignment">
                <img src={CanSolLogo.src} alt="CanSolLogo" />
              </div>
              <div className="art-craft-child-details-alignment">
                <h6>CANSON</h6>
                <p>Art and Craft</p>
                <div>
                  <div className="art-craft-product">
                    <span>2.1k Followers </span>
                    <p></p>
                    <span> 543 Product</span>
                  </div>
                  <div className="art-craft-visitor">
                    <span>Today Visitor : 1.248</span>
                    <div className="art-craft-pre">
                      <img src={aroww.src} alt="pre" /> <span>75%</span>
                    </div>
                  </div>
                </div>

                <div className="follow-button-alignment">
                  <button>Follow Store</button>
                </div>
              </div>
            </div>
          </div>
          <div className="art-craft-trending-store-gridItem-alignment">
            <div className="art-craft-details-box-alignment">
              <div className="art-craft-details-logo-alignment">
                <img src={FabLogo.src} alt="FabLogo" />
              </div>
              <div className="art-craft-child-details-alignment">
                <h6>FABRIANO</h6>
                <p>Art and Craft</p>
                <div>
                  <div className="art-craft-product">
                    <span>2.1k Followers </span>
                    <p></p>
                    <span> 543 Product</span>
                  </div>
                  <div className="art-craft-visitor">
                    <span>Today Visitor : 1.248</span>
                    <div className="art-craft-pre">
                      <img src={aroww.src} alt="pre" /> <span>75%</span>
                    </div>
                  </div>
                </div>

                <div className="follow-button-alignment">
                  <button>Follow Store</button>
                </div>
              </div>
            </div>
          </div>
          <div className="art-craft-trending-store-gridItem-alignment">
            <div className="art-craft-details-box-alignment">
              <div className="art-craft-details-logo-alignment">
                <img src={PoscaLogo.src} alt="PoscaLogo" />
              </div>
              <div className="art-craft-child-details-alignment">
                <h6>POSCA</h6>
                <p>Art and Craft</p>
                <div>
                  <div className="art-craft-product">
                    <span>2.1k Followers </span>
                    <p></p>
                    <span> 543 Product</span>
                  </div>
                  <div className="art-craft-visitor">
                    <span>Today Visitor : 1.248</span>
                    <div className="art-craft-pre">
                      <img src={aroww.src} alt="pre" /> <span>75%</span>
                    </div>
                  </div>
                </div>

                <div className="follow-button-alignment">
                  <button>Follow Store</button>
                </div>
              </div>
            </div>
          </div>
          <div className="art-craft-trending-store-gridItem-alignment">
            <div className="art-craft-details-box-alignment">
              <div className="art-craft-details-logo-alignment">
                <img src={WinsonLogo.src} alt="WinsonLogo" />
              </div>
              <div className="art-craft-child-details-alignment">
                <h6>WINSOR & NEWTON</h6>
                <p>Art and Craft</p>
                <div>
                  <div className="art-craft-product">
                    <span>2.1k Followers </span>
                    <p></p>
                    <span> 543 Product</span>
                  </div>
                  <div className="art-craft-visitor">
                    <span>Today Visitor : 1.248</span>
                    <div className="art-craft-pre">
                      <img src={aroww.src} alt="pre" /> <span>75%</span>
                    </div>
                  </div>
                </div>

                <div className="follow-button-alignment">
                  <button>Follow Store</button>
                </div>
              </div>
            </div>
          </div>
          <div className="art-craft-trending-store-gridItem-alignment">
            <div className="art-craft-details-box-alignment">
              <div className="art-craft-details-logo-alignment">
                <img src={SakubaLogo.src} alt="SakubaLogo" />
              </div>
              <div className="art-craft-child-details-alignment">
                <h6>SAKURA</h6>
                <p>Art and Craft</p>
                <div>
                  <div className="art-craft-product">
                    <span>2.1k Followers </span>
                    <p></p>
                    <span> 543 Product</span>
                  </div>
                  <div className="art-craft-visitor">
                    <span>Today Visitor : 1.248</span>
                    <div className="art-craft-pre">
                      <img src={aroww.src} alt="pre" /> <span>75%</span>
                    </div>
                  </div>
                </div>

                <div className="follow-button-alignment">
                  <button>Follow Store</button>
                </div>
              </div>
            </div>
          </div>
          <div className="art-craft-trending-store-gridItem-alignment">
            <div className="art-craft-details-box-alignment">
              <div className="art-craft-details-logo-alignment">
                <img src={SakubaLogo.src} alt="SakubaLogo" />
              </div>
              <div className="art-craft-child-details-alignment">
                <h6>SAKURA</h6>
                <p>Art and Craft</p>
                <div>
                  <div className="art-craft-product">
                    <span>2.1k Followers </span>
                    <p></p>
                    <span> 543 Product</span>
                  </div>
                  <div className="art-craft-visitor">
                    <span>Today Visitor : 1.248</span>
                    <div className="art-craft-pre">
                      <img src={aroww.src} alt="pre" /> <span>75%</span>
                    </div>
                  </div>
                </div>

                <div className="follow-button-alignment">
                  <button>Follow Store</button>
                </div>
              </div>
            </div>
          </div>
          <div className="art-craft-trending-store-gridItem-alignment">
            <div className="art-craft-details-box-alignment">
              <div className="art-craft-details-logo-alignment">
                <img src={WinsonLogo.src} alt="WinsonLogo" />
              </div>
              <div className="art-craft-child-details-alignment">
                <h6>WINSOR & NEWTON</h6>
                <p>Art and Craft</p>
                <div>
                  <div className="art-craft-product">
                    <span>2.1k Followers </span>
                    <p></p>
                    <span> 543 Product</span>
                  </div>
                  <div className="art-craft-visitor">
                    <span>Today Visitor : 1.248</span>
                    <div className="art-craft-pre">
                      <img src={aroww.src} alt="pre" /> <span>75%</span>
                    </div>
                  </div>
                </div>

                <div className="follow-button-alignment">
                  <button>Follow Store</button>
                </div>
              </div>
            </div>
          </div>
          <div className="art-craft-trending-store-gridItem-alignment">
            <div className="art-craft-details-box-alignment">
              <div className="art-craft-details-logo-alignment">
                <img src={PoscaLogo.src} alt="PoscaLogo" />
              </div>
              <div className="art-craft-child-details-alignment">
                <h6>POSCA</h6>
                <p>Art and Craft</p>
                <div>
                  <div className="art-craft-product">
                    <span>2.1k Followers </span>
                    <p></p>
                    <span> 543 Product</span>
                  </div>
                  <div className="art-craft-visitor">
                    <span>Today Visitor : 1.248</span>
                    <div className="art-craft-pre">
                      <img src={aroww.src} alt="pre" /> <span>75%</span>
                    </div>
                  </div>
                </div>

                <div className="follow-button-alignment">
                  <button>Follow Store</button>
                </div>
              </div>
            </div>
          </div>
          <div className="art-craft-trending-store-gridItem-alignment">
            <div className="art-craft-details-box-alignment">
              <div className="art-craft-details-logo-alignment">
                <img src={FabLogo.src} alt="FabLogo" />
              </div>
              <div className="art-craft-child-details-alignment">
                <h6>FABRIANO</h6>
                <p>Art and Craft</p>
                <div>
                  <div className="art-craft-product">
                    <span>2.1k Followers </span>
                    <p></p>
                    <span> 543 Product</span>
                  </div>
                  <div className="art-craft-visitor">
                    <span>Today Visitor : 1.248</span>
                    <div className="art-craft-pre">
                      <img src={aroww.src} alt="pre" /> <span>75%</span>
                    </div>
                  </div>
                </div>

                <div className="follow-button-alignment">
                  <button>Follow Store</button>
                </div>
              </div>
            </div>
          </div>
          <div className="art-craft-trending-store-gridItem-alignment">
            <div className="art-craft-details-box-alignment">
              <div className="art-craft-details-logo-alignment">
                <img src={CanSolLogo.src} alt="CanSolLogo" />
              </div>
              <div className="art-craft-child-details-alignment">
                <h6>CANSON</h6>
                <p>Art and Craft</p>
                <div>
                  <div className="art-craft-product">
                    <span>2.1k Followers </span>
                    <p></p>
                    <span> 543 Product</span>
                  </div>
                  <div className="art-craft-visitor">
                    <span>Today Visitor : 1.248</span>
                    <div className="art-craft-pre">
                      <img src={aroww.src} alt="pre" /> <span>75%</span>
                    </div>
                  </div>
                </div>

                <div className="follow-button-alignment">
                  <button>Follow Store</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
