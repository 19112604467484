import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface pageScrollEventSlice {
  loading: true | false
  error: {} | null
  called: true | false
  data: any
}

const initialState = {
  loading: false,
  error: null,
  data: { scroll: 0 },
  called: false,
} as pageScrollEventSlice

export const fetchPageScrollEventSlice = createAsyncThunk(
  'fetchScroll/fetchPageScrollEventSlice',
  async (data: any) => {
    return data
  },
)

const pageScrollEventSlice = createSlice({
  name: 'fetchScroll',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPageScrollEventSlice.pending, (state, action) => {
      state.loading = true
      state.error = null
      state.called = true
    })
    builder.addCase(fetchPageScrollEventSlice.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
      state.called = true
    })
    builder.addCase(fetchPageScrollEventSlice.rejected, (state, action) => {
      state.loading = false
      state.called = false
      state.error = action.error
      console.error('Error fetching cards!')
    })
  },
})

// export var _actions = pageScrollEventSlice.actions
export default pageScrollEventSlice.reducer
