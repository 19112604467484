import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import GET_ALL_STORE from 'src/graphql/queries/getStores'
import client1 from 'src/utils/apolloClient'
import GET_ALL_PRPDUCTS from 'src/graphql/queries/getAllProducts'

interface fetchAllProduct {
  loading: true | false
  error: {} | null
  called: true | false
  product: []
}

const initialState = {
  loading: false,
  error: null,
  currentStep: '',
  called: false,
  product: [],
} as fetchAllProduct

export const fetchProductByCategory = createAsyncThunk('fetchProduct/fetchProducteData', async (post: any) => {
  const { data } = await client1.query({
    query: GET_ALL_PRPDUCTS,
    variables: {
      category: post?.category,
      subCategory: post?.subCategory,
      childSubCategory: post?.childSubCategory,
      isShopifyProduct: post?.isShopifyProduct,
      page: post?.page,
      limit: post?.limit,
      order: post?.order,
      imagelimit: post?.imagelimit,
      maxPrice: post?.maxPrice,
      minPrice: post?.minPrice,
    },
    fetchPolicy: 'network-only',
  })
  return data
})

const fetchAllProduct = createSlice({
  name: 'fetchProduct',
  initialState,
  reducers: {
    setProductByCategoryState: (state: any, action) => {
      state.product = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductByCategory.pending, (state, action) => {
      state.called = false
    })
    builder.addCase(fetchProductByCategory.fulfilled, (state, action) => {
      state.called = true
      state.product = action?.payload
    })
    builder.addCase(fetchProductByCategory.rejected, (state, action) => {
      state.called = true
      state.error = action.error.message
    })
  },
})

// export var _actions = fetchAllProduct.actions
export const { setProductByCategoryState } = fetchAllProduct.actions
export default fetchAllProduct.reducer
