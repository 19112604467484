// @ts-nocheck
import React from 'react'
import BookCoverImg from '../../../../../public/assets/img/bookCover.png'
import SearchIcon from '../../../../../public/assets/icon/search.svg'
import star from '../../../../../public/assets/img/WomansCategory/star.png'
import aroww from '../../../../../public/assets/img/WomansCategory/aroww.png'
import BlackheartIcon from '../../../../../public/assets/icon/heart-black.svg'
import ShareIcon from '../../../../../public/assets/icon/share-black.svg'
import DropdownIcon from '../../../../../public/assets/icon/chevron-down.svg'
import ShareIcon2 from '../../../../../public/assets/icon/share.svg'
import HeartIcon from '../../../../../public/assets/icon/heart.svg'
import AuthorImg from '../../../../../public/assets/img/auther-img.png'
import PRBookIcon from '../../../../../public/assets/icon/pr-book.svg'
import PRUserIcon from '../../../../../public/assets/icon/pr-users.svg'
import PRHeartIcon from '../../../../../public/assets/icon/pr-heart.svg'
import AuthorBannerImg from '../../../../../public/assets/img/author-banner.png'
import CoatIcon from '../../../../../public/assets/icon/coat-icon.svg'
import Slider from 'react-slick'

export default function AuthorModal() {
  const offerSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2.05,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.05,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 899,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  }

  return (
    <div className="author-modal-section">
      <div className="author-modal-all-details-alignment">
        <div className="author-banner-bg-alignment">
          <img src={AuthorBannerImg.src} alt="AuthorBannerImg" />
        </div>

        <div className="author-details-section">
          <div className="author-details-flex-alignment">
            <div className="author-all-profile-details-alignment">
              <div className="author-img-alignment">
                <img src={AuthorImg.src} alt="AuthorImg" />
              </div>
              <div className="author-personal-details-alignment">
                <h5>David Baldacci</h5>
                <p>Portland, Maine</p>
                <div className="author-child-details-alignment">
                  <div className="book-follow-like-alignment">
                    <div className="icon-alignment">
                      <img src={PRBookIcon.src} alt="PRBookIcon" />
                    </div>
                    <p>150 books</p>
                  </div>
                  <div className="book-follow-like-alignment">
                    <div className="icon-alignment">
                      <img src={PRUserIcon.src} alt="PRUserIcon" />
                    </div>
                    <p>12M follower</p>
                  </div>
                  <div className="book-follow-like-alignment">
                    <div className="icon-alignment">
                      <img src={PRHeartIcon.src} alt="PRHeartIcon" />
                    </div>
                    <p>275 likes</p>
                  </div>
                </div>
                <div className="mobile-follow-details-alignment">
                  <div className="mobile-follow-page-button-alignment">
                    <button>Follow Page</button>
                  </div>
                  <div className="mobile-option-alignment">
                    <img src={HeartIcon.src} alt="HeartIcon" />
                  </div>
                  <div className="mobile-option-alignment">
                    <img src={ShareIcon2.src} alt="ShareIcon2" />
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile-author-child-details-alignment">
              <div className="mobile-book-follow-like-alignment">
                <div className="mobile-icon-alignment">
                  <img src={PRBookIcon.src} alt="PRBookIcon" />
                </div>
                <p>150 books</p>
              </div>
              <div className="mobile-book-follow-like-alignment">
                <div className="mobile-icon-alignment">
                  <img src={PRUserIcon.src} alt="PRUserIcon" />
                </div>
                <p>12M follower</p>
              </div>
              <div className="mobile-book-follow-like-alignment">
                <div className="mobile-icon-alignment">
                  <img src={PRHeartIcon.src} alt="PRHeartIcon" />
                </div>
                <p>275 likes</p>
              </div>
            </div>
            <div className="follow-details-alignment">
              <div className="option-alignment">
                <img src={HeartIcon.src} alt="HeartIcon" />
              </div>
              <div className="option-alignment">
                <img src={ShareIcon2.src} alt="ShareIcon2" />
              </div>

              <div className="follow-page-button-alignment">
                <button>Follow Page</button>
              </div>
            </div>
          </div>

          <div className="author-review-details-alignment">
            <div className="review-heading-alignment">
              <h4>Reviews</h4>
              <a>+ Review</a>
            </div>

            <div className="author-child-review-details-alignment">
              {[0, 1, 2, 3].map((_, index) => {
                return (
                  <div className="author-child-all-detail-alignment" key={index}>
                    <div>
                      <img src={CoatIcon.src} alt="CoatIcon" />
                    </div>
                    <div className="author-note-alignment">
                      <p>Jack Bring me to the world I Lorem ipsum dolor sit amet consectetur adipiscing.</p>
                    </div>
                    <div className="profile-img-alignment">
                      <div className="child-author-img-alignment">
                        <img src={AuthorImg.src} alt="AuthorImg" />
                      </div>
                      <div className="child-author-name-alignment">
                        <p>Marco</p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="david-offer-book-alignment">
            <div className="david-offer-heading-alignment">
              <h6>Special Offer of David’s Book</h6>
              <a>See All</a>
            </div>
            <div className="david-offer-details-alignment">
              <Slider {...offerSlider}>
                {[0, 1, 2, 3, 4, 5].map((_, index) => {
                  return (
                    <div className="david-book-other-space-alignment" key={index}>
                      <div className="david-offer-book-gridItem">
                        <div className="david-offer-book-details">
                          <div className="book-cover-img">
                            <img src={BookCoverImg.src} alt="BookCoverImg" />
                          </div>
                          <div className="book-name-details-alignement">
                            <h5>A Gambling Man</h5>
                            <p>by Davic Baldacci</p>
                            <div className="price-alignment-flex">
                              <div className="price-alignment">
                                <h6>$ 22,5 </h6>
                                <span>
                                  <del>$ 34</del>
                                </span>
                              </div>
                              <div className="offer-label-alignment">
                                <p>25% OFF</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          </div>
          <div className="david-other-book-details-alignment">
            <div className="mobile-view-heading-alignment">
              <h4>David’s Collection</h4>
              <span>(150 books)</span>
            </div>
            <div className="david-other-book-filter-alignment">
              <div className="filter-seacch-alignment">
                <div className="seacrh-alignment">
                  <img src={SearchIcon.src} alt="SearchIcon" />
                  <input type="text" placeholder="Search David’s books" />
                </div>
              </div>
              <div className="category-alignment">
                <label>Sort by</label>
                <div className="category-dropdown-alignment">
                  <p>Latest</p>
                  <img src={DropdownIcon.src} alt="DropdownIcon" />
                </div>
              </div>
            </div>
            <div className="david-other-all-details-alignment">
              <div className="david-other-all-details-grid-alignment">
                {[0, 1, 2, 3, 4, 5].map((_, index) => {
                  return (
                    <div className="david-other-all-details-gridItem-alignment" key={index}>
                      <div className="most-liked-book-alignment">
                        <div className="book-img-alignment">
                          <img src={BookCoverImg.src} alt="BookCoverImg" />
                        </div>
                        <div className="most-liked-name-alignment">
                          <h6>The Sweetness of Water</h6>
                          <p>Nathan Harris</p>
                          <div className="book-rate-alignment">
                            <p className="hike">
                              <span className="incris">
                                <img src={aroww.src} alt="incris" /> 200%
                              </span>
                            </p>
                            <div className="rating">
                              <span className="rating_point">4.8</span>
                              <div className="stars">
                                <img src={star.src} alt="star" />
                              </div>
                              <span className="num">(437)</span>
                            </div>
                          </div>
                          <div className="book-price-alignment">
                            <h5>$21.00</h5>
                            <span>
                              <del>$21.00</del>
                            </span>
                          </div>
                          <div className="like-share-alignment">
                            <div className="like-alignment">
                              <img src={BlackheartIcon.src} alt="BlackheartIcon" />
                              <span>235</span>
                            </div>
                            <div className="like-alignment">
                              <img src={ShareIcon.src} alt="ShareIcon " />
                              <span>235</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
