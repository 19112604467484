import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import GET_MY_GROUP from 'src/graphql/queries/getMyGroup'
import { notification } from 'antd'
import { Radio } from 'antd'
import { fetchProductShareOptions } from 'src/store/slices/productShareOptionsSlice'
import { useAppDispatch } from 'src/store/store'
import SHARE_POST_MUTATION from 'src/graphql/mutations/createSharePost'
import { useSelector } from 'react-redux'
import FramerAnimationModal from 'src/utils/FramerAnimationModal'
import { fetchJoinedClubsData, setJoinedClub } from 'src/store/slices/fetchAllClubsSlice'

export default function ShareOnMyGroupModal(props) {
  const { setIsSharePostOnGroup, groupId, isSharePostOnGroup } = props
  const fetchProductShareOptionsData = useSelector((state: any) => state?.productShareOptions?.data)
  const fetchProductImageResize = useSelector((state: any) => state?.productResizeData?.data)
  const joinedGroup = useSelector((state: any) => state?.clubData?.joinedClubs)

  const dispatch = useAppDispatch()

  // const { loading, error, data } = useQuery(GET_MY_GROUP, {
  //   variables: {
  //     isJoined: true,
  //   },
  // })
  const [search, setSearch] = useState<any>('')
  const [followers, setFollowers] = useState<any>([])
  const [value, setValue] = useState<any>(groupId)

  useEffect(() => {
    dispatch(fetchJoinedClubsData({ page: 1, limit: 100, type: 'JOIN_CLUBS' }))
  }, [])

  const handleChange = (e) => {
    setSearch(e.target.value)
  }

  let selectedFam = followers?.filter((fam) => fam.isChecked)

  const handleCancel = () => {
    dispatch(fetchProductShareOptions({ modal: '', isOpen: false, id: {} }))
    dispatch(setJoinedClub([]))
  }

  const handleUnselect = () => {
    setFollowers(
      followers?.map((fam) => {
        return { ...fam, isChecked: false }
      }),
    )
  }

  function onChange(e, fam) {
    setFollowers(
      followers?.map((follow) => {
        if (follow?.id === fam?.id) {
          return { ...follow, isChecked: e.target.checked }
        } else {
          return { ...follow }
        }
      }),
    )
  }

  const [createSharePost] = useMutation(SHARE_POST_MUTATION, {
    onCompleted: (res) => {
      notification['success']({
        message: 'Product shared successfully',
        description: 'Your post has been created successfully',
      })
    },
    onError: (error) => {
      console.log(error)
    },
  })

  // const postHandler = (data) => {
  //   createSharePost({
  //     variables: {
  //       content: data,
  //       store_name: fetchProductShareOptions?.id?.selectedProduct?.storeData?.name,
  //       share_post_for: 'GROUP',
  //       product_id: fetchProductShareOptions?.id?.selectedProduct?.id,
  //       group_id: groupId?.id ? [groupId?.id] : [],
  //     },
  //     refetchQueries: [{ query: MY_FEED_QUERY, variables: { page: 1, limit: 10 } }],  //   }).then((response) => {
  //     if (response?.data !== undefined) {
  //       dispatch(fetchProductShareOptions({ modal: '', isOpen: false, id: {} }))
  //     }
  //   })
  // }

  useEffect(() => {
    setFollowers(joinedGroup || [])
  }, [joinedGroup])

  const handleOnChnage = (e) => {
    setValue(e.target.value)
    dispatch(
      fetchProductShareOptions({
        ...fetchProductShareOptionsData,
        id: { ...fetchProductShareOptionsData?.id, groupData: e.target.value },
      }),
    )
  }

  const handleShareGroup = (value) => {
    if (fetchProductImageResize?.isShareBookmark) {
      dispatch(
        fetchProductShareOptions({
          modal: 'shareProductFromBookmark',
          isOpen: true,
          id: {
            shareData: fetchProductImageResize?.shareData,
            isGroupPost: true,
            detail: fetchProductImageResize?.detail,
            groupData: fetchProductShareOptionsData?.id?.groupData,
          },
        }),
      )
    } else {
      dispatch(
        fetchProductShareOptions({
          ...fetchProductShareOptionsData,
          modal: 'productShareOnMyFeedModal',
        }),
      )
    }
  }

  return (
    <div className="dropdown-modal-details">
      <div className="dropdown-modal-wrapper">
        <FramerAnimationModal>
          <div className="dropdown-modal-box-alignment">
            <div className="bg-white rounded-xl -m-16">
              <div className="modal-header flex justify-between items-center px-8 py-4">
                <p className="text-black font-medium text-lg">Share with Club</p>
                <button onClick={handleCancel}>
                  <img src="/assets/img/feed/ic_Close.svg" />
                </button>
              </div>
              <div style={{ padding: '16px' }}>
                <div className="rounded-2xl h-16 w-full border border-gray-300 items-center px-3 mb-2 relative">
                  <img src="/assets/icon/search-icon-grey.svg" alt="search" className="absolute top-4" />
                  <input
                    type={'text'}
                    placeholder="Search Club"
                    onChange={(e) => handleChange(e)}
                    className="w-full h-full pl-10"
                  ></input>
                </div>
                {selectedFam?.length > 0 && (
                  <div className="flex justify-between items-center mt-3">
                    <div className="text-sm text-gray-400 font-normal px-4">{selectedFam?.length} friends selected</div>
                    <div className="text-sm text-blue-500 cursor-pointer" onClick={() => handleUnselect()}>
                      Unselect All
                    </div>
                  </div>
                )}
                <div className="invite-modal" style={{ height: '336px', overflow: 'scroll' }}>
                  {
                    (search
                      ? followers?.filter((supplier) => supplier?.name?.toLowerCase().includes(search?.toLowerCase()))
                      : followers
                    )?.map((fam, index) => {
                      return (
                        <div
                          className={`rounded-2xl w-full flex justify-between items-center px-4 py-1 py-2 my-2 hover:bg-indigo-50 ${
                            fam?.isChecked && 'bg-indigo-50'
                          }`}
                          key={index}
                        >
                          <div className="flex justify-start items-center">
                            <div className="relative">
                              <img
                                src={fam?.banner_image}
                                className="mr-4 object-cover"
                                style={{ width: '48px', height: '34px', borderRadius: '3.33913px' }}
                              />
                              <div className="absolute flex justify-center h-3 w-3 bg-green-500 rounded-full z-10 bottom-0 right-7"></div>
                            </div>
                            <div>
                              <div className="text-sm font-medium text-black cursor-default">{fam?.name}</div>
                            </div>
                          </div>
                          <div className="radio-button-class">
                            <Radio.Group onChange={handleOnChnage} value={fetchProductShareOptionsData?.id?.groupData}>
                              <Radio value={fam}></Radio>
                            </Radio.Group>
                          </div>
                        </div>
                      )
                    })
                    // ) : (
                    //   <div className="flex justify-center items-center h-full text-base text-gray-400">Not found</div>
                    // )
                  }
                </div>
                <div className="border-t -mx-6 px-6 pt-4">
                  <div className="flex justify-end gap-5">
                    {fetchProductShareOptionsData?.id?.groupData ? (
                      <div className="send-button-align" onClick={() => handleShareGroup(value)}>
                        <button>Next</button>
                      </div>
                    ) : (
                      <div className="send-button-align close">
                        <button>Next</button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FramerAnimationModal>
      </div>
    </div>
  )
}
