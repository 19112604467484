import React from 'react'
import styles from './AddProductModalHeader.module.scss'
import { useSelector } from 'react-redux'
import { Spin } from 'antd'

export default function AddProductModalHeader({
  handleSubmit,
  handleEdit,
  setLoadingProduct,
  loadingProduct,
  validateForm,
  productData,
  setProductData,
}) {
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  const handleChange = (e) => {
    setProductData({ ...productData, isPublish: e.target.checked })
  }

  return (
    <div className={styles.addProductModalHeadingAlignment}>
      <div className={styles.headingAlignment}>
        <h2>
          Add New Product<span>Published</span>
          {/* <div className={styles.draftBox}>
<p>Draft</p>
        </div> */}
        </h2>
      </div>
      <div className={styles.addProductOtherOptionAlignment}>
        <div className={styles.peviewDeleteButtonAlignment}>
          <a>Discard</a>
          <button className={styles.deleteIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path
                d="M2.25 4.5H3.75H15.75"
                stroke="#E71616"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.25 4.5V15C14.25 15.3978 14.092 15.7794 13.8107 16.0607C13.5294 16.342 13.1478 16.5 12.75 16.5H5.25C4.85218 16.5 4.47064 16.342 4.18934 16.0607C3.90804 15.7794 3.75 15.3978 3.75 15V4.5M6 4.5V3C6 2.60218 6.15804 2.22064 6.43934 1.93934C6.72064 1.65804 7.10218 1.5 7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5"
                stroke="#E71616"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.5 8.25V12.75"
                stroke="#E71616"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.5 8.25V12.75"
                stroke="#E71616"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <button>
            <img src="/assets/seller/icons/airplay.svg" alt="PreviewIcon" />
          </button>
        </div>
        {/* {loadingProduct ? (
          <Spin />
        ) : ( */}
        <div className={styles.publishedAndSaveButtonAlignment}>
          {/* <div className={styles.publishBoxAlignment}>
            <p>Publish</p>

            <div className="add-modal-cusotom-toggle">
              <label className="add-modal-switch">
                <input type="checkbox" onChange={(e) => handleChange(e)} />
                <span className="add-modal-slider add-modal-round"></span>
              </label>
            </div>
          </div> */}
          <div
            className={styles.saveButton}
            onClick={(e) => {
              if (validateForm()) {
                getAllState?.lastModalData?.isEdit ? handleEdit(e) : handleSubmit(e)
              } else {
                // alert('Please fill all the fields')
              }
            }}
          >
            <button>
              Save
              {/* <img src="/assets/icon/button-loader.svg" alt="button loader" /> */}
            </button>
          </div>
        </div>
        {/* )} */}
      </div>
    </div>
  )
}
