import React, { useState, useEffect } from 'react'
import styles from './MobileViewSelectCategoryModal.module.scss'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/client'
import GET_CATEGORIES_QUERY from 'src/graphql/queries/getCategories'
import classNames from 'classnames'

export default function MobileViewSelectCategoryModal() {
  const { loading, error, data } = useQuery(GET_CATEGORIES_QUERY)
  const [categories, setCategories] = useState([])
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const fetchProductImageResizeData = useSelector((state: any) => state?.productResizeData?.data)

  const [tabOpen, setTabOpen] = useState(0)
  const [subTabOpen, setSubTabOpen] = useState(0)
  const dispatch = useAppDispatch()
  const handleChangeTab = (key, data) => {
    if (data === 'category') {
      if (tabOpen === key) {
        setTabOpen(0)
      } else {
        setTabOpen(key)
      }
    } else {
      if (subTabOpen === key) {
        setSubTabOpen(0)
      } else {
        setSubTabOpen(key)
      }
    }
  }

  const [MainOptionCatagoty, setMainOptionCatagoty] = useState(false)
  useEffect(() => {
    if (getAllState?.lastModalData?.isEdit && getAllState?.lastModalData?.id) {
      let editProductData = getAllState?.lastModalData?.id
      // dispatch(
      //   fetchProductImageResizeModal({
      //     modal: '',
      //     isOpen: false,
      //     id: {
      //       ...fetchProductImageResizeData?.id,
      //       selectedCategories: editProductData?.category?.map((cat) => {
      //         return {
      //           category_id: cat?.category?.id,
      //           subCategory_id: cat?.subCategory?.id,
      //           childSubCategory_id: cat?.childSubCategory?.id,
      //         }
      //       }),
      //     },
      //   }),
      // )

      let Array1 = editProductData?.category
      let Array2 = data?.getAllCategoryDetail
      if (Array2?.length > 0) {
        let outputArray = Array2?.map((category) => {
          let subCategory = category.subCategory.map((subCat) => {
            let childSubCategory = subCat.childSubCategory.map((childSubCat) => {
              let isChecked = Array1?.some(
                (item) =>
                  item.category.id === category.id &&
                  item.subCategory.id === subCat.id &&
                  item.childSubCategory.id === childSubCat.id,
              )
              return { ...childSubCat, isChecked }
            })
            return { ...subCat, childSubCategory }
          })
          return { ...category, subCategory }
        })
        setCategories(outputArray)
      }
    } else {
      setCategories(data?.getAllCategoryDetail)
    }
  }, [getAllState, data])

  const handleClick = (e, category, subCategory, childSubCategory) => {
    let cat = category.id
    let subCat = subCategory.id
    let childSubCate = childSubCategory.id
    let filteredData = fetchProductImageResizeData?.id?.selectedCategories?.filter(
      (cate) => cate.childSubCategory_id === childSubCate,
    )
    if (filteredData?.length > 0) {
      dispatch(
        fetchProductImageResizeModal({
          modal: '',
          isOpen: false,
          id: {
            ...fetchProductImageResizeData?.id,
            selectedCategories: fetchProductImageResizeData?.id?.selectedCategories?.filter(
              (cate) => cate.childSubCategory_id !== childSubCate,
            ),
          },
        }),
      )
    } else {
      dispatch(
        fetchProductImageResizeModal({
          modal: '',
          isOpen: false,
          id: {
            ...fetchProductImageResizeData?.id,
            selectedCategories: [
              ...fetchProductImageResizeData?.id?.selectedCategories,
              { category_id: cat, subCategory_id: subCat, childSubCategory_id: childSubCate },
            ],
          },
        }),
      )
    }
    let filtredData = categories?.map((cat) => {
      if (cat?.id === category?.id) {
        return {
          ...cat,
          subCategory: cat?.subCategory?.map((subcat) => {
            if (subcat?.id === subCategory?.id) {
              return {
                ...subcat,
                childSubCategory: subcat?.childSubCategory?.map((subchildcat) => {
                  if (subchildcat?.id === childSubCategory?.id) {
                    return { ...subchildcat, isChecked: e.target.checked }
                  } else {
                    return subchildcat
                  }
                }),
              }
            } else {
              return subcat
            }
          }),
        }
      } else {
        return cat
      }
    })
    setCategories(filtredData)
  }

  return (
    <div className={styles.mobileViewSelectCategoryModalAlignment}>
      <div className={styles.mobileViewSelectCategoryModalHeader}>
        <h6>Select Category</h6>
        <a>Cancel</a>
      </div>
      <div className={styles.mobileViewSelectCategoryModalBodyAlignment}>
        <div className={styles.mobileViewSelectCategoryOptionAlignment}>
          {categories?.map((category, index) => {
            return (
              <>
                <div className={styles.mobileViewMainCategoryNameAlignment}>
                  <div
                    className={styles.mobileCategoryIcon}
                    onClick={() => {
                      setMainOptionCatagoty(!MainOptionCatagoty)
                      handleChangeTab(category?.id, 'category')
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <rect x="0.5" y="0.5" width="17" height="17" rx="2.5" fill="#ECEEF4" stroke="#ECEEF4" />
                      <path
                        d="M5.00391 10.998L9.00391 6.99805L13.0039 10.998"
                        stroke="#7A7E88"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <h6>{category.name}</h6>
                </div>
                <div className={tabOpen === category?.id ? styles.mainTabShowArea : styles.mainTabHiddenArea}>
                  <div className={styles.mobileChildCategoryMain}>
                    {category?.subCategory?.map((subCategory, i) => {
                      return (
                        <>
                          <div className={styles.mobileViewChildCategoryAlignment}>
                            <div
                              className={styles.mobileCategoryIcon}
                              onClick={() => {
                                handleChangeTab(subCategory?.id, 'subcategory')
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <rect
                                  x="0.5"
                                  y="-0.5"
                                  width="17"
                                  height="17"
                                  rx="2.5"
                                  transform="matrix(1 0 0 -1 0 17)"
                                  stroke="#ECEEF4"
                                />
                                <path
                                  d="M5.00391 7.00195L9.00391 11.002L13.0039 7.00195"
                                  stroke="#7A7E88"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <p>{subCategory?.name}</p>
                          </div>
                          <div
                            className={
                              subTabOpen === subCategory?.id
                                ? classNames(styles.mobileViewChildCategoryOptionAlignment, styles.tabShowArea)
                                : classNames(styles.mobileViewChildCategoryOptionAlignment, styles.tabHiddenArea)
                            }
                          >
                            {subCategory?.childSubCategory?.map((childSubCategory, i) => {
                              return (
                                <div className={styles.mobileViewChildCategoryOptionName}>
                                  <div className={styles.mobileInputCheckboxAlignment}>
                                    <input
                                      type="checkbox"
                                      checked={fetchProductImageResizeData?.id?.selectedCategories?.some(
                                        (cate) =>
                                          cate.category_id === category?.id &&
                                          cate.subCategory_id === subCategory?.id &&
                                          cate.childSubCategory_id === childSubCategory?.id,
                                      )}
                                      onClick={(e) => handleClick(e, category, subCategory, childSubCategory)}
                                    />
                                  </div>
                                  <p>{childSubCategory?.name}</p>
                                </div>
                              )
                            })}
                          </div>
                        </>
                      )
                    })}
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>
    </div>
  )
}
