import React, { useRef, useState } from 'react'
import Slider from 'react-slick'
import DemoImg from '../../../../../../public/assets/img/demoimg1.png'
import DropdownArrow from '../../../../../../public/assets/icon/down-arrow.svg'
import NoMeadiaImg from '../../../../../../public/assets/img/media-no-data.png'
import MediaProfileModal from '../MediaProfileModal'

export default function UserMedia(props) {
  const { allPost, editPostHandler } = props
  const [MediaModal, setMediaModal] = useState(false)
  const [sliderIndex, setSliderIndex] = useState(null)
  const [postId, setPostId] = useState({ id: null, post_for: null })
  const [commentId, setCommentId] = useState({ id: null, comment_for: '' })

  const productRef: any = useRef()

  let filteredPost = allPost
    ?.filter((post) => post?.post_for == 'USER')
    .map((media) => {
      let attache = media?.attachements.map((att) => {
        return { ...att, ...media }
      })
      return attache
    })
    .flat()

  const HandleViewMedia = (media, index) => {
    setMediaModal(true)
    setPostId({ id: media?.id, post_for: media?.post_for })
    setCommentId({ id: media?.id, comment_for: media?.post_for ? 'POST' : 'SHAREPOST' })
    // setSliderIndex(index)
    // productRef.innerSlider.list.setSliderIndex(5)
    // productRef.innerSlider.list.focus()
    // sliderIndex?.innerSlider?.slickGoTo(index)
    // productRef?.current?.innerSlider(index)
    // sliderIndex?.innerSlider?.slideHandler()
    // productRef?.current?.slick('slickGoTo', index)
    // sliderIndex?.slickGoTo(4)
    productRef?.current?.slickGoTo(4)
    // productRef?.current?.innerSlider?.list?.focus()
  }

  return (
    <React.Fragment>
      <div className="mobile-view-media-box-alignment">
        <div className="mobile-view-product-media-details-alignment">
          <div className="media-details-alignment">
            <span>{filteredPost?.length} media</span>
          </div>
          <div className="media-photo-video-dropdown-alignment">
            <p>Photos and videos</p>
            <div>
              <img src={DropdownArrow.src} alt="DropdownArrow" />
            </div>
          </div>
        </div>
        <div className="social-product-media-alignemt">
          {filteredPost?.length > 0 &&
            filteredPost?.map((media, index) => {
              return (
                <div className="social-product-img-alignment" onClick={() => HandleViewMedia(media, index)} key={index}>
                  {media?.url.includes('mp4') ? (
                    <video loop autoPlay muted>
                      <source src={media?.url} type="video/mp4" />
                    </video>
                  ) : (
                    <img src={media?.url} alt="DemoImg" />
                  )}
                </div>
              )
            })}
        </div>
        {filteredPost?.length === 0 && (
          <div className="mobile-view-media-no-data-modal-alignment">
            <div>
              <img src={NoMeadiaImg.src} alt="NoMeadiaImg" />
            </div>
            <div className="media-no-data-name-alignment">
              <span>
                This account has <br /> no media.
              </span>
            </div>
          </div>
        )}
      </div>

      {MediaModal && (
        <MediaProfileModal
          setMediaModal={setMediaModal}
          mediaData={filteredPost}
          postId={postId}
          setCommentId={setCommentId}
          commentId={commentId}
          editPostHandler={editPostHandler}
        />
      )}
    </React.Fragment>
  )
}
