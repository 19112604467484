import React, { useEffect } from 'react'
import styles from './MobileJewelryClubModal.module.scss'
import ClubSlider from 'src/components/StoreAndProductDetailModal/ClubModal/ClubSlider'
import GET_GLOBAL_SEARCH_WITH_ELASTIC_SEARCH from 'src/graphql/queries/sellerQueries/getGlobalSearchWithElasticSearch'
import { useMutation, useQuery } from '@apollo/client'
import LEAVE_GROUP from 'src/graphql/mutations/leaveGroup'
import JOIN_GROUP from 'src/graphql/mutations/joinGroup'
import { useAuth } from 'src/utils/auth'
import GET_MY_GROUP from 'src/graphql/queries/getGroups'
import { useSelector } from 'react-redux'
import Link from 'next/link'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAppDispatch } from 'src/store/store'
import GET_JOINED_GROUP from 'src/graphql/queries/sellerQueries/getJoinedGroups'
import { fetchManageClubsData } from 'src/store/slices/fetchAllClubsSlice'

export default function MobileJewelryClubModal() {
  const { user }: any = useAuth()
  // const joinedGroup = useQuery(GET_JOINED_GROUP)
  const fetchSearchModalData = useSelector((state: any) => state?.searchModalSlice?.data)
  const dispatch = useAppDispatch()
  const allManageClub = useSelector((state: any) => state?.clubData?.manageClubs)

  useEffect(() => {
    dispatch(fetchManageClubsData({ page: 1, limit: 100, type: 'SUGGESTED_CLUBS' }))
  }, [])

  const [leaveGroup] = useMutation(LEAVE_GROUP, {
    onCompleted: (res) => {},
    onError: (err) => {},
  })

  const [joingroup] = useMutation(JOIN_GROUP, {
    onCompleted: (res) => {},
    onError: (err) => {},
  })

  const leaveGroupCall = (club) => {
    leaveGroup({
      variables: {
        userId: user.id,
        groupId: club?.id,
      },
      update: (proxy, { data }) => {
        const joinedGroup: any = proxy.readQuery({
          query: GET_JOINED_GROUP,
        })
        let updatedData = joinedGroup?.getJoinedGroups?.filter((grp) => grp?.id !== club?.id)
        proxy.writeQuery({
          query: GET_JOINED_GROUP,
          data: { getJoinedGroups: updatedData },
        })

        const suggestedGroup: any = proxy.readQuery({
          query: GET_MY_GROUP,
        })
        proxy.writeQuery({
          query: GET_MY_GROUP,
          data: {
            groups: [
              ...suggestedGroup?.groups,
              {
                ...club,
                isFollow: false,
                members: club?.members?.filter((grp) => grp?.id !== user?.id),
              },
            ],
          },
        })
      },
    })
  }

  const leaveClubYouManageCall = (club) => {
    leaveGroup({
      variables: {
        userId: user.id,
        groupId: club?.id,
      },
    })
  }

  const joingroupCall = (club) => {
    joingroup({
      variables: { group_id: club?.id, user_id: user?.id, isAdmin: false },
      update: (proxy, { data }) => {
        const dataInCache: any = proxy.readQuery({
          query: GET_GLOBAL_SEARCH_WITH_ELASTIC_SEARCH,
          variables: {
            search: fetchSearchModalData?.search,
            limit: 10,
            page: 0,
          },
        })
        let updatedData = dataInCache?.globalSearchWithElasticSearch?.data?.groups?.map((grp) => {
          if (club?.id === grp?.id) {
            return {
              ...grp,
              isFollow: true,
              members: [
                {
                  firstName: user?.firstName,
                  lastName: user?.lastName,
                  id: user?.id,
                  profileUrl: user?.logo_image,
                },
                ...grp?.members,
              ],
            }
          } else {
            return grp
          }
        })
        let datass = proxy.writeQuery({
          query: GET_GLOBAL_SEARCH_WITH_ELASTIC_SEARCH,
          data: {
            globalSearchWithElasticSearch: {
              data: { ...dataInCache?.globalSearchWithElasticSearch?.data, groups: updatedData },
            },
          },
        })
      },
    })
  }

  return (
    <div className={styles.mobileJewelryClubModalSection}>
      <div className={styles.mobileJewelryClubModalAlignment}>
        <div className={styles.mobileJewelryClubModalHeading}>
          <svg xmlns="http://www.w3.org/2000/svg" width="37" height="32" viewBox="0 0 37 32" fill="none">
            <path
              d="M27 0H10C4.47715 0 0 4.47715 0 10V22C0 27.5228 4.47715 32 10 32H27C32.5228 32 37 27.5228 37 22V10C37 4.47715 32.5228 0 27 0Z"
              fill="white"
            />
            <path
              d="M21 10L16 16L21 22"
              stroke="#808191"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <h6>Jewelry Clubs</h6>
        </div>

        <div className={styles.mobileJewelryClubModalBodyAlignment}>
          {/* <ClubSlider club={club} leaveGroupCall={leaveGroupCall} joingroupCall={joingroupCall} /> */}
          <div className={styles.mobileJewelryClubModalgridAlignment}>
            {allManageClub?.map((club, index) => (
              <React.Fragment key={index}>
                <ClubSlider club={club} leaveGroupCall={leaveGroupCall} joingroupCall={joingroupCall} isOpen={true} />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
