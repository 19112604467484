import React from 'react'
import styles from './ShopProductCard.module.scss'
import LazyImage from 'src/utils/LazyImage'
import LazyImageTrendingProduct from 'src/utils/LazyImageTrendingProduct'
import Link from 'next/link'

export default function ShopProductCard({ productData }) {
  return (
    <div className={styles.shopTrendingProductBox}>
      <div className={styles.childGrid}>
        {productData?.products.map((items, index) => {
          return (
            <Link href={`/product/${items?.product_data?.slug}`}>
              <div className={styles.childImg} key={index}>
                <LazyImageTrendingProduct
                  image={{
                    src: items?.product_data?.images[0]?.src
                      ? items?.product_data?.images[0]?.src
                      : '/assets/img/shop-img.png',
                    alt: 'Icon',
                  }}
                  className={'imageContainer'}
                  objectFit={'cover'}
                />
                <div className={styles.textAlignment}>
                  <div className={styles.spacer}>
                    <p>${items?.product_data?.dis_price || 0}</p>
                    <span>{items?.product_data?.title}</span>
                  </div>
                </div>
              </div>
            </Link>
          )
        })}
      </div>

      <div className={styles.boxChildDetails}>
        <div className={styles.boxProfileName}>
          <div className={styles.profileImg}>
            <img
              src={
                productData?.products[0]?.childSubCategory?.media
                  ? productData?.products[0]?.childSubCategory?.media
                  : '/assets/img/shop-img.png'
              }
              alt="shop img"
            />
          </div>
          <h6>{productData?.products[0]?.childSubCategory?.name}</h6>
        </div>
        {/* <p>{productData?.total_count}+ items</p> */}
      </div>
    </div>
  )
}
