import { gql } from '@apollo/client'

const GET_PRODUCTS_REVIEWS = gql`
query GetProductReviewsBySlug($input: getProductReviewsBySlugRequest) {
  getProductReviewsBySlug(input: $input) {
    success
    message
    data {
      id
      userReviews {
        id
        product_id
        user_id
        seller_id
        order_items_id
        order_master_id
        store_id
        overAllRating
        title
        user_details {
          logo_image
          firstName
          lastName
        }
        details
        createdAt
        updatedAt
        userReviewsOnProductsMedias {
          id
          userReviewsOnProductsId
          position
          media_type
          media_for
          url
          createdAt
          updatedAt
        }
      }
    }
  }
}
`
export default GET_PRODUCTS_REVIEWS