import { gql } from '@apollo/client'
import { GROUP_DETAILS, LOGGED_USER_DETAILS, MESSAGE_DETAILS } from '../fragments/fragments'

export const REGISTER_USER = gql`
  mutation registerUser($username: String!, $password: String!) {
    register(username: $username, password: $password) {
      ...LoggedUserDetails
    }
  }
  ${LOGGED_USER_DETAILS}
`

export const START_STOP_TYPING = gql`
  mutation startStopTyping($chatType: String!, $conversationId: Int!, $action: String!) {
    startStopTyping(chatType: $chatType, conversationId: $conversationId, action: $action)
  }
`

export const LOGIN_USER = gql`
  mutation loginUser($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      ...LoggedUserDetails
    }
  }
  ${LOGGED_USER_DETAILS}
`

export const SEND_PRIVATE_MSG = gql`
  mutation submitPrivateMsg($receiverId: Int!, $body: String!, $replyMessageId: Int, $images: [String]) {
    sendPrivateMessage(receiverId: $receiverId, body: $body, replyMessageId: $replyMessageId, images: $images) {
      ...MessageDetails
      replyMessage {
        body
        conversationId
        createdAt
        id
        isSeen
        senderId
        user {
          firstName
          id
          lastName
          profileAvtar
        }
      }
    }
  }
  ${MESSAGE_DETAILS}
`



export const MUTE_UNMUTE_USER = gql`
  mutation muteUnmuteUser($conversationId: Int!, $mute: Boolean!) {
    muteUnmuteUser(conversationId: $conversationId, mute: $mute) {
      mutedParticipants
    }
  }
`

export const REMOVE_PRIVATE_MSG = gql`
  mutation removePrivateMessage($removePrivateMessageId: Int!, $receiverId: Int!) {
    removePrivateMessage(id: $removePrivateMessageId, receiverId: $receiverId) {
      success
      message
      data {
        id
        type
        participants
      }
    }
  }
`

export const REMOVE_GROUP_MSG = gql`
  mutation removeGroupMessage($removeGroupMessageId: Int!, $conversationId: Int!) {
    removeGroupMessage(id: $removeGroupMessageId, conversationId: $conversationId) {
      success
      message
      data {
        id
        type  
        participants
        conversationId
      }
    }
  }
`

export const EDIT_PRIVATE_MSG = gql`
  mutation editPrivateMsg($editPrivateMessageId: Int!, $body: String!, $receiverId: Int!) {
    editPrivateMessage(id: $editPrivateMessageId, body: $body, receiverId: $receiverId) {
      ...MessageDetails
      replyMessage {
        body
        conversationId
        createdAt
        id
        isSeen
        senderId
        user {
          firstName
          id
          lastName
          profileAvtar
        }
      }
    }
  }
  ${MESSAGE_DETAILS}
`

export const REACT_ON_MESSAGE = gql`
  mutation ReactOnMessage($messageId: Int!, $emojiId: Int!, $receiverId: Int!, $chatType: String!) {
    reactOnMessage(message_id: $messageId, emoji_id: $emojiId, receiverId: $receiverId, chatType: $chatType) {
      ...MessageDetails
      replyMessage {
        body
        conversationId
        #createdAt
        id
        #isSeen
        senderId
        user {
          firstName
          id
          lastName
          profileAvtar
        }
      }
    }
  }
  ${MESSAGE_DETAILS}
`

export const EDIT_GROUP_MSG = gql`
  mutation editGroupMessage($conversationId: Int!, $body: String!, $replyMessageId: Int, $editGroupMessageId: Int!) {
    editGroupMessage(
      conversationId: $conversationId
      body: $body
      replyMessageId: $replyMessageId
      id: $editGroupMessageId
    ) {
      ...MessageDetails
    }
  }
  ${MESSAGE_DETAILS}
`

export const SEND_GROUP_MSG = gql`
  mutation submitGroupMsg($conversationId: Int!, $body: String!, $replyMessageId: Int) {
    sendGroupMessage(conversationId: $conversationId, body: $body, replyMessageId: $replyMessageId) {
      ...MessageDetails
    }
  }
  ${MESSAGE_DETAILS}
`

export const SEND_GLOBAL_MSG = gql`
  mutation submitGlobalMsg($body: String!) {
    sendGlobalMessage(body: $body) {
      ...MessageDetails
    }
  }
  ${MESSAGE_DETAILS}
`

export const REMOVE_GROUP_USER = gql`
  mutation RemoveGroupUser($conversationId: Int!, $userId: Int!) {
    removeGroupUser(conversationId: $conversationId, userId: $userId) {
      groupId
      participants
    }
  }
`

export const ADD_GROUP_USER = gql`
  mutation addUser($conversationId: Int!, $participants: [Int!]!) {
    addGroupUser(conversationId: $conversationId, participants: $participants) {
      groupId
      participants
    }
  }
`

export const EDIT_GROUP_NAME = gql`
  mutation updateGroupName($conversationId: Int!, $name: String!) {
    editGroupName(conversationId: $conversationId, name: $name) {
      groupId
      name
    }
  }
`

export const ACCEPT_GROUP_REQUEST = gql`
  mutation AcceptGroupRequest($status: String!, $groupId: Int!) {
    acceptGroupRequest(status: $status, group_id: $groupId) {
      group_id
      user_id
      invited_user_id
      status
      id
      name
      admin
      type
      participants
      createdAt
      latestMessage {
        id
        body
        conversationId
        senderId
        isSeen
        createdAt
        user {
          id
          firstName
          lastName
          profileAvtar
        }
      }
      adminUser {
        id
        firstName
        lastName
        profileAvtar
        isAdmin
      }
      users {
        id
        firstName
        lastName
        profileAvtar
        isAdmin
        isInvitedUser
      }
      invitedUsers {
        id
        firstName
        lastName
        profileAvtar
        isInvitedUser
      }
    }
  }
`

export const INVITE_FOR_GROUP_MESSAGE = gql`
  mutation InviteGroupUsers($conversationId: Int!, $participants: [Int!]!) {
    inviteUsers(conversationId: $conversationId, participants: $participants) {
      groupId
      participants
    }
  }
`

export const CREATE_MESSAGE_GROUP = gql`
  mutation createMessageGroup($name: String!, $participants: [Int!]) {
    createMessageGroup(name: $name, participants: $participants) {
      id
      name
      admin
      type
      participants
      createdAt
      adminUser {
        id
        firstName
        lastName
        profileAvtar
    }
    latestMessage {
        id
        body
        conversationId
        senderId
        isSeen
        createdAt
        user {
          id
          firstName
          lastName
          profileAvtar
        }
      }
      users {
        id
        firstName
        lastName
        profileAvtar
      }
    }
  }
`

export const LEAVE_GROUP = gql`
  mutation LeaveMessageGroup($conversationId: Int!) {
    leaveMessageGroup(conversationId: $conversationId)
  }
`

export const DELETE_GROUP = gql`
  mutation removeGroup($conversationId: Int!) {
    deleteGroup(conversationId: $conversationId)
  }
`

export const READ_ALL_MSG = gql`
  mutation readAllMessages($chatType: String!, $conversationId: Int!) {
    readAllMessages(chatType: $chatType, conversationId: $conversationId)
  }
`
