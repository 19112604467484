import React from 'react'
import styles from './MobileJewelryLiveChannel.module.scss'
export default function MobileJewelryLiveChannel() {
  return (
    <div className={styles.mobileJewelryLiveChannelSection}>
      <div className={styles.mobileJewelryLiveChannelAlignment}>
        <div className={styles.mobileJewelryLiveChannelDetailsAlignment}>
          <div className={styles.mobileJewelryLiveChannelHeadingAlignment}>
            <h6>Live Channel</h6>
            <a>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <rect x="1.5" y="4.5" width="15" height="11.25" rx="2.25" stroke="#556EE6" strokeWidth="1.25" />
                <path
                  d="M12.75 2.25L9 4.5L5.25 2.25"
                  stroke="#556EE6"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.08913 11.0844C9.51171 11.0119 9.79553 10.6106 9.72305 10.188C9.65058 9.76542 9.24926 9.4816 8.82667 9.55408C8.40409 9.62655 8.12028 10.0279 8.19275 10.4505C8.26523 10.873 8.66655 11.1569 9.08913 11.0844Z"
                  stroke="#556EE6"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.3019 8.41973C10.5519 8.59631 10.7647 8.82044 10.928 9.07929C11.0913 9.33814 11.202 9.62664 11.2537 9.9283C11.3054 10.23 11.2972 10.5389 11.2295 10.8373C11.1617 11.1358 11.0359 11.418 10.859 11.6678M7.6141 12.2204C7.36411 12.0438 7.15138 11.8197 6.98808 11.5608C6.82477 11.302 6.71408 11.0135 6.66234 10.7118C6.61061 10.4101 6.61884 10.1012 6.68656 9.80277C6.75429 9.5043 6.88018 9.2221 7.05704 8.97231M11.1989 7.15135C12.0392 7.74578 12.609 8.6496 12.783 9.66406C12.9569 10.6785 12.7209 11.7205 12.1267 12.561M6.7171 13.4888C5.87683 12.8943 5.30706 11.9905 5.13308 10.9761C4.95909 9.9616 5.19514 8.91957 5.78932 8.07913"
                  stroke="#556EE6"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Explore other Channel
            </a>
          </div>
          <div className={styles.mobileJewelryLiveChannelAllDetailsAlignment}>
            <div className={styles.mobileJewelryLiveChannelAllDetailsGrid}>
              {[0, 1, 2, 3].map((_, index) => {
                return (
                  <div className={styles.mobileJewelryLiveChannelAllDetailsGridItem} key={index}>
                    <div className={styles.jewelryLiveChnnelDetails}>
                      <div className={styles.jewelryLiveDetailsImgAlignment}>
                        <img src="/assets/shop/img/demo-img-2.png" alt="demo-img" />

                        <div className={styles.jewelryLiveDetailsBlurDetails}>
                          <div className={styles.jewelryLiveAndViewAlignment}>
                            <div className={styles.jewelryLiveBoxAlignment}>
                              <img src="/assets/shop/icons/live-icon.svg" alt="live" />
                              <p>Live</p>
                            </div>

                            <div className={styles.jewelryLiveNumberAlignment}>
                              <img src="/assets/shop/icons/eye.svg" alt="eyes" />
                              <p>9.642</p>
                            </div>
                          </div>

                          <div className={styles.jewelryLiveAndViewOtherDetailsALignment}>
                            <div className={styles.jewelryLiveAndViewOtherName}>
                              <h6>Live Adventures Await</h6>
                              <div className={styles.jewelryBrandNameAlignment}>
                                <div className={styles.jewelryBrandImg}>
                                  <img src="/assets/shop/img/demo-img-2.png" alt="demo-img" />
                                </div>
                                <p>NIKE Official</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <div className={styles.mobileJewelryLiveChannelDetailsAlignment}>
          <div className={styles.mobileJewelryLiveChannelHeadingAlignment}>
            <h6 className={styles.suggestionHeading}>Suggested Channel</h6>
          </div>
          <div className={styles.mobileJewelryLiveChannelAllDetailsAlignment}>
            <div className={styles.mobileJewelryLiveChannelAllDetailsGrid}>
              {[0, 1, 2, 3].map((_, index) => {
                return (
                  <div className={styles.mobileJewelryLiveChannelAllDetailsGridItem} key={index}>
                    <div className={styles.jewelryLiveChnnelDetails}>
                      <div className={styles.jewelryLiveDetailsImgAlignment}>
                        <img src="/assets/shop/img/demo-img-2.png" alt="demo-img" />

                        <div className={styles.jewelryLiveDetailsBlurDetails}>
                          <div className={styles.jewelryLiveAndViewAlignment}>
                            <div className={styles.jewelryLiveBoxAlignment}>
                              <img src="/assets/shop/icons/live-icon.svg" alt="live" />
                              <p>Live</p>
                            </div>

                            <div className={styles.jewelryLiveNumberAlignment}>
                              <img src="/assets/shop/icons/eye.svg" alt="eyes" />
                              <p>9.642</p>
                            </div>
                          </div>

                          <div className={styles.jewelryLiveAndViewOtherDetailsALignment}>
                            <div className={styles.jewelryLiveAndViewOtherName}>
                              <h6>Live Adventures Await</h6>
                              <div className={styles.jewelryBrandNameAlignment}>
                                <div className={styles.jewelryBrandImg}>
                                  <img src="/assets/shop/img/demo-img-2.png" alt="demo-img" />
                                </div>
                                <p>NIKE Official</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
