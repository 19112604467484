import { gql } from '@apollo/client'

const GET_SINGLE_COLLECTION = gql`
  query GetSingleCollection($slug: String!, $isPrivate: Boolean, $userSlug: String) {
    getSingleCollection(slug: $slug, isPrivate: $isPrivate, user_slug: $userSlug) {
      success
      message
      data {
        id
        name
        slug
        user {
          id
          firstName
          lastName
          logo_image
          userName
        }
        product_count
        product_images
        likes
        product_count
        isPrivate
      }
    }
  }
`
export default GET_SINGLE_COLLECTION
