// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react'
import GET_PRODUCT_BY_NAME from 'src/graphql/queries/getProductByName'
import CREATE_CART_MUTATION from 'src/graphql/mutations/createCart'
import { useMutation, useQuery } from '@apollo/client'
import TabSection from 'src/components/ProductDetail/TabSection'
import TabSectionForMobile from 'src/components/StoreAndProductDetailModal/ProductDetailModal/TabSectionForMobile'
import GET_ALL_PRODUCT_FOR_CHECKOUT from 'src/graphql/queries/getAllProductForCheckout'
import { notification } from 'antd'
import Loader from '../../UI/Loader'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import Slider from 'react-slick'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAuth } from 'src/utils/auth'
import GET_CURRENT_USER from 'src/graphql/queries/getCurrentUser'
import FOLLOW_STORE from 'src/graphql/mutations/followStore'
import GET_STORE_FOLLOW from 'src/graphql/queries/getStoreFollow'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
import UNFOLLOW_STORE from 'src/graphql/mutations/unFollowStore'
import GET_SINGLE_PRODUCT_LIKE from 'src/graphql/queries/getSingleProductLike'
import DELETE_LIKE_MUTATION from 'src/graphql/mutations/deleteLike'
import CREATE_LIKE_MUTATION from 'src/graphql/mutations/createLike'
import moment from 'moment'
import ADD_BOOKMARK from 'src/graphql/mutations/addBookmark'
import REMOVE_BOOKMARK from 'src/graphql/mutations/removeBookmark'
import Link from 'next/link'
import { fetchFollowedStoreData } from 'src/store/slices/fetchAllStoresSlice'
import { fetchCartData, getAllCartCountSliceData } from 'src/store/slices/getCartCount'
import client1 from 'src/utils/apolloClient'
import { useRouter } from 'next/router'
import { manageBookmark } from 'src/store/slices/manageBookmarkTab'
import GET_PRODUCTS_REVIEWS from 'src/graphql/queries/getProductReviewsBySlug'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" style={{ rotate: "180deg" }}>
        <circle opacity="0.6" cx="20" cy="20" r="20" transform="rotate(90 20 20)" fill="white" />
        <path d="M25 19L20 23L15 19" stroke="#1E2432" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <circle opacity="0.6" cx="20" cy="20" r="20" transform="rotate(-90 20 20)" fill="white" />
        <path d="M15 21L20 17L25 21" stroke="#1E2432" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  )
}

function SamplePreviewNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <circle opacity="0.6" cx="20" cy="20" r="20" transform="rotate(180 20 20)" fill="white" />
        <path d="M21 25L17 20L21 15" stroke="#1E2432" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
  )
}

function SamplePreviewPrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <circle opacity="0.6" cx="20" cy="20" r="20" transform="rotate(180 20 20)" fill="white" />
        <path d="M21 25L17 20L21 15" stroke="#1E2432" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
  )
}

const ProductDetailModal = (props) => {
  const { productId, product_data } = props

  const { user } = useAuth()
  const dispatch = useAppDispatch()
  const router = useRouter()
  let width

  if (typeof window !== 'undefined') {
    width = window.innerWidth
  }

  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const [isOpenPreviewModal, setIsopenPreviewModal] = useState(false)

  const [mobileViewModal, setMobileViewModal] = useState(false)
  const [imageIndex, setImageIndex] = useState(1)
  const [data, setData] = useState({ getSingleProduct: product_data })
  // const [loading, setLoading] = useState(false)
  const bookmarkTabData = useSelector((state: any) => state?.manageBookmarkTabsSlice?.data)

  const isMobile = width < 600

  const tabArray = {
    customerTraction: 1,
    comments: 2,
    gallery: 3,
    shipping: 4,
    rateAndReview: 5,
  }
  const [isDesktop, setIsDesktop] = useState(0)

  const handleResize = () => setIsDesktop(width)

  useEffect(() => {
    setIsDesktop(width)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  // const { loading, error, data, refetch } = useQuery(GET_PRODUCT_BY_NAME, {
  //   variables: { store_id: Number(productId) },
  // })

  const fetchProduct = async () => {
    try {
      // const { data, loading } = await client1.query({
      //   query: GET_PRODUCT_BY_NAME,
      //   variables: { slug: productId },
      // })
      // setLoading(loading)
      // setData(data)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    fetchProduct()
  }, [productId])

  const cartCount = useSelector((state: any) => state?.getCartCount?.cartCount)

  const isProductLike = useQuery(GET_SINGLE_PRODUCT_LIKE, {
    variables: { slug: productId },
  })

  const store_follow_status = useQuery(GET_STORE_FOLLOW, {
    variables: {
      storeId: data?.getSingleProduct?.store_id,
      product_Id: product_data?.id,
    },
  })

  useEffect(() => {
    store_follow_status.refetch()
  }, [])

  useEffect(() => {
    fetchProduct()
    isProductLike.refetch()
  }, [])

  const [openTraction, setOpenTraction] = useState(0)
  const [scrollY, setScrollY] = useState(0)
  const [productQty, setProductQty] = useState(1)
  const [ProductCheckOutModal, setProductCheckOutModal] = useState(false)
  const [productDataForCheckout, setProductDataForCheckout] = useState([])

  const [totalReviews, setTotalReviews] = useState(0);
  const [overallRating, setOverallRating] = useState(0);

  const getProductReview = async () => {
    try {

      const { data } = await client1.query({
        query: GET_PRODUCTS_REVIEWS,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            order: "New to old",
            rating: 4,
            slug: productId,
          }
        },
      });
      setTotalReviews(data?.getProductReviewsBySlug?.data?.userReviews?.length);

      const overallAverageRating = data?.getProductReviewsBySlug?.data?.userReviews?.length
        ? data?.getProductReviewsBySlug?.data?.userReviews?.reduce((acc, curr) => acc + curr.overAllRating, 0) / data?.getProductReviewsBySlug?.data?.userReviews?.length
        : 0;

      setOverallRating(overallAverageRating); // Fix here
    } catch (error) {
      console.error('Error fetching product reviews:', error);
    }
  };

  useEffect(() => {
    getProductReview();
  }, [productId]);

  useEffect(() => {
    if (addProductDetailSlice?.id?.productDetails?.productDetailVariant?.inventory_quantity <= 0) {
      setProductQty(0)
    }
  }, [addProductDetailSlice])

  const [productMobileTab, setProductMobileTab] = useState(1)
  const [viewIndex, SetViewIndex] = useState(1)

  const sliderRef: any = useRef()
  const tabSection = useRef(null)

  function logit() {
    setScrollY(window.pageYOffset)
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', logit)
    }
    watchScroll()
    return () => {
      window.removeEventListener('scroll', logit)
    }
  })

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (mobileViewModal && sliderRef.current && !sliderRef.current.contains(e.target)) {
        setMobileViewModal(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [mobileViewModal])

  const [createCart] = useMutation(CREATE_CART_MUTATION, {
    onCompleted: (res) => {
      dispatch(getAllCartCountSliceData())
      dispatch(fetchCartData())
      // notification['success']({
      //   message: 'Product added successfully',
      //   description: 'Your product has been added successfully',
      // })
      // getCartDetail()
    },
    onError: (error) => {
      notification['error']({
        message: 'Error',
        description: 'Sorry, you can only purchase allowable quantity of this product',
      })
    },
  })

  useEffect(() => {
    if (data?.getSingleProduct?.quantity > 0) {
      setProductQty(1)
    }
  }, [data])

  const addToCart = () => {
    // createCart({
    //   variables: { parent_id: name, quantity: productQty, cart_for: 'PRODUCT' },
    // })
    if (user) {
      if (addProductDetailSlice?.id?.productDetails?.productDetailVariant?.inventory_quantity > 0) {
        createCart({
          variables: {
            input: {
              parent_id: data?.getSingleProduct.id,
              variant_id: addProductDetailSlice?.id?.productDetails?.productDetailVariant?.id,
              quantity: productQty,
              cart_for: 'PRODUCT',
            },
          },
          refetchQueries: [{ query: GET_CURRENT_USER }],
        })
      }
    } else {
      handleRedirect()
    }
  }

  const [getAllProductForCheckout] = useMutation(GET_ALL_PRODUCT_FOR_CHECKOUT, {
    onCompleted: () => {
      // notification['success']({
      //   message: 'Order placed successfully',
      //   description: 'Your order has been placed successfully',
      // })
    },
    onError: (error) => {
      notification['error']({
        message: 'Error',
        description: 'Sorry, something went wrong',
      })
    },
  })

  useEffect(() => {
    if (data?.getSingleProduct?.variants?.length > 0) {
      const defaultOptions: { [key: string]: string } = {}

      data?.getSingleProduct?.variants[0].total_variant.forEach((variantOption, index) => {
        const optionName = data.getSingleProduct.options[index]?.name
        defaultOptions[optionName] = variantOption.variant_option.value
      })

      const finalData = data?.getSingleProduct?.variants.find((variant) => {
        return Object.entries(defaultOptions).every(([key, value]) => {
          return variant.total_variant.some((option) => option.variant_option.value === value)
        })
      })

      const colorCode = data?.getSingleProduct?.options
        ?.find((option) => option.name === 'color')
        ?.data.find((color) => color.value === defaultOptions?.color)?.colorCode

      // setColorCode(colorCode)
      // setSelectedVariant(finalData)
      // setSelectedOptions(defaultOptions)

      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            productDetails: {
              ...addProductDetailSlice?.id?.productDetails,
              productDetailVariant: finalData,
              productSelectedOption: defaultOptions,
              productDetailColor: colorCode,
            },
          },
        }),
      )
    } else {
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            productDetails: {
              ...addProductDetailSlice?.id?.productDetails,
              productDetailVariant: {
                ...data?.getSingleProduct?.inventoryPrice,
                inventory_quantity: data?.getSingleProduct?.inventoryPrice?.quantity,
              },
            },
          },
        }),
      )
    }
  }, [data?.getSingleProduct])

  const handleOptionSelect = (optionName, optionValue, colorCode) => {
    // setSelectedOptions((prevSelectedOptions) => ({
    //   ...prevSelectedOptions,
    //   [optionName]: optionValue,
    // }))

    // optionName === 'color' && setColorCode(colorCode)

    const updatedSelectedOptions = {
      ...addProductDetailSlice?.id?.productDetails?.productSelectedOption,
      [optionName]: optionValue,
    }

    const finalData = data?.getSingleProduct?.variants.find((variant) => {
      return Object.entries(updatedSelectedOptions).every(([key, value]) => {
        return variant.total_variant.some((option) => option.variant_option.value === value)
      })
    })

    // setSelectedVariant(finalData)

    dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          productDetails: {
            ...addProductDetailSlice?.id?.productDetails,
            productDetailVariant: finalData,
            productSelectedOption: updatedSelectedOptions,
            productDetailColor:
              optionName === 'color' ? colorCode : addProductDetailSlice?.id?.productDetails?.productDetailColor,
          },
        },
      }),
    )
  }

  const handleShareData = (prod) => {
    if (user) {
      dispatch(
        fetchProductImageResizeModal({
          modal: 'mobileProductDetailsShareModal',
          isOpen: true,
          price:
            data?.getSingleProduct.variants?.length > 0
              ? addProductDetailSlice?.id?.productDetails?.productDetailVariant?.price
              : data?.getSingleProduct?.inventoryPrice?.price,
          dis_price:
            data?.getSingleProduct.variants?.length > 0
              ? addProductDetailSlice?.id?.productDetails?.productDetailVariant?.listPrice
              : data?.getSingleProduct?.inventoryPrice?.listPrice,
          shareData: product_data,
        }),
      )
    } else {
      handleRedirect()
    }
  }

  // const checkoutSingleItem = () => {
  //   if (user) {
  //     getAllProductForCheckout({
  //       variables: {
  //         products: [{ variant_id: selectedVariant?.id, quantity: productQty }],
  //         product_id: data?.getSingleProduct.id,
  //       },
  //     }).then((refetch: any) => {
  //       if (refetch.data !== undefined) {
  //         setProductCheckOutModal(true)
  //         setProductDataForCheckout(refetch)
  //         dispatch(
  //           fetchModalData({
  //             allModalData: [
  //               ...getAllState?.allModalData,
  //               { modal: 'checkout', isOpen: true, id: refetch, modalFor: 'bigModal' },
  //             ],
  //             lastModalData: getAllState?.lastModalData,
  //           }),
  //         )
  //       }
  //     })
  //   } else {
  //     dispatch(
  //       guestAccountDetailSlice({
  //         modal: 'authModal',
  //         isOpen: true,
  //         id: {
  //           media:
  //             'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
  //           modalFor: 'login',
  //           startWith: 'loginModal',
  //         },
  //       }),
  //     )
  //   }
  // }

  const settings = {
    customPaging: function (i) {
      let dots = addProductDetailSlice?.id?.productDetails?.productDetailVariant?.image
        ? [
          { src: addProductDetailSlice?.id?.productDetails?.productDetailVariant?.image?.media },
          ...data?.getSingleProduct?.images,
        ]
        : data?.getSingleProduct?.images
      return (
        <a>
          <div className="new-produtc-img-alignment-dots" key={i}>
            <div
              className="poroduct-image-background"
              style={{
                backgroundImage: `url(${dots[i]?.src})`,
              }}
            ></div>
          </div>
        </a>
      )
    },
    dotsClass: 'slick-dots slick-thumb',
    // dots: true,
    // infinite: true,
    // // slidesToShow: 1.01,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    // vertical: true,
    // verticalSwiping: true,

    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    centerPadding: '0px',
    // beforeChange: function (currentSlide, nextSlide) {},
    // afterChange: function (currentSlide) {
    //   setImageIndex(currentSlide + 1)
    // },
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    afterChange: function (currentSlide) {
      setCurrentImageIndex(currentSlide);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          // vertical: false,
          // verticalSwiping: false,
        },
      },
    ],
  }

  const previewModalSetting = {
    customPaging: function (i) {
      let dots = addProductDetailSlice?.id?.productDetails?.productDetailVariant?.image
        ? [
          { src: addProductDetailSlice?.id?.productDetails?.productDetailVariant?.image?.media },
          ...data?.getSingleProduct?.images,
        ]
        : data?.getSingleProduct?.images
      return (
        <a>
          <img src={dots[i]?.src} style={{ objectFit: 'cover' }} />
        </a>
      )
    },
    dotsClass: 'slick-dots slick-thumb',
    // dots: true,
    // infinite: true,
    // // slidesToShow: 1.01,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    // vertical: true,
    // verticalSwiping: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    verticalSwiping: true,
    swipeToSlide: true,
    centerPadding: '0px',
    // beforeChange: function (currentSlide, nextSlide) {},
    // afterChange: function (currentSlide) {
    //   setImageIndex(currentSlide + 1)
    // },
    prevArrow: <SamplePreviewPrevArrow />,
    nextArrow: <SamplePreviewNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          // vertical: false,
          // verticalSwiping: false,
        },
      },
    ],
  }

  const checkoutSingleItem = () => {
    if (user) {
      if (addProductDetailSlice?.id?.productDetails?.productDetailVariant?.inventory_quantity > 0) {
        getAllProductForCheckout({
          variables: {
            products: [
              {
                variant_id: addProductDetailSlice?.id?.productDetails?.productDetailVariant?.id,
                quantity: productQty,
                product_id: data?.getSingleProduct?.id,
              },
            ],
            // productId: data?.getSingleProduct.id,
          },
        }).then((refetch: any) => {
          if (refetch.data !== undefined) {
            setProductCheckOutModal(true)
            setProductDataForCheckout(refetch)
            dispatch(
              fetchModalData({
                allModalData: [
                  ...getAllState?.allModalData,
                  { modal: 'checkout', isOpen: true, id: refetch, modalFor: 'bigModal' },
                ],
                lastModalData: getAllState?.lastModalData,
              }),
            )

            dispatch(
              manageAddProductDetail({
                modal: '',
                isOpen: false,
                id: {
                  ...addProductDetailSlice?.id,
                  productDetails: {
                    ...addProductDetailSlice?.id?.productDetails,
                    productDetailVariant: {},
                    productSelectedOption: {},
                    productDetailColor: '',
                  },
                },
              }),
            )
          }
        })
      }
    } else {
      dispatch(
        guestAccountDetailSlice({
          modal: 'authModal',
          isOpen: true,
          id: {
            media:
              'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
            modalFor: 'login',
            startWith: 'loginModal',
          },
        }),
      )
    }
  }

  const mobileSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    dots: true,
    vertical: false,
    verticalSwiping: false,
  }

  const [followStore] = useMutation(FOLLOW_STORE, {
    onCompleted: (res) => {
      // notification['success']({
      //   message: ' Followed successfully',
      //   description: 'You have been followed successfully',
      // })
      dispatch(fetchFollowedStoreData({ limit: 10, page: 1, type: 'JOIN_STORES' }))
    },
    onError: (err) => {
      notification['error']({
        message: 'Error',
        description: 'Something went wrong',
      })
    },
  })

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const handleCloseProductModel = () => {
    router.back()
    // let filtredModal = getAllState?.allModalData?.filter((item) => item?.modal !== getAllState?.lastModalData?.modal)
    // dispatch(
    //   fetchModalData({
    //     ...getAllState,
    //     allModalData: filtredModal,
    //     lastModalData: getAllState?.lastModalData,
    //   }),
    // )
  }

  const [unfollowStore] = useMutation(UNFOLLOW_STORE, {
    onCompleted: (res) => {
      // notification['success']({
      //   message: ' Unfollowed successfully',
      //   description: 'You have been unfollowed successfully',
      // })
      dispatch(fetchFollowedStoreData({ limit: 10, page: 1, type: 'JOIN_STORES' }))
    },
    onError: (err) => {
      notification['error']({
        message: 'Error',
        description: 'Something went wrong',
      })
    },
  })

  const handleFollow = () => {
    if (user) {
      followStore({
        variables: { store_id: data?.getSingleProduct?.store_id },
        refetchQueries: refetchQueries,
      })
    } else {
      handleRedirect()
    }
  }

  const handleUnFollow = () => {
    unfollowStore({
      variables: { storeId: data?.getSingleProduct?.store_id },
      refetchQueries: refetchQueries,
    })
  }

  const refetchQueries = () => {
    return [
      {
        query: GET_STORE_FOLLOW,
        variables: {
          storeId: data?.getSingleProduct?.store_id,
        },
      },
      // {
      //   query: GET_ALL_STORE,
      //   variables: {
      //     limit: limit,
      //     page: page,
      //     type: "JOINED",
      //   },
      // },
    ]
  }

  const handleBookMarkModel = () => {
    if (user) {
      if (store_follow_status?.data?.getStoreFollow?.bookmark_id) {
        removeBookmark({
          variables: {
            id: store_follow_status?.data?.getStoreFollow?.bookmark_id,
          },
        })
      } else {
        dispatch(
          manageBookmark({
            modal: '',
            isOpen: true,
            id: {
              ...bookmarkTabData?.id,
              type: 'product',
              store_id: data?.getSingleProduct?.store_id,
              bookmark_id: store_follow_status?.data?.getStoreFollow?.bookmark_id,
              collection_id: store_follow_status?.data?.getStoreFollow?.collection_id,
              product_id: Number(product_data?.id),
              addProductToCollection: {
                ...bookmarkTabData?.id?.deleteCollection,
                isShowAddCollectionModel: true,
                productId: Number(product_data?.id),
              },
            },
          }),
        )
      }
    } else {
      handleRedirect()
    }
  }

  const [addBookmark] = useMutation(ADD_BOOKMARK, {
    onCompleted: (res) => {
      store_follow_status.refetch()
      fetchProduct()
      if (isMobile) {
        dispatch(
          fetchMobileModalData({
            allModalData: [
              ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
              {
                modal: 'mobileViewBookMarkModal',
                isOpen: true,
                id: { isSeller: false, group: null, type: 'post' },
                data: {
                  id: data?.getSingleProduct,
                },
                transform: 360,
              },
            ],
            lastModalData: fetchMobileModal?.lastModalData,
          }),
        )
      } else {
        dispatch(
          fetchProductImageResizeModal({
            modal: 'mobileViewBookMarkModal',
            isOpen: true,
            data: data?.getSingleProduct,
          }),
        )
      }
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const [removeBookmark] = useMutation(REMOVE_BOOKMARK, {
    onCompleted: (res) => {
      store_follow_status.refetch()
      fetchProduct()
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const handleCommentModel = () => {
    setProductMobileTab(6)
    SetViewIndex(3)
    tabSection.current.scrollIntoView({ top: 200, screenLeft: 200, behavior: 'smooth' })
  }

  // const liked = data?.getSingleProduct?.likes?.find((like) => like?.user?.id === user?.id)
  const normalPostLikeHandler = () => {
    if (user) {
      let likeFor = 'PRODUCT'
      if (isProductLike?.data?.getProductSingleLike?.isLike) {
        deleteLike({
          variables: { id: isProductLike?.data?.getProductSingleLike?.like_id, like_for: likeFor },
          refetchQueries: [{ query: GET_SINGLE_PRODUCT_LIKE, variables: { slug: productId } }],
        })
        return
      }
      createLike({
        variables: { parent_id: data?.getSingleProduct?.id, like_for: likeFor },
        refetchQueries: [{ query: GET_SINGLE_PRODUCT_LIKE, variables: { slug: productId } }],
      })
    } else {
      handleRedirect()
    }
  }

  const [deleteLike] = useMutation(DELETE_LIKE_MUTATION, {
    onError: (error) => {
      console.log(error)
    },
  })

  const [createLike] = useMutation(CREATE_LIKE_MUTATION, {
    onError: (error) => {
      console.log(error)
    },
  })

  
  const handelImageSlider = () => { }

  const handleOpenStore = (store: any) => {
    dispatch(
      fetchModalData({
        allModalData: [
          ...getAllState?.allModalData,
          {
            modal: 'store',
            isOpen: true,
            id: {
              store: {
                name: store?.name,
                id: store?.id,
                slug: store?.slug,
              },
            },
            modalFor: 'bigModal',
            startDate: moment().toISOString(),
          },
        ],
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  const handlePreview = () => {
    setIsopenPreviewModal(true)
  }

  const handleClose = () => {
    setIsopenPreviewModal(false)
  }

  const updateQuantity = (type) => {
    if (type === '+') {
      if (addProductDetailSlice?.id?.productDetails?.productDetailVariant?.inventory_quantity > 0) {
        if (addProductDetailSlice?.id?.productDetails?.productDetailVariant?.inventory_quantity === productQty) {
        } else {
          setProductQty(productQty + 1)
        }
      } else if (addProductDetailSlice?.id?.productDetails?.productDetailVariant?.inventory_quantity < 0) {
        setProductQty(0)
      } else {
      }
    } else {
      if (addProductDetailSlice?.id?.productDetails?.productDetailVariant?.inventory_quantity > 0) {
        if (productQty === 1) {
          setProductQty(1)
        } else {
          setProductQty(productQty - 1)
        }
      } else {
        setProductQty(0)
      }
    }
  }

  // if (loading) return <Loader />
  // if (error) return <h1>Error! {error.message}</h1>

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const saveScrollPosition = () => {
      setScrollPosition(window.scrollY);
    };

    const restoreScrollPosition = () => {
      window.scrollTo(0, scrollPosition);
    };

    router.events.on('routeChangeStart', saveScrollPosition);
    router.events.on('routeChangeComplete', restoreScrollPosition);

    return () => {
      router.events.off('routeChangeStart', saveScrollPosition);
      router.events.off('routeChangeComplete', restoreScrollPosition);
    };
  }, [scrollPosition, router.events]);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  return (
    <>
      <div>
        <div>
          <div
            style={openTraction > 0 ? { overflow: 'hidden', height: '100vh' } : {}}
            className="transition-main-container"
          >
            <div className="product-detail-wrapper">
              {/* <div className="product-detail-secondary">
                <ProductImageSectionMobile product={data?.getSingleProduct} openTraction={openTraction} />

                <div
                  className={
                    scrollY >= 100
                      ? 'product-deatil-container modal-show-area'
                      : 'product-deatil-container modal-close-area'
                  }
                >
                  <div className="border-raduis-8">
                    <div className="product-main-template ">
                      <ProductImageSection product={data?.getSingleProduct} />
                      <ProductDetailSection
                        product={data?.getSingleProduct}
                        setProductQty={setProductQty}
                        productQty={productQty}
                      />
                    </div>
                  </div>
                  <TabSectionForMobile
                    getSingleProduct={data?.getSingleProduct}
                    setOpenTraction={setOpenTraction}
                    openTraction={openTraction}
                    product={{ id: data?.getSingleProduct?.id, comment_count: data?.getSingleProduct?.comment_count }}
                    tabArray={tabArray}
                  />
                </div>
                <ProductCheckoutSection
                  product={data?.getSingleProduct}
                  productQty={productQty}
                  setProductQty={setProductQty}
                  setProductCheckOutModal={setProductCheckOutModal}
                  setProductDataForCheckout={setProductDataForCheckout}
                />
              </div> */}

              <div className="new-product-details-secondary-section">
                <div className="back-btn-left-alignment" onClick={() => router.back()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M16 19L9 12L16 5"
                      stroke="#7A7E88"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                {/* <div className="back-btn-left-alignment-mobile" onClick={() => router.back()}>
                  <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 1L1 7L6 13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div> */}
                <div className="new-product-left-side-alignment">
                  <div className="new-product-details-slider-box">
                    <div className="new-product-webView">
                      <Slider {...settings}>
                        {/* {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(() => { */}
                        {/* return ( */}
                        {/* {selectedVariant?.image?.media && <img src={selectedVariant?.image?.media} />} */}
                        {
                          data?.getSingleProduct?.images?.length > 0 &&
                          (addProductDetailSlice?.id?.productDetails?.productDetailVariant?.image?.media
                            ? [
                              {
                                src: addProductDetailSlice?.id?.productDetails?.productDetailVariant?.image?.media,
                              },
                              ...data?.getSingleProduct?.images,
                            ]
                            : data?.getSingleProduct?.images
                          )?.map((item, index) => {
                            return (
                              <div className="new-produtc-img-alignment" key={index} onClick={handlePreview}>
                                {/* <img src={item?.src} /> */}
                                <div
                                  className="poroduct-image-background"
                                  style={{
                                    backgroundImage: `url(${item?.src})`,
                                  }}
                                ></div>
                                {/* <LazyImage /> */}
                                {/* <LazyImage
                                    image={{ src: item?.src, alt: 'Icon' }}
                                    objectFit={'cover'}
                                    handleClickOnImage={() => handelImageSlider()}
                                    // className={'new-produtc-img-alignment-image'}
                                    // height={isDesktop > 1024 ? '100%' : 140}
                                    // imageClassName={'post-loaded-radious'}
                                  /> */}
                              </div>
                            )
                          })
                          // <div className="new-produtc-img-alignment">
                          //   <img src="/assets/img/demo-img-product.png" />
                          // </div>
                          // )
                        }
                        {/* })} */}
                      </Slider>
                    </div>

                    <div className="new-product-mobileView" onClick={() => setMobileViewModal(true)}>
                      <Slider {...mobileSettings}>
                        {data?.getSingleProduct?.images?.length > 0 &&
                          (addProductDetailSlice?.id?.productDetails?.productDetailVariant?.image?.media
                            ? [
                              { src: addProductDetailSlice?.id?.productDetails?.productDetailVariant?.image?.media },
                              ...data?.getSingleProduct?.images,
                            ]
                            : data?.getSingleProduct?.images
                          ).map((item, index) => {
                            return (
                              <div className="new-produtc-img-alignment" key={index}>
                                <img src={item?.src ?? '/assets/img/demo-img-product.png'} />
                              </div>
                            )
                          })}
                      </Slider>
                    </div>

                    <div className="new-product-details-slider-all-details">
                      <div onClick={() => handleCloseProductModel()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                          <path
                            opacity="0.6"
                            d="M34 6H10C4.47715 6 0 10.4772 0 16V28C0 33.5228 4.47715 38 10 38H34C39.5228 38 44 33.5228 44 28V16C44 10.4772 39.5228 6 34 6Z"
                            fill="#1E2432"
                          />
                          <path
                            d="M24 16L19 22L24 28"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="new-product-slider-right-side-alignment">
                        <div className="slider-banner-cartbox-alignment">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M6 5H3V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V5H18H6Z"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M16 9C16 10.0609 15.5786 11.0783 14.8284 11.8284C14.0783 12.5786 13.0609 13 12 13C10.9391 13 9.92172 12.5786 9.17157 11.8284C8.42143 11.0783 8 10.0609 8 9"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8 5C8 3.93913 8.42143 2.92172 9.17157 2.17157C9.92172 1.42143 10.9391 1 12 1C13.0609 1 14.0783 1.42143 14.8284 2.17157C15.5786 2.92172 16 3.93913 16 5"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>

                          <div className="cart-number-alignment">
                            <div className="cart-number-box">
                              <p>{cartCount || 0}</p>
                            </div>
                          </div>
                        </div>

                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                          <path
                            opacity="0.6"
                            d="M34 6H10C4.47715 6 0 10.4772 0 16V28C0 33.5228 4.47715 38 10 38H34C39.5228 38 44 33.5228 44 28V16C44 10.4772 39.5228 6 34 6Z"
                            fill="#1E2432"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15 25.1875C13.8954 25.1875 13 24.2361 13 23.0625C13 21.8889 13.8954 20.9375 15 20.9375C16.1046 20.9375 17 21.8889 17 23.0625C17 24.2361 16.1046 25.1875 15 25.1875ZM22 25.1875C20.8954 25.1875 20 24.2361 20 23.0625C20 21.8889 20.8954 20.9375 22 20.9375C23.1046 20.9375 24 21.8889 24 23.0625C24 24.2361 23.1046 25.1875 22 25.1875ZM27 23.0625C27 24.2361 27.8954 25.1875 29 25.1875C30.1046 25.1875 31 24.2361 31 23.0625C31 21.8889 30.1046 20.9375 29 20.9375C27.8954 20.9375 27 21.8889 27 23.0625Z"
                            fill="white"
                          />
                        </svg> */}
                      </div>
                    </div>
                  </div>
                  <div className="new-product-side-details-alignment">
                    <div className="top-img-details-alignment">
                      <div className="number-img">
                        <h4>
                          <span>{currentImageIndex + 1}</span> of{' '}
                          {addProductDetailSlice?.id?.productDetails?.productDetailVariant?.image?.media
                            ? [
                              { src: addProductDetailSlice?.id?.productDetails?.productDetailVariant?.image?.media },
                              ...data?.getSingleProduct?.images,
                            ]?.length
                            : data?.getSingleProduct?.images?.length}
                        </h4>
                      </div>
                    </div>

                    <div className="product-bottom-details-alignment">
                      <div className="bottom-option-alignment ">
                        <div className="webViewBookMark" onClick={() => handleBookMarkModel()}>
                          {store_follow_status?.data?.getStoreFollow?.isBookmark ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="#556EE6"
                            >
                              <path
                                d={
                                  'M19 21L12 16L5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21Z'
                                }
                                stroke="#556EE6"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d={
                                  'M14 3H7C6.46957 3 5.96086 3.21071 5.58579 3.58579C5.21071 3.96086 5 4.46957 5 5V21L12 16L19 21V8'
                                }
                                stroke="#7A7E88"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path d="M19 1L19 5" stroke="#7A7E88" strokeWidth="1.5" strokeLinecap="round" />
                              <path d="M21 3L17 3" stroke="#7A7E88" strokeWidth="1.5" strokeLinecap="round" />
                            </svg>
                          )}
                        </div>
                        <div
                          className=" mobileViewBookmark"
                          onClick={() => {
                            if (user) {
                              if (store_follow_status?.data?.getStoreFollow?.bookmark_id) {
                                removeBookmark({
                                  variables: {
                                    id: store_follow_status?.data?.getStoreFollow?.bookmark_id,
                                  },
                                })
                              } else {
                                dispatch(
                                  manageBookmark({
                                    modal: '',
                                    isOpen: false,
                                    id: {
                                      ...bookmarkTabData?.id,
                                      type: 'product',
                                      store_id: data?.getSingleProduct?.store_id,
                                      bookmark_id: store_follow_status?.data?.getStoreFollow?.bookmark_id,
                                      collection_id: store_follow_status?.data?.getStoreFollow?.collection_id,
                                      product_id: Number(product_data?.id),
                                      addProductToCollection: {
                                        ...bookmarkTabData?.id?.deleteCollection,
                                        isShowAddCollectionModel: true,
                                        productId: Number(product_data?.id),
                                      },
                                    },
                                  }),
                                )

                                dispatch(
                                  fetchMobileModalData({
                                    allModalData: [
                                      ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                                      {
                                        modal: 'bookmarkProductModal',
                                        isOpen: true,
                                        id: {
                                          isSeller: false,
                                          group: null,
                                          type: 'post',
                                          price:
                                            data?.getSingleProduct.variants?.length > 0
                                              ? addProductDetailSlice?.id?.productDetails?.productDetailVariant?.price
                                              : data?.getSingleProduct?.inventoryPrice?.price,
                                          dis_price:
                                            data?.getSingleProduct.variants?.length > 0
                                              ? addProductDetailSlice?.id?.productDetails?.productDetailVariant
                                                ?.listPrice
                                              : data?.getSingleProduct?.inventoryPrice?.listPrice,
                                        },
                                        transform: 305,
                                      },
                                    ],
                                    lastModalData: fetchMobileModal?.lastModalData,
                                  }),
                                )
                              }
                            } else {
                              handleRedirect()
                            }
                            // handleBookMarkModel()
                            // dispatch(
                            // fetchMobileModalData({
                            //   allModalData: [
                            //     ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                            //     {
                            //       modal: 'mobileViewBookMarkModal',
                            //       isOpen: true,
                            //       id: { isSeller: false, group: null, type: 'post' },
                            //       transform: 360,
                            //     },
                            //   ],
                            //   lastModalData: fetchMobileModal?.lastModalData,
                            // }),
                            // )
                          }}
                        >
                          {store_follow_status?.data?.getStoreFollow?.isBookmark ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="#556EE6"
                            >
                              <path
                                d={
                                  'M19 21L12 16L5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21Z'
                                }
                                stroke="#556EE6"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M19 21L12 16L5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21Z"
                                stroke="#7A7E88"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                      <div
                        className="bottom-option-alignment"
                        onClick={() => {
                          handleCommentModel()
                        }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z"
                            stroke="#7A7E88"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="bottom-option-alignment">
                        <div className="webViewBookMark" onClick={() => handleShareData(data)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z"
                              stroke="#7A7E88"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
                              stroke="#7A7E88"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z"
                              stroke="#7A7E88"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8.58984 13.5098L15.4198 17.4898"
                              stroke="#7A7E88"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15.4098 6.50977L8.58984 10.4898"
                              stroke="#7A7E88"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>

                        <div
                          className=" mobileViewBookmark"
                          onClick={() => {
                            if (user) {
                              dispatch(
                                fetchMobileModalData({
                                  allModalData: [
                                    ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                                    {
                                      modal: 'mobileProductDetailsShareModal',
                                      isOpen: true,
                                      id: {
                                        isSeller: false,
                                        group: null,
                                        type: 'post',
                                        price:
                                          data?.getSingleProduct.variants?.length > 0
                                            ? addProductDetailSlice?.id?.productDetails?.productDetailVariant?.price
                                            : data?.getSingleProduct?.inventoryPrice?.price,
                                        dis_price:
                                          data?.getSingleProduct.variants?.length > 0
                                            ? addProductDetailSlice?.id?.productDetails?.productDetailVariant?.listPrice
                                            : data?.getSingleProduct?.inventoryPrice?.listPrice,
                                      },
                                      transform: 305,
                                    },
                                  ],
                                  lastModalData: fetchMobileModal?.lastModalData,
                                }),
                              )
                            } else {
                              handleRedirect()
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z"
                              stroke="#7A7E88"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
                              stroke="#7A7E88"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z"
                              stroke="#7A7E88"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8.58984 13.5098L15.4198 17.4898"
                              stroke="#7A7E88"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15.4098 6.50977L8.58984 10.4898"
                              stroke="#7A7E88"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="bottom-option-alignment" onClick={() => normalPostLikeHandler()}>
                        {' '}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill={isProductLike?.data?.getProductSingleLike?.isLike ? '#FF5733' : 'none'}
                        >
                          <path
                            d="M7.5 4C4.46244 4 2 6.43905 2 9.44773C2 14.8955 8.5 19.848 12 21C15.5 19.848 22 14.8955 22 9.44773C22 6.43905 19.5375 4 16.5 4C14.6399 4 12.9954 4.91467 12 6.31469C11.0046 4.91467 9.36015 4 7.5 4Z"
                            stroke={isProductLike?.data?.getProductSingleLike?.isLike ? '#FF5733' : '#7A7E88'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="new-product-right-side-alignment">
                  <div className="new-product-top-details-alignment">
                    <div className="new-product-details-heading-alignment">
                      <h6>{data?.getSingleProduct?.title}</h6>

                      <div className="more-option-icon" onClick={() => handleShareData(data)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="4" viewBox="0 0 18 4" fill="none">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2C4 3.10457 3.10457 4 2 4ZM9 4C7.89543 4 7 3.10457 7 2C7 0.89543 7.89543 0 9 0C10.1046 0 11 0.89543 11 2C11 3.10457 10.1046 4 9 4ZM14 2C14 3.10457 14.8954 4 16 4C17.1046 4 18 3.10457 18 2C18 0.89543 17.1046 0 16 0C14.8954 0 14 0.89543 14 2Z"
                            fill="#979797"
                          />
                        </svg>
                      </div>
                    </div>

                    <div className="new-product-bootm-details-alignment">
                      <div className="other-details-flex-alignment">
                        <div className="other-price-alignment">
                          <h6>
                            $
                            {data?.getSingleProduct?.variants?.length > 0
                              ? addProductDetailSlice?.id?.productDetails?.productDetailVariant?.price
                              : data?.getSingleProduct?.inventoryPrice?.price}
                          </h6>
                          {addProductDetailSlice?.id?.productDetails?.productDetailVariant?.listPrice ||
                            (data?.getSingleProduct?.inventoryPrice?.listPrice && (
                              <span>
                                $
                                {data?.getSingleProduct.variants?.length > 0
                                  ? addProductDetailSlice?.id?.productDetails?.productDetailVariant?.listPrice
                                  : data?.getSingleProduct?.inventoryPrice?.listPrice}
                              </span>
                            ))}
                        </div>
                        {/* <div className="off-box-alignment">
                          <p>40% OFF</p>
                        </div> */}
                      </div>
                      <div className="rating-flex-alignment">
                        <div>
                          <h6>{overallRating?.toFixed(1)}</h6>
                        </div>
                        <div className="rating-alignment">
                          {[0, 1, 2, 3, 4].map((item, index) => {
                            return (
                              <div key={index}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="19"
                                  height="19"
                                  viewBox="0 0 19 19"
                                  fill="none"
                                >
                                  <path
                                    d="M8.28633 3.38183C8.78647 2.38155 10.2139 2.38155 10.7141 3.38183L12.0254 6.00446C12.2283 6.41032 12.6206 6.68791 13.0709 6.74419L15.4851 7.04597C16.5704 7.18163 17.0576 8.4796 16.3297 9.2959L14.5701 11.2691C14.2958 11.5766 14.1761 11.9921 14.2448 12.3984L14.7551 15.4191C14.94 16.5141 13.8032 17.3556 12.8099 16.859L10.1071 15.5076C9.72506 15.3165 9.27534 15.3165 8.89326 15.5076L6.1948 16.8568C5.20068 17.3539 4.06327 16.5106 4.25001 15.4149L4.76379 12.4004C4.83324 11.993 4.71321 11.5761 4.43774 11.2679L2.67554 9.29665C1.94611 8.48067 2.43298 7.18125 3.51901 7.0455L5.92948 6.74419C6.37974 6.68791 6.77209 6.41032 6.97502 6.00446L8.28633 3.38183Z"
                                    fill="#FF7D23"
                                  />
                                </svg>
                              </div>
                            )
                          })}
                        </div>
                        <div>
                          <span>({totalReviews})</span>
                        </div>
                      </div>
                      <div className="follow-store-alignemnt">
                        <div
                          className="soldby-text-alignment"
                        // onClick={() => handleOpenStore(data?.getSingleProduct?.store)}
                        >
                          <Link href={`/store/${data?.getSingleProduct?.store?.slug}`}>
                            <h6>
                              {' '}
                              Sold By <span>{data?.getSingleProduct?.store?.name}</span>
                            </h6>
                          </Link>
                          <div className="soldby-text">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="11"
                              viewBox="0 0 12 11"
                              fill="none"
                            >
                              <path
                                d="M5.14817 0.703658C5.49914 0.00171185 6.50086 0.00171208 6.85183 0.703658L7.77206 2.54412C7.91446 2.82893 8.18979 3.02372 8.50576 3.06322L10.2 3.275C10.9616 3.3702 11.3035 4.28105 10.7926 4.85388L9.55781 6.23857C9.36535 6.45439 9.28137 6.74595 9.32953 7.03108L9.68763 9.15086C9.81744 9.91929 9.01969 10.5098 8.32264 10.1613L6.42591 9.21296C6.1578 9.0789 5.84221 9.0789 5.57409 9.21296L3.68041 10.1598C2.98279 10.5086 2.18461 9.91683 2.31566 9.14795L2.67621 7.03251C2.72494 6.74658 2.64072 6.45402 2.4474 6.23777L1.21076 4.85441C0.69888 4.2818 1.04054 3.36993 1.80266 3.27467L3.49424 3.06322C3.81021 3.02372 4.08554 2.82893 4.22794 2.54412L5.14817 0.703658Z"
                                fill="#7A7E88"
                              />
                            </svg>
                            <span> {overallRating?.toFixed(1)}</span>
                          </div>
                        </div>

                        {store_follow_status?.data?.getStoreFollow?.isFollow ? (
                          <a
                          // onClick={handleUnFollow}
                          >
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="10"
                                viewBox="0 0 13 10"
                                fill="none"
                              >
                                <path
                                  d="M1 4.5L5 8.5L12 1"
                                  stroke="#556EE6"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <p>Followed</p>
                          </a>
                        ) : (
                          <a onClick={handleFollow}>
                            <p>+ Follow Store</p>
                          </a>
                        )}
                      </div>

                      {data?.getSingleProduct?.options?.length > 0 && (
                        <div className="product-all-details-section">
                          <div className="product-details-grid-alignment">
                            {data?.getSingleProduct.options?.map((item, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <h6>{item?.name} </h6>
                                  <div className="grid-left-side-alignment">
                                    {data?.getSingleProduct.options[index]?.data?.map((data, i) => {
                                      return (
                                        <React.Fragment key={i}>
                                          <div
                                            // className="size-box-alignment"
                                            // className="activeSizeBox"
                                            className={
                                              addProductDetailSlice?.id?.productDetails?.productSelectedOption[
                                                item.name
                                              ] === data.value
                                                ? 'size-box-alignment activeSizeBox'
                                                : 'size-box-alignment'
                                            }
                                            onClick={() => handleOptionSelect(item.name, data.value, data?.colorCode)}
                                          >
                                            {data?.colorCode && (
                                              <div
                                                className="colorBoxAlignment"
                                                style={{ background: `${data?.colorCode}` }}
                                              ></div>
                                            )}
                                            <span>{data?.value}</span>
                                          </div>
                                        </React.Fragment>
                                      )
                                    })}
                                  </div>
                                </React.Fragment>
                              )
                            })}
                          </div>
                          <div
                            className="product-mobile-view-show-all-button"
                            onClick={() =>
                              dispatch(
                                fetchMobileModalData({
                                  allModalData: [
                                    ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                                    {
                                      modal: 'mobileViewProductVarint',
                                      isOpen: true,
                                      id: productId,
                                      transform: 230,
                                    },
                                  ],
                                  lastModalData: fetchMobileModal?.lastModalData ? fetchMobileModal?.lastModalData : [],
                                }),
                              )
                            }
                          >
                            <a>Show All </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="new-product-sticky-alignment">
                    <div className="quantity-details-alignment">
                      <h6>Quantity</h6>
                      <div className="quantity-other-details-alignment">
                        <div className="quantity-input-flex-alignment">
                          <div
                            className="incremnt-icon-img"
                            // onClick={() => setProductQty(productQty > 2 ? productQty - 1 : 1)}
                            onClick={() => updateQuantity('-')}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path d="M17 10H3" stroke="#556EE6" strokeWidth="1.5" strokeLinecap="round" />
                            </svg>
                          </div>
                          <div className="quantity-input-aignment">
                            <input type="text" placeholder="0" value={productQty} disabled />
                          </div>
                          <div className="incremnt-icon-img" onClick={() => updateQuantity('+')}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path d="M10 3V17" stroke="#556EE6" strokeWidth="1.5" strokeLinecap="round" />
                              <path d="M17 10H3" stroke="#556EE6" strokeWidth="1.5" strokeLinecap="round" />
                            </svg>
                          </div>
                        </div>
                        <span>
                          Stock :{' '}
                          {addProductDetailSlice?.id?.productDetails?.productDetailVariant?.inventory_quantity < 0
                            ? 0
                            : addProductDetailSlice?.id?.productDetails?.productDetailVariant?.inventory_quantity}
                        </span>
                      </div>
                    </div>

                    <div className="quantity-other-all-details-alignment">
                      <div className="quantity-other-all-details-left">
                        {Object.entries(addProductDetailSlice?.id?.productDetails?.productSelectedOption).map(
                          ([key, value]) => (
                            <div className="quantity-other-all-box" key={key}>
                              {addProductDetailSlice?.id?.productDetails?.productDetailColor && key === 'color' && (
                                <div
                                  className="quantity-all-color-box-alignment"
                                  style={{ background: addProductDetailSlice?.id?.productDetails?.productDetailColor }}
                                ></div>
                              )}
                              <span>{value}</span>
                            </div>
                          ),
                        )}
                      </div>

                      <div className="quantity-other-all-right-side-alignment">
                        <div className="checkout-cart-button">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M21 11.5C21.0034 12.8199 20.6951 14.1219 20.1 15.3C19.3944 16.7118 18.3098 17.8992 16.9674 18.7293C15.6251 19.5594 14.0782 19.9994 12.5 20C11.1801 20.0035 9.87812 19.6951 8.7 19.1L5.84605 20.0513C4.67341 20.4422 3.5578 19.3266 3.94868 18.154L4.9 15.3C4.30493 14.1219 3.99656 12.8199 4 11.5C4.00061 9.92179 4.44061 8.37488 5.27072 7.03258C6.10083 5.69028 7.28825 4.6056 8.7 3.90003C9.87812 3.30496 11.1801 2.99659 12.5 3.00003H13C15.0843 3.11502 17.053 3.99479 18.5291 5.47089C20.0052 6.94699 20.885 8.91568 21 11V11.5Z"
                              stroke="#556EE6"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div
                          className={
                            addProductDetailSlice?.id?.productDetails?.productDetailVariant?.inventory_quantity > 0 &&
                              user
                              ? 'checkout-cart-button'
                              : 'checkout-cart-button cart-button-disabled'
                          }
                          onClick={() => addToCart()}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M3.97689 9.84C4.01712 9.33881 4.24464 8.87115 4.61417 8.53017C4.98369 8.18918 5.46808 7.9999 5.97089 8H18.0289C18.5317 7.9999 19.0161 8.18918 19.3856 8.53017C19.7551 8.87115 19.9827 9.33881 20.0229 9.84L20.8259 19.84C20.848 20.1152 20.8129 20.392 20.7227 20.6529C20.6326 20.9139 20.4894 21.1533 20.3022 21.3562C20.115 21.5592 19.8878 21.7211 19.6349 21.8319C19.382 21.9427 19.109 21.9999 18.8329 22H5.16689C4.89081 21.9999 4.61774 21.9427 4.36487 21.8319C4.112 21.7211 3.8848 21.5592 3.69759 21.3562C3.51037 21.1533 3.36719 20.9139 3.27706 20.6529C3.18693 20.392 3.1518 20.1152 3.17389 19.84L3.97689 9.84V9.84Z"
                              stroke="#556EE6"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M16 11V6C16 4.93913 15.5786 3.92172 14.8284 3.17157C14.0783 2.42143 13.0609 2 12 2C10.9391 2 9.92172 2.42143 9.17157 3.17157C8.42143 3.92172 8 4.93913 8 6V11"
                              stroke="#556EE6"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M11.9995 12.9229V18.4613"
                              stroke="#556EE6"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                            />
                            <path
                              d="M14.769 15.6924H9.23058"
                              stroke="#556EE6"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                            />
                          </svg>
                        </div>
                        <div className="checkout-button-alignment " onClick={() => checkoutSingleItem()}>
                          <button
                            className={
                              addProductDetailSlice?.id?.productDetails?.productDetailVariant?.inventory_quantity > 0
                                ? ''
                                : 'button-disabled'
                            }
                          >
                            Checkout
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {Boolean(data?.getSingleProduct?.attributes?.length) && (
                <div className="product-specifications-section">
                  <div className="product-specifications-alignment">
                    <div className="product-specifications-heading">
                      <h4>Product Specifications</h4>
                    </div>

                    <div className="product-specifications-detailstable-alignment">
                      <table cellPadding={0} cellSpacing={0}>
                        <tbody>
                          {data?.getSingleProduct?.attributes?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <th>{item.name}</th>
                                <td>{item.value}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
              {isOpenPreviewModal && (
                <>
                  <div className="product-priview-modal">
                    <div className="close-button-design" onClick={handleClose}>
                      X
                    </div>
                    <div className="center-alignment-modal">
                      <Slider {...previewModalSetting}>
                        {data?.getSingleProduct?.images?.length > 0 &&
                          (addProductDetailSlice?.id?.productDetails?.productDetailVariant?.image?.media
                            ? [
                              {
                                src: addProductDetailSlice?.id?.productDetails?.productDetailVariant?.image?.media,
                              },
                              ...data?.getSingleProduct?.images,
                            ]
                            : data?.getSingleProduct?.images
                          )?.map((item, index) => {
                            return (
                              <div className="produtc-img-alignment" key={index}>
                                <img src={item?.src} />
                              </div>
                              // <div className="new-produtc-img-alignment-preview" key={index} onClick={handlePreview}>
                              //   <div
                              //     className="poroduct-image-background-preview"
                              //     style={{
                              //       backgroundImage: `url(${item?.src})`,
                              //     }}
                              //   ></div>
                              // </div>
                            )
                          })}
                      </Slider>
                    </div>
                  </div>
                </>
              )}
              <TabSectionForMobile
                getSingleProduct={data?.getSingleProduct}
                setOpenTraction={setOpenTraction}
                openTraction={openTraction}
                product={{ id: data?.getSingleProduct?.id, comment_count: data?.getSingleProduct?.comment_count }}
                tabArray={tabArray}
                productMobileTab={productMobileTab}
                setProductMobileTab={setProductMobileTab}
              />
              {/* ================================================ FOR_MOBILE_START ========================================================= */}
              <div
                className="checkout-button-section-mobile"
                style={openTraction > 0 ? { zIndex: -999 } : { display: 'block' }}
              >
                <div className="mobile-view-selected-product-all-details-alignment">
                  <div className="mobile-view-all-details-flex-alignment">
                    {Object.entries(addProductDetailSlice?.id?.productDetails?.productSelectedOption).map(
                      ([key, value]) => (
                        <div className="mobile-view-select-box" key={key}>
                          {addProductDetailSlice?.id?.productDetails?.productDetailColor && key === 'color' && (
                            <div
                              className="mobile-color-box"
                              style={{ background: addProductDetailSlice?.id?.productDetails?.productDetailColor }}
                            ></div>
                          )}
                          <span>{value}</span>
                          {/* <p>6.5 EU</p> */}
                        </div>
                      ),
                    )}

                    {/* <div className="mobile-view-select-box">
                      <div className="mobile-color-box"></div>
                      <p>Dark Blue</p>
                    </div>
                    <div className="mobile-view-select-box">
                      <p>medium</p>
                    </div>

                    <div className="mobile-view-select-box">
                      <p>1 pcs</p>
                    </div> */}
                  </div>
                </div>
                <div className="checkout-contain-main">
                  {' '}
                  <div className="shop-icon-alignment">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M21 11.5C21.0034 12.8199 20.6951 14.1219 20.1 15.3C19.3944 16.7118 18.3098 17.8992 16.9674 18.7293C15.6251 19.5594 14.0782 19.9994 12.5 20C11.1801 20.0035 9.87812 19.6951 8.7 19.1L5.84605 20.0513C4.67341 20.4422 3.5578 19.3266 3.94868 18.154L4.9 15.3C4.30493 14.1219 3.99656 12.8199 4 11.5C4.00061 9.92179 4.44061 8.37488 5.27072 7.03258C6.10083 5.69028 7.28825 4.6056 8.7 3.90003C9.87812 3.30496 11.1801 2.99659 12.5 3.00003H13C15.0843 3.11502 17.053 3.99479 18.5291 5.47089C20.0052 6.94699 20.885 8.91568 21 11V11.5Z"
                        stroke="#556EE6"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="shop-icon-alignment" onClick={addToCart}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M6 6H3V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H19C19.5304 22 20.0391 21.7893 20.4142 21.4142C20.7893 21.0391 21 20.5304 21 20V6H18H6Z"
                        stroke="#556EE6"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path d="M12 11V16.5385" stroke="#556EE6" strokeWidth="1.5" strokeLinecap="round" />
                      <path d="M14.7695 13.7695H9.23107" stroke="#556EE6" strokeWidth="1.5" strokeLinecap="round" />
                      <path
                        d="M8 6C8 4.93913 8.42143 3.92172 9.17157 3.17157C9.92172 2.42143 10.9391 2 12 2C13.0609 2 14.0783 2.42143 14.8284 3.17157C15.5786 3.92172 16 4.93913 16 6"
                        stroke="#556EE6"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="checkout-bg-contain" onClick={() => checkoutSingleItem()}>
                    <button
                      className={
                        addProductDetailSlice?.id?.productDetails?.productDetailVariant?.inventory_quantity > 0
                          ? ''
                          : 'button-disabled'
                      }
                    >
                      Check Out
                      {/* {addProductDetailSlice?.id?.productDetails?.productDetailVariant?.inventory_quantity > 0 ? (
                        <s>Check Out</s>
                      ) : (
                        'Check Out'
                      )} */}
                    </button>
                  </div>
                </div>
              </div>
              {/* ================================================ FOR_MOBILE_END ========================================================= */}

              <div ref={tabSection} style={{ margin: "0 0 64px 0" }}>
                <TabSection
                  getSingleProduct={data?.getSingleProduct}
                  viewIndex={viewIndex}
                  SetViewIndex={SetViewIndex}
                />
              </div>
            </div>
          </div>
          {/* {openTraction > 0 && (
          <TabSectionModal setOpenTraction={setOpenTraction} openTraction={openTraction}>
            {openTraction == tabArray.customerTraction && <CustomerTraction />}
            {openTraction == tabArray.comments && (
              <Comments
                description={data?.etSingleProduct?.description}
                productId={data?.getSingleProduct?.id}
                comments={data?.getSingleProduct?.comments}
                product={{ id: data?.getSingleProduct?.id, comment_count: data?.getSingleProduct?.comment_count }}
                height={250}
              />
            )}
            {openTraction == tabArray.gallery && <GalleryForMobile />}
            {openTraction == tabArray.shipping && <Shipping description={data?.getSingleProduct?.description} />}
          </TabSectionModal>
        )} */}
          {/* {openTraction == tabArray.rateAndReview && (
          <RateReviewModalMobile setOpenTraction={setOpenTraction} product={data?.getSingleProduct} />
        )} */}
        </div>
      </div>

      <div
        className={
          mobileViewModal
            ? 'mobile-view-slider-view-img-wrapper open-wrapper-alignment'
            : 'mobile-view-slider-view-img-wrapper close-wrapper-alignment'
        }
      >
        <div ref={sliderRef} className="mobile-view-slider-img-box">
          <Slider {...settings}>
            {data?.getSingleProduct?.images.map((images, index) => {
              return (
                <div className="mobile-view-slider-img" key={index}>
                  <img src={images?.src} />
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    </>
  )
}

export default ProductDetailModal
