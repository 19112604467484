// import React from 'react'
// import NewArrival from 'src/components/StoreAndProductDetailModal/ShopModal/NewArrival'

// export default function NewArrivalCommon() {
//   return (
//     <div>
//       <NewArrival />
//     </div>
//   )
// }

import React, { useEffect, useState } from 'react'
import styles from './NewArrivalCommon.module.scss'
import { useRouter } from 'next/router'
import { fetchProductByCategory } from 'src/store/slices/fetchAllProduct'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import GET_ALL_BOOKMARK from 'src/graphql/queries/getAllBookmark'
import { useQuery } from '@apollo/client'
import BookMarkProductCard from 'src/components/BookmarkMain/AllbookmarksDetails/BookMarkProductCard'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
const Newarrivalcartoon = '/assets/img/arrivaltitle/arrivalcartoon.png'

export default function NewArrivalCommon() {
  const router = useRouter()
  const [page, setPage] = useState(1)
  const [dataLimite, setDataLimite] = useState(40)
  //   const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const getAllState = useSelector((state: any) => state?.manageMobileModal?.data)

  const dispatch = useAppDispatch()

  useEffect(() => {
    setPage(1)
    dispatch(
      fetchProductByCategory({
        category: 'Fashion & Accessories',
        subCategory: router?.asPath?.includes('women') ? 'Women' : 'Men',
        childSubCategory: router?.query?.category ? router?.query?.category : '',
        isShopifyProduct: false,
        page: page,
        limit: dataLimite,
      }),
    )
  }, [])

  const { loading, error, data, refetch } = useQuery(GET_ALL_BOOKMARK, {
    variables: {
      page: page,
      limit: dataLimite,
    },
  })

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const normalPostLikeHandler = () => {}

  const bookmarkProduct = () => {}

  const handleAddToCollection = () => {}

  return (
    <>
      <div className={styles.Newarrivaltitlemain} style={{ background: getAllState?.lastModalData?.id?.color }}>
        <div className={styles.Newarrivaltitletextmain}>
          <h4>{getAllState?.lastModalData?.id?.title}</h4>
          <p>{getAllState?.lastModalData?.id?.description}</p>
        </div>

        <div className={styles.Newarrivalcartoon}>
          <img src={Newarrivalcartoon} alt="Newarrivalcartoon" />
        </div>
      </div>

      <div className={styles.allBookMarkDetailsBodyAlignment}>
        {data?.getAllBookmark?.data?.length ? (
          <div className={styles.allBookMarkDetailsGrid}>
            {data?.getAllBookmark?.data?.map((item:any, index:number) => {
              return (
                <BookMarkProductCard
                  item={item?.products}
                  index={index}
                  isInCollection={false}
                  handleRedirect={handleRedirect}
                  normalPostLikeHandler={normalPostLikeHandler}
                  bookmarkProduct={bookmarkProduct}
                  handleAddToCollection={handleAddToCollection}
                  isFilter={true}
                />
              )
            })}
          </div>
        ) : (
          <div className={styles.noDataBookMarkCollectionDetails}>
            <img src="/assets/icon/no-bookmark-product-data.svg" alt="no data" />
            <p>No item saved on bookmark.</p>
          </div>
        )}
      </div>
    </>
  )
}
