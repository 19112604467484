import Checkbox from 'antd/lib/checkbox/Checkbox'
import React, { useEffect, useRef, useState } from 'react'
import productImage from 'public/assets/img/product/fashion-watch1.png'
import { ApiPost } from 'src/Helpers/Api/ApiData'
import { API } from 'src/config/API/api.config'
import { useAuth } from 'src/utils/auth'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
import styles from '../AddProductModalVariant.module.scss'
import classNames from 'classnames'
import AddFilterDropdownBox from '../../AddFilterDropdownBox/AddFilterDropdownBox'

export default function VariantData(props: any) {
  const { variantData, isEditable, setIsEditable, handleVariantDataChange } = props
  const [images, setImages] = useState<any>(null)
  const dispatch = useAppDispatch()
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const [sizeDropdown2, setSizeDropdown2] = useState(false)
  const [colorDropdown2, setColorDropdown2] = useState(false)
  const [dropdownIndex, setDropdownIndex] = useState(null)
  const [otherDropdown, setOtherDropdown] = useState({})
  const filterRef: any = useRef()
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([])
  const [newVariantData, setNewVariantData] = useState(addProductDetailSlice?.id?.variant?.allOptions || [])
  const [filteredData, setFilteredData] = useState([])

  const transformedArray = getAllState?.allModalData[0]?.isEdit
    ? addProductDetailSlice?.id?.variant?.colorCodeArray?.map((item) => {
        if (item.name === 'Color' || item.name === 'color') {
          const colorData = item?.data?.map((data) => {
            return { name: data?.name, colorCode: data?.colorCode }
          })
          return { name: item?.name, data: colorData }
        } else if (item.name !== 'Color' && item.name !== 'SIZE') {
          return { name: item?.name, data: item?.data }
        } else {
          return item
        }
      })
    : addProductDetailSlice?.id?.variant?.allVariant?.map((item) => {
        if (item.name === 'Color') {
          const colorData = item?.data?.map((data) => {
            return { name: data?.name, colorCode: data?.colorCode }
          })
          return { name: item?.name, data: colorData }
        } else if (item.name !== 'Color' && item.name !== 'SIZE') {
          return { name: item?.name, data: item?.data }
        } else {
          return item
        }
      })

  const order = ['SIZE', 'Color']

  const sizeAndColorData = transformedArray?.filter((item) => order?.includes(item.name))

  // Filter out the remaining transformedArray
  const remainingData = transformedArray?.filter((item) => !order?.includes(item.name))

  // Sort the 'SIZE' and 'Color' data based on the order
  const sortedSizeAndColorData = sizeAndColorData?.sort((a, b) => order?.indexOf(a.name) - order?.indexOf(b.name))

  // Concatenate the sorted 'SIZE' and 'Color' data with the remaining data
  const finalSortedArray = sortedSizeAndColorData?.concat(remainingData)

  const [filterdDropdown, setFilterdDropdown] = useState(finalSortedArray || [])
  const bindInput = (value) => {
    var regex = new RegExp('^[^0-9]*$')
    var key = String.fromCharCode(!value.charCode ? value.which : value.charCode)
    if (regex.test(key)) {
      value.preventDefault()
      return false
    }
  }

  useEffect(() => {
    setFilterdDropdown(transformedArray)
  }, [addProductDetailSlice?.id?.variant?.allVariant])

  const handleInputData = (e, index) => {
    handleVariantDataChange(index, e.target.name, e.target.value)
  }
  const handleImages = async (e, index) => {
    // let imageResponse
    // let UploadImageRoute =
    //   API.endpoint.includes('stage') || API.endpoint.includes('local')
    //     ? `/upload?type=bluejestic-stage/store/${user?.store?.id}/product`
    //     : `/upload?type=bluejestic-production/store/${user?.store?.id}/product`
    // const formData = new FormData()

    // formData.append('image', e.target.files[0])
    // formData.append('media_for', 'product')
    // imageResponse = await ApiPost(UploadImageRoute, formData)
    // const image = imageResponse?.data?.image[0]?.id
    // handleVariantDataChange(index, 'image', image)
    handleVariantDataChange(index, 'image', e.target.files[0])
    setImages({ ...images, [index]: URL?.createObjectURL(e.target.files[0]) })
  }

  const handleDropdownClick = (index) => {
    if (dropdownIndex !== index) {
      setOtherDropdown((prevOpenDropdowns) => ({
        ...prevOpenDropdowns,
        [dropdownIndex]: false,
      }))
    }

    setDropdownIndex(index)
    setOtherDropdown((prevOpenDropdowns) => ({
      ...prevOpenDropdowns,
      [index]: true,
    }))
  }

  const handleCheckData = (e, data, index) => {
    const updatedData = [...newVariantData]
    updatedData[index] = { ...updatedData[index], isChecked: e.target.checked } // Update the specific item
    let newVarient = updatedData

    setNewVariantData((prevData) => {
      const updatedData = [...prevData] // Create a copy of the array
      updatedData[index] = { ...updatedData[index], isChecked: e.target.checked } // Update the specific item
      return updatedData
    })

    let NewSelectedFilter = []

    filterdDropdown?.map((item, index) => {
      item?.data?.map((data) => {
        let filterVarient = []
        newVarient?.map((variant) => {
          if (variant?.total_variant?.includes(data?.name ? data?.name : data)) {
            filterVarient.push(variant)
          }
          return variant
        })
        if (filterVarient?.every((item) => item?.isChecked && !NewSelectedFilter?.includes(data))) {
          NewSelectedFilter?.push(data)
        }
      })
    })

    const removedColorCode = NewSelectedFilter.map((item) => {
      if (typeof item === 'object' && item.name) {
        return item.name
      } else {
        return item
      }
    })

    setSelectedCheckboxes(removedColorCode)
    setFilteredData(NewSelectedFilter)
  }

  const handleResetData = () => {
    setSelectedCheckboxes([])
    setFilteredData([])
    setNewVariantData(addProductDetailSlice?.id?.variant?.allOptions || [])
  }

  const handleFilteredBox = (e, data, colorCode) => {
    const checkboxValue = e.target.checked

    let updatedSelectedCheckboxes = [...selectedCheckboxes]
    let updatedFilteredData = [...filteredData]

    if (checkboxValue) {
      if (!updatedSelectedCheckboxes.includes(data)) {
        updatedSelectedCheckboxes.push(data)
      }
      if (colorCode) {
        updatedFilteredData.push({ name: data, colorCode })
      } else {
        updatedFilteredData.push(data)
      }
    } else {
      updatedSelectedCheckboxes = updatedSelectedCheckboxes.filter((item) => item !== data)
      if (colorCode) {
        updatedFilteredData = updatedFilteredData.filter((item) => item?.name !== data)
      } else {
        updatedFilteredData = updatedFilteredData.filter((item) => item !== data)
      }
    }
    setSelectedCheckboxes(updatedSelectedCheckboxes)
    setFilteredData(updatedFilteredData)
    const updatedData = newVariantData.map((item) => {
      const isChecked = updatedSelectedCheckboxes.some((selectedData) => item?.total_variant?.includes(selectedData))
      return { ...item, isChecked }
    })

    setNewVariantData(updatedData)
    const currentDropdownData = filterdDropdown[dropdownIndex]?.data
    const isAllChecked = currentDropdownData?.every((data) => updatedSelectedCheckboxes?.includes(data))

    if (isAllChecked) {
      const allData = filterdDropdown?.reduce((acc, dropdown) => [...acc, ...dropdown.data], [])
      setSelectedCheckboxes(allData)
      updateAllCheckboxes(true)
    }
  }

  const updateAllCheckboxes = (checked) => {
    const allData = filterdDropdown.reduce((acc, dropdown) => [...acc, ...dropdown.data], [])
    const updatedData = newVariantData.map((item) => ({
      ...item,
      isChecked: checked,
    }))
    setNewVariantData(updatedData)
    setSelectedCheckboxes(checked ? allData : [])
  }

  const handleEditableModle = async (index, variant) => {
    await dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          variant: {
            ...addProductDetailSlice?.id.variant,
            isVariantModel: true,
          },
          isEditableData: {
            index: index,
            data: variant,
          },
        },
      }),
    )
  }

  let dummy_array = [
    { name: 'S' },
    { name: 'Red', color: '#ff0000' },
    { name: 'L' },
    { name: 'Blue', color: '#0000FF' },
    { name: 'S' },
    { name: 'Red', color: '#ff0000' },
    { name: 'L' },
    { name: 'Blue', color: '#0000FF' },
    { name: 'S' },
    { name: 'Red', color: '#ff0000' },
    { name: 'L' },
    { name: 'Blue', color: '#0000FF' },
    { name: 'S' },
    { name: 'Red', color: '#ff0000' },
    { name: 'L' },
    { name: 'Blue', color: '#0000FF' },
  ]

  const [newFilterdDropdown, setNewFilterdDropdown] = useState(false)

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (newFilterdDropdown && filterRef.current && !filterRef.current.contains(e.target)) {
        setNewFilterdDropdown(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [newFilterdDropdown])

  return (
    <>
      {/* <div className={styles.allProdutcTabAlignment}>
        <div className={styles.allProdutcVariantTabBox} onClick={() => setSizeDropdown2(!sizeDropdown2)}>
          <p>All size</p>
          <img src="/assets/icon/drop-down-icon-blue.svg" alt="drop-down-icon" />
          <div
            className={
              sizeDropdown2
                ? classNames(styles.allProductVarintTabDropdownBox, styles.openVariantDropdown)
                : classNames(styles.allProductVarintTabDropdownBox, styles.closeVariantDropdown)
            }
          >
            <div className={styles.allProdutctoptionAlignment}>
              <p>All Size</p>
            </div>
            <div className={styles.allProdutctoptionAlignment}>
              <p>S</p>
            </div>
            <div className={styles.allProdutctoptionAlignment}>
              <p>M</p>
            </div>
            <div className={styles.allProdutctoptionAlignment}>
              <p>L</p>
            </div>
          </div>
        </div>
        <div
          className={styles.allProdutcVariantTabBox}
          ref={colorRef}
          onClick={() => setColorDropdown2(!colorDropdown2)}
        >
          <p>All color</p>
          <img src="/assets/icon/drop-down-icon-blue.svg" alt="drop-down-icon" />

          <div
            className={
              colorDropdown2
                ? classNames(
                    styles.allProductVarintTabDropdownBox,
                    styles.colorWidthAlignment,
                    styles.openVariantDropdown,
                  )
                : classNames(
                    styles.allProductVarintTabDropdownBox,
                    styles.colorWidthAlignment,
                    styles.closeVariantDropdown,
                  )
            }
          >
            <div className={styles.allProdutctoptionAlignment}>
              <div className={styles.colorBoxAlignment}></div>
              <p>All Color</p>
            </div>
            <div className={styles.allProdutctoptionAlignment}>
              <div className={styles.colorBoxAlignment}></div>
              <p>Red</p>
            </div>
            <div className={styles.allProdutctoptionAlignment}>
              <div className={styles.colorBoxAlignment}></div>
              <p>Blue</p>
            </div>
            <div className={styles.allProdutctoptionAlignment}>
              <div className={styles.colorBoxAlignment}></div>
              <p>Orange</p>
            </div>
          </div>
        </div>
        <div className={styles.allProdutcVariantTabBox}>
          <p>All Material</p>
          <img src="/assets/icon/drop-down-icon-blue.svg" alt="drop-down-icon" />
        </div> */}

      {/* {filterdDropdown?.map((item, index) => {
          return (
            <>
              <span onClick={() => handleDropdownClick(index)}>All {item?.name}</span>
              <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                <div>
                  <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1 1L4 4L7 1"
                      stroke="#ACB1C0"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {otherDropdown[index] && (
                    <div
                      style={{ background: 'white' }}
                      className={styles.unitDetailsDropdownBoxAlignment}
                      ref={filterRef}
                    >
                      {filterdDropdown[dropdownIndex].data?.map((data, i) => {
                        return (
                          <>
                            <div style={{ display: 'flex', gap: '15px' }}>
                              <input
                                type="checkbox"
                                onChange={(e) => handleFilteredBox(e, data)}
                                checked={selectedCheckboxes.includes(data)}
                              />
                              <p>{data}</p>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  )}
                </div>
              </div>
            </>
          )
        })} */}
      {/* </div> */}

      <div ref={filterRef} className={styles.filterRelative}>
        <div className={styles.addProdutcFilterAlignment}>
          <div className={styles.addProductLeftSideAlignment}>
            <div className={styles.addProductFilterBox} onClick={() => setNewFilterdDropdown(true)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M1.33081 3.33057H14.6641" stroke="#4C515D" strokeLinecap="round" />
                <path d="M3.33081 7.99707H12.6641" stroke="#4C515D" strokeLinecap="round" />
                <path d="M5.99756 12.6641H9.99756" stroke="#4C515D" strokeLinecap="round" />
              </svg>

              <h6>Filters</h6>
            </div>
            <div className={styles.allAddFilterDetailsAlignment}>
              <div className={styles.addProductAllFilterListAlignment}>
                {filteredData.map((it, index) => {
                  return (
                    <div className={styles.sizeBoxFilterAlignment} key={index}>
                      <div className={it.colorCode ? styles.colorBoxAlignment : styles.sizeDetailsBox}>
                        {it?.name ? (
                          <div className={styles.colorBoxDetails}>
                            <div className={styles.colorShowBox} style={{ background: it.colorCode }}></div>
                            <p>{it?.name}</p>
                          </div>
                        ) : (
                          <p>{it}</p>
                        )}
                        <div onClick={(e) => handleFilteredBox(e, it?.name ? it?.name : it, it?.colorCode)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path d="M12 4L4 12" stroke="#7A7E88" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4 4L12 12" stroke="#7A7E88" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  )
                })}
                {/* <div className={styles.sizeBoxFilterAlignment}>
                        
                      </div> */}
              </div>
            </div>
          </div>

          <div className={styles.clearFilterAlignment}>
            <a onClick={() => handleResetData()}>Clear Filter</a>
          </div>
          {/* <div className={styles.addProdutcMain}>
          <div className={styles.addProductFilterAllDetailsBoxAlignment}>
            <div className={styles.addProdutcHeadingAlignment}>
              <h6>Filter</h6>
              <a>Reset</a>
            </div>
            <div className={styles.addProductBodyAlignment}>
              <div className={styles.addProdutcBodyChildDetails}>
                <label>Size</label>
                <div className={styles.addProdutcAllChildList}>
                  <div className={styles.addChildListBox}>
                    <p>XS</p>
                  </div>
                  <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
                    <p>S </p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <p>M</p>
                  </div>
                  <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
                    <p>L</p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <p>XL</p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <p>XXL</p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <p>XXXL</p>
                  </div>
                </div>
              </div>

              <div className={styles.addProdutcBodyChildDetails}>
                <label>Color</label>
                <div className={styles.addProdutcAllChildList}>
                  <div className={styles.addChildListBox}>
                    <div className={styles.colorBoxAlignment}></div>
                    <p>Red</p>
                  </div>
                  <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
                    <div className={classNames(styles.colorBoxAlignment, styles.blueBg)}></div>
                    <p>Blue</p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <div className={classNames(styles.colorBoxAlignment, styles.yellowBg)}></div>
                    <p>Yellow</p>
                  </div>
                  <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
                    <div className={classNames(styles.colorBoxAlignment, styles.purpleBg)}></div>
                    <p>Purple</p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <div className={classNames(styles.colorBoxAlignment, styles.darkBlue)}></div>
                    <p>Dark Blue </p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <div className={classNames(styles.colorBoxAlignment, styles.greenBg)}></div>
                    <p>Green</p>
                  </div>
                </div>
              </div>

              <div className={styles.addProdutcBodyChildDetails}>
                <label>Material </label>
                <div className={styles.addProdutcAllChildList}>
                  <div className={styles.addChildListBox}>
                    <p>Metal</p>
                  </div>
                  <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
                    <p>Polymer</p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <p>Rubber</p>
                  </div>
                </div>
              </div>
              <div className={styles.addProdutcBodyChildDetails}>
                <label>Size</label>
                <div className={styles.addProdutcAllChildList}>
                  <div className={styles.addChildListBox}>
                    <p>XS</p>
                  </div>
                  <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
                    <p>S </p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <p>M</p>
                  </div>
                  <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
                    <p>L</p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <p>XL</p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <p>XXL</p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <p>XXXL</p>
                  </div>
                </div>
              </div>

              <div className={styles.addProdutcBodyChildDetails}>
                <label>Color</label>
                <div className={styles.addProdutcAllChildList}>
                  <div className={styles.addChildListBox}>
                    <div className={styles.colorBoxAlignment}></div>
                    <p>Red</p>
                  </div>
                  <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
                    <div className={classNames(styles.colorBoxAlignment, styles.blueBg)}></div>
                    <p>Blue</p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <div className={classNames(styles.colorBoxAlignment, styles.yellowBg)}></div>
                    <p>Yellow</p>
                  </div>
                  <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
                    <div className={classNames(styles.colorBoxAlignment, styles.purpleBg)}></div>
                    <p>Purple</p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <div className={classNames(styles.colorBoxAlignment, styles.darkBlue)}></div>
                    <p>Dark Blue </p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <div className={classNames(styles.colorBoxAlignment, styles.greenBg)}></div>
                    <p>Green</p>
                  </div>
                </div>
              </div>

              <div className={styles.addProdutcBodyChildDetails}>
                <label>Material </label>
                <div className={styles.addProdutcAllChildList}>
                  <div className={styles.addChildListBox}>
                    <p>Metal</p>
                  </div>
                  <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
                    <p>Polymer</p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <p>Rubber</p>
                  </div>
                </div>
              </div>
              <div className={styles.addProdutcBodyChildDetails}>
                <label>Size</label>
                <div className={styles.addProdutcAllChildList}>
                  <div className={styles.addChildListBox}>
                    <p>XS</p>
                  </div>
                  <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
                    <p>S </p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <p>M</p>
                  </div>
                  <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
                    <p>L</p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <p>XL</p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <p>XXL</p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <p>XXXL</p>
                  </div>
                </div>
              </div>

              <div className={styles.addProdutcBodyChildDetails}>
                <label>Color</label>
                <div className={styles.addProdutcAllChildList}>
                  <div className={styles.addChildListBox}>
                    <div className={styles.colorBoxAlignment}></div>
                    <p>Red</p>
                  </div>
                  <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
                    <div className={classNames(styles.colorBoxAlignment, styles.blueBg)}></div>
                    <p>Blue</p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <div className={classNames(styles.colorBoxAlignment, styles.yellowBg)}></div>
                    <p>Yellow</p>
                  </div>
                  <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
                    <div className={classNames(styles.colorBoxAlignment, styles.purpleBg)}></div>
                    <p>Purple</p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <div className={classNames(styles.colorBoxAlignment, styles.darkBlue)}></div>
                    <p>Dark Blue </p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <div className={classNames(styles.colorBoxAlignment, styles.greenBg)}></div>
                    <p>Green</p>
                  </div>
                </div>
              </div>

              <div className={styles.addProdutcBodyChildDetails}>
                <label>Material </label>
                <div className={styles.addProdutcAllChildList}>
                  <div className={styles.addChildListBox}>
                    <p>Metal</p>
                  </div>
                  <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
                    <p>Polymer</p>
                  </div>
                  <div className={styles.addChildListBox}>
                    <p>Rubber</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        </div>

        <AddFilterDropdownBox
          newFilterdDropdown={newFilterdDropdown}
          setNewFilterdDropdown={setNewFilterdDropdown}
          newVariantData={newVariantData}
          setNewVariantData={setNewVariantData}
          selectedCheckboxes={selectedCheckboxes}
          setSelectedCheckboxes={setSelectedCheckboxes}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          handleResetData={handleResetData}
        />
      </div>

      <div className={styles.addProductTable}>
        <table cellPadding={0} cellSpacing={0}>
          <thead>
            <tr>
              <th>
                <Checkbox />
              </th>
              <th>Image</th>
              <th>Variant</th>
              <th>Price</th>
              <th>Qty.</th>
              <th>SKU</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {addProductDetailSlice?.id?.variant?.allOptions?.map((variant, index) => {
              return (
                <tr>
                  <td>
                    <div className={styles.variantCheckBoxAlignment}>
                      <Checkbox
                        onChange={(e) => handleCheckData(e, variant, index)}
                        checked={newVariantData[index]?.isChecked ?? false}
                      />
                      {/* <input type="checkbox" checked={newVariantData[index]?.isChecked ?? false} /> */}
                    </div>
                  </td>
                  <td>
                    <div className={styles.variantImagesAlignment}>
                      {variant.image_id ? (
                        <img
                          src={
                            getAllState?.lastModalData?.isEdit
                              ? variant?.image?.media
                                ? variant?.image?.media
                                : URL?.createObjectURL(variant?.image_id)
                              : images?.[index]
                              ? images?.[index]
                              : URL?.createObjectURL(variant?.image_id) ?? ''
                            // : URL?.createObjectURL(variant?.[index]?.image_id)
                          }
                          alt="PreviewIcon"
                        />
                      ) : (
                        <div className={styles.noImgBoxAlignment}>
                          <input type="file" name="image" onChange={(e) => handleImages(e, index)} />
                          {/* <input type="file" name="image" onChange={(e) => handleInputData(e)} /> */}
                          <p>Select Img</p>
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className={styles.variantDetailsAlignment}>
                      <h6>
                        {variant?.total_variant?.map((item, idx) => {
                          return idx === variant.total_variant.length - 1 ? item : item + ', '
                        })}
                      </h6>
                      <p>Available</p>
                    </div>
                  </td>
                  <td className={styles.responisvePadding}>
                    <div className={styles.variantProceInputAlignment}>
                      <span>$</span>
                      <input
                        type="text"
                        name="price"
                        placeholder="price"
                        value={variant.price}
                        onChange={(e) => handleInputData(e, index)}
                      />
                    </div>
                  </td>
                  <td className={styles.responisvePadding}>
                    <div className={styles.variantQtyInputAlignment}>
                      <input
                        type="number"
                        name="inventory_quantity"
                        placeholder="qty"
                        onKeyPress={bindInput}
                        value={variant.inventory_quantity}
                        min={0}
                        onChange={(e) => handleInputData(e, index)}
                      />
                    </div>
                  </td>
                  <td className={styles.responisvePadding}>
                    <div className={styles.varianSkuInputAlignment}>
                      <input
                        className="border-2"
                        type="text"
                        name="sku"
                        placeholder="sku"
                        value={variant.sku}
                        onChange={(e) => handleInputData(e, index)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className={styles.actionDetailsAlignment}>
                      <button
                        onClick={() => {
                          handleEditableModle(index, variant)
                          setIsEditable({ ...isEditable, isEditable: true, index: index, data: variant })
                        }}
                      >
                        Make Edit
                      </button>

                      <div className={styles.resetIconAlignment}>
                        <img src="/assets/icon/reset-icon.svg" alt="reset icon" />
                      </div>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {/* <div className="d-flex justify-between w-full gap-2">
        <Checkbox />
        {variant.image_id ? (
          <img src={images ?? URL?.createObjectURL(variant.image_id)} alt="PreviewIcon" width="50px" />
        ) : (
          <div className="h-10 bg-gray-200">
            <input type="file" name="image" onChange={(e) => handleImages(e)} /> */}
      {/* <input type="file" name="image" onChange={(e) => handleInputData(e)} /> */}
      {/* </div>
        )}
        <p>
          {variant?.total_variant?.map((item, idx) => {
            return idx === variant.total_variant.length - 1 ? item : item + ','
          })}
        </p>
        <input
          className="border-2"
          type="text"
          name="price"
          placeholder="price"
          value={variant.price}
          onChange={(e) => handleInputData(e)}
        />
        <input
          className="border-2"
          type="text"
          name="inventory_quantity"
          placeholder="qty"
          value={inventory ?? variant.inventory_quantity}
          onChange={(e) => handleInputData(e)}
        />
        <input
          className="border-2"
          type="text"
          name="sku"
          placeholder="sku"
          value={variant.sku}
          onChange={(e) => handleInputData(e)}
        /> */}
      {/* <input
        className="border-2"
        type="text"
        name="inventory_quantity"
        placeholder="list price"
        value={inventory ?? variant.inventory_quantity}
        onChange={(e) => handleInputData(e)}
      /> */}
      {/* <button
          onClick={() => {
            handleEditableModle()
            setIsEditable({ ...isEditable, isEditable: true, index: index, data: variant })
          }}
        >
          Make Edit
        </button>
      </div> */}
    </>
  )
}
