import { gql } from '@apollo/client'

const query = gql`
  mutation ($store_id: Int!) {
    followStore(input: { store_id: $store_id }) {
      id
      user_id
      store_id
    }
  }
`
export default query
