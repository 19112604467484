import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAppDispatch } from 'src/store/store'

export default function MobileViewFilterModal({ isOpen, children }) {
  const dispatch = useAppDispatch()
  const [startX, setStartX] = useState(null)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  let productImage = []

  function handleTouchStart(e) {
    setStartX(e.touches[0].pageX)
  }

  function handleTouchMove(e) {
    if (startX && startX + e.touches[0].pageY > 150) {
      handleClose()
    }
  }

  function handleTouchEnd() {
    setStartX(null)
  }

  const handleClose = () => {
    let datass = fetchMobileModal?.allModalData?.filter(
      (item) => item?.modal !== fetchMobileModal?.lastModalData?.modal,
    )
    dispatch(
      fetchMobileModalData({
        allModalData: datass,
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
  }

  useEffect(() => {
    if (fetchMobileModal?.lastModalData?.isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [fetchMobileModal])

  if (fetchMobileModal?.lastModalData?.modal === 'productOption') {
    productImage = [...(fetchMobileModal?.lastModalData?.id?.images || [])]
  }

  return (
    <React.Fragment>
      <div onClick={() => handleClose()} className={`follow-followingOverlay ${isOpen ? 'open' : ''}`}>
        {fetchMobileModal?.lastModalData?.modal === 'productOption' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              // paddingBottom: '250px',
            }}
          >
            {/* <div className="mobile-view-product-category-card-alignment">
              <div className="mobile-view-product-category-card-img-alignment">
                <div className="share-me-feed-img-alignment">
                  {productImage?.length > 0 && productImage[0]?.src?.includes('mp4') ? (
                    <video muted autoPlay loop style={{ objectFit: 'cover' }}>
                      <source src={productImage[0]?.src} />
                    </video>
                  ) : (
                    <img src={productImage[0]?.src} alt="ProductImg" />
                  )}
                </div>
              </div>
              <div className="mobile-view-product-category-details-allignment">
                <h4>
                  {fetchMobileModal?.lastModalData?.id?.title?.length > 34
                    ? fetchMobileModal?.lastModalData?.id?.title.substring(0, 34) + '...'
                    : fetchMobileModal?.lastModalData?.id?.title}
                </h4>
                <div className="mobile-price-alignment">
                  <p>
                    ${' '}
                    {fetchMobileModal?.lastModalData?.id?.dis_price
                      ? fetchMobileModal?.lastModalData?.id?.dis_price
                      : fetchMobileModal?.lastModalData?.id?.inventoryPrice?.price}
                  </p>
                  <span>
                    ${' '}
                    {fetchMobileModal?.lastModalData?.id?.dis_listPrice
                      ? fetchMobileModal?.lastModalData?.id?.dis_listPrice
                      : fetchMobileModal?.lastModalData?.id?.inventoryPrice?.listPrice}
                  </span>
                </div>
              </div>
            </div> */}
          </div>
        )}
      </div>
      <div
        className={`follow-following-modal ${isOpen ? 'open' : ''}`}
        style={
          isOpen
            ? {
                transform: `translate(0, ${fetchMobileModal?.lastModalData?.transform}${
                  fetchMobileModal?.lastModalData?.unit ? fetchMobileModal?.lastModalData?.unit : `px`
                })`,
                // height: `calc(100% - ${fetchMobileModal?.lastModalData?.transform}px)`,
                zIndex: fetchMobileModal?.lastModalData?.zIndex,
              }
            : {
                translate: `0 calc(100% - -${fetchMobileModal?.lastModalData?.transform}${
                  fetchMobileModal?.lastModalData?.unit ? fetchMobileModal?.lastModalData?.unit : `px`
                })`,
              }
        }
      >
        <div
          className="store-product-filter-click-drop-alignment"
          style={{ zIndex: fetchMobileModal?.lastModalData?.zIndex }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <p></p>
        </div>
        {children}
      </div>
    </React.Fragment>
  )
}
