import React from 'react'
import { GET_ALL_USERS } from 'src/graphql/queries/queries'
import { useQuery } from '@apollo/client'
import { useStateContext } from 'src/utils/state'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'

export default function MobileViewPersonalAccount(props) {
  const {
    data: userData,
    loading: loadingUsers,
    refetch,
  }: any = useQuery<any>(GET_ALL_USERS, {
    onError: (err) => { },
  })
  const { selectChat } = useStateContext()
  const dispatch = useAppDispatch()
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)

  const handleClose = () => {
    let datass = fetchMobileModal?.allModalData?.filter(
      (item) => item?.modal !== fetchMobileModal?.lastModalData?.modal,
    )
    dispatch(
      fetchMobileModalData({
        allModalData: datass,
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
  }

  return (
    <>
      <div className="message-modal-personal-account-body-details-alignment">
        <div className="mobile-view-select-account-modal-alignment">
          <div className="mobile-view-select-account-sticky">
            <div className="mobile-view-select-account-heading-alignment">
              <h4>Select Account</h4>
              <div className="mobile-view-header-close-alignment" onClick={() => handleClose()}>
                <img src="/assets/icon/close_icon.svg" alt="close icon" />
              </div>
            </div>
            <div className="mobile-view-select-search-alignment">
              <div className="mobile-view-select-account-search-alignment">
                <div className="mobile-view-search-icon">
                  <img src="/assets/icon/search.svg" alt="search" />
                </div>
                <input type="text" placeholder="Search" />
              </div>
            </div>
          </div>

          <div className="mobile-view-select-acount-modal-body-alignment">
            {userData?.getAllUsers
              .filter((user) => !user?.latestMessage)
              ?.map((u, index) => {
                return (
                  <div
                    className="mobile-view-account-details-alignment"
                    onClick={() => {
                      selectChat(u, 'private')
                      dispatch(
                        fetchMobileModalData({
                          allModalData: [
                            // ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                            {
                              modal: 'chatBoxModal',
                              isOpen: true,
                              id: 0,
                              transform: 42,
                              zIndex: 9999999,
                            },
                          ],
                          lastModalData: fetchMobileModal?.lastModalData,
                        }),
                      )
                    }}
                    key={index}
                  >
                    <div className="mobile-view-account-profile-img-alignment">
                      <img src={u?.logo_image} alt="profile img" />
                    </div>
                    <p>{u?.firstName + ' ' + u?.lastName}</p>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}
