import { gql } from '@apollo/client'

const GET_MY_GROUP = gql`
  query {
    groups {
      id
      name
      slug
      description
      hashtags
      coverImage
      bannerImage
      privacy
      user_id
      isFollow
      members {
        id
        firstName
        lastName
        profileUrl: profileAvtar
      }
    }
  }
`

export default GET_MY_GROUP

// query GETGROUPS($isJoined: Boolean, $isMyGroups: Boolean) {
//   groups(isJoined: $isJoined, isMyGroups: $isMyGroups) {
//     id
//     name
//     description
//     hashtags
//     coverImage
//     bannerImage
//     privacy
//     user_id
//     isFollow
//     members {
//       id
//       firstName
//       lastName
//       profileUrl: profileAvtar
//     }
//     #user {
//     #  id
//     #  firstName
//     #  lastName
//     #  email
//     #  #confirmEmail
//     #}
//   }
// }
