import React from 'react'
import HeartIcon from '../../../../../../../public/assets/icon/heart.svg'
import ShareIcon2 from '../../../../../../../public/assets/icon/share.svg'
import BookIcon from '../../../../../../../public/assets/icon/book.svg'
import UserIcon from '../../../../../../../public/assets/icon/users.svg'
import BlackheartIcon from '../../../../../../../public/assets/icon/heart-black.svg'
import AuthorImg from '../../../../../../../public/assets/img/auther-img.png'

export default function BookDetailAuthor() {
  return (
    <div className="book-all-author-all-details-alignment">
      <div className="book-author-heading-alignment">
        <h5>Author</h5>
        <div className="auther-options-alignment">
          <div className="follow-page-button-alignment">
            <button>Follow Page</button>
          </div>
          <div className="like-share-alignment">
            <div className="box-alignment">
              <img src={HeartIcon.src} alt="HeartIcon" />
            </div>
            <div className="box-alignment">
              <img src={ShareIcon2.src} alt="ShareIcon" />
            </div>
          </div>
        </div>
      </div>
      <div className="book-more-author-box-alignment">
        <div className="author-img-alignment">
          <img src={AuthorImg.src} alt="AuthorImg" />
        </div>
        <div className="more-auther-details-alignment">
          <h6>David Baldaccci</h6>
          <p>Portland, Maine</p>
          <div className="mobile-auther-options-alignment">
            <div className="mobile-follow-page-button-alignment">
              <button>Follow Page</button>
            </div>
            <div className="mobile-like-share-alignment">
              <div className="mobile-box-alignment">
                <img src={HeartIcon.src} alt="HeartIcon" />
              </div>
              <div className="mobile-box-alignment">
                <img src={ShareIcon2.src} alt="ShareIcon" />
              </div>
            </div>
          </div>
          <div className="auther-following-details-alignment">
            <div className="child-anme-alignment">
              <img src={BookIcon.src} alt="BookIcon" />
              <span>150</span>
            </div>
            <div className="child-anme-alignment">
              <img src={UserIcon.src} alt="UserIcon" />
              <span>12M</span>
            </div>
            <div className="child-anme-alignment">
              <img src={BlackheartIcon.src} alt="BlackheartIcon" />
              <span>275 </span>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-auther-following-details-alignment">
        <div className="mobile-child-anme-alignment">
          <img src={BookIcon.src} alt="BookIcon" />
          <span>150 books</span>
        </div>
        <div className="mobile-child-anme-alignment">
          <img src={UserIcon.src} alt="UserIcon" />
          <span>12M follower</span>
        </div>
        <div className="mobile-child-anme-alignment">
          <img src={BlackheartIcon.src} alt="BlackheartIcon" />
          <span>275 likes </span>
        </div>
      </div>
    </div>
  )
}
