// @ts-nocheck


import React from 'react'
import ShopProductCard from '../ShopProductCard'
import Slider from 'react-slick'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <img src="/assets/icon/right-icon-black.svg" alt="RightArrow" />
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <img src="/assets/icon/left-icon-black.svg" alt="LeftArrow" />
    </div>
  )
}

export default function ShopTrendingProduct({ product, childSubCategoryId }) {
  const settings = {
    infinite: false,
    dots: false,
    speed: 500,
    slidesToShow: 3.60,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1.60,
          slidesToScroll: 1,
          centerMode: false,
          infinite: true,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1.30,
          slidesToScroll: 1,
          centerMode: false,
          infinite: true,
        },
      },
    ],
  }

  return (
    <div className="ShopTrendingProductSliderMain">
      <Slider {...settings}>
        {product?.map((item, index) => {
          return item?.total_count ? (
            <div key={index}>
              <ShopProductCard productData={item} />
            </div>
          ) : null
        })}
      </Slider>
    </div>
  )
}
