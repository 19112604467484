import React, { useEffect, useState } from 'react'
import styles from './BookmarkProduct.module.scss'
import Lottie from 'react-lottie-player'
import emptyCart from '../../../components/onbording/lottie/test-file.json'
import { useQuery } from '@apollo/client'
import AllProduct from 'src/components/ProductPage/AllProduct'
import { VariablesInAllowedPositionRule } from 'graphql'
import { fetchAllStoreProducts, setStoreProductState } from 'src/store/slices/fetchAllStoresSlice'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'

interface StoreProduct {
  id: number;
  is_liked?: boolean;
  [key: string]: any;  // For other properties
}

interface StoreData {
  getAllStoreProduct?: {
    getAllProductByStore?: {
      data?: StoreProduct[];
      total?: number;
    };
  };
}

export default function BookmarkProduct() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768)
  const allStores: StoreData = useSelector((state: any) => state?.storesData)
  const [filterData, setFilterData] = useState<any>({ subCategory: [], category: [] })
  const dispatch = useAppDispatch()
  const [page, setPage] = useState(1)

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    dispatch(setStoreProductState([]))
    getBookmarkProduct()
  }, [])

  const getBookmarkProduct = (product_id = null, type = null) => {
    dispatch(
      fetchAllStoreProducts({
        page: 1,
        limit: 10,
      }),
    ).then(() => {
      if (type === 'dislike' || type === 'like') {
        let update_product = allStores?.getAllStoreProduct?.getAllProductByStore?.data?.map((prd) => {
          if (prd?.id === product_id) {
            return {
              ...prd,
              likes: type === 'like' ? { id: 3.3333 } : null,
            }
          } else {
            return prd
          }
        })
        dispatch(
          setStoreProductState({
            getAllProductByStore: {
              data: update_product,
            },
          }),
        )
      }
    })
  }

  const fetchUpdatedProduct = (product_id, type) => {
    getBookmarkProduct(product_id, type)
  }

  useEffect(() => {
    getBookmarkProduct()
  }, [page])

  return (
    <div className={styles.bookMarkProductAlignment}>
      <div className={styles.bookMarkModalProductHeading}>
        <div className={styles.bookMarkModalProductNameAlignment}>
          <h6>All Bookmarks</h6>
          {/* <div className={styles.bookmarkCollectionNumber}>
            <span>{allStores?.getAllStoreProduct?.getAllProductByStore?.data?.length}</span>
          </div>
          <div className={styles.bookmarkProductNumber}>
        </div> */}
          {/* <div className={styles.bookmarkEditAlignment}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12 20H21" stroke="#7A7E88" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path
              d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z"
              stroke="#7A7E88"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              />
              </svg>
            </div> */}
        </div>
        <span>{allStores?.getAllStoreProduct?.getAllProductByStore?.data?.length} Products</span>
      </div>
      {Boolean(allStores?.getAllStoreProduct?.getAllProductByStore?.data?.length) ? (
        <div className={styles.bookMarkAllProductDetailsAlignment}>
          <AllProduct
            products={allStores?.getAllStoreProduct?.getAllProductByStore?.data}
            storeId={0}
            color={'#FFFFFF'}
            total={allStores?.getAllStoreProduct?.getAllProductByStore?.total}
            isFilterMenu={true}
            isShowToast={true}
            topForFilter={0}
            setFilterData={setFilterData}
            filterData={filterData}
            APIcall={fetchUpdatedProduct}
          />
        </div>
      ) : (
        <div className={styles.bookMarkProductEmptyCartDetails}>
          {/* <div>
            <Lottie loop={false} animationData={emptyCart} play />
          </div> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="149" height="96" viewBox="0 0 149 96" fill="none">
            <path d="M65.3906 13.0371V18.893" stroke="#9C6AC5" strokeWidth="1.30131" strokeLinecap="round" />
            <path
              d="M129.57 25.3594C129.528 25.3869 129.482 25.4076 129.434 25.4205L111.255 30.3347L105.596 31.8637L89.5012 36.213C89.4553 36.2243 89.4082 36.2304 89.3609 36.231C89.3284 36.2304 89.296 36.228 89.2638 36.2238L24.3646 24.3916L24.1488 24.352C24.0081 24.3257 23.8836 24.2448 23.8024 24.1269C23.7213 24.0089 23.6901 23.8637 23.7158 23.7229C23.7415 23.5821 23.8219 23.4572 23.9395 23.3755C24.057 23.2939 24.2022 23.2621 24.3431 23.2872L25.649 23.5246L89.3394 35.1374L103.211 31.3888L110.971 29.2914L128.869 24.4528L129.153 24.3772C129.276 24.3448 129.406 24.3566 129.521 24.4105C129.636 24.4644 129.729 24.557 129.783 24.6722C129.796 24.6997 129.807 24.7287 129.815 24.7585C129.845 24.8717 129.837 24.9914 129.793 25.0997C129.749 25.208 129.671 25.2991 129.57 25.3594Z"
              fill="#9C6AC5"
            />
            <path
              d="M89.8996 95.05C89.7573 95.05 89.6208 94.9938 89.5197 94.8937C89.4187 94.7935 89.3613 94.6575 89.36 94.5153L88.8203 35.6964C88.8191 35.5533 88.8747 35.4155 88.9749 35.3134C89.0752 35.2113 89.2119 35.1532 89.355 35.1519H89.36C89.5022 35.1519 89.6387 35.208 89.7398 35.3082C89.8408 35.4083 89.8983 35.5443 89.8996 35.6866L90.4392 94.5054C90.4405 94.6485 90.3848 94.7863 90.2846 94.8884C90.1843 94.9905 90.0476 95.0486 89.9045 95.05H89.8996Z"
              fill="#9C6AC5"
            />
            <path
              d="M111.253 87.8943L105.594 89.4232L103.209 31.3886L110.969 29.2915C110.935 29.3033 111.249 87.5202 111.253 87.8943Z"
              fill="#9C6AC5"
            />
            <path
              d="M48.1067 62.4466C48.0395 62.4465 47.9724 62.439 47.9068 62.4242L29.1897 58.1977C28.9556 58.1446 28.7522 58.0008 28.6241 57.7978C28.4961 57.5948 28.4538 57.3493 28.5066 57.1151C28.5594 56.881 28.703 56.6774 28.9058 56.5491C29.1086 56.4207 29.354 56.3781 29.5882 56.4306L48.3054 60.6571C48.5227 60.7062 48.7143 60.8336 48.8436 61.0151C48.9728 61.1965 49.0307 61.4193 49.0061 61.6407C48.9816 61.8621 48.8762 62.0667 48.7103 62.2154C48.5444 62.364 48.3295 62.4464 48.1067 62.4466Z"
              fill="white"
            />
            <path
              d="M73.1641 42.7344V53.8991C73.1641 54.3766 73.673 54.682 74.0944 54.4573L77.3335 52.7297C77.5521 52.6131 77.8187 52.6349 78.0155 52.7854L81.8076 55.6853C82.2239 56.0036 82.8245 55.7068 82.8245 55.1827V44.3085C82.8245 44.0041 82.6077 43.7429 82.3085 43.6868L73.9133 42.1126C73.5239 42.0396 73.1641 42.3383 73.1641 42.7344Z"
              fill="#9C6AC5"
              stroke="#9C6AC5"
              strokeWidth="1.26525"
            />
            <path
              d="M65.0664 12.7118L82.3882 1.37388C82.758 1.13181 83.1991 1.02243 83.6391 1.06362L144.205 6.73237C145.951 6.89577 146.613 9.09951 145.246 10.1976L129.156 23.1223"
              stroke="#9C6AC5"
              strokeWidth="1.30131"
              strokeLinecap="round"
            />
            <path
              d="M24.3996 23.773L7.77133 15.5697C6.43153 14.9087 7.07979 12.8797 8.55464 13.118L74.036 23.698C74.341 23.7473 74.6299 23.8682 74.879 24.0509L89.1397 34.5088"
              stroke="#9C6AC5"
              strokeWidth="1.30131"
              strokeLinecap="round"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M129.974 24.0428C130.636 24.8159 131.003 25.7984 131.009 26.8161L131.43 78.7642C131.441 79.6887 131.152 80.5919 130.609 81.3398C130.065 82.0877 129.295 82.6403 128.412 82.9157L91.0123 94.7264C90.592 94.8583 90.154 94.925 89.7135 94.9242C89.4113 94.9256 89.1097 94.8942 88.8142 94.8307L25.8649 81.4371C24.9001 81.2274 24.0357 80.6951 23.4144 79.9279C22.793 79.1607 22.4519 78.2045 22.4473 77.2172V26.5643C22.4475 25.6078 22.7652 24.6784 23.3505 23.9218C23.5957 23.6049 23.882 23.3259 24.2 23.0909L25.0489 23.8185C24.6811 24.0486 24.3612 24.3515 24.1103 24.7109C23.7307 25.2545 23.527 25.9014 23.5265 26.5643V77.2173C23.5298 77.9576 23.7853 78.6747 24.251 79.2502C24.7167 79.8258 25.3646 80.2254 26.0879 80.3831L89.0408 93.7766C89.5878 93.8909 90.155 93.8636 90.6885 93.6974L128.088 81.8868C128.75 81.6797 129.328 81.2648 129.735 80.7037C130.143 80.1425 130.359 79.465 130.351 78.7714L129.93 26.8233C129.927 26.3138 129.804 25.8121 129.57 25.3592C129.337 24.9124 129.003 24.526 128.595 24.2307C128.186 23.9354 127.715 23.7391 127.218 23.6575L65.4159 13.5089C65.2424 13.4801 65.0667 13.4657 64.8907 13.4657C64.6175 13.4645 64.3453 13.4996 64.0813 13.5701L51.7706 16.7539L44.3083 18.6845L41.4606 18.3048L49.3279 16.2682L63.8115 12.5232C64.3931 12.3729 64.9996 12.3459 65.5922 12.4441L127.394 22.5926C128.398 22.7559 129.313 23.2697 129.974 24.0428Z"
              fill="#9C6AC5"
            />
          </svg>
          <p>No item saved on bookmark</p>
        </div>
      )}
    </div>
  )
}
