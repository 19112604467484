import React from 'react'
import styles from '../FinancePayment.module.scss'
import KYCVerifiedEnterCode from '../../SellerFinance/FinancePayment/KYCVerifiedEnterCode'

export default function WithdrawOTPverificationModal() {
  return (
    <div>
      <div className={styles.financePaymentBox}>
        <div className={styles.financePaymentHeading}>
          <h4>Withdraw</h4>
        </div>
        <div>
          <KYCVerifiedEnterCode />
        </div>
      </div>
    </div>
  )
}
