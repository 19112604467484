import { gql } from '@apollo/client'

const GET_MUTUAL_FOLLOWING = gql`
  query GetMyFriends($userId: Int) {
    getMutualFollowing(user_id: $userId) {
      success
      message
      data {
        friend_id
        user {
          id
          firstName
          lastName
          logo_image
          userName
          city
        }
      }
    }
  }
`
export default GET_MUTUAL_FOLLOWING
