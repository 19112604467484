import React, { useCallback, useEffect, useState } from 'react'
import styles from './MobileViewAdjustImageModal.module.scss'
import Cropper from 'react-easy-crop'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'
import getCroppedImg from 'src/utils/helperFuncs'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'

export default function MobileViewAdjustImageModal() {
  const dispatch = useAppDispatch()
  const fetchProductImageResizeData = useSelector((state: any) => state?.productResizeData?.data)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const [selectedMedia, setSelectedMedia] = useState<any>({})
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({ width: 0, height: 0, x: 0, y: 0 })
  const [mediaSize, setMediaSize] = useState({
    width: 0,
    height: 0,
    naturalWidth: 0,
    naturalHeight: 0,
  })

  // useEffect(() => {
  //   if (Boolean(fetchProductImageResizeData?.id?.media?.length)) {
  //     let filteredData = fetchProductImageResizeData?.id?.media?.find(
  //       (me) => me?.id === fetchProductImageResizeData?.id?.selectedIndexofMediaForEdit,
  //     )
  //     if (filteredData) {
  //       setZoom(filteredData?.oldFile?.zoom)
  //       setCrop(filteredData?.oldFile?.crop)
  //       setRotation(filteredData?.oldFile?.rotate)
  //     }
  //   }
  // }, [])

  useEffect(() => {
    if (fetchProductImageResizeData?.id?.media?.length > 0) {
      let imagesMedia =
        fetchProductImageResizeData?.id?.media?.filter(
          (med, index) => med?.id === fetchProductImageResizeData?.id?.selectedIndexofMediaForEdit, //FIELD_CHANGE
        ) &&
        fetchProductImageResizeData?.id?.media?.filter(
          (med, index) => med?.id === fetchProductImageResizeData?.id?.selectedIndexofMediaForEdit, //FIELD_CHANGE
        )[0]
      let finalURL = imagesMedia?.newfile
      // let filteredData = fetchProductImageResizeData?.id?.media?.find(
      //   (me) => me?.id === fetchProductImageResizeData?.id?.selectedIndexofMediaForEdit,
      // )
      if (imagesMedia?.newfile?.type) {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          setSelectedMedia(reader.result)
        })
        reader.readAsDataURL(finalURL)
      } else {
        setSelectedMedia(finalURL)
      }
      // if (imagesMedia) {
      // setZoom(imagesMedia?.oldFile?.zoom)
      // setCrop(imagesMedia?.oldFile?.crop)
      //   setRotation(imagesMedia?.oldFile?.rotate)
      // }
    }
  }, [])

  const onCropComplete = useCallback((croppedArea, croppedAreaPixelsCB) => {
    setCroppedAreaPixels(croppedAreaPixelsCB)
  }, [])

  const handleCloseModal = () => {
    dispatch(
      fetchMobileModalData({
        allModalData: [
          ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
          {
            modal: 'adjustImageMobile',
            isOpen: false,
            id: 0,
            transform: 0,
          },
        ],
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
  }

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(selectedMedia, croppedAreaPixels, rotation)
      let mediaFile = fetchProductImageResizeData?.id?.media
      let mappedData = mediaFile?.map((_) => {
        if (_?.id === fetchProductImageResizeData?.id?.selectedIndexofMediaForEdit) {
          return { ..._, oldFile: { baseURL: croppedImage, zoom: Number(zoom), rotate: Number(rotation), crop: crop } }
        } else {
          return _
        }
      })
      dispatch(
        fetchProductImageResizeModal({
          modal: '',
          isOpen: false,
          id: {
            ...fetchProductImageResizeData?.id,
            media: mappedData,
          },
        }),
      )
      handleCloseModal()
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])

  const handleDelete = () => {
    let removedMedia = fetchProductImageResizeData?.id?.media.filter(
      (url, ind) => ind !== fetchProductImageResizeData?.id?.index,
    )
    dispatch(
      fetchProductImageResizeModal({
        modal: '',
        isOpen: false,
        id: {
          ...fetchProductImageResizeData?.id,
          media: removedMedia,
          isDeleted: fetchProductImageResizeData?.id?.file?.isEdit
            ? [...fetchProductImageResizeData?.id?.isDeleted, fetchProductImageResizeData?.id?.file?.id]
            : [...fetchProductImageResizeData?.id?.isDeleted],
        },
      }),
    )
    dispatch(
      fetchMobileModalData({
        allModalData: [
          ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
          {
            modal: 'adjustImageMobile',
            isOpen: false,
            id: 0,
            transform: 7,
          },
        ],
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
  }

  return (
    <div className={styles.mobileViewAdjustImageModalSection}>
      <div className={styles.mobileViewAdjustImageModalHeaderAlignment}>
        <h6>Adjust Image</h6>
        <a onClick={handleCloseModal}>Cancel</a>
      </div>
      <div className={styles.mobileViewAdjustBodyAlignment}>
        <div className={styles.mobileViewAdjustResizeAlignment}>
          <Cropper
            image={selectedMedia}
            crop={crop}
            rotation={rotation}
            minZoom={1}
            zoom={zoom}
            zoomWithScroll={true}
            showGrid={true}
            aspect={
              fetchProductImageResizeData?.id?.selectedSize?.width /
              fetchProductImageResizeData?.id?.selectedSize?.height
            }
            // objectFit="contain"
            setMediaSize={setMediaSize}
            restrictPosition={true}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onCropAreaChange={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>

        <div className={styles.mobileViewAdjustResizeOptionAlignment}>
          <div className={styles.mobileViewAdjustResizeOptionName}>
            <label>Zoom</label>
            <div className={styles.mobileViewAdjustInputAlignment}>
              <input
                type="range"
                value={zoom}
                min={1}
                max={3}
                step={0.0001}
                aria-labelledby="Zoom"
                onChange={(e: any) => {
                  setZoom(e.target.value)
                }}
              />{' '}
            </div>
          </div>
          {/* <div className={styles.mobileViewAdjustResizeOptionName}>
            <label>Rotate</label>
            <div className={styles.mobileViewAdjustInputAlignment}>
              <input
                type="range"
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Zoom"
                onChange={(e: any) => {
                  setRotation(e.target.value)
                }}
              />
            </div>
          </div> */}
        </div>
      </div>
      <div className={styles.mobileViewAdjustButtonAlignment}>
        <div className={styles.mobileViewDeleteButton} onClick={handleDelete}>
          <button>Delete</button>
        </div>
        <div className={styles.mobileSaveChangesButton} onClick={showCroppedImage}>
          <button>Save Change</button>
        </div>
      </div>
    </div>
  )
}
