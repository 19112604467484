import React from 'react'
import styles from './StoresModal.module.scss'
import NewSuggestedStores from 'src/components/Stores/NewSuggestedStores'
import PopularStoreNew from 'src/components/Stores/PopularStoreNew'
import NewFollowedStore from 'src/components/Stores/NewFollowedStore'
import { useAuth } from 'src/utils/auth'
import SeeAllStore from './SeeAllStore'
import StoreFollowed from './StoreFollowed'
import StoresPopular from './StoresPopular'
import StoresSuggested from './StoresSuggested'
// import PopularStoreNew from 'src/components/Stores/PopularStoreNew'

export default function StoresModal() {
  const { user } = useAuth()
  return (
    // <div className={styles.storeModalSection}>
    //   <div className={styles.storeModalBoxAlignment}>
    //     <div className={styles.storeModalHeadingAlignment}>
    //       <h4>Stores</h4>
    //     </div>
    //   </div>
    //   <div className={styles.storeModalMobileHeaderSticky}>
    //     <div className={styles.storeModalMobileViewHeaderAlignment}>
    //       <h4>Stores</h4>
    //       <div className={styles.mobileViewBackArrowAlignment}>
    //         <svg xmlns="http://www.w3.org/2000/svg" width="37" height="32" viewBox="0 0 37 32" fill="none">
    //           <path
    //             d="M27 0H10C4.47715 0 0 4.47715 0 10V22C0 27.5228 4.47715 32 10 32H27C32.5228 32 37 27.5228 37 22V10C37 4.47715 32.5228 0 27 0Z"
    //             fill="white"
    //           />
    //           <path
    //             d="M21 10L16 16L21 22"
    //             stroke="#808191"
    //             strokeWidth="2"
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //           />
    //         </svg>
    //       </div>
    //     </div>
    //   </div>
    //   <div>
    //     {user ? (
    //       <>
    //         <StoreFollowed />
    //         <StoresPopular />
    //         <StoresSuggested />
    //       </>
    //     ) : (
    //       <>
    //         <StoresSuggested />
    //       </>
    //     )}
    //   </div>
    // </div>
    <div className={styles.storeNewmodalUi}>
      {user ? (
        <>
          <NewFollowedStore />
          <NewSuggestedStores />
          <PopularStoreNew />
        </>
      ) : (
        <SeeAllStore className={''} />
      )}
    </div>
  )
}
