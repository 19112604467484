import { useMutation } from '@apollo/client'
import OtpInput from 'react-otp-input';
import { notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import FORGOT_PASSWORD from 'src/graphql/mutations/forgotPassword'
import VERIFY_OTP_FOR_FORGOT from 'src/graphql/mutations/verifyOTPForForgot'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAppDispatch } from 'src/store/store'

export default function NewVerificationCode() {
  const dispatch = useAppDispatch()
  const guestDetail = useSelector((state: any) => state?.guestAccountInfo?.data)

  const [errors, setErrors] = useState<any>({})
  const [minutes, setMinutes] = useState(1)
  const [seconds, setSeconds] = useState(0)
  const [verificationCode, setVerificationCode] = useState(null)

  const handleBackForgot = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: { ...guestDetail?.id, media: 'login', modalFor: 'forgot' },
      }),
    )
  }

  const handleNewPassword = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: { ...guestDetail?.id, media: 'login', modalFor: 'newPassword', otp: verificationCode },
      }),
    )
  }

  const validateForm = () => {
    let formIsValid = true
    let errors = {}
    if (verificationCode.trim() === '') {
      formIsValid = false
      errors['email'] = '*Please Enter OTP.'
    }
    setErrors(errors)
    return formIsValid
  }

  const [verifyOTPForForgot] = useMutation(VERIFY_OTP_FOR_FORGOT, {
    onCompleted: (data) => {
      if (data?.verifyOTPForForgot?.success) {
        notification['success']({
          message: 'Verified',
          description: 'OTP Verified Successfully',
        })
        handleNewPassword()
      } else {
        notification['error']({
          message: 'Error',
          description: 'Invalid OTP',
        })
      }
    },
    onError: (error) => {
      console.log(error)
      notification['error']({
        message: 'Error',
        description: 'Something went wrong, Please try later',
      })
    },
  })

  const submitCode = async () => {
    // if (validatevalidateForm()) {
    await verifyOTPForForgot({
      variables: {
        otp: verificationCode,
        email: guestDetail?.id?.email,
      },
    })
    // }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (minutes === 0 && seconds === 0) {
        clearInterval(timer)
      } else {
        if (seconds === 0) {
          setMinutes(minutes - 1)
          setSeconds(59)
        } else {
          setSeconds(seconds - 1)
        }
      }
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [minutes, seconds])

  const [forgotPassword] = useMutation(FORGOT_PASSWORD, {
    onCompleted: (data) => {
      notification['success']({
        message: 'E-Mail sent!',
        description: 'Please Check Email',
      })
    },
    onError: (error) => {
      console.log(error)
      notification['error']({
        message: 'Error',
        description: 'Something went wrong, Please try later',
      })
    },
  })

  const resendCode = async () => {
    if (minutes === 0 && seconds === 0) {
      setMinutes(1)
      setSeconds(0)
      await forgotPassword({
        variables: {
          email: guestDetail?.id?.email,
        },
      })
    }
  }

  return (
    <div className="new-verify-code-alignment">
      <div className="new-verify-code-heding-alignment">
        <h4>Enter Security Code</h4>
        <p>Enter the code we just sent to </p>
        <span>{guestDetail?.id?.emails}</span>
      </div>
      <div className="new-verify-code-input-alignment">
        <OtpInput
          value={verificationCode}
          onChange={setVerificationCode}
          numInputs={6}
          renderSeparator={<span className='m-1'></span>}
          inputStyle={{ width: '47px', height: '47px', borderRadius: '8px', border: '1px solid #E2E8F0', backgroundColor: '#F5F7FA' }}
          renderInput={(props) => <input {...props} />}
        />
      </div>

      <div className="new-verify-code-option-alignment incorrect-code-alignment">
        {/* <div className="incorrect-notes-alignment">
          <p>Incorrect code! Please try again.</p>
        </div> */}
        <div className="new-verify-mobile-view">
          <div
            className="resend-code-alignment"
            onClick={() => resendCode()}
            style={minutes === 0 && seconds === 0 ? { opacity: 1 } : { opacity: 0.5 }}
          >
            <a>
              Resend Code
              {minutes > 0 || seconds > 0
                ? `(${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds})`
                : ''}
            </a>
          </div>
          <div className="back-button-alignment rounded-lg bg-[#556EE6] text-white" onClick={submitCode}>
            <button className='!text-white'>Submit</button>
          </div>
          <div className="back-button-alignment flex items-center justify-center" onClick={handleBackForgot}>
            <button className='!w-[100px]'>Back</button>
          </div>
        </div>
      </div>
    </div>
  )
}
