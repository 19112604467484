import React from 'react'
import styles from './MobileviewUserProfileBio.module.scss'

export default function MobileviewUserProfileBio() {
  return (
    <div className={styles.mobileViewUserProfileBioSection}>
      <h4>About Darrell</h4>

      <div className={styles.mobileViewUserProfileDescription}>
        <p>
          Lorem Ipsum is simply dummy text of the big printing and typesetting industry. Lorem Ipsum has been the
          industry's standard, dolor sit amet consec Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard{' '}
        </p>
        <p>
          Lorem Ipsum is simply dummy text of the big printing and typesetting industry. Lorem Ipsum has been the
          industry's standard.
        </p>
      </div>
    </div>
  )
}
