import React from 'react'
import { useSelector } from 'react-redux'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAppDispatch } from 'src/store/store'

export default function MobileMessageChatOptionModal() {
  const dispatch = useAppDispatch()
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)

  return (
    <div className="more-option-modal-body-details-alignment">
      <ul>
        <li
          className="active-more-option"
        // onClick={() => setIsSearchtextModal(true)}
        >
          <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                stroke="#636773"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.0004 20.9984L16.6504 16.6484"
                stroke="#636773"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {/* 
                <img src="/assets/icon/search-black-icon.svg" alt="search" className="active-img" />

                <img src="/assets/icon/message-search-grey-icon.svg" alt="search" className="defalt-img" /> */}
          </div>
          <p>Search Message</p>
        </li>
        <li
          onClick={() => {
            dispatch(
              fetchMobileModalData({
                ...fetchMobileModal,
                secondModal: {
                  modal: 'messageMediaModal',
                  isOpen: true,
                  id: 0,
                  transform: 128,
                  zIndex: 99999999,
                  modalFor: 'mobileSecondModal',
                },
              }),
            )
          }}
        >
          <div>
            <img src="/assets/icon/media-grey-icon.svg" alt="search" className="defalt-img" />

            <img src="/assets/icon/media-black-icon.svg" alt="search" className="active-img" />
          </div>
          <p>Media</p>
        </li>
        <li>
          <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.7295 21C13.5537 21.3031 13.3014 21.5547 12.9978 21.7295C12.6941 21.9044 12.3499 21.9965 11.9995 21.9965C11.6492 21.9965 11.3049 21.9044 11.0013 21.7295C10.6977 21.5547 10.4453 21.3031 10.2695 21"
                stroke="#636773"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.6303 13C18.1855 11.3714 17.9734 9.68804 18.0003 8"
                stroke="#636773"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.26 6.25977C6.08627 6.82338 5.99861 7.40999 6 7.99977C6 14.9998 3 16.9998 3 16.9998H17"
                stroke="#636773"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.9999 8.00011C18.0015 6.91318 17.7079 5.84622 17.1503 4.91321C16.5927 3.98019 15.7921 3.21617 14.834 2.70275C13.876 2.18934 12.7965 1.94581 11.7108 1.99818C10.6251 2.05056 9.57409 2.39686 8.66992 3.00011"
                stroke="#636773"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M2 2L22 22" stroke="#636773" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

            {/* <img src="/assets/icon/mute-black-icon.svg" alt="search" className="active-img" /> */}
          </div>
          <p>Mute</p>
        </li>
        {/* <li>
          <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M22 16.3333C22 16.9227 21.7659 17.4879 21.3491 17.9047C20.9324 18.3214 20.3671 18.5556 19.7778 18.5556H7.03618C6.6573 18.5556 6.29393 18.7061 6.02603 18.974L4.43872 20.5613C3.53877 21.4612 2 20.8238 2 19.5511V5.22222C2 4.63285 2.23413 4.06762 2.65087 3.65087C3.06762 3.23413 3.63285 3 4.22222 3H19.7778C20.3671 3 20.9324 3.23413 21.3491 3.65087C21.7659 4.06762 22 4.63285 22 5.22222V16.3333Z"
                stroke="#636773"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M12 7V12" stroke="#636773" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M12 15H12.01" stroke="#636773" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <p>Report</p>
        </li> */}
        <li>
          <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 6H5H21" stroke="#E71616" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path
                d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                stroke="#E71616"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M10 11V17" stroke="#E71616" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M14 11V17" stroke="#E71616" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <p className="delete-text">Delete Chat</p>
        </li>
      </ul>
    </div>
  )
}
