import { gql } from '@apollo/client'

const GET_SHIPPING_POLICY = gql`
query GetStoreShippingPolicy($input: getStoreShippingPolicyReq) {
  getStoreShippingPolicy(input: $input) {
    success
    message
    data {
      name
      shippingPolicy
    }
  }
}
`
export default GET_SHIPPING_POLICY
