import gql from 'graphql-tag'

const GET_COMMENT_COUNT = gql`
  query GetCommentCount($getCommentCountId: Int!, $commentFor: String!) {
    getCommentCount(id: $getCommentCountId, comment_for: $commentFor) {
      count
    }
  }
`
export default GET_COMMENT_COUNT
