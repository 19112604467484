import { initializeApp } from 'firebase/app'
import { FacebookAuthProvider, GoogleAuthProvider, TwitterAuthProvider, getAuth } from 'firebase/auth'

const webkey = 'AIzaSyCdbLlkYvz4YMw4wGsgSRyFNaLtjZYiRzQ'

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_APP_ID,
}

const app = initializeApp(firebaseConfig)

export const provider = new GoogleAuthProvider()
export const facebookProvider = new FacebookAuthProvider()
export const TwitterProvider = new TwitterAuthProvider()

export const auth = getAuth(app)
