import { gql } from '@apollo/client'

const SHARE_MESSAGE = gql`
  mutation ShareMessage($productId: Int!, $userId: [Int!], $type: String) {
    shareMessage(product_id: $productId, user_id: $userId, type: $type) {
      message
      success
    }
  }
`
export default SHARE_MESSAGE
