import React from 'react'
import styles from './PeopleFollowers.module.scss'
import { useAuth } from 'src/utils/auth'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import LazyImage from 'src/utils/LazyImage'
import Link from 'next/link'
import { fetchSearchData } from 'src/store/slices/searchModalSlice'

export default function PeopleItem({ people, declineFriendRequestCall, sendFriendRequestCall }) {
  
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const { user } = useAuth()
  const dispatch = useAppDispatch()
  const fetchSearchModalData = useSelector((state: any) => state?.searchModalSlice?.data)

  const handelImageSlider = () => {}

  const handleCloseModel = () => {
    dispatch(fetchSearchData({ ...fetchSearchModalData, isOpen: false, id: {}, search: '', searchPreview: '' }))
    dispatch(
      fetchModalData({
        allModalData: [
          ...getAllState?.allModalData,
          { modal: 'peopleModal', isOpen: false, id: 0, modalFor: 'bigModal' },
        ],
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  return (
    <div className={styles.peopleFollowersGridItemAlignment}>
      <div className={styles.peopleFollowersChildDetails}>
        <div className={styles.peopleFollowersChildImg}>
          <LazyImage
            image={{
              src: people?.logo_image
                ? people?.logo_image
                : 'https://bluejestic-media.storage.googleapis.com/demo-test/dummy-profile (1)-1669122101773-764781589-1669182656605-3255156-1694692268774-918686311.jpg',
              alt: 'Icon',
            }}
            handleClickOnImage={() => handelImageSlider()}
            borderRadius={'50%'}
          />
        </div>
        <div className={styles.peopleFollowersChildAllDetails}>
          {/* <Link href={`/user/${people?.userName}`}> */}
          <Link href={{ pathname: `/user/[id]`, query: { id: people?.userName } }}>
            <div className={styles.PeopleFollowersname}>
              <h6 onClick={() => handleCloseModel()}>{people?.firstName + ' ' + people?.lastName}</h6>
            </div>
          </Link>
          {/* <p>{people?.jobTitle ? people?.jobTitle : ''}</p> */}
          <p>{people?.jobTitle ? people?.jobTitle : ''}</p>
          <span>
            {people?.followers_count || 0} {people?.followers_count > 1 ? 'Followers' : 'Follower'}
          </span>
          <div className={styles.peopleFollowersButtonAlignment}>
            {people?.id !== user?.id ? (
              people?.isFollow ? (
                <button className={styles.following} onClick={() => declineFriendRequestCall(people)}>
                  Following
                </button>
              ) : (
                <button onClick={() => sendFriendRequestCall(people)}>Follow</button>
              )
            ) : (
              <button>You</button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
