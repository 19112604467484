import React from 'react';

const CartReminder: React.FC<any> = ({ notification, setOpen }: any) => {
    const payload = notification?.payload;
    return (
        <div className="py-4 px-1 sm:px-3 md:px-6">
            <div className="flex items-start space-x-5 p-4 hover:bg-gray-50">
                {payload?.product && (
                    <div className="flex-shrink-0 w-[56px] h-[56px] rounded-lg overflow-hidden  border-white bg-gray-100">
                        <img
                            src={payload.product}
                            alt={payload.productName || "Product"}
                            width={56}
                            height={56}
                            className="w-full h-full object-cover"
                        />
                    </div>
                )}
                <div className="">
                    <div className="flex items-center">
                        <div>
                            <span className="text-sm font-semibold text-gray-900 mr-2">Cart Reminder: 🛒</span>
                            <span className="">{payload?.message}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartReminder;