import React, { useEffect, useState } from 'react'
import { useAuth } from 'src/utils/auth'
import { Checkbox } from 'antd'
import { fetchProductShareOptions } from 'src/store/slices/productShareOptionsSlice'
import { useAppDispatch } from 'src/store/store'
import FramerAnimationModal from 'src/utils/FramerAnimationModal'
import { useQuery } from '@apollo/client'
import GET_USER_BY_NAME from 'src/graphql/queries/getUserByName'
import client1 from 'src/utils/apolloClient'
import { useRouter } from 'next/router'
import { clubMembersInvite } from 'src/store/slices/inviteClubMebersSlice'
import { useSelector } from 'react-redux'
import GET_MUTUAL_FOLLOWING from 'src/graphql/queries/sellerQueries/getMutualFollowing'

export default function InviteClubMemberModel() {
  const dispatch = useAppDispatch()
  const { user } = useAuth()
  const router = useRouter()
  const [search, setSearch] = useState<any>('')
  const [followers, setFollowers] = useState<any>([])
  const [friendsData, setFriendsData] = useState<any>([])
  const inviteClubMembers = useSelector((state: any) => state?.manageInviteClubMemberSlice?.data)

  const handleChange = (e) => {
    setSearch(e.target.value)
  }

  useEffect(() => {
    globalSearchCall()
  }, [inviteClubMembers?.data])

  const globalSearchCall = async () => {
    const { data }: any = await client1.query({
      query: GET_MUTUAL_FOLLOWING,
      // variables: { slug: user?.userName },
    })
    setFriendsData(data?.getMutualFollowing?.data)
  }

  let selectedFam = followers?.filter((fam) => fam.isChecked)

  const handleCancel = () => {
    dispatch(
      clubMembersInvite({
        data: {
          showModel: false,
        },
      }),
    )
  }

  const handleUnselect = () => {
    setFollowers(
      followers?.map((fam) => {
        return { ...fam, isChecked: false }
      }),
    )
  }

  function onChange(e, fam) {
    setFollowers(
      followers?.map((follow) => {
        if (follow?.user?.id === fam?.user?.id) {
          return { ...follow, isChecked: e.target.checked }
        } else {
          return { ...follow }
        }
      }),
    )
  }

  useEffect(() => {
    setFollowers(friendsData || [])
  }, [friendsData])

  return (
    <div className="dropdown-modal-details">
      <div className="dropdown-modal-wrapper">
        <FramerAnimationModal>
          <div className="dropdown-modal-box-alignment">
            <div className="bg-white rounded-xl -m-16">
              <div className="modal-header flex justify-between items-center px-8 py-4">
                <p className=" text-black font-medium text-lg">Share with Friends</p>
                <button onClick={handleCancel}>
                  <img src="/assets/img/feed/ic_Close.svg" />
                </button>
              </div>
              <div style={{ padding: '16px' }}>
                <div className="rounded-2xl h-16 w-full border border-gray-300 items-center px-3 mb-2 relative">
                  <img src="/assets/icon/search-icon-grey.svg" alt="search" className="absolute top-4" />
                  <input
                    type={'text'}
                    placeholder="Search"
                    onChange={(e) => handleChange(e)}
                    className="w-full h-full pl-10"
                  ></input>
                </div>
                {selectedFam?.length > 0 && (
                  <div className="flex justify-between items-center mt-3">
                    <div className="text-sm text-gray-400 font-normal px-4">{selectedFam?.length} friends selected</div>
                    <div className="text-sm text-blue-500 cursor-pointer" onClick={() => handleUnselect()}>
                      Unselect All
                    </div>
                  </div>
                )}
                <div className="invite-modal" style={{ height: '336px', overflow: 'scroll' }}>
                  {
                    (search
                      ? followers?.filter(
                          (supplier) =>
                            supplier?.user?.firstName?.toLowerCase().includes(search?.toLowerCase()) ||
                            supplier?.user?.lastName?.toLowerCase().includes(search?.toLowerCase()) ||
                            (supplier?.user?.firstName + ' ' + supplier?.user?.lastName)
                              ?.toLowerCase()
                              .includes(search?.toLowerCase()) ||
                            (supplier?.user?.firstName + supplier?.user?.lastName)
                              ?.toLowerCase()
                              .includes(search?.toLowerCase()),
                        )
                      : followers
                    )?.map((fam, index) => {
                      return (
                        <div
                          className={`rounded-2xl w-full flex justify-between items-center px-4 py-1 py-2 my-2 hover:bg-indigo-50 ${
                            fam?.isChecked && 'bg-indigo-50'
                          }`}
                          key={index}
                        >
                          <div className="flex justify-start items-center">
                            <div className="relative">
                              <img
                                src={fam?.user.logo_image}
                                className="rounded-full mr-4 object-cover"
                                style={{ width: '36px', height: '36px' }}
                              />
                              <div className="absolute flex justify-center h-3 w-3 bg-green-500 rounded-full z-10 bottom-0 right-7"></div>
                            </div>
                            <div>
                              <div className="text-sm font-medium text-black cursor-default">
                                {(fam?.user?.firstName ? fam?.user?.firstName : '') +
                                  '  ' +
                                  (fam?.user?.lastName ? fam?.user?.lastName : '')}
                              </div>
                            </div>
                          </div>
                          <Checkbox onChange={(e) => onChange(e, fam)} checked={fam?.isChecked}></Checkbox>
                        </div>
                      )
                    })
                    // ) : (
                    //   <div className="flex justify-center items-center h-full text-base text-gray-400">Not found</div>
                    // )
                  }
                </div>
                <div className="border-t -mx-6 px-6 pt-4">
                  <div className="flex gap-5">
                    <div className="form-details-alignment">
                      <input type="text" placeholder="Type your message here" />
                    </div>
                    <div className="send-button-align">
                      <button>Send</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FramerAnimationModal>
      </div>
    </div>
  )
}
