import { gql } from '@apollo/client'

const query = gql`
  mutation SEND_INVITATION($emails: [String]) {
    sendInvitation(emails: $emails) {
      message
      success
    }
  }
`
export default query
