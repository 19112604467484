import React, { useState } from 'react'
import { X } from 'lucide-react'
import Select from 'react-select'
import 'react-country-state-city/dist/react-country-state-city.css'
import { Country, State } from 'country-state-city'
import { isEmpty } from 'src/utils/isEmpty'
import { notification } from 'antd'

interface TaxRuleModalProps {
  isOpen: boolean
  onClose: () => void
  onSave: (rule: TaxRule) => void
}

export interface TaxRule {
  country: string
  state: string
  rate_percentage: number
}

export default function TaxRuleModal({ isOpen, onClose, onSave }: TaxRuleModalProps) {
  const [country, setCountry] = useState(null)
  const [state, setState] = useState(null)
  const [rate_percentage, setRatePercentage] = useState('')
  const [states, setStates] = useState([])

  if (!isOpen) return null
  const countries = Country.getAllCountries().map((country) => ({
    value: country.isoCode,
    label: country.name,
  }))

  const handleCountryChange = (option) => {
    const country = option?.label || null
    setCountry(country)

    if (option?.value) {
      const fetchedStates = State.getStatesOfCountry(option.value).map((state) => ({
        value: state.isoCode,
        label: state.name,
      }))
      setStates(fetchedStates)
    } else {
      setStates([])
    }
    setState(null)
  }

  const handleStateChange = (option) => {
    setState(option?.label)
  }
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    console.log(`country`, country, `state`, state, `rate_percentage`, rate_percentage)
    if (isEmpty(country) || isEmpty(state) || isEmpty(rate_percentage)) {
      notification['error']({
        message: 'Error',
        description: 'Please fill all details.',
      })
    } else {
      onSave({
        country: country,
        state: state,
        rate_percentage: parseFloat(rate_percentage),
      })
      setCountry(null)
      setState(null)
      setRatePercentage('')
      onClose()
    }
  }

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl w-full max-w-md mx-4">
        <div className="flex items-center justify-between p-6 border-b border-gray-100">
          <h2 className="text-xl font-semibold text-gray-800">Add Tax Rule</h2>
          <button onClick={onClose} className="p-2 text-gray-400 hover:text-gray-600 rounded-lg">
            <X size={20} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Country</label>
            <Select
              options={countries}
              onChange={handleCountryChange}
              value={countries?.find((option) => option.label === country)}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">State/Region</label>
            <Select
              options={states}
              onChange={(option) => handleStateChange(option)}
              value={(() => {
                const selectedState = states?.find((option) => option.label == state)
                return selectedState == undefined ? null : selectedState
              })()}
              // isDisabled={!country}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Tax Rate (%)</label>
            <input
              type="number"
              value={rate_percentage}
              onChange={(e) => setRatePercentage(e.target.value)}
              className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
              placeholder="Enter tax rate"
              min="0"
              max="100"
              step="0.01"
              required
            />
          </div>

          <div className="flex justify-end gap-4 pt-4">
            <button type="button" onClick={onClose} className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg">
              Cancel
            </button>
            <button type="submit" className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700">
              Add Rule
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
