import React from 'react'
const customStyles = {
  avatarContainer: {
    width: 40,
    height: 40,
    marginLeft: 0,
    borderRadius: 40,
    boxShadow: '0 0 6px rgba(0,0,0,0.2)',
  },
}
export default function PopOverComments(props) {
  return (
    <div>
      <div className="flex w-64 truncate">
        <img src={props.avtar} style={customStyles.avatarContainer} />
        <div className="pl-2">
          <p className="text-sm underline decoration-1  text-black font-medium">{props.name}</p>
          <span className="text-xs text-gray font-medium block " style={{ fontSize: '12px' }}>
            {props.comment}
          </span>
        </div>
      </div>
    </div>
  )
}
