import { gql } from '@apollo/client'

const GET_POPULAR_STORES = gql`
  query GET_STORE_BY_CATEGORY($category: [Int], $subCategory: [Int], $limit: Int, $page: Int) {
    getAllPopularStores(category: $category, subCategory: $subCategory, limit: $limit, page: $page) {
      success
      message
      data {
        stores {
          id
          name
          logo_image
          banner_image
          isFollow
          isLike
          like_count
          followers_count
          product_count
          product_images
          slug
        }
      }
    }
  }
`
export default GET_POPULAR_STORES
