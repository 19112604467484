import React, { useEffect, useState } from 'react'
import styles from './SharedCollectionModal.module.scss'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useAuth } from 'src/utils/auth'
import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/client'
import GET_ALL_BOOKMARK_COLLECTIONS from 'src/graphql/queries/getBookmarkCollections'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useRouter } from 'next/router'
import GET_SHARED_COLLECTION from 'src/graphql/queries/getSharedCollection'
const BookmarkImg = '/assets/img/bookMark-img.png'
const ProfileImg = '/assets/img/profile/profileimage.png'

export default function SharedCollectionModal() {
  const dispatch = useAppDispatch()
  const { user }: any = useAuth()
  const router = useRouter()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const { loading, error, data, refetch } = useQuery(GET_SHARED_COLLECTION)

  // ---------------- Windows issue ---------------
  let width: number = 0
  if (typeof window !== 'undefined') {
    width = window.innerWidth
  }
  const [isDesktop, setIsDesktop] = useState(width >= 768)

  const handleSharePost = (item: { id: string | number; slug: string; name: string; user: { userName: string; logo_image: string; firstName: string; lastName: string }; isPrivate: boolean; product_images: string[]; product_count: number; likes: number }) => {
    if (isDesktop) {
      // dispatch(
      //   fetchModalData({
      //     allModalData: [
      //       ...getAllState?.allModalData,
      //       { modal: 'bookmarksharedcollectionModal', isOpen: true, item: item?.id, modalFor: 'bigModal' },
      //     ],
      //     lastModalData: getAllState?.lastModalData,
      //   }),
      // )
      // router.push(`/shared-collection/${item?.slug}`)
      // if (item?.isPrivate) {
      //   router.push(`/boomark-collection/${item?.slug}`)
      // } else {
      router.push(`/user/${item?.user?.userName}/collection/${item?.slug}`)
      // }
    } else {
      dispatch(
        fetchMobileModalData({
          allModalData: [
            ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
            {
              modal: 'mobileViewFriendCollectionModal',
              isOpen: true,
              id: item?.slug,
              transform: 110,
            },
          ],
          lastModalData: fetchMobileModal?.lastModalData ? fetchMobileModal?.lastModalData : [],
        }),
      )
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(width >= 768)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    refetch()
  }, [])

  return (
    <div className={styles.sharedCollectionModalAlignment}>
      <div className={styles.mobilesSharedCollectionDetailsSerch}>
        <div className={styles.mobileSearchIcon}>
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path
              d="M19 27C23.4183 27 27 23.4183 27 19C27 14.5817 23.4183 11 19 11C14.5817 11 11 14.5817 11 19C11 23.4183 14.5817 27 19 27Z"
              stroke="#ACB1C0"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M29.0004 28.9999L24.6504 24.6499"
              stroke="#ACB1C0"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <input type="text" placeholder="Search Collections" />
      </div>
      {
        data?.getSharedCollection?.length > 0 ? (
          <div className={styles.sharedBookMarkDataAlignment}>
            <div className={styles.sharedCollectionGridAlignment}>
              {data?.getSharedCollection?.map((item: { id: string | number; slug: string; name: string; user: { userName: string; logo_image: string; firstName: string; lastName: string }; isPrivate: boolean; product_images: string[]; product_count: number; likes: number }, index: number) => {
                return (
                  <div className={styles.sharedCollectionGridItem} onClick={() => handleSharePost(item)} key={index}>
                    <div className={styles.bookMarkCollectionMainDetailsAlignment}>
                      <div className={styles.bookMarkCollectionCardDetails}>
                        {Boolean(item?.product_images?.length) ? (
                          item?.product_images.slice(0, 3).map((images:any, i:number) => {
                            return (
                              <div key={`t${i}`} className={styles.bookMarkCollectionCardImg}>
                                <img src={images} alt="bootmark-img" />
                              </div>
                            )
                          })
                        ) : (
                          <div className={styles.bookMarkCollectionCardImg}>
                            <img src="/assets/seller/img/shoes0.png" alt="bootmark-img" />
                          </div>
                        )}
                      </div>
                      <div className={styles.sharedBookMarkCollectionNamesAlignment}>
                        <h6>{item?.name}</h6>
                        <div className={styles.sharedCollectionProfileAlignment}>
                          <div className={styles.sharedProfileImg}>
                            <img src={item?.user?.logo_image ? item?.user?.logo_image : ProfileImg} alt="ProfileImg" />
                          </div>
                          <p>{item?.user?.firstName + ' ' + item?.user?.lastName}</p>
                        </div>

                        <div className={styles.sharedCollectionBottomDetails}>
                          <span>{item?.product_count} Products</span>
                          <p></p>
                          <span>{item?.likes} likes</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        ) : (
          // ========================= No Public Collection =========================
          <div className={styles.sharedNoCollectionData}>
            <img src="/assets/icon/no-bookmark-img.svg" alt="no bookmark icon" />
            <p>No public collection yet. Be the first to share your collection!</p>
          </div>
        )
        //  ========================= No Public Collection End =========================
      }
    </div>
  )
}
