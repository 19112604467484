import React, { useState } from 'react'
import SellerLeftSidebar from 'src/components/Seller/SellerLeftSidebar'
import SellerProtectedRoute from 'src/hoc/SellerProtectedRoute'
import MobileViewSellerSidebar from './MobileViewSellerSidebar'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { fetchPageScrollEventSlice } from 'src/store/slices/pageScrollEventSlice'
import { useAuth } from 'src/utils/auth'
import { useRouter } from 'next/router'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'
import { fetchProductErrorHandler } from 'src/store/slices/productErrorHandlerSlice'
import {
  Home,
  ShoppingBag,
  Users,
  Settings,
  MessageSquare,
  Video,
  Package,
  TrendingUp,
  ShoppingCart,
  Bell,
} from 'lucide-react'

// const SellerSidebar = ({ social-activity/children }) => {
const SellerSidebar = ({ children }) => {
  const router = useRouter()
  const path = router.asPath
  const { user }: any = useAuth()
  const dispatch = useAppDispatch()
  const fetchSroll = useSelector((state: any) => state?.pageScrollEventSlice?.data)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const fetchProductImageResizeData = useSelector((state: any) => state?.productResizeData?.data)
  const [isDashboardSidebarModal, setIsDashboardSidebarModal] = useState(false)
  const [openSidebar, setOpenSidebar] = useState(false)
  const sellerOnboarding = path.split('/')
  const navigation = [
    { name: 'Dashboard', href: '/seller/dashboard', icon: Home },
    { name: 'Products', href: '/seller/products', icon: Package },
    { name: 'Orders', href: '/seller/orders', icon: ShoppingCart },
    { name: 'Customers', href: '/seller/customers', icon: Users },
    { name: 'Live Stream', href: '/seller/live-stream', icon: Video },
    { name: 'Chat', href: '/seller/chat', icon: MessageSquare },
    { name: 'Analytics', href: '/seller/analytics', icon: TrendingUp },
    { name: 'Settings', href: '/seller/settings', icon: Settings },
  ]

  React.useEffect(() => {
    if (isDashboardSidebarModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [isDashboardSidebarModal])

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop
    dispatch(
      fetchPageScrollEventSlice({
        scroll: scrollPosition,
      }),
    )
  }

  const renderSentence = () => {
    switch (router?.pathname) {
      case '/seller/dashboard':
        return 'Dashboard'
      case '/seller/social-activity':
        return 'Social Activity'
      case '/seller/insight':
        return 'Insight'
      case '/seller/finance':
        return 'Finance'
      case '/seller/products':
        return 'Product'
      case '/seller/orders':
        return 'Orders'
      case '/seller/order-shipping':
        return 'Order & Shipping'
      case '/seller/setting':
        return 'Settings'
      default:
        return ''
    }
  }

  const handleClose = (data) => {
    setIsDashboardSidebarModal(data)
  }

  return (
    <>
      {/* Desktop Layout */}
      <div className="hidden md:flex">
        <div className="w-[280px] flex-shrink-0">
          <SellerLeftSidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
        </div>
        <div className="flex-1 min-h-screen" style={{ background: '#ECEEF4' }}>
          <div className="max-w-screen-2xl mx-auto px-24 pt-8">
            {children}
          </div>
        </div>
      </div>
      
      {/* Mobile Layout */}
      <div className="block md:hidden">
        <div className="mobile-view-seller-sidebar">
          <div className="mobileViewScrollHeader">
            <div
              className={
                fetchSroll?.scroll > 220
                  ? 'mobileViewScrollHeaderAlignment'
                  : 'mobileViewScrollHeaderAlignment no-border-alignment'
              }
            >
              <div className="mobileViewBackIcon" onClick={() => router.back()}>
                <img src="/assets/icon/Back.svg" alt="back" />
              </div>
              <div className="mobileViewScrollProfileDetailsAlignment">
                <div className="mobileViewScrollProfileDetailsImg">
                  <img src={user?.store?.logo_image} alt="seller profile img" />
                </div>
                <h6>{user?.store?.name}</h6>
              </div>
            </div>
          </div>
          
          <div
            className={
              fetchSroll?.scroll > 220 ? 'sellerMobileViewSidebarDetails' : 'sellerMobileViewSidebarDetails show'
            }
          >
            <div className="sellerMobileHedingFlex">
              <div className="sellerMenuIcon" onClick={() => setIsDashboardSidebarModal(true)}>
                <img src="/assets/seller/icons/menu.svg" alt="menu icon" />
              </div>
              <h4>{renderSentence()}</h4>
            </div>

            {router?.pathname === '/seller/products' && (
              <div
                className="sellerMobileViewButton"
                onClick={() => {
                  dispatch(
                    fetchModalData({
                      allModalData: [
                        ...getAllState?.allModalData,
                        { modal: 'addProductModal', isOpen: true, id: 0, modalFor: 'productModal', isEdit: false },
                      ],
                      lastModalData: getAllState?.lastModalData,
                    }),
                  )
                  dispatch(
                    fetchProductImageResizeModal({
                      modal: '',
                      isOpen: false,
                      id: {
                        ...fetchProductImageResizeData?.id,
                        prevImages: [],
                        prevVideos: [],
                        isDeleted: [],
                        selectedIndexofMediaForEdit: '',
                        selectedSize: { width: 1080, height: 1080 },
                        isSelected: false,
                        ratio: '1 : 1',
                        media: [],
                      },
                    }),
                  )
                  dispatch(
                    fetchProductErrorHandler({
                      errors: {},
                      focusFiled: '',
                    }),
                  )
                }}
              >
                <button>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M8 3.33398V12.6673"
                      stroke="#556EE6"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.33301 8H12.6663"
                      stroke="#556EE6"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  New Product
                </button>
              </div>
            )}

            {router?.pathname === '/seller/orders' && (
              <div
                className="sellerMobileViewButton"
                onClick={() => {
                  dispatch(
                    fetchModalData({
                      allModalData: [
                        ...getAllState?.allModalData,
                        { modal: 'addOrderModal', isOpen: true, id: 0, modalFor: 'orderModal', isEdit: false },
                      ],
                      lastModalData: getAllState?.lastModalData,
                    }),
                  )
                  dispatch(
                    fetchProductImageResizeModal({
                      modal: '',
                      isOpen: false,
                      id: {
                        ...fetchProductImageResizeData?.id,
                        prevImages: [],
                        prevVideos: [],
                        isDeleted: [],
                        selectedIndexofMediaForEdit: '',
                        selectedSize: { width: 1080, height: 1080 },
                        isSelected: false,
                        ratio: '1 : 1',
                        media: [],
                      },
                    }),
                  )
                  dispatch(
                    fetchProductErrorHandler({
                      errors: {},
                      focusFiled: '',
                    }),
                  )
                }}
              >
                <button>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M8 3.33398V12.6673"
                      stroke="#556EE6"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.33301 8H12.6663"
                      stroke="#556EE6"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  New Order
                </button>
              </div>
            )}

            {router?.pathname === '/seller/setting' && (
              <div className="seller-mobile-view-save-button">
                <button>Save </button>
              </div>
            )}
          </div>
          <div
            className={`mobile-view-seller-follow-followingOverlay ${isDashboardSidebarModal ? 'open' : ''}`}
            onClick={() => setIsDashboardSidebarModal(false)}
          />
          <div className={`mobile-view-seller-follow-following-modal ${isDashboardSidebarModal ? 'open' : ''}`}>
            <MobileViewSellerSidebar handleClose={handleClose} />
          </div>
        </div>
        <div className="flex-1 pt-8" style={{ background: '#ECEEF4' }}>
          {children}
        </div>
      </div>
    </>
  )
}

export default SellerSidebar
