import React, { useState } from 'react'
import styles from './BookMarkProductCard.module.scss'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { useAuth } from 'src/utils/auth'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import moment from 'moment'
import { useRouter } from 'next/router'
import LazyImage from 'src/utils/LazyImage'
import Link from 'next/link'

interface Collection {
  isPrivate: boolean;
  slug: string;
  name: string;
}

interface Product {
  id: string | number;
  slug?: string;
  images?: { src: string }[];
  title?: string;
  dis_price?: number;
  dis_listPrice?: number;
  bookmark?: {
    id: string | number;
    collection?: Collection;
    [key: string]: any;
  } | null;
  likes?: { id: string | number };
  [key: string]: any;
}

interface ModalData {
  modal: string;
  [key: string]: any;
}

interface BookMarkProductCardProps {
  item?: Product;
  index: number;
  isInCollection?: boolean;
  handleRedirect?: () => void;
  handleAddToCollection?: (product: Product) => void;
  normalPostLikeHandler?: (item: Product) => void;
  bookmarkProduct?: (product: Product) => void;
  isFilter?: boolean;
  onShareData?: (item: Product) => void;
}

export default function BookMarkProductCard({
  item,
  index,
  isInCollection,
  handleRedirect,
  handleAddToCollection,
  normalPostLikeHandler,
  bookmarkProduct,
  isFilter,
  onShareData,
}: BookMarkProductCardProps) {
  const dispatch = useAppDispatch()
  const { user } = useAuth()
  const router = useRouter()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  const handleShareData = (prod: Product) => {
    if (user) {
      dispatch(
        fetchProductImageResizeModal({
          modal: 'mobileProductDetailsShareModal',
          isOpen: true,
          shareData: prod,
          singleBookmarkProduct: {
            id: prod?.id,
            isProduct: true,
          },
          price: item?.dis_listPrice,
          dis_price: item?.dis_price,
          product_id: prod?.id,
        }),
      )
      // dispatch(
      //   fetchModalData({
      //     allModalData: [
      //       ...getAllState?.allModalData,
      //       { modal: '', id: prod?.id, modalFor: 'bigModal' },
      //     ],
      //     lastModalData: getAllState?.lastModalData,
      //   }),
      // )
    } else {
      handleRedirect?.()
    }
  }

  const handleMovetoCollection = (collection: Collection) => {
    if (collection?.isPrivate) {
      router.push(`/boomark-collection/${collection?.slug}`)
    } else {
      router.push(`/shared-collection/${collection?.slug}`)
    }
  }

  const Redirect = (item: Product) => {
    if (item?.slug) {
      router.push(`/product/${item?.slug}`)
      let filtredModal = getAllState?.allModalData?.filter((item: ModalData) => item?.modal !== getAllState?.lastModalData?.modal)
      dispatch(
        fetchModalData({
          ...getAllState,
          allModalData: filtredModal,
          lastModalData: getAllState?.lastModalData,
        }),
      )
    }
  }

  return (
    // <Link href={`/product/${item?.slug}`}>
    <div
      key={index}
      className={styles.bookMarkProductCardAlignment}
      onClick={() => item && Redirect(item)}
    // onClick={() => {
    //   dispatch(
    //     fetchModalData({
    //       allModalData: [
    //         ...getAllState?.allModalData,
    //         {
    //           modal: 'productDetail',
    //           isOpen: true,
    //           store_id: 0,
    //           id: item?.id,
    //           modalFor: 'bigModal',
    //           startDate: moment().toISOString(),
    //         },
    //       ],
    //       lastModalData: getAllState?.lastModalData,
    //     }),
    //   )
    // }}
    >
      <div className={styles.modileViewImg}>
        <LazyImage
          image={{
            src: item?.images && item.images[0]?.src ? item.images[0].src : '/assets/img/new-bookmark-img.png',
            alt: 'product img',
          }}
          objectFit={'cover'}
          height={299}
          className={styles.imagecontainer}
        />
        {/* <img src={item?.images[0]?.src ? item?.images[0]?.src : '/assets/img/new-bookmark-img.png'} alt="product img" /> */}
        <div
          className={styles.mobileViewBookMarkAlignment}
          onClick={(e) => {
            e.stopPropagation()
            if (item && bookmarkProduct) {
              bookmarkProduct(item)
            }
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M19 21L12 16L5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21Z"
              fill={item?.bookmark ? 'white' : 'none'}
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className={styles.bookmarkBottomDetailsAlignment}>
        <div className={styles.bookMarkProductName}>
          <h6>{item?.title}</h6>
        </div>
        <div className={styles.priceAlignmentFlex}>
          <span>${item?.dis_price ? item?.dis_price : 0}</span>
          <div className={styles.rightSideAlignment}>
            <div
              className={styles.rightSideOption}
              onClick={(e) => {
                e.stopPropagation()
                if (item && bookmarkProduct) {
                  bookmarkProduct(item)
                }
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path
                  d="M17.4154 19.25L10.9987 14.6667L4.58203 19.25V4.58333C4.58203 4.0971 4.77519 3.63079 5.119 3.28697C5.46282 2.94315 5.92913 2.75 6.41536 2.75H15.582C16.0683 2.75 16.5346 2.94315 16.8784 3.28697C17.2222 3.63079 17.4154 4.0971 17.4154 4.58333V19.25Z"
                  fill={item?.bookmark ? 'white' : 'none'}
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div
              className={styles.rightSideOption}
              onClick={() => handleShareData(item)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.58984 13.5098L15.4198 17.4898"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.4098 6.50977L8.58984 10.4898"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div
              className={styles.rightSideOption}
              onClick={() => {normalPostLikeHandler(item)}}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill={item?.likes?.id ? '#FF0000' : 'none'}
              >
                <path
                  d="M7.5 4C4.46244 4 2 6.43905 2 9.44773C2 14.8955 8.5 19.848 12 21C15.5 19.848 22 14.8955 22 9.44773C22 6.43905 19.5375 4 16.5 4C14.6399 4 12.9954 4.91467 12 6.31469C11.0046 4.91467 9.36015 4 7.5 4Z"
                  stroke={item?.likes?.id ? '#FF0000' : 'white'}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bookMarkTopDetailsAlignment}>
        {/* <==================== Add Remove Bookmark design start ==================> */}
        {/* {!item?.bookmark?.collection?.name && (
            <div
              className={styles.bookMarkBoxAlignment}
              onClick={(e) => {
                e.stopPropagation()
                handleAddToCollection(item)
              }}
            >
              <div className={styles.bookMarkOptionBox}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M5 12L19 12" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path
                    d="M12 5L19 12L12 19"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className={styles.bookMarkOptionBox}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M11.0753 3.47984L14.7908 3.01439C15.8168 2.88586 16.7504 3.63239 16.876 4.6818M5.46455 19.6118C4.43855 19.7403 3.50493 18.9938 3.37927 17.9444L3.03797 15.0942L2.69667 12.244L2.35537 9.39378L2.01407 6.54359C1.88841 5.49418 2.61828 4.53927 3.64429 4.41074"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <rect
                    x="5.97852"
                    y="5.26172"
                    width="15.0447"
                    height="15.0447"
                    rx="1.395"
                    transform="rotate(3.20951 5.97852 5.26172)"
                    fill="white"
                    stroke="white"
                    strokeWidth="1.5"
                  />
                </svg>
              </div>
            </div>
          )} */}

        {item?.bookmark?.collection?.name &&
          (isInCollection ? (
            <>
              {/* <div className={styles.bookMarkBoxAlignment} onClick={() => handleRemoveBookmark(item)}> */}
              {/* Remove Arrow Design start */}
              {/* <div className={styles.bookMarkOptionBox}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M19 11.9999L5 11.9999"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 18.9999L5 11.9999L12 4.99988"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div> */}
              {/* Remove Arrow Design End */}
              {/* <div className={styles.bookMarkOptionBox}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M11.0753 3.47984L14.7908 3.01439C15.8168 2.88586 16.7504 3.63239 16.876 4.6818M5.46455 19.6118C4.43855 19.7403 3.50493 18.9938 3.37927 17.9444L3.03797 15.0942L2.69667 12.244L2.35537 9.39378L2.01407 6.54359C1.88841 5.49418 2.61828 4.53927 3.64429 4.41074"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <rect
                      x="5.97852"
                      y="5.26172"
                      width="15.0447"
                      height="15.0447"
                      rx="1.395"
                      transform="rotate(3.20951 5.97852 5.26172)"
                      fill="white"
                      stroke="white"
                      strokeWidth="1.5"
                    />
                  </svg>
                </div> */}

              {/* <div className={styles.hoverDetails}>
                  <button aria-label="Tooltip Bottom" tooltip-position="bottom">
                    Bottom
                  </button>
                </div> */}

              {/* <p>Remove from collection.</p> */}
              {/* </div> */}

              <div className={styles.bookMarkMobileViewMoreOptionAlignment}>
                <svg xmlns="http://www.w3.org/2000/svg" width="41" height="31" viewBox="0 0 41 31" fill="none">
                  <rect opacity="0.4" x="8" y="8" width="25" height="15" rx="5" fill="#1E2432" />
                  <circle cx="15.5" cy="15.5" r="1.5" transform="rotate(-90 15.5 15.5)" fill="#F8F8F8" />
                  <circle cx="20.5" cy="15.5" r="1.5" transform="rotate(-90 20.5 15.5)" fill="#F8F8F8" />
                  <circle cx="25.5" cy="15.5" r="1.5" transform="rotate(-90 25.5 15.5)" fill="#F8F8F8" />
                </svg>
              </div>
            </>
          ) : (
            <div
              className={styles.bookMarkNotesALignment}
              onClick={(e) => {
                e.stopPropagation()
                const collection = item?.bookmark?.collection
                if (collection) {
                  handleMovetoCollection(collection)
                }
              }}
            >
              <p>{item?.bookmark?.collection?.name}</p>
            </div>
          ))}

        {/* <==================== Add Remove Bookmark design end ==================> */}

        {/* <==================== text notes design start ==================> */}

        {/* <div className={styles.bookMarkNotesALignment}>
          <p>on Shoe</p>
        </div> */}

        {/* <==================== text notes design start ==================> */}
      </div>
    </div>
    // </Link>
  )
}
