import React from 'react'
import styles from '../FinancePayment.module.scss'
import KYCVerifiedStep2 from '../../SellerFinance/FinancePayment/KYCVerifiedStep2'

export default function WithdrawDetailModal() {
  return (
    <div>
      <div className={styles.financePaymentBox}>
        <div className={styles.financePaymentHeading}>
          <h4>Withdraw</h4>
        </div>
        <div>
          <KYCVerifiedStep2 />
        </div>
      </div>
    </div>
  )
}
