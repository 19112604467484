import React, { useEffect, useState } from 'react'
import styles from './ProductVariant.module.scss'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
export default function ProductVariant() {
  const dispatch = useAppDispatch()
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)

  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  const transformedArray = getAllState?.allModalData[0]?.isEdit
    ? addProductDetailSlice?.id?.variant?.colorCodeArray?.map((item) => {
        if (item.name === 'Color') {
          const colorData = item?.data?.map((data) => {
            return { name: data?.name, colorCode: data?.colorCode }
          })
          return { name: item?.name, data: colorData }
        } else if (item.name !== 'Color' && item.name !== 'SIZE') {
          return { name: item?.name, data: item?.data }
        } else {
          return item
        }
      })
    : addProductDetailSlice?.id?.variant?.allVariant?.map((item) => {
        if (item.name === 'Color') {
          const colorData = item?.data?.map((data) => {
            return { name: data?.name, colorCode: data?.colorCode }
          })
          return { name: item?.name, data: colorData }
        } else if (item.name !== 'Color' && item.name !== 'SIZE') {
          return { name: item?.name, data: item?.data }
        } else {
          return item
        }
      })

  const [filterdDropdown, setFilterdDropdown] = useState(transformedArray || [])
  const [selectedCheckboxes, setSelectedCheckboxes] = useState(addProductDetailSlice?.id?.filterColorList || [])
  const [filteredData, setFilteredData] = useState(addProductDetailSlice?.id?.filteredList || [])
  const [dropdownIndex, setDropdownIndex] = useState(null)

  const [images, setImages] = useState<any>(null)

  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)

  const handleEditableModle = async (index, variant) => {
    await dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          variant: {
            ...addProductDetailSlice?.id.variant,
            isVariantModel: true,
          },
          isEditableData: {
            index: index,
            data: variant,
          },
        },
      }),
    )
  }

  const handleImages = async (e, index) => {
    // let imageResponse
    // let UploadImageRoute =
    //   API.endpoint.includes('stage') || API.endpoint.includes('local')
    //     ? `/upload?type=bluejestic-stage/store/${user?.store?.id}/product`
    //     : `/upload?type=bluejestic-production/store/${user?.store?.id}/product`
    // const formData = new FormData()

    // formData.append('image', e.target.files[0])
    // formData.append('media_for', 'product')
    // imageResponse = await ApiPost(UploadImageRoute, formData)
    // const image = imageResponse?.data?.image[0]?.id
    // handleVariantDataChange(index, 'image', image)
    setImages({ ...images, [index]: URL?.createObjectURL(e.target.files[0]) })

    let finalData = addProductDetailSlice?.id?.variant?.allOptions.map((item, i) => {
      if (i === index) {
        return { ...item, image_id: e.target.files[0] }
      }
      return item
    })

    const isCheckedRemoved = finalData.map((variant) => {
      const { isChecked, ...rest } = variant
      return rest
    })

    await dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          variant: {
            ...addProductDetailSlice?.id.variant,
            allOptions: isCheckedRemoved,
          },
          mobileProductsData: {
            ...addProductDetailSlice?.id?.mobileProductsData,
            variant: isCheckedRemoved,
          },
        },
      }),
    )
  }

  const handleFilteredBox = async (e, data, colorCode) => {
    const checkboxValue = e.target.checked

    let updatedSelectedCheckboxes = [...selectedCheckboxes]
    let updatedFilteredData = [...filteredData]

    if (checkboxValue) {
      if (!updatedSelectedCheckboxes.includes(data)) {
        updatedSelectedCheckboxes.push(data)
      }
      if (colorCode) {
        updatedFilteredData.push({ name: data, colorCode })
      } else {
        updatedFilteredData.push(data)
      }
    } else {
      updatedSelectedCheckboxes = updatedSelectedCheckboxes.filter((item) => item !== data)
      if (colorCode) {
        updatedFilteredData = updatedFilteredData.filter((item) => item?.name !== data)
      } else {
        updatedFilteredData = updatedFilteredData.filter((item) => item !== data)
      }
    }
    setSelectedCheckboxes(updatedSelectedCheckboxes)
    setFilteredData(updatedFilteredData)

    dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          filteredList: updatedFilteredData || [],
          filterColorList: updatedSelectedCheckboxes || [],
        },
      }),
    )

    const updatedData = addProductDetailSlice?.id?.newVariantDataState?.map((item) => {
      const isChecked = updatedSelectedCheckboxes.some((selectedData) => item?.total_variant?.includes(selectedData))
      return { ...item, isChecked }
    })

    dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          newVariantDataState: updatedData,
        },
      }),
    )

    const currentDropdownData = filterdDropdown[dropdownIndex]?.data
    const isAllChecked = currentDropdownData?.every((data) => updatedSelectedCheckboxes?.includes(data))

    if (isAllChecked) {
      const allData = filterdDropdown?.reduce((acc, dropdown) => [...acc, ...dropdown.data], [])
      // setSelectedCheckboxes(allData)
      updateAllCheckboxes(true)

      await dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            filterColorList: allData || [],
            filteredList: allData || [],
          },
        }),
      )
    }
  }

  const updateAllCheckboxes = async (checked) => {
    const allData = filterdDropdown.reduce((acc, dropdown) => [...acc, ...dropdown.data], [])
    const updatedData = addProductDetailSlice?.id?.newVariantDataState?.map((item) => ({
      ...item,
      isChecked: checked,
    }))
    // setNewVariantData(updatedData)

    await dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          newVariantDataState: updatedData,
          filterColorList: checked ? allData : [],
          filteredList: checked ? allData : [],
        },
      }),
    )
    // setSelectedCheckboxes(checked ? allData : [])
  }

  const handleClearFilter = async () => {
    await dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          filterColorList: [],
          filteredList: [],
        },
      }),
    )
  }

  const handleBack = () => {
    let datass = getAllState?.allModalData?.filter((item) => item?.modal !== getAllState?.lastModalData?.modal)
    dispatch(
      fetchModalData({
        allModalData: datass,
        lastModalData: getAllState?.lastModalData,
        isUpdateState: true,
      }),
    )
  }

  useEffect(() => {
    setSelectedCheckboxes(addProductDetailSlice?.id?.filterColorList || [])
    setFilteredData(addProductDetailSlice?.id?.filteredList || [])
    setFilterdDropdown(transformedArray || [])
  }, [addProductDetailSlice?.id?.filterColorList, addProductDetailSlice?.id?.filteredList])

  return (
    <div className={styles.mobileViewProductVariantModal}>
      <div className={styles.mobileViewProductHeadingAlignment}>
        <div className={styles.mobileViewHeading}>
          <div className={styles.headerBackArrow} onClick={handleBack}>
            <svg xmlns="http://www.w3.org/2000/svg" width="37" height="32" viewBox="0 0 37 32" fill="none">
              <path
                d="M27 0H10C4.47715 0 0 4.47715 0 10V22C0 27.5228 4.47715 32 10 32H27C32.5228 32 37 27.5228 37 22V10C37 4.47715 32.5228 0 27 0Z"
                fill="white"
              />
              <path
                d="M21 10L16 16L21 22"
                stroke="#808191"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <h4>Manage Product Variant</h4>
        </div>

        <div className={styles.mobileViewFilterAlignment}>
          <div
            className={styles.mobileViewFilterBox}
            onClick={() => {
              dispatch(
                fetchMobileModalData({
                  ...fetchMobileModal,
                  allModalData: [
                    ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                    {
                      modal: 'mobileFilterModal',
                      isOpen: true,
                      id: { isSeller: false, group: null, type: 'post' },
                      transform: 24,
                    },
                  ],

                  lastModalData: fetchMobileModal?.lastModalData,
                }),
              )

              dispatch(
                manageAddProductDetail({
                  modal: '',
                  isOpen: false,
                  id: {
                    ...addProductDetailSlice?.id,
                    filteredList: filteredData || [],
                    filterColorList: selectedCheckboxes || [],
                  },
                }),
              )
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M1.33057 3.33057H14.6639" stroke="#4C515D" strokeLinecap="round" />
              <path d="M3.33057 7.99707H12.6639" stroke="#4C515D" strokeLinecap="round" />
              <path d="M5.99707 12.6641H9.99707" stroke="#4C515D" strokeLinecap="round" />
            </svg>

            <p>Filters</p>
          </div>

          <div className={styles.mobileViewFilterDetailsFlex}>
            <div className={styles.allFilterNameAlignment}>
              {filteredData.map((it) => {
                return (
                  <div className={styles.sizeBoxFilterAlignment}>
                    <div className={it.colorCode ? styles.colorBoxAlignment : styles.sizeDetailsBox}>
                      {it.name ? (
                        <div className={styles.mobileChildViewFilterBox}>
                          <div className={styles.filterName}>
                            <div className={styles.mobileColorMoxAlignment} style={{ background: it.colorCode }}></div>
                            <span>{it.name}</span>
                          </div>
                          <div
                            className={styles.mobileCloseIcon}
                            onClick={(e) => handleFilteredBox(e, it?.name ? it?.name : it, it?.colorCode)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path d="M12 4L4 12" stroke="#7A7E88" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M4 4L12 12" stroke="#7A7E88" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </div>
                        </div>
                      ) : (
                        <div className={styles.mobileChildViewFilterBox}>
                          <div className={styles.filterName}>
                            <span>{it}</span>
                          </div>
                          <div
                            className={styles.mobileCloseIcon}
                            onClick={(e) => handleFilteredBox(e, it?.name ? it?.name : it, it?.colorCode)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path d="M12 4L4 12" stroke="#7A7E88" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M4 4L12 12" stroke="#7A7E88" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
            <div className={styles.clearFilterAlignment} onClick={() => handleClearFilter()}>
              <a>Clear</a>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.mobileVIewProductBodyAlignment}>
        {filteredData?.length > 0
          ? addProductDetailSlice?.id?.newVariantDataState
              ?.filter((item) => item.isChecked)
              ?.map((variant, index) => {
                return (
                  <>
                    <div className={styles.mobileViewDetailsBox} key={index}>
                      <div className={styles.mobileViewProductImg}>
                        {variant.image_id ? (
                          <img
                            src={
                              getAllState?.allModalData.find((item) => item?.modal === 'addProductModal')?.isEdit
                                ? variant?.image?.media
                                  ? variant?.image?.media
                                  : URL?.createObjectURL(variant?.image_id)
                                : images?.[index]
                                ? images?.[index]
                                : variant?.image_id
                                ? URL?.createObjectURL(variant?.image_id)
                                : ''
                              // : URL?.createObjectURL(variant?.[index]?.image_id)
                            }
                            alt="PreviewIcon"
                          />
                        ) : (
                          <div className={styles.noImgBoxAlignment}>
                            <input type="file" name="image" onChange={(e) => handleImages(e, index)} />
                            {/* <input type="file" name="image" onChange={(e) => handleInputData(e)} /> */}
                            <p>Select Img</p>
                          </div>
                        )}
                        {/* <img src="/assets/img/demo-img-2.png" alt="varint img" /> */}
                      </div>

                      <div className={styles.mobileViewBodyProductDetails}>
                        <div className={styles.mobileViewVarintProductDetails}>
                          <div className={styles.mobileDetailsTopFlex}>
                            <h6>
                              {variant?.total_variant?.map((item, idx) => {
                                return idx === variant.total_variant.length - 1 ? item : item + ', '
                              })}
                            </h6>
                            <div
                              className={styles.statusText}
                              onClick={() => {
                                handleEditableModle(index, variant)
                              }}
                            >
                              <p>Available</p>
                            </div>
                          </div>
                          <div className={styles.mobileDetailsBottomDetails}>
                            <p>Price: ${variant.price}</p>
                            <div className={styles.partborder}></div>
                            <p>Qty: {variant.inventory_quantity}</p>
                            <div className={styles.partborder}></div>

                            <p>SKU: {variant.sku}</p>
                          </div>
                        </div>
                        <div className={styles.mobileViewMoreDetailsArrow}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M10.5 18L16.5 12L10.5 6"
                              stroke="#7A7E88"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </>
                )
              })
          : addProductDetailSlice?.id?.variant?.allOptions?.map((variant, index) => {
              return (
                <div className={styles.mobileViewDetailsBox} key={index}>
                  {/* <img src="/assets/img/demo-img-2.png" alt="varint img" /> */}
                  {variant.image_id ? (
                    <div className={styles.mobileViewProductImg}>
                      <img
                        src={
                          getAllState?.allModalData.find((item) => item?.modal === 'addProductModal')?.isEdit
                            ? variant?.image?.media
                              ? variant?.image?.media
                              : URL?.createObjectURL(variant?.image_id)
                            : images?.[index]
                            ? images?.[index]
                            : variant?.image_id
                            ? URL?.createObjectURL(variant?.image_id)
                            : ''
                          // : URL?.createObjectURL(variant?.[index]?.image_id)
                        }
                        alt="PreviewIcon"
                      />
                    </div>
                  ) : (
                    <div className={styles.noImgBoxAlignment}>
                      <input type="file" name="image" onChange={(e) => handleImages(e, index)} />
                      {/* <input type="file" name="image" onChange={(e) => handleInputData(e)} /> */}
                      <p>Select Img</p>
                    </div>
                  )}

                  <div className={styles.mobileViewBodyProductDetails}>
                    <div className={styles.mobileViewVarintProductDetails}>
                      <div className={styles.mobileDetailsTopFlex}>
                        <h6>
                          {variant?.total_variant?.map((item, idx) => {
                            return idx === variant.total_variant.length - 1 ? item : item + ', '
                          })}
                        </h6>
                        <div
                          className={styles.statusText}
                          onClick={() => {
                            handleEditableModle(index, variant)
                          }}
                        >
                          <p>Available</p>
                        </div>
                      </div>
                      <div className={styles.mobileDetailsBottomDetails}>
                        <p>Price: ${variant.price}</p>
                        <div className={styles.partborder}></div>
                        <p>Qty: {variant.inventory_quantity}</p>
                        <div className={styles.partborder}></div>

                        <p>SKU: {variant.sku}</p>
                      </div>
                    </div>
                    <div className={styles.mobileViewMoreDetailsArrow}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M10.5 18L16.5 12L10.5 6"
                          stroke="#7A7E88"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              )
            })}
      </div>

      <div className={styles.mobileViewBottomButton} onClick={handleBack}>
        <button>Save Change</button>
      </div>
    </div>
  )
}
