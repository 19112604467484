import styles from "./BannerStreams.module.scss";
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import moment from 'moment'
import LiveStreamRunner from "./LiveStreamRunner";
import { useMutation } from "@apollo/client";
import REACT_TO_LIVE_STREAM from "src/graphql/mutations/reactToLiveStream";
import { StreamReactionButton } from "../StreamReactionButton";
import { Clock, Star, TimerOff } from "lucide-react";
import Countdown from "react-countdown";

const BannerStreamsCard = ({ item, isHost }: { item: any, isHost?: boolean }) => {
    const dispatch = useAppDispatch()
    const getAllState = useSelector((state: any) => state?.manageModalData?.data)
    const handleLiveStream = (data) => {
        dispatch(
            fetchModalData({
                allModalData: [
                    ...getAllState?.allModalData,
                    {
                        modal: 'liveStream',
                        isOpen: true,
                        modalFor: 'bigModal',
                        data: {
                            uuid: data?.uuid,
                            isHost: !!isHost,
                            isStartStream: !isHost,
                            hostName: data?.title,
                            finalTime: data?.final_date,
                        },
                        startDate: moment().toISOString(),
                    },
                ],
                lastModalData: getAllState?.lastModalData,
            }),
        )
    }

    const durationRemaining = moment(item?.final_date).add(item?.duration, 'milliseconds').diff(moment());
    return (
        <div className={`${styles.channelsBannerSliderAlignment} `} onClick={() => handleLiveStream(item)}  >
            <div className={styles.channelsBannerImg}>
                {item?.media && (
                    <LiveStreamRunner uuid={item?.uuid} mediaUrl={item?.media} />
                )}
                <div className={styles.channelBannerImgDetailsAlignment}>
                    <div className={styles.channelBannerTopDetailsAlignment}>
                        <div className={styles.liveAndViewDetailsALignment}>
                            <div className={styles.liveButtonAlignment}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                >
                                    <g clipPath="url(#clip0_1106_72)">
                                        <path
                                            d="M8.00016 14.6668C11.6821 14.6668 14.6668 11.6821 14.6668 8.00016C14.6668 4.31826 11.6821 1.3335 8.00016 1.3335C4.31826 1.3335 1.3335 4.31826 1.3335 8.00016C1.3335 11.6821 4.31826 14.6668 8.00016 14.6668Z"
                                            stroke="white"
                                            strokeWidth="1.25"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M7.90674 11.0229C9.56359 11.0229 10.9067 9.6798 10.9067 8.02295C10.9067 6.36609 9.56359 5.02295 7.90674 5.02295C6.24988 5.02295 4.90674 6.36609 4.90674 8.02295C4.90674 9.6798 6.24988 11.0229 7.90674 11.0229Z"
                                            fill="white"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1106_72">
                                            <rect width="16" height="16" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>

                                <p>LIVE</p>
                            </div>

                            <div className={styles.liveViewNumberAlignment}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                >
                                    <path
                                        d="M0.666992 7.99935C0.666992 7.99935 3.33366 2.66602 8.00033 2.66602C12.667 2.66602 15.3337 7.99935 15.3337 7.99935C15.3337 7.99935 12.667 13.3327 8.00033 13.3327C3.33366 13.3327 0.666992 7.99935 0.666992 7.99935Z"
                                        stroke="#E4E4E8"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M8 9.99951C9.10457 9.99951 10 9.10408 10 7.99951C10 6.89494 9.10457 5.99951 8 5.99951C6.89543 5.99951 6 6.89494 6 7.99951C6 9.10408 6.89543 9.99951 8 9.99951Z"
                                        stroke="#E4E4E8"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <p>{item?.participants_count}</p>
                            </div>
                        </div>
                        <div >

                        </div>
                    </div>
                    <div className={styles.channelBottomDetailsAlignment}>
                        <div className={styles.channelBannerHeadingNameAlignment}>
                            <h6>{item?.title}</h6>
                            <div className={styles.channelBannerLogoAlignment}>
                                <div className={styles.channelLogoImg}>
                                    <img src={item?.store_logo} alt="logo" />
                                </div>
                                <p>{item?.store_name}</p>
                            </div>
                        </div>
                        <div className='absolute top-8 right-4 flex text-red-500 font-bold'>
                            <TimerOff size={16} className='mr-2 mt-0.5' />
                            <Countdown
                                date={new Date(Date.now() + durationRemaining)} //item?.final_date  item?.duration}
                                renderer={({ hours, minutes, seconds }) => (
                                    <div>
                                        {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
                                    </div>
                                )}
                            />
                            <div className='ml-1'>s</div>
                        </div>
                        <StreamReactionButton item={item} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BannerStreamsCard;
