import { gql } from '@apollo/client'

const FORGOT_PASSWORD = gql`
  mutation FORGOT_PASSWORD($email: String!) {
    forgotPassword(email: $email) {
      message
      success
    }
  }
`
export default FORGOT_PASSWORD
