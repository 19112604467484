import { gql } from '@apollo/client'

const GET_RATE_ESTIMATE = gql`
  query GetRateEstimate($input: productDetailInput) {
    getRateEstimate(input: $input) {
      data {
        carrier_delivery_days
        estimated_delivery_date
        service_type
        shipping_amount {
          amount
        }
      }
      message
      success
    }
  }
`
export default GET_RATE_ESTIMATE
