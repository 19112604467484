import React, { useEffect, useState } from 'react'
import styles from './MobileViewSpecialOffers.module.scss'
import CategoryProduct from '../CategoryProduct'
import { fetchProductByCategory } from 'src/store/slices/fetchAllProduct'
import { useAppDispatch } from 'src/store/store'
import { useRouter } from 'next/router'
export default function MobileViewSpecialOffers() {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const [specialOffersData, setSpecialOffersData] = useState([])

  useEffect(() => {
    fetchProduct()
  }, [])

  const fetchProduct = () => {
    dispatch(
      fetchProductByCategory({
        category: 'Fashion & Accessories',
        subCategory: router?.asPath?.includes('women') ? 'Women' : 'Men',
        childSubCategory: router?.asPath?.includes('women') ? 'scarves' : 'sunglasses',
        isShopifyProduct: false,
        page: 1,
        limit: 12,
      }),
    ).then((response) => {
      setSpecialOffersData(response?.payload?.getAllProducts?.data)
    })
  }
  return (
    <div className={styles.mobileViewSpeacialOfferModal}>
      <div className={styles.mobileViewHeading}>
        <h6>Special Offers</h6>
      </div>

      <div className={styles.mobileViewBodyAlignment}>
        <div className={styles.mobileViewSpeacialOfferDetailsAlignment}>
          <div className={styles.mobileViewSpeacialOfferGrid}>
            {specialOffersData?.map((item, index) => {
              return <CategoryProduct index={index} item={item} />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
