import { gql } from '@apollo/client'

const REMOVE_PRODUCT_FROM_COLLECTIONS = gql`
mutation RemoveProductFromCollection(
  $collectionId: Int
  $productId: Int
  $isRemoveFromCollectionOnly: Boolean
) {
  removeProductFromCollection(
    collection_id: $collectionId
    product_id: $productId
    isRemoveFromCollectionOnly: $isRemoveFromCollectionOnly
  ) {
    message
    success
  }
}
`

export default REMOVE_PRODUCT_FROM_COLLECTIONS
