import { Checkbox } from 'antd'
import React from 'react'

export default function AddGroupMessageModal({
  staticArray,
  setGroupMessageModal,
  setGroupName,
  setSearchTag,
  participants,
  handleChange,
  searchTag,
  handleCreateGroup,
}) {
  return (
    <div className="group-select-account-modal-alignment">
      <div className="group-select-account-white-box-alignment">
        <div className="group-select-account-sticky">
          <div className="group-select-account-heading-alignment">
            <h4>Create Group Messaging</h4>
            <div className="group-header-close-alignment" onClick={() => setGroupMessageModal(false)}>
              <img src="/assets/icon/close-white.svg" alt="close icon" />
            </div>
          </div>
          <div className="group-profile-tag-alignment">
            <div className="group-message-profile-img-box-alignment-sidebar">
              {staticArray?.map((profile, index) => {
                return (
                  <div className={`group-message-flex-alignment-${index}`} key={index}>
                    <div className={`group-message-profile-img-alignment-${index}`}>
                      <img src={profile} alt="group profile" />
                    </div>
                  </div>
                )
              })}
              <div className="group-message-first-dot-alignment">
                <div className="first-dot-width-alignment">
                  <img src="/assets/img/gdot1.png" alt="group profile" />
                </div>
              </div>
              <div className="group-message-second-dot-alignment">
                <div className="second-dot-width-alignment">
                  <img src="/assets/img/gdot2.png" alt="group profile" />
                </div>
              </div>
              <div className="group-message-third-dot-alignment">
                <div className="third-dot-width-alignment">
                  <img src="/assets/img/gdot3.png" alt="group profile" />
                </div>
              </div>
            </div>
            <div className="group-name-edit-input-box-alignment">
              <input type="text" placeholder="Fashion Lovers|" onChange={(e) => setGroupName(e.target.value)} />
            </div>
          </div>
          <div className="group-details-main-header-alignment">
            <div className="group-main-text-alignment">
              <span>Invite</span>
              <div className="text-box-number-alignment">
                <p>{participants?.filter((supplier: any) => supplier?.isChecked)?.length}</p>
              </div>
            </div>
            <div className="group-select-search-alignment">
              <div className="group-select-account-search-alignment">
                <div>
                  <img src="/assets/icon/search.svg" alt="search" />
                </div>
                <input type="text" placeholder="Search" onChange={(e) => setSearchTag(e.target.value)} />
              </div>
            </div>
          </div>
        </div>
        <div className="group-details-alignment">
          {participants
            ?.filter((supplier: any) => supplier?.name?.toLowerCase().includes(searchTag?.toLowerCase()))
            ?.map((u, index) => {
              return (
                <div className="group-details-main-alignment" key={index}>
                  <div className="group-peronal-details-alignment">
                    <div className="group-personal-profile-img">
                      <img src={u?.user?.logo_image} alt="user img" />
                    </div>
                    <p>{u?.user?.firstName + ' ' + u?.user?.lastName}</p>
                  </div>
                  <div className="group-select-input-alignment">
                    <Checkbox onChange={(e) => handleChange(e, u)} checked={u?.isChecked}></Checkbox>
                  </div>
                </div>
              )
            })}
        </div>
        <div className="group-footer-button-alignment">
          <div className="group-cancle-button-alignment">
            <button>Cancel</button>
          </div>
          <div className="create-button-alignment" onClick={handleCreateGroup}>
            <button>Create</button>
          </div>
        </div>
      </div>
    </div>
  )
}
