import React, { useState } from 'react'
import ShippingCusProfileModal from './ShippingCusProfileModal'

export default function ShippingCuspProfile() {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <div className="mt-10 md:col-span-3 lg:col-span-4 bg-white p-20 rounded-xl shadow-sm border border-gray-100">
        <div className="max-w-xl mx-auto text-center">
          <div className="mx-auto w-16 h-16 bg-indigo-100 dark:bg-indigo-900/30 rounded-full flex items-center justify-center mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-package w-8 h-8 text-indigo-600 dark:text-indigo-400"
            >
              <path d="m7.5 4.27 9 5.15" />
              <path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z" />
              <path d="m3.3 7 8.7 5 8.7-5" />
              <path d="M12 22V12" />
            </svg>
          </div>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">No custom shipping profiles yet</h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            Create your first custom shipping profile to start configuring your shipping options.
          </p>
          <button
            className="mt-5 inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-md"
            onClick={() => setIsModalOpen(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-plus mr-2"
            >
              <path d="M5 12h14"></path>
              <path d="M12 5v14"></path>
            </svg>
            Add Custom Profile
          </button>
        </div>
      </div>

      <ShippingCusProfileModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  )
}
