import React, { useState } from 'react'
import MapIcon from '../../../../../public/assets/icon/map-pin-grey.svg'
import BrifeCaseIcon from '../../../../../public/assets/icon/briefcase.svg'
import EduBrifeCaseIcon from '../../../../../public/assets/icon/edu-briefcase.svg'
import WorkBrifeCaseIcon from '../../../../../public/assets/icon/Workbriefcase.svg'
import CameraIcon from '../../../../../public/assets/icon/camera.svg'
import MessageIcon from '../../../../../public/assets/icon/message-circle.svg'
import { useAuth } from 'src/utils/auth'
import DECLINE_FRIEND_REQUEST from 'src/graphql/mutations/declineFriendRequest'
import SEND_FRIEND_REQUEST from 'src/graphql/mutations/sendFriendRequest'
import GET_USER_BY_NAME from 'src/graphql/queries/getUserByName'
import { useMutation } from '@apollo/client'
import Link from 'next/link'
import DownArrow from '../../../../../public/assets/icon/chevron-down.svg'
import MobileviewMoreaboutmodal from './MobileviewMoreaboutmodal'
import MobileviewFollowModal from './MobileviewFollowModal'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { fetchUserMobileModalData } from 'src/store/slices/fetchUserMobileModalData'

export default function UserAbout(props) {
  const { setFollowModal, userData, userId, setFollowers, setIsOpenFollowerModal, setViewIndex } = props
  const { user } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const dispatch = useAppDispatch()

  const [declineFriendRequest] = useMutation(DECLINE_FRIEND_REQUEST, {
    onCompleted: () => {
      // notification['success']({
      //   message: 'Remove Request successfully',
      // })
    },
    onError: (error) => { },
  })

  const [sendFriendRequest] = useMutation(SEND_FRIEND_REQUEST, {
    onCompleted: ({ sendFriendRequest }) => {
      // notification['success']({
      //   message: 'Friend Request sent successfully',
      //   description: 'Your friend request has been sent',
      // })
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const handleDeclineRequest = () => {
    if (user) {
      declineFriendRequest({
        variables: { user_id: user.id, friend_id: parseInt(userId) },
        refetchQueries: [{ query: GET_USER_BY_NAME, variables: { id: parseInt(userId) } }],
      })
    } else {
      handleRedirect()
    }
  }

  const handleSendRequest = () => {
    if (user) {
      sendFriendRequest({
        variables: {
          friend_id: parseInt(userId),
          user_id: user?.id,
          isActive: true,
          isFriend: true,
        },
        refetchQueries: [{ query: GET_USER_BY_NAME, variables: { id: parseInt(userId) } }],
      })
    } else {
      handleRedirect()
    }
  }

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const handleShowFollower = (data) => {
    if (user) {
      setFollowModal(true)
      setFollowers(data)
    } else {
      handleRedirect()
    }
  }

  const handleShowFollowerMobile = (data) => {
    // let payload = {
    //   viewIndex: data === 'Followers' ? '1' : '2',
    //   userData: userData,
    //   userId: userId,
    //   storeId: null,
    //   for: 'userProfile',
    // }

    // dispatch(
    //   fetchMobileModalData({
    //     allModalData: [
    //       ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
    //       { modal: 'followersFollowingsModal', isOpen: true, id: payload, transform: 6 },
    //     ],
    //     lastModalData: fetchMobileModal?.lastModalData,
    //   }),
    // )

    setIsOpenFollowerModal(true)
    if (data === 'Followers') {
      setViewIndex('1')
    } else {
      setViewIndex('2')
    }
  }

  return (
    <div className="">
      <div className="social-profile-box-alignment">
        <div className="social-profile-details-alignment">
          <div className="profile-img-alignment">
            <img src={userData?.profileUrl || userData?.logo_image} alt="OwnerImg" />
            <div className="edit-profile-img">
              <img src={CameraIcon.src} alt="CameraIcon" />
            </div>
          </div>
          <div className="profile-name-alignment">
            <h5>{`${userData?.firstName + ' ' + userData?.lastName} `}</h5>
            <div className="location-alignment">
              <img src={MapIcon.src} alt="MapIcon" />
              <span>
                {userData?.city ? `${userData?.city},` : '____'}
                {userData?.state?.split('/')?.length > 0 ? userData?.state?.split('/')[1] : ''}
              </span>
            </div>
            {userId == user?.id ? (
              <Link href="/customerportal/profile" scroll={false}>
                <div className="edit-button-alignment">
                  <button>Edit Profile</button>
                </div>
              </Link>
            ) : userData?.isActiveForFriendStatus && userData?.isFriendForFriendStatus ? (
              <div className="edit-button-alignment" onClick={() => handleDeclineRequest()}>
                <button>Following</button>
              </div>
            ) : (
              <div className="edit-button-alignment" onClick={() => handleSendRequest()}>
                <button>Follow</button>
              </div>
            )}

            {/* Mobile View follow-button alignment */}

            <div className="mobile-view-edit-follow-button-alignment">
              {userId == user?.id ? (
                <Link href="/customerportal/profile" scroll={false}>
                  <div className="mobile-follow-button-alignment" onClick={() => handleDeclineRequest()}>
                    <button className="active-follow-button">Edit Profile</button>
                  </div>
                </Link>
              ) : userData?.isActiveForFriendStatus && userData?.isFriendForFriendStatus ? (
                <div className="mobile-follow-button-alignment" onClick={() => handleDeclineRequest()}>
                  <button className="active-follow-button">Following</button>
                </div>
              ) : (
                <div className="mobile-follow-button-alignment" onClick={() => handleSendRequest()}>
                  <button>Follow</button>
                </div>
              )}
              <div className="mobile-message-button-alignment">
                <button>
                  <img src={MessageIcon.src} alt="MessageIcon" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="social-notes-alignment">
          <p>
            {userData?.about}
            {/* <span>linktr.ee/Darellst</span> */}
          </p>
        </div>
        <div className="follow-following-alignment-web">
          <div className="follow-details-alignemt" onClick={() => handleShowFollower('Followers')}>
            <h6>{userData?.followers?.length}</h6>
            <span>Followers</span>
          </div>
          <div className="following-details-alignemt" onClick={() => handleShowFollower('Following')}>
            <h6>{userData?.followings?.length}</h6>
            <span>Following</span>
          </div>
        </div>
        <div className="follow-following-alignment-mobile">
          <div className="follow-details-alignemt" onClick={() => handleShowFollowerMobile('Followers')}>
            <h6>{userData?.followers?.length}</h6>
            <span>Followers</span>
          </div>
          <div className="following-details-alignemt" onClick={() => handleShowFollowerMobile('Following')}>
            <h6>{userData?.followings?.length}</h6>
            <span>Following</span>
          </div>
        </div>
        {userData?.interest && (
          <div className="interest-details-alignment">
            <div className="icon-alignment">
              <img src={BrifeCaseIcon.src} alt="BrifeCaseIcon" />
            </div>
            <div className="interest-name-tag-alignment">
              <h5>Interests</h5>
              <div className="tag-alignment">
                <div className="tag-box-alignment ">
                  <span>{userData?.interest}</span>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="interest-details-alignment">
          <div className="icon-alignment">
            <img src={EduBrifeCaseIcon.src} alt="EduBrifeCaseIcon" />
          </div>
          <div className="interest-name-tag-alignment">
            <h5>Education</h5>
            <div className="univercity-details-alignment">
              {/* <h6>Oxford Uniersity, London</h6> */}
              <h6>{userData?.education ?? '-'}</h6>
            </div>
          </div>
        </div>
        <div className="interest-details-alignment">
          <div className="icon-alignment">
            <img src={WorkBrifeCaseIcon.src} alt="WorkBrifeCaseIcon" />
          </div>
          <div className="interest-name-tag-alignment">
            <h5>Work and experience</h5>
            <div className="univercity-details-alignment">
              {/* <h6>Microsoft</h6>
              <span>Principal Software Engineer</span>
              <p>2015-present</p> */}
              <h6>{userData?.experience ?? '-'}</h6>
            </div>
          </div>
        </div>

        {/* <-------------------------------------------USER_PROFILE_MOBILE_VIEW_ABOUT_START-------------------------------------------------------> */}
        <div
          className="mobile-view-about-me-alignment"
          onClick={() =>
            dispatch(
              fetchUserMobileModalData({
                allModalData: [
                  ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                  { modal: 'userProfileAboutModal', isOpen: true, id: userData, transform: 230, for: 'user' },
                ],
                lastModalData: fetchMobileModal?.lastModalData,
              }),
            )
          }
        >
          {/* <div className="mobile-view-name-alignment">
            <h5>More About Me</h5>
            <p>Interest, Education, Experience</p>
          </div>
          <div className="mobile-dropdown-icon-alignment">
            <img src={DownArrow.src} alt="DownArrow" />
          </div> */}
        </div>
      </div>
      {/* <----------------------------------------------USER_PROFILE_MOBILE_VIEW_ABOUT_END-------------------------------------------------------> */}

      {/* {aboutMoreModal &&  */}

      {/* <MobileviewMoreaboutmodal setIsOpen={setIsOpen} isOpen={isOpen} userData={userData} /> */}
    </div>
  )
}
