import { gql } from '@apollo/client'

export default gql`
  query MYFEED($group_id: Int, $myPost: Boolean, $user_id: Int, $page: Int, $limit: Int) {
    getAllPost(group_id: $group_id, myPost: $myPost, user_id: $user_id, page: $page, limit: $limit) {
      success
      message
      data {
        content
        isSave
        isBookmarked
        bookmark_id
        id
        savedPost {
          id
        }
        createdAt
        post_for
        store_name
        store_id
        store {
          id
          name
          logo_image
          banner_image
          # logo
          # cover_image
          slug
        }
        product {
          id
          title
          slug
          description
          quantity
          listPrice
          price
          image
          video
          dis_price
          dis_listPrice
          isFeature
          bookmark {
            product_id
            id
            collection {
              id
            }
          }
          store {
            id
            name
            slug
          }
        }
        collection {
          id
          name
          user_id
          user {
            userName
          }
          like_count
          isPrivate
          product_count
          images
          slug
          likes {
            user_id
          }
          bookmark_product {
            id
            title
            dis_price
            slug
            dis_listPrice
            images {
              id
              src
            }
          }
        }
        group {
          id
          name
          slug
        }
        user {
          id
          userName
          firstName
          lastName
          logo_image
          banner_image
          # profileImage {
          #   url
          # }
          # coverImage {
          #   url
          # }
        }
        attachements: medias {
          id
          url
        }
        comment_count
        likes {
          id
          user {
            id
            firstName
            logo_image
            lastName
            userName
          }
        }
      }
    }
  }
`
