import React from 'react'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import IdeaForYouBanner from '../../../../../public/assets/img/BookApplience/ideaForYouBanner.png'
import MobileIdeaForYouBanner from '../../../../../public/assets/img/BookApplience/mobileIdeaForBg.png'
import IdeaOneImg from '../../../../../public/assets/img/idea1.png'
import MobileViewIdeaForYouModal from '../MobileViewIdeaForYouModal'
export default function BookApplianceIdeaModal() {
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  const handleClose = () => {
    let datass = getAllState?.allModalData?.filter((item: any) => item?.modal !== getAllState?.lastModalData?.modal)
    dispatch(
      fetchModalData({
        allModalData: datass,
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  return (
    <div className="book-appliance-idea-modal-alignment">
      <div className="mobile-view-book-appliance-back-alignment">
        <div className="book-appliance-back_details-alignment" onClick={() => handleClose()}>
          <div>
            <img src="/assets/icon/left-icon.svg" alt="back-page" />
          </div>
          <p>Home...</p>
        </div>
        <div className="mobile-heading-alignment">
          <h4>Ideas for You</h4>
        </div>
      </div>
      <div className="book-appliance-idea-banner-alignment">
        <img src={IdeaForYouBanner.src} alt="IdeaForYouBanner" />
        <img src={MobileIdeaForYouBanner.src} alt="IdeaForYouBanner" className="mobile-view-banner" />

        <div className="book-appliance-idea-banner-details">
          <h4>Ideas for You</h4>
          <p>Transform your home into your dream space with our curated decor ideas! </p>
        </div>
      </div>

      <div className="book-appliance-idea-details-alignment">
        <div className="book-appliance-idea-grid-alignment">
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((_, index) => {
            return (
              <div className="book-appliance-idea-gridItem-alignment" key={index}>
                <img src={IdeaOneImg.src} alt="IdeaOneImg" />
              </div>
            )
          })}
        </div>
        <div>
          <MobileViewIdeaForYouModal />
        </div>
      </div>
    </div>
  )
}
