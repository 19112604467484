import { gql } from '@apollo/client'

export default gql`
  query GET_COMMENT_BY_ID($id: Int!, $comment_for: String!) {
    getCommentById(id: $id, comment_for: $comment_for) {
      success
      message
      count
      data {
        id
        comment
        createdAt
        commentReply {
          comment
          image
          createdAt
          commentReplyLike {
            id
            user {
              id
              firstName
            }
          }
          user {
            id
            firstName
            lastName
            logo_image
            banner_image
          }
        }
        user {
          id
          firstName
          lastName
          userName
          logo_image
          banner_image
          isActiveForFriendStatus
          isFriendForFriendStatus
          followers {
            id
            user {
              id
              firstName
              logo_image
            }
          }
          followings {
            id
            user: friend {
              id
              firstName
              logo_image
            }
          }
        }
        likes {
          id
          user {
            id
            # firstName
            # logo_image
            # lastName
          }
        }
      }
    }
  }
`
