import React, { useEffect, useState, useRef } from 'react';
import Image from 'next/image';
import { useLazyQuery } from '@apollo/client';
import { Spin } from 'antd';
import GET_ELASTIC_TYPE_HEAD_SEARCH from 'src/graphql/queries/getElasticTypeHeadSearch';
import GET_GLOBAL_SEARCH_WITH_ELASTIC_SEARCH from 'src/graphql/queries/sellerQueries/getGlobalSearchWithElasticSearch';

interface SearchFlyoutProps {
    searchTerm: string;
    setSearchTerm: (searchTerm: string) => void;
    instanceId?: string;
}

interface Product {
    id: string;
    name: string;
    image: string;
    rating: number;
    reviews: number;
}

export const SearchFlyout = ({ searchTerm, setSearchTerm, instanceId = 'default' }: SearchFlyoutProps) => {
    const [getElasticTypeHeadSearch, { data, loading, error }] = useLazyQuery(GET_ELASTIC_TYPE_HEAD_SEARCH, {
        fetchPolicy: 'network-only',
    });

    const [hoveredCategory, setHoveredCategory] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const flyoutRef = useRef(null);

    useEffect(() => {
        const handleClick = (event) => {
            // Get the clicked flyout element if any
            const targetFlyout = event.target.closest('[data-flyout-id]');
            const clickedInstanceId = targetFlyout?.getAttribute('data-flyout-id');

            // If we clicked inside a different flyout instance, don't close this one
            if (clickedInstanceId && clickedInstanceId !== instanceId) {
                return;
            }

            // If we clicked outside this flyout instance, close it
            if (!flyoutRef.current?.contains(event.target)) {
                setSearchTerm('');
            }
        };

        if (searchTerm) {
            document.addEventListener('click', handleClick);
            return () => document.removeEventListener('click', handleClick);
        }
    }, [searchTerm, setSearchTerm, instanceId]);



    useEffect(() => {
        if (searchTerm) {
            getElasticTypeHeadSearch({
                variables: {
                    search: searchTerm,
                    limit: 1000,
                },
            });
        }
    }, [searchTerm]);

    const searchResponse = data?.elasticTypeHeadSearch || [];

    // Set default category when data is loaded
    useEffect(() => {
        if (searchResponse.length > 0 && !hoveredCategory && !selectedCategory) {
            const initialCategory = searchResponse[0];
            setHoveredCategory(initialCategory);
            setSelectedCategory(initialCategory);
        }
    }, [searchResponse]);

    const navigateToProduct = (product) => {
        setSearchTerm('');
        window.location.href = `/product/${product.slug}`;
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setHoveredCategory(category);
    };

    const handleCategoryHover = (category) => {
        setHoveredCategory(category);
    };

    const currentCategory = hoveredCategory || selectedCategory;

    return (
        <div ref={flyoutRef} data-flyout-id={instanceId}>
            {searchTerm && (
                <div className="absolute top-[45px] w-[120%] md:w-[140%] left-0 bg-white shadow-lg border border-gray-300 z-50 h-[70vh]">
                    {loading ? (
                        <div className='h-[60vh] flex items-center justify-center'>
                            <Spin size="large" tip="loading" />
                        </div>
                    ) : (
                        <div className="flex flex-col md:flex-row h-full ">
                            {/* Categories Sidebar */}
                            <div className="w-full md:w-[35%] bg-[#f0f2f4] h-auto md:h-full border-b md:border-b-0 border-gray-200 overflow-y-auto md:overflow-y-scroll">
                                <ul className="flex md:block whitespace-nowrap md:whitespace-normal py-2 md:pt-10">
                                    {searchResponse.map((category, index) => (
                                        <li
                                            key={index}
                                            className={`inline-block md:block px-4 md:px-6 py-2 text-[13px] cursor-pointer transition-colors
                                                ${currentCategory?.categoryName === category?.categoryName
                                                    ? 'bg-white text-indigo-700 font-medium'
                                                    : 'text-[#1d252c] hover:bg-white hover:text-indigo-700'
                                                }`}
                                            onClick={() => handleCategoryClick(category)}
                                            onMouseEnter={() => handleCategoryHover(category)}
                                        >
                                            <span className="flex items-center">
                                                <span className="truncate font-semibold text-[14px]">{category?.categoryName}</span>
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* Products Grid */}
                            <div className="flex-1 bg-white h-full overflow-y-auto">
                                <div className="p-4">
                                    <h2 className="text-base md:text-[18px] text-[#1d252c] font-medium mb-4">
                                        Products for "{currentCategory?.categoryName}"
                                    </h2>
                                    <div className="space-y-4">
                                        {currentCategory?.products?.map((product) => (
                                            <div
                                                key={product.id}
                                                onClick={() => navigateToProduct(product)}
                                            >
                                                <a className="flex items-start gap-3 md:gap-4 p-2 hover:bg-gray-200 rounded-lg transition-colors">
                                                    <div className="w-[40px] md:w-[60px] h-[40px] md:h-[60px] flex items-center justify-center bg-white p-2 border border-gray-200 rounded">
                                                        <img
                                                            src={product?.images?.[0]?.src}
                                                            alt={product?.title}
                                                            className="max-w-full max-h-full object-contain"
                                                            width={60}
                                                            height={60}
                                                        />
                                                    </div>
                                                    <div className="flex-1 min-w-0">
                                                        <h3 className="text-xs md:text-[13px] text-[#1d252c] font-light line-clamp-2 overflow-ellipsis mb-0">
                                                            {product?.title}
                                                        </h3>
                                                        <div className="flex items-center mt-1">
                                                            <div className="flex text-[#ffce00] text-xs md:text-sm">
                                                                {'★'.repeat(Math.floor(product?.rating || 5))}
                                                                {'☆'.repeat(5 - Math.floor(product?.rating || 5))}
                                                            </div>
                                                            <span className="ml-2 text-[12px] md:text-[11px] text-[#0046be]">
                                                                ({product?.reviews?.toLocaleString() || 1} reviews)
                                                            </span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
