import { gql } from '@apollo/client'

export default gql`
  query GET_ALL_PRPDUCTS(
    $category: String
    $subCategory: String
    $childSubCategory: String
    $isShopifyProduct: Boolean
    $store_id: Int
    $page: Int
    $limit: Int
    $order: String
    $imagelimit: Int
    $minPrice: Int
    $maxPrice: Int
  ) {
    getAllProducts(
      category: $category
      subCategory: $subCategory
      childSubCategory: $childSubCategory
      isShopifyProduct: $isShopifyProduct
      store_id: $store_id
      page: $page
      limit: $limit
      order: $order
      imagelimit: $imagelimit
      minPrice: $minPrice
      maxPrice: $maxPrice
    ) {
      data {
        id
        like_count
        comment_count
        sharepost_count
        store_id
        title
        description
        isFeature
        isActive
        metaTitle
        dis_price
        slug
        dis_listPrice
        keywords
        metaDescription
        isVisible
        isFreeShipping
        condition
        is_deleted
        productLikes {
          id
        }
        likes {
          id
        }
        variants {
          id
          title
          price
          sku
          barcode
          inventory_quantity
          old_inventory_quantity
          image_id
          image {
            media
          }
          total_variant {
            variant_option_id
            variant_option {
              value
              variation_id
            }
          }
        }
        shareCount {
          id
        }
        inventoryPrice {
          price
          listPrice
          quantity
          sku
        }
        shipping {
          weightValue
          weightUnit
          unit
          length
          width
          height
        }
        #categories {
        #  category_id
        #  subCategory_id
        #  childSubCategory_id
        #}
        options {
          name
          data {
            value
          }
        }
        attributes {
          name
          value
        }
        images {
          media_id
          src
        }
        tags {
          tag
        }
        customers {
          id
          firstName
          lastName
          profileAvtar
        }
      }
      total
    }
  }
`
