import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import UserProfileFollowersModalMobile from './UserProfileFollowersModalMobile'
import GET_STORE_BY_NAME from 'src/graphql/queries/getStoreByName'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { fetchUserMobileModalData } from 'src/store/slices/fetchUserMobileModalData'

export default function MobileviewFollowModal({ userData, userId, setViewIndex, viewIndex, storeId, storeName }) {
  const { TabPane } = Tabs

  const [startX, setStartX] = useState(null)
  const fetchMobileModal = useSelector((state: any) => state?.userMobileModal?.data)
  const dispatch = useAppDispatch()

  function handleTouchStart(e) {
    setStartX(e.touches[0].pageX)
  }

  function handleTouchMove(e) {
    if (startX && startX + e.touches[0].pageY > 150) {
      handleClose()
    }
  }

  function handleTouchEnd() {
    setStartX(null)
  }

  const handleChange = (key) => {
    setViewIndex(key)
  }

  useEffect(() => {
    if (fetchMobileModal?.lastModalData?.isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [fetchMobileModal?.lastModalData?.isOpen])

  const mobileModals = () => {
    if (fetchMobileModal?.lastModalData?.isOpen && fetchMobileModal?.lastModalData?.for === 'user') {
      switch (fetchMobileModal?.lastModalData?.modal) {
        case 'userFollowingModal':
          return (
            <Tabs defaultActiveKey="1" centered activeKey={viewIndex.toString()} onChange={handleChange}>
              <TabPane tab="Followers" key="1">
                <UserProfileFollowersModalMobile
                  userInfo={userData}
                  type={viewIndex === '1' && 'Followers'}
                  userId={userId}
                  storeId={storeId}
                  storeName={storeName}
                  refetchQuery={{
                    query: GET_STORE_BY_NAME,
                    variables: { storeName: parseInt(storeName) },
                  }}
                />
              </TabPane>
              <TabPane tab={`${storeId ? 'Likes' : 'Following'}`} key="2">
                <UserProfileFollowersModalMobile
                  userInfo={userData}
                  type={viewIndex === '2' && 'Following'}
                  userId={userId}
                  storeId={storeId}
                  storeName={storeName}
                  refetchQuery={{
                    query: GET_STORE_BY_NAME,
                    variables: { storeName: parseInt(storeName) },
                  }}
                />
              </TabPane>
            </Tabs>
          )
        case 'groupControlModal':
          return <></>
      }
    }
  }

  const handleClose = () => {
    let datass = fetchMobileModal?.allModalData?.filter(
      (item) => item?.modal !== fetchMobileModal?.lastModalData?.modal,
    )
    dispatch(
      fetchUserMobileModalData({
        allModalData: datass,
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
  }

  return (
    // <React.Fragment>
    //   <div
    //     onClick={() => handleClose()}
    //     className={`follow-followingOverlay ${fetchMobileModal?.lastModalData?.isOpen ? 'open' : ''}`}
    //   />
    //   <div className={`follow-following-modal ${fetchMobileModal?.lastModalData?.isOpen ? 'open' : ''}`}>
    //     <div
    //       className="mobile-view-follow-following-click-drop-alignment"
    //       onTouchStart={handleTouchStart}
    //       onTouchMove={handleTouchMove}
    //       onTouchEnd={handleTouchEnd}
    //     >
    //       <p></p>
    //     </div>
    //     {mobileModals()}
    //   </div>
    // </React.Fragment>
    <Tabs defaultActiveKey="1" centered activeKey={viewIndex.toString()} onChange={handleChange}>
      <TabPane tab="Followers" key="1">
        <UserProfileFollowersModalMobile
          userInfo={userData}
          type={viewIndex === '1' && 'Followers'}
          userId={userId}
          storeId={storeId}
          storeName={storeName}
          refetchQuery={{
            query: GET_STORE_BY_NAME,
            variables: { storeName: parseInt(storeName) },
          }}
        />
      </TabPane>
      <TabPane tab={`${storeId ? 'Likes' : 'Following'}`} key="2">
        <UserProfileFollowersModalMobile
          userInfo={userData}
          type={viewIndex === '2' && 'Following'}
          userId={userId}
          storeId={storeId}
          storeName={storeName}
          refetchQuery={{
            query: GET_STORE_BY_NAME,
            variables: { storeName: parseInt(storeName) },
          }}
        />
      </TabPane>
    </Tabs>
  )
}
