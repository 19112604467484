import { gql } from '@apollo/client'

export default gql`
  query GetStorePosts($slug: String) {
    getStorePosts(slug: $slug) {
      data {
        id
        title
        content
        createdAt
        attachements: medias {
          id
          url
        }
        store_id
        store {
          id
          name
          logo
          cover_image
          slug
        }
        post_for
        comment_count
        likes {
          id
          user {
            id
            userName
            firstName
            lastName
            logo_image
            banner_image
          }
        }
      }
      message
      success
    }
  }
`
