import React, { useRef, useEffect, useState } from 'react'
import styles from './AddProductTomodel.module.scss'
import BookmarkSlider from '../BookmarkSlider'
import { useAppDispatch } from 'src/store/store'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'
import { useMutation, useQuery } from '@apollo/client'
import GET_ALL_BOOKMARK_COLLECTIONS from 'src/graphql/queries/getBookmarkCollections'
import ADD_BOOKMARK from 'src/graphql/mutations/addBookmark'
import { useSelector } from 'react-redux'

const Crossicon = '/assets/icon/crossicon.svg'
interface Props {
  onClose?: () => void
  productId: string | number
}

export default function AddProductCollectionModal({ onClose, productId }: Props) {
  const dispatch = useAppDispatch()

  // const closeBokMarkModel = () => {
  //   dispatch(
  //     fetchProductImageResizeModal({
  //       modal: 'mobileViewBookMarkModal',
  //       isOpen: false,
  //     }),
  //   );
  // };

  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        if (onClose){
          onClose();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [onClose])

  return (
    <div className={styles.AddProductCollectionModal} ref={modalRef}>
      <div className={styles.AddProductCollectiontitle}>
        <h1>Add Product to </h1>
        <img src={Crossicon} alt="Crossicon" onClick={onClose} />
      </div>
      <BookmarkSlider productId={productId} />
    </div>
  )
}
