import React, { useEffect, useRef, useState } from 'react'
import styles from './GroupMenubar.module.scss'
import classNames from 'classnames'
import { useAuth } from 'src/utils/auth'
import { useMutation } from '@apollo/client'
import GETGROUP from 'src/graphql/queries/getGroup'
import { useRouter } from 'next/router'
import LEAVE_GROUP from 'src/graphql/mutations/leaveGroup'
import { notification } from 'antd'
import UPDATE_GROUP_DETAIL from 'src/graphql/mutations/updateGroupDetail'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchClubMembers } from 'src/store/slices/fetchAllClubsSlice'
import client1 from 'src/utils/apolloClient'
import CHECK_MEMBER_STATUS from 'src/graphql/queries/checkMemberStatus'
import { Modal } from 'antd'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'

export default function GroupMenubar(props) {
  const { setTab, groupData, tab, handleJoinClub } = props
  const { user } = useAuth()
  const router = useRouter()
  const dropdownRef: any = useRef()
  const dispatch = useAppDispatch()
  const [tabIndex, setTabIndex] = useState(0)
  const [isEditName, setIsEditName] = useState(true)
  const [clubNameData, setClubNameData] = useState('')
  const [optionDropdown, setOptionDropdown] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [selectedGroupId, setSelectedGroupId] = useState(null)

  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const allManageClub = useSelector((state: any) => state?.clubData?.clubMembers)
  const isAdmin = allManageClub?.find((data) => data?.id === user?.id)?.isAdmin

  useEffect(() => {
    dispatch(fetchClubMembers(router?.query?.id))
  }, [router])

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (optionDropdown && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setOptionDropdown(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [optionDropdown])

  function activeLink(id) {
    setTabIndex(id)
    const navlink: any = document.querySelectorAll('.men-tab-alignment__link')
    navlink.forEach((item) => item.classList.remove('men-tab-active-link'))
    navlink.forEach((item, index) => {
      if (index === id) {
        return item.classList.add('men-tab-active-link')
      } else {
        return item
      }
    })
  }

  const handleLeaveGroup = async (id) => {
    setSelectedGroupId(id)
    // const { data } = await client1.query({
    //   query: CHECK_MEMBER_STATUS,
    //   variables: {
    //     groupId: groupData?.group?.id,
    //   },
    //   fetchPolicy: 'network-only',
    // })

    // if (data?.checkMemberStatus?.data?.isOwner) {
    setOpenConfirmation(true)
    // } else {
    //   leaveGroupCall(id, false)
    // }
  }

  const leaveGroupCall = (id, is_navigate) => {
    leaveGroup({
      variables: {
        userId: user.id,
        groupId: id,
      },
      context: { is_navigate: is_navigate },
      refetchQueries: [
        {
          query: GETGROUP,
          variables: {
            slug: router?.query?.id,
          },
        },
      ],
    })
  }

  const handleDeleteCancel = () => {
    setOpenConfirmation(false)
  }

  const [leaveGroup] = useMutation(LEAVE_GROUP, {
    onCompleted: (res, { context: { is_navigate } }) => {
      setOpenConfirmation(false)
      setOptionDropdown(false)
      dispatch(fetchClubMembers(router?.query?.id))
    },
    onError: (err) => {
      console.log('err', err)
    },
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setClubNameData(value)
  }

  const handleSaveClubName = async () => {
    await updateGroup({
      variables: {
        input: {
          id: groupData?.group?.id,
          name: clubNameData,
        },
      },
    })
  }

  const [updateGroup] = useMutation(UPDATE_GROUP_DETAIL, {
    onCompleted: (data) => {
      notification['success']({
        message: 'Group Updated',
        description: 'Group Name Updated Successfully',
      })
      router.replace(`/club/${data?.updateGroupDetail.slug}`)
      setIsEditName(true)
    },
    onError: (error) => {
      notification['error']({
        message: 'Error',
        description: 'Something went wrong, Please try later',
      })
    },
  })

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const handleCreateClub = () => {
    if (user) {
      dispatch(
        fetchModalData({
          ...getAllState,
          groupCreationModal: {
            modal: 'createClubModal',
            isOpen: true,
            id: { data: groupData, isEdit: true, slug: router?.query?.id },
            modalFor: 'smallModal',
          },
        }),
      )
    } else {
      handleRedirect()
    }
  }

  const handleCopyClick = (text) => {
    navigator?.clipboard
      .writeText(text)
      .then(() => {
        notification['success']({
          message: 'Copied!',
        })
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err)
      })
  }

  return (
    <>
      <div className={styles.clubHeaderSticky}>
        <div className={styles.clubTabDetailsAlignment}>
          <div className={styles.clubTabLeftSideAlignment}>
            <div className={styles.clubGroupProfileImgAlignment}>
              {/* <LazyImage
                image={{
                  src: groupData?.group?.coverImage?.url,
                  alt: 'Icon',
                }}
                imageClassName={'group-post-media-radious'}
                objectFit={'cover'}
                // handleClickOnImage={() => handelImageSlider()}
                className={styles.groupProfileSkeleton}
              /> */}
              {groupData?.group?.logo_image && (
                <img
                  src={
                    groupData?.group?.logo_image ? groupData?.group?.logo_image : ''
                    // groupData?.group?.logo_image ? groupData?.group?.logo_image : '/assets/img/product/noImagePreview.png'
                  }
                  alt="profile img"
                />
              )}

              {isAdmin && (
                <div className={styles.editProfileImgAlignment}>
                  <div className={styles.profileEditIcon} onClick={() => handleCreateClub()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <g clipPath="url(#clip0_1306_34307)">
                        <path
                          d="M8.25 3H3C2.60218 3 2.22064 3.15804 1.93934 3.43934C1.65804 3.72064 1.5 4.10218 1.5 4.5V15C1.5 15.3978 1.65804 15.7794 1.93934 16.0607C2.22064 16.342 2.60218 16.5 3 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V9.75"
                          stroke="white"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13.875 1.87517C14.1734 1.5768 14.578 1.40918 15 1.40918C15.422 1.40918 15.8266 1.5768 16.125 1.87517C16.4234 2.17354 16.591 2.57821 16.591 3.00017C16.591 3.42213 16.4234 3.8268 16.125 4.12517L9 11.2502L6 12.0002L6.75 9.00017L13.875 1.87517Z"
                          stroke="white"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1306_34307">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              )}
            </div>
            {isEditName ? (
              <div className={styles.profileNameAlignment}>
                <h4>{groupData?.group?.name} </h4>
                {isAdmin && (
                  <div
                    className={styles.editOption}
                    onClick={() => {
                      setClubNameData(groupData?.group?.name), setIsEditName(false)
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path
                        d="M12.75 2.25023C12.947 2.05324 13.1808 1.89699 13.4382 1.79038C13.6956 1.68378 13.9714 1.62891 14.25 1.62891C14.5286 1.62891 14.8044 1.68378 15.0618 1.79038C15.3192 1.89699 15.553 2.05324 15.75 2.25023C15.947 2.44721 16.1032 2.68106 16.2098 2.93843C16.3165 3.1958 16.3713 3.47165 16.3713 3.75023C16.3713 4.0288 16.3165 4.30465 16.2098 4.56202C16.1032 4.81939 15.947 5.05324 15.75 5.25023L5.625 15.3752L1.5 16.5002L2.625 12.3752L12.75 2.25023Z"
                        stroke="#7A7E88"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                )}
              </div>
            ) : (
              //  <======================= Edit Name Design Start ==========================>
              <div className={styles.clubNameEditAlignment}>
                <input
                  type="text"
                  placeholder="Men Fashion"
                  name="name"
                  value={clubNameData}
                  onChange={(e) => handleChange(e)}
                />

                <div className={styles.clubOptionAlignment}>
                  <div className={styles.clubOption} onClick={() => handleSaveClubName()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M20 6L9 17L4 12"
                        stroke="#389300"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className={styles.clubOption} onClick={() => setIsEditName(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                      <path
                        d="M18.5312 6.93945L6.53125 18.9395"
                        stroke="#E10000"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.53125 6.93945L18.5312 18.9395"
                        stroke="#E10000"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            )}
            {/* <======================= Edit Name Design End ==========================> */}
          </div>
          <div className={styles.clubGroupProfileRightSideAlignment}>
            <div className={styles.clubGroupProfileOption}>
              <div
                // className={`men-group-profile-option-box ${tab === 'feed' ? 'activeTabAlignmet' : ''}`}
                className={classNames(styles.clubGroupprofileOptionBox, tab === 'feed' ? styles.activeTabAlignmet : '')}
                onClick={() => {
                  activeLink(0)
                  setTab('feed')
                }}
              >
                <div>
                  <div className={styles.clubGroupnewTabIconAlignment}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M8.14992 18.6665C9.15324 18.6665 9.96658 17.8532 9.96658 16.8499C9.96658 15.8466 9.15324 15.0332 8.14992 15.0332C7.1466 15.0332 6.33325 15.8466 6.33325 16.8499C6.33325 17.8532 7.1466 18.6665 8.14992 18.6665Z"
                        fill="#636773"
                      />
                      <path
                        d="M7.65825 10.5249C6.95825 10.4082 6.33325 10.9832 6.33325 11.6915C6.33325 12.2832 6.77492 12.7582 7.35825 12.8582C9.79159 13.2832 11.7083 15.2082 12.1416 17.6415C12.2416 18.2249 12.7166 18.6665 13.3083 18.6665C14.0166 18.6665 14.5916 18.0415 14.4833 17.3415C14.2054 15.6326 13.3967 14.0545 12.1716 12.831C10.9466 11.6075 9.36753 10.8006 7.65825 10.5249ZM7.63325 5.76653C6.94159 5.69153 6.33325 6.24987 6.33325 6.94153C6.33325 7.54987 6.79159 8.04987 7.39159 8.1082C12.3999 8.6082 16.3833 12.5915 16.8833 17.5999C16.9416 18.2082 17.4416 18.6665 18.0499 18.6665C18.7499 18.6665 19.2999 18.0582 19.2333 17.3665C18.6249 11.2499 13.7583 6.37487 7.63325 5.76653Z"
                        fill="#636773"
                      />
                    </svg>
                  </div>
                  <p>Feed</p>
                </div>
              </div>
              <div
                // className={`men-group-profile-option-box ${tab === 'member' ? 'activeTabAlignmet' : ''}`}
                className={classNames(
                  styles.clubGroupprofileOptionBox,
                  tab === 'member' ? styles.activeTabAlignmet : '',
                )}
                onClick={() => {
                  activeLink(1)
                  setTab('member')
                }}
              >
                <div>
                  <div className={styles.clubGroupnewTabIconAlignment}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M12 5.5C12.9283 5.5 13.8185 5.86875 14.4749 6.52513C15.1313 7.1815 15.5 8.07174 15.5 9C15.5 9.92826 15.1313 10.8185 14.4749 11.4749C13.8185 12.1313 12.9283 12.5 12 12.5C11.0717 12.5 10.1815 12.1313 9.52513 11.4749C8.86875 10.8185 8.5 9.92826 8.5 9C8.5 8.07174 8.86875 7.1815 9.52513 6.52513C10.1815 5.86875 11.0717 5.5 12 5.5ZM5 8C5.56 8 6.08 8.15 6.53 8.42C6.38 9.85 6.8 11.27 7.66 12.38C7.16 13.34 6.16 14 5 14C4.20435 14 3.44129 13.6839 2.87868 13.1213C2.31607 12.5587 2 11.7956 2 11C2 10.2044 2.31607 9.44129 2.87868 8.87868C3.44129 8.31607 4.20435 8 5 8ZM19 8C19.7956 8 20.5587 8.31607 21.1213 8.87868C21.6839 9.44129 22 10.2044 22 11C22 11.7956 21.6839 12.5587 21.1213 13.1213C20.5587 13.6839 19.7956 14 19 14C17.84 14 16.84 13.34 16.34 12.38C17.2119 11.2544 17.6166 9.8362 17.47 8.42C17.92 8.15 18.44 8 19 8ZM5.5 18.25C5.5 16.18 8.41 14.5 12 14.5C15.59 14.5 18.5 16.18 18.5 18.25V20H5.5V18.25ZM0 20V18.5C0 17.11 1.89 15.94 4.45 15.6C3.86 16.28 3.5 17.22 3.5 18.25V20H0ZM24 20H20.5V18.25C20.5 17.22 20.14 16.28 19.55 15.6C22.11 15.94 24 17.11 24 18.5V20Z"
                        fill="#636773"
                      />
                    </svg>
                  </div>
                  <p>Member</p>
                </div>
              </div>
              <div
                // className={`men-group-profile-option-box ${tab === 'media' ? 'activeTabAlignmet' : ''}`}
                className={classNames(
                  styles.clubGroupprofileOptionBox,
                  tab === 'media' ? styles.activeTabAlignmet : '',
                )}
                onClick={() => {
                  activeLink(2)
                  setTab('media')
                }}
              >
                <div>
                  <div className={styles.clubGroupnewTabIconAlignment}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M22 8V13.81C21.12 13.3 20.1 13 19 13C15.69 13 13 15.69 13 19C13 19.34 13.04 19.67 13.09 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0391 2 18.5304 2 18V6C2 4.89 2.89 4 4 4H10L12 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8ZM17 22L22 19L17 16V22Z"
                        fill="#636773"
                      />
                    </svg>
                  </div>
                  <p>Media</p>
                </div>
              </div>
            </div>
            <div className={styles.clubGroupLastSideAlignment}>
              <div className={styles.joinButtonAlignment} onClick={() => handleJoinClub()}>
                <button className={groupData?.group?.isExist ? styles.joinedButton : ''}>
                  {groupData?.group?.isExist ? 'Joined' : 'Join'}
                </button>
              </div>
              <div
                className={styles.clubmoreOptionBoxALignment}
                onClick={() => setOptionDropdown(true)}
                ref={dropdownRef}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path
                    d="M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z"
                    fill="#636773"
                    stroke="#636773"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.25 9.75C14.6642 9.75 15 9.41421 15 9C15 8.58579 14.6642 8.25 14.25 8.25C13.8358 8.25 13.5 8.58579 13.5 9C13.5 9.41421 13.8358 9.75 14.25 9.75Z"
                    fill="#636773"
                    stroke="#636773"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.75 9.75C4.16421 9.75 4.5 9.41421 4.5 9C4.5 8.58579 4.16421 8.25 3.75 8.25C3.33579 8.25 3 8.58579 3 9C3 9.41421 3.33579 9.75 3.75 9.75Z"
                    fill="#636773"
                    stroke="#636773"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div
                  className={
                    optionDropdown
                      ? classNames(styles.otherDetailsBoxAlignment, styles.openDropdownBox)
                      : classNames(styles.otherDetailsBoxAlignment, styles.closeDropdownBox)
                  }
                >
                  <div className={styles.otherBoxBoraderAliggnment}>
                    <div
                      className={styles.otherBoxDetails}
                      onClick={() => handleCopyClick(`${process.env.NEXT_PUBLIC_DOMAIN}${router?.asPath}`)}
                    >
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z"
                            stroke="#1E2432"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5"
                            stroke="#1E2432"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className={styles.optionNameAlignment}>
                        <h4>Copy Link</h4>
                        <p>Copy link to share</p>
                      </div>
                    </div>
                    {/* <div className={styles.otherBoxDetails}>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M19 3H5C3.89543 3 3 3.89543 3 5V10C3 11.1046 3.89543 12 5 12H19C20.1046 12 21 11.1046 21 10V5C21 3.89543 20.1046 3 19 3Z"
                            stroke="#1E2432"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 15H5C3.89543 15 3 15.8954 3 17V19C3 20.1046 3.89543 21 5 21H10C11.1046 21 12 20.1046 12 19V17C12 15.8954 11.1046 15 10 15Z"
                            stroke="#1E2432"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M19 15H17C15.8954 15 15 15.8954 15 17V19C15 20.1046 15.8954 21 17 21H19C20.1046 21 21 20.1046 21 19V17C21 15.8954 20.1046 15 19 15Z"
                            stroke="#1E2432"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className={styles.optionNameAlignment}>
                        <h4>Share on my feed</h4>
                        <p>Share this club on my feed</p>
                      </div>
                    </div>
                    <div className={styles.otherBoxDetails}>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21"
                            stroke="#1E2432"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z"
                            stroke="#1E2432"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M23 20.9999V18.9999C22.9993 18.1136 22.7044 17.2527 22.1614 16.5522C21.6184 15.8517 20.8581 15.3515 20 15.1299"
                            stroke="#1E2432"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16 3.12988C16.8604 3.35018 17.623 3.85058 18.1676 4.55219C18.7122 5.2538 19.0078 6.11671 19.0078 7.00488C19.0078 7.89305 18.7122 8.75596 18.1676 9.45757C17.623 10.1592 16.8604 10.6596 16 10.8799"
                            stroke="#1E2432"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className={styles.optionNameAlignment}>
                        <h4>Share with Friends</h4>
                        <p>Share this club with friends</p>
                      </div>
                    </div> */}
                    {isAdmin && (
                      <div className={styles.otherBoxDetails}>
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M16 20V18.3333C16 17.4493 15.6067 16.6014 14.9065 15.9763C14.2064 15.3512 13.2568 15 12.2667 15H5.73333C4.74319 15 3.7936 15.3512 3.09347 15.9763C2.39333 16.6014 2 17.4493 2 18.3333V20"
                              stroke="#1E2432"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8.5 11C10.433 11 12 9.433 12 7.5C12 5.567 10.433 4 8.5 4C6.567 4 5 5.567 5 7.5C5 9.433 6.567 11 8.5 11Z"
                              stroke="#1E2432"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M19 8V14"
                              stroke="#1E2432"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M22 11H16"
                              stroke="#1E2432"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className={styles.optionNameAlignment}>
                          <h4>Invite</h4>
                          <p>Invite people to join this group</p>
                        </div>
                      </div>
                    )}
                    <div className={styles.otherBoxDetails}>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M22 16.3333C22 16.9227 21.7659 17.4879 21.3491 17.9047C20.9324 18.3214 20.3671 18.5556 19.7778 18.5556H7.03618C6.6573 18.5556 6.29393 18.7061 6.02603 18.974L4.43872 20.5613C3.53877 21.4612 2 20.8238 2 19.5511V5.22222C2 4.63285 2.23413 4.06762 2.65087 3.65087C3.06762 3.23413 3.63285 3 4.22222 3H19.7778C20.3671 3 20.9324 3.23413 21.3491 3.65087C21.7659 4.06762 22 4.63285 22 5.22222V16.3333Z"
                            stroke="#1E2432"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 7V12"
                            stroke="#1E2432"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 15H12.01"
                            stroke="#1E2432"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className={styles.optionNameAlignment}>
                        <h4>Report</h4>
                        <p>Report this club to Bluejestic</p>
                      </div>
                    </div>
                    {groupData?.group?.isExist && (
                      <div className={styles.otherBoxDetails} onClick={() => handleLeaveGroup(groupData?.group?.id)}>
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9"
                              stroke="#E71616"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M16 17L21 12L16 7"
                              stroke="#E71616"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M21 12H9"
                              stroke="#E71616"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className={classNames(styles.optionNameAlignment, styles.blockSection)}>
                          <h4>Leave Club</h4>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Remove Club"
        open={openConfirmation}
        onOk={() => leaveGroupCall(selectedGroupId, true)}
        onCancel={() => handleDeleteCancel()}
        wrapClassName="post-add-edit-modal"
        maskStyle={{ zIndex: 9999 }}
      >
        <p>Are you sure you want to leave this club?</p>
      </Modal>
    </>
  )
}
