import { gql } from '@apollo/client'

const ADD_BOOKMARK = gql`
  mutation addBookmark($productId: Int!, $collectionId: Int) {
    addBookmark(product_id: $productId, collection_id: $collectionId) {
      id
      product_id
    }
  }
`
export default ADD_BOOKMARK
