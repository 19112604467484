import React from 'react'
import styles from './noDataSection.module.scss'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
const VideoIcon = '/assets/img/video-vector.svg'
export default function NoDataSection({ text, title, loading }: { text?: string, title?: string, loading?: boolean }) {
  return (
    <>
      {loading ? <SkeletonTheme baseColor="#d1d1d1" highlightColor="#FFFFFF">
        <Skeleton
          style={{
            width: '100%',
            height: '300px',
          }}
        />
      </SkeletonTheme> : <div className={styles.noDataSectionAlignment}>
        <div className={styles.noDataTitle}>{title} </div>
        <div className={styles.subboxDesign}>
          <div className={styles.centerAlignmentIcon}>
            <img src={VideoIcon} alt="VideoIcon" />
          </div>
          <p>{text || `We don't have a live stream happening right now, but check back soon!`}</p>
        </div>
      </div>}

    </>
  )
}
