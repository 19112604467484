import React, { useState } from 'react'
import styles from './SellerLeftSidebar.module.scss'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { useAuth } from 'src/utils/auth'

export default function SellerLeftSidebar({ setOpenSidebar, openSidebar }) {
  const { seller: authSeller }: any = useAuth()
  const router = useRouter()
  const path = router.asPath
  const seller = path.split('/')[2]

  return (
    <div
      className={
        openSidebar
          ? classNames(styles.sellerLeftSidebarSection, styles.activeSidebarDetails)
          : styles.sellerLeftSidebarSection
      }
    >
      {/* <div className={styles.sellerLeftSidebarSection}> */}
      <div className={styles.sellerLeftSidebarAlignment}>
        <div className={styles.sellerMainSidebarHeadingAlignment}>
          <div className={styles.sellerMainProfileImgAlignment}>
            <img src={authSeller?.seller_detail?.logo_image} alt="seller profile img" />
          </div>
          <div className={styles.sellerToggleIcon} onClick={() => setOpenSidebar(!openSidebar)}>
            <img src="/assets/seller/icons/menu.svg" alt="menu icon" />
          </div>
          <div className={styles.sellerMainProfileDetailsAlignment}>
            <h6>{authSeller?.seller_detail?.name}</h6>
            <div className={styles.sellerProfiileRateFlexAlignment}>
              <div className={styles.sellerOverRateAlignment}>
                <p>4.8</p>
              </div>
              <div className={styles.sellerRateStarFlexAlignment}>
                {[0, 1, 2, 3].map((e, i) => {
                  return (
                    <div key={i} className={styles.starIconAlignment}>
                      <img src="/assets/seller/icons/star-filled.svg" alt="star" />
                    </div>
                  )
                })}
                <div className={styles.starIconAlignment}>
                  <img src="/assets/seller/icons/halfRateStar.svg" alt="star" />
                </div>
              </div>
              <span>(437)</span>
            </div>
            <div className={styles.mobileViewLocationDetailsAlignment}>
              <div className={styles.mobileViewMapIcon}>
                <img src="/assets/seller/icons/map-icon.svg" alt="map icon" />
              </div>
              <p>Sandford, Florida</p>
            </div>
          </div>
        </div>
        <div className={styles.sellerMainSidebarDetailsAlignment}>
          <ul>
            <li>
              <Link href="/seller/dashboard">
                <div
                  className={classNames(
                    styles.sellerOptionAlignment,
                    seller === 'dashboard' ? styles.activeOption : '',
                  )}
                >
                  <div className={styles.sellerHoverImg}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4.0625 11.375C3.84701 11.375 3.64035 11.2894 3.48798 11.137C3.3356 10.9846 3.25 10.778 3.25 10.5625V4.06409C3.25 3.8486 3.3356 3.64194 3.48798 3.48956C3.64035 3.33719 3.84701 3.25159 4.0625 3.25159H10.5625C10.778 3.25159 10.9847 3.33719 11.137 3.48956C11.2894 3.64194 11.375 3.8486 11.375 4.06409V10.5625C11.375 10.778 11.2894 10.9846 11.137 11.137C10.9847 11.2894 10.778 11.375 10.5625 11.375H4.0625ZM15.4375 11.375C15.222 11.375 15.0153 11.2894 14.863 11.137C14.7106 10.9846 14.625 10.778 14.625 10.5625V4.06409C14.625 3.8486 14.7106 3.64194 14.863 3.48956C15.0153 3.33719 15.222 3.25159 15.4375 3.25159H21.9359C22.1514 3.25159 22.358 3.33719 22.5104 3.48956C22.6628 3.64194 22.7484 3.8486 22.7484 4.06409V10.5625C22.7484 10.778 22.6628 10.9846 22.5104 11.137C22.358 11.2894 22.1514 11.375 21.9359 11.375H15.4375ZM4.0625 22.75C3.84701 22.75 3.64035 22.6644 3.48798 22.512C3.3356 22.3596 3.25 22.153 3.25 21.9375V15.4375C3.25 15.222 3.3356 15.0153 3.48798 14.8629C3.64035 14.7106 3.84701 14.625 4.0625 14.625H10.5625C10.778 14.625 10.9847 14.7106 11.137 14.8629C11.2894 15.0153 11.375 15.222 11.375 15.4375V21.9375C11.375 22.153 11.2894 22.3596 11.137 22.512C10.9847 22.6644 10.778 22.75 10.5625 22.75H4.0625ZM15.4375 22.75C15.222 22.75 15.0153 22.6644 14.863 22.512C14.7106 22.3596 14.625 22.153 14.625 21.9375V15.4375C14.625 15.222 14.7106 15.0153 14.863 14.8629C15.0153 14.7106 15.222 14.625 15.4375 14.625H21.9359C22.1514 14.625 22.358 14.7106 22.5104 14.8629C22.6628 15.0153 22.7484 15.222 22.7484 15.4375V21.9375C22.7484 22.153 22.6628 22.3596 22.5104 22.512C22.358 22.6644 22.1514 22.75 21.9359 22.75H15.4375Z"
                        fill="#556EE6"
                      />
                    </svg>
                  </div>
                  <div className={styles.sellerDefaltImg}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4.0625 11.3751C3.84701 11.3751 3.64035 11.2895 3.48798 11.1371C3.3356 10.9847 3.25 10.7781 3.25 10.5626V4.06421C3.25 3.84872 3.3356 3.64206 3.48798 3.48968C3.64035 3.33731 3.84701 3.25171 4.0625 3.25171H10.5625C10.778 3.25171 10.9847 3.33731 11.137 3.48968C11.2894 3.64206 11.375 3.84872 11.375 4.06421V10.5626C11.375 10.7781 11.2894 10.9847 11.137 11.1371C10.9847 11.2895 10.778 11.3751 10.5625 11.3751H4.0625ZM15.4375 11.3751C15.222 11.3751 15.0153 11.2895 14.863 11.1371C14.7106 10.9847 14.625 10.7781 14.625 10.5626V4.06421C14.625 3.84872 14.7106 3.64206 14.863 3.48968C15.0153 3.33731 15.222 3.25171 15.4375 3.25171H21.9359C22.1514 3.25171 22.358 3.33731 22.5104 3.48968C22.6628 3.64206 22.7484 3.84872 22.7484 4.06421V10.5626C22.7484 10.7781 22.6628 10.9847 22.5104 11.1371C22.358 11.2895 22.1514 11.3751 21.9359 11.3751H15.4375ZM4.0625 22.7501C3.84701 22.7501 3.64035 22.6645 3.48798 22.5121C3.3356 22.3597 3.25 22.1531 3.25 21.9376V15.4376C3.25 15.2221 3.3356 15.0154 3.48798 14.863C3.64035 14.7107 3.84701 14.6251 4.0625 14.6251H10.5625C10.778 14.6251 10.9847 14.7107 11.137 14.863C11.2894 15.0154 11.375 15.2221 11.375 15.4376V21.9376C11.375 22.1531 11.2894 22.3597 11.137 22.5121C10.9847 22.6645 10.778 22.7501 10.5625 22.7501H4.0625ZM15.4375 22.7501C15.222 22.7501 15.0153 22.6645 14.863 22.5121C14.7106 22.3597 14.625 22.1531 14.625 21.9376V15.4376C14.625 15.2221 14.7106 15.0154 14.863 14.863C15.0153 14.7107 15.222 14.6251 15.4375 14.6251H21.9359C22.1514 14.6251 22.358 14.7107 22.5104 14.863C22.6628 15.0154 22.7484 15.2221 22.7484 15.4376V21.9376C22.7484 22.1531 22.6628 22.3597 22.5104 22.5121C22.358 22.6645 22.1514 22.7501 21.9359 22.7501H15.4375Z"
                        fill="#7A7E88"
                      />
                    </svg>
                  </div>

                  <span>Dashboard</span>
                </div>
              </Link>
            </li>
            <li>
              <Link href="/seller/social-activity">
                <div
                  className={classNames(
                    styles.sellerOptionAlignment,
                    seller === 'social-activity' ? styles.activeOption : '',
                  )}
                >
                  <div className={styles.sellerDefaltImg}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.79837 16.9C6.79837 17.9451 5.98465 18.75 5.03224 18.75C4.07984 18.75 3.26611 17.9451 3.26611 16.9C3.26611 15.8548 4.07984 15.0499 5.03224 15.0499C5.98465 15.0499 6.79837 15.8548 6.79837 16.9Z"
                        stroke="#7A7E88"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M9.31452 23.3997C9.31452 23.6935 9.06844 24.1471 8.24633 24.5719C7.46238 24.9769 6.32721 25.2497 5.03226 25.2497C3.73731 25.2497 2.60214 24.9769 1.81818 24.5719C0.996073 24.1471 0.75 23.6935 0.75 23.3997C0.75 23.106 0.996073 22.6523 1.81818 22.2275C2.60214 21.8225 3.73731 21.5497 5.03226 21.5497C6.32721 21.5497 7.46238 21.8225 8.24633 22.2275C9.06844 22.6523 9.31452 23.106 9.31452 23.3997Z"
                        stroke="#7A7E88"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M7.21756 2.31102C7.21756 3.19659 6.52901 3.87216 5.731 3.87216C4.933 3.87216 4.24445 3.19659 4.24445 2.31102C4.24445 1.42544 4.933 0.749878 5.731 0.749878C6.52901 0.749878 7.21756 1.42544 7.21756 2.31102Z"
                        stroke="#7A7E88"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M9.45429 8.08836C9.45429 8.30235 9.2708 8.68378 8.54988 9.05627C7.86711 9.40904 6.87172 9.6495 5.73117 9.6495C4.59063 9.6495 3.59524 9.40904 2.91247 9.05627C2.19155 8.68378 2.00806 8.30235 2.00806 8.08836C2.00806 7.87438 2.19155 7.49294 2.91247 7.12046C3.59524 6.76769 4.59063 6.52722 5.73117 6.52722C6.87172 6.52722 7.86711 6.76769 8.54988 7.12046C9.2708 7.49294 9.45429 7.87438 9.45429 8.08836Z"
                        stroke="#7A7E88"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M21.8947 7.366C21.8947 8.88978 20.7055 10.0827 19.2899 10.0827C17.8743 10.0827 16.6851 8.88978 16.6851 7.366C16.6851 5.84222 17.8743 4.64929 19.2899 4.64929C20.7055 4.64929 21.8947 5.84222 21.8947 7.366Z"
                        stroke="#7A7E88"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M25.2499 16.0332C25.2499 16.5663 24.8161 17.2367 23.6904 17.8183C22.6029 18.3801 21.0484 18.7499 19.2902 18.7499C17.5321 18.7499 15.9776 18.3801 14.8901 17.8183C13.7644 17.2367 13.3306 16.5663 13.3306 16.0332C13.3306 15.5002 13.7644 14.8298 14.8901 14.2482C15.9776 13.6863 17.5321 13.3165 19.2902 13.3165C21.0484 13.3165 22.6029 13.6863 23.6904 14.2482C24.8161 14.8298 25.2499 15.5002 25.2499 16.0332Z"
                        stroke="#7A7E88"
                        strokeWidth="1.5"
                      />
                    </svg>
                  </div>
                  <div className={styles.sellerHoverImg}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_27_1351)">
                        <path
                          d="M6.79837 16.8999C6.79837 17.945 5.98465 18.7499 5.03224 18.7499C4.07984 18.7499 3.26611 17.945 3.26611 16.8999C3.26611 15.8547 4.07984 15.0498 5.03224 15.0498C5.98465 15.0498 6.79837 15.8547 6.79837 16.8999Z"
                          stroke="#556EE6"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M9.31452 23.3998C9.31452 23.6936 9.06844 24.1472 8.24633 24.572C7.46238 24.9769 6.32721 25.2498 5.03226 25.2498C3.73731 25.2498 2.60214 24.9769 1.81818 24.572C0.996073 24.1472 0.75 23.6936 0.75 23.3998C0.75 23.1061 0.996073 22.6524 1.81818 22.2276C2.60214 21.8226 3.73731 21.5498 5.03226 21.5498C6.32721 21.5498 7.46238 21.8226 8.24633 22.2276C9.06844 22.6524 9.31452 23.1061 9.31452 23.3998Z"
                          stroke="#556EE6"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M7.21749 2.31114C7.21749 3.19671 6.52894 3.87228 5.73093 3.87228C4.93293 3.87228 4.24438 3.19671 4.24438 2.31114C4.24438 1.42556 4.93293 0.749878 5.73093 0.749878C6.52894 0.749878 7.21749 1.42556 7.21749 2.31114Z"
                          stroke="#556EE6"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M9.45429 8.08824C9.45429 8.30223 9.2708 8.68366 8.54988 9.05615C7.86711 9.40892 6.87172 9.64938 5.73117 9.64938C4.59063 9.64938 3.59524 9.40892 2.91247 9.05615C2.19155 8.68366 2.00806 8.30223 2.00806 8.08824C2.00806 7.87426 2.19155 7.49282 2.91247 7.12034C3.59524 6.76757 4.59063 6.5271 5.73117 6.5271C6.87172 6.5271 7.86711 6.76757 8.54988 7.12034C9.2708 7.49282 9.45429 7.87426 9.45429 8.08824Z"
                          stroke="#556EE6"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M21.8947 7.36588C21.8947 8.88966 20.7055 10.0826 19.2899 10.0826C17.8743 10.0826 16.6851 8.88966 16.6851 7.36588C16.6851 5.8421 17.8743 4.64917 19.2899 4.64917C20.7055 4.64917 21.8947 5.8421 21.8947 7.36588Z"
                          stroke="#556EE6"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M25.2499 16.0331C25.2499 16.5662 24.8161 17.2366 23.6904 17.8182C22.6029 18.38 21.0484 18.7498 19.2902 18.7498C17.5321 18.7498 15.9776 18.38 14.8901 17.8182C13.7644 17.2366 13.3306 16.5662 13.3306 16.0331C13.3306 15.5001 13.7644 14.8297 14.8901 14.2481C15.9776 13.6862 17.5321 13.3164 19.2902 13.3164C21.0484 13.3164 22.6029 13.6862 23.6904 14.2481C24.8161 14.8297 25.2499 15.5001 25.2499 16.0331Z"
                          stroke="#556EE6"
                          strokeWidth="1.5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_27_1351">
                          <rect width="26" height="26" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>

                  <span>Social Activity</span>
                </div>
              </Link>
            </li>
            <li>
              <Link href="/seller/insight">
                <div
                  className={classNames(styles.sellerOptionAlignment, seller === 'insight' ? styles.activeOption : '')}
                >
                  <div className={styles.sellerDefaltImg}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.25 8.66646H20.5834V21.6665H5.41669V10.8331H9.75002V4.33313H16.25V8.66646ZM14.0834 6.4998H11.9167V19.4998H14.0834V6.4998ZM16.25 10.8331V19.4998H18.4167V10.8331H16.25ZM9.75002 12.9998V19.4998H7.58335V12.9998H9.75002Z"
                        fill="#7A7E88"
                      />
                    </svg>
                  </div>
                  <div className={styles.sellerHoverImg}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.2501 8.66634H20.5835V21.6664H5.41675V10.833H9.75008V4.33301H16.2501V8.66634ZM14.0835 6.49968H11.9168V19.4997H14.0835V6.49968ZM16.2501 10.833V19.4997H18.4168V10.833H16.2501ZM9.75008 12.9997V19.4997H7.58341V12.9997H9.75008Z"
                        fill="#556EE6"
                      />
                    </svg>
                  </div>

                  <span>Insight</span>
                </div>
              </Link>
            </li>
            <li>
              <Link href="/seller/orders">
                <div
                  className={classNames(styles.sellerOptionAlignment, seller === 'orders' ? styles.activeOption : '')}
                >
                  <div className={styles.sellerDefaltImg}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1.625 22.7498H24.375V24.3748H1.625V22.7498ZM21.9375 8.93728C22.153 8.93728 22.3597 8.85167 22.512 8.6993C22.6644 8.54693 22.75 8.34026 22.75 8.12478V5.68728C22.7499 5.5203 22.6984 5.35739 22.6025 5.22073C22.5065 5.08408 22.3708 4.9803 22.2138 4.92353L13.2763 1.67353C13.0977 1.60899 12.9023 1.60899 12.7237 1.67353L3.78625 4.92353C3.62922 4.9803 3.49349 5.08408 3.39754 5.22073C3.30159 5.35739 3.25007 5.5203 3.25 5.68728V8.12478C3.25 8.34026 3.3356 8.54693 3.48798 8.6993C3.64035 8.85167 3.84701 8.93728 4.0625 8.93728H4.875V19.4998H3.25V21.1248H22.75V19.4998H21.125V8.93728H21.9375ZM4.875 6.25603L13 3.29853L21.125 6.25603V7.31228H4.875V6.25603ZM14.625 19.4998H11.375V8.93728H14.625V19.4998ZM6.5 8.93728H9.75V19.4998H6.5V8.93728ZM19.5 19.4998H16.25V8.93728H19.5V19.4998Z"
                        fill="#7A7E88"
                      />
                    </svg>
                  </div>
                  <div className={styles.sellerHoverImg}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1.625 22.7499H24.375V24.3749H1.625V22.7499ZM21.9375 8.9374C22.153 8.9374 22.3597 8.85179 22.512 8.69942C22.6644 8.54705 22.75 8.34038 22.75 8.1249V5.6874C22.7499 5.52042 22.6984 5.35751 22.6025 5.22085C22.5065 5.0842 22.3708 4.98042 22.2138 4.92365L13.2763 1.67365C13.0977 1.60911 12.9023 1.60911 12.7237 1.67365L3.78625 4.92365C3.62922 4.98042 3.49349 5.0842 3.39754 5.22085C3.30159 5.35751 3.25007 5.52042 3.25 5.6874V8.1249C3.25 8.34038 3.3356 8.54705 3.48798 8.69942C3.64035 8.85179 3.84701 8.9374 4.0625 8.9374H4.875V19.4999H3.25V21.1249H22.75V19.4999H21.125V8.9374H21.9375ZM4.875 6.25615L13 3.29865L21.125 6.25615V7.3124H4.875V6.25615ZM14.625 19.4999H11.375V8.9374H14.625V19.4999ZM6.5 8.9374H9.75V19.4999H6.5V8.9374ZM19.5 19.4999H16.25V8.9374H19.5V19.4999Z"
                        fill="#556EE6"
                      />
                    </svg>
                  </div>

                  <span>Orders</span>
                </div>
              </Link>
            </li>
            <li>
              <Link href="/seller/finance">
                <div
                  className={classNames(styles.sellerOptionAlignment, seller === 'finance' ? styles.activeOption : '')}
                >
                  <div className={styles.sellerDefaltImg}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1.625 22.7498H24.375V24.3748H1.625V22.7498ZM21.9375 8.93728C22.153 8.93728 22.3597 8.85167 22.512 8.6993C22.6644 8.54693 22.75 8.34026 22.75 8.12478V5.68728C22.7499 5.5203 22.6984 5.35739 22.6025 5.22073C22.5065 5.08408 22.3708 4.9803 22.2138 4.92353L13.2763 1.67353C13.0977 1.60899 12.9023 1.60899 12.7237 1.67353L3.78625 4.92353C3.62922 4.9803 3.49349 5.08408 3.39754 5.22073C3.30159 5.35739 3.25007 5.5203 3.25 5.68728V8.12478C3.25 8.34026 3.3356 8.54693 3.48798 8.6993C3.64035 8.85167 3.84701 8.93728 4.0625 8.93728H4.875V19.4998H3.25V21.1248H22.75V19.4998H21.125V8.93728H21.9375ZM4.875 6.25603L13 3.29853L21.125 6.25603V7.31228H4.875V6.25603ZM14.625 19.4998H11.375V8.93728H14.625V19.4998ZM6.5 8.93728H9.75V19.4998H6.5V8.93728ZM19.5 19.4998H16.25V8.93728H19.5V19.4998Z"
                        fill="#7A7E88"
                      />
                    </svg>
                  </div>
                  <div className={styles.sellerHoverImg}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1.625 22.7499H24.375V24.3749H1.625V22.7499ZM21.9375 8.9374C22.153 8.9374 22.3597 8.85179 22.512 8.69942C22.6644 8.54705 22.75 8.34038 22.75 8.1249V5.6874C22.7499 5.52042 22.6984 5.35751 22.6025 5.22085C22.5065 5.0842 22.3708 4.98042 22.2138 4.92365L13.2763 1.67365C13.0977 1.60911 12.9023 1.60911 12.7237 1.67365L3.78625 4.92365C3.62922 4.98042 3.49349 5.0842 3.39754 5.22085C3.30159 5.35751 3.25007 5.52042 3.25 5.6874V8.1249C3.25 8.34038 3.3356 8.54705 3.48798 8.69942C3.64035 8.85179 3.84701 8.9374 4.0625 8.9374H4.875V19.4999H3.25V21.1249H22.75V19.4999H21.125V8.9374H21.9375ZM4.875 6.25615L13 3.29865L21.125 6.25615V7.3124H4.875V6.25615ZM14.625 19.4999H11.375V8.9374H14.625V19.4999ZM6.5 8.9374H9.75V19.4999H6.5V8.9374ZM19.5 19.4999H16.25V8.9374H19.5V19.4999Z"
                        fill="#556EE6"
                      />
                    </svg>
                  </div>

                  <span>Finance</span>
                </div>
              </Link>
            </li>
            <li>
              <Link href="/seller/products">
                <div
                  className={classNames(styles.sellerOptionAlignment, seller === 'products' ? styles.activeOption : '')}
                >
                  <div className={styles.sellerDefaltImg}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20.7437 3.2183C20.5506 2.8982 20.2784 2.63324 19.9531 2.44899C19.6279 2.26474 19.2607 2.16741 18.8869 2.16638H7.11319C6.35702 2.16638 5.64527 2.56938 5.25635 3.2183L2.3216 8.10847C2.22005 8.27683 2.16648 8.46976 2.16669 8.66638C2.17101 9.71203 2.55614 10.7203 3.25002 11.5025V20.583C3.25002 21.778 4.22177 22.7497 5.41669 22.7497H20.5834C21.7783 22.7497 22.75 21.778 22.75 20.583V11.5025C23.4439 10.7203 23.829 9.71203 23.8334 8.66638C23.8336 8.46976 23.78 8.27683 23.6784 8.10847L20.7437 3.2183ZM21.6494 8.9383C21.5825 9.46135 21.3274 9.94216 20.9319 10.2909C20.5363 10.6396 20.0273 10.8323 19.5 10.833C18.3051 10.833 17.3334 9.8613 17.3334 8.66638C17.3334 8.59272 17.3063 8.52772 17.2911 8.45838L17.3128 8.45405L16.4884 4.33305H18.8869L21.6494 8.9383V8.9383ZM11.7206 4.33305H14.2784L15.1591 8.7368C15.1212 9.89813 14.17 10.833 13 10.833C11.83 10.833 10.8789 9.89813 10.8409 8.7368L11.7206 4.33305ZM7.11319 4.33305H9.51169L8.68835 8.45405L8.71002 8.45838C8.69377 8.52772 8.66669 8.59272 8.66669 8.66638C8.66669 9.8613 7.69494 10.833 6.50002 10.833C5.97271 10.8323 5.46371 10.6396 5.06816 10.2909C4.6726 9.94216 4.41756 9.46135 4.35069 8.9383L7.11319 4.33305ZM10.8334 20.583V17.333H15.1667V20.583H10.8334ZM17.3334 20.583V17.333C17.3334 16.1381 16.3616 15.1664 15.1667 15.1664H10.8334C9.63844 15.1664 8.66669 16.1381 8.66669 17.333V20.583H5.41669V12.8459C5.76444 12.9358 6.12302 12.9997 6.50002 12.9997C7.11515 13 7.72327 12.8691 8.28373 12.6156C8.8442 12.362 9.3441 11.9918 9.75002 11.5296C10.5441 12.431 11.7076 12.9997 13 12.9997C14.2924 12.9997 15.4559 12.431 16.25 11.5296C16.6559 11.9918 17.1558 12.362 17.7163 12.6156C18.2768 12.8691 18.8849 13 19.5 12.9997C19.877 12.9997 20.2356 12.9358 20.5834 12.8459V20.583H17.3334Z"
                        fill="#7A7E88"
                      />
                    </svg>
                  </div>
                  <div className={styles.sellerHoverImg}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20.7438 3.21818C20.5507 2.89808 20.2785 2.63312 19.9532 2.44887C19.628 2.26462 19.2608 2.16729 18.887 2.16626H7.11325C6.35708 2.16626 5.64533 2.56926 5.25641 3.21818L2.32166 8.10835C2.22011 8.27671 2.16654 8.46964 2.16675 8.66626C2.17107 9.71191 2.5562 10.7202 3.25008 11.5024V20.5829C3.25008 21.7779 4.22183 22.7496 5.41675 22.7496H20.5835C21.7784 22.7496 22.7501 21.7779 22.7501 20.5829V11.5024C23.444 10.7202 23.8291 9.71191 23.8335 8.66626C23.8337 8.46964 23.7801 8.27671 23.6785 8.10835L20.7438 3.21818ZM21.6495 8.93818C21.5826 9.46123 21.3275 9.94204 20.932 10.2908C20.5364 10.6395 20.0274 10.8322 19.5001 10.8329C18.3052 10.8329 17.3335 9.86118 17.3335 8.66626C17.3335 8.5926 17.3064 8.5276 17.2912 8.45826L17.3129 8.45393L16.4885 4.33293H18.887L21.6495 8.93818ZM11.7207 4.33293H14.2785L15.1592 8.73668C15.1213 9.89801 14.1701 10.8329 13.0001 10.8329C11.8301 10.8329 10.879 9.89801 10.841 8.73668L11.7207 4.33293ZM7.11325 4.33293H9.51175L8.68841 8.45393L8.71008 8.45826C8.69383 8.5276 8.66675 8.5926 8.66675 8.66626C8.66675 9.86118 7.695 10.8329 6.50008 10.8329C5.97277 10.8322 5.46377 10.6395 5.06822 10.2908C4.67266 9.94204 4.41762 9.46123 4.35075 8.93818L7.11325 4.33293ZM10.8335 20.5829V17.3329H15.1668V20.5829H10.8335ZM17.3335 20.5829V17.3329C17.3335 16.138 16.3617 15.1663 15.1668 15.1663H10.8335C9.6385 15.1663 8.66675 16.138 8.66675 17.3329V20.5829H5.41675V12.8458C5.7645 12.9357 6.12308 12.9996 6.50008 12.9996C7.11521 12.9999 7.72333 12.869 8.28379 12.6155C8.84426 12.3619 9.34416 11.9917 9.75008 11.5295C10.5442 12.4309 11.7077 12.9996 13.0001 12.9996C14.2925 12.9996 15.456 12.4309 16.2501 11.5295C16.656 11.9917 17.1559 12.3619 17.7164 12.6155C18.2769 12.869 18.885 12.9999 19.5001 12.9996C19.8771 12.9996 20.2357 12.9357 20.5835 12.8458V20.5829H17.3335Z"
                        fill="#556EE6"
                      />
                    </svg>
                  </div>
                  <span>Products</span>
                </div>
              </Link>
            </li>
            <li>
              <Link href="/seller/order-shipping">
                <div
                  className={classNames(
                    styles.sellerOptionAlignment,
                    seller === 'order-shipping' ? styles.activeOption : '',
                  )}
                >
                  <div className={styles.sellerDefaltImg}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.9481 2.75143C12.2699 2.57272 12.6319 2.47894 13 2.47894C13.3681 2.47894 13.7301 2.57272 14.0519 2.75143L22.1932 7.27326C22.362 7.36712 22.5026 7.5044 22.6005 7.67087C22.6984 7.83735 22.75 8.02697 22.75 8.2201V17.1413C22.7499 17.5277 22.6465 17.907 22.4505 18.24C22.2545 18.5729 21.973 18.8474 21.6353 19.035L14.0519 23.2492C13.7301 23.4279 13.3681 23.5217 13 23.5217C12.6319 23.5217 12.2699 23.4279 11.9481 23.2492L4.36475 19.035C4.02714 18.8475 3.74577 18.5732 3.54977 18.2405C3.35378 17.9077 3.25028 17.5286 3.25 17.1424V8.2201C3.24999 8.02697 3.30161 7.83735 3.39952 7.67087C3.49742 7.5044 3.63805 7.36712 3.80683 7.27326L11.9492 2.75143H11.9481Z"
                        stroke="#7A7E88"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13 12.9997V23.2914M3.25 7.58307L13 12.9997L3.25 7.58307ZM13 12.9997L22.75 7.58307L13 12.9997Z"
                        stroke="#7A7E88"
                        strokeWidth="2"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.125 10.2914L17.875 4.87476"
                        stroke="#7A7E88"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.5 13.3551L9.75 15.1664"
                        stroke="#7A7E88"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className={styles.sellerHoverImg}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.9481 2.75101C12.2699 2.5723 12.6319 2.47852 13 2.47852C13.3681 2.47852 13.7301 2.5723 14.0519 2.75101L22.1932 7.27284C22.362 7.3667 22.5026 7.50398 22.6005 7.67045C22.6984 7.83693 22.75 8.02655 22.75 8.21968V17.1409C22.7499 17.5273 22.6465 17.9066 22.4505 18.2396C22.2545 18.5725 21.973 18.847 21.6353 19.0346L14.0519 23.2488C13.7301 23.4275 13.3681 23.5213 13 23.5213C12.6319 23.5213 12.2699 23.4275 11.9481 23.2488L4.36475 19.0346C4.02714 18.8471 3.74577 18.5728 3.54977 18.2401C3.35378 17.9073 3.25028 17.5282 3.25 17.142V8.21968C3.24999 8.02655 3.30161 7.83693 3.39952 7.67045C3.49742 7.50398 3.63805 7.3667 3.80683 7.27284L11.9492 2.75101H11.9481Z"
                        stroke="#556EE6"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13 12.9996V23.2913M13 12.9996L3.25 7.58301M13 12.9996L22.75 7.58301"
                        stroke="#556EE6"
                        strokeWidth="2"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.125 10.2916L17.875 4.875"
                        stroke="#556EE6"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.5 13.3555L9.75 15.1668"
                        stroke="#556EE6"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>

                  <span>Shipping</span>
                </div>
              </Link>
            </li>
            <li>
              <Link href="/seller/resources">
                <div
                  className={classNames(
                    styles.sellerOptionAlignment,
                    seller === 'resources' ? styles.activeOption : '',
                  )}
                >
                  <div className={styles.sellerDefaltImg}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_504_16230)">
                        <path
                          d="M4.329 10.3998L13 15.5998L26 7.79976L13 -0.000244141L0 7.79976H13V10.3998H4.329ZM0 10.3998V20.7998L2.6 17.9138V11.9598L0 10.3998ZM13 25.9998L6.5 22.0998L3.9 20.5398V12.7398L13 18.1998L22.1 12.7398V20.5398L13 25.9998Z"
                          fill="#7A7E88"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_504_16230">
                          <rect width="26" height="26" fill="white" transform="translate(0 -0.000244141)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className={styles.sellerHoverImg}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_27_1360)">
                        <mask id="mask0_27_1360" maskUnits="userSpaceOnUse" x="0" y="-1" width="26" height="27">
                          <path d="M26 -0.000244141H0V25.9998H26V-0.000244141Z" fill="white" />
                        </mask>
                        <g mask="url(#mask0_27_1360)">
                          <path
                            d="M4.329 10.3998L13 15.5998L26 7.79976L13 -0.000244141L0 7.79976H13V10.3998H4.329ZM0 10.3998V20.7998L2.6 17.9138V11.9598L0 10.3998ZM13 25.9998L6.5 22.0998L3.9 20.5398V12.7398L13 18.1998L22.1 12.7398V20.5398L13 25.9998Z"
                            fill="#556EE6"
                          />
                        </g>
                      </g>
                      <defs>
                        <clipPath id="clip0_27_1360">
                          <rect width="26" height="26" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>

                  <span>Resources</span>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.sellerSettingAlignment}>
        <ul>
          <li>
            <Link href="/seller/setting">
              <div
                className={classNames(styles.sellerOptionAlignment, seller === 'setting' ? styles.activeOption : '')}
              >
                <div className={styles.sellerHoverImg}>
                  <img src="/assets/seller/icons/setting-icon-active.svg" />
                </div>
                <div className={styles.sellerDefaltImg}>
                  <img src="/assets/seller/icons/setting-icon.svg" />
                </div>

                <span>Settings</span>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}
