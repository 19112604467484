import React, { useState, useRef, useEffect } from 'react';
import { notification } from 'antd';
import styles from './report.module.scss';
import { useQuery, useMutation } from '@apollo/client';
import GET_REPORT_CONTENT from 'src/graphql/queries/getReportContent';
import CREATE_REPORT from 'src/graphql/mutations/createReport';
import { useAuth } from 'src/utils/auth'
import PostheaderMobile from '../Feed/NormalPost/PostHeader/postheadermobile';

interface ReportPostModalProps {
  isOpen: boolean;
  onClose: () => void;
  postId: string;
  isSharedPost: boolean;
  // onSubmit: (reason: string) => void;
}

const ReportPostModal: React.FC<ReportPostModalProps> = ({
  isOpen,
  onClose,
  postId,
  isSharedPost,
  // onSubmit,
}) => {
  const [isMobileView, setIsMobileView] = useState(false);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const postheaderMobileRef = useRef(null);
  const { user }: any = useAuth()

  const { data: content, loading } = useQuery(GET_REPORT_CONTENT, {
    skip: !user,
  });

  const [createReport] = useMutation(CREATE_REPORT);

  const reportReasons = content?.getAllReportPostContents?.data;

  useEffect(() => {
    const checkMobileView = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    checkMobileView();
    window.addEventListener('resize', checkMobileView);

    return () => window.removeEventListener('resize', checkMobileView);
  }, []);

  // Reset bottomsheet state when modal closes
  useEffect(() => {
    if (!isOpen) {
      setIsBottomSheetOpen(false);
    }
  }, [isOpen]);

  // Handle bottomsheet open/close
  useEffect(() => {
    if (isOpen && isMobileView && !isBottomSheetOpen) {
      setTimeout(() => {
        postheaderMobileRef.current?.toggleOpen();
        setIsBottomSheetOpen(true);
      }, 0);
    }
  }, [isOpen, isMobileView, isBottomSheetOpen]);

  const handleReasonClick = async (reason: string, reportPostContentsId: number) => {
    try {
      const response = await createReport({
        variables: {
          input: {
            postId,
            reportPostContentsId,
            isSharedPost,
          },
        },
      });

      if (response.data?.createReportPost?.success) {
        notification.success({
          message: 'Report Submitted',
          description: 'Thank you for reporting. We will review this post.',
        });
        if (isMobileView) {
          postheaderMobileRef.current?.toggleOpen();
        }
        onClose();
      }
    } catch (error) {
      console.error('Error creating report:', error);
      notification.error({
        message: 'Report Submission Failed',
        description: 'Unable to submit your report. Please try again later.',
      });
    }
  };

  if (!isOpen) return null;

  const reportContent = (
    <div className={`${isMobileView ? styles.reportPostModalmainMobile : styles.reportPostModalmain} `}>
      <div className={styles.reportPostModal}>
        <div className={styles.reportPostModalheader}>
          {!isMobileView && <div></div>}
          <h1>Post Reporting</h1>
          {!isMobileView && (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={onClose}
              style={{ cursor: 'pointer' }}
            >
              <g clip-path="url(#clip0_2848_6278)">
                <path d="M3.516 20.484C2.36988 19.377 1.45569 18.0529 0.826781 16.5889C0.197873 15.1248 -0.133162 13.5502 -0.147008 11.9568C-0.160854 10.3635 0.142767 8.78331 0.746137 7.30855C1.34951 5.83379 2.24055 4.49397 3.36726 3.36726C4.49397 2.24055 5.83379 1.34951 7.30855 0.746137C8.78331 0.142767 10.3635 -0.160854 11.9568 -0.147008C13.5502 -0.133162 15.1248 0.197873 16.5889 0.826781C18.0529 1.45569 19.377 2.36988 20.484 3.516C22.6699 5.77922 23.8794 8.81045 23.8521 11.9568C23.8247 15.1032 22.5627 18.1129 20.3378 20.3378C18.1129 22.5627 15.1032 23.8247 11.9568 23.8521C8.81045 23.8794 5.77922 22.6699 3.516 20.484ZM5.208 18.792C7.00935 20.5933 9.4525 21.6053 12 21.6053C14.5475 21.6053 16.9906 20.5933 18.792 18.792C20.5933 16.9906 21.6053 14.5475 21.6053 12C21.6053 9.4525 20.5933 7.00935 18.792 5.208C16.9906 3.40665 14.5475 2.39466 12 2.39466C9.4525 2.39466 7.00935 3.40665 5.208 5.208C3.40665 7.00935 2.39466 9.4525 2.39466 12C2.39466 14.5475 3.40665 16.9906 5.208 18.792ZM17.088 8.604L13.692 12L17.088 15.396L15.396 17.088L12 13.692L8.604 17.088L6.912 15.396L10.308 12L6.912 8.604L8.604 6.912L12 10.308L15.396 6.912L17.088 8.604Z" fill="#90949E" />
              </g>
              <defs>
                <clipPath id="clip0_2848_6278">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}
        </div>
        <div className={styles.reportPostModalbody}>
          <h1 className={styles.descriptionText}>Why do you want to repost this post?</h1>
          <p className={styles.descriptionText}>
            If you believe this post violates community guidelines or poses a risk to
            users, please select the reason that best describes your concern
          </p>
          <div className={styles.reportOptions}>
            {reportReasons?.map((reason) => (
              <div
                key={reason.title}
                className={styles.reportOption}
                onClick={() => handleReasonClick(reason.reason, reason.id)}
              >
                <h1>{reason.reason}</h1>
                <p>{reason.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isMobileView ? (
        <PostheaderMobile ref={postheaderMobileRef} menu={reportContent} Zindexmodal="999999999" />
      ) : (
        reportContent
      )}
    </>
  );
};

export default ReportPostModal;
