import React from 'react'
import NormalPost from 'src/components/Feed/NormalPost'
import PostSomething from 'src/components/Feed/PostSomething'
import { useAuth } from 'src/utils/auth'
const NoDataIcon = '/assets/icon/no-data-icon.svg'
export default function UserFeed(props) {
  const { allPost, userData } = props
  const { user } = useAuth()

  return (
    <React.Fragment>
      <div className="social-product-details-alignment">
        {user?.id === userData?.id && (
          <PostSomething title={'Post Something'} mobile={false} type="post" group={{}} isSeller={false} />
        )}
        {allPost?.length > 0 ? (
          allPost?.map((post: any, i) => {
            return (
              <React.Fragment key={i}>
                <NormalPost
                  index={i}
                  post={post}
                  store={{}}
                  image={true}
                  type="post"
                  color={false}
                  feedSize={true}
                  isUserPostPreviewModal={false}
                />
              </React.Fragment>
            )
          })
        ) : (
          <div className="no-data-social-produtc-box-alignment ">
            <img src={NoDataIcon} alt="NoDataIcon" />
            <p>This account doesn’t have any post yet.</p>
          </div>
        )}{' '}
      </div>
      {/* {allPost?.length === 0 && (
        <div className="mobile-view-feed-no-data-modal-alignment">
          <div>
            <img src={NoFollowingImg} alt="NoFollowingImg" />
          </div>
          <div className="feed-no-data-name-alignment">
            <span>
              This account has not <br /> yet posted any content
            </span>
          </div>
        </div>
      )} */}
    </React.Fragment>
  )
}
