import React, { useEffect, useState } from 'react'
import styles from './invitefriendsemailmodelmobile.module.scss'
import { useAppDispatch } from 'src/store/store'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import SEND_INVITATION from '../../graphql/mutations/sendInvitation'

export default function Invitefriendsemailmodelmobile({ transformedContacts }) {
  const dispatch = useAppDispatch()
  const [searchQuery, setSearchQuery] = useState('')
  const [isChecked, setIsChecked] = useState([])
  const [selectedEmails, setSelectedEmails] = useState([])
  const fetchMobileModal = useSelector((state: any) => state?.userMobileModal?.data)

  useEffect(() => {
    if (transformedContacts.length > 0) {
      setIsChecked(Array(transformedContacts.length).fill(false))
    }
  }, [transformedContacts])

  const handleCheckboxToggle = (index) => {
    const updatedChecked = [...isChecked]
    updatedChecked[index] = !updatedChecked[index]
    setIsChecked(updatedChecked)

    const email = transformedContacts[index]?.emailAddresses?.[0]?.value
    if (email) {
      setSelectedEmails((prev) => {
        if (updatedChecked[index]) {
          return prev.includes(email) ? prev : [...prev, email] // Add only if not already included
        } else {
          return prev.filter((e) => e !== email) // Remove if unchecked
        }
      })
    }
  }

  const handleSelectAllToggle = () => {
    const allChecked = isChecked.every((checked) => checked)
    const newCheckedState = Array(transformedContacts.length).fill(!allChecked)
    setIsChecked(newCheckedState)

    if (!allChecked) {
      const allEmails = transformedContacts
        .filter((item) => item?.emailAddresses?.[0]?.value)
        .map((item) => item.emailAddresses[0].value)
      setSelectedEmails(allEmails)
    } else {
      setSelectedEmails([]) // Clear selection on "Uncheck All"
    }
  }

  const filteredContacts = transformedContacts.filter((item) => {
    const email = item?.emailAddresses?.[0]?.value || ''
    const phone = item?.phoneNumbers?.[0]?.canonicalForm || ''
    return email.toLowerCase().includes(searchQuery) || phone.includes(searchQuery)
  })
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase())
  }

  const handleCloseModel = () => {
    dispatch(
      fetchMobileModalData({
        allModalData: [
          ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
          {
            modal: 'invitefriendsemailmodelmobile',
            isOpen: false,
            id: 0,
            transform: 230,
          },
        ],
        lastModalData: fetchMobileModal?.lastModalData ? fetchMobileModal?.lastModalData : [],
      }),
    )
  }

  const [sendInvitation] = useMutation(SEND_INVITATION, {
    onCompleted: ({ sendInvitation }) => {
      handleCloseModel()
    },
    onError: (error) => {
    },
  })

  const handleSendInvitation = () => {
    sendInvitation({
      variables: {
        emails: selectedEmails,
      },
    })
  }
  return (
    <>
      {/* <div className={styles.Invitefriendemailmodelback} onClick={onClose}></div> */}
      <div className={styles.Invitefriendemailmodelmain}>
        {/* <div className={styles.Invitefriendmodeltop}></div> */}
        <div className={styles.Invitefrientitle}>
          <p>Invite Friends to Bluejestic</p>
          <span onClick={() => handleCloseModel()}>Cancel</span>
        </div>
        <div className={styles.Sharepera}>
          <p>Invite friends using your Gmail address book</p>
        </div>
        <div className={styles.searchdivmain}>
          <input
            type="text"
            placeholder="Search"
            value={searchQuery} // Bind input to searchQuery state
            onChange={handleSearchChange}
          />
          <div className={styles.searchicon}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                stroke="#ACB1C0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.0001 20.9984L16.6501 16.6484"
                stroke="#ACB1C0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className={styles.friendsselcteddivmain}>
          <p>
            {isChecked.filter((checked) => checked).length} friend{isChecked.length !== 1 ? 's' : ''} selected
          </p>
          <span onClick={handleSelectAllToggle}>
            {isChecked.every((checked) => checked) ? 'Unselect All' : 'Select All'}
          </span>
        </div>
        <div className={styles.friendsemaildivmain}>
          {filteredContacts?.map((item, index) => {
            const email = item?.emailAddresses?.[0]?.value
            return (
              <div>
                {item?.emailAddresses?.length > 0 && (
                  <div
                    className={`${styles.friendsemaildiv} ${isChecked[index] ? styles.active : ''}`}
                    onClick={() => handleCheckboxToggle(index)}
                  >
                    <p>{email}</p>
                    <div className={`${styles.customradiobox} ${isChecked[index] ? styles.checked : ''}`}>
                      <div className={styles.customradioboxcircle}></div>
                    </div>
                  </div>
                )}
              </div>
            )
          })}
        </div>
        <div className={styles.sendinvitationbutton}>
          <button type="button" disabled={!isChecked.some((checked) => checked)} onClick={() => handleSendInvitation()}>
            Send Invitations
          </button>
        </div>
      </div>
    </>
  )
}