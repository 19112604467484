import { gql } from '@apollo/client'

const query = gql`
  mutation UpdateCart($input: CartUpdateInput!) {
    updateCart(input: $input) {
      success
      message
    }
  }
`
export default query
