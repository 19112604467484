// export const WOMEN_CATEGORY = 'Women'

export const WOMEN_CATEGORY_IMAGE = [
  'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201180-1674541755051-922297094.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178-1674541838967-714383969.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201181-1674541864749-565730144.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%281%29-1674541888786-357781350.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%282%29-1674541916234-818383110.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%283%29-1674541979909-920836375.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%284%29-1674542013530-322155643.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%285%29-1674542097489-631164801.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%286%29-1674542121058-25977368.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%287%29-1674542145469-912773288.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%288%29-1674542170745-777964015.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201181%20%281%29-1674542197803-71148781.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201182-1674542227483-601427949.png',
  'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201183-1674542250982-797235046.png',
]

export const WOMEN_SECTION_TEXT = {
  category: [
    {
      name: 'Activewear',
      description: 'Empower your workout with our womens activewear collection, designed for style and performance.',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201180-1674541755051-922297094.png',
    },
    {
      name: 'Bottoms',
      description: 'Look and feel great in our collection of womens bottoms, designed for all-day comfort and style',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178-1674541838967-714383969.png',
    },
    {
      name: 'Coats',
      description: 'Stay warm in style with our collection of womens coats',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201181-1674541864749-565730144.png',
    },
    {
      name: 'Jacket',
      description: 'Stay protected and stylish with our collection of womens jackets',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%281%29-1674541888786-357781350.png',
    },
    {
      name: 'Pants',
      description: 'Elevate your style with our collection of womens pants, designed for comfort and fit.',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%282%29-1674541916234-818383110.png',
    },
    {
      name: 'Dresses',
      description: 'Elevate your wardrobe with our collection of womens dresses, designed for any occasion',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%283%29-1674541979909-920836375.png',
    },
    {
      name: 'Jeans',
      description: 'Look and feel great in our collection of womens jeans, designed for comfort and style',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%284%29-1674542013530-322155643.png',
    },
    {
      name: 'Leggings',
      description: 'Stay comfortable and fashionable with our collection of womens leggings.',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%285%29-1674542097489-631164801.png',
    },
    {
      name: 'Shoes',
      description: 'Elevate your style with our collection of womens shoes, designed for comfort and fashion',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%286%29-1674542121058-25977368.png',
    },
    {
      name: 'Sleepwear',
      description: 'Relax in style with our collection of womens sleepwear',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%287%29-1674542145469-912773288.png',
    },
    {
      name: 'Sneakers',
      description:
        'Discover the ultimate blend of fashion and function in our womens sneakers. Elevate your look and stay comfortable on the go',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%288%29-1674542170745-777964015.png',
    },
    {
      name: 'Socks',
      description: 'Comfort meets style with our womens socks. Soft and stylish, a perfect addition to any outfit',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201181%20%281%29-1674542197803-71148781.png',
    },
    {
      name: 'Tops',
      description: 'Stay on top of fashion with our womens tops. Versatile styles for any occasion, comfort included.',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201182-1674542227483-601427949.png',
    },
    {
      name: 'Wedding',
      description:
        'Dream wedding dress found here. From classic to contemporary, find the perfect gown for your special day',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201183-1674542250982-797235046.png',
    },
  ],
  now_trending: {
    text: 'Lead the fashion pack with our collection of trendy and on-point designs, always up-to-date with the latest styles',
    image: '',
  },
  find_your_style: {
    text: 'Explore the latest fashion trends and find your unique style with our easy-to-use platform',
    image: '',
  },
  fashion_group: {
    text: 'Stay ahead of the curve with the latest styles and trends. Elevate your wardrobe and elevate your style with our fashion club',
    image: '',
  },
  trending_store: {
    text: 'Find the latest styles and hottest products at our Trending Store. Be on-trend and on-point with every purchase',
    image: '',
  },
  men_fashion_enthusiasts: {
    text: 'Find out women fashion inspiration from creative people around the globe',
    image: '',
  },
}

export const WOMEN_FIND_YOUR_STYLE = [
  {
    name: 'Artsy',
    image:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyle/Rectangle%2015774-1674544258915-491454055.png',
    color: '#d5e3fc',
    bannerImage:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyleBanner//image%20168%20%281%29-1675838916172-692074626.png',
    opacity: 0.6,
  },
  {
    name: 'Chic',
    image:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyle/Rectangle%2015774-1674544143636-905558893.png',
    bannerImage:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyleBanner//image%20168%20%282%29-1675838981617-716401181.png',
    color: '#fbecf1',
    opacity: 0.6,
  },
  {
    name: 'Formal',
    image:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyle/Rectangle%2015774-1674544171068-107967558.png',
    bannerImage:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyleBanner//image%20168%20%283%29-1675839007089-986222373.png',
    color: '#c89f9f',
    opacity: 0.6,
  },
  {
    name: 'Casual',
    image:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyle/Rectangle%2015774-1674544062902-46953332.png',
    bannerImage:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyleBanner//image%20168%20%284%29-1675839022632-290314794.png',
    color: '#e8ead3',
    opacity: 0.6,
  },
  {
    name: 'Sexy',
    image:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyle/Rectangle%2015774-1674544018329-845085806.png',
    bannerImage:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyleBanner//image%20168%20%287%29-1675839070174-200921115.png',
    color: '#aaacb1',
    opacity: 0.6,
  },
  {
    name: 'Elegant',
    image:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyle/Rectangle%2015774-1674544102538-472889623.png',
    bannerImage:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyleBanner//image%20168%20%285%29-1675839033832-590225831.png',
    color: '#fbf2db',
    opacity: 0.6,
  },
  {
    name: 'Street',
    image:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyle/Rectangle%2015774-1674544200457-607122469.png',
    bannerImage:
      'https://bluejestic-media.s3.amazonaws.com/fashion/women/findYourStyleBanner//image%20168%20%286%29-1675839046742-863983274.png',
    color: '#d29ffa',
    opacity: 0.6,
  },
]

export const JEWELRY_CATEGORY = [
  {
    name: 'Necklace',
    description: 'Men’s necklaces: a sleek blend of style and confidence, adding a bold yet effortless edge to any look.',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/jewelry/Group 1000006845-1701760278997-700327548.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/jewelry/beautiful-luxury-necklace-jewelry-stand-neck-1701408781945-154306476.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Earrings',
    description: 'Men’s earrings make a bold statement of confidence and style, adding a refined touch that redefines modern masculinity.',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/jewelry/Group 1000006846-1701760377184-279954638.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/jewelry/silver-aesthetic-wallpaper-with-earrings 1-1701409366220-481500210.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Bracelets',
    description: 'Men’s bracelets make a bold statement—sleek, stylish, and versatile, they add effortless sophistication to any look.',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/jewelry/Group 1000006847-1701760440826-730398438.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/jewelry/Rectangle 15863-1701409129382-120485229.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Rings',
    description: 'Men’s rings: bold, timeless, and crafted for confidence, making every gesture a statement of style.',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/jewelry/Group 1000006848-1701760547154-843640076.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/jewelry/Rectangle 15863 (1)-1701409143347-108940358.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Anklets',
    description: 'Uncover Our Mesmerizing Anklets Collection and Embrace Subtle Elegance on Your Ankles.',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/jewelry/Group 1000006849-1701760592673-424482635.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/jewelry/Rectangle 15863 (2)-1701409230649-165187619.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Brooches',
    description: 'Explore Our Stunning Brooches Collection and Adorn Your Attire with Artistic Flair.',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/jewelry/Group 1000006850-1701760632856-105574929.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/jewelry/Rectangle 15863 (3)-1701409247297-306489789.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Sets',
    description: 'Explore Our Stunning Brooches Collection and Adorn Your Attire with Artistic Flair.',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/jewelry/Group 1000006851-1701760193254-64117206.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/jewelry/Rectangle 15863 (4)-1701409264508-657728307.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Men’s Jewelry',
    description: "Explore Our Distinctive Men's Jewelry Collection and Define Your Style with Masculine Elegance.",
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/jewelry/Group 1000006852-1701760678131-365600235.png0',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/jewelry/Rectangle 15863 (5)-1701409282189-322408962.png',
    __typename: 'SubCategory',
  },
]
