import { gql } from '@apollo/client'
const REACT_TO_LIVE_STREAM = gql`
  mutation reactToLiveStream($input: reactToLiveStreamInput) {
    reactToLiveStream(input: $input) {
      message
      success
    }
  }
`

export default REACT_TO_LIVE_STREAM
