// @ts-nocheck


import React, { useState } from 'react'
import Slider from 'react-slick'
import BlueShoppingIcon from '../../../../../public/assets/icon/blue-shopping-bag.svg'
import CREATE_CART_MUTATION from 'src/graphql/mutations/createCart'
import REMOVE_BOOKMARK from 'src/graphql/mutations/removeBookmark'
// import MY_FEED_QUERY from 'src/graphql/queries/getMyFeed'
import { useMutation } from '@apollo/client'
import { Modal, notification } from 'antd'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import useDeviceDetect from 'src/utils/useDeviceDetect'
import moment from 'moment'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAuth } from 'src/utils/auth'
import ADD_BOOKMARK from 'src/graphql/mutations/addBookmark'
import LazyImage from 'src/utils/LazyImage'
import { fetchAllFeedSliceData } from 'src/store/slices/fetchAllFeedSlice'
import { getAllCartCountSliceData } from 'src/store/slices/getCartCount'
import Link from 'next/link'
import AddProductCollectionModal from 'src/components/AddProductTomodel'
import { manageBookmark } from 'src/store/slices/manageBookmarkTab'

const RightArrow = '/assets/icon/share-right-arrow.svg'
const LeftArrow = '/assets/icon/share-left-arrow.svg'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <img src={RightArrow} alt="RightArrow" />
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <img src={LeftArrow} alt="LeftArrow" />
    </div>
  )
}

export default function PostShareProduct(props) {
  const { post } = props
  const { user }: any = useAuth()
  const [isCalling, setIsCalling] = useState(false)
  const [sharedProductPreview, setSharedProductPreview] = useState(false)
  const [previewURL, setPreviewURL] = useState('')
  const dispatch = useAppDispatch()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const bookmarkTabData = useSelector((state: any) => state?.manageBookmarkTabsSlice?.data)

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2.04,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.04,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleDivClick = (post) => {
    // bookmarkProduct(post)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const [createCart] = useMutation(CREATE_CART_MUTATION, {
    onCompleted: (res) => {
      dispatch(getAllCartCountSliceData())
    },
    onError: (error) => {
      notification['error']({
        message: 'Error',
        description: 'Sorry, you can only purchase allowable quantity of this product',
      })
    },
  })

  const QuantityHandler = () => {
    if (user) {
      createCart({
        variables: { input: { parent_id: post?.product?.id, quantity: 1, cart_for: 'PRODUCT' } },
      })
    } else {
      handleRedirect()
    }
  }

  const [addBookmark] = useMutation(ADD_BOOKMARK, {
    onCompleted: (res) => {
      dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const [removeBookmark] = useMutation(REMOVE_BOOKMARK, {
    onCompleted: (res) => {
      dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const bookmarkProduct = (product) => {
    if (user) {
      if (!isCalling) {
        if (product?.isBookmarked) {
          setIsCalling(true)
          removeBookmark({
            variables: {
              id: product?.bookmark_id,
            },
            // update: (proxy, { data }) => {
            //   const existingData: any = proxy.readQuery({
            //     query: MY_FEED_QUERY,
            //   })
            //   const newData = {
            //     getAllPost: {
            //       ...existingData.getAllPost,
            //       data: existingData.getAllPost.data.map((post) => {
            //         if (post.id === product.id) {
            //           return { ...post, isBookmarked: !product.isBookmarked }
            //         } else {
            //           return post
            //         }
            //       }),
            //     },
            //   }
            //   proxy.writeQuery({
            //     query: MY_FEED_QUERY,
            //     data: newData,
            //   })
            // },
          }).then((response) => {
            if (response?.data !== undefined) {
              setIsCalling(false)
            }
          })
          return
        }
        setIsCalling(true)
        addBookmark({
          variables: {
            productId: product?.product?.id,
            // collectionId: 5,
          },
          // update: (proxy, { data }) => {
          //   let getMsgVariables = {
          //     category: '',
          //     subCategory: '',
          //     where: {
          //       isVisible: true,
          //     },
          //   }
          //   const existingData: any = proxy.readQuery({
          //     query: MY_FEED_QUERY,
          //     variables: getMsgVariables,
          //   })
          //   const newData = {
          //     getAllPost: {
          //       ...existingData.getAllPost,
          //       data: existingData.getAllPost.data.map((post) => {
          //         if (post.id === product.id) {
          //           return { ...post, isBookmarked: !product.isBookmarked, bookmark_id: data?.addBookmark?.id }
          //         } else {
          //           return post
          //         }
          //       }),
          //     },
          //   }
          //   proxy.writeQuery({
          //     query: MY_FEED_QUERY,
          //     data: newData,
          //   })
          // },
        }).then((response) => {
          if (response?.data !== undefined) {
            setIsCalling(false)
          }
        })
      }
    } else {
      handleRedirect()
    }
  }

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const handleCancel = () => {
    setSharedProductPreview(!sharedProductPreview)
  }

  const handelImageSlider = () => {}

  const addBookmarkFromFeed = (post) => {
    
    if (user) {
      if (post?.isBookmarked) {
        removeBookmark({
          variables: {
            id: post?.product?.bookmark?.id,
          },
        })
      } else {
        dispatch(
          manageBookmark({
            modal: '',
            isOpen: true,
            id: {
              ...bookmarkTabData?.id,
              // post: post,
              type: 'feed',
              bookmark_id: post?.product?.bookmark?.id,
              product_id: post?.product?.id,
              collection_id: post?.product?.bookmark?.collection?.id,
              addProductToCollection: {
                ...bookmarkTabData?.id?.deleteCollection,
                isShowAddCollectionModel: true,
                productId: post?.product?.id,
              },
            },
          }),
        )
      }
    } else {
      handleRedirect()
    }
  }

  return (
    <React.Fragment>
      <div className="share-post-design-section">
        {post?.product?.image?.length > 2 ? (
          // <div className="share-post-design-section-slider-alignment">
          <Slider {...settings}>
            {post?.product?.image?.map((url, index) => {
              return (
                <div
                  key={index}
                  className="share-product-img-alignment"
                  onClick={() => {
                    setSharedProductPreview(true)
                    setPreviewURL(url)
                  }}
                >
                  {url?.includes('mp4') ? (
                    <video muted autoPlay loop style={{ objectFit: 'cover' }}>
                      <source src={url} />
                    </video>
                  ) : (
                    // <LazyImage
                    //   image={{
                    //     src: url,
                    //     alt: 'Icon',
                    //   }}
                    //   objectFit={'cover'}
                    //   handleClickOnImage={() => handelImageSlider()}
                    //   className={'shared-product-media'}
                    // />
                    <div
                      className="shared-product-media"
                      style={{
                        backgroundImage: `url(${url})`,
                      }}
                    ></div>
                  )}
                </div>
              )
            })}
          </Slider>
        ) : (
          // <></>
          // </div>
          <div className="share-post-design-grid-section">
            {post?.product?.image?.map((url, index) => {
              return (
                <div
                  className="share-product-img-alignment"
                  key={index}
                  onClick={() => {
                    setSharedProductPreview(true)
                    setPreviewURL(url)
                  }}
                >
                  {url?.includes('mp4') || url?.includes('mov') ? (
                    <video muted autoPlay loop style={{ objectFit: 'cover' }}>
                      <source src={url} />
                    </video>
                  ) : (
                    // <img src={url} alt="DemoProductImg" />
                    <LazyImage
                      image={{
                        src: url,
                        alt: 'Icon',
                      }}
                      objectFit={'cover'}
                      handleClickOnImage={() => handelImageSlider()}
                    />
                  )}
                </div>
              )
            })}
          </div>
        )}
        <div className="share-post-details-alignment">
          <div>
            <Link href={`/product/${post?.product?.slug}`}>
              <div
                className="share-post-heading"
                // onClick={() => {
                //   dispatch(
                //     fetchModalData({
                //       allModalData: [
                //         ...getAllState?.allModalData,
                //         {
                //           modal: 'productDetail',
                //           isOpen: true,
                //           id: post?.product?.id,
                //           store_id: post?.store?.id,
                //           modalFor: 'bigModal',

                //           startDate: moment().toISOString(),
                //         },
                //       ],
                //       lastModalData: getAllState?.lastModalData,
                //     }),
                //   )
                // }}
              >
                <h5>
                  {useDeviceDetect()?.isMobile
                    ? post?.product?.title?.length > 30
                      ? post?.product?.title.substring(0, 30) + '...'
                      : post?.product?.title
                    : post?.product?.title?.length > 68
                    ? post?.product?.title.substring(0, 68) + '...'
                    : post?.product?.title}
                  {}
                </h5>
              </div>
            </Link>
            <div className="share-post-price-alignment">
              <h6>
                {post?.product?.dis_price && '$'}
                {post?.product?.dis_price}
              </h6>
              <span>
                <del>
                  {post?.product?.dis_listPrice && '$'}
                  {post?.product?.dis_listPrice}
                </del>
              </span>
            </div>
            {/* <div className="share-post-delivery-shipping">
              <div className="best-seller-alignment">
                <h6>Best seller</h6>
              </div>
              <div className="dot-alignment">
                <p></p>
              </div>
              <div className="delivery-menu-align">
                <img src={'/assets/img/feed/shared-icon/truck-icon.svg'} />
                <span>free</span>
              </div>
              <div className="dot-alignment">
                <p></p>
              </div>
              <div className="arrow-menu-align">
                <img src={'/assets/img/feed/shared-icon/side-icon-up-green.svg'} />
                <span>200%</span>
              </div>
            </div> */}
          </div>
          <div>
            <div className="share-post-other-option-alignment">
              <div className="share-post-box-alignment" onClick={() => addBookmarkFromFeed(post)}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill={post?.isBookmarked ? '#556EE6' : 'none'}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 21L12 16L5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21Z"
                    stroke="#556EE6"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="share-post-box-alignment" onClick={() => QuantityHandler()}>
                <img src={BlueShoppingIcon.src} alt="BlueShoppingIcon" />
                {/* <div>aaaaaa</div> */}
              </div>
            </div>
          </div>
          {isModalOpen && <AddProductCollectionModal onClose={handleCloseModal} productId={post?.product?.id} />}
        </div>
      </div>
      <Modal
        open={sharedProductPreview}
        className="my-auto rounded-2xl"
        wrapClassName="post-add-edit-modal post-view-modal"
        onCancel={handleCancel}
        footer={null}
        closable={false}
        maskStyle={{ zIndex: 999999999 }}
        centered
      >
        <div className="bg-white rounded-0 -m-8" style={{ minWidth: '100%', height: '100%' }}>
          {previewURL.includes('mp4') ? (
            <video controls className="w-full h-full rounded-2xl" autoPlay muted>
              <source src={previewURL} id="video_here" />
              Your browser does not support HTML5 video.
            </video>
          ) : (
            <img src={previewURL} className="w-full h-full rounded-2xl" />
          )}
        </div>
      </Modal>
    </React.Fragment>
  )
}
