import { useQuery } from '@apollo/client'
import moment from 'moment'
import Productsdropdownicons from 'public/assets/icon/productsdropdownicons'
import React, { useEffect, useRef, useState } from 'react'
import GET_ALL_BILLING_ADDRESS from 'src/graphql/queries/getAllBillingAddress'
import freetruckicon from '../../../public/assets/icon/freetruckimage.png'
import { DeleteIcon } from '../Seller/OrderShippingMain/shipping/ShippingMethodSvg'
export default function ItemsSection({
  handleRemove,
  productArray,
  deliveryData,
  setDeliveryOption,
  deliveryOption,
  getShipDetail,
  shipDetailData,
  loading,
  selectedService,
  setSelectedService,
  setIsAllResponseSucces,
}) {
  const { data } = useQuery(GET_ALL_BILLING_ADDRESS)

  const [isDropdownOpen, setIsDropdownOpen] = useState({})
  const dropdownRefs = useRef(new Map())

  const setDropdownRef = (key, ref) => {
    if (ref) {
      dropdownRefs.current.set(key, ref)
    } else {
      dropdownRefs.current.delete(key)
    }
  }

  const defaultSelectedAddress = data?.getAllBillingAddress?.some((address) => address?.isDefault === true)

  useEffect(() => {
    handleTotalAmount()
  }, [shipDetailData?.getShipDetail])

  const handleTotalAmount = (tempid?: number, tempIndex?: number, remainAmount?: number) => {
    let totalAmount = 0

    productArray?.getSelectedProducts?.carts?.forEach((cart) => {
      cart?.product.forEach((product) => {
        let shippingData = shipDetailData?.getShipDetail?.find((ship_id) => ship_id?.product_id === product?.id)
        const isAllSuccess = shipDetailData?.getShipDetail.every((num) => num?.success)
        setIsAllResponseSucces(isAllSuccess)
        const data = shippingData?.data
        const serviceCodeMap = new Map()

        data?.forEach((item) => {
          const serviceCode = item.service_code
          const amount = item.rate_details[0]?.amount?.amount
          if (
            !serviceCodeMap.has(serviceCode) ||
            amount < serviceCodeMap.get(serviceCode).rate_details[0]?.amount?.amount
          ) {
            serviceCodeMap.set(serviceCode, item)
          }
        })

        const ShippingDetailArray = Array.from(serviceCodeMap.values())

        if (product?.variant && !shippingData?.isShopifyProduct) {
          const filteredServices = ShippingDetailArray?.filter((service) =>
            product?.shipping_method?.service_code?.includes(service?.service_code),
          )

          let serviceProcessed = false // Flag to stop further iteration
          for (let index = 0; index < filteredServices.length; index++) {
            let service = filteredServices[index]

            if (tempid) {
              if (!serviceProcessed && selectedService[product?.id] && tempid == product?.id && tempIndex == index) {
                for (let rateDetail of service?.rate_details || []) {
                  totalAmount += parseFloat(rateDetail?.amount?.amount.toFixed(2))
                }
                serviceProcessed = true // Stop further processing
                break
              }
            } else {
              if (!serviceProcessed && index === 0) {
                setSelectedService((prev) => ({ ...prev, [product.id]: service }))

                for (let rateDetail of service?.rate_details || []) {
                  totalAmount += parseFloat(rateDetail?.amount?.amount.toFixed(2))
                }
                serviceProcessed = true
              }
            }
          }
        } else if (shippingData?.is_free_delivery) {
          if (shippingData?.ship_charge) {
            totalAmount += shippingData?.ship_charge
          }
        } else if (shippingData?.data === null && shippingData?.ship_charge) {
          totalAmount += shippingData?.ship_charge
        }
      })
    })

    setDeliveryOption({
      ...deliveryOption,
      shipping_amount: { amount: remainAmount ? remainAmount + totalAmount : totalAmount },
    })
  }

  useEffect(() => {
    if (deliveryData?.data?.length > 0) {
      setDeliveryOption(deliveryData?.data?.[0])
    }
  }, [deliveryData?.data])

  useEffect(() => {
    if (productArray?.getSelectedProducts?.carts) {
      let payload = []
      productArray.getSelectedProducts.carts.forEach((cart) => {
        cart.product.forEach((product) => {
          let tempObj = {
            productId: product.id,
            quantity: product.selected_quantity,
            variant_id: product.variant?.id,
          }
          payload.push(tempObj)
        })
      })
      // getShipDetail({
      //   variables: {
      //     input: {
      //       products: payload,
      //     },
      //   },
      // })
      getShipDetail({
        input: {
          products: payload,
        },
      })
    }
  }, [productArray])

  const handleServiceSelect = (productId, service, index) => {
    let temp = Object.keys(selectedService)
    let abc = temp.filter((tempID) => tempID != productId)

    let totalAmount = 0
    abc.forEach((id) => {
      if (selectedService[id] && selectedService[id]?.rate_details) {
        // Summing the amount in rate_details for the matched service
        selectedService[id].rate_details.forEach((detail) => {
          totalAmount += detail?.amount?.amount
        })
      }
    })

    // toggleDropdown(productId)

    setSelectedService((prev) => ({ ...prev, [productId]: service }))
    setIsDropdownOpen((prev) => ({ ...prev, [productId]: false }))
    handleTotalAmount(productId, index, totalAmount)
  }

  const toggleDropdown = (productId) => {
    setIsDropdownOpen((prev) => ({
      ...prev,
      [productId]: !prev[productId],
    }))
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Array.from(dropdownRefs.current.values()).every((ref) => ref && !ref.contains(event.target))
      if (isOutside) {
        setIsDropdownOpen({})
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <React.Fragment>
      <div className="items-container-main">
        <div className="items-contain-main">
          <div className="product-delivery-section">
            <div className="grid-product-section">
              {React.Children.toArray(
                productArray?.getSelectedProducts?.carts?.map((cart: any, mainIndex) => (
                  <>
                    <div className="store-logo-align" key={mainIndex}>
                      <img
                        src={
                          cart?.storeDetail?.logo_url
                            ? cart?.storeDetail?.logo_url
                            : '/assets/img/cart/profileImage.png'
                        }
                      />
                      <span>{cart?.storeDetail?.name}</span>
                    </div>
                    {cart?.product.map((product: any, i) => {
                      let shippingData = shipDetailData?.getShipDetail?.find(
                        (ship_id) => ship_id?.product_id === product?.id,
                      )
                      const isData = shippingData?.data
                      const serviceCodeMap = new Map()
                      isData?.forEach((item) => {
                        const serviceCode = item.service_code
                        const amount = item.rate_details[0]?.amount?.amount
                        if (
                          !serviceCodeMap.has(serviceCode) ||
                          amount < serviceCodeMap.get(serviceCode).rate_details[0]?.amount?.amount
                        ) {
                          serviceCodeMap.set(serviceCode, item)
                        }
                      })
                      const ShippingDetailArray = Array.from(serviceCodeMap.values())
                      return (
                        <div className="items-section-card" key={`q${i}`}>
                          <div className="flex-product-class">
                            <div className="product-image-contain">
                              <img
                                src={
                                  product?.variant?.image?.media
                                    ? product?.variant?.image?.media
                                    : product?.images && product?.images[0]?.src
                                }
                              />
                            </div>
                            <div className="product-detail-grid">
                              <div className="product-title-align">
                                <h2>
                                  {product?.title?.length > 35
                                    ? product?.title.substring(0, 35) + '...'
                                    : product?.title}
                                </h2>
                              </div>
                              <div className="discription-contain">
                                {product?.variant && (
                                  <>
                                    <div className="product-size-color">
                                      {product?.variant?.total_variant?.map((variant) => {
                                        return (
                                          <div className="color-text-align">
                                            <span className="color-count">
                                              {variant?.variant_option?.data?.name}:{'  '}{' '}
                                              <span> {variant?.variant_option?.value}</span>
                                            </span>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </>
                                )}
                                <div>
                                  {loading ? (
                                    <div>loading</div>
                                  ) : !shippingData?.success ? (
                                    <>
                                      <div className="ml-10 products-size-dropdown-alignment">
                                        <div className="products-drop-down-item products-drop-down-item-free">
                                          <span>
                                            You can not purchase this item.
                                            <br />
                                            please remove this item to create an order
                                          </span>
                                            <div 
                                            onClick={() => handleRemove(product?.id)} 
                                            style={{ cursor: 'pointer' }}
                                            onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                                            onMouseLeave={(e) => e.currentTarget.style.opacity = '0.7'}
                                            >
                                            <DeleteIcon />
                                            </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : !shippingData?.isShopifyProduct ? (
                                    <div
                                      ref={(ref) => setDropdownRef(product?.id, ref)}
                                      className={`products-size-dropdown ${
                                        isDropdownOpen[product?.id] ? 'active' : ''
                                      }`}
                                      onClick={() => {
                                        toggleDropdown(product?.id)
                                      }}
                                    >
                                      <span>
                                        {selectedService[product?.id]?.service_type || 'Select Delivery Dispatch'}{' '}
                                        <Productsdropdownicons />
                                      </span>
                                      {isDropdownOpen[product?.id] && shippingData?.data && (
                                        <div className="products-size-dropdown-alignment">
                                          {ShippingDetailArray?.filter((service) =>
                                            product?.shipping_method?.service_code?.includes(service?.service_code),
                                          )?.map((service, index) => (
                                            <div
                                              key={index}
                                              className={`products-drop-down-item ${
                                                selectedService[product?.id]?.service_code === service?.service_code
                                                  ? 'active'
                                                  : ''
                                              } `}
                                              onClick={(event) => {
                                                event.stopPropagation()
                                                handleServiceSelect(product?.id, service, index)
                                              }}
                                            >
                                              <span>
                                                {service.service_type} - ${service.rate_details[0]?.amount?.amount}
                                              </span>
                                              <small>
                                                {service.carrier_friendly_name} - {service.delivery_days} days
                                                <br />
                                                Est. Delivery:{' '}
                                                {moment(service.estimated_delivery_date).format('MMM DD, YYYY')}
                                              </small>
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <>
                                      {(shippingData.ship_charge == 0 && !shippingData?.is_free_delivery) || shippingData?.is_free_delivery ? (
                                        <div className="products-size-dropdown-alignment">
                                          <div className="products-drop-down-item products-drop-down-item-free">
                                            <span>Free shipping</span>
                                          </div>
                                        </div>
                                      ) : !shippingData?.data && shippingData?.ship_charge ? (
                                        <div className="products-size-dropdown-alignment">
                                          <div className="products-drop-down-item products-drop-down-item-charge">
                                            <span>Ship Charge:</span>
                                            <small>${shippingData?.ship_charge}</small>
                                          </div>
                                        </div>
                                      ) : null}
                                    </>
                                  )}
                                </div>
                                <div className="quantity-section">
                                  <span className="quantity-count">
                                    Quantity: <span>{product?.selected_quantity}</span>
                                  </span>
                                </div>

                                <div className="product-price-align">
                                  <span>
                                    ${' '}
                                    {product?.variant
                                      ? Number(product?.variant?.price)
                                      : Number(product?.inventoryPrice?.price)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </>
                )),
              )}
            </div>
            {data?.getAllBillingAddress?.length < 1 ? (
              <div className="plaese-pera-main">
                <span>Please add your shipping address</span>
              </div>
            ) : defaultSelectedAddress ? (
              <></>
            ) : (
              <div className="plaese-pera-main">
                <span>Please select your shipping address</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
