import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import GET_CART_COUNT from 'src/graphql/queries/getCartCount'
import client1 from 'src/utils/apolloClient'
import GET_CART_ITEMS from 'src/graphql/queries/getCartItems'
import GET_USER_ACTIVITY_REPORT from 'src/graphql/queries/getUserActivityReport'
import moment from 'moment'

interface accountSummarySlice {
  loading: true | false
  error: {} | null
  called: true | false
  activityReport: {}
}

const initialState = {
  loading: false,
  error: null,
  called: false,
  activityReport: {
    current_post_count: 0,
    current_post_count_diff: 0,
    current_like_count: 0,
    current_like_count_diff_pct: 0,
    current_comment_count: 0,
    current_comment_count_diff_pct: 0,
  },
} as accountSummarySlice

export const fetchAccountSummaryReport = createAsyncThunk(
  'accountSummary/fetchAccountSummaryReport',
  async (payload: any) => {
    let { startDate, endDate, timeInterval } = payload
    const { data } = await client1.query({
      query: GET_USER_ACTIVITY_REPORT,
      variables: {
        startDate: startDate,
        endDate: endDate,
        timeInterval: timeInterval,
      },
      fetchPolicy: 'network-only',
    })
    return data?.getUserActivityReport?.data
  },
)

const accountSummarySlice = createSlice({
  name: 'accountSummary',
  initialState,
  reducers: {
    // setCartData: (state: any, action) => {
    //   state.cartData = action.payload
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAccountSummaryReport.pending, (state: any, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchAccountSummaryReport.fulfilled, (state: any, action) => {
      state.loading = 'success'
      state.activityReport = action?.payload
    })
    builder.addCase(fetchAccountSummaryReport.rejected, (state: any, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })
  },
})

// export const { setCartData } = accountSummarySlice.actions
export default accountSummarySlice.reducer
