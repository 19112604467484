import { gql } from '@apollo/client'

const GET_CATEGORIES_QUERY = gql`
  query {
    getAllCategoryDetail {
      id
      name
      description
      media
      position
      subCategory {
        id
        name
        media
        position
        slug
        childSubCategory {
          id
          slug
          name
          description
          banner_media
          media
          position
          nestedChildSubCategory {
            id
            description
            name
            media
            banner_media
            position
          }
        }
      }
    }
  }
`
export default GET_CATEGORIES_QUERY
