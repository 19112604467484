import React, { useEffect, useState } from 'react'
import styles from './KYCVerifiedEnterCode.module.scss'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import WITHDRAW_PAYMENT_FROM_SELLER from 'src/graphql/queries/sellerQueries/withDrawPaymentFromSeller'
import { useMutation } from '@apollo/client'
import sellerClient from 'src/utils/apolloSellerClient'
import SEND_OTP_FOR_WITHDRAWAL from 'src/graphql/queries/sellerQueries/sendOTPForWithdrawal'
import { fetchSellerWithdrawModalData } from 'src/store/slices/manageSellerWithdrawSlice'
export default function KYCVerifiedEnterCode() {
  const dispatch = useAppDispatch()
  const sellerWithdrawData = useSelector((state: any) => state?.sellerWithdrawData?.data)
  const [minutes, setMinutes] = useState(1)
  const [seconds, setSeconds] = useState(0)
  const [verificationCode, setVerificationCode] = useState(null)

  const [withdrawPayment] = useMutation(WITHDRAW_PAYMENT_FROM_SELLER, {
    onCompleted: () => {
      dispatch(
        fetchModalData({
          allModalData: [
            ...sellerWithdrawData?.allModalData,
            { modal: 'sellerWithdrawRequestProcessed', isOpen: true, id: 0, modalFor: 'sellerWithdrawSize' },
          ],
          lastModalData: sellerWithdrawData?.lastModalData,
          withdrawFund: { ...sellerWithdrawData?.withdrawFund },
        }),
      )
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const [sendOTPForWithdrawal] = useMutation(SEND_OTP_FOR_WITHDRAWAL, {
    onCompleted: () => {},
    onError: (error) => {
      console.log(error)
    },
  })

  const HandleNext = () => {
    if (minutes === 0 && seconds === 0) {
      sendOTPForWithdrawal({ client: sellerClient })
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (minutes === 0 && seconds === 0) {
        clearInterval(timer)
      } else {
        if (seconds === 0) {
          setMinutes(minutes - 1)
          setSeconds(59)
        } else {
          setSeconds(seconds - 1)
        }
      }
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [minutes, seconds])

  const handleSubmit = () => {
    withdrawPayment({
      client: sellerClient,
      variables: {
        amount: Number(sellerWithdrawData?.withdrawFund?.selectedAmout),
        accountId: sellerWithdrawData?.withdrawFund?.accountId,
        otp: verificationCode,
      },
    })
  }

  return (
    <div className={styles.kYCVerifiedCodeSection}>
      <div className={styles.kYCVerifiedCodeHeading}>
        <h4>Enter Code</h4>
        <p>We have sent you OTP to your registered email address for verification</p>
      </div>
      <div className={classNames(styles.kYCVerifiedCodeMainAlignment, styles.successPassword)}>
        <div className={styles.incorrecrPassWordNotes}>
          <p>Incorrect code! Please try again.</p>
        </div>
        <div className={styles.kYCVerifiedInputFlexAlignment}>
          <div className={styles.kYCVerifiedInputAlignment}>
            <input type="text" onChange={(e) => setVerificationCode(e.target.value)} />
          </div>
          {/* <div className={styles.kYCVerifiedInputAlignment}>
            <input type="text" />
          </div>
          <div className={styles.kYCVerifiedInputAlignment}>
            <input type="text" />
          </div>
          <div className={styles.kYCVerifiedInputAlignment}>
            <input type="text" />
          </div> */}
        </div>
      </div>
      <div className={styles.kYCVerifiedOptionAlignment}>
        <a onClick={() => HandleNext()} style={minutes === 0 && seconds === 0 ? { opacity: 1 } : { opacity: 0.5 }}>
          Resend Code({`${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`})
        </a>
        <a>Back</a>
        <p onClick={handleSubmit}>Submit</p>
      </div>
    </div>
  )
}
