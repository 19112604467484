import { useRouter } from 'next/router'
import { useAuth } from 'src/utils/auth'
import Loader from 'src/components/UI/Loader'

const ProtectedRoute = (ProtectedComponent) => {
  return (props) => {
    // const { user, loading }: any = useAuth()
    // const router = useRouter()
    // if (loading) {
    //   return <Loader />
    // } else if (!user) {
    //   router.push('/')
    // } else if (!user?.isUserOnboardCompleted) {
    //   router.push('/onboarding')
    // } else {
    return <ProtectedComponent {...props} />
  }
  // }
}

export default ProtectedRoute
