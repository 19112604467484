import React from 'react';
import { X } from 'lucide-react';

interface TaxRule {
  country: string;
  state: string;
  rate_percentage: number;
}

interface TaxRulesTableProps {
  rules: TaxRule[];
  onDelete: (index: number) => void;
}

export default function TaxRulesTable({ rules = [], onDelete }: TaxRulesTableProps) {
  if (!rules) return null;
  return (
    <div className="overflow-hidden rounded-lg border border-gray-200">
      <table className="w-full">
        <thead>
          <tr className="bg-gray-50">
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Country
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              State/Region
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Rate (%)
            </th>
            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {rules.map((rule, index) => (
            <tr key={index} className="hover:bg-gray-50">
              <td className="px-6 py-4 text-sm text-gray-900">{rule.country}</td>
              <td className="px-6 py-4 text-sm text-gray-900">{rule.state}</td>
              <td className="px-6 py-4 text-sm text-gray-900">{rule.rate_percentage}%</td>
              <td className="px-6 py-4 text-right">
                <button
                  onClick={() => onDelete(index)}
                  className="text-gray-400 hover:text-red-600 transition-colors"
                >
                  <X size={16} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}