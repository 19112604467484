import React, { useEffect, useState } from 'react'
import styles from './AddProductTags.module.scss'
import classNames from 'classnames'
import CircleCloseIcon from '../../../../Icons/close-circle.svg'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'

export default function AddProductTags({ setProductData, productData, setTags, tags }) {
  const colorArray = [
    '#FFFAEA',
    '#F0E9F6',
    '#FFF2E9',
    '#FFFAEA',
    '#F0E9F6',
    '#FFF2E9',
    '#FFFAEA',
    '#F0E9F6',
    '#FFF2E9',
    '#FFFAEA',
    '#F0E9F6',
    '#FFF2E9',
    '#FFFAEA',
    '#F0E9F6',
    '#FFF2E9',
    '#FFFAEA',
    '#F0E9F6',
    '#FFF2E9',
  ]

  const FontcolorArray = [
    '#B5941F',
    '#6B21A8',
    '#E87220',
    '#B5941F',
    '#6B21A8',
    '#E87220',
    '#B5941F',
    '#6B21A8',
    '#E87220',
    '#B5941F',
    '#6B21A8',
    '#E87220',
    '#B5941F',
    '#6B21A8',
    '#E87220',
    '#B5941F',
    '#6B21A8',
    '#E87220',
  ]

  const [value, setValue] = useState('')
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const dispatch = useAppDispatch()

  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)])
  }

  const addTags = async (event, type) => {
    if (type === 'enter') {
      let textValue = event.target.value
      if (textValue.trim() !== '') {
        setTags([...(tags ?? []), textValue])
        setProductData({ ...productData, tags: [...(tags ?? []), textValue] })

        await dispatch(
          manageAddProductDetail({
            modal: '',
            isOpen: false,
            id: {
              ...addProductDetailSlice?.id,
              mobileProductsData: {
                ...addProductDetailSlice?.id?.mobileProductsData,
                tags: [...(tags ?? []), textValue],
              },
            },
          }),
        )
        setValue('')
      }
    } else {
      if (event.trim() !== '') {
        setTags([...tags, event])
        setProductData({ ...productData, tags: [...tags, event] })

        await dispatch(
          manageAddProductDetail({
            modal: '',
            isOpen: false,
            id: {
              ...addProductDetailSlice?.id,
              mobileProductsData: {
                ...addProductDetailSlice?.id?.mobileProductsData,
                tags: [...tags, event],
              },
            },
          }),
        )
        setValue('')
      }
    }
  }

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handleAdd = () => {
    addTags(value, 'click')
  }

  return (
    <div className={styles.addProductTagsSection}>
      <div className={styles.addProductTagsBox}>
        <div className={styles.addProductTagsBoxHeading}>
          <h4>Tags</h4>
        </div>
        <div className={styles.addProductTagDetailsAlignment}>
          <div className={styles.tageFormAlignment}>
            <div className={styles.enterCodeInput}>
              <input
                type="text"
                placeholder="Enter keyword"
                onKeyUp={(event) => (event.key === 'Enter' ? addTags(event, 'enter') : null)}
                onChange={(e) => handleChange(e)}
                value={value}
              />
            </div>
            <div className={styles.addButtonAlignment}>
              <button onClick={() => addTags(value, '')}>Add </button>
            </div>
          </div>
          <div className={styles.tagDetailsAlignment}>
            {tags?.map((tag, index) => {
              return (
                <div className={styles.tagBoxAlignment} style={{ background: colorArray[index] }} key={index}>
                  <span style={{ color: FontcolorArray[index] }}>
                    {tag?.length > 30 ? tag.substring(0, 30) + '...' : tag}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    onClick={() => removeTags(index)}
                  >
                    <path
                      d="M12 4L4 12"
                      stroke={FontcolorArray[index]}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4 4L12 12"
                      stroke={FontcolorArray[index]}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {/* <img src={'/assets/icon/close-circle.svg'} alt="CircleCloseIcon" onClick={() => removeTags(index)} /> */}
                </div>
              )
            })}
          </div>
          <div className={styles.mobileViewTagDetailsAlignment}>
            {tags?.map((tag, index) => {
              return (
                <div className={styles.mobileViewTagBoxAlignment} style={{ background: colorArray[index] }} key={index}>
                  <p style={{ color: FontcolorArray[index] }}>
                    {tag?.length > 30 ? tag.substring(0, 30) + '...' : tag}
                  </p>
                  <svg
                    onClick={() => removeTags(index)}
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                  >
                    <path
                      d="M9 1L1.00125 9"
                      stroke={FontcolorArray[index]}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1 1L8.99875 9"
                      stroke={FontcolorArray[index]}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
