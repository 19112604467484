import React from 'react'
import PostComments from '../PostComments'
import PostCommentInput from '../PostCommentInput'
import PostContent from '../PostContent'
import PostHeader from '../PostHeader'
export default function MobileViewComment(props) {
  const {
    isNormalPost,
    isGroupPost,
    isStorePost,
    isClose,
    setShowMore,
    post,
    commentId,
    store,
    type,
    setIsMobileComment,
    isSetClose,
    editPost,
    showModal,
    showDeletePostModal,
    isSmallBox,
    editPostMobile,
  } = props

  return (
    <div className="mobile-view-comment-section">
      <div className={`mobile-view-comment-modal-alignment ${isSmallBox ? 'small-comment-box' : ''}`}>
        <div className="mobile-view-modal-comment-details-alignment">
          {!isSmallBox && (
            <div className="mobile-view-comment-back-alignment">
              <div
                className="back_details-alignment"
                onClick={() => {
                  setIsMobileComment(false)
                  isSetClose(true)
                }}
              >
                <div>
                  <img src="/assets/icon/left-icon.svg" alt="back-page" />
                </div>
                <p>Back</p>
              </div>
              <div className="mobile-heading-alignment">
                <h4>Comments</h4>
              </div>
            </div>
          )}
          <div className="mobile-view-cart-height-alignment">
            <div className="mobile-view-comment-profile-details-alignment">
              {/* <div className="mobile-view-comment-profile-alignment">
              <div className="mobile-profile-img-alignment">
                <img src={UserIcon.src} alt="avatar" />
              </div>
              <div className="mobile-profile-details-alignment">
                <h6>Grace Zhang</h6>
                <div className="mobile-child-details-alignment">
                  <span>20 May at 09:28 PM</span>
                  <p></p>
                  <div className="mobile-comment-view-alignment">
                    <img src={EyesIcon.src} alt="EyesIcon" />
                    <span>420</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile-view-notes-alignment">
              <p>Check out these beach pictures.</p>
            </div> */}
              <PostHeader
                isNormalPost={isNormalPost}
                isGroupPost={isGroupPost}
                isStorePost={isStorePost}
                post={post}
                store={store}
                editPost={editPost}
                editPostMobile={editPostMobile}
                showModal={showModal}
                showDeletePostModal={showDeletePostModal}
                isHideMoreMenu={true}
              />
              <div style={{ paddingTop: '10px' }}>
                <PostContent content={post?.content} />
              </div>
            </div>

            {/* <div className="mobile-comment-body-details-alignment">
            <div>
              <div className="mobile-comment-body-box-alignment">
                <div className="mobile-comment-main-details-alignment">
                  <div className="mobile-comment-main-details-alignment">
                    <div className="mobile-comment-userImg-alignment">
                      <img src={UserIcon.src} alt="UserIcon" />
                    </div>
                    <div className="mobile-comment-heading-details-alignment">
                      <h6>Brenda Jones</h6>
                      <span>2m</span>
                    </div>
                  </div>
                </div>
                <div className="mobile-comment-body-notes-alignment">
                  <p>Can you provide additional information for these speakers?</p>
                </div>
                <div className="mobile-comment-img-uploded-details-alignment">
                  <div className="mobile-comment-img-alignment">
                    <img src={C1.src} alt="C1" />
                  </div>
                  <div className="mobile-comment-img-alignment">
                    <img src={C2.src} alt="C2" />
                  </div>
                  <div className="mobile-comment-img-alignment">
                    <img src={C3.src} alt="C3" />
                  </div>
                  <div className="mobile-comment-img-alignment last-img-alignment ">
                    <img src={C4.src} alt="C4" />
                    <div className="name-alignment">
                      <h4>4 More</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mobile-other-option-alignment">
                <div className="mobile-other-first-alignment">
                  <div className="replies-likes-main">
                    <div className="replies-section-align">
                      <img src={'/assets/icon/feed/comment-reply.svg'} />
                    </div>
                    <span>275 replies</span>
                  </div>

                  <div className="replies-likes-main">
                    <div className="replies-section-align">
                      <svg
                        width="24"
                        height="21"
                        viewBox="0 0 24 21"
                        fill={'#FFFFFF'}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.1263 1.33594C3.83561 1.33594 1.16797 4.00359 1.16797 7.29427C1.16797 13.2526 8.20963 18.6693 12.0013 19.9293C15.793 18.6693 22.8346 13.2526 22.8346 7.29427C22.8346 4.00359 20.167 1.33594 16.8763 1.33594C14.8611 1.33594 13.0796 2.33634 12.0013 3.86758C10.923 2.33634 9.14146 1.33594 7.1263 1.33594Z"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          stroke={'#556EE6'}
                        />
                      </svg>
                    </div>
                    <span>122 likes</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="mobile-comment-body-box-alignment">
                <div className="mobile-comment-main-details-alignment">
                  <div className="mobile-comment-main-details-alignment">
                    <div className="mobile-comment-userImg-alignment">
                      <img src={UserIcon.src} alt="UserIcon" />
                    </div>
                    <div className="mobile-comment-heading-details-alignment">
                      <h6>Brenda Jones</h6>
                      <span>2m</span>
                    </div>
                  </div>
                </div>
                <div className="mobile-comment-body-notes-alignment">
                  <p>Can you provide additional information for these speakers?</p>
                </div>
                <div className="child-mobile-comment-details-alignment">
                  <div className="mobile-comment-main-details-alignment child-alignment ">
                    <div className="mobile-comment-userImg-alignment">
                      <img src={UserIcon.src} alt="UserIcon" />
                    </div>
                    <div>
                      <div className="mobile-comment-heading-details-alignment">
                        <h6>Brenda Jones</h6>
                        <span>2m</span>
                      </div>
                      <div className="child-details-child-notes-alignment">
                        <p>I already put on the dersciption, check it please</p>
                        <div className="see-more-alignment">
                          <a>Show More Replies</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mobile-other-option-alignment">
                <div className="mobile-other-first-alignment">
                  <div className="replies-likes-main">
                    <div className="replies-section-align">
                      <img src={'/assets/icon/feed/comment-reply.svg'} />
                    </div>
                    <span>275 replies</span>
                  </div>
                  <div className="replies-likes-main">
                    <div className="replies-section-align">
                      <svg
                        width="24"
                        height="21"
                        viewBox="0 0 24 21"
                        fill={'#FFFFFF'}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.1263 1.33594C3.83561 1.33594 1.16797 4.00359 1.16797 7.29427C1.16797 13.2526 8.20963 18.6693 12.0013 19.9293C15.793 18.6693 22.8346 13.2526 22.8346 7.29427C22.8346 4.00359 20.167 1.33594 16.8763 1.33594C14.8611 1.33594 13.0796 2.33634 12.0013 3.86758C10.923 2.33634 9.14146 1.33594 7.1263 1.33594Z"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          stroke={'#556EE6'}
                        />
                      </svg>
                    </div>
                    <span>122 likes</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="mobile-comment-body-box-alignment">
                <div className="mobile-comment-main-details-alignment">
                  <div className="mobile-comment-main-details-alignment">
                    <div className="mobile-comment-userImg-alignment">
                      <img src={UserIcon.src} alt="UserIcon" />
                    </div>
                    <div className="mobile-comment-heading-details-alignment">
                      <h6>Brenda Jones</h6>
                      <span>2m</span>
                    </div>
                  </div>
                </div>
                <div className="mobile-comment-body-notes-alignment">
                  <p>Can you provide additional information for these speakers?</p>
                </div>
                <div className="child-mobile-comment-details-alignment">
                  <div className="mobile-comment-main-details-alignment child-alignment ">
                    <div className="mobile-comment-userImg-alignment">
                      <img src={UserIcon.src} alt="UserIcon" />
                    </div>
                    <div>
                      <div className="mobile-comment-heading-details-alignment">
                        <h6>Brenda Jones</h6>
                        <span>2m</span>
                      </div>
                      <div className="child-details-child-notes-alignment">
                        <p>I already put on the dersciption, check it please</p>
                        <div className="see-more-alignment">
                          <a>Show More Replies</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mobile-other-option-alignment">
                <div className="mobile-other-first-alignment">
                  <div className="replies-likes-main">
                    <div className="replies-section-align">
                      <img src={'/assets/icon/feed/comment-reply.svg'} />
                    </div>
                    <span>275 replies</span>
                  </div>
                  <div className="replies-likes-main">
                    <div className="replies-section-align">
                      <svg
                        width="24"
                        height="21"
                        viewBox="0 0 24 21"
                        fill={'#FFFFFF'}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.1263 1.33594C3.83561 1.33594 1.16797 4.00359 1.16797 7.29427C1.16797 13.2526 8.20963 18.6693 12.0013 19.9293C15.793 18.6693 22.8346 13.2526 22.8346 7.29427C22.8346 4.00359 20.167 1.33594 16.8763 1.33594C14.8611 1.33594 13.0796 2.33634 12.0013 3.86758C10.923 2.33634 9.14146 1.33594 7.1263 1.33594Z"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          stroke={'#556EE6'}
                        />
                      </svg>
                    </div>
                    <span>122 likes</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="mobile-comment-body-box-alignment">
                <div className="mobile-comment-main-details-alignment">
                  <div className="mobile-comment-main-details-alignment">
                    <div className="mobile-comment-userImg-alignment">
                      <img src={UserIcon.src} alt="UserIcon" />
                    </div>
                    <div className="mobile-comment-heading-details-alignment">
                      <h6>Brenda Jones</h6>
                      <span>2m</span>
                    </div>
                  </div>
                </div>
                <div className="mobile-comment-body-notes-alignment">
                  <p>Can you provide additional information for these speakers?</p>
                </div>
                <div className="child-mobile-comment-details-alignment">
                  <div className="mobile-comment-main-details-alignment child-alignment ">
                    <div className="mobile-comment-userImg-alignment">
                      <img src={UserIcon.src} alt="UserIcon" />
                    </div>
                    <div>
                      <div className="mobile-comment-heading-details-alignment">
                        <h6>Brenda Jones</h6>
                        <span>2m</span>
                      </div>
                      <div className="child-details-child-notes-alignment">
                        <p>I already put on the dersciption, check it please</p>
                        <div className="see-more-alignment">
                          <a>Show More Replies</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mobile-other-option-alignment">
                <div className="mobile-other-first-alignment">
                  <div className="replies-likes-main">
                    <div className="replies-section-align">
                      <img src={'/assets/icon/feed/comment-reply.svg'} />
                    </div>
                    <span>275 replies</span>
                  </div>
                  <div className="replies-likes-main">
                    <div className="replies-section-align">
                      <svg
                        width="24"
                        height="21"
                        viewBox="0 0 24 21"
                        fill={'#FFFFFF'}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.1263 1.33594C3.83561 1.33594 1.16797 4.00359 1.16797 7.29427C1.16797 13.2526 8.20963 18.6693 12.0013 19.9293C15.793 18.6693 22.8346 13.2526 22.8346 7.29427C22.8346 4.00359 20.167 1.33594 16.8763 1.33594C14.8611 1.33594 13.0796 2.33634 12.0013 3.86758C10.923 2.33634 9.14146 1.33594 7.1263 1.33594Z"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          stroke={'#556EE6'}
                        />
                      </svg>
                    </div>
                    <span>122 likes</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
            <div className="mobile-comment-body-details-alignment">
              <PostComments
                setShowMore={setShowMore}
                showMore={true}
                postId={post?.id}
                backgroundColor="#f8f8f8"
                commentId={commentId}
                isClose={isClose}
                isProductComment={false}
                isBottonBorderVisible={true}
                isShowMore={false}
              />
            </div>
          </div>
          <div className="mobile-view-footer-input-alignment">
            <PostCommentInput
              type={type}
              isPost={true}
              store={store}
              post={post}
              isMobileView={true}
              isButton={false}
              isMobileButton={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

{
  /* <div className="mobile-user-alignment">
              <img src={UserIcon.src} alt="UserIcon" />
            </div>
            <div className="mobile-input-alignment">
              <div className="mobile-input-details-alignment">
                <input type="text" />
                <div className="icon-alignment">
                  <img src={SmileIcon.src} alt="SmileIcon" />
                </div>
              </div>
              <div className="mobile-img-alignment">
                <img src={UploadImgIcon.src} alt="UploadImgIcon" />
              </div>
              <div className="mobile-send-button-alignment">
                <button>
                  <img src={SendIcon.src} alt="SendIcon" />
                </button>
              </div>
            </div> */
}
