import React, { useEffect, useRef, useState } from 'react'
// import styles from './LiveStreamModel.module.scss'
import LiveStreamComment from './LiveStreamComment'
import classNames from 'classnames'
import { addParticipants } from 'src/livestream/api/addParticipants'
import axios, { AxiosResponse } from 'axios'
import { useAppDispatch } from 'src/store/store'
import { createMeetingSlice } from 'src/store/slices/createMeetingSlice'
import { DyteProvider, useDyteClient, useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core'
import { DyteDialogManager, DyteGrid, DyteSimpleGrid, DyteUIBuilder, generateConfig } from '@dytesdk/react-ui-kit'
import { useAuth } from 'src/utils/auth'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import { JOIN_LIVE_STREAM } from 'src/graphql/mutations/joinLiveStream'
import sellerClient from 'src/utils/apolloSellerClient'
import client1 from 'src/utils/apolloClient'
import LiveStreamModal from './StreamMainComponent'
import moment from 'moment'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { QUICK_LIVE_STREAM } from 'src/graphql/mutations/quickLiveStream'
import Loader from 'src/components/UI/Loader'

type ResponseData = {
  success: boolean
  data: {
    token: string
  }
}

const LiveStreamMain = (props) => {
  const [meeting, initMeeting] = useDyteClient()
  const [token, setToken] = useState<any>('')
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const [inputValue, setInputValue] = useState({
    username: '',
  })
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const [hostData, setHostData] = useState<any>({})
  const [joinLiveStream] = useMutation(JOIN_LIVE_STREAM, {
    // ...(true && client: 0),
    client: getAllState?.lastModalData?.data?.isHost ? sellerClient : client1,
    onCompleted: (res) => {
      // setLoadingProduct(false)
    },
    onError: (err) => {
      console.log('err', err)
    },
  })

  // useEffect(() => {
  //   onSubmitLogin()
  // }, []);

  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   onSubmitLogin()
  // }

  // const handleChange = (e) => {
  //   const { name, value } = e.target
  //   setInputValue((prevState) => ({ ...prevState, [name]: value }))
  // }

  const onSubmitLogin = async () => {
    const authToken: any = await createMeeting()
    if (authToken) {
      dispatch(
        createMeetingSlice({
          authToken,
        }),
      )
      // setToken(authToken?.meetingDetail?.data?.data?.token)
      // router.push(`/meeting/?token=${authToken?.meetingDetail?.data?.data?.token}`)
    } else {
      console.log('There was an error obtaining the authToken')
    }
  }

  const createMeeting = async () => {
    try {
      const id: any = await addParticipants()
      const orgID = '1f6e1dab-1302-4733-a02a-b1f863525e98'
      const apiKey = 'de84adcce79b4701d71d'
      const base64ApiKey = btoa(`${orgID}:${apiKey}`)
      const options = {
        method: 'POST',
        url: `https://api.dyte.io/v2/meetings/${id.data.data.id}/participants`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${base64ApiKey}`,
        },
        data: {
          name: getAllState?.lastModalData?.data?.result?.store?.name,
          picture: getAllState?.lastModalData?.data?.result?.store?.logo,
          preset_name: 'livestream_host',
          custom_participant_id: '1234',
        },
      }
      const response: AxiosResponse<ResponseData> = await axios(options)
      if (response.data.success) {
        dispatch(
          fetchModalData({
            allModalData: [
              ...getAllState?.allModalData,
              {
                modal: 'liveStream',
                isOpen: true,
                modalFor: 'bigModal',
                data: {
                  isHost: true,
                  isStartStream: getAllState?.lastModalData?.data?.result ? false : true,
                  result: getAllState?.lastModalData?.data?.result,
                  uuid: getAllState?.lastModalData?.data?.uuid,
                  finalTime: getAllState?.lastModalData?.data?.finalTime,
                },
                startDate: moment().toISOString(),
              },
            ],
            lastModalData: getAllState?.lastModalData,
          }),
        )
        return { meetingDetail: response, participantsId: id }
      }
    } catch (error) {
      console.error(error)
    }
    // setToken(result?.data?.joinLiveStream?.authToken)
  }

  useEffect(() => {
    if (getAllState?.lastModalData?.data?.isStartStream) {
      const fetchData = async () => {
        let result = await joinLiveStream({
          variables: {
            input: {
              meeting_id: getAllState?.lastModalData?.data?.uuid,
            },
          },
        })
        setToken(result?.data?.joinLiveStream?.authToken)
        setHostData(result?.data?.joinLiveStream)
      }
      fetchData()
    }
    else if (getAllState?.lastModalData?.data?.result) {
      setLoading(false)
      setToken(getAllState?.lastModalData?.data?.result?.authToken)
      setHostData(getAllState?.lastModalData?.data?.result)
    } else {
      setLoading(false)
    }
  }, [getAllState?.lastModalData?.data])

  useEffect(() => {
    if (getAllState?.lastModalData?.data && token) {
      initMeeting({
        authToken: token,
        defaults: {
          audio: false,
          video: true,
        },
      }).then((meeting) => {
        setLoading(false)
        return meeting?.join()

      })
    }
  }, [token])

  return (
    <>
      {loading && <Loader />}
      {/* {getAllState?.lastModalData?.data?.isStartStream ? ( */}
      <DyteProvider value={meeting}>
        <LiveStreamModal meeting={meeting} hostData={hostData ? hostData : getAllState?.lastModalData?.data} streamTimes={getAllState?.lastModalData?.data} />
      </DyteProvider>
      {/* ) : (
        <div>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="form-group">
              <input
                required
                onChange={(e) => handleChange(e)}
                type="text"
                className="is-invalid"
                placeholder="Enter username"
                name="username"
              />
            </div>
            <button type="submit" className="text-white mt-14" style={{ fontSize: '16px', background: 'blue' }}>
              Get Started
            </button>
          </form>
        </div>
      )} */}
    </>
  )
}

export default LiveStreamMain
