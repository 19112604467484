import React, { useCallback, useEffect, useState } from 'react'
import AllProduct from 'src/components/ProductPage/AllProduct'
import { useSelector } from 'react-redux'
import { fetchAllStoreProducts, setStoreProductState } from 'src/store/slices/fetchAllStoresSlice'
import { useAppDispatch } from 'src/store/store'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAuth } from 'src/utils/auth'
import CREATE_LIKE_MUTATION from 'src/graphql/mutations/createLike'
import { useMutation } from '@apollo/client'
import { setProductByCategoryState } from 'src/store/slices/fetchAllProduct'

export default function StoreProductSection({ storeData, slug }) {
  const { user } = useAuth()
  const [page, setPage] = useState(1)
  const [dataLimite, setDataLimite] = useState(40)
  const [filterData, setFilterData] = useState<any>({ subCategory: [], category: [] })
  const dispatch = useAppDispatch()
  const allStores = useSelector((state: any) => state?.storesData)
  const [maxPrice, setMaxPrice] = useState(1000) // Set initial max value
  const [minPrice, setMinPrice] = useState(1)

  // useEffect(() => {
  //   dispatch(setStoreProductState([]))
  // }, [])

  useEffect(() => {
    if (storeData?.id) {
      fetchProduct(maxPrice, minPrice)
    }
  }, [storeData?.id, page])

  const fetchProduct = (maxPrice, minPrice, product_id = null, type = null) => {
    if (storeData?.id) {
      if (type === 'price') {
        setMaxPrice(maxPrice)
        setMinPrice(minPrice)
      }
      dispatch(
        fetchAllStoreProducts({
          maxPrice: maxPrice,
          minPrice: minPrice,
          store_id: storeData?.id,
          page: page,
          limit: dataLimite,
          search: '',
          status: '',
          subCategory: [],
        }),
      ).then(() => {
        if (type === 'dislike' || type === 'like') {
          let update_product = allStores?.getAllStoreProduct?.getAllProductByStore?.data?.map((prd) => {
            if (prd?.id === product_id) {
              return {
                ...prd,
                likes: type === 'like' ? { id: 3.3333 } : null,
              }
            } else {
              return prd
            }
          })
          dispatch(
            setStoreProductState({
              getAllProductByStore: {
                data: update_product,
              },
            }),
          )
        }
      })
    }
  }

  const fetchUpdatedProduct = (product_id, type) => {
    if (type === 'price') {
      fetchProduct(product_id?.maxValue, product_id?.minValue, product_id, type)
    } else {
      fetchProduct(maxPrice, minPrice, product_id, type)
    }
  }

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const [createLike] = useMutation(CREATE_LIKE_MUTATION, {
    onCompleted: async (response, { context: { product_id } }) => {
      if (response?.createLike?.id) {
        const update_product = allStores?.getAllStoreProduct?.getAllProductByStore?.data?.map((prd) => {
          if (prd?.id === product_id) {
            return {
              ...prd,
              likes: {
                id: 0,
              },
            }
          } else {
            return prd
          }
        })
        dispatch(setStoreProductState(update_product))
      }
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const likeProduct = (product) => {
    if (user) {
      const likeFor = 'PRODUCT'
      createLike({
        variables: { parent_id: product?.id, like_for: 'PRODUCT' },
        context: { product_id: product?.id },
      })
    } else {
      handleRedirect()
    }
  }

  return (
    /* <FeaturedProduct /> */
    <AllProduct
      APIcall={fetchUpdatedProduct}
      products={allStores?.getAllStoreProduct?.getAllProductByStore?.data}
      storeId={0}
      color={'#FFFFFF'}
      storeData={{ name: storeData?.name, id: storeData?.id }}
      isFilterMenu={true}
      total={allStores?.getAllStoreProduct?.getAllProductByStore?.total}
      isCalled={allStores?.called}
      dataLimite={dataLimite}
      setPage={setPage}
      page={page}
      topForFilter={25}
      isPadding={true}
      mobileTopForFilter={97}
      setFilterData={setFilterData}
      filterData={filterData}
      likeProduct={likeProduct}
      category_slug={null}
      subCategory_slug={null}
      childSubCategory_slug={null}
      store_slug={slug}
    />
  )
}
