import { gql } from '@apollo/client'

export const UPDATE_GROUP_DETAIL = gql`
  mutation UPDATE_GROUP_DETAIL($input: updateGroupDetailInput) {
    updateGroupDetail(input: $input) {
      id
      slug
    }
  }
`

export default UPDATE_GROUP_DETAIL