import * as React from 'react';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import REACT_TO_LIVE_STREAM from 'src/graphql/mutations/reactToLiveStream';

export const StreamReactionButton = ({ item }: any) => {
  const [liked, setLiked] = useState<boolean>();
  const [hover, setHover] = useState(false);
  const [reactionCount, setReactionCount] = useState<number>();

  useEffect(() => {
    setLiked(item?.currentUserLiked)
    setReactionCount(item?.reaction_count)
  }, [item?.currentUserLiked, item?.reaction_count])
  const [reactToStreamMutation, { loading: reactToStreamLoading }] = useMutation(REACT_TO_LIVE_STREAM, {
    onCompleted: () => {
      setLiked(true);
      setReactionCount(reactionCount + 1);
    },
    onError: () => {
      setLiked(false);
      setReactionCount(reactionCount - 1);
    }
  });

  async function handleLike(e: any, item: any) {
    e.stopPropagation();
    if (!liked) {
      await reactToStreamMutation({
        variables: {
          input: {
            meeting_id: item?.uuid,
            reaction: 'like',
          }
        }
      });
    }
  }

  return (
    <div className=" flex items-center flex-col">
      <div className={`flex items-center justify-center rounded-md bg-[#1E2432] ${liked ? "cursor-not-allowed" : "cursor-pointer"}  p-2`} onClick={(e) => handleLike(e, item)}>
        {reactToStreamLoading ? (
          <svg className="animate-spin h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill={liked ? "#FF3737" : "none"}
            stroke={liked ? "#FF3737" : "white"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ fill: liked ? "#FF3737" : hover ? "#FF3737" : "none" }}
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
          >
            <path
              d="M7.5 3.99951C4.46244 3.99951 2 6.43856 2 9.44725C2 14.895 8.5 19.8475 12 20.9995C15.5 19.8475 22 14.895 22 9.44725C22 6.43856 19.5375 3.99951 16.5 3.99951C14.6399 3.99951 12.9954 4.91419 12 6.3142C11.0046 4.91419 9.36015 3.99951 7.5 3.99951Z"
            />
          </svg>
        )}
      </div>
      <p className="text-white">{reactionCount}</p>
    </div>
  );
};
