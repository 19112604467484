import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import GET_GROUPS_ONBOARDING from 'src/graphql/queries/getAllgroupsOnboarding'
import GET_GROUP_MEMBERS from 'src/graphql/queries/getGroupMember'
import GET_GROUPS from 'src/graphql/queries/getGroups'
import GET_JEWELRY_COLLECTION from 'src/graphql/queries/getJewelryCollection'
import GET_JOINED_GROUP from 'src/graphql/queries/sellerQueries/getJoinedGroups'
import GET_MY_GROUPS from 'src/graphql/queries/sellerQueries/getMyGroups'
import client1 from 'src/utils/apolloClient'

interface fetchAllClub {
  loading: true | false
  error: {} | null
  called: true | false
  jewelryCollection: []
  ShopbyCollections: []
}

const initialState = {
  loading: false,
  error: null,
  currentStep: '',
  jewelryCollection: [],
  ShopbyCollections: [],
  called: false,
} as fetchAllClub

export const fetchJewelrySlice = createAsyncThunk('fetchClubs/fetchJewelrySlice', async (post: any) => {
  const { data } = await client1.query({
    query: GET_JEWELRY_COLLECTION,
    variables: {
      type: post?.type,
    },
    fetchPolicy: 'network-only',
  })
  return { data: data?.getJewelryCollection ? data?.getJewelryCollection : [], type: post?.type }
})

const fetchAllClub = createSlice({
  name: 'fetchClubs',
  initialState,
  reducers: {
    // setJoinedClub: (state: any, action) => {
    //   state.joinedClubs = action.payload
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchJewelrySlice.pending, (state: any, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchJewelrySlice.fulfilled, (state: any, action) => {
      state.loading = 'success'
      if (action?.payload?.type === 'for_banner') {
        state.bannerCollection = action?.payload?.data
      } else {
        state.ShopbyCollections = action?.payload?.data
      }
    })
    builder.addCase(fetchJewelrySlice.rejected, (state: any, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })
  },
})

// export var _actions = fetchAllClub.actions
// export const { setJoinedClub } = fetchAllClub.actions
export default fetchAllClub.reducer
