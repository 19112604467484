import { gql } from '@apollo/client'

const query = gql`
mutation EndLiveStream($uuid: String, $time: String) {
  endLiveStream(uuid: $uuid, time: $time) {
    success
    message
  }
}
`
export default query
