import { ArrowRightIcon } from '@heroicons/react/24/solid'

interface WelcomeProps {
  handleStart: () => void
}

export default function WelcomePage({ handleStart }: WelcomeProps) {
  return (
    <div className="flex min-h-screen">
      <div className="flex-1 flex items-center justify-center p-8">
        <div className="max-w-xl w-full space-y-8">
          <div>
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white">Welcome to Bluejestic! 🎉</h1>
            <p className="mt-4 text-base text-gray-600 dark:text-gray-300">
              We are excited to have you onboard! Our platform is here to help your business grow by connecting you with
              engaged customers and providing tools to boost your sales.
            </p>
          </div>

          <div className="space-y-6">
            <div className="bg-white dark:bg-gray-800 shadow-sm rounded-lg p-6">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">What's next?</h3>
              <ul className="mt-4 space-y-4">
                <li className="flex items-start">
                  <span className="flex-shrink-0 h-6 w-6 flex items-center justify-center rounded-full bg-blue-100 dark:bg-blue-900 text-blue-600 dark:text-blue-200">
                    1
                  </span>
                  <p className="ml-3 text-sm text-gray-600 dark:text-gray-300">Complete your business profile</p>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 h-6 w-6 flex items-center justify-center rounded-full bg-blue-100 dark:bg-blue-900 text-blue-600 dark:text-blue-200">
                    2
                  </span>
                  <p className="ml-3 text-sm text-gray-600 dark:text-gray-300">Verify your personal information</p>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 h-6 w-6 flex items-center justify-center rounded-full bg-blue-100 dark:bg-blue-900 text-blue-600 dark:text-blue-200">
                    3
                  </span>
                  <p className="ml-3 text-sm text-gray-600 dark:text-gray-300">Set up your tax preferences</p>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 h-6 w-6 flex items-center justify-center rounded-full bg-blue-100 dark:bg-blue-900 text-blue-600 dark:text-blue-200">
                    4
                  </span>
                  <p className="ml-3 text-sm text-gray-600 dark:text-gray-300">Set up your shipping profile</p>
                </li>
              </ul>
            </div>
          </div>

          <button
            onClick={handleStart}
            className="mt-8 inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800"
          >
            Let's start your onboarding
            <ArrowRightIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>

      <div className="hidden lg:flex flex-1 relative bg-gray-900">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-600/20 to-indigo-600/20 mix-blend-soft-light" />
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-l from-black/60 via-black/30 to-transparent z-10" />
          <div className="absolute inset-0 z-10">
            <video autoPlay loop muted className="absolute w-full h-full object-cover object-center scale-[1.02]">
              <source src="/video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div className="absolute inset-0 flex items-center justify-center z-20">
          <div className="max-w-2xl mx-auto px-8">
            <div className="backdrop-blur-sm bg-black/20 p-8 rounded-xl border border-white/10 text-center">
              <h2 className="text-4xl font-bold text-white">
                Empower Your Brand. Engage Your Customers. Grow with Bluejestic
              </h2>
              <p className="mt-6 text-lg text-white/90 max-w-xl mx-auto">
                Join thousands of successful sellers who have expanded their reach and increased their sales through our
                platform.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
