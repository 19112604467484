import React from 'react'

export default function NoComment() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
      <div className="new-comment-no-data-alignment">
        <div>
          <img src="/assets/icon/no-comment-icon.svg" alt="no comment icon" />
        </div>
        <p>No comments.</p>
      </div>
    </div>
  )
}
