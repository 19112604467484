import React from 'react'
import styles from './CategoryFeaturedProducts.module.scss'
import FeaturedProductsDetails from 'src/components/Category/ShopByCategoryDetails/FeaturedProductsDetails'
export default function CategoryFeaturedProducts() {
  return (
    <div className={styles.categoryFeaturedProductSection}>
      <div className={styles.categoryFeatureheading}>
        <h4>Featured Products</h4>
      </div>
      <div className={styles.categoryFetureBodyAlignemt}>
        <div className={styles.categoryFetureGridAlignment}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => {
            return <FeaturedProductsDetails index={index} />
          })}
        </div>
      </div>
    </div>
  )
}
