import React from 'react'
import styles from './AddProductModalProductFeaturedProduct.module.scss'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'

export default function AddProductModalProductFeaturedProduct({ setProductData, productData }) {
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const dispatch = useAppDispatch()

  const handleChecked = async (e) => {
    setProductData({ ...productData, isFeature: e.target.checked })

    await dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          mobileProductsData: {
            ...addProductDetailSlice?.id?.mobileProductsData,
            isFeature: e.target.checked,
          },
        },
      }),
    )
  }
  return (
    <div className={styles.addProductModalProductFeaturedSection}>
      <div className={styles.addProductModalProductFeaturedBox}>
        <div className={styles.productFeaturedHeadingAlignment}>
          <h4>Featured Product</h4>
          <p>Add this product as a featured product</p>
        </div>
        <div className={styles.productFeaturedoptionBgAlignment}>
          <div className={classNames(styles.productFeatureoptionBoxAlignment, styles.deactive)}>
            <img src="/assets/seller/icons/feature-icon.svg" alt="EyesIcon" />
          </div>
          <label className="switch mb-0">
            <input type="checkbox" onClick={(e) => handleChecked(e)} checked={productData?.isFeature ? true : false} />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
    </div>
  )
}
