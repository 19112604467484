import React, { forwardRef, useState } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export const addTransformParam = (src: string) => {
  return src && src.indexOf('?') === -1 ? '?t=w' : '&t=w'
}

const contentHubSizes = [576, 992, 1200, 1920, 2880]
const deviceSizes = [576, 992, 1440, 1920, 2880]

interface LazyImagePropType {
  image?: any
  priority?: boolean
  sizes?: string
  className?: string
  width?: number
  height?: any
  disableSkeleton?: boolean
  objectFit?: any
  loading?: 'lazy' | 'eager' | undefined
  handleClickOnImage?: any
  imageClassName?: any
  borderRadius?: any
  position?: any
}

const LazyImage: React.ForwardRefRenderFunction<any, LazyImagePropType> = (
  {
    image,
    priority,
    sizes,
    width,
    height,
    loading = 'lazy',
    className,
    disableSkeleton,
    objectFit,
    handleClickOnImage,
    imageClassName,
    borderRadius,
    position,
  },
  ref,
) => {
  const { src, alt } = image
  const [isLoaded, setIsLoaded] = useState(false)
  const handleImageLoad = () => {
    setIsLoaded(true)
  }

  const generateSrcSet = () => {
    return deviceSizes
      .map((deviceSize, index) => `${src}${addTransformParam(src)} ${contentHubSizes[index]}w`)
      .join(', ')
  }

  return (
    <>
      <div style={{ height: '100%', position: 'relative' }} className={className}>
        {/* !isLoaded && !disableSkeleton */}
        {!isLoaded && !disableSkeleton && (
          <SkeletonTheme baseColor="#d1d1d1" highlightColor="#FFFFFF">
            <Skeleton
              style={{
                width: width ? width : '100%',
                height: height ? height : '100%',
                borderRadius: borderRadius,
                position: position,
              }}
              className={className}
            />
          </SkeletonTheme>
        )}

        {image ? (
          <img
            alt={alt}
            width={width}
            height={height}
            onLoad={handleImageLoad}
            loading={loading}
            onClick={handleClickOnImage}
            src={`${src}${addTransformParam(src)}${deviceSizes[deviceSizes.length - 1]}`}
            // srcSet={generateSrcSet()}
            className={imageClassName}
            sizes={sizes ? sizes : `(max-width: 768px) 100vw, 50vw`}
            style={{
              opacity: isLoaded ? 1 : 0,
              // transition: 'opacity 1s',
              width: '100%',
              // height: '100%',
              objectFit: objectFit,
              cursor: 'pointer',
              objectPosition: 'center',
            }}
          />
        ) : null}
      </div>
    </>
  )
}

export default forwardRef(LazyImage)
