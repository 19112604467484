import gql from 'graphql-tag'

const GET_ALL_STORE = gql`
  query GetAllStore($page: Int, $limit: Int, $type: String, $search: String) {
    getAllStore(page: $page, limit: $limit, type: $type, search: $search) {
      data {
        stores {
          id
          name
          logo_image
          banner_image
          isFollow
          isLike
          like_count
          followers_count
          product_count
          product_images
          slug
        }
        count
      }
    }
  }
`
export default GET_ALL_STORE
// $limit: Int, $page: Int
//  limit: $limit, page: $page
