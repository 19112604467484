import { Radio } from 'antd'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import GET_CURRENT_USER_QUERY from 'src/graphql/queries/getCurrentUser'
import { useAuth } from 'src/utils/auth'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import client1 from 'src/utils/apolloClient'

export default function FooterMenu(props: any) {
  const router = useRouter()
  const { user } = useAuth()
  const temp: any = router.pathname.split('/')
  const fname = temp[1]
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const [currentUser, setCurrentUser] = useState<any>({})

  const fetchUserData = async () => {
    if (user) {
      try {
        const currentUserData: any = await client1.query({
          query: GET_CURRENT_USER_QUERY,
        })

        if (currentUserData) {
          setCurrentUser(currentUserData)
        }
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const handleMove = () => {
    if (user) {
      router.push('/menu')
    } else {
      handleRedirect()
    }
  }

  const haneleMoveToMessage = () => {
    if (user) {
      dispatch(
        fetchModalData({
          allModalData: [
            ...getAllState?.allModalData,
            { modal: 'messageModal', isOpen: true, id: { width: 70 }, modalFor: 'bigModal' },
          ],
          lastModalData: getAllState?.lastModalData,
        }),
      )
    } else {
      handleRedirect()
    }
  }

  return (
    <div className="bottom_main mobile:block tablet:block smallTablet:block lg:hidden">
      <div className="bottom-menu">
        <Radio.Group defaultValue="5">
          <Radio.Button
            value="1"
            onClick={() => {
              router.push('/feed')
            }}
          >
            <div className="item-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M7 19C8.10457 19 9 18.1046 9 17C9 15.8954 8.10457 15 7 15C5.89543 15 5 15.8954 5 17C5 18.1046 5.89543 19 7 19Z"
                  fill={fname == 'feed' ? '#556EE6' : '#ACB1C0'}
                />
                <path
                  d="M6.54004 9.53791C5.72644 9.40232 5 10.0706 5 10.8938C5 11.5814 5.51335 12.1334 6.19135 12.2497C9.01961 12.7436 11.2473 14.9808 11.751 17.8088C11.8672 18.4867 12.4193 19 13.107 19C13.9303 19 14.5986 18.2736 14.4727 17.4601C14.1498 15.474 13.2098 13.64 11.7859 12.218C10.3621 10.7961 8.52673 9.85838 6.54004 9.53791ZM6.51099 4.00787C5.70706 3.9207 5 4.56959 5 5.37343C5 6.08042 5.53272 6.66151 6.2301 6.72931C12.0513 7.3104 16.6811 11.9398 17.2622 17.7603C17.33 18.4673 17.9112 19 18.6182 19C19.4319 19 20.0711 18.293 19.9936 17.4892C19.2866 10.3805 13.6301 4.71486 6.51099 4.00787Z"
                  fill={fname == 'feed' ? '#556EE6' : '#ACB1C0'}
                />
              </svg>
            </div>
            {fname == 'feed' ? (
              <div className={`mobile-view-text-alignment ${fname == 'feed' ? 'active-text' : ''}`}>Feed</div>
            ) : (
              <div className="mobile-view-text-alignment">Feed</div>
            )}
          </Radio.Button>
          <Radio.Button
            value="2"
            onClick={() => {
              if(user){
                router.push('/channels')
              }else{
                handleRedirect()
              }
            }}
          >
            <div className="item-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect
                  x="2"
                  y="6"
                  width="20"
                  height="15"
                  rx="3"
                  stroke={fname == 'channels' ? '#556EE6' : '#ACB1C0'}
                  strokeWidth="1.5"
                />
                <path
                  d="M17 3L12 6L7 3"
                  stroke={fname == 'channels' ? '#556EE6' : '#ACB1C0'}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.1187 14.779C12.6821 14.6824 13.0605 14.1473 12.9639 13.5838C12.8673 13.0204 12.3322 12.642 11.7687 12.7386C11.2053 12.8352 10.8269 13.3703 10.9235 13.9338C11.0201 14.4972 11.5552 14.8756 12.1187 14.779Z"
                  stroke={fname == 'channels' ? '#556EE6' : '#ACB1C0'}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.7359 11.2263C14.0692 11.4618 14.3529 11.7606 14.5706 12.1057C14.7884 12.4509 14.9359 12.8355 15.0049 13.2377C15.0739 13.6399 15.0629 14.0518 14.9726 14.4498C14.8823 14.8477 14.7145 15.224 14.4787 15.5571M10.1521 16.2938C9.81882 16.0584 9.53518 15.7596 9.31743 15.4144C9.09969 15.0693 8.95211 14.6846 8.88312 14.2824C8.81414 13.8802 8.82512 13.4683 8.91542 13.0704C9.00572 12.6724 9.17357 12.2961 9.40939 11.9631M14.9319 9.53514C16.0523 10.3277 16.812 11.5328 17.0439 12.8854C17.2759 14.238 16.9612 15.6274 16.169 16.748M8.95613 17.985C7.83578 17.1924 7.07608 15.9873 6.8441 14.6347C6.61213 13.2821 6.92686 11.8928 7.71909 10.7722"
                  stroke={fname == 'channels' ? '#556EE6' : '#ACB1C0'}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {fname == 'channel' ? (
              <div className={`mobile-view-text-alignment ${fname == 'channel' ? 'active-text' : ''}`}>Channel</div>
            ) : (
              <div className="mobile-view-text-alignment">Channel</div>
            )}
          </Radio.Button>
          <Radio.Button
            value="3"
            onClick={() => {
              router.push('/shop')
            }}
          >
            <div className="item-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M19.574 20.412C19.9116 20.0346 20.1013 19.5229 20.1013 18.9892V9.29956H3.89893V18.9892C3.89893 19.5229 4.0886 20.0346 4.42621 20.412C4.76383 20.7893 5.22173 21.0013 5.69919 21.0013H18.3011C18.7785 21.0013 19.2364 20.7893 19.574 20.412Z"
                  fill={fname == 'shop' ? '#556EE6' : '#ACB1C0'}
                  stroke={fname == 'shop' ? '#556EE6' : '#ACB1C0'}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.2998 20.9997V15.2624C9.2998 13.771 10.5088 12.562 12.0002 12.562C13.4916 12.562 14.7006 13.771 14.7006 15.2624V20.9997"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.66483 3.54555C3.80667 3.21459 4.13211 3 4.49218 3H7.49917L6.87692 7.35574C6.71747 8.47188 5.76157 9.30093 4.6341 9.30093C3.0076 9.30093 1.91098 7.63787 2.55169 6.14288L3.66483 3.54555Z"
                  fill="white"
                  stroke={fname == 'shop' ? '#556EE6' : '#ACB1C0'}
                  strokeWidth="1.5"
                />
                <path
                  d="M7.49925 3H11.9999V6.79244C11.9999 8.17784 10.8768 9.30093 9.49143 9.30093C7.96489 9.30093 6.79227 7.94888 7.00816 6.43769L7.49925 3Z"
                  fill="white"
                  stroke={fname == 'shop' ? '#556EE6' : '#ACB1C0'}
                  strokeWidth="1.5"
                />
                <path
                  d="M12 3H16.5007L16.9918 6.43769C17.2076 7.94888 16.035 9.30093 14.5085 9.30093C13.1231 9.30093 12 8.17784 12 6.79244V3Z"
                  fill="white"
                  stroke={fname == 'shop' ? '#556EE6' : '#ACB1C0'}
                  strokeWidth="1.5"
                />
                <path
                  d="M16.5005 3H19.5075C19.8676 3 20.193 3.21459 20.3348 3.54555L21.448 6.14288C22.0887 7.63787 20.9921 9.30093 19.3656 9.30093C18.2381 9.30093 17.2822 8.47188 17.1227 7.35574L16.5005 3Z"
                  fill="white"
                  stroke={fname == 'shop' ? '#556EE6' : '#ACB1C0'}
                  strokeWidth="1.5"
                />
              </svg>
            </div>
            {fname == 'shop' ? (
              <div className={`mobile-view-text-alignment ${fname == 'shop' ? 'active-text' : ''}`}>Shop</div>
            ) : (
              <div className="mobile-view-text-alignment">Shop</div>
            )}
          </Radio.Button>
          <Radio.Button value="4" onClick={() => haneleMoveToMessage()}>
            <div className="item-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M21.9999 11.4444C22.0038 12.911 21.6611 14.3576 20.9999 15.6667C20.216 17.2353 19.0108 18.5546 17.5193 19.477C16.0279 20.3993 14.3091 20.8882 12.5555 20.8889C11.089 20.8927 9.64233 20.5501 8.33331 19.8889L5.16227 20.9459C3.85934 21.3802 2.61978 20.1406 3.05409 18.8377L4.1111 15.6667C3.44992 14.3576 3.10728 12.911 3.11111 11.4444C3.11179 9.69085 3.60067 7.97207 4.52301 6.48063C5.44535 4.98919 6.76471 3.78399 8.33331 3.00003C9.64233 2.33884 11.089 1.99621 12.5555 2.00003H13.1111C15.427 2.1278 17.6144 3.10532 19.2545 4.74542C20.8947 6.38553 21.8722 8.57296 21.9999 10.8889V11.4444Z"
                  stroke={getAllState?.lastModalData?.modal === 'messageModal' ? '#556EE6' : '#ACB1C0'}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {
              <div
                className={`mobile-view-text-alignment ${
                  getAllState?.lastModalData?.modal === 'messageModal' ? 'active-text' : ''
                }`}
              >
                Messages
              </div>
            }
          </Radio.Button>
          <Radio.Button value="5" onClick={() => handleMove()}>
            <div className="mobile-view-profile-img-alignment">
              {/* <img src={user?.logo_image ? user?.logo_image : '/assets/img/feed-profile-img.png'} alt="profile img" /> */}
              <img src={user?.logo_image ? user?.logo_image : '/assets/img/defaultprofileicon.png'} alt="profile img" />
              {/* <img src="/assets/img/feed-profile-img.png" alt="profile img" /> */}
            </div>
            {
              <div className={`mobile-view-text-alignment ${fname === 'menu' ? 'active-text' : ''}`}>
                {user ? 'Me' : 'Login'}
              </div>
            }
          </Radio.Button>
        </Radio.Group>
      </div>
      {/* {CartModal && (
        <div className="mobile-view-cart-menu-modal">
          <div className="mobile-view-modal-wrapper">
            <ShoppingCart
              setCheckoutModal={setCheckoutModal}
              setCartModal={setCartModal}
              setProductDataForCheckout={setProductDataForCheckout}
              isBack={true} 
            />
          </div>
        </div>
      )}
   
        {checkoutModal && (
          <div className="mobile-view-cart-menu-modal">
            <div className="mobile-view-modal-wrapper">
              <Checkout toggle={setCheckoutModal} productArray={productDataForCheckout} modalClose={setCartModal} />
            </div>
          </div>
        )} */}
    </div>
  )
}
