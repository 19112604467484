import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface guestAccount {
  loading: true | false
  error: {} | null
  called: true | false
  data: any
}

const initialState = {
  loading: false,
  error: null,
  data: {
    modal: '',
    isOpen: false,
    id: 0,
  },
  called: false,
} as guestAccount

export const guestAccountDetailSlice = createAsyncThunk(
  'fetchProductDetail/guestAccountDetailSlice',
  async (data: any) => {
    return data
  },
)

const guestAccount = createSlice({
  name: 'fetchGuestAccountDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(guestAccountDetailSlice.pending, (state, action) => {
      state.loading = true
      state.error = null
      state.called = true
    })
    builder.addCase(guestAccountDetailSlice.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
      state.called = true
    })
    builder.addCase(guestAccountDetailSlice.rejected, (state, action) => {
      state.loading = false
      state.called = false
      state.error = action.error
      console.error('Error fetching cards!')
    })
  },
})

// export var _actions = guestAccount.actions
export default guestAccount.reducer
