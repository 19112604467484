import { gql } from '@apollo/client'
import userFragment from '../fragments/userFragment'

export default gql`
mutation Signin($input: signinSellerInput!) {
  signin(input: $input) {
    isSeller
    isUserOnboardCompleted
    message
    role
    success
    token
  }
}
`

// ${userFragment}

// mutation LOGIN($identifier: String!, $password: String!) {
//   signin(input: { email: $identifier, password: $password }) {
//     token
//     seller_token
//   }
// }
