import React, { useEffect, useState } from 'react'
import styles from './NewArrivalModal.module.scss'
import AllProduct from 'src/components/ProductPage/AllProduct'
import { useRouter } from 'next/router'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchProductByCategory, setProductByCategoryState } from 'src/store/slices/fetchAllProduct'

export default function NewArrivalModal() {
  const router = useRouter()
  const [page, setPage] = useState(1)
  const [dataLimite, setDataLimite] = useState(40)
  const dispatch = useAppDispatch()
  const productByCategory = useSelector((state: any) => state?.productByCategory)
  const [filterData, setFilterData] = useState<any>({ subCategory: [], category: [] })

  useEffect(() => {
    setPage(1)
    fetchProduct()
  }, [])

  const fetchProduct = (product_id = null, type = null) => {
    dispatch(
      fetchProductByCategory({
        category: 'Fashion & Accessories',
        subCategory: 'Women',
        childSubCategory: router?.query?.category ? router?.query?.category : '',
        isShopifyProduct: false,
        page: page,
        limit: dataLimite,
      }),
    ).then(() => {
      if (type === 'dislike' || type === 'like') {
        let update_product = productByCategory?.product?.getAllProducts?.data?.map((prd) => {
          if (prd?.id === product_id) {
            return {
              ...prd,
              likes: type === 'like' ? { id: 3.3333 } : null,
            }
          } else {
            return prd
          }
        })
        dispatch(
          setProductByCategoryState({
            getAllProducts: {
              data: update_product,
            },
          }),
        )
      }
    })
  }

  const fetchUpdatedProduct = (product_id, type) => {
    fetchProduct(product_id, type)
  }

  return (
    <div className={styles.newArrivalModalSection}>
      <div className={styles.newArrivalModalHeaderAlignment}>
        <div className={styles.newArrivalMainLineBoxAlignment}>
          <div className={styles.newArrivalMainLineBoxFlexAlignment}>
            {[...Array(200)].map((_, index) => {
              return (
                <React.Fragment key={index}>
                  <p> New Arrival</p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                    <circle cx="4" cy="4" r="4" fill="#ECEEF4" />
                  </svg>
                </React.Fragment>
              )
            })}
            <p> New Arrival</p>
          </div>
        </div>
      </div>
      <div className={styles.newArrivalModalMobileViewHeading}>
        <h4>New Arrival</h4>
      </div>
      <div className={styles.newArrivalModalDetailsAlignment}>
        <AllProduct
          products={productByCategory?.product?.getAllProducts?.data}
          storeId={0}
          color={'#FFFFFF'}
          isFilterMenu={true}
          total={productByCategory?.product?.getAllProducts?.total}
          dataLimite={dataLimite}
          setPage={setPage}
          page={page}
          topForFilter={0}
          isCalled={productByCategory?.called}
          setFilterData={setFilterData}
          filterData={filterData}
          APIcall={fetchUpdatedProduct}
        />
      </div>
    </div>
  )
}
