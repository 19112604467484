import { gql } from '@apollo/client'

export default gql`
  mutation GET_ALL_PRODUCT_FOR_CHECKOUT($products: [productArray]) {
    getSelectedProducts(input: { products: $products }) {
      carts {
        storeDetail {
          id
          name
          logo_url
        }
        product {
          shipping_method {
            service_code
          }
          id
          store_id
          title
          cart_item_id
          status
          selected_quantity
          dis_price
          dis_listPrice
          brand_id
          like_count
          comment_count
          sharepost_count
          total_variants
          total_inventory_quantity
          description
          isFeature
          isActive
          metaTitle
          keywords
          metaDescription
          isVisible
          isFreeShipping
          condition
          is_deleted
          images {
            media_id
            src
          }
          variant {
            id
            title
            price
            listPrice
            sku
            isTaxable
            barcode
            inventory_quantity
            old_inventory_quantity
            image_id
            on_hand
            total_variant {
              variant_option_id
              variant_option {
                value
                variation_id
                data {
                  name
                }
              }
            }
            image {
              media
            }
            weightValue
            weightUnit
            unit
            height
            width
            length
          }
          inventoryPrice {
            price
            listPrice
            quantity
            sku
          }
          shipping {
            weightValue
            weightUnit
            unit
            length
            width
            height
          }
          store {
            id
            name
            logo_url
          }
        }
      }
      count
    }
  }
`
