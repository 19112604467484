import styles from "./PastStreams.module.scss";
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import EyesIcon from '../../../../public/assets/icon/eye.svg'
import moment from 'moment'
import { StreamReactionButton } from "../StreamReactionButton";

const PastStreamsCard = ({ item }) => {
    const dispatch = useAppDispatch()
    const getAllState = useSelector((state: any) => state?.manageModalData?.data)
    const openPastMeetingVideo = (data) => {
        dispatch(
            fetchModalData({
                allModalData: [
                    ...getAllState?.allModalData,
                    {
                        modal: 'pastMeetingVideo',
                        isOpen: true,
                        modalFor: 'bigModal',
                        data: {
                            uuid: data?.uuid,
                            isHost: false,
                            isStartStream: true,
                            hostName: data?.title,
                            finalTime: data?.final_date,
                        },
                        startDate: moment().toISOString(),
                    },
                ],
                lastModalData: getAllState?.lastModalData,
            }),
        )
    }

    return (
        <div className={styles.sellerSocialPastLiveStreamsDetailsGrid} onClick={() => openPastMeetingVideo(item)}>
            <div className={styles.sellerSocialPastLiveStreamsDetailsGridItem}>
                <div className={styles.sellerSocialPastLiveStreamsBoxAlignment}>
                    <img src={item.media} alt="channel img" />

                    <div className={styles.sellerSocialPastLiveStreamsTimeAlignment}>
                        <img src={EyesIcon.src} alt="EyesIcon" />
                        <p>{item?.participants_count}</p>
                    </div>

                    <div className={styles.sellerSocialPastLiveStreamsBottomAlignment}>
                        <div className={styles.sellerSocialPastLiveStreamsAllDetailsAlignment}>
                            <h6>{item?.title}</h6>
                            <span className='text-white'>{moment(item?.final_date, 'HH:mm').format('MMMM DD, YYYY')}</span>
                            <div className="flex items-center text-white">
                                <span>{moment(item?.final_date).format('h:mm A')}</span>
                                <span className="mx-1"> - </span>
                                <span>{moment(item?.final_date).add(item?.duration, 'milliseconds').format('h:mm A')}</span>
                            </div>
                        </div>
                        <div className={styles.sellerSocialPastLiveStreamsLiveShareAlignment}>
                            <StreamReactionButton item={item} />

                            {/* <div className={styles.sellerSocialPastLiveStreamsDetailsBoxAlignment}>
                                <div className={styles.childBoxAlignment}>
                                    <img src="/assets/icon/share-white.svg" alt="share icon" />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PastStreamsCard;
