import React from 'react'
import ImageGridPage from '../ImageGridPage'

const PostMedia = (props) => {
  const {
    isNormalPost,
    isGroupPost,
    isStorePost,
    post,
    image,
    setCommentId,
    commentId,
    editPost,
    setIsMobileComment,
    isUserPostPreviewModal,
    editPostMobile,
  } = props

  return (
    <div style={{ width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
      {image && (
        <div style={{ width: '100%', maxWidth: '100%' }}>
          <ImageGridPage
            images={post?.attachements}
            post={post}
            setCommentId={setCommentId}
            commentId={commentId}
            editPost={editPost}
            editPostMobile={editPostMobile}
            setIsMobileComment={setIsMobileComment}
            isUserPostPreviewModal={isUserPostPreviewModal}
          />
        </div>
      )}
    </div>
  )
}

export default PostMedia
