import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import PostComments from 'src/components/Feed/NormalPost/PostComments'
import PostHeader from 'src/components/Feed/NormalPost/PostHeader'
import PostReaction from 'src/components/Feed/NormalPost/PostReaction'
import InputWithEmoti from 'src/components/ProductDetail/InputWithEmoti'
import { useAuth } from 'src/utils/auth'
import GET_POST_BY_ID from 'src/graphql/queries/getSinglePost'
import Loader from 'src/components/UI/Loader'

export default function LearnFromArtistsdetailsModal() {
  const isStorePost = false
  const isNormalPost = true
  const isGroupPost = false
  const { user }: any = useAuth()

  const postId = {
    id: 113,
  }
  const getSingle = useQuery(GET_POST_BY_ID, { variables: { post_id: postId?.id } })

  const [showMore, setShowMore] = useState(false)
  const [isClose, isSetClose] = useState(true)
  const [getSingleProduct, setGetSingleProduct] = useState<any>({})
  const [commentId, setCommentId] = useState({ id: null, comment_for: '' })

  useEffect(() => {
    if (getSingle?.data?.getSinglePost) {
      setGetSingleProduct(getSingle?.data?.getSinglePost)
    }
  }, [getSingle?.data])

  if (getSingle?.error) return <p>`Error! ${getSingle?.error.message}`</p>
  return (
    <>
      <div className="learn-from-artistsdetails-modal">
        <div className="learn-from-artistsdetails-grid-alignment">
          <div className="learn-from-artistsdetails-gridItem">
            <div className="learn-from-artistsdetails-left-side-alignment">
              <div className="learn-from-artistsdetails-left-side-main-img-alignment">
                <img src="/assets/img/artCarft/artLearnArtists/la4.png" alt="learn artists" />
              </div>

              <div className="learn-from-artistsdetails-more-video-section-alignment">
                <h6>More Videos</h6>
                <div className="learn-from-artistsdetails-more-video-alignment">
                  <div className="learn-from-artistsdetails-more-video-grid-alignment">
                    <div className="learn-from-artistsdetails-more-video-gridIem-alignment">
                      <div className="learn-from-artistsdetails-more-video-all-details-alignment">
                        <div className="learn-from-artistsdetails-more-video-img-alignment">
                          <img src="/assets/img/artCarft/artLearnArtists/la4.png" alt="learn img" />
                        </div>
                        <div className="art-craft-learns-artists-all-child-details-alignment">
                          <span>Andy Sebastian</span>
                          <p>Five minute Craft : DIY Tutorial for Hand made</p>
                        </div>
                      </div>
                    </div>
                    <div className="learn-from-artistsdetails-more-video-gridIem-alignment">
                      <div className="learn-from-artistsdetails-more-video-all-details-alignment">
                        <div className="learn-from-artistsdetails-more-video-img-alignment">
                          <img src="/assets/img/artCarft/artLearnArtists/la2.png" alt="learn img" />
                        </div>
                        <div className="art-craft-learns-artists-all-child-details-alignment">
                          <span>Andy Sebastian</span>
                          <p>Five minute Craft : DIY Tutorial for Hand made</p>
                        </div>
                      </div>
                    </div>

                    <div className="learn-from-artistsdetails-more-video-gridIem-alignment">
                      <div className="learn-from-artistsdetails-more-video-all-details-alignment">
                        <div className="learn-from-artistsdetails-more-video-img-alignment">
                          <img src="/assets/img/artCarft/artLearnArtists/la3.png" alt="learn img" />
                        </div>
                        <div className="art-craft-learns-artists-all-child-details-alignment">
                          <span>Andy Sebastian</span>
                          <p>Five minute Craft : DIY Tutorial for Hand made</p>
                        </div>
                      </div>
                    </div>
                    <div className="learn-from-artistsdetails-more-video-gridIem-alignment">
                      <div className="learn-from-artistsdetails-more-video-all-details-alignment">
                        <div className="learn-from-artistsdetails-more-video-img-alignment">
                          <img src="/assets/img/artCarft/artLearnArtists/la3.png" alt="learn img" />
                        </div>
                        <div className="art-craft-learns-artists-all-child-details-alignment">
                          <span>Andy Sebastian</span>
                          <p>Five minute Craft : DIY Tutorial for Hand made</p>
                        </div>
                      </div>
                    </div>

                    <div className="learn-from-artistsdetails-more-video-gridIem-alignment">
                      <div className="learn-from-artistsdetails-more-video-all-details-alignment">
                        <div className="learn-from-artistsdetails-more-video-img-alignment">
                          <img src="/assets/img/artCarft/artLearnArtists/la2.png" alt="learn img" />
                        </div>
                        <div className="art-craft-learns-artists-all-child-details-alignment">
                          <span>Andy Sebastian</span>
                          <p>Five minute Craft : DIY Tutorial for Hand made</p>
                        </div>
                      </div>
                    </div>

                    <div className="learn-from-artistsdetails-more-video-gridIem-alignment">
                      <div className="learn-from-artistsdetails-more-video-all-details-alignment">
                        <div className="learn-from-artistsdetails-more-video-img-alignment">
                          <img src="/assets/img/artCarft/artLearnArtists/la4.png" alt="learn img" />
                        </div>
                        <div className="art-craft-learns-artists-all-child-details-alignment">
                          <span>Andy Sebastian</span>
                          <p>Five minute Craft : DIY Tutorial for Hand made</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="learn-from-artistsdetails-gridItem">
            <div className="learn-from-artistsdetails-second-part-alignment">
              <div className="learn-from-artistsdetails-modal-details-alignment">
                <div className="learn-from-artistsdetails-flex-alignment">
                  <PostHeader
                    isNormalPost={isNormalPost}
                    isGroupPost={isGroupPost}
                    isStorePost={isStorePost}
                    post={getSingleProduct}
                    store={getSingleProduct?.store !== null ? getSingleProduct?.store : {}}
                    //   setMediaModal={setMediaModal}
                    //   setOpenStoreModal={setOpenStoreModal}
                    //   editPost={editPost}
                  />
                </div>
                <div className="learn-from-artistsdetails-notes-alignment">
                  <p>
                    {getSingleProduct?.content?.length > 84
                      ? getSingleProduct?.content.substring(0, 84) + '...'
                      : getSingleProduct?.content}
                  </p>
                </div>
              </div>
              <PostReaction
                isNormalPost={isNormalPost}
                isGroupPost={isGroupPost}
                isStorePost={isStorePost}
                isSetClose={isSetClose}
                setShowMore={setShowMore}
                post={getSingleProduct}
                store={getSingleProduct?.store !== null ? getSingleProduct?.store : {}}
                group={getSingleProduct?.group !== null ? getSingleProduct?.group : {}}
                //   setCommentId={setCommentId}
                isPreview={true}
                //   setIsMobileComment={setIsMobileComment}
              />
              <div className="social-chat-details-section">
                <div className="social-chat-details-alignment">
                  <PostComments
                    comments={getSingleProduct?.comments}
                    setShowMore={setShowMore}
                    showMore={showMore}
                    postId={postId?.id}
                    backgroundColor="#FFFFFF"
                    commentId={commentId}
                    isProductComment={false}
                    isBottonBorderVisible={true}
                    isShowMore={true}
                  />
                </div>
              </div>
              {user && (
                <div className="post-footer flex items-center max-w-fit commentbox-padding">
                  <img
                    src={user?.logo_image ? user?.logo_image : '/assets/img/dummy-profile.svg'}
                    alt={user?.profileImage?.alternativeText}
                    className="rounded-full object-cover comment-image"
                  />
                  <InputWithEmoti
                    post={getSingleProduct}
                    type={'post'}
                    isPost={true}
                    store={getSingleProduct?.store}
                    isButton={false}
                  />
                </div>
              )}
            </div>
            <div className="learn-from-product-recommendation-alignment">
              <div className="learn-from-product-recommendation-heading">
                <h5>Product Recommendation</h5>
              </div>
              <div className="learn-from-product-hignt-bg-alignment">
                <div className="learn-from-product-hight-alignment">
                  {[0, 1, 2, 3, 4, 5, 6].map((_, index) => {
                    return (
                      <div className="learn-from-product-all-details-flex-alignment" key={index}>
                        <div className="learn-from-product-product-details-alignment">
                          <div className="learn-from-product-product-img-alignment">
                            <img src="/assets/img/artCarft/dry1.png" alt="DRYimg" />
                          </div>

                          <div className="learn-from-product-product-child-details-alignment">
                            <h6>Coloured paper: The UltimateThe UltimateThe UltimateThe UltimateThe Ultimate</h6>

                            <div className="learn-from-product-rate-alignment">
                              <span>4.8</span>
                              <div className="learn-from-product-starIcon-alignment">
                                <img src="/assets/icon/orange-star.svg" alt="StarIcon" />
                                <img src="/assets/icon/orange-star.svg" alt="StarIcon" />
                                <img src="/assets/icon/orange-star.svg" alt="StarIcon" />
                                <img src="/assets/icon/orange-star.svg" alt="StarIcon" />
                                <img src="/assets/icon/orange-star.svg" alt="StarIcon" />
                              </div>
                            </div>

                            <div className="diy-rrom-price-alignment">
                              <p>$24</p>
                            </div>
                          </div>
                        </div>
                        <div className="learn-from-product-other-option-alignment">
                          <img src="/assets/icon/bookmark.svg" alt="BookMarkIcon" />
                          <img src="/assets/icon/shopping-bag.svg" alt="ShoppingBagIcon" />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-from-artistasdetails-modal">
        <div className="mobile-view-art-craft-back-alignment">
          <div className="art-craft-back_details-alignment">
            <div>
              <img src="/assets/icon/left-icon.svg" alt="back-page" />
            </div>
            <p>Learn ...</p>
          </div>
          <div className="mobile-heading-alignment">
            <h4>How to make F...</h4>
          </div>
        </div>
        <div className="mobile-learn-from-artistasdetails-body">
          <div className="mobile-learn-from-artistsDetails-img-alignment">
            <img src="/assets/img/artCarft/artLearnArtists/la4.png" alt="learn img" />
          </div>

          <div className="mobile-view-video-player-option-alignment">
            <div className="mobile-video-player-range-time-alignment">
              <img src="/assets/img/artCarft/range-area.png" alt="video option" />

              <span>05:20/12:40</span>
            </div>
            <div className="mobile-video-player-video-other-option-alignment">
              <div>
                <img src="/assets/img/artCarft/videoFirstIcon.png" alt="video first" />
              </div>
              <div className="mobile-video-center-option-details-alignment">
                <div>
                  <img src="/assets/img/artCarft/video-player-left.png" alt="video first" />
                </div>
                <div>
                  <img src="/assets/img/artCarft/play.png" alt="video first" />
                </div>
                <div>
                  <img src="/assets/img/video-right-option.png" alt="video first" />
                </div>
              </div>
              <div>
                <img src="/assets/img/artCarft/maximize.png" alt="video first" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
