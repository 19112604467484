import React from 'react'
import styles from './ProductImageUploder.module.scss'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'
import { v4 as uuidv4 } from 'uuid'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'

export default function ProductImageUploder({ validation, setvalidation }) {
  const dispatch = useAppDispatch()
  const fetchProductImageResizeData = useSelector((state: any) => state?.productResizeData?.data)
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)

  const onDropEventHandler = (e) => {
    e.preventDefault()
    let previousFiles = fetchProductImageResizeData?.id?.media
    const files = []
    if (e.dataTransfer.items) {
      Array.from(e.dataTransfer.items).forEach((item: any) => {
        if (item.kind === 'file') {
          const file = item.getAsFile()
          files.push(file)
        }
      })
    }

    const uploadedFilesPromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.onerror = () => {
          reject(reader.error)
        }
        reader.readAsDataURL(file)
      })
    })

    Promise.all(uploadedFilesPromises)
      .then((base64Results) => {
        const uploadedFiles = base64Results.map((base64String, index) => {
          previousFiles = [
            ...previousFiles,
            {
              newfile: base64String,
              originalFile: files[index]?.type?.includes('video') ? files[index] : null,
              oldFile: {
                baseURL: '',
                zoom: 1,
                rotate: 0,
                crop: { x: 0, y: 0 },
              },
              ratio: fetchProductImageResizeData?.id?.ratio,
              isEdit: false,
              id: uuidv4(),
            },
          ]
        })
        dispatch(
          fetchProductImageResizeModal({
            modal: '',
            isOpen: false,
            id: {
              ...fetchProductImageResizeData?.id,
              prevImages: fetchProductImageResizeData?.id?.prevImages,
              prevVideos: fetchProductImageResizeData?.id?.prevVideos,
              media: previousFiles,
              ratio: fetchProductImageResizeData?.id?.ratio,
            },
          }),
        )
      })
      .catch((error) => {
        console.error('An error occurred while converting files to base64:', error)
      })
  }

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
  }

  const getCroppedImg = (imageSrc, cropWidth = 1080, cropHeight = 1620) => {
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.src = imageSrc
      image.onload = () => {
        const canvas = document.createElement('canvas')
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        const width = cropWidth * scaleX
        const height = cropHeight * scaleY

        canvas.width = cropWidth
        canvas.height = cropHeight
        const ctx = canvas.getContext('2d')
        const maxSize = Math.max(image.width, image.height)
        const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

        // set each dimensions to double largest dimension to allow for a safe area for the
        // image to rotate in without being clipped by canvas context
        canvas.width = safeArea
        canvas.height = safeArea

        // translate canvas context to a central location on image to allow rotating around the center.
        ctx.translate(safeArea / 2, safeArea / 2)
        ctx.rotate(getRadianAngle(0))
        ctx.translate(-safeArea / 2, -safeArea / 2)

        // draw rotated image and store data.
        ctx.fillStyle = '#DCDCDC'
        ctx.fillRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5)
        const data = ctx.getImageData(0, 0, safeArea, safeArea)

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = cropWidth
        canvas.height = cropHeight

        let startfrom = image.width / 2 > cropWidth ? image.width / 2 - cropWidth / 2 : 0

        // paste generated rotate image with correct offsets for x,y crop values.
        ctx.putImageData(
          data,
          Math.round(0 - safeArea / 2 + image.width * 0.5 - startfrom),
          Math.round(0 - safeArea / 2 + image.height * 0.5 - 0),
        )

        canvas.toBlob((blob) => {
          const reader = new FileReader()
          reader.onloadend = () => {
            const base64String = reader.result
            resolve(base64String)
          }
          reader?.readAsDataURL(blob)
        }, 'image/jpeg')
      }

      image.onerror = (error) => {
        reject(error)
      }
    })
  }

  const OnChangeHandler = async (e) => {
    setvalidation({ ...validation, images: '' })
    e.preventDefault()
    const files = e.target.files
    const images = Array.from(files).map((file: any) => file)
    let realImage = []
    try {
      const croppedImages = await Promise.all(
        images.map(async (image, index) => {
          const reader: any = new FileReader()
          reader.onloadend = () => {
            const base64String = reader.result
            realImage = [...realImage, base64String]
          }
          reader.readAsDataURL(image)
          const croppedImage: any = await getCroppedImg(
            URL.createObjectURL(image),
            fetchProductImageResizeData?.id?.selectedSize?.width,
            fetchProductImageResizeData?.id?.selectedSize?.height,
          )
          return croppedImage
        }),
      )
      let addOtherData = croppedImages?.map((img, index) => {
        return {
          newfile: realImage[index],
          originalFile: files[index]?.type?.includes('video') ? files[index] : null,
          oldFile: {
            baseURL: img,
            zoom: 1,
            rotate: 0,
            crop: { x: 0, y: 0 },
          },
          isEdit: false,
          ratio: fetchProductImageResizeData?.id?.ratio,
          id: uuidv4(),
        }
      })
      await dispatch(
        fetchProductImageResizeModal({
          modal: '',
          isOpen: false,
          id: {
            ...fetchProductImageResizeData?.id,
            prevImages: fetchProductImageResizeData?.id?.prevImages,
            prevVideos: fetchProductImageResizeData?.id?.prevVideos,
            media: [...fetchProductImageResizeData?.id?.media, addOtherData],
          },
        }),
      )

      await dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            productPreview: {
              ...addProductDetailSlice?.id?.productPreview,
              image: [...addProductDetailSlice?.id?.productPreview?.image, ...addOtherData],
            },
          },
        }),
      )
    } catch (error) {
      console.error('Error cropping images:', error)
    }
  }

  return (
    // className={classNames(styles.noDataBoxAlignment, validation['images'] ? styles.mediaError : '')}
    <div
      className={styles.noDataBoxAlignment}
      // onDragOver={(e) => {
      //   e.preventDefault()
      // }}
      // onDrop={(e) => onDropEventHandler(e)}
    >
      <div>
        <div className={styles.addIconsAlignment}>
          <img src="/assets/seller/icons/img-upload.svg" alt="ImgUploadIcon" />
          <img src="/assets/seller/icons/slaceImg.svg" alt="SlaceImg" />
          <img src="/assets/seller/icons/video-upload.svg" alt="VideoUploadIcon" />
        </div>
        <div className={styles.drapAndDropAlignment}>
          <p>
            Drag and Drop or <a>Browse</a>
          </p>
        </div>
      </div>
      <div className={styles.allowedImgTypeAlignment}>
        <p>.jpg, .png, .webp, .mp4,</p>
      </div>
      <input type="file" onChange={(e) => OnChangeHandler(e)} multiple={true} />
    </div>
  )
}
