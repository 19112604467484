import { gql } from '@apollo/client'

export default gql`
  query currentUser {
    currentUser {
      seller {
        profile_image
        phoneNumber
        isOnboardCompleted
        id
        firstName
        lastName
        email
        jobTitle
        aboutUs
        language
        currentQueryForVerification
        isSellerInfoVerified
        user_id
        isVerify
        sellerApprovedStatusNew
        country
        seller_business_detail {
          business_email
          business_phone_number
          business_address
          business_address_detail
          business_city
          business_state
          business_zip_code
          business_country
        }
        seller_detail {
          phoneNumber
          id
          name
          slug
          logo_image
          banner_image
          createdAt
          companyLegalName
          state
          state_name
          country
          postalCode
          streetAddress
          city
        }
        # user {
        #   id
        #   firstName
        #   lastName
        #   email
        #   confirmEmail
        #   password
        #   confirmPassword
        #   mobileNumber
        #   gender
        #   ext
        #   jobTitle
        #   userFor
        #   businessInformation {
        #     id
        #     logo_image
        #     logo_url
        #     title
        #   }
        #   logo_image
        #   banner_image
        #   seller_id
        #   isSellerInfoVerified
        #   isSeller
        #   isUser
        #   createdAt
        #   isFirstTime
        #   bday
        #   role
        #   address
        #   subAddress
        #   city
        #   state
        #   country
        #   zipCode
        #   phoneNumber
        #   cartCount
        #   becomeSellerStatus
        #   store {
        #     id
        #     name
        #     slug
        #     logo_image
        #     banner_image
        #     createdAt
        #     companyLegalName
        #     state
        #     state_name
        #     country
        #     postalCode
        #     streetAddress
        #     city
        #   }
        #   isOnboardCompleted
        #   isUserOnboardCompleted
        #   userName
        # }
      }
      user {
        id
        firstName
        lastName
        cartCount
        isOnboardCompleted
        email
        phoneNumber
        gender
        bday
        role
        isFirstTime
        jobTitle
        userName
        seller {
          profile_image
          phoneNumber
          isOnboardCompleted
          id
          firstName
          lastName
          email
          jobTitle
          aboutUs
          language
          currentQueryForVerification
          isSellerInfoVerified
          user_id
          isVerify
          sellerApprovedStatusNew
          country
          seller_business_detail {
            business_email
            business_phone_number
            business_address
            business_address_detail
            business_city
            business_state
            business_zip_code
            business_country
          }
          seller_detail {
            id
            phoneNumber
            name
            slug
            logo_image
            banner_image
            createdAt
            companyLegalName
            state
            state_name
            country
            postalCode
            streetAddress
            city
          }
          # user {
          #   id
          #   firstName
          #   lastName
          #   email
          #   confirmEmail
          #   password
          #   confirmPassword
          #   mobileNumber
          #   gender
          #   ext
          #   jobTitle
          #   userFor
          #   businessInformation {
          #     id
          #     logo_image
          #     logo_url
          #     title
          #   }
          #   logo_image
          #   banner_image
          #   seller_id
          #   isSellerInfoVerified
          #   isSeller
          #   isUser
          #   createdAt
          #   isFirstTime
          #   bday
          #   role
          #   address
          #   subAddress
          #   city
          #   state
          #   country
          #   zipCode
          #   phoneNumber
          #   cartCount
          #   becomeSellerStatus
          #   store {
          #     id
          #     name
          #     slug
          #     logo_image
          #     banner_image
          #     createdAt
          #     companyLegalName
          #     state
          #     state_name
          #     country
          #     postalCode
          #     streetAddress
          #     city
          #   }
          #   isOnboardCompleted
          #   isUserOnboardCompleted
          #   userName
          # }
        }
        store {
          id
          name
          slug
          logo_image
          banner_image
          createdAt
          companyLegalName
          state
          state_name
          country
          postalCode
          streetAddress
          city
          phoneNumber
        }
        logo_image
        banner_image
        becomeSellerStatus
        bio_detail {
          marital_status
          occupation
          education
          interest
          about
          experience
          hobbies
        }
        bday
        city
        state
        country
        zipCode
        address
        createdAt
        isUserOnboardCompleted
      }
    }
  }
`
