import React from 'react'
import NowTrendingBanner from '../../../../../public/assets/img/artCarft/now-trending-bg.png'
import NT1 from '../../../../../public/assets/img/artCarft/nt1.png'
import NT2 from '../../../../../public/assets/img/artCarft/nt2.png'
import NT3 from '../../../../../public/assets/img/artCarft/nt3.png'
import NT4 from '../../../../../public/assets/img/artCarft/nt4.png'
import starIcon from '../../../../../public/assets/icon/orange-star.svg'
import BootMarkIcon from '../../../../../public/assets/icon/bookmark-white.svg'
import MoreOptionIcon from '../../../../../public/assets/icon/more-option.svg'
export default function NowTrendingArtCarft() {
  return (
    <div className="art-craft-now-trending-store-details-alignment">
      <div className="mobile-view-art-craft-back-alignment">
        <div className="art-craft-back_details-alignment">
          <div>
            <img src="/assets/icon/left-icon.svg" alt="back-page" />
          </div>
          <p>Home...</p>
        </div>
        <div className="mobile-heading-alignment">
          <h4>Now Trending</h4>
        </div>
      </div>
      <div className="art-craft-now-trending-store-banner-alignment">
        <img src={NowTrendingBanner.src} alt="NowTrendingBanner" />
        <img src="/assets/img/artCarft/mobileviewNoeTrendingBanner.png" alt="MobileNowTrendingBanner" className="mobile-view-banner" />

        <div className="art-craft-now-trending-store-banner-details">
          <h4>Now Trending</h4>
          <p>Unleash your creativity with the hottest products in the art and craft world.</p>
        </div>
      </div>
      <div className="art-craft-now-trending-details-alignment">
        <div className="art-craft-now-trending-details-grid-alignment">
          <div className="art-craft-now-trending-details-gridItem-alignment">
            <div className="art-craft-now-trending-detailsimg-details-alignment">
              <img src={NT1.src} alt="NT1" />
              <div className="art-craft-now-trending-hover-details-alignment">
                <div className="bottom-img-texta-alignment">
                  <h5>The Elegant Grey Chair</h5>
                  <div className="bottom-price-alignment">
                    <div className="price-offer-details-alignment">
                      <span>$245</span>
                      <del>$ 425.00</del>
                    </div>
                    <div className="price-offer-alignment">
                      <span>40% OFF</span>
                    </div>
                  </div>
                  <div className="bottom-rate-alignment">
                    <div className="bottom-rate-star-alignment">
                      <span>4.8</span>
                      <div className="rate-star-alignment">
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                      </div>
                    </div>
                    <div className="rate-number-alignment">
                      <span>(437)</span>
                    </div>
                  </div>
                </div>
                <div className="top-img-details-alignment">
                  <div className="other-option-alignment-details">
                    <div className="hover-option-box-alignment">
                      <img src={BootMarkIcon.src} alt="BootMarkIcon" />
                    </div>
                    <div className="hover-option-box-alignment">
                      <img src={MoreOptionIcon.src} alt="MoreOptionIcon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="art-craft-now-trending-detailsimg-details-alignment">
              <img src={NT4.src} alt="NT1" />
              <div className="art-craft-now-trending-hover-details-alignment">
                <div className="bottom-img-texta-alignment">
                  <h5>The Elegant Grey Chair</h5>
                  <div className="bottom-price-alignment">
                    <div className="price-offer-details-alignment">
                      <span>$245</span>
                      <del>$ 425.00</del>
                    </div>
                    <div className="price-offer-alignment">
                      <span>40% OFF</span>
                    </div>
                  </div>
                  <div className="bottom-rate-alignment">
                    <div className="bottom-rate-star-alignment">
                      <span>4.8</span>
                      <div className="rate-star-alignment">
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                      </div>
                    </div>
                    <div className="rate-number-alignment">
                      <span>(437)</span>
                    </div>
                  </div>
                </div>
                <div className="top-img-details-alignment">
                  <div className="other-option-alignment-details">
                    <div className="hover-option-box-alignment">
                      <img src={BootMarkIcon.src} alt="BootMarkIcon" />
                    </div>
                    <div className="hover-option-box-alignment">
                      <img src={MoreOptionIcon.src} alt="MoreOptionIcon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="art-craft-now-trending-details-gridItem-alignment">
            <div className="art-craft-now-trending-detailsimg-details-alignment">
              <img src={NT2.src} alt="NT1" />
              <div className="art-craft-now-trending-hover-details-alignment">
                <div className="bottom-img-texta-alignment">
                  <h5>The Elegant Grey Chair</h5>
                  <div className="bottom-price-alignment">
                    <div className="price-offer-details-alignment">
                      <span>$245</span>
                      <del>$ 425.00</del>
                    </div>
                    <div className="price-offer-alignment">
                      <span>40% OFF</span>
                    </div>
                  </div>
                  <div className="bottom-rate-alignment">
                    <div className="bottom-rate-star-alignment">
                      <span>4.8</span>
                      <div className="rate-star-alignment">
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                      </div>
                    </div>
                    <div className="rate-number-alignment">
                      <span>(437)</span>
                    </div>
                  </div>
                </div>
                <div className="top-img-details-alignment">
                  <div className="other-option-alignment-details">
                    <div className="hover-option-box-alignment">
                      <img src={BootMarkIcon.src} alt="BootMarkIcon" />
                    </div>
                    <div className="hover-option-box-alignment">
                      <img src={MoreOptionIcon.src} alt="MoreOptionIcon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="art-craft-now-trending-detailsimg-details-alignment">
              <img src={NT3.src} alt="NT1" />
              <div className="art-craft-now-trending-hover-details-alignment">
                <div className="bottom-img-texta-alignment">
                  <h5>The Elegant Grey Chair</h5>
                  <div className="bottom-price-alignment">
                    <div className="price-offer-details-alignment">
                      <span>$245</span>
                      <del>$ 425.00</del>
                    </div>
                    <div className="price-offer-alignment">
                      <span>40% OFF</span>
                    </div>
                  </div>
                  <div className="bottom-rate-alignment">
                    <div className="bottom-rate-star-alignment">
                      <span>4.8</span>
                      <div className="rate-star-alignment">
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                      </div>
                    </div>
                    <div className="rate-number-alignment">
                      <span>(437)</span>
                    </div>
                  </div>
                </div>
                <div className="top-img-details-alignment">
                  <div className="other-option-alignment-details">
                    <div className="hover-option-box-alignment">
                      <img src={BootMarkIcon.src} alt="BootMarkIcon" />
                    </div>
                    <div className="hover-option-box-alignment">
                      <img src={MoreOptionIcon.src} alt="MoreOptionIcon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="art-craft-now-trending-details-gridItem-alignment">
            <div className="art-craft-now-trending-detailsimg-details-alignment">
              <img src={NT3.src} alt="NT1" />
              <div className="art-craft-now-trending-hover-details-alignment">
                <div className="bottom-img-texta-alignment">
                  <h5>The Elegant Grey Chair</h5>
                  <div className="bottom-price-alignment">
                    <div className="price-offer-details-alignment">
                      <span>$245</span>
                      <del>$ 425.00</del>
                    </div>
                    <div className="price-offer-alignment">
                      <span>40% OFF</span>
                    </div>
                  </div>
                  <div className="bottom-rate-alignment">
                    <div className="bottom-rate-star-alignment">
                      <span>4.8</span>
                      <div className="rate-star-alignment">
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                      </div>
                    </div>
                    <div className="rate-number-alignment">
                      <span>(437)</span>
                    </div>
                  </div>
                </div>
                <div className="top-img-details-alignment">
                  <div className="other-option-alignment-details">
                    <div className="hover-option-box-alignment">
                      <img src={BootMarkIcon.src} alt="BootMarkIcon" />
                    </div>
                    <div className="hover-option-box-alignment">
                      <img src={MoreOptionIcon.src} alt="MoreOptionIcon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="art-craft-now-trending-detailsimg-details-alignment">
              <img src={NT1.src} alt="NT1" />
              <div className="art-craft-now-trending-hover-details-alignment">
                <div className="bottom-img-texta-alignment">
                  <h5>The Elegant Grey Chair</h5>
                  <div className="bottom-price-alignment">
                    <div className="price-offer-details-alignment">
                      <span>$245</span>
                      <del>$ 425.00</del>
                    </div>
                    <div className="price-offer-alignment">
                      <span>40% OFF</span>
                    </div>
                  </div>
                  <div className="bottom-rate-alignment">
                    <div className="bottom-rate-star-alignment">
                      <span>4.8</span>
                      <div className="rate-star-alignment">
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                      </div>
                    </div>
                    <div className="rate-number-alignment">
                      <span>(437)</span>
                    </div>
                  </div>
                </div>
                <div className="top-img-details-alignment">
                  <div className="other-option-alignment-details">
                    <div className="hover-option-box-alignment">
                      <img src={BootMarkIcon.src} alt="BootMarkIcon" />
                    </div>
                    <div className="hover-option-box-alignment">
                      <img src={MoreOptionIcon.src} alt="MoreOptionIcon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="art-craft-now-trending-details-gridItem-alignment">
            <div className="art-craft-now-trending-detailsimg-details-alignment">
              <img src={NT4.src} alt="NT1" />
              <div className="art-craft-now-trending-hover-details-alignment">
                <div className="bottom-img-texta-alignment">
                  <h5>The Elegant Grey Chair</h5>
                  <div className="bottom-price-alignment">
                    <div className="price-offer-details-alignment">
                      <span>$245</span>
                      <del>$ 425.00</del>
                    </div>
                    <div className="price-offer-alignment">
                      <span>40% OFF</span>
                    </div>
                  </div>
                  <div className="bottom-rate-alignment">
                    <div className="bottom-rate-star-alignment">
                      <span>4.8</span>
                      <div className="rate-star-alignment">
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                      </div>
                    </div>
                    <div className="rate-number-alignment">
                      <span>(437)</span>
                    </div>
                  </div>
                </div>
                <div className="top-img-details-alignment">
                  <div className="other-option-alignment-details">
                    <div className="hover-option-box-alignment">
                      <img src={BootMarkIcon.src} alt="BootMarkIcon" />
                    </div>
                    <div className="hover-option-box-alignment">
                      <img src={MoreOptionIcon.src} alt="MoreOptionIcon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="art-craft-now-trending-detailsimg-details-alignment">
              <img src={NT2.src} alt="NT1" />
              <div className="art-craft-now-trending-hover-details-alignment">
                <div className="bottom-img-texta-alignment">
                  <h5>The Elegant Grey Chair</h5>
                  <div className="bottom-price-alignment">
                    <div className="price-offer-details-alignment">
                      <span>$245</span>
                      <del>$ 425.00</del>
                    </div>
                    <div className="price-offer-alignment">
                      <span>40% OFF</span>
                    </div>
                  </div>
                  <div className="bottom-rate-alignment">
                    <div className="bottom-rate-star-alignment">
                      <span>4.8</span>
                      <div className="rate-star-alignment">
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                        <img src={starIcon.src} alt="starIcon" />
                      </div>
                    </div>
                    <div className="rate-number-alignment">
                      <span>(437)</span>
                    </div>
                  </div>
                </div>
                <div className="top-img-details-alignment">
                  <div className="other-option-alignment-details">
                    <div className="hover-option-box-alignment">
                      <img src={BootMarkIcon.src} alt="BootMarkIcon" />
                    </div>
                    <div className="hover-option-box-alignment">
                      <img src={MoreOptionIcon.src} alt="MoreOptionIcon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
