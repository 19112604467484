import React, { useEffect, useRef, useState } from 'react'
import styles from './MarketplaceHeader.module.scss'
import classNames from 'classnames'
import Link from 'next/link'
import nookies from 'nookies'
import { useAuth } from 'src/utils/auth'
import { useRouter } from 'next/router'
const MarketplaceLogo = '/assets/img/bluejestic-new-logo.svg'
const MobileMenu = '/assets/img/mobile-menu.svg'

export default function MarketplaceHeader() {
  const [header, setHeader] = useState(false)
  const [menu, setMenu] = useState(false)
  const dropdownRef: any = useRef()
  const { updateUser }: any = useAuth()
  const router: any = useRouter()
  const { seller } = useAuth()

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (header && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setHeader(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [header])

  useEffect(() => {
    if (header) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [header])

  const hadleLogout = () => {
    nookies.set(undefined, 'token', '', {
      path: '/',
    })
    // nookies.set(undefined, 'role', '', {
    //   path: '/',
    // })
    updateUser(null)
    router.push('/marketplace')
  }

  return (
    <>
      <div className={styles.marketplaceHeader}>
        <div className="cus-container-md">
          <div className={styles.headerAlignment}>
            <div className={styles.logo}>
              <img src={MarketplaceLogo} alt="MarketplaceLogo" />
              <span>Marketplace Center</span>
            </div>
            <div className={styles.menu}>
              <a>
                <span>Home</span>
              </a>
              <a>
                <span>Launch</span>
                <div className={styles.dropdown}>
                  <div className={styles.spacerAlignment}>
                    <div className={styles.launchHeader}>
                      <p>Launch</p>
                    </div>
                    <div className={styles.bodyAlignment}>
                      <Link href="/marketplace/getstarted">
                        <div className={styles.boxdesign}>
                          <p>Get Started</p>
                          <h6>From Idea to Empire: Your E-commerce Starts Here</h6>
                        </div>
                      </Link>
                      <div className={styles.boxdesign}>
                        <p>Pricing</p>
                        <h6>E-commerce Fees that Fuel Your Business</h6>
                      </div>
                      <Link href="/marketplace/shipping-management">
                        <div className={styles.boxdesign}>
                          <p>Shipping Management</p>
                          <h6>Marketplace Shipping Solutions for Growth</h6>
                        </div>
                      </Link>
                      <div className={styles.boxdesign}>
                        <p>Seller Portal</p>
                        <h6>Connect, Sell, and Grow in Our Marketplace</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <a>
                <span>Blog</span>
              </a>
            </div>
            <div className={styles.buttonAlignment}>
              {seller ? (
                <p>
                  Hi {seller?.firstName}, <span onClick={hadleLogout}> {`   logout`}</span>
                </p>
              ) : (
                <Link href="/marketplace/signin">
                  <a>Login</a>
                </Link>
              )}
              <Link href="/marketplace/application">
                <button>Join Bluejestic</button>
              </Link>
            </div>
            <div className={styles.mobilemenu} onClick={() => setHeader(!header)}>
              <img src={MobileMenu} alt="MobileMenu" />
            </div>
          </div>
        </div>
      </div>
      {header && <div className={styles.headerBlur}></div>}
      <div className={classNames(styles.mobileheader, header ? styles.show : styles.hide)} ref={dropdownRef}>
        <div className={styles.menudesign}>
          <span>Home</span>
        </div>
        <div className={styles.menudesign}>
          <span onClick={() => setMenu(!menu)}>Launch</span>
          <div className={classNames(styles.childMenu, menu ? styles.show : styles.hide)}>
            <Link href="/marketplace/getstarted">
              <div className={styles.childboxDesign}>
                <p>Get Started</p>
                <h6>From Idea to Empire: Your E-commerce Starts Here</h6>
              </div>
            </Link>
            <div className={styles.childboxDesign}>
              <p>Pricing</p>
              <h6>E-commerce Fees that Fuel Your Business</h6>
            </div>
            <Link href="/marketplace/shipping-management">
              <div className={styles.childboxDesign}>
                <p>Shipping Management</p>
                <h6>Marketplace Shipping Solution for Growth</h6>
              </div>
            </Link>
            <div className={styles.childboxDesign}>
              <p>Seller Portal</p>
              <h6>Connect, Sell, and Grow in Our Marketplace</h6>
            </div>
          </div>
        </div>
        <div className={styles.menudesign}>
          <span>Blog</span>
        </div>
        <div className={styles.buttonalignmentMobile}>
          <Link href={'/marketplace/signin'}>Login</Link>

          <Link href="/marketplace/application">
            <button>Join Bluejestic</button>
          </Link>
        </div>
      </div>
    </>
  )
}
