import { gql } from '@apollo/client'

const GET_GLOBAL_SEARCH_WITH_ELASTIC_SEARCH = gql`
  query searchProductsWithElasticSearch($search: String, $limit: Int, $page: Int, $minPrice: Int, $maxPrice: Int) {
    searchProductsWithElasticSearch(
      search: $search
      limit: $limit
      page: $page
      minPrice: $minPrice
      maxPrice: $maxPrice
    ) {
      success
      message
      data {
        products {
          id
          title
          dis_price
          dis_listPrice
          like_count
          comment_count
          images
          slug
        }
        total
      }
    }
  }
`
export default GET_GLOBAL_SEARCH_WITH_ELASTIC_SEARCH
// $limit: Int, $page: Int
//  limit: $limit, page: $page
