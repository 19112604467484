import React from 'react'
import Hearticon from '../../../../../../public/assets/icon/heart-white.svg'
import ShareIcon from '../../../../../../public/assets/icon/share-white.svg'
import BootMark from '../../../../../../public/assets/icon/bookmark-white.svg'
import BookBannerImg from '../../../../../../public/assets/img/bookCover.png'
import PerHearticon from '../../../../../../public/assets/icon/pr-heart.svg'
import PerCommentIcon from '../../../../../../public/assets/icon/pr-comment.svg'
import PerShareIcon from '../../../../../../public/assets/icon/pr-share.svg'
import star from '../../../../../../public/assets/img/WomansCategory/star.png'
import aroww from '../../../../../../public/assets/img/WomansCategory/aroww.png'

export default function BookDetailView() {
  return (
    <div>
      <div className="book-details-modal-bg-alignment">
        <div className="book-banner-option-alignment">
          <div>
            <img src={Hearticon.src} alt="Hearticon" />
          </div>
          <div>
            <img src={ShareIcon.src} alt="ShareIcon" />
          </div>
          <div>
            <img src={BootMark.src} alt="BootMark" />
          </div>
        </div>
      </div>
      <div className="book-details-profile-alignment">
        <div className="book-main-details-section">
          <div className="book-img-alignment">
            <img src={BookBannerImg.src} alt="BookBannerImg" />
          </div>
          <div className="book-main-all-details-alignment">
            <h4>Billy Summers</h4>
            <p>
              by <span>Stephen King</span>
            </p>
            <div className="book-all-details-number-alignment">
              <div className="book-child-details-alignment">
                <div>
                  <img src={PerHearticon.src} alt="PerHearticon" />
                </div>
                <p>275 likes</p>
              </div>
              <div className="book-child-details-alignment">
                <div>
                  <img src={PerCommentIcon.src} alt="PerCommentIcon" />
                </div>
                <p>323 shares</p>
              </div>
              <div className="book-child-details-alignment">
                <div>
                  <img src={PerShareIcon.src} alt="PerShareIcon" />
                </div>
                <p>14k comments </p>
              </div>
            </div>
            <div className="book-rate-alignment">
              <p className="hike">
                <span className="incris">
                  <img src={aroww.src} alt="incris" /> 200%
                </span>
              </p>
              <div className="rating">
                <span className="rating_point">4.8</span>
                <div className="stars">
                  <img src={star.src} alt="star" />
                </div>
                <span className="num">(437)</span>
              </div>
            </div>
            <div className="book-all-notes-alignment">
              <p>
                Chances are, if you’re a target of Billy Summers, two immutable truths apply: You’ll never even know
                what hit you, and you’re really getting what you deserve. He’s a killer for hire and the best in the
                business—but he’ll do the job only if the assignment is a truly bad person. But now, ti...
              </p>
            </div>
            <div className="stock-details-alignment">
              <span>Stock</span>
              <p>120 pcs</p>
            </div>
            <div className="book-price-alignment">
              <h6>$15.00</h6>
              <span>
                <del>$20.00</del>
              </span>
              <p>(25% OFF)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
