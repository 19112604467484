import React, { useEffect, useState } from 'react'
import { City, Country, State } from 'country-state-city'
import Select from 'react-select'
import 'react-country-state-city/dist/react-country-state-city.css'

interface CountryProps {
  countryId: string
  stateId: null | string
  cityId: null | string
  state: string | null
}
function SellerCountryComponent({
  setErrors,
  countryIds,
  setCountryIds,
  errors,
}: {
  setErrors: any
  countryIds: CountryProps
  setCountryIds: any
  errors: any
}) {
  const [cities, setCities] = useState([])
  const [states, setStates] = useState([])
  const countries = Country.getAllCountries().map((country) => ({
    value: country.isoCode,
    label: country.name,
  }))

  // useEffect(() => {
  //   if (countryIds.countryId !== null) {
  //     const fetchedStates = State.getStatesOfCountry(countryIds.countryId).map((state) => ({
  //       value: state.isoCode,
  //       label: state.name,
  //     }))
  //     setStates(fetchedStates) // Update states list
  //   }

  //   if (countryIds.stateId !== null) {
  //     const fetchedCities = City.getCitiesOfState(countryIds.countryId, countryIds.stateId).map((city) => ({
  //       value: city.name,
  //       label: city.name,
  //     }))
  //     setCities(fetchedCities)
  //   }
  // }, [countryIds])
  const handleCountryChange = (option) => {
    const country = option?.value || null
    setCountryIds((prev) => ({ ...prev, countryId: country ,country:option?.label }))
    setErrors((prev) => ({ ...prev, country: '' }))

    if (option?.value) {
      const fetchedStates = State.getStatesOfCountry(option.value).map((state) => ({
        value: state.isoCode,
        label: state.name,
      }))
      setStates(fetchedStates)
    } else {
      setStates([])
    }
    setCountryIds((prev) => ({ ...prev, stateId: null, cityId: null }))
    setErrors((prev) => ({ ...prev, stateId: '', cityId: '' }))
    setCities([])
  }

  const handleStateChange = (option) => {
    setCountryIds((prev) => ({ ...prev, stateId: option?.value || null, state: option?.label || null }))
    setErrors((prev) => ({ ...prev, stateId: '', state: '' }))
    setCities([]) // Clear old cities immediately

    const fetchedCities = City.getCitiesOfState(countryIds.countryId, option?.value).map((city) => ({
      value: city.name,
      label: city.name,
    }))
    setCities(fetchedCities)
    setCountryIds((prev) => ({ ...prev, cityId: null }))
    setErrors((prev) => ({ ...prev, cityId: '' }))
  }

  return (
    <div className="my-4 flex justify-between space-x-4 country-state-city-inputs-main">
      <div className="border-gray-200 w-1/3">
        <label>
          Country <span className="text-red-600">*</span>{' '}
          {errors.country && <span className="text-sm text-red-600">{errors.country}</span>}
        </label>
        <Select
          options={countries}
          onChange={handleCountryChange}
          value={countries?.find((option) => option.value === countryIds?.countryId)}
        />
      </div>
      <div className="border-gray-200 w-1/3">
        <label>
          State <span className="text-red-600">*</span>{' '}
          {errors.state && <span className="text-sm text-red-600">{errors.state}</span>}
        </label>
        <Select
          options={states}
          onChange={(option) => handleStateChange(option)}
          value={(() => {
            const selectedState = states.find((option) => option.value == countryIds?.stateId)
            return selectedState == undefined ? null : selectedState
          })()}
          isDisabled={!countryIds.countryId}
        />
      </div>
      <div className="border-gray-200 w-1/3">
        <label>
          City <span className="text-red-600">*</span>{' '}
          {errors.city && <span className="text-sm text-red-600">{errors.city}</span>}
        </label>
        <Select
          options={cities}
          onChange={(option) => {
            setCountryIds((prev) => ({ ...prev, cityId: option?.value || null }))
            setErrors((prev) => ({ ...prev, city: '' }))
          }}
          value={(() => {
            const selectedState = cities.find((option) => option.value === countryIds?.cityId)
            return selectedState == undefined ? null : selectedState
          })()}
          isDisabled={!countryIds.stateId} // Disable if no state is selected
        />
      </div>
    </div>
  )
}

export default SellerCountryComponent
