import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import GET_ALL_PRODUCT_FOR_CHECKOUT from 'src/graphql/queries/getAllProductForCheckout'
import { useMutation } from '@apollo/client'
import { notification } from 'antd'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'

export default function CartFooter({ productIdArray }) {
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  const [getAllProductForCheckout] = useMutation(GET_ALL_PRODUCT_FOR_CHECKOUT, {
    onCompleted: () => {},
    onError: (error) => {
      notification['error']({
        message: 'Error',
        description: 'Sorry, something went wrong',
      })
    },
  })

  const moveToCheckout = () => {
    let productId = productIdArray.map((pro) => {
      return {
        variant_id: pro?.variant_id,
        quantity: pro?.quantity,
        product_id: pro?.productId,
      }
    })

    getAllProductForCheckout({
      variables: {
        products: productId,
      },
    }).then((refetch) => {
      if (refetch.data !== undefined) {
        dispatch(
          fetchModalData({
            allModalData: [
              ...getAllState?.allModalData,
              { modal: 'checkout', isOpen: true, id: refetch, modalFor: 'bigModal' },
            ],
            lastModalData: getAllState?.lastModalData,
          }),
        )
      }
    })
  }

  return (
    <div className="cart-item-detail">
      <div className="product-item-alignment">
        <div className="processed-to-checkout-section">
          <div className="selected-item-title">
            <span className="product-count">
              Selected Item:{' '}
              <span>
                {productIdArray?.length}
                {/* {ProductData.map((product) => product?.qty).reduce((prev, curr) => prev + curr, 0)} */}
              </span>
            </span>
          </div>
          <div className="selected-item-contain">
            <div className="selected-item-title">
              <span className="product-count">
                Subtotal:
                <span>
                  ${productIdArray.map((product) => product?.totalPrice).reduce((prev, curr) => prev + curr, 0)}
                </span>
              </span>
            </div>
            {productIdArray.length > 0 ? (
              <div className="button-alignment-section" onClick={() => moveToCheckout()}>
                <button>Processed to Checkout</button>
              </div>
            ) : (
              <div className="button-alignment-section button-opa">
                <button>Processed to Checkout</button>
              </div>
            )}
          </div>
        </div>

        <div className="mobile-processed-to-checkout-section">
          <div className="mobile-processed-details-flex-alignment">
            <div>
              <div className="mobile-process-child-details-alignment">
                <span>Selected Item: </span>
                <p>{productIdArray?.length ? productIdArray?.length : 0 }</p>
              </div>
              <div className="mobile-process-child-details-alignment">
                <span className="sec">Subtotal: </span>
                <p className="sec">$ {productIdArray.map((product) => product?.totalPrice).reduce((prev, curr) => prev + curr, 0)}</p>
              </div>
            </div>
            <div className="button-alignment-section" onClick={() => moveToCheckout()}>
              <button>Processed to Checkout</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
