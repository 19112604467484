import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import GET_GROUPS_ONBOARDING from 'src/graphql/queries/getAllgroupsOnboarding'
import GET_SINGLE_ORDER from 'src/graphql/queries/getSingleOrder'
import client1 from 'src/utils/apolloClient'

interface fetchOrderDetail {
  loading: true | false
  error: {} | null
  called: true | false
  getSingleDetail: []
}

const initialState = {
  loading: false,
  error: null,
  called: false,
  getSingleDetail: [],
} as fetchOrderDetail

export const fetchSingleOrderDetail = createAsyncThunk('fetchClubs/fetchSingleOrderDetail', async (post: any) => {
  const { data } = await client1.query({
    query: GET_SINGLE_ORDER,
    variables: {
      orderMasterId: post?.order_master_id,
      order_item_id: post?.order_item_id
    },
    fetchPolicy: 'network-only',
  })
  return data?.getSingleOrder?.data
})

const fetchOrderDetail = createSlice({
  name: 'fetchClubs',
  initialState,
  reducers: {
    setJoinedClub: (state: any, action) => {
      state.joinedClubs = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSingleOrderDetail.pending, (state, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchSingleOrderDetail.fulfilled, (state, action) => {
      state.loading = 'success'
      state.getSingleDetail = action?.payload
    })
    builder.addCase(fetchSingleOrderDetail.rejected, (state, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })
  },
})

export default fetchOrderDetail.reducer
