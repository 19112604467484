import { gql } from '@apollo/client'

const query = gql`
mutation DELETECARTITEM($deleteCartItemsId: [Int!]) {
  deleteCartItems(id: $deleteCartItemsId) {
    message
  }
}
`
export default query
