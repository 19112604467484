import { useMutation } from '@apollo/client'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { REMOVE_GROUP_USER } from 'src/graphql/mutations/mutations'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useAuth } from 'src/utils/auth'
import { useStateContext } from 'src/utils/state'
import client1 from 'src/utils/apolloClient'


export default function GroupMessageSidebar({ handleOpen }) {
  const dispatch = useAppDispatch()
  const productRef: any = useRef()
  const { user } = useAuth()
  const { selectedChat, notify, updateName } = useStateContext()
  const [ChatMemberModal, setChatMemberModal] = useState(false)

  let checkAdmin = selectedChat?.chatData?.users?.find((u) => u?.id === user?.id)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  const [removeUserfromGroup, { loading: updatingName }] = useMutation(REMOVE_GROUP_USER, {

    client: client1,
    onError: (err) => {
      alert(err)
    },
  })

  const handleOpenGroupSidebar = () => {
    if (getAllState?.lastModalData?.modal === 'messageModal') {
      dispatch(
        fetchModalData({
          allModalData: [
            ...getAllState?.allModalData,
            {
              modal: 'messageModal',
              isOpen: true,
              id: {
                width:
                  getAllState?.lastModalData?.id?.width === 70
                    ? 90
                    : getAllState?.lastModalData?.id?.width === 90 && 70,
              },
              modalFor: 'bigModal',
            },
          ],
          lastModalData: getAllState?.lastModalData,
        }),
      )
    }
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (ChatMemberModal && productRef.current && !productRef.current.contains(e.target)) {
        setChatMemberModal(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [ChatMemberModal])

  const handleAddAdmin = () => { }

  const handleRestrictMember = (user) => {
    removeUserfromGroup({
      variables: { conversationId: selectedChat?.chatData?.id, userId: user?.id },
    })
  }

  function handleClose() {
    setChatMemberModal(false)
  }

  const adminUsers = selectedChat?.chatData?.adminUser?.map((admin) => ({ ...admin, isAdmin: true }))
  let invitedUsers = Boolean(selectedChat?.chatData?.invitedUsers?.length) ? selectedChat?.chatData?.invitedUsers : []
  const chatUser = [...selectedChat?.chatData?.users].filter((user) => !invitedUsers?.find((inv) => inv?.id === user?.id)).filter((user) => !adminUsers?.find((admin) => admin?.id === user?.id));

  return (
    <div
      className={`group-message-sidebar-section ${getAllState?.lastModalData?.id?.width === 90 && 'group-member-sidebar-open-alignment'
        }`}
    >
      <div className="group-message-sidebar-alignment">
        <div className="group-message-arrow-alignment">
          <div onClick={() => handleOpenGroupSidebar()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path
                d="M11.25 4.5L6.75 9L11.25 13.5"
                stroke="#636773"
                strokeWidth="1.125"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="member-heading-alignment">
            <h4>Member</h4>
            <div className="message-number-alignment">
              <span>{[...adminUsers, ...selectedChat?.chatData?.users]?.length}</span>
            </div>
          </div>
        </div>
        <div
          className="group-message-groups-details-alignment"
          style={selectedChat?.chatType === 'invite' ? { padding: 0 } : {}}
        >
          {selectedChat?.chatType !== 'invite' && (
            <div
              className="group-message-groups-details-add-user-flex-alignment cursor-pointer"
              onClick={() => handleOpen(true)}
            >
              <div className="group-message-groups-details-add-user-box-alignment">
                <img src="/assets/icon/user-plus-blue.svg" alt="user icon" />
              </div>
              <p>Invite</p>
            </div>
          )}
          <div className="group-message-groups-user-details-section">
            {selectedChat
              ? [...adminUsers, ...chatUser, ...invitedUsers]?.map((us, index) => {
                return (
                  <div
                    className="group-message-groups-user-flex-alignment"
                    key={index}
                  // onMouseEnter={handleClose}
                  >
                    <div className="group-message-group-user-profile-img-alignment">
                      <div className="group-message-profile-width-alignment">
                        <img
                          src={us?.profileAvtar?.length > 0 ? us?.profileAvtar : '/assets/img/user/blank-user.svg'}
                          alt="member img"
                          style={us?.isInvitedUser ? { opacity: 0.5 } : {}}
                        />
                      </div>
                      <h6 style={us?.isInvitedUser ? { opacity: 0.5 } : {}}>{us?.firstName + ' ' + us?.lastName}</h6>
                      {us?.isAdmin && (
                        <div className="admin-box-alignment">
                          <p>Admin</p>
                        </div>
                      )}
                    </div>
                    {!us?.isInvitedUser && (
                      <div className="more-option-alignmnet">
                        <div
                          onClick={(e) => {
                            setChatMemberModal(!ChatMemberModal)
                          }}
                        >
                          <img src="/assets/icon/more-horizontal.svg" alt="more icon" />
                        </div>
                        {/* {ChatMemberModal && (
                            <div className="more-option-dropdown-position" ref={productRef}>
                              <div className="more-option-dropdown-alignment">
                                <ul>
                                  {checkAdmin && <li onClick={handleAddAdmin}>Add as Admin</li>}
                                  {checkAdmin && <li>Restrict Member</li>}
                                  <li
                                    // onClick={() => handleRestrictMember(us)}
                                    className="remove-group-alignment"
                                  >
                                    Remove from Group
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )} */}
                      </div>
                    )}
                  </div>
                )
              })
              : ''}
          </div>
        </div>
      </div>
    </div>
  )
}
