import React, { useRef, useState } from 'react'
import styles from './CreateClubProfile.module.scss'
import { useSelector } from 'react-redux'

export default function CreateClubProfile(props) {
  const { inputValue, setInputValue, errors, setErrors } = props
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const coverImageInputField = useRef(null)
  const bannerImageInputField = useRef(null)

  const handleCoverImageUploadBtnClick = () => {
    coverImageInputField.current.click()
  }

  const handleBannerImageUploadBtnClick = () => {
    bannerImageInputField.current.click()
  }

  const handelOnChange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.files[0] })
  }

  return (
    <div className={styles.createClubProfileSection}>
      <div className={styles.createClubProfileDetails}>
        <div className={styles.createClubProfileBox}>
          <div className={styles.createClubProfileCoverBox}>
            {inputValue?.banner_image ? (
              <img
                src={
                  getAllState?.groupCreationModal?.id?.isEdit
                    ? !inputValue?.banner_image
                      ? null
                      : typeof inputValue?.banner_image === 'string'
                      ? inputValue?.banner_image
                      : URL.createObjectURL(inputValue?.banner_image)
                    : !inputValue?.banner_image
                    ? null
                    : typeof inputValue?.banner_image === 'string' ? inputValue?.banner_image : URL.createObjectURL(inputValue?.banner_image)
                }
                // src={getAllState?.groupCreationModal?.id?.isEdit ? }
                style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              />
            ) : (
              <p>No cover image</p>
            )}
          </div>

          <div className={styles.createClubProfileOtherDetails}>
            <div className={styles.createClubProfileLeftSideAlignment}>
              <div className={styles.createClubProfileImg}>
                {inputValue?.logo_image ? (
                  <img
                    src={
                      getAllState?.groupCreationModal?.id?.isEdit
                        ? !inputValue?.logo_image
                          ? null
                          : typeof inputValue?.logo_image === 'string'
                          ? inputValue?.logo_image
                          : URL.createObjectURL(inputValue?.logo_image)
                        : !inputValue?.logo_image
                        ? null
                        : typeof inputValue?.logo_image === 'string' ? inputValue?.logo_image : URL.createObjectURL(inputValue?.logo_image)
                    }
                    style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                  />
                ) : (
                  <p>No image</p>
                )}
              </div>
              <div className={styles.createClubLeftBox}></div>
            </div>

            <div className={styles.createClubProfileRightSideAlignment}>
              <div className={styles.createClubSmallBox}></div>
              <div className={styles.createClubSmallBox}></div>
              <div className={styles.createClubSmallBox}></div>
              <div className={styles.createClubBigBox}></div>
            </div>
          </div>
        </div>

        <div className={styles.createClubImgUplodedAlignment}>
          <div className={styles.createClubImgUplodedFlexAlignment}>
            <div>
              <div className={styles.uplodedImgBoxAlignment} onClick={handleCoverImageUploadBtnClick}>
                <img src="/assets/icon/cloud-icon.svg" alt="cloud icon" />
                <p>Club Profile Image</p>
                <input
                  name="logo_image"
                  type="file"
                  className="hidden"
                  ref={coverImageInputField}
                  onChange={(e) => handelOnChange(e)}
                />
              </div>
              {!inputValue?.logo_image && <span style={{ color: 'red' }}>{errors['logo_image']}</span>}
            </div>
            <div>
              <div className={styles.uplodedImgBoxAlignment} onClick={handleBannerImageUploadBtnClick}>
                <img src="/assets/icon/cloud-icon.svg" alt="cloud icon" />
                <p>Club Banner Image</p>
                <input
                  name="banner_image"
                  type="file"
                  className="hidden"
                  ref={bannerImageInputField}
                  onChange={(e) => handelOnChange(e)}
                />
              </div>
              {!inputValue?.banner_image && <span style={{ color: 'red' }}>{errors['banner_image']}</span>}
            </div>
          </div>

          {/* <div className={styles.removeClubImgUplodedFlexAlignment}>
            <div className={styles.removeImgBoxAlignment}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M2.25 4.5H3.75H15.75"
                  stroke="#E71616"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.25 4.50049V15.0005C14.25 15.3983 14.092 15.7798 13.8107 16.0611C13.5294 16.3425 13.1478 16.5005 12.75 16.5005H5.25C4.85218 16.5005 4.47064 16.3425 4.18934 16.0611C3.90804 15.7798 3.75 15.3983 3.75 15.0005V4.50049M6 4.50049V3.00049C6 2.60266 6.15804 2.22113 6.43934 1.93983C6.72064 1.65852 7.10218 1.50049 7.5 1.50049H10.5C10.8978 1.50049 11.2794 1.65852 11.5607 1.93983C11.842 2.22113 12 2.60266 12 3.00049V4.50049"
                  stroke="#E71616"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.49805 8.24951V12.7495"
                  stroke="#E71616"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.502 8.24951V12.7495"
                  stroke="#E71616"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p>Remove Profile Image</p>
            </div>

            <div className={styles.removeImgBoxAlignment}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M2.25 4.5H3.75H15.75"
                  stroke="#E71616"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.25 4.50049V15.0005C14.25 15.3983 14.092 15.7798 13.8107 16.0611C13.5294 16.3425 13.1478 16.5005 12.75 16.5005H5.25C4.85218 16.5005 4.47064 16.3425 4.18934 16.0611C3.90804 15.7798 3.75 15.3983 3.75 15.0005V4.50049M6 4.50049V3.00049C6 2.60266 6.15804 2.22113 6.43934 1.93983C6.72064 1.65852 7.10218 1.50049 7.5 1.50049H10.5C10.8978 1.50049 11.2794 1.65852 11.5607 1.93983C11.842 2.22113 12 2.60266 12 3.00049V4.50049"
                  stroke="#E71616"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.49805 8.24951V12.7495"
                  stroke="#E71616"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.502 8.24951V12.7495"
                  stroke="#E71616"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p>Remove Banner Image</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
