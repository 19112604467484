// @ts-nocheck

import moment from 'moment'
import React, { useState } from 'react'
import StarRatings from 'react-star-ratings'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAppDispatch } from 'src/store/store'
import { useAuth } from 'src/utils/auth'

export default function RateAndReviewDetails({ movetoImageModal, ratingData }) {

  const { user }: any = useAuth()
  const dispatch = useAppDispatch()
  const [rating, setRating] = useState(0); // Initialize rating state with 0
  const isMobile = window.innerWidth < 600

  // Calculate the overall average rating
  const overallAverageRating = ratingData.length
    ? ratingData.reduce((acc, curr) => acc + curr.overAllRating, 0) / ratingData.length
    : 0;

  // Calculate the percentage of each star rating
  const totalRatings = ratingData.length;
  const starCounts = [5, 4, 3, 2, 1].map(star => ({
    star,
    count: ratingData.filter(rate => rate.overAllRating === star).length,
    percentage: totalRatings ? (ratingData.filter(rate => rate.overAllRating === star).length / totalRatings) * 100 : 0
  }));

  const handleRating = (rate: number) => {
    setRating(rate)
    if (user) {
    } else {
      handleRedirect()
    }
  }

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  return (
    <>
      <div className="review-count-box">
        <div>
          <div className="modal-all-review-notes-alignment">
            <p>Ultimate Black Sporty Sneaker - Limited Edition</p>
          </div>
          <div className="ratting-count-align ratting-all-modal-bg">
            <div className="rating-main-count ratting-all-star-modal-bg ">
              <span className="">{ratingData?.length}</span>
            </div>
            <div className="total-rating-count">
              <p className="">({ratingData?.length})</p>
            </div>
          </div>

          <div className="mobile-view-star-rating-alignment ratting-all-star-modal-bg">
            <StarRatings
              svgIconViewBox='0 0 23 23"'
              rating={overallAverageRating} // Pass the overall average rating
              starRatedColor="#FF7D23"
              changeRating={handleRating}
              starEmptyColor="#E4E4E8"
              numberOfStars={5}
              svgIconPath="M10.0201 4.11979C10.6318 2.89633 12.3778 2.89633 12.9895 4.11979L14.5575 7.2558C14.8057 7.75222 15.2856 8.09174 15.8363 8.16058L18.7174 8.52072C20.0449 8.68665 20.6408 10.2742 19.7504 11.2727L17.6475 13.6308C17.312 14.007 17.1656 14.5151 17.2496 15.0121L17.8603 18.6274C18.0866 19.9668 16.6961 20.9961 15.4812 20.3886L12.2471 18.7716C11.7798 18.5379 11.2298 18.5379 10.7624 18.7716L7.53366 20.386C6.31774 20.9939 4.92656 19.9625 5.15496 18.6224L5.76986 15.0146C5.8548 14.5162 5.708 14.0063 5.37106 13.6294L3.26509 11.2736C2.37291 10.2755 2.9684 8.68619 4.29675 8.52015L7.17327 8.16058C7.72399 8.09174 8.20387 7.75222 8.45208 7.2558L10.0201 4.11979Z"
              starDimension="26px"
              starSpacing="1px"
              name="rating"
            />
          </div>
          <div className="rate-review-all-details-alignment second-rate-review-all-details-modal">
            {starCounts.map(({ star, percentage }, index) => (
              <div className="rate-review-all-child-details" key={index}>
                <div className="rate-review-left-side-alignment">
                  <div className="rate-star-number-flex">
                    <p>{star}</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                      <path
                        d="M5.07245 0.855107C5.45462 0.0907602 6.54538 0.0907615 6.92755 0.855108L8.08513 3.17026C8.24019 3.48039 8.53999 3.6925 8.88405 3.73551L11.04 4.00499C11.8693 4.10866 12.2416 5.10048 11.6853 5.72424L10.1271 7.47156C9.91753 7.70656 9.82608 8.02404 9.87853 8.33451L10.3265 10.9865C10.4679 11.8232 9.59921 12.4663 8.8402 12.0868L6.46378 10.8986C6.17182 10.7526 5.82818 10.7526 5.53622 10.8986L3.16312 12.0851C2.40348 12.4649 1.53436 11.8205 1.67705 10.9833L2.12824 8.33607C2.18131 8.02472 2.08959 7.70615 1.8791 7.47068L0.318393 5.72481C-0.23899 5.1013 0.133043 4.10837 0.962914 4.00464L3.11595 3.73551C3.46001 3.6925 3.75981 3.48039 3.91487 3.17025L5.07245 0.855107Z"
                        fill="#FF7D23"
                      />
                    </svg>
                  </div>
                  <div className="rate-average-area-alignment">
                    <div className='rate-average-area-alignment-fill' style={{ width: `${percentage.toFixed(0)}%` }}></div>
                  </div>
                </div>
                <div className="rate-review-right-side-alignment">
                  <p>{percentage.toFixed(0)}%</p>
                </div>
              </div>
            ))}
          </div>
          <div className="mobile-view-rate-and-review-all-img second-all-media-alignment">
            {ratingData.slice(0, 4).map((rate, index) => {
              const mediaUrl = rate.userReviewsOnProductsMedias[0]?.url;
              return (
                <div className="mobile-view-review-img" key={index}>
                  <img src={mediaUrl || '/assets/img/demo-img-2.png'} alt="review-img" />
                </div>
              );
            })}

            {ratingData.length > 4 && (
              <div className="mobile-view-review-img last-show-img">
                <img src={ratingData[4].userReviewsOnProductsMedias[0]?.url || '/assets/img/demo-img-2.png'} alt="review-img" />
                <div className="mobile-view-total-number">
                  <p>{ratingData.length - 4} More</p>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <div className="image-section-align" onClick={() => movetoImageModalMobile()}>
      <div className="image-contanier-align">
        {[...Array(3)].map(() => {
          return <img src={'/assets/img/product/fashion-watch1.png'} />
        })}
      </div>
      <div className="image-font-align">
        <span>Images</span>
      </div>
      <div className="image-count-align">
        <span>(54)</span>
      </div>
    </div> */}
      </div>
      <div className="review-detail-section review-modal-dropdown-alignment">
        <div className="new-review-details-alignment">
          <div className="new-review-details-top-alignment">
            {/* <div className="new-review-left-alignment">
              <div className="new-review-dropdown-alignment">
                <p>All Stars</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M4 6L8 10L12 6" stroke="#636773" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              <div className="new-review-dropdown-alignment">
                <p>Most Recent</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M4 6L8 10L12 6" stroke="#636773" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div> */}

            <div className="image-section-align" onClick={() => movetoImageModal()}>
              <div className="image-contanier-align">
                {ratingData.slice(0, 3).map((rate, index) => {
                  const mediaUrl = rate.userReviewsOnProductsMedias[0]?.url;
                  return <img src={mediaUrl || '/assets/img/product/fashion-watch1.png'} key={index} />;
                })}
              </div>
              <div className="image-font-align">
                <span>Media</span>
              </div>
              <div className="image-count-align">
                <span>({ratingData.length})</span>
              </div>
            </div>
          </div>
          <div className="comment-box-main-section all-comment-box-details">
            <div className="comment-alignment all-comment-details">
              {ratingData.length > 0 && ratingData?.map((rate, index) => {
                return (
                  <div className="comment-container-main" key={index}>
                    <div className="comment-section">
                      <div className="comment-details-star-alignment">
                        <StarRatings
                          svgIconViewBox="0 0 28 28"
                          // svgIconViewBox= "0 0 13 12" : "0 0 28 28"}
                          rating={rate?.overAllRating}
                          starRatedColor="#FF7D23"
                          starEmptyColor="#E4E4E8"
                          numberOfStars={5}
                          svgIconPath="M12.0644 4.92537C12.7926 3.46898 14.8709 3.46898 15.5991 4.92537L17.5084 8.74389C17.8039 9.33482 18.3751 9.73898 19.0307 9.82093L22.5458 10.2603C24.1259 10.4578 24.8353 12.3477 23.7754 13.5362L21.2135 16.4091C20.8141 16.8569 20.6399 17.4618 20.7398 18.0534L21.4828 22.4514C21.7521 24.0458 20.097 25.2711 18.6507 24.5479L14.7155 22.5803C14.1592 22.3022 13.5044 22.3022 12.9481 22.5803L9.01913 24.5448C7.57171 25.2685 5.91566 24.0407 6.18755 22.4454L6.93561 18.0564C7.03672 17.4631 6.86197 16.8561 6.46089 16.4074L3.89514 13.5373C2.83309 12.3492 3.54197 10.4573 5.12322 10.2596L8.63284 9.82093C9.28842 9.73898 9.85967 9.33482 10.1551 8.74389L12.0644 4.92537Z"
                          starDimension={isMobile ? '12px' : '24px'}
                          starSpacing="1px"
                          name="rating"
                        />
                      </div>
                      <div className="comment-description">
                        <p>{rate?.details}</p>
                      </div>
                      <div className="mobile-view-comment-reviewimg-alignment">
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((_, index) => {
                          return (
                            <div className="mobile-view-review-img" key={index}>
                              <img src="/assets/img/productdemoimg.png" alt="product imf" />
                            </div>
                          )
                        })}
                      </div>
                      <div className="commenter-profile-section">
                        <div className="image-name-align">
                          <div className="user-profile-image">
                            <img src={rate?.user_details?.logo_image} />
                          </div>
                          <span>{rate?.user_details?.firstName}</span>
                        </div>
                        <div className="date-alignment">
                          <span> {moment(rate?.createdAt).format('MMM DD')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          {/* <div className="comment-see-all-comment-alignment">
            <a>See all comments</a>
          </div> */}
        </div>
        <div className='no-reviews-message-div'>
          {ratingData.length === 0 && (
            <div className="no-reviews-message">
              <p>No reviews yet</p>
            </div>
          )}
        </div>
        {/* <div className="rate-main-header">
          <div className="ratting-menu-section">
            {ratingSection.map((rate) => {
              return (
                <div
                  className={selectedRate === rate.id ? 'star-container active-class' : 'star-container'}
                  onClick={() => handleSelect(rate)}
                >
                  <div className="all-start-align">
                    <span>{rate.name}</span>
                  </div>
                  <div className="start-icon-align">{rate.starIcon}</div>
                  <div className="feedback-total-count">
                    <p>({rate.ratting})</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div> */}

        {/* <div className="new-comment-box-alignmet">
          <div className="new-comment-rate-icon-flex">
            {[0, 1, 2, 3, 4].map(() => {
              return (
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                  <path
                    d="M6.02442 2.2685C6.36662 1.5841 7.3433 1.5841 7.6855 2.2685L8.43271 3.76293C8.57156 4.04062 8.84 4.23055 9.14808 4.26906L10.4999 4.43804C11.2425 4.53086 11.5758 5.41895 11.0778 5.97747L10.0798 7.09653C9.89217 7.30695 9.81029 7.59123 9.85725 7.86923L10.1504 9.6045C10.277 10.3537 9.49915 10.9295 8.81952 10.5897L7.27023 9.81506C7.00881 9.68435 6.70111 9.68435 6.43969 9.81506L4.89337 10.5882C4.21318 10.9283 3.43495 10.3513 3.56273 9.60167L3.85776 7.87062C3.90528 7.59184 3.82315 7.30659 3.63467 7.09575L2.63546 5.97798C2.13637 5.41968 2.46949 4.53061 3.21257 4.43772L4.56184 4.26906C4.86991 4.23055 5.13836 4.04062 5.2772 3.76293L6.02442 2.2685Z"
                    fill="#FF7D23"
                  />
                </svg>
              )
            })}
          </div>
          <div className="new-comment-description-alignment">
            <p>Lorem ipsum dolor sit amet,</p>
          </div>
          <div className="new-comment-profile-img-flex-alignment">
            {[0, 1].map(() => {
              return (
                <div className="new-comment-img">
                  <img src="/assets/img/img-1.png" alt="imgess" />
                </div>
              )
            })}
          </div>
          <div className="new-comment-profile-details">
            <div className="new-comment-profile-flex">
              <div className="new-profile-img">
                <img src="/assets/img/profile-img2.png" alt="profile img" />
              </div>
              <p>M****</p>
            </div>

            <div className="new-comment-date-alignment">
              <span>Jun 22</span>
            </div>
          </div>
        </div> */}
      </div>
    </>
  )
}
