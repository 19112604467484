import manageModalSlice from './manageModalSlice'
import manageEditPostSlice from './manageEditPostSlice'
import manageApplianceCategorySlice from './manageApplianceCategorySlice'
import manageCategoryDetail from './manageCategoryDetail'
import postAddEditModal from './postAddEditSlice'
import userProfileModalScroll from './userProfileonScrollSlice'
import mobileModalSlice from './mobileModalSlice'
import productResizeSlice from './productResizeSlice'
import pageScrollEventSlice from './pageScrollEventSlice'
import productErrorHandlerSlice from './productErrorHandlerSlice'
import searchModalSlice from './searchModalSlice'
import trafficEngagementFilterSlice from './trafficEngagementFilterSlice'
import productShareOptionsSlice from './productShareOptionsSlice'
import manageAddProductDetail from './manageAddProductDetail'
import manageBookmarTabs from './manageBookmarkTab'
import manageBookmarRename from './manageBookmarkRename'
import inviteClubMembers from './inviteClubMebersSlice'
import guestAccountDetailSlice from './guestAccountDetailSlice'
import sellerWithdrawModalData from './manageSellerWithdrawSlice'
import postCountData from './createPostCountSlice'
import postProductCountData from './createPostProductSlice'
import createMeetingSlice from './createMeetingSlice'
import fetchStoreData from './updateStoreDetailSlice'
import fetchAllFeed from './fetchAllFeedSlice'
import fetchAllClub from './fetchAllClubsSlice'
import fetchAllStore from './fetchAllStoresSlice'
import fetchProductSlice from './productsDataSlice'
import getAllCartCountSliceData from './getCartCount'
import fetchUserMobileModalData from './fetchUserMobileModalData'
import fetchClubMobileModalData from './fetchClubMobileModalData'
import globalSearchSlice from './globalSearchSlice'
import fetchCategory from './fetchCategory'
import accountSummarySlice from './accountSummarySlice'
import fetchAllPeopleSlice from './fetchAllPeopleSlice'
import fetchOrderDetail from './fetchOrderDetail'
import fetchJewelrySlice from './fetchJewelrySlice'
import fetchAllProduct from './fetchAllProduct'

export const Slices = {
  manageModalSlice,
  manageEditPostSlice,
  manageApplianceCategorySlice,
  manageCategoryDetail,
  postAddEditModal,
  userProfileModalScroll,
  mobileModalSlice,
  productResizeSlice,
  pageScrollEventSlice,
  productErrorHandlerSlice,
  searchModalSlice,
  trafficEngagementFilterSlice,
  productShareOptionsSlice,
  manageAddProductDetail,
  manageBookmarTabs,
  manageBookmarRename,
  inviteClubMembers,
  guestAccountDetailSlice,
  sellerWithdrawModalData,
  postCountData,
  postProductCountData,
  createMeetingSlice,
  fetchStoreData,
  fetchAllFeed,
  fetchAllClub,
  fetchAllStore,
  fetchProductSlice,
  getAllCartCountSliceData,
  fetchUserMobileModalData,
  fetchClubMobileModalData,
  globalSearchSlice,
  fetchCategory,
  accountSummarySlice,
  fetchAllPeopleSlice,
  fetchOrderDetail,
  fetchJewelrySlice,
  fetchAllProduct,
}
