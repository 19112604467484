import router from 'next/router';
import React from 'react';

const ClubActivity: React.FC<any> = ({ notification, setOpen }: any) => {
    const payload = notification?.payload;
    return (
        <div className="py-4 px-1 sm:px-3 md:px-6">
            <div className="flex items-start space-x-5 p-4 hover:bg-gray-50">
                {payload?.product && (
                    <div className="flex-shrink-0 w-[58px] h-[58px] rounded-lg overflow-hidden  border-white bg-gray-100">
                        <img
                            src={payload.product}
                            alt={payload.productName || "Product"}
                            width={58}
                            height={58}
                            className="w-full h-full object-cover"
                        />
                    </div>
                )}

                <div className="">
                    <div className="flex items-center">
                        <div>
                            <div>
                                <span className="text-sm font-semibold text-gray-900 mr-2">Shoping Club Activity: 📢</span>
                                <span>New Conversation in </span>
                            </div>
                            <a onClick={() => router.push(`/club/${payload?.clubSlug}`)} className='hover:underline text-[#556EE6] hover:text-[#556EE6] mr-2 font-semibold '>{payload?.clubName}</a>
                            <span className="">{payload?.message}</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ClubActivity;