// @ts-nocheck


import React from 'react'
import { useSelector } from 'react-redux'
import Slider from 'react-slick'
import { VIDEO_ARRAY } from 'src/Helpers/Json/LoginMedia'

const settings: any = {
  dots: true,
  arrows: false,
  infinite: true,
  lazyLoad: true,
  speed: 750,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
}

export default function NewModalSlider() {
  const guestDetail = useSelector((state: any) => state?.guestAccountInfo?.data)

  return (
    <div>
      {guestDetail?.id?.media === 'login' ? (
        <div className="login-slider-wrapper">
          <Slider {...settings} className="owl-carousel">
            {VIDEO_ARRAY?.map((md, index) => {
              return (
                <div className="item" key={index}>
                  <div className="video-details-alignment">
                    <video muted autoPlay style={{ objectFit: 'cover' }}>
                      <source src={md} />
                    </video>
                  </div>
                  <div className="slide-details">
                    <h4>Sharing Product with Friends</h4>
                    <p>
                      Share favorite finds with friends & family, brings you closer together, turning shopping into a
                      thrilling collaborative journey
                    </p>
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
      ) : (
        <div className="login-slider-wrapper">
          {/* <Slider {...settings} className="owl-carousel"> */}
          <div className="item">
            <div className="video-details-alignment">
              <video muted autoPlay style={{ objectFit: 'cover' }}>
                <source src={guestDetail?.id?.media} />
              </video>
            </div>
            <div className="slide-details">
              <h4>Sharing Product with Friends</h4>
              <p>
                Share favorite finds with friends & family, brings you closer together, turning shopping into a
                thrilling collaborative journey
              </p>
            </div>
          </div>
          {/* </Slider> */}
        </div>
      )}
    </div>
  )
}
