import React from 'react';
import NotificationFooter from '../NotificationFooter';
import UserNotificationsProfile from '../NoticationIcon';

const MessageAlert: React.FC<any> = ({ notification, setOpen }: any) => {
    const payload = notification?.payload;
    const imagePayload = {
        name: payload?.userName,
        image: payload?.userImg,
    }
    return (
        <div className="py-4 px-1 sm:px-3 md:px-6">
            <div className="flex items-start space-x-5 p-4 hover:bg-gray-50">
                <UserNotificationsProfile imagePayload={imagePayload} />
                <div className=" min-w-0">
                    <div className="flex items-center">
                        <div className='flex items-start'>
                            <div className="flex items-start space-x-2 text-sm text-gray-500 ">
                                <span className='text-sm  text-gray-800'><b>{payload?.userName}</b> {payload?.message}</span>
                            </div>
                        </div>
                    </div>
                    <NotificationFooter notification={notification} />
                </div>
            </div>
        </div>
    );
};

export default MessageAlert;