import React from 'react'
import styles from './SellerTools.module.scss'
import classNames from 'classnames'
export default function SellerTools() {
  return (
    <div className={styles.sellerToolsSection}>
      <div className={styles.sellerToolsAlignment}>
        <div className={styles.sellerToolsBox}>
          <div className={styles.sellerHeadingAlignment}>
            <h6>Tools</h6>
            <p>How would you like to grow your business?</p>
          </div>

          <div className={styles.sellerToolsDetailsAlignment}>
            <div className={classNames(styles.sellerToolsOptionDetailsAlignment, styles.activeTools)}>
              <div className={styles.sellerToolsOptionDetailsTools}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.642238 11.6507C0.449328 11.8483 0.453119 12.1649 0.650706 12.3578C0.848292 12.5507 1.16485 12.5469 1.35776 12.3493L0.642238 11.6507ZM5.4 7.49333L5.758 7.14428C5.66394 7.04781 5.53491 6.99338 5.40017 6.99333C5.26543 6.99329 5.13637 7.04763 5.04224 7.14404L5.4 7.49333ZM8 10.16L7.642 10.5091C7.73614 10.6056 7.86528 10.66 8.00012 10.66C8.13497 10.66 8.26409 10.6055 8.35817 10.5089L8 10.16ZM14.5 3.99342C14.4963 3.7173 14.2695 3.49641 13.9934 3.50004L9.49381 3.55925C9.21769 3.56288 8.9968 3.78967 9.00043 4.06578C9.00407 4.3419 9.23085 4.5628 9.50697 4.55916L13.5066 4.50654L13.5592 8.50619C13.5629 8.78231 13.7897 9.0032 14.0658 8.99957C14.3419 8.99593 14.5628 8.76915 14.5592 8.49303L14.5 3.99342ZM1.35776 12.3493L5.75776 7.84263L5.04224 7.14404L0.642238 11.6507L1.35776 12.3493ZM5.042 7.84238L7.642 10.5091L8.358 9.81095L5.758 7.14428L5.042 7.84238ZM8.35817 10.5089L14.3582 4.34887L13.6418 3.65113L7.64183 9.81113L8.35817 10.5089Z"
                    fill="#7A7E88"
                  />
                </svg>
              </div>
              <p>Boost a Post</p>
            </div>
            <div className={styles.sellerToolsOptionDetailsAlignment}>
              <div className={styles.sellerToolsOptionDetailsTools}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 14V13C12 12.4696 11.7893 11.9609 11.4142 11.5858C11.0391 11.2107 10.5304 11 10 11H6C5.46957 11 4.96086 11.2107 4.58579 11.5858C4.21071 11.9609 4 12.4696 4 13V14"
                    stroke="#7A7E88"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 9C9.10457 9 10 8.10457 10 7C10 5.89543 9.10457 5 8 5C6.89543 5 6 5.89543 6 7C6 8.10457 6.89543 9 8 9Z"
                    stroke="#7A7E88"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M8 2L8 1" stroke="#7A7E88" strokeLinecap="round" />
                  <path d="M14 8L15 8" stroke="#7A7E88" strokeLinecap="round" />
                  <path d="M1 8L2 8" stroke="#7A7E88" strokeLinecap="round" />
                  <path d="M11.9088 3.91804L12.9153 2.9115" stroke="#7A7E88" strokeLinecap="round" />
                  <path d="M2.85008 2.91155L3.85663 3.91809" stroke="#7A7E88" strokeLinecap="round" />
                </svg>
              </div>
              <p>Get more Visitors</p>
            </div>
            <div className={styles.sellerToolsOptionDetailsAlignment}>
              <div className={styles.sellerToolsOptionDetailsTools}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2 14V11.3333M2 11.3333V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H7.66667L8.33333 2.66667H14L12 6.66667L14 10.6667H8.33333L7.66667 10H3.33333C2.97971 10 2.64057 10.1405 2.39052 10.3905C2.14048 10.6406 2 10.9797 2 11.3333ZM8 2.33333V6"
                    stroke="#7A7E88"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <p>Promote your page</p>
            </div>
            <div className={styles.sellerToolsOptionDetailsAlignment}>
              <div className={styles.sellerToolsOptionDetailsTools}>
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.54167 3.66667V1M10.2083 3.66667V1M3.83333 6.33333H10.9167M2.41667 13H12.3333C12.7091 13 13.0694 12.8595 13.3351 12.6095C13.6007 12.3594 13.75 12.0203 13.75 11.6667V3.66667C13.75 3.31304 13.6007 2.97391 13.3351 2.72386C13.0694 2.47381 12.7091 2.33333 12.3333 2.33333H2.41667C2.04094 2.33333 1.68061 2.47381 1.41493 2.72386C1.14926 2.97391 1 3.31304 1 3.66667V11.6667C1 12.0203 1.14926 12.3594 1.41493 12.6095C1.68061 12.8595 2.04094 13 2.41667 13Z"
                    stroke="#7A7E88"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <p>Plan your marketing</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
