import React from 'react'
import { useStateContext } from 'src/utils/state'
import { SVGComponent } from '../../MessageModalSVG'
import UserMessageItem from '../UserMessageItem'
import GroupMessageItem from '../GroupMessageItem'

export default function SearchPeopleSection({ allUserData, searchTag }) {
  return (
    <>
      {Boolean(
        allUserData?.filter((supplier: any) => supplier?.name?.toLowerCase()?.includes(searchTag?.toLowerCase()))
          ?.length,
      ) ? (
        allUserData
          ?.filter((supplier: any) => supplier?.name?.toLowerCase()?.includes(searchTag?.toLowerCase()))
          ?.map((group: any) => {
            if (group?.adminUser) {
              return <GroupMessageItem group={group} />
            } else {
              return <UserMessageItem chatData={group} />
            }
          })
      ) : (
        <div className="no-search-details-alignment">
          <div className="no-search-img-alignment">
            <SVGComponent />
          </div>
          <p>Not found.</p>
        </div>
      )}
    </>
  )
}
