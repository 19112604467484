import { gql } from '@apollo/client'

const ADD_STORELIKE = gql`
  mutation AddStoreLike($storeId: Int) {
    addStoreLike(store_id: $storeId) {
      message
      success
    }
  }
`
export default ADD_STORELIKE
