import { gql } from '@apollo/client'

export default gql`
  mutation CREATE_SAVE_POST($post: Int!, $user: Int!) {
    createSavePost(input: { post_id: $post, user_id: $user }) {
      id
      post_id
    }
  }
`
