import React, { useEffect, useRef, useState } from 'react'
import styles from './MobileAddSizeVariant.module.scss'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'

export default function MobileAddSizeVariant() {
  const houreRef: any = useRef()
  const dispatch = useAppDispatch()
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const sizeArraySelected = [
    { size: 'S', id: 0 },
    { size: 'M', id: 1 },
    { size: 'L', id: 2 },
    { size: 'XL', id: 3 },
    { size: '2XL', id: 4 },
    { size: '3XL', id: 3 },
  ]
  const [SizeDropdown, setSizeDropdown] = useState(false)
  const [colorArray, setColorArray] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [sizeArray, setSizeArray] = useState(sizeArraySelected)

  const handleSelect = async (size) => {
    let textValue = size
    if (textValue.trim() !== '') {
      setColorArray([...colorArray, textValue])
      setInputValue('')
    }
  }

  const handleChange = (e) => {
    setInputValue(e.target.value)
  }

  const addTags = (event, type) => {
    if (type === 'enter') {
      let textValue = event.target.value
      if (textValue.trim() !== '') {
        setColorArray([...colorArray, textValue])
        setInputValue('')
      }
    } else {
      if (event.trim() !== '') {
        setColorArray([...colorArray, event])
        setInputValue('')
      }
    }
  }

  const handleRemove = (indexToRemove) => {
    setColorArray(colorArray.filter((_, index) => index !== indexToRemove))
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (SizeDropdown && houreRef.current && !houreRef.current.contains(e.target)) {
        setSizeDropdown(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [SizeDropdown])

  const handleSubmitSize = () => {
    if (addProductDetailSlice?.id?.variant?.dataEdit?.size) {
      // submitEditedSize({ name: 'SIZE', variant: colorArray, index: dataEdit?.index }, 'size')
      // setAddSizeModalOpen(false)
      // setDataEdit({})
      let data = { name: 'SIZE', variant: colorArray, index: addProductDetailSlice?.id?.variant?.dataEdit?.index }
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            variant: {
              ...addProductDetailSlice?.id?.variant,
              allVariant: addProductDetailSlice?.id?.variant?.allVariant?.map((add, index) => {
                if (index === data?.index) {
                  return { name: data?.name, data: data?.variant }
                } else {
                  return add
                }
              }),
              colorCodeArray: addProductDetailSlice?.id?.variant?.allVariant?.map((add, index) => {
                if (index === data?.index) {
                  return { name: data?.name, data: data?.variant }
                } else {
                  return add
                }
              }),
              allFinalVariant: addProductDetailSlice?.id?.variant?.allVariant?.map((add, index) => {
                if (index === data?.index) {
                  return { name: data?.name, data: data?.variant }
                } else {
                  return add
                }
              }),
              isAddSizeModalOpen: false,
              dataEdit: {},
            },
          },
        }),
      )
    } else {
      let data = { name: 'SIZE', variant: colorArray }
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            variant: {
              ...addProductDetailSlice?.id?.variant,
              allVariant: [...addProductDetailSlice?.id?.variant?.allVariant, { name: data.name, data: data.variant }],
              colorCodeArray: [
                ...addProductDetailSlice?.id?.variant?.allVariant,
                { name: data.name, data: data.variant },
              ],
              isAddSizeModalOpen: false,
              dataEdit: {},
            },
          },
        }),
      )
    }
    let datass = fetchMobileModal?.allModalData?.filter(
      (item) => item?.modal !== fetchMobileModal?.lastModalData?.modal,
    )
    dispatch(
      fetchMobileModalData({
        allModalData: datass,
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
  }

  useEffect(() => {
    if (addProductDetailSlice?.id?.variant?.dataEdit?.size) {
      setColorArray(addProductDetailSlice?.id?.variant?.dataEdit?.size?.data)
    }
  }, [addProductDetailSlice?.id?.variant?.dataEdit])

  return (
    <div className={styles.mobileAddSizeVariantSection}>
      <div className={styles.mobileAddSizeVariantHeading}>
        <h6>Add Size Variant</h6>
        <a>Close</a>
      </div>
      <div className={styles.mobileAddSizeOptionValueBodyDetails}>
        <div className={styles.mobileAddSizeOptionValueTop}>
          {Boolean(colorArray?.length) ? (
            <div className={styles.mobileAddSIzeDetailsAlignment}>
              {colorArray?.map((item, index) => {
                return (
                  <div className={styles.mobileAddSIzeDetailsSizeAlignment}>
                    <p>{item}</p>
                    <a onClick={() => handleRemove(index)}> Remove</a>
                  </div>
                )
              })}
            </div>
          ) : (
            <div className={styles.mobileAddSizeNoSizeAddedAlignment}>
              <p>No size added</p>
            </div>
          )}
        </div>
        <div className={styles.mobileAddSizeOptionValueBottom}>
          <div className={styles.mobileOptionValueInputDetails}>
            <label>Option Value</label>
            <div className={styles.mobileOptionValueInput} onClick={() => setSizeDropdown(!SizeDropdown)}>
              <input
                type="text"
                placeholder="Enter keyword"
                onKeyUp={(event) => (event.key === 'Enter' ? addTags(event, 'enter') : null)}
                onChange={(e) => handleChange(e)}
                value={inputValue}
              />
              {SizeDropdown && (
                <div className={styles.mobileDropdownAlignment} ref={houreRef}>
                  {sizeArray?.map((val) => {
                    return <p onClick={() => handleSelect(val?.size)}>{val?.size}</p>
                  })}
                </div>
              )}
            </div>
          </div>
          <div className={styles.mobileOptionViewButtonAlignment}>
            <button
              onClick={() => handleSubmitSize()}
              className={Boolean(colorArray?.length) ? styles.activeButton : ''}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
