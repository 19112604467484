import React from 'react'
import { useAuth } from 'src/utils/auth'

import { timeAgo, truncateString } from 'src/utils/helperFuncs'
import { useStateContext } from 'src/utils/state'

export default function InvitedGroupSection({ setinvitedDropdown, inviteUser, invitedDropdown }) {
  const { selectChat } = useStateContext()
  const { user } = useAuth()
  return (
    <div className='your-invited-section-alignment'>
      <div className="your-invited-heading-alignment">
        <div className="your-invited-heading-name-flex-alignment">
          <h6>You are invited</h6>
          <div className="invited-number-alignment">
            <span>{inviteUser?.getAllGroupInvitedUsers?.length}</span>
          </div>
        </div>
        <div className="your-invited-details-arrow" onClick={() => setinvitedDropdown(!invitedDropdown)}>
          <img
            src="/assets/seller/icons/grey-top-arrow.svg"
            alt="top arrow"
            height={24}
            width={24}
            className={invitedDropdown ? 'bottom-rotet-arrow-none' : 'bottom-rotet-arrow '}
          />
        </div>
      </div>
      {invitedDropdown && <div
        className={`your-invited-user-details-alignment ${invitedDropdown ? 'your-invited-user-all-details-alignment' : 'your-invited-user-all-details-alignment-hidden'
          }`}
      >
        {inviteUser &&
          inviteUser?.getAllGroupInvitedUsers?.map((group, index) => {
            let filteredUser = [...group?.adminUser, ...group?.users].filter((userValue) => userValue?.id !== user?.id)
            return (
              <div className="group-message-tab-alignment" key={group.id} onClick={() => selectChat(group, 'invite')}>
                <div className="group-message-tab-box-alignment">
                  <div className="group-message-profile-details-alignment">
                    <div className="group-message-profile-img-box-alignment-sidebar">
                      {[...Array(3)].map((profile, index) => {
                        return (
                          <div className={`group-message-flex-alignment-${index}`} key={index}>
                            <div className={`group-message-profile-img-alignment-${index}`}>
                              <img
                                src={
                                  filteredUser && filteredUser[index]
                                    ? filteredUser && filteredUser[index]?.profileAvtar
                                    : '/assets/img/dummy-group-user.svg'
                                }
                                alt="group profile"
                              />
                            </div>
                          </div>
                        )
                      })}
                      <div className="group-message-first-dot-alignment">
                        <div className="first-dot-width-alignment">
                          <img src="/assets/img/gdot1.png" alt="group profile" />
                        </div>
                      </div>
                      <div className="group-message-second-dot-alignment">
                        <div className="second-dot-width-alignment">
                          <img src="/assets/img/gdot2.png" alt="group profile" />
                        </div>
                      </div>
                      <div className="group-message-third-dot-alignment">
                        <div className="third-dot-width-alignment">
                          <img src="/assets/img/gdot3.png" alt="group profile" />
                        </div>
                      </div>
                    </div>
                    <div className="group-message-profile-all-details-alignment">
                      <h5>{truncateString(group?.name, 25)}</h5>
                      <div className="group-message-images-icon-and-text-alignment">
                        <div className="group-message-image-group">
                          {filteredUser?.slice(0, 3)?.map((us, index) => {
                            return <img src={us?.profileAvtar} key={index} />
                          })}
                        </div>
                        <span>
                          {filteredUser?.slice(0, 3)[filteredUser?.slice(0, 3)?.length - 1]?.firstName}
                          {filteredUser?.slice(0, 3)?.length > 3
                            ? `, and ${filteredUser?.slice(0, 3)?.length - 1} other part of this group`
                            : ' part of this group'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="group-message-time-alignment">
                    <div className="group-total-message-alignment">
                      <div className="group-total-message-box-alignmant">
                        <p>8</p>
                      </div>
                    </div>
                    <span>{timeAgo(new Date(group?.latestMessage?.createdAt).getTime())}</span>
                  </div>
                </div>
              </div>
            )
          })}
      </div>}
    </div>
  )
}
