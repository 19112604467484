import React, { useEffect, useState } from 'react'
import styles from './KYCVerifiedStep2.module.scss'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { useAuth } from 'src/utils/auth'
import moment from 'moment'
import { fetchSellerWithdrawModalData } from 'src/store/slices/manageSellerWithdrawSlice'
import WITHDRAW_PAYMENT_FROM_SELLER from 'src/graphql/queries/sellerQueries/withDrawPaymentFromSeller'
import { useMutation } from '@apollo/client'
import sellerClient from 'src/utils/apolloSellerClient'
import SEND_OTP_FOR_WITHDRAWAL from 'src/graphql/queries/sellerQueries/sendOTPForWithdrawal'

export default function KYCVerifiedStep2() {
  const { user }: any = useAuth()
  const dispatch = useAppDispatch()
  const SellerAccountBalance = useSelector((state: any) => state?.sellerWithdrawData?.data)
  const [comment, setComment] = useState('')

  const [sendOTPForWithdrawal] = useMutation(SEND_OTP_FOR_WITHDRAWAL, {
    onCompleted: () => {
      dispatch(
        fetchSellerWithdrawModalData({
          allModalData: [
            ...SellerAccountBalance?.allModalData,
            { modal: 'sellerWithdrawOTPverification', isOpen: true, id: 0, modalFor: 'sellerWithdrawSize' },
          ],
          lastModalData: SellerAccountBalance?.lastModalData,
          withdrawFund: { ...SellerAccountBalance?.withdrawFund, note: comment },
        }),
      )
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const HandleNext = () => {
    if (
      SellerAccountBalance?.withdrawFund?.accountId &&
      SellerAccountBalance?.withdrawFund?.amount?.upcoming_amount >= SellerAccountBalance?.withdrawFund?.selectedAmout
    ) {
      sendOTPForWithdrawal({ client: sellerClient })
    }
  }

  useEffect(() => {
    if (comment !== SellerAccountBalance?.withdrawFund?.note) {
      setComment(SellerAccountBalance?.withdrawFund?.note)
    }
  }, [])

  return (
    <div className={styles.kYCVerifiedStep2Section}>
      <div className={styles.kYCVerifiedStep2Box}>
        <div className={styles.kYCVerifiedStep2FlexAlignment}>
          <div>
            <img src="/assets/seller/icons/union-icon.svg" alt="union icon" />
          </div>
          <div className={styles.kYCVerifiedStep2lrftDetails}>
            <p>Transfer Amount</p>
            <h6>${SellerAccountBalance?.withdrawFund?.selectedAmout}</h6>
          </div>
        </div>
        <div className={styles.kYCVerifiedStep2AddressBox}>
          <div className={styles.kYCVerifiedStep2ChildDetails}>
            <label>From</label>
            <p>{user?.store?.name} Account Balance</p>
          </div>
          <div className={styles.kYCVerifiedStep2ChildDetails}>
            <label>To</label>
            <p>
              {SellerAccountBalance?.withdrawFund?.bankDetail?.account_holder_name}{' '}
              {SellerAccountBalance?.withdrawFund?.bankDetail?.bank_name}
            </p>
          </div>
          <div className={styles.kYCVerifiedStep2ChildDetails}>
            <label>Estimated Deposit</label>
            {/* <p>June 24 , 2023</p> */}
            <p>{moment(new Date()).clone().add(7, 'days').format('MMM DD, yyyy')}</p>
          </div>
        </div>
        <div className={styles.kYCVerifiedWriteNoteBox}>
          <label>Note</label>
          <div className={styles.kYcVerifiedNoteArea}>
            <textarea
              placeholder="Write note here..."
              onChange={(e) => setComment(e.target.value)}
              value={comment}
            ></textarea>
          </div>
        </div>
      </div>
      <div className={styles.kYCVerifiedStep2ButtonAlignment}>
        <div className={styles.kYCWithdrawButton}>
          <button onClick={() => HandleNext()}>Withdraw</button>
        </div>
        <div className={styles.kYCWithBackButton}>
          <button>Back</button>
        </div>
      </div>
    </div>
  )
}
