import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import GET_USER_BY_NAME from 'src/graphql/queries/getUserByName'
import Loader from 'src/components/UI/Loader'
import UserCoverImageSection from 'src/components/UserProfile/UserProfileModal/UserCoverImageSection'
import UserDataSection from 'src/components/UserProfile/UserProfileModal/UserDataSection'
import UserAbout from 'src/components/UserProfile/UserProfileModal/UserAbout'
import UserFollowModal from 'src/components/UserProfile/UserProfileModal/UserFollowModal'
import MobileviewFollowModal from './UserAbout/MobileviewFollowModal'

export default function UserProfileModal(props) {
  const screenWidth = window.innerWidth
  const { userId, slug, editPostHandler } = props
  const { loading, error, data, refetch } = useQuery(GET_USER_BY_NAME, {
    variables: { slug: slug || userId },
  })
  const [FollowModal, setFollowModal] = useState(false)
  const [followers, setFollowers] = useState([])
  const [isVisible, setIsVisible] = useState(false)
  const [isOpenFollowerModal, setIsOpenFollowerModal] = useState(false)
  const [viewIndex, setViewIndex] = useState('1')

  useEffect(() => {
    refetch()
  }, [])

  if (loading) return <Loader />
  if (error) return <p>`Error! ${error.message}`</p>

  return (
    <>
      <div className={`social-product-product-modal-alignment ${FollowModal && 'overflow-hidden-alignment'}`}>
        <UserCoverImageSection userData={data?.getUserbyName} userId={userId} />
        <div className="social-product-details-alignment">
          <div className="social-grid-alignment">
            <UserAbout
              setFollowModal={setFollowModal}
              userData={data?.getUserbyName}
              userId={userId}
              setFollowers={setFollowers}
              setIsOpenFollowerModal={setIsOpenFollowerModal}
              setViewIndex={setViewIndex}
            />
            <UserDataSection
              userData={data?.getUserbyName}
              userId={userId}
              editPostHandler={editPostHandler}
              setIsVisible={setIsVisible}
              isVisible={isVisible}
            />
          </div>
        </div>
        {FollowModal && (
          <UserFollowModal
            setFollowModal={setFollowModal}
            userData={data?.getUserbyName}
            followers={followers}
            userId={userId}
          />
        )}
        {screenWidth <= 768 && <MobileviewFollowModal
          // setIsOpenFollowerModal={setIsOpenFollowerModal}
          userData={data?.getUserbyName}
          // isOpenFollowerModal={isOpenFollowerModal}
          userId={userId}
          storeId={null}
          setViewIndex={setViewIndex}
          viewIndex={viewIndex}
          storeName={''}
        />}
      </div>
    </>
  )
}
