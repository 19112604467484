import { gql } from '@apollo/client'

const ADD_PRODUCT_VIEW = gql`
  mutation addProductView($input: productViewInput!) {
    addProductView(input: $input) {
      success
      message
      data {
        id
      }
    }
  }
`
export default ADD_PRODUCT_VIEW
