import { Checkbox } from 'antd'
import React from 'react'
import CartProduct from './CartProduct'

export default function CartProductSection({
  index,
  product,
  setTempProductArray,
  tempProductArray,
  productIdArray,
  setProductIdArray,
}) {
  const onChange = (e, data) => {
    if (tempProductArray.includes(data)) {
      setTempProductArray(tempProductArray.filter((pro) => pro.id !== product.id))
    } else {
      tempProductArray.push(data)
      setTempProductArray([...tempProductArray])
    }
  }

  return (
    <div className="main-card" key={index}>
      <div>
        <Checkbox onChange={(e) => onChange(e, product)} defaultChecked />
      </div>
      <div className="cart-grid-align">
        <div className="store-logo-align">
          <img
            src={product?.storeDetail?.logo_url ? product?.storeDetail?.logo_url : '/assets/img/cart/profileImage.png'}
          />
          <span>{product?.storeDetail?.name}</span>
        </div>
        <div className={tempProductArray.includes(product) ? 'cart-product-main cart-opacity' : 'cart-product-main'}>
          <div className="card-section">
            {product?.product?.map((prd, index) => {
              return (
                <CartProduct
                  index={index}
                  product={prd.product}
                  productIdArray={productIdArray}
                  setProductIdArray={setProductIdArray}
                  selectedQuantity={prd?.quantity}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
