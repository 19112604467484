import { gql } from '@apollo/client'

const CREATE_ORDER = gql`
  mutation CreateOrders($input: paymentInput!) {
    createOrders(input: $input) {
      data {
        generate_id
        order_id
        paymentId
        redirectUrl
      }
      generate_id
      message
      success
      error {
        success
        message
        variant_id
      }
    }
  }
`
export default CREATE_ORDER
