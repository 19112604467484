import { gql } from '@apollo/client'

const CREATE_REPORT = gql`
 mutation CreateReportPost($input: createReportPostInput) {
  createReportPost(input: $input) {
    message
    success
  }
}
`
export default CREATE_REPORT
