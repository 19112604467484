import React, { useState } from 'react'
import FooterMenu from 'src/components/mobileVersionMenu/FooterMenu'
import { useSelector } from 'react-redux'

export default function MobileLayout(props: any) {
  const [nobottomMenuItem, setNobottomMenuItem] = useState(props.index)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)

  return (
    <React.Fragment>
      <div
        className="mobile-view-footer-buttom  mobile:block tablet:block smallTablet:block lg:hidden"
        style={{
          zIndex: fetchMobileModal?.lastModalData?.zIndex ? fetchMobileModal?.lastModalData?.footer_index : 'none',
        }}
      >
        <div className="mobile-view-page-wrapper">
          <FooterMenu nobottomMenuItem={nobottomMenuItem} />
        </div>
      </div>
    </React.Fragment>
  )
}
