import React from 'react'
import BookDetailInfo from './BookDetailInfo'
import BookDetailAuthor from './BookDetailAuthor'
import BookDetailComment from './BookDetailComment'

export default function BookInfoSection() {
  return (
    <div>
      <div className="book-other-details-left-side-alignment">
        <BookDetailInfo />
        <BookDetailAuthor />
        <BookDetailComment />
      </div>
    </div>
  )
}
