import React from 'react'
import styles from './StockReportDetaiilsModal.module.scss'
import GET_PRODUCT_STOCK_REPORT from 'src/graphql/queries/sellerQueries/getProductStockReport'
import { useQuery } from '@apollo/client'
import { useAuth } from 'src/utils/auth'

export default function StockReportModal() {
  const { user }: any = useAuth()
  const { loading, error, data, refetch } = useQuery(GET_PRODUCT_STOCK_REPORT, {
    variables: { storeId: user?.store?.id, stock_status: '' },
  })

  React.useEffect(() => {
    refetch()
  }, [])

  return (
    <div className={styles.stockReportModalDetailsSection}>
      <div className={styles.stockReportModalDetailsBg}>
        <div className={styles.stockReportModalDetailsHeadingAlignment}>
          <div className={styles.stockReportModalDetailsHeading}>
            <h5>Stock Report</h5>
          </div>
          <div className={styles.stockReportmodalTabAndSearchAlignment}>
            <div className={styles.stockReportModalTabAlignment}>
              <ul>
                <li
                  className={styles.tabActive}
                  onClick={() => refetch({ storeId: user?.store?.id, stock_status: '' })}
                >
                  All
                </li>
                <li onClick={() => refetch({ storeId: user?.store?.id, stock_status: 'in_stock' })}>In Stock</li>
                <li onClick={() => refetch({ storeId: user?.store?.id, stock_status: 'low_stock' })}>Low Stock</li>
                <li onClick={() => refetch({ storeId: user?.store?.id, stock_status: 'out_of_stock' })}>
                  Out of Stock
                </li>
              </ul>
            </div>
            <div className={styles.stockReportSearchAlignment}>
              <div className={styles.stockReportSearchIcon}>
                <img src="/assets/icon/search-icon.svg" alt="search" />
              </div>
              <input type="text" placeholder="Type keyword here..." />
            </div>
          </div>
          <div className={styles.stockReportTotalProduct}>
            <span>{data?.getProductStockReport?.data?.length} products</span>
          </div>
        </div>
        <div className={styles.stockReportModalDetailsAlignment}>
          <div className={styles.stockReportModalTableAlignment}>
            <table cellPadding={0} cellSpacing={0}>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Product Name</th>
                  <th className={styles.textCenter}>Stock</th>
                  <th className={styles.textCenter}>Price</th>
                  <th className={styles.textCenter}>SKU</th>
                  <th className={styles.textEnd}>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {data?.getProductStockReport?.data.map((product, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className={styles.stockProductImg}>
                          <img src={product?.cropImages[0]?.croppedFile?.baseURL} alt="product img" />
                        </div>
                      </td>
                      <td>
                        <span>
                          {product?.title.length > 28 ? product?.title.substring(0, 28) + '...' : product?.title}
                        </span>
                      </td>
                      <td>
                        <div
                          className={
                            product?.current_stock_status === 'In Stock'
                              ? `${styles.stockDetails}`
                              : product?.current_stock_status === 'Low Stock'
                              ? `${styles.stockDetails} ${styles.lowStock}`
                              : product?.current_stock_status === 'Out of Stock'
                              ? `${styles.stockDetails} ${styles.outStock}`
                              : ' '
                          }
                        >
                          <p>{product?.current_stock_status}</p>
                        </div>
                      </td>
                      <td>
                        <span className={styles.priceTag}>${product?.price}</span>
                      </td>
                      <td>
                        <div className={styles.skuAlignment}>
                          <p>{product?.sku}</p>
                        </div>
                      </td>
                      <td>
                        <div className={styles.quantityAlignment}>
                          <span>{product?.quantity}</span>

                          <div className={styles.moreIconAlignment}>
                            <img src="/assets/seller/icons/More-details-icon.svg" alt="more-details" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
