import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface postAddEditSlice {
  loading: true | false
  error: {} | null
  called: true | false
  data: any
}

const initialState = {
  loading: false,
  error: null,
  data: {
    modal: '',
    isOpen: false,
    id: {
      editPost: { isEdit: false, content: null, id: null },
      editPostHandler: { isEdit: false, content: null, id: null },
      mobile: false,
      type: '',
      group: {
        id: 0,
      },
    },
  },
  called: false,
} as postAddEditSlice

export const fetchPostAddEditModal = createAsyncThunk('fetchModal/fetchPostAddEditModal', async (data: any) => {
  return data
})

const postAddEditSlice = createSlice({
  name: 'fetchModal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPostAddEditModal.pending, (state, action) => {
      state.loading = true
      state.error = null
      state.called = true
    })
    builder.addCase(fetchPostAddEditModal.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
      state.called = true
    })
    builder.addCase(fetchPostAddEditModal.rejected, (state, action) => {
      state.loading = false
      state.called = false
      state.error = action.error
      console.error('Error fetching cards!')
    })
  },
})

// export var _actions = postAddEditSlice.actions
export default postAddEditSlice.reducer
