import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { fetchUserProfilModalScroll } from 'src/store/slices/userProfileonScrollSlice'
import { useAppDispatch } from 'src/store/store'
import ModalPart from './ModalPart'
import Overlay from './Overlay'
import TumbnailPreviewModal from 'src/components/Seller/SellerProducts/AddProductModal/TumbnailPreviewModal'
import { useMutation } from '@apollo/client'
import ADD_STORE_ACTIVITY from 'src/graphql/mutations/sellerMutation/addStoreActivity'
import moment from 'moment'
import { useAuth } from 'src/utils/auth'
import ADD_PRODUCT_VIEW from 'src/graphql/mutations/sellerMutation/addProductView'

export default function ModalSlide(props) {
  const { isModalOpen, children } = props
  const myElementRef = useRef(null)
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const [deviceType, setDeviceType] = useState('')
  const [addStoreActivity] = useMutation(ADD_STORE_ACTIVITY, {
    onCompleted: (res) => {},
    onError: (err) => {},
  })

  const [addProductView] = useMutation(ADD_PRODUCT_VIEW, {
    onCompleted: (res) => {},
    onError: (err) => {},
  })

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth
      if (screenWidth < 768) {
        setDeviceType('Mobile')
      } else if (screenWidth >= 768 && screenWidth < 1024) {
        setDeviceType('Tablet')
      } else {
        setDeviceType('Desktop')
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

    const handleClose = () => {
      if (getAllState?.lastModalData?.modal === 'store') {
        addStoreActivity({
          variables: {
            input: {
              // store_id: getAllState?.lastModalData?.id?.id,
              store_id: getAllState?.lastModalData?.id?.store?.id,
              startDate: getAllState?.lastModalData?.startDate,
              endDate: moment().toISOString(),
              deviceName: deviceType,
            },
          },
        })
      }
      if (getAllState?.lastModalData?.modal === 'productDetail') {
        addProductView({
          variables: {
            input: {
              store_id: getAllState?.lastModalData?.store_id,
              product_id: getAllState?.lastModalData?.id,
              startDate: getAllState?.lastModalData?.startDate,
              endDate: moment().toISOString(),
            },
          },
        })
      }
      let filtredModal = getAllState?.allModalData?.filter((item) => item?.modal !== getAllState?.lastModalData?.modal)
      dispatch(
        fetchModalData({
          ...getAllState,
          allModalData: filtredModal,
          lastModalData: getAllState?.lastModalData,
        }),
      )
    }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        handleClose()
      }
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleClose])

  const handleScroll = () => {
    const scrollTop = myElementRef.current.scrollTop
    dispatch(
      fetchUserProfilModalScroll({
        scroll: scrollTop,
      }),
    )
  }

  useEffect(() => {
    dispatch(
      fetchUserProfilModalScroll({
        scroll: 0,
      }),
    )
  }, [getAllState])

  useEffect(() => {
    if (getAllState?.lastModalData?.isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [getAllState])

  return (
    <React.Fragment>
      <div
        onClick={() => handleClose()}
        className={`overlay ${isModalOpen ? 'open' : ''}`}
        style={
          fetchMobileModal?.lastModalData?.modal === 'chatBoxModal' &&
          getAllState?.lastModalData?.modal === 'userDetail'
            ? { zIndex: 999999 }
            : {}
        }
      ></div>
      <div
        className={`special-offer-modal ${isModalOpen ? 'open' : ''}`} //ahiya ?
        style={
          fetchMobileModal?.lastModalData?.modal === 'chatBoxModal' &&
          getAllState?.lastModalData?.modal === 'userDetail'
            ? { zIndex: 999999 }
            : {}
        }
      >
        <div
          className="sidebar-modal-alignment"
          style={
            getAllState?.lastModalData?.modal === 'productDetail' ||
            getAllState?.lastModalData?.modal === 'store' ||
            getAllState?.lastModalData?.modal === 'orderDetailModal' ||
            getAllState?.lastModalData?.modal === 'group'
              ? { background: 'white' }
              : {}
          }
          ref={myElementRef}
          onScroll={handleScroll}
        >
          {children}
        </div>
      </div>
    </React.Fragment>
  )
}
