import { useMutation, useQuery } from '@apollo/client'
import { notification } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import GET_ALL_BILLING_ADDRESS from 'src/graphql/queries/getAllBillingAddress'
import REMOVE_ADDRESS_DETAIL from 'src/graphql/mutations/removeShippingAddress'
import UPDATE_BILLING_ADDRESS from 'src/graphql/mutations/updateBillingAddress'
import { City, Country, State } from 'country-state-city'

export default function SelectMapAddressModal({
  setIsMapModalVisible,
  setIsSelectMapModalVisible,
  isAddressEdit,
  setIsAddressEdit,
  addressFormData,
  setAddressFormData,
  latLong,
  allCountry,
  setAllCountry,
  allState,
  setAllState,
  selectedCity,
  setSelectedCity,
}) {
  const { loading, error, data, refetch } = useQuery(GET_ALL_BILLING_ADDRESS)
  const [addressArray, setAddressArray] = useState([])
  const [cardId, setCardId] = useState(9999)

  useEffect(() => {
    setAddressArray(data?.getAllBillingAddress)
    let defaultData = data?.getAllBillingAddress?.filter((card) => card?.isDefault)
    setCardId(defaultData && defaultData[0]?.id)
  }, [data])

  useEffect(() => {
    refetch()
  }, [])

  const state = ['Illinois', 'Texas', 'Florida', 'Alaska', 'Hawaii']
  const country = ['United States', 'India', 'United kingdom', 'Vietnam', 'Philippines']

  const [deleteBillingAddress] = useMutation(REMOVE_ADDRESS_DETAIL, {
    onCompleted: (data) => {
      notification['success']({
        message: 'Card removed successfully ',
        description: 'Something went wrong, Please try later',
      })
    },
    onError: (error) => {
      console.log(error)
      notification['error']({
        message: 'Error',
        description: 'Something went wrong, Please try later',
      })
    },
  })

  const handleRemoveAddress = (data) => {
    deleteBillingAddress({
      variables: {
        id: data?.id,
      },
      refetchQueries: [{ query: GET_ALL_BILLING_ADDRESS }],
    })
  }

  const handleClick = (id) => {
    setCardId(id)
  }

  const [updateBillingAddress] = useMutation(UPDATE_BILLING_ADDRESS, {
    onCompleted: (data) => {
      notification['success']({
        message: 'Card updated successfully ',
        description: 'Something went wrong, Please try later',
      })
    },
    onError: (error) => {
      console.log(error)
      notification['error']({
        message: 'Error',
        description: 'Something went wrong, Please try later',
      })
    },
  })

  const createShippingAddressHandler = () => {
    updateBillingAddress({
      variables: {
        id: cardId,
        isDefault: true,
      },
      refetchQueries: [{ query: GET_ALL_BILLING_ADDRESS }],
    })
    setIsSelectMapModalVisible(false)
  }

  return (
    <div className="shipping-address-modal">
      <div className="shipping-address-title-text">
        <h1>Your Shipping Address</h1>
      </div>
      <div className="card-main-grid">
        {addressArray?.map((data, index) => {
          return (
            <div
              className="address-list-container"
              style={cardId === data.id ? { border: '1px solid #70C217' } : { border: '1px solid #E4E4E8' }}
              onClick={() => handleClick(data.id)}
              key={index}
            >
              <div className="address-contain-box">
                <div className="address-default-box">
                  <div className="address-text">
                    <span>Address {index + 1}</span>
                  </div>
                  {data.isDefault && (
                    <div className="default-text">
                      <span>Default</span>
                    </div>
                  )}
                </div>
                <div className="edit-remove-box">
                  <div className="edit-text">
                    <span
                      onClick={() => {
                        setIsAddressEdit(true)
                        setIsMapModalVisible(true)
                        setIsSelectMapModalVisible(false)
                        setAddressFormData({ ...data })
                        setAllState(State.getStatesOfCountry(data.country))
                        setAllCountry(Country.getAllCountries())
                        setSelectedCity(City.getCitiesOfState(data.country, data.state))
                      }}
                    >
                      Edit
                    </span>
                  </div>
                  <div className="remove-text" onClick={() => handleRemoveAddress(data)}>
                    <span>Remove</span>
                  </div>
                </div>
              </div>
              <div className="address-align-grid">
                <div className="apartment-text">
                  <span>Apartment</span>
                </div>
                <div className="address-text-align">
                  <h2>{data?.firstName + ' ' + data?.lastName}</h2>
                  <h4>{data?.streetAddress + ', ' + data?.buildingName + ', ' + data?.zipcode}</h4>
                </div>
                <div className="user-number-text">
                  <span>{data?.number}</span>
                </div>
              </div>
              {data.isPinLocation && (
                <div className="location-pin-align">
                  <div className="location-icon">
                    <img src={'/assets/img/checkout/delivery-location-icon.svg'} />
                  </div>
                  <div className="location-pinned-text">
                    <span>Location Pinned</span>
                  </div>
                </div>
              )}
            </div>
          )
        })}
      </div>
      {/* <div className="horulyRateAlignment">
        <div className="rateBox" onClick={() => setIsOpen(true)}>
          <div>
            <span>jainam</span>
          </div>
          <div>
            <img src={'/assets/img/checkout/delivery-location-icon.svg'} alt="DownIcon" />
          </div>
        </div>
        <div className={isOpen ? 'hourlyRateDropdownDesign dropdownShow' : 'hourlyRateDropdownDesign dropdownHidden'}>
          <div className="hourlyRateDropdownDesignAlignment">
            {state?.length > 0 ? (
              state?.map((rate) => {
                return <a>{rate}</a>
              })
            ) : (
              <a>No Data Found</a>
            )}
          </div>
        </div>
      </div> */}
      <div>
        <div
          className="add-new-address-modal"
          onClick={() => {
            setIsMapModalVisible(true)
            setIsSelectMapModalVisible(false)
            setAddressFormData({
              firstName: '',
              lastName: '',
              streetAddress: '',
              country: country[0],
              state: state[0],
              city: '',
              zipcode: '',
              buildingName: '',
              number: '',
              isDefault: false,
              isPinLocation: false,
              latitude: latLong.lat,
              longitude: latLong.lng,
            })
          }}
        >
          <button>+ Add New Address</button>
        </div>
      </div>
      <div className="button-section">
        <div className="button-end">
          <div className="cancle-button-contain" onClick={() => setIsSelectMapModalVisible(false)}>
            Cancel
          </div>
          <div className="save-button-align" onClick={() => createShippingAddressHandler()}>
            <button>Save and Close</button>
          </div>
        </div>
      </div>
    </div>
  )
}
