import { gql } from '@apollo/client'

const UNSAVED_POST = gql`
  mutation UnSavePost($unSavePostId: Int, $postId: Int) {
    unSavePost(id: $unSavePostId, post_id: $postId) {
      message
      success
    }
  }
`
export default UNSAVED_POST
