import React from 'react';
import MessageIcon from 'public/assets/svg/MessageIcon';
import ShoppingIcon from 'public/assets/svg/ShoppingIcon';
import SocialIcon from 'public/assets/svg/SocialIcon';
import { timeAgo } from 'src/utils/helperFuncs';

interface NotificationFooterProps {
    notification: any;
}

const notificationTypes = [
    { id: 'shopping', label: 'Shopping', icon: <ShoppingIcon small /> },
    { id: 'social', label: 'Social', icon: <SocialIcon small /> },
    { id: 'chat', label: 'Chat', icon: <MessageIcon small /> },
];
const NotificationFooter: React.FC<NotificationFooterProps> = ({ notification }) => {
    const notificatonTypeValue = notificationTypes.find((type) => notification?.payload?.type === type.id);
    return (
        <div className="flex items-center gap-2 text-gray-500 mt-2">
            <div className="flex items-center gap-2">
                {notificatonTypeValue?.icon}
                <span className="text-[13px] font-normal text-[#7A7E88]">{notificatonTypeValue?.label}</span>
                <div className="flex items-center gap-2">
                    <span className='bg-[#7A7E88] h-[4px] w-[4px] rounded-full' />
                    <span className="text-[13px] font-normal text-[#7A7E88]">{timeAgo(new Date((notification?.createdAt)).getTime())}</span>
                </div>
            </div>
        </div>
    );
};

export default NotificationFooter;