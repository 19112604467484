import { Upload, X } from 'lucide-react'
import React, { useState } from 'react'
import { isEmpty } from 'src/utils/isEmpty'

const PersonalInformationSection = ({
  isValid,
  inputValue,
  setInputValue,
  handleNext,
  errors,
  setErrors,
  handleChange,
  user
}) => {
  // const [inputValue, setInputValue] = useState({
  //   firstName: '',
  //   lastName: '',
  //   email: '',
  //   phoneNumber: '',
  // })
  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target
  //   setInputValue((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }))
  // }

  const handleAvatarUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setInputValue((prev) => ({ ...prev, profile: file, profile_image: reader.result as string }))
        setErrors((prev) => ({ ...prev, profile_image: '' }))
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <div className="bg-white rounded-xl p-8 shadow-sm border border-gray-100">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-gray-800">Personal Information</h2>
      </div>

      {/* Avatar Upload */}
      <div className="mb-8">
        <label className="block text-base font-medium text-gray-700 mb-4">
          Profile Picture <span className="text-red-600">*</span>
          {errors.profile_image && <span className="text-sm text-red-600">{errors.profile_image}</span>}
        </label>
        <div className="flex items-center gap-8">
          {inputValue?.profile_image ? (
            <div className="relative">
              <img
                src={inputValue?.profile_image}
                alt="Profile"
                className="w-32 h-32 object-cover rounded-full shadow-sm"
                style={{ width: '128px', height: '128px' }}
              />
              <button
                onClick={() => {
                  setInputValue((prev) => ({ ...prev, profile_image: null }))
                }}
                className="absolute -top-2 -right-2 p-1.5 bg-white rounded-full shadow-md text-gray-500 hover:text-gray-700 transition-colors"
              >
                <X size={16} />
              </button>
            </div>
          ) : (
            <label
              className="w-32 h-32 flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-full cursor-pointer hover:border-indigo-500 transition-colors"
              style={{ width: '128px', height: '128px' }}
            >
              <Upload className="text-gray-400 mb-2" size={24} />
              <span className="text-sm text-gray-500">Upload Photo</span>
              <input type="file" className="hidden" accept="image/*" onChange={handleAvatarUpload} />
            </label>
          )}
          <div className="text-sm text-gray-600">
            <p className="font-medium mb-2">Photo Requirements:</p>
            <ul className="list-disc list-inside space-y-1">
              <li>Minimum size: 400x400 pixels</li>
              <li>File format: PNG or JPG</li>
              <li>Maximum size: 2MB</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-base font-medium text-gray-700 mb-2">
            First Name <span className="text-red-600">*</span>
            {errors.firstName && <span className="text-sm text-red-600">{errors.firstName}</span>}
          </label>
          <input
            type="text"
            name="firstName"
            value={inputValue.firstName}
            onChange={handleChange}
            className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            placeholder="Enter your first name"
          />
        </div>

        <div>
          <label className="block text-base font-medium text-gray-700 mb-2">
            Last Name <span className="text-red-600">*</span>
            {errors.lastName && <span className="text-sm text-red-600">{errors.lastName}</span>}
          </label>
          <input
            type="text"
            name="lastName"
            value={inputValue.lastName}
            onChange={handleChange}
            className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            placeholder="Enter your last name"
          />
        </div>

        <div>
          <label className="block text-base font-medium text-gray-700 mb-2">Email Address</label>
          <input
            type="email"
            name="email"
            value={inputValue.email}
            disabled
            className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent cursor-not-allowed"
            placeholder="Enter your email address"
          />
        </div>

        <div>
          <label className="block text-base font-medium text-gray-700 mb-2">
            Phone Number <span className="text-red-600">*</span>
            {errors.phoneNumber && <span className="text-sm text-red-600">{errors.phoneNumber}</span>}
          </label>
          <input
            type="tel"
            name="phoneNumber"
            value={inputValue.phoneNumber}
            onChange={handleChange}
            className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            placeholder="Enter your phone number"
          />
        </div>
      </div>
      {!isEmpty(user) && (
        <div className="flex justify-end mt-5">
          <button
            onClick={handleNext}
            className={`px-8 py-3 rounded-lg transition-colors bg-indigo-600 hover:bg-indigo-700 text-white`}
          >
            Save Changes
          </button>
        </div>
      )}
    </div>
  )
}

export default PersonalInformationSection
