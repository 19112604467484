import React, { useEffect, useRef, useState } from 'react'
import styles from './AddProductModalVariant.module.scss'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
import VariantData from './VariantData'
import AddProductShipping from '../AddProductShipping'
import classNames from 'classnames'
import { fetchModalData } from 'src/store/slices/manageModalSlice'

export default function AddProductModalVariant() {
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const [variantData, setVariantData] = useState(addProductDetailSlice?.id?.variant?.allOptions || [])
  const [newVariantData, setNewVariantData] = useState(addProductDetailSlice?.id?.variant?.allOptions || [])

  const transformedArray = addProductDetailSlice?.id?.variant?.allVariant?.map((item) => {
    if (item.name === 'Color') {
      const colorData = item?.data?.map((colorItem) => colorItem?.name)
      return { name: item?.name, data: colorData }
    } else {
      return item
    }
  })
  const [filterdDropdown, setFilterdDropdown] = useState(transformedArray || [])
  const [isEditable, setIsEditable] = useState({
    isEditable: false,
    index: null,
    data: null,
  })

  const [IbsDropdown, setIbsDropdown] = useState(false)
  const [InDropdown, setInDropdown] = useState(false)
  const productRef: any = useRef()

  const [otherDropdown, setOtherDropdown] = useState({})
  const [dropdownIndex, setDropdownIndex] = useState(null)
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([])
  const filterRef: any = useRef()
  const [sizeDropdown2, setSizeDropdown2] = useState(false)
  const [colorDropdown2, setColorDropdown2] = useState(false)

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (IbsDropdown && productRef.current && !productRef.current.contains(e.target)) {
        setIbsDropdown(false)
      }
      if (InDropdown && productRef.current && !productRef.current.contains(e.target)) {
        setInDropdown(false)
      }
      if (otherDropdown && filterRef.current && !filterRef.current.contains(e.target)) {
        setOtherDropdown((prevOpenDropdowns) => ({
          ...prevOpenDropdowns,
          [dropdownIndex]: false,
        }))
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [IbsDropdown, InDropdown, otherDropdown])

  const handleDropdownClick = (index) => {
    if (dropdownIndex !== index) {
      setOtherDropdown((prevOpenDropdowns) => ({
        ...prevOpenDropdowns,
        [dropdownIndex]: false,
      }))
    }

    setDropdownIndex(index)
    setOtherDropdown((prevOpenDropdowns) => ({
      ...prevOpenDropdowns,
      [index]: true,
    }))
  }

  const handleFilteredBox = (e, data) => {
    const checkboxValue = e.target.checked

    let updatedSelectedCheckboxes = [...selectedCheckboxes]

    if (checkboxValue) {
      if (!updatedSelectedCheckboxes.includes(data)) {
        updatedSelectedCheckboxes.push(data)
      }
    } else {
      updatedSelectedCheckboxes = updatedSelectedCheckboxes.filter((item) => item !== data)
    }
    setSelectedCheckboxes(updatedSelectedCheckboxes)
    const updatedData = newVariantData.map((item) => {
      const isChecked = updatedSelectedCheckboxes.some((selectedData) => item.total_variant.includes(selectedData))
      return { ...item, isChecked }
    })

    setNewVariantData(updatedData)
    const currentDropdownData = filterdDropdown[dropdownIndex].data
    const isAllChecked = currentDropdownData.every((data) => updatedSelectedCheckboxes.includes(data))

    if (isAllChecked) {
      const allData = filterdDropdown.reduce((acc, dropdown) => [...acc, ...dropdown.data], [])
      setSelectedCheckboxes(allData)
      updateAllCheckboxes(true)
    }
  }

  const updateAllCheckboxes = (checked) => {
    const allData = filterdDropdown.reduce((acc, dropdown) => [...acc, ...dropdown.data], [])
    const updatedData = newVariantData.map((item) => ({
      ...item,
      isChecked: checked,
    }))
    setNewVariantData(updatedData)
    setSelectedCheckboxes(checked ? allData : [])
  }

  const handleSelectUnit = async (un) => {
    let finalData = []
    finalData = newVariantData.map((item, i) => {
      if (i === isEditable.index) {
        return { ...item, weightUnit: un?.name, unit: un?.unit === 'lb' ? 'in' : 'cm' }
      }
      return item
    })

    setNewVariantData(finalData)
  }

  // -----------------------------------Shipping---------------------------------------------
  useEffect(() => {
    setVariantData(addProductDetailSlice?.id.variant.allOptions)
    setNewVariantData(addProductDetailSlice?.id.variant.allOptions)
  }, [addProductDetailSlice?.id?.variant?.allOptions])

  useEffect(() => {
    const transformedArray = addProductDetailSlice?.id?.variant?.allVariant?.map((item) => {
      if (item.name === 'Color') {
        const colorData = item?.data?.map((colorItem) => colorItem?.name)
        return { name: item?.name, data: colorData }
      } else {
        return item
      }
    })
    setFilterdDropdown(transformedArray)
  }, [addProductDetailSlice?.id?.variant?.allVariant])

  const handleVariantDataChange = async (index, field, value) => {
    let finalData = []
    if (field === 'price') {
      finalData = variantData.map((item, i) => {
        if (i === index) {
          return { ...item, price: value }
        }
        return item
      })
    } else if (field === 'inventory_quantity') {
      finalData = variantData.map((item, i) => {
        if (i === index) {
          return { ...item, inventory_quantity: parseInt(value) }
        }
        return item
      })
    } else if (field === 'sku') {
      finalData = variantData.map((item, i) => {
        if (i === index) {
          return { ...item, sku: value }
        }
        return item
      })
    } else if (field === 'compare_at_price') {
      finalData = variantData.map((item, i) => {
        if (i === index) {
          return { ...item, compare_at_price: value }
        }
        return item
      })
    } else if (field === 'image') {
      finalData = variantData.map((item, i) => {
        if (i === index) {
          return { ...item, image_id: value }
        }
        return item
      })
    }

    const isCheckedRemoved = finalData.map((variant) => {
      const { isChecked, ...rest } = variant
      return rest
    })

    // const prices = isCheckedRemoved.map((item) => item.price)

    const filteredPrices = isCheckedRemoved
      .filter((item) => parseFloat(item.price) > 0) // Filter out items with price less than or equal to 0
      .map((item) => parseFloat(item.price)) // Map prices to numbers

    const smallestPrice = filteredPrices.reduce((min, price) => (price < min ? price : min), filteredPrices[0])
    // if (filteredPrices.length > 0) {

    // } else {
    // }

    // let selectedVariant = getSelectedVariant(isCheckedRemoved)

    await dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          variant: {
            ...addProductDetailSlice?.id.variant,
            allOptions: isCheckedRemoved,
          },
          productPreview: {
            ...addProductDetailSlice?.id?.productPreview,
            price: smallestPrice ? smallestPrice : 0,
          },
        },
      }),
    )
  }

  const getSelectedVariant = async (variantArray) => {
    if (variantArray.length === 0) {
      return null
    }

    const isSamePrice = await variantArray.every((variant, index) => {
      return index === 0 || variant.price === variantArray[0].price
    })

    if (isSamePrice) {
      return variantArray[0]
    } else {
      let cheapestVariant = variantArray[0]
      for await (const variant of variantArray) {
        if (parseFloat(variant.price) < parseFloat(cheapestVariant.price)) {
          cheapestVariant = variant
        }
      }
      return cheapestVariant
    }
  }

  const handleNewVariantData = async (index, field, value) => {
    let finalData = []
    if (field === 'price') {
      finalData = newVariantData.map((item, i) => {
        if (i === index) {
          return { ...item, price: value }
        }
        return item
      })
    } else if (field === 'inventory_quantity') {
      finalData = newVariantData.map((item, i) => {
        if (i === index) {
          return { ...item, inventory_quantity: parseInt(value) }
        }
        return item
      })
    } else if (field === 'sku') {
      finalData = newVariantData.map((item, i) => {
        if (i === index) {
          return { ...item, sku: value }
        }
        return item
      })
    }
    //  else if (field === 'compare_at_price') {
    //   finalData = newVariantData.map((item, i) => {
    //     if (i === index) {
    //       return { ...item, compare_at_price: value }
    //     }
    //     return item
    //   })
    // }
    else if (field === 'weightValue') {
      finalData = newVariantData.map((item, i) => {
        if (i === index) {
          return { ...item, [field]: value }
        }
        return item
      })
    } else if (field === 'isTaxable') {
      finalData = newVariantData.map((item, i) => {
        if (i === index) {
          return { ...item, isTaxable: value }
        }
        return item
      })
    } else if (field === 'barcode') {
      finalData = newVariantData.map((item, i) => {
        if (i === index) {
          return { ...item, barcode: value }
        }
        return item
      })
    } else {
      finalData = newVariantData.map((item, i) => {
        if (i === index) {
          return { ...item, [field]: parseInt(value) }
        }
        return item
      })
    }

    setNewVariantData(finalData)
    setIsEditable({ ...isEditable, data: finalData[isEditable.index] })
  }

  const handleSaveNewVariant = async () => {
    let finalData = []
    finalData = newVariantData.map((item, i) => {
      if (i === isEditable.index) {
        return { ...item, ...isEditable.data }
      }
      return item
    })

    const isCheckedRemoved = finalData.map((variant) => {
      const { isChecked, ...rest } = variant
      return rest
    })

    await dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          variant: {
            ...addProductDetailSlice?.id.variant,
            allOptions: isCheckedRemoved,
          },
        },
      }),
    )
  }

  const handleResetInfo = async () => {
    const indexToClear = isEditable.index // Index to clear (zero-based)

    // Properties to clear
    const propertiesToClear = [
      'price',
      // 'listPrice',
      'inventory_quantity',
      'sku',
      'barcode',
      'weightValue',
      'length',
      'width',
      'height',
    ]

    const clearedArray = newVariantData.map((item, index) => {
      if (index === indexToClear) {
        const clearedItem = { ...item }
        propertiesToClear.forEach((property) => {
          clearedItem[property] = 0
          clearedItem.weightUnit = 'pound' // Set weightUnit to "pound"
          clearedItem.unit = 'in'
          clearedItem.isTaxable = false
        })
        return clearedItem
      } else {
        return item
      }
    })
    setNewVariantData(clearedArray)
  }

  const outputArray = []

  function generateVariants(currentVariant, currentIndex, finalData) {
    if (currentIndex === finalData?.length) {
      outputArray.push({
        total_variant: currentVariant,
        // weightValue: '200',
        // weightUnit: 'pound',
        // length: 2,
        // width: 2,
        // height: 2,
        // unit: 'inch',
        // inventory_quantity: 100,
        // old_inventory_quantity: 200,
        // isTaxable: true,
        // grams: 530,
        // price: '50',
        // sku: 'dadsdadasdasd',
        // barcode: '5151511565656',
      })
      return
    }

    const currentVariants = finalData[currentIndex].data
    for (const variant of currentVariants) {
      const newVariant = [...currentVariant, variant]
      generateVariants(newVariant, currentIndex + 1, finalData)
    }
  }

  const handleDelete = async (index) => {
    const allVariant = addProductDetailSlice?.id?.variant?.allVariant.filter((data, ind) => ind !== index)
    const arrayForColorObj = allVariant.map((item) => {
      const name = item.name.toLowerCase() // Convert the name to lowercase
      const data = item.data.map((entry) => {
        if (typeof entry === 'object' && entry.name) {
          return entry.name // Extract the "name" property if it's an object
        }
        return entry
      })

      return { name, data }
    })

    await generateVariants([], 0, arrayForColorObj)

    // if (outputArray.length === 1 && outputArray[0]?.total_variant?.length === 0) {
    //   for (let i = 0; i < outputArray.length; i++) {
    //     if (outputArray[i].hasOwnProperty('total_variant')) {
    //       delete outputArray[i].total_variant
    //     }
    //   }
    // }

    dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          variant: {
            ...addProductDetailSlice?.id?.variant,
            allVariant: addProductDetailSlice?.id?.variant?.allVariant?.filter((data, ind) => ind !== index),
            allFinalVariant: addProductDetailSlice?.id?.variant?.allVariant?.filter((data, ind) => ind !== index),
            colorCodeArray: addProductDetailSlice?.id?.variant?.colorCodeArray?.filter((data, ind) => ind !== index),
            allOptions: outputArray,
          },
        },
      }),
    )
  }

  const editSizevariant = (variant, index) => {
    if (variant?.name === 'size' || variant?.name === 'SIZE') {
      // setDataEdit({ size: { ...variant, data: [...variant.data, ''] }, index: index })
      // setAddSizeModalOpen(true)
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            variant: {
              ...addProductDetailSlice?.id?.variant,
              dataEdit: { size: { ...variant, data: [...variant.data, ''] }, index: index },
              isAddSizeModalOpen: true,
            },
          },
        }),
      )
    } else if (variant?.name === 'Color' || variant?.name === 'color') {
      // setDataEdit({ color: { ...variant, data: [...variant.data, { name: '', colorCode: '' }] }, index: index })
      // setAddColorModalOpen(true)
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            variant: {
              ...addProductDetailSlice?.id?.variant,
              dataEdit: { color: { ...variant, data: [...variant.data, { name: '', colorCode: '' }] }, index: index },
              isAddColorModalOpen: true,
            },
          },
        }),
      )
    } else {
      // setDataEdit({ other: { ...variant, data: [...variant.data, ''] }, index: index })
      // setAddOtherVariantModalOpen(true)
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            variant: {
              ...addProductDetailSlice?.id?.variant,
              dataEdit: { other: { ...variant, data: [...variant.data, ''] }, index: index },
              isAddOtherVariantModalOpen: true,
            },
          },
        }),
      )
    }
  }

  const editSizevariantMobile = (variant, index) => {
    if (variant?.name === 'SIZE') {
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            variant: {
              ...addProductDetailSlice?.id?.variant,
              dataEdit: { size: { ...variant, data: [...variant.data] }, index: index },
            },
          },
        }),
      )

      dispatch(
        fetchMobileModalData({
          allModalData: [
            ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
            {
              modal: 'addSizeVariant',
              isOpen: true,
              id: 0,
              transform: 20,
            },
          ],
          lastModalData: fetchMobileModal?.lastModalData,
        }),
      )
    } else if (variant?.name === 'Color') {
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            selectedColorArray: [],
            selectedColor: '',
            selectedText: '',
            variant: {
              ...addProductDetailSlice?.id?.variant,
              dataEdit: { color: { ...variant, data: [...variant.data] }, index: index },
            },
          },
        }),
      )
      dispatch(
        fetchMobileModalData({
          allModalData: [
            ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
            {
              modal: 'addAddColorVariantMobile',
              isOpen: true,
              id: 0,
              transform: 20,
            },
          ],
          lastModalData: fetchMobileModal?.lastModalData,
        }),
      )
    } else {
      // setDataEdit({ other: { ...variant, data: [...variant.data, ''] }, index: index })
      // setAddOtherVariantModalOpen(true)
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            variant: {
              ...addProductDetailSlice?.id?.variant,
              dataEdit: { other: { ...variant, data: [...variant.data, ''] }, index: index },
              isAddOtherVariantModalOpen: true,
            },
          },
        }),
      )
    }
    // if (variant?.name === 'SIZE') {
    //   dispatch(
    //     manageAddProductDetail({
    //       modal: '',
    //       isOpen: false,
    //       id: {
    //         ...addProductDetailSlice?.id,
    //         variant: {
    //           ...addProductDetailSlice?.id?.variant,
    //           dataEdit: { size: { ...variant, data: [...variant.data, ''] }, index: index },
    //           isAddSizeModalOpen: true,
    //         },
    //       },
    //     }),
    //   )
    // } else if (variant?.name === 'Color') {
    //   dispatch(
    //     manageAddProductDetail({
    //       modal: '',
    //       isOpen: false,
    //       id: {
    //         ...addProductDetailSlice?.id,
    //         variant: {
    //           ...addProductDetailSlice?.id?.variant,
    //           dataEdit: { color: { ...variant, data: [...variant.data, { name: '', colorCode: '' }] }, index: index },
    //           isAddColorModalOpen: true,
    //         },
    //       },
    //     }),
    //   )
    // } else {
    //   dispatch(
    //     manageAddProductDetail({
    //       modal: '',
    //       isOpen: false,
    //       id: {
    //         ...addProductDetailSlice?.id,
    //         variant: {
    //           ...addProductDetailSlice?.id?.variant,
    //           dataEdit: { other: { ...variant, data: [...variant.data, ''] }, index: index },
    //           isAddOtherVariantModalOpen: true,
    //         },
    //       },
    //     }),
    //   )
    // }
  }

  const handleAddVariantModal = () => {
    dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          variant: {
            ...addProductDetailSlice?.id?.variant,
            isAddVariantModal: true,
          },
        },
      }),
    )
  }

  return (
    <div className={styles.addProductModalVariantSection}>
      <div className={styles.addProductModalVariantBox}>
        <div className={styles.addProductTopBoxAlignment}>
          <div className={styles.addProductModalHeadingFlex}>
            <div className={styles.addProductHeadingAlignment}>
              <h4>Product Variant</h4>
              <div className={styles.addProductNotesAlignment}>
                <span>Add option (i.e Size, Color) to to create Product Variant</span>
              </div>
            </div>
            <div className={styles.addProductButtonAlignment}>
              <a>Cancel</a>
              <div className={styles.saveChangesButtonAlignment}>
                <button>Save Change</button>
              </div>
            </div>
          </div>
          <div>
            {addProductDetailSlice?.id?.variant?.allVariant?.length > 0 ? (
              // {addProductDetailSlice?.id?.variant?.allFinalVariant?.length > 0 ? (
              <div className={styles.variantInfoMain}>
                {addProductDetailSlice?.id?.variant?.allVariant?.map((variant, index) => {
                  // {addProductDetailSlice?.id?.variant?.allFinalVariant?.map((variant, index) => {
                  let joinData =
                    variant.name === 'Color'
                      ? variant?.data?.map((data) => data?.name).join(', ')
                      : variant?.data?.map((data) => data).join(', ')
                  return (
                    <div className={styles.sizeColorContainer}>
                      <div className={styles.nameVariantSection}>
                        <div className={styles.sizeTitle}>
                          <span>{variant.name}</span>{' '}
                        </div>
                        <div className={styles.sizeInput}>
                          <span>{joinData?.length > 43 ? joinData.substring(0, 43) + '...' : joinData}</span>
                        </div>
                      </div>
                      <div className={styles.removeEditSection}>
                        <div className={styles.removeVariant} onClick={() => handleDelete(index)}>
                          <span>Remove</span>
                        </div>
                        <div className={styles.editVariant} onClick={() => editSizevariant(variant, index)}>
                          <span>Edit</span>
                        </div>
                        <div
                          className={styles.mobieViewEditVariant}
                          onClick={() => editSizevariantMobile(variant, index)}
                        >
                          <span>Edit</span>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              // <div className={styles.noVariantAlignment}>No Variant Added</div>
              ''
            )}
          </div>
          <div className={styles.addVariatButtonAlignment}>
            {/* <button onClick={() => setAddVariantModal(true)}> */}
            <button className={styles.webViewButtonAlignment} onClick={() => handleAddVariantModal()}>
              <img src="/assets/seller/icons/plus-icon.svg" alt="PlusIcon" />
              Add Option
            </button>

            <button
              className={styles.mobileViewButtonAlignment}
              onClick={() =>
                dispatch(
                  fetchMobileModalData({
                    allModalData: [
                      ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                      {
                        modal: 'addVariantMobile',
                        isOpen: true,
                        id: 0,
                        transform: 7,
                      },
                    ],
                    lastModalData: fetchMobileModal?.lastModalData,
                  }),
                )
              }
            >
              <img src="/assets/seller/icons/plus-icon.svg" alt="PlusIcon" />
              Add Variants
            </button>
          </div>
        </div>
        <div className={styles.allProductDetailsAlignment}>
          <div className={styles.allProductDetailsBoxAlignment}>
            {/* <div className={styles.allProductVariantDetailsHeading}>
              <h4>Variant</h4>
            </div> */}
            {/* <div className={styles.allProdutcTabAlignment}>
              <div className={styles.allProdutcVariantTabBox} onClick={() => setSizeDropdown2(!sizeDropdown2)}>
                <p>All size</p>
                <img
                  src="/assets/icon/drop-down-icon-blue.svg"
                  alt="drop-down-icon"
                  className={sizeDropdown2 ? styles.productActiveArrow : ''}
                />
                <div
                  className={
                    sizeDropdown2
                      ? classNames(styles.allProductVarintTabDropdownBox, styles.openVariantDropdown)
                      : classNames(styles.allProductVarintTabDropdownBox, styles.closeVariantDropdown)
                  }
                >
                  <div className={styles.allProdutctoptionAlignment}>
                    <p>All Size</p>
                  </div>
                  <div className={styles.allProdutctoptionAlignment}>
                    <p>S</p>
                  </div>
                  <div className={styles.allProdutctoptionAlignment}>
                    <p>M</p>
                  </div>
                  <div className={styles.allProdutctoptionAlignment}>
                    <p>L</p>
                  </div>
                </div>
              </div>
              <div className={styles.allProdutcVariantTabBox} onClick={() => setColorDropdown2(!colorDropdown2)}>
                <p>All color</p>
                <img
                  src="/assets/icon/drop-down-icon-blue.svg"
                  alt="drop-down-icon"
                  className={colorDropdown2 ? styles.productActiveArrow : ''}
                />

                <div
                  className={
                    colorDropdown2
                      ? classNames(
                          styles.allProductVarintTabDropdownBox,
                          styles.colorWidthAlignment,
                          styles.openVariantDropdown,
                        )
                      : classNames(
                          styles.allProductVarintTabDropdownBox,
                          styles.colorWidthAlignment,
                          styles.closeVariantDropdown,
                        )
                  }
                >
                  <div className={styles.allProdutctoptionAlignment}>
                    <div className={styles.colorBoxAlignment}></div>
                    <p>All Color</p>
                  </div>
                  <div className={styles.allProdutctoptionAlignment}>
                    <div className={styles.colorBoxAlignment}></div>
                    <p>Red</p>
                  </div>
                  <div className={styles.allProdutctoptionAlignment}>
                    <div className={styles.colorBoxAlignment}></div>
                    <p>Blue</p>
                  </div>
                  <div className={styles.allProdutctoptionAlignment}>
                    <div className={styles.colorBoxAlignment}></div>
                    <p>Orange</p>
                  </div>
                </div>
              </div>
              <div className={styles.allProdutcVariantTabBox}>
                <p>All Material</p>
                <img src="/assets/icon/drop-down-icon-blue.svg" alt="drop-down-icon" />
              </div>
            </div> */}

            {/* <div className={styles.addProductTableAlignment}>
              {addProductDetailSlice?.id?.variant?.allOptions?.length !== 1 &&
              addProductDetailSlice?.id?.variant?.allOptions[0]?.total_variant?.length !== 0 ? (
                <div style={{ width: '100%', position: 'relative', zIndex: '999' }}>
                  {addProductDetailSlice?.id?.variant?.allOptions?.map((variant, index) => {
                    return (
                      <VariantData
                        // variant={variant}
                        // index={index}
                        variantData={variantData}
                        setVariantData={setVariantData}
                        isEditable={isEditable}
                        setIsEditable={setIsEditable}
                        handleVariantDataChange={handleVariantDataChange}
                      />
                    )
                  })}
                </div>
              ) : (
                <></>
              )}
            </div> */}

            <div className={styles.addProductTableAlignment}>
              {addProductDetailSlice?.id?.variant?.allOptions?.[0]?.total_variant.length > 0 ? (
                <div style={{ width: '100%', position: 'relative', zIndex: '999' }}>
                  {/* {addProductDetailSlice?.id?.variant?.allOptions?.map((variant, index) => {
                    return ( */}
                  <VariantData
                    // variant={variant}
                    // index={index}
                    variantData={variantData}
                    setVariantData={setVariantData}
                    isEditable={isEditable}
                    setIsEditable={setIsEditable}
                    handleVariantDataChange={handleVariantDataChange}
                  />
                  {/* )
                  })} */}
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className={styles.mobileViewMangeProductAlignment}>
              <h4>Manage Product Variant</h4>
              <div
                onClick={() => {
                  dispatch(
                    fetchModalData({
                      allModalData: [
                        ...getAllState?.allModalData,
                        { modal: 'productsVariantData', isOpen: true, id: 0, modalFor: 'mediumModal', isEdit: false },
                      ],
                      lastModalData: getAllState?.lastModalData,
                    }),
                  )
                }}
                className={styles.moreManageMobileViewAlignment}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M8.75 15L13.75 10L8.75 5"
                    stroke="#7A7E88"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        {/* <VariantData /> */}
      </div>

      {/* {addProductDetailSlice?.id?.variant?.isVariantModel && isEditable?.isEditable && (
        <div>
          <div
            style={{
              display: 'flex',
              gap: '25px',
              marginBottom: '20px',
              marginTop: '10px',
            }}
          >
            <div style={{ display: 'flex', gap: ' 15px', alignItems: 'center', justifyContent: 'center' }}>
              {filterdDropdown?.map((item, index) => {
                return (
                  <>
                    <span onClick={() => handleDropdownClick(index)}>All {item?.name}</span>
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                      <div>
                        <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M1 1L4 4L7 1"
                            stroke="#ACB1C0"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        {otherDropdown[index] && (
                          <div
                            style={{ background: 'white' }}
                            className={styles.unitDetailsDropdownBoxAlignment}
                            ref={filterRef}
                          >
                            {filterdDropdown[dropdownIndex].data?.map((data, i) => {
                              return (
                                <>
                                  <div style={{ display: 'flex', gap: '15px' }}>
                                    <input
                                      type="checkbox"
                                      onChange={(e) => handleFilteredBox(e, data)}
                                      checked={selectedCheckboxes.includes(data)}
                                    />
                                    <p>{data}</p>
                                  </div>
                                </>
                              )
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
          </div>

          <div style={{ display: 'flex', gap: '20px' }}>
            <div>
              {addProductDetailSlice?.id?.variant?.allOptions?.map((item, index) => {
                return (
                  <>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <input type="checkbox" checked={newVariantData[index]?.isChecked ?? false} />
                      <div
                        style={{ display: 'flex', gap: '10px' }}
                        onClick={() =>
                          setIsEditable({
                            ...isEditable,
                            data: item,
                            index: index,
                          })
                        }
                      >
                        <img
                          src={
                            item?.image_id ? URL?.createObjectURL(item?.image_id) : '/assets/seller/icons/plus-icon.svg'
                          }
                          alt="PlusIcon"
                          width="50px"
                          height="50px"
                        />
                        <p>
                          {item?.total_variant?.map((data, idx) => {
                            return idx === item.total_variant.length - 1 ? data : data + ','
                          })}
                        </p>
                      </div>
                    </div>
                  </>
                )
              })}
            </div>

            <div>
              <div style={{ display: 'flex', gap: '25px' }}>
                <img
                  src={
                    newVariantData[isEditable?.index]?.image_id
                      ? URL?.createObjectURL(newVariantData?.[isEditable?.index]?.image_id)
                      : '/assets/seller/icons/plus-icon.svg'
                  }
                  alt="PlusIcon"
                  width="50px"
                  height="50px"
                />
                <p>
                  {isEditable?.data?.total_variant?.map((item, idx) => {
                    return idx === isEditable?.data?.total_variant.length - 1 ? item : item + ','
                  })}
                </p>
                <button onClick={() => handleResetInfo()}>Reset Information</button>
                <button onClick={() => handleSaveNewVariant()}>Save</button>
              </div>
              <h1>Inventory & Pricing</h1>
              <div className={styles.addProductInventoryPricingdDetails}>
                <div>
                  <div style={{ display: 'flex', gap: '20px' }}>
                    <div className={styles.formInputDetailsAlignment}>
                      <label>
                        Price <span>*</span>
                      </label>
                      <div style={{ display: 'flex' }}>
                        <input
                          type="text"
                          className={styles.secondInputAlignment}
                          onChange={(e) => handleNewVariantData(isEditable.index, e.target.name, e.target.value)}
                          name="price"
                          value={newVariantData[isEditable.index]?.price ?? ''}
                        />{' '}
                        <div className={styles.iconAlignment2}>
                          <span>$</span>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div style={{ display: 'flex', gap: '20px' }}>
                    <div className={styles.formInputDetailsAlignment}>
                      <label>
                        Quantity <span>*</span>
                      </label>
                      <input
                        // required
                        type="number"
                        onChange={(e) => handleNewVariantData(isEditable.index, e.target.name, e.target.value)}
                        name="inventory_quantity"
                        value={newVariantData[isEditable.index]?.inventory_quantity ?? 0}
                      />
                    </div>
                    <div className={styles.formInputDetailsAlignment}>
                      <label>SKU</label>
                      <input
                        // required
                        type="text"
                        onChange={(e) => handleNewVariantData(isEditable.index, e.target.name, e.target.value)}
                        name="sku"
                        value={newVariantData[isEditable.index]?.sku ?? ''}
                      />
                    </div>
                  </div>
                  <div className={styles.formInputDetailsAlignment}>
                    <label>Barcode</label>
                    <input
                      // required
                      type="text"
                      onChange={(e) => handleNewVariantData(isEditable.index, e.target.name, e.target.value)}
                      name="barcode"
                      value={newVariantData[isEditable.index]?.barcode ?? ''}
                    />
                  </div>

                  <div className={styles.formInputDetailsAlignment}>
                    <input
                      type="checkbox"
                      id="isTaxable"
                      name="isTaxable"
                      // defaultChecked={false}
                      checked={newVariantData[isEditable.index]?.isTaxable ?? false}
                      value={newVariantData[isEditable.index]?.isTaxable ?? false}
                      onChange={(e) =>
                        handleNewVariantData(isEditable.index, e.target.name, e.target.checked ? true : false)
                      }
                    />
                    <label htmlFor="isTaxable"> Charge tax on this variant</label>
                  </div>
                  <div></div>
                </div>
              </div>

              <div style={{ marginTop: '25px' }}>
                <div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h4>Shipping</h4>
                    <div className={styles.shippingHeadingOtherOptionAlignment}>
                      <span>Unit</span>
                      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                        <div onClick={() => setIbsDropdown(!IbsDropdown)}>
                          <p>{newVariantData?.shipping?.weight?.name}</p>
                          <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M1 1L4 4L7 1"
                              stroke="#ACB1C0"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          {IbsDropdown && (
                            <div
                              style={{ background: 'red' }}
                              className={styles.unitDetailsDropdownBoxAlignment}
                              ref={productRef}
                            >
                              {unitObject?.map((o) => {
                                return (
                                  <div
                                    className={`${styles.unitDetailsDropdownOption} ${
                                      newVariantData?.shipping?.weight?.unit === o?.unit ? styles.selected : ''
                                    }`}
                                    onClick={() => handleSelectUnit(o)}
                                  >
                                    <p>{o?.unit}</p>
                                  </div>
                                )
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.addProductShippingFormAlignment}>
                    <div>
                      <label>Item weight</label>
                      <div className={styles.formInputRelative}>
                        <input
                          type="text"
                          name="weightValue"
                          className={styles.otherInputpadding}
                          onChange={(e) => handleNewVariantData(isEditable.index, e.target.name, e.target.value)}
                          value={newVariantData[isEditable.index]?.weightValue ?? ''}
                        />
                        <div className={styles.iconAlignment}>
                          <span>{newVariantData[isEditable.index]?.weightUnit}</span>
                        </div>
                      </div>
                    </div>
                    <div className={styles.threeGridAlignment}>
                      <div className={styles.formInputDetailsAlignment}>
                        <label>Length</label>
                        <div className={styles.formInputRelative}>
                          <input
                            type="text"
                            name="length"
                            className={styles.childInputPadding}
                            onChange={(e) => handleNewVariantData(isEditable.index, e.target.name, e.target.value)}
                            value={newVariantData[isEditable.index]?.length ?? ''}
                          />
                          <div className={styles.iconAlignment}>
                            <span>{newVariantData[isEditable.index]?.unit}</span>
                          </div>
                        </div>
                      </div>
                      <div className={styles.formInputDetailsAlignment}>
                        <label>Width</label>
                        <div className={styles.formInputRelative}>
                          <input
                            type="text"
                            name="width"
                            className={styles.childInputPadding}
                            onChange={(e) => handleNewVariantData(isEditable.index, e.target.name, e.target.value)}
                            value={newVariantData[isEditable.index]?.width ?? ''}
                          />
                          <div className={styles.iconAlignment}>
                            <span>{newVariantData[isEditable.index]?.unit}</span>
                          </div>
                        </div>
                      </div>
                      <div className={styles.formInputDetailsAlignment}>
                        <label>Height</label>
                        <div className={styles.formInputRelative}>
                          <input
                            type="text"
                            name="height"
                            className={styles.childInputPadding}
                            onChange={(e) => handleNewVariantData(isEditable.index, e.target.name, e.target.value)}
                            value={newVariantData[isEditable.index]?.height ?? ''}
                          />
                          <div className={styles.iconAlignment}>
                            <span>{newVariantData[isEditable.index]?.unit}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </div>
  )
}
