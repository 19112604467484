export default function SocialIcon({ small }: { small?: boolean }) {
    return (
        small ?
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <g clip-path="url(#clip0_602_449)">
                    <circle cx="4.33317" cy="9.66691" r="1.16667" stroke="#7A7E88" />
                    <path d="M6.83301 13.6669C6.83301 13.8631 6.68396 14.1459 6.21151 14.4083C5.75599 14.6614 5.09282 14.8336 4.33301 14.8336C3.57319 14.8336 2.91003 14.6614 2.45451 14.4083C1.98205 14.1459 1.83301 13.8631 1.83301 13.6669C1.83301 13.4707 1.98205 13.188 2.45451 12.9255C2.91003 12.6724 3.57319 12.5002 4.33301 12.5002C5.09282 12.5002 5.75599 12.6724 6.21151 12.9255C6.68396 13.188 6.83301 13.4707 6.83301 13.6669Z" stroke="#7A7E88" />
                    <circle cx="3.33333" cy="2.00033" r="0.833333" stroke="#7A7E88" />
                    <path d="M5.50008 5.33358C5.50008 5.41035 5.42485 5.61446 4.99543 5.82917C4.59591 6.02893 4.009 6.16691 3.33341 6.16691C2.65783 6.16691 2.07092 6.02893 1.6714 5.82917C1.24198 5.61446 1.16675 5.41035 1.16675 5.33358C1.16675 5.2568 1.24198 5.05269 1.6714 4.83798C2.07092 4.63823 2.65783 4.50024 3.33341 4.50024C4.009 4.50024 4.59591 4.63823 4.99543 4.83798C5.42485 5.05269 5.50008 5.2568 5.50008 5.33358Z" stroke="#7A7E88" />
                    <circle cx="11.3333" cy="4.66772" r="1.5" stroke="#7A7E88" />
                    <path d="M14.8333 9.33423C14.8333 9.5951 14.6088 9.96588 13.9381 10.3012C13.2973 10.6216 12.377 10.8342 11.3333 10.8342C10.2895 10.8342 9.36923 10.6216 8.72843 10.3012C8.05773 9.96588 7.83325 9.5951 7.83325 9.33423C7.83325 9.07336 8.05773 8.70258 8.72843 8.36723C9.36923 8.04683 10.2895 7.83423 11.3333 7.83423C12.377 7.83423 13.2973 8.04683 13.9381 8.36723C14.6088 8.70258 14.8333 9.07336 14.8333 9.33423Z" stroke="#7A7E88" />
                </g>
                <defs>
                    <clipPath id="clip0_602_449">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            :
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                <g clip-path="url(#clip0_341_1696)">
                    <circle cx="4.06201" cy="9.0625" r="1.0625" stroke="#636773" />
                    <path d="M6.37451 12.8125C6.37451 12.9799 6.24645 13.2367 5.80793 13.4803C5.38634 13.7145 4.77005 13.875 4.06201 13.875C3.35397 13.875 2.73768 13.7145 2.3161 13.4803C1.87757 13.2367 1.74951 12.9799 1.74951 12.8125C1.74951 12.6451 1.87757 12.3883 2.3161 12.1447C2.73768 11.9105 3.35397 11.75 4.06201 11.75C4.77005 11.75 5.38634 11.9105 5.80793 12.1447C6.24645 12.3883 6.37451 12.6451 6.37451 12.8125Z" stroke="#636773" />
                    <circle cx="3.125" cy="1.875" r="0.75" stroke="#636773" />
                    <path d="M5.125 5C5.125 5.05376 5.06842 5.23704 4.66916 5.43667C4.29981 5.62135 3.75456 5.75 3.125 5.75C2.49544 5.75 1.95019 5.62135 1.58084 5.43667C1.18158 5.23704 1.125 5.05376 1.125 5C1.125 4.94624 1.18158 4.76296 1.58084 4.56333C1.95019 4.37865 2.49544 4.25 3.125 4.25C3.75456 4.25 4.29981 4.37865 4.66916 4.56333C5.06842 4.76296 5.125 4.94624 5.125 5Z" stroke="#636773" />
                    <circle cx="10.6248" cy="4.37598" r="1.375" stroke="#636773" />
                    <path d="M13.8748 8.75098C13.8748 8.97733 13.6783 9.31686 13.0528 9.62959C12.4572 9.92737 11.5995 10.126 10.6248 10.126C9.65001 10.126 8.79227 9.92737 8.19671 9.62959C7.57125 9.31686 7.37476 8.97733 7.37476 8.75098C7.37476 8.52462 7.57125 8.1851 8.19671 7.87236C8.79227 7.57459 9.65001 7.37598 10.6248 7.37598C11.5995 7.37598 12.4572 7.57459 13.0528 7.87236C13.6783 8.1851 13.8748 8.52462 13.8748 8.75098Z" stroke="#636773" />
                </g>
                <defs>
                    <clipPath id="clip0_341_1696">
                        <rect width="15" height="15" fill="white" />
                    </clipPath>
                </defs>
            </svg>
    )
}