import { gql } from '@apollo/client'

export const LOGGED_USER_DETAILS = gql`
  fragment LoggedUserDetails on LoggedUser {
    id
    firstName
    token
  }
`

export const MESSAGE_DETAILS = gql`
  fragment MessageDetails on Message {
    id
    body
    conversationId
    senderId
    #isSeen
    #createdAt
    updatedAt
    isEdited
    user {
      id
      firstName
      lastName
      profileAvtar
    }
  }
`

export const GROUP_DETAILS = gql`
  fragment GroupDetails on Group {
    id
    name
    admin
    type
    participants
    createdAt
    latestMessage {
      ...MessageDetails
    }
    adminUser {
      id
      firstName
      lastName
      profileAvtar
    }
    users {
      id
      firstName
      lastName
      profileAvtar
    }
  }
  ${MESSAGE_DETAILS}
`
