// @ts-nocheck

import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import ShareCollectionProduct from './ShareCollectionProduct'
import Product from 'src/components/ProductPage/AllProduct/Product'
import { useAuth } from 'src/utils/auth'
import { useAppDispatch } from 'src/store/store'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useSelector } from 'react-redux'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useMutation } from '@apollo/client'
import ADD_COLLECTION_LIKE from 'src/graphql/mutations/addCollectionLike'
// import MY_FEED_QUERY from 'src/graphql/queries/getMyFeed'
import { useRouter } from 'next/router'
import { fetchAllFeedSliceData } from 'src/store/slices/fetchAllFeedSlice'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <img src="/assets/icon/next-arrow.svg" alt="next arrow" />
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <img src="/assets/icon/prev-arrow.svg" alt="prev arrow" />
    </div>
  )
}
export default function PostShareFeed({ post, isNormalPost }) {
  const { user }: any = useAuth()
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2.016,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }
  const dispatch = useAppDispatch()
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }
      
  
  const handelOnclick = (item) => {
    if (user) {
      if (isDesktop) {
        dispatch(
          fetchModalData({
            allModalData: [
              ...getAllState?.allModalData,
              {
                modal: 'collectionByProduct',
                isOpen: true,
                id: { width: 70, post: item },
                item: item?.collection?.id,
                userName: item?.collection?.user?.userName,
                modalFor: 'bigModal',
              },
            ],
            lastModalData: getAllState?.lastModalData,
          }),
        )
        // if (item?.collection?.isPrivate) {
        //   router.push(`/bookmark-collection/${item?.collection?.slug}`)
        // } else {
        //   router.push(`/shared-collection/${item?.collection?.slug}`)
        // }
      } else {
        dispatch(
          fetchMobileModalData({
            allModalData: [
              ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
              {
                modal: 'mobileViewCollectionDetail',
                isOpen: true,
                id: item?.id,
                transform: 112,
              },
            ],
            lastModalData: fetchMobileModal?.lastModalData ? fetchMobileModal?.lastModalData : [],
          }),
        )
      }
    } else {
      handleRedirect()
    }
  }

  const handleLikeCollection = (postData) => {
    if (user) {
      addLikeCollection({
        variables: {
          collectionId: postData?.collection?.id,
        },
        // refetchQueries: [
        //   {
        //     query: MY_FEED_QUERY,
        //     variables: {
        //       page: 1,
        //       limit: 10,
        //     },
        //   },
        // ],
      })
    } else {
      handleRedirect()
    }
  }

  const [addLikeCollection] = useMutation(ADD_COLLECTION_LIKE, {
    onCompleted: (res) => {
      dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  return (
    <div className="shre-all-feed-details-modal-body ">
      {post?.collection?.bookmark_product?.length > 2 ? (
        <div className="share-all-feed-details-box-alignment">
          <Slider {...settings}>
            {post?.collection?.bookmark_product?.map((b, index) => {
              return (
                <React.Fragment key={index}>
                  <Product product={b} showProduct={{}} storeData={{}} isForPost={true} />
                </React.Fragment>
              )
            })}
          </Slider>
        </div>
      ) : (
        <div className="share-all-feed-details-box-alignment collection-product-grid-view">
          {post?.collection?.bookmark_product?.map((b, index) => {
            return (
              <React.Fragment key={index}>
                <Product
                  index={index}
                  product={b}
                  showProduct={{}}
                  storeData={{}}
                  isForPost={true}
                  className={'margin-view-1'}
                />
              </React.Fragment>
            )
          })}
        </div>
      )}
      <div className="shared-profile-name-alignment">
        <div className="shared-profile-name-details">
          <div className="shared-feed-profile-alignment">
            <div className="bookMarkCollectionCardDetails">
              {/* <div className="bookMarkCollectionCardImg">
                <img src="/assets/seller/img/shoes0.png" alt="bootmark-img" />
              </div>
              <div className="bookMarkCollectionCardImg">
                <img src="/assets/img/bookMark-img.png" alt="bootmark-img" />
              </div>
              <div className="bookMarkCollectionCardImg">
                <img src="/assets/img/bookMark-img.png" alt="bootmark-img" />
              </div> */}
              {post?.collection?.images?.map((img, index) => {
                return (
                  <div className="bookMarkCollectionCardImg" key={index}>
                    <img src={img} alt="bootmark-img" />
                  </div>
                )
              })}
            </div>
          </div>
          <div className="shared-all-profile-name">
            <h6 onClick={() => handelOnclick(post)}>{post?.collection?.name}</h6>
            <div className="name-child-details-alignment">
              <span>{post?.collection?.bookmark_product?.length || 0} Products</span>
              <p></p>
              <span>{post?.collection?.like_count} likes</span>
            </div>
          </div>
        </div>

        <div className="shared-like-alignment" onClick={() => handleLikeCollection(post)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill={post?.collection?.likes?.find((like) => like?.user_id === user?.id) ? '#FF0000' : 'none'}
          >
            <path
              d="M7.5 4C4.46244 4 2 6.43905 2 9.44773C2 14.8955 8.5 19.848 12 21C15.5 19.848 22 14.8955 22 9.44773C22 6.43905 19.5375 4 16.5 4C14.6399 4 12.9954 4.91467 12 6.31469C11.0046 4.91467 9.36015 4 7.5 4Z"
              // stroke="#7A7E88"
              stroke={post?.collection?.likes?.find((like) => like?.user_id === user?.id) ? '#FF0000' : '#7A7E88'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  )
}
