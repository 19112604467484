import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface inviteClubMembers {
  loading: true | false
  error: {} | null
  called: true | false
  data: any
}

const initialState = {
  loading: false,
  error: null,
  data: {
    showModel: false,
  },
  called: false,
} as inviteClubMembers

export const clubMembersInvite = createAsyncThunk('fetchProductDetail/clubMembersInvite', async (data: any) => {
  return data
})

const inviteClubMembers = createSlice({
  name: 'fetchProductDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(clubMembersInvite.pending, (state, action) => {
      state.loading = true
      state.error = null
      state.called = true
    })
    builder.addCase(clubMembersInvite.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
      state.called = true
    })
    builder.addCase(clubMembersInvite.rejected, (state, action) => {
      state.loading = false
      state.called = false
      state.error = action.error
      console.error('Error fetching cards!')
    })
  },
})

// export var _actions = inviteClubMembers.actions
export default inviteClubMembers.reducer
