import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import NormalPost from 'src/components/Feed/NormalPost'
import Loader from 'src/components/UI/Loader'
import MY_FEED_QUERY from 'src/graphql/queries/getMyFeed'
import { fetchAllFeedSliceData } from 'src/store/slices/fetchAllFeedSlice'
import { useAppDispatch } from 'src/store/store'

interface EditPostState {
  isEdit: boolean;
  content: string | null;
  id: string | number | null;
}

interface EditPostParams {
  id: string | number;
  content?: string | null;
  isEdit: boolean;
}

export default function MobileViewIdeaForYouModal() {
  // const { loading, error, data, refetch } = useQuery(MY_FEED_QUERY)
  const [editPost, setEditPost] = useState<EditPostState>({ isEdit: false, content: null, id: null })
  const dispatch = useAppDispatch()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const allPost = useSelector((state: any) => state?.feedData?.allPost)

  useEffect(() => {
    dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
  }, [])

  const editPostHandler = ({ id, content, isEdit }: EditPostParams) => {
    setEditPost({ isEdit: isEdit, content: content || null, id })
  }

  return (
    <div className="mobile-view-idea-for-you-modal-alignment">
      {allPost?.length > 0 ? (
        allPost?.map((post: any, i: number) => (
          <div key={i}>
            <NormalPost
              index={i}
              post={post}
              image={true}
              store={{}}
              color={false}
              feedSize={true}
              type="post"
              isUserPostPreviewModal={false}
            />
          </div>
        ))
      ) : (
        <>
          <div className="no-data-box-alignment">
            <Loader />
          </div>
        </>
      )}
    </div>
  )
}
