import React from 'react'
import styles from './SellerLeftSidebar.module.scss'
import SellerTips from './SellerTips'
import SellerTools from './SellerTools'
import SellerFollowers from './SellerFollowers'
import SellerAccountBalance from './SellerAccountBalance'
import SellerPaymentMethod from './SellerPaymentMethod'
import { useRouter } from 'next/router'
import SellerCustomers from './SellerCustomers'
import { useSelector } from 'react-redux'

export default function SellerRightSidebar() {
  const router = useRouter()
  const path = router.asPath.split('/')
  const silderBarComponent = () => {
    if (path[2] === 'dashboard') {
      return (
        <React.Fragment>
          <SellerTips />
          <SellerTools />
        </React.Fragment>
      )
    } else if (path[2] === 'social-activity') {
      return (
        <React.Fragment>
          <SellerFollowers />
          <SellerTools />
        </React.Fragment>
      )
    } else if (path[2] === 'insight') {
      return (
        <React.Fragment>
          <SellerCustomers />
          <SellerTips />
          <SellerTools />
        </React.Fragment>
      )
    } else if (path[2] === 'finance') {
      return (
        <React.Fragment>
          <SellerAccountBalance />
          <SellerPaymentMethod />
          <SellerTools />
        </React.Fragment>
      )
    } else if (path[2] === 'products') {
      return (
        <React.Fragment>
          <SellerCustomers />
          <SellerTips />
          <SellerTools />
        </React.Fragment>
      )
    } else if (path[2] === 'order-shipping') {
      return (
        <React.Fragment>
          <SellerTips />
          <SellerTools />
        </React.Fragment>
      )
    } else if (path[2] === 'resources') {
      return (
        <React.Fragment>
          <SellerTips />
          <SellerTools />
        </React.Fragment>
      )
    }
  }

  return <div className={styles.sellerRightSidebarSection}>{silderBarComponent()}</div>
}
