import { gql } from '@apollo/client'

const query = gql`
  mutation REMOVE_FOLLOWER($id: Int!) {
    deleteFollowDetail(id: $id) {
      follower_id
      following_id
    }
  }
`
export default query
