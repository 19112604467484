// export const WOMEN_CATEGORY = 'Women'

export const WOMEN_CATEGORY_IMAGE = [
  'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1180-1701752902442-276510464.png',
  'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1178-1701753003462-955714776.png',
  'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1181-1701753047041-162790301.png',
  'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1178-1701753095249-667924651.png',
  'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1178-1701753132146-975324491.png',
  'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1178-1701753165196-84021971.png',
  'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1178-1701753196534-111446090.png',
  'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1178 (3)-1701753501281-720086404.png',
  'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1178-1701753294877-900904057.png',
  'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1178 (1)-1701753328083-915724320.png',
  'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1178 (2)-1701753354295-168939734.png',
  'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1181-1701753383840-430233519.png',
  'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1182-1701753410363-222781482.png',
  'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1183-1701753445983-401205060.png',
]

export const WOMEN_SECTION_TEXT = {
  category: [
    {
      name: 'Activewear',
      description: 'Empower your workout with our womens activewear collection, designed for style and performance.',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201180-1674541755051-922297094.png',
    },
    {
      name: 'Bottoms',
      description: 'Look and feel great in our collection of womens bottoms, designed for all-day comfort and style',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178-1674541838967-714383969.png',
    },
    {
      name: 'Coats',
      description: 'Stay warm in style with our collection of womens coats',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201181-1674541864749-565730144.png',
    },
    {
      name: 'Jacket',
      description: 'Stay protected and stylish with our collection of womens jackets',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%281%29-1674541888786-357781350.png',
    },
    {
      name: 'Pants',
      description: 'Elevate your style with our collection of womens pants, designed for comfort and fit.',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%282%29-1674541916234-818383110.png',
    },
    {
      name: 'Dresses',
      description: 'Elevate your wardrobe with our collection of womens dresses, designed for any occasion',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%283%29-1674541979909-920836375.png',
    },
    {
      name: 'Jeans',
      description: 'Look and feel great in our collection of womens jeans, designed for comfort and style',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%284%29-1674542013530-322155643.png',
    },
    {
      name: 'Leggings',
      description: 'Stay comfortable and fashionable with our collection of womens leggings.',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%285%29-1674542097489-631164801.png',
    },
    {
      name: 'Shoes',
      description: 'Elevate your style with our collection of womens shoes, designed for comfort and fashion',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%286%29-1674542121058-25977368.png',
    },
    {
      name: 'Sleepwear',
      description: 'Relax in style with our collection of womens sleepwear',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%287%29-1674542145469-912773288.png',
    },
    {
      name: 'Sneakers',
      description:
        'Discover the ultimate blend of fashion and function in our womens sneakers. Elevate your look and stay comfortable on the go',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201178%20%288%29-1674542170745-777964015.png',
    },
    {
      name: 'Socks',
      description: 'Comfort meets style with our womens socks. Soft and stylish, a perfect addition to any outfit',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201181%20%281%29-1674542197803-71148781.png',
    },
    {
      name: 'Tops',
      description: 'Stay on top of fashion with our womens tops. Versatile styles for any occasion, comfort included.',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201182-1674542227483-601427949.png',
    },
    {
      name: 'Wedding',
      description:
        'Dream wedding dress found here. From classic to contemporary, find the perfect gown for your special day',
      bannerImage:
        'https://bluejestic-media.s3.amazonaws.com/fashion/men/categoryBanner/CategoryActiveImg-1674471477304-875955896.png',
      image:
        'https://bluejestic-media.s3.amazonaws.com/fashion/women/category/Ellipse%201183-1674542250982-797235046.png',
    },
  ],
  now_trending: {
    text: 'Lead the fashion pack with our collection of trendy and on-point designs, always up-to-date with the latest styles',
    image: '',
  },
  find_your_style: {
    text: 'Explore the latest fashion trends and find your unique style with our easy-to-use platform',
    image: '',
  },
  fashion_group: {
    text: 'Stay ahead of the curve with the latest styles and trends. Elevate your wardrobe and elevate your style with our fashion club',
    image: '',
  },
  trending_store: {
    text: 'Find the latest styles and hottest products at our Trending Store. Be on-trend and on-point with every purchase',
    image: '',
  },
  men_fashion_enthusiasts: {
    text: 'Find out women fashion inspiration from creative people around the globe',
    image: '',
  },
}

export const WOMEN_FIND_YOUR_STYLE = [
  {
    name: 'Artsy',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Rectangle 15774-1701756861674-977629563.png',
    color: '#d5e3fc',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/image 168-1701758372558-185838382.png',
    opacity: 0.6,
    text: "Stand out with our artsy women's styles. Bold, unique designs for self-expression",
  },

  {
    name: 'Vintage',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Rectangle 15774 (4)-1701757626175-893030303.png',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/image 168 (4)-1701758613392-675039467.png',
    color: '#d29ffa',
    opacity: 0.6,
    text: "Experience luxury with our elegant women's fashion. Timeless designs and high-quality fabrics for a sophisticated look",
  },

  {
    name: 'Chic',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Rectangle 15774-1701756761097-61973575.png',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/image 168 (1)-1701758502993-881418050.png',
    color: '#fbecf1',
    opacity: 0.6,
    text: "Effortlessly chic and on-trend with our collection of women's styles. Perfect for any occasion",
  },

  {
    name: 'Formal',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Rectangle 15774-1701757034040-294241817.png',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/image 168 (2)-1701758545173-671282501.png',
    color: '#c89f9f',
    opacity: 0.6,
    text: "Exude sophistication and class with our formal women's styles. Perfect for any special occasion or professional setting",
  },

  {
    name: 'Casual',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Rectangle 15774 (1)-1701757096010-321585968.png',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/image 168 (3)-1701758578783-6096380.png',
    color: '#e8ead3',
    opacity: 0.6,
    text: "Unleash your effortless style with our collection of casual women's fashion. Easy, comfortable and perfect for everyday wear",
  },
  {
    name: 'Sexy',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Rectangle 15774-1701756829255-341899849.png',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/image 168 (6)-1701758688191-400592688.png',
    color: '#aaacb1',
    opacity: 0.6,
    text: "Elevate your style and confidence with our sexy women's fashion. Make a statement and turn heads",
  },

  {
    name: 'Elegant',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Rectangle 15774 (2)-1701757134629-804985871.png',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/image 168 (4)-1701758613392-675039467.png',
    color: '#fbf2db',
    opacity: 0.6,
    text: "Experience luxury with our elegant women's fashion. Timeless designs and high-quality fabrics for a sophisticated look",
  },

  {
    name: 'Street',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Rectangle 15774 (3)-1701757178480-677646995.png',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/image 168 (5)-1701758654948-167579216.png',
    color: '#d29ffa',
    opacity: 0.6,
    text: "Make a bold statement with our street-inspired women's fashion. Stand out and express your individuality",
  },
]

export const WOMEN_CATEGORY = [
  {
    name: 'Activewear',
    description: "Empower your workout with our women's activewear collection, designed for style and performance.",
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Group 1-1701754178527-719636327.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1180-1701752902442-276510464.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Bottoms',
    description: "Look and feel great in our collection of women's bottoms, designed for all-day comfort and style",
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Group 1-1701754776625-308312468.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1178-1701753003462-955714776.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Coats',
    description: "Stay warm in style with our collection of women's coats",
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Group 1-1701754980336-325254865.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1181-1701753047041-162790301.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Jacket',
    description: "Stay protected and stylish with our collection of women's jackets",
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Group 1-1701755052990-972917863.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1178-1701753095249-667924651.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Pants',
    description: "Elevate your style with our collection of women's pants, designed for comfort and fit.",
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Group 1-1701755098354-586865048.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1178-1701753132146-975324491.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Dresses',
    description: "Elevate your wardrobe with our collection of women's dresses, designed for any occasion",
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Group 1 (1)-1701755139175-453011912.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1178-1701753165196-84021971.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Jeans',
    description: "Look and feel great in our collection of women's jeans, designed for comfort and style",
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Group 1 (2)-1701755197770-355913409.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1178-1701753196534-111446090.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Leggings',
    description: "Stay comfortable and fashionable with our collection of women's leggings.",
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Group 1 (3)-1701755233212-467571554.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1178 (3)-1701753501281-720086404.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Shoes',
    description: "Elevate your style with our collection of women's shoes, designed for comfort and fashion",
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Group 1 (4)-1701755271469-310753210.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1178-1701753294877-900904057.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Sleepwear',
    description: "Relax in style with our collection of women's sleepwear",
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Group 1 (5)-1701755311266-406620479.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1178 (1)-1701753328083-915724320.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Sneakers',
    description:
      "Discover the ultimate blend of fashion and function in our women's sneakers. Elevate your look and stay comfortable on the go",
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Group 1 (6)-1701755352234-407385379.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1178 (2)-1701753354295-168939734.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Socks',
    description: "Comfort meets style with our women's socks. Soft and stylish, a perfect addition to any outfit",
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Group 1 (7)-1701755391658-698034986.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1181-1701753383840-430233519.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Tops',
    description: "Stay on top of fashion with our women's tops. Versatile styles for any occasion, comfort included.",
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Group 1 (8)-1701755430796-498070017.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1182-1701753410363-222781482.png',
    __typename: 'SubCategory',
  },
  {
    name: 'Wedding',
    description:
      'Dream wedding dress found here. From classic to contemporary, find the perfect gown for your special day',
    bannerImage:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Group 1 (9)-1701755465368-294620119.png',
    image:
      'https://bluejestic-media.storage.googleapis.com/bluejestic-stage/category/fashion/women/Ellipse 1183-1701753445983-401205060.png',
    __typename: 'SubCategory',
  },
]
