import React, { useEffect, useRef, useState } from 'react'
import styles from './CreateClubInvitePeople.module.scss'
import GET_SUGGESTED_USERS from 'src/graphql/queries/sellerQueries/getSuggestedUsers'
import { useMutation, useQuery } from '@apollo/client'
import useOnClickOutside from 'src/components/Hook/useOnClickOutSide'
import { useSelector } from 'react-redux'
import GET_MUTUAL_FOLLOWING from 'src/graphql/queries/sellerQueries/getMutualFollowing'

export default function CreateClubInvitePeople(props) {
  const { inputValue, setInputValue, errors, setErrors } = props
  const [isDivOpen, setIsDivOpen] = useState(false)
  const [allUserData, setAllUserData] = useState([])
  const [searchData, setSearchData] = useState('')
  const { loading, error, data, refetch } = useQuery(GET_MUTUAL_FOLLOWING)
  const ref = useRef()

  useOnClickOutside(ref, () => onCancel())

  const onCancel = () => {
    setIsDivOpen(false)
  }

  useEffect(() => {
    if (data) {
      const newData = data?.getMutualFollowing?.data?.map((userData) => {
        return {
          name: userData?.user?.firstName + ' ' + userData?.user?.lastName,
          value: userData?.user?.id,
          url: userData?.user?.logo_image,

        }
      })
      setAllUserData(newData)
    }
  }, [data])

  const handleSearch = (e) => {
    setSearchData(e.target.value)
    setIsDivOpen(true)
    const searchTerm = e.target.value.toLowerCase()
    if (searchTerm === '') {
      setAllUserData(allUserData)
    }
    // const filteredData = JSON.parse(JSON.stringify(allUserData))?.filter((item) => item?.name?.toLowerCase()?.includes(searchTerm));
    const filteredData = allUserData?.map((item) => ({
      ...item,
      isMatched: item?.name?.toLowerCase()?.includes(searchTerm),
    }))
    setAllUserData(filteredData)
  }

  const handelOnClick = (value) => {
    const cloneArr = [...allUserData]
    const indexValue = cloneArr?.findIndex((item) => item?.value === value)
    if (indexValue !== -1) {
      cloneArr[indexValue].added = !cloneArr[indexValue].added
      setAllUserData([...cloneArr])
      setInputValue({ ...inputValue, userAdd: [...cloneArr].filter((item) => item?.added) })
    }
  }

  return (
    <div className={styles.createClubInvitePeopleSection}>
      {inputValue?.userAdd?.length < 1 && <span style={{ color: 'red' }}>{errors['userAdd']}</span>}
      <div className={styles.createClubInvitePeopleSearchAlignment}>
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <path
            d="M19 27C23.4183 27 27 23.4183 27 19C27 14.5817 23.4183 11 19 11C14.5817 11 11 14.5817 11 19C11 23.4183 14.5817 27 19 27Z"
            stroke="#ACB1C0"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M29.0004 28.9999L24.6504 24.6499"
            stroke="#ACB1C0"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <input
          name="userAdd"
          type="text"
          placeholder="Serach people"
          value={searchData}
          onClick={() => setIsDivOpen(true)}
          onChange={(e) => handleSearch(e)}
        />

        {isDivOpen && (
          <div className={styles.searchDetailsBoxAlignment} ref={ref}>
            {(!allUserData.some((item) => item.isMatched)
              ? allUserData
              : allUserData.filter((item) => item.isMatched)
            ).map((item, index) => {
              return (
                <div className={styles.searchDetailsFlexAlignment} key={index}>
                  <div className={styles.searchDetailsLeftide}>
                    <div className={styles.searchDetailsProfileImg}>
                      <img src={item?.url} alt="profile img" />
                    </div>
                    <h6>{item?.name}</h6>
                  </div>

                  <div className={styles.addButtonAlignment}>
                    <button onClick={() => handelOnClick(item?.value)}>{item?.added ? 'Added' : 'Add'}</button>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>

      <div className={styles.createClubInviteAllNameAlignment}>
        {inputValue?.userAdd?.map((addedData, index) => {
          return (
            <div className={styles.createClubInviteFlexAlignment} key={index}>
              <div className={styles.createClubInvitePeopleDetails}>
                <div className={styles.createClubInvitePeopleImg}>
                  <img src={addedData?.url} alt="profile img" />
                </div>
                <h6>{addedData?.name}</h6>
              </div>
              <div className={styles.createClubCloseIcon} onClick={() => handelOnClick(addedData?.value)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M18 6L6 18"
                    stroke="#A7ABB3"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6 6L18 18"
                    stroke="#A7ABB3"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
