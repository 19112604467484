import React, { useEffect, useRef, useState } from 'react'
import styles from './AddProductCategorySelection.module.scss'
import classNames from 'classnames'
import GET_CATEGORIES_QUERY from 'src/graphql/queries/getCategories'
import { useQuery } from '@apollo/client'
import client1 from 'src/utils/apolloClient'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'

export default function AddProductCategorySelection({ productData, setProductData, validation, setvalidation }) {
  const [isOpenCategory, setIsOpenCategory] = useState(false)
  const [isOpenSubCategory, setIsOpenSubCategory] = useState(false)
  const [isOpenSubChildCategory, setIsOpenSubChildCategory] = useState(false)
  const fetchProductImageResizeData = useSelector((state: any) => state?.productResizeData?.data)
  const dispatch = useAppDispatch()
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)

  const categoryRef: any = useRef()
  const subCategoryRef: any = useRef()
  const childSubcategoryRef: any = useRef()

  const [data, setData] = useState([])

  const getCategory = async () => {
    const { data } = await client1.query({
      query: GET_CATEGORIES_QUERY,
      fetchPolicy: 'network-only',
    })
    setData(data?.getAllCategoryDetail)
  }

  useEffect(() => {
    getCategory()
  }, [])

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpenCategory && categoryRef.current && !categoryRef.current.contains(e.target)) {
        setIsOpenCategory(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isOpenCategory])

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpenSubCategory && subCategoryRef.current && !subCategoryRef.current.contains(e.target)) {
        setIsOpenSubCategory(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isOpenSubCategory])

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpenSubChildCategory && childSubcategoryRef.current && !childSubcategoryRef.current.contains(e.target)) {
        setIsOpenSubChildCategory(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isOpenSubChildCategory])

  const [category, setCategory] = useState<any>()
  const [subCategory, setSubCategory] = useState(null)
  const [childSubCategory, setChildSubCategory] = useState(null)
  const [errors, setErrors] = useState<any>({})

  useEffect(() => {
    if (Boolean(addProductDetailSlice?.id?.mobileProductsData?.category?.length)) {
      let existing_category = addProductDetailSlice?.id?.mobileProductsData?.category[0]
      let find_category = data.find((cat) => cat?.id === existing_category?.category_id)
      if (find_category) {
        setCategory(find_category)
        if (Boolean(find_category?.subCategory?.length)) {
          let find_sub_category = find_category?.subCategory?.find(
            (cat) => cat?.id === existing_category?.subCategory_id,
          )
          if (find_sub_category) {
            setSubCategory(find_sub_category)
            if (Boolean(find_sub_category?.childSubCategory?.length)) {
              let find_sub_child_category = find_sub_category?.childSubCategory?.find(
                (cat) => cat?.id === existing_category?.childSubCategory_id,
              )
              if (find_sub_child_category) {
                setChildSubCategory(find_sub_child_category)
              }
            }
          }
        }
      }
    }
  }, [addProductDetailSlice])

  const handleCategorySelect = (e, item, name) => {
    e.stopPropagation()
    if (name === 'category') {
      setCategory(item)
      setSubCategory(null)
      setChildSubCategory(null)
      dispatch(
        fetchProductImageResizeModal({
          modal: '',
          isOpen: false,
          id: {
            ...fetchProductImageResizeData?.id,
            selectedCategories: { category_id: item?.id, subCategory_id: null, childSubCategory_id: null },
          },
        }),
      )

      setIsOpenCategory(false)
    }
    if (name === 'subCategory') {
      setSubCategory(item)
      setChildSubCategory(null)
      dispatch(
        fetchProductImageResizeModal({
          modal: '',
          isOpen: false,
          id: {
            ...fetchProductImageResizeData?.id,
            selectedCategories:
              // ...fetchProductImageResizeData?.id?.selectedCategories,
              {
                ...fetchProductImageResizeData?.id?.selectedCategories,
                subCategory_id: item?.id,
                childSubCategory_id: null,
              },
          },
        }),
      )
      setIsOpenSubCategory(false)
      setErrors({ ...errors, subCategory: '' })
    }
    if (name === 'childSubCategory') {
      setChildSubCategory(item)
      dispatch(
        fetchProductImageResizeModal({
          modal: '',
          isOpen: false,
          id: {
            ...fetchProductImageResizeData?.id,
            selectedCategories: {
              ...fetchProductImageResizeData?.id?.selectedCategories,
              childSubCategory_id: item?.id,
            },
          },
        }),
      )
      setIsOpenSubChildCategory(false)
      setErrors({ ...errors, childSubCategory: '' })
    }
  }

  const handleOpen = (type) => {
    if (type === 'category') {
      setIsOpenCategory(!isOpenCategory)
    } else if (type === 'subCategory') {
      setIsOpenSubCategory(!isOpenSubCategory)
    } else {
      setIsOpenSubChildCategory(!isOpenSubChildCategory)
    }
  }

  // useEffect(() => {
  //   dispatch(
  //     fetchProductImageResizeModal({
  //       modal: '',
  //       isOpen: false,
  //       id: {
  //         ...fetchProductImageResizeData?.id,
  //         selectedCategories: [
  //           // ...fetchProductImageResizeData?.id?.selectedCategories,
  //           { category_id: category?.id, subCategory_id: subCategory?.id, childSubCategory_id: childSubCategory?.id },
  //         ],
  //       },
  //     }),
  //   )
  // }, [category, subCategory, childSubCategory])

  const handleClick = (e, category, subCategory, childSubCategory) => {
    let cat = category.id
    let subCat = subCategory.id
    let childSubCate = childSubCategory.id
    let filteredData = fetchProductImageResizeData?.id?.selectedCategories?.filter(
      (cate) => cate.childSubCategory_id === childSubCate,
    )
    if (filteredData?.length > 0) {
      dispatch(
        fetchProductImageResizeModal({
          modal: '',
          isOpen: false,
          id: {
            ...fetchProductImageResizeData?.id,
            selectedCategories: fetchProductImageResizeData?.id?.selectedCategories?.filter(
              (cate) => cate.childSubCategory_id !== childSubCate,
            ),
          },
        }),
      )
    } else {
      dispatch(
        fetchProductImageResizeModal({
          modal: '',
          isOpen: false,
          id: {
            ...fetchProductImageResizeData?.id,
            selectedCategories: [
              ...fetchProductImageResizeData?.id?.selectedCategories,
              { category_id: cat, subCategory_id: subCat, childSubCategory_id: childSubCate },
            ],
          },
        }),
      )
    }
    let filtredData = data?.map((cat) => {
      if (cat?.id === category?.id) {
        return {
          ...cat,
          subCategory: cat?.subCategory?.map((subcat) => {
            if (subcat?.id === subCategory?.id) {
              return {
                ...subcat,
                childSubCategory: subcat?.childSubCategory?.map((subchildcat) => {
                  if (subchildcat?.id === childSubCategory?.id) {
                    return { ...subchildcat, isChecked: e.target.checked }
                  } else {
                    return subchildcat
                  }
                }),
              }
            } else {
              return subcat
            }
          }),
        }
      } else {
        return cat
      }
    })
    setData(filtredData)
    setvalidation({ ...validation, category: '' })
  }

  return (
    <div className={styles.addProductModalCategoryAlignment}>
      <div className={styles.addProductModalCategoryBox}>
        <div className={styles.addProductModalCategoryHeading}>
          <h4>Category</h4>
          <span className={styles.starAlignment}>*</span>
          <span className={styles.starAlignment}>{validation?.category}</span>
        </div>
        <div className={styles.categoryNotes}>
          <span>Product Categories</span>
        </div>
        <div className={styles.categoryDropdownContainer}>
          <div
            className={styles.addProductStatusInputAlignment}
            ref={categoryRef}
            onClick={() => handleOpen('category')}
          >
            <input
              type="text"
              placeholder="Select Category"
              value={category?.name ? category?.name : 'Select Category'}
              readOnly
            />
            <div className={styles.addProductStatusDropdownIcon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className={isOpenCategory ? styles.productStatusActiveArrow : ''}
              >
                <path d="M4 7L8 11L12 7" stroke="#636773" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <div
              className={
                isOpenCategory
                  ? classNames(styles.productStatusDropdownBox, styles.openDropdownBox)
                  : classNames(styles.productStatusDropdownBox, styles.closeDropdownBox)
              }
            >
              <div className={styles.selectedListProductStatus}>
                {data?.map((item, index) => {
                  return <p onClick={(e) => handleCategorySelect(e, item, 'category')}>{item?.name}</p>
                })}
              </div>
            </div>
          </div>
        </div>
        {category?.subCategory?.length > 0 && (
          <div className={styles.categoryDropdownContainer}>
            <div
              className={styles.addProductStatusInputAlignment}
              ref={subCategoryRef}
              onClick={() => handleOpen('subCategory')}
            >
              <input
                type="text"
                placeholder="Select Category"
                value={subCategory?.name ? subCategory?.name : 'Select Sub Category'}
                // value={subCategory?.name ? subCategory?.name : category?.name ? 'Select Category' : 'Existing value'}

                readOnly
              />
              <div className={styles.addProductStatusDropdownIcon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  className={isOpenSubCategory ? styles.productStatusActiveArrow : ''}
                >
                  <path d="M4 7L8 11L12 7" stroke="#636773" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              <div
                className={
                  isOpenSubCategory
                    ? classNames(styles.productStatusDropdownBox, styles.openDropdownBox)
                    : classNames(styles.productStatusDropdownBox, styles.closeDropdownBox)
                }
              >
                <div className={styles.selectedListProductStatus}>
                  {category?.subCategory?.map((item, index) => {
                    return <p onClick={(e) => handleCategorySelect(e, item, 'subCategory')}>{item?.name}</p>
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
        {subCategory?.childSubCategory?.length > 0 && (
          <div className={styles.categoryDropdownContainer}>
            <div
              className={styles.addProductStatusInputAlignment}
              ref={childSubcategoryRef}
              onClick={() => handleOpen('childSubCategory')}
            >
              <input
                type="text"
                placeholder="Select Category"
                readOnly
                value={childSubCategory?.name ? childSubCategory?.name : 'Select Child Sub Category'}
              />
              <div className={styles.addProductStatusDropdownIcon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  className={isOpenSubChildCategory ? styles.productStatusActiveArrow : ''}
                >
                  <path d="M4 7L8 11L12 7" stroke="#636773" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              <div
                className={
                  isOpenSubChildCategory
                    ? classNames(styles.productStatusDropdownBox, styles.openDropdownBox)
                    : classNames(styles.productStatusDropdownBox, styles.closeDropdownBox)
                }
              >
                <div className={styles.selectedListProductStatus}>
                  {subCategory?.childSubCategory?.map((item, index) => {
                    return <p onClick={(e) => handleCategorySelect(e, item, 'childSubCategory')}>{item?.name}</p>
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
