import React, { useEffect, useRef, useState } from 'react'
import styles from './AddProductModalAttributes.module.scss'
export default function AddProductModalAttributes({ setAttributeArray, attributeArray }) {
  const variantName = ['Material', 'Pattern', 'Style', 'Weight', 'Dimensions']
  const categoryRef: any = useRef()
  const [AttributeDropdown, setAttributeDropdown] = useState(false)
  const [inputIndex, setInputIndex] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [sizeArray, setSizeArray] = useState(variantName)
  const [searchResult, setSearchResult] = useState([])
  const [error, setError] = useState([])
  const [errorForValue, setErrorForValue] = useState([])

  const handleEnter = async (e, index) => {
    if (e.key === 'Enter') {
      if (validateForm(attributeArray)) {
        setInputIndex(null)
        setInputValue('')
        setAttributeArray([...attributeArray, { name: '', value: '' }])
      }
    }
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (inputIndex && categoryRef.current && !categoryRef.current.contains(e.target)) {
        setInputIndex(null)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [inputIndex])

  useEffect(() => {
    if (inputValue === '') {
      setSizeArray(variantName)
      setSearchResult(variantName)
    } else {
      let datat = sizeArray?.filter((rep) => rep?.toLowerCase()?.includes(inputValue?.toLowerCase()))
      setSearchResult(datat)
    }
  }, [inputValue])

  const handleChange = (e, id) => {
    let changed = []
    const { name, value } = e.target
    // if (name === "name") {
    setInputValue(value)
    changed = attributeArray.map((color, index) => {
      if (index === id) {
        return { ...color, [name]: value }
      } else {
        return color
      }
    })
    if (name === 'name') {
      validateName(changed)
      setAttributeArray(changed)
      setInputIndex(id)
    } else {
      validateValue(changed)
      setAttributeArray(changed)
      setInputIndex(null)
    }
  }

  const handleClickInput = (index) => {
    setInputIndex(index)
    setInputValue('')
    setSizeArray(variantName)
  }

  const handleSelect = async (id, data) => {
    let errors = {}
    let ID = id
    if (data !== '') {
      let selectedSetting = attributeArray.map((col, index) => {
        if (index === ID) {
          return { ...col, name: data }
        } else {
          return col
        }
      })
      validateName(selectedSetting)
      setAttributeArray(selectedSetting)
      setInputIndex(null)
    }
  }

  const handleAdd = () => {
    if (validateForm(attributeArray)) {
      setInputIndex(null)
      setInputValue('')
      setAttributeArray([...attributeArray, { name: '', value: '' }])
    }
  }

  const validateName = (changed) => {
    let errors: any = {}
    let formIsValid = true
    changed.map((data, index) => {
      if (index !== 0) {
        if (data.name.trim() === '') {
          // if (index !== 0) {
          formIsValid = false
          return (errors[index] = 'Enter valid input*')
          // }
        }
      }
    })
    setError(errors)
    return formIsValid
  }

  const validateValue = (changed) => {
    let errorsForValue: any = {}
    let formIsValid = true
    changed.map((data, index) => {
      if (index !== 0) {
        if (data?.value.trim() === '') {
          formIsValid = false
          return (errorsForValue[index] = 'Enter valid value*')
        }
      }
    })
    setErrorForValue(errorsForValue)
    return formIsValid
  }

  const validateForm = (changed) => {
    let errors: any = {}
    let errorsForValue: any = {}
    let formIsValid = true
    changed.map((data, index) => {
      if (data.name.trim() === '') {
        // if (index !== 0) {
        formIsValid = false
        return (errors[index] = 'Enter valid input*')
        // }
      }
    })
    changed.map((data, index) => {
      if (data?.value.trim() === '') {
        // if (index !== 0) {
        formIsValid = false
        return (errorsForValue[index] = 'Enter valid value*')
        // }
      }
    })
    setError(errors)
    setErrorForValue(errorsForValue)
    return formIsValid
  }

  const handleRemove = (index) => {
    setAttributeArray(attributeArray.filter((data, ind) => ind !== index))
  }

  const handleAddAttributes = () => {
    setAttributeArray([{ name: '', value: '' }])
  }

  return (
    <div className={styles.addProductModalAttributesSection}>
      <div className={styles.addProductModalAttributesBox}>
        <div className={styles.attributesaddProductHeadingAlignment}>
          <h4>Product Specifications</h4>
          <div className={styles.attributesaddProductNotesAlignment}>
            <span>Provide any detail specification to aid customer comprehension</span>
          </div>
        </div>
        {attributeArray?.length > 0 ? (
          <>
            <div className={styles.addAttributeFromAlignment}>
              {/* <div className={styles.addAttributeGrid}>
                <div className={styles.addAttributeGridItem}>
                  <div className={styles.labelAlignment}>
                    <label>Attribute Name</label>
                  </div>
                </div>
                <div className={styles.addAttributeGridItem}>
                  <div className={styles.labelAlignment}>
                    <label>Value</label>
                  </div>
                </div>
              </div> */}
              {attributeArray?.map((variant, index, row) => {
                return (
                  <div className={styles.addAttributeGrid} key={index}>
                    <div className={styles.addAttributeGridItem}>
                      <div className={styles.atrributeFormInputAlignment}>
                        <div className={styles.inputAlignment} onClick={() => setAttributeDropdown(!AttributeDropdown)}>
                          {/* <span style={{ color: 'red' }}>{error[index]}++++++++++</span> */}
                          <input
                            // autoFocus
                            type="text"
                            placeholder="e.g Brand"
                            name="name"
                            onChange={(e) => handleChange(e, index)}
                            value={variant?.name}
                            className={`${styles.firstInut} ${Boolean(error[index]) ? styles.specError : ''}`}
                            onClick={() => handleClickInput(index)}
                            // className={styles.firstInut}
                          />
                          {inputIndex == index && searchResult?.length > 0 && (
                            <div className={styles.dropdownAlignment} ref={categoryRef}>
                              <ul>
                                {(inputValue ? searchResult : sizeArray)?.map((val, i) => {
                                  return (
                                    <li onClick={() => handleSelect(index, val)} key={i}>
                                      {val}
                                    </li>
                                  )
                                })}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={styles.addAttributeGridItem}>
                      <div className={styles.atrributeFormInputAlignment}>
                        <div className={styles.inputAlignment}>
                          {/* <span style={{ color: 'red' }}>{errorForValue[index]}</span> */}
                          <input
                            type="text"
                            placeholder="e.g Nike"
                            name="value"
                            onChange={(e) => handleChange(e, index)}
                            value={variant?.value}
                            className={`${styles.secondInut} ${Boolean(errorForValue[index]) ? styles.specError : ''}`}
                            onKeyPress={(e) => handleEnter(e, index)}
                            onClick={() => setInputIndex(null)}
                            // className={styles.secondInut}
                          />{' '}
                        </div>
                      </div>
                    </div>
                    {/* {index + 1 !== row.length && ( */}
                    {row?.length !== 1 && (
                      <div className={styles.deleteIconAlignment} onClick={() => handleRemove(index)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                          <path
                            d="M2.25 4.5H3.75H15.75"
                            stroke="#A7ABB3"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M14.25 4.5V15C14.25 15.3978 14.092 15.7794 13.8107 16.0607C13.5294 16.342 13.1478 16.5 12.75 16.5H5.25C4.85218 16.5 4.47064 16.342 4.18934 16.0607C3.90804 15.7794 3.75 15.3978 3.75 15V4.5M6 4.5V3C6 2.60218 6.15804 2.22064 6.43934 1.93934C6.72064 1.65804 7.10218 1.5 7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5"
                            stroke="#A7ABB3"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7.5 8.25V12.75"
                            stroke="#A7ABB3"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10.5 8.25V12.75"
                            stroke="#A7ABB3"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    )}
                    {/* {index + 1 === row.length && (
                          <div className={styles.addButtonAlignment} onClick={handleAdd}>
                            <button className={styles.activeButton}>Add</button>
                          </div>
                        )} */}
                  </div>
                )
              })}
            </div>
          </>
        ) : (
          <>
            {/* <div>
              <div className={styles.noAttributesAlignment}>No Attributes Added</div>
            </div> */}
            <div className={styles.addAttributesButtonAlignment} onClick={handleAddAttributes}>
              <button>
                <img src="/assets/seller/icons/plus-icon.svg" alt="PlusIcon" />
                Add Attributes
              </button>
            </div>
          </>
        )}

        <div className={styles.mobileViewAttributeSpecificationSection}>
          <div className={styles.mobileViewAttributeSpecificationDetails}>
            <div className={styles.mobileViewArttributeLeftSide}>
              <h6>Dimension</h6>
              <p>12.99 x 8.66 x 4.33 inches; 1.1 Pounds</p>
            </div>
            <div className={styles.mobileViewAttributrRightSide}>
              <a>Remove</a>
              <a>Edit</a>
            </div>
          </div>
          <div className={styles.mobileViewAttributeSpecificationDetails}>
            <div className={styles.mobileViewArttributeLeftSide}>
              <h6>Item model Number</h6>
              <p>CD0197</p>
            </div>
            <div className={styles.mobileViewAttributrRightSide}>
              <a>Remove</a>
              <a>Edit</a>
            </div>
          </div>
          <div className={styles.mobileViewAttributeSpecificationDetails}>
            <div className={styles.mobileViewArttributeLeftSide}>
              <h6>ASIN</h6>
              <p>B07RJTMPM6</p>
            </div>
            <div className={styles.mobileViewAttributrRightSide}>
              <a>Remove</a>
              <a>Edit</a>
            </div>
          </div>

          <div className={styles.mobileViewAddNew}>
            <a>+ Add New</a>
          </div>
        </div>
      </div>
    </div>
  )
}

// {attributeArray.length > 0 && (
//   <>
//     <div>
//       <div className={styles.noAttributesAlignment}>No Attributes Added</div>
//     </div>
//     <div className={styles.addAttributesButtonAlignment}>
//       <button>
//         <img src="/assets/seller/icons/plus-icon.svg" alt="PlusIcon" />
//         Add Attributes
//       </button>
//     </div>
//   </>
// )}
