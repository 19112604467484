import { useState } from 'react'
import { useAuth } from 'src/utils/auth'
import PostCommetSection from './PostCommetSection'
import PostHeader from './PostHeader'
import PostContent from './PostContent'
import PostMedia from './PostMedia'
import PostShareProduct from './PostShareProduct'
import PostReaction from './PostReaction'
import PostCommentInput from './PostCommentInput'
import PostSharewithFriendModal from './PostSharewithFriendModal'
import PostDeleteModal from './PostDeleteModal'
import MobileViewComment from './MobileViewComment'
import { fetchPostAddEditModal } from 'src/store/slices/postAddEditSlice'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { Post, Store } from 'src/types'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import LazyLoad from 'src/utils/LazyLoad'
import PostShareFeed from '../PostShareFeed'

export type Props = {
  index: any
  post: any
  store: any
  type: string
  image: boolean
  feedSize: boolean
  isUserPostPreviewModal: boolean
  color: boolean
}

const NormalPost: React.VFC<Props> = ({ post, store, type, image, feedSize, isUserPostPreviewModal, index }: Props) => {
  const { user } = useAuth()
  const dispatch = useAppDispatch()
  const postAddEditModal = useSelector((state: any) => state?.postAddEditModal?.data)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isAllFeedModalVisible, setIsAllFeedModalVisible] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isClose, isSetClose] = useState(true)
  const [showMore, setShowMore] = useState(false)
  const [commentId, setCommentId] = useState({ id: null, comment_for: '' })
  const [isMobileComment, setIsMobileComment] = useState(false)
  const fetchAddEditPost = useSelector((state: any) => state?.postAddEditModal?.data)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)

  const isStorePost = type === 'store' ? true : false
  const isNormalPost = type === 'post' ? true : false
  const isGroupPost = type === 'group' ? true : false

  const showModal = () => {
    if (user) {
      setIsModalVisible(true)
    }
  }

  const handleOk = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const showDeletePostModal = (post) => {
    setIsModalOpen(true)
  }

  const editPost = () => {

    let data = {
      ...postAddEditModal?.id,
      type: type,
      editPost: {
        isEdit: true,
        content: post?.content,
        id: post.id,
        // store_slug: 'alexandermcqueen'
      },
    }
    dispatch(fetchPostAddEditModal({ modal: 'postAddEditModal', isOpen: true, id: data }))
  }

  const handleDeleteCancel = () => {
    setIsModalOpen(false)
  }

  const editPostMobile = () => {
    dispatch(
      fetchMobileModalData({
        allModalData: [
          ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
          {
            modal: 'postAddEditModalMobile',
            isOpen: true,
            id: {
              ...fetchMobileModal?.lastModalData?.id,
              type: type,
              editPost: { isEdit: true, content: post?.content, id: post.id },
            },
            transform: 42,
          },
        ],
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
  }

  // <LazyLoad id={index || 'menu_block'}>
  return (
    <div
      className="rounded-xl mobile:border-t mobile:border-gray-300 custom-mb-8 feed-post-details-box"
      style={feedSize ? { background: 'white' } : { background: '#ECEEF4' }}
    >
      <>
        <div className="post-section-main">
          <div className="flex justify-between mobile-view-padding post-header-padding">
            <PostHeader
              isNormalPost={isNormalPost}
              isGroupPost={isGroupPost}
              isStorePost={isStorePost}
              post={post}
              store={store}
              editPost={editPost}
              showModal={showModal}
              showDeletePostModal={showDeletePostModal}
              editPostMobile={editPostMobile}
            />
          </div>
          <PostContent content={post?.content} />
          <PostMedia
            isNormalPost={isNormalPost}
            isGroupPost={isGroupPost}
            isStorePost={isStorePost}
            image={image}
            post={post}
            setCommentId={setCommentId}
            commentId={commentId}
            editPost={editPost}
            editPostMobile={editPostMobile}
            setIsMobileComment={setIsMobileComment}
            isUserPostPreviewModal={isUserPostPreviewModal}
          />
          {!post.post_for && post?.product && (
            <PostShareProduct isNormalPost={isNormalPost} isGroupPost={isGroupPost} post={post} />
          )}
          {!post.post_for && post?.collection && <PostShareFeed post={post} isNormalPost={isNormalPost} />}
        </div>
        <PostReaction
          isNormalPost={isNormalPost}
          isGroupPost={isGroupPost}
          isStorePost={isStorePost}
          isSetClose={isSetClose}
          setShowMore={setShowMore}
          post={post}
          store={store}
          setCommentId={setCommentId}
          setIsMobileComment={setIsMobileComment}
          isPreview={false}
        />
        {/* {isShowComment && ( */}
        {user && (
          <div className="post-input-align-responsive">
            <PostCommetSection
              isClose={isClose}
              setShowMore={setShowMore}
              showMore={showMore}
              post={post}
              type={type}
              store={store}
              commentId={commentId}
            />
            <PostCommentInput
              user={user}
              type={type}
              isPost={true}
              store={store}
              isButton={false}
              post={post}
              isMobileButton={false}
            />
          </div>
        )}
        {/* )} */}
        {isMobileComment && (
          <MobileViewComment
            isSmallBox={false}
            isClose={isClose}
            setShowMore={setShowMore}
            isSetClose={isSetClose}
            showMore={showMore}
            post={post}
            type={type}
            store={store}
            commentId={commentId}
            setIsMobileComment={setIsMobileComment}
            isNormalPost={isNormalPost}
            isGroupPost={isGroupPost}
            isStorePost={isStorePost}
            editPost={editPost}
            editPostMobile={editPostMobile}
            showModal={showModal}
            showDeletePostModal={showDeletePostModal}
          />
        )}
        <PostSharewithFriendModal
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          user={user}
          handleOk={handleOk}
        />
        <PostDeleteModal
          isModalOpen={isModalOpen}
          isNormalPost={isNormalPost}
          isGroupPost={isGroupPost}
          isStorePost={isStorePost}
          setIsModalOpen={setIsModalOpen}
          post={post}
          handleDeleteCancel={handleDeleteCancel}
        />
      </>
    </div>
  )
  {
    /* </LazyLoad> */
  }
}

export default NormalPost
