import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { useAuth } from 'src/utils/auth'
import CREATE_LIKE_MUTATION from 'src/graphql/mutations/createLike'
import DELETE_LIKE_MUTATION from 'src/graphql/mutations/deleteLike'
import GET_STORE_BY_NAME from 'src/graphql/queries/getStoreByName'
import Avatar from 'src/components/shared/Avatar'
import getImageUrl from 'src/utils/getImageUrl'
import { Popover } from 'antd'
import PopOverAllComments from '../PopOver/PopOverAllComments'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAppDispatch } from 'src/store/store'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useSelector } from 'react-redux'
import { setCurrentStep, setGroupPosts, setStorePosts } from 'src/store/slices/fetchAllFeedSlice'
import client1 from 'src/utils/apolloClient'
import GET_SINGLE_LIKE from 'src/graphql/queries/getSingleLike'
import { useRouter } from 'next/router'
import GET_POST_BY_ID from 'src/graphql/queries/getSinglePost'
import { likePost } from 'src/utils/notificationManager'
import { SEND_POST_LIKE } from 'src/graphql/mutations/novu'
export default function PostReaction(props) {
  const { user }: any = useAuth()
  const {
    isSetClose,
    setShowMore,
    post,
    isNormalPost,
    isGroupPost,
    isStorePost,
    store,
    setCommentId,
    isPreview,
    setIsMobileComment,
  } = props
  let width
  if (typeof window !== 'undefined') {
    width = window.innerWidth
  }

  const [likeData, setLikeData] = useState({ id: null, like_for: '', post_id: null, likes: [] })
  const content_comments = (comments) => <PopOverAllComments comments={comments} />
  const [isBlockLike, setIsBlockLike] = useState<any>(false)
  const dispatch = useAppDispatch()
  const allPost = useSelector((state: any) => state?.feedData?.allPost)
  const groupPosts = useSelector((state: any) => state?.feedData?.groupPosts)
  const storePost = useSelector((state: any) => state?.feedData?.storePost)
  const recommendationPost = useSelector((state: any) => state?.feedData?.productRecommendations)

  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const liked = post?.likes?.find((like) => like?.user?.id === user?.id)
  const router = useRouter()

  const HandleLike = (postData) => {
    if (user) {
      if (!isBlockLike) {
        if (isNormalPost && post?.post_for) {
          normalPostLikeHandler(postData)
        } else if (isStorePost) {
          storePostLikeHandler(postData)
        } else if (isGroupPost) {
          if (!post?.post_for && post?.product) {
            sharedGroupProductLikeHandler(postData)
          }
          // else if (!post?.post_for && post?.product) {
          //   normalPostLikeHandler(postData)
          // }
          else {
            groupNormalPostLikeHandler(postData)
          }
        } else if (isNormalPost && !post?.post_for && post?.product) {
          sharedProductLikeHandler(postData)
        } else if (isNormalPost && !post?.post_for && post?.collection) {
          normalPostLikeHandler(postData)
        }
      }
    } else {
      handleLogin()
    }
  }

  const sharedGroupProductLikeHandler = (post) => {
    let likeFor = 'PRODUCT'
    setIsBlockLike(true)
    setLikeData({ id: liked?.id ? liked?.id : null, like_for: likeFor, post_id: post?.id, likes: post?.likes })
    if (liked) {
      let update_like = groupPosts.map((lk) => {
        if (lk?.id === post?.id) {
          return {
            ...lk,
            likes: lk?.likes?.filter((l) => l?.id !== liked.id),
          }
        } else {
          return lk
        }
      })
      dispatch(setGroupPosts(update_like))
      deleteLike({
        variables: {
          id: liked?.id,
          like_for: likeFor,
        },
        context: { setState: setGroupPosts, post_for: 'GROUP_FEED' },
      })
      return
    }
    let update_like = groupPosts.map((lk) => {
      if (lk?.id === post?.id) {
        return {
          ...lk,
          likes: [
            ...lk?.likes,
            {
              id: null,
              user: {
                id: user?.id,
                firstName: user?.firstName,
                lastName: user?.lastName,
                profileUrl: user?.logo_image,
                userName: user?.userName,
              },
            },
          ],
        }
      } else {
        return lk
      }
    })
    dispatch(setGroupPosts(update_like))
    createLike({
      variables: { parent_id: post?.product?.id, like_for: likeFor },
      context: { setState: setGroupPosts, post_for: 'GROUP_FEED' },
    })
  }

  const groupNormalPostLikeHandler = (post) => {
    let likeFor = 'post'

    setLikeData({ id: liked?.id ? liked.id : null, like_for: likeFor, post_id: post?.id, likes: post?.likes })
    // if (isNormalPost) {
    setIsBlockLike(true)
    if (liked && liked?.id) {
      let update_like = groupPosts.map((lk) => {
        if (lk?.id === post?.id) {
          return {
            ...lk,
            likes: lk?.likes?.filter((l) => l?.id !== liked.id),
          }
        } else {
          return lk
        }
      })
      dispatch(setGroupPosts(update_like))
      deleteLike({
        variables: { id: liked.id, like_for: likeFor },
        context: { setState: setGroupPosts, post_for: 'GROUP_FEED' },
      })
      return
    }
    let update_like = groupPosts.map((lk) => {
      if (lk?.id === post?.id) {
        return {
          ...lk,
          likes: [
            ...lk?.likes,
            {
              id: null,
              user: {
                id: user?.id,
                firstName: user?.firstName,
                lastName: user?.lastName,
                profileUrl: user?.logo_image,
                userName: user?.userName,
              },
            },
          ],
        }
      } else {
        return lk
      }
    })
    dispatch(setGroupPosts(update_like))
    createLike({
      variables: { parent_id: post.id, like_for: likeFor },
      context: { setState: setGroupPosts, post_for: 'GROUP_FEED' },
    })
    // }
  }

  const normalPostLikeHandler = (post) => {
    let likeFor = 'post'
    setLikeData({ id: liked?.id ? liked.id : null, like_for: likeFor, post_id: post?.id, likes: post?.likes })
    // if (isNormalPost) {
    setIsBlockLike(true)
    if (liked && liked?.id) {
      let update_like = allPost.map((lk) => {
        if (lk?.id === post?.id) {
          return {
            ...lk,
            likes: lk?.likes?.filter((l) => l?.id !== liked.id),
          }
        } else {
          return lk
        }
      })
      dispatch(setCurrentStep(update_like))
      deleteLike({
        variables: { id: liked.id, like_for: likeFor },
        context: { setState: setCurrentStep, post_for: 'FEED' },
        refetchQueries: [{ query: GET_POST_BY_ID, variables: { post_id: post?.id } }],
      })
      return
    }
    let update_like = allPost.map((lk) => {
      if (lk?.id === post?.id) {
        return {
          ...lk,
          likes: [
            ...lk?.likes,
            {
              id: null,
              user: {
                id: user?.id,
                firstName: user?.firstName,
                lastName: user?.lastName,
                profileUrl: user?.logo_image,
                userName: user?.userName,
              },
            },
          ],
        }
      } else {
        return lk
      }
    })
    dispatch(setCurrentStep(update_like))
    createLike({
      variables: { parent_id: post.id, like_for: likeFor },
      context: { setState: setCurrentStep, post_for: 'FEED' },
      refetchQueries: [{ query: GET_POST_BY_ID, variables: { post_id: post?.id } }],
    })
    // }
  }

  const sharedProductLikeHandler = (post) => {
    let likeFor = 'PRODUCT'
    setLikeData({ id: liked?.id ? liked?.id : null, like_for: likeFor, post_id: post?.id, likes: post?.likes })
    if (isNormalPost) {
      setIsBlockLike(true)
      if (liked) {
        let update_like = allPost.map((lk) => {
          if (lk?.id === post?.id) {
            return {
              ...lk,
              likes: lk?.likes?.filter((l) => l?.id !== liked.id),
            }
          } else {
            return lk
          }
        })
        dispatch(setCurrentStep(update_like))
        deleteLike({
          variables: {
            id: liked?.id,
            like_for: likeFor,
          },
          context: { setState: setCurrentStep, post_for: 'FEED' },
        })
        return
      }
      let update_like = allPost.map((lk) => {
        if (lk?.id === post?.id) {
          return {
            ...lk,
            likes: [
              ...lk?.likes,
              {
                id: null,
                user: {
                  id: user?.id,
                  firstName: user?.firstName,
                  lastName: user?.lastName,
                  profileUrl: user?.logo_image,
                  userName: user?.userName,
                },
              },
            ],
          }
        } else {
          return lk
        }
      })
      dispatch(setCurrentStep(update_like))
      createLike({
        variables: { parent_id: post?.product?.id, like_for: likeFor },
        context: { setState: setCurrentStep, post_for: 'FEED' },
      })
    }
  }

  const storePostLikeHandler = (post) => {
    let likeFor = 'SELLERPOST'
    setIsBlockLike(true)
    setLikeData({ id: liked?.id ? liked?.id : null, like_for: likeFor, post_id: post?.id, likes: post?.likes })

    if (liked) {
      // setLikeData({ id: liked.id, like_for: likeFor })
      let update_like = storePost.map((lk) => {
        if (lk?.id === post?.id) {
          return {
            ...lk,
            likes: lk?.likes?.filter((l) => l?.id !== liked.id),
          }
        } else {
          return lk
        }
      })
      dispatch(setStorePosts(update_like))
      deleteLike({
        variables: { id: liked.id, like_for: likeFor },
        context: { setState: setStorePosts, post_for: 'STORE_FEED' },
        // refetchQueries: [
        // { query: GET_STORE_BY_NAME, variables: { slug: post?.store?.slug } },
        // {
        //   query: MY_FEED_QUERY,
        //   variables: {
        //     page: 1,
        //     limit: 10,
        //   },
        // },
        // ],
      })
      return
    }
    let update_like = storePost?.map((lk) => {
      if (lk?.id === post?.id) {
        return {
          ...lk,
          likes: [
            ...lk?.likes,
            {
              id: null,
              user: {
                id: user?.id,
                firstName: user?.firstName,
                lastName: user?.lastName,
                profileUrl: user?.logo_image,
                userName: user?.userName,
              },
            },
          ],
        }
      } else {
        return lk
      }
    })
    dispatch(setStorePosts(update_like))
    createLike({
      variables: { parent_id: post.id, like_for: likeFor },
      context: { setState: setStorePosts, post_for: 'STORE_FEED' },
      // refetchQueries: [
      // { query: GET_STORE_BY_NAME, variables: { slug: post?.store?.slug } },
      // {
      //   query: MY_FEED_QUERY,
      //   variables: {
      //     page: 1,
      //     limit: 10,
      //   },
      // },
      // ],
    })
  }

  const groupPostLikeHandler = (post) => {
    let likeFor = 'PRODUCT'
    setLikeData({ id: liked?.id ? liked?.id : null, like_for: likeFor, post_id: post?.id, likes: post?.likes })
    setIsBlockLike(true)

    if (liked) {
      let update_like = groupPosts.map((lk) => {
        if (lk?.id === post?.id) {
          return {
            ...lk,
            likes: lk?.likes?.filter((l) => l?.id !== liked.id),
          }
        } else {
          return lk
        }
      })
      // dispatch(setCurrentStep(update_like))
      dispatch(setGroupPosts(update_like))
      deleteLike({
        variables: { id: liked.id, like_for: post?.post_for ? post?.post_for : likeFor },
        context: { setState: setGroupPosts, post_for: 'GROUP_FEED' },
      })
      return
    }
    let update_like = groupPosts.map((lk) => {
      if (lk?.id === post?.id) {
        return {
          ...lk,
          likes: [
            ...lk?.likes,
            {
              id: null,
              user: {
                id: user?.id,
                firstName: user?.firstName,
                lastName: user?.lastName,
                profileUrl: user?.logo_image,
                userName: user?.userName,
              },
            },
          ],
        }
      } else {
        return lk
      }
    })
    dispatch(setCurrentStep(update_like))
    createLike({
      variables: { parent_id: post.id, like_for: post?.post_for ? post?.post_for : likeFor },
      context: { setState: setGroupPosts, post_for: 'GROUP_FEED' },
    })
  }

  const [sendPostLike] = useMutation(SEND_POST_LIKE)
  const [createLike] = useMutation(CREATE_LIKE_MUTATION, {
    onCompleted: async (response, { context: { setState, post_for } }) => {
      let state = []
      switch (post_for) {
        case 'GROUP_FEED':
          state = groupPosts
          break
        case 'FEED':
          state = allPost
          break
        case 'STORE_FEED':
          state = storePost
          break
        default:
          break
      }
      if (response?.createLike?.id) {
        const { data } = await client1.query({
          query: GET_SINGLE_LIKE,
          variables: {
            parentId: response?.createLike?.id,
            likeFor: likeData?.like_for,
          },
          fetchPolicy: 'network-only',
        })
        if (data?.getSingleLike?.success) {
          let update_like = state?.map((lk) => {
            if (lk?.id === likeData?.post_id) {
              return {
                ...lk,
                likes: lk?.likes?.map((cr) => {
                  if (cr?.user?.id === user?.id) {
                    return { ...cr, id: response?.createLike?.id }
                  } else {
                    return cr
                  }
                }),
              }
            } else {
              return lk
            }
          })

          dispatch(setState(update_like))
          // likePost({
          //   sourceUsername: user?.userName,
          //   destinationID: post?.user?.id,
          //   postURL: router.asPath,
          // })
          sendPostLike({
            variables: {
              input: {
                to: post?.user?.id,
                userName: user?.userName,
                userImg: user?.logo_image,
              }
            },
          })
        } else {
          let update_like = state.map((lk) => {
            if (lk?.id === likeData?.post_id) {
              return {
                ...lk,
                likes: lk?.likes?.filter((l) => l?.user?.id !== user?.id),
              }
            } else {
              return lk
            }
          })
          dispatch(setState(update_like))
          sendPostLike({
            variables: {
              input: {
                to: post?.user?.id,
                userName: user?.userName,
                userImg: user?.logo_image,
              }
            },
          })

          // likePost({
          //   sourceUsername: user?.userName,
          //   destinationID: post?.user?.userName,
          //   postURL: router.asPath,
          // })
        }
      }
      setIsBlockLike(false)
    },
    onError: (error) => {
      setIsBlockLike(false)
    },
  })

  const [deleteLike] = useMutation(DELETE_LIKE_MUTATION, {
    onCompleted: async (response, { context: { setState, post_for } }) => {
      setIsBlockLike(false)
      let state = []
      switch (post_for) {
        case 'GROUP_FEED':
          state = groupPosts
          break
        case 'FEED':
          state = allPost
          break
        case 'STORE_FEED':
          state = storePost
          break
        default:
          break
      }
      if (likeData?.id) {
        const { data } = await client1.query({
          query: GET_SINGLE_LIKE,
          variables: {
            parentId: likeData?.id,
            likeFor: likeData?.like_for,
          },
          fetchPolicy: 'network-only',
        })

        if (data?.getSingleLike?.success) {
          let update_like = state.map((lk) => {
            if (lk?.id === likeData?.post_id) {
              return { ...lk, likes: lk?.likes?.filter((l) => l?.id !== likeData?.id) }
            } else {
              return lk
            }
          })
          dispatch(setState(update_like))
        } else {
          let update_like = state.map((lk) => {
            if (lk?.id === likeData?.post_id) {
              return {
                ...lk,
                likes: likeData?.likes,
              }
            } else {
              return lk
            }
          })
          dispatch(setState(update_like))
        }
      }
    },
    onError: (error) => {
      console.log(error)
      setIsBlockLike(false)
    },
  })

  const handleOPenComment = () => {
    if (user) {
      if (post?.comment_count > 0) isSetClose((prev) => !prev)
      setCommentId({ id: post?.id, comment_for: post?.post_for ? 'POST' : 'SHAREPOST', post: post })
    } else {
      handleLogin()
    }
  }

  const handleLogin = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Share-Product-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const handleMove = (post) => {
    if (user) {
      HandleLike(post)
    } else {
      handleLogin()
    }
  }

  const handleShare = () => {
    if (user) {
    } else {
      handleLogin()
    }
  }

  const handleCommentModal = () => {
    if (user) {
      // if (post?.comment_count > 0) {
      dispatch(
        fetchMobileModalData({
          allModalData: [
            ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
            {
              modal: 'mobilecommentModal',
              isOpen: true,
              id: {
                post: post,
                postFor: isGroupPost ? 'group' : isNormalPost ? 'user' : '',
              },
              transform: 16,
              unit: "%"
            },
          ],
          lastModalData: fetchMobileModal?.lastModalData,
        }),
      )
      // }
    } else {
      handleLogin()
    }
  }

  // const handleBookmarkModal = () => {
  //   if (user) {
  //     // if (post?.comment_count > 0) {
  //     dispatch(
  //       fetchMobileModalData({
  //         allModalData: [
  //           ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
  //           {
  //             modal: 'mobilecommentModal',
  //             isOpen: true,
  //             id: {
  //               post: post,
  //               postFor: isGroupPost ? 'group' : isNormalPost ? 'user' : '',
  //             },
  //             transform: 16,
  //             unit: "%"
  //           },
  //         ],
  //         lastModalData: fetchMobileModal?.lastModalData,
  //       }),
  //     )
  //     // }
  //   } else {
  //     handleLogin()
  //   }
  // }

  return (
    <div
      className={`flex justify-between items-center md:mr-6 comment-part active-border-comment  mobile:border-gray-300 ${user ? 'border-bottom-alignment' : ''
        }`}
    >
      <div className="post-comment-share-section">
        {/* web view comment design start  ====================> */}
        <div
          className="post-comment-section web-view-comment-section"
          style={isPreview ? { borderLeft: '#e4e4e8 1px solid' } : {}}
          onClick={() => {
            handleOPenComment()
          }}
        >
          <div className="icons-option-alignment">
            <img src={'/assets/icon/feed/comment-post-icon.svg'} className="defult-icon-alignment" />

            <img src={'/assets/icon/feed/comment-post-icon-active.svg'} className="hover-icon-alignment" />
          </div>
          <span> {post?.comment_count === 0 ? '' : post?.comment_count}</span>
        </div>
        {/* web view comment design end ====================> */}

        {/* mobile view comment design start ====================> */}

        <div
          className="post-comment-section mobile-child-details-alignment"
          style={isPreview ? { borderLeft: '#e4e4e8 1px solid' } : {}}
          onClick={() => handleCommentModal()}
        >
          <div className="icons-option-alignment">
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="21" viewBox="0 0 23 21" fill="none">
              <path
                d="M22 15C22 15.6188 21.7542 16.2123 21.3166 16.6499C20.879 17.0875 20.2855 17.3333 19.6667 17.3333H6.28799C5.89016 17.3333 5.50863 17.4914 5.22733 17.7727L3.56066 19.4393C2.61571 20.3843 1 19.715 1 18.3787V3.33333C1 2.71449 1.24583 2.121 1.68342 1.68342C2.121 1.24583 2.71449 1 3.33333 1H19.6667C20.2855 1 20.879 1.24583 21.3166 1.68342C21.7542 2.121 22 2.71449 22 3.33333V15Z"
                stroke="#7A7E88"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <span> {post?.comment_count === 0 ? '' : post?.comment_count}</span>
        </div>

        {/* <div
          className="post-comment-section mobile-child-details-alignment"
          style={isPreview ? { borderLeft: '#e4e4e8 1px solid' } : {}}
          onClick={() => handleBookmarkModal()}
        >
          <div className="icons-option-alignment">
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="21" viewBox="0 0 23 21" fill="none">
              <path
                d="M22 15C22 15.6188 21.7542 16.2123 21.3166 16.6499C20.879 17.0875 20.2855 17.3333 19.6667 17.3333H6.28799C5.89016 17.3333 5.50863 17.4914 5.22733 17.7727L3.56066 19.4393C2.61571 20.3843 1 19.715 1 18.3787V3.33333C1 2.71449 1.24583 2.121 1.68342 1.68342C2.121 1.24583 2.71449 1 3.33333 1H19.6667C20.2855 1 20.879 1.24583 21.3166 1.68342C21.7542 2.121 22 2.71449 22 3.33333V15Z"
                stroke="#7A7E88"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <span> {post?.comment_count === 0 ? '' : post?.comment_count}</span>
        </div> */}

        {/* mobile view comment design end ====================> */}

        <div className="post-share-section" onClick={() => handleShare()}>
          <div className="icons-option-alignment">
            <img src={'/assets/icon/feed/share-post-icon.svg'} className="defult-icon-alignment" />

            <img src={'/assets/icon/feed/share-post-icon-active.svg'} className="hover-icon-alignment" />
          </div>
        </div>
      </div>

      <div className="post-like-section">
        <svg
          width="24"
          height="21"
          viewBox="0 0 24 21"
          fill={liked ? '#FF0000' : '#FFFFFF'}
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => {
            handleMove(post)
          }}
        >
          <path
            d="M7.1263 1.33594C3.83561 1.33594 1.16797 4.00359 1.16797 7.29427C1.16797 13.2526 8.20963 18.6693 12.0013 19.9293C15.793 18.6693 22.8346 13.2526 22.8346 7.29427C22.8346 4.00359 20.167 1.33594 16.8763 1.33594C14.8611 1.33594 13.0796 2.33634 12.0013 3.86758C10.923 2.33634 9.14146 1.33594 7.1263 1.33594Z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke={liked ? '#FF0000' : '#ACB1C0'}
          />
        </svg>
        <div className="flex items-center">
          <Avatar
            avatarlist={post?.likes
              ?.filter((like, i) => i <= 3)
              .map((like, i) => {
                let profilePhoto =
                  like?.user?.id === user?.id
                    ? user?.logo_image
                    : 'https://bluejestic-media.s3.amazonaws.com/dummy-profile%20%281%29-1669122101773-764781589-1669182656605-3255156.jpg'
                return {
                  id: i,
                  comment: like?.like,
                  name: like?.user?.firstName + ' ' + like?.user?.lastName,
                  avatarURL: like?.user?.logo_image ? getImageUrl(like?.user?.logo_image) : profilePhoto,
                }
              })}
            type={true}
            radius={width?.innerWidth <= 767 ? 24 : 26}
            border={2}
          />
          {post?.likes?.length > 4 && (
            <Popover content={() => content_comments(post?.likes)} placement="topLeft">
              <div className="popover-main-align">
                <button>+{post?.likes?.length - 4}</button>
              </div>
            </Popover>
          )}
        </div>
      </div>
    </div>
  )
}
