import React from 'react'
import BookInfoSection from './BookInfoSection'
import MoreBookSection from './MoreBookSection'

export default function BookDetailMain() {
  return (
    <div className="book-other-details-section">
      <div className="book-other-details-grid-alignment">
        <BookInfoSection />
        <MoreBookSection />
      </div>
    </div>
  )
}
