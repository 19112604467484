'use client'

import React, { useState, useRef } from 'react'
import { useAuth } from '../../../utils/auth'
import getImageUrl from '../../../utils/getImageUrl'
import { useAppDispatch } from '../../../store/store'
import { fetchPostAddEditModal } from '../../../store/slices/postAddEditSlice'
import { useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import CREATE_POST_MUTATION from '../../../graphql/mutations/createPost'
import { notification, Spin } from 'antd'
import axios from 'axios'
import nookies from 'nookies'
import { API } from '../../../config/API/api.config'
import GET_STORE_BY_NAME from '../../../graphql/queries/getStoreByName'
import { fetchAllFeedSliceData } from '../../../store/slices/fetchAllFeedSlice'
import { fetchUserPost } from 'src/store/slices/fetchAllFeedSlice'
import { fetchGroupPost } from '../../../store/slices/fetchAllFeedSlice'
import styles from './styles.module.scss'
import data from '@emoji-mart/data'
import { Picker } from 'emoji-mart'
import { Input } from 'antd'
import {
  GlobalOutlined,
  UserOutlined,
  TeamOutlined,
  LockOutlined,
  HomeOutlined,
  DownOutlined,
  CheckOutlined,
} from '@ant-design/icons'

const SUPPORTED_VIDEO_FORMATS = [
  'video/mp4',
  'video/webm',
  'video/ogg',
  'video/quicktime',
  'video/x-msvideo',
  'video/x-matroska',
  'video/3gpp',
  'video/x-ms-wmv',
]

const SUPPORTED_IMAGE_FORMATS = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml']

interface PostSomethingMobileProps {
  title: string
  type: string
  group?: any
  isSeller?: boolean
  mobile?: boolean
}

const PostSomethingMobile: React.FC<PostSomethingMobileProps> = ({
  title,
  type,
  group = {},
  isSeller = false,
  mobile = true,
}) => {
  const { user }: any = useAuth()
  const dispatch = useAppDispatch()
  const [postContent, setPostContent] = useState('')
  const [newUserInfo, setNewUserInfo] = useState<any>({ profileImages: [] })
  const [loading, setLoading] = useState(false)
  const [progressPercent, setProgressPercent] = useState(0)
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [showMediaPicker, setShowMediaPicker] = useState(false)
  const [showGifPicker, setShowGifPicker] = useState(false)
  const [showAiPicker, setShowAiPicker] = useState(false)
  const [audience, setAudience] = useState('everyone')
  const [showAudienceModal, setShowAudienceModal] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const fetchAddEditPost = useSelector((state: any) => state?.postAddEditModal?.data)
  const [showGifModal, setShowGifModal] = useState(false);
  const [gifs, setGifs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoadingGifs, setIsLoadingGifs] = useState(false);

  const handleGifButtonClick = () => {
    setShowGifModal(!showGifModal);
    if (!showGifModal) {
      fetchGifs(''); // Only fetch when opening
    }
  };

  const fetchGifs = async (query: string) => {
    setIsLoadingGifs(true);
    try {
      const apiKey = process.env.NEXT_PUBLIC_TENOR_API_KEY;
      const url = `https://tenor.googleapis.com/v2/search?q=${query || 'trending'}&key=${apiKey}&client_key=social_commerce&limit=20`;
      const response = await axios.get(url);

      if (response.data.results) {
        setGifs(response.data.results);
      } else {
        notification.error({
          message: 'No GIFs found',
          description: 'Try a different search term.'
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error loading GIFs',
        description: 'Please check your internet connection and try again.'
      });
    } finally {
      setIsLoadingGifs(false);
    }
  };

  const handleGifSearch = (value: string) => {
    setSearchQuery(value);
    fetchGifs(value);
  };

  const handleGifSelect = (gif: any) => {
    const gifUrl = gif.media_formats?.mediumgif?.url || gif.media_formats?.gif?.url || gif.url;
    setPostContent(prev => prev + ' ' + gifUrl);
    setShowGifModal(false);
  };

  const isNormalPost = type === 'normal'
  const isGroupPost = type === 'group'
  const isStorePost = isSeller

  const page = 1
  const limit = 10

  const audienceOptions = [
    {
      id: 'everyone',
      title: 'Everyone',
      description: 'Anyone on Social Commerce can see this post',
      icon: <GlobalOutlined />,
    },
    {
      id: 'friends',
      title: 'Friends',
      description: 'Only your friends can see this post',
      icon: <UserOutlined />,
    },
    {
      id: 'clubs',
      title: 'My Clubs',
      description: 'Share with members of your clubs',
      icon: <TeamOutlined />,
    },
    {
      id: 'only_me',
      title: 'Only Me',
      description: 'Only you can see this post',
      icon: <LockOutlined />,
    },
  ]

  const getAudienceDisplay = () => {
    const option = audienceOptions.find((opt) => opt.id === audience)
    return option?.title || 'Everyone'
  }

  const [createPost] = useMutation(CREATE_POST_MUTATION, {
    onCompleted: () => {
      notification.success({
        message: 'Post created successfully',
        description: 'Your post has been created successfully',
      })

      if (isSeller) {
        dispatch(
          fetchGroupPost({
            page,
            limit,
            group_id: group?.id,
            prevPost: [],
          }),
        )
      } else if (isNormalPost) {
        dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
        dispatch(fetchUserPost({ page, limit, user_id: user?.id, prevPost: [] }))
      } else if (isGroupPost) {
        dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
        dispatch(
          fetchGroupPost({
            page,
            limit,
            group_id: group?.id,
            prevPost: [],
          }),
        )
      }
      setProgressPercent(100)
      setTimeout(() => {
        setLoading(false)
        setPostContent('')
        setNewUserInfo({ profileImages: [] })
        setIsBottomSheetOpen(false)
      }, 500)
    },
    onError: (error) => {
      console.error('Error creating post:', error)
      notification.error({
        message: 'Error creating post',
        description: error.message,
      })
      setLoading(false)
      setProgressPercent(0)
    },
  })

  const isVideoFile = (file: File) => {
    return SUPPORTED_VIDEO_FORMATS.includes(file?.type)
  }

  const isImageFile = (file: File) => {
    return SUPPORTED_IMAGE_FORMATS.includes(file?.type)
  }

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || [])

    // Validate file types
    const invalidFiles = files.filter((file) => !isImageFile(file) && !isVideoFile(file))
    if (invalidFiles.length > 0) {
      notification.error({
        message: 'Invalid file format',
        description: 'Please upload only images or videos in supported formats.',
      })
      return
    }

    // Check file sizes
    const MAX_FILE_SIZE = 100 * 1024 * 1024 // 100MB limit
    const oversizedFiles = files.filter((file) => file.size > MAX_FILE_SIZE)
    if (oversizedFiles.length > 0) {
      notification.error({
        message: 'File too large',
        description: 'Files must be less than 100MB',
      })
      return
    }

    setNewUserInfo((prev) => ({
      ...prev,
      profileImages: [...prev.profileImages, ...files],
    }))
  }

  const handleRemoveImage = (index: number) => {
    setNewUserInfo((prev) => ({
      ...prev,
      profileImages: prev.profileImages.filter((_: any, i: number) => i !== index),
    }))
  }

  const handleEmojiSelect = (emoji: any) => {
    setPostContent((prev) => prev + emoji.native)
    setShowEmojiPicker(false)
  }

  const handleSubmit = async () => {
    if (!postContent?.trim() && !newUserInfo.profileImages?.length) return

    setLoading(true)
    setProgressPercent(25)

    try {
      // Handle new post
      setProgressPercent(50)

      // Handle media uploads if any
      let media_type = ''
      let ImageId: number[] = []
      if (newUserInfo.profileImages.length > 0) {
        setProgressPercent(30)
        const UploadFile = new FormData()

        // Determine media type based on first file
        const firstFile = newUserInfo.profileImages[0]
        if (isVideoFile(firstFile)) {
          media_type = 'video'
        } else if (isImageFile(firstFile)) {
          media_type = 'image'
        }

        let BaseURL = API?.endpoint
        BaseURL = BaseURL.replace('/graphql', '')
        BaseURL = BaseURL.replace('.bluejestic', '.api.bluejestic')

        const UploadImageRoute =
          process.env.NODE_ENV === 'development'
            ? `/upload?type=bluejestic-stage/post`
            : `/upload?type=bluejestic-production/post`

        newUserInfo.profileImages.forEach((file: File) => {
          UploadFile.append('image', file)
        })
        UploadFile.append('media_for', 'post')

        const token = nookies.get()
        await axios
          .post(BaseURL + UploadImageRoute, UploadFile, {
            headers: {
              'Content-Type': 'multipart/form-data',
              authorization: `Bearer ${token?.token}`,
            },
          })
          .then((res: any) => {
            res?.data?.image?.map((image: any) => {
              ImageId.push(image.id)
            })
            setProgressPercent(50)
          })
          .catch((err) => {
            setProgressPercent(0)
            notification.error({
              message: 'Upload failed',
              description: 'Failed to upload media files. Please try again.',
            })
            throw err
          })
      }

      await createPost({
        variables: {
          content: postContent,
          title: postContent,
          post_for: isSeller ? 'SELLER' : isNormalPost ? 'USER' : 'GROUP',
          media_id: ImageId,
          group_id: isNormalPost ? null : group?.id,
          store_id: isSeller && user?.store ? user?.store?.id : null,
          media_type: media_type,
          audience: audience,
        },
        refetchQueries: isSeller ? [{ query: GET_STORE_BY_NAME, variables: { storeName: user?.store?.name } }] : [],
      })
    } catch (error: any) {
      console.error('Error submitting post:', error)
      notification.error({
        message: 'Error creating post',
        description: error.message,
      })
      setLoading(false)
      setProgressPercent(0)
    }
  }

  if (!mobile || fetchAddEditPost?.isOpen) return null

  return user ? (
    <div className="block lg:hidden">
      {/* Trigger Button */}
      <div className="feed-mobileSellerCommentPostAlignment" onClick={() => setIsBottomSheetOpen(true)}>
        <div className="feed-mobileSellerCommentBox">
          <h6>{title}</h6>
          <div className="feed-mobileSellerPostBoxAlignment">
            <div className={styles.postImgAlignment}>
              <img
                src={
                  isSeller
                    ? user?.store?.logo_image
                    : user?.logo_image
                    ? getImageUrl(user?.logo_image)
                    : '/assets/img/dummy-profile.svg'
                }
                alt="Profile"
                className={styles.postImg}
              />
            </div>
            <div className="feed-mobileSellerInputAlignment">
              <img src="/assets/seller/icons/feather.svg" alt="feather icon" />
              <input type="text" placeholder="Write something..." readOnly />
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Sheet */}
      <div
        className={`${styles.overlay} ${
          isBottomSheetOpen || showEmojiPicker || showGifPicker || showAiPicker ? styles.open : styles.closed
        }`}
        onClick={() => {
          if (showEmojiPicker) setShowEmojiPicker(false)
          else if (showGifPicker) setShowGifPicker(false)
          else if (showAiPicker) setShowAiPicker(false)
          else setIsBottomSheetOpen(false)
        }}
      />

      {/* Main Bottom Sheet */}
      <div className={`${styles['bottom-sheet']} ${isBottomSheetOpen ? styles.open : styles.closed}`}>
        <div className={styles['bottom-sheet-header']}>
          <div className={styles.title}>Create Post</div>
          <button className={styles['close-button']} onClick={() => setIsBottomSheetOpen(false)}>
            Close
          </button>
        </div>

        <div className={styles['bottom-sheet-content']}>
          {showAudienceModal ? (
            <div className={styles['audience-modal']}>
              <div className={styles['modal-header']}>
                <button className={styles['back-button']} onClick={() => setShowAudienceModal(false)}>
                  <img src="/assets/seller/icons/arrow-left.svg" alt="Back" />
                </button>
                <div className={styles.title}>Who can see your post?</div>
              </div>
              <div className={styles['modal-body']}>
                {audienceOptions.map((option) => (
                  <button
                    key={option.id}
                    className={`${styles['audience-option']} ${audience === option.id ? styles.selected : ''}`}
                    onClick={() => {
                      setAudience(option.id)
                      setShowAudienceModal(false)
                    }}
                  >
                    <span className={styles.icon}>{option.icon}</span>
                    <div className={styles.text}>
                      <div className={styles.title}>{option.title}</div>
                      <div className={styles.description}>{option.description}</div>
                    </div>
                    {audience === option.id && <CheckOutlined className={styles.check} />}
                  </button>
                ))}
              </div>
            </div>
          ) : (
            <>
              {/* Post Header with Avatar and Audience Selector */}
              <div className={styles['post-header']}>
                <div className={styles.avatar}>
                  <img
                    src={
                      isSeller
                        ? user?.store?.logo_image
                        : user?.logo_image
                        ? getImageUrl(user?.logo_image)
                        : '/assets/img/dummy-profile.svg'
                    }
                    alt="Profile"
                  />
                </div>
                <button className={styles['audience-selector']} onClick={() => setShowAudienceModal(true)}>
                  {audienceOptions.find((opt) => opt.id === audience)?.icon}
                  <span>{getAudienceDisplay()}</span>
                  <DownOutlined className={styles.chevron} />
                </button>
              </div>

              {/* Post Input Area */}
              <div className={styles['post-input']}>
                <textarea
                  value={postContent}
                  onChange={(e) => setPostContent(e.target.value)}
                  placeholder="What's on your mind?"
                  rows={4}
                />
              </div>

              {/* Media Preview */}
              {newUserInfo.profileImages.length > 0 && (
                <div className={styles['media-preview']}>
                  <div className={styles['media-grid']} data-count={Math.min(newUserInfo.profileImages.length, 5)}>
                    {newUserInfo.profileImages.slice(0, 5).map((file: File, index: number) => (
                      <div key={index} className={styles['preview-item']}>
                        {isVideoFile(file) ? (
                          <video className={styles['media-content']}>
                            <source src={URL.createObjectURL(file)} type={file.type} />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            src={URL.createObjectURL(file)}
                            alt={`Preview ${index}`}
                            className={styles['media-content']}
                          />
                        )}
                        <button onClick={() => handleRemoveImage(index)} className={styles['remove-button']}>
                          ×
                        </button>
                      </div>
                    ))}
                    {newUserInfo.profileImages.length > 5 && (
                      <div className={`${styles['preview-item']} ${styles['more-overlay']}`}>
                        {isVideoFile(newUserInfo.profileImages[5]) ? (
                          <video className={styles['media-content']}>
                            <source
                              src={URL.createObjectURL(newUserInfo.profileImages[5])}
                              type={newUserInfo.profileImages[5].type}
                            />
                          </video>
                        ) : (
                          <img
                            src={URL.createObjectURL(newUserInfo.profileImages[5])}
                            alt="More media"
                            className={styles['media-content']}
                          />
                        )}
                        <div className={styles['more-count']}>+{newUserInfo.profileImages.length - 5}</div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* Action Buttons */}
              <div className={styles['action-buttons']}>
                <div className={styles['media-buttons']}>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*, video/*"
                    onChange={handleImageUpload}
                    multiple
                    className="hidden"
                  />
                  <button
                    onClick={() => fileInputRef.current?.click()}
                    className={`${styles['media-button']} ${styles.photo}`}
                  >
                    <img src="/assets/img/feed/postimg.png" alt="Media" />
                    <span>Media</span>
                  </button>
                  <button
                    onClick={() => setShowEmojiPicker(true)}
                    className={`${styles['media-button']} ${styles.emoji}`}
                  >
                    <img src="/assets/img/feed/postemoji.png" alt="Emoji" />
                    <span>Emoji</span>
                  </button>
                  <button onClick={handleGifButtonClick} className={`${styles['media-button']} ${styles.gif}`}>
                    <img src="/assets/img/feed/postgif.png" alt="GIF" />
                    <span>GIF</span>
                  </button>
                  <button onClick={() => setShowAiPicker(true)} className={`${styles['media-button']} ${styles.ai}`}>
                    <img src="/assets/img/feed/postai.png" alt="AI Post" />
                    <span>AI Post</span>
                  </button>
                </div>
                <button
                  onClick={handleSubmit}
                  disabled={!postContent?.trim() && !newUserInfo.profileImages?.length}
                  className={styles['post-button']}
                >
                  {loading ? 'Posting...' : 'Post'}
                </button>
              </div>

              {/* Progress Bar */}
              {loading && progressPercent > 0 && (
                <div className={styles['progress-bar']}>
                  <div className={styles.progress} style={{ width: `${progressPercent}%` }} />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* Emoji Picker Modal */}
      {showEmojiPicker && (
        <div className={styles['modal-overlay']}>
          <div className={`${styles['modal-content']} ${styles.open}`}>
            <div className={styles['modal-header']}>
              <button className={styles['back-button']} onClick={() => setShowEmojiPicker(false)}>
                <img src="/assets/seller/icons/arrow-left.svg" alt="Back" />
              </button>
              <h2 className={styles.title}>Add Emoji</h2>
              <button className={styles['close-button']} onClick={() => setShowEmojiPicker(false)}>
                ×
              </button>
            </div>
            <div className={styles['modal-body']}>
              <div className={styles['emoji-picker']}>
                <Picker onEmojiSelect={handleEmojiSelect} />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* GIF Picker Modal */}
      {showGifModal && (
        <div className={styles['modal-overlay']}>
          <div className={`${styles['modal-content']} ${styles.open}`}>
            <div className={styles['modal-header']}>
              <button className={styles['back-button']} onClick={() => setShowGifModal(false)}>
                <img src="/assets/seller/icons/arrow-left.svg" alt="Back" />
              </button>
              <h2 className={styles.title}>Add GIF</h2>
              <button className={styles['close-button']} onClick={() => setShowGifModal(false)}>
                ×
              </button>
            </div>
            <div className={styles['modal-body']}>
              <div className={styles['gif-picker']}>
                <Input.Search
                  placeholder="Search GIFs..."
                  onChange={(e) => handleGifSearch(e.target.value)}
                  className={styles['search-input']}
                />
                <div className={styles['gif-grid']}>
                  {isLoadingGifs ? (
                    <div className={styles.loading}>
                      <Spin />
                    </div>
                  ) : (
                    gifs.map((gif) => (
                      <div
                        key={gif.id}
                        className={styles['gif-item']}
                        onClick={() => handleGifSelect(gif)}
                      >
                        <img
                          src={gif.media_formats?.mediumgif?.url || gif.media_formats?.gif?.url || gif.url}
                          alt={gif.content_description || 'GIF'}
                          loading="lazy"
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* AI Post Modal */}
      {showAiPicker && (
        <div className={styles['modal-overlay']}>
          <div className={`${styles['modal-content']} ${styles.open}`}>
            <div className={styles['modal-header']}>
              <button className={styles['back-button']} onClick={() => setShowAiPicker(false)}>
                <img src="/assets/seller/icons/arrow-left.svg" alt="Back" />
              </button>
              <h2 className={styles.title}>AI Post Generator</h2>
              <button className={styles['close-button']} onClick={() => setShowAiPicker(false)}>
                ×
              </button>
            </div>
            <div className={styles['modal-body']}>
              <div className={styles['ai-post']}>
                <div className={styles['tone-selector']}>
                  <select value="casual" onChange={(e) => console.log(e.target.value)}>
                    <option value="casual">Casual</option>
                    <option value="professional">Professional</option>
                    <option value="enthusiastic">Enthusiastic</option>
                    <option value="informative">Informative</option>
                  </select>
                </div>
                <div className={styles['prompt-input']}>
                  <textarea
                    value=""
                    onChange={(e) => console.log(e.target.value)}
                    placeholder="What would you like to post about?"
                  />
                </div>
                <button
                  className={styles['generate-button']}
                  onClick={() => console.log('Generate Post')}
                  disabled={true}
                >
                  Generate Post
                </button>
                {false && (
                  <div className={styles['generated-content']}>
                    <h3>Generated Post</h3>
                    <p></p>
                    <button className={styles['use-content-button']} onClick={() => console.log('Use This Post')}>
                      Use This Post
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  ) : null
}

export default PostSomethingMobile
