import React from 'react'
import styles from './JewelryLiveChannelsModal.module.scss'
export default function JewelryLiveChannelsModal() {
  return (
    <div className={styles.jewelryLiveChannelsModalSection}>
      <div className={styles.jewelryLiveChannelsModalAlignment}>
        <div className={styles.jewelryLiveChannelsDetailsAlignment}>
          <div className={styles.jewelryLiveChannelsDetailsHeading}>
            <h6>Live Channels</h6>

            <div className={styles.exploreChannel}>
              <a>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <rect x="1.5" y="4.5" width="15" height="11.25" rx="2.25" stroke="#556EE6" strokeWidth="1.25" />
                  <path
                    d="M12.75 2.25L9 4.5L5.25 2.25"
                    stroke="#556EE6"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.08913 11.0846C9.51171 11.0121 9.79553 10.6108 9.72305 10.1882C9.65058 9.76566 9.24926 9.48184 8.82667 9.55432C8.40409 9.62679 8.12028 10.0281 8.19275 10.4507C8.26523 10.8733 8.66655 11.1571 9.08913 11.0846Z"
                    stroke="#556EE6"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.3019 8.41997C10.5519 8.59656 10.7647 8.82068 10.928 9.07953C11.0913 9.33839 11.202 9.62689 11.2537 9.92855C11.3054 10.2302 11.2972 10.5391 11.2295 10.8376C11.1617 11.1361 11.0359 11.4183 10.859 11.668M7.6141 12.2206C7.36411 12.044 7.15138 11.8199 6.98808 11.5611C6.82477 11.3022 6.71408 11.0137 6.66234 10.712C6.61061 10.4104 6.61884 10.1015 6.68656 9.80302C6.75429 9.50454 6.88018 9.22234 7.05704 8.97255M11.1989 7.1516C12.0392 7.74603 12.609 8.64985 12.783 9.6643C12.9569 10.6788 12.7209 11.7208 12.1267 12.5612M6.7171 13.489C5.87683 12.8946 5.30706 11.9907 5.13308 10.9763C4.95909 9.96184 5.19514 8.91982 5.78932 8.07938"
                    stroke="#556EE6"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Explore other Channel
              </a>
            </div>
          </div>

          <div className={styles.jewelryLiveChannelsDetailsBodyDetails}>
            <div className={styles.jewelryLiveChannelsDetailsGrid}>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((_, index) => {
                return (
                  <div className={styles.jewelryLiveChannelsDetailsGridItem} key={index}>
                    <div className={styles.modalJewelryLiveDetailsImgAlignment}>
                      <img src="/assets/shop/img/demo-img-2.png" alt="demo-img" />

                      <div className={styles.modalJewelryLiveDetailsBlurDetails}>
                        <div className={styles.jewelryLiveAndViewAlignment}>
                          <div className={styles.jewelryLiveBoxAlignment}>
                            <img src="/assets/shop/icons/live-icon.svg" alt="live" />
                            <p>Live</p>
                          </div>

                          <div className={styles.jewelryLiveNumberAlignment}>
                            <img src="/assets/shop/icons/eye.svg" alt="eyes" />
                            <p>9.642</p>
                          </div>
                        </div>

                        <div className={styles.jewelryLiveAndViewOtherDetailsALignment}>
                          <div className={styles.jewelryLiveAndViewOtherName}>
                            <h6>Live Adventures Await</h6>
                            <div className={styles.dateAlignment}>
                              <p>May 8, 2023</p>
                            </div>
                            <div className={styles.jewelryBrandNameAlignment}>
                              <div className={styles.jewelryBrandImg}>
                                <img src="/assets/shop/img/demo-img-2.png" alt="demo-img" />
                              </div>
                              <p>NIKE Official</p>
                            </div>
                          </div>
                          <div className={styles.jewelryLiveAndViewHeartShareAlignment}>
                            <div className={styles.jewelryLiveAndViewOtherDetailsBoxNames}>
                              <div className={styles.jewelryLiveAndViewOtherDetailsBox}>
                                <img src="/assets/shop/icons/heart.svg" alt="heart icon" />
                              </div>
                              <p>234k</p>
                            </div>
                            <div className={styles.jewelryLiveAndViewOtherDetailsBoxNames}>
                              <div className={styles.jewelryLiveAndViewOtherDetailsBox}>
                                <img src="/assets/shop/icons/share-2.svg" alt="share icon" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className={styles.jewelryLiveChannelsDetailsAlignment}>
          <div className={styles.jewelryLiveChannelsDetailsHeading}>
            <h6>Suggested Channels</h6>
          </div>

          <div className={styles.jewelryLiveChannelsDetailsBodyDetails}>
            <div className={styles.jewelryLiveChannelsDetailsGrid}>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((_, index) => {
                return (
                  <div className={styles.jewelryLiveChannelsDetailsGridItem} key={index}>
                    <div className={styles.modalJewelryLiveDetailsImgAlignment}>
                      <img src="/assets/shop/img/demo-img-2.png" alt="demo-img" />

                      <div className={styles.modalJewelryLiveDetailsBlurDetails}>
                        <div className={styles.jewelryLiveAndViewAlignment}>
                          <div className={styles.jewelryLiveBoxAlignment}>
                            <img src="/assets/shop/icons/live-icon.svg" alt="live" />
                            <p>Live</p>
                          </div>

                          <div className={styles.jewelryLiveNumberAlignment}>
                            <img src="/assets/shop/icons/eye.svg" alt="eyes" />
                            <p>9.642</p>
                          </div>
                        </div>

                        <div className={styles.jewelryLiveAndViewOtherDetailsALignment}>
                          <div className={styles.jewelryLiveAndViewOtherName}>
                            <h6>Live Adventures Await</h6>
                            <div className={styles.dateAlignment}>
                              <p>May 8, 2023</p>
                            </div>
                            <div className={styles.jewelryBrandNameAlignment}>
                              <div className={styles.jewelryBrandImg}>
                                <img src="/assets/shop/img/demo-img-2.png" alt="demo-img" />
                              </div>
                              <p>NIKE Official</p>
                            </div>
                          </div>
                          <div className={styles.jewelryLiveAndViewHeartShareAlignment}>
                            <div className={styles.jewelryLiveAndViewOtherDetailsBoxNames}>
                              <div className={styles.jewelryLiveAndViewOtherDetailsBox}>
                                <img src="/assets/shop/icons/heart.svg" alt="heart icon" />
                              </div>
                              <p>234k</p>
                            </div>
                            <div className={styles.jewelryLiveAndViewOtherDetailsBoxNames}>
                              <div className={styles.jewelryLiveAndViewOtherDetailsBox}>
                                <img src="/assets/shop/icons/share-2.svg" alt="share icon" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
