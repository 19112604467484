import { useState } from 'react'
import { Modal } from 'antd'
import FileUpload from 'src/components/FileUpload'
import { useMutation } from '@apollo/client'
import CREATE_GROUP_COMMENT from 'src/graphql/mutations/createGroupComment'
import GET_STORE_BY_NAME from 'src/graphql/queries/getStoreByName'
// import MY_FEED_QUERY from 'src/graphql/queries/getMyFeed'
import GET_PRODUCT_BY_NAME from 'src/graphql/queries/getProductByName'
import GET_COMMENT_BY_ID from 'src/graphql/queries/getCommentById'
import InputEmoji from 'react-input-emoji'
import SendIcon from '../../../../public/assets/icon/send.svg'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchAllFeedSliceData, fetchStorePost } from 'src/store/slices/fetchAllFeedSlice'
import { useAuth } from 'src/hooks/useAuth'
import FileUploadComment from 'src/components/FileUploadComment'

const InputWithEmoti = (props: any) => {
  const { type, post, store, isButton, isMobileButton } = props
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [newUserInfo, setNewUserInfo] = useState({ profileImages: [] })
  const [postContent, setPostContent] = useState('')
  const dispatch = useAppDispatch()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  // const allPost = useSelector((state: any) => state?.feedData?.allPost)
  const { user, seller }: any = useAuth()

  const isStorePost = type === 'store' ? true : false
  const isNormalPost = type === 'post' ? true : false
  const isGroupPost = type === 'group' ? true : false
  const isProduct = type === 'product' ? true : false

  const updateUploadedFiles = (files: any) => {
    console.log('Uploaded files:', files); // Debugging uploaded files
    setNewUserInfo({ ...newUserInfo, profileImages: files })
  }

  const [createComment] = useMutation(CREATE_GROUP_COMMENT, {
    onCompleted: (res) => {
      dispatch(fetchAllFeedSliceData({ page, limit, prevPost: [] }))
      dispatch(fetchStorePost({ page, limit, slug: seller?.seller_detail?.slug, prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const normalPostCommentHandler = () => {
    let commentFor = 'post'
    if (postContent.trim().length === 0) return
    createComment({
      variables: { parent_id: post.id, comment: postContent, comment_for: commentFor },
      refetchQueries: [
        { query: GET_COMMENT_BY_ID, variables: { id: post?.id, comment_for: 'POST' } },
        // { query: MY_FEED_QUERY },
      ],
    })
    setPostContent('')
  }

  const storePostCommentHandler = () => {
    if (postContent.trim().length === 0) return
    createComment({
      variables: { parent_id: post.id, comment: postContent, comment_for: 'store' },
      refetchQueries: [
        { query: GET_STORE_BY_NAME, variables: { storeName: store?.businessInformation?.name } },
        { query: GET_COMMENT_BY_ID, variables: { id: post?.id, comment_for: 'POST' } },
      ],
    }).then((refetch) => {
      if (refetch.data !== undefined) {
        setPostContent('')
      }
    })
  }

  const groupPostCommentHandler = () => {
    if (postContent.trim().length === 0) return
    createComment({
      variables: { parent_id: post?.id, comment: postContent, comment_for: 'group' },
      // variables: { parent_id: post?.product?.id, comment: postContent, comment_for: 'PRODUCT' },

      refetchQueries: [
        // { query: MY_FEED_QUERY, variables: { group_id: post?.groupId } },
        { query: GET_COMMENT_BY_ID, variables: { id: post?.id, comment_for: 'POST' } },
        // { query: GET_COMMENT_BY_ID, variables: { id: post?.id, comment_for: 'SHAREPOST' } },
      ],
    }).then((refetch) => {
      if (refetch.data !== undefined) {
        setPostContent('')
      }
    })
  }

  const productCommentHandler = () => {
    if (postContent.trim().length === 0) return
    createComment({
      variables: { parent_id: post?.id, comment: postContent, comment_for: 'PRODUCT' },
      refetchQueries: [
        // { query: GET_PRODUCT_BY_NAME, variables: { name: Number(post?.id) } },
        { query: GET_COMMENT_BY_ID, variables: { id: post?.id, comment_for: 'PRODUCT' } },
      ],
    }).then((refetch) => {
      if (refetch.data !== undefined) {
        setPostContent('')
      }
    })
  }

  const sharedProductCommentHandler = () => {
    if (postContent.trim().length === 0) return
    createComment({
      variables: { parent_id: post?.product?.id, comment: postContent, comment_for: 'PRODUCT' },
      refetchQueries: [
        // { query: MY_FEED_QUERY },
        { query: GET_COMMENT_BY_ID, variables: { id: post?.id, comment_for: 'SHAREPOST' } },
      ],
    }).then((refetch) => {
      if (refetch.data !== undefined) {
        setPostContent('')
      }
    })
  }

  const showModal = (e) => {
    e.stopPropagation()
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleComment = () => {
    console.log('Comment:', postContent); // Log the comment
    if (isNormalPost && post?.post_for) {
      normalPostCommentHandler()
    } else if (isStorePost) {
      storePostCommentHandler()
    } else if (isGroupPost) {
      if (!post?.post_for) {
        sharedProductCommentHandler()
      } else {
        groupPostCommentHandler()
      }
    } else if (isProduct) {
      productCommentHandler()
    } else if (isNormalPost && !post?.post_for) {
      sharedProductCommentHandler()
    }
  }

  return (
    <>
      {/* <input type="file" className="w-full h-full" /> */}
      <div
        className="relative flex rounded-2xl w-full ml-4"
        style={{ border: '1px solid #F1F1F5', background: '#FAFAFB', height: isMobileButton ? '45px' : '' }}
      >
       {/* <FileUploadComment multiple updateFilesCb={updateUploadedFiles} /> */}
        <InputEmoji
          // placeholder="Write your comment…"
          value={postContent}
          fontSize={13}
          borderRadius={10}
          style={{ height: '80px' }}
          disable
          onChange={setPostContent}
          onKeyDown={(e: any) => {
            if (e.code === 'Enter') {
              console.log('Comment:', postContent); // Log the comment
              handleComment()
              if (e.code === 'Enter') {
                handleComment()
              }
            }
          }}
        />

        {/* {newUserInfo.profileImages.length > 0 && (
            <div className="image-preview">
            {newUserInfo.profileImages.map((file, index) => (
              <img key={index} src={URL.createObjectURL(file)} alt={`preview-${index}`} className="preview-image" />
            ))}
            </div>
        )} */}

        {/* <button className="mr-6 comment-textbox-alignment relative" onClick={showModal}> */}
        {/* <button className="mr-6 comment-textbox-alignment relative cursor-pointer">
          <svg viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#92929D"
              d="M23.8067 14.852V18.65C23.8067 20.5061 22.168 22 20.1596 22H18.326H18.3217H5.57138C3.56297 22 1.92432 20.5061 1.92432 18.65V5.35C1.92432 3.49395 3.56297 2 5.57138 2H20.1596C22.168 2 23.8067 3.49395 23.8067 5.35V14.8483C23.8067 14.8495 23.8067 14.8508 23.8067 14.852ZM22.7647 15.0708L17.8532 10.775L12.941 15.0284L18.5553 21H20.1596C21.6042 21 22.7647 19.942 22.7647 18.65V15.0708ZM22.7647 13.7178V5.35C22.7647 4.05803 21.6042 3 20.1596 3H5.57138C4.12684 3 2.96633 4.05803 2.96633 5.35V17.445L9.02416 11.646C9.23514 11.444 9.57932 11.4527 9.77894 11.6651L12.243 14.2859L17.5059 9.72878C17.7049 9.55643 18.0077 9.55716 18.2059 9.73045L22.7647 13.7178ZM17.1519 21L9.37223 12.7253L2.97554 18.8487C3.08725 20.0487 4.20163 21 5.57138 21H17.1519ZM8.89591 8.7C7.78633 8.7 6.88631 7.83843 6.88631 6.775C6.88631 5.71157 7.78633 4.85 8.89591 4.85C10.0055 4.85 10.9055 5.71157 10.9055 6.775C10.9055 7.83843 10.0055 8.7 8.89591 8.7ZM8.89591 7.7C9.43059 7.7 9.8635 7.28558 9.8635 6.775C9.8635 6.26442 9.43059 5.85 8.89591 5.85C8.36124 5.85 7.92833 6.26442 7.92833 6.775C7.92833 7.28558 8.36124 7.7 8.89591 7.7Z"
            />
          </svg>
          <input type="file" className="absolute top-0 left-0 opacity-0 w-full h-full" />
        </button> */}
      </div>
      {isButton && (
        <div
          onClick={() => handleComment()}
          className={postContent?.length > 0 ? 'active-button' : 'product-button-section'}
        >
          <button>Send</button>
        </div>
      )}
      {isMobileButton && (
        <div
          className={postContent?.length > 0 ? 'active-button' : 'product-button-section'}
          onClick={() => handleComment()}
        >
          <button>
            <img src={SendIcon.src} alt="SendIcon" />
          </button>
        </div>
      )}
      <Modal
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        width={625}
        className="p-4 bg-blue rounded-xl"
      >
        <div className="bg-white rounded-xl -m-16">
          <div className="modal-header flex justify-between items-center px-8 py-4">
            <p className="text-sm text-black font-medium">Upload Image</p>
            <button onClick={handleCancel}>
              <img src="/assets/img/feed/ic_Close.svg" />
            </button>
          </div>
          <div>
            <form className="p-8">
              <FileUpload multiple updateFilesCb={updateUploadedFiles} />
            </form>
          </div>
          <div className="flex border-t p-8">
            <div className="bg-blue rounded-xl flex items-center px-4 " style={{ height: 30 }}>
              <button
                className="text-12 text-white font-medium w-32"
              // onClick={postHandler}
              >
                Post
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default InputWithEmoti
