import API_LOCAL from './api-local'
import API_PROD from './api-prod'
import API_STAGE from './api-stage'
import API_GATEWAY_STAGE from './api-gateway-stage'
import API_GATEWAY_PROD from './api-gateway-prod'

let hostname

if (typeof window !== 'undefined') {
  hostname = window.location.origin.split('/')[2]
}

// export const API = 
//   hostname === 'localhost:3000'
//     ? API_GATEWAY_STAGE
//     : hostname === 'stage.bluejestic.com'
//     ? API_GATEWAY_STAGE
//     : hostname === 'bluejestic.com'
//     ? API_GATEWAY_PROD
//     : API_GATEWAY_STAGE

export const API = {
  endpoint: process.env.NEXT_PUBLIC_API_GATEWAY_ENDPOINT,
  // endpoint: 'https://caac-2405-201-200d-115e-4547-940d-34df-fc42.ngrok-free.app/graphql',
  // endpoint: 'https://graph.bluejestic.com/graphql',
  // endpoint: 'http://localhost:3301/graphql',
}
