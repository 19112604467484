import { gql } from '@apollo/client'

export default gql`
  mutation addPost(
    $content: String!
    $post_for: String!
    $group_id: Int
    $media_id: [Int]
    $store_id: Int
    $media_type: String
    $visible_for: String
  ) {
    addPost(
      input: {
        content: $content
        post_for: $post_for
        group_id: $group_id
        media_id: $media_id
        store_id: $store_id
        media_type: $media_type
        visible_for: $visible_for
      }
    ) {
      success
      message
    }
  }
`
