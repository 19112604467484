import { useNotifications } from '@novu/notification-center';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spin } from 'antd';
import NotificationsComponents from '../NoticationComponents';

const NotificationsList = ({ notifications, isFetching, hasNextPage, fetchNextPage, isFetchingNextPage, setOpen }) => {
    const { markNotificationAsRead } = useNotifications();

    const groupByDate = (notifications: any) => {
        const grouped = notifications?.reduce((acc, notification) => {
            const date = new Date(notification.createdAt).toDateString();
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(notification);
            return acc;
        }, {});
        return grouped;
    }
    const groupedNotifications = groupByDate(notifications);
    const groupedDates = groupedNotifications ? Object?.keys(groupedNotifications) : [];

    const showDate = (date: any) => {
        const today = new Date().toDateString();
        const yesterday = new Date(Date.now() - 86400000).toDateString();
        return date === today ? 'Today' : date === yesterday ? 'Yesterday' : date;
    }
    return (
        <div className="max-h-[60vh] h-[60vh] overflow-y-auto">
            {isFetching && notifications?.length === 0 ? <div className='w-[500px] h-[400px] flex justify-center items-center'><Spin size="large" tip="loading" /></div> :
                <div className='relative'>
                    {notifications?.length === 0 ? (
                        <div className="p-4 py-8 text-center text-gray-500">
                            No notifications
                        </div>
                    ) : (
                        <div>
                            <InfiniteScroll
                                dataLength={notifications?.length} //This is important field to render the next data
                                next={fetchNextPage}
                                hasMore={hasNextPage}
                                height={'60vh'}
                                loader={<div className='w-[500px] h-[200px] flex justify-center items-center'><Spin size="large" tip="loading" /></div>}

                            >
                                {groupedDates.map((date: any) => (
                                    <div key={date}>
                                        <h2 className="text-gray-500 text-[13px] py-5 font-normal flex m-0 ml-10">{showDate(date)}</h2>
                                        {groupedNotifications[date]?.map((notification: any) => (
                                            <div
                                                key={notification.id}
                                                onClick={() => !notification?.read && markNotificationAsRead(notification.id)}
                                                className={`relative cursor-pointer border-b-[0.5px] border-gray-200 bg-white hover:!bg-[#F8F8F8] ${!notification?.read
                                                    ? ' bg-[#F8F8F8]'
                                                    : ''
                                                    }`}
                                            >  {!notification?.read && <span className="absolute top-5 left-5 w-3 h-3 bg-red-700 rounded-full"></span>}
                                                <NotificationsComponents notification={notification} setOpen={setOpen} />
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </InfiniteScroll>
                        </div>
                    )}
                </div>}
        </div>


    );
};

export default NotificationsList;