// apolloClients.js

import nookies from 'nookies'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
import { API } from 'src/config/API/api.config'

export function createApolloClient(tokenName) {
  const httpLink = createUploadLink({
    uri: API.endpoint,
  })

  const authLink = setContext((_, { headers }) => {
    const token = nookies.get()[tokenName]

    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })
}
