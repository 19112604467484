import React from 'react'
import styles from './MobileViewAddVariantModal.module.scss'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
export default function MobileViewAddVariantModal() {
  const dispatch = useAppDispatch()
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)

  const handleDelete = (index) => {
    // setAllVariant(addProductDetailSlice?.id?.variant?.allVariant.filter((data, ind) => ind !== index))
    // setAllFinalVariant(addProductDetailSlice?.id?.variant?.allVariant.filter((data, ind) => ind !== index))
    dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          selectedColorArray: [],
          selectedColor: '',
          selectedText: '',
          variant: {
            ...addProductDetailSlice?.id?.variant,
            allVariant: addProductDetailSlice?.id?.variant?.allVariant.filter((data, ind) => ind !== index),
            allFinalVariant: addProductDetailSlice?.id?.variant?.allVariant.filter((data, ind) => ind !== index),
            colorCodeArray: addProductDetailSlice?.id?.variant?.allVariant.filter((data, ind) => ind !== index),
            dataEdit: {},
          },
        },
      }),
    )
  }

  const editSizevariant = (variant, index) => {
    if (variant?.name === 'SIZE') {
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            variant: {
              ...addProductDetailSlice?.id?.variant,
              dataEdit: { size: { ...variant, data: [...variant.data] }, index: index },
            },
          },
        }),
      )

      dispatch(
        fetchMobileModalData({
          allModalData: [
            ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
            {
              modal: 'addSizeVariant',
              isOpen: true,
              id: 0,
              transform: 20,
            },
          ],
          lastModalData: fetchMobileModal?.lastModalData,
        }),
      )
    } else if (variant?.name === 'Color') {
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            selectedColorArray: [],
            selectedColor: '',
            selectedText: '',
            variant: {
              ...addProductDetailSlice?.id?.variant,
              dataEdit: { color: { ...variant, data: [...variant.data] }, index: index },
              colorStates: variant?.data?.map((color) => {
                return { name: color?.name, color: color?.color }
              }),
            },
          },
        }),
      )
      dispatch(
        fetchMobileModalData({
          allModalData: [
            ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
            {
              modal: 'addAddColorVariantMobile',
              isOpen: true,
              id: 0,
              transform: 20,
            },
          ],
          lastModalData: fetchMobileModal?.lastModalData,
        }),
      )
    } else {
      // setDataEdit({ other: { ...variant, data: [...variant.data, ''] }, index: index })
      // setAddOtherVariantModalOpen(true)
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            variant: {
              ...addProductDetailSlice?.id?.variant,
              dataEdit: { other: { ...variant, data: [...variant.data, ''] }, index: index },
              isAddOtherVariantModalOpen: true,
            },
          },
        }),
      )
    }
  }

  const outputArray = []

  function generateVariants(currentVariant, currentIndex, finalData, outputArray) {
    if (currentIndex === finalData?.length) {
      outputArray.push({
        total_variant: currentVariant,
        // weightValue: '200',
        // weightUnit: 'pound',
        // length: 2,
        // width: 2,
        // height: 2,
        // unit: 'inch',
        isTaxable: false,
        isChecked: false,
        weightUnit: 'pound',
        unit: 'inch',
        old_inventory_quantity: 0,
        inventory_quantity: 0,
        price: '0',
        sku: '0',
        // grams: 530,
        // barcode: '5151511565656',
      })
      return
    }

    const currentVariants = finalData[currentIndex]?.data
    if (currentVariants && Symbol.iterator in Object(currentVariants)) {
      for (const variant of currentVariants) {
        const newVariant = [...currentVariant, variant]
        generateVariants(newVariant, currentIndex + 1, finalData, outputArray)
      }
    } else {
      // If data is not defined, proceed to the next level without adding any variants
      generateVariants(currentVariant, currentIndex + 1, finalData, outputArray)
    }
  }

  const submitFinalVariant = async () => {
    let sizeVariant = addProductDetailSlice?.id?.variant?.allVariant?.filter((size) => size?.name === 'SIZE')
    let colorVariant = addProductDetailSlice?.id?.variant?.allVariant?.filter((size) => size?.name === 'Color')
    let otherVariant = addProductDetailSlice?.id?.variant?.allVariant?.filter(
      (size) => size?.name !== 'SIZE' && size?.name !== 'Color',
    )

    const updatedVariant = {
      other: [
        { name: 'size', data: sizeVariant[0]?.data },
        // { name: 'color', data: colorVariant[0]?.data.map((colorObj) => colorObj.name) },
        { name: 'color', data: colorVariant[0]?.data },
      ],
    }

    const sizeAndColorArray = [
      { name: 'size', data: updatedVariant.other[0].data },
      { name: 'color', data: updatedVariant.other[1].data },
      ...otherVariant,
    ]

    // Create the new "variant" object with the "size" and "color" as separate objects
    const finalVariant = sizeAndColorArray.concat(updatedVariant.other.slice(2))
    const noColorCode = {
      other: [
        { name: 'size', data: sizeVariant[0]?.data },
        { name: 'color', data: colorVariant[0]?.data.map((colorObj) => colorObj.name) },
        // { name: 'color', data: colorVariant[0]?.data },
      ],
    }

    const sizeAndNoCode = [
      { name: 'size', data: noColorCode.other[0].data },
      { name: 'color', data: noColorCode.other[1].data },
      ...otherVariant,
    ]

    const finalVariantNoCode = sizeAndNoCode.concat(noColorCode.other.slice(2))
    await generateVariants([], 0, finalVariantNoCode, outputArray)

    dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          variant: {
            ...addProductDetailSlice?.id?.variant,
            allFinalVariant: addProductDetailSlice?.id?.variant?.allVariant,
            allOptions: outputArray,
            isAddVariantModal: false,
          },
        },
      }),
    )
    dispatch(
      fetchMobileModalData({
        allModalData: [
          ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
          {
            modal: 'addVariantMobile',
            isOpen: false,
            id: 0,
            transform: 7,
          },
        ],
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
    // }
  }

  return (
    <div className={styles.mobileViewAddVariantModalSection}>
      <div className={styles.mobileViewAddVariantModalHeader}>
        <h6>Add Variant</h6>
        <a>Close</a>
      </div>
      <div className={styles.mobileViewAddVariantModalBodyDetails}>
        <div className={styles.mobileViewAddVariantDetailsBox}>
          {/* <div className={styles.mobileViewAddVariantModalNoVariantSection}>
            <p>No variant added.</p>
          </div> */}
          <div className={styles.mobileViewAddVariantListAlignment}>
            {addProductDetailSlice?.id?.variant?.allVariant?.map((variant, index) => {
              let joinData =
                variant.name === 'Color'
                  ? variant?.data?.map((data) => data?.name).join(', ')
                  : variant?.data?.map((data) => data).join(', ')
              return (
                <div className={styles.mobileViewAddVariantListName}>
                  <div className={styles.mobileViewVariant}>
                    <div className={styles.mobileViewNameSize}>
                      <h6>{variant.name}</h6>
                      <p>{joinData?.length > 43 ? joinData.substring(0, 43) + '...' : joinData}</p>
                    </div>
                  </div>
                  <div className={styles.mobileViewEditRemoveAlignment}>
                    <a onClick={() => handleDelete(index)}>Remove</a>
                    <a onClick={() => editSizevariant(variant, index)}>Edit</a>
                  </div>
                </div>
              )
            })}
          </div>
          <div className={styles.mobileViewAddVariantOptionAlignment}>
            <div className={styles.mobileViewAddVariantOptionGrid}>
              <div className={styles.mobileViewAddVariantOptionGridItem}>
                <div
                  className={styles.mobileViewAddVariantOptionBox}
                  onClick={() => {
                    let sizeFilter = addProductDetailSlice?.id?.variant?.allVariant?.filter(
                      (size) => size.name === 'SIZE',
                    )
                    if (sizeFilter?.length === 0) {
                      dispatch(
                        fetchMobileModalData({
                          allModalData: [
                            ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                            {
                              modal: 'addSizeVariant',
                              isOpen: true,
                              id: 0,
                              transform: 20,
                            },
                          ],
                          lastModalData: fetchMobileModal?.lastModalData,
                        }),
                      )
                    }
                  }}
                >
                  <div className={styles.mobileViewIconsAlignment}>
                    <img src="/assets/seller/icons/SizeIcon.svg" alt="SizeIcon" />
                  </div>
                  <p>Size </p>
                </div>
              </div>

              <div className={styles.mobileViewAddVariantOptionGridItem}>
                <div
                  className={styles.mobileViewAddVariantOptionBox}
                  onClick={() => {
                    let sizeFilter = addProductDetailSlice?.id?.variant?.allVariant?.filter(
                      (size) => size.name === 'Color',
                    )
                    if (sizeFilter?.length === 0) {
                      dispatch(
                        fetchMobileModalData({
                          allModalData: [
                            ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                            {
                              modal: 'addAddColorVariantMobile',
                              isOpen: true,
                              id: 0,
                              transform: 20,
                            },
                          ],
                          lastModalData: fetchMobileModal?.lastModalData,
                        }),
                      )
                      dispatch(
                        manageAddProductDetail({
                          modal: '',
                          isOpen: false,
                          id: {
                            ...addProductDetailSlice?.id,
                            selectedText: '',
                            selectedColorArray: [],
                            selectedColor: '',
                          },
                        }),
                      )
                    }
                  }}
                >
                  <div className={styles.mobileViewIconsAlignment}>
                    <img src="/assets/seller/img/round-pic.png" alt="ColorIcon" />
                  </div>
                  <p>Color</p>
                </div>
              </div>
              <div className={styles.mobileViewAddVariantOptionGridItem}>
                <div className={styles.mobileViewAddVariantOptionBox}>
                  <p>Other</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.recentlyCreateAlignment}>
            <h6>Recently Created</h6>

            <div className={styles.recentlyCreateResizeBox}>
              <div className={styles.createdNameAlignment}>
                <h6>Size </h6>
                <p>XS, S, M, L, XL</p>
              </div>
              <div className={styles.addMoreButtonAlignment}>
                <a>Add</a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.saveChangesButtonAlignment} onClick={() => submitFinalVariant()}>
          <button>Save Change</button>
        </div>
      </div>
    </div>
  )
}
