import React, { useEffect, useState } from 'react'
import PeopleFollowers from '../PeopleFollowers'
import { useMutation, useQuery } from '@apollo/client'
import { useAuth } from 'src/utils/auth'
import SEND_FRIEND_REQUEST from 'src/graphql/mutations/sendFriendRequest'
import DECLINE_FRIEND_REQUEST from 'src/graphql/mutations/declineFriendRequest'
import GET_USER_FOLLOWERS from 'src/graphql/queries/sellerQueries/getUserFollowers'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAppDispatch } from 'src/store/store'
import { fetchAllPeople } from 'src/store/slices/fetchAllPeopleSlice'
import { useSelector } from 'react-redux'
import { SEND_NEW_FOLLOWER } from 'src/graphql/mutations/novu'

export default function FollowersSection({ index, search, setSearch }) {
  const { user } = useAuth()
  const dispatch = useAppDispatch()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const peopleData = useSelector((state: any) => state?.peopleData)

  useEffect(() => {
    setPage(1)
    dispatch(
      fetchAllPeople({
        limit: limit,
        page: 1,
        type: 'FOLLOWERS',
        prevPost: [],
      }),
    )
  }, [])

  const [sendFriendRequest] = useMutation(SEND_FRIEND_REQUEST, {
    onCompleted: () => {
      dispatch(fetchAllPeople({ limit: limit, page: page, type: 'FOLLOWERS', prevPost: [] }))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const [declineFriendRequest] = useMutation(DECLINE_FRIEND_REQUEST, {
    onCompleted: () => {
      dispatch(fetchAllPeople({ limit: limit, page: page, type: 'FOLLOWERS', prevPost: [] }))
    },
    onError: (error) => console.log(error),
  })

  const declineFriendRequestCall = (people) => {
    if (user) {
      declineFriendRequest({
        variables: { user_id: user?.id, friend_id: people?.id },
      })
    } else {
      handleRedirect()
    }
  }

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const [sendNewFollower] = useMutation(SEND_NEW_FOLLOWER);
  const sendFriendRequestCall = (people) => {
    sendFriendRequest({
      variables: {
        friend_id: people?.id,
        user_id: user?.id,
        isActive: true,
        isFriend: true,
      },
    })
    sendNewFollower({
      variables: {
        input: {
          to: people?.id,
          userName: user?.userName,
          userImg: user?.logo_image,
          userSlug: user?.userName,
        }
      }
    },)
  }

  const fetchMorePeople = (page, limit) => {
    dispatch(
      fetchAllPeople({
        limit: limit,
        page: page,
        type: 'FOLLOWERS',
        prevPost: peopleData?.followers,
      }),
    )
  }

  const handleSeeMore = () => {
    setPage((prevState) => prevState + 1)
    fetchMorePeople(page + 1, limit)
  }

  useEffect(() => {
    if (index === '1') {
      setPage(1)
      dispatch(
        fetchAllPeople({
          limit: limit,
          page: 1,
          type: 'FOLLOWERS',
          prevPost: [],
        }),
      )
    }
  }, [index])

  return (
    <PeopleFollowers
      handleSeeMore={handleSeeMore}
      data={peopleData?.followers}
      title={'Followers'}
      declineFriendRequestCall={declineFriendRequestCall}
      sendFriendRequestCall={sendFriendRequestCall}
      setSearch={setSearch}
    />
  )
}
