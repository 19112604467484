import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { Slices } from './slices'

const store = configureStore({
  reducer: {
    manageModalData: Slices.manageModalSlice,
    editPostData: Slices.manageEditPostSlice,
    manageApplianceCategoryModal: Slices.manageApplianceCategorySlice,
    manageCategoryDetail: Slices.manageCategoryDetail,
    postAddEditModal: Slices.postAddEditModal,
    userProfileModalScroll: Slices.userProfileModalScroll,
    manageMobileModal: Slices.mobileModalSlice,
    productResizeData: Slices.productResizeSlice,
    pageScrollEventSlice: Slices.pageScrollEventSlice,
    productErrorHandlerSlice: Slices.productErrorHandlerSlice,
    searchModalSlice: Slices.searchModalSlice,
    trafficEngagementFilterSlice: Slices.trafficEngagementFilterSlice,
    productShareOptions: Slices.productShareOptionsSlice,
    manageAddProductDetailSlice: Slices.manageAddProductDetail,
    manageBookmarkTabsSlice: Slices.manageBookmarTabs,
    manageBookmarRenameSlice: Slices.manageBookmarRename,
    manageInviteClubMemberSlice: Slices.inviteClubMembers,
    guestAccountInfo: Slices.guestAccountDetailSlice,
    sellerWithdrawData: Slices.sellerWithdrawModalData,
    postCountData: Slices.postCountData,
    createPostProduct: Slices.postProductCountData,
    createMeetingSlice: Slices.createMeetingSlice,
    storeDetailData: Slices.sellerWithdrawModalData,
    storeDetail: Slices.fetchStoreData,
    feedData: Slices.fetchAllFeed,
    clubData: Slices.fetchAllClub,
    storesData: Slices.fetchAllStore,
    peopleData: Slices.fetchAllPeopleSlice,
    fetchCategory: Slices.fetchCategory,
    productData: Slices.fetchProductSlice,
    getCartCount: Slices.getAllCartCountSliceData,
    userMobileModal: Slices.fetchUserMobileModalData,
    clubMobileModal: Slices.fetchClubMobileModalData,
    globalSearchData: Slices.globalSearchSlice,
    accountSummary: Slices.accountSummarySlice,
    fetchOrderDetail: Slices.fetchOrderDetail,
    fetchJewelryData: Slices.fetchJewelrySlice,
    productByCategory: Slices.fetchAllProduct
  },
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch

export default store
