import React, { useEffect, useState } from 'react'
import { Popover, notification } from 'antd'
import getImageUrl from 'src/utils/getImageUrl'
import { DEFAULT_USER_AVATAR } from 'src/config/constant'
import { useMutation } from '@apollo/client'
import FOLLOW_STORE from 'src/graphql/mutations/followStore'
import UNFOLLOW_STORE from 'src/graphql/mutations/unFollowStore'
import moment from 'moment'
import DECLINE_FRIEND_REQUEST from 'src/graphql/mutations/declineFriendRequest'
import SEND_FRIEND_REQUEST from 'src/graphql/mutations/sendFriendRequest'
import JOIN_GROUP from 'src/graphql/mutations/joinGroup'
import LEAVE_GROUP from 'src/graphql/mutations/leaveGroup'
import { useAuth } from 'src/utils/auth'
import PopOverSection from '../../PopOver/PopOverSection'
import StorePopOver from '../../PopOver/StorePopOver'
import GroupPopOver from '../../PopOver/GroupPopOver'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import Link from 'next/link'
import GET_USER from 'src/graphql/queries/getUserByName'
import GET_STORE from 'src/graphql/queries/getStoreByName'
import GET_GROUP from 'src/graphql/queries/getGroup'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'

export default function PostHeaderPopOver(props) {
  const { user } = useAuth()
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const { isNormalPost, isGroupPost, isStorePost, post, store } = props
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)

  let width
  if (typeof window !== 'undefined') {
    width = window.innerWidth
  }
  const [isDesktop, setIsDesktop] = useState(width >= 768)

  const [declineFriendRequest] = useMutation(DECLINE_FRIEND_REQUEST, {
    onCompleted: () => {},
    onError: (error) => {},
  })

  const [sendFriendRequest] = useMutation(SEND_FRIEND_REQUEST, {
    onCompleted: ({ sendFriendRequest }) => {},
    onError: (error) => {
      console.log(error)
    },
  })

  const [joingroup] = useMutation(JOIN_GROUP, {
    onCompleted: (res) => {},
    onError: (err) => {
      console.log('err', err)
      notification['error']({
        message: 'Error',
        description: 'Something went wrong',
      })
    },
  })

  const [leaveGroup] = useMutation(LEAVE_GROUP, {
    onCompleted: (res) => {},
    onError: (err) => {
      console.log('err', err)
    },
  })

  const [followStore] = useMutation(FOLLOW_STORE, {
    onCompleted: (res) => {
      notification['success']({
        message: ' Followed successfully',
        description: 'You have been followed successfully',
      })
    },
    onError: (err) => {
      notification['error']({
        message: 'Error',
        description: 'Something went wrong',
      })
    },
  })

  const [unfollowStore] = useMutation(UNFOLLOW_STORE, {
    onCompleted: (res) => {
      notification['success']({
        message: ' Unfollowed successfully',
        description: 'You have been unfollowed successfully',
      })
    },
    onError: (err) => {
      notification['error']({
        message: 'Error',
        description: 'Something went wrong',
      })
    },
  })

  const userPopOver = (
    <PopOverSection
      profile={post?.user?.profileImage?.url ? getImageUrl(post?.user?.profileImage?.url) : DEFAULT_USER_AVATAR}
      profile_back={post?.user?.coverImage?.url ? getImageUrl(post?.user?.coverImage?.url) : DEFAULT_USER_AVATAR}
      name={post?.user?.firstName + ' ' + post?.user?.lastName}
      id={post?.user?.userName}
      showDetailName1={'Followings'}
      showDetailName2={'Followers'}
      showDetail1={post?.user?.followings?.length > 0 ? post?.user?.followings : []}
      showDetail2={post?.user?.followers?.length > 0 ? post?.user?.followers : []}
      isFollow={post?.user?.isActiveForFriendStatus && post?.user?.isFriendForFriendStatus ? true : false}
      buttonHandler1={() =>
        sendFriendRequest({
          variables: {
            friend_id: post?.user?.id,
            user_id: user?.id,
            isActive: true,
            isFriend: true,
          },
          refetchQueries: [
            {
              query: GET_USER,
              variables: { slug: post?.user?.userName },
            },
          ],
        })
      }
      buttonHandler2={() =>
        declineFriendRequest({
          variables: { user_id: user.id, friend_id: post?.user?.id },
          refetchQueries: [
            {
              query: GET_USER,
              variables: { slug: post?.user?.userName },
            },
          ],
        })
      }
      isGroup={false}
    />
  )

  const storePopOver = (post) => (
    <StorePopOver
      name={post?.store?.name}
      slug={post?.store?.slug}
      id={post?.store?.id}
      buttonHandler1={() =>
        followStore({
          variables: { store_id: post?.product?.store?.id },
          refetchQueries: [
            {
              query: GET_STORE,
              variables: { slug: post?.store?.slug },
            },
          ],
        })
      }
      buttonHandler2={() =>
        unfollowStore({
          variables: { storeId: post?.product?.store?.id },
          refetchQueries: [
            {
              query: GET_STORE,
              variables: { slug: post?.store?.slug },
            },
          ],
        })
      }
    />
  )

  const storePopOverForStore = (
    <StorePopOver
      name={post?.store?.name}
      slug={post?.store?.slug}
      id={post?.store?.id}
      buttonHandler1={() =>
        followStore({
          variables: { store_id: post?.product?.store?.id },
          refetchQueries: [
            {
              query: GET_STORE,
              variables: { slug: store?.slug },
            },
          ],
        })
      }
      buttonHandler2={() =>
        unfollowStore({
          variables: { storeId: post?.product?.store?.id },
          refetchQueries: [
            {
              query: GET_STORE,
              variables: { slug: store?.slug },
            },
          ],
        })
      }
      isGroup={false}
    />
  )

  const shareProductPopOver = (
    <StorePopOver
      name={post?.product?.store?.name}
      slug={post?.product?.store?.slug}
      id={post?.store?.id}
      buttonHandler1={() =>
        followStore({
          variables: { store_id: post?.product?.store?.id },
          refetchQueries: [
            {
              query: GET_STORE,
              variables: { slug: post?.product?.store?.slug },
            },
          ],
        })
      }
      buttonHandler2={() =>
        unfollowStore({
          variables: { storeId: post?.product?.store?.id },
          refetchQueries: [
            {
              query: GET_STORE,
              variables: { slug: post?.product?.store?.slug },
            },
          ],
        })
      }
    />
  )

  const groupPopOver = (
    <GroupPopOver
      name={post?.group?.name}
      slug={post?.group?.slug}
      id={post?.group?.id}
      buttonHandler1={() =>
        joingroup({
          variables: { group_id: post?.group?.id, user_id: user.id, isAdmin: false },
          refetchQueries: [
            {
              query: GET_GROUP,
              variables: { id: parseInt(post?.group?.slug) },
            },
          ],
        })
      }
      buttonHandler2={() =>
        leaveGroup({
          variables: {
            userId: user.id,
            groupId: post?.group?.id,
          },
          refetchQueries: [
            {
              query: GET_GROUP,
              variables: { id: parseInt(post?.group?.slug) },
            },
          ],
        })
      }
    />
  )

  useEffect(() => {
    // if (typeof window !== 'undefined') {
    const handleResize = () => {
      setIsDesktop(width >= 768)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
    // Code that references window
    // }
  }, [])

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const handelOnclick = (item) => {
    if (user) {
      if (isDesktop) {
        dispatch(
          fetchModalData({
            allModalData: [
              ...getAllState?.allModalData,
              {
                modal: 'collectionByProduct',
                isOpen: true,
                id: { width: 70, post: item },
                item: item?.collection?.id,
                userName: item?.collection?.user?.userName,
                modalFor: 'bigModal',
              },
            ],
            lastModalData: getAllState?.lastModalData,
          }),
        )
        // if (item?.collection?.isPrivate) {
        //   router.push(`/bookmark-collection/${item?.collection?.slug}`)
        // } else {
        //   router.push(`/shared-collection/${item?.collection?.slug}`)
        // }
      } else {
        dispatch(
          fetchMobileModalData({
            allModalData: [
              ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
              {
                modal: 'mobileViewCollectionDetail',
                isOpen: true,
                id: item?.id,
                transform: 112,
              },
            ],
            lastModalData: fetchMobileModal?.lastModalData ? fetchMobileModal?.lastModalData : [],
          }),
        )
      }
    } else {
      handleRedirect()
    }
  }

  return (
    <div>
      <div className="d-flex font-medium gap-1 text-black text-sm align-items-center flex-wrap">
        {/* FOR_NORMAL_POST==================================================================================== */}
        {isNormalPost && post?.post_for === 'USER' && (
          <Link href={`/user/${post?.user?.userName}`} passHref>
            <div className="profile-username-align">
              <Popover content={userPopOver} placement="bottomLeft">
                <span className="hover:underline cursor-pointer post-owner-font">
                  {post.user?.firstName + ' ' + post.user?.lastName}
                </span>
              </Popover>
            </div>
          </Link>
        )}
        {isNormalPost && post?.post_for === 'SELLER' && (
          <div className="profile-username-align">
            <Link href={`/store/${post?.store?.slug}`}>
              <Popover content={storePopOver(post)} placement="bottomLeft">
                <span
                  className="hover:underline cursor-pointer post-owner-font"
                  // onClick={() => {
                  //   dispatch(
                  //     fetchModalData({
                  //       allModalData: [
                  //         ...getAllState?.allModalData,
                  //         {
                  //           modal: 'store',
                  //           isOpen: true,
                  //           id: { store: { name: post?.store_name, id: post?.store_id } },
                  //           modalFor: 'bigModal',
                  //           startDate: moment().toISOString(),
                  //         },
                  //       ],
                  //       lastModalData: getAllState?.lastModalData,
                  //     }),
                  //   )
                  // }}
                >
                  {post?.store?.name}
                </span>
              </Popover>
            </Link>
          </div>
        )}
        {isNormalPost && !post?.post_for && post?.product && (

          <>
            <div className="profile-username-align">
              <Link href={`/user/${post?.user?.userName}`}>
                <Popover content={userPopOver} placement="bottomLeft">
                  <span
                    className="hover:underline cursor-pointer post-owner-font"
                    // onClick={() => {
                    //   dispatch(
                    //     fetchModalData({
                    //       allModalData: [
                    //         ...getAllState?.allModalData,
                    //         { modal: 'userDetail', isOpen: true, id: post?.user?.id, modalFor: 'bigModal' },
                    //       ],
                    //       lastModalData: getAllState?.lastModalData,
                    //     }),
                    //   )
                    // }}
                  >
                    {post.user?.firstName + ' ' + post.user?.lastName + ' '}
                  </span>
                </Popover>
              </Link>
              {post.product !== null && !post?.group && (
                <>
                  shared Product from
                  <Link href={`/store/${post?.product?.store?.slug}`}>
                    <Popover content={shareProductPopOver} placement="bottomLeft">
                      <span
                        className="product-share-name"
                        // onClick={() => {
                        //   dispatch(
                        //     fetchModalData({
                        //       allModalData: [
                        //         ...getAllState?.allModalData,
                        //         {
                        //           modal: 'store',
                        //           isOpen: true,
                        //           id: {
                        //             store: {
                        //               name: post?.product?.store?.name,
                        //               id: post?.product?.store?.id,
                        //             },
                        //           },
                        //           modalFor: 'bigModal',
                        //           startDate: moment().toISOString(),
                        //         },
                        //       ],
                        //       lastModalData: getAllState?.lastModalData,
                        //     }),
                        //   )
                        // }}
                      >
                        {' '}
                        {post?.product?.store?.name}
                      </span>
                    </Popover>
                  </Link>
                  <span>{'  '}catalog</span>
                </>
              )}
              {post.product !== null && post?.group && (
                <>
                  shared Product from
                  <Link href={`/store/${post?.product?.store?.slug}`}>
                    <Popover content={shareProductPopOver} placement="bottomLeft">
                      <span className="product-share-name"> {post?.product?.store?.name}</span>
                    </Popover>
                  </Link>
                  <span>
                    {' '}
                    on club
                    <Link href={`/club/${post?.group?.slug}`}>
                      <Popover content={groupPopOver} placement="bottomLeft">
                        <span className="product-share-name"> {post?.group?.name}</span>
                      </Popover>
                    </Link>
                  </span>
                </>
              )}
            </div>
          </>
        )}
        {isNormalPost && !post?.post_for && post?.collection && (

          <>
            <div className="profile-username-align">
              <Link href={`/user/${post?.user?.userName}`}>
                <Popover content={userPopOver} placement="bottomLeft">
                  <span className="hover:underline cursor-pointer post-owner-font">
                    {post.user?.firstName + ' ' + post.user?.lastName + ' '}
                  </span>
                </Popover>
              </Link>
              {post.collection !== null && !post?.group && (
                <>
                  <span className="second-details">shared his bookmark collection</span>
                  {/* <Popover content={shareProductPopOver} placement="bottomLeft"> */}
                  <span
                    className="product-share-name"
                    onClick={() => handelOnclick(post)}
                  >
                    {' '}
                    {post.collection?.name}
                  </span>
                  {/* </Popover> */}
                  {/* <span>{'  '}catalog</span> */}
                </>
              )}
            </div>
          </>
        )}
        {isNormalPost && post?.post_for === 'GROUP' && (
          <>
            <div className="profile-username-align">
              <Link href={`/user/${post?.user?.userName}`}>
                <Popover content={userPopOver} placement="bottomLeft">
                  <span
                    className="hover:underline cursor-pointer post-owner-font"
                    // onClick={() => {
                    //   dispatch(
                    //     fetchModalData({
                    //       allModalData: [
                    //         ...getAllState?.allModalData,
                    //         { modal: 'userDetail', isOpen: true, id: post?.user?.id, modalFor: 'bigModal' },
                    //       ],
                    //       lastModalData: getAllState?.lastModalData,
                    //     }),
                    //   )
                    // }}
                  >
                    {post.user?.firstName + ' ' + post.user?.lastName + ' '}
                  </span>
                </Popover>
              </Link>
              <span className=" second-details">
                {post.group !== null && !post?.product && post?.post_for === 'GROUP' && (
                  <>
                    Shared a post to club
                    <Link href={`/club/${post?.group?.slug}`}>
                      <Popover content={groupPopOver} placement="bottomLeft">
                        <span
                          className="product-share-name"
                          // onClick={() => {
                          //   dispatch(
                          //     fetchModalData({
                          //       allModalData: [
                          //         ...getAllState?.allModalData,
                          //         { modal: 'group', isOpen: true, id: post?.group?.id, modalFor: 'bigModal' },
                          //       ],
                          //       lastModalData: getAllState?.lastModalData,
                          //     }),
                          //   )
                          // }}
                        >
                          {' '}
                          {post?.group?.name}{' '}
                        </span>
                      </Popover>
                    </Link>
                  </>
                )}
              </span>
            </div>
          </>
        )}
        {/* FOR_STORE_POST==================================================================================== */}
        {isStorePost && (

          <div className="profile-username-align">
            <Popover content={storePopOverForStore} placement="bottomLeft">
              <span className="hover:underline cursor-pointer post-owner-font">{post?.store?.name}</span>{' '}
            </Popover>
          </div>
        )}
        {/* FOR_GROUP_POST==================================================================================== */}
        {isGroupPost && (

          <div className="profile-username-align">
            <Link href={`/user/${post?.user?.userName}`}>
              <Popover content={userPopOver} placement="bottomRight">
                <span
                  className="hover:underline cursor-pointer post-owner-font"
                  // onClick={() => {
                  //   dispatch(
                  //     fetchModalData({
                  //       allModalData: [
                  //         ...getAllState?.allModalData,
                  //         { modal: 'userDetail', isOpen: true, id: post?.user?.id, modalFor: 'bigModal' },
                  //       ],
                  //       lastModalData: getAllState?.lastModalData,
                  //     }),
                  //   )
                  // }}
                >
                  {post?.user?.firstName + ' ' + post?.user?.lastName + ' '}
                </span>{' '}
              </Popover>
            </Link>
          </div>
        )}
      </div>
      <div className="post-time-date-main">
        <span>{post?.createdAt ? moment(post?.createdAt).format('MMM DD [at] hh:mm A') : ''}</span>
        {/* <div className="dott-icon-align"></div>
        <div className="d-flex items-center _hover-reached viewer-count-size">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M15.4698 7.83C14.8817 6.30882 13.8608 4.99331 12.5332 4.04604C11.2056 3.09878 9.62953 2.56129 7.99979 2.5C6.37005 2.56129 4.79398 3.09878 3.46639 4.04604C2.1388 4.99331 1.11787 6.30882 0.529787 7.83C0.490071 7.93985 0.490071 8.06015 0.529787 8.17C1.11787 9.69118 2.1388 11.0067 3.46639 11.954C4.79398 12.9012 6.37005 13.4387 7.99979 13.5C9.62953 13.4387 11.2056 12.9012 12.5332 11.954C13.8608 11.0067 14.8817 9.69118 15.4698 8.17C15.5095 8.06015 15.5095 7.93985 15.4698 7.83ZM7.99979 12.5C5.34979 12.5 2.54979 10.535 1.53479 8C2.54979 5.465 5.34979 3.5 7.99979 3.5C10.6498 3.5 13.4498 5.465 14.4648 8C13.4498 10.535 10.6498 12.5 7.99979 12.5Z"
              fill="#808191"
            />
            <path
              d="M8 5C7.40666 5 6.82664 5.17595 6.33329 5.50559C5.83994 5.83524 5.45543 6.30377 5.22836 6.85195C5.0013 7.40013 4.94189 8.00333 5.05765 8.58527C5.1734 9.16721 5.45912 9.70176 5.87868 10.1213C6.29824 10.5409 6.83279 10.8266 7.41473 10.9424C7.99667 11.0581 8.59987 10.9987 9.14805 10.7716C9.69623 10.5446 10.1648 10.1601 10.4944 9.66671C10.8241 9.17336 11 8.59334 11 8C11 7.20435 10.6839 6.44129 10.1213 5.87868C9.55871 5.31607 8.79565 5 8 5ZM8 10C7.60444 10 7.21776 9.8827 6.88886 9.66294C6.55996 9.44318 6.30362 9.13082 6.15224 8.76537C6.00087 8.39991 5.96126 7.99778 6.03843 7.60982C6.1156 7.22186 6.30608 6.86549 6.58579 6.58579C6.86549 6.30608 7.22186 6.1156 7.60982 6.03843C7.99778 5.96126 8.39992 6.00087 8.76537 6.15224C9.13082 6.30362 9.44318 6.55996 9.66294 6.88886C9.8827 7.21776 10 7.60444 10 8C10 8.53043 9.78929 9.03914 9.41421 9.41421C9.03914 9.78929 8.53043 10 8 10Z"
              fill="#808191"
            />
          </svg>
          <p className="not-italic text-base pl-1 " onClick={handleRedirect}>
            420
          </p>
        </div> */}
        <></>
      </div>
    </div>
  )
}
