import { gql } from '@apollo/client'
import { ASTValidationContext } from 'graphql/validation/ValidationContext'

export const CREATE_PRODUCT_MUTATION = gql`
  mutation CreateProduct(
  $input: ProductInput
  ) {
    createProduct(
      input: $input
    ) {
      success
      message
    }
  }
`
