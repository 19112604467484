import React from 'react'
import styles from './SellerPaymentMethod.module.scss'
export default function SellerPaymentMethod() {
  return (
    <div className={styles.sellerPaymentMethodSection}>
      <div className={styles.sellerPaymentMethodBox}>
        <div className={styles.sellerPaymentMethodHeading}>
          <h5>Payment Method</h5>
        </div>

        <div className={styles.sellerPaymentMethodDetails}>
          <div className={styles.sellerPaymentMethodCardBox}>
            <img src="/assets/seller/img/paymentCardBg.png" alt="payment card" />

            <div className={styles.sellerPaymentCardDetailsAlignment}>
              <div className={styles.sellerPaymentLogoAlignment}>
                <img src="/assets/seller/img/payoneer-logo.png" alt="payoneer logo" />
              </div>

              <div className={styles.sellerWifeTrasferAlignment}>
                <p>Wire Transfer</p>
              </div>

              <div className={styles.sellerCardPersonDetailsAlignment}>
                <h6>Andy Sebastian</h6>
                <p>loremipsum@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
