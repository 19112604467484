import { gql } from '@apollo/client'

const query = gql`
  mutation UpdateCollection($slug: String, $name: String) {
    updateCollection(slug: $slug, name: $name) {
      message
      success
      slug
    }
  }
`
export default query
