import React, { useEffect, useState } from 'react'
import styles from './AddFilterDropdownBoxDetails.module.scss'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
export default function AddFilterDropdownBoxDetails({
  selectedCheckboxes,
  setSelectedCheckboxes,
  filteredData,
  setFilteredData,
  handleResetData,
  newVariantData,
  setNewVariantData,
  newFilterdDropdown,
}) {
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const dispatch = useAppDispatch()

  const transformedArray =
    getAllState?.allModalData[0]?.isEdit === true
      ? addProductDetailSlice?.id?.variant?.colorCodeArray?.map((item) => {
          if (item.name === 'Color' || item.name === 'color') {
            const colorData = item?.data?.map((data) => {
              return { name: data?.name, colorCode: data?.colorCode }
            })
            return { name: item?.name, data: colorData }
          } else if (item.name !== 'Color' && item.name !== 'SIZE') {
            return { name: item?.name, data: item?.data }
          } else {
            return item
          }
          // if (item.name === 'Color') {
          //   const colorData = item?.data?.map((colorItem) => colorItem?.name)
          //   return { name: item?.name, data: colorData }
          // } else {
          //   return item
          // }
        })
      : addProductDetailSlice?.id?.variant?.allVariant?.map((item) => {
          if (item.name === 'Color') {
            const colorData = item?.data?.map((data) => {
              return { name: data?.name, colorCode: data?.colorCode }
            })
            return { name: item?.name, data: colorData }
          } else if (item.name !== 'Color' && item.name !== 'SIZE') {
            return { name: item?.name, data: item?.data }
          } else {
            return item
          }
        })

  const order = ['SIZE', 'Color']

  const sizeAndColorData = transformedArray?.filter((item) => order?.includes(item.name))

  const remainingData = transformedArray?.filter((item) => !order?.includes(item.name))

  const sortedSizeAndColorData = sizeAndColorData?.sort((a, b) => order?.indexOf(a.name) - order?.indexOf(b.name))

  const finalSortedArray = sortedSizeAndColorData?.concat(remainingData)

  const [filterdDropdown, setFilterdDropdown] = useState(finalSortedArray || [])
  const [dropdownIndex, setDropdownIndex] = useState(null)

  const handleFilteredBox = async (isCheck, data, colorCode) => {
    const checkboxValue = isCheck

    let updatedSelectedCheckboxes = [...selectedCheckboxes]
    let updatedFilteredData = [...filteredData]

    if (checkboxValue) {
      if (!updatedSelectedCheckboxes.includes(data)) {
        updatedSelectedCheckboxes.push(data)
        if (colorCode) {
          updatedFilteredData.push({ name: data, colorCode })
        } else {
          updatedFilteredData.push(data)
        }
      }
    } else {
      updatedSelectedCheckboxes = updatedSelectedCheckboxes.filter((item) => item !== data)
      if (colorCode) {
        updatedFilteredData = updatedFilteredData.filter((item) => item?.name !== data)
      } else {
        updatedFilteredData = updatedFilteredData.filter((item) => item !== data)
      }
    }
    setSelectedCheckboxes(updatedSelectedCheckboxes)
    setFilteredData(updatedFilteredData)

    await dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          filteredList: updatedFilteredData || [],
          filterColorList: updatedSelectedCheckboxes || [],
        },
      }),
    )

    const updatedData = newVariantData.map((item) => {
      const isChecked = updatedSelectedCheckboxes.some((selectedData) => item?.total_variant?.includes(selectedData))
      return { ...item, isChecked }
    })

    setNewVariantData(updatedData)
    const currentDropdownData = filterdDropdown[dropdownIndex]?.data
    const isAllChecked = currentDropdownData?.every((data) => updatedSelectedCheckboxes.includes(data))

    if (isAllChecked) {
      const allData = filterdDropdown.reduce((acc, dropdown) => [...acc, ...dropdown.data], [])
      setSelectedCheckboxes(allData)
      updateAllCheckboxes(true)

      await dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            filterColorList: allData,
          },
        }),
      )
    }
  }

  const updateAllCheckboxes = async (checked) => {
    const allData = filterdDropdown.reduce((acc, dropdown) => [...acc, ...dropdown.data], [])
    const updatedData = newVariantData.map((item) => ({
      ...item,
      isChecked: checked,
    }))
    setNewVariantData(updatedData)
    setSelectedCheckboxes(checked ? allData : [])
    await dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          filterColorList: checked ? allData : [],
        },
      }),
    )
  }

  useEffect(() => {
    setFilterdDropdown(transformedArray)
    if (newFilterdDropdown === true) {
      setNewVariantData(addProductDetailSlice?.id?.variant?.allOptions || [])
    }
  }, [newFilterdDropdown])

  return (
    <>
      {/* <div className={styles.addProdutcHeadingAlignment}>
        <h6>Filter</h6>
        <a>Reset</a>
      </div>
      <div className={styles.addProductBodyAlignment}>
        <div className={styles.addProdutcBodyChildDetails}>
          <label>Size</label>
          <div className={styles.addProdutcAllChildList}>
            <div className={styles.addChildListBox}>
              <p>XS</p>
            </div>
            <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
              <p>S </p>
            </div>
            <div className={styles.addChildListBox}>
              <p>M</p>
            </div>
            <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
              <p>L</p>
            </div>
            <div className={styles.addChildListBox}>
              <p>XL</p>
            </div>
            <div className={styles.addChildListBox}>
              <p>XXL</p>
            </div>
            <div className={styles.addChildListBox}>
              <p>XXXL</p>
            </div>
          </div>
        </div>

        <div className={styles.addProdutcBodyChildDetails}>
          <label>Color</label>
          <div className={styles.addProdutcAllChildList}>
            <div className={styles.addChildListBox}>
              <div className={styles.colorBoxAlignment}></div>
              <p>Red</p>
            </div>
            <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
              <div className={classNames(styles.colorBoxAlignment, styles.blueBg)}></div>
              <p>Blue</p>
            </div>
            <div className={styles.addChildListBox}>
              <div className={classNames(styles.colorBoxAlignment, styles.yellowBg)}></div>
              <p>Yellow</p>
            </div>
            <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
              <div className={classNames(styles.colorBoxAlignment, styles.purpleBg)}></div>
              <p>Purple</p>
            </div>
            <div className={styles.addChildListBox}>
              <div className={classNames(styles.colorBoxAlignment, styles.darkBlue)}></div>
              <p>Dark Blue </p>
            </div>
            <div className={styles.addChildListBox}>
              <div className={classNames(styles.colorBoxAlignment, styles.greenBg)}></div>
              <p>Green</p>
            </div>
          </div>
        </div>

        <div className={styles.addProdutcBodyChildDetails}>
          <label>Material </label>
          <div className={styles.addProdutcAllChildList}>
            <div className={styles.addChildListBox}>
              <p>Metal</p>
            </div>
            <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
              <p>Polymer</p>
            </div>
            <div className={styles.addChildListBox}>
              <p>Rubber</p>
            </div>
          </div>
        </div>

        <div className={styles.addProdutcBodyChildDetails}>
          <label>
            Other Variant <span>(just placeholder)</span>
          </label>

          <div className={styles.addProdutcAllChildList}>
            <div className={styles.addChildListBox}>
              <p>Other 1</p>
            </div>
            <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
              <p>Other 2</p>
            </div>
            <div className={styles.addChildListBox}>
              <p>Other 3</p>
            </div>
            <div className={styles.addChildListBox}>
              <p>Other 4</p>
            </div>
          </div>
        </div>
      </div> */}
      <div className={styles.addProdutcHeadingAlignment}>
        <h6>Filter</h6>
        <a onClick={() => handleResetData()}>Reset</a>
      </div>
      <div className={styles.addProductBodyAlignment}>
        {filterdDropdown?.some((item) => item.name.toUpperCase() === 'SIZE') && (
          <div className={styles.addProdutcBodyChildDetails}>
            <label>Size</label>
            <div className={styles.addProdutcAllChildList}>
              {filterdDropdown
                ?.find((item) => item.name.toUpperCase() === 'SIZE')
                ?.data?.map((data, i) => {
                  return (
                    <>
                      <div
                        className={classNames(
                          styles.addChildListBox,
                          filteredData?.includes(data) && styles.activeDetails,
                        )}
                        key={i}
                        onClick={() => handleFilteredBox(selectedCheckboxes?.includes(data) ? false : true, data, null)}
                      >
                        {/* <input
                            type="checkbox"
                            onChange={(e) => handleFilteredBox(e, data, null, i)}
                            checked={selectedCheckboxes.includes(data)}
                          /> */}
                        <p>{data}</p>
                      </div>
                    </>
                  )
                })}
            </div>
          </div>
        )}

        {filterdDropdown?.some((item) => item.name.toUpperCase() === 'COLOR') && (
          <div className={styles.addProdutcBodyChildDetails}>
            <label>Color</label>
            <div className={styles.addProdutcAllChildList}>
              {filterdDropdown
                ?.find((item) => item.name.toUpperCase() === 'COLOR')
                ?.data?.map((data, i) => {
                  return (
                    <>
                      <div
                        className={classNames(
                          styles.addChildListBox,
                          selectedCheckboxes?.includes(data?.name) && styles.activeDetails,
                        )}
                        onClick={() =>
                          handleFilteredBox(
                            selectedCheckboxes?.includes(data?.name) ? false : true,
                            data?.name,
                            data?.colorCode,
                          )
                        }
                      >
                        <div className={styles.colorBoxAlignment} style={{ background: `${data?.colorCode}` }}></div>
                        <p>{data?.name}</p>
                      </div>
                    </>
                  )
                })}
              {/* <div className={styles.addChildListBox}>
                <div className={styles.colorBoxAlignment}></div>
                <p>Red</p>
              </div>
              <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
                <div className={classNames(styles.colorBoxAlignment, styles.blueBg)}></div>
                <p>Blue</p>
              </div>
              <div className={styles.addChildListBox}>
                <div className={classNames(styles.colorBoxAlignment, styles.yellowBg)}></div>
                <p>Yellow</p>
              </div>
              <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
                <div className={classNames(styles.colorBoxAlignment, styles.purpleBg)}></div>
                <p>Purple</p>
              </div>
              <div className={styles.addChildListBox}>
                <div className={classNames(styles.colorBoxAlignment, styles.darkBlue)}></div>
                <p>Dark Blue </p>
              </div>
              <div className={styles.addChildListBox}>
                <div className={classNames(styles.colorBoxAlignment, styles.greenBg)}></div>
                <p>Green</p>
              </div> */}
            </div>
          </div>
        )}

        <div className={styles.addProdutcBodyChildDetails}>
          {/* <label>Material </label>
            <div className={styles.addProdutcAllChildList}>
              <div className={styles.addChildListBox}>
                <p>Metal</p>
              </div>
              <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
                <p>Polymer</p>
              </div>
              <div className={styles.addChildListBox}>
                <p>Rubber</p>
              </div>
            </div> */}

          {filterdDropdown?.map((item, index) => {
            return (
              <>
                {item.name !== 'Color' && item.name !== 'SIZE' && (
                  <React.Fragment key={index}>
                    <label>{item?.name} </label>
                    <div className={styles.addProdutcAllChildList}>
                      {filterdDropdown[index]?.data?.map((data, i) => {
                        return (
                          <div
                            key={i}
                            className={classNames(
                              styles.addChildListBox,
                              selectedCheckboxes?.includes(data) && styles.activeDetails,
                            )}
                            onClick={() =>
                              handleFilteredBox(selectedCheckboxes.includes(data) ? false : true, data, null)
                            }
                          >
                            {/* <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleFilteredBox(selectedCheckboxes.includes(data) ? false : true, data, null)
                                  }
                                  checked={selectedCheckboxes.includes(data)}
                                /> */}
                            <p>{data}</p>
                          </div>
                        )
                      })}
                    </div>
                  </React.Fragment>
                )}
              </>
            )
          })}
        </div>

        {/* <div className={styles.addProdutcBodyChildDetails}>
            <label>
              Other Variant <span>(just placeholder)</span>
            </label>

            <div className={styles.addProdutcAllChildList}>
              <div className={styles.addChildListBox}>
                <p>Other 1</p>
              </div>
              <div className={classNames(styles.addChildListBox, styles.activeDetails)}>
                <p>Other 2</p>
              </div>
              <div className={styles.addChildListBox}>
                <p>Other 3</p>
              </div>
              <div className={styles.addChildListBox}>
                <p>Other 4</p>
              </div>
            </div>
          </div> */}
      </div>
    </>
  )
}
