import React, { useEffect } from 'react'
import ClubSlider from '../../ClubModal/ClubSlider'
import styles from './JewelryClubModal.module.scss'
import { useSelector } from 'react-redux'
import { fetchManageClubsData } from 'src/store/slices/fetchAllClubsSlice'
import { useAppDispatch } from 'src/store/store'

export default function JewelryClubModal() {
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const allManageClub = useSelector((state: any) => state?.clubData?.manageClubs)
  const dispatch = useAppDispatch()

  const leaveGroupCall = () => {}
  const joingroupCall = () => {}

  useEffect(() => {
    dispatch(fetchManageClubsData({ page: 1, limit: 100, type: 'SUGGESTED_CLUBS' }))
  }, [])

  return (
    <div className={styles.jewelryClubModalModalSection}>
      <div className={styles.jewelryClubModalModalAlignment}>
        <div className={styles.jewelryClubModalHeading}>
          <h4>{getAllState?.lastModalData?.id?.title}</h4>
        </div>
        <div className={styles.jewelryClubModalBodyAlignment}>
          <div className={styles.jewelryClubModalGridAlignment}>
            {allManageClub?.map((club, index) => (
              <React.Fragment key={index}>
                <ClubSlider club={club} leaveGroupCall={leaveGroupCall} joingroupCall={joingroupCall} isOpen={true} />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
