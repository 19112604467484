import React, { useEffect, useState } from 'react'
import CoverPhoto from './CoverPhoto'
import NavMenu from './NavMenu'
import Link from 'next/link'
import { useRouter } from 'next/router'
const LeftSidebar = (props) => {
  const { currentUser, index } = props
  const router = useRouter()

  return (
    <>
      <div className="dashboard-left-sidebar-clone"></div>
      <div className="dashboard-left-sidebar sticky">
        <div>
          <CoverPhoto currentUser={currentUser} />
          <NavMenu index={index} />
        </div>
        <div className="summary-user-profile-setting">
          <ul>
            <li className={router.pathname.includes('profile') ? 'tab-active' : ''}>
              <Link href="/customerportal/profile" scroll={false}>
                <a className="flex items-center">
                  <div className="deActive-img-alignment">
                    <img src="/assets/icon/settingDeactive-icon.svg" alt="Profile" />
                  </div>
                  <div className="active-img-alignment">
                    <img src="/assets/icon/settingActive-icon.svg" alt="Profile" />
                  </div>
                  <span className="ml-7">Profile</span>
                </a>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default LeftSidebar
