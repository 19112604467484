import React, { useState } from 'react'
import { Plus, AlertTriangle, Download, FileText, Upload, FolderOpen } from 'lucide-react'
import { useLanguage } from 'src/contexts/LanguageContext'
import TaxRuleModal from './tax/TaxRuleModal'
import TaxRulesTable from './tax/TaxRulesTable'

interface TaxRule {
  country: string
  state: string
  rate_percentage: number
}

export default function SellerOnboardTaxesTab({
  inputValue,
  setInputValue,
  handleNext,
  errors,
  setErrors,
  handleChange,
}) {
  const { t } = useLanguage()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [taxRules, setTaxRules] = useState<TaxRule[]>([])
  const [documents, setDocuments] = useState<any[]>([]) // Empty array to demonstrate empty state

  const handleAddRule = (rule: TaxRule) => {
    const TaxRule = {
      country: rule.country,
      state: rule.state,
      rate_percentage: String(rule.rate_percentage),
    }
    setInputValue({ ...inputValue, tax_rules: [...taxRules, TaxRule] })
    setTaxRules([...taxRules, rule])
  }

  const handleDeleteRule = (index: number) => {
    setTaxRules(taxRules.filter((_, i) => i !== index))
    setInputValue({ ...inputValue, tax_rules: taxRules.filter((_, i) => i !== index) })
  }

  const handleCheckboxChange = (category) => {
    setInputValue({
      ...inputValue,
      tax_categories:
        inputValue?.tax_categories?.length > 0
          ? inputValue?.tax_categories?.includes(category)
            ? inputValue?.tax_categories?.filter((item) => item !== category)
            : [...inputValue?.tax_categories, category]
          : [category],
    })
  }

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        // setInputValue((prev) => ({ ...prev, logo_image: reader.result as string }))
        setInputValue((prev) => ({
          ...prev,
          sellerIdentification: {
            id_type: 'tax_document',
            document: reader.result as string,
          },
        }))
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <div className="max-w-full space-y-8">
      {/* Tax Status */}
      <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
        <div className="flex items-center gap-3">
          <AlertTriangle className="text-yellow-600" size={24} />
          <div>
            <h3 className="font-medium text-yellow-900">Tax Configuration Required</h3>
            <p className="text-sm text-yellow-700">
              Please configure your tax settings to ensure compliance with local regulations.
            </p>
          </div>
        </div>
      </div>

      {/* General Tax Settings */}
      <div className="bg-white rounded-lg border border-gray-200 p-9">
        <h2 className="text-lg font-semibold text-gray-800 mb-4">General Tax Settings</h2>
        <div className="space-y-4">
          <label className="flex items-start gap-3">
            <input
              name="is_tax_calculation"
              value={inputValue?.is_tax_calculation}
              onChange={handleChange}
              type="checkbox"
              className="mt-2 h-6 w-6 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
            />
            <div>
              <span className="block text-sm font-medium text-gray-700">Enable Tax Calculation</span>
              <span className="block text-sm text-gray-500">
                Automatically calculate taxes based on customer location
              </span>
            </div>
          </label>

          <label className="flex items-start gap-3">
            <input
              name="is_price_include_tax"
              value={inputValue?.is_price_include_tax}
              onChange={handleChange}
              type="checkbox"
              className="mt-2 h-6 w-6 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
            />
            <div>
              <span className="block text-sm font-medium text-gray-700">Prices Include Tax</span>
              <span className="block text-sm text-gray-500">Display product prices with tax included</span>
            </div>
          </label>
        </div>
      </div>

      {/* Tax Rules */}
      <div className="bg-white rounded-lg border border-gray-200 p-6">
        <div className="flex items-center justify-between mb-4">
          <div>
            <h2 className="text-lg font-semibold text-gray-800">Tax Rules</h2>
            <p className="text-sm text-gray-500">Configure tax rates for different regions</p>
          </div>
          <button
            onClick={() => setIsModalOpen(true)}
            className="flex items-center gap-2 px-4 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
          >
            <Plus size={16} />
            <span>Add Rule</span>
          </button>
        </div>

        {taxRules.length > 0 ? (
          <TaxRulesTable rules={taxRules} onDelete={handleDeleteRule} />
        ) : (
          <div> No tax rule found pls add one tax rule.</div>
        )}
      </div>

      {/* Tax Categories */}
      <div className="bg-white rounded-lg border border-gray-200 p-6">
        <h2 className="text-lg font-semibold text-gray-800 mb-4">Tax Categories</h2>
        <div className="space-y-4">
          <label className="flex items-start gap-3 p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors">
            <input
              type="checkbox"
              className="mt-2 h-6 w-6 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
              onChange={() => handleCheckboxChange('Digital')}
              checked={inputValue?.tax_categories?.includes('Digital')}
            />
            <div>
              <span className="block text-sm font-medium text-gray-700">Digital Products</span>
              <span className="block text-sm text-gray-500">
                Apply special tax rules for digital goods and services
              </span>
            </div>
          </label>

          <label className="flex items-start gap-3 p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors">
            <input
              type="checkbox"
              className="mt-2 h-6 w-6 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
              onChange={() => handleCheckboxChange('Physical')}
              checked={inputValue?.tax_categories?.includes('Physical')}
            />
            {/* <input
              type="checkbox"
              className="mt-2 h-6 w-6 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
            /> */}
            <div>
              <span className="block text-sm font-medium text-gray-700">Physical Products</span>
              <span className="block text-sm text-gray-500">Apply standard tax rates for physical goods</span>
            </div>
          </label>
        </div>
      </div>

      {/* Annual Tax Documents */}
      <div className="bg-white rounded-lg border border-gray-200 p-6">
        <h2 className="text-lg font-semibold text-gray-800 mb-4">Annual Tax Documents</h2>

        {/* Document Generation */}
        <div className="mb-6">
          <h3 className="text-sm font-medium text-gray-700 mb-2">Generate Tax Reports</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <button className="flex items-center justify-center gap-2 p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors cursor-pointer">
              <FileText size={18} className="text-gray-500" />
              <span className="text-sm text-gray-700">Generate Annual Report</span>
            </button>
            <button className="flex items-center justify-center gap-2 p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors cursor-pointer">
              <FileText size={18} className="text-gray-500" />
              <span className="text-sm text-gray-700">Generate Quarterly Report</span>
            </button>
          </div>
        </div>

        {/* Document History */}
        <div>
          <h3 className="text-sm font-medium text-gray-700 mb-3">Document History</h3>
          {documents.length === 0 ? (
            <div className="border border-gray-200 rounded-lg p-8">
              <div className="flex flex-col items-center justify-center text-center">
                <div className="p-3 bg-gray-50 rounded-full mb-4">
                  <FolderOpen className="w8 h-8 text-gray-400" style={{ width: '32px', height: '32px' }} />
                </div>
                <h4 className="text-sm font-medium text-gray-900 mb-1">No Documents Yet</h4>
                <p className="text-sm text-gray-500 mb-4">
                  Generate your first tax report or upload existing documents to get started
                </p>
                <button className="text-sm text-indigo-600 hover:text-indigo-700 font-medium">
                  Learn about tax documents
                </button>
              </div>
            </div>
          ) : (
            <div className="border border-gray-200 rounded-lg divide-y divide-gray-200">
              <div className="p-4 flex items-center justify-between hover:bg-gray-50">
                <div className="flex items-center gap-3">
                  <FileText size={18} className="text-gray-400" />
                  <div>
                    <p className="text-sm font-medium text-gray-900">Annual Tax Report 2023</p>
                    <p className="text-xs text-gray-500">Generated on Jan 15, 2024</p>
                  </div>
                </div>
                <button className="p-2 text-gray-400 hover:text-gray-600">
                  <Download size={18} />
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Document Upload */}
        <div className="mt-6">
          <h3 className="text-sm font-medium text-gray-700 mb-3">Upload Tax Documents</h3>
          <label className="flex flex-col items-center justify-center w-full h-50 border-2 border-dashed border-gray-300 rounded-lg hover:border-indigo-500 cursor-pointer transition-colors">
            <div className="flex flex-col items-center justify-center py-10">
              <Upload className="text-gray-400 mb-2" size={24} />
              <p className="text-sm text-gray-500">Upload additional tax documents</p>
              <p className="text-xs text-gray-400">PDF, DOC up to 10MB</p>
            </div>
            <input type="file" className="hidden" accept=".pdf,.doc,.docx" onChange={handleFileUpload} />
          </label>
        </div>
      </div>

      {/* Save Button */}
      <div className="flex justify-end">
        <button
          onClick={handleNext}
          className="px-6 py-4 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
        >
          Save Changes
        </button>
      </div>

      {/* Tax Rule Modal */}
      <TaxRuleModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onSave={handleAddRule} />
    </div>
  )
}
