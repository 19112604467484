import React, { useMemo, useState } from 'react'
import { AddService } from './ShippingMethodSvg'
import countryList from 'react-select-country-list'

const AddNewShippingService = ({shippingservicesList}) => {
  const [isFormVisible, setIsFormVisible] = useState(false)
    const options = useMemo(() => countryList().getData(), [])
  const openForm = () => {
    setIsFormVisible(true)
  }
  const closeForm = () => {
    setIsFormVisible(false)
  }
  return (
    <>
      {isFormVisible && (
        <div className="p-4 border border-gray-200 dark:border-dark-600 rounded-lg bg-gray-50 dark:bg-dark-700">
          <form className="space-y-4">
            {/* <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      Select Continent
                    </label>
                    <select className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 dark:bg-dark-700 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm py-3 px-4">
                      <option value="">Select a continent</option>
                      <option value="North America">North America</option>
                      <option value="South America">South America</option>
                      <option value="Europe">Europe</option>
                      <option value="Asia">Asia</option>
                      <option value="Africa">Africa</option>
                      <option value="Oceania">Oceania</option>
                    </select>
                  </div> */}
            <div>
              <div className="pb-3 flex justify-between items-end">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Select Continent
                </label>
              </div>
              <select className="mt-1 block w-full rounded-md border border-gray-300 dark:border-dark-600 dark:bg-dark-700 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm py-3 px-4">
                {options?.map((countryList, index) => (
                  <option
                    disabled={shippingservicesList?.some((data) => data?.country === countryList?.value)}
                    key={index}
                    value={countryList?.value}
                  >
                    {countryList?.label}
                  </option>
                ))}
              </select>
            </div>
            {/* <div>
                    <div className="pb-3 flex justify-between items-end">
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Select Continent
                      </label>
                    </div>
                    <input
                      placeholder="Enter carrier name"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-dark-600 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent bg-white dark:bg-dark-800 text-gray-900 dark:text-white"
                      type="text"
                    />
                  </div> */}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Carrier Service</label>
              <select className="block w-full rounded-md border border-gray-300 dark:border-dark-600 dark:bg-dark-700 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm py-2">
                <option value="">Select carrier service</option>
                <optgroup label="USPS">
                  <option value="Priority Mail Express">Priority Mail Express</option>
                  <option value="Priority Mail">Priority Mail</option>
                  <option value="First-Class Mail">First-Class Mail</option>
                  <option value="Media Mail">Media Mail</option>
                  <option value="Retail Ground">Retail Ground</option>
                </optgroup>
                <optgroup label="UPS">
                  <option value="Next Day Air">Next Day Air</option>
                  <option value="2nd Day Air">2nd Day Air</option>
                  <option value="Ground">Ground</option>
                  <option value="SurePost">SurePost</option>
                  <option value="Mail Innovations">Mail Innovations</option>
                </optgroup>
                <optgroup label="FedEx">
                  <option value="Priority Overnight">Priority Overnight</option>
                  <option value="2Day">2Day</option>
                  <option value="Ground">Ground</option>
                  <option value="Express Saver">Express Saver</option>
                  <option value="SmartPost">SmartPost</option>
                </optgroup>
                <optgroup label="DHL">
                  <option value="Express">Express</option>
                  <option value="Ground">Ground</option>
                  <option value="eCommerce">eCommerce</option>
                  <option value="Parcel Direct">Parcel Direct</option>
                  <option value="SmartMail">SmartMail</option>
                </optgroup>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Carrier Rate</label>
              <div className="relative rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  className="block w-full rounded-md border border-gray-300 dark:border-dark-600 dark:bg-dark-700 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm py-2 pl-7 pr-4"
                  placeholder="  Enter carrier rate"
                  step="0.01"
                  min="0"
                  type="number"
                />
              </div>
            </div>
            <div className="flex gap-2 pt-2">
              <button
                type="submit"
                className="flex-1 p-2 bg-indigo-600 text-white rounded-lg text-sm font-medium hover:bg-indigo-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
              >
                Add Carrier
              </button>
              <button
                type="button"
                className="p-2 border border-gray-300 dark:border-dark-600 rounded-lg text-sm text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-dark-700"
                onClick={closeForm}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
      <div className="flex justify-end">
        <button
          type="button"
          className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-indigo-600 dark:text-indigo-400 hover:text-indigo-500"
          onClick={openForm}
        >
          <AddService />
          Add Service
        </button>
      </div>
    </>
  )
}

export default AddNewShippingService
