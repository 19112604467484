import { gql } from '@apollo/client'

const VERIFY_OTP_FOR_FORGOT = gql`
  mutation VerifyOTPForForgot($otp: String!, $email: String!) {
    verifyOTPForForgot(otp: $otp, email: $email) {
      message
      success
    }
  }
`
export default VERIFY_OTP_FOR_FORGOT
