import { gql } from '@apollo/client'

const REMOVE_PRODUCT_FROM_STREAM = gql`
mutation RemoveProductFromLivestream($uuid: String, $productId: Int) {
  removeProductFromLivestream(uuid: $uuid, product_id: $productId) {
    message
    success
  }
}
`

export default REMOVE_PRODUCT_FROM_STREAM
