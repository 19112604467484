import React from 'react'
import styles from './MobileSelectImageRatioModal.module.scss'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { notification } from 'antd'
import { fetchProductImageResizeModal } from 'src/store/slices/productResizeSlice'
import { useAppDispatch } from 'src/store/store'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'

export default function MobileSelectImageRatioModal() {
  const dispatch = useAppDispatch()
  const fetchProductImageResizeData = useSelector((state: any) => state?.productResizeData?.data)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)

  const handleSelectSize = (ratio, size) => {
    let EditedImage = Boolean(
      fetchProductImageResizeData?.id?.media?.filter((md) => md?.ratio === fetchProductImageResizeData?.id?.ratio)
        ?.length,
    )
    if (fetchProductImageResizeData?.id?.ratio !== ratio && EditedImage) {
      notification['error']({
        message: 'Remove old Images',
        description: 'First remove the old ratio image and only then you can change the ratio',
        style: { marginTop: '50px' },
      })
    } else {
      let data = {
        ...fetchProductImageResizeData?.id,
        ratio: ratio,
        selectedSize: size,
        isSelected: true,
      }
      dispatch(
        fetchProductImageResizeModal({
          modal: '',
          isOpen: false,
          id: data,
        }),
      )
      dispatch(
        fetchMobileModalData({
          allModalData: [
            ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
            {
              modal: 'adjustImageMobile',
              isOpen: false,
              id: 0,
              transform: 7,
            },
          ],
          lastModalData: fetchMobileModal?.lastModalData,
        }),
      )
    }
  }

  const handleClose = () => {
    dispatch(
      fetchMobileModalData({
        allModalData: [
          ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
          {
            modal: 'adjustImageMobile',
            isOpen: false,
            id: 0,
            transform: 7,
          },
        ],
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
  }

  return (
    <div className={styles.mobileSelectImageRatioModalAlignment}>
      <div className={styles.mobileSelectImageRatioModalHeading}>
        <h6>Select Image Ratio</h6>
        <a onClick={handleClose}>Cancel</a>
      </div>
      <div className={styles.mobileSelectImageRatioModalBodyAlignment}>
        <div className={styles.mobileSelectImageRatioModalGrid}>
          <div
            className={styles.mobileSelectImageRatioModalGridItem}
            onClick={() => handleSelectSize('1 : 1', { width: 44, height: 44 })}
          >
            <div className={styles.mobileSelectBoxAlignment}>
              <p>1 : 1</p>
            </div>
          </div>
          <div
            className={styles.mobileSelectImageRatioModalGridItem}
            onClick={() => handleSelectSize('9 : 16', { width: 1080, height: 1920 })}
          >
            <div className={classNames(styles.mobileSelectBoxAlignment, styles.secondBox)}>
              <p>9 : 16</p>
            </div>
          </div>
          <div
            className={styles.mobileSelectImageRatioModalGridItem}
            onClick={() => handleSelectSize('2 : 3', { width: 1080, height: 1620 })}
          >
            <div className={classNames(styles.mobileSelectBoxAlignment, styles.thirdBox)}>
              <p>2 : 3</p>
            </div>
          </div>
          <div
            className={styles.mobileSelectImageRatioModalGridItem}
            onClick={() => handleSelectSize('4 : 5', { width: 1080, height: 1350 })}
          >
            <div className={classNames(styles.mobileSelectBoxAlignment, styles.forthBox)}>
              <p>4 : 5</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
