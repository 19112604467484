import React, { useState } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const addTransformParam = (src: string) => {
  return src && src.indexOf('?') === -1 ? '?t=w' : '&t=w'
}

const contentHubSizes = [576, 992, 1200, 1920, 2880]
const deviceSizes = [576, 992, 1440, 1920, 2880]

interface LazyProductImagePropType {
  image?: any
  priority?: boolean
  sizes?: string
  className?: string
  width?: number
  height?: number
  disableSkeleton?: boolean
  objectFit?: any
  loading?: 'lazy' | 'eager' | undefined
  handleClickOnImage?: any
  imageClassName?: any
}

const LazyProductImage: React.FC<LazyProductImagePropType> = ({
  image,
  priority,
  sizes,
  width,
  height,
  loading = 'lazy',
  className,
  disableSkeleton,
  objectFit,
  handleClickOnImage,
  imageClassName,
}) => {
  const { src, alt } = image
  const [isLoaded, setIsLoaded] = useState(true)
  const handleImageLoad = () => {
    setIsLoaded(true)
  }

  return (
    <>
      {!isLoaded && !disableSkeleton && (
        <div style={{ position: 'relative', width, height }}>
          <SkeletonTheme baseColor="#d1d1d1" highlightColor="#FFFFFF">
            <Skeleton style={{ width: width, height: height }} className={imageClassName} />
          </SkeletonTheme>
        </div>
      )}

      {image ? (
        // <img
        //   alt={alt}
        //   // width={width}
        //   // height={height}
        //   onLoad={handleImageLoad}
        //   loading={loading}
        //   onClick={handleClickOnImage}
        //   src={`${src}${addTransformParam(src)}${deviceSizes[deviceSizes.length - 1]}`}
        //   srcSet={deviceSizes
        //     .map((deviceSize, index) => `${src}${addTransformParam(src)}${contentHubSizes[index]} ${deviceSize}w`)
        //     .join(', ')}
        //   className={imageClassName}
        //   sizes={sizes ? sizes : `(max-width: 768px) 100vw, 50vw`}
        //   style={{
        //     opacity: isLoaded ? 1 : 0,
        //     transition: 'opacity 1s',
        //     // position: 'absolute',
        //     top: 0,
        //     left: 0,
        //     width: '100%',
        //     height: '100%',
        //     objectFit: objectFit,
        //     cursor: 'pointer',
        //     objectPosition: 'center',
        //   }}
        // />
        <div
          className="poroduct-image-background"
          style={{
            backgroundImage: `url(${src})`,
            // background : "red"
          }}
        ></div>
      ) : // <div
      //   className="poroduct-image-background"
      //   style={{
      //     backgroundImage: `url(${src})`,
      //     // background : "red"
      //   }}
      // ></div>
      null}
    </>
  )
}

export default LazyProductImage
