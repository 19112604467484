import { gql } from '@apollo/client'

const GET_DATE_WISE_PRODUCT_TRACTION = gql`
  query GetProductTraction(
    $productId: Int!
    $startDate: String!
    $endDate: String!
    $timeInterval: String
    $timeZone: String
  ) {
    getProductTraction(
      product_id: $productId
      start_date: $startDate
      end_date: $endDate
      time_interval: $timeInterval
      time_zone: $timeZone
    ) {
      message
      success
      data
    }
  }
`
export default GET_DATE_WISE_PRODUCT_TRACTION
