// @ts-nocheck
import React, { useState } from 'react'
import SearchIcon from '../../../../../../public/assets/icon/search.svg'
import BookSingleclub from './BookSingleclub'
import BookCoverImg from '../../../../../../public/assets/img/bookCover.png'
import SmallMemberImg from '../../../../../../public/assets/img/small-member-img.png'
import users from '../../../../../../public/assets/img/WomansCategory/users.png'
import BookBannerImg from '../../../../../../public/assets/img/book-banner-img.png'
import like from '../../../../../../public/assets/img/WomansCategory/like.png'
import Slider from 'react-slick'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import AosAnimation from 'src/utils/AosAnimation'
export default function BookClubs(props) {
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    // slidesToShow: 1.055,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const { setIsOpen, setMobileModal } = props
  return (
    <div className="book-club-section-alignment">
      <div className="heading-alignment">
        <h6>Book Clubs</h6>
        <a
          onClick={() =>
            dispatch(
              fetchModalData({
                allModalData: [
                  ...getAllState?.allModalData,
                  { modal: 'bookClub', isOpen: true, id: 0, modalFor: 'bigModal' },
                ],
                lastModalData: getAllState?.lastModalData,
              }),
            )
          }
        >
          See More
        </a>
      </div>
      <div className="book-club-create-section">
        <div className="create-input-alignment">
          <img src={SearchIcon.src} alt="SearchIcon" />
          <input type="text" placeholder="Search Clubs" />
        </div>
        <div className="create-clun-alignment">
          <button>+ Create Club</button>
        </div>
      </div>
      <div className="book-club-created-details-alignment">
        {[0, 1, 2, 3, 4, 5, 6, 7].map((_, index) => (
          <BookSingleclub index={index} />
        ))}
      </div>

      {/* Mobile view ====================================== */}
      <div className="heading-alignment-mobile">
        <h6>Book Clubs</h6>
        <a onClick={() => setMobileModal({ name: 'bookclub' })}>See More</a>
      </div>
      <div className="mobile-view-book-club-created-details-alignment">
        <Slider {...settings}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((_, index) => {
            return (
              <>
                <AosAnimation animationType="fade-up" duration={1000}>
                  <div className="book-club-details_cover" key={index}>
                    <div className="card_fashion">
                      <div className="img_cover">
                        <img src={BookBannerImg.src} alt="img_cover" />
                      </div>
                      <div className="card_details">
                        <div className="card_details_cover">
                          <h5>Book Club 56</h5>
                          <p>Lorem ipsum Dolor sit amet consectetur</p>
                          <div className="users">
                            <div className="left_part">
                              <div className="user_img pr-4">
                                <img src={users.src} alt="user_img" />
                              </div>
                              {[...Array(3)].map((_, index) => {
                                return (
                                  <div className="users_likes" key={index}>
                                    <div className="user_box">
                                      <img src={like.src} alt="user_box" />
                                    </div>
                                  </div>
                                )
                              })}
                              <div className="count">+120</div>
                            </div>
                            <div className="right_part">
                              <button>Join Group</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </AosAnimation>
              </>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}
