import { gql } from '@apollo/client'

const ADD_COLLECTION_LIKE = gql`
  mutation AddCollectionLike($collectionId: Int!) {
    addCollectionLike(collection_id: $collectionId) {
      message
      success
    }
  }
`
export default ADD_COLLECTION_LIKE
