import React, { useEffect, useState, useRef } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import GET_ALL_BILLING_ADDRESS from 'src/graphql/queries/getAllBillingAddress'
import TAX_CALCULATION from 'src/graphql/mutations/taxCalculation'

export default function OrderSummarySection({ totalProductCalculation, handlePurchase, deliveryOption, defaultAddress  }) {
  const [taxData, setTaxData] = useState(null)
  const [taxCalculation] = useMutation(TAX_CALCULATION)
  const prevZipcodeRef = useRef(null)
  
// let defaultAddress = data?.getAllBillingAddress?.filter((data) => data?.isDefault)  

  useEffect(() => {
    if (defaultAddress?.length === 0) return
    const currentZipcode = defaultAddress?.[0].zipcode
    if (currentZipcode && currentZipcode !== prevZipcodeRef.current) {
      prevZipcodeRef.current = currentZipcode
      taxCalculation({ variables: { zipcode: currentZipcode } })
        .then((res) => {

          setTaxData(res.data)
        })
        .catch((error) => {
          console.log(error)
        })  
    }
    
  }, [defaultAddress, taxCalculation])

  const taxValue = (totalProductCalculation?.totalPrice || 0) * ((taxData?.taxCalculation?.data?.total_rate || 0) / 100)
  const totalValue = (totalProductCalculation?.totalPrice || 0) + (isNaN(parseFloat(deliveryOption?.shipping_amount?.amount)) ? 0 : parseFloat(deliveryOption?.shipping_amount?.amount)) + taxValue

  

  return (
    <div className="order-summary-main">
      <div className="title-grid">
        <div className="order-summary-text">
          <h3>Order Summary</h3>
        </div>
        <div className="summary-table">
          <div className="flex-class-table">
            <div className="summary-menu">
              <span>Items ({totalProductCalculation?.productCount})</span>
            </div>
            <div className="summary-amount">
              <span>$ {totalProductCalculation?.totalPrice ?? '0'}</span>
            </div>
          </div>
          <div className="flex-class-table">
            <div className="summary-menu">
              <span>Discount</span>
            </div>
            <div className="summary-amount">
              <span>$ 0</span>
            </div>
          </div>
          <div className="flex-class-table">
            <div className="summary-menu">
              <span>Shipping</span>
            </div>
            <div className="summary-amount">
              <span>
                ${' '}
                {isNaN(parseFloat(deliveryOption?.shipping_amount?.amount))
                  ? 0
                  : parseFloat(deliveryOption?.shipping_amount?.amount).toFixed(2)}
              </span>
            </div>
          </div>
          <div className="flex-class-table">
            <div className="summary-menu">
              <span>handling</span>
            </div>
            <div className="summary-amount">
              <span>$ 0</span>
            </div>
          </div>
          <div className="flex-class-table">
            <div className="summary-menu">
              <span>Estimated Tax</span>
            </div>
            <div className="summary-amount">
              <span>
                $ {taxValue.toFixed(2)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-class-total">
        <div className="total-menu-text">
          <span>Total:</span>
        </div>
        <div className="total-menu-price">
          <span>
            ${totalValue.toFixed(2)}
          </span>
        </div>
      </div>

      <div className="mobile-view-button-alignment">
        <div className="mobile-view-total-price-alignment">
          <span>
            Total:{' '}
            <p>
              ${' '}
              {totalValue.toFixed(2)}
            </p>
          </span>
        </div>
        <div className="mobile-view-perchase-button-alignment" onClick={(e) => handlePurchase(e)}>
          <button>Purchase</button>
        </div>
      </div>
    </div>
  )
}
