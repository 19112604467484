import React, { useState } from 'react'
import getImageUrl from 'src/utils/getImageUrl'

export default function PopOverAllComments(props) {
  const [show, setShow] = useState(4)
  const customStyles = {
    avatarContainer: {
      width: 40,
      height: 40,
      marginLeft: 0,
      borderRadius: 40,
      boxShadow: '0 0 6px rgba(0,0,0,0.2)',
    },
  }
  return (
    <>
      <div className=" bottom-20  left-0 w-64  rounded-sm z-50 truncate">
        {props?.comments.map((comment: any, i: any) => {
          if (i < show) {
            return (
              <div className="flex items-center" key={i}>
                <div className="flex items-center mt-2 mb-3">
                  <img
                    src={
                      comment?.user?.profileUrl
                        ? getImageUrl(comment?.user?.profileUrl)
                        : 'https://randomuser.me/api/portraits/women/13.jpg'
                    }
                    className="object-cover"
                    style={customStyles.avatarContainer}
                  />
                  <div className="pl-2">
                    <p className="text-sm underline decoration-1  text-black font-medium">
                      {comment?.user?.firstName + ' ' + comment?.user?.lastName}{' '}
                    </p>
                    {/* <span className="text-xs text-gray-400 font-medium block" style={{ fontSize: '12px' }}>
                      {comment?.comment}
                    </span> */}
                  </div>
                </div>
              </div>
            )
          }
        })}
        <div className="flex items-start">
          <span
            className="text-xs text-gray font-medium block text-blue "
            style={{ fontSize: '12px' }}
            onClick={() => setShow(show == 4 ? 10 : 4)}
          >
            {show == 4 ? 'View All' : 'View Less'}
          </span>
        </div>
      </div>
    </>
  )
}
