import React from 'react'
import GalleryForMobileDetails from '../GalleryForMobileDetails'

export default function AllGalleryForMobileModal({
  setOpenImagePrev,
  splittedArray,
  setSeeAllMediaModal,
  seeAllMediaModal,
}) {
  return (
    <div className="all-child-details-modal-alignment">
      <div
        className={
          seeAllMediaModal
            ? 'all-child-details-modal-wrapper open-modal-wrapper'
            : 'all-child-details-modal-wrapper close-modal-wrapper'
        }
      >
        <div className="all-child-header-sticky">
          <div className="all-child-modal-header-alignment">
            <div className="mobile-view-back-arrow-alignment" onClick={() => setSeeAllMediaModal(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                <path
                  opacity="0.6"
                  d="M34 6H10C4.47715 6 0 10.4772 0 16V28C0 33.5228 4.47715 38 10 38H34C39.5228 38 44 33.5228 44 28V16C44 10.4772 39.5228 6 34 6Z"
                  fill="#1E2432"
                />
                <path
                  d="M24 16L19 22L24 28"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <h6>Gallery</h6>
          </div>
        </div>
        <div className="all-child-modal-all-details-alignment">
          <GalleryForMobileDetails setOpenImagePrev={setOpenImagePrev} splittedArray={splittedArray} />
        </div>
      </div>
    </div>
  )
}
