import React from 'react'
import { Tabs } from 'antd'
import PersonalMessageTab from '../PersonalMessageTab'
import GroupMessageTab from '../GroupMessageTab'

export default function MessageKnobs() {
  const { TabPane } = Tabs
  return (
    <div className="message-tab-alignment message-modal-tab">
      <Tabs defaultActiveKey="1" className="user-info-tabs">
        <TabPane tab={'Personal'} key="1" className="">
          {/* <div className="message-all-chat-details-alignment"> */}
          <PersonalMessageTab />
          {/* </div> */}
        </TabPane>
        <TabPane tab={'Group'} key="2">
          <GroupMessageTab />
        </TabPane>
      </Tabs>
    </div>
  )
}
