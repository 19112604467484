import React from 'react'
import styles from './SellerTips.module.scss'
export default function SellerTips() {
  return (
    <div className={styles.sellerTipsSection}>
      <div className={styles.sellerTipsBoxAlignment}>
        <div className={styles.sellerTipsHeadingAlignment}>
          <h6>Tips</h6>
        </div>

        <div className={styles.sellerTipsDetailsAlignment}>
          <div className={styles.sellerTipsDetailsBoxAlignment}>
            <div className={styles.sellerTipsBannerAlignment}>
              <img src="/assets/seller/img/tip-bg.png" alt="tips bg" />
              <div className={styles.sellerTipsCardDetailsAlignment}>
                <p>Offer promotions and discounts</p>

                <div className={styles.rightArrowAlignment}>
                  <img src="/assets/seller/icons/right-brown-arrow.svg" alt="right arrow" />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.sellerTipsDetailsBoxAlignment}>
            <div className={styles.sellerTipsBannerAlignment}>
              <img src="/assets/seller/img/tip2-bg.png" alt="tips bg" />
              <div className={styles.sellerTipsCardDetailsAlignment}>
                <p className={styles.greenArrow}>Enhance website usability</p>

                <div className={styles.rightArrowAlignment}>
                  <img src="/assets/seller/icons/right-green-arrow.svg" alt="right arrow" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
