import { gql } from '@apollo/client'

const GET_STORE_FOLLOW = gql`
  query GetStoreFollow($storeId: Int, $product_Id: Int) {
    getStoreFollow(store_id: $storeId, product_id: $product_Id) {
      isFollow
      isBookmark
      bookmark_id
      collection_id
    }
  }
`
export default GET_STORE_FOLLOW
