import React, { useEffect, useState } from 'react'
import styles from './SellerAccountBalance.module.scss'
import MobileViewWithdrawModal from './MobileViewWithdrawModal'
import MobileViewWithdrawDetailsModal from './MobileViewWithdrawDetailsModal'
import MobileViewWithdrawPasswordModal from './MobileViewWithdrawPasswordModal'
import MobileViewWithdrawRequestModal from './MobileViewWithdrawRequestModal'
import { Modal, Spin, notification } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import SELLER_BANK_ONBOARDING_STATUS from 'src/graphql/queries/sellerQueries/SellerBankOnboardingStatus'
import SELLER_AVAILABLE_AMOUNT from 'src/graphql/queries/sellerQueries/sellerAvailableAmount'
import WITHDRAW_PAYMENT_FROM_SELLER from 'src/graphql/queries/sellerQueries/withDrawPaymentFromSeller'
import { ApiPost, ApiSellerPost } from 'src/Helpers/Api/ApiData'
import sellerClient from 'src/utils/apolloSellerClient'
import Loader from 'src/components/UI/Loader'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import MobileViewInputOtpModal from './MobileViewInputOtpModal'
import MobileViewTransactionDetails from './MobileViewTransactionDetails'
import SELLER_CONNECT_ACCOUNTS from 'src/graphql/queries/sellerQueries/sellerConnectAccounts'
import { fetchSellerWithdrawModalData } from 'src/store/slices/manageSellerWithdrawSlice'

export default function SellerAccountBalance() {
  const dispatch = useAppDispatch()
  const sellerWithdrawData = useSelector((state: any) => state?.sellerWithdrawData?.data)
  const [isMobileViewWithdrawModal, setIsMobileViewWithdrawModal] = useState(false)
  const [isMobileViewWithdrawDetailsModal, setIsMobileViewWithdrawDetailsModal] = useState(false)
  const [isMobileViewWithdrawPasswordModal, setIsMobileViewWithdrawPasswordModal] = useState(false)
  const [isMobileViewWithdrawRequestModal, setIsMobileViewWithdrawRequestModal] = useState(false)
  const [isMobileViewWithdrawTransactionDetailsModal, setIsMobileViewWithdrawTransactionDetailsModal] = useState(false)
  const [isMobileViewWithdrawOtpModal, setIsMobileViewWithdrawOtpModal] = useState(false)
  const [paymentSuccessModal, setPaymentSuccessModal] = useState(false)

  const handleCancel = () => {
    setPaymentSuccessModal(!paymentSuccessModal)
  }

  const sellerOnboardingStatus = useQuery(SELLER_BANK_ONBOARDING_STATUS, { client: sellerClient })
  const sellerAvailableAmount = useQuery(SELLER_AVAILABLE_AMOUNT, { client: sellerClient })
  const sellerBankDetail = useQuery(SELLER_CONNECT_ACCOUNTS, { client: sellerClient })

  useEffect(() => {
    sellerOnboardingStatus.refetch()
    setPaymentSuccessModal(true)
  }, [])

  const [withdrawPayment] = useMutation(WITHDRAW_PAYMENT_FROM_SELLER, {
    onCompleted: () => {},
    onError: (error) => {
      console.log(error)
    },
  })

  const handleWithdraw = async () => {
    // withdrawPayment({
    //   client: sellerClient,
    //   variables: {
    //     amount: 0,
    //     accountId: data?.sellerBankOnboardingStatus?.data?.accountId,
    //   },
    // })
    const coverImageUpload: any = await ApiSellerPost('/stripe/onboard-seller', null, 'sellerToken')
      .then((res: any) => {
        if (res?.status === 200) {
          // window.open(res?.data?.url)
          window.location.href = res?.data?.url
        }
      })
      .catch((err) => {
        notification['error']({
          message: err,
          description: 'Your onboarding is already completed',
          style: { marginTop: '50px' },
        })
      })
  }

  const handleWithdrawModal = () => {
    const bankDetail = sellerBankDetail?.data?.sellerConnectAccounts?.data
    dispatch(
      fetchSellerWithdrawModalData({
        allModalData: [
          ...sellerWithdrawData?.allModalData,
          {
            modal: 'sellerWithdrawModalFirst',
            isOpen: true,
            id: 0,
            modalFor: 'sellerWithdrawSize',
          },
        ],
        lastModalData: sellerWithdrawData?.lastModalData,
        withdrawFund: {
          amount: sellerAvailableAmount?.data?.sellerAvailableAmount?.data,
          bankDetail: Boolean(bankDetail?.length) ? bankDetail[0] : null,
          accountId: sellerOnboardingStatus?.data?.sellerBankOnboardingStatus?.data?.accountId,
        },
      }),
    )
  }

  const withdrwalSection = () => {
    let isDetailSubmitted = Boolean(sellerOnboardingStatus?.data?.sellerBankOnboardingStatus?.data?.details_submitted)
    let isPaymentOnbordCompleted = Boolean(
      sellerOnboardingStatus?.data?.sellerBankOnboardingStatus?.data?.isPaymentOnboardingCompleted,
    )
    if (sellerAvailableAmount?.loading || sellerOnboardingStatus?.loading)
      return (
        <div className="flex">
          <div className="m-auto">
            <Spin tip="loading" />
          </div>
        </div>
      )
    if (!isDetailSubmitted && !isPaymentOnbordCompleted) {
      return (
        <div className={styles.sellerSetUpPaymentBox}>
          <p>For withdrawal eligibility, ensure your payment setup is arranged.</p>
          <div className={styles.sellerPaymentButtonAlignment}>
            <button onClick={() => handleWithdraw()}>Setup Payment</button>
          </div>
          <div className={styles.sellerPaymentBottomDetails}>
            <div className={styles.learnMoreAlignment}>
              <a>
                Learn more{' '}
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                  <path d="M1 7.00012L7 1.00012" stroke="#556EE6" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M1 1.00012H7V7.00012" stroke="#556EE6" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </a>
            </div>
            <div className={styles.sellerPaymentOtherDetails}>
              <div className={styles.otherDetailsAlignment}>
                <img src="/assets/seller/icons/bluejesctic-logo.svg" alt="logo" />
              </div>
              <div className={styles.otherDetailsAlignment}>
                <img src="/assets/seller/icons/icon1.svg" alt="icon" />
              </div>
              <div className={styles.otherDetailsAlignment}>
                <img src="/assets/seller/icons/connect-icon.svg" alt="connect" />
              </div>
            </div>
          </div>
        </div>
      )
    } else if (isDetailSubmitted && !isPaymentOnbordCompleted) {
      return (
        <div className={styles.verificationReviewBoxAlignment}>
          <div className={styles.verificationReviewBoxHeadingAlignment}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
              <path
                d="M25.1176 28C25.1176 28.7956 24.8 29.5587 24.2347 30.1213C23.6693 30.6839 22.9025 31 22.1029 31H4.01471C3.21516 31 2.44835 30.6839 1.88299 30.1213C1.31762 29.5587 1 28.7956 1 28V4C1 3.20435 1.31762 2.44129 1.88299 1.87868C2.44835 1.31607 3.21516 1 4.01471 1H16.2941L25.1176 9.82353"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.2938 0.999939V9.82347H25.1173"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M16.294 17.4707H6.88223" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M16.294 23.3529H6.88223" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
              <path
                d="M10.4116 11.5882H8.64694H6.88223"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M25.1175 25.1177C28.3663 25.1177 30.9999 22.4841 30.9999 19.2353C30.9999 15.9866 28.3663 13.353 25.1175 13.353C21.8688 13.353 19.2352 15.9866 19.2352 19.2353C19.2352 22.4841 21.8688 25.1177 25.1175 25.1177Z"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M25.1175 15.7057V19.2352L27.4704 20.4116"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <div className={styles.verificationHeadingTextAlignment}>
              <h4>Verification in Review </h4>
              <p>June 2, 2023</p>
            </div>
          </div>
          <div>
            <div className={styles.verificationDescriptionText}>
              <p>
                Payment setup is under review. Verification will take up to 5 business days. Withdrawal access is
                available immediately after your account is verified.
              </p>
            </div>
            <div className={styles.veficationDescriptionBottomDetails}>
              <div className={styles.sellerPaymentBottomDetails}>
                <div className={styles.learnMoreAlignment}>
                  <a>
                    Learn more{' '}
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                      <path d="M1 7.00012L7 1.00012" stroke="#556EE6" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M1 1.00012H7V7.00012" stroke="#556EE6" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </a>
                </div>
                <div className={styles.sellerPaymentOtherDetails}>
                  <div className={styles.otherDetailsAlignment}>
                    <img src="/assets/seller/icons/bluejesctic-logo.svg" alt="logo" />
                  </div>
                  <div className={styles.otherDetailsAlignment}>
                    <img src="/assets/seller/icons/icon1.svg" alt="icon" />
                  </div>
                  <div className={styles.otherDetailsAlignment}>
                    <img src="/assets/seller/icons/connect-icon.svg" alt="connect" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else if (isDetailSubmitted && isPaymentOnbordCompleted) {
      return (
        <div className={styles.withdrawButtonAlignment}>
          <button className={styles.activeButton} onClick={handleWithdrawModal}>
            Withdraw
          </button>
        </div>
      )
    }
  }

  return (
    <>
      <div className={styles.sellerAccountBalanceSection}>
        <div className={styles.sellerAccountMainBoxAlignment}>
          <div className={styles.sellerBalanceBoxALignment}>
            <div className={styles.balanceBoxTopDetailsAlignment}>
              <div className={styles.topFirstDetails}>
                <h4 onClick={() => setIsMobileViewWithdrawModal(true)}>Balance</h4>
                <div className={styles.historyAlignment}>
                  <h6>History</h6>
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                    <path
                      d="M0.999878 6.99988L6.99988 0.999878"
                      stroke="#CAD2F7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M0.999878 0.999878H6.99988V6.99988"
                      stroke="#CAD2F7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>

              <div className={styles.upcomingDetailsAlignment}>
                <h5>Up Coming Payment</h5>
                <div className={styles.moreDetailsAlignment}>
                  <h6>More Detail</h6>
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                    <path
                      d="M0.999878 6.99988L6.99988 0.999878"
                      stroke="#CAD2F7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M0.999878 0.999878H6.99988V6.99988"
                      stroke="#CAD2F7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className={styles.upcomingPriceAlignmeny}>
                <p>${sellerAvailableAmount?.data?.sellerAvailableAmount?.data?.upcoming_amount.toLocaleString()}</p>
              </div>
            </div>

            <div className={styles.balanceBoxBottomDetailsAlignment}>
              <p>Available</p>
              <div className={styles.availablePriceAlignment}>
                <span>${sellerAvailableAmount?.data?.sellerAvailableAmount?.data?.final_amount.toLocaleString()}</span>
              </div>
            </div>
          </div>

          <div className={styles.sellerBalanceBottomDetailsAlignment}>
            {/* <div className={styles.verificationReviewBoxAlignment}>
              <div className={styles.verificationReviewBoxHeadingAlignment}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <path
                    d="M25.1176 28C25.1176 28.7956 24.8 29.5587 24.2347 30.1213C23.6693 30.6839 22.9025 31 22.1029 31H4.01471C3.21516 31 2.44835 30.6839 1.88299 30.1213C1.31762 29.5587 1 28.7956 1 28V4C1 3.20435 1.31762 2.44129 1.88299 1.87868C2.44835 1.31607 3.21516 1 4.01471 1H16.2941L25.1176 9.82353"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.2938 0.999939V9.82347H25.1173"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M16.294 17.4707H6.88223" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M16.294 23.3529H6.88223" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                  <path
                    d="M10.4116 11.5882H8.64694H6.88223"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M25.1175 25.1177C28.3663 25.1177 30.9999 22.4841 30.9999 19.2353C30.9999 15.9866 28.3663 13.353 25.1175 13.353C21.8688 13.353 19.2352 15.9866 19.2352 19.2353C19.2352 22.4841 21.8688 25.1177 25.1175 25.1177Z"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M25.1175 15.7057V19.2352L27.4704 20.4116"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <div className={styles.verificationHeadingTextAlignment}>
                  <h4>Verification in Review </h4>
                  <p>June 2, 2023</p>
                </div>
              </div>
              <div>
                <div className={styles.verificationDescriptionText}>
                  <p>
                    Payment setup is under review. Verification will take up to 5 business days. Withdrawal access is
                    available immediately after your account is verified.
                  </p>
                </div>
                <div className={styles.veficationDescriptionBottomDetails}>
                  <div className={styles.sellerPaymentBottomDetails}>
                    <div className={styles.learnMoreAlignment}>
                      <a>
                        Learn more{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                          <path
                            d="M1 7.00012L7 1.00012"
                            stroke="#556EE6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1 1.00012H7V7.00012"
                            stroke="#556EE6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </a>
                    </div>
                    <div className={styles.sellerPaymentOtherDetails}>
                      <div className={styles.otherDetailsAlignment}>
                        <img src="/assets/seller/icons/bluejesctic-logo.svg" alt="logo" />
                      </div>
                      <div className={styles.otherDetailsAlignment}>
                        <img src="/assets/seller/icons/icon1.svg" alt="icon" />
                      </div>
                      <div className={styles.otherDetailsAlignment}>
                        <img src="/assets/seller/icons/connect-icon.svg" alt="connect" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className={styles.verifiactionSuccessBoxAlignment}>
              <div className={styles.verificationSuccessBoxHeadingAlignment}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <path
                    d="M25.1176 28C25.1176 28.7956 24.8 29.5587 24.2347 30.1213C23.6693 30.6839 22.9025 31 22.1029 31H4.01471C3.21516 31 2.44835 30.6839 1.88299 30.1213C1.31762 29.5587 1 28.7956 1 28V4C1 3.20435 1.31762 2.44129 1.88299 1.87868C2.44835 1.31607 3.21516 1 4.01471 1H16.2941L25.1176 9.82353"
                    stroke="#1D8603"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.2938 0.999939V9.82347H25.1173"
                    stroke="#1D8603"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M16.294 17.4707H6.8822" stroke="#1D8603" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M16.294 23.3529H6.8822" stroke="#1D8603" strokeLinecap="round" strokeLinejoin="round" />
                  <path
                    d="M10.4116 11.5882H8.64691H6.8822"
                    stroke="#1D8603"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M25 25C28.3137 25 31 22.3137 31 19C31 15.6863 28.3137 13 25 13C21.6863 13 19 15.6863 19 19C19 22.3137 21.6863 25 25 25Z"
                    stroke="#1D8603"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 18.3877L24.304 21L28 17"
                    stroke="#1D8603"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <div className={styles.verificationSuccessHeadingTextAlignment}>
                  <h4>Verified</h4>
                  <p>June 2, 2023</p>
                </div>
              </div>
              <div className={styles.verificationFlexAlignment}>
                <div className={styles.verificationSuccessDescriptionText}>
                  <p>
                    The verification outcome suggests that your submission doesn't meet the requirements. Kindly verify
                    the accuracy of the provided information.
                  </p>
                  <div className={styles.learnMoreAlignment}>
                    <a>
                      Learn more{' '}
                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                        <path
                          d="M1 7.00012L7 1.00012"
                          stroke="#556EE6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 1.00012H7V7.00012"
                          stroke="#556EE6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>

              <div className={styles.verificationSuccessCloseAlignment}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M18 6L6 18"
                    stroke="#1D8603"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6 6L18 18"
                    stroke="#1D8603"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div> */}

            {/* <div className={styles.withdrawPandingBox}>
              <p>1 Withdrawal Pending</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M5 12H19" stroke="#7A7E88" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path
                  d="M12 5L19 12L12 19"
                  stroke="#7A7E88"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div> */}

            {withdrwalSection()}

            {/* <div className={styles.withdrawButtonAlignment}>
                  <button className={styles.activeButton}>Withdraw</button>
                </div> */}

            {/* <div className={styles.verifiactionFailedBoxAlignment}>
              <div className={styles.verificationFailedBoxHeadingAlignment}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <path
                    d="M25.1176 28C25.1176 28.7956 24.8 29.5587 24.2347 30.1213C23.6693 30.6839 22.9025 31 22.1029 31H4.01471C3.21516 31 2.44835 30.6839 1.88299 30.1213C1.31762 29.5587 1 28.7956 1 28V4C1 3.20435 1.31762 2.44129 1.88299 1.87868C2.44835 1.31607 3.21516 1 4.01471 1H16.2941L25.1176 9.82353"
                    stroke="#E71616"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.2938 0.999939V9.82347H25.1173"
                    stroke="#E71616"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M16.294 17.4707H6.88223" stroke="#E71616" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M16.294 23.3529H6.88223" stroke="#E71616" strokeLinecap="round" strokeLinejoin="round" />
                  <path
                    d="M10.4116 11.5882H8.64693H6.88223"
                    stroke="#E71616"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M25 25C28.3137 25 31 22.3137 31 19C31 15.6863 28.3137 13 25 13C21.6863 13 19 15.6863 19 19C19 22.3137 21.6863 25 25 25Z"
                    stroke="#E71616"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M27 17L23 21" stroke="#E71616" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M23 17L27 21" stroke="#E71616" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                <div className={styles.verificationFailedHeadingTextAlignment}>
                  <h4>Verification Failed </h4>
                  <p>June 2, 2023</p>
                </div>
              </div>
              <div className={styles.verificationFlexAlignment}>
                <div className={styles.verificationFailedDescriptionText}>
                  <p>
                    The verification outcome suggests that your submission doesn't meet the requirements. Kindly verify
                    the accuracy of the provided information.
                  </p>
                  <div className={styles.learnMoreAlignment}>
                    <a>
                      Learn more{' '}
                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                        <path
                          d="M1 7.00012L7 1.00012"
                          stroke="#556EE6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1 1.00012H7V7.00012"
                          stroke="#556EE6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>

              <div className={styles.verificationFailedCloseAlignment}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M18 6L6 18"
                    stroke="#E71616"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6 6L18 18"
                    stroke="#E71616"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div> */}

            {/* <div className={styles.sellerSetUpPaymentBox}>
              <p>For withdrawal eligibility, ensure your payment setup is arranged.</p>
              <div className={styles.sellerPaymentButtonAlignment}>
                <button onClick={() => handleWithdraw()}>Setup Payment</button>
              </div>
              <div className={styles.sellerPaymentBottomDetails}>
                <div className={styles.learnMoreAlignment}>
                  <a>
                    Learn more{' '}
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                      <path d="M1 7.00012L7 1.00012" stroke="#556EE6" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M1 1.00012H7V7.00012" stroke="#556EE6" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </a>
                </div>
                <div className={styles.sellerPaymentOtherDetails}>
                  <div className={styles.otherDetailsAlignment}>
                    <img src="/assets/seller/icons/bluejesctic-logo.svg" alt="logo" />
                  </div>
                  <div className={styles.otherDetailsAlignment}>
                    <img src="/assets/seller/icons/icon1.svg" alt="icon" />
                  </div>
                  <div className={styles.otherDetailsAlignment}>
                    <img src="/assets/seller/icons/connect-icon.svg" alt="connect" />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        {/* Mobile View Withdraw Modal Start */}

        <div
          className={`follow-followingOverlay ${isMobileViewWithdrawModal ? 'open' : ''}`}
          onClick={() => setIsMobileViewWithdrawModal(false)}
        />
        <div className={`follow-following-modal ${isMobileViewWithdrawModal ? 'open' : ''}`}>
          <MobileViewWithdrawModal
            setIsMobileViewWithdrawDetailsModal={setIsMobileViewWithdrawDetailsModal}
            setIsMobileViewWithdrawModal={setIsMobileViewWithdrawModal}
          />
        </div>
        {/* Mobile View Withdraw Modal End */}

        {/* Mobile View Withdraw Details Modal Start */}

        <div
          className={`follow-followingOverlay ${isMobileViewWithdrawDetailsModal ? 'open' : ''}`}
          onClick={() => setIsMobileViewWithdrawDetailsModal(false)}
        />
        <div className={`follow-following-modal ${isMobileViewWithdrawDetailsModal ? 'open' : ''}`}>
          <MobileViewWithdrawDetailsModal
            setIsMobileViewWithdrawDetailsModal={setIsMobileViewWithdrawDetailsModal}
            setIsMobileViewWithdrawPasswordModal={setIsMobileViewWithdrawPasswordModal}
            setIsMobileViewWithdrawOtpModal={setIsMobileViewWithdrawOtpModal}
          />
        </div>
        {/* Mobile View Withdraw Details Modal End */}

        {/* Mobile View Withdraw password Modal Start */}
        {/* {isMobileViewWithdrawPasswordModal && (
          <MobileViewWithdrawPasswordModal
            setIsMobileViewWithdrawPasswordModal={setIsMobileViewWithdrawPasswordModal}
            setIsMobileViewWithdrawRequestModal={setIsMobileViewWithdrawRequestModal}
          />
        )} */}
        {/* Mobile View Withdraw password Modal End */}

        {/* Mobile View Withdraw Otp Modal Start */}

        <div
          className={`follow-followingOverlay ${isMobileViewWithdrawOtpModal ? 'open' : ''}`}
          onClick={() => setIsMobileViewWithdrawOtpModal(false)}
        />
        <div className={`follow-following-modal ${isMobileViewWithdrawOtpModal ? 'open' : ''}`}>
          <MobileViewInputOtpModal setIsMobileViewWithdrawRequestModal={setIsMobileViewWithdrawRequestModal} />
        </div>
        {/* Mobile View Withdraw Otp Modal End */}

        {/* Mobile View Withdraw Details Modal Start */}

        <div
          className={`follow-followingOverlay ${isMobileViewWithdrawRequestModal ? 'open' : ''}`}
          onClick={() => setIsMobileViewWithdrawRequestModal(false)}
        />
        <div className={`follow-following-modal ${isMobileViewWithdrawRequestModal ? 'open' : ''}`}>
          <MobileViewWithdrawRequestModal
            setIsMobileViewWithdrawTransactionDetailsModal={setIsMobileViewWithdrawTransactionDetailsModal}
          />
        </div>
        {/* Mobile View Withdraw Details Modal End */}

        {/* Mobile View Withdraw Details Modal Start */}

        <div
          className={`follow-followingOverlay ${isMobileViewWithdrawTransactionDetailsModal ? 'open' : ''}`}
          onClick={() => setIsMobileViewWithdrawTransactionDetailsModal(false)}
        />
        <div className={`follow-following-modal ${isMobileViewWithdrawTransactionDetailsModal ? 'open' : ''}`}>
          <MobileViewTransactionDetails />
        </div>
        {/* Mobile View Withdraw Details Modal End */}
      </div>
      {/* <Modal
        open={paymentSuccessModal}
        className="my-auto rounded-2xl mt-64"
        wrapClassName="post-add-edit-modal"
        onCancel={handleCancel}
        footer={null}
        closable={false}
        maskStyle={{ zIndex: 9999 }}
        style={{ maxWidth: '382px' }}
        centered
      >
        <div className="bg-white rounded-0 -m-8" style={{ minWidth: '100%', height: '100%' }}>
          <div className={styles.thankYouBoxAlignment}>
            <div className={styles.thankYouRightIcon}>
              <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76" viewBox="0 0 76 76" fill="none">
                <path
                  d="M38 2C18.1177 2 2 18.1177 2 38C2 57.8823 18.1177 74 38 74C57.8823 74 74 57.8823 74 38C74 34.4845 73.4961 31.0868 72.5566 27.875C71.9189 25.6947 71.0804 23.6002 70.0625 21.6127M58.25 8.23106C59.8468 9.31937 61.3515 10.5328 62.75 11.8572C64.1783 13.2099 65.4958 14.6783 66.6875 16.2476"
                  stroke="#4A9E35"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
                <path
                  d="M24 36L35.3667 47L55 28"
                  stroke="#4A9E35"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className={styles.thankYouDescription}>
              <h4>Thank you!</h4>
              <p>
                Your payment setup is under review. Verification will take up to 5 business days. Withdrawal access is
                available immediately after your account is verified.
              </p>
            </div>
            <div className={styles.closeButtonAlignment}>
              <button>Close</button>
            </div>
          </div>
        </div>
      </Modal> */}
    </>
  )
}
