import React from 'react'
import styles from './MobileViewTransactionDetails.module.scss'
export default function MobileViewTransactionDetails() {
  return (
    <div className={styles.mobileViewTransactionDetailsSection}>
      <div className={styles.mobileViewTransactionDetailsHeading}>
        <h4>Transaction Detail</h4>
        <a>Close</a>
      </div>

      <div className={styles.mobileViewTransactionDetailsBody}>
        <div className={styles.mobileViewTransactionDetailsStatusDetailsAlignment}>
          <div className={styles.mobileViewTransactionIcon}>
            <img src="/assets/seller/icons/withdraw-icon.png" alt="withdraw icon" />
          </div>
          <div className={styles.mobileViewTransactionAllDetailsALignment}>
            <h4>WITHDRAWAL</h4>
            <p>ID 27746833648 </p>
            <div className={styles.mobileViewTransactionStatusAlignment}>
              <span>Pending</span>
            </div>
          </div>
        </div>
        <div className={styles.mobileViewTransactionSlipAllDetailsAlignment}>
          <div className={styles.mobileViewTransactionSlipChildDetails}>
            <div className={styles.slipHeadingAlignment}>
              <label>Transfer Amount</label>
            </div>

            <div className={styles.rightSideAlignment}>
              <h6>$2,455</h6>
            </div>
          </div>
          <div className={styles.mobileViewTransactionSlipChildDetails}>
            <div className={styles.slipHeadingAlignment}>
              <label>Date and Time</label>
            </div>

            <div className={styles.rightSideAlignment}>
              <h6>Sunday,</h6>
              <h6>Aug 13, 2023</h6>
              <p>9:14 AM</p>
            </div>
          </div>
          <div className={styles.mobileViewTransactionSlipChildDetails}>
            <div className={styles.slipHeadingAlignment}>
              <label>Estimated Deposit </label>
            </div>

            <div className={styles.rightSideAlignment}>
              <h6>Tuesday,</h6>
              <h6>August 15, 2023</h6>
            </div>
          </div>
          <div className={styles.mobileViewTransactionSlipChildDetails}>
            <div className={styles.slipHeadingAlignment}>
              <label>Withdrawal To</label>
            </div>

            <div className={styles.rightSideAlignment}>
              <h6>Esther Howard</h6>
              <p>Bank of America - x564</p>
            </div>
          </div>
          <div className={styles.mobileViewTransactionSlipChildDetails}>
            <div className={styles.slipHeadingAlignment}>
              <label>Note</label>
            </div>

            <div className={styles.rightSideAlignment}>
              <h6>-</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
