import { gql } from '@apollo/client'

const GET_SINGLE_LIKE = gql`
  query GetSingleLike($parentId: Int!, $likeFor: String!) {
    getSingleLike(parent_id: $parentId, like_for: $likeFor) {
      data {
        isLike
        like_id
      }
      message
      success
    }
  }
`
export default GET_SINGLE_LIKE
