import React from 'react'
import { useAuth } from 'src/utils/auth'
import PostComments from './PostComments'

const PostCommetSection = (props) => {
  const { user } = useAuth()
  const { isClose, setShowMore, showMore, post, commentId } = props

  return (
    <PostComments
      setShowMore={setShowMore}
      showMore={showMore}
      postId={post?.id}
      backgroundColor="#f8f8f8"
      commentId={commentId}
      isClose={isClose}
      isProductComment={false}
      isBottonBorderVisible={false}
      isShowMore={true}
    />
  )
}

export default PostCommetSection
