import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from '@apollo/client'
import AllProduct from '../../ProductPage/AllProduct'
import StoreMainSection from './StoreMainSection'
import FeaturedProduct from './FeaturedProduct/FeaturedProduct'
import AboutStore from './StoreAbout'
import MediaSection from './StoreMedia'
import StoreEvent from './StoreEvent'
import StoreFeed from './StoreFeed'
import GET_STORE_BY_NAME from 'src/graphql/queries/getStoreByName'
import Loader from '../../UI/Loader'
import { useAppDispatch } from 'src/store/store'
import { fetchUserProfilModalScroll } from 'src/store/slices/userProfileonScrollSlice'
import StoreProductSection from './StoreProductSection'

export default function StoreProductModal(props) {
  const { storeData, slug } = props
  const dispatch = useAppDispatch()
  const myElementRef = useRef(null)
  const { loading, error, data, refetch } = useQuery(GET_STORE_BY_NAME, {
    variables: { storeName: storeData?.name },
  })

  const [tab, setTab] = useState('product')

  useEffect(() => {
    refetch()
  }, [])

  const handleScroll = () => {
    const scrollTop = myElementRef?.current?.scrollTop
    dispatch(
      fetchUserProfilModalScroll({
        scroll: scrollTop,
      }),
    )
  }

  // if (loading) return <Loader />
  // if (error) return <p>`Error! ${error.message}`</p>

  return (
    <div className="product-store-modal-body" ref={myElementRef} onScroll={handleScroll}>
      {/* <StoreMainSection setTab={setTab} store={data?.getSingleStore} tab={tab} slug={slug}/> */}
      {tab === 'product' && <StoreProductSection storeData={storeData} slug={slug} />}
      {tab === 'about' && <AboutStore store={data?.getSingleStore} />}
      {tab === 'feed' && <StoreFeed store={data?.getSingleStore} />}
      {tab === 'media' && <MediaSection store={data?.getSingleStore} />}
      {tab === 'event' && <StoreEvent storeData={storeData} />}
    </div>
  )
}
