import React, { useState } from 'react'
import DiyRoomDetailsModal from './DiyRoomDetailsModal'
import { fetchApplianceCategory } from 'src/store/slices/manageApplianceCategorySlice'
import { useAppDispatch } from 'src/store/store'
import Link from 'next/link'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useSelector } from 'react-redux'

export default function DIYRoomArtCarft() {
  const dispatch = useAppDispatch()
  const [DiyImgModal, setDiyImgModal] = useState(false)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)

  return (
    <div className="art-craft-diy-room-store-details-alignment">
      <div className="mobile-view-art-craft-back-alignment">
        <div
          className="art-craft-back_details-alignment"
          onClick={() => {
            dispatch(fetchApplianceCategory({ modal: '', isOpen: false, id: 0 }))
          }}
        >
          <div>
            <img src="/assets/icon/left-icon.svg" alt="back-page" />
          </div>
          <p>Home...</p>
        </div>
        <div className="mobile-heading-alignment">
          <h4>DIY Room</h4>
        </div>
      </div>
      <div className="art-craft-diy-room-store-banner-alignment">
        <img src="/assets/img/artCarft/diyroom-bg.png" alt="DiyRoomBanner" />
        <img
          src="/assets/img/artCarft/MobileViewIdeaBanner.png"
          alt="MobileNowTrendingBanner"
          className="mobile-view-banner"
        />
        <div className="art-craft-diy-room-store-banner-positiona-alignment">
          <div className="container">
            <div className="art-craft-diy-room-store-banner-flex-alignment">
              <Link
                href={{
                  pathname: '/art-craft',
                }}
                scroll={false}
              >
                <div
                  className="left-arrow-alignment"
                  onClick={() => {
                    dispatch(fetchApplianceCategory({ modal: '', isOpen: false, id: 0 }))
                  }}
                >
                  <img src="/assets/icon/leftArrow.svg" alt="left arrow" className="cursor-pointer " />
                </div>
              </Link>
              <div className="art-craft-diy-room-store-banner-details">
                <h4>DIY Room</h4>
                <p>Creating something beautiful with your own hands is the ultimate form of self-expression</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="art-craft-diy-tab-alignment">
        <ul>
          <li>All</li>
          <li>paper Craft</li>
          <li>Ceramics</li>
          <li>Wall Art</li>
          <li>Miniature</li>
          <li>Lamps</li>
          <li>Collage</li>
          <li>Wooden art</li>
          <li>Metal art</li>
        </ul>
      </div>

      <div className="art-craft-diy-body-details-alignment">
        <div className="container">
          <div className="art-craft-diy-body-grid-details-alignment">
            <div className="art-craft-diy-body-gridItem-details-alignment">
              <div className="art-craft-diy-box-details-alignment">
                {/* WEB_DIV+++++++++++++++++++++++++++++++ */}
                <div className="art-carft-web-view-details-alignment">
                  <div className="art-carft-diy-images-grid-alignment" onClick={() => setDiyImgModal(true)}>
                    <div className="art-carft-diy-images-gridItem-alignment">
                      <div className="art-craft-diy-first-img-alignment">
                        <img src="/assets/img/artCarft/dry1.png" alt="dry img" />
                      </div>
                    </div>
                    <div className="art-carft-diy-images-gridItem-alignment">
                      <div className="art-craft-second-part-alignment">
                        <div className="art-carft-child-img-alignment">
                          <img src="/assets/img/artCarft/dry2.png" alt="dry img" />
                        </div>
                        <div className="art-carft-child-img-alignment">
                          <img src="/assets/img/artCarft/dry3.png" alt="dry img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* MOBILE_DIV+++++++++++++++++++++++++++++++ */}
                <div className="art-carft-mobile-view-details-alignment">
                  <div
                    className="art-carft-diy-images-grid-alignment"
                    onClick={() =>
                      dispatch(
                        fetchMobileModalData({
                          allModalData: [
                            ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                            { modal: 'DIYroomModal', isOpen: true, id: 0, transform: 8 },
                          ],
                          lastModalData: fetchMobileModal?.lastModalData,
                        }),
                      )
                    }
                  >
                    <div className="art-carft-diy-images-gridItem-alignment">
                      <div className="art-craft-diy-first-img-alignment">
                        <img src="/assets/img/artCarft/dry1.png" alt="dry img" />
                      </div>
                    </div>
                    <div className="art-carft-diy-images-gridItem-alignment">
                      <div className="art-craft-second-part-alignment">
                        <div className="art-carft-child-img-alignment">
                          <img src="/assets/img/artCarft/dry2.png" alt="dry img" />
                        </div>
                        <div className="art-carft-child-img-alignment">
                          <img src="/assets/img/artCarft/dry3.png" alt="dry img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="art-craft-child-details-alignment">
                  <h4>Dreamy Paper Cut Lamp</h4>
                  <div className="art-craft-auther-details-alignment">
                    <span>by</span>
                    <div className="art-craft-author-profile-details-alignment">
                      <div className="author-profile-img-alignment">
                        <img src="/assets/img/artCarft/articsImg1.png" alt="artics img" />
                      </div>
                      <h6>Annete Black</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="art-craft-diy-body-gridItem-details-alignment">
              <div className="art-craft-diy-box-details-alignment second-color">
                <div className="art-carft-diy-images-grid-alignment" onClick={() => setDiyImgModal(true)}>
                  <div className="art-carft-diy-images-gridItem-alignment">
                    <div className="art-craft-diy-first-img-alignment">
                      <img src="/assets/img/artCarft/dry4.png" alt="dry img" />
                    </div>
                  </div>
                  <div className="art-carft-diy-images-gridItem-alignment">
                    <div className="art-craft-second-part-alignment">
                      <div className="art-carft-child-img-alignment">
                        <img src="/assets/img/artCarft/dry5.png" alt="dry img" />
                      </div>
                      <div className="art-carft-child-img-alignment">
                        <img src="/assets/img/artCarft/dry6.png" alt="dry img" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="art-craft-child-details-alignment">
                  <h4>Dreamy Paper Cut Lamp</h4>
                  <div className="art-craft-auther-details-alignment">
                    <span>by</span>
                    <div className="art-craft-author-profile-details-alignment">
                      <div className="author-profile-img-alignment">
                        <img src="/assets/img/artCarft/articsImg1.png" alt="artics img" />
                      </div>
                      <h6>Annete Black</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="art-craft-diy-body-gridItem-details-alignment">
              <div className="art-craft-diy-box-details-alignment third-color ">
                <div className="art-carft-diy-images-grid-alignment" onClick={() => setDiyImgModal(true)}>
                  <div className="art-carft-diy-images-gridItem-alignment">
                    <div className="art-craft-diy-first-img-alignment">
                      <img src="/assets/img/artCarft/dry7.png" alt="dry img" />
                    </div>
                  </div>
                  <div className="art-carft-diy-images-gridItem-alignment">
                    <div className="art-craft-second-part-alignment">
                      <div className="art-carft-child-img-alignment">
                        <img src="/assets/img/artCarft/dry8.png" alt="dry img" />
                      </div>
                      <div className="art-carft-child-img-alignment">
                        <img src="/assets/img/artCarft/dry9.png" alt="dry img" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="art-craft-child-details-alignment">
                  <h4>Dreamy Paper Cut Lamp</h4>
                  <div className="art-craft-auther-details-alignment">
                    <span>by</span>
                    <div className="art-craft-author-profile-details-alignment">
                      <div className="author-profile-img-alignment">
                        <img src="/assets/img/artCarft/articsImg1.png" alt="artics img" />
                      </div>
                      <h6>Annete Black</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="art-craft-diy-body-gridItem-details-alignment">
              <div className="art-craft-diy-box-details-alignment">
                <div className="art-carft-diy-images-grid-alignment" onClick={() => setDiyImgModal(true)}>
                  <div className="art-carft-diy-images-gridItem-alignment">
                    <div className="art-craft-diy-first-img-alignment">
                      <img src="/assets/img/artCarft/dry1.png" alt="dry img" />
                    </div>
                  </div>
                  <div className="art-carft-diy-images-gridItem-alignment">
                    <div className="art-craft-second-part-alignment">
                      <div className="art-carft-child-img-alignment">
                        <img src="/assets/img/artCarft/dry2.png" alt="dry img" />
                      </div>
                      <div className="art-carft-child-img-alignment">
                        <img src="/assets/img/artCarft/dry3.png" alt="dry img" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="art-craft-child-details-alignment">
                  <h4>Dreamy Paper Cut Lamp</h4>
                  <div className="art-craft-auther-details-alignment">
                    <span>by</span>
                    <div className="art-craft-author-profile-details-alignment">
                      <div className="author-profile-img-alignment">
                        <img src="/assets/img/artCarft/articsImg1.png" alt="artics img" />
                      </div>
                      <h6>Annete Black</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="art-craft-diy-body-gridItem-details-alignment">
              <div className="art-craft-diy-box-details-alignment second-color">
                <div className="art-carft-diy-images-grid-alignment" onClick={() => setDiyImgModal(true)}>
                  <div className="art-carft-diy-images-gridItem-alignment">
                    <div className="art-craft-diy-first-img-alignment">
                      <img src="/assets/img/artCarft/dry4.png" alt="dry img" />
                    </div>
                  </div>
                  <div className="art-carft-diy-images-gridItem-alignment">
                    <div className="art-craft-second-part-alignment">
                      <div className="art-carft-child-img-alignment">
                        <img src="/assets/img/artCarft/dry5.png" alt="dry img" />
                      </div>
                      <div className="art-carft-child-img-alignment">
                        <img src="/assets/img/artCarft/dry6.png" alt="dry img" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="art-craft-child-details-alignment">
                  <h4>Dreamy Paper Cut Lamp</h4>
                  <div className="art-craft-auther-details-alignment">
                    <span>by</span>
                    <div className="art-craft-author-profile-details-alignment">
                      <div className="author-profile-img-alignment">
                        <img src="/assets/img/artCarft/articsImg1.png" alt="artics img" />
                      </div>
                      <h6>Annete Black</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="art-craft-diy-body-gridItem-details-alignment">
              <div className="art-craft-diy-box-details-alignment third-color ">
                <div className="art-carft-diy-images-grid-alignment" onClick={() => setDiyImgModal(true)}>
                  <div className="art-carft-diy-images-gridItem-alignment">
                    <div className="art-craft-diy-first-img-alignment">
                      <img src="/assets/img/artCarft/dry7.png" alt="dry img" />
                    </div>
                  </div>
                  <div className="art-carft-diy-images-gridItem-alignment">
                    <div className="art-craft-second-part-alignment">
                      <div className="art-carft-child-img-alignment">
                        <img src="/assets/img/artCarft/dry8.png" alt="dry img" />
                      </div>
                      <div className="art-carft-child-img-alignment">
                        <img src="/assets/img/artCarft/dry9.png" alt="dry img" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="art-craft-child-details-alignment">
                  <h4>Dreamy Paper Cut Lamp</h4>
                  <div className="art-craft-auther-details-alignment">
                    <span>by</span>
                    <div className="art-craft-author-profile-details-alignment">
                      <div className="author-profile-img-alignment">
                        <img src="/assets/img/artCarft/articsImg1.png" alt="artics img" />
                      </div>
                      <h6>Annete Black</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DiyRoomDetailsModal DiyImgModal={DiyImgModal} setDiyImgModal={setDiyImgModal} />
      {/* </div> */}
    </div>
  )
}
