export const getCroppedImg = async (imageUrl) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = imageUrl

    img.onload = () => {
      const targetHeight = img.height
      const targetWidth = targetHeight * (4 / 6)

      const offsetX = (img.width - targetWidth) / 2
      const offsetY = (img.height - targetHeight) / 2
      const canvas = document.createElement('canvas')
      canvas.width = targetWidth
      canvas.height = targetHeight

      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, offsetX, offsetY, targetWidth, targetHeight, 0, 0, targetWidth, targetHeight)
      // ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

      canvas.toBlob((blob) => {
        const croppedImage = new File([blob], `cropped_${img.name}`, {
          type: blob.type,
        })
        resolve(canvas.toDataURL())
      }, img.type)
    }

    img.onerror = (error) => {
      reject(error)
    }
  })
}
const createImage = async (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

export const compressImages = async (item) => {
  const file = item // get the file
  const blobURL = URL.createObjectURL(file)
  const image = await createImage(blobURL)
  const [newWidth, newHeight] = calculateSize(image, image?.width / 2, image?.height / 2)
  const canvas = document.createElement('canvas')
  canvas.width = newWidth
  canvas.height = newHeight
  const ctx = canvas.getContext('2d')
  ctx.drawImage(image, 0, 0, newWidth, newHeight)

  let finalImages = dataURLtoFile(canvas.toDataURL('image/jpeg'), item?.name)
  return await finalImages
  // };
}
const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)?.[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}
function calculateSize(img, maxWidth, maxHeight) {
  let width = img.width
  let height = img.height

  // calculate the width and height, constraining the proportions
  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width)
      width = maxWidth
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height)
      height = maxHeight
    }
  }
  return [width, height]
}
