import React from 'react'
import styles from './CategorySpecialOffers.module.scss'
import NewArrivalProduct from 'src/components/Category/ShopByCategoryDetails/NewArrivalProduct'
export default function CategorySpecialOffers() {
  return (
    <div className={styles.categorySpecialOfferdProductSection}>
      <div className={styles.categorySpecialOfferheading}>
        <h4>Special Offers</h4>
      </div>

      <div className={styles.categorySpecialOfferFilter}>
        <ul>
          <li className={styles.activeFIlter}>All </li>
          <li>Hats</li>
          <li>Glasses</li>
          <li>Watches</li>
          <li>Wallets</li>
          <li>Belts</li>
          <li>Ties</li>
          <li>Gloves</li>
          <li>Cufflinks</li>
        </ul>
      </div>

      <div className={styles.categorySpecialOfferBodyAlignment}>
        <div className={styles.categoryGridAlignment}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((_, index) => {
            return (
              <div className={styles.categoryGridItem} key={`a${index}`}>
                <NewArrivalProduct product={{}} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
