import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import GET_PRODUCTS_BY_CATEGORY from 'src/graphql/queries/getAllProductByCategory'
import client1 from 'src/utils/apolloClient'

interface fetchProductData {
  loading: true | false
  error: {} | null
  called: true | false
  allPost: []
  groupPosts: []
  userPosts: []
}

const initialState = {
  loading: false,
  error: null,
  currentStep: '',
  allPost: [],
  groupPosts: [],
  userPosts: [],
  called: false,
} as fetchProductData

export const fetchProductByCategory = createAsyncThunk('fetchClubs/fetchProductByCategory', async (post: any) => {
  const { data } = await client1.query({
    query: GET_PRODUCTS_BY_CATEGORY,
    variables:{
      categoryId: post?.categoryId,
      subCategoryId: post?.subCategoryId,
      childSubCategoryId: post?.childSubCategoryId,
      limit: post?.limit,
      page: post?.page,
    },
    fetchPolicy: 'network-only',
  })
  return data?.getAllProductByCategory?.data
})

export const fetchTrendingProducts = createAsyncThunk('fetchClubs/fetchTrendingProducts', async (post: any) => {
  const { data } = await client1.query({
    query: GET_PRODUCTS_BY_CATEGORY,
    variables:{
      categoryId: post?.categoryId,
      subCategoryId: post?.subCategoryId,
      childSubCategoryId: post?.childSubCategoryId,
      limit: post?.limit,
      page: post?.page,
    },
    fetchPolicy: 'network-only',
  })
  return data?.getAllProductByCategory?.data
})

export const highlyRatedProducts = createAsyncThunk('fetchClubs/highlyRatedProducts', async (post: any) => {
  const { data } = await client1.query({
    query: GET_PRODUCTS_BY_CATEGORY,
    variables:{
      categoryId: post?.categoryId,
      subCategoryId: post?.subCategoryId,
      childSubCategoryId: post?.childSubCategoryId,
      limit: post?.limit,
      page: post?.page,
    },
    fetchPolicy: 'network-only',
  })
  return data?.getAllProductByCategory?.data
})

const fetchProductData = createSlice({
  name: 'fetchClubs',
  initialState,
  reducers: {
    setCurrentStep: (state: any, action) => {
      state.allPost = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductByCategory.pending, (state, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchProductByCategory.fulfilled, (state, action) => {
      state.loading = 'success'
      state.allProducts = action?.payload
    })
    builder.addCase(fetchProductByCategory.rejected, (state, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })

    builder.addCase(fetchTrendingProducts.pending, (state, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchTrendingProducts.fulfilled, (state, action) => {
      state.loading = 'success'
      state.allTrendingProducts = action?.payload
    })
    builder.addCase(fetchTrendingProducts.rejected, (state, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })

    builder.addCase(highlyRatedProducts.pending, (state, action) => {
      state.loading = 'loading'
    })
    builder.addCase(highlyRatedProducts.fulfilled, (state, action) => {
      state.loading = 'success'
      state.highlyRatedProduct = action?.payload
    })
    builder.addCase(highlyRatedProducts.rejected, (state, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })
  },
})

// export var _actions = fetchProductData.actions
export const { setCurrentStep } = fetchProductData.actions
export default fetchProductData.reducer
