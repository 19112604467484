import React, { useState } from 'react'
import styles from './AddVariant.module.scss'
import AddSizeModal from './AddSizeModal/AddSizeModal'
import AddColorModal from './AddColorModal'
import AddOtherVariantModal from './AddOtherVariantModal'
import classNames from 'classnames'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
export default function AddVariant() {
  const dispatch = useAppDispatch()
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  const openAddSizeModal = () => {
    let sizeFilter = addProductDetailSlice?.id?.variant?.allVariant?.filter((size) => size.name === 'SIZE')
    if (sizeFilter?.length === 0) {
      // setAddSizeModalOpen(true)
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            variant: {
              ...addProductDetailSlice?.id?.variant,
              isAddSizeModalOpen: true,
            },
          },
        }),
      )
    }
  }

  const openAddColorModal = () => {
    let sizeFilter = addProductDetailSlice?.id?.variant?.allVariant?.filter((size) => size.name === 'Color')
    if (sizeFilter?.length === 0) {
      // setAddColorModalOpen(true)
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            variant: {
              ...addProductDetailSlice?.id?.variant,
              isAddColorModalOpen: true,
            },
          },
        }),
      )
    }
  }

  const openAddOtherModal = () => {
    // setColorArray([''])
    // setAddOtherVariantModalOpen(true)
    dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          variant: {
            ...addProductDetailSlice?.id?.variant,
            colorArray: [''],
            isAddOtherVariantModalOpen: true,
          },
        },
      }),
    )
  }

  const handleDelete = (index) => {
    // setAllVariant(addProductDetailSlice?.id?.variant?.allVariant.filter((data, ind) => ind !== index))
    // setAllFinalVariant(addProductDetailSlice?.id?.variant?.allVariant.filter((data, ind) => ind !== index))
    dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          variant: {
            ...addProductDetailSlice?.id?.variant,
            allVariant: addProductDetailSlice?.id?.variant?.allVariant.filter((data, ind) => ind !== index),
            allFinalVariant: addProductDetailSlice?.id?.variant?.allVariant.filter((data, ind) => ind !== index),
            colorCodeArray: addProductDetailSlice?.id?.variant?.colorCodeArray.filter((data, ind) => ind !== index),
          },
        },
      }),
    )
  }

  const editSizevariant = (variant, index) => {
    if (variant?.name === 'SIZE') {
      // setDataEdit({ size: { ...variant, data: [...variant.data, ''] }, index: index })
      // setAddSizeModalOpen(true)
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            variant: {
              ...addProductDetailSlice?.id?.variant,
              dataEdit: { size: { ...variant, data: [...variant.data, ''] }, index: index },
              isAddSizeModalOpen: true,
            },
          },
        }),
      )
    } else if (variant?.name === 'Color') {
      // setDataEdit({ color: { ...variant, data: [...variant.data, { name: '', colorCode: '' }] }, index: index })
      // setAddColorModalOpen(true)
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            variant: {
              ...addProductDetailSlice?.id?.variant,
              dataEdit: { color: { ...variant, data: [...variant.data, { name: '', colorCode: '' }] }, index: index },
              isAddColorModalOpen: true,
            },
          },
        }),
      )
    } else {
      // setDataEdit({ other: { ...variant, data: [...variant.data, ''] }, index: index })
      // setAddOtherVariantModalOpen(true)
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            variant: {
              ...addProductDetailSlice?.id?.variant,
              dataEdit: { other: { ...variant, data: [...variant.data, ''] }, index: index },
              isAddOtherVariantModalOpen: true,
            },
          },
        }),
      )
    }
  }

  const outputArray = []

  function generateVariants(currentVariant, currentIndex, finalData, outputArray) {
    if (currentIndex === finalData?.length) {
      outputArray.push({
        total_variant: currentVariant,
        // weightValue: '200',
        // weightUnit: 'pound',
        // length: 2,
        // width: 2,
        // height: 2,
        // unit: 'inch',
        isTaxable: false,
        isChecked: false,
        weightUnit: 'pound',
        unit: 'inch',
        old_inventory_quantity: 0,
        inventory_quantity: 0,
        price: '0',
        sku: '0',
        // grams: 530,
        // barcode: '5151511565656',
      })
      return
    }

    const currentVariants = finalData[currentIndex]?.data
    if (currentVariants && Symbol.iterator in Object(currentVariants)) {
      for (const variant of currentVariants) {
        const newVariant = [...currentVariant, variant]
        generateVariants(newVariant, currentIndex + 1, finalData, outputArray)
      }
    } else {
      // If data is not defined, proceed to the next level without adding any variants
      generateVariants(currentVariant, currentIndex + 1, finalData, outputArray)
    }
  }

  const submitFinalVariant = async () => {
    // if (variantValidate()) {
    // setAllFinalVariant(allVariant)
    // toggle(false)
    let sizeVariant = addProductDetailSlice?.id?.variant?.allVariant?.filter((size) => size?.name === 'SIZE')
    let colorVariant = addProductDetailSlice?.id?.variant?.allVariant?.filter((size) => size?.name === 'Color')
    let otherVariant = addProductDetailSlice?.id?.variant?.allVariant?.filter(
      (size) => size?.name !== 'SIZE' && size?.name !== 'Color',
    )

    const updatedVariant = {
      other: [
        { name: 'size', data: sizeVariant[0]?.data },
        // { name: 'color', data: colorVariant[0]?.data.map((colorObj) => colorObj.name) },
        { name: 'color', data: colorVariant[0]?.data },
      ],
    }

    const sizeAndColorArray = [
      { name: 'size', data: updatedVariant.other[0].data },
      { name: 'color', data: updatedVariant.other[1].data },
      ...otherVariant,
    ]

    // Create the new "variant" object with the "size" and "color" as separate objects
    const finalVariant = sizeAndColorArray.concat(updatedVariant.other.slice(2))
    const noColorCode = {
      other: [
        { name: 'size', data: sizeVariant[0]?.data },
        { name: 'color', data: colorVariant[0]?.data.map((colorObj) => colorObj.name) },
        // { name: 'color', data: colorVariant[0]?.data },
      ],
    }

    const sizeAndNoCode = [
      { name: 'size', data: noColorCode.other[0].data },
      { name: 'color', data: noColorCode.other[1].data },
      ...otherVariant,
    ]

    const finalVariantNoCode = sizeAndNoCode.concat(noColorCode.other.slice(2))
    await generateVariants([], 0, finalVariantNoCode, outputArray)

    await dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          variant: {
            ...addProductDetailSlice?.id?.variant,
            allFinalVariant: finalVariant,
            allOptions: outputArray,
            isAddVariantModal: false,
          },
        },
      }),
    )
    // }
  }

  // const submitFinalVariant = async () => {
  //   let multipleArray = [
  //     { name: "color", data: ["red", "blue", "green"] },
  //     { name: "size", data: ["S", "M", "L"] },
  //     { name: "weight", data: ["100", "200", "300"] },
  //   ];
  //   let array = multipleArray
  //     .map((variant) => {
  //       return [variant.data];
  //     })
  //     .flat();
  // };

  // const combineAll = (array) => {
  //   const res = [];
  //   let max = array.length - 1;
  //   const helper = (arr, i) => {
  //     for (let j = 0, l = array[i].length; j < l; j++) {
  //       let copy = arr.slice(0);
  //       copy.push(array[i][j]);
  //       if (i == max) res.push(copy);
  //       else helper(copy, i + 1);
  //     }
  //   };
  //   helper([], 0);
  //   return res;
  // };

  // function getCombn(arr) {
  //   if (arr.length == 1) {
  //     return arr[0];
  //   } else {
  //     var ans = [];
  //     var otherCases = getCombn(arr.slice(1));
  //     for (var i = 0; i < otherCases.length; i++) {
  //       for (var j = 0; j < arr[0].length; j++) {
  //         ans.push({ data: arr[0][j] , value: otherCases[i]  });
  //       }
  //     }
  //     return ans;
  //   }
  // }

  // function combinations(multipleArray) {
  //   let results = [];
  //   for (let i = 0; i < multipleArray[0].data.length; i++) {
  //     for (let j = 0; j < multipleArray[1].data.length; j++) {
  //       for (let k = 0; k < multipleArray[2].data.length; k++) {
  //         let object = {
  //           [multipleArray[0].name]: multipleArray[0].data[i],
  //           [multipleArray[1].name]: multipleArray[1].data[j],
  //           [multipleArray[2].name]: multipleArray[2].data[k],
  //         };
  //         results.push(object);
  //       }
  //     }
  //   }
  //   return results;
  // }

  //===================================================REDUCE METHOD ==============================
  //   var parts = [
  //     ["red", "blue", "green"],
  //     ["S", "M", "L"],
  //     ["100", "200", "300"],
  //   ],
  //   result = parts.reduce((a, b) => a.reduce((r, v) => r.concat(b.map((w) => [].concat(v, w))), []));

  // if (m < multipleArray.length - 1) {
  //   // recursive(multipleArray[m].data.length, multipleArray[m].data);
  // }

  // let data = multipleArray.reduce((a, object, index, total) => {}, []);

  const variantValidate = () => {
    let errors = {}
    let formIsValid = true
    let sizeFilter = addProductDetailSlice?.id?.variant?.allVariant?.filter((vsize) => vsize?.name === 'SIZE')
    let colorFilter = addProductDetailSlice?.id?.variant?.allVariant?.filter((vsize) => vsize?.name === 'Color')
    if (sizeFilter?.length === 0) {
      formIsValid = false
      errors['size'] = 'Please Add Size Variant*'
    }
    if (colorFilter?.length === 0) {
      formIsValid = false
      errors['color'] = 'Please Add Color Variant*'
    }
    // setVariantError(errors)
    // dispatch(
    //   manageAddProductDetail({
    //     modal: '',
    //     isOpen: false,
    //     id: {
    //       ...addProductDetailSlice?.id,
    //       variant: {
    //         ...addProductDetailSlice?.id?.variant,
    //         variantError: errors,
    //       },
    //     },
    //   }),
    // )
    return formIsValid
  }

  const handleCancel = () => {
    dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          variant: {
            ...addProductDetailSlice?.id?.variant,
            isAddVariantModal: false,
          },
        },
      }),
    )
  }

  return (
    <>
      <div className={styles.addVariantModalAlignment}>
        <div className={styles.modalWrapper}>
          <div className={styles.modalBoxAlignment}>
            <div className={styles.modalHeaderAlignment}>
              <h3>Add Variant</h3>
              <div className={styles.headerButtonAlignment}>
                <div className={styles.cancleButtonAlignment} onClick={() => handleCancel()}>
                  <button>Cancel</button>
                </div>
                <div
                  className={classNames(styles.saveButtonAlignment, styles.active)}
                  onClick={() => submitFinalVariant()}
                >
                  <button>Save and Close</button>
                </div>
              </div>
            </div>
            {addProductDetailSlice?.id?.variant?.allVariant?.map((variant, index) => {
              let joinData =
                variant.name === 'Color'
                  ? variant?.data?.map((data) => data?.name).join(', ')
                  : variant?.data?.map((data) => data).join(', ')
              return (
                <div className={styles.sizeColorContainer}>
                  <div className={styles.nameVariantSection}>
                    <div className={styles.sizeTitle}>
                      <span>{variant.name}</span>{' '}
                    </div>
                    <div className={styles.sizeInput}>
                      <span>{joinData?.length > 43 ? joinData.substring(0, 43) + '...' : joinData}</span>
                    </div>
                  </div>
                  <div className={styles.removeEditSection}>
                    <div className={styles.removeVariant} onClick={() => handleDelete(index)}>
                      <span>Remove</span>
                    </div>
                    <div className={styles.editVariant} onClick={() => editSizevariant(variant, index)}>
                      <span>Edit</span>
                    </div>

                    <div className={styles.mobieViewEditVariant} onClick={() => editSizevariant(variant, index)}>
                      <span>m Edit</span>
                    </div>
                  </div>
                </div>
              )
            })}
            <div className={styles.modalBodyAlignment}>
              <div className={styles.variantGridAlignment}>
                <div className={styles.variantGridItemAlignment} onClick={() => openAddSizeModal()}>
                  <span style={{ color: 'red' }}>{addProductDetailSlice?.id?.variant?.variantError['size']}</span>
                  <div className={styles.iconsAlignment}>
                    <img src="/assets/seller/icons/SizeIcon.svg" alt="SizeIcon" />
                  </div>
                  <p>Size </p>
                </div>
                <div className={styles.variantGridItemAlignment} onClick={() => openAddColorModal()}>
                  <span style={{ color: 'red' }}>{addProductDetailSlice?.id?.variant?.variantError['color']}</span>
                  <div className={styles.iconsAlignment}>
                    <img src="/assets/seller/img/round-pic.png" alt="ColorIcon" />
                  </div>
                  <p>Color</p>
                </div>
                <div className={styles.variantGridItemAlignment} onClick={() => openAddOtherModal()}>
                  <p>Other</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
