import React from 'react'
import DropdownIcon from '../../../../../../../public/assets/icon/downArrow.svg'
import SearchIcon from '../../../../../../../public/assets/icon/search-icon-grey.svg'
import NoFollowingImg from '../../../../../../../public/assets/img/no-following-img.png'
import NoFollowImg from '../../../../../../../public/assets/img/no-flollows-dat.png'
import NoLikeImg from '../../../../../../../public/assets/img/no-like.png'
import DECLINE_FRIEND_REQUEST from 'src/graphql/mutations/declineFriendRequest'
import GET_USER_BY_NAME from 'src/graphql/queries/getUserByName'
import SEND_FRIEND_REQUEST from 'src/graphql/mutations/sendFriendRequest'
import { useMutation } from '@apollo/client'
import { useAuth } from 'src/utils/auth'
import GET_STORE_BY_NAME from 'src/graphql/queries/getStoreByName'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAppDispatch } from 'src/store/store'
import Link from 'next/link'
import { fetchUserMobileModalData } from 'src/store/slices/fetchUserMobileModalData'
import { useSelector } from 'react-redux'

export default function UserProfileFollowersModalMobile(props) {
  const { userInfo, type, userId, storeId, storeName } = props
  const { user }: any = useAuth()
  const dispatch = useAppDispatch()
  const fetchMobileModal = useSelector((state: any) => state?.userMobileModal?.data)

  const [declineFriendRequest] = useMutation(DECLINE_FRIEND_REQUEST, {
    onCompleted: () => {},
    onError: (error) => {},
  })

  const refetchQuery = () => {
    return [
      {
        query: GET_USER_BY_NAME,
        variables: { id: parseInt(userId) },
      },
      {
        query: GET_STORE_BY_NAME,
        variables: { storeName: storeName },
      },
    ]
  }

  let filterAccountUser =
    type === 'Followers'
      ? userId !== user?.id
        ? userInfo?.followers?.filter((flwer) => flwer?.user?.id !== user?.id)
        : userInfo?.followers
      : userId !== user?.id
      ? userInfo?.followings?.filter((flwing) => flwing?.user?.id !== user?.id)
      : userInfo?.followings

  let ownUser = (type === 'Followers' ? userInfo?.followers : userInfo?.followings)?.filter(
    (followers) => followers?.user?.id === user?.id,
  )

  const [sendFriendRequest] = useMutation(SEND_FRIEND_REQUEST, {
    onCompleted: ({ sendFriendRequest }) => {},
    onError: (error) => {
      console.log(error)
    },
  })

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const handleClose = () => {
    let datass = fetchMobileModal?.allModalData?.filter(
      (item) => item?.modal !== fetchMobileModal?.lastModalData?.modal,
    )
    dispatch(
      fetchUserMobileModalData({
        allModalData: datass,
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
  }

  return (
    <div className="mobile-view-follow-following-bottom-modal-alignment">
      <div className="mobile-view-follow-following-body-alignment">
        <div className="mobile-view-follow-following-details-sort-alignment">
          <p>
            {type === 'Followers' ? userInfo?.followers?.length : storeId ? 0 : userInfo?.followings?.length}{' '}
            {type === 'Followers' ? type : storeId ? 'Likes' : type}
          </p>
          <div className="mobile-sort-dropdown-alignment">
            <span>latest</span>
            <div>
              <img src={DropdownIcon.src} alt="DropdownIcon" />
            </div>
          </div>
        </div>
        <div className="mobile-view-follow-following-search-alignment">
          <div className="search-alignment">
            <img src={SearchIcon.src} alt="SearchIcon" />
          </div>
          <input type="text" placeholder="Search" />
        </div>
        <div className="mobile-view-follow-following-details-alignment">
          {ownUser?.length > 0 && (
            <Link href={`/user/${ownUser[0]?.user?.userName}`}>
              <div className="mobile-view-follow-following-personal-data-alignment" onClick={() => handleClose()}>
                <div className="mobile-profile-details-alignment">
                  <div className="mobile-profile-img-alignment">
                    <img
                      src={
                        ownUser[0]?.user?.profileUrl
                          ? ownUser[0]?.user?.profileUrl
                          : user?.logo_image
                          ? user?.logo_image
                          : ''
                      }
                      alt="profile img"
                    />
                  </div>
                  <div className="mobile-profile-name-alignment">
                    <p>{`${ownUser[0]?.user?.firstName} ${' '} ${ownUser[0]?.user?.lastName} `}</p>
                  </div>
                </div>
              </div>
            </Link>
          )}
          {filterAccountUser?.length > 0 ? (
            filterAccountUser
              ?.filter((uid) => uid?.user?.id !== user?.id)
              ?.map((userFollow, index) => {
                return (
                  <Link href={`/user/${userFollow?.user?.userName}`}>
                    <div
                      className="mobile-view-follow-following-personal-data-alignment"
                      key={index}
                      onClick={() => handleClose()}
                    >
                      <div className="mobile-profile-details-alignment">
                        <div className="mobile-profile-img-alignment">
                          <img
                            src={user?.id === userFollow?.user?.id ? user?.logo_image : userFollow?.user?.logo_image}
                            alt="profile img"
                          />
                        </div>
                        <div className="mobile-profile-name-alignment">
                          <p>{userFollow?.user?.firstName + ' ' + userFollow?.user?.lastName}</p>
                        </div>
                      </div>
                      {userId === user?.id && !storeId ? (
                        type === 'Followers' && !storeId ? (
                          <div
                            className="mobile-follow-following-button-alignment"
                            onClick={() =>
                              declineFriendRequest({
                                variables: { user_id: userFollow?.user?.id, friend_id: user.id },
                                refetchQueries: refetchQuery,
                              })
                            }
                          >
                            <button>remove</button>
                          </div>
                        ) : (
                          <div
                            className="mobile-follow-following-button-alignment"
                            onClick={() =>
                              declineFriendRequest({
                                variables: { user_id: user.id, friend_id: userFollow?.user?.id },
                                refetchQueries: refetchQuery,
                              })
                            }
                          >
                            <button>Following</button>
                          </div>
                        )
                      ) : userFollow?.user?.isActiveForFriendStatus && userFollow?.user?.isFriendForFriendStatus ? (
                        <div
                          className="mobile-follow-following-button-alignment"
                          onClick={() => {
                            if (user) {
                              declineFriendRequest({
                                variables: { user_id: user.id, friend_id: userFollow?.user?.id },
                                refetchQueries: refetchQuery,
                              })
                            } else {
                              handleRedirect()
                            }
                          }}
                        >
                          <button className="following">Following</button>
                        </div>
                      ) : (
                        <div
                          className="mobile-follow-following-button-alignment "
                          onClick={() => {
                            if (user) {
                              sendFriendRequest({
                                variables: {
                                  friend_id: userFollow?.user?.id,
                                  user_id: user?.id,
                                  isActive: true,
                                  isFriend: true,
                                },
                                refetchQueries: refetchQuery,
                              })
                            } else {
                              handleRedirect()
                            }
                          }}
                        >
                          <button className="active-follow">Follow</button>
                        </div>
                      )}
                    </div>
                  </Link>
                )
              })
          ) : storeId ? (
            type === 'Followers' ? (
              <div className="no-store-data-following-details-alignment">
                <div>
                  <img src={NoFollowImg.src} alt="no follow" />
                </div>
                <div className="no-follow-name-alignment">
                  <span>
                    Follow the store and be the first to know about the latest collections and exclusive deals!
                  </span>
                </div>
              </div>
            ) : (
              <div className="no-store-data-following-details-alignment">
                <div>
                  <img src={NoLikeImg.src} alt="no like" />
                </div>
                <div className="no-follow-name-alignment like-alignment">
                  <span>Spread love by become the first person who like this store </span>
                </div>
              </div>
            )
          ) : (
            <div className="mobile-view-no-following-details-alignment">
              <div>
                <img src={NoFollowingImg.src} alt="NoFollowingImg" />
              </div>
              <span>{type === 'Followers' ? 'No Follower' : 'No Following'}</span>
            </div>
          )}
        </div>
      </div>
      {/* {!storeId && (
        <div>
          <div className="mobile-view-no-data-may-following-alignment">
            <div className="mobile-view-no-data-may-following-headign">
              <h5>People you may know</h5>
            </div>
            <div className="mobile-view-no-data-follow-alignment">
              {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((e, i) => {
                return (
                  <div className="mobile-view-no-data-follow-data-alignment" key={i}>
                    <div className="mobile-profile-details-alignment">
                      <div className="mobile-profile-img-alignment">
                        <img src="/assets/img/request/img-8.jpg" alt="profile img" />
                      </div>
                      <div className="mobile-profile-name-alignment">
                        <p>Alice Miller</p>
                      </div>
                    </div>
                    <div className="mobile-no-data-follow-button-alignment">
                      <button>Follow</button>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )} */}
    </div>
  )
}
