import { useMutation, useQuery } from '@apollo/client'
import { Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'
import { INVITE_FOR_GROUP_MESSAGE } from 'src/graphql/mutations/mutations'
import { GET_ALL_GROUP_USER, GET_GROUPS } from 'src/graphql/queries/queries'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAppDispatch } from 'src/store/store'
import { useAuth } from 'src/utils/auth'
import { useStateContext } from 'src/utils/state'
import client1 from 'src/utils/apolloClient'

export default function ManageGroupMember({ handleOpen }) {
  const { selectedChat, notify, updateName, selectChat } = useStateContext()
  const dispatch = useAppDispatch()
  const [selectedUser, setSelecteduser] = useState([])
  const [searchTag, setSearchTag] = useState('')
  const { user }: any = useAuth()
  const { data, loading, error, refetch } = useQuery(GET_ALL_GROUP_USER, {
    client: client1,
    variables: { group_id: selectedChat?.chatData?.id },
    onError: (err) => { },
  })

  useEffect(() => {
    refetch()
  }, [])

  const handleChange = (e, selected) => {
    setSelecteduser(
      selectedUser?.map((user) => {
        if (user?.id === selected?.id) {
          return { ...user, isChecked: !user?.isChecked }
        } else {
          return user
        }
      }),
    )
  }

  const [InviteGroupUsers] = useMutation(INVITE_FOR_GROUP_MESSAGE, {
    client: client1,
    onCompleted: (res) => { },
    onError: (err) => {
      console.log('err', err)
    },
  })

  const handleSendInvitation = () => {
    if (user) {
      let filteredArray = selectedUser.filter((user) => user?.isChecked)?.map((us) => us?.id)
      InviteGroupUsers({
        variables: { conversationId: selectedChat?.chatData?.id, participants: filteredArray },
        // refetchQueries: [{ query: GET_ALL_GROUP_USER, variables: { group_id: selectedChat?.chatData?.id } }],

        update: (proxy, { data }) => {
          const returnedData = data
          refetch()
          const dataInCache: any = proxy.readQuery({
            query: GET_GROUPS,
          })

          let filteredArray = selectedUser
            .filter((user) => user?.isChecked)
            .map((grp) => {
              return { ...grp, isInvitedUser: true }
            })

          let addInvitedUser = {
            ...selectedChat?.chatData,
            invitedUsers: [...selectedChat?.chatData?.invitedUsers, ...filteredArray],
          }
          selectChat(addInvitedUser, 'group')
        },
      })
    } else {
      handleRedirect()
    }
  }

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  useEffect(() => {
    if (data?.getAllGroupUsers?.length > 0) {
      let inputSData = data?.getAllGroupUsers.map((user) => {
        return { ...user, isChecked: false, name: user?.firstName + ' ' + user?.lastName }
      })
      setSelecteduser(inputSData)
    }
  }, [data])

  return (
    <div className="invite-people-modal-alignment">
      <div className="invite-people-modal-wrapper-alignment">
        <div className="invite-white-modal-box-alignment">
          <div className="invite-people-modal-header-alignment">
            <h6>Invite People</h6>
            <div
              className="invite-header-close-alignment"
              onClick={() => {
                handleOpen(false)
              }}
            >
              <img src="/assets/icon/close-white.svg" alt="close icon" />
            </div>
          </div>
          <div className="invite-prople-modal-body-alignment">
            <div className="invite-people-search-alignment">
              <div className="invite-people-account-search-alignment">
                <div>
                  <img src="/assets/icon/search.svg" alt="search" />
                </div>
                <input type="text" placeholder="Search" onChange={(e) => setSearchTag(e.target.value)} />
              </div>
            </div>
            <div className="invite-selected-alignment">
              <span>{selectedUser?.filter((supplier: any) => supplier?.isChecked)?.length} selected</span>
            </div>
            <div className="invite-selected-people-details-alignment">
              {selectedUser
                ?.filter((supplier: any) => supplier?.name?.toLowerCase().includes(searchTag?.toLowerCase()))
                ?.map((invite) => {
                  return (
                    <div className="invite-selected-people-flex-alignment">
                      <div className="invite-selected-profile-details-alignment">
                        <div className="invite-selected-profile-img">
                          <img
                            src={invite?.logo_image?.length > 0 ? invite?.logo_image : '/assets/img/user.png'}
                            alt="user icon"
                          />
                        </div>
                        <p>{invite?.firstName + ' ' + invite?.lastName}</p>
                      </div>
                      {!invite?.isInvited ? (
                        <div className="invite-selected-checkbox-alignment">
                          <Checkbox onChange={(e) => handleChange(e, invite)} checked={invite?.isChecked}></Checkbox>
                        </div>
                      ) : (
                        <span>invited</span>
                      )}
                    </div>
                  )
                })}
            </div>
            {/* <div className="invite-people-status-alignment">
              <div className="invite-atatus-details-alignment">
                <div className="check-icon-alignment">
                  <img src="/assets/icon/green-check.svg" alt="check icon" />
                </div>

                <span>3 invitations sent</span>
              </div>
            </div> */}
          </div>
          <div className="invite-people-modal-footer-alignment">
            <div className="cancle-button-alignment">
              <button
                onClick={() => {
                  handleOpen(false)
                }}
              >
                Cancel
              </button>
            </div>
            <div className="send-invition-button-alignment" onClick={handleSendInvitation}>
              <button>Send Invitation ({selectedUser?.filter((supplier: any) => supplier?.isChecked)?.length})</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
