// @ts-nocheck
// import { API } from '../../config/api.config'
import { API } from 'src/config/API/api.config'
import Auth from '../auth'
import nookies from 'nookies'
export const BaseURL = 'https://stage-graph.api.bluejestic.com'
// export const BaseURL =
//   API.endpoint.includes('stage') || API.endpoint.includes('local')
//     ? 'https://graph.api.bluejestic.com'
//     : 'https://stage-graph.api.bluejestic.com'
// export const BaseURL = 'http://localhost:3301'
export const getBaseURL = 'https://api.us-east.tinybird.co/v0/pipes'
const axios = require('axios').default

const defaultHeaders = {
  isAuth: true,
  AdditionalParams: {},
  isJsonRequest: true,
}

const { token, seller_token } = nookies.get()

export const ApiPostNoAuth = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        BaseURL + type,
        userData,
        getHttpOptions({
          ...defaultHeaders,
          isAuth: false,
        }),
      )
      .then((responseJson) => {
        resolve(responseJson)
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty('response') &&
          error.response &&
          error.response.hasOwnProperty('data') &&
          error.response.data &&
          error.response.data.hasOwnProperty('error') &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message)
        } else {
          reject(error?.response?.data?.message)
        }
      })
  })
}

export const ApiPutNoAuth = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        BaseURL + type,
        userData,
        getHttpOptions({
          ...defaultHeaders,
          isAuth: false,
        }),
      )
      .then((responseJson) => {
        resolve(responseJson)
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty('response') &&
          error.response &&
          error.response.hasOwnProperty('data') &&
          error.response.data &&
          error.response.data.hasOwnProperty('error') &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message)
        } else {
          reject(error?.response?.data?.message)
        }
      })
  })
}

export const ApiGetNoAuth = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        BaseURL + type,
        getHttpOptions({
          ...defaultHeaders,
          isAuth: false,
        }),
      )
      .then((responseJson) => {
        resolve(responseJson)
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty('response') &&
          error.response &&
          error.response.hasOwnProperty('data') &&
          error.response.data &&
          error.response.data.hasOwnProperty('error') &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message)
        } else {
          reject(error?.response?.data?.message)
        }
      })
  })
}

export const Api = (type, methodtype, userData) => {
  return new Promise((resolve, reject) => {
    userData = userData || {}
    axios({
      url: BaseURL + type,
      headers: getHttpOptions(),
      data: userData,
      type: methodtype,
    })
      .then((responseJson) => {
        resolve(responseJson)
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty('response') &&
          error.response &&
          error.response.hasOwnProperty('data') &&
          error.response.data &&
          error.response.data.hasOwnProperty('error') &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message)
        } else {
          reject(error?.response?.data?.message)
        }
      })
  })
}

export const ApiGet = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(getBaseURL + type)
      .then((responseJson) => {
        resolve(responseJson)
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty('response') &&
          error.response &&
          error.response.hasOwnProperty('data') &&
          error.response.data &&
          error.response.data.hasOwnProperty('error') &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message)
        } else {
          reject(error?.response?.data?.message)
        }
      })
  })
}

export const ApiPost = (type: any, userData: any) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BaseURL + type, userData, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson)
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty('response') &&
          error.response &&
          error.response.hasOwnProperty('data') &&
          error.response.data &&
          error.response.data.hasOwnProperty('error') &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message)
        } else {
          reject(error?.response?.data?.message)
        }
      })
  })
}

export const ApiSellerPost = (type: any, userData: any, key: any) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BaseURL + type, {}, getHttpOptionsSeller())
      .then((responseJson) => {
        resolve(responseJson)
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty('response') &&
          error.response &&
          error.response.hasOwnProperty('data') &&
          error.response.data &&
          error.response.data.hasOwnProperty('error') &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message)
        } else {
          reject(error?.response?.data?.message)
        }
      })
  })
}

export const ApiPut = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .put(BaseURL + type, userData, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson)
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty('response') &&
          error.response &&
          error.response.hasOwnProperty('data') &&
          error.response.data &&
          error.response.data.hasOwnProperty('error') &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message)
        } else {
          reject(error?.response?.data?.message)
        }
      })
  })
}

export const ApiPatch = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(BaseURL + type, userData, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson)
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty('response') &&
          error.response &&
          error.response.hasOwnProperty('data') &&
          error.response.data &&
          error.response.data.hasOwnProperty('error') &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message)
        } else {
          reject(error?.response?.data?.message)
        }
      })
  })
}

export const ApiDelete = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(BaseURL + type, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson)
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty('response') &&
          error.response &&
          error.response.hasOwnProperty('data') &&
          error.response.data &&
          error.response.data.hasOwnProperty('error') &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message)
        } else {
          reject(error?.response?.data?.message)
        }
      })
  })
}

export const ApiDownload = (type, userData) => {
  let method = userData && Object.keys(userData).length > 0 ? 'POST' : 'GET'
  return new Promise((resolve, reject) => {
    axios({
      url: BaseURL + type,
      method,
      headers: getHttpOptions(),
      data: userData,
    })
      .then((res) => res.blob())
      .then((res) => resolve(res))
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty('response') &&
          error.response &&
          error.response.hasOwnProperty('data') &&
          error.response.data &&
          error.response.data.hasOwnProperty('error') &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message)
        } else {
          reject(error?.response?.data?.message)
        }
      })
  })
}

export const ApiGetBuffer = (url) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'GET',
      mode: 'no-cors',
    })
      .then((response: any) => {
        if (response.ok) {
          return response.buffer()
        } else {
          resolve(null)
        }
      })
      .then((buffer) => {
        resolve(buffer)
      })
      .catch((error) => {
        console.error(error)
        reject(error)
      })
  })
}

export const getHttpOptions = (options = defaultHeaders) => {
  let headers = {}
  const tokenData = nookies.get()
  const { token } = nookies.get()
  if (options.hasOwnProperty('isAuth') && options.isAuth) {
    if (token) {
      headers['authorization'] = `Bearer ${token}`
    } else {
      headers['authorization'] = `Bearer ${tokenData.seller_token}`
    }
  }

  // if (options.hasOwnProperty('isAuth') && options.isAuth) {
  //   headers['x-auth-token'] = Auth.getToken()
  // }

  if (options.hasOwnProperty('isJsonRequest') && options.isJsonRequest) {
    headers['Content-Type'] = 'application/json'
  }

  if (options.hasOwnProperty('AdditionalParams') && options.AdditionalParams) {
    headers = {
      ...headers,
      ...options.AdditionalParams,
    }
  }

  return {
    headers,
  }
}

export const getHttpOptionsSeller = (options = defaultHeaders) => {
  let headers = {}

  if (options.hasOwnProperty('isAuth') && options.isAuth) {
    headers['x-auth-token'] = `${seller_token}`
  }

  // if (options.hasOwnProperty('isAuth') && options.isAuth) {
  //   headers['x-auth-token'] = Auth.getToken()
  // }

  if (options.hasOwnProperty('isJsonRequest') && options.isJsonRequest) {
    headers['Content-Type'] = 'application/json'
  }

  if (options.hasOwnProperty('AdditionalParams') && options.AdditionalParams) {
    headers = {
      ...headers,
      ...options.AdditionalParams,
    }
  }

  return {
    headers,
  }
}
