import React, { useEffect, useState } from 'react'
import styles from './CreateClubModal.module.scss'
import classNames from 'classnames'
import CreateClubBasicInfo from './CreateClubBasicInfo'
import CreateClubProfile from './CreateClubProfile'
import CreateClubInvitePeople from './CreateClubInvitePeople'
import CREATEGROUP from 'src/graphql/mutations/createGroup'
import { ApiPost } from 'src/Helpers/Api/ApiData'
import { API } from 'src/config/API/api.config'
import INVITE_USER_TO_GROUP from 'src/graphql/mutations/inviteUserToGroup'
import { useAppDispatch } from 'src/store/store'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import { Spin, notification } from 'antd'
import UPDATE_GROUP_DETAIL from 'src/graphql/mutations/updateGroupDetail'
import GETGROUP from 'src/graphql/queries/getGroup'
import GET_JOINED_GROUP from 'src/graphql/queries/sellerQueries/getJoinedGroups'
import GET_GROUPS_ONBOARDING from 'src/graphql/queries/getAllgroupsOnboarding'
import { useRouter } from 'next/router'
import { fetchJoinedClubsData } from 'src/store/slices/fetchAllClubsSlice'

export default function CreateClubModal() {
  const [inputValue, setInputValue] = useState({
    name: '',
    description: '',
    category: null,
    hashtags: [],
    privacy: '',
    banner_image: null,
    logo_image: null,
    userAdd: [],
    subCategory_id: [],
    editCoverImg: {},
    editBannerImg: {},
  })
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const dispatch = useAppDispatch()
  const [errors, setErrors] = useState({})
  const [steeper, setStepper] = useState({ step1: true, step2: false, step3: false })
  const [loading, setLoading] = useState(false)
  const router = useRouter()

  const handelOnClick = (name) => {
    name == 'step1' && setStepper({ step1: true, step2: false, step3: false })
    name == 'step2' && setStepper({ step1: false, step2: true, step3: false })
    name == 'step3' && setStepper({ step1: false, step2: false, step3: true })
  }

  useEffect(() => {
    let editData = getAllState?.groupCreationModal?.id?.data?.group
    if (getAllState?.groupCreationModal?.id?.isEdit) {
      setInputValue({
        ...editData,
        category: getAllState?.groupCreationModal?.id?.data?.group?.subCategory_id?.id,
      })
    }
  }, [getAllState?.groupCreationModal?.id?.isEdit])

  const validateForm = () => {
    let formIsValid = true
    let errors = {}
    if (inputValue?.name.trim() === '') {
      formIsValid = false
      errors['name'] = '*Please Enter Name!'
    }

    if (inputValue?.description.trim() === '') {
      formIsValid = false
      errors['description'] = '*Please Enter Description!'
    }

    // if (!inputValue?.tag) {
    //   formIsValid = false
    //   errors['tag'] = '*Please Enter tag!'
    // }

    if (!inputValue?.banner_image) {
      formIsValid = false
      errors['banner_image'] = '*Please select bannerImage!'
    }
    if (!inputValue?.logo_image) {
      formIsValid = false
      errors['logo_image'] = '*Please select coverImage!'
    }

    if (!inputValue?.category) {
      formIsValid = false
      errors['category'] = '*Please enter category!'
    }
    if (!inputValue?.privacy) {
      formIsValid = false
      errors['privacy'] = '*Please enter Privacy!'
    }
    if (inputValue?.userAdd?.length < 1) {
      formIsValid = false
      errors['userAdd'] = '*Please add minimum 1 users!'
    }
    setErrors(errors)
    return formIsValid
  }

  const [updateGroup] = useMutation(UPDATE_GROUP_DETAIL, {
    onCompleted: (data) => {
      notification['success']({
        message: 'Group Updated',
        description: 'Group Updated Successfully',
      })
      router.replace(`/club/${data?.updateGroupDetail.slug}`)
      setLoading(false)
      setInputValue({
        name: '',
        description: '',
        category: null,
        hashtags: [],
        privacy: '',
        banner_image: null,
        logo_image: null,
        userAdd: [],
        subCategory_id: [],
        editCoverImg: {},
        editBannerImg: {},
      })
      setStepper({ step1: true, step2: false, step3: false })
    },
    onError: (error) => {
      notification['error']({
        message: 'Error',
        description: 'Something went wrong, Please try later',
      })
      setLoading(false)
    },
  })

  const [createGroup] = useMutation(CREATEGROUP, {
    onCompleted: (data) => {
      notification['success']({
        message: 'Group Created',
        description: 'Group Created Successfully',
      })
      setLoading(false)
      setInputValue({
        name: '',
        description: '',
        category: null,
        hashtags: [],
        privacy: '',
        banner_image: null,
        logo_image: null,
        userAdd: [],
        subCategory_id: [],
        editCoverImg: {},
        editBannerImg: {},
      })
      setStepper({ step1: true, step2: false, step3: false })
      dispatch(fetchJoinedClubsData({ page: 1, limit: 100, type: 'JOIN_CLUBS' }))
    },
    onError: (error) => {
      notification['error']({
        message: 'Error',
        description: 'Something went wrong, Please try later',
      })
      setLoading(false)
    },
  })

  const [inviteUserToGroup] = useMutation(INVITE_USER_TO_GROUP, {
    onCompleted: (data) => {
      setLoading(false)
      dispatch(
        fetchModalData({
          ...getAllState,
          groupCreationModal: { modal: 'createClubModal', isOpen: false, id: 0, modalFor: 'smallModal' },
        }),
      )
    },
    onError: (error) => {
      notification['error']({
        message: 'Error',
        description: 'Something went wrong, Please try later',
      })
      setLoading(false)
    },
  })

  let UploadImageRoute =
    API.endpoint.includes('stage') || API.endpoint.includes('local')
      ? `/upload?type=bluejestic-stage/groupBanner`
      : `/upload?type=bluejestic-production/groupBanner`

  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading(true)
      let bannerImageId
      if (typeof inputValue?.banner_image != 'string') {
        const UploadFile = new FormData()
        console.log('inputValue?.banner_image', inputValue);
        
        UploadFile.append('image', inputValue?.banner_image)
        UploadFile.append('media_for', 'groups')
        const bannerImageUpload: any = await ApiPost(UploadImageRoute, UploadFile)
          .then((res) => {
            return res
          })
          .catch((err) => {
            setLoading(false)
            return err
          })
        bannerImageId = await bannerImageUpload?.data?.image[0].media
      }

      // ===========================================================================================================

      let coverImageId

      if (typeof inputValue?.logo_image != 'string') {
        const CoverImage = new FormData()
        CoverImage.append('image', inputValue?.logo_image)
        CoverImage.append('media_for', 'groups')

        const coverImageUpload: any = await ApiPost(UploadImageRoute, CoverImage)
          .then((res) => {
            return res
          })
          .catch((err) => {
            setLoading(false)
            return err
          })
        coverImageId = await coverImageUpload?.data?.image[0].media
      }

      if (getAllState?.groupCreationModal?.id?.isEdit) {
        let updatedGroup = await updateGroup({
          variables: {
            input: {
              id: getAllState?.groupCreationModal?.id?.data?.group?.id
                ? getAllState?.groupCreationModal?.id?.data?.group?.id
                : getAllState?.lastModalData?.id,
              name: inputValue?.name,
              description: inputValue?.description,
              // category: "test category",
              hashtags: inputValue?.hashtags,
              privacy: inputValue?.privacy,
              banner_image: inputValue?.banner_image?.id ? inputValue?.banner_image?.id : bannerImageId,
              logo_image: inputValue?.logo_image?.id ? inputValue?.logo_image?.id : coverImageId,
              subCategory_id: [Number(inputValue?.category)],
            },
          },
          refetchQueries: [
            {
              query: GETGROUP,
              variables: { slug: getAllState?.groupCreationModal?.id?.slug },
            },
          ],
        })

        {
          inputValue?.userAdd?.length &&
            inviteUserToGroup({
              variables: { groupId: getAllState?.lastModalData?.id, userId: inputValue?.userAdd?.map((x) => x?.value) },
            })
        }

        dispatch(
          fetchModalData({
            ...getAllState,
            groupCreationModal: { modal: 'createClubModal', isOpen: false, id: 0, modalFor: 'smallModal' },
          }),
        )
      } else {
        let createdGroup = await createGroup({
          variables: {
            name: inputValue?.name,
            description: inputValue?.description,
            category: 'test category',
            hashtags: inputValue?.hashtags,
            privacy: inputValue?.privacy,
            banner_image: bannerImageId,
            logo_image: coverImageId,
            subCategory_id: [Number(inputValue?.category)],
          },
          refetchQueries: [
            {
              query: GET_JOINED_GROUP,
              // variables: { id: parseInt(getAllState?.lastModalData?.id) },
            },
            {
              query: GET_GROUPS_ONBOARDING,
              variables: {
                type: 'SUGGESTED_CLUBS',
              },
            },
          ],
        })

        inviteUserToGroup({
          variables: {
            groupId: createdGroup?.data?.createGroup?.id,
            userId: inputValue?.userAdd?.map((x) => x?.value),
          },
        })
      }
    }
  }

  const handleCloseCreateModel = () => {
    dispatch(
      fetchModalData({
        ...getAllState,
        groupCreationModal: {
          modal: 'createClubModal',
          isOpen: false,
          id: {
            isEdit: false,
          },
          modalFor: 'smallModal',
        },
      }),
    )
  }

  return (
    <div className={styles.createClubModalSection}>
      <div className={styles.createClubModalTopDetails}>
        <div className={styles.createClubModalHeading}>
          <h4>Create a New Club</h4>
          {loading ? (
            <div className="flex">
              <div className="m-auto">
                <Spin tip="loading" />
              </div>
            </div>
          ) : (
            <div className={styles.createClubButton}>
              <button onClick={handleSubmit}>{getAllState?.groupCreationModal?.id?.isEdit ? 'Edit' : 'Create'}</button>
            </div>
          )}
        </div>

        <div className={styles.mobileViewCreateClubModalHeading}>
          <div onClick={() => handleCloseCreateModel()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="37" height="32" viewBox="0 0 37 32" fill="none">
              <path
                d="M27 0H10C4.47715 0 0 4.47715 0 10V22C0 27.5228 4.47715 32 10 32H27C32.5228 32 37 27.5228 37 22V10C37 4.47715 32.5228 0 27 0Z"
                fill="white"
              />
              <path
                d="M21 10L16 16L21 22"
                stroke="#808191"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

          <h4>Create Club</h4>
        </div>

        <div className={styles.createClubModalStepper}>
          <div className={styles.createClubModalStepperFlex}>
            <div
              className={classNames(styles.createClubModalStepBox, steeper?.step1 && styles.activeStep)}
              onClick={() => handelOnClick('step1')}
            >
              <span>Step 1</span>
              <h5>Basic Info</h5>
            </div>
            <div
              className={classNames(styles.createClubModalStepBox, steeper?.step2 && styles.activeStep)}
              onClick={() => handelOnClick('step2')}
            >
              <span>Step 2</span>
              <h5>
                Profile & <br /> <span>Cover Image</span>
              </h5>
            </div>
            <div
              className={classNames(styles.createClubModalStepBox, steeper?.step3 && styles.activeStep)}
              onClick={() => handelOnClick('step3')}
            >
              <span>Step 3</span>
              <h5>Invite People</h5>
            </div>
          </div>
        </div>
      </div>

      <div>
        {steeper?.step1 && (
          <CreateClubBasicInfo
            inputValue={inputValue}
            setInputValue={setInputValue}
            errors={errors}
            setErrors={setErrors}
          />
        )}
        {steeper?.step2 && (
          <CreateClubProfile
            inputValue={inputValue}
            setInputValue={setInputValue}
            errors={errors}
            setErrors={setErrors}
          />
        )}
        {steeper?.step3 && (
          <CreateClubInvitePeople
            inputValue={inputValue}
            setInputValue={setInputValue}
            errors={errors}
            setErrors={setErrors}
          />
        )}
      </div>

      <div className={styles.createMobileViewButton}>
        <button onClick={() => handleSubmit()}>
          {getAllState?.groupCreationModal?.id?.isEdit ? 'Edit' : 'Create'}
        </button>
      </div>
    </div>
  )
}
