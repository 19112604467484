import React, { useState } from 'react'
import CardInput from '../Cart/CardInput'

export default function CardInputView() {
  const [selectedMethod, setSelectedMethod] = useState('credit')

  const handleChange = (e) => {
    setSelectedMethod(e.target.value)
  }

  return (
    <React.Fragment>
      <div className="payment-method-box-main">
        <div className="payment-method-card-main">
          {/* <div className="paypal-contain">
            <input
              type="radio"
              name="payment-type"
              value="paypal"
              checked={selectedMethod === 'paypal' ? true : false}
              onClick={(e) => handleChange(e)}
            />
            <img src={'/assets/img/checkout/paypal-logo.svg'} alt="Logo" />
          </div> */}
          <div className="paypal-contain">
            <input
              type="radio"
              name="payment-type"
              value="credit"
              checked={selectedMethod === 'credit' ? true : false}
              onClick={(e) => handleChange(e)}
            />
            <h3>Credit Card</h3>
          </div>
          <div className="card-number-alignment">{selectedMethod === 'credit' && <CardInput />}</div>
        </div>
      </div>

      {/* <Modal
                open={isSelectMapModalVisible}
                onCancel={() => handleCancel()}
                footer={null}
                closable={false}
                width={588}
                wrapClassName="post-add-edit-modal"
                maskStyle={{ zIndex: 9999 }}
            >
                <SelectCreditCardModal
                    setIsMapModalVisible={setIsMapModalVisible}
                    setIsSelectMapModalVisible={setIsSelectMapModalVisible}
                    setAddressFormData={setAddressFormData}
                    addressFormData={addressFormData}
                    setIsCardEdit={setIsCardEdit}
                    isCardEdit={isCardEdit}
                    data={data}
                />
            </Modal> */}
    </React.Fragment>
  )
}
