import { notification } from 'antd'
import PersonalInformationSection from './PersonalInformationSection'
import { isEmpty } from 'src/utils/isEmpty'

export default function SellerOnBoardingProfileTab({
  user,
  isValid,
  inputValue,
  setInputValue,
  handleNext,
  errors,
  setErrors,
  handleChange,
}) {
  const handlePasswordSubmit = async () => {
    if (isValid()) {
      if (inputValue?.new_password === inputValue?.new_password_confirm) {
        handleNext()
      } else {
        notification['error']({
          message: 'Error',
          description: 'New password and Confirm password do not match',
        })
      }
    }
  }

  return (
    <div className="max-w-full mx-auto space-y-8">
      {/* Personal Information */}
      <PersonalInformationSection
        {...{ user, isValid, inputValue, setInputValue, handleNext, errors, setErrors, handleChange }}
      />

      {/* Password Change */}
      {isEmpty(user) && (
        <div className="bg-white rounded-xl p-8 shadow-sm border border-gray-100">
          <h2 className="text-2xl font-semibold text-gray-800 mb-6">Change Password</h2>
          <div className="space-y-6">
            <div>
              <label className="block text-base font-medium text-gray-700 mb-2">
                Current Password <span className="text-red-600">*</span>
                {errors.current_password && <span className="text-sm text-red-600">{errors.current_password}</span>}
              </label>
              <input
                type="password"
                name="current_password"
                value={inputValue.current_password}
                onChange={handleChange}
                className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                placeholder="Enter current password"
              />
            </div>

            <div>
              <label className="block text-base font-medium text-gray-700 mb-2">
                New Password <span className="text-red-600">*</span>{' '}
                {errors.new_password && <span className="text-sm text-red-600">{errors.new_password}</span>}
              </label>
              <input
                type="password"
                value={inputValue?.new_password}
                name="new_password"
                onChange={handleChange}
                className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                placeholder="Enter new password"
              />
            </div>

            <div>
              <label className="block text-base font-medium text-gray-700 mb-2">
                Confirm New Password <span className="text-red-600">*</span>{' '}
                {errors.new_password_confirm && (
                  <span className="text-sm text-red-600">{errors.new_password_confirm}</span>
                )}
              </label>
              <input
                type="password"
                value={inputValue?.new_password_confirm}
                name="new_password_confirm"
                onChange={handleChange}
                className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                placeholder="Confirm new password"
              />
            </div>
          </div>
          <div className="flex justify-end mt-5">
            <button
              className="px-8 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
              onClick={handlePasswordSubmit}
            >
              Save Changes
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
