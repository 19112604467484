import { gql } from '@apollo/client'

export default gql`
  mutation SHARE_POST_MUTATION(
    $content: String!
    $product_id: Int
    $store_name: String
    $share_post_for: String
    $group_id: [Int]
    $bookmark_id: Int
    $collection_id: Int
  ) {
    sharePost(
      input: {
        content: $content
        product_id: $product_id
        store_name: $store_name
        share_post_for: $share_post_for
        group_id: $group_id
        bookmark_id: $bookmark_id
        collection_id: $collection_id
      }
    ) {
      status
      success
      message
      data {
        id
        product_id
        content
      }
    }
  }
`
