import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_ALL_USERS } from 'src/graphql/queries/queries'
import UserMessageItem from '../UserMessageItem'
import { Spin } from 'antd'
import client1 from 'src/utils/apolloClient'

export default function PersonalMessageTab() {
  const { data: userData, loading: loadingUsers }: any = useQuery<any>(GET_ALL_USERS, {
    client: client1,
    onError: (err) => { },
  })


  return (
    <div className="message-all-chat-details-alignment">
      {loadingUsers ? <div className="loader-align w-full flex justify-center mt-20"> <Spin size="large" tip="loading" /></div> :
        <>
          {userData?.getAllUsers.filter((user) => user?.latestMessage)?.length > 0 ? (
            // .sort((a: any, b: any) => {
            //   const dateA = new Date(a.latestMessage.createdAt)
            //   const dateB = new Date(b.latestMessage.createdAt)
            //   return dateB.getTime() - dateA.getTime()
            // })
            userData?.getAllUsers
              .filter((user) => user?.latestMessage)
              ?.sort((a: any, b: any) => {
                return new Date(parseInt(b?.latestMessage?.updatedAt)).getTime() - new Date(parseInt(a?.latestMessage?.updatedAt)).getTime()
              })
              ?.map((u, index) => {
                return (
                  <React.Fragment key={index}>
                    <UserMessageItem
                      chatData={{
                        ...u,
                        user: { firstName: u?.firstName, lastName: u?.lastName, logo_image: u?.logo_image },
                      }}
                    />
                  </React.Fragment>
                )
              })
          ) : (
            <div className="no-search-details-alignment">
              <div className="no-search-img-alignment">
                <img src="/assets/img/no-search-img.png" alt="no search img" />
              </div>
              <p>Not found.</p>
            </div>
          )}
        </>}
    </div>
  )
}
