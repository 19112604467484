import { gql } from '@apollo/client'

const query = gql`
  mutation DELETE_SHARED_POST($post_id: Int!) {
    deleteSharePost(id: $post_id) {
      message
      sharePost
    }
  }
`
export default query
