import React from 'react'
import e1 from '../../../../../public/assets/img/artCarft/creative-club-bg.png'

export default function CreativeClubsArtCarft() {
  return (
    <div className="art-craft-creative-club-store-details-section">
      <div className="mobile-view-art-craft-back-alignment">
        <div className="art-craft-back_details-alignment">
          <div>{/* <img src={e1.src} alt="back-page" /> */}</div>
          <p>Home...</p>
        </div>
        <div className="mobile-heading-alignment">
          <h4>Creative Clubs</h4>
        </div>
      </div>
      <div className="art-craft-creative-club-store-banner-alignment">
        <img src={e1.src} alt="creative club bg" />
        <img
          src="/assets/img/artCarft/mobileviewCreativeClubBanner.png"
          alt="MobileNowcreative-clubBanner"
          className="mobile-view-banner"
        />

        <div className="art-craft-creative-club-store-banner-details">
          <h4>Creative Clubs</h4>
          <p>Take your art to the next level with our Creative Clubs.</p>
        </div>
      </div>
      <div className="art-craft-creative-club-all-details-alignment">
        <div className="art-craft-creative-club-grid-details-alignment">
          <div className="art-craft-creative-club-gridItem-details-alignment">
            <div className="creative-clubs-right_slider_cover">
              <div className="card_creative-clubs">
                <div className="creative-clubs-img_cover">
                  <img src="/assets/img/artCarft/demo-img.png" alt="img_cover" />
                </div>
                <div className="creative-clubs-card_details">
                  <div className="creative-clubs-card_details_cover">
                    <h5>Home Masters</h5>
                    <p>Mastering the art of home comfort</p>
                    <div className="creative-clubs-users">
                      <div className="creative-clubs-left_part">
                        <div className="creative-clubs-user_img pr-4">
                          <img src="/assets/img/WomansCategory/users.png" alt="user_img" />
                        </div>
                        {[...Array(3)].map((_, index) => {
                          return (
                            <div className="creative-clubs-users_likes" key={index}>
                              <div className="creative-clubs-user_box">
                                <img src="/assets/img/WomansCategory/like.png " alt="user_box" />
                              </div>
                            </div>
                          )
                        })}
                        <div className="creative-clubs-count">+120</div>
                      </div>
                      <div className="creative-clubs-right_part">
                        <button>Join Club</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="art-craft-creative-club-gridItem-details-alignment">
            <div className="creative-clubs-right_slider_cover">
              <div className="card_creative-clubs">
                <div className="creative-clubs-img_cover">
                  <img src="/assets/img/artCarft/appl1.png" alt="img_cover" />
                </div>
                <div className="creative-clubs-card_details">
                  <div className="creative-clubs-card_details_cover">
                    <h5>ApplianceWorks</h5>
                    <p>Works like a charm, every time.</p>
                    <div className="creative-clubs-users">
                      <div className="creative-clubs-left_part">
                        <div className="creative-clubs-user_img pr-4">
                          <img src="/assets/img/WomansCategory/users.png" alt="user_img" />
                        </div>
                        {[...Array(3)].map((_, index) => {
                          return (
                            <div className="creative-clubs-users_likes" key={index}>
                              <div className="creative-clubs-user_box">
                                <img src="/assets/img/WomansCategory/like.png " alt="user_box" />
                              </div>
                            </div>
                          )
                        })}
                        <div className="creative-clubs-count">+120</div>
                      </div>
                      <div className="creative-clubs-right_part">
                        <button>Join Club</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="art-craft-creative-club-gridItem-details-alignment">
            <div className="creative-clubs-right_slider_cover">
              <div className="card_creative-clubs">
                <div className="creative-clubs-img_cover">
                  <img src="/assets/img/artCarft/demo1.png" alt="img_cover" />
                </div>
                <div className="creative-clubs-card_details">
                  <div className="creative-clubs-card_details_cover">
                    <h5>Domestic Innovations</h5>
                    <p>Making your house a tech-savvy home</p>
                    <div className="creative-clubs-users">
                      <div className="creative-clubs-left_part">
                        <div className="creative-clubs-user_img pr-4">
                          <img src="/assets/img/WomansCategory/users.png" alt="user_img" />
                        </div>
                        {[...Array(3)].map((_, index) => {
                          return (
                            <div className="creative-clubs-users_likes" key={index}>
                              <div className="creative-clubs-user_box">
                                <img src="/assets/img/WomansCategory/like.png " alt="user_box" />
                              </div>
                            </div>
                          )
                        })}
                        <div className="creative-clubs-count">+120</div>
                      </div>
                      <div className="creative-clubs-right_part">
                        <button>Join Club</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="art-craft-creative-club-gridItem-details-alignment">
            <div className="creative-clubs-right_slider_cover">
              <div className="card_creative-clubs">
                <div className="creative-clubs-img_cover">
                  <img src="/assets/img/artCarft/demo1.png" alt="img_cover" />
                </div>
                <div className="creative-clubs-card_details">
                  <div className="creative-clubs-card_details_cover">
                    <h5>Domestic Innovations</h5>
                    <p>Making your house a tech-savvy home</p>
                    <div className="creative-clubs-users">
                      <div className="creative-clubs-left_part">
                        <div className="creative-clubs-user_img pr-4">
                          <img src="/assets/img/WomansCategory/users.png" alt="user_img" />
                        </div>
                        {[...Array(3)].map((_, index) => {
                          return (
                            <div className="creative-clubs-users_likes" key={index}>
                              <div className="creative-clubs-user_box">
                                <img src="/assets/img/WomansCategory/like.png " alt="user_box" />
                              </div>
                            </div>
                          )
                        })}
                        <div className="creative-clubs-count">+120</div>
                      </div>
                      <div className="creative-clubs-right_part">
                        <button>Join Club</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="art-craft-creative-club-gridItem-details-alignment">
            <div className="creative-clubs-right_slider_cover">
              <div className="card_creative-clubs">
                <div className="creative-clubs-img_cover">
                  <img src="/assets/img/artCarft/demo-img.png" alt="img_cover" />
                </div>
                <div className="creative-clubs-card_details">
                  <div className="creative-clubs-card_details_cover">
                    <h5>Home Masters</h5>
                    <p>Mastering the art of home comfort</p>
                    <div className="creative-clubs-users">
                      <div className="creative-clubs-left_part">
                        <div className="creative-clubs-user_img pr-4">
                          <img src="/assets/img/WomansCategory/users.png" alt="user_img" />
                        </div>
                        {[...Array(3)].map((_, index) => {
                          return (
                            <div className="creative-clubs-users_likes" key={index}>
                              <div className="creative-clubs-user_box">
                                <img src="/assets/img/WomansCategory/like.png " alt="user_box" />
                              </div>
                            </div>
                          )
                        })}
                        <div className="creative-clubs-count">+120</div>
                      </div>
                      <div className="creative-clubs-right_part">
                        <button>Join Club</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="art-craft-creative-club-gridItem-details-alignment">
            <div className="creative-clubs-right_slider_cover">
              <div className="card_creative-clubs">
                <div className="creative-clubs-img_cover">
                  <img src="/assets/img/artCarft/appl1.png" alt="img_cover" />
                </div>
                <div className="creative-clubs-card_details">
                  <div className="creative-clubs-card_details_cover">
                    <h5>ApplianceWorks</h5>
                    <p>Works like a charm, every time.</p>
                    <div className="creative-clubs-users">
                      <div className="creative-clubs-left_part">
                        <div className="creative-clubs-user_img pr-4">
                          <img src="/assets/img/WomansCategory/users.png" alt="user_img" />
                        </div>
                        {[...Array(3)].map((_, index) => {
                          return (
                            <div className="creative-clubs-users_likes" key={index}>
                              <div className="creative-clubs-user_box">
                                <img src="/assets/img/WomansCategory/like.png " alt="user_box" />
                              </div>
                            </div>
                          )
                        })}
                        <div className="creative-clubs-count">+120</div>
                      </div>
                      <div className="creative-clubs-right_part">
                        <button>Join Club</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
