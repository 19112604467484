import gql from 'graphql-tag'

const GET_USPS_SERVICES = gql`
  query GetUSPSServices {
    getUSPSServices {
      message
      success
      data {
        service_code
        carrier_code
        carrier_id
        days
        is_domestic
        is_international
        is_multi_package_supported
        name
      }
    }
  }
`
export default GET_USPS_SERVICES
