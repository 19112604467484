import { gql } from '@apollo/client'

const GET_ELASTIC_TYPE_HEAD_SEARCH = gql`
  query elasticTypeHeadSearch($search: String!, $limit: Int) {
    elasticTypeHeadSearch(search: $search, limit: $limit) {
      categoryName
      products {
        id
        title
        price
        slug
        subCategory
        childSubcategory
        images {
          src
          media_id
        }
      }
    }
  }
`
export default GET_ELASTIC_TYPE_HEAD_SEARCH
