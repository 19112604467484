import React, { useEffect, useState } from 'react'
import ShopTrendingProduct from '../ShopTrendingProduct'
import styles from './ShopCubeSlider.module.scss'
import { useAppDispatch } from 'src/store/store'
import { fetchTrendingProducts } from 'src/store/slices/productsDataSlice'

export default function ShopCubeSlider({
  handleOpenModal,
  title,
  description,
  modalName,
  color,
  height,
  isSeeAll = false,
  childSubCategoryId,
  titleColor,
  descriptionColor,
}) {
  const dispatch = useAppDispatch()
  const [product, setProduct] = useState([])

  useEffect(() => {
    dispatch(fetchTrendingProducts({ page: 1, limit: 4, childSubCategoryId: childSubCategoryId }))
      .then((res) => {
        setProduct(res?.payload)
      })
      .catch((error) => {
        console.log('error', error)
      })
  }, [childSubCategoryId])

  return (
    <>
      <div className={styles.trendingBlocBox} style={{ background: color, minHeight: height}}>
        <div className={styles.flexBox}>
          <div className={styles.flexBoxItems}>
            <div className={styles.text}>
              <div>
              <p style={{ color: titleColor }}>{title}</p>
              <span style={{ color: descriptionColor }}>{description}</span>
              </div>
              {isSeeAll && (
                <button onClick={() => handleOpenModal({ modalName, title, description, color, childSubCategoryId })}>
                  See All
                </button>
              )}
            </div>
          </div>
          <div className={styles.flexBoxItems}>
            <ShopTrendingProduct product={product} childSubCategoryId={childSubCategoryId} />
          </div>
        </div>
      </div>
    </>
  )
}
