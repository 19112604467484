import React, { useState } from 'react'
import styles from './MobileFilterModal.module.scss'
import AddFilterDropdownBoxDetails from '../../AddFilterDropdownBox/AddFilterDropdownBoxDetails/AddFilterDropdownBoxDetails'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
export default function MobileFilterModal() {
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)

  const dispatch = useAppDispatch()

  const [selectedCheckboxes, setSelectedCheckboxes] = useState(addProductDetailSlice?.id?.filteredList || [])
  const [filteredData, setFilteredData] = useState(addProductDetailSlice?.id?.filteredList || [])
  const [newVariantData, setNewVariantData] = useState(addProductDetailSlice?.id?.variant?.allOptions || [])
  const [newFilterdDropdown, setNewFilterdDropdown] = useState(false)
  const handleResetData = () => {
    setSelectedCheckboxes([])
    setFilteredData([])
    setNewVariantData(addProductDetailSlice?.id?.variant?.allOptions || [])
  }

  const handleApplyFilter = async () => {
    await dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          newVariantDataState: newVariantData,
          filterColorList: selectedCheckboxes || [],
          filteredList: filteredData || [],
        },
      }),
    )
  }

  return (
    <div className={styles.mobileViewFilterAlignment}>
      <div className={styles.mobileViewFilterDetailsAlignent}>
        <AddFilterDropdownBoxDetails
          selectedCheckboxes={selectedCheckboxes}
          setSelectedCheckboxes={setSelectedCheckboxes}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          handleResetData={handleResetData}
          newVariantData={newVariantData}
          setNewVariantData={setNewVariantData}
          newFilterdDropdown={newFilterdDropdown}
        />
      </div>

      <div className={styles.mobileViewFilterButton} onClick={() => handleApplyFilter()}>
        <button>Apply</button>
      </div>
    </div>
  )
}
