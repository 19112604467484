import React, { useState } from 'react'
import Slider from 'react-slick'
import Gallery from './Gallery'
const RightIcon = '/assets/img/product-detail/right-icon.svg'
const LeftIcon = '/assets/img/product-detail/left-icon.svg'

export default function RatedImages({ setOpenImageModal, product, review }) {
  
  const [selectedRate, setSelectedRate] = useState(0)

  function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <div className="right-full-arrow-alignment" onClick={onClick}>
        <img src={RightIcon} alt="RightIcon" />
      </div>
    )
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <div className="left-full-arrow-alignment" onClick={onClick}>
        <img src={LeftIcon} alt="LeftIcon" />
      </div>
    )
  }

  const settings = {
    className: 'center',
    centerMode: false,
    infinite: true,
    centerPadding: '60px',
    slidesToShow: 3,
    speed: 500,
    rows: 2,
    slidesPerRow: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  const starIcon = (
    <svg width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.06414 1.92489C9.79234 0.468488 11.8707 0.468489 12.5989 1.92489L14.5082 5.7434C14.8036 6.33433 15.3749 6.7385 16.0304 6.82044L19.5455 7.25983C21.1257 7.45735 21.8351 9.34718 20.7752 10.5357L18.2132 13.4086C17.8139 13.8564 17.6396 14.4613 17.7396 15.0529L18.4825 19.4509C18.7519 21.0453 17.0967 22.2706 15.6505 21.5475L11.7152 19.5798C11.1589 19.3017 10.5041 19.3017 9.94783 19.5798L6.01889 21.5443C4.57147 22.268 2.91541 21.0402 3.18731 19.4449L3.93537 15.0559C4.03648 14.4626 3.86173 13.8556 3.46064 13.4069L0.894897 10.5368C-0.167149 9.34874 0.541729 7.4568 2.12298 7.25915L5.6326 6.82044C6.28818 6.7385 6.85942 6.33433 7.15489 5.7434L9.06414 1.92489Z"
        fill="#FF7D23"
      />
    </svg>
  )

  const ratingSection = [
    { id: 6, name: 'All', starIcon: starIcon, ratting: 16 },
    { id: 5, name: '5', starIcon: starIcon, ratting: 5 },
    { id: 4, name: '4', starIcon: starIcon, ratting: 4 },
    { id: 3, name: '3', starIcon: starIcon, ratting: 3 },
    { id: 2, name: '2', starIcon: starIcon, ratting: 2 },
    { id: 1, name: '1', starIcon: starIcon, ratting: 1 },
  ]

  const handleSelect = (rate) => {
    setSelectedRate(rate.id)
  }

  const movetoImageModal = () => {
    setOpenImageModal(false)
  }

  return (
    <div>
      <div className="rate-main-header-for-image">
        <div className="back-sectiona-main">
          <div className="back-button-align" onClick={() => movetoImageModal()}>
            <span>{'< Back'}</span>
          </div>
          <div className="image-main-title-align">
            <span>Media</span>
          </div>
        </div>
        {/* <div className="ratting-menu-section">
          {ratingSection.map((rate) => {
            return (
              <div
                className={selectedRate === rate.id ? 'star-container active-class' : 'star-container'}
                onClick={() => handleSelect(rate)}
              >
                <div className="all-start-align">
                  <span>{rate.name}</span>
                </div>
                <div className="start-icon-align">{rate.starIcon}</div>
                <div className="feedback-total-count">
                  <p>({rate.ratting})</p>
                </div>
              </div>
            )
          })}
        </div> */}

        {/* <div className="rate-main-header-star-alignment">
          <div className="new-review-dropdown-alignment">
            <p>All Stars</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M4 6L8 10L12 6" stroke="#636773" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <div className="new-review-dropdown-alignment">
            <p>Most Recent</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M4 6L8 10L12 6" stroke="#636773" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
        </div> */}
      </div>
      <div>
        {/* <Slider {...settings}>
          {imageArray.map((data, index) => {
            return (
              <div className="main-image-container">
                <div className="image-contaoner-main">
                  <img src={data.image} />
                </div>
                <div className="image-info-main">
                  <div className="image-rate-section">
                    <div className="star-count-section">
                      <span>5</span>
                      <svg width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9.06414 1.92489C9.79234 0.468488 11.8707 0.468489 12.5989 1.92489L14.5082 5.7434C14.8036 6.33433 15.3749 6.7385 16.0304 6.82044L19.5455 7.25983C21.1257 7.45735 21.8351 9.34718 20.7752 10.5357L18.2132 13.4086C17.8139 13.8564 17.6396 14.4613 17.7396 15.0529L18.4825 19.4509C18.7519 21.0453 17.0967 22.2706 15.6505 21.5475L11.7152 19.5798C11.1589 19.3017 10.5041 19.3017 9.94783 19.5798L6.01889 21.5443C4.57147 22.268 2.91541 21.0402 3.18731 19.4449L3.93537 15.0559C4.03648 14.4626 3.86173 13.8556 3.46064 13.4069L0.894897 10.5368C-0.167149 9.34874 0.541729 7.4568 2.12298 7.25915L5.6326 6.82044C6.28818 6.7385 6.85942 6.33433 7.15489 5.7434L9.06414 1.92489Z"
                          fill="#FF7D23"
                        />
                      </svg>
                    </div>
                    <div className="image-count-alignment">
                      <img src={'/assets/img/product-detail/dummy-image-icon.svg'} />
                      <span>2</span>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </Slider> */}
        <Gallery getSingleProduct={review} isGallery={false} />
      </div>
    </div>
  )
}
