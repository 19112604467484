import { gql } from '@apollo/client'

const GET_ALL_ORDERS_DATA_WITH_CUSTOMER_DETAILS = gql`
  query getAllOrdersDataWithCustomerDetails($store_id: Int, $user_id: Int) {
    getAllOrdersDataWithCustomerDetails(store_id: $store_id, user_id: $user_id) {
      success
      message
      data {
        total_orders
        total_spent
        customer {
          id
          firstName
          lastName
          logo_image
          banner_image
          address
          state
          city
          country
          email
          phone
          mobile
          shipping_address {
            streetAddress
            city
            country
            state
            zipcode
          }
        }
      }
    }
  }
`
export default GET_ALL_ORDERS_DATA_WITH_CUSTOMER_DETAILS
