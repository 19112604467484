import { gql } from '@apollo/client'

const GET_ALL_STORE_FOLLOWERS = gql`
  query Query($getAllStoreFollowersId: Int!) {
    getAllStoreFollowers(id: $getAllStoreFollowersId) {
      id
      firstName
      lastName
      # phoneNumber
      # gender
      # bday
      # jobTitle
      followers_count: follower_count
      isFriendForFriendStatus
      isActiveForFriendStatus
      profileUrl
    }
  }
`

export default GET_ALL_STORE_FOLLOWERS
// query GET_FOLLOWERS($id: Int!) {
//   getAllStoreFollowers(id: $id) {
//     id
//     firstName
//     lastName
//     # phoneNumber
//     # gender
//     # bday
//     # jobTitle
//     isFriendForFriendStatus
//     isActiveForFriendStatus
//     profileUrl
//   }
// }
