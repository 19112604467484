import React, { useEffect, useState } from 'react'
import Product from 'src/components/ProductPage/AllProduct/Product'
import { useSelector } from 'react-redux'
import { useMutation, useQuery } from '@apollo/client'
import GET_ALL_BOOKMARK from 'src/graphql/queries/getAllBookmark'
import styles from '../CollectionByProductModal/CollectionByProduct.module.scss'
import REMOVE_PRODUCT_FROM_COLLECTIONS from 'src/graphql/mutations/removeProductFromCollection'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAppDispatch } from 'src/store/store'

export default function MobileViewRemoveBookmarkProduct() {
  const dispatch = useAppDispatch()
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const [splittedArray, setSplittedArray] = useState<any[][]>([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [removedId, setRemovedId] = useState<(string | number)[]>([])
  const { loading, error, data, refetch } = useQuery(GET_ALL_BOOKMARK, {
    variables: {
      slug: fetchMobileModal?.lastModalData?.id,
      page: 1,
      limit: 10,
    },
  })

  let width: number
  if (typeof window !== 'undefined') {
    width = window.innerWidth
  }

  const handleRemoveProduct = (item: any, isUndo: boolean) => {
    if (isUndo) {
      setRemovedId(removedId.filter((id: any) => id !== item?.id))
    } else {
      setRemovedId([...removedId, item?.id])
    }
  }

  const handleRemove = () => {
    if (removedId.length > 0) {
      // removeProductFromBookmark({
      //   variables: {
      //     collectionId: fetchMobileModal?.lastModalData?.id,
      //     productId: removedId,
      //     isRemoveFromCollectionOnly: true,
      //   }
      // })

      let datass = fetchMobileModal?.allModalData?.filter(
        (item: { modal: string }) => item?.modal !== fetchMobileModal?.lastModalData?.modal,
      )
      dispatch(
        fetchMobileModalData({
          allModalData: datass,
          lastModalData: fetchMobileModal?.lastModalData,
        }),
      )
    } else {
      let datass = fetchMobileModal?.allModalData?.filter(
        (item: { modal: string }) => item?.modal !== fetchMobileModal?.lastModalData?.modal,
      )
      dispatch(
        fetchMobileModalData({
          allModalData: datass,
          lastModalData: fetchMobileModal?.lastModalData,
        }),
      )
    }
  }

  const [removeProductFromBookmark] = useMutation(REMOVE_PRODUCT_FROM_COLLECTIONS, {
    onCompleted: (res) => {
      refetch()
      setRemovedId([])
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const spliceIntoChunks = (tempDiscardtweet: any, chunkSize: any) => {
    const resChunk: any = []
    if (tempDiscardtweet?.length > 0) {
      let discardtweet = [...tempDiscardtweet]
      while (discardtweet.length > 0) {
        const chunk = discardtweet && discardtweet?.splice(0, chunkSize)
        resChunk.push(chunk)
        setSplittedArray(resChunk)
      }
    } else {
      setSplittedArray([])
    }
    return resChunk
  }

  useEffect(() => {
    spliceIntoChunks(
      data?.getAllBookmark?.data,
      Math.ceil(data?.getAllBookmark?.data?.length / (width <= 767 ? 2 : isModalVisible ? 3 : 4)),
    )
  }, [data?.getAllBookmark?.data])

  return (
    <>
      <div className={styles.CollectionByProductBox}>
        <div className={styles.bookMarkProductMainFlexAlignment}>
          <div className={styles.bookMarkModalProductNameAlignment}>
            <h6>Remove Product</h6>
          </div>

          <div className={styles.doneDetails} onClick={() => handleRemove()}>
            <a> Done</a>
          </div>
        </div>
        <div className="allproduct-container">
          <div className="store-main-grid">
            <div className="product-details-alignment">
              <div
                className={
                  isModalVisible
                    ? 'product-card-section list-view-grid-section four-col-grid'
                    : ' list-view-grid-section product-card-section'
                }
              >
                {splittedArray?.map((productColumn:any, index) => {
                  return (
                    <>
                      {productColumn?.map((item: any, idx:number) => {
                        return item && item?.id && !removedId.includes(item?.id) ? (
                          <div key={idx} onClick={() => handleRemoveProduct(item, false)}>
                            <Product
                              index={`l${idx}`}
                              product={item}
                              showProduct={{}}
                              storeData={{}}
                              isForPost={false}
                              isRemoveProduct={true}
                            />
                          </div>
                        ) : (
                          <div
                            key={index}
                            onClick={() => handleRemoveProduct(item, true)}
                            className="removeProductAlignment"
                          >
                            <div className="remove-product-details-alignment">
                              <div>
                                <img src="/assets/icon/remove-icon.svg" alt="remove icon" />
                              </div>
                              <h6>Removed from Collection</h6>
                            </div>
                            <div className="undo-button-alignment">
                              <button>Undo</button>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
