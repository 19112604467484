import React from 'react'
import styles from './ProductSizeSelectionModal.module.scss'
import classNames from 'classnames'

export default function ProductSizeSelectionModal({ handleSelectSize }) {
  return (
    <div className={styles.productSizeBoxAlignment}>
      <div className={styles.productMainFlexAlignment}>
        <div className={styles.productChildDetailsAlignment}>
          {/* <h4>Portrait</h4> */}
          <h4></h4>
          <div className={styles.sizesDetailsFlexAlignment}>
            <div
              className={styles.sizesDetailsFlexAlignment}
              onClick={() => handleSelectSize('1 : 1', { width: 44, height: 44 })}
            >
              <div className={styles.squareBox}>
                <span>1 : 1</span>
              </div>
            </div>
            <div
              className={classNames(styles.squareBox, styles.twoByThreeBox)}
              onClick={() => handleSelectSize('2 : 3', { width: 1080, height: 1620 })}
            >
              <span>2 : 3</span>
            </div>
            <div
              className={classNames(styles.squareBox, styles.fourByFive)}
              onClick={() => handleSelectSize('4 : 5', { width: 1080, height: 1350 })}
            >
              <span>4 : 5</span>
            </div>
          </div>
        </div>

        {/* <div className={styles.productChildDetailsAlignment}>
          <h4>Square</h4>
          <div
            className={styles.sizesDetailsFlexAlignment}
            onClick={() => handleSelectSize('1 : 1', { width: 44, height: 44 })}
          >
            <div className={styles.squareBox}>
              <span>1 : 1</span>
            </div>
          </div>
        </div>
        <div className={styles.productChildDetailsAlignment}>
          <h4>Portrait</h4>
          <div className={styles.sizesDetailsFlexAlignment}>
            <div
              className={classNames(styles.squareBox, styles.nineBySixteenBox)}
              onClick={() => handleSelectSize('9 : 16', { width: 1080, height: 1920 })}
            >
              <span>9 : 16</span>
            </div>
            <div
              className={classNames(styles.squareBox, styles.twoByThreeBox)}
              onClick={() => handleSelectSize('2 : 3', { width: 1080, height: 1620 })}
            >
              <span>2 : 3</span>
            </div>
            <div
              className={classNames(styles.squareBox, styles.fourByFive)}
              onClick={() => handleSelectSize('4 : 5', { width: 1080, height: 1350 })}
            >
              <span>4 : 5</span>
            </div>
          </div>
        </div>
        <div className={styles.productChildDetailsAlignment}>
          <h4>Landscape</h4>
          <div className={styles.sizesDetailsFlexAlignment}>
            <div
              className={classNames(styles.squareBox, styles.threeByTwoBox)}
              onClick={() => handleSelectSize('3 : 2', { width: 1620, height: 1080 })}
            >
              <span>3 : 2</span>
            </div>
            <div
              className={classNames(styles.squareBox, styles.sixteenByNineBox)}
              onClick={() => handleSelectSize('16 : 9', { width: 1920, height: 1080 })}
            >
              <span>16 : 9</span>
            </div>
            <div
              className={classNames(styles.squareBox, styles.fiveByFourBOx)}
              onClick={() => handleSelectSize('5 : 4', { width: 1350, height: 1080 })}
            >
              <span>5 : 4</span>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}
