import React from 'react'
import BookBannerImg from '../../../../../../../public/assets/img/bookCover.png'

export default function MoreBookBySection(_, index) {
  return (
    <div className="more-book-alignment" key={index}>
      <div className="more-book-heding-alignment">
        <h5>More Books By Nathan Harris</h5>
        <a>See More</a>
      </div>
      <div className="more-book-details-body-alignment">
        {[0, 1, 2, 3, 4].map((_, i) => {
          return (
            <div className="more-book-box-alignment" key={`t${i}`}>
              <div className="book-img-alignment ">
                <img src={BookBannerImg.src} alt="AuthorImg" />
              </div>
              <div className="more-book-name-alignment">
                <h5>How Innovation Work</h5>
                <p>$21.00</p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
