import React from 'react'
import { useQuery } from '@apollo/client'
import GET_SHIPPING_POLICY from '../../../../../graphql/queries/getShippingPolicy'

const Shipping: React.FC<{
  storeId: number
}> = ({
  storeId
}) => {
  console.log('gdfgdfgdfgfd', storeId)
  const { data, loading } = useQuery(GET_SHIPPING_POLICY, {
    variables: {
      input: {
        store_id: storeId 
      }
    }
  })

  if (loading) return <div>Loading...</div>

  const shippingPolicy = data?.getStoreShippingPolicy?.data?.shippingPolicy || ''

  return (
    <>
      <div className="shipping-section-align">
        <div className="shiiping-heading-alignment">
          <h6>Shipping Policy</h6>
        </div>
        <div className="text-10 text-gray-500 font-normal shipping-section-align-body">
          <div className="shipping-all-details-notes" dangerouslySetInnerHTML={{ __html: shippingPolicy }} />
        </div>
      </div>
      <div className="mobile-view-all-shipping-seaction ">
        <a>Show more</a>
      </div>
    </>
  )
}

export default Shipping
