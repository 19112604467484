import React from 'react'
import styles from './JewelryStoreModal.module.scss'
import { useMutation, useQuery } from '@apollo/client'
import NewStoreSlider from '../../StoresModal/NewStoreSlider'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAppDispatch } from 'src/store/store'
import FOLLOW_STORE from 'src/graphql/mutations/followStore'
import UNFOLLOW_STORE from 'src/graphql/mutations/unFollowStore'
import { notification } from 'antd'
import { useAuth } from 'src/utils/auth'
import { useSelector } from 'react-redux'
import GET_POPULAR_STORES from 'src/graphql/queries/sellerQueries/getAllPopularStores'
import { fetchModalData } from 'src/store/slices/manageModalSlice'

export default function JewelryStoreModal() {
  const dispatch = useAppDispatch()
  const { user }: any = useAuth()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  const { data: storeData, loading, refetch } = useQuery(GET_POPULAR_STORES, {
    variables: {
      category: [1],
      limit: 10,
      page: 1,
    },
  })

  const [followStore] = useMutation(FOLLOW_STORE, {
    onCompleted: () => {
      refetch()
      notification['success']({
        message: 'Success',
        description: 'Store followed successfully',
      })
    },
    onError: (err) => {
      notification['error']({
        message: 'Error',
        description: 'Something went wrong',
      })
    },
  })

  const [unfollowStore] = useMutation(UNFOLLOW_STORE, {
    onCompleted: () => {
      refetch()
      notification['success']({
        message: 'Success',
        description: 'Store unfollowed successfully',
      })
    },
    onError: (err) => {
      notification['error']({
        message: 'Error',
        description: 'Something went wrong',
      })
    },
  })

  const followStoreCall = (store) => {
    if (user) {
      followStore({
        variables: { store_id: store.id },
      })
    } else {
      handleRedirect()
    }
  }

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }

  const unfollowStoreCall = (store) => {
    if (user) {
      unfollowStore({
        variables: { store_id: store.id },
      })
    }
  }

  const handleClose = () => {}

  return (
    <div className={styles.jewelryStoreModalSection}>
      <div className={styles.jewelryStoreModalAlignment}>
        <div className={styles.bookApplianceBackDetailsAlignment} onClick={() => handleClose()}>
          <div>
            <img src="/assets/icon/left-icon.svg" alt="back-page" />
          </div>
          <p>Trending...</p>
          <div className={styles.mobileHeadingAlignment}>
            <h4>Now Trending</h4>
          </div>
        </div>
        <div className={styles.jewelryStoreModalBannerAlignment}>
          <h4>Trending Store</h4>
          {/* <div className={styles.jewelryStoreBg}>
            <img src="/assets/jewelry/store-bg.png" alt="store-bg" />
            <div className={styles.jewelryBannerDetailsAlignment}>
              <div className={styles.logoAlignment}>
                <img src="/assets/jewelry/jewelry-logo.svg" alt="logo" />
              </div>
              <p>Explore Our Stunning Jewelry Collection Now!</p>
            </div>
            <div className={styles.jewelryArrowAlignment}>
              <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                <path
                  d="M13.4165 32.5832L32.5832 13.4165"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.4165 13.4165H32.5832V32.5832"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div> */}
        </div>

        <div className={styles.jewelryStoreModalAllDetailsAlignment}>
          {loading ? (
            <div>Loading...</div>
          ) : storeData?.getAllPopularStores?.data?.stores?.length > 0 ? (
            <div className={styles.jewelryStoreModalAllDetailsGrid}>
              {storeData.getAllPopularStores.data.stores.map((store, index) => (
                <React.Fragment key={index}>
                  <NewStoreSlider
                    store={store}
                    followStoreCall={followStoreCall}
                    unfollowStoreCall={unfollowStoreCall}
                    handleAddStoreLike={() => {}}
                  />
                </React.Fragment>
              ))}
            </div>
          ) : (
            <div>No stores found</div>
          )}
        </div>
      </div>
    </div>
  )
}
