import React from 'react'
import dynamic from 'next/dynamic'
import { TwitterVideoEmbed } from 'react-twitter-embed'

const ReactPlayerFind = dynamic(() => import('react-player'), { ssr: false }) as any

export default function PostContent(props) {
  const { content } = props
  const regex = /(?:(?:https?|ftp):\/\/)?[\w/\-?=%.]+\.[\w/\-&?=%.]+/
  const urlInPostContent = content?.match(regex)
  let data = content?.split('/')
  // let youtube_id = content.includes('youtube') ? content?.split('=')[1] : content.split('/')[3]

  return (
    <div className="text-sm font-normal w-full link-size-video post-description">
      {content?.split(' ').map((part, index) => {
      if (part.includes('https://twitter.com')) {
        return (
          <div key={index} style={{ width: '100%', maxWidth: '100%' }}>
        <TwitterVideoEmbed id={part.split('/').pop()} />
          </div>
        )
      } else if (part.includes('https://media.tenor.com/')) {
        return (
          <div key={index} style={{ display: 'inline-block', width: '48%', margin: '1%' }}>
        <img src={part} alt="Tenor GIF" style={{ width: '100%', maxWidth: '100%' }} />
          </div>
        )
      } else if (part.includes('https://')) {
        return (
          <iframe
        key={index}
        src={
          part.includes('youtube')
            ? `//www.youtube.com/embed/${part.split('=')[1]}`
            : `https://player.vimeo.com/video/${part.split('/')[3]}`
        }
        frameBorder="0"
        allowFullScreen
        height="350px"
        style={{ width: '100%', maxWidth: '100%' }}
          ></iframe>
        )
      } else {
        return <span key={index}>{part} </span>
      }
      })}
    </div>
  )
}
