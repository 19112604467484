import { gql } from '@apollo/client'
import { GROUP_DETAILS, MESSAGE_DETAILS } from '../fragments/fragments'

export const GET_ALL_USERS = gql`
  query {
    getAllUsers {
      id
      firstName
      lastName
      #profileAvtar
      logo_image
      createdAt
      userName
      mutedParticipants
      latestMessage {
        ...MessageDetails
      }
    }
  }
  ${MESSAGE_DETAILS}
`

export const GET_GROUPS = gql`
  query GetGroups {
    getGroups {
      id
      name
      admin
      type
      participants
      createdAt
      mutedParticipants
      latestMessage {
        id
        body
        conversationId
        senderId
        isSeen
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
          profileAvtar
        }
      }
      adminUser {
        id
        firstName
        lastName
        profileAvtar
        isAdmin
      }
      users {
        id
        firstName
        lastName
        profileAvtar
      }
      invitedUsers {
        id
        firstName
        lastName
        profileAvtar
        isInvitedUser
      }
    }
  }
`

export const GET_GROUP_INVITED_USER = gql`
  query {
    getAllGroupInvitedUsers {
      id
      name
      admin
      type
      participants
      createdAt
      latestMessage {
        id
        body
        conversationId
        senderId
        isSeen
        createdAt
        user {
          id
          firstName
          lastName
          profileAvtar
        }
      }
      adminUser {
        id
        firstName
        lastName
        profileAvtar
        isAdmin
      }
      groupInvitedUser {
        id
        firstName
        lastName
        profileAvtar
      }
      users {
        id
        firstName
        lastName
        profileAvtar
      }
      invitedUsers {
        id
        firstName
        lastName
        profileAvtar
        isInvitedUser
      }
    }
  }
`

export const GET_ALL_GROUP_USER = gql`
  query GetAllGroupUsers($group_id: Int!) {
    getAllGroupUsers(group_id: $group_id) {
      id
      firstName
      lastName
      createdAt
      profileAvtar
      logo_image
      isInvited
    }
  }
`

export const GET_PRIVATE_MSGS = gql`
  query fetchPrivateMsgs($userId: Int!) {
    getPrivateMessages(userId: $userId) {
      id
      body
      conversationId
      senderId
      isSeen
      images
      isEdited
      createdAt
      reactions {
        id
        user_id
        emoji_id
        message_id
      }
      user {
        id
        firstName
        lastName
        profileAvtar
      }
      shared_content {
        id
        message_id
        product_id
        group_id
        store_id
        type
        product {
          id
          store_id
          slug
          title
          status
          dis_price
          dis_listPrice
          images {
            media_id
            src
            id
            position
          }
        }
      }
      replyMessage {
        body
        conversationId
        createdAt
        id
        senderId
        user {
          firstName
          id
          lastName
          profileAvtar
        }
      }
    }
  }
`

export const GET_GROUP_MSGS = gql`
  query fetchGroupMsgs($conversationId: Int!) {
    getGroupMessages(conversationId: $conversationId) {
      id
      body
      conversationId
      senderId
      isSeen
      createdAt
      mutedParticipants
      user {
        id
        firstName
        lastName
        profileAvtar
      }
      reactions {
        id
        user_id
        emoji_id
        message_id
      }
      replyMessage {
        body
        conversationId
        createdAt
        id
        senderId
        user {
          firstName
          id
          lastName
          profileAvtar
        }
      }
    }
  }
`

export const GET_GLOBAL_MSGS = gql`
  query {
    getGlobalMessages {
      ...MessageDetails
    }
  }
  ${MESSAGE_DETAILS}
`
