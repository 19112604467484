import React from 'react'
import { useStateContext } from 'src/utils/state'

export default function AddPersonalMessageModal({ userData, setPersonalMessageModal }) {
  const { selectChat } = useStateContext()

  return (
    <div className="select-account-modal-alignment">
      <div className="select-account-white-box-alignment">
        <div className="select-account-sticky">
          <div className="select-account-heading-alignment">
            <h4>Select Account</h4>
            <div className="header-close-alignment" onClick={() => setPersonalMessageModal(false)}>
              <img src="/assets/icon/close-white.svg" alt="close icon" />
            </div>
          </div>
          <div className="select-search-alignment">
            <div className="select-account-search-alignment">
              <div>
                <img src="/assets/icon/search.svg" alt="search" />
              </div>
              <input type="text" placeholder="Search" />
            </div>
          </div>
        </div>
        <div className="select-acount-modal-body-alignment">
          {userData?.getMutualFollowing?.data
            .filter((user) => !user?.latestMessage)
            ?.map((u, index) => {
              return (
                <div
                  className="account-details-alignment"
                  onClick={() => {
                    selectChat(
                      {
                        firstName: u?.user?.firstName,
                        lastName: u?.user?.lastName,
                        id: u?.user?.id,
                        profileAvtar: [u?.user?.logo_image],
                        userName: u?.user?.userName,
                        city: u?.user?.city
                      },
                      'private',
                    )
                    setPersonalMessageModal(false)
                  }}
                  key={index}
                >
                  <div className="account-profile-img-alignment">
                    <img src={u?.user?.logo_image} alt="profile img" />
                  </div>
                  <p>{u?.user?.firstName + ' ' + u?.user?.lastName}</p>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}
