import React, { useEffect, useState } from 'react'
import styles from './JewelryUpdatedModal.module.scss'
import { useRouter } from 'next/router'
import { fetchProductByCategory, setProductByCategoryState } from 'src/store/slices/fetchAllProduct'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import AllProduct from 'src/components/ProductPage/AllProduct'
const Newarrivalcartoon = '/assets/img/arrivaltitle/arrivalcartoon.png'

export default function JewelryUpdatedModal() {
  const router = useRouter()
  const [page, setPage] = useState(1)
  const [dataLimite, setDataLimite] = useState(40)
  const [maxPrice, setMaxPrice] = useState(1000) // Set initial max value
  const [minPrice, setMinPrice] = useState(1)
  const getAllState = useSelector((state: any) => state?.manageMobileModal?.data)
  const productByCategory = useSelector((state: any) => state?.productByCategory)

  const dispatch = useAppDispatch()

  useEffect(() => {
    setPage(1)
    fetchProduct(maxPrice, minPrice)
  }, [page])

  const fetchProduct = (maxPrice:number, minPrice:number) => {
    dispatch(
      fetchProductByCategory({
        category: 'Jewelry',
        subCategory: router?.asPath?.includes('women') ? 'Women' : 'Men',
        childSubCategory: router?.query?.category ? router?.query?.category : '',
        isShopifyProduct: false,
        page: page,
        limit: dataLimite,
        maxPrice: maxPrice,
        minPrice: minPrice,
      }),
    )
  }

  const fetchUpdatedProduct = (product_id:any, type:any) => {
    let update_product = []
    if (type === 'price') {
      setMaxPrice(product_id?.maxValue)
      setMinPrice(product_id?.minValue)
      fetchProduct(product_id?.maxValue, product_id?.minValue)
    } else if (type === 'dislike') {
      update_product = productByCategory?.product?.getAllProducts?.total?.map((prd:any) => {
        if (prd?.id === product_id) {
          return {
            ...prd,
            likes: null,
          }
        } else {
          return prd
        }
      })
    } else if (type === 'like') {
      update_product = productByCategory?.product?.getAllProducts?.total?.map((prd:any) => {
        if (prd?.id === product_id) {
          return {
            ...prd,
            likes: {
              id: 3.3333,
            },
          }
        } else {
          return prd
        }
      })
    }

    dispatch(
      setProductByCategoryState({
        getAllProducts: {
          data: update_product,
        },
      }),
    )
    // fetchProduct()
  }

  return (
    <>
      <div className={styles.Newarrivaltitlemain} style={{ background: getAllState?.lastModalData?.id?.color }}>
        <div className={styles.Newarrivaltitletextmain}>
          <h4>{getAllState?.lastModalData?.id?.title}</h4>
          <p>{getAllState?.lastModalData?.id?.description}</p>
        </div>

        <div className={styles.Newarrivalcartoon}>
          <img src={Newarrivalcartoon} alt="Newarrivalcartoon" />
        </div>
      </div>
      <div className={styles.allBookMarkDetailsBodyAlignment}>
        <AllProduct
          products={productByCategory?.product?.getAllProducts?.data}
          storeId={0}
          color={'#FFFFFF'}
          isFilterMenu={true}
          isPadding={true}
          total={productByCategory?.product?.getAllProducts?.total}
          dataLimite={dataLimite}
          setPage={setPage}
          page={page}
          topForFilter={0}
          mobileTopForFilter={0}
          isCalled={productByCategory?.called}
          APIcall={fetchUpdatedProduct}
        />
        {/* {data?.getAllBookmark?.data?.length ? (
          <div className={styles.allBookMarkDetailsGrid}>
            {data?.getAllBookmark?.data?.map((item, index) => {
              return (
                <BookMarkProductCard
                  item={item}
                  index={index}
                  isInCollection={false}
                  handleRedirect={handleRedirect}
                  normalPostLikeHandler={normalPostLikeHandler}
                  bookmarkProduct={bookmarkProduct}
                  handleAddToCollection={handleAddToCollection}
                  isFilter={true}
                />
              )
            })}
          </div>
        ) : (
          <div className={styles.noDataBookMarkCollectionDetails}>
            <img src="/assets/icon/no-bookmark-product-data.svg" alt="no data" />
            <p>No item saved on bookmark.</p>
          </div>
        )} */}
      </div>
    </>
  )
}
