import React from 'react'
import { useSelector } from 'react-redux'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAppDispatch } from 'src/store/store'

export default function MessageMediaPreview() {
  const dispatch = useAppDispatch()
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)

  return (
    <div className="media-all-details-modal-alignment">
      <div className="media-details-modal-wrapper">
        <div className="media-details-heading-alignment">
          {/* <div className="media-details-profile-alignment">
            <div className="media-profile-img-alignment">
              <img src="/assets/img/mpi1.png" alt="profile" />
            </div>
            <h6>Marvin McKinney</h6>
          </div> */}
            <div
            className="media-close-button-alignment"
            style={{ marginLeft: 'auto' }}
            onClick={() => {
              dispatch(
              fetchMobileModalData({
                ...fetchMobileModal,
                messageMediaPreviewModal: {},
              }),
              )
            }}
            >
            <img src="/assets/icon/close-white.svg" alt="close icon" />
            </div>
        </div>

        <div className="media-main-img-alignment">
          <img src={fetchMobileModal?.messageMediaPreviewModal?.id?.selectedImage} alt="media profile" />
        </div>

        <div className="media-other-option-alignment">
          {/* <div className="media-option-flex"> 
            <img src="/assets/icon/forward-icon.svg" alt="forward icon" />
            <div>
              <p>Forward</p>
            </div>
          </div>
          <div className="media-option-flex">
            <img src="/assets/icon/download-icon.svg" alt="forward icon" />
            <div>
              <p>Download</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
