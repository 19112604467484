import { Modal } from 'antd'
import React from 'react'

export default function DiyRoomDetailsModal({
  DiyImgModal,
  setDiyImgModal,
}: {
  DiyImgModal: boolean
  setDiyImgModal: (val: boolean) => void
}) {
  // const { DiyImgModal, setDiyImgModal } = props

  const handleCancelModal = () => {
    setDiyImgModal(false)
  }

  return (
    <div className="diyRoomDetailsWeb-view-modal-alignment">
      <Modal
        open={DiyImgModal}
        onCancel={handleCancelModal}
        footer={null}
        centered
        closable={false}
        width={1276}
        wrapClassName="post-add-edit-modal highlight-modal-align"
        maskStyle={{ zIndex: 9999 }}
      >
        <div className="diy-room-details-modal-wrapper ">
          <div className="diy-room-details-modal-box-alignment">
            <div className="diy-room-details-grid-alignment">
              <div className="diy-room-left-side-alignment">
                <div className="diy-room-left-side-first-img-alignment">
                  <img src="/assets/img/artCarft/dry1.png" alt="DRYimg" />
                </div>

                <div className="diy-room-second-img-alignment">
                  <div className="diy-child-img-alignment">
                    <img src="/assets/img/artCarft/dry2.png" alt="DRYimg" />
                  </div>
                  <div className="diy-child-img-alignment">
                    <img src="/assets/img/artCarft/dry3.png" alt="DRYimg" />
                  </div>
                </div>
              </div>

              <div className="diy-room-right-side-alignment">
                <h6>Dreamy Paper Cut Lamp</h6>
                <div className="diy-room-by-details-alignment">
                  <span>by</span>
                  <div className="author-details-alignment">
                    <div className="author-img-alignment">
                      <img src="/assets/img/artCarft/dry1.png" alt="DRYimg" />
                    </div>
                    <p>Annete Black</p>
                  </div>
                </div>
                <div className="diy-room-all-details-alignment">
                  <span>4 Items you Need :</span>
                  <div className="diy-room-hignt-bg-alignment">
                    <div className="diy-room-hight-alignment">
                      {[0, 1, 2, 3, 4, 5, 6].map((_, index) => {
                        return (
                          <div className="diy-room-all-details-flex-alignment" key={index}>
                            <div className="diy-room-product-details-alignment">
                              <div className="diy-room-product-img-alignment">
                                <img src="/assets/img/artCarft/dry1.png" alt="DRYimg" />
                              </div>

                              <div className="diy-room-product-child-details-alignment">
                                <h6>Coloured paper: The UltimateThe UltimateThe UltimateThe UltimateThe Ultimate</h6>

                                <div className="diy-room-rate-alignment">
                                  <span>4.8</span>
                                  <div className="diy-room-starIcon-alignment">
                                    <img src="/assets/icon/orange-star.svg" alt="StarIcon" />
                                    <img src="/assets/icon/orange-star.svg" alt="StarIcon" />
                                    <img src="/assets/icon/orange-star.svg" alt="StarIcon" />
                                    <img src="/assets/icon/orange-star.svg" alt="StarIcon" />
                                    <img src="/assets/icon/orange-star.svg" alt="StarIcon" />
                                  </div>
                                </div>

                                <div className="diy-rrom-price-alignment">
                                  <p>$24</p>
                                </div>
                              </div>
                            </div>
                            <div className="diy-room-other-option-alignment">
                              <img src="/assets/icon/bookmark.svg" alt="BookMarkIcon" />
                              <img src="/assets/icon/shopping-bag.svg" alt="ShoppingBagIcon" />
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
