
import React from 'react'
import { useSelector } from 'react-redux'

import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useStateContext } from 'src/utils/state'


export default function MobileMessageSelected({
  handleEdit,
  handleRemove,
  sendReaction }) {
  const dispatch = useAppDispatch()
  const { selectedChat, notify, updateName, selectChat } = useStateContext()
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const selectedMessage = fetchMobileModal?.lastModalData?.id?.message



  const handleReplyMessage = (msg) => {
    selectChat({ ...selectedChat?.chatData, replyMessageId: msg?.id }, selectedChat?.chatType)
    closeMessageModal()
  }

  const editMessage = () => {
    handleEdit(selectedMessage)
    closeMessageModal()
  }

  const handleSendReaction = (id) => {
    sendReaction(selectedMessage, id)
    closeMessageModal()
  }
  const closeMessageModal = () => {
    dispatch(
      fetchMobileModalData({
        allModalData: [
          ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
          {
            modal: 'chatBoxModal',
            isOpen: true,
            id: {
              selectedMessageId: null,
              isMessageSelected: false,
            },
            transform: 42,
            zIndex: 9999999,
          },
        ],
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
  }
  return (
    <>
      <div
        className={`mobile-message-selected-alignment-Overlay ${fetchMobileModal?.lastModalData?.id?.isMessageSelected ? 'open' : ''
          }`}
        onClick={() =>
          dispatch(
            fetchMobileModalData({
              allModalData: [
                ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                {
                  modal: 'chatBoxModal',
                  isOpen: true,
                  id: {
                    selectedMessageId: null,
                    isMessageSelected: false,
                  },
                  transform: 42,
                  zIndex: 9999999,
                },
              ],
              lastModalData: fetchMobileModal?.lastModalData,
            }),
          )
        }
      />
      <div
        className={`mobile-message-selected-alignment--modal ${fetchMobileModal?.lastModalData?.id?.isMessageSelected ? 'open' : ''
          }`}
      >
        <div className="mobile-view-reply-story-rection-alignment">
          <div className="mobile-view-reply-story-rection-grid">
            <div className="mobile-view-reply-rection-box-alignment" onClick={() => handleSendReaction(1)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 23.131C6.22759 23.131 0 19.5103 0 11.5655C0 3.62069 6.22759 0 12 0C15.2069 0 18.1655 1.05517 20.3586 2.97931C22.7379 5.08965 24 8.06897 24 11.5655C24 15.0621 22.7379 18.0207 20.3586 20.131C18.1655 22.0552 15.1862 23.131 12 23.131Z"
                  fill="url(#paint0_radial_465_18896)"
                />
                <path
                  d="M7.94521 7.02197C7.07831 7.02197 6.29004 7.75439 6.29004 8.97094C6.29004 10.1875 7.07831 10.9178 7.94521 10.9178C8.81418 10.9178 9.60038 10.1854 9.60038 8.97094C9.60038 7.75646 8.82245 7.02197 7.94521 7.02197Z"
                  fill="#422B0D"
                />
                <path
                  d="M7.87242 7.83532C7.57863 7.69464 7.22484 7.81877 7.08208 8.11257C6.97242 8.34222 7.02208 8.61739 7.20621 8.79532C7.50001 8.93601 7.8538 8.81188 7.99656 8.51808C8.10621 8.28843 8.05656 8.01326 7.87242 7.83532Z"
                  fill="#896024"
                />
                <path
                  d="M15.8895 7.02197C15.0227 7.02197 14.2344 7.75439 14.2344 8.97094C14.2344 10.1875 15.0227 10.9178 15.8895 10.9178C16.7564 10.9178 17.5447 10.1854 17.5447 8.97094C17.5447 7.75646 16.7564 7.02197 15.8895 7.02197Z"
                  fill="#422B0D"
                />
                <path
                  d="M15.807 7.83532C15.5132 7.69464 15.1594 7.81877 15.0166 8.11257C14.907 8.34222 14.9566 8.61739 15.1408 8.79532C15.4346 8.93601 15.7884 8.81188 15.9311 8.51808C16.0408 8.28843 15.9911 8.01326 15.807 7.83532Z"
                  fill="#896024"
                />
                <path
                  d="M11.8756 17.5058C9.999 17.5202 8.20728 16.732 6.95142 15.3375C6.85625 15.2278 6.83349 15.0727 6.89349 14.9402C6.95349 14.8058 7.0859 14.7189 7.2328 14.7168C7.299 14.7168 7.36521 14.7354 7.42314 14.7685C8.42038 15.3416 10.088 16.0533 11.8756 16.0533H11.9045C13.69 16.0533 15.3597 15.3416 16.3549 14.7685C16.4128 14.7354 16.479 14.7168 16.5452 14.7168C16.6921 14.7189 16.8245 14.8058 16.8845 14.9402C16.9466 15.0727 16.9238 15.2278 16.8266 15.3375C15.5707 16.732 13.7749 17.5223 11.8983 17.5058"
                  fill="#422B0D"
                />
                <path
                  d="M21.9085 4.69043C23.0113 6.46974 23.5864 8.58836 23.5864 10.9449C23.5864 14.4415 22.3244 17.4001 19.9451 19.5104C17.752 21.4346 14.7726 22.5104 11.5864 22.5104C7.84989 22.5104 3.93127 20.9897 1.61816 17.7642C3.84437 21.418 8.03196 23.1311 12.0002 23.1311C15.1864 23.1311 18.1658 22.0553 20.3589 20.1311C22.7382 18.0208 24.0002 15.0622 24.0002 11.5656C24.0002 8.92974 23.2823 6.58767 21.9085 4.69043Z"
                  fill="#EB8F00"
                />
                <defs>
                  <radialGradient
                    id="paint0_radial_465_18896"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(12 11.5655) scale(11.7848)"
                  >
                    <stop offset="0.5" stop-color="#FDE030" />
                    <stop offset="0.92" stop-color="#F7C02B" />
                    <stop offset="1" stop-color="#F4A223" />
                  </radialGradient>
                </defs>
              </svg>
            </div>
            <div className="mobile-view-reply-rection-box-alignment" onClick={() => handleSendReaction(2)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 23.131C6.22759 23.131 0 19.5103 0 11.5655C0 3.62069 6.22759 0 12 0C15.2069 0 18.1655 1.05517 20.3586 2.97931C22.7379 5.08965 24 8.06897 24 11.5655C24 15.0621 22.7379 18.0207 20.3586 20.131C18.1655 22.0552 15.1862 23.131 12 23.131Z"
                  fill="url(#paint0_radial_465_18880)"
                />
                <path
                  d="M21.9085 4.69043C23.0113 6.46974 23.5864 8.58836 23.5864 10.9449C23.5864 14.4415 22.3244 17.4001 19.9451 19.5104C17.752 21.4346 14.7726 22.5104 11.5864 22.5104C7.84989 22.5104 3.93127 20.9897 1.61816 17.7642C3.84437 21.418 8.03196 23.1311 12.0002 23.1311C15.1864 23.1311 18.1658 22.0553 20.3589 20.1311C22.7382 18.0208 24.0002 15.0622 24.0002 11.5656C24.0002 8.92974 23.2823 6.58767 21.9085 4.69043Z"
                  fill="#EB8F00"
                />
                <path
                  d="M12.1657 15.7862C11.425 15.7862 10.6905 15.662 9.99327 15.4137C9.13465 15.1179 8.20155 15.5751 7.90569 16.4317C7.7381 16.9158 7.80638 17.4496 8.08982 17.8758C8.97948 19.2413 10.3864 19.9862 12.1657 19.9862C13.945 19.9862 15.3519 19.2413 16.2415 17.8758C16.7443 17.1206 16.5374 16.1006 15.7822 15.5979C15.356 15.3144 14.8222 15.2462 14.3381 15.4137C13.6409 15.662 12.9064 15.7862 12.1657 15.7862Z"
                  fill="#422B0D"
                />
                <path
                  opacity="0.8"
                  d="M5.91659 17.0068C7.91624 17.0068 9.53728 15.4784 9.53728 13.593C9.53728 11.7076 7.91624 10.1792 5.91659 10.1792C3.91694 10.1792 2.2959 11.7076 2.2959 13.593C2.2959 15.4784 3.91694 17.0068 5.91659 17.0068Z"
                  fill="url(#paint1_radial_465_18880)"
                />
                <path
                  opacity="0.8"
                  d="M18.4137 17.0068C20.4133 17.0068 22.0343 15.4784 22.0343 13.593C22.0343 11.7076 20.4133 10.1792 18.4137 10.1792C16.414 10.1792 14.793 11.7076 14.793 13.593C14.793 15.4784 16.414 17.0068 18.4137 17.0068Z"
                  fill="url(#paint2_radial_465_18880)"
                />
                <path
                  d="M21.1018 5.66295C19.4032 5.18502 18.1556 6.57329 18.1556 6.57329C18.1556 6.57329 18.0107 4.99881 16.5583 4.50433C14.8163 3.90639 12.977 4.85812 12.8673 7.32639C12.7432 10.1381 16.4632 13.8292 16.4632 13.8292C16.4632 13.8292 21.0811 12.5878 22.5935 9.95605C23.8245 7.81467 22.3866 6.02295 21.1018 5.66295Z"
                  fill="#F44336"
                />
                <path
                  d="M21.1017 5.66293C20.8928 5.60707 20.6776 5.58224 20.4624 5.58638C21.0707 5.99397 21.5528 6.56293 21.8528 7.22914C22.1445 7.93052 22.221 8.83672 21.6666 9.91879C20.4645 12.2712 16.7631 13.7278 16.4941 13.8291C16.8314 13.734 21.1472 12.4926 22.6017 9.96224C23.8245 7.81466 22.3866 6.02293 21.1017 5.66293Z"
                  fill="#C62828"
                />
                <path
                  d="M18.1528 6.82758C18.1404 6.6124 18.1073 6.39723 18.0576 6.1862C17.9376 5.57792 17.5611 5.05033 17.0231 4.73792C16.6549 4.52275 16.2473 4.3862 15.8252 4.33447C15.8252 4.33447 16.4459 4.52275 16.8886 5.38137C17.1142 5.8324 17.2486 6.32482 17.2817 6.82758C17.2797 7.01171 17.3128 7.19171 17.379 7.36344C17.4535 7.53516 17.6355 7.63654 17.8217 7.60758C18.1052 7.53516 18.1673 7.18137 18.1528 6.82758Z"
                  fill="#C62828"
                />
                <path
                  d="M14.1306 5.84089C14.5175 5.36089 15.1526 4.90365 15.7609 5.32572C16.0837 5.55124 16.0878 6.19055 15.6926 6.47813C15.0264 6.96641 14.9954 7.2871 14.9209 7.55606C14.8299 7.87675 14.7719 8.25951 14.4802 8.42089C14.1885 8.58227 13.9319 8.42089 13.7788 7.95951C13.5181 7.23951 13.6506 6.43675 14.1306 5.84089Z"
                  fill="#FF847A"
                />
                <path
                  d="M7.26614 4.46264C5.58821 5.00678 5.31924 6.85437 5.31924 6.85437C5.31924 6.85437 4.32614 5.61299 2.83649 6.01437C1.05924 6.48195 0.0557962 8.29437 1.32407 10.4068C2.79304 12.8275 7.93235 13.8309 7.93235 13.8309C7.93235 13.8309 11.0875 10.2392 10.8889 7.21023C10.7255 4.74402 8.53442 4.05092 7.26614 4.46264Z"
                  fill="#F44336"
                />
                <path
                  d="M7.26595 4.46265C7.06112 4.52886 6.86457 4.62403 6.68457 4.74403C7.41698 4.7461 8.13285 4.953 8.75354 5.34196C9.38664 5.75576 9.95147 6.47576 10.0922 7.6861C10.3963 10.3116 8.12043 13.5744 7.94457 13.8123C8.17423 13.5475 11.077 10.1213 10.8866 7.20817C10.7253 4.74403 8.53423 4.05093 7.26595 4.46265Z"
                  fill="#C62828"
                />
                <path
                  d="M5.46269 7.07157C5.33441 6.89571 5.18958 6.73433 5.02821 6.58951C4.59372 6.14675 3.99165 5.91088 3.37303 5.94399C2.9489 5.96882 2.53303 6.07847 2.15234 6.27089C2.15234 6.27089 2.77303 6.08261 3.61717 6.55433C4.05786 6.80882 4.44062 7.1502 4.74683 7.55571C4.84614 7.71089 4.97441 7.8433 5.12545 7.94882C5.28269 8.05019 5.48958 8.03157 5.62821 7.90537C5.81234 7.68192 5.66958 7.35502 5.46269 7.07157Z"
                  fill="#C62828"
                />
                <path
                  d="M1.57222 8.48268C1.5805 8.30889 1.61153 8.13716 1.66326 7.97165C1.74808 7.62406 1.99429 7.33648 2.32326 7.19785C2.63981 7.09854 3.07843 7.16889 3.24808 7.48544C3.39912 7.76682 3.28946 8.10613 3.2067 8.41648C3.13222 8.77234 3.09084 9.13647 3.08257 9.50061C3.08464 9.79854 2.8467 10.0406 2.54877 10.0448C2.26739 10.0448 2.04808 9.80061 1.89084 9.56682C1.67153 9.2482 1.55981 8.86958 1.57222 8.48268Z"
                  fill="#FF847A"
                />
                <defs>
                  <radialGradient
                    id="paint0_radial_465_18880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(12 11.5655) scale(11.7848)"
                  >
                    <stop offset="0.5" stop-color="#FDE030" />
                    <stop offset="0.92" stop-color="#F7C02B" />
                    <stop offset="1" stop-color="#F4A223" />
                  </radialGradient>
                  <radialGradient
                    id="paint1_radial_465_18880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(5.91659 12.0228) scale(3.94591 3.54326)"
                  >
                    <stop stop-color="#ED7770" />
                    <stop offset="0.9" stop-color="#ED7770" stop-opacity="0" />
                  </radialGradient>
                  <radialGradient
                    id="paint2_radial_465_18880"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(24.3143 521.501) scale(4.02621)"
                  >
                    <stop stop-color="#ED7770" />
                    <stop offset="0.9" stop-color="#ED7770" stop-opacity="0" />
                  </radialGradient>
                </defs>
              </svg>
            </div>
            <div className="mobile-view-reply-rection-box-alignment" onClick={() => handleSendReaction(3)}>
              <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.3241 0.666748C12.429 0.666748 10.9979 4.73433 10.9979 4.73433C10.9979 4.73433 9.58633 0.666748 5.66456 0.666748C2.71701 0.666748 -0.642982 3.05389 0.597905 8.48036C1.83879 13.9068 11.0086 20.6668 11.0086 20.6668C11.0086 20.6668 20.1445 13.9068 21.3872 8.48036C22.6281 3.05389 19.5099 0.666748 16.3241 0.666748Z"
                  fill="#F44336"
                />
                <path
                  d="M5.06258 1.07212C8.12036 1.07212 9.63858 4.43119 10.1292 5.79189C10.1986 5.98628 10.4635 5.99173 10.5435 5.80279L10.9915 4.73276C10.3604 2.67262 8.62525 0.666992 5.66347 0.666992C4.43681 0.666992 3.14081 1.0812 2.13281 1.95503C3.02348 1.35734 4.06703 1.07212 5.06258 1.07212Z"
                  fill="#CC3333"
                />
                <path
                  d="M16.3243 0.666992C15.3839 0.666992 14.527 0.875912 13.8586 1.29739C14.3279 1.11208 14.9092 1.07212 15.4852 1.07212C18.367 1.07212 20.9466 3.30483 19.7821 8.46607C18.783 12.897 12.9448 18.2726 11.1617 20.3236C11.0479 20.4544 11.0088 20.6652 11.0088 20.6652C11.0088 20.6652 20.1448 13.9053 21.3874 8.47878C22.6283 3.05413 19.5154 0.666992 16.3243 0.666992Z"
                  fill="#CC3333"
                />
                <path
                  d="M2.64391 3.54556C3.31058 2.69534 4.50169 1.99228 5.51146 2.80253C6.05724 3.24035 5.81902 4.1378 5.33013 4.51567C4.61902 5.06795 4.00035 5.4004 3.56658 6.26515C3.30702 6.78473 3.15058 7.35335 3.07058 7.93288C3.03858 8.16178 2.74525 8.2181 2.63502 8.01645C1.8848 6.65756 1.67147 4.78636 2.64391 3.54556Z"
                  fill="#FF8A80"
                />
                <path
                  d="M13.3318 5.33317C13.0189 5.33317 12.7984 5.02433 12.9122 4.72639C13.1238 4.17775 13.3975 3.64727 13.7264 3.17493C14.2135 2.47732 15.1398 2.07038 15.7264 2.49367C16.3273 2.92605 16.2491 3.79079 15.8278 4.20863C14.9229 5.10426 13.7815 5.33317 13.3318 5.33317Z"
                  fill="#FF8A80"
                />
              </svg>
            </div>
            <div className="mobile-view-reply-rection-box-alignment" onClick={() => handleSendReaction(4)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.16631 7.86281C7.06938 8.94365 7.00137 10.8564 7.61181 11.6706C7.61181 11.6706 7.32444 9.5694 9.90055 6.93307C10.9378 5.87179 11.1776 4.4283 10.8154 3.34569C10.6096 2.73238 10.2338 2.22574 9.90736 1.87198C9.71691 1.66399 9.86314 1.32089 10.1403 1.33334C11.8169 1.41156 14.5342 1.89864 15.6887 4.92784C16.1954 6.25755 16.2329 7.63171 15.9914 9.02898C15.8384 9.92138 15.2942 11.9053 16.5355 12.1488C17.4214 12.323 17.8499 11.5871 18.0421 11.0573C18.122 10.8369 18.3992 10.7818 18.5488 10.9578C20.0452 12.7372 20.1727 14.8331 19.8632 16.6375C19.2647 20.1253 15.886 22.6639 12.5294 22.6639C8.33618 22.6639 4.99829 20.1556 4.13279 15.6153C3.7842 13.7825 3.96105 10.156 6.66469 7.59616C6.86533 7.40416 7.19351 7.57482 7.16631 7.86281Z"
                  fill="url(#paint0_radial_465_18907)"
                />
                <path
                  d="M14.0624 14.3856C12.5168 12.3057 13.2088 9.93248 13.588 8.98675C13.639 8.86231 13.503 8.74498 13.3959 8.82142C12.731 9.29429 11.369 10.4071 10.7347 11.9733C9.87604 14.0905 9.93725 15.1269 10.4457 16.3926C10.7517 17.1553 10.3964 17.317 10.2178 17.3455C10.0444 17.3739 9.88454 17.253 9.75701 17.1268C9.39142 16.7624 9.12786 16.2877 9.00203 15.7758C8.97483 15.6655 8.83709 15.6353 8.77418 15.726C8.29807 16.414 8.05151 17.5179 8.0396 18.2983C8.0022 20.7106 9.90835 22.6661 12.2141 22.6661C15.1201 22.6661 17.2371 19.3063 15.5673 16.4975C15.0827 15.6798 14.627 15.1447 14.0624 14.3856Z"
                  fill="url(#paint1_radial_465_18907)"
                />
                <defs>
                  <radialGradient
                    id="paint0_radial_465_18907"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(11.6989 22.7192) rotate(-179.74) scale(12.0028 20.5893)"
                  >
                    <stop offset="0.3144" stop-color="#FF9800" />
                    <stop offset="0.6616" stop-color="#FF6D00" />
                    <stop offset="0.9715" stop-color="#F44336" />
                  </radialGradient>
                  <radialGradient
                    id="paint1_radial_465_18907"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(12.3739 10.2327) rotate(90.5536) scale(13.1293 9.45129)"
                  >
                    <stop offset="0.2141" stop-color="#FFF176" />
                    <stop offset="0.3275" stop-color="#FFF27D" />
                    <stop offset="0.4868" stop-color="#FFF48F" />
                    <stop offset="0.6722" stop-color="#FFF7AD" />
                    <stop offset="0.7931" stop-color="#FFF9C4" />
                    <stop offset="0.8221" stop-color="#FFF8BD" stop-opacity="0.804" />
                    <stop offset="0.8627" stop-color="#FFF6AB" stop-opacity="0.529" />
                    <stop offset="0.9101" stop-color="#FFF38D" stop-opacity="0.2088" />
                    <stop offset="0.9409" stop-color="#FFF176" stop-opacity="0" />
                  </radialGradient>
                </defs>
              </svg>
            </div>
            <div className="mobile-view-reply-rection-box-alignment" onClick={() => handleSendReaction(5)}>
              <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.8104 0.502277L15.5025 6.6426C15.7085 7.11263 16.1545 7.43465 16.6666 7.48065L23.2249 8.06868C23.967 8.17669 24.263 9.08673 23.7249 9.61076L18.7847 13.761C18.3847 14.097 18.2026 14.625 18.3127 15.1351L19.7487 21.8574C19.8747 22.5955 19.1007 23.1595 18.4367 22.8095L12.7124 19.4573C12.2723 19.1993 11.7283 19.1993 11.2883 19.4573L5.56397 22.8075C4.90194 23.1555 4.1259 22.5935 4.2519 21.8554L5.68798 15.1331C5.79599 14.623 5.61598 14.095 5.21595 13.759L0.273691 9.61276C-0.262337 9.09073 0.0336787 8.17869 0.773718 8.07068L7.33207 7.48265C7.8441 7.43665 8.29012 7.11463 8.49613 6.6446L11.1883 0.504277C11.5223 -0.167759 12.4783 -0.167759 12.8104 0.502277Z"
                  fill="#FDD835"
                />
                <path
                  d="M12.6149 7.01052L12.1589 2.48628C12.1409 2.23427 12.0889 1.80225 12.4929 1.80225C12.8129 1.80225 12.9869 2.46828 12.9869 2.46828L14.355 6.10048C14.871 7.48255 14.659 7.95657 14.161 8.23659C13.5889 8.55661 12.7449 8.30659 12.6149 7.01052Z"
                  fill="#FFFF8D"
                />
                <path
                  d="M18.2563 13.3592L22.1806 10.297C22.3746 10.135 22.7246 9.877 22.4446 9.58298C22.2226 9.35097 21.6225 9.68498 21.6225 9.68498L18.1883 11.0271C17.1643 11.3811 16.4843 11.9051 16.4243 12.5651C16.3462 13.4452 17.1363 14.1232 18.2563 13.3592Z"
                  fill="#F4B400"
                />
              </svg>
            </div>
            <div className="mobile-view-reply-rection-box-alignment" onClick={() => handleSendReaction(6)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.6498 23.5939C14.7847 23.5798 11.986 23.5114 9.23264 23.2702C7.33728 23.1033 5.37924 22.84 3.89235 22.0559C3.47308 21.8348 3.0149 21.7805 2.6086 21.7342C2.4357 21.7141 2.27362 21.6961 2.1353 21.6659C1.08064 21.4347 0.343675 20.5863 0.339352 19.6032L0.324224 13.0192C0.322063 12.1085 1.02013 11.3466 1.98618 11.2059C2.00347 11.2038 2.0186 11.1998 2.03588 11.1958C2.15475 11.1596 4.99455 10.2992 7.13413 8.74313C8.14124 8.01135 9.00356 6.88352 9.44228 5.72755C9.87884 4.5756 9.87668 3.3191 9.87451 2.30989C9.87451 1.65852 9.87235 0.848339 10.0625 0.729726C10.525 0.446262 11.0351 0.301514 11.5775 0.301514C13.246 0.301514 14.949 1.71884 15.016 3.16229C15.0635 4.16347 14.7026 5.34557 14.3547 6.48747C13.944 7.84046 13.5874 9.0085 13.9095 9.8026C13.957 9.92121 14.078 9.99761 14.212 9.99761C14.2163 9.99761 15.7875 9.94735 15.7875 9.94735H15.8027C17.5662 9.94735 19.0012 11.2822 19.0034 12.9207L19.0142 21.4066C19.0142 21.9916 18.7721 22.5404 18.3269 22.9525C17.8839 23.3647 17.2939 23.5939 16.665 23.5939H16.6498Z"
                  fill="url(#paint0_radial_465_18919)"
                />
                <path
                  d="M11.5775 0.603116C13.0601 0.603116 14.6312 1.90183 14.6918 3.1744C14.7371 4.12732 14.3849 5.28531 14.0434 6.40509C13.6155 7.80834 13.2481 9.02061 13.6068 9.90719C13.7019 10.1424 13.944 10.2972 14.2141 10.2972H14.2379L15.794 10.2469H15.8069C17.3911 10.2469 18.6813 11.4451 18.6835 12.9208L18.6943 21.4066C18.6943 21.9092 18.4846 22.3836 18.1021 22.7395C17.7196 23.0953 17.2117 23.2923 16.6692 23.2923H16.652C14.7933 23.2783 12.0032 23.2099 9.26286 22.9707C7.40424 22.8078 5.4851 22.5505 4.05223 21.7946C3.57677 21.5433 3.06241 21.485 2.64962 21.4368C2.48537 21.4187 2.33193 21.4006 2.2109 21.3724C1.3032 21.1734 0.667812 20.4456 0.66565 19.6033L0.648361 13.0193C0.6462 12.2593 1.22972 11.622 2.03584 11.5054C2.06826 11.5014 2.10284 11.4934 2.1331 11.4833C2.25412 11.4471 5.14579 10.5686 7.33076 8.9804C8.38325 8.21444 9.28663 7.03635 9.7448 5.82811C10.2008 4.62791 10.1987 3.34327 10.1965 2.30993C10.1965 1.8194 10.1943 1.15597 10.2851 0.954934C10.6828 0.719719 11.1172 0.603116 11.5775 0.603116ZM11.5775 0C11.0048 0 10.4256 0.144748 9.88312 0.478472C9.18289 0.908695 9.94147 3.49807 9.13535 5.62506C8.65124 6.90166 7.72841 7.92696 6.9331 8.50394C4.81513 10.0439 1.93643 10.9084 1.93643 10.9084C0.819095 11.0712 -0.00215693 11.9678 4.2555e-06 13.0213L0.017294 19.6053C0.0216163 20.7392 0.875286 21.6981 2.06178 21.9595C2.54156 22.064 3.1964 22.0359 3.73238 22.3193C5.16524 23.0752 7.00225 23.3768 9.20234 23.5698C11.9254 23.809 14.6918 23.8794 16.6476 23.8955H16.6692C18.1453 23.8955 19.3448 22.7817 19.3426 21.4066L19.3318 12.9208C19.3297 11.1094 17.7498 9.64584 15.8069 9.64584H15.781L14.2163 9.6961C13.676 8.3612 15.446 5.35768 15.3423 3.14827C15.2666 1.57815 13.4664 0 11.5775 0Z"
                  fill="#EDA600"
                />
                <path
                  d="M12.4574 11.6986C12.8637 12.7038 12.868 14.2719 12.4574 15.2409C11.8631 16.6401 10.4086 17.8644 8.72935 18.0795C8.31873 18.1318 8.56294 18.4836 8.97141 18.4313C10.789 18.1981 13.2484 17.5126 13.8881 15.9948C15.643 11.8232 13.5899 9.63791 12.6735 10.2893C12.2326 10.6069 12.2283 11.1296 12.4574 11.6986Z"
                  fill="#EDA600"
                />
                <path
                  d="M16.814 13.1901C15.0072 13.1901 12.8353 12.8785 12.7423 11.3888C12.7164 10.9626 12.8547 10.6047 13.1659 10.2971C13.985 9.48897 15.9971 9.04468 18.8326 9.04468C19.8613 9.04468 20.6112 9.107 20.6199 9.107C20.6285 9.107 20.6393 9.10901 20.648 9.10901H20.6674C20.6977 9.107 20.7258 9.107 20.756 9.107C21.6745 9.107 22.4417 9.87698 22.5044 10.8601C22.5692 11.8773 21.8496 12.7458 20.9008 12.7961C20.8857 12.7961 20.8684 12.7981 20.8533 12.8021C20.8338 12.8041 18.8326 13.1901 16.814 13.1901Z"
                  fill="url(#paint1_radial_465_18919)"
                />
                <path
                  d="M18.8353 9.34628C19.8468 9.34628 20.5859 9.40659 20.5924 9.40659C20.6118 9.4086 20.6313 9.4086 20.6507 9.4086C20.6637 9.4086 20.6767 9.4086 20.6875 9.40659C20.7113 9.40458 20.735 9.40458 20.7566 9.40458C21.5023 9.40458 22.129 10.0499 22.1809 10.8722C22.2068 11.2984 22.0771 11.7025 21.8156 12.0121C21.5714 12.3015 21.2386 12.4704 20.882 12.4905C20.8496 12.4925 20.8172 12.4966 20.7869 12.5026C20.7675 12.5066 18.7943 12.8866 16.8146 12.8866C15.6649 12.8866 14.7529 12.7619 14.1067 12.5167C13.2184 12.1789 13.0866 11.7004 13.0671 11.3707C13.0455 11.027 13.1536 10.7515 13.4043 10.5023C14.1499 9.76846 16.1295 9.34628 18.8353 9.34628ZM18.8353 8.74316C16.3673 8.74316 12.274 9.09096 12.4188 11.4069C12.5247 13.0796 14.6945 13.4917 16.8146 13.4917C18.8915 13.4917 20.9209 13.0957 20.9209 13.0957C22.049 13.0333 22.9049 12.0241 22.8292 10.84C22.7579 9.69408 21.8416 8.80348 20.7588 8.80348C20.7221 8.80348 20.6875 8.80348 20.6507 8.80549C20.6507 8.80549 19.8922 8.74316 18.8353 8.74316Z"
                  fill="#EDA600"
                />
                <path
                  d="M18.1694 17.0156C15.8115 17.0156 13.002 16.6477 13.002 14.8906C13.002 13.1335 15.8115 12.7656 18.1694 12.7656C20.1685 12.7656 21.9903 13.037 22.0076 13.039C22.0249 13.041 22.0422 13.0431 22.0595 13.0431C23.0126 13.0431 23.6761 13.9799 23.6761 14.8202C23.6761 15.7068 22.9694 16.7361 22.0595 16.7361C22.0422 16.7361 22.0249 16.7382 22.0076 16.7402C21.9882 16.7442 20.1663 17.0156 18.1694 17.0156Z"
                  fill="url(#paint2_radial_465_18919)"
                />
                <path
                  d="M18.1693 13.0694C20.1403 13.0694 21.9384 13.3368 21.9557 13.3388C21.9903 13.3448 22.0249 13.3469 22.0595 13.3469C22.7986 13.3469 23.3518 14.1269 23.3518 14.8225C23.3518 15.5482 22.7554 16.4368 22.0595 16.4368C22.0249 16.4368 21.9903 16.4388 21.9557 16.4449C21.9384 16.4469 20.1382 16.7143 18.1693 16.7143C16.6932 16.7143 15.5197 16.5695 14.679 16.282C13.4666 15.8679 13.3261 15.2889 13.3261 14.8908C13.3261 14.4908 13.4666 13.9138 14.679 13.4996C15.5175 13.2142 16.6932 13.0694 18.1693 13.0694ZM18.1693 12.4663C15.591 12.4663 12.6777 12.9066 12.6777 14.8928C12.6777 16.8791 15.5889 17.3194 18.1693 17.3194C20.2203 17.3194 22.0595 17.0419 22.0595 17.0419C23.1746 17.0419 24.0002 15.8538 24.0002 14.8245C24.0002 13.7952 23.1746 12.7458 22.0595 12.7458C22.0595 12.7437 20.2181 12.4663 18.1693 12.4663Z"
                  fill="#EDA600"
                />
                <path
                  d="M19.9652 23.6987C19.0553 23.6987 14.4455 23.6323 13.0494 22.3316C12.8052 22.1044 12.6885 21.8592 12.6885 21.5817C12.6928 20.5263 14.6357 19.9211 18.018 19.9211C19.2239 19.9211 20.1575 20.0016 20.1683 20.0016C20.177 20.0016 20.1878 20.0036 20.1964 20.0036C20.7022 20.0056 21.1798 20.1966 21.5364 20.5444C21.8973 20.8942 22.094 21.3606 22.094 21.8551C22.0896 22.8704 21.236 23.6946 20.19 23.6946C20.1748 23.6987 20.0992 23.6987 19.9652 23.6987Z"
                  fill="url(#paint3_radial_465_18919)"
                />
                <path
                  d="M18.0199 20.2245C19.2064 20.2245 20.1292 20.3029 20.1379 20.3049C20.1573 20.3069 20.1768 20.3069 20.1962 20.3069C20.6133 20.3089 21.0067 20.4677 21.3027 20.7552C21.6053 21.0487 21.7717 21.4407 21.7696 21.8569C21.7674 22.7053 21.0585 23.3968 20.1832 23.3968H20.1811H20.1681C20.1638 23.3968 20.0903 23.3989 19.9628 23.3989C16.0402 23.3989 13.9093 22.7093 13.2761 22.1202C13.0362 21.8971 13.0103 21.7081 13.0103 21.5855C13.0167 20.7451 14.9337 20.2245 18.0199 20.2245ZM18.0199 19.6213C15.7031 19.6213 12.3705 19.9108 12.3662 21.5815C12.3576 23.8854 18.6985 24 19.965 24C20.1054 24 20.1832 23.9979 20.1832 23.9979H20.1897C21.4173 23.9979 22.4136 23.041 22.4179 21.8569C22.4222 20.6708 21.4281 19.7058 20.1984 19.7018C20.1984 19.7038 19.2539 19.6213 18.0199 19.6213Z"
                  fill="#EDA600"
                />
                <path
                  d="M19.1571 20.5079C17.2141 20.5079 12.6973 20.3029 12.6973 18.4031C12.6973 16.8068 15.9542 16.5696 17.8971 16.5696C19.6152 16.5696 21.0956 16.7465 21.1108 16.7485C21.1237 16.7505 21.1389 16.7505 21.1518 16.7505C22.053 16.7505 23.1034 17.1647 23.1034 18.3327C23.1034 19.4384 22.1741 20.4436 21.1518 20.4436H21.1259C21.1173 20.4456 20.2852 20.5079 19.1571 20.5079Z"
                  fill="url(#paint4_radial_465_18919)"
                />
                <path
                  d="M17.897 16.2695V16.8726C19.5893 16.8726 21.0545 17.0476 21.0675 17.0496C21.0956 17.0536 21.1237 17.0536 21.1518 17.0536C21.9385 17.0536 22.7792 17.3893 22.7792 18.3342C22.7792 19.265 21.9882 20.1435 21.1518 20.1435C21.1345 20.1435 21.1172 20.1435 21.0978 20.1456C21.0891 20.1456 20.27 20.2079 19.1549 20.2079C17.977 20.2079 16.2934 20.1375 14.96 19.8078C13.6741 19.4882 13.0214 19.0157 13.0214 18.4066C13.0214 18.2196 13.0214 17.6507 14.3916 17.2506C15.2453 17.0013 16.4231 16.8747 17.8949 16.8747L17.897 16.2695ZM17.897 16.2695C15.3987 16.2695 12.373 16.6354 12.373 18.4046C12.373 20.4893 16.5658 20.811 19.157 20.811C20.3154 20.811 21.1518 20.7467 21.1518 20.7467C22.3707 20.7467 23.4275 19.5746 23.4275 18.3342C23.4275 17.0938 22.3707 16.4505 21.1518 16.4505C21.1518 16.4505 19.6541 16.2695 17.897 16.2695Z"
                  fill="#EDA600"
                />
                <path d="M4.1735 22.2325L3.13829 18.4329L2.70605 21.8505L4.1735 22.2325Z" fill="#EDA600" />
                <defs>
                  <radialGradient
                    id="paint0_radial_465_18919"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(9.35563 5.44631) rotate(90.1343) scale(17.4983 14.8097)"
                  >
                    <stop offset="0.6" stop-color="#FFCA28" />
                    <stop offset="1" stop-color="#FFB300" />
                  </radialGradient>
                  <radialGradient
                    id="paint1_radial_465_18919"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(20.4172 10.9419) rotate(176.955) scale(6.10869 2.78173)"
                  >
                    <stop offset="0.5993" stop-color="#FFCA28" />
                    <stop offset="1" stop-color="#FFB300" />
                  </radialGradient>
                  <radialGradient
                    id="paint2_radial_465_18919"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(21.3825 14.8377) rotate(-179.941) scale(6.87652 2.56517)"
                  >
                    <stop offset="0.5993" stop-color="#FFCA28" />
                    <stop offset="1" stop-color="#FFB300" />
                  </radialGradient>
                  <radialGradient
                    id="paint3_radial_465_18919"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(20.0415 21.7699) rotate(-179.745) scale(5.77494 2.7968)"
                  >
                    <stop offset="0.5993" stop-color="#FFCA28" />
                    <stop offset="1" stop-color="#FFB300" />
                  </radialGradient>
                  <radialGradient
                    id="paint4_radial_465_18919"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(20.8636 18.4909) rotate(-179.939) scale(6.5795 2.54575)"
                  >
                    <stop offset="0.5993" stop-color="#FFCA28" />
                    <stop offset="1" stop-color="#FFB300" />
                  </radialGradient>
                </defs>
              </svg>
            </div>
            <div className="mobile-view-reply-rection-box-alignment" onClick={() => sendReaction(selectedMessage, 7)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 23.131C6.22759 23.131 0 19.5103 0 11.5655C0 3.62069 6.22759 0 12 0C15.2069 0 18.1655 1.05517 20.3586 2.97931C22.7379 5.08966 24 8.06897 24 11.5655C24 15.0621 22.7379 18.0207 20.3586 20.131C18.1655 22.0552 15.1862 23.131 12 23.131Z"
                  fill="url(#paint0_radial_465_18935)"
                />
                <path
                  d="M21.9085 4.69019C23.0113 6.4695 23.5864 8.58812 23.5864 10.9447C23.5864 14.4412 22.3244 17.3998 19.9451 19.5102C17.752 21.4343 14.7726 22.5102 11.5864 22.5102C7.84989 22.5102 3.93127 20.9895 1.61816 17.764C3.84437 21.4178 8.03196 23.1309 12.0002 23.1309C15.1864 23.1309 18.1658 22.055 20.3589 20.1309C22.7382 18.0205 24.0002 15.0619 24.0002 11.5654C24.0002 8.9295 23.2823 6.58743 21.9085 4.69019Z"
                  fill="#EB8F00"
                />
                <path
                  d="M7.94521 7.02222C7.07831 7.02222 6.29004 7.75463 6.29004 8.97118C6.29004 10.1877 7.07831 10.9181 7.94521 10.9181C8.81418 10.9181 9.60038 10.1857 9.60038 8.97118C9.60038 7.7567 8.82245 7.02222 7.94521 7.02222Z"
                  fill="#422B0D"
                />
                <path
                  d="M7.87242 7.83508C7.57863 7.69439 7.22484 7.81853 7.08208 8.11232C6.97242 8.34198 7.02208 8.61715 7.20621 8.79508C7.50001 8.93577 7.8538 8.81163 7.99656 8.51784C8.10621 8.28818 8.05656 8.01301 7.87242 7.83508Z"
                  fill="#896024"
                />
                <path
                  d="M15.8895 7.02222C15.0227 7.02222 14.2344 7.75463 14.2344 8.97118C14.2344 10.1877 15.0227 10.9181 15.8895 10.9181C16.7564 10.9181 17.5447 10.1857 17.5447 8.97118C17.5447 7.7567 16.7564 7.02222 15.8895 7.02222Z"
                  fill="#422B0D"
                />
                <path
                  d="M15.807 7.83508C15.5132 7.69439 15.1594 7.81853 15.0166 8.11232C14.907 8.34198 14.9566 8.61715 15.1408 8.79508C15.4346 8.93577 15.7884 8.81163 15.9311 8.51784C16.0408 8.28818 15.9911 8.01301 15.807 7.83508Z"
                  fill="#896024"
                />
                <path
                  d="M12.1204 14.7288C13.9969 14.7122 15.7928 15.5026 17.0486 16.897C17.1438 17.0067 17.1666 17.1619 17.1066 17.2943C17.0466 17.4288 16.9141 17.5157 16.7672 17.5177C16.701 17.5177 16.6348 17.4991 16.5769 17.466C15.5797 16.8929 13.9121 16.1812 12.1245 16.1812H12.0955C10.31 16.1812 8.64035 16.8929 7.64518 17.466C7.58725 17.4991 7.52104 17.5177 7.45484 17.5177C7.30794 17.5157 7.17759 17.4288 7.11759 17.2943C7.05553 17.1619 7.07828 17.0067 7.17553 16.897C8.43139 15.5026 10.2252 14.7122 12.1017 14.7288"
                  fill="#422B0D"
                />
                <defs>
                  <radialGradient
                    id="paint0_radial_465_18935"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(12 11.5655) scale(11.7848 11.7848)"
                  >
                    <stop offset="0.5" stop-color="#FDE030" />
                    <stop offset="0.92" stop-color="#F7C02B" />
                    <stop offset="1" stop-color="#F4A223" />
                  </radialGradient>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <div className="more-option-modal-body-details-alignment">
          <div className="more-option-modal-grid-alignment">
            <div className="more-option-modal-gridItem-alignment">
              <div
                className="more-option-modal-box-alignment"
                onClick={() => {
                  dispatch(
                    fetchMobileModalData({
                      ...fetchMobileModal,
                      secondModal: {
                        modal: 'removeMessageModal',
                        isOpen: true,
                        id: 0,
                        transform: 350,
                        zIndex: 99999999,
                        modalFor: 'mobileSecondModal',
                      },
                    }),
                  )
                }}
              >
                <div>
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.25 11.5L10.75 11.5L22.75 11.5"
                      stroke="#E71616"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M21.25 11.5L21.25 22C21.25 22.3978 21.092 22.7794 20.8107 23.0607C20.5294 23.342 20.1478 23.5 19.75 23.5L12.25 23.5C11.8522 23.5 11.4706 23.342 11.1893 23.0607C10.908 22.7794 10.75 22.3978 10.75 22L10.75 11.5M13 11.5L13 10C13 9.60218 13.158 9.22064 13.4393 8.93934C13.7206 8.65804 14.1022 8.5 14.5 8.5L17.5 8.5C17.8978 8.5 18.2794 8.65804 18.5607 8.93934C18.842 9.22064 19 9.60218 19 10L19 11.5"
                      stroke="#E71616"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path d="M14.5 15.25L14.5 19.75" stroke="#E71616" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17.5 15.25L17.5 19.75" stroke="#E71616" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <p className="delete-details">Delete</p>
              </div>
            </div>
            <div className="more-option-modal-gridItem-alignment" onClick={() => handleReplyMessage(selectedMessage)}>
              <div className="more-option-modal-box-alignment active-message-selected">
                <div>
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.75 17.4995L10 13.7495L13.75 9.99951"
                      stroke="#636773"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22 21.9995L22 16.7495C22 15.9539 21.6839 15.1908 21.1213 14.6282C20.5587 14.0656 19.7956 13.7495 19 13.7495L10 13.7495"
                      stroke="#636773"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p>Reply</p>
              </div>
            </div>
            {/* <div className="more-option-modal-gridItem-alignment">
              <div className="more-option-modal-box-alignment">
                <div>
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_465_18957)">
                      <path
                        d="M22 13.75H15.25C14.4216 13.75 13.75 14.4216 13.75 15.25V22C13.75 22.8284 14.4216 23.5 15.25 23.5H22C22.8284 23.5 23.5 22.8284 23.5 22V15.25C23.5 14.4216 22.8284 13.75 22 13.75Z"
                        stroke="#636773"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.75 18.25H10C9.60218 18.25 9.22064 18.092 8.93934 17.8107C8.65804 17.5294 8.5 17.1478 8.5 16.75V10C8.5 9.60218 8.65804 9.22064 8.93934 8.93934C9.22064 8.65804 9.60218 8.5 10 8.5H16.75C17.1478 8.5 17.5294 8.65804 17.8107 8.93934C18.092 9.22064 18.25 9.60218 18.25 10V10.75"
                        stroke="#636773"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_465_18957">
                        <rect width="18" height="18" fill="white" transform="translate(7 7)" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <p>Copy</p>
              </div>
            </div> */}
            <div className="more-option-modal-gridItem-alignment" onClick={() => editMessage()}>
              <div
                className="more-option-modal-box-alignment"
                onClick={() => {
                  // dispatch(
                  //   fetchMobileModalData({
                  //     ...fetchMobileModal,
                  //     secondModal: {
                  //       modal: 'messageForwardModal',
                  //       isOpen: true,
                  //       id: 0,
                  //       transform: 30,
                  //       zIndex: 99999999,
                  //       modalFor: 'mobileSecondModal',
                  //     },
                  //   }),
                  // )
                }}
              >
                <div className='mt-2'>
                  <img src="/assets/icon/message-edit-icon.svg" alt="left arrow" />
                </div>
                <p>Edit</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
