import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { APPLIANCE_CATEGORY } from 'src/Helpers/Json/ApplianceCategory'
import { ART_CRAFT_CATEGORY } from 'src/Helpers/Json/ArtCraftCategory'
import { BEAUTY_COSMETICS_CATEGORY } from 'src/Helpers/Json/BeautyCosmetics'
import { JEWELRY_CATEGORY } from 'src/Helpers/Json/JewelryCategoryList'
import { MEN_CATEGORY, MEN_STYLE } from 'src/Helpers/Json/ManCategoryList'
import { WOMEN_CATEGORY, WOMEN_FIND_YOUR_STYLE } from 'src/Helpers/Json/WomanCategoryList'

interface manageApplianceModalData {
  loading: true | false
  error: {} | null
  called: true | false
  data: any
}

const initialState = {
  loading: false,
  error: null,
  data: { isEdit: false, modal: '', id: 0, for: '' },
  called: false,
} as manageApplianceModalData

export const fetchApplianceCategory = createAsyncThunk(
  'fetchApplianceData/fetchApplianceCategory',
  async (data: any) => {
    let filterData

    if (data?.id !== '') {
      if (data?.for === 'men') {
        if (data?.type === 'trending') {
          return data
        } else if (data?.type === 'styleDetail') {
          filterData = MEN_STYLE.find((category) => category?.name === data?.id)
        } else if (data?.type === 'club') {
          return data
        } else if (data?.type === 'store') {
          return data
        } else if (data?.type === 'influencers') {
          return data
        } else if (data?.type === 'style') {
          return data
        } else {
          filterData = MEN_CATEGORY.find((category) => category?.name === data?.id)
        }
      }

      if (data?.for === 'women') {
        if (data?.type === 'trending') {
          return data
        } else if (data?.type === 'styleDetail') {
          filterData = WOMEN_FIND_YOUR_STYLE.find((category) => category?.name === data?.id)
        } else if (data?.type === 'club') {
          return data
        } else if (data?.type === 'store') {
          return data
        } else if (data?.type === 'influencers') {
          return data
        } else if (data?.type === 'style') {
          return data
        } else {
          filterData = WOMEN_CATEGORY.find((category) => category?.name === data?.id)
        }
      }

      if (data?.for === 'appliance') {
        filterData = APPLIANCE_CATEGORY.find((category) => category?.name === data?.id)
      }

      if (data?.for === 'beautyCosmetics') {
        filterData = BEAUTY_COSMETICS_CATEGORY.find((category) => category?.name === data?.id)
      }

      if (data?.for === 'artCraft') {
        if (data?.type === 'diy') {
          return data
        } else {
          filterData = ART_CRAFT_CATEGORY.find((category) => category?.name === data?.id)
        }
      }

      if (data?.for === 'jewelry') {
        if (data?.type === 'trending') {
          return data
        } else if (data?.type === 'styleDetail') {
          filterData = MEN_STYLE.find((category) => category?.name === data?.id)
        } else if (data?.type === 'club') {
          return data
        } else if (data?.type === 'store') {
          return data
        } else if (data?.type === 'fans') {
          return data
        } else if (data?.type === 'style') {
          return data
        } else {
          filterData = JEWELRY_CATEGORY.find((category) => category?.name === data?.id)
        }
      }

      if (filterData) {
        let finalData = { ...data, id: filterData }
        return finalData
      }
    }
  },
)

const manageApplianceModalData = createSlice({
  name: 'fetchApplianceData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchApplianceCategory.pending, (state, action) => {
      state.loading = true
      state.error = null
      state.called = true
    })
    builder.addCase(fetchApplianceCategory.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
      state.called = true
    })
    builder.addCase(fetchApplianceCategory.rejected, (state, action) => {
      state.loading = false
      state.called = false
      state.error = action.error
      console.error('Error fetching cards!')
    })
  },
})

// export var _actions = manageApplianceModalData.actions
export default manageApplianceModalData.reducer
