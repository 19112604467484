export const translations = {
  en: {
    // Navigation
    dashboard: 'Dashboard',
    social: 'Social',
    insights: 'Insights',
    products: 'Products',
    customers: 'Customers',
    settings: 'Settings',
    orders: 'Orders',
    shipping: 'Shipping',
    
    // User Profile
    viewProfile: 'View Profile',
    savedItems: 'Saved Items',
    myOrders: 'My Orders',
    paymentMethods: 'Payment Methods',
    logout: 'Logout',
    storeOwner: 'Store Owner',
    
    // Stats
    totalSales: 'Total Sales',
    totalOrders: 'Total Orders',
    newCustomers: 'New Customers',
    avgOrderValue: 'Avg. Order Value',
    
    // Products
    addProduct: 'Add Product',
    editProduct: 'Edit Product',
    deleteSelected: 'Delete Selected',
    searchProducts: 'Search products...',
    inStock: 'In Stock',
    lowStock: 'Low Stock',
    edit: 'Edit',
    delete: 'Delete',
    
    // Product Modal
    step: 'Step',
    general: 'General',
    // shipping: 'Shipping',
    options: 'Options',
    seo: 'SEO',
    productImages: 'Product Images',
    setPrimary: 'Set as Primary',
    removeImage: 'Remove Image',
    uploadImage: 'Upload Image',
    productName: 'Product Name',
    enterProductName: 'Enter product name',
    sku: 'SKU',
    enterSku: 'Enter SKU',
    category: 'Category',
    selectCategory: 'Select Category',
    subcategory: 'Subcategory',
    selectSubcategory: 'Select Subcategory',
    subSubcategory: 'Sub-subcategory',
    selectSubSubcategory: 'Select Sub-subcategory',
    price: 'Price',
    listPrice: 'List Price',
    quantity: 'Quantity',
    description: 'Description',
    writeDescription: 'Write a detailed product description...',
    
    // Product Modal - Shipping
    shippingMethods: 'Shipping Methods',
    chooseShippingMethod: 'Choose how you want to ship this product',
    flatRate: 'Flat Rate',
    flatRateDesc: 'Charge a fixed rate for shipping',
    rate: 'Rate',
    freeShipping: 'Free Shipping',
    freeShippingDesc: 'No shipping charges',
    weightBased: 'Weight Based',
    weightBasedDesc: 'Calculate shipping based on weight',
    internationalShipping: 'International Shipping',
    selectRegions: 'Select regions where this product can be shipped',
    baseRate: 'base rate',
    packageDetails: 'Package Details',
    enterPackageDetails: 'Enter the package dimensions and weight',
    weight: 'Weight',
    dimensions: 'Dimensions',
    
    // Product Modal - Options
    variants: 'Variants',
    addVariant: 'Add Variant',
    variantType: 'Variant Type',
    variantValues: 'Variant Values',
    attributes: 'Attributes',
    addAttribute: 'Add Attribute',
    attributeName: 'Attribute Name',
    attributeValue: 'Attribute Value',
    customOptions: 'Custom Options',
    addOption: 'Add Option',
    
    // Product Modal - SEO
    seoTitle: 'SEO Title',
    enterSeoTitle: 'Enter SEO title',
    seoTitleDesc: 'Recommended length: 50-60 characters',
    metaDescription: 'Meta Description',
    enterMetaDescription: 'Enter meta description',
    metaDescriptionDesc: 'Recommended length: 150-160 characters',
    urlHandle: 'URL Handle',
    enterUrlHandle: 'Enter URL handle',
    focusKeywords: 'Focus Keywords',
    enterKeywords: 'Enter keywords separated by commas',
    keywordsDesc: 'Add keywords that describe your product',
    openGraphImage: 'Open Graph Image',
    uploadFile: 'Upload a file',
    dragDrop: 'or drag and drop',
    imageRequirements: 'PNG, JPG up to 5MB',
    
    // Product Modal - Buttons
    saveProduct: 'Save Product',
    cancel: 'Cancel',
    next: 'Next',
    previous: 'Previous',
    
    // Common
    welcome: 'Welcome back',
    search: 'Search...',
    comingSoon: 'Coming soon...',
    shippingDashboard: 'Shipping dashboard and tracking information will be displayed here.',
    month: 'month',
    current: 'Current',
    upgrade: 'Upgrade',
    save: 'Save Changes',
  },
  es: {
    // Navigation
    dashboard: 'Panel',
    social: 'Social',
    insights: 'Análisis',
    products: 'Productos',
    customers: 'Clientes',
    settings: 'Ajustes',
    orders: 'Pedidos',
    shipping: 'Envíos',
    
    // User Profile
    viewProfile: 'Ver Perfil',
    savedItems: 'Elementos Guardados',
    myOrders: 'Mis Pedidos',
    paymentMethods: 'Métodos de Pago',
    logout: 'Cerrar Sesión',
    storeOwner: 'Dueño de la Tienda',
    
    // Stats
    totalSales: 'Ventas Totales',
    totalOrders: 'Pedidos Totales',
    newCustomers: 'Nuevos Clientes',
    avgOrderValue: 'Valor Promedio',
    
    // Products
    addProduct: 'Añadir Producto',
    editProduct: 'Editar Producto',
    deleteSelected: 'Eliminar Seleccionados',
    searchProducts: 'Buscar productos...',
    inStock: 'En Stock',
    lowStock: 'Stock Bajo',
    edit: 'Editar',
    delete: 'Eliminar',
    
    // Product Modal
    step: 'Paso',
    general: 'General',
    // shipping: 'Envío',
    options: 'Opciones',
    seo: 'SEO',
    productImages: 'Imágenes del Producto',
    setPrimary: 'Establecer como Principal',
    removeImage: 'Eliminar Imagen',
    uploadImage: 'Subir Imagen',
    productName: 'Nombre del Producto',
    enterProductName: 'Ingrese el nombre del producto',
    sku: 'SKU',
    enterSku: 'Ingrese SKU',
    category: 'Categoría',
    selectCategory: 'Seleccionar Categoría',
    subcategory: 'Subcategoría',
    selectSubcategory: 'Seleccionar Subcategoría',
    subSubcategory: 'Sub-subcategoría',
    selectSubSubcategory: 'Seleccionar Sub-subcategoría',
    price: 'Precio',
    listPrice: 'Precio de Lista',
    quantity: 'Cantidad',
    description: 'Descripción',
    writeDescription: 'Escriba una descripción detallada del producto...',
    
    // Product Modal - Shipping
    shippingMethods: 'Métodos de Envío',
    chooseShippingMethod: 'Elija cómo desea enviar este producto',
    flatRate: 'Tarifa Plana',
    flatRateDesc: 'Cobrar una tarifa fija por envío',
    rate: 'Tarifa',
    freeShipping: 'Envío Gratis',
    freeShippingDesc: 'Sin cargos de envío',
    weightBased: 'Basado en Peso',
    weightBasedDesc: 'Calcular envío según el peso',
    internationalShipping: 'Envío Internacional',
    selectRegions: 'Seleccione las regiones donde se puede enviar este producto',
    baseRate: 'tarifa base',
    packageDetails: 'Detalles del Paquete',
    enterPackageDetails: 'Ingrese las dimensiones y el peso del paquete',
    weight: 'Peso',
    dimensions: 'Dimensiones',
    
    // Product Modal - Options
    variants: 'Variantes',
    addVariant: 'Añadir Variante',
    variantType: 'Tipo de Variante',
    variantValues: 'Valores de Variante',
    attributes: 'Atributos',
    addAttribute: 'Añadir Atributo',
    attributeName: 'Nombre del Atributo',
    attributeValue: 'Valor del Atributo',
    customOptions: 'Opciones Personalizadas',
    addOption: 'Añadir Opción',
    
    // Product Modal - SEO
    seoTitle: 'Título SEO',
    enterSeoTitle: 'Ingrese título SEO',
    seoTitleDesc: 'Longitud recomendada: 50-60 caracteres',
    metaDescription: 'Meta Descripción',
    enterMetaDescription: 'Ingrese meta descripción',
    metaDescriptionDesc: 'Longitud recomendada: 150-160 caracteres',
    urlHandle: 'URL Amigable',
    enterUrlHandle: 'Ingrese URL amigable',
    focusKeywords: 'Palabras Clave',
    enterKeywords: 'Ingrese palabras clave separadas por comas',
    keywordsDesc: 'Agregue palabras clave que describan su producto',
    openGraphImage: 'Imagen Open Graph',
    uploadFile: 'Subir un archivo',
    dragDrop: 'o arrastrar y soltar',
    imageRequirements: 'PNG, JPG hasta 5MB',
    
    // Product Modal - Buttons
    saveProduct: 'Guardar Producto',
    cancel: 'Cancelar',
    next: 'Siguiente',
    previous: 'Anterior',
    
    // Common
    welcome: 'Bienvenido',
    search: 'Buscar...',
    comingSoon: 'Próximamente...',
    shippingDashboard: 'El panel de envíos y la información de seguimiento se mostrarán aquí.',
    month: 'mes',
    current: 'Actual',
    upgrade: 'Actualizar',
    save: 'Guardar Cambios',
  }
};