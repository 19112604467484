import React, { useState, useEffect } from 'react'
import { Menu, Dropdown, Button } from 'antd'
import Product from 'src/components/ProductPage/AllProduct/Product'
import CustomerTraction from 'src/components/ProductDetail/CustomerTraction'
import SearchIcon from '../../../../public/assets/icon/search-folllow.svg'
import ListViewIcon from '../../../../public/assets/icon/list-view.svg'
import GridViewIcon from '../../../../public/assets/icon/grid-view-new.svg'
import ProductFilter from './ProductFilter'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAuth } from 'src/utils/auth'
import ProductFilterBar from 'src/components/StoreAndProductDetailModal/StoreProductModal/ProductFilterBar'
import ProductPagination from 'src/components/StoreAndProductDetailModal/StoreProductModal/ProductFilterBar/ProductPagination'

const AllProduct = (props: any) => {
  const {
    products,
    color,
    storeData,
    isFilterMenu,
    isPadding = true,
    isInCollection,
    isShowToast,
    total,
    dataLimite,
    page,
    setPage,
    topForFilter,
    mobileTopForFilter = 0,
    isMobile = false,
    isForMen = false,
    setFilterData,
    filterData,
    isCalled,
    likeProduct,
    APIcall,
    category_slug = null,
    subCategory_slug = null,
    childSubCategory_slug = null,
    store_slug = null,
  } = props
  const { user }: any = useAuth()
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const dispatch = useAppDispatch()

  let width
  if (typeof window !== 'undefined') {
    width = window.innerWidth
  }
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isSort, setIsSort] = useState('Latest')
  const [splittedArray, setSplittedArray] = useState([])
  const [listSplittedArray, setListSplittedArray] = useState([])
  const [boxOpen, setBoxOpen] = useState(false)
  const [showProduct, setShowproduct] = useState(true)
  const allStores = useSelector((state: any) => state?.storesData)

  const spliceIntoChunks = (tempDiscardtweet: any, chunkSize: any) => {
    const resChunk: any = []
    if (tempDiscardtweet?.length > 0) {
      let discardtweet = [...tempDiscardtweet]
      while (discardtweet.length > 0) {
        const chunk = discardtweet && discardtweet?.splice(0, chunkSize)
        resChunk.push(chunk)
        setSplittedArray(resChunk)
      }
    } else {
      setSplittedArray([])
    }
    return resChunk
  }

  useEffect(() => {
    spliceIntoChunks(products, Math.ceil(products?.length / (width <= 767 ? 2 : isModalVisible ? 3 : 4)))
  }, [products, showProduct, isModalVisible])

  const spliceIntoChunksList = (tempDiscardtweet: any, chunkSize: any) => {
    const resChunk: any = []
    if (tempDiscardtweet?.length > 0) {
      let discardtweet = [...tempDiscardtweet]
      while (discardtweet.length > 0) {
        const chunk = discardtweet && discardtweet?.splice(0, chunkSize)
        resChunk.push(chunk)
        setListSplittedArray(resChunk)
      }
    } else {
      setListSplittedArray([])
    }
    return resChunk
  }

  useEffect(() => {
    spliceIntoChunksList(products, Math.ceil(products?.length / (width <= 767 ? 2 : 2)))
  }, [products, showProduct])

  useEffect(() => {
    const handleScroll = () => {
      sessionStorage.setItem('productsScrollPosition', window.scrollY.toString());
    };

    window.addEventListener('scroll', handleScroll);
    
    // Restore scroll position when component mounts
    const savedPosition = sessionStorage.getItem('productsScrollPosition');
    if (savedPosition !== null) {
      window.scrollTo(0, parseInt(savedPosition));
      sessionStorage.removeItem('productsScrollPosition'); // Clear after restoring
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const changeSortType = (e: { key: string }) => {
    setIsSort(e.key)
  }

  const SortType = (
    <Menu onClick={changeSortType}>
      <Menu.Item key="Latest">Latest</Menu.Item>
      <Menu.Item key="Highest">Highest</Menu.Item>
      <Menu.Item key="Lowest">Lowest</Menu.Item>
    </Menu>
  )

  const handleChangeView = (data) => {
    // refetch()
    if (data === 'grid') {
      setShowproduct(true)
    } else {
      setShowproduct(false)
    }
  }

  const handleBoxOpen = () => {
    if (user) {
      setBoxOpen(!boxOpen)
    } else {
      handleRedirect()
    }
  }

  const handleRedirect = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: {
          media: 'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
          modalFor: 'login',
          startWith: 'loginModal',
        },
      }),
    )
  }


  return (
    <React.Fragment>
      {isFilterMenu && (
        <ProductFilterBar
          total={total}
          dataLimite={dataLimite}
          setPage={setPage}
          page={page}
          topForFilter={topForFilter}
          setIsModalVisible={setIsModalVisible}
          isModalVisible={isModalVisible}
          handleBoxOpen={handleBoxOpen}
        />
      )}
      <div className="allproduct-container">
        {isFilterMenu && (
          <>
            {/* <div className="allproduct-mobile-view-header-alignment">
              <div className="allproduct-mobile-view-header-flex-alignment">
                <div className="allproduct-mobile-heading-name-alignment">
                  <h5>All Products</h5>
                  <span>({products?.length} item)</span>
                </div>
                <div className="allproduct-search-grid-alignment">
                  <div>
                    <img src={SearchIcon.src} alt="SearchIcon" />
                  </div>
                  <div>
                    {!showProduct ? (
                      <div onClick={() => handleChangeView('grid')}>
                        <img src={GridViewIcon.src} alt="GridViewIcon" />
                      </div>
                    ) : (
                      <div onClick={() => handleChangeView('list')}>
                        <img src={ListViewIcon.src} alt="ListViewIcon" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div> */}
            <div className="all-product-mobile-view-open-alignment " style={{ top: mobileTopForFilter }}>
              <div>
                <div
                  className={`items-center flex-menu mobile-view-sticky-header  ${isForMen ? 'men-filter-bg' : ''} ${isPadding ? '' : 'filter-category-mobile-view'
                    } `}
                >
                  {/* <----------------------------------------------FILTER_MENU_WEB_START-------------------------------------------------------> */}
                  <div className="flex items-center gap-5 padding-menu filter-menu-for-web">
                    <button
                      className="flex items-center filter-button"
                      onClick={() => setIsModalVisible(!isModalVisible)}
                    >
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.33008 3.33105H14.6634" stroke="#636773" strokeLinecap="round" />
                        <path d="M3.33008 7.99805H12.6634" stroke="#636773" strokeLinecap="round" />
                        <path d="M5.99609 12.6641H9.99609" stroke="#636773" strokeLinecap="round" />
                      </svg>
                      <p>Filters</p>
                    </button>
                    <button className="flex items-center filter-button" onClick={() => handleBoxOpen()}>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.33203 2.66602L1.33203 13.3329" stroke="#636773" strokeLinecap="round" />
                        <path d="M14.666 13.333L1.33222 13.333" stroke="#636773" strokeLinecap="round" />
                        <path
                          d="M3.33203 9.99967C7.37801 2.38063 10.3205 13.8092 13.9987 3.33301"
                          stroke="#636773"
                          strokeLinecap="round"
                        />
                      </svg>
                      <p className="mobile-text-none">Category Engagement</p>
                    </button>
                  </div>
                  {/* <----------------------------------------------FILTER_MENU_WEB_END-------------------------------------------------------> */}
                  {/* <----------------------------------------------FILTER_MENU_MOBILE_START-------------------------------------------------------> */}
                  <div className="flex items-center gap-5 padding-menu filter-menu-for-mobile">
                    <button
                      className={`flex items-center filter-button ${isForMen ? 'men-filter' : ''}`}
                      // onClick={() => dispatch(fetchMobileModalData({ modal: 'filterModal', transform: 0, isOpen: true }))}
                      onClick={() => {
                        // setIsModalVisible(!isModalVisible)
                        dispatch(
                          fetchMobileModalData({
                            allModalData: [
                              ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                              {
                                modal: 'filterModal', isOpen: true, id: 0, transform: 20, unit: '%', data: {
                                  category_slug: category_slug,
                                  subCategory_slug: subCategory_slug,
                                  childSubCategory_slug: childSubCategory_slug,
                                  store_slug: store_slug,
                                },
                              },
                            ],
                            lastModalData: fetchMobileModal?.lastModalData,
                          }),
                        )
                      }}
                    >
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.33008 3.33105H14.6634" stroke="#636773" strokeLinecap="round" />
                        <path d="M3.33008 7.99805H12.6634" stroke="#636773" strokeLinecap="round" />
                        <path d="M5.99609 12.6641H9.99609" stroke="#636773" strokeLinecap="round" />
                      </svg>
                      <p>Filters</p>
                    </button>
                    <button className={`flex items-center filter-button ${isForMen ? 'men-filter' : ''}`}>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.33203 2.66602L1.33203 13.3329" stroke="#636773" strokeLinecap="round" />
                        <path d="M14.666 13.333L1.33222 13.333" stroke="#636773" strokeLinecap="round" />
                        <path
                          d="M3.33203 9.99967C7.37801 2.38063 10.3205 13.8092 13.9987 3.33301"
                          stroke="#636773"
                          strokeLinecap="round"
                        />
                      </svg>
                      <p className="mobile-text-none">Category Engagement</p>
                    </button>
                  </div>
                  {/* <----------------------------------------------FILTER_MENU_MOBILE_END-------------------------------------------------------> */}
                  <div className="flex items-center relative dropdown-align">
                    <p className="text-sm text-blue font-normal mr-2 sort-text-alignment">Sort : </p>
                    <Dropdown overlay={SortType} className=" right-0 flex justify-center h-14">
                      <Button className={`filter-button dropdown-gap ${isForMen ? 'men-filter' : ''}`}>
                        {isSort}
                        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1L5 5L9 1" stroke="#636773" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </Button>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>

            {boxOpen && (
              <div>
                <div className="category-enagement-card-design">
                  <CustomerTraction getSingleProduct={products} />
                  <div className="show-more-text">
                    <p>Show more</p>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {/* <div className="man-all-product-mobile-view-padding-alignment"> */}
        <div className="">
          <div className={isModalVisible ? 'store-main-grid' : 'store-main-grid-toogle store-main-grid'}>
            <div className={isModalVisible ? 'store-filter-box' : 'store-filter-box store-filter-box-hidden'}>
              <ProductFilter
                APIcall={APIcall}
                category_slug={category_slug}
                subCategory_slug={subCategory_slug}
                childSubCategory_slug={childSubCategory_slug}
                store_slug={store_slug}
              />
            </div>

            <div className={`product-details-alignment`}>
              {showProduct ? (
                <div
                  className={isModalVisible ? 'product-card-section four-col-grid ' : 'product-card-section'}
                  style={{ background: color, position: 'relative', minHeight: '40px' }}
                >
                
                  {/* {splittedArray.reduce((acc, curr) => acc + curr.length, 0) === products?.length ? ( */}
                  {products?.length > 0 ? (
                    splittedArray?.map((productColumn, index) => {
                      return (
                        <div key={index}>
                          {productColumn?.map((item: any, i) => {
                            return (
                              <div key={`k${i}`}>
                                {
                                  <Product
                                    APIcall={APIcall}
                                    product={{ ...item, storeData: storeData }}
                                    showProduct={showProduct}
                                    storeData={storeData}
                                    isInCollection={isInCollection}
                                    isShowToast={isShowToast}
                                    isForPost={false}
                                    likeProduct={likeProduct}
                                    dataLimite={dataLimite}
                                    setPage={setPage}
                                    page={page}
                                  />
                                }
                              </div>
                            )
                          })}
                        </div>
                      )
                    })
                  ) : isCalled ? (
                    <div className="absolute items-center justify-center h-10 w-full">
                      <p className="text-xl font-semibold text-center text-gray-500">Product Not Found</p>
                    </div>
                  ) : (
                    [
                      [0, 0, 0, 0, 0],
                      [0, 0, 0, 0, 0],
                      [0, 0, 0, 0, 0],
                      [0, 0, 0, 0, 0],
                    ]?.map((productColumn, index) => {
                      return (
                        <div key={`m${index}`}>
                          {productColumn?.map((item: any, i) => {
                            return (
                              <div key={`k${i}`}>
                                <Product
                                  APIcall={APIcall}
                                  product={{ ...item, storeData: storeData }}
                                  showProduct={showProduct}
                                  storeData={storeData}
                                  likeProduct={likeProduct}
                                  dataLimite={dataLimite}
                                  page={page}
                                  setPage={setPage}
                                />
                              </div>
                            )
                          })}
                        </div>
                      )
                    })
                  )}
                </div>
              ) : (
                <>
                  <div
                    className={
                      isModalVisible
                        ? 'product-card-section list-view-grid-section four-col-grid'
                        : ' list-view-grid-section product-card-section'
                    }
                    style={{ background: color }}
                  >
                    {listSplittedArray?.length > 0 ? (
                      listSplittedArray?.map((productColumn, index) => {
                        return (
                          <div key={index}>
                            {productColumn?.map((item: any, i) => {
                              return (
                                <Product
                                  APIcall={APIcall}
                                  index={`o${i}`}
                                  product={{ ...item, storeData: storeData }}
                                  showProduct={showProduct}
                                  storeData={storeData}
                                  likeProduct={likeProduct}
                                  dataLimite={dataLimite}
                                  page={page}
                                  setPage={setPage}
                                />
                              )
                            })}
                          </div>
                        )
                      })
                    ) : (
                      <div></div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* </div> */}
        <div className={`mobile-view-pagination-alignment ${isMobile ? 'menCategoryBgAlignment' : ''}`}>
          <ProductPagination
            pages={Math.ceil(total / dataLimite)}
            current={page}
            onClick={setPage}
            isMobile={isMobile}
          />
        </div>
      </div>
      {/* <MobileViewFilterModal isFilterModal={isFilterModal} setIsFilterModal={setIsFilterModal} /> */}
    </React.Fragment>
  )
}

// function areEqual(prevProps, nextProps) {
//   if (Boolean(prevProps?.products?.length)) {
//     return prevProps?.products?.length === nextProps?.products?.length
//   }
// }

// export default React.memo(AllProduct, areEqual)

export default AllProduct
