import React, { useEffect, useState } from 'react'
import CouponSection from 'src/components/Checkout/CouponSection'
import OrderSummarySection from 'src/components/Checkout/OrderSummarySection'
import ShppingAddressSection from 'src/components/Checkout/ShppingAddressSection'
import Loader from 'src/components/UI/Loader'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { Col, notification, Row } from 'antd'
import Link from 'next/link'
import ItemsSection from 'src/components/Checkout/ItemsSection'
import { useRouter } from 'next/router'
import downArrow from '/public/assets/icon/down-arrow.svg'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import ADD_PAYMENT from 'src/graphql/mutations/addPayment'
import CREATE_ORDER from 'src/graphql/mutations/createOrder'
import CardInputView from 'src/components/Checkout/CardInputView'
import GET_ALL_PRODUCT_FOR_CHECKOUT from 'src/graphql/queries/getAllProductForCheckout'
import { fetchCartData, getAllCartCountSliceData } from 'src/store/slices/getCartCount'
import GET_RATE_ESTIMATE from 'src/graphql/queries/getRateEstimate'
import GET_SHIP_DETAIL from 'src/graphql/queries/getShipDetails'
import GET_ALL_BILLING_ADDRESS from 'src/graphql/queries/getAllBillingAddress'
import client1 from 'src/utils/apolloClient'
import DELETE_CART_ITEM from 'src/graphql/mutations/deleteCartItem'
const thanks = '/assets/img/thanks.png'

export default function Checkout(props) {
  const { toggle, productArray, modalClose } = props
  const router = useRouter()
  const [item, setItem] = useState(false)
  const [openSlider, setOpenSlider] = useState('')
  const [payProcessing, setPayProcessing] = useState(false)
  const [paymentDone, setIsPaymentDone] = useState(false)
  const [orderId, setOrderId] = useState(0)
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const stripe = useStripe()
  const elements = useElements()
  const [productIdArray, setProductIdArray] = useState([])
  const [deliveryData, setDeliveryData] = useState([])
  const [deliveryOption, setDeliveryOption] = useState(' ')
  // const [getShipDetail, { data: shipDetailData, loading  }] = useLazyQuery(GET_SHIP_DETAIL)
  const [isAllResponseSuccess, setIsAllResponseSucces] = useState(false)

  const [shipDetailData, setShipDetailData] = useState(null)
  const [loading, setLoading] = useState(false)

  const { loading: bililingLoading, error, data } = useQuery(GET_ALL_BILLING_ADDRESS)
  let defaultAddress = data?.getAllBillingAddress?.filter((data) => data?.isDefault)
  const getShipDetail = async (palyload) => {
    setLoading(true)
    const { data, loading: getShipDetailLoading } = await client1.query({
      query: GET_SHIP_DETAIL,
      fetchPolicy: 'network-only',
      variables: palyload,
    })
    setLoading(getShipDetailLoading)
    setShipDetailData(data)
  }

  // useEffect(() => {
  //   getuspsservices()
  // }, [])

  const { refetch } = useQuery(GET_RATE_ESTIMATE, {
    skip: true,
  })
  
  const [removeId, setRemoveID] = useState<null | number>(null)
  const [getAllProductForCheckout] = useMutation(GET_ALL_PRODUCT_FOR_CHECKOUT, {
    onCompleted: () => {},
    onError: (error) => {
      notification['error']({
        message: 'Error',
        description: 'Sorry, something went wrong',
      })
    },
  })

  const [DeleteCartItem] = useMutation(DELETE_CART_ITEM, {
    async onCompleted() {
      dispatch(getAllCartCountSliceData())
      dispatch(fetchCartData())
    },
    onError(err) {
      notification['error']({
        message: 'Error',
        description: err.message,
      })
    },
  })

  const handleRemove = (id) => {
    
    const formattedArray = productArray?.data?.getSelectedProducts?.carts.flatMap((cart) =>
      cart?.product?.map((prd) => ({
        product_id: prd?.id,
        quantity: prd?.selected_quantity,
        store_id: prd?.store_id,
        variant_id: prd?.variant?.id,
        // cart_id: prd?.cart_item_id,
      })),
    )
    
    console.log('duduvdnudbvdfsdfdsfsfuibsf', productArray?.data?.getSelectedProducts);
   
    
    const filterProductArray = formattedArray?.filter((data) => data?.product_id !== id)
    // console.log('duduvdnudbvdfsdfdsfsfuibsf', productArray);
    
    // DeleteCartItem({
    //   variables: { deleteCartItemsId: filterProductArray?.[0]?.store_id },
    // });

    let productId = filterProductArray.map((pro) => {
      
      return {
        variant_id: pro?.variant_id,
        quantity: pro?.quantity,
        product_id: pro?.product_id,
        cart_id: pro?.cart_item_id,
      }
    })

    // console.log('duduvdnudbvdfsdfdsfsfuibsf', productId);
    
    getAllProductForCheckout({
      variables: {
        products: productId,
      },
    }).then((refetch) => {
      if (refetch.data !== undefined) {
        dispatch(
          fetchModalData({
            allModalData: [
              ...getAllState?.allModalData,
              { modal: 'checkout', isOpen: true, id: refetch, modalFor: 'bigModal' },
            ],
            lastModalData: getAllState?.lastModalData,
          }),
        )
      }
    })
  }
  const [totalProductCalculation, setTotalProductCalculation] = useState({
    productCount: 0,
    totalPrice: 0,
    prodIdArray: [],
  })
  const [loadingProduct, setLoadingProduct] = useState(false)

  const [addPayment] = useMutation(ADD_PAYMENT, {
    onCompleted: (data) => {
      // notification['success']({
      //     message: 'Payment added successfully ',
      //     description: 'Payment in processsing ',
      // })
      // setIsMapModalVisible(false)
    },
    onError: (error) => {
      notification['error']({
        message: 'Error',
        description: 'Something went wrong, Please try later',
      })
    },
  })
  const [selectedService, setSelectedService] = useState({})

  const [createOrder] = useMutation(CREATE_ORDER, {
    onCompleted: (data) => {
      dispatch(getAllCartCountSliceData())

      if (data?.createOrders?.success) {
        setLoadingProduct(false)
        setPayProcessing(false)
        setOrderId(data?.createOrders?.generate_id)
        setIsPaymentDone(true)
        // modalClose(false)
        notification['success']({
          message: data?.createOrders?.message,
          description: '',
        })
      } else {
        notification['error']({
          message: 'Error',
          description: 'Something went wrong, Please try later',
        })
        setLoadingProduct(false)
      }
      // setIsMapModalVisible(false)
    },
    onError: (error) => {
      notification['error']({
        message: 'Error',
        description: 'Something went wrong, Please try later',
      })
    },
  })

  // const [confirmPayment] = useMutation(CONFIRM_PAYMENT, {
  //   onCompleted: (res) => {
  //     notification['success']({
  //       message: res?.data?.confirmPayment?.message,
  //     })
  //   },
  //   onError: () => {
  //     notification['error']({
  //       message: 'Error',
  //       description: 'Sorry, something went wrong',
  //     })
  //   },
  // })

  // const [varifyPayment] = useMutation(VERIFY_PAYMENT, {
  //   onCompleted: () => {
  //     notification['success']({
  //       message: 'Order placed successfully',
  //       description: 'Your order has been placed successfully',
  //     })
  //   },
  //   onError: () => {
  //     notification['error']({
  //       message: 'Error',
  //       description: 'Sorry, something went wrong',
  //     })
  //   },
  // })

  // const handlePurchase = async (e) => {
  //   e.preventDefault()
  //   setPayProcessing(true)
  //   await confirmPayment({
  //     variables: {
  //       input: {
  //         products: totalProductCalculation?.prodIdArray,
  //       },
  //     },
  //   })
  //     .then(async (refetch) => {
  //       if (refetch.data !== undefined) {
  //         if (refetch?.data?.confirmPayment?.success && refetch?.data?.confirmPayment?.data?.redirectUrl) {
  //           let url = refetch?.data?.confirmPayment?.data?.redirectUrl
  //           let height = 700
  //           let width = 1000
  //           var left = (screen.width - width) / 2
  //           var top = (screen.height - height) / 2
  //           let paymentId = refetch?.data?.confirmPayment?.data?.paymentId
  //           var newWindow = window.open(
  //             url,
  //             'center window',
  //             'resizable = yes, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left,
  //           )

  //           setTimeout(async () => {
  //             newWindow.close()
  //             await varifyPayment({
  //               variables: {
  //                 paymentId: paymentId,
  //                 order_id: Number(refetch?.data?.confirmPayment?.data?.order_id),
  //               },
  //             })
  //               .then((res) => {
  //                 if (res?.data?.verifyPayment?.success) {
  //                   setOrderId(res?.data?.verifyPayment?.generate_id)
  //                   setIsPaymentDone(true)
  //                   modalClose(false)
  //                 } else {
  //                 }
  //               })
  //               .catch((error) => {
  //                 // notification['error']({
  //                 //   message: 'Error',
  //                 //   description: 'Sorry, something went wrong',
  //                 // })
  //               })
  //           }, 5000)
  //         } else {
  //           if (refetch?.data?.confirmPayment?.success && !refetch?.data?.confirmPayment?.data?.redirectUrl) {
  //             setPayProcessing(false)
  //             setOrderId(refetch?.data?.confirmPayment?.generate_id)
  //             setIsPaymentDone(true)
  //             modalClose(false)
  //           } else {
  //           }
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       notification['error']({
  //         message: 'Error',
  //         description: 'Sorry, something went wrong',
  //       })
  //     })
  // }

  const handlePurchase = (event) => {
    event.preventDefault()
    setLoadingProduct(true)
    if (!stripe || !elements) {
      alert('Stripe or elements is missing. Please provide them.')
      setLoadingProduct(false)
      return
    } else {
      addPayment({
        variables: {
          amount: totalProductCalculation?.totalPrice,
        },
      })
        .then(async (res) => {
          let key = res?.data?.addPayment?.client_secret
          if (key) {
            const result = await stripe.confirmCardPayment(key, {
              payment_method: { card: elements.getElement(CardElement) },
            })
            if (result?.error) {
              notification['error']({
                message: result?.error?.code,
                description: result?.error?.message,
              })
              setLoadingProduct(false)
            } else {

              if (shipDetailData?.getShipDetail) {
                createOrder({
                  variables: {
                    input: {
                      payment_id: result?.paymentIntent?.id,
                      products: totalProductCalculation?.prodIdArray,
                    },
                  },
                })
              } else {
                notification['error']({
                  message: 'Error',
                  description: 'Something went wrong please try again letter',
                })
              }
            }
          } else {
            alert('Please Fill the details')
          }
        })
        .catch((error) => {
          setLoadingProduct(false)
        })
    }
  }

  useEffect(() => {
    let totalProduct = productArray?.data?.getSelectedProducts?.carts
      ?.map((pro) => {
        return pro?.product
      })
      .flat()

    if (totalProduct?.length > 0) {
      let totalPrice = totalProduct
        ?.map((prod) => {
          return Number(prod?.variant ? prod?.variant?.price : prod?.inventoryPrice?.price) * prod?.selected_quantity
        })
        ?.reduce((cur, prev) => cur + +prev || 0)

      const prodIdArray = totalProduct?.map((product) => {
        // Find the shipping detail for the current product
        const shipDetail = shipDetailData?.getShipDetail?.find((item) => item?.product_id === product?.id)
        
        // Filter the service data based on the product's shipping method
        const selectedData = shipDetail?.data?.filter((service) =>
          product?.shipping_method?.service_code?.includes(service?.service_code),
        )

        // Construct and return the object for the product
        return {
          productId: product?.id,
          quantity: product?.selected_quantity,
          variant_id: product?.variant?.id || null,
          ...(selectedService[product?.id] && {
            service_code: selectedService[product?.id]?.service_code,
            delivery_estimate: selectedService[product?.id]?.delivery_days,
            delivery_estimate_day: selectedService[product?.id]?.estimated_delivery_date,
          }),
        }
      })
      setTotalProductCalculation({
        productCount: totalProduct?.length,
        totalPrice: totalPrice,
        prodIdArray: prodIdArray,
      })
    }
  }, [productArray, shipDetailData?.getShipDetail, loading, selectedService])


  useEffect(() => {
    if (productIdArray.length > 0) {
      refetch({ input: { product_data: productIdArray } }).then((res) => {
        setDeliveryData(res?.data?.getRateEstimate)
      })
    }
  }, [productIdArray, refetch])

  useEffect(() => {
    if (productArray?.data?.getSelectedProducts?.carts?.length > 0) {
      const formattedArray = productArray?.data?.getSelectedProducts?.carts
        .flatMap((cart) =>
          cart?.product?.map((prd) => ({
            product_id: prd?.id,
            quantity: prd?.selected_quantity,
            store_id: prd?.store_id,
            variant_id: prd?.variant?.id,
          })),
        )
        .filter(Boolean)

      setProductIdArray(formattedArray?.length > 0 ? formattedArray : [])
    }
  }, [productArray?.data?.getSelectedProducts?.carts])

  useEffect(() => {
    if (
      productArray?.data?.getSelectedProducts?.carts?.length === 0 ||
      productArray?.data?.getSelectedProducts?.carts === null
    ) {
      router.back()
    }
  }, [productArray])

  const handleOpenSlider = (data) => {
    if (openSlider === data) {
      setOpenSlider('')
    } else {
      setOpenSlider(data)
    }
  }

  if (payProcessing) return <Loader />

  return (
    <div>
      {paymentDone ? (
        <Col className="" span={24}>
          <div className="bg-white rounded-xl py-36 px-56 mb-12">
            <Row>
              <Col span={16}>
                <div className="">
                  <h3 className="text-xl text-black font-medium">Thank you for your order</h3>
                  <p className="mt-12 text-base text-black font-medium">Your order number is </p>
                  <p className="text-indigo-600 text-base font-medium mt-1">{orderId}</p>
                  <p className="mt-4 text-black text-base font-medium">Estimated Delivery</p>
                  <p className="text-base text-gray-700">Wednesday, Jan. 1 - Friday, Jan. 15</p>
                  <p className="text-base text-gray-700 mt-4">
                    We’ve emailed you an order confirmation and
                    <br /> we’ll notify you when order has shipped.
                  </p>
                  <div className="mt-4">
                    <Link href="/feed" scroll={false}>
                      <a>
                        <button
                          type="button"
                          className="bg-blue px-24 py-3 mt-8 rounded-full text-white text-medium text-base mx-auto"
                        >
                          Continue shopping
                        </button>
                      </a>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col span={8}>
                <div className="flex items-center h-full">
                  <img src={thanks} alt="Thankyou" />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      ) : (
        <React.Fragment>
          <div className="mobile-view-back-and-text-alignment">
            <div className="text-button-alignment">
              <p>Checkout</p>
              <div
                className="back-button"
                onClick={() => {
                  let datass = getAllState?.allModalData?.filter(
                    (item) => item?.modal !== getAllState?.lastModalData?.modal,
                  )
                  dispatch(
                    fetchModalData({
                      allModalData: datass,
                      lastModalData: getAllState?.lastModalData,
                    }),
                  )
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <path
                    d="M22 0H10C4.47715 0 0 4.47715 0 10V22C0 27.5228 4.47715 32 10 32H22C27.5228 32 32 27.5228 32 22V10C32 4.47715 27.5228 0 22 0Z"
                    fill="white"
                  />
                  <path
                    d="M19 10L14 16L19 22"
                    stroke="#808191"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="checkout-page-container">
            <div className="checkout-main-title">
              <div className="checkout-main-text">
                <span>Checkout</span>
              </div>
            </div>
            <div className="grid-container-box">
              <div className="item-shipping-grid">
                <div className="shpping-address-coupon">
                  {/* //Shpping Address Section============================================================================= */}
                  <div className="shipping-address-main">
                    <div
                      className="mobile-view-all-content-alignment-for-page"
                      onClick={() => handleOpenSlider('shippingAddress')}
                    >
                      <div className="shpping-address-align">
                        <div className="shpping-address-icon">
                          <img src={'/assets/img/checkout/shipping-truck-icon.svg'} />
                        </div>
                        <div className="shpping-address-grey">
                          <span>Shpping Address</span>
                        </div>
                      </div>
                      <div className="mobile-view-icon-show">
                        <img
                          src={downArrow.src}
                          className={
                            openSlider === 'shippingAddress'
                              ? 'icon-rotate transition-icon'
                              : 'transition-icon icon-rotate-stable'
                          }
                        />
                      </div>
                    </div>
                    <div className={openSlider === 'shippingAddress' ? 'mobile-content-show' : 'mobile-content-hidden'}>
                      <ShppingAddressSection defaultAddress={defaultAddress} />
                    </div>
                  </div>
                  {/* //Coupon Section====================================================================================== */}
                  <div className="coupon-main">
                    <div
                      className="mobile-view-all-content-alignment-for-page"
                      onClick={() => handleOpenSlider('coupon')}
                    >
                      <div className="coupon-align">
                        <div className="coupon-icon">
                          <img src={'/assets/img/checkout/tag-icon.svg'} />
                        </div>
                        <div className="coupon-grey">
                          <span>Coupon</span>
                        </div>
                      </div>
                      <div className="mobile-view-icon-show">
                        <img
                          src={downArrow.src}
                          className={
                            openSlider === 'coupon'
                              ? 'icon-rotate transition-icon'
                              : 'transition-icon icon-rotate-stable'
                          }
                        />
                      </div>
                    </div>
                    <div className={openSlider === 'coupon' ? 'mobile-content-show' : 'mobile-content-hidden'}>
                      <CouponSection />
                    </div>
                  </div>
                </div>
                {/* //Items Section========================================================================================= */}
                <div className="items-section-main">
                  <div className="mobile-view-all-content-alignment-for-page" onClick={() => handleOpenSlider('item')}>
                    <div className="items-align">
                      <div className="shpping-address-icon">
                        <img src={'/assets/img/checkout/shipping-truck-icon.svg'} />
                      </div>
                      <div className="shpping-address-grey">
                        <span>Items ({totalProductCalculation?.productCount})</span>
                      </div>
                    </div>
                    <div className="mobile-view-icon-show" onClick={() => setItem(!item)}>
                      <img
                        src={downArrow.src}
                        className={
                          openSlider === 'item' ? 'icon-rotate transition-icon' : 'transition-icon icon-rotate-stable'
                        }
                      />
                    </div>
                  </div>
                  <div className={openSlider === 'item' ? 'mobile-content-show' : 'mobile-content-hidden'}>
                    <ItemsSection
                      setIsAllResponseSucces={setIsAllResponseSucces}
                      handleRemove={handleRemove}
                      selectedService={selectedService}
                      setSelectedService={setSelectedService}
                      getShipDetail={getShipDetail}
                      shipDetailData={shipDetailData}
                      loading={loading}
                      productArray={productArray?.data}
                      deliveryData={deliveryData}
                      deliveryOption={deliveryOption}
                      setDeliveryOption={setDeliveryOption}
                    />
                  </div>
                </div>
              </div>
              {/* //Payment Method Section ================================================================================= */}
              <div className="payment-method-main">
                <div className="payment-bg-alignment">
                  <div
                    className="mobile-view-all-content-alignment-for-page"
                    onClick={() => handleOpenSlider('paymentMethod')}
                  >
                    <div className="payment-align">
                      <div className="payment-icon">
                        <img src={'/assets/img/checkout/credit-card.svg'} />
                      </div>
                      <div className="payment-grey">
                        <span>Payment method</span>
                      </div>
                    </div>
                    <div className="mobile-view-icon-show">
                      <img
                        src={downArrow.src}
                        className={
                          openSlider === 'paymentMethod'
                            ? 'icon-rotate transition-icon'
                            : 'transition-icon icon-rotate-stable'
                        }
                      />
                    </div>
                  </div>
                  <div className={openSlider === 'paymentMethod' ? 'mobile-content-show' : 'mobile-content-hidden'}>
                    {/* <PaymentMethodSection /> */}
                    <CardInputView />
                  </div>
                </div>
                {/* //Order Summary Section ================================================================================== */}
                <div className="margin-class-align">
                  <OrderSummarySection
                    defaultAddress={defaultAddress}
                    totalProductCalculation={totalProductCalculation}
                    handlePurchase={handlePurchase}
                    deliveryOption={deliveryOption}

                    // zipcode={shipDetailData?.getShipDetail?.[0]?.zipcode}
                  />
                  <div className="button-section-align">
                    {productArray?.data?.getSelectedProducts?.carts?.length > 0 ? (
                      <div
                        style={{
                          cursor: !isAllResponseSuccess ? 'not-allowed' : '',
                        }}
                        className={`button-container`}
                        onClick={(event) => {
                          isAllResponseSuccess ? handlePurchase(event) : null
                        }}
                      >
                        <button className={!isAllResponseSuccess ? 'cursor-not-allowed' : ''}>Purchase</button>
                      </div>
                    ) : (
                      <div className="payment-button-disable">
                        <button>Purchase</button>
                      </div>
                    )}
                    <div
                      className="cancle-button-align"
                      onClick={() => {
                        let datass = getAllState?.allModalData?.filter(
                          (item) => item?.modal !== getAllState?.lastModalData?.modal,
                        )
                        dispatch(
                          fetchModalData({
                            allModalData: datass,
                            lastModalData: getAllState?.lastModalData,
                          }),
                        )
                      }}
                    >
                      <button>Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}
