import React, { useEffect, useRef, useState } from 'react'
import styles from './MobileAddColorVariant.module.scss'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'

export default function MobileAddColorVariant() {
  const sizeArraySelected = [
    { name: 'Red', colorCode: '#ff0000', id: 0 },
    { name: 'Orange', colorCode: '#ff8000', id: 1 },
    { name: 'Yellow', colorCode: '#ffff00', id: 2 },
    { name: 'Chartreuse', colorCode: '#80ff00', id: 3 },
    { name: 'Green', colorCode: '#00ff00', id: 4 },
    { name: 'Spring green', colorCode: '#00ff80', id: 5 },
    { name: 'Cyan', colorCode: '#00ffff', id: 6 },
    { name: 'Dodger Blue', colorCode: '#0080ff', id: 7 },
    { name: 'Blue', colorCode: '#0000ff', id: 8 },
    { name: 'Purple', colorCode: '#8000ff', id: 9 },
    { name: 'Violet', colorCode: '#ff00ff', id: 10 },
    { name: 'Magenta', colorCode: '#ff0080', id: 11 },
  ]

  const dispatch = useAppDispatch()
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)
  // const colorPickerRef: any = useRef()
  const [colorArray, setColorArray] = useState(addProductDetailSlice?.id?.variant?.colorStates?.color?.data || [])
  const [inputValue, setInputValue] = useState({ name: '', colorCode: '' })
  // const [sizeArray, setSizeArray] = useState(sizeArraySelected)

  const handleChange = (e) => {
    setInputValue({ ...inputValue, name: e.target.value })
  }

  const handleEnter = async (e, type) => {
    // debugger
    if (inputValue.name.trim() !== '' && addProductDetailSlice?.id?.selectedColor.trim() !== '') {
      if (type === 'enter') {
        let event = e
        let textValue = event.target.value
        if (textValue.trim() !== '') {
          setColorArray([...(colorArray || []), { ...inputValue, colorCode: addProductDetailSlice?.id?.selectedColor }])
        }
      } else {
        let event = inputValue?.name
        if (event.trim() !== '') {
          setColorArray([...(colorArray || []), { ...inputValue, colorCode: addProductDetailSlice?.id?.selectedColor }])
        }
      }

      await dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            variant: {
              ...addProductDetailSlice?.id?.variant,
              colorStates: {
                ...addProductDetailSlice?.id?.variant?.colorStates,
                color: {
                  ...addProductDetailSlice?.id?.variant?.colorStates?.color,
                  data: [
                    ...(addProductDetailSlice?.id?.variant?.colorStates?.color?.data || []),
                    { name: inputValue.name, colorCode: addProductDetailSlice?.id?.selectedColor },
                  ],
                },
              },
            },
            selectedColor: '',
          },
        }),
      )

      setInputValue({ name: '', colorCode: '' })
    }
  }

  const handleSubmitColor = () => {
    if (addProductDetailSlice?.id?.variant?.dataEdit?.color) {
      let data = {
        name: 'Color',
        variant: addProductDetailSlice?.id?.variant?.colorStates?.color?.data,
        index: addProductDetailSlice?.id?.variant?.dataEdit?.index,
      }

      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            variant: {
              ...addProductDetailSlice?.id?.variant,
              allVariant: addProductDetailSlice?.id?.variant?.allVariant?.map((add, idx) => {
                if (idx === data?.index) {
                  return { name: data?.name, data: data?.variant }
                } else {
                  return add
                }
              }),
              colorCodeArray: addProductDetailSlice?.id?.variant?.allVariant?.map((add, index) => {
                if (index === data?.index) {
                  return { name: data?.name, data: data?.variant }
                } else {
                  return add
                }
              }),
              allFinalVariant: addProductDetailSlice?.id?.variant?.allVariant?.map((add, index) => {
                if (index === data?.index) {
                  return { name: data?.name, data: data?.variant }
                } else {
                  return add
                }
              }),
              isAddColorModalOpen: false,
              dataEdit: {},
              colorStates: {},
            },
          },
        }),
      )
    } else {
      let data = { name: 'Color', variant: addProductDetailSlice?.id?.variant?.colorStates?.color?.data }
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            variant: {
              ...addProductDetailSlice?.id?.variant,
              allVariant: [...addProductDetailSlice?.id?.variant?.allVariant, { name: data.name, data: data.variant }],
              colorCodeArray: [
                ...addProductDetailSlice?.id?.variant?.allVariant,
                { name: data.name, data: data.variant },
              ],
              isAddColorModalOpen: false,
              dataEdit: {},
            },
          },
        }),
      )
    }
    let datass = fetchMobileModal?.allModalData?.filter(
      (item) => item?.modal !== fetchMobileModal?.lastModalData?.modal,
    )
    dispatch(
      fetchMobileModalData({
        allModalData: datass,
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
  }

  const openColorPicker = () => {
    dispatch(
      fetchMobileModalData({
        allModalData: [
          ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
          {
            modal: 'mobilePicUpModal',
            isOpen: true,
            id: 0,
            transform: 45,
          },
        ],
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
    dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          selectedText: inputValue?.name,
          selectedColorArray: colorArray,
        },
      }),
    )
  }

  useEffect(() => {
    setColorArray(addProductDetailSlice?.id?.variant?.colorStates?.color?.data)
    // dispatch(
    //   manageAddProductDetail({
    //     modal: '',
    //     isOpen: false,
    //     id: {
    //       ...addProductDetailSlice?.id,
    //       variant: {
    //         ...addProductDetailSlice?.id?.variant,
    //         colorStates: {
    //           ...addProductDetailSlice?.id?.variant?.colorStates,
    //           color: {
    //             ...addProductDetailSlice?.id?.variant?.colorStates?.color,
    //             data: addProductDetailSlice?.id?.variant?.colorStates?.color?.data,
    //           },
    //         },
    //       },
    //     },
    //   }),
    // )
  }, [])

  const handleRemoveColor = (indexToRemove) => {
    setColorArray(colorArray.filter((_, index) => index !== indexToRemove))

    dispatch(
      manageAddProductDetail({
        modal: '',
        isOpen: false,
        id: {
          ...addProductDetailSlice?.id,
          variant: {
            ...addProductDetailSlice?.id?.variant,
            allVariant: addProductDetailSlice?.id?.variant?.allVariant.filter((data, ind) => ind !== indexToRemove),
            allFinalVariant: addProductDetailSlice?.id?.variant?.allVariant.filter(
              (data, ind) => ind !== indexToRemove,
            ),
            colorCodeArray: addProductDetailSlice?.id?.variant?.colorCodeArray.filter(
              (data, ind) => ind !== indexToRemove,
            ),
            dataEdit: {
              ...addProductDetailSlice?.id?.variant?.dataEdit,
              color: {
                ...addProductDetailSlice?.id?.variant?.dataEdit?.color,
                data: addProductDetailSlice?.id?.variant?.dataEdit?.color?.data.filter(
                  (data, ind) => ind !== indexToRemove,
                ),
              },
            },

            colorStates: {
              ...addProductDetailSlice?.id?.variant?.colorStates,
              color: {
                ...addProductDetailSlice?.id?.variant?.colorStates?.color,
                data: addProductDetailSlice?.id?.variant?.colorStates?.color?.data.filter(
                  (data, ind) => ind !== indexToRemove,
                ),
              },
            },
          },
        },
      }),
    )
  }

  useEffect(() => {
    if (addProductDetailSlice?.id?.selectedText.trim() !== '') {
      setInputValue({ ...inputValue, name: addProductDetailSlice?.id?.selectedText })
    }
    if (Boolean(addProductDetailSlice?.id?.selectedColorArray?.length)) {
      setColorArray(addProductDetailSlice?.id?.variant?.dataEdit?.color?.data)

      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            variant: {
              ...addProductDetailSlice?.id?.variant,
              colorStates: {
                ...addProductDetailSlice?.id?.variant?.colorStates,
                color: {
                  ...addProductDetailSlice?.id?.variant?.colorStates?.color,
                  data: addProductDetailSlice?.id?.selectedColorArray,
                },
              },
            },
          },
        }),
      )
    }
  }, [])

  useEffect(() => {
    if (addProductDetailSlice?.id?.variant?.dataEdit?.color) {
      setColorArray(addProductDetailSlice?.id?.variant?.dataEdit?.color?.data)
      dispatch(
        manageAddProductDetail({
          modal: '',
          isOpen: false,
          id: {
            ...addProductDetailSlice?.id,
            variant: {
              ...addProductDetailSlice?.id?.variant,
              colorStates: {
                ...addProductDetailSlice?.id?.variant?.colorStates,
                color: {
                  ...addProductDetailSlice?.id?.variant?.colorStates?.color,
                  data: addProductDetailSlice?.id?.variant?.dataEdit?.color?.data,
                },
              },
            },
          },
        }),
      )
    }
  }, [addProductDetailSlice?.id?.variant?.dataEdit])

  return (
    <div className={styles.mobileAddColorVariantSection}>
      <div className={styles.mobileAddColorVariantHeading}>
        <h6>Add Color Variant</h6>
        <a>Close</a>
      </div>
      <div className={styles.mobileAddColorVariantBodyDetails}>
        <div className={styles.mobileAddColorVariantTopDetailsAlignment}>
          {Boolean(addProductDetailSlice?.id?.variant?.colorStates?.color?.data?.length) ? (
            <div className={styles.mobileAddColorVariantColorDetails}>
              {addProductDetailSlice?.id?.variant?.colorStates?.color?.data?.map((data, index) => {
                return (
                  <div className={styles.mobileAddColorVariantColorFlex}>
                    <div className={styles.mobileColorNameAndBoxAlignment}>
                      <div className={styles.colorBoxAlignment} style={{ backgroundColor: data?.colorCode }}></div>
                      <p>{data?.name}</p>
                    </div>
                    <a onClick={() => handleRemoveColor(index)}>Remove</a>
                  </div>
                )
              })}
            </div>
          ) : (
            <div className={styles.mobileAddColorVariantNOContentDetails}>
              <p>No color added</p>
            </div>
          )}
        </div>
        <div className={styles.mobileAddColorVariantBottomDetailsAlignment}>
          <div className={styles.mobileAddColorVariantDetailsOptionFlex}>
            <div className={styles.mobileAddColorVariantBoxALignment}>
              <div
                className={styles.mobileAddPlusBoxAlignment}
                style={{ background: addProductDetailSlice?.id?.selectedColor }}
                onClick={() => openColorPicker()}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M10 4.16666V15.8333"
                    stroke="#556EE6"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.16797 10H15.8346"
                    stroke="#556EE6"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className={styles.mobileViewColorNameAddInput}>
                <input
                  type="text"
                  placeholder="Add New Color"
                  onChange={(e) => handleChange(e)}
                  value={inputValue?.name}
                  onKeyUp={(event) => (event.key === 'Enter' ? handleEnter(event, 'enter') : null)}
                  autoFocus
                />
              </div>
            </div>
            <a onClick={() => handleEnter(event, 'click')}>Add</a>
          </div>
          <div className={styles.mobileAddColorVariantSaveButton}>
            <button
              onClick={() => handleSubmitColor()}
              disabled={Boolean(colorArray?.length) ? false : true}
              className={Boolean(colorArray?.length) ? styles.activeButton : ''}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
