import React, { useEffect, useState } from 'react'
import styles from './JewelryNewArrivalModal.module.scss'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { fetchProductByCategory } from 'src/store/slices/fetchAllProduct'
import Link from 'next/link'

export default function JewelryNewArrivalModal() {
  const [page, setPage] = useState(1)
  const [dataLimite, setDataLimite] = useState(40)
  const productByCategory = useSelector((state: any) => state?.productByCategory)

  useEffect(() => {
    setPage(1)
    dispatch(
      fetchProductByCategory({
        category: 'Jewelry',
        subCategory: '',
        childSubCategory: '',
        isShopifyProduct: false,
        page: page,
        limit: dataLimite,
        maxPrice: null,
        minPrice: null,
      }),
    )
  }, [])

  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  const handleOpenProduct = (clickedProduct) => {
    // dispatch(
    //   fetchModalData({
    //     allModalData: [
    //       ...getAllState?.allModalData,
    //       {
    //         modal: 'productDetail',
    //         isOpen: true,
    //         store_id: clickedProduct?.store_id,
    //         id: clickedProduct?.id,
    //         modalFor: 'bigModal',
    //         startDate: moment().toISOString(),
    //       },
    //     ],
    //     lastModalData: getAllState?.lastModalData,
    //   }),
    // )
  }

  return (
    <div className={styles.jewelryNewArrivalModalSection}>
      <div className={styles.jewelryNewArrivalModalAlignment}>
        <div className={styles.jewelryNewArrivalHeading}>
          <h4>New Arrival</h4>
        </div>

        <div className={styles.jewelryModalModalBodyAlignment}>
          <div className={styles.jewelryModalModalBodyGridAlignment}>
            {productByCategory?.product?.getAllProducts?.data?.length > 0
              ? productByCategory?.product?.getAllProducts?.data?.map((prd, index) => {
                  return (
                    <Link href={`/product/${prd?.slug}`}>
                      <div
                        className={styles.jewelryModalModalBodyGridItem}
                        // onClick={() => handleOpenProduct(prd)}
                        key={index}
                      >
                        <div className={styles.jewelryModalNewArrivalDetailsImg}>
                          <img src={Boolean(prd?.images?.length) && prd?.images[0]?.src} alt="new arrival" />
                          <div className={styles.jewelryModalNewArrivalAllDetails}>
                            <p>{prd?.title}</p>
                            <div className={styles.jewelryModalPrice}>
                              <span>${prd?.dis_price}</span>
                            </div>
                          </div>
                          <div className={styles.jewelryModalMoreOptionAlignment}>
                            <div className={styles.jewelryModalIconAlignment}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                              >
                                <path
                                  d="M17.4163 19.25L10.9997 14.6667L4.58301 19.25V4.58333C4.58301 4.0971 4.77616 3.63079 5.11998 3.28697C5.4638 2.94315 5.93011 2.75 6.41634 2.75H15.583C16.0692 2.75 16.5356 2.94315 16.8794 3.28697C17.2232 3.63079 17.4163 4.0971 17.4163 4.58333V19.25Z"
                                  stroke="white"
                                  strokeWidth="1.375"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <div className={styles.jewelryModalIconAlignment}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M8.59009 13.51L15.4201 17.49"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M15.4101 6.51001L8.59009 10.49"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <div className={styles.jewelryModalIconAlignment}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M7.5 4C4.46244 4 2 6.43905 2 9.44773C2 14.8955 8.5 19.848 12 21C15.5 19.848 22 14.8955 22 9.44773C22 6.43905 19.5375 4 16.5 4C14.6399 4 12.9954 4.91467 12 6.31469C11.0046 4.91467 9.36015 4 7.5 4Z"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  )
                })
              : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1].map((_, index) => {
                  return (
                    <div className={styles.jewelryModalModalBodyGridItem} key={index}>
                      <div className={styles.jewelryModalNewArrivalDetailsImg}>
                        {/* <div style={{ position: 'relative', width, height }}> */}
                        <SkeletonTheme baseColor="#d1d1d1" highlightColor="#FFFFFF">
                          <Skeleton style={{ height: 305 }} />
                        </SkeletonTheme>
                        {/* </div> */}
                        {/* <img src={Boolean(prd?.images?.length) && prd?.images[0]?.src} alt="new arrival" /> */}
                        {/* <img src="/assets/jewelry/new-arrival-img.png" alt="new arrival" /> */}
                        <div className={styles.jewelryModalNewArrivalAllDetails}>
                          <p>{''}</p>
                          <div className={styles.jewelryModalPrice}>
                            <span>{''}</span>
                          </div>
                        </div>
                        <div className={styles.jewelryModalMoreOptionAlignment}>
                          <div className={styles.jewelryModalIconAlignment}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                            >
                              <path
                                d="M17.4163 19.25L10.9997 14.6667L4.58301 19.25V4.58333C4.58301 4.0971 4.77616 3.63079 5.11998 3.28697C5.4638 2.94315 5.93011 2.75 6.41634 2.75H15.583C16.0692 2.75 16.5356 2.94315 16.8794 3.28697C17.2232 3.63079 17.4163 4.0971 17.4163 4.58333V19.25Z"
                                stroke="white"
                                strokeWidth="1.375"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div className={styles.jewelryModalIconAlignment}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M8.59009 13.51L15.4201 17.49"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M15.4101 6.51001L8.59009 10.49"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div className={styles.jewelryModalIconAlignment}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7.5 4C4.46244 4 2 6.43905 2 9.44773C2 14.8955 8.5 19.848 12 21C15.5 19.848 22 14.8955 22 9.44773C22 6.43905 19.5375 4 16.5 4C14.6399 4 12.9954 4.91467 12 6.31469C11.0046 4.91467 9.36015 4 7.5 4Z"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
          </div>
        </div>
      </div>
    </div>
  )
}
