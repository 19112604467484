import React, { useEffect, useState } from 'react'
import styles from './SellerOnboardingMain.module.scss'
import SellerOnboardingBanner from './SellerOnboardingBanner'
import SellerOnboardingTellSelf from './SellerOnboardingTellSelf'
import SellerOnboardingGetStarted from './SellerOnboardingGetStarted'
import SellerOnboardingThankYou from './SellerOnboardingThankYou'
import { useMutation, useQuery } from '@apollo/client'
import SELLER_APPROVAL_DETAIL from 'src/graphql/mutations/addSellerApprovalDetail'
import { useAuth } from 'src/utils/auth'
import { useRouter } from 'next/router'
import GET_CURRENT_USER from 'src/graphql/queries/getCurrentUser'
import SellerOnboardingRejected from './SellerOnboardingRejected'

export default function SellerOnboardingMain() {
  const { user, updateUser }: any = useAuth()
  const router = useRouter()
  let initialValue = {
    title: '',
    firstName: '',
    lastName: '',
    accountType: '',
    email: '',
    phoneNumber: '',
    companyLegalName: '',
    websiteUrl: '',
    streetAddress: '',
    city: '',
    state: '',
    postalCode: '',
    productType: 'Women - Apparel',
    businessType: 'Part-time',
  }
  const [inputValue, setInputValue] = useState(initialValue)
  const [page, setPage] = useState('')

  const handleNext = (pageValue) => {
    setPage(pageValue)
    // router.push('/seller/onboarding?onboard=step2')
  }

  const [submitSellerForm] = useMutation(SELLER_APPROVAL_DETAIL, {
    onCompleted: (res) => {
      setPage('success')
      // updateUser({ ...user, becomeSellerStatus: 'pending' })
    },
    onError: (err) => {},
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    submitSellerForm({
      variables: {
        input: inputValue,
      },
      refetchQueries: [{ query: GET_CURRENT_USER }],
    })
  }

  // useEffect(() => {
  //   if (router?.query?.onboard === 'step1') {
  //     if (user?.becomeSellerStatus === 'approved' || user?.becomeSellerStatus === 'pending') {
  //       setPage('success')
  //     } else if (!user?.becomeSellerStatus) {
  //       setPage('about')
  //     }
  //   } else if (router?.query?.onboard === 'step2') {
  //     if (user?.becomeSellerStatus === 'approved' || user?.becomeSellerStatus === 'pending') {
  //       setPage('success')
  //     } else if (!user?.becomeSellerStatus) {
  //       if (inputValue?.accountType === '') {
  //         setPage('about')
  //       } else {
  //         setPage('started')
  //       }
  //     }
  //   } else if (router?.query?.onboard === 'step3') {
  //     if (user?.becomeSellerStatus === 'approved' || user?.becomeSellerStatus === 'pending') {
  //       setPage('success')
  //     } else {
  //       setPage('about')
  //     }
  //   } else if (router?.query?.onboard === 'step4') {
  //     setPage('success')
  //     router.replace('/seller/dashboard')
  //   } else {
  //     router.replace('/seller/onboarding?onboard=step1')
  //   }
  // }, [router])

  useEffect(() => {
    if (user?.becomeSellerStatus === 'pending') {
      setPage('success')
    } else if (user?.becomeSellerStatus === 'rejected') {
      setPage('rejected')
    } else if (!user?.becomeSellerStatus || user?.becomeSellerStatus === '') {
      setPage('about')
    } else if (user?.becomeSellerStatus === 'approved') {
      // setPage('success')
      // router.replace('/seller/onboarding')
    }
  }, [user])

  // useEffect(() => {
  //   if (!user?.becomeSellerStatus && router?.query?.onboard === 'step1' && inputValue?.accountType === '') {
  //     setPage('about')
  //     router.push('/seller/onboarding/?onboard=step1')
  //   } else if (user?.becomeSellerStatus === 'approved' || user?.becomeSellerStatus === 'pending') {
  //     if (router?.query?.onboard === 'step1' || router?.query?.onboard === 'step2') {
  //       router.push('/seller/onboarding/?onboard=step3')
  //     } else {
  //       // router.push('/seller/onboarding/?onboard=step1')
  //       setPage('about')
  //     }
  //   } else if (user?.becomeSellerStatus === 'approved' || user?.becomeSellerStatus === 'pending') {
  //     setPage('success')
  //   }
  // }, [])

  return (
    <div className={styles.sellerOnboardingMainSection}>
      <div className={styles.sellerOnboardingAlignment}>
        <div className={styles.sellerOnboardingBoxAlignment}>
          <div className={styles.sellerOnboardingGrid}>
            <div className={styles.sellerOnboardingGridItem}>
              <SellerOnboardingBanner />
            </div>
            <div className={styles.sellerOnboardingGridItem}>
              {page === 'about' && (
                <SellerOnboardingTellSelf
                  setInputValue={setInputValue}
                  inputValue={inputValue}
                  handleNext={handleNext}
                />
              )}
              {page === 'started' && (
                <SellerOnboardingGetStarted
                  setInputValue={setInputValue}
                  inputValue={inputValue}
                  handleSubmit={handleSubmit}
                />
              )}
              {page === 'success' && <SellerOnboardingThankYou />}
              {page === 'rejected' && <SellerOnboardingRejected />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
