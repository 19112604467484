
interface UserNotificationsProfileProps {
    notification?: any;
    imagePayload?: any;
    variant?: 'small' | 'default';
}

const UserNotificationsProfile: React.FC<UserNotificationsProfileProps> = ({ notification, imagePayload, variant = 'default' }) => {
    const size = variant === 'small' ? 'w-[32px] h-[32px]' : 'w-[32px] h-[32px] sm:w-[56px] sm:h-[56px]';

    return (
        <div className="">
            <div className="relative mt-2">
                {imagePayload?.image ? (
                    <div className={size}>
                        <img
                            src={imagePayload?.image}
                            alt={imagePayload?.name}
                            className="w-full h-full rounded-full object-cover"
                        />
                    </div>
                ) : (
                    <div className={`${size} bg-gray-200 rounded-full flex items-center justify-center`}>
                        <span className="text-gray-500 text-lg font-semibold">
                            {imagePayload?.name?.charAt(0) || ''}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserNotificationsProfile;
