import React from 'react'
import styles from './PeopleFollowers.module.scss'
import PeopleItem from '../PeopleItem'
import classNames from 'classnames'
import ShowMoreButton from 'src/components/Comman/ShowMoreButton'
import { useSelector } from 'react-redux'
import { Spin } from 'antd'
const CuateImage = '/assets/img/cuate-lg.svg'
export default function PeopleFollowers({
  title,
  declineFriendRequestCall,
  sendFriendRequestCall,
  data,
  handleSeeMore,
  setSearch,
}) {
  const peopleDetail = useSelector((state: any) => state?.peopleData)
  const peopleData = useSelector((state: any) => state?.peopleData)

  const handleSearchChange = (e) => {
    setSearch(e.target.value)
  }

  return (
    <div className={styles.peopleFollowersSection}>
      {title === 'Find People' ? (
        <h5 className={styles.textStyle}>
          {title} ({data?.length || 0})
        </h5>
      ) : (
        <h5 className={styles.textStyle}>
          {title} ({data?.length})
        </h5>
      )}
      <div className={styles.hideSearch}>
        <div className={styles.peopleFollowerSearchAlignment}>
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path
              d="M19 27C23.4183 27 27 23.4183 27 19C27 14.5817 23.4183 11 19 11C14.5817 11 11 14.5817 11 19C11 23.4183 14.5817 27 19 27Z"
              stroke="#ACB1C0"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M28.9999 28.9999L24.6499 24.6499"
              stroke="#ACB1C0"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <input
            type="text"
            name=""
            id=""
            placeholder="Search Stores by Name or by Type"
            onChange={(e) => handleSearchChange(e)}
          />
        </div>
      </div>
      <div className={styles.peopleFollowersDetails}>
        {data?.length > 0 ? (
          <>
            <div className={classNames(styles.peopleFollowersAllDetailsAlignment, styles.widthNameAlignment)}>
              <div className={styles.findPeopleNameAlignment}>
                <h6>Recommended People</h6>
                <p>People you might be know</p>
              </div>
              <div className={styles.peopleFollowersGridAlignment}>
                {data?.map((people, index) => (
                  <React.Fragment key={index}>
                    <PeopleItem
                      people={people}
                      declineFriendRequestCall={declineFriendRequestCall}
                      sendFriendRequestCall={sendFriendRequestCall}
                    />
                  </React.Fragment>
                ))}
              </div>
              {peopleDetail?.people_total_count !== data?.length && (
                <div className={styles.showMoreButtonContain}>
                  {peopleDetail?.loading ? (
                    <div>
                      <div className="m-auto flex justify-center items-center">
                        <Spin size="large" tip="loading" />
                      </div>
                    </div>
                  ) : (
                    <ShowMoreButton onClick={handleSeeMore} />
                  )}
                </div>
              )}
            </div>
          </>
        ) : (
          <div>
            <div className={styles.noFollowesDataBoxAlignment}>
              <div className={styles.imgCenter}>
                <img src={CuateImage} alt="CuateImage" draggable={false} />
              </div>
              <p>You don’t have any followers yet</p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
