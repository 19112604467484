import { isSameDay, isToday, isYesterday, format } from 'date-fns'

export const formatDateInWords = (date) => {
  return format(new Date(date), "MMM d', ' YYY")
}

export const formatDateInNum = (date) => {
  return format(new Date(date), 'dd/MM/yy')
}

export const formatTime = (date) => {
  return format(new Date(date), "h':'mm a")
}

export const formatToYesterDay = (date) => {
  return isToday(new Date(date)) ? 'Today' : isYesterday(new Date(date)) ? 'Yesterday' : formatDateInWords(date)
}

export const formatRecentDate = (date) => {
  const first = isToday(new Date(date)) ? 'Today' : isYesterday(new Date(date)) ? 'Yesterday' : formatDateInNum(date)

  return first.concat(` at ${formatTime(date)}`)
}

export const sameDay = (prevDate, currentDate) => {
  return isSameDay(new Date(prevDate), new Date(currentDate))
}

export const getErrorMsg = (err) => {
  if (err.graphQLErrors[0]?.message) {
    return err.graphQLErrors[0].message
  } else {
    return err?.message
  }
}

// import { parse } from 'user-agent'

// export function getDeviceType(req) {
//   const userAgent = req ? req.headers['user-agent'] : navigator.userAgent
//   const ua = parse(userAgent)

//   if (ua?.device?.family === 'iPhone' || ua?.device?.family === 'Android') {
//     return 'Mobile'
//   } else if (ua?.device?.family === 'iPad' || ua?.device?.family === 'Android Tablet') {
//     return 'Tablet'
//   } else {
//     return 'Desktop'
//   }
// }

export const toCamelCase = (string) => {
  let cleanedString = string.replace(/[^a-zA-Z0-9]+/g, ' ')
  let words = cleanedString.trim().split(' ')
  words[0] = words[0][0].toLowerCase() + words[0].slice(1)
  for (let i = 1; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].slice(1)
  }
  let camelCaseString = words.join('')
  return camelCaseString
}

export const truncateString = (string, maxCharLimit) => {
  return string?.length < maxCharLimit ? (string ? string : '') : string ? string.slice(0, maxCharLimit) : ''
}

export const timeAgo = (prevDate) => {
  const diff = Number(new Date()) - prevDate
  const minute = 60 * 1000
  const hour = minute * 60
  const day = hour * 24
  const month = day * 30
  const year = day * 365
  // if (moment(prevDate).format('DD/MM/YYY') === moment(new Date()).format('DD/MM/YYY')) {
  switch (true) {
    case diff < minute:
      const seconds = Math.round(diff / 1000)
      return `${seconds} ${seconds > 1 ? 'seconds' : 'second'} ago`
    case diff < hour:
      return Math.round(diff / minute) + 'm ago'
    case diff < day:
      return Math.round(diff / hour) + 'h ago'
    case diff < month:
      return Math.round(diff / day) + 'day ago'
    case diff < year:
      return Math.round(diff / month) + 'month ago'
    case diff > year:
      return Math.round(diff / year) + 'y ago'
    default:
      return ''
  }
}

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}

export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation)

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

// export default async function getCroppedImg(
//   imageSrc,
//   pixelCrop,
//   rotation = 0,
//   inputSize,
//   flip = { horizontal: false, vertical: false },
// ) {

//   const image: any = await createImage(imageSrc)
//   const canvas = document.createElement('canvas')
//   const ctx = canvas.getContext('2d')

//   if (!ctx) {
//     return null
//   }

//   const rotRad = getRadianAngle(rotation)

//   // calculate bounding box of the rotated image
//   const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation)
//   // console.log(
//   //   'bBoxWidth',
//   //   bBoxWidth,
//   //   'bBoxHeight',
//   //   bBoxHeight,
//   //   'pixelCrop',
//   //   pixelCrop,
//   //   'inputSize',
//   //   inputSize
//   // )
//   // set canvas size to match the bounding box
//   canvas.width = bBoxWidth
//   canvas.height = bBoxHeight

//   // translate canvas context to a central location to allow rotating and flipping around the center
//   // ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
//   ctx.rotate(rotRad)
//   // ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
//   // ctx.translate(-image.width / 2, -image.height / 2)
//   // draw rotated image
//   ctx.drawImage(image, 0, 0)

//   // croppedAreaPixels values are bounding box relative
//   // extract the cropped image using these values
//   const data = ctx.getImageData(
//     pixelCrop.x,
//     pixelCrop.y,
//     pixelCrop.width,
//     pixelCrop.height,
//     // inputSize.width,
//     // inputSize.height
//   )
//   // set canvas width to final desired crop size - this will clear existing context
//   // canvas.width = pixelCrop.width
//   // canvas.height = pixelCrop.height
//   canvas.width = inputSize.width
//   canvas.height = inputSize.height

//   // paste generated rotate image at the top left corner
//   ctx.putImageData(data, 0, 0)

//   // As Base64 string
//   // return canvas.toDataURL('image/jpeg');

//   // As a blob
//   return new Promise((resolve, reject) => {
//     canvas.toBlob((file) => {
//       resolve(URL.createObjectURL(file))
//     }, 'image/jpeg')
//   })
// }

export default async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image: any = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const maxSize = Math.max(image.width, image.height)
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea
  canvas.height = safeArea

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2)
  ctx.rotate(getRadianAngle(rotation))
  ctx.translate(-safeArea / 2, -safeArea / 2)

  // draw rotated image and store data.
  ctx.fillStyle = '#DCDCDC'
  ctx.fillRect(0, 0, canvas.width, canvas.height)
  ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5)
  const data = ctx.getImageData(0, 0, safeArea, safeArea)

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
  )

  // As Base64 string
  return canvas.toDataURL('image/jpeg')

  // As a blob
  // return new Promise((resolve, reject) => {
  //   canvas.toBlob((file) => {
  //     resolve(URL.createObjectURL(file))
  //   }, 'image/jpeg')
  // })
}
