import React, { useEffect, useState } from 'react'
import { Checkbox } from 'antd'
import { MapView } from './MapView'
import { useMutation } from '@apollo/client'
import { notification } from 'antd'
import CREATE_BILLING_ADDRESS from 'src/graphql/mutations/createBillingAddress'
import UPDATE_BILLING_ADDRESS from 'src/graphql/mutations/updateBillingAddress'
import GET_ALL_BILLING_ADDRESS from 'src/graphql/queries/getAllBillingAddress'
import TAX_CALCULATION from 'src/graphql/mutations/taxCalculation' // Add this import
import { Country, State, City } from 'country-state-city'

export default function AddShppingAddressModal({
  setIsMapModalVisible,
  setIsSelectMapModalVisible,
  isAddressEdit,
  setIsAddressEdit,
  addressFormData,
  setAddressFormData,
  setLatLong,
  latLong,
  allCountry,
  setAllCountry,
  allState,
  setAllState,
  selectedCity,
  setSelectedCity,
}) {
  const [hidePin, setHidePin] = useState(false)
  const [error, setError] = useState<any>()

  const addressChangeHandler = (e) => {
    setAddressFormData({ ...addressFormData, [e.target.name]: e.target.value })
    setError({ ...error, [e.target.name]: '' })
  }

  const onChange = (e) => {
    setAddressFormData({ ...addressFormData, isDefault: e.target.checked })
  }

  const countrySelector = (e) => {
    let name = e.target.name
    let value = e.target.value

    if (name === 'country_name') {
      let filteredCountry = allCountry.find((cu) => cu.name === value)
      setAddressFormData({
        ...addressFormData,
        [name]: value ? value : '',
        country: filteredCountry?.isoCode ? filteredCountry?.isoCode : '',
        state: '',
        state_name: '',
        city: '',
      })
      setAllState(State.getStatesOfCountry(filteredCountry?.isoCode ? filteredCountry?.isoCode : ''))
      setSelectedCity([])
    } else if (name === 'state_name') {
      let filteredState = allState.find((cu) => cu.name === value)
      setAddressFormData({
        ...addressFormData,
        [name]: value,
        state: filteredState?.isoCode ? filteredState?.isoCode : '',
        city: '',
      })
      setSelectedCity(
        City.getCitiesOfState(addressFormData.country, filteredState?.isoCode ? filteredState?.isoCode : ''),
      )
    } else {
      setAddressFormData({
        ...addressFormData,
        [name]: value,
      })
    }
  }

  const handlePinLocation = () => {
    setHidePin(true)
    setAddressFormData({ ...addressFormData, isPinLocation: true })
  }

  useEffect(() => {
    setAddressFormData({ ...addressFormData, latitude: latLong.lat, longitude: latLong.lng })
  }, [latLong])

  const [addBillingAddress] = useMutation(CREATE_BILLING_ADDRESS, {
    onCompleted: (data) => {
      notification['success']({
        message: 'Card added successfully ',
        // description: 'Something went wrong, Please try later',
      })
    },
    onError: (error) => {
      notification['error']({
        message: 'Error',
        // description: 'Something went wrong, Please try later',
      })
    },
  })

  const [updateBillingAddress] = useMutation(UPDATE_BILLING_ADDRESS, {
    onCompleted: (data) => {
      notification['success']({
        message: 'Card updated successfully ',
        // description: 'Something went wrong, Please try later',
      })
      setIsMapModalVisible(false)
      setIsSelectMapModalVisible(true)
    },
    onError: (error) => {
      notification['error']({
        message: 'Error',
        // description: 'Something went wrong, Please try later',
      })
    },
  })

  const [taxCalculation] = useMutation(TAX_CALCULATION, {
    onCompleted: (data) => {
      if (data && data?.taxCalculation?.success === 'true') {        
        // Proceed with saving the address
        saveAddress();
      } else {
        notification['error']({
          message: 'Error',
          description: 'Invalid Zipcode, please enter a valid zipcode',
        });
      }
    },
    onError: (error) => {
      notification['error']({
        message: 'Error',
        description: 'Invalid Zipcode, please enter a valid zipcode',
      });
    },
  });

  const validateData = () => {
    let isFormValid = true
    let err = {
      firstName: '',
      lastName: '',
      streetAddress: '',
      country: '',
      state: '',
      city: '',
      zipcode: '',
      number: '',
    }
    if (!addressFormData?.firstName) {
      isFormValid = false
      err.firstName = '* Please enter first Name'
    }

    if (!addressFormData?.lastName) {
      isFormValid = false
      err.lastName = '* Please enter last Name'
    }

    if (!addressFormData?.streetAddress) {
      isFormValid = false
      err.streetAddress = '* Please enter streetAddress'
    }

    if (!addressFormData?.country) {
      isFormValid = false
      err.country = '* Please select country'
    }

    if (!addressFormData?.state) {
      isFormValid = false
      err.state = '* Please selece state'
    }

    if (!addressFormData?.city) {
      isFormValid = false
      err.city = '* Please select city'
    }

    if (!addressFormData?.zipcode) {
      isFormValid = false
      err.zipcode = '* Please enter zipcode'
    }

    if (!addressFormData?.number) {
      isFormValid = false
      err.number = '* Please enter your number'
    }
    setError(err)
    return isFormValid
  }

  const bindInput = (value) => {
    var regex = new RegExp('^[^0-9]*$')
    var key = String.fromCharCode(!value.charCode ? value.which : value.charCode)
    if (regex.test(key)) {
      value.preventDefault()
      return false
    }
  }

  const saveAddress = () => {
    if (isAddressEdit) {
      let object = {
        firstName: addressFormData.firstName,
        lastName: addressFormData.lastName,
        streetAddress: addressFormData.streetAddress,
        country: addressFormData.country,
        country_name: addressFormData.country_name,
        state: addressFormData.state,
        state_name: addressFormData.state_name,
        city: addressFormData.city,
        zipcode: addressFormData.zipcode,
        buildingName: addressFormData.buildingName,
        number: addressFormData.number,
        isDefault: addressFormData.isDefault,
        isPinLocation: addressFormData.isPinLocation,
        latitude: addressFormData.latitude,
        longitude: addressFormData.longitude,
      }

      updateBillingAddress({
        variables: {
          id: addressFormData.id,
          firstName: addressFormData.firstName,
          lastName: addressFormData.lastName,
          streetAddress: addressFormData.streetAddress,
          country: addressFormData.country,
          country_name: addressFormData.country_name,
          state: addressFormData.state,
          state_name: addressFormData.state_name,
          city: addressFormData.city,
          zipcode: addressFormData.zipcode,
          buildingName: addressFormData.buildingName,
          number: addressFormData.number,
          isDefault: addressFormData.isDefault,
          isPinLocation: addressFormData.isPinLocation,
          latitude: addressFormData.latitude,
          longitude: addressFormData.longitude,
        },
        refetchQueries: [{ query: GET_ALL_BILLING_ADDRESS }],
      })
    } else {
      let object = {
        firstName: addressFormData.firstName,
        lastName: addressFormData.lastName,
        streetAddress: addressFormData.streetAddress,
        country: addressFormData.country,
        country_name: addressFormData.country_name,
        state: addressFormData.state,
        state_name: addressFormData.state_name,
        city: addressFormData.city,
        zipcode: addressFormData.zipcode,
        buildingName: addressFormData.buildingName,
        number: addressFormData.number,
        isDefault: addressFormData.isDefault,
        isPinLocation: addressFormData.isPinLocation,
        latitude: addressFormData.latitude,
        longitude: addressFormData.longitude,
      }

      addBillingAddress({
        variables: {
          firstName: addressFormData.firstName,
          lastName: addressFormData.lastName,
          streetAddress: addressFormData.streetAddress,
          country: addressFormData.country,
          country_name: addressFormData.country_name,
          state: addressFormData.state,
          state_name: addressFormData.state_name,
          city: addressFormData.city,
          zipcode: addressFormData.zipcode,
          buildingName: addressFormData.buildingName,
          number: addressFormData.number,
          isDefault: addressFormData.isDefault,
          isPinLocation: addressFormData.isPinLocation,
          latitude: addressFormData.latitude,
          longitude: addressFormData.longitude,
        },
        refetchQueries: [{ query: GET_ALL_BILLING_ADDRESS }],
      })
      setIsMapModalVisible(false)
      setIsSelectMapModalVisible(true)
    }
  }

  const createShippingAddressHandler = () => {
    if (validateData()) {
      taxCalculation({
        variables: {
          zipcode: addressFormData.zipcode,
        },
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    createShippingAddressHandler()
  }

  return (
    <div>
      <div className="map-modal-title">
        <h1>Your Shipping Address</h1>
      </div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="all-input-grid">
          <div className="pin-location-section">
            <label>
              Pin Location <span>(optional)</span>
            </label>
            <div className="map-box-contain">
              <div className="map-container">
                <MapView latitude={latLong.lat} longitude={latLong.lng} setLatLong={setLatLong} />
              </div>
              {!addressFormData.isPinLocation && (
                <div className="absolute-button-section">
                  <div className="button-background" onClick={() => handlePinLocation()}>
                    <button>Pin Location</button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="first-last-section">
            <div className="input-section">
              <label>First Name</label>
              <div className="input-box-contain">
                <input
                  type="text"
                  placeholder="First Name*"
                  onChange={addressChangeHandler}
                  name="firstName"
                  value={addressFormData?.firstName}
                  required
                />
              </div>
              <span style={{ color: 'red' }}>{error?.firstName}</span>
            </div>
            <div className="input-section">
              <label>Last Name</label>
              <div className="input-box-contain">
                <input
                  type="text"
                  placeholder="Last name*"
                  onChange={addressChangeHandler}
                  name="lastName"
                  value={addressFormData?.lastName}
                  required
                />
              </div>
              <span style={{ color: 'red' }}>{error?.lastName}</span>
            </div>
          </div>
          <div className="input-section">
            <label>Street Address</label>
            <div className="input-box-contain" style={{ width: '100%' }}>
              <input
                type="text"
                placeholder="e.g. 123 Main St Orlando, FL "
                onChange={addressChangeHandler}
                name="streetAddress"
                value={addressFormData?.streetAddress}
                required
              />
            </div>
            <span style={{ color: 'red' }}>{error?.streetAddress}</span>
          </div>
          <div className="dropdown-last-section">
            <div className="select-section">
              <label>Country</label>
              <div className="select-box-contain">
                <select
                  onChange={countrySelector}
                  name="country_name"
                  required
                  value={addressFormData?.country_name}
                  style={{ width: '233px' }}
                >
                  {allCountry?.map((state: any, index) => {
                    return (
                      <option value={state.name} key={index}>
                        {state.name}
                      </option>
                    )
                  })}
                </select>
              </div>
              <span style={{ color: 'red' }}>{error?.country}</span>
            </div>

            <div className="select-section">
              <label>State</label>
              <div className="select-box-contain" style={{ width: '184px' }}>
                <select
                  onChange={countrySelector}
                  name="state_name"
                  value={addressFormData?.state_name}
                  required
                  disabled={!Boolean(allState?.length)}
                >
                  <option value={''}>{'Select Option'}</option>
                  {(addressFormData?.country.trim() !== ''
                    ? State.getStatesOfCountry(addressFormData?.country)
                    : allState
                  ).map((state: any, index: any) => {
                    return (
                      <option value={state.name} key={index}>
                        {state.name}
                      </option>
                    )
                  })}
                </select>
              </div>
              <span style={{ color: 'red' }}>{error?.state}</span>
            </div>
          </div>
          <div className="dropdown-last-section">
            <div className="select-section">
              <label>City</label>
              <div className="select-box-contain">
                <select
                  onChange={countrySelector}
                  name="city"
                  value={addressFormData?.city}
                  style={{ width: '233px' }}
                  required
                  disabled={!Boolean(selectedCity.length)}
                >
                  <option value={''}>{'Select Option'}</option>
                  {selectedCity.map((state, index) => {
                    return (
                      <option value={state.name} key={index}>
                        {state.name}
                      </option>
                    )
                  })}
                </select>
              </div>
              <span style={{ color: 'red' }}>{error?.city}</span>
            </div>

            <div className="input-section">
              <label>Zip Code</label>
              <div className="input-box-contain" style={{ width: '111px' }}>
                <input
                  type="text"
                  onChange={addressChangeHandler}
                  name="zipcode"
                  value={addressFormData?.zipcode}
                  onKeyPress={bindInput}
                  required
                />
              </div>
              <span style={{ color: 'red' }}>{error?.zipcode}</span>
            </div>
          </div>
          <div className="first-last-section">
            <div className="input-section">
              <label>
                Building Name<span>(optional)</span>
              </label>
              <div className="input-box-contain" style={{ width: '286px' }}>
                <input
                  type="text"
                  onChange={addressChangeHandler}
                  name="buildingName"
                  placeholder="e.g. 123 Main St Orlando, FL "
                  value={addressFormData?.buildingName}
                  // required
                />
              </div>
            </div>
            <div className="input-section">
              <label>Phone Number </label>
              <div className="input-box-contain" style={{ width: '230px' }}>
                <input
                  type="text"
                  onChange={addressChangeHandler}
                  name="number"
                  value={addressFormData?.number}
                  placeholder="e.g. 847473663"
                  onKeyPress={bindInput}
                  required
                  // maxLength={10}
                />
              </div>
              <span style={{ color: 'red' }}>{error?.number}</span>
            </div>
          </div>
          <div className="checkbox-contain">
            <Checkbox onChange={(e) => onChange(e)} checked={addressFormData?.isDefault} />
            <div className="checkbox-align">
              <span>Set as default shipping address</span>
            </div>
          </div>
          <div className="button-section">
            <div className="button-end">
              <div
                className="cancle-button-contain"
                onClick={() => {
                  setIsMapModalVisible(false)
                  setIsSelectMapModalVisible(true)
                  // setAddressFormData("")
                  setError('')
                }}
              >
                Cancel
              </div>
              <div className="save-button-align">
                <button type="submit">Save</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
