import React from 'react'
import BookClubs from '../../../BookInformationMain/BookClubs'
import MoreBookBySection from './MoreBookBySection'

export default function MoreBookSection() {
  return (
    <div className="book-other-details-right-side-alignment">
      <MoreBookBySection />
      <BookClubs />
    </div>
  )
}
