import React, { useState } from 'react'
import { useAuth } from 'src/utils/auth'
import getImageUrl from 'src/utils/getImageUrl'
import { useAppDispatch } from 'src/store/store'
import { fetchPostAddEditModal } from 'src/store/slices/postAddEditSlice'
import { useRouter } from 'next/router'

type Props = {
  title: String
  mobile: Boolean
  type: string
  group: object
  isSeller: boolean
}
const PostSomething: React.VFC<Props> = ({ title, mobile, type, group, isSeller }: Props) => {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const { user }: any = useAuth()
  const [inputValue, setInputValue] = useState()

  const path = router.asPath
  const seller = path.split('/')[1]

  const showModal = () => {
    let data = {
      editPost: { isEdit: false, content: null, id: null },
      editPostHandler: { isEdit: false, content: null, id: null },
      isSeller: isSeller,
      mobile: mobile,
      type: type,
      group: group,
    }
    dispatch(fetchPostAddEditModal({ modal: 'postAddEditModal', isOpen: true, id: data }))
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  return user ? (
    <div className="bg-white rounded-xl custom-mb-8 mobile:hidden tablet:hidden smallTablet:hidden post-something-box-alignment">
      <div className="px-8 py-2 border-b border-gray-200 post-something-heading">
        <p className="text-sm text-black font-medium">{title}</p>
      </div>
      <div
        className={`flex items-center ${seller === 'seller' ? 'seller-create-post-img' : ''}`}
        style={{ padding: '7px 20px 15px 20px' }}
      >
        <div className={`create-post-image ${seller === 'seller' ? 'seller-create-post-img' : ''} `}>
          {/* seller-create-post-img */}
          <img
            src={
              seller === 'seller'
                ? user?.store?.logo_image
                : user?.logo_image
                ? getImageUrl(user?.logo_image)
                : '/assets/img/dummy-profile.svg'
            }
            alt={user?.logo_image?.alternativeText}
            className="object-cover"
            // style={isSeller ? { borderRadius: '50%' } : { borderRadius: '50%' }}
          />
        </div>
        {!mobile ? (
          <div className="ml-8 w-full" onClick={showModal}>
            {/* <input
              type="text"
              placeholder="What’s on your mind?"
              value={inputValuem}
              onChange={(e) => handleInputChange(e)}
              readOnly
              style={{ fontSize: 13, fontWeight: 500 }}
              className="w-full text-sm font-normal"
            /> */}
            <input
              type="text"
              defaultValue={inputValue}
              placeholder="What’s on your mind?"
              onChange={handleInputChange}
              readOnly
            />
          </div>
        ) : (
          <div className=" rounded-2xl border border-gray-100 w-full flex ml-4" onClick={showModal}>
            <input
              type="text"
              value={inputValue}
              placeholder="What’s on your mind?"
              style={{ height: '30px', padding: '10px', width: '100%', borderRadius: '10px' }}
              onChange={handleInputChange}
              readOnly
            />
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default PostSomething
