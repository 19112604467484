import React, { useRef, useState } from 'react'
import styles from './CreateClubBasicInfo.module.scss'
import classNames from 'classnames'
import GET_CATEGORIES_QUERY from 'src/graphql/queries/getCategories'
import { useQuery } from '@apollo/client'
// import Select from 'react-select'
import useOnClickOutside from 'src/components/Hook/useOnClickOutSide'

export default function CreateClubBasicInfo(props) {
  const { inputValue, setInputValue, errors, setErrors } = props
  const getCategories = useQuery(GET_CATEGORIES_QUERY)
  const [dropDown, setDropDown] = useState({ drop1: false, drop2: false })
  const ref = useRef()

  useOnClickOutside(ref, () => onCancel())

  const onCancel = () => {
    setDropDown({ drop1: false, drop2: false })
  }

  let categories = getCategories?.data?.getAllCategoryDetail
    ?.map((category) => {
      return category?.subCategory
    })
    .flat()
  const [tagValue, setTagValue] = useState('')

  const handelOnChange = (e) => {
    const { name, value } = e.target
    setInputValue({ ...inputValue, [name]: value })
  }

  const handelOnAddClick = () => {
    if (tagValue.trim() !== '') {
      let data = { ...inputValue, hashtags: [...inputValue.hashtags, tagValue] }

      setInputValue({ ...inputValue, hashtags: [...inputValue.hashtags, tagValue] })
      setTagValue('')
    }
  }

  const removeTag = (index) => {
    setInputValue((prevState) => {
      const newTagArray = [...prevState.hashtags]
      newTagArray.splice(index, 1)
      return { ...prevState, hashtags: newTagArray }
    })
  }

  const categoryOption = categories?.map((item) => {
    return { value: item.id, label: item.name }
  })

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handelOnAddClick()
    }
  }

  const handleSelect = (value) => {
    setInputValue({ ...inputValue, privacy: value })
    setDropDown({ drop1: false, drop2: false })
  }

  const handleCategorySelect = (value) => {
    setInputValue({ ...inputValue, category: value })
    setDropDown({ drop1: false, drop2: false })
  }

  return (
    <div className={styles.createClubsBasicInfoSection}>
      <div className={styles.createClubsBasicFormAlignment}>
        <div className={styles.createClubBasicInputAlignment}>
          <label>Club Name</label>
          <div className={styles.createClubsInput}>
            <input
              type="text"
              placeholder="e.g. Fashion Fusion Hub"
              name="name"
              value={inputValue?.name}
              onChange={(e) => handelOnChange(e)}
            />
          </div>
          {!inputValue?.name && <span style={{ color: 'red' }}>{errors['name']}</span>}
        </div>

        <div className={styles.createClubBasicInputAlignment}>
          <label>Club Description</label>
          <div className={styles.createClubsInput}>
            <textarea
              placeholder="Write about club here..."
              name="description"
              value={inputValue?.description}
              onChange={(e) => handelOnChange(e)}
            ></textarea>
          </div>
          {!inputValue?.description && <span style={{ color: 'red' }}>{errors['description']}</span>}
        </div>
        <div className={styles.createClubBasicInputAlignment}>
          <div className={styles.createClubOtherDetailsGrid}>
            <div className={styles.createClubOtherDetailsGridItem}>
              <label>Club Category</label>
              <div
                className={classNames(
                  styles.createClubsInput,
                  styles.selectCreatelub,
                  styles.mobileViewClubCategoryInput,
                )}
              >
                <input
                  type="text"
                  placeholder="Select"
                  value={categoryOption?.find((item) => item?.value === inputValue?.category)?.label ?? ''}
                  onClick={() => setDropDown({ drop1: !dropDown?.drop1, drop2: false })}
                />

                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M6 9L12 15L18 9"
                      stroke="#ACB1C0"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div
                  className={
                    dropDown?.drop1
                      ? classNames(styles.createClubDropdowmBoxAlignment, styles.openDropdown)
                      : classNames(styles.createClubDropdowmBoxAlignment, styles.closeDropdown)
                  }
                  ref={ref}
                >
                  <ul>
                    {categoryOption?.map(
                      (item, index) => (
                        <li onClick={() => handleCategorySelect(item?.value)} key={index}>
                          {' '}
                          {item?.label}
                        </li>
                      ),
                      // <li className={styles.activeSelect}> {item?.label}</li>
                    )}
                  </ul>
                </div>
              </div>
              {!inputValue?.category && <span style={{ color: 'red' }}>{errors['category']}</span>}
            </div>
            <div className={styles.createClubOtherDetailsGridItem}>
              <label>Group privacy</label>
              <div className={classNames(styles.createClubsInput, styles.selectOtionAlignment)}>
                <input
                  type="text"
                  value={inputValue?.privacy}
                  placeholder="Select"
                  onClick={() => setDropDown({ drop1: false, drop2: !dropDown?.drop2 })}
                />
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M6 9L12 15L18 9"
                      stroke="#ACB1C0"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <div
                  className={
                    dropDown?.drop2
                      ? classNames(styles.createClubDropdowmBoxAlignment, styles.openDropdown)
                      : classNames(styles.createClubDropdowmBoxAlignment, styles.closeDropdown)
                  }
                >
                  <ul>
                    <li onClick={() => handleSelect('Private')}>Private</li>
                    <li onClick={() => handleSelect('Public')}>Public</li>
                    {/* <li className={styles.activeSelect}> {item?.label}</li> */}
                  </ul>
                </div>
              </div>
              {!inputValue?.privacy && <span style={{ color: 'red' }}>{errors['privacy']}</span>}
            </div>
          </div>
        </div>
        <div className={styles.createClubBasicInputAlignment}>
          <div className={styles.createClubTagAlignment}>
            <div className={styles.mobileTagAlignment}>
              <label>Tag</label>
              <div className={classNames(styles.createClubsInput, styles.tagInput)}>
                <input
                  type="text"
                  name="hashtags"
                  placeholder="Enter Keyword"
                  value={tagValue}
                  onChange={(e) => setTagValue(e.target.value)}
                  onKeyPress={(e) => handleKeyPress(e)}
                />
              </div>
            </div>

            <div className={styles.tagAddButton} onClick={handelOnAddClick}>
              <button>+ Add </button>
            </div>
          </div>

          <div className={styles.createClubTagNamesBoxFlex}>
            {inputValue.hashtags?.map((item, index) => (
              <div className={styles.createClubTagNamesBox} key={index}>
                <p>{item}</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  onClick={() => removeTag(index)}
                >
                  <path d="M9 1L1.00125 9" stroke="#390072" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M1 1L8.99875 9" stroke="#390072" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
