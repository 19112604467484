import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from 'src/store/store'
import { SignupForm } from './types'
import { useMutation } from '@apollo/client'
import { notification } from 'antd'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import REGISTER_MUTATION from 'src/graphql/mutations/register'
import { useSelector } from 'react-redux'
import { signInWithPopup } from 'firebase/auth'
import { TwitterProvider, auth, facebookProvider, provider } from 'src/Helpers/Firebase/Firebase'
import nookies from 'nookies'
import { useRouter } from 'next/router'

export default function NewSignupForm() {
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = useForm<SignupForm>({})
  const dispatch = useAppDispatch()
  const [passwordShown, setPasswordShown] = useState(false)
  const [passwordShowns, setPasswordShowns] = useState(false)
  const guestDetail = useSelector((state: any) => state?.guestAccountInfo?.data)
  const [designClass, setDesignClass] = useState(false)
  const password = watch('password')
  const router = useRouter()

  const handleBackogin = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: { media: 'login', modalFor: 'login', startWith: 'loginModal' },
      }),
    )
  }

  useEffect(() => {
    reset()
  }, [guestDetail])

  const [registerUser] = useMutation(REGISTER_MUTATION, {
    onCompleted: (res) => {
      if (res?.signup?.success) {
        if (res?.signup?.data?.login?.token) {
          const token = res?.signup?.data?.login?.token
          nookies.set(undefined, 'token', token, {
            path: '/',
          })
          dispatch(
            guestAccountDetailSlice({
              modal: 'authModal',
              isOpen: false,
              id: {
                media: [],
                modalFor: '',
              },
            }),
          )
          if (res?.signup?.data?.login?.isUserOnboardCompleted) {
            router.push('/feed')
          } else {
            router.push('/onboarding')
          }
        } else {
          dispatch(
            guestAccountDetailSlice({
              modal: 'authModal',
              isOpen: true,
              id: { ...guestDetail?.id, media: 'login', modalFor: 'registerSuccess' },
            }),
          )
        }
      } else {
        notification['error']({
          message: 'Error',
          description: res?.signup?.message,
        })
      }
    },
    onError: (error) => {
      let message = JSON.parse(JSON.stringify(error))
      notification['error']({
        message: 'Error',
        description: message?.message ? message?.message : 'Something went Wrong, Contact your Administrator.',
      })
    },
  })

  const toastMessage = (message) => {
    return (
      <div className="wrong-fill-tooltip-box-alignment">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 9.99999C18.3334 5.39762 14.6025 1.66666 10.0001 1.66666C5.39771 1.66666 1.66675 5.39762 1.66675 9.99999C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z"
            stroke="#E71616"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 6.66666V9.99999"
            stroke="#E71616"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 13.3333H10.0083"
            stroke="#E71616"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p>{message}</p>
      </div>
    )
  }

  const togglePassword = () => {
    setPasswordShown(!passwordShown)
  }

  const togglePasswords = () => {
    setPasswordShowns(!passwordShowns)
  }

  useEffect(() => {
    if (errors.acceptTerms) {
      setDesignClass(true)
      setTimeout(() => {
        setDesignClass(false)
      }, 250)
    }
  }, [errors.acceptTerms])

  const handleSocialSigUp = (e, social_type) => {
    if (social_type === 'GOOGLE') {
      signInWithPopup(auth, provider)
        .then((result: any) => { 
          registerUser({
            variables: {
              social_type: social_type,
              access_token: result?.user?.accessToken,
            },
          })
        })
        .catch((error) => {
          console.log('Caught error Popup closed', error)
          const errorData = JSON.parse(JSON.stringify(error))
          console.log(errorData)
          if (errorData?.code === 'auth/account-exists-with-different-credential') {
            notification['error']({
              message: 'Error',
              description: 'User already Exist.',
            })
          }
        })
    } else if (social_type === 'FACEBOOK') {
      signInWithPopup(auth, facebookProvider)
        .then((result: any) => {
          registerUser({
            variables: {
              social_type: social_type,
              access_token: result?.user?.accessToken,
            },
          })
        })
        .catch((error) => {
          console.log('Caught error Popup closed', error)
          const errorData = JSON.parse(JSON.stringify(error))
          console.log(errorData?.code)
          if (errorData?.code === 'auth/account-exists-with-different-credential') {
            notification['error']({
              message: 'Error',
              description: 'User already Exist.',
            })
          }
        })
    } else if (social_type === 'TWITTER') {
      signInWithPopup(auth, TwitterProvider)
        .then((result: any) => {
          registerUser({
            variables: {
              social_type: social_type,
              access_token: result?.user?.accessToken,
              email: result?._tokenResponse?.email ? result?._tokenResponse?.email : null,
            },
          })
        })
        .catch((error) => {
          console.log('Caught error Popup closed', error)
          const errorData = JSON.parse(JSON.stringify(error))
          console.log(errorData?.code)
          if (errorData?.code === 'auth/account-exists-with-different-credential') {
            notification['error']({
              message: 'Error',
              description: 'User already Exist.',
            })
          }
        })
    }
  }

  return (
    <div className="new-register-form-alignment">
      <div className="new-register-mobile-view-back" onClick={handleBackogin}>
        <button>
          <svg xmlns="http://www.w3.org/2000/svg" width="7" height="14" viewBox="0 0 7 14" fill="none">
            <path d="M6 1L1 7L6 13" stroke="#808191" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>{' '}
          Back
        </button>
      </div>
      <div className="new-register-logo-alignment">
        <img src="/assets/img/bluejestic-new-logo.svg" alt="logo" />
      </div>
      <div className="new-register-form-details-alignment">
        <form
          className="form-wrapper"
          onSubmit={handleSubmit((data: SignupForm) => {
            registerUser({
              variables: {
                // username: data.username,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                password: data.password,
                social_type: 'MANUAL',
              },
            })
          })}
        >
          <div
            className={`new-register-form-input ${
              errors.firstName?.message && 'input-error-input'
            } register-show-input-password-icon`}
          >
            <input
              {...register('firstName', {
                required: 'first name is required',
              })}
              type="text"
              className="is-invalid"
              placeholder="First Name"
            />
            {errors.firstName?.message && toastMessage('Enter first name')}
          </div>
          <div className="new-register-form-input ">
            <input
              {...register('lastName', {
                required: 'last name is required',
              })}
              type="text"
              className="is-invalid"
              placeholder="Last Name"
            />
          </div>
          <div
            className={`new-register-form-input ${
              errors.email?.message && 'input-error-input'
            } register-show-input-password-icon`}
          >
            <input
              {...register('email', {
                required: 'Email is required',
              })}
              placeholder="Email"
              className="is-invalid"
              type="email"
            />
            {errors.email?.message && toastMessage('Enter Email')}
            {/* <div className="wrong-fill-tooltip-box-alignment">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 9.99999C18.3334 5.39762 14.6025 1.66666 10.0001 1.66666C5.39771 1.66666 1.66675 5.39762 1.66675 9.99999C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z"
                  stroke="#E71616"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 6.66666V9.99999"
                  stroke="#E71616"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 13.3333H10.0083"
                  stroke="#E71616"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p>Password doesn’t match</p>
            </div> */}
          </div>
          <div
            className={`new-register-form-input ${
              errors.password?.message && 'input-error-input'
            } register-show-input-password-icon`}
          >
            <input
              {...register('password', {
                required: 'Password is required',
              })}
              placeholder="Password"
              className="is-invalid"
              type={passwordShown ? 'text' : 'password'}
            />
            {errors.password?.message && toastMessage('Enter Password')}
            <div className="register-new-create-password-icon">
              <div
                className={`cursor-pointer fa ${passwordShown ? 'fa-eye' : 'fa-eye-slash'}`}
                onClick={togglePassword}
              ></div>
            </div>
          </div>
          <div
            className={`new-register-form-input ${
              errors.confirmPassword?.message && 'input-error-input'
            } register-show-input-password-icon`}
          >
            <input
              {...register('confirmPassword', {
                required: 'Confirm password is required',
                validate: (value) => value === password || 'Your password and confirmation password do not match',
              })}
              placeholder="Confirm Password"
              className="is-invalid"
              type={passwordShowns ? 'text' : 'password'}
            />
            {errors.confirmPassword?.message && toastMessage('Password doesn’t match')}
            <div className="register-new-create-password-icon">
              {/* <div className="register-right-sign-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
                  <path d="M5.4 12L0 4.8L5.4 7.2L15.6 0L5.4 12Z" fill="#1D8603" />
                </svg>
              </div> */}
              <div
                className={`cursor-pointer fa ${passwordShowns ? 'fa-eye' : 'fa-eye-slash'}`}
                onClick={togglePasswords}
              ></div>
            </div>

            {/* <div className="new-register-password-box-alignment">
            <div className="new-register-password-box-heading">
              <h6>Medium</h6>
            </div>
            <div className="new-register-password-box-lines-alignment">
              <div className="new-register-box-lines new-register-box-lines-active"></div>
              <div className="new-register-box-lines new-register-box-lines-active"></div>
              <div className="new-register-box-lines"></div>
            </div>
            <div className="new-register-password-box-conditions">
              <div className="new-register-password-box-all-conditins-details ">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                    <path d="M3.80769 10L0 4L3.80769 6L11 0L3.80769 10Z" fill="#1D8603" />
                  </svg>
                </div>
                <p>At least 8 characters</p>
              </div>
              <div className="new-register-password-box-all-conditins-details ">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                    <path d="M3.80769 10L0 4L3.80769 6L11 0L3.80769 10Z" fill="#1D8603" />
                  </svg>
                </div>
                <p>Must contain special character</p>
              </div>
              <div className="new-register-password-box-all-conditins-details ">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                    <path d="M3.80769 10L0 4L3.80769 6L11 0L3.80769 10Z" fill="#1D8603" />
                  </svg>
                </div>
                <p>At least contain one Uppercase </p>
              </div>
              <div className="new-register-password-box-all-conditins-details ">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                    <path d="M3.80769 10L0 4L3.80769 6L11 0L3.80769 10Z" fill="#1D8603" />
                  </svg>
                </div>
                <p>At least contain one Number</p>
              </div>
            </div>
          </div> */}
          </div>
          <div
            className={`new-register-form-terms-and-condition-alignment ${
              designClass && 'animation-button-class-name'
            }`}
          >
            <div className="new-register-checkbox-alignment">
              <input
                {...register('acceptTerms', {
                  required: 'Please accept our terms and services',
                })}
                type="checkbox"
                className="is-invalid mr-1"
              />
              {/* {errors.acceptTerms?.message && <div className="invalid-feedback">{errors.acceptTerms.message}</div>} */}
            </div>
            <p>
              I have read and accept the <a>Term of Services</a> & <a>Privacy Policy</a>
            </p>
          </div>
          <div className="new-register-create-button">
            <button
              type="submit"
              //  className="disable-button"
            >
              Create Account
            </button>
          </div>
        </form>
        <div className="new-register-login-with-section">
          <h6>or Signup with</h6>

          <div className="all-otions-login-alignmrent">
            <div className="register-with-option-icon" onClick={(e) => handleSocialSigUp(e, 'GOOGLE')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                <path
                  d="M41.3802 22.4545C41.3802 21.0363 41.2529 19.6727 41.0165 18.3636H22.1802V26.109H32.9438C32.4711 28.5999 31.0529 30.709 28.9256 32.1272V37.1636H35.4165C39.1983 33.6727 41.3802 28.5454 41.3802 22.4545Z"
                  fill="#4285F4"
                />
                <path
                  d="M22.1797 41.9999C27.5797 41.9999 32.107 40.2181 35.4161 37.1636L28.9252 32.1272C27.1434 33.3272 24.8706 34.0545 22.1797 34.0545C16.9797 34.0545 12.5616 30.5454 10.9797 25.8181H4.3252V30.9817C7.6161 37.509 14.3615 41.9999 22.1797 41.9999Z"
                  fill="#34A853"
                />
                <path
                  d="M10.9802 25.7999C10.5802 24.5999 10.3438 23.3272 10.3438 21.9999C10.3438 20.6727 10.5802 19.3999 10.9802 18.1999V13.0363H4.32563C2.96199 15.7272 2.18018 18.7636 2.18018 21.9999C2.18018 25.2363 2.96199 28.2727 4.32563 30.9636L9.50744 26.9272L10.9802 25.7999Z"
                  fill="#FBBC05"
                />
                <path
                  d="M22.1797 9.96363C25.1252 9.96363 27.7434 10.9818 29.8343 12.9454L35.5615 7.21818C32.0888 3.98182 27.5797 2 22.1797 2C14.3615 2 7.6161 6.4909 4.3252 13.0364L10.9797 18.2C12.5616 13.4727 16.9797 9.96363 22.1797 9.96363Z"
                  fill="#EA4335"
                />
              </svg>
            </div>
            <div className="register-with-option-icon" onClick={(e) => handleSocialSigUp(e, 'FACEBOOK')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 22.1117C2 32.055 9.22167 40.3233 18.6667 42V27.555H13.6667V22H18.6667V17.555C18.6667 12.555 21.8883 9.77833 26.445 9.77833C27.8883 9.77833 29.445 10 30.8883 10.2217V15.3333H28.3333C25.8883 15.3333 25.3333 16.555 25.3333 18.1117V22H30.6667L29.7783 27.555H25.3333V42C34.7783 40.3233 42 32.0567 42 22.1117C42 11.05 33 2 22 2C11 2 2 11.05 2 22.1117Z"
                  fill="#1877F2"
                />
              </svg>
            </div>
            <div className="register-with-option-icon" onClick={(e) => handleSocialSigUp(e, 'TWITTER')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                <path
                  d="M33.5017 4H39.635L26.235 19.251L42 40H29.6567L19.99 27.4142L8.92667 40H2.79L17.1233 23.6869L2 4.00166H14.6567L23.395 15.5055L33.5017 4ZM31.35 36.3457H34.7483L12.81 7.46342H9.16333L31.35 36.3457Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>

          <div className="new-register-login-option-alignment">
            <p>Already have account?</p> <a onClick={handleBackogin}>Login</a>
          </div>
        </div>
      </div>
    </div>
  )
}
