import { gql } from '@apollo/client'

const GET_LIVESTREAM_RECORDINGS = gql`
  query GetLivestreamRecording($uuid: String!) {
    getLivestreamRecording(uuid: $uuid) {
        message
        success
        data {
          final_date
          duration
          store_name
          store_logo
          slug
          title
          recording_url
          participants {
            name
            preset_name
            createdAt
          }
        }
    }
}
`
export default GET_LIVESTREAM_RECORDINGS
