import React from 'react'
import styles from './RequestSubmitted.module.scss'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
export default function RequestSubmitted() {
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)

  const HandleNext = () => {
    dispatch(
      fetchModalData({
        allModalData: [
          ...getAllState?.allModalData,
          { modal: 'sellerWithdrawTransactionDetail', isOpen: true, id: 0, modalFor: 'sellerWithdrawSize' },
        ],
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  return (
    <div className={styles.requestSubmittedSection}>
      <div className={styles.requestSubmittedAlignment}>
        <div className={styles.submissionTopAlignment}>
          <div className={styles.submissionIconAlignment}>
            <img src="/assets/seller/icons/submission-icon.png" alt="submit" />
          </div>
          <p>Your withrawal Request has been processed</p>
        </div>

        <div className={styles.submissionBottomAlignment}>
          <div className={styles.closeButtonAlignment}>
            <button>Close</button>
          </div>
          <div className={styles.checkStatus} onClick={() => HandleNext()}>
            <a>Check Status</a>
          </div>
        </div>
      </div>
    </div>
  )
}
