import { useMutation, useSubscription } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useStateContext } from 'src/utils/state'
import {
  EDIT_GROUP_MSG,
  EDIT_PRIVATE_MSG,
  SEND_GROUP_MSG,
  SEND_PRIVATE_MSG,
  START_STOP_TYPING,
} from 'src/graphql/mutations/mutations'
import { MESSAGE_TYPING } from 'src/graphql/subscriptions/subscriptions'
import { useAuth } from 'src/utils/auth'
import { ApiPost } from 'src/Helpers/Api/ApiData'
import { API } from 'src/config/API/api.config'
import { compressImages } from '../../../../Helpers/localhelper'
import { notification } from 'antd'
import socketClient, { MESSAGE_EVENTS } from 'src/utils/socketClient'
import { Smile, X } from 'lucide-react'
import EmojiPicker from 'emoji-picker-react'
import client1 from 'src/utils/apolloClient'
import debounce from 'debounce';

export default function SendMessage({
  setMessageBody,
  messageBody,
  selectedEditMessage,
  setSelectedEditMessage,
  handleEdit,
  handleEditSubmit,
}) {
  const { user }: any = useAuth()
  const { selectedChat, notify, selectChat } = useStateContext()
  // const [messageBody, setMessageBody] = useState('')
  const [typing, setTyping] = useState({ typerId: null, type: '', conversationId: null, description: '' })
  const [lastTypingUpdateTime, setLastTypingUpdateTime] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [lastChangeTime, setLastChangeTime] = useState(0)
  const [uploadedImage, setUploadedImage] = useState([])
  const [showEmojiPicker, setShowEmojiPicker] = useState(null)

  const [submitPrivateMsg, { loading: loadingPrivate }] = useMutation(SEND_PRIVATE_MSG, {
    client: client1,
    onError: (err) => {
      alert(err)
    },
  })

  const [submitGroupMsg, { loading: loadingGroup }] = useMutation(SEND_GROUP_MSG, {
    client: client1,
    onError: (err) => {
      alert(err)
    },
  })

  const [startStopTyping] = useMutation(START_STOP_TYPING, {
    client: client1,
    onError: (err) => {
      alert(err)
    },
  })


  const [editPrivateMsg, { loading }] = useMutation(EDIT_PRIVATE_MSG, {
    client: client1,
    onCompleted: (res) => {
      setSelectedEditMessage(undefined)
    },
    onError: (err) => {
      alert(err)
    },
  })

  const [editGroupMsg] = useMutation(EDIT_GROUP_MSG, {
    client: client1,
    onCompleted: (res) => {
      setSelectedEditMessage(undefined)
    },
    onError: (err) => {
      alert(err)
    },
  })

  const handleSendMessage = async (e) => {
    e.preventDefault()
    // if (messageBody.trim() === '' || !selectedChat) return
    let UploadImageRoute =
      API.endpoint.includes('stage') || API.endpoint.includes('local')
        ? `/upload?type=bluejestic-stage/message/${selectedChat?.chatData?.id}/product`
        : `/upload?type=bluejestic-production/message/${selectedChat?.chatData?.id}/product`

    if (selectedChat.chatType === 'private') {
      let ImageId = []
      if (selectedChat.chatData?.replyMessageId) {
        if (uploadedImage.length > 0) {
          const UploadFile = new FormData()
          uploadedImage.forEach((file) => {
            UploadFile.append('image', file)
          })
          UploadFile.append('media_for', 'product')
          const files = await ApiPost(UploadImageRoute, UploadFile)
            .then((res: any) => {
              res?.data?.image?.map((image) => {
                ImageId.push(image.media)
              })
            })
            .catch((err) => {
              return err
            })
        }
        await submitPrivateMsg({
          variables: {
            receiverId: selectedChat.chatData.id,
            body: messageBody,
            replyMessageId: selectedChat?.chatData?.replyMessageId,
            images: ImageId?.length > 0 ? ImageId : [],
          },
          update: () => {
            clearInput()
          },
        })
      } else {
        if (uploadedImage.length > 0) {
          const UploadFile = new FormData()
          uploadedImage.forEach((file) => {
            UploadFile.append('image', file)
          })
          UploadFile.append('media_for', 'product')
          const files = await ApiPost(UploadImageRoute, UploadFile)
            .then((res: any) => {
              res?.data?.image?.map((image) => {
                ImageId.push(image.media)
              })
            })
            .catch((err) => {
              return err
            })
        }
        if (selectedEditMessage) {
          await editPrivateMsg({
            variables: {
              editPrivateMessageId: selectedEditMessage?.id,
              body: messageBody,
              receiverId: selectedChat.chatData.id,
            },
            update: () => {
              clearInput()
            },
          })
        } else {
          await submitPrivateMsg({
            variables: {
              receiverId: selectedChat.chatData.id,
              body: messageBody,
              images: ImageId?.length > 0 ? ImageId : [],
            },
            update: () => {
              clearInput()
            },
          })
        }
      }
    } else if (selectedChat.chatType === 'group') {
      if (selectedChat.chatData?.replyMessageId) {
        submitGroupMsg({
          variables: {
            conversationId: selectedChat.chatData.id,
            body: messageBody,
            replyMessageId: selectedChat.chatData?.replyMessageId,
          },
          update: () => {
            clearInput()
          },
        })
      } else {
        if (selectedEditMessage) {
          await editGroupMsg({
            variables: {
              editGroupMessageId: selectedEditMessage?.id,
              body: messageBody,
              conversationId: selectedChat.chatData.id,
            },
            update: () => {
              clearInput()
            },
          })
        } else {
          submitGroupMsg({
            variables: {
              conversationId: selectedChat.chatData.id,
              body: messageBody,
            },
            update: () => {
              clearInput()
            },
          })
        }
      }
    }
  }

  const clearInput = () => {
    setMessageBody('')
    setUploadedImage([])
    let ReflectedData = { ...selectedChat?.chatData }
    delete ReflectedData?.replyMessageId
    selectChat(ReflectedData, selectedChat?.chatType)
  }


  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setCurrentTime(Date.now())
  //   }, 500)

  //   return () => clearInterval(timer)
  // }, [])

  const startTypingMessage = () => {
    startStopTyping({
      variables: {
        chatType: selectedChat.chatType,
        conversationId: selectedChat.chatData.id,
        action: 'START'
      },
    })
  }

  const stopTypingMessage = () => {
    startStopTyping({
      variables: {
        chatType: selectedChat.chatType,
        conversationId: selectedChat.chatData.id,
        action: 'STOP'
      },
    })
  }
  // useEffect(() => {
  //   if (lastChangeTime - lastTypingUpdateTime > 1500) {
  //     startStopTyping({
  //       variables: {
  //         chatType: selectedChat.chatType,
  //         conversationId: selectedChat.chatData.id,
  //         action: 'STOP'
  //       },
  //     })
  //   }
  // }, [lastChangeTime])

  const handleUpload = (e) => {
    const files = e.target.files
    const maxSize = 10 * 1024 * 1024 // 10MB in bytes
    if (files?.[0]?.size <= maxSize) {
      const images = Array.from(files)
      handleUploadFiles(images)
    } else {
      notification['error']({
        message: 'Error',
        description: `File is too large. Maximum size allowed is 10MB.`,
      })
      e.target.value = ''
    }

    // const images = Array.from(files)
    //   .map((file: File) => {
    //     if ((file as File).size <= maxSize) {
    //       return file
    //     } else {
    //       notification['error']({
    //         message: 'Error',
    //         description: `File ${(file as File).name} is too large. Maximum size allowed is 10MB.`,
    //       })
    //       return null
    //     }
    //   })
    //   .filter(Boolean) // Filter out null entries

    // handleUploadFiles(images)
    // setUploadedImage(images)
  }

  const handleUploadFiles = async (files) => {
    let uploaded = []
    let thumbnails = []
    let uploadStatus = ''
    let postType = ''
    const regex = new RegExp(/[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF|webp|avif)$/)

    if (await files?.every((item, index) => regex.test(item?.type || item?.data?.type))) {
      await Promise.all(
        await files?.map(async (item, i) => {
          // if (i + 1 <= 7) {
          // if ((await files?.every((item, index) => regex.test(item?.type))) && item?.size < 5e6) {
          if (item?.size > 3e6) {
            let finalData = await compressImages(item)
            if (finalData) {
              uploaded?.push(finalData)
            }
          } else {
            uploaded?.push(item)
          }
          // }
          // }
        }),
      )

      uploadStatus = 'active'
      postType = 'image'
    }
    // else {
    //   notifyError("You can upload max 15 MB data.");
    // }

    setUploadedImage(uploaded)
  }

  function handleRemoveImage(item: any): void {
    setUploadedImage(uploadedImage.filter((i) => i !== item))
  }


  const handleEmojiChange = (emojiData) => {
    setMessageBody(messageBody + emojiData.emoji)
    setShowEmojiPicker(false)
  }

  return (
    <React.Fragment>

      {uploadedImage?.length > 0 && <div className="uploaded-image-alignment">
        {uploadedImage.map((item, index) => (
          <div key={index} className="uploaded-image-box">
            <div className="flex items-center">
              <img src={URL.createObjectURL(item)} alt="uploaded-image" className="h-full w-full object-cover" />
              <p className="uploaded-image-name">{item?.name}</p>
            </div>
            <div className="uploaded-image-close-btn" onClick={() => handleRemoveImage(item)}>
              <X />
            </div>
          </div>
        ))}
      </div>}
      <div className="message-box-footer-alignment">
        {selectedEditMessage && 'Edit On'}
        <div
          className="message-box-input-alignment"
          style={{
            borderColor: messageBody || uploadedImage.length > 0 || selectedEditMessage ? '#c0c0c5' : '',
          }}
        >
          <input
            type="text"
            placeholder="Type message here..."
            value={messageBody}
            onChange={(e: any) => {
              setMessageBody(e.target.value)

            }}
            onKeyDown={(e: any) => {
              if (e.code === 'Enter') {
                handleSendMessage(e)
              } else {
                startTypingMessage();
              }
            }}
            onKeyUp={(e: any) => {
              debounce(stopTypingMessage, 800)();
            }}
          />
          <div className="message-input-other-option-alignment">
            {/* <div className="message-input-icon-alignment">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M16.08 8.28768L9.18747 15.1802C8.34309 16.0246 7.19786 16.4989 6.00372 16.4989C4.80959 16.4989 3.66436 16.0246 2.81997 15.1802C1.97559 14.3358 1.50122 13.1906 1.50122 11.9964C1.50122 10.8023 1.97559 9.65707 2.81997 8.81268L9.71247 1.92018C10.2754 1.35726 11.0389 1.04102 11.835 1.04102C12.6311 1.04102 13.3946 1.35726 13.9575 1.92018C14.5204 2.48311 14.8366 3.24659 14.8366 4.04268C14.8366 4.83878 14.5204 5.60226 13.9575 6.16518L7.05747 13.0577C6.77601 13.3391 6.39427 13.4973 5.99622 13.4973C5.59818 13.4973 5.21643 13.3391 4.93497 13.0577C4.65351 12.7762 4.49539 12.3945 4.49539 11.9964C4.49539 11.5984 4.65351 11.2166 4.93497 10.9352L11.3025 4.57518"
                  stroke="#636773"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div> */}
            <div className="message-input-icon-alignment">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M14.25 2.25H3.75C2.92157 2.25 2.25 2.92157 2.25 3.75V14.25C2.25 15.0784 2.92157 15.75 3.75 15.75H14.25C15.0784 15.75 15.75 15.0784 15.75 14.25V3.75C15.75 2.92157 15.0784 2.25 14.25 2.25Z"
                  stroke="#636773"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.375 7.5C6.99632 7.5 7.5 6.99632 7.5 6.375C7.5 5.75368 6.99632 5.25 6.375 5.25C5.75368 5.25 5.25 5.75368 5.25 6.375C5.25 6.99632 5.75368 7.5 6.375 7.5Z"
                  stroke="#636773"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.75 11.25L12 7.5L3.75 15.75"
                  stroke="#636773"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <input type="file" onChange={(e) => handleUpload(e)} />
            </div>
            <div className="message-input-icon-alignment">
              <div className="relative">
                <button
                  onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  className="p-1 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 rounded-full hover:bg-gray-100 dark:hover:bg-dark-700"
                >
                  <Smile size={20} />
                </button>
                {showEmojiPicker && (
                  <div className="absolute bottom-full right-0 mb-2 emoji-picker">
                    <EmojiPicker
                      onEmojiClick={handleEmojiChange}
                      width={300}
                      height={400}
                    />
                  </div>
                )}
              </div>

              {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <g clipPath="url(#clip0_104_4371)">
                  <path
                    d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                    stroke="#636773"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6 10.5C6 10.5 7.125 12 9 12C10.875 12 12 10.5 12 10.5"
                    stroke="#636773"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.75 6.75H6.75667"
                    stroke="#636773"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.25 6.75H11.2567"
                    stroke="#636773"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_104_4371">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg> */}
            </div>
          </div>
        </div>
        <div
          className="message-send-button-alignment"
          style={{
            opacity:
              messageBody || uploadedImage.length > 0 || selectedEditMessage ? 1 : 0.5,
          }}
          onClick={(e) => handleSendMessage(e)}
        >
          {/* {selectedEditMessage ? (
            <div>Save Edit</div>
          ) : ( */}
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 2L11 13" stroke="#F8F8F8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M22 2L15 22L11 13L2 9L22 2Z"
              stroke="#F8F8F8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {/* )} */}
        </div>
      </div>
    </React.Fragment>
  )
}
