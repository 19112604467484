// @ts-nocheck

import React, { useEffect, useState, useRef, useCallback } from 'react'
import FilterIcon from '../../../../../public/assets/icon/filter-2.svg'
import DownArrowIcon from '../../../../../public/assets/icon/down-arrow-icon.svg'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import GET_CATEGORIES_QUERY from 'src/graphql/queries/getCategories'
import client1 from 'src/utils/apolloClient'
import { useProductFilter } from 'src/utils/ProductContext'
import { InputNumber, Slider } from 'antd'
import GET_MIN_MAX_AVG_PRICE_OF_PRODUCTS from 'src/graphql/queries/getMinMaxAvgPriceOfProductsInput'

export default function ProductFilter({ APIcall, category_slug, subCategory_slug, childSubCategory_slug, store_slug }) {
  const [openTab, setOpenTab] = useState('')
  const [categoryData, setCategoryData] = useState<{ id: number } | null>(null)
  const { setFilterData, filterData } = useProductFilter()
  const [rangeValue, setRangeValue] = useState<[number, number]>([1, 1000])
  const [maxValuesData, setMaxValuesData] = useState<number | 1>(1)
  const isInitialMount = useRef(true)
  const minDistance = 5
  const minValue = rangeValue[0]
  const maxValue = rangeValue[1]

  useEffect(() => {
    getOrderData()
  }, [childSubCategory_slug])


  const getOrderData = async () => {
    const { data }: any = await client1.query({
      query: GET_MIN_MAX_AVG_PRICE_OF_PRODUCTS,
      variables: {
        input: {
          category: category_slug,
          subCategory: subCategory_slug,
          childSubCategory: childSubCategory_slug,
          store_slug: store_slug,
        },
      },
    })
    const fetchedMaxValue = data?.getMinMaxAvgPriceOfProducts?.data
    setMaxValuesData(fetchedMaxValue?.maxPrice)
    setRangeValue([minValue, fetchedMaxValue?.maxPrice]) // Update the max value in rangeValue
  }

  const handleSliderChange = (newValue: [number, number]) => {
    if (!Array.isArray(newValue)) {
      return
    }

    const [newMin, newMax] = newValue

    if (newMax - newMin >= minDistance) {
      setRangeValue(newValue)
    } else {
      if (newMin !== rangeValue[0]) {
        setRangeValue([newMax - minDistance, newMax])
      } else {
        setRangeValue([newMin, newMin + minDistance])
      }
    }
  }

  const handleMinInputChange = (value) => {
    const parsedValue = Number(value)
    if (!isNaN(parsedValue) && parsedValue < rangeValue[1] - minDistance) {
      setRangeValue([parsedValue, rangeValue[1]])
    }
  }

  const handleMaxInputChange = (value) => {
    const parsedValue = Number(value)
    if (!isNaN(parsedValue) && parsedValue > rangeValue[0] + minDistance && parsedValue <= maxValuesData) {
      setRangeValue([rangeValue[0], parsedValue])
    }
  }

  const getCategory = async () => {
    const data = await client1.query({ query: GET_CATEGORIES_QUERY })

    if (data?.data?.getAllCategoryDetail?.length > 0) {
      const updatedData = data.data.getAllCategoryDetail.map((category) => ({
        ...category,
        subCategory: category.subCategory || [],
      }))

      setCategoryData(updatedData[0]) // Set the first category as categoryData
    }
  }

  const handleSelect = (tab) => {
    setOpenTab((prevTab) => (prevTab === tab ? '' : tab))
  }

  const handleClick = (subCategory, fieldName) => {
    if (fieldName === 'subCategory') {
      const prevSubCategory = filterData?.subCategory || []
      const isSelected = prevSubCategory.some((s) => s.subCategory === subCategory.id)

      setFilterData({
        ...filterData,
        [fieldName]: isSelected
          ? prevSubCategory.filter((s) => s.subCategory !== subCategory.id)
          : [...prevSubCategory, { subCategory: subCategory.id, category: subCategory.category_id }],
      })
    }
  }

  // const handleClick = (c, fieldName) => {
  //   if (fieldName === 'subCategory') {
  //     let prevSubCategory = filterData?.subCategory
  //     let prevCategory = filterData?.category

  //     if (prevSubCategory?.find((s) => s?.subCategory === c?.id)) {
  //       setFilterData({
  //         ...filterData,
  //         [fieldName]: prevSubCategory.filter((sid) => sid?.subCategory !== c?.id),
  //         // category: c?.category_id,
  //       })
  //       // handleFilter({
  //       //   ...filterData,
  //       //   [fieldName]: prevSubCategory.filter((sid) => sid !== c?.id),
  //       //   category: prevCategory.filter((sid) => sid !== c?.category_id),
  //       // })
  //     } else {
  //       setFilterData({
  //         ...filterData,
  //         [fieldName]: [...prevSubCategory, { subCategory: c?.id, category: c?.category_id }],
  //         // category: [...prevCategory, c?.category_id],
  //       })
  //       // handleFilter({
  //       //   ...filterData,
  //       //   [fieldName]: [...prevSubCategory, { subCategory: c?.id }],
  //       //   category: [...prevCategory, c?.category_id],
  //       // })
  //     }
  //   }
  // }

  const useDebounceTwoStates = (state1, state2, delay) => {
    const [readyToCallApi, setReadyToCallApi] = useState(false)

    const debounce = useCallback(() => {
      setReadyToCallApi(false)
      const handler = setTimeout(() => {
        setReadyToCallApi(true)
      }, delay)

      return () => {
        clearTimeout(handler)
      }
    }, [state1, state2, delay])

    useEffect(() => {
      const cleanup = debounce()
      return cleanup
    }, [debounce])

    return readyToCallApi
  }

  const isReadyForApiCall = useDebounceTwoStates(minValue, maxValue, 3000) // 300ms debounce

  useEffect(() => {
    if (isReadyForApiCall) {
      APIcall?.({ minValue, maxValue: maxValue >= maxValuesData ? maxValuesData : maxValue }, 'price') // Send 1000 only on initial cal
    }
  }, [isReadyForApiCall, minValue, maxValue])

  return (
    <React.Fragment>
      <div className="stickyFilter">
        <div className="filter-modal-heading-alignment">
          <div className="filter-main-name-alignment">
            <img src={FilterIcon.src} alt="FilterIcon" />
            <h6>Filters</h6>
          </div>
          {/* <div className="filter-reseat-alignment">
            <span>Apply</span>
          </div> */}
        </div>
        <div className="filter-category-details-alignment">
          {/* <div className="main-filter-categories-box">
            <div className="main-filter-categories-box-header" onClick={() => handleSelect('category')}>
              <p>Categories</p>
              <div className="drop-down-icon-alignment">
                <img
                  src={DownArrowIcon.src}
                  alt="DownArrowIcon"
                  className={
                    openTab === 'category' ? 'icon-rotate transition-icon' : 'transition-icon icon-rotate-stable'
                  }
                />
              </div>
            </div>
            <div
              className={
                openTab === 'category' ? 'main-filter-details content-show' : 'main-filter-details content-hidden'
              }
            >
              <div className="main-filter-details-alignment">
                {categoryData?.map((category) => {
                  return category?.subCategory?.map((sub, index) => {
                    return (
                      <button
                        key={sub?.id}
                        className={filterData?.subCategory?.some((s) => s?.subCategory === sub.id) ? 'active' : ''}
                        onClick={() => handleClick({ ...sub, category_id: category.id }, 'subCategory')}
                      >
                        {sub?.name}
                      </button>
                    )
                  })
                })}
                <span>Show More</span>
              </div>
            </div>
          </div>
          <div className="colors-box-alignemtn">
            <div className="color-header" onClick={() => handleSelect('color')}>
              <p>Color</p>
              <div className="drop-down-icon-alignment">
                <img
                  src={DownArrowIcon.src}
                  alt="DownArrowIcon"
                  className={openTab === 'color' ? 'icon-rotate transition-icon' : 'transition-icon icon-rotate-stable'}
                />
              </div>
            </div>
            <div
              className={openTab === 'color' ? 'color-body-content content-show' : 'color-body-content content-hidden'}
            >
              <div className="color-body-content-alignment">
                <div className="button-alignment">
                  <button>White</button>
                  <button>Blue</button>
                  <button>Red</button>
                  <button>Yellow</button>
                  <button>pink</button>
                  <button>Black</button>
                  <button>Green</button>
                </div>
                <div className="show-more-text">
                  <span>Show More</span>
                </div>
              </div>
            </div>
          </div>
          <div className="colors-box-alignemtn">
            <div className="color-header" onClick={() => handleSelect('size')}>
              <p>Size</p>
              <div className="drop-down-icon-alignment">
                <img
                  src={DownArrowIcon.src}
                  alt="DownArrowIcon"
                  className={openTab === 'size' ? 'icon-rotate transition-icon' : 'transition-icon icon-rotate-stable'}
                />
              </div>
            </div>
            <div
              className={openTab === 'size' ? 'color-body-content content-show' : 'color-body-content content-hidden'}
            >
              <div className="color-body-content-alignment">
                <div className="button-alignment">
                  <button>SX</button>
                  <button>S</button>
                  <button>M</button>
                  <button>L </button>
                  <button>XL</button>
                </div>
              </div>
            </div>
          </div>
          <div className="main-filter-categories-box">
            <div className="main-filter-categories-box-header" onClick={() => handleSelect('brand')}>
              <p>Brand</p>
              <div className="drop-down-icon-alignment">
                <img
                  src={DownArrowIcon.src}
                  alt="DownArrowIcon"
                  className={openTab === 'brand' ? 'icon-rotate transition-icon' : 'transition-icon icon-rotate-stable'}
                />
              </div>
            </div>
            <div
              className={
                openTab === 'brand' ? 'main-filter-details content-show' : 'main-filter-details content-hidden'
              }
            >
              <div className="main-filter-details-alignment">
                <button>Craft Boutique NY</button>
                <button>Big Clock Store</button>
                <button>Ugears USA</button>
                <button>Ugears USA</button>
                <button>Print Time </button>
                <button>Map Clock (2)</button>
                <span>Meta Ultimate</span>
              </div>
            </div>
          </div>
          <div className="colors-box-alignemtn">
            <div className="color-header" onClick={() => handleSelect('condition')}>
              <p>Condition</p>
              <div className="drop-down-icon-alignment">
                <img
                  src={DownArrowIcon.src}
                  alt="DownArrowIcon"
                  className={
                    openTab === 'condition' ? 'icon-rotate transition-icon' : 'transition-icon icon-rotate-stable'
                  }
                />
              </div>
            </div>
            <div
              className={
                openTab === 'condition' ? 'color-body-content content-show' : 'color-body-content content-hidden'
              }
            >
              <div className="color-body-content-alignment">
                <div className="button-alignment">
                  <button>New</button>
                  <button>Used</button>
                </div>
              </div>
            </div>
          </div> */}
          <div className="price-range-alignment">
            <div className="price-range-header" onClick={() => handleSelect('priceRange')}>
              <p>Price range</p>
              <div className="drop-down-icon-alignment">
                <img
                  src={DownArrowIcon.src}
                  alt="DownArrowIcon"
                  className={
                    openTab === 'priceRange' ? 'icon-rotate transition-icon' : 'transition-icon icon-rotate-stable'
                  }
                />
              </div>
            </div>
            {/* <div
              className={
                openTab === 'priceRange'
                  ? 'price-range-details-input-alignment content-show'
                  : 'price-range-details-input-alignment content-hidden'
              }
            >
              <div className="price-slider-main">
                <div className="price-slider-line" ref={sliderRef}>
                  <div
                    className="price-slider-increse-one"
                    style={{ left: `${(minValue / maxLimit) * 100}%` }}
                    onMouseDown={handleMinMouseDown}
                  ></div>
                  <div
                    className="price-slider-increse-two"
                    style={{ left: `${(maxValue / maxLimit) * 100}%` }}
                    onMouseDown={handleMaxMouseDown}
                  ></div>
                  <div
                    className="price-slider-incresed-fill"
                    style={{
                      left: `${(minValue / maxLimit) * 100}%`,
                      width: `${((maxValue - minValue) / maxLimit) * 100}%`,
                    }}
                  ></div>
                </div>
              </div>
            </div> */}
            <div
              className={
                openTab === 'priceRange'
                  ? 'price-range-details-input-alignment content-show'
                  : 'price-range-details-input-alignment content-hidden'
              }
            >
              <Slider
                range
                value={[rangeValue[0], Math.min(rangeValue[1], maxValuesData)]} // Display max 100
                onChange={handleSliderChange}
                min={0}
                max={maxValuesData}
                tooltipVisible={false}
              />

              <div className="price-range-details-input-alignment content-show">
                <div className="price-range-body-alignment">
                  <div className="price-range-input-alignment">
                    <label>Minimum</label>
                    <InputNumber
                      min={0}
                      max={Math.min(rangeValue[1] - minDistance, maxValuesData)}
                      value={rangeValue[0]}
                      onChange={handleMinInputChange}
                      style={{ width: '45%' }}
                    />
                  </div>
                  <div className="minus-alignment">
                    <span>-</span>
                  </div>
                  <div className="price-range-input-alignment">
                    <label>Maximum</label>
                    <InputNumber
                      min={rangeValue[0] + minDistance}
                      max={maxValuesData} // Restrict input display max to 100
                      value={Math.min(rangeValue[1], maxValuesData)} // Display max 100
                      onChange={handleMaxInputChange}
                      style={{ width: '45%' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
