import { gql } from '@apollo/client'

const GET_REPORT_CONTENT = gql`
  query GetAllReportPostContents {
  getAllReportPostContents {
    success
    message
    data {
      id
      reason
      description
      isActive
      isDeleted
      createdAt
      updatedAt
    }
  }
}
`
export default GET_REPORT_CONTENT
