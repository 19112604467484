import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import GET_SINGLE_CATEGORY from 'src/graphql/queries/getSingleCategory'
import GET_GLOBAL_SEARCH_WITH_ELASTIC_SEARCH from 'src/graphql/queries/sellerQueries/getGlobalSearchWithElasticSearch'
import client1 from 'src/utils/apolloClient'

interface fetchCategory {
  loading: true | false
  error: {} | null
  called: true | false
  cartCount: number
  cartData: []
}

const initialState = {
  loading: false,
  error: null,
  data: 0,
  called: false,
  cartCount: 0,
  cartData: [],
} as fetchCategory

export const fetchCategoryBySlug = createAsyncThunk(
  'fetchCategory/fetchCategoryBySlug',
  async (categoryDetail: any) => {
    const { type, categoryName, subcategoryName, childsubSlug, nestedSlug } = categoryDetail
    const { data } = await client1.query({
      query: GET_SINGLE_CATEGORY,
      variables: {
        type: type,
        categoryName: categoryName,
        subcategoryName: subcategoryName,
        childsubSlug: childsubSlug,
        nestedSlug: nestedSlug,
      },
      fetchPolicy: 'network-only',
    })
    return { data: data?.getSingleCategory?.data, subcategoryName: subcategoryName }
  },
)

const fetchCategory = createSlice({
  name: 'fetchCategory',
  initialState,
  reducers: {
    // : (state: any, action) => {
    //   state.cartData = action.payload
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategoryBySlug.pending, (state: any, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchCategoryBySlug.fulfilled, (state: any, action) => {
      state.loading = 'success'
      if (action?.payload?.subcategoryName === 'women') {
        state.womenCategoryData = action?.payload?.data
        state.categoryData = action?.payload?.data
      } else if (action?.payload?.subcategoryName === 'men') {
        state.menCategoryData = action?.payload?.data
        state.categoryData = action?.payload?.data
      } else if (action?.payload?.subcategoryName === 'women-accessories') {
        state.womenAccessoriesCategoryData = action?.payload?.data
        state.categoryData = action?.payload?.data
      } else if (action?.payload?.subcategoryName === 'men-accessories') {
        state.menAccessoriesCategoryData = action?.payload?.data
        state.categoryData = action?.payload?.data
      }  else {
        state.categoryData = action?.payload
      }
    })
    builder.addCase(fetchCategoryBySlug.rejected, (state: any, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })
  },
})

// export const {  } = fetchCategory.actions
export default fetchCategory.reducer
