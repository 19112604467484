import { gql } from '@apollo/client'

const query = gql`
  mutation CreateBookmarkCollection($input: BookmarkCollectionInput!) {
    createBookmarkCollection(input: $input) {
      success
      message
      data {
        id
        name
      }
    }
  }
`
export default query
