import React, { useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { Modal } from 'antd'
import AddShppingAddressModal from './AddShppingAddressModal'
import { MapView } from './MapView'
import SelectMapAddressModal from './SelectMapAddressModal'
import GET_ALL_BILLING_ADDRESS from 'src/graphql/queries/getAllBillingAddress'
import { useQuery } from '@apollo/client'
import { Country, State } from 'country-state-city'

interface ShppingAddressSectionProps {
  defaultAddress:any
  // setZipcode: React.Dispatch<React.SetStateAction<string>>;
  // ...other props if any...
}

const ShppingAddressSection: React.FC<ShppingAddressSectionProps> = ({ defaultAddress }) => {
  // const { loading, error, data } = useQuery(GET_ALL_BILLING_ADDRESS)

  const [isMapModalVisible, setIsMapModalVisible] = useState(false)
  const [isSelectMapModalVisible, setIsSelectMapModalVisible] = useState(false)
  const [isAddressEdit, setIsAddressEdit] = useState(false)
  const [allCountry, setAllCountry] = useState<any>(Country.getAllCountries())
  const [allState, setAllState] = useState<any>(State.getAllStates())
  const [selectedCity, setSelectedCity] = useState([])

  const country = ['United States', 'India', 'United kingdom', 'Vietnam', 'Philippines']
  const city = ['Jacksonville', 'Miami', 'Tampa', '	Lakeland', 'Pine Hills']
  const [latLong, setLatLong] = useState({ lat: 27.98375, lng: -81.75455 })
  // const [latLong, setLatLong] = useState({ lat: 27.98375, lng: -81.75455 })
  const state = ['Illinois', 'Texas', 'Florida', 'Alaska', 'Hawaii']
  const [addressFormData, setAddressFormData] = useState<any>({
    firstName: '',
    lastName: '',
    streetAddress: '',
    country: country[0],
    state: state[0],
    city: '',
    zipcode: '',
    buildingName: '',
    number: '',
    isDefault: false,
    isPinLocation: false,
    latitude: latLong.lat,
    longitude: latLong.lng,
  })

  // let defaultAddress = data?.getAllBillingAddress?.filter((data) => data?.isDefault)

  const handleCancel = () => {
    setIsMapModalVisible(false)
    setIsSelectMapModalVisible(false)
  }

  const handleCancelModal = () => {
    setIsMapModalVisible(false)
    setIsSelectMapModalVisible(true)
  }
  const handleClick = (coord) => {
    setLatLong(coord)
  }

  return (
    <>
      <div>
        <div className="shipping-address-card-main">
          <div className="map-contain">
            <MapView latitude={latLong.lat} longitude={latLong.lng} setLatLong={setLatLong} />
          </div>
          <div className="card-description-part">
            <div className="apartment-text-align">
              <span>Apartment</span>
            </div>
            <div className="name-text-align">
              <h2>
                {defaultAddress && defaultAddress[0]?.firstName} {'  '}
                {defaultAddress && defaultAddress[0]?.lastName}{' '}
                {defaultAddress && defaultAddress[0]?.firstName && defaultAddress && defaultAddress[0]?.lastName && ','}
              </h2>
            </div>
            <div className="address-text-align">
              <p>
                {defaultAddress && defaultAddress[0]?.streetAddress}
                {defaultAddress && defaultAddress[0]?.streetAddress && ','} {defaultAddress && defaultAddress[0]?.state}
                {defaultAddress && defaultAddress[0]?.state && ','} {defaultAddress && defaultAddress[0]?.zipcode}
              </p>
            </div>
            <div className="mobile-text-align">
              <span>{defaultAddress && defaultAddress[0]?.number}</span>
            </div>
            <div className="chnage-text-align" onClick={() => setIsSelectMapModalVisible(true)}>
              <span>{defaultAddress?.length > 0 && 'Change'}</span>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isMapModalVisible}
        onCancel={handleCancelModal}
        footer={null}
        closable={false}
        width={588}
        maskStyle={{ zIndex: 9999 }}
        wrapClassName="post-add-edit-modal"
      >
        <AddShppingAddressModal
          setIsMapModalVisible={setIsMapModalVisible}
          setIsSelectMapModalVisible={setIsSelectMapModalVisible}
          isAddressEdit={isAddressEdit}
          setIsAddressEdit={setIsAddressEdit}
          setAddressFormData={setAddressFormData}
          addressFormData={addressFormData}
          latLong={latLong}
          setLatLong={setLatLong}
          setAllCountry={setAllCountry}
          allCountry={allCountry}
          setAllState={setAllState}
          allState={allState}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
        />
      </Modal>
      <Modal
        open={isSelectMapModalVisible}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        width={588}
        maskStyle={{ zIndex: 9999 }}
        wrapClassName="post-add-edit-modal"
      >
        <SelectMapAddressModal
          setIsMapModalVisible={setIsMapModalVisible}
          setIsSelectMapModalVisible={setIsSelectMapModalVisible}
          isAddressEdit={isAddressEdit}
          setIsAddressEdit={setIsAddressEdit}
          setAddressFormData={setAddressFormData}
          addressFormData={addressFormData}
          latLong={latLong}
          setAllCountry={setAllCountry}
          allCountry={allCountry}
          setAllState={setAllState}
          allState={allState}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
        />
      </Modal>
    </>
  )
}

export default ShppingAddressSection
