const Description: React.FC<{
  description: string
  // features: string
}> = ({
  description,
  // features
}) => {
  return (
    <div>
      <div className="p-8">
        <div className="mb-3 text-black font-medium text-base">Description</div>
        <div className="text-sm text-gray-500 font-normal Description-html-layout" dangerouslySetInnerHTML={{ __html: description }}></div>
      </div>
      {/* <div className="bg-white pt-4">
        <div className="mb-3 text-black font-medium text-base">Highlights</div>
        <div><ReactMarkdown>{features}</ReactMarkdown></div>
      </div> */}
    </div>
  )
}

export default Description
