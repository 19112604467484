import React from 'react';
import router from 'next/router';

const LiveEvents: React.FC<any> = ({ notification, setOpen }: any) => {
    const payload = notification?.payload;

    const navigateToStore = () => {
        setOpen(false);
        router.push(`/store/${payload?.storeSlug}`);
    }
    return (
        <div className="py-4 px-1 sm:px-3 md:px-6">
            <div className="flex items-start space-x-5 p-4 hover:bg-gray-50">
                {payload?.storeImg && (
                    <div className="flex-shrink-0 w-[58px] h-[58px] rounded-lg overflow-hidden  border-white bg-gray-100">
                        <img
                            src={payload.storeImg}
                            alt={payload.storeName || "Product"}
                            width={58}
                            height={58}
                            className="w-full h-full object-cover"
                        />
                    </div>
                )}
                <div className="">
                    <div className="flex items-center">
                        <div>
                            <div>
                                <span className="text-sm font-semibold text-gray-900 mr-2">Live Event Alert:</span>
                                <a onClick={navigateToStore} className='hover:underline text-[#556EE6] hover:text-[#556EE6] mr-2 font-semibold '>{payload?.storeName}</a>
                                <span>{payload?.message} </span>
                            </div>
                            <a onClick={navigateToStore} className='hover:underline text-[#556EE6] hover:text-[#556EE6] mr-2 font-semibold '>Tap to check events</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LiveEvents;