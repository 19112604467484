// @ts-nocheck


import React, { useEffect, useState } from 'react'
import styles from './PopularStoreNew.module.scss'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { fetchPopularStoreData } from 'src/store/slices/fetchAllStoresSlice'
import Slider from 'react-slick'
import NewStoreSlider from 'src/components/StoreAndProductDetailModal/StoresModal/NewStoreSlider'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <svg style={{ transform:'none'}} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <circle opacity="0.6" cx="20" cy="20" r="20" fill="#F8F8F8" />
        <path d="M19 15L23 20L19 25" stroke="#1E2432" strokeLinecap="round" strokeLinejoin="round" />
      </svg>{' '}
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div className={className} onClick={onClick}>
    <svg  style={{ transform: "rotate(180deg)"}} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <circle opacity="0.6" cx="20" cy="20" r="20" fill="#F8F8F8" />
        <path d="M19 15L23 20L19 25" stroke="#1E2432" strokeLinecap="round" strokeLinejoin="round" />
      </svg>{' '}
    </div>
  )
}

const PopularStoreNew = () => {
  const dispatch = useAppDispatch()
  const allPopularStores = useSelector((state: any) => state?.storesData?.allPopularStores)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.2,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1699,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 923,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const followStoreCall = () => {}

  const unfollowStoreCall = () => {}

  const handleAddStoreLike = () => {}

  useEffect(() => {
    dispatch(fetchPopularStoreData({ limit: limit, page: page, type: 'ALL_STORE', search: '' }))
  }, [])

  return (
    <div>
      <div className={styles.popularStoreAlignment}>
        <div className={styles.flexBox}>
          <div className={styles.flexBoxItems}>
            <div className={styles.text}>
              <div>
                <h2>Popular Store</h2>
                <p>Browse by trending store</p>
              </div>
              <button
                className={styles.mobileHide}
                onClick={() => {
                  dispatch(
                    fetchModalData({
                      ...getAllState,
                      seeAllStore: {
                        modal: 'seeAllStoreModal',
                        isOpen: true,
                        storeFor: 'ALL_STORE',
                        id: 0,
                        modalFor: 'smallModal',
                      },
                    }),
                  )
                }}
              >
                See All
              </button>
              <button
                className={styles.mobileShow}
                onClick={() => {
                  dispatch(
                    fetchMobileModalData({
                      allModalData: [
                        ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                        {
                          modal: 'popularMobileViewStoreModal',
                          isOpen: true,
                          id: { isSeller: false, group: null, type: 'post' },
                          transform: 42,
                        },
                      ],
                      lastModalData: fetchMobileModal?.lastModalData,
                    }),
                  )
                }}
              >
                See All
              </button>
            </div>
          </div>
          <div className={styles.flexBoxItems}>
            <Slider {...settings}>
              {allPopularStores?.map((store, index) => (
                <React.Fragment key={index}>
                  <NewStoreSlider
                    store={store}
                    followStoreCall={followStoreCall}
                    unfollowStoreCall={unfollowStoreCall}
                    handleAddStoreLike={handleAddStoreLike}
                  />
                </React.Fragment>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopularStoreNew
