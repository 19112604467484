import React from 'react'
import MediaImg1 from '../../../../public/assets/img/media1.png'
import DropdownArrow from '../../../../public/assets/icon/down-arrow.svg'
import NoMeadiaImg from '../../../../public/assets/img/media-no-data.png'
import styles from './GroupMedia.module.scss'
export default function GroupMedia() {
  return (
    <>
      <div className={styles.mediaSection}>
        <div className={styles.mobileViewClubMediaDetailsAlignment}>
          <div className={styles.mediaDetailsAlignment}>
            <span>3214 media</span>
          </div>
          <div className={styles.mediaPhotoVideoDropdownAlignment}>
            <p>Photos and videos</p>
            <div>
              <img src={DropdownArrow.src} alt="DropdownArrow" />
            </div>
          </div>
        </div>
        <div className={styles.mediaDetailsGridAlignment}>
          {
            // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((_, index) => {
            //   return (
            //     <div className={styles.mediaImgAlignment} key={index}>
            //       <img src={MediaImg1.src} alt="MediaImg1" />
            //     </div>
            //   )
            // })
            <div className={styles.Nomediabx}>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="88" height="80" viewBox="0 0 88 80" fill="none">
                  <path
                    d="M2.74143 23.0006C2.08253 20.0956 3.65466 17.147 6.43393 16.0752L41.0357 2.73142C44.6504 1.33746 48.6189 3.68354 49.1401 7.5225L52.2455 30.399C52.6637 33.4798 50.6593 36.3697 47.6272 37.0574L13.8213 44.7252C10.5897 45.4582 7.37576 43.4326 6.64277 40.201L2.74143 23.0006Z"
                    fill="url(#paint0_linear_1306_32970)"
                  />
                  <ellipse
                    cx="22.8625"
                    cy="19.9682"
                    rx="2.78539"
                    ry="3.1527"
                    transform="rotate(-12.7794 22.8625 19.9682)"
                    fill="#996AFE"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M51.3568 23.8532L52.2454 30.3993C52.6636 33.4801 50.6592 36.37 47.6271 37.0577L13.8212 44.7255C10.5896 45.4585 7.37567 43.4329 6.64268 40.2013L6.51579 39.6418L14.417 30.0242C15.4411 28.7775 17.1139 28.2682 18.659 28.7326L22.7256 29.9547C24.4947 30.4864 26.3978 29.7375 27.3301 28.1428L34.0771 16.6017C35.2772 14.5489 37.9924 13.99 39.9056 15.402L51.3568 23.8532Z"
                    fill="#996AFE"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M48.5483 5.61512L49.4229 30.1006C49.5044 32.3835 48.0283 34.4315 45.8366 35.076L12.5087 44.8762C12.9416 44.8747 13.381 44.826 13.8207 44.7263L47.6266 37.0585C50.6586 36.3708 52.663 33.4809 52.2448 30.4001L49.1394 7.5236C49.046 6.8351 48.8416 6.19462 48.5483 5.61512Z"
                    fill="url(#paint1_linear_1306_32970)"
                  />
                  <path
                    d="M84.8063 48.376C85.4094 45.4899 83.8263 42.5933 81.0715 41.5426L38.6944 25.3787C35.0612 23.9929 31.0896 26.3762 30.6032 30.2343L26.5005 62.7788C26.1095 65.8806 28.1658 68.7628 31.226 69.4024L72.4808 78.0239C75.7245 78.7017 78.9035 76.6217 79.5813 73.3781L84.8063 48.376Z"
                    fill="url(#paint2_linear_1306_32970)"
                  />
                  <ellipse
                    cx="3.23936"
                    cy="4.00969"
                    rx="3.23936"
                    ry="4.00969"
                    transform="matrix(-0.978854 -0.204562 -0.204562 0.978854 65.0044 43.5918)"
                    fill="#996AFE"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M27.7316 53.0127L26.5004 62.7787C26.1094 65.8805 28.1657 68.7627 31.226 69.4023L72.4808 78.0238C75.7244 78.7016 78.9034 76.6216 79.5812 73.378L80.0641 71.0676L70.672 58.9908C69.6069 57.6213 67.7805 57.0945 66.1497 57.6865L60.8541 59.6086C58.9994 60.2818 56.9308 59.5021 55.9821 57.772L47.6482 42.5751C46.4363 40.3651 43.5072 39.8132 41.5741 41.4306L27.7316 53.0127Z"
                    fill="#996AFE"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M31.0896 28.5016L29.9427 61.8443C29.8636 64.1457 31.3665 66.2036 33.5828 66.8285L73.7385 78.1515C73.3238 78.1532 72.9026 78.1117 72.4804 78.0234L31.2256 69.402C28.1653 68.7624 26.109 65.8802 26.5 62.7784L30.6028 30.2339C30.6807 29.6154 30.8483 29.0349 31.0896 28.5016Z"
                    fill="url(#paint3_linear_1306_32970)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1306_32970"
                      x1="24.0594"
                      y1="5.45697"
                      x2="32.0294"
                      y2="40.5953"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#E1D0FF" />
                      <stop offset="1" stop-color="#C39FFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_1306_32970"
                      x1="25.8196"
                      y1="10.9295"
                      x2="32.5229"
                      y2="40.4832"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#B096E7" stop-opacity="0" />
                      <stop offset="1" stop-color="#6528E7" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_1306_32970"
                      x1="59.6433"
                      y1="28.5261"
                      x2="50.2692"
                      y2="73.382"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#E1D0FF" />
                      <stop offset="1" stop-color="#C39FFF" />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear_1306_32970"
                      x1="58.1346"
                      y1="34.3618"
                      x2="49.9926"
                      y2="73.3225"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#B096E7" stop-opacity="0" />
                      <stop offset="1" stop-color="#6528E7" />
                    </linearGradient>
                  </defs>
                </svg>
                <p>No media.</p>
              </div>
            </div>
          }
        </div>

        {/* <img src={NoMeadiaImg.src} alt="NoMeadiaImg" /> */}
        {/* <div className={styles.clubMobileViewMediaNoDataModalAlignment}>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="88" height="80" viewBox="0 0 88 80" fill="none">
              <path
                d="M2.74143 23.0006C2.08253 20.0956 3.65466 17.147 6.43393 16.0752L41.0357 2.73142C44.6504 1.33746 48.6189 3.68354 49.1401 7.5225L52.2455 30.399C52.6637 33.4798 50.6593 36.3697 47.6272 37.0574L13.8213 44.7252C10.5897 45.4582 7.37576 43.4326 6.64277 40.201L2.74143 23.0006Z"
                fill="url(#paint0_linear_1306_32970)"
              />
              <ellipse
                cx="22.8625"
                cy="19.9682"
                rx="2.78539"
                ry="3.1527"
                transform="rotate(-12.7794 22.8625 19.9682)"
                fill="#996AFE"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M51.3568 23.8532L52.2454 30.3993C52.6636 33.4801 50.6592 36.37 47.6271 37.0577L13.8212 44.7255C10.5896 45.4585 7.37567 43.4329 6.64268 40.2013L6.51579 39.6418L14.417 30.0242C15.4411 28.7775 17.1139 28.2682 18.659 28.7326L22.7256 29.9547C24.4947 30.4864 26.3978 29.7375 27.3301 28.1428L34.0771 16.6017C35.2772 14.5489 37.9924 13.99 39.9056 15.402L51.3568 23.8532Z"
                fill="#996AFE"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48.5483 5.61512L49.4229 30.1006C49.5044 32.3835 48.0283 34.4315 45.8366 35.076L12.5087 44.8762C12.9416 44.8747 13.381 44.826 13.8207 44.7263L47.6266 37.0585C50.6586 36.3708 52.663 33.4809 52.2448 30.4001L49.1394 7.5236C49.046 6.8351 48.8416 6.19462 48.5483 5.61512Z"
                fill="url(#paint1_linear_1306_32970)"
              />
              <path
                d="M84.8063 48.376C85.4094 45.4899 83.8263 42.5933 81.0715 41.5426L38.6944 25.3787C35.0612 23.9929 31.0896 26.3762 30.6032 30.2343L26.5005 62.7788C26.1095 65.8806 28.1658 68.7628 31.226 69.4024L72.4808 78.0239C75.7245 78.7017 78.9035 76.6217 79.5813 73.3781L84.8063 48.376Z"
                fill="url(#paint2_linear_1306_32970)"
              />
              <ellipse
                cx="3.23936"
                cy="4.00969"
                rx="3.23936"
                ry="4.00969"
                transform="matrix(-0.978854 -0.204562 -0.204562 0.978854 65.0044 43.5918)"
                fill="#996AFE"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.7316 53.0127L26.5004 62.7787C26.1094 65.8805 28.1657 68.7627 31.226 69.4023L72.4808 78.0238C75.7244 78.7016 78.9034 76.6216 79.5812 73.378L80.0641 71.0676L70.672 58.9908C69.6069 57.6213 67.7805 57.0945 66.1497 57.6865L60.8541 59.6086C58.9994 60.2818 56.9308 59.5021 55.9821 57.772L47.6482 42.5751C46.4363 40.3651 43.5072 39.8132 41.5741 41.4306L27.7316 53.0127Z"
                fill="#996AFE"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.0896 28.5016L29.9427 61.8443C29.8636 64.1457 31.3665 66.2036 33.5828 66.8285L73.7385 78.1515C73.3238 78.1532 72.9026 78.1117 72.4804 78.0234L31.2256 69.402C28.1653 68.7624 26.109 65.8802 26.5 62.7784L30.6028 30.2339C30.6807 29.6154 30.8483 29.0349 31.0896 28.5016Z"
                fill="url(#paint3_linear_1306_32970)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1306_32970"
                  x1="24.0594"
                  y1="5.45697"
                  x2="32.0294"
                  y2="40.5953"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#E1D0FF" />
                  <stop offset="1" stop-color="#C39FFF" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_1306_32970"
                  x1="25.8196"
                  y1="10.9295"
                  x2="32.5229"
                  y2="40.4832"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#B096E7" stop-opacity="0" />
                  <stop offset="1" stop-color="#6528E7" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_1306_32970"
                  x1="59.6433"
                  y1="28.5261"
                  x2="50.2692"
                  y2="73.382"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#E1D0FF" />
                  <stop offset="1" stop-color="#C39FFF" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_1306_32970"
                  x1="58.1346"
                  y1="34.3618"
                  x2="49.9926"
                  y2="73.3225"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#B096E7" stop-opacity="0" />
                  <stop offset="1" stop-color="#6528E7" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className={styles.clubMediaNoDataNameAlignment}>
            <span>No media.</span>
          </div>
        </div> */}
      </div>
    </>
  )
}
