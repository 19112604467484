import React from 'react'
import styles from './FeaturedProductsDetails.module.scss'

export default function FeaturedProductsDetails({ index }) {
  return (
    <div className={styles.featuredProductsBannerImg} key={index}>
      <img src="/assets/img/feature-product-img.png" alt="feature product" />

      <div className={styles.featuredProductChildProfile}>
        <img src="/assets/img/watches-img.png" alt="watch img" />

        <div className={styles.hoverEffectDetails}>
          <div className={styles.hoverEffectBox}>
            <div className={styles.hoverAllDetails}>
              <p>Rosy Crystal Radiance Ring</p>
              <div className={styles.hoverBottomDetails}>
                <div className={styles.priceAlignment}>
                  <span>$299</span>
                </div>
                <div className={styles.hoverOptionDetails}>
                  <div className={styles.optionBox}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                      <path
                        d="M17.4163 19.25L10.9997 14.6667L4.58301 19.25V4.58333C4.58301 4.0971 4.77616 3.63079 5.11998 3.28697C5.4638 2.94315 5.93011 2.75 6.41634 2.75H15.583C16.0692 2.75 16.5356 2.94315 16.8794 3.28697C17.2232 3.63079 17.4163 4.0971 17.4163 4.58333V19.25Z"
                        stroke="white"
                        strokeWidth="1.375"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className={styles.optionBox}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.58984 13.51L15.4198 17.49"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.4098 6.51001L8.58984 10.49"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className={styles.optionBox}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M7.5 4C4.46244 4 2 6.43905 2 9.44773C2 14.8955 8.5 19.848 12 21C15.5 19.848 22 14.8955 22 9.44773C22 6.43905 19.5375 4 16.5 4C14.6399 4 12.9954 4.91467 12 6.31469C11.0046 4.91467 9.36015 4 7.5 4Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
