import React, { useEffect, useState } from 'react'
import styles from './OrderHistory.module.scss'
import classNames from 'classnames'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/store/store'
import { useQuery } from '@apollo/client'
import GET_ALL_ORDER_HISTORY from 'src/graphql/queries/sellerQueries/getAllOrdersHistory'
import { useAuth } from 'src/utils/auth'
import moment from 'moment'

export default function OrderHistory() {
  const { user }: any = useAuth()
  const dispatch = useAppDispatch()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const { loading, error, data, refetch } = useQuery(GET_ALL_ORDER_HISTORY, {
    variables: {
      store_id: user?.store?.id,
      start_date: moment().startOf('day').format('2000-MM-DD[T]00:00:00.000'),
      end_date: moment().startOf('day').format('YYYY-MM-DD[T]23:59:59.999'),
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      time_interval: 'hour',
      user_id: getAllState?.lastModalData?.id?.customer?.id,
    },
  })

  const [selected, setSelected] = useState('All Time')

  const handleMoveOnOrder = (order_id) => {
    dispatch(
      fetchModalData({
        allModalData: [
          ...getAllState?.allModalData,
          { modal: 'sellerOrderDetailModal', isOpen: true, id: order_id, modalFor: 'mediumModal' },
        ],
        lastModalData: getAllState?.lastModalData,
      }),
    )
  }

  const orderStatus = (status) => {
    switch (status) {
      case 'pending':
        return 'Pending'
      default:
        return ''
    }
  }

  const sendDate = (startDate, endDate, time_interval) => {
    refetch({
      store_id: user?.store?.id,
      start_date: startDate,
      end_date: endDate,
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      time_interval: 'hour',
      user_id: getAllState?.lastModalData?.id?.customer?.id,
    })
  }

  const handleFilter = (input) => {
    setSelected(input)
    switch (input) {
      case 'All Time':
        return sendDate(
          moment().startOf('day').format('2000-MM-DD[T]00:00:00.000'),
          moment().format('YYYY-MM-DD[T]HH:mm:ss.000'),
          'hour',
        )
      case 'Today':
        return sendDate(
          moment().startOf('day').format('YYYY-MM-DD[T]00:00:00.000'),
          moment().format('YYYY-MM-DD[T]HH:mm:ss.000'),
          'hour',
        )
      case 'This Week':
        return sendDate(
          moment().startOf('week').format('YYYY-MM-DD[T]HH:mm:ss.000'),
          moment().format('YYYY-MM-DD[T]HH:mm:ss.000'),
          'day',
        )
      case 'This Month':
        return sendDate(
          moment().date(1).format('YYYY-MM-DD[T]00:00:00.000'),
          moment().format('YYYY-MM-DD[T]HH:mm:ss.000'),
          'day',
        )
      case 'This Year':
        return sendDate(
          moment().startOf('year').format('YYYY-MM-DD[T]HH:mm:ss.000'),
          moment().format('YYYY-MM-DD[T]HH:mm:ss.000'),
          'day',
        )
      default:
        return ''
    }
  }

  const styleCall = (type) => {
    switch (type) {
      case selected:
        return styles.orderHistoryTabActive
      default:
        return ''
    }
  }

  useEffect(() => {
    refetch()
  }, [])

  return (
    <div className={styles.customerOrderHistoryAlignment}>
      <div className={styles.customerOrderHeading}>
        <h5>Order History</h5>
      </div>
      <div className={styles.customerFilterAlignment}>
        <div className={styles.customerFilterBoxAlignment}>
          <div>
            <img src="/assets/seller/icons/filter-icon.svg" alt="filter icon" />
          </div>
          <span>Filters</span>
        </div>
        <div className={styles.customerFilterOrderHistoryTabAlignment}>
          <div
            className={classNames(styles.customerFilterOrderHistoryTabBox, styleCall('All Time'))}
            onClick={() => handleFilter('All Time')}
          >
            <span>All Time</span>
          </div>
          <div
            className={classNames(styles.customerFilterOrderHistoryTabBox, styleCall('Today'))}
            onClick={() => handleFilter('Today')}
          >
            <span>Today</span>
          </div>
          <div
            className={classNames(styles.customerFilterOrderHistoryTabBox, styleCall('This Week'))}
            onClick={() => handleFilter('This Week')}
          >
            <span>This Week</span>
          </div>
          <div
            className={classNames(styles.customerFilterOrderHistoryTabBox, styleCall('This Month'))}
            onClick={() => handleFilter('This Month')}
          >
            <span>This Month</span>
          </div>
          <div
            className={classNames(styles.customerFilterOrderHistoryTabBox, styleCall('This Year'))}
            onClick={() => handleFilter('This Year')}
          >
            <span>This Year</span>
          </div>
          <div className={classNames(styles.customerFilterOrderHistoryTabBox, styleCall('Custom'))}>
            <span>Custom</span>
            <div className={styles.downArrowAIcon}>
              <img src="/assets/seller/icons/down-grey.svg" alt="down arrow" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.customerFilterTableAlignment}>
        <table cellPadding={0} cellSpacing={0}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Product</th>
              <th>Price</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data?.getAllOrdersHistory?.data?.map((o, index) => {
              return (
                <tr onClick={() => handleMoveOnOrder(o?.order_id)} key={index}>
                  <td className={styles.idAlignment}>{o?.generate_id}</td>
                  <td className={styles.idAlignment}>{moment().format('MMMM DD, YYYY')}</td>
                  <td>
                    <div className={styles.productDetailsAlignment}>
                      <div className={styles.productImg}>
                        <img src={Boolean(o?.product?.images) && o?.product?.images[0]} alt="demo img" />
                      </div>

                      <div className={styles.productDetailsAllDetails}>
                        <p>{o?.product?.title}</p>
                        <div className={styles.viewAllAlignment}>
                          <a>View Detail</a>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className={styles.idAlignment}>${o?.price}</td>
                  <td>
                    <div className={styles.deliveryStatus}>
                      <span>{orderStatus(o?.order_status)}</span>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
