import React, { useEffect, useState } from 'react'
import styles from './DeleteCollectionModal.module.scss'
import { useAppDispatch } from 'src/store/store'
import { manageBookmark } from 'src/store/slices/manageBookmarkTab'
import { useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import REMOVE_BOOKMARK_COLLECTIONS from 'src/graphql/mutations/removeCollection'
import { notification } from 'antd'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import GET_ALL_BOOKMARK_COLLECTIONS from 'src/graphql/queries/getBookmarkCollections'

export default function DeleteCollectionModal() {
  const dispatch = useAppDispatch()
  const bookmarkTabData = useSelector((state: any) => state?.manageBookmarkTabsSlice?.data)
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768)

  const handleRemoveCollection = () => {
    removeBookmark({
      variables: {
        slug: bookmarkTabData?.id?.deleteCollection?.collectionId,
      },
      refetchQueries: [
        {
          query: GET_ALL_BOOKMARK_COLLECTIONS,
          variables: {
            isPrivate: bookmarkTabData?.id?.isActiveBookmark ? true : false,
          },
        },
      ],
    })
    let closeModel = fetchMobileModal?.allModalData?.filter(
      (item) => item?.modal !== fetchMobileModal?.lastModalData?.modal,
    )
    dispatch(
      fetchMobileModalData({
        allModalData: closeModel,
        lastModalData: fetchMobileModal?.lastModalData,
      }),
    )
  }

  const [removeBookmark] = useMutation(REMOVE_BOOKMARK_COLLECTIONS, {
    onCompleted: (res) => {
      notification['success']({
        message: 'Collection Deleted Successfully',
        description: 'Your collection has been deleted successfully.',
      })
      dispatch(
        manageBookmark({
          modal: '',
          isOpen: false,
          id: {
            ...bookmarkTabData?.id,
            deleteCollection: {
              ...bookmarkTabData?.id?.deleteCollection,
              isShowDeleteModel: false,
              collectionId: null,
              collectionName: '',
            },
          },
        }),
      )

      let datass = getAllState?.allModalData?.filter((item) => item?.modal !== getAllState?.lastModalData?.modal)
      {
        isDesktop &&
          dispatch(
            fetchModalData({
              allModalData: datass,
              lastModalData: getAllState?.lastModalData,
            }),
          )
      }

      setTimeout(() => {
        let collectionCloseModel = fetchMobileModal?.allModalData?.filter(
          (item) => item?.modal !== fetchMobileModal?.lastModalData?.modal,
        )
        dispatch(
          fetchMobileModalData({
            allModalData: collectionCloseModel,
            lastModalData: fetchMobileModal?.lastModalData,
          }),
        )
      }, 1000)
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const handleCloseModel = () => {
    dispatch(
      manageBookmark({
        modal: '',
        isOpen: false,
        id: {
          ...bookmarkTabData?.id,
          deleteCollection: {
            ...bookmarkTabData?.id?.deleteCollection,
            isShowDeleteModel: false,
            collectionId: null,
            collectionName: '',
          },
        },
      }),
    )
  }

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className={styles.deleteCollectionModalSection}>
      <div className={styles.deleteCollectionModalWrapper}>
        <div className={styles.deleteCollectionModalBox}>
          <div className={styles.deleteCollecyionHeading}>
            <h6>Are you sure you want to delete {bookmarkTabData?.id?.deleteCollection?.collectionName} Collection?</h6>
          </div>
          <div className={styles.deleteButtonAlignment}>
            <button onClick={() => handleRemoveCollection()}>Yes, Delete</button>
            <button onClick={() => handleCloseModel()}>Cancel</button>
          </div>
        </div>
      </div>
    </div>
  )
}
