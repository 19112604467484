import React from 'react';
import NotificationFooter from '../NotificationFooter';
import UserNotificationsProfile from '../NoticationIcon';
import router from 'next/router';
import PartyIcon from 'public/assets/svg/PartyIcon';

const NewFollowers: React.FC<any> = ({ notification, setOpen }: any) => {
    const payload = notification?.payload;
    const imagePayload = {
        name: payload?.userName,
        image: payload?.userImg,
    }
    return (
        <div className="py-4 px-1 sm:px-3 md:px-6">
            <div className="flex items-start space-x-5 p-4 hover:bg-gray-50">
                <UserNotificationsProfile imagePayload={imagePayload} />
                <div className="w-full">
                    <div className=" items-center">
                        <div className=''>
                            <span className="text-sm font-semibold text-gray-900 my-0 mr-1">{payload?.userName}</span>
                            <span className='mx-1 mr-2 inline-flex'><PartyIcon /></span>
                            <span>{payload?.message}</span>

                        </div>
                        <div className='flex items-center justify-between w-full'>
                            <NotificationFooter notification={notification} />
                            <div className='flex items-center mt-2'>
                                <button
                                    onClick={() => {
                                        setOpen?.(false);
                                        router.push(`/user/${payload?.userSlug}`);
                                    }}
                                    className='py-2 px-5 text-white bg-[#556EE6] rounded-xl ml-2 text-[12px] sm:text-[13px]'>Follow Back</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default NewFollowers;