import { gql } from '@apollo/client'

const GET_GLOBAL_SEARCH_WITH_ELASTIC_SEARCH = gql`
  query globalSearchWithElasticSearch($search: String, $limit: Int, $page: Int) {
    globalSearchWithElasticSearch(search: $search, limit: $limit, page: $page) {
      success
      message
      data {
        products {
          id
          title
          dis_price
          dis_listPrice
          comment_count
          like_count
          images
        }
        stores {
          id
          name
          logo_image
          banner_image
          isFollow
          isLike
          like_count
          followers_count
          product_count
          product_images
        }
        users {
          id
          firstName
          lastName
          profileUrl: profileAvtar
          # profileCoverImage
          jobTitle
          isFollow
          followers_count
        }
        groups {
          id
          name
          description
          hashtags
          category_id
          coverImage
          bannerImage
          category
          privacy
          user_id
          isFollow
          total_members
          members {
            id
            firstName
            lastName
            profileUrl: profileAvtar
          }
        }
      }
    }
  }
`
export default GET_GLOBAL_SEARCH_WITH_ELASTIC_SEARCH
// $limit: Int, $page: Int
//  limit: $limit, page: $page
