import React, { useRef, useState } from 'react'
import styles from './AddProductModalDescription.module.scss'
import dynamic from 'next/dynamic'
// import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAppDispatch } from 'src/store/store'
import { useSelector } from 'react-redux'
import { manageAddProductDetail } from 'src/store/slices/manageAddProductDetail'
const SunEditor: any = dynamic(() => import('suneditor-react'), {
  ssr: false,
})

export default function AddProductModalDescription({ setProductData, productData, validation, setvalidation }) {
  // const [contents, setContents] = useState('')
  // const editorRef = useRef()
  // const handleChnage = (content) => {
  //   setProductData({ ...productData, description: content })
  // }
  const dispatch = useAppDispatch()
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  const addProductDetailSlice = useSelector((state: any) => state?.manageAddProductDetailSlice?.data)

  const changeEditorValue = async (content) => {
    // setProductData({ ...productData, description: content })
    setProductData((prevState) => ({ ...prevState, description: content }))

    setvalidation({ ...validation, description: '' })

    // await dispatch(
    //   manageAddProductDetail({
    //     modal: '',
    //     isOpen: false,
    //     id: {
    //       ...addProductDetailSlice?.id,
    //       mobileProductsData: {
    //         ...addProductDetailSlice?.id?.mobileProductsData,
    //         description: content,
    //       },
    //     },
    //   }),
    // )
  }
  return (
    <div className={styles.addProductModalDescriptionAlignment}>
      <div className={styles.addProductModalDescriptionBox}>
        <div
          className={styles.descriptionHeadingAlignment}
          onClick={() =>
            dispatch(
              fetchMobileModalData({
                allModalData: [
                  ...(fetchMobileModal?.allModalData ? fetchMobileModal?.allModalData : []),
                  {
                    modal: 'mobileProductDescription',
                    isOpen: true,
                    id: 0,
                    transform: 7,
                  },
                ],
                lastModalData: fetchMobileModal?.lastModalData,
              }),
            )
          }
        >
          <h4>Product Description</h4>
          <span className={styles.starAlignment}>*</span>
          <span className={styles.starAlignment}>{validation?.description}</span>
        </div>
        <div>
          <SunEditor
            name="editorData"
            onChange={(content) => changeEditorValue(content)}
            setContents={productData?.description}
            setOptions={{
              buttonList: [
                ['undo', 'redo'],
                ['font', 'fontSize', 'formatBlock'],
                ['bold', 'underline', 'italic', 'strike', 'superscript', 'subscript'],
                ['removeFormat'],
                ['outdent', 'indent'],
                ['align', 'horizontalRule', 'list', 'lineHeight'],
                ['table'],
                ['link', 'image', 'video'],
                ['fullScreen', 'showBlocks', 'codeView'],
                ['preview'],
              ],
            }}
          />
        </div>
      </div>
    </div>
  )
}
