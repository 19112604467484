const protocol = 'http'
// const host = "192.168.0.125:8000/api";
// const host = "192.168.0.109:8000/api";

// FOR_RUN_API_GATEWAY
// const host = '127.0.0.1'
// const port = '4000'
// const trailUrl = '/graphql'

const host = 'localhost'
const port = '3301'
const trailUrl = '/graphql'

const hostUrl = `${protocol}://${host}${port ? ':' + port : ''}`
const endpoint = `${protocol}://${host}${port ? ':' + port : ''}${trailUrl}`

export default {
  protocol: protocol,
  host: host,
  port: port,
  apiUrl: trailUrl,
  endpoint: endpoint,
  hostUrl: hostUrl,
}
