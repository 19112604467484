import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchMobileModalData } from 'src/store/slices/mobileModalSlice'
import { useAppDispatch } from 'src/store/store'
import styles from './MobileViewSecondModal.module.scss'

export default function MobileViewSecondModal({ isOpen, children }) {
  const dispatch = useAppDispatch()
  const [startX, setStartX] = useState(null)
  const fetchMobileModal = useSelector((state: any) => state?.manageMobileModal?.data)
  let productImage = []

  function handleTouchStart(e) {
    setStartX(e.touches[0].pageX)
  }

  function handleTouchMove(e) {
    if (startX && startX + e.touches[0].pageY > 150) {
      handleClose()
    }
  }

  function handleTouchEnd() {
    setStartX(null)
  }

  const handleClose = () => {
    let datass = fetchMobileModal?.allModalData?.filter((item) => item?.modal !== fetchMobileModal?.secondModal?.modal)
    dispatch(
      fetchMobileModalData({
        ...fetchMobileModal,
        secondModal: {},
      }),
    )
  }

  useEffect(() => {
    if (fetchMobileModal?.secondModal?.isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [fetchMobileModal])

  if (fetchMobileModal?.secondModal?.modal === 'productOption') {
    productImage = [...fetchMobileModal?.secondModal?.id?.video, ...fetchMobileModal?.secondModal?.id?.image]
  }

  return (
    <React.Fragment>
      <div
        onClick={() => handleClose()}
        className={`${styles.secondFollowFollowingOverlay} ${isOpen ? styles.secondFollowFollowingOverlayOpen : ''}`}
      ></div>
      <div
        className={`${styles.secondFollowFollowingModal} ${isOpen ? styles.secondFollowFollowingModalOpen : ''}`}
        style={
          isOpen
            ? {
                transform: `translate(0, ${fetchMobileModal?.secondModal?.transform}px)`,
                zIndex: fetchMobileModal?.secondModal?.zIndex,
              }
            : {}
        }
      >
        <div
          className="store-product-filter-click-drop-alignment"
          style={{ zIndex: fetchMobileModal?.secondModal?.zIndex }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <p></p>
        </div>
        {children}
      </div>
    </React.Fragment>
  )
}
