// @ts-nocheck
import React from 'react'
import Slider from 'react-slick'
// import 'slick-carousel/slick/slick.css'
// import 'slick-carousel/slick/slick-theme.css'

export default function DIYRoomModal() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }

  const slides = [
    "/assets/img/artCarft/dry1.png",
    "/assets/img/artCarft/dry2.png",
    "/assets/img/artCarft/dry3.png"
  ]

  return (
    <div className="mobile-view-diy-room-modal-main-alignment">
      <div className="mobile-view-diy-room-modal-alignment">
        <div className="mobile-view-diy-slider-alignment">
          <Slider {...settings}>
            {slides.map((slide, index) => (
              <div key={index}>
                <div className="mobile-view-diy-main-alignment">
                  <div className="mobile-view-main-img-alignment">
                    <img src={slide} alt={`diy room ${index + 1}`} />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}
