import { gql } from '@apollo/client'

export default gql`
  mutation UPDATE_BILLING_ADDRESS(
    $id: Int!
    $firstName: String
    $lastName: String
    $streetAddress: String
    $country: String
    $country_name: String
    $state: String
    $state_name: String
    $city: String
    $zipcode: String
    $buildingName: String
    $number: String
    $isDefault: Boolean
    $isPinLocation: Boolean
    $latitude: Float
    $longitude: Float
  ) {
    updateBillingAddress(
      input: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        streetAddress: $streetAddress
        country: $country
        country_name: $country_name
        state: $state
        state_name: $state_name
        city: $city
        zipcode: $zipcode
        buildingName: $buildingName
        number: $number
        isDefault: $isDefault
        isPinLocation: $isPinLocation
        latitude: $latitude
        longitude: $longitude
      }
    ) {
      firstName
      lastName
      streetAddress
      country
      state
      city
      zipcode
      buildingName
      number
      isDefault
      isPinLocation
      latitude
      longitude
    }
  }
`
