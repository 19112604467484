import React from 'react'
import { useSelector } from 'react-redux'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useAppDispatch } from 'src/store/store'
import styles from '../FinancePayment.module.scss'
import RequestSubmitted from '../../SellerFinance/FinancePayment/RequestSubmitted'

export default function WithdrawRequestProcessedModal() {
  return (
    <div>
      <div className={styles.financePaymentBox}>
        <div className={styles.financePaymentHeading}>
          <h4>Withdraw</h4>
        </div>
        <div>
          <RequestSubmitted />
        </div>
      </div>
    </div>
  )
}
