import React, { useState } from 'react'
import SmallMemberImg from '../../../../../../../public/assets/img/small-member-img.png'
import image1 from '../../../../../../../public/assets/img/book-img/image14.png'
import image2 from '../../../../../../../public/assets/img/book-img/image15.png'
import image3 from '../../../../../../../public/assets/img/book-img/image16.png'
import image4 from '../../../../../../../public/assets/img/book-img/image17.png'

export default function BookSingleclub({ index }) {
  const newReleaseImages = [
    image1.src,
    image2.src,
    image3.src,
    image4.src,
    image1.src,
    image2.src,
    image3.src,
    image4.src,
  ]
  return (
    <div className="book-club-create-box-alignment">
      <div className="book-club-img-alignment">
        <img src={newReleaseImages[index]} alt="BookCoverImg" />
      </div>
      <div className="book-club-create-details-alignment">
        <h6>Romance Hunters</h6>
        <div className="auther-member-details-join-alignment">
          <div className="member-all-details">
            <div className="member-img-flex-alignment">
              <div className="member-img-alignment">
                <img src={SmallMemberImg.src} alt="SmallMemberImg" />
              </div>
              <div className="member-img-alignment">
                <img src={SmallMemberImg.src} alt="SmallMemberImg" />
              </div>
              <div className="member-img-alignment">
                <img src={SmallMemberImg.src} alt="SmallMemberImg" />
              </div>
            </div>
            <div className="member-total-alignment">
              <p> 2.458 members</p>
            </div>
          </div>
          <div className="join-club-button-alignment">
            <button>Join Club</button>
          </div>
        </div>
      </div>
    </div>
  )
}
