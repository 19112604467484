import axios, { AxiosResponse } from 'axios'

type ResponseData = {
  success: boolean
  data: {
    id: string
  }
}

// fetches unique meeting ID needed to obtain token

export const addParticipants = async () => {
  const orgID = '1f6e1dab-1302-4733-a02a-b1f863525e98'
  const apiKey = 'de84adcce79b4701d71d'
  const base64ApiKey = btoa(`${orgID}:${apiKey}`)

  const options = {
    method: 'POST',
    url: 'https://api.dyte.io/v2/meetings',
    headers: {
      Authorization: `Basic ${base64ApiKey}`,
      'Content-Type': 'application/json',
    },
    data: {
      title: 'Sample meeting',
      preferred_region: 'ap-south-1',
      record_on_start: false,
    },
  }

  try {
    const response: AxiosResponse<ResponseData> = await axios(options)
    if (response.data.success) {
      return response
    }
  } catch (error) {
    console.error('Error creating meeting:', error)
  }
}
