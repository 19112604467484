// @ts-nocheck


import moment from 'moment'
import React, { useState } from 'react'
import StarRatings from 'react-star-ratings'

export default function ImagePrevModalMobile({ openImagePrev }) {
  const [rating, setRating] = useState(4.5) // initial rating value

  const handleRating = (rate: number) => {
    setRating(rate)
  }

  return (
    <div className="image-main-contain">
      <div className="image-container-prev-main">
        <div className="image-section-postion">
          <div className="image-class">
            <img src={openImagePrev.image} />
          </div>
          <div className="image-count-hover-section">
            <span>1/8</span>
          </div>
        </div>
      </div>
      <div className="comment-description">
        <div>
          <StarRatings
            rating={rating}
            starRatedColor="orange"
            changeRating={handleRating}
            starEmptyColor="#E4E4E8"
            numberOfStars={5}
            svgIconPath="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
            starDimension="15px"
            starSpacing="1px"
            name="rating"
          />
        </div>
        <div className="feedback-info-text">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce bibendum nisi ligul</p>
        </div>
        <div className="commenter-profile-sectionsss">
          <div className="image-name-align">
            <div className="user-profile-image">
              <img src={'/assets/img/product/fashion-watch1.png'} />
            </div>
            <span>M****</span>
          </div>
          <div className="date-alignment">
            <span> {moment(new Date()).format('MMM DD')}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
