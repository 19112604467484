import { gql } from '@apollo/client'

const GET_SEARCH_GROUP_WITH_ELASTIC_SEARCH = gql`
  query searchGroupsWithElasticSearch($search: String, $limit: Int, $page: Int) {
    searchGroupsWithElasticSearch(search: $search, limit: $limit, page: $page) {
      success
      message
      data {
        id
        name
        description
        banner_image
        logo_image
        privacy
        slug
        isExist
        total_members
        members {
          id
          #firstName
          #lastName
          #userName
          logo_image
          banner_image
        }
      }
    }
  }
`
export default GET_SEARCH_GROUP_WITH_ELASTIC_SEARCH
// $limit: Int, $page: Int
//  limit: $limit, page: $page
