import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import GET_GLOBAL_SEARCH_WITH_ELASTIC_SEARCH from 'src/graphql/queries/sellerQueries/getGlobalSearchWithElasticSearch'
import GET_PRODUCTS_WITH_ELASTIC_SEARCH from 'src/graphql/queries/sellerQueries/getSearchProductsWithElasticSearch'
import GET_SEARCH_STORE_WITH_ELASTIC_SEARCH from 'src/graphql/queries/sellerQueries/getSearchStoressWithElasticSearch'
import GET_SEARCH_USERS_WITH_ELASTIC_SEARCH from 'src/graphql/queries/sellerQueries/getSearchUsersWithElasticSearch'
import GET_SEARCH_GROUP_WITH_ELASTIC_SEARCH from 'src/graphql/queries/sellerQueries/getSearchGroupsWithElasticSearch'
import client1 from 'src/utils/apolloClient'

interface globalSearchSlice {
  loading: true | false
  error: {} | null
  called: true | false
  cartCount: number
  cartData: []
}

const initialState = {
  loading: false,
  error: null,
  data: 0,
  called: false,
  cartCount: 0,
  cartData: [],
} as globalSearchSlice

export const fetchGlobalSearchData = createAsyncThunk('globalSearch/fetchGlobalSearchData', async (search: any) => {
  const { data } = await client1.query({
    query: GET_GLOBAL_SEARCH_WITH_ELASTIC_SEARCH,
    variables: {
      search: search?.keyword,
      limit: search?.limit,
      page: search?.page,
    },
    fetchPolicy: 'network-only',
  })
  return data?.globalSearchWithElasticSearch
})

export const fetchProductSearchData = createAsyncThunk('globalSearch/fetchProductSearchData', async (search: any) => {
  const { data } = await client1.query({
    query: GET_PRODUCTS_WITH_ELASTIC_SEARCH,
    variables: {
      search: search?.keyword,
      limit: search?.limit,
      page: search?.page,
      maxPrice: search?.maxPrice,
      minPrice: search?.minPrice,
    },
    fetchPolicy: 'network-only',
  })
  return data?.searchProductsWithElasticSearch
})

export const fetchStoreSearchData = createAsyncThunk('globalSearch/fetchStoreSearchData', async (search: any) => {
  const { data } = await client1.query({
    query: GET_SEARCH_STORE_WITH_ELASTIC_SEARCH,
    variables: {
      search: search?.keyword,
      limit: search?.limit,
      page: search?.page,
    },
    fetchPolicy: 'network-only',
  })
  return data?.searchStoressWithElasticSearch
})

export const fetchUserSearchData = createAsyncThunk('globalSearch/fetchUserSearchData', async (search: any) => {
  const { data } = await client1.query({
    query: GET_SEARCH_USERS_WITH_ELASTIC_SEARCH,
    variables: {
      search: search?.keyword,
      limit: search?.limit,
      page: search?.page,
    },
    fetchPolicy: 'network-only',
  })
  return data?.searchUsersWithElasticSearch
})

export const fetchClubSearchData = createAsyncThunk('globalSearch/fetchClubSearchData', async (search: any) => {
  const { data } = await client1.query({
    query: GET_SEARCH_GROUP_WITH_ELASTIC_SEARCH,
    variables: {
      search: search?.keyword,
      limit: search?.limit,
      page: search?.page,
    },
    fetchPolicy: 'network-only',
  })
  return data?.searchGroupsWithElasticSearch
})

const globalSearchSlice = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {
    // : (state: any, action) => {
    //   state.cartData = action.payload
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGlobalSearchData.pending, (state: any, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchGlobalSearchData.fulfilled, (state: any, action) => {
      state.loading = 'success'
      state.stores = action?.payload?.data?.stores
      state.products = action?.payload?.data?.products
      state.groups = action?.payload?.data?.groups
      state.users = action?.payload?.data?.users
    })
    builder.addCase(fetchGlobalSearchData.rejected, (state: any, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })
    builder.addCase(fetchProductSearchData.pending, (state: any, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchProductSearchData.fulfilled, (state: any, action) => {
      state.loading = 'success'
      state.products = action?.payload?.data?.products
    })
    builder.addCase(fetchProductSearchData.rejected, (state: any, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })
    builder.addCase(fetchStoreSearchData.pending, (state: any, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchStoreSearchData.fulfilled, (state: any, action) => {
      state.loading = 'success'
      state.stores = action?.payload?.data?.stores
    })
    builder.addCase(fetchStoreSearchData.rejected, (state: any, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })
    builder.addCase(fetchUserSearchData.pending, (state: any, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchUserSearchData.fulfilled, (state: any, action) => {
      state.loading = 'success'
      state.users = action?.payload?.data?.users
    })
    builder.addCase(fetchUserSearchData.rejected, (state: any, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })
    builder.addCase(fetchClubSearchData.pending, (state: any, action) => {
      state.loading = 'loading'
    })
    builder.addCase(fetchClubSearchData.fulfilled, (state: any, action) => {
      state.loading = 'success'
      state.groups = action?.payload?.data
    })
    builder.addCase(fetchClubSearchData.rejected, (state: any, action) => {
      state.loading = 'failure'
      state.error = action.error.message
    })
  },
})

// export const {  } = globalSearchSlice.actions
export default globalSearchSlice.reducer
