import nookies from 'nookies'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
import { API } from 'src/config/API/api.config'

const httpLink = createUploadLink({
  uri: API.endpoint,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from cookies
  const { token } = nookies.get()

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      // Authorization: seller_token ? `Bearer ${seller_token}` : '',
    },
  }
})

const sellerClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

export default sellerClient
