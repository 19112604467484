import { gql } from '@apollo/client'

const GET_SINGLE_COLLECTION_LIKE = gql`
  query GetCollectionSingleLike($slug: String, $userSlug: String) {
    getCollectionSingleLike(slug: $slug, user_slug: $userSlug) {
      isLike
      isPrivate
      isFollow
      message
      success
    }
  }
`
export default GET_SINGLE_COLLECTION_LIKE
