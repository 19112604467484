import React, { useEffect } from 'react'
import { useAuth } from 'src/utils/auth'
import SearchIcon from '../../../../../public/assets/icon/search-icon.svg'
import DECLINE_FRIEND_REQUEST from 'src/graphql/mutations/declineFriendRequest'
import SEND_FRIEND_REQUEST from 'src/graphql/mutations/sendFriendRequest'
import GET_STORE_BY_NAME from 'src/graphql/queries/getStoreByName'
import { useMutation } from '@apollo/client'
import { useAppDispatch } from 'src/store/store'
import { fetchModalData } from 'src/store/slices/manageModalSlice'
import { useSelector } from 'react-redux'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import Link from 'next/link'
import styles from './StoreFollowers.module.scss'
import { fetchStoreFollowers } from 'src/store/slices/fetchAllFeedSlice'
import { useRouter } from 'next/router'

export default function StoreFollowers(props) {
  const { store } = props
  const { user } = useAuth()
  const router = useRouter()
  const getAllState = useSelector((state: any) => state?.manageModalData?.data)
  const dispatch = useAppDispatch()

  const allFollowers = useSelector((state: any) => state?.feedData?.storeFollowers)

  useEffect(() => {
    dispatch(fetchStoreFollowers(router?.query?.id))
  }, [])

  const [declineFriendRequest] = useMutation(DECLINE_FRIEND_REQUEST, {
    onCompleted: () => {
      dispatch(fetchStoreFollowers(router?.query?.id))
    },
    onError: (error) => {},
  })

  const [sendFriendRequest] = useMutation(SEND_FRIEND_REQUEST, {
    onCompleted: ({ sendFriendRequest }) => {
      dispatch(fetchStoreFollowers(router?.query?.id))
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const handleDeclineRequest = (userId) => {
    if (user) {
      declineFriendRequest({
        variables: { user_id: user.id, friend_id: parseInt(userId) },
        refetchQueries: [{ query: GET_STORE_BY_NAME, variables: { slug: store?.businessInformation?.slug } }],
      })
    } else {
      dispatch(
        guestAccountDetailSlice({
          modal: 'authModal',
          isOpen: true,
          id: {
            media:
              'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
            modalFor: 'login',
            startWith: 'loginModal',
          },
        }),
      )
    }
  }

  const handleSendRequest = (userId) => {
    if (user) {
      sendFriendRequest({
        variables: {
          friend_id: parseInt(userId),
          user_id: user?.id,
          isActive: true,
          isFriend: true,
        },
        refetchQueries: [{ query: GET_STORE_BY_NAME, variables: { slug: store?.businessInformation?.slug } }],
      })
    } else {
      dispatch(
        guestAccountDetailSlice({
          modal: 'authModal',
          isOpen: true,
          id: {
            media:
              'https://storage.googleapis.com/bluejestic-media/bluejestic-stage/loginmedia/Live-Shopping-Short.mp4',
            modalFor: 'login',
            startWith: 'loginModal',
          },
        }),
      )
    }
  }

  return (
    <>
      {allFollowers?.length > 0 ? (
        <div className={styles.followAlignment}>
          <div className={styles.followMainFlexAlignment}>
            <div className={styles.headingAlignment}>
              <h4>Followers</h4>
              <p>.</p>
              <span>{allFollowers?.length}</span>
            </div>
            <div className={styles.seeAllAlignment}>
              <a>See All</a>
            </div>
          </div>
          <div className={styles.serachAlignment}>
            <div className={styles.searchInputAlignment}>
              <div className={styles.iconAlignment}>
                <img src={SearchIcon.src} alt="SearchIcon" />
              </div>
              <input type="text" placeholder="Search" />
            </div>
          </div>
          <div className={styles.followPeopleDetailsAlignment}>
            {allFollowers?.map((flr, index) => {
              let isFollow = flr?.user?.isActiveForFriendStatus && flr?.user?.isFriendForFriendStatus ? true : false
              return (
                <div className={styles.followPeopleDetailsFlex} key={index}>
                  <div className={styles.followProfileAlignment}>
                    <div className={styles.profileImg}>
                      <img src={flr?.user?.profileUrl} alt="AdidasLogo" />
                    </div>
                    <Link href={`/user/${flr?.user?.userName}`}>
                      <p
                        className="hover:underline cursor-pointer"
                        // onClick={() => {
                        //   dispatch(
                        //     fetchModalData({
                        //       allModalData: [
                        //         ...getAllState?.allModalData,
                        //         { modal: 'userDetail', isOpen: true, id: flr?.user?.id, modalFor: 'bigModal' },
                        //       ],
                        //       lastModalData: getAllState?.lastModalData,
                        //     }),
                        //   )
                        // }}
                      >
                        {flr?.user.firstName + ' ' + flr?.user.lastName}
                      </p>
                    </Link>
                  </div>

                  {flr?.user?.id === user?.id ? (
                    <div className={styles.followButtonAlignment}>
                      <button>You</button>
                    </div>
                  ) : isFollow ? (
                    <div className={styles.followButtonAlignment} onClick={() => handleDeclineRequest(flr?.user?.id)}>
                      <button className="following">Following</button>
                    </div>
                  ) : (
                    <div className={styles.followButtonAlignment} onClick={() => handleSendRequest(flr?.user?.id)}>
                      <button>+ Follow</button>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        //  <--------------------- No data box alignment start - ------------------------------------>
        <div className="no-data-following-alignment">
          <img src="/assets/icon/no-followers-icon.svg" alt="no-follower icon" />
          <p>Be the first follower.</p>
        </div>
        //  <--------------------- No data box alignment end - ------------------------------------>
      )}
    </>
  )
}
