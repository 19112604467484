import { Col, Progress, Row } from 'antd'
import React, { useRef, useState, useEffect } from 'react'
import styles from './FileUpload.module.css'

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 5000000000

const convertNestedObjectToArray = (nestedObj) => Object.keys(nestedObj).map((key) => nestedObj[key])

type Props = {
  label?: string
  maxFileSizeInBytes?: number
  accept?: string
  multiple?: boolean
  isdrag?: boolean
  progressPercent?: number
  updateFilesCb: (file) => void
  initialFiles?: any
}

const FileUpload: React.FC<Props> = ({
  label,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  isdrag = false,
  progressPercent,
  multiple = true,
  accept = 'image/*,video/*',
  initialFiles = [],
  ...otherProps
}) => {
  const fileInputField = useRef(null)
  const [files, setFiles] = useState<{ [key: string]: File }>({})
  const [initialUrls, setInitialUrls] = useState<string[]>([])
  const [dragOver, setDragOver] = useState(false)

  useEffect(() => {
    if (initialFiles?.length > 0) {
      setInitialUrls(initialFiles)
      // Convert URLs to File objects
      initialFiles.forEach((url, index) => {
        if (typeof url === 'string') {
          fetch(url)
            .then((res) => res.blob())
            .then((blob) => {
              const fileName = url.split('/').pop() || `file-${index}`
              const fileType = blob.type || 'image/jpeg'
              const file = new File([blob], fileName, { type: fileType })
              setFiles((prevFiles) => ({
                ...prevFiles,
                [fileName]: file,
              }))
            })
            .catch((error) => console.error('Error loading initial file:', error))
        }
      })
    }
  }, [initialFiles])

  const handleUploadBtnClick = () => {
    fileInputField.current.click()
  }

  const addNewFiles = (newFiles) => {
    const allFiles = { ...files }
    const updatedFiles = {}

    // Check if we already have a video
    const hasExistingVideo = Object.values(allFiles).some((file) => file.type.includes('video'))
    // Check if we already have images
    const hasExistingImages = Object.values(allFiles).some((file) => file.type.includes('image'))

    for (let file of newFiles) {
      const isVideo = file.type.includes('video')
      const isImage = file.type.includes('image')

      // Skip invalid file types
      if (!isVideo && !isImage) continue

      // Check file size
      if (file.size > maxFileSizeInBytes) {
        alert('File size exceeds the limit.')
        continue
      }

      // Handle video restrictions
      if (isVideo) {
        if (hasExistingVideo || hasExistingImages) {
          alert('Only one video allowed, or cannot mix videos with images.')
          return allFiles
        }
        if (!multiple) {
          return { [file.name]: file }
        }
      }

      // Handle image restrictions
      if (isImage) {
        if (hasExistingVideo) {
          alert('Cannot mix images with video.')
          return allFiles
        }
      }

      // Add valid file
      updatedFiles[file.name] = file
    }

    // If we're not allowing multiple files, only keep the latest
    if (!multiple) {
      const fileNames = Object.keys(updatedFiles)
      if (fileNames.length > 0) {
        const lastName = fileNames[fileNames.length - 1]
        return { [lastName]: updatedFiles[lastName] }
      }
      return allFiles
    }

    return { ...allFiles, ...updatedFiles }
  }

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles)
      updateUploadedFiles(updatedFiles)
    }
  }

  const removeFile = (fileName) => {
    delete files[fileName]
    setFiles({ ...files })
    updateUploadedFiles({ ...files })
  }

  const updateUploadedFiles = (files) => {
    setFiles(files)
    if (updateFilesCb) {
      updateFilesCb(Object.values(files))
    }
  }

  useEffect(() => {
    if (initialFiles.length > 0) {
      const filesAsArray = convertNestedObjectToArray(files)
      updateFilesCb(filesAsArray)
    }
  }, [files, initialFiles, updateFilesCb])

  const totalFiles = initialUrls.length + Object.keys(files).length
  const getGridClass = () => {
    if (totalFiles === 2) return styles.previewGridTwo
    if (totalFiles === 4) return styles.previewGridFour
    return styles.previewGrid
  }

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setDragOver(true)
  }

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setDragOver(false)
  }

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setDragOver(false)

    const droppedFiles = Array.from(e.dataTransfer.files)
    const updatedFiles = addNewFiles(droppedFiles)
    updateUploadedFiles(updatedFiles)
  }

  return isdrag ? (
    <section className={styles.fileUploadContainerMobile}>
      <label className="input-label">{label}</label>
      <div className={getGridClass()}>
        {/* Show initial URLs as images */}
        {initialUrls.slice(0, 5).map((url, index) => (
          <div key={`initial-${index}`} className={styles.previewItem}>
            <img src={url} alt={`Initial file ${index + 1}`} />
            <button
              className={styles.removeButton}
              onClick={() => {
                setInitialUrls((prev) => prev.filter((_, i) => i !== index))
                updateUploadedFiles(Object.values(files))
              }}
            >
              ×
            </button>
          </div>
        ))}
        {/* Show newly uploaded files */}
        {Object.keys(files)
          .slice(0, Math.max(0, 5 - initialUrls.length))
          .map((fileName, index) => {
            let file = files[fileName]
            const totalIndex = initialUrls.length + index
            const isLastItem = totalIndex === 4 && Object.keys(files).length + initialUrls.length > 5
            return (
              <div
                key={fileName}
                className={`${styles.previewItem} ${isLastItem ? styles.lastItem : ''} ${
                  totalFiles === 1 ? styles.large : ''
                }`}
              >
                {file.type.startsWith('image/') ? (
                  <img src={URL.createObjectURL(file)} alt={fileName} />
                ) : (
                  <video src={URL.createObjectURL(file)} />
                )}
                <button className={styles.removeButton} onClick={() => removeFile(fileName)}>
                  ×
                </button>
                {isLastItem && (
                  <div className={styles.counter}>+{Object.keys(files).length + initialUrls.length - 5}</div>
                )}
              </div>
            )
          })}
      </div>
      {Object.keys(files).length != 0 && (
        <div className="antprogressbarmain" style={{ margin: '0 0 24px 0' }}>
          <Row justify="center">
            <Col span={23}>
              <Progress percent={progressPercent} />
            </Col>
          </Row>
        </div>
      )}
      <div className={styles.fileUploadDrag}>
        <div className={styles.fileUploadDragBox}>
          <input
            className="form-field"
            type="file"
            ref={fileInputField}
            onChange={handleNewFileUpload}
            {...otherProps}
          />
          <p>
            <img src="/assets/seller/icons/image.svg" alt="image" />
            <span>Drag and drop your files here</span>
          </p>
          <p>or</p>
          <button type="button" onClick={handleUploadBtnClick}>
            Browse Files
          </button>
        </div>
      </div>
    </section>
  ) : (
    <section className={styles.fileUploadContainer}>
      <label className="input-label">{label}</label>
      <div className={getGridClass()}>
        {/* Show initial URLs as images */}
        {initialUrls.slice(0, 5).map((url, index) => (
          <div key={`initial-${index}`} className={styles.previewItem}>
            <img src={url} alt={`Initial file ${index + 1}`} />
            <button
              className={styles.removeButton}
              onClick={() => {
                setInitialUrls((prev) => prev.filter((_, i) => i !== index))
                updateUploadedFiles(Object.values(files))
              }}
            >
              ×
            </button>
          </div>
        ))}
        {/* Show newly uploaded files */}
        {Object.keys(files)
          .slice(0, Math.max(0, 5 - initialUrls.length))
          .map((fileName, index) => {
            let file = files[fileName]
            const totalIndex = initialUrls.length + index
            const isLastItem = totalIndex === 4 && Object.keys(files).length + initialUrls.length > 5
            return (
              <div
                key={fileName}
                className={`${styles.previewItem} ${isLastItem ? styles.lastItem : ''} ${
                  totalFiles === 1 ? styles.large : ''
                }`}
              >
                {file.type.startsWith('image/') ? (
                  <img src={URL.createObjectURL(file)} alt={fileName} />
                ) : (
                  <video src={URL.createObjectURL(file)} />
                )}
                <button className={styles.removeButton} onClick={() => removeFile(fileName)}>
                  ×
                </button>
                {isLastItem && (
                  <div className={styles.counter}>+{Object.keys(files).length + initialUrls.length - 5}</div>
                )}
              </div>
            )
          })}
      </div>
      <div className={styles.uploadSection}>
        <div
          className={`${styles.uploadButton} ${dragOver ? styles.dragOver : ''}`}
          onClick={handleUploadBtnClick}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32">
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor="#7c3aed" />
                <stop offset="100%" stopColor="#3b82f6" />
              </linearGradient>
            </defs>
            <path d="M16 8l-8 8h5v8h6v-8h5l-8-8zM4 24v2h24v-2H4z" fill="url(#gradient)" />
          </svg>
          <p>Add Photos & Videos</p>
        </div>

        <input
          className={styles.hiddenInput}
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          accept={accept}
          multiple={multiple}
          {...otherProps}
        />
      </div>

      {progressPercent > 0 && (
        <div className={styles.progressBar}>
          <Progress percent={progressPercent} />
        </div>
      )}
    </section>
  )
}

export default FileUpload
