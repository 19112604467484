import React, { useState } from 'react'
import { LoginForm } from './types'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import nookies from 'nookies'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
import { useAppDispatch } from 'src/store/store'
import LOGIN from 'src/graphql/mutations/login'
import { useRouter } from 'next/router'
import { notification } from 'antd'
import { useSelector } from 'react-redux'
import { signInWithPopup } from 'firebase/auth'
import { TwitterProvider, auth, facebookProvider, provider } from 'src/Helpers/Firebase/Firebase'
import firebase from 'firebase/app'
import 'firebase/auth'

export default function NewLoginForm() {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const guestDetail = useSelector((state: any) => state?.guestAccountInfo?.data)
  const [passwordShowns, setPasswordShowns] = useState(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginForm>({})

  const [loginUser] = useMutation(LOGIN, {
    onCompleted: (data) => {
      if (data?.signin?.success) {
        // notification['success']({
        //   message: 'Login successful',
        //   description: "You've login successfully.",
        // })
        const {
          signin: { token: token },
        } = data
        nookies.set(undefined, 'token', token, {
          path: '/',
        })
        // nookies.set(undefined, 'seller_token', data?.signin?.seller_token, {
        //   path: '/',
        // })
        // nookies.set(undefined, 'role', data?.signin?.role, {
        //   path: '/',
        // })
        dispatch(
          guestAccountDetailSlice({
            modal: 'authModal',
            isOpen: false,
            id: {
              media: [],
              modalFor: '',
            },
          }),
        )

        if (data?.signin?.role === 'user') {
          if (data?.signin?.isUserOnboardCompleted) {
            const getAllNookies = nookies.get()
            const redirectUrl = getAllNookies?.redirectUrl
            router.push(redirectUrl ? redirectUrl : '/feed')
            nookies.destroy(undefined, 'redirectUrl', { path: '/' })
            // router.push('/feed')
          } else {
            router.push('/onboarding')
          }
        }

        // router.push('/feed')

        // **********************

        // if (data?.signin?.role === 'user') {
        // if (data?.signin?.isUserOnboardCompleted) {
        //   router.push('/feed')
        // } else {
        //   router.push('/onboarding')
        // }

        // ********************
        // }
        // else if (data?.signin?.role === 'admin') {
        //   const { role } = nookies.get()
        //   if (role === 'admin') {
        //     router.push('/admin/store')
        //   }
        // }
      } else {
        notification['error']({
          message: 'Error',
          description: data?.signin?.message,
        })
        return
      }
    },
    onError: (error: any) => {
      // let message = JSON.parse(JSON.stringify(error))

      notification['error']({
        message: 'Error',
        description: 'Something went Wrong, Contact your Administrator.',
        // description: message?.message ? message?.message : 'Something went wrong, Please try later',
      })
    },
  })

  const gotoForgotPassword = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: { ...guestDetail?.id, media: 'login', modalFor: 'forgot' },
      }),
    )
  }

  const onSubmitLogin = (data: LoginForm) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latitude = position.coords.latitude.toString()
          const longitude = position.coords.longitude.toString()
          loginUser({
            variables: {
              input: {
                email: data.email,
                password: data.password,
                social_type: 'MANUAL',
                loginActivity: {
                  longitude: longitude,
                  latitude: latitude,
                  deviceName: '',
                },
              },
            },
          })
        },
        (error) => {
          console.error('Error retrieving user location:', error)
          loginUser({
            variables: {
              input: {
                email: data.email,
                password: data.password,
                social_type: 'MANUAL',
                loginActivity: {
                  longitude: '',
                  latitude: '',
                  deviceName: '',
                },
              },
            },
          })
        },
      )
    } else {
      console.error('Geolocation is not supported')
    }
  }

  const handleSignUp = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: { media: 'login', modalFor: 'signup', startWith: 'signupModal' },
      }),
    )
  }

  const handleBack = () => {
    dispatch(guestAccountDetailSlice({ modal: '', isOpen: false, id: 0 }))
  }

  const togglePasswords = () => {
    setPasswordShowns(!passwordShowns)
  }

  const handleSocialSigUp = async (e, social_type) => {
    if (social_type === 'GOOGLE') {
      signInWithPopup(auth, provider)
        .then((result: any) => {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              async (position) => {
                const latitude = position.coords.latitude.toString()
                const longitude = position.coords.longitude.toString()
                loginUser({
                  variables: {
                    input: {
                      social_type: social_type,
                      access_token: result?.user?.accessToken,
                      loginActivity: {
                        longitude: longitude,
                        latitude: latitude,
                        deviceName: '',
                      },
                    },
                  },
                })
              },
              (error) => {
                // console.error('Error retrieving user location:', error)
                loginUser({
                  variables: {
                    input: {
                      social_type: social_type,
                      access_token: result?.user?.accessToken,
                      loginActivity: {
                        longitude: '',
                        latitude: '',
                        deviceName: '',
                      },
                    },
                  },
                })
              },
            )
          } else {
            console.error('Geolocation is not supported')
          }
        })
        .catch((error) => {
          console.log('Caught error Popup closed', error)
          const errorData = JSON.parse(JSON.stringify(error))
          console.log(errorData?.code)
          if (errorData?.code === 'auth/account-exists-with-different-credential') {
            notification['error']({
              message: 'Error',
              description: 'User already Exist.',
            })
          }
        })
    } else if (social_type === 'FACEBOOK') {
      try {
        await signInWithPopup(auth, facebookProvider)
          .then((result: any) => {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                async (position) => {
                  const latitude = position.coords.latitude.toString()
                  const longitude = position.coords.longitude.toString()
                  loginUser({
                    variables: {
                      input: {
                        social_type: social_type,
                        access_token: result?.user?.accessToken,
                        loginActivity: {
                          longitude: longitude,
                          latitude: latitude,
                          deviceName: '',
                        },
                      },
                    },
                  })
                },
                (error) => {
                  // console.error('Error retrieving user location:', error)
                  loginUser({
                    variables: {
                      input: {
                        social_type: social_type,
                        access_token: result?.user?.accessToken,
                        loginActivity: {
                          longitude: '',
                          latitude: '',
                          deviceName: '',
                        },
                      },
                    },
                  })
                },
              )
            } else {
              console.error('Geolocation is not supported')
            }
          })
          .catch((error) => {
            console.log('Caught error Popup closed', error)
            const errorData = JSON.parse(JSON.stringify(error))
            console.log(errorData?.code)
            if (errorData?.code === 'auth/account-exists-with-different-credential') {
              notification['error']({
                message: 'Error',
                description: 'User already Exist.',
              })
            }
          })
        // console.log('result.user', result.user)
      } catch (error) {
        console.log('error', error)
      }
    } else if (social_type === 'TWITTER') {
      try {
        // const result = await
        signInWithPopup(auth, TwitterProvider)
          .then((result: any) => {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                async (position) => {
                  const latitude = position.coords.latitude.toString()
                  const longitude = position.coords.longitude.toString()
                  loginUser({
                    variables: {
                      input: {
                        social_type: social_type,
                        access_token: result?.user?.accessToken,
                        email: result?._tokenResponse?.email ? result?._tokenResponse?.email : '',
                        loginActivity: {
                          longitude: longitude,
                          latitude: latitude,
                          deviceName: '',
                        },
                      },
                    },
                  })
                },
                (error) => {
                  loginUser({
                    variables: {
                      input: {
                        social_type: social_type,
                        access_token: result?.user?.accessToken,
                        email: result?._tokenResponse?.email ? result?._tokenResponse?.email : '',
                        loginActivity: {
                          longitude: '',
                          latitude: '',
                          deviceName: '',
                        },
                      },
                    },
                  })
                },
              )
            } else {
              console.error('Geolocation is not supported')
            }
          })
          .catch((error) => {
            console.log('Caught error Popup closed', error)
            const errorData = JSON.parse(JSON.stringify(error))
            console.log(errorData?.code)
            if (errorData?.code === 'auth/account-exists-with-different-credential') {
              notification['error']({
                message: 'Error',
                description: 'User already Exist.',
              })
            }
          })
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  return (
    <div className="new-login-form-alignment">
      <div className="new-login-mobile-view-back" onClick={() => handleBack()}>
        <button>
          <svg xmlns="http://www.w3.org/2000/svg" width="7" height="14" viewBox="0 0 7 14" fill="none">
            <path d="M6 1L1 7L6 13" stroke="#808191" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>{' '}
          Back
        </button>
      </div>
      <div className="new-login-mobile-View_logo">
        <img src="/assets/img/bluejestic-new-logo.svg" alt="logo" />
      </div>
      <div className="new-login-form-heading">
        <h4>Login</h4>
      </div>
      <form className="form-wrapper" onSubmit={handleSubmit(onSubmitLogin)}>
        <div className="new-login-form-input-alignment">
          <div className="new-login-form-input">
            {/* input-error-input */}
            <div className="new-login-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M16.6668 17.5V15.8333C16.6668 14.9493 16.3156 14.1014 15.6905 13.4763C15.0654 12.8512 14.2176 12.5 13.3335 12.5H6.66683C5.78277 12.5 4.93493 12.8512 4.30981 13.4763C3.68469 14.1014 3.3335 14.9493 3.3335 15.8333V17.5"
                  fill="#5F40AF"
                />
                <path
                  d="M16.6668 17.5V15.8333C16.6668 14.9493 16.3156 14.1014 15.6905 13.4763C15.0654 12.8512 14.2176 12.5 13.3335 12.5H6.66683C5.78277 12.5 4.93493 12.8512 4.30981 13.4763C3.68469 14.1014 3.3335 14.9493 3.3335 15.8333V17.5H16.6668Z"
                  stroke="#5F40AF"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.99984 9.16667C11.8408 9.16667 13.3332 7.67428 13.3332 5.83333C13.3332 3.99238 11.8408 2.5 9.99984 2.5C8.15889 2.5 6.6665 3.99238 6.6665 5.83333C6.6665 7.67428 8.15889 9.16667 9.99984 9.16667Z"
                  fill="#5F40AF"
                  stroke="#5F40AF"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <input
              {...register('email', {
                required: 'Email or username',
              })}
              placeholder="Email"
              type="email"
              className="is-invalid"
            />
            {/* {errors.email?.message && <div className="invalid-feedback">{errors.email.message}</div>} */}
            {/* <input type="text" placeholder="Email or username" /> */}{' '}
          </div>
          <div className="new-login-form-input">
            {/* input-error-input */}
            <div className="new-login-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clipPath="url(#clip0_1579_41076)">
                  <mask id="mask0_1579_41076" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                    <path d="M20 0H0V20H20V0Z" fill="white" />
                  </mask>
                  <g>
                    <path
                      d="M15.625 7.5H15V5C15 2.2425 12.7575 0 10 0C7.2425 0 5 2.2425 5 5V7.5H4.375C3.34167 7.5 2.5 8.34084 2.5 9.375V18.125C2.5 19.1592 3.34167 20 4.375 20H15.625C16.6583 20 17.5 19.1592 17.5 18.125V9.375C17.5 8.34084 16.6583 7.5 15.625 7.5ZM6.66667 5C6.66667 3.16167 8.16167 1.66667 10 1.66667C11.8383 1.66667 13.3333 3.16167 13.3333 5V7.5H6.66667V5ZM10.8333 13.935V15.8333C10.8333 16.2933 10.4608 16.6667 10 16.6667C9.53917 16.6667 9.16667 16.2933 9.16667 15.8333V13.935C8.67083 13.6458 8.33333 13.1142 8.33333 12.5C8.33333 11.5808 9.08083 10.8333 10 10.8333C10.9192 10.8333 11.6667 11.5808 11.6667 12.5C11.6667 13.1142 11.3292 13.6458 10.8333 13.935Z"
                      fill="#5F40AF"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_1579_41076">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <input
              {...register('password', {
                required: 'Password is required',
              })}
              placeholder="Password"
              type={passwordShowns ? 'text' : 'password'}
              className="is-invalid password-show-input"
            />
            <div className="password-show-icon">
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M14.9502 14.9499C13.5257 16.0358 11.7911 16.6373 10.0002 16.6666C4.16683 16.6666 0.833496 9.99994 0.833496 9.99994C1.87007 8.06819 3.30778 6.38045 5.05016 5.04994M8.25016 3.53327C8.82377 3.39901 9.41105 3.33189 10.0002 3.33327C15.8335 3.33327 19.1668 9.99994 19.1668 9.99994C18.661 10.9463 18.0577 11.8372 17.3668 12.6583M11.7668 11.7666C11.538 12.0122 11.262 12.2092 10.9553 12.3459C10.6486 12.4825 10.3176 12.556 9.98191 12.5619C9.64623 12.5678 9.3128 12.5061 9.0015 12.3803C8.69021 12.2546 8.40743 12.0675 8.17004 11.8301C7.93264 11.5927 7.74549 11.3099 7.61975 10.9986C7.49402 10.6873 7.43227 10.3539 7.43819 10.0182C7.44411 9.68252 7.51759 9.35148 7.65423 9.04481C7.79087 8.73815 7.98787 8.46215 8.2335 8.23327"
                  stroke="#7A7E88"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path d="M2 2L18 18" stroke="#7A7E88" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
              </svg> */}
              <div
                className={`cursor-pointer fa ${passwordShowns ? 'fa-eye' : 'fa-eye-slash'}`}
                onClick={togglePasswords}
              ></div>
            </div>
            {/* {errors.password?.message && <div className="invalid-feedback">{errors.password.message}</div>} */}
          </div>
          <div className="remeber-login-alignment">
            <div className="remeber-input-alignment">
              <input {...register('remember')} type="checkbox" className="is-invalid mr-1" />
              <label>Remember me</label>
            </div>
            <div className="forgot-password-alignment" onClick={() => gotoForgotPassword()}>
              <a>Forgot Password</a>
            </div>
          </div>
          <div className="login-button-alignment">
            <button type="submit">Login</button>
          </div>
        </div>
      </form>
      <div className="login-with-section">
        {/* <div className="login-error-message-section">
          <p>Email or password is incorrect.</p>
        </div> */}
        <h6>or login with</h6>
        <div className="login-with-option-section">
          <div className="with-option-icon" onClick={(e) => handleSocialSigUp(e, 'GOOGLE')}>
            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
              <path
                d="M41.3802 22.4545C41.3802 21.0363 41.2529 19.6727 41.0165 18.3636H22.1802V26.109H32.9438C32.4711 28.5999 31.0529 30.709 28.9256 32.1272V37.1636H35.4165C39.1983 33.6727 41.3802 28.5454 41.3802 22.4545Z"
                fill="#4285F4"
              />
              <path
                d="M22.1797 41.9999C27.5797 41.9999 32.107 40.2181 35.4161 37.1636L28.9252 32.1272C27.1434 33.3272 24.8706 34.0545 22.1797 34.0545C16.9797 34.0545 12.5616 30.5454 10.9797 25.8181H4.3252V30.9817C7.6161 37.509 14.3615 41.9999 22.1797 41.9999Z"
                fill="#34A853"
              />
              <path
                d="M10.9802 25.7999C10.5802 24.5999 10.3438 23.3272 10.3438 21.9999C10.3438 20.6727 10.5802 19.3999 10.9802 18.1999V13.0363H4.32563C2.96199 15.7272 2.18018 18.7636 2.18018 21.9999C2.18018 25.2363 2.96199 28.2727 4.32563 30.9636L9.50744 26.9272L10.9802 25.7999Z"
                fill="#FBBC05"
              />
              <path
                d="M22.1797 9.96363C25.1252 9.96363 27.7434 10.9818 29.8343 12.9454L35.5615 7.21818C32.0888 3.98182 27.5797 2 22.1797 2C14.3615 2 7.6161 6.4909 4.3252 13.0364L10.9797 18.2C12.5616 13.4727 16.9797 9.96363 22.1797 9.96363Z"
                fill="#EA4335"
              />
            </svg>
          </div>
          <div className="with-option-icon" onClick={(e) => handleSocialSigUp(e, 'FACEBOOK')}>
            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 22.1117C2 32.055 9.22167 40.3233 18.6667 42V27.555H13.6667V22H18.6667V17.555C18.6667 12.555 21.8883 9.77833 26.445 9.77833C27.8883 9.77833 29.445 10 30.8883 10.2217V15.3333H28.3333C25.8883 15.3333 25.3333 16.555 25.3333 18.1117V22H30.6667L29.7783 27.555H25.3333V42C34.7783 40.3233 42 32.0567 42 22.1117C42 11.05 33 2 22 2C11 2 2 11.05 2 22.1117Z"
                fill="#1877F2"
              />
            </svg>
          </div>
          <div className="with-option-icon" onClick={(e) => handleSocialSigUp(e, 'TWITTER')}>
            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
              <path
                d="M33.5017 4H39.635L26.235 19.251L42 40H29.6567L19.99 27.4142L8.92667 40H2.79L17.1233 23.6869L2 4.00166H14.6567L23.395 15.5055L33.5017 4ZM31.35 36.3457H34.7483L12.81 7.46342H9.16333L31.35 36.3457Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="login-dont-account-section">
        <p>Don’t have any account?</p>
        <a onClick={handleSignUp}>Sign Up</a>
      </div>
    </div>
  )
}
